import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '../../skeleton'

export const IdChip = ({
  size = 'm',
  isLoading = false
}) => {
  const { t } = useTranslation('componentLibrary')

  return isLoading
    ? (
      <Skeleton
        variant="rounded"
        sx={[
          size === 's' && { width: '20px', height: '20px' },
          size === 'm' && { width: '24px', height: '24px' }
        ]}
      />
    )
    : (
      <Typography
        variant="caption"
        sx={[
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: '0',
            backgroundColor: 'action.selected',
            color: 'text.primary',
            borderRadius: '2px',
            width: '24px',
            height: '24px'
          },
          size === 's' && {
            width: '20px',
            height: '20px'
          }
        ]}
      >
        {t('general.id')}
      </Typography>
    )
}

IdChip.propTypes = {
  /** size-variant of the component */
  size: PropTypes.oneOf([ 's', 'm' ]),
  /** Loading state */
  isLoading: PropTypes.bool
}
