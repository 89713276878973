import React, { useCallback, useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { getUUID } from "./utils";
import Header from "@core/components/Header";
import Footer from "@core/components/Footer";
import { Navigate, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import "@mercedes-benz/typeface-mb-corpo-a";
import "@mercedes-benz/typeface-mb-corpo-s";
import AccessRoutes from "./route";
import { NavDrawer, useUser, usePermission } from "@alice/component_library";
import { Grid } from "@mui/material";
import { isSuperAdmin } from "./utils";
import Loader from "@components/Loader";
import { HasCCAccess } from "@actions/controlCenterActions";
import { AdminRoles } from "@actions/userActions";
import { isInsightsEnabled } from "./config";
import { hasData } from "@utils/Validator";
import SnackBar from "@core/components/SnackBar";
import {
  setSessionStorage,
  getSessionStorage,
  clearSessionStorage,
} from "@src/utils";
import ScrollToTop from "@utils/ScrollTop";
import { Element } from "react-scroll";
import { hasReviewAccess } from "./actions";

const drawerWidth = 70;

export const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  [theme.breakpoints.down("xl", "md", "lg")]: {
    minWidth: `calc(100% - ${drawerWidth}px)`,
  },
  [theme.breakpoints.down("sm", "xs")]: {
    minWidth: "100%  !important",
  },
}));

const App = () => {
  const [sideBar, setSideBar] = useState();
  const dispatch = useDispatch();
  const { user } = useUser();
  const { adminRoles: adminRolesFromCL = new Set() } = usePermission();
  const adminRolesFromstate = useMemo(
    () => (adminRolesFromCL ? [...adminRolesFromCL] : []),
    [adminRolesFromCL]
  );
  useEffect(() => {
    const sessionId = getSessionStorage("sessionId");
    if (sessionId !== user?.id || sessionId === null) {
      clearSessionStorage();
      setSessionStorage("sessionId", user?.id);
    }
  }, [user?.id]);

  const getSideBarMenus = (routes) =>
    routes
      .filter((d) => d.isMenu && d.isActive)
      .sort((a, b) => a.order - b.order);

  const getRoutes = useCallback(() => {
    let allROutes = [...AccessRoutes];
    if (!isInsightsEnabled) {
      return getSideBarMenus(allROutes);
    }
    let adminRoles = { active: [], all: adminRolesFromstate };
    if (hasData(adminRoles.all) && isSuperAdmin(adminRoles.all, "cc")) {
      const index = allROutes.findIndex((m) => m.name === "control-center");
      allROutes[index].isActive = true;
      dispatch(HasCCAccess(true));
      adminRoles.active = adminRoles.all;
    }
    if (
      hasData(adminRoles.all) &&
      isSuperAdmin(adminRoles.all, "accessReview")
    ) {
      const indexAR = allROutes.findIndex((m) => m.name === "access-review");
      allROutes[indexAR].isActive = true;
      adminRoles.active = adminRoles.all;
      dispatch(hasReviewAccess(true));
    }
    dispatch(AdminRoles(adminRoles));
    const menus = getSideBarMenus(allROutes);
    setSideBar(menus);
  }, [adminRolesFromstate, dispatch]);

  useEffect(() => {
    if (adminRolesFromstate) getRoutes();
  }, [adminRolesFromstate, getRoutes]);

  if (!hasData(sideBar)) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <div className="mh-95vh">
          <Loader />
        </div>
      </Grid>
    );
  }

  return (
    <div className="main-container" id="scroller">
      <Element name="myScrollToElement"></Element>
      <React.Fragment>
        <Main>
          <Header />
          <NavDrawer showSupport navTargets={sideBar} size="small">
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Navigate to="/access" />} />
              <Route
                path="/access/profile/overview"
                element={<Navigate to="/access/profile" />}
              />
              {AccessRoutes.map((route, i) => {
                if (route.path && route.component) {
                  return (
                    <Route
                      key={getUUID()}
                      path={route.path}
                      element={route.component}
                    />
                  );
                }
                return null;
              })}
              <Route path="*" element={<Navigate to="/access" />} />
            </Routes>
            <Footer />
          </NavDrawer>
          <SnackBar />
        </Main>
      </React.Fragment>
    </div>
  );
};

export default App;
