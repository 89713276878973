import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import { AppCard } from "@alice/component_library";
import { fetchAvailableApplications } from "@sliceactions/newRequestActions";
import { resetAvailableApplications } from "@slice/newRequestSlice";
import NoApplicationsIcon from "@Icons/newRequestPage/NoApplications.svg";
import SearchComponent from "../PageHeader/Filters/SearchComponent";
import {
  AppList,
  NoApplicationContainer,
  SelectApplicationPopover,
  StyledMenuItem,
} from "./styledComponent";
import { AppListSkeleton } from "../Skeleton/CommonSkeletons";
const limit = 5;
const FilterPopover = ({
  userId,
  open,
  anchorEl,
  handleClose,
  selectedApplication,
  handleSelectApplication,
}) => {
  const dispatch = useDispatch();
  const sentinelRef = useRef(null);
  const { t } = useTranslation();
  const {
    data: applications = [],
    loading,
    hasMore,
    offset,
  } = useSelector((state) => state.newRequestSlice.availableApplications);
  const [search, setSearch] = useState("");
  const handleSearch = (key) => {
    if (key === search) return;
    setSearch(key);
    dispatch(resetAvailableApplications());
  };
  const handleSelected = (data) => {
    handleSelectApplication(data);
    handleClose();
  };
  useEffect(() => {
    dispatch(fetchAvailableApplications({ userId: userId, search: search }));
  }, [dispatch, userId, search]);
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry.isIntersecting && hasMore) {
        dispatch(
          fetchAvailableApplications({
            userId: userId,
            offset: offset + limit,
            search: search,
          })
        );
      }
    });

    if (sentinelRef.current) {
      intersectionObserver.observe(sentinelRef.current);
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [dispatch, hasMore, offset, userId, search]);

  return (
    <SelectApplicationPopover
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <SearchComponent
        handleSearch={handleSearch}
        searchKey={search}
        label={t("search-for-appId-name-description")}
      />
      <AppList>
        {applications?.map((el, index) => (
          <StyledMenuItem
            onClick={() => handleSelected(el)}
            ref={
              loading || !hasMore
                ? null
                : index === applications.length - 1
                ? sentinelRef
                : undefined
            }
            selectedApplicationId={selectedApplication.id}
            id={el.id}
          >
            <div className="select-application-list">
              <Divider sx={{ marginBottom: "16px" }} orientation="horizontal" />
              <AppCard data={el} size="s" variant="tiny" />
            </div>
          </StyledMenuItem>
        ))}

        {loading && <AppListSkeleton />}
        {!loading && !!!applications.length && (
          <NoApplicationContainer>
            <NoApplicationsIcon />
            <Typography variant="body1`">
              {t("no-applications-found")}
            </Typography>
          </NoApplicationContainer>
        )}
      </AppList>
    </SelectApplicationPopover>
  );
};

export default FilterPopover;
