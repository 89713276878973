import React, { useState, useEffect, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Box,
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
} from "@mui/material";

import {
  AliceIcon,
  AliceIconType,
  Message,
  CloseButton,
  SearchBar,
  Table,
  usePermission,
} from "@alice/component_library";

import Communication from "@services/Communication";
import urlConfig from "@services/urlConfig";
import { MyUserDetails } from "@actions";
import { getUUID, isSuperAdmin } from "@utils";
import { hasData } from "@utils/Validator";

import NoResults from "@components/Noresults";
import { TextOnlyTooltip } from "@components/TextOnlyTooltip";
import { SecondaryButton, PrimaryButton } from "@components/button";

import { WarningIcon } from "@components/Styled";
import TableRowsLoader from "@components/Skeleton/TableRowsLoader";
import Detail from "@Icons/detail";
import BulkRemoval from "@pages/user/bulkAssignRemoval";
import {
  LoadMoreContainer,
  SpanCount,
  PopUpTitleStyle,
  PopUpContentStyle,
  BulkRemoveButtonContainer,
  RemoveButtonContainer,
  SearchBarContainer,
  TabbleContainer,
} from "../../styledComponents";
import { isMobile } from "react-device-detect";
import { headerData } from "@pages/CC/helper.js";
import { useWidth } from "@utils/hooks";
import { useNotification } from "@alice/component_library";

const UserTable = ({ t, navigate }) => {
  const limit = 10;
  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: true,
    isDesktop: false,
  });
  const dispatch = useDispatch();
  const myUsers = useSelector((state) => state.controlCenter.users);
  const widthPanel = useWidth();
  const { notify } = useNotification();

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
      }
    }
  }, [widthPanel]);

  const [offset, setOffset] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState(myUsers.search || "");
  const [searchKey, setSearchKey] = useState(myUsers.search || "");
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteSelectd, setDeleteSelectd] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [failedIds, setFailedIds] = useState([]);

  const { adminRoles: adminRoleSet } = usePermission();
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const errorHandler = useCallback(
    (err) => {
      setIsLoading(false);
      setLoadMore(false);
      notify({
        severity: "error",
        message: `${t("user-update-failed-message")}. ${t("try-again")}`,
      });
    },
    [dispatch, t]
  );

  const searchHandler = (e) => {
    setOffset(0);
    setSearch(e);
    setSearchKey(e);
    setIsLoading(true);
    getUsers(e);
  };

  const loadMoreHandler = () => {
    setLoadMore(true);
    getUsers(search, offset, true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteSelectd({});
    setUploadLoading(false);
  };

  const getTableData = () => {
    const table = [];
    const data = [...dataList];

    data.forEach((d) => {
      let temp = [];
      temp.push(
        <Link
          style={{ textDecoration: "none", color: "rgb(0, 121, 214)" }}
          to={`/access/insights/user/${d.id}`}
        >
          {d.id}
        </Link>
      );
      temp.push(d.surname);
      temp.push(d.givenname);
      temp.push(failedIds.includes(d.id) ? "_" : d?.rolerels?.totalCount);
      temp.push(getStatus(d));
      temp.push(getDetailIcon());

      table.push(temp);
    });
    return table;
  };

  const detailsClickHandler = (user) => {
    const id = user?.userId?.props?.children;
    failedIds.includes(user.userId)
      ? notify({
          severity: "error",
          message: "Missing Permission",
        })
      : navigate(`/access/insights/user/${id}`);
  };

  const getUserRole = useCallback(
    (userData, isLoadMore, searchKey = "", start) => {
      const userList = userData?.users;
      let failedInfo = [];
      const roleRequests = userList.map((d) =>
        Communication.get(urlConfig.rolesCC(d.id))
          .then((res) => res)
          .catch((e) => {
            if (e.response.status === 403) {
              failedInfo = [...failedInfo, d.id];
              setFailedIds(failedInfo);
            }
          })
      );

      Communication.all(roleRequests)
        .then((resp) => {
          let users = userList.map((u, i) => ({
            ...u,
            roles: resp[i]?.data?.roles ?? [],
            rolerels: resp[i]?.data?.rels,
          }));

          if (isLoadMore) {
            users = dataList.concat(users);
            setLoadMore(false);
            setOffset(start + limit);
          }
          dispatch(
            MyUserDetails({
              data: users,
              isLoaded: true,
              search: searchKey,
              totalCount: userData?.rels?.totalCount,
            })
          );
          setDataList(users);
          setIsLoading(false);
        })
        .catch((e) => errorHandler);
    },
    [dataList, dispatch, errorHandler, setFailedIds]
  );

  const getUsers = useCallback(
    (searchKey = "", start = 0, isLoadMore = false) => {
      Communication.get(urlConfig.users(searchKey, start, limit))
        .then((resp) => {
          if (resp.data) {
            getUserRole(resp?.data, isLoadMore, searchKey, start);
          }
        })
        .catch(errorHandler);
    },
    [errorHandler, getUserRole]
  );

  useEffect(() => {
    if (myUsers.isLoaded) {
      setDataList(myUsers?.data);
      setOffset(myUsers?.data?.length);
    } else {
      if (!isLoading) {
        setIsLoading(true);
        getUsers();
      }
    }
  }, [myUsers, isLoading, getUsers]);

  const getDetailIcon = () => (
    <Stack
      direction="row"
      spacing={1}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <span>
        <Detail />
      </span>
    </Stack>
  );

  const getStatus = (user) => (
    <Stack
      direction="row"
      spacing={1}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      {user.isActive ? (
        <TextOnlyTooltip title={t("active")}>
          <Box>
            <AliceIcon iconType={AliceIconType.ACTIVE} />
          </Box>
        </TextOnlyTooltip>
      ) : (
        <TextOnlyTooltip title={t("inactive")}>
          <Box>
            <AliceIcon iconType={AliceIconType.INACTIVE} />
          </Box>
        </TextOnlyTooltip>
      )}
    </Stack>
  );

  const getNoData = (key) =>
    key?.length > 0 ? (
      <NoResults keysearch={key} searchFlag={true} />
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Message icon={<WarningIcon />} title={t("no_data_found")} />
      </div>
    );

  const loadMoreusers = () => (
    <>
      {loadMore ? (
        <TableRowsLoader
          showTableHeader={false}
          sx={{ marginTop: "-40px", paddingBottom: "40px" }}
          columnCount={windowSize.isMobile ? 1 : 6}
        />
      ) : (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingBottom: "40px",
          }}
        >
          <LoadMoreContainer>
            <span>
              {myUsers?.data?.length !== myUsers.totalCount && (
                <SecondaryButton
                  onButtonClick={() => loadMoreHandler()}
                  label={t("user_loadMore")}
                  className="mb-20"
                />
              )}
            </span>
            <SpanCount>
              {myUsers?.data?.length} / {myUsers?.totalCount} {t("users-shown")}
            </SpanCount>
          </LoadMoreContainer>
        </Stack>
      )}
    </>
  );

  const getDialog = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <PopUpTitleStyle>{t("import_role_removals")}</PopUpTitleStyle>
        <hr class="MuiDivider-root" style={{ opacity: "0.2" }} />
      </DialogTitle>
      <DialogContent style={{ paddingTop: "0px" }}>
        <div style={{ paddingBottom: "20px" }}>
          {!uploadLoading && (
            <PopUpContentStyle>{t("CSV_removal_statement")}</PopUpContentStyle>
          )}
          <BulkRemoval
            submitHandler={() => {
              setUploadLoading(true);
            }}
          />
        </div>
      </DialogContent>
      <CloseButton
        onClick={() => handleClose(deleteSelectd.id)}
        sx={{ position: "absolute", top: "10px", right: "10px" }}
      />
    </Dialog>
  );

  return (
    <>
      <BulkRemoveButtonContainer>
        <div className="bulk-upload-header">{t("reporting-users")}</div>
        <RemoveButtonContainer>
          {!isMobile && isSuperAdmin(adminRoles, "bulkImport") ? (
            <PrimaryButton
              label={t("remove_assignments_via_CSV_bulk_import")}
              onButtonClick={handleClickOpen}
            />
          ) : null}
        </RemoveButtonContainer>
      </BulkRemoveButtonContainer>
      <SearchBarContainer
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="Search_bar"
      >
        <FormControl variant="filled">
          <SearchBar
            label={t("searchbar_label_user")}
            variant="filled"
            onSearch={(e) => searchHandler(e)}
            onClear={() => searchHandler("")}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </FormControl>
      </SearchBarContainer>
      <Grid container className="mh-60vh">
        {isLoading ? (
          <TableRowsLoader
            sx={{ padding: "40px 0", margin: { xs: "0 20px", lg: "0px" } }}
            columnCount={windowSize.isMobile ? 1 : 6}
          />
        ) : hasData(dataList) ? (
          <TabbleContainer key={getUUID()}>
            <Table
              columns={headerData(t, windowSize.isMobile)}
              data={getTableData()}
              infoText={false}
              onSelect={(row) => detailsClickHandler(row[0])}
              itemsPerPage={dataList?.length}
            />
            {loadMoreusers()}
          </TabbleContainer>
        ) : (
          getNoData(searchKey)
        )}
      </Grid>
      {!isMobile && getDialog()}
    </>
  );
};

export default withTranslation()(memo(UserTable));
