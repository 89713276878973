import React, { useState } from "react";
import { Accordion, AccordionSummary } from "@alice/component_library";
import { Chip, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const AccordianContainer = ({ isMobile, children, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <Accordion
      aria-controls="orgPicker-Content"
      id="selectedRoles-Header"
      expanded={isExpanded}
      sx={[
        {
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "divider",
          cursor: "unset",
          boxShadow: "unset",
          transition: "background-color 250ms",
          backgroundColor: "common.white",
        },
      ]}
      header={
        <AccordionSummary onClick={handleToggle}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" gap={1} justifyContent="center">
              <Typography variant="h5">Selected Roles</Typography>
            </Stack>

            <Stack
              sx={{
                flexFlow: "row",
                gap: 1,
                color: isExpanded ? "primary.main" : "text.primary",
              }}
            >
              <ArrowDropDownIcon
                sx={[
                  { transition: "transform 250ms" },
                  isExpanded && { transform: "rotate(180deg)" },
                ]}
              />
            </Stack>
          </Stack>
        </AccordionSummary>
      }
    >
      {children}
    </Accordion>
  );
};

export default AccordianContainer;
