import { overViewActionTypes } from "../constants/actionTypes";
import {
  getWorkFlowUrl,
  convertWorkFlowForRequest,
  getRequestsUrl,
  getTaskAndWFFilter,
  generateStateString,
} from "../pages/util";
import Communication from "../services/Communication";
import { isWorkFlowEnabled } from "../config";
import { endPoint } from "../constants/endPointTypes";
import { insertSorted } from "@utils/arrayUtils";
import { splitWord } from "@utils";
import urlConfig from "@services/urlConfig";
import { hasData } from "@utils/Validator";
const actions = {
  fetchAllRequests: (payload) => ({
    type: overViewActionTypes.FETCH_REQUEST_OVERVIEWS,
    data: payload,
  }),

  updateNotification: (payload) => ({
    type: overViewActionTypes.UPDATE_REQUEST_NOTIFICATION,
    data: payload,
  }),

  storeRequestPageCount: (payload) => ({
    type: overViewActionTypes.SET_REQUEST_COUNT,
    data: payload,
  }),

  storeOverViewTaskInfo: (payload) => ({
    type: overViewActionTypes.SET_OVERVIEW_TASK_INFO,
    data: payload,
  }),
  storeRecentRequest: (payload) => ({
    type: overViewActionTypes.FETCH_RECENT_REQUEST,
    data: payload,
  }),
  storeDeeplinkInfo: (payload) => ({
    type: overViewActionTypes.STORE_REQUEST_DEEPLINK,
    data: payload,
  }),
};

/**Fetching all Open Task information */
export const FetchAllRequests = (
  user,
  state,
  search,
  limit = 500,
  isFromDashboard = false,
  offset = 0,
  requestList = [],
  taskCount = 0,
  workflowCount = 0,
  isRereload = false
) => {
  return function (dispatch) {
    dispatch(actions.fetchAllRequests({ data: [], loading: true }));
    let taskUrl = getRequestsUrl(
      user,
      search,
      state,
      "-",
      limit,
      isFromDashboard,
      offset
    );
    if (limit === 0) {
      taskUrl = getRequestsUrl(
        user,
        search,
        state,
        "-",
        1,
        isFromDashboard,
        offset
      );
    }

    const workFlowUrl = getWorkFlowUrl(
      state,
      search,
      user,
      false,
      false,
      offset,
      limit,
      true,
      isFromDashboard,
      "-"
    );

    let hasActiveRequest = Communication.activeRequests.some(
      (request) => request.endpoint === endPoint.TASK
    );

    if (hasActiveRequest) {
      Communication.cancel(endPoint.TASK);
    }

    hasActiveRequest = Communication.activeRequests.some(
      (request) => request.endpoint === endPoint.WORKFLOW
    );

    if (hasActiveRequest) {
      Communication.cancel(endPoint.WORKFLOW);
    }
    const requests = [];
    if (requestList.length > 0) {
      if (taskCount > offset || isRereload)
        requests.push(Communication.get(taskUrl, endPoint.TASK));
    } else {
      requests.push(Communication.get(taskUrl, endPoint.TASK));
    }

    if (isWorkFlowEnabled) {
      if (requestList.length > 0) {
        if (workflowCount > offset || isRereload) {
          requests.push(Communication.get(workFlowUrl, endPoint.WORKFLOW));
        }
      } else requests.push(Communication.get(workFlowUrl, endPoint.WORKFLOW));
    }
    Communication.all(requests)
      .then((res) => {
        const data = res.map((d) => d.data);
        let tasksResp = {};
        let tasks = [];
        if (requestList.length > 0) {
          if (taskCount > offset) {
            tasksResp = data[0];
            tasks = tasksResp.tasks;
            taskCount = tasksResp?.rels?.totalCount ?? 0;
          }
        } else {
          tasksResp = data[0];
          tasks = tasksResp.tasks;
          taskCount = tasksResp?.rels?.totalCount ?? 0;
        }
        let result = tasks;
        if (requestList.length > 0 && workflowCount <= offset) {
        } else {
          if (isWorkFlowEnabled) {
            let workflowsResp = {};
            if (requestList.length > 0 && taskCount <= offset)
              workflowsResp = data[0];
            else workflowsResp = data[1];
            const { workflows = [], rels } = workflowsResp;
            workflowCount = rels.totalCount;
            let tottalWorkFlows = workflows;
            const WFTaskProcessed = convertWorkFlowForRequest(
              tottalWorkFlows,
              false,
              false,
              state
            );
            if (state === "COMPLETED")
              workflowCount -= tottalWorkFlows.length - WFTaskProcessed.length;
            result = tasks.concat(WFTaskProcessed);
            result = result.sort(
              (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
            );
          }
        }
        let finalResult = [...requestList, ...result];

        dispatch(
          actions.fetchAllRequests({
            data: finalResult,
            taskCount: taskCount,
            workflowCount: workflowCount,
            loading: false,
            isLoaded: isFromDashboard ? false : true,
            searchKey: search,
            requestStatus: isFromDashboard ? "Show all" : state,
          })
        );
        if (state === "PENDING") {
          dispatch(
            actions.updateNotification({
              count: workflowCount + taskCount,
              isLoaded: true,
            })
          );
        }
      })
      .catch((err) => {
        Communication.removeActiveRequest(endPoint.TASK);
        Communication.removeActiveRequest(endPoint.WORKFLOW);
        if (err.code !== "ERR_CANCELED") {
          dispatch(
            actions.fetchAllRequests({
              data: [],
              loading: false,
              error: true,
            })
          );
          dispatch(
            actions.updateNotification({
              count: 0,
              isLoaded: true,
            })
          );
        }
      });
  };
};

export const RemovedStoredRequestsData = () => (dispatch) => {
  dispatch(actions.removeStoredRequestData([]));
};

export const getCountsForRequestPage = (
  user,
  filter = "",
  userType = "",
  search = ""
) => {
  return function (dispatch) {
    const taskURL = "/tasks";
    const workFlowURL = "/workflows";
    const { taskFilter, wfFilter, wfFilterTask } =
      filter !== "All" || filter !== "" ? getTaskAndWFFilter(filter) : {};

    const keysForCount = [
      "roleRequestCount",
      "workFlowCount",
      "entitileMentTaskCount",
      "entitileMentWorkflowCount",
      "techUserCount",
      "deprovisioningCount",
    ];

    const taskStatus = taskFilter && generateStateString(taskFilter, "state");
    const WFtaskState =
      wfFilterTask && generateStateString(wfFilterTask, "tasks.taskData.state");
    const WFStateTask = wfFilter && generateStateString(wfFilter, "state");

    const urlSearchParamsForTaskURL = new URLSearchParams();
    const filtersForTaskURL = [];

    const urlSearchParamsForWorkFlowURL = new URLSearchParams();
    const urlSearchParamsForEntitleMentWorkFlowURL = new URLSearchParams();
    const filtersForWorkFlowURL = ["workflowData.type==ROLE_APPROVAL_WORKFLOW"];

    const query = search.length ? `'*${search.trim()}*'` : "";
    filtersForTaskURL.push(
      `taskType=in=(APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT,APPROVE_ROLE_ASSIGNMENT)`
    );

    if (userType === "Beneficiary") {
      filtersForTaskURL.push(`(affectedData.user.id==${user})`);
    } else if (userType === "Requester") {
      filtersForTaskURL.push(`(originator.id==${user})`);
    } else if (userType === "") {
      filtersForTaskURL.push(
        `(affectedData.user.id==${user},originator.id==${user})`
      );
    } else {
      filtersForTaskURL.push(
        `(affectedData.user.id==${user};originator.id==${user})`
      );
    }

    if (filter.length > 0 && (filter !== "All" || filter !== ""))
      filtersForTaskURL.push(taskStatus);

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else {
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${query},affectedData.user.surname==${query},originator.id==${query},originator.givenname==${query},originator.surname==${query})`
        );
      }
    }

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(" "));
      }
    }

    urlSearchParamsForTaskURL.set("offset", 0);
    urlSearchParamsForTaskURL.set("limit", 1);
    const roleRequsetTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_APPLICATIONROLE_TO_ROLE)`
    );

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const entitlementRequsetTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_TECHUSER_TO_APPLICATION)`
    );
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const assignTechUserTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(0, 1, `taskType=in=(APPROVE_ROLE_DEPROVISIONING)`);
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const deprovisioningTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_USER_TO_ORGANIZATION)`
    );
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }

    filtersForTaskURL.splice(0, 1);
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }

    if (userType === "Beneficiary") {
      filtersForWorkFlowURL.push(`(workflowData.roleToUser.user.id==${user})`);
    } else if (userType === "Requester") {
      filtersForWorkFlowURL.push(`(workflowData.requester.id==${user})`);
    } else if (userType === "") {
      filtersForWorkFlowURL.push(
        `(workflowData.requester.id==${user},workflowData.roleToUser.user.id==${user})`
      );
    } else {
      filtersForWorkFlowURL.push(
        `(workflowData.requester.id==${user};workflowData.roleToUser.user.id==${user})`
      );
    }

    if (filter.length > 0 && filter !== "All") {
      const workFlowFilter =
        filter === "PENDING"
          ? `((${WFtaskState};${WFStateTask}),${WFStateTask})`
          : `((` + WFtaskState + `) ; (` + WFStateTask + `))`;
      filtersForWorkFlowURL.push(workFlowFilter);
    }

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;

        filtersForWorkFlowURL.push(
          `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query})`
        );
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else {
        filtersForWorkFlowURL.push(
          `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${query},workflowData.roleToUser.user.surname==${query},workflowData.requester.id==${query},workflowData.requester.givenname==${query},workflowData.requester.surname==${query},workflowData.requester.surname==${query})`
        );
      }
    }

    const filterForEntitleWorkFlowUrl = filtersForWorkFlowURL.map((item) =>
      item.replace(
        "workflowData.type==ROLE_APPROVAL_WORKFLOW",
        "workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL"
      )
    );

    if (filtersForWorkFlowURL.length) {
      if (filtersForWorkFlowURL.length > 1) {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join(";")
        );
        urlSearchParamsForEntitleMentWorkFlowURL.set(
          "filter",
          filterForEntitleWorkFlowUrl.join(";")
        );
      } else {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join("")
        );
        urlSearchParamsForEntitleMentWorkFlowURL.set(
          "filter",
          filterForEntitleWorkFlowUrl.join("")
        );
      }
    }

    urlSearchParamsForWorkFlowURL.set("offset", 0);
    urlSearchParamsForWorkFlowURL.set("limit", 0);
    urlSearchParamsForEntitleMentWorkFlowURL.set("offset", 0);
    urlSearchParamsForEntitleMentWorkFlowURL.set("limit", 0);

    const allWorkFlowUrl = `/gems${workFlowURL}?${urlSearchParamsForWorkFlowURL.toString()}`;
    const entitleMentWorkFlowUrl = `/gems${workFlowURL}?${urlSearchParamsForEntitleMentWorkFlowURL.toString()}`;

    const requests = [Communication.get(roleRequsetTaskURL)];
    requests.push(Communication.get(allWorkFlowUrl));
    requests.push(Communication.get(entitleMentWorkFlowUrl));
    requests.push(Communication.get(entitlementRequsetTaskURL));
    requests.push(Communication.get(assignTechUserTaskURL));
    requests.push(Communication.get(deprovisioningTaskURL));
    Communication.all(requests).then((r) => {
      const countObject = {};
      dispatch(
        actions.storeRequestPageCount({
          data: {
            tottalCount: 0,
            roleRequestCount: 0,
            entitileMentCount: 0,
            techUserCount: 0,
            deprovisioningCount: 0,
          },
        })
      );
      r.forEach((d, i) => {
        countObject[keysForCount[i]] = d?.data?.rels?.totalCount;
      });
      const {
        tottalCount,
        entitileMentWorkflowCount,
        entitileMentTaskCount,
        ...rest
      } = countObject;
      const { roleRequestCount, techUserCount, deprovisioningCount } = rest;

      const roleRequestTottalCount =
        roleRequestCount +
        entitileMentWorkflowCount +
        entitileMentTaskCount +
        techUserCount +
        deprovisioningCount;

      const updatedData = {
        ...rest,
        entitileMentCount: entitileMentWorkflowCount + entitileMentTaskCount,
        roleRequestCount:
          rest.roleRequestCount >= 500
            ? 500 + rest.workFlowCount
            : rest.roleRequestCount + rest.workFlowCount,
        tottalCount:
          roleRequestTottalCount >= 500
            ? 500 + rest.workFlowCount
            : roleRequestTottalCount + rest.workFlowCount,
      };
      dispatch(actions.storeRequestPageCount({ data: updatedData }));
    });
  };
};

export const getFetchRequest = (
  type,
  user,
  filter = "",
  userType = "",
  search = "",
  offset,
  limit,
  taskList,
  workFLowList,
  tottalCount = 750,
  topPage,
  sortValue
) => {
  return function (dispatch) {
    const taskURL = "/tasks";
    const workFlowURL = "/workflows";
    const { taskFilter, wfFilter, wfFilterTask } =
      filter !== "All" || filter !== "" ? getTaskAndWFFilter(filter) : {};
    const taskStatus = taskFilter && generateStateString(taskFilter, "state");
    const WFtaskState =
      wfFilterTask && generateStateString(wfFilterTask, "tasks.taskData.state");
    const WFStateTask = wfFilter && generateStateString(wfFilter, "state");

    const urlSearchParamsForTaskURL = new URLSearchParams();
    const filtersForTaskURL = [];

    const urlSearchParamsForWorkFlowURL = new URLSearchParams();
    const filtersForWorkFlowURL = [];

    const query = search.length ? `'*${search.trim()}*'` : "";
    let structureInfo = "allTasks";

    switch (type) {
      case "APPROVE_ROLE_ASSIGNMENT":
        filtersForTaskURL.push(
          `taskType=in=(APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT,APPROVE_ROLE_ASSIGNMENT)`
        );
        filtersForWorkFlowURL.push(
          "(workflowData.type==ROLE_APPROVAL_WORKFLOW)"
        );
        structureInfo = "roleRequest";
        break;
      case "APPROVE_ROLE_DEPROVISIONING":
        filtersForTaskURL.push(`taskType=in=(APPROVE_ROLE_DEPROVISIONING)`);
        structureInfo = "deProvisionInfo";
        break;
      case "APPROVE_TECHUSER_TO_APPLICATION":
        filtersForTaskURL.push(`taskType=in=(APPROVE_TECHUSER_TO_APPLICATION)`);
        structureInfo = "techUserToapplication";
        break;
      case "APPROVE_APPLICATIONROLE_TO_ROLE":
        filtersForTaskURL.push(`taskType=in=(APPROVE_APPLICATIONROLE_TO_ROLE)`);
        filtersForWorkFlowURL.push(
          "(workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL)"
        );
        structureInfo = "entitelementToRole";
        break;
      default:
        filtersForWorkFlowURL.push(
          "(workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL,workflowData.type==ROLE_APPROVAL_WORKFLOW)"
        );
        break;
    }

    if (filter.length > 0 && (filter !== "All" || filter !== ""))
      filtersForTaskURL.push(taskStatus);

    if (userType === "Beneficiary") {
      filtersForTaskURL.push(`(affectedData.user.id==${user})`);
    } else if (userType === "Requester") {
      filtersForTaskURL.push(`(originator.id==${user})`);
    } else if (userType === "") {
      filtersForTaskURL.push(
        `(affectedData.user.id==${user},originator.id==${user})`
      );
    } else {
      filtersForTaskURL.push(
        `(affectedData.user.id==${user};originator.id==${user})`
      );
    }

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else {
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${query},affectedData.user.surname==${query},originator.id==${query},originator.givenname==${query},originator.surname==${query})`
        );
      }
    }

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(" "));
      }
    }
    const sortFilterValueTsk =
      parseInt(sortValue) === 1 ? "-creationDate" : "+creationDate";
    urlSearchParamsForTaskURL.set("sort", sortFilterValueTsk);
    urlSearchParamsForTaskURL.set("offset", offset.taskOffset);
    urlSearchParamsForTaskURL.set(
      "limit",
      type === "APPROVE_ROLE_ASSIGNMENT" || type === "allTasks"
        ? limit / 2
        : limit
    );

    const roleRequsetTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    if (userType === "Beneficiary") {
      filtersForWorkFlowURL.push(`(workflowData.roleToUser.user.id==${user})`);
    } else if (userType === "Requester") {
      filtersForWorkFlowURL.push(`(workflowData.requester.id==${user})`);
    } else if (userType === "") {
      filtersForWorkFlowURL.push(
        `(workflowData.requester.id==${user},workflowData.roleToUser.user.id==${user})`
      );
    } else {
      filtersForWorkFlowURL.push(
        `(workflowData.requester.id==${user};workflowData.roleToUser.user.id==${user})`
      );
    }

    if (filter.length > 0 && filter !== "All") {
      const workFlowFilter =
        filter === "PENDING"
          ? `((${WFtaskState};${WFStateTask}),${WFStateTask})`
          : `((` + WFtaskState + `) ; (` + WFStateTask + `))`;
      filtersForWorkFlowURL.push(workFlowFilter);
    }

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;

        filtersForWorkFlowURL.push(
          `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query})`
        );
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else {
        filtersForWorkFlowURL.push(
          `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${query},workflowData.roleToUser.user.surname==${query},workflowData.requester.id==${query},workflowData.requester.givenname==${query},workflowData.requester.surname==${query},workflowData.requester.surname==${query})`
        );
      }
    }

    if (filtersForWorkFlowURL.length) {
      if (filtersForWorkFlowURL.length > 1) {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join(";")
        );
      } else {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join("")
        );
      }
    }

    const sortFilterValueWf =
      parseInt(sortValue) === 1 ? "-startTime" : "+startTime";
    urlSearchParamsForWorkFlowURL.set("sort", sortFilterValueWf);
    urlSearchParamsForWorkFlowURL.set("offset", offset.workFlow);
    urlSearchParamsForWorkFlowURL.set("limit", limit / 2);

    const allWorkFlowUrl = `/gems${workFlowURL}?${urlSearchParamsForWorkFlowURL.toString()}`;
    const requests =
      tottalCount.taskListTottalCount > offset.taskOffset
        ? [Communication.get(roleRequsetTaskURL)]
        : [];
    if (
      (type === "APPROVE_ROLE_ASSIGNMENT" ||
        type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
        type === "ENTITLEMENT_TO_ROLE_APPROVAL" ||
        type === "allTasks") &&
      tottalCount.workFlowTottalCount > offset.workFlow
    )
      requests.push(Communication.get(allWorkFlowUrl));

    if (requests.length) {
      dispatch(actions.storeOverViewTaskInfo({ loading: true }));
      Communication.all(requests).then((r) => {
        const data = r.map((d) => d.data);
        let result = [];
        let taskCount = 0;
        if (tottalCount.taskListTottalCount > offset.taskOffset) {
          const [tasksResp = {}] = data;
          let { tasks = [], rels = { totalCount: 0 } } = tasksResp;
          taskCount = rels?.totalCount;
          result = tasks;
        }
        if (
          (type === "APPROVE_ROLE_ASSIGNMENT" ||
            type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
            type === "ENTITLEMENT_TO_ROLE_APPROVAL" ||
            type === "allTasks") &&
          tottalCount.workFlowTottalCount > offset.workFlow
        ) {
          let workflowsResp = {};
          if (tottalCount.taskListTottalCount > offset.taskOffset) {
            [, workflowsResp = {}] = data;
          } else {
            [workflowsResp = {}] = data;
          }
          const { workflows = [], rels } = workflowsResp;
          dispatch(
            actions.storeOverViewTaskInfo({
              data: [...workflows, ...workFLowList],
              isLoaded: true,
              paginationInfo: {
                offset: offset.workFlow,
                limit: limit / 2,
              },
              fetchInfo: {
                totalCount: rels.totalCount,
              },
              structureInfo: "workflowInfo",
              loading: false,
            })
          );
          const WFTaskProcessed = convertWorkFlowForRequest(workflows);
          result = result.concat(WFTaskProcessed);
        }
        result.forEach((newTask) =>
          insertSorted(taskList, newTask, parseInt(sortValue) !== 1)
        );
        dispatch(
          actions.storeOverViewTaskInfo({
            data: taskList,
            paginationInfo: {
              offset: offset.taskOffset,
              limit:
                type === "APPROVE_ROLE_ASSIGNMENT" || type === "allTasks"
                  ? limit / 2
                  : limit,
              currentPage: topPage,
            },
            fetchInfo: {
              totalCount: taskCount,
            },
            isLoaded: true,
            structureInfo: structureInfo,
            errorInfo: { error: false, errorMessage: "" },
            loading: false,
          })
        );
      });
    }
  };
};
export const GetRecentRequestActiviy = (userId) => (dispatch) => {
  const urlSearchParamsForTask = new URLSearchParams();
  const urlSearchParamsForWf = new URLSearchParams();
  urlSearchParamsForTask.set("offset", 0);
  urlSearchParamsForTask.set("limit", 15);
  urlSearchParamsForTask.set("sort", "-lastModifyDate");
  urlSearchParamsForTask.set(
    "filter",
    `(affectedData.user.id==${userId},originator.id==${userId});((state==REJECTED),(state==FAILED),(state==EXPIRED),(state==COMPLETED))`
  );
  urlSearchParamsForWf.set("offset", 0);
  urlSearchParamsForWf.set("limit", 15);
  urlSearchParamsForWf.set(
    "filter",
    `(workflowData.type==ROLE_APPROVAL_WORKFLOW);(workflowData.requester.id==${userId},workflowData.roleToUser.user.id==${userId});(state==COMPLETED)`
  );
  urlSearchParamsForWf.set("sort", "-tasks.endTime");
  const taskUrl = `/gems/tasks?${urlSearchParamsForTask.toString()}`;
  const wfUrl = `/gems/workflows?${urlSearchParamsForWf.toString()}`;
  const requests = [Communication.get(taskUrl), Communication.get(wfUrl)];
  dispatch(
    actions.storeRecentRequest({
      loading: true,
      recentRequestActivity: [],
      isLoaded: false,
    })
  );
  return Communication.all(requests)
    .then((res) => {
      const [taskRes, workflowRes] = res;
      const workflows = workflowRes?.data?.workflows;
      const tasks = taskRes?.data?.tasks;

      const WFTaskProcessed = convertWorkFlowForRequest(workflows, true);
      const result = [...tasks, ...WFTaskProcessed]
        .sort(
          (a, b) => new Date(b?.lastModifyDate) - new Date(a?.lastModifyDate)
        )
        .slice(0, 10);

      dispatch(
        actions.storeRecentRequest({
          loading: false,
          recentRequestActivity: result,
          isLoaded: true,
        })
      );
      return result;
    })
    .catch((er) => {
      dispatch(
        actions.storeRecentRequest({
          loading: false,
          recentRequestActivity: [],
          isLoaded: true,
        })
      );
      return er && er.response && er.response.status;
    });
};

export const loadWorkFlow = (id, userId) => async (dispatch) => {
  const url = `${urlConfig.workflowsById(id)}`;
  dispatch(
    actions.storeDeeplinkInfo({
      loading: true,
      isLoaded: false,
    })
  );
  Communication.get(url)
    .then((resp) => {
      if (resp.data && hasData(resp?.data?.workflows)) {
        const { workflows = [] } = resp?.data;
        const WFTaskProcessed = convertWorkFlowForRequest(
          workflows,
          true,
          userId
        );
        const selectedWorkflow = WFTaskProcessed[0];
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: selectedWorkflow,
            workFlowData: workflows[0],
          })
        );
      } else {
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: {},
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        actions.storeDeeplinkInfo({
          loading: false,
          isLoaded: false,
        })
      );
    });
};
export const loadTask = (id, userId) => async (dispatch) => {
  const url = urlConfig.taskById(id); // urlConfig.taskById(id);
  dispatch(
    actions.storeDeeplinkInfo({
      loading: true,
      isLoaded: false,
    })
  );
  Communication.get(url)
    .then((resp) => {
      if (resp.data && hasData(resp.data.tasks)) {
        const [task = {}] = resp?.data?.tasks;

        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: task,
          })
        );
      } else {
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: {},
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        actions.storeDeeplinkInfo({
          loading: false,
          isLoaded: false,
        })
      );
    });
};
