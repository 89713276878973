import { Stack } from '@mui/material'
import { SubTitle } from '../../../card/genericCard/componets/idTitle/SubTitle'
import { UserGroupAvatar } from './UserGroupAvatar/UserGroupAvatar'

export const UserGroupAvatarInfo = ({
  name,
  variant,
  size,
  isLoading,
  sx
}) => (
  <Stack
    sx={[
      {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
        overflow: 'hidden'
      },
      variant === 'list' && { maxWidth: '300px' },
      sx
    ]}
  >
    <UserGroupAvatar size={size} name={name} />

    <SubTitle isIdChipHidden title={name} isLoading={isLoading} size={size} sx={{ gap: 0 }} />
  </Stack>
)
