import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { SummaryPageContainer } from "../../styledComponents";
import ConfiguredRoles from "./ConfiguredRoles";
import Header from "./Header";
import SummaryErrorBoundary from "./SummaryErrorBundary";

const SummaryPage = ({
  requestReason = "",
  configuredRoles = [],
  selectedUser = {},
}) => {
  const [view, setView] = useState("list");
  return (
    <ErrorBoundary fallback={SummaryErrorBoundary}>
      <SummaryPageContainer id="new-request-summary">
        <Header
          configuredRoles={configuredRoles}
          requestReason={requestReason}
          view={view}
          setView={setView}
          selectedUser={selectedUser}
        />
        <ConfiguredRoles view={view} configuredRoles={configuredRoles} />
      </SummaryPageContainer>
    </ErrorBoundary>
  );
};

export default SummaryPage;
