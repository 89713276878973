import React, { useRef, useEffect } from "react";
import { Inlay } from "@alice/component_library/";
import RoleDetails from "./RoleDetails";

const RoleInlay = ({
  open,
  selectedData,
  onClose,
  anchorElement,
  needScrollIntoView = false,
}) => {
  const inlayRef = useRef(null);
  useEffect(() => {
    if (open && needScrollIntoView) {
      inlayRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [open, needScrollIntoView]);
  return (
    <Inlay open={open} anchorElement={anchorElement}>
      <RoleDetails
        ref={inlayRef}
        showClose
        onClose={onClose}
        role={selectedData}
      />
    </Inlay>
  );
};

export default RoleInlay;
