import { useCallback } from 'react'
import { CacheService, CacheStorage, CacheTTL } from '../../data'

export const useCache = (storage = CacheStorage.memory, ttl = CacheTTL.oneHour) => {
  const getCachedItem = useCallback((key) => CacheService.get(key, { storage }), [ storage ])
  const hasCachedItem = useCallback((key) => CacheService.has(key, { storage }), [ storage ])
  const removeCachedItem = useCallback((key) => CacheService.remove(key, { storage }), [ storage ])
  const clearCache = useCallback(() => CacheService.clear({ storage }), [ storage ])
  const clearAllCaches = useCallback(() => CacheService.clearAll(), [])

  const setCachedItem = useCallback(
    (
      key,
      value,
      overrideExisting = true,
      ttlOverride = ttl
    ) => CacheService.set(
      key,
      value,
      {
        storage,
        overrideExisting,
        ttl: ttlOverride
      }
    ),
    [ storage, ttl ]
  )

  return {
    getCachedItem,
    setCachedItem,
    hasCachedItem,
    removeCachedItem,
    clearCache,
    clearAllCaches
  }
}
