import { Stack } from '@mui/material'
import { AdminPanelSettingsOutlined, VpnLockOutlined } from '@mui/icons-material'
import { mergeSxProps } from '../../../../util'
import { CardInfoChip } from '../../../chip'
import { CountryFlag } from '../../../icon'
import { FocusChip } from '../../../projectFocus'
import { Skeleton } from '../../../skeleton'

export const OrgCardContextualChips = ({
  central,
  decentral,
  country,
  orgGroupType,
  isTiny,
  isLoading,
  isInfoChipsShown,
  isCentralChipsShown,
  isFlagShown,
  isFocusShown,
  sx = {} || []
}) => (
  <Stack
    sx={mergeSxProps({
      gridArea: 'contextualChips',
      flexFlow: 'row',
      gap: 2
    }, sx)}
  >
    {isLoading
      ? (
        <Skeleton
          variant="rounded"
          sx={{
            width: 100,
            height: 24,
            borderRadius: 50
          }}
        />
      )
      : (
        <>
          {isInfoChipsShown && (
            <>
              {isFlagShown && (
                <CountryFlag
                  countryCode={country}
                  sx={{
                    height: 24,
                    width: 'unset',
                    borderRadius: '16px',
                    'svg, img': { height: '100%' }
                  }}
                />
              )}

              {isFocusShown && <FocusChip groupType={orgGroupType} sx={{ width: 'fit-content' }} />}
            </>
          )}

          {isCentralChipsShown && (!isTiny
            ? (
              <>
                {central && (
                  <CardInfoChip
                    icon={<AdminPanelSettingsOutlined />}
                    contentText="Central"
                  />
                )}

                {decentral && (
                  <CardInfoChip
                    icon={<VpnLockOutlined />}
                    contentText="Decentral"
                  />
                )}
              </>
            )
            : (
              <CardInfoChip
                icon={(
                  <Stack direction="row" gap={1}>
                    {central && (<AdminPanelSettingsOutlined />)}
                    {decentral && (<VpnLockOutlined />)}
                  </Stack>
                )}
              />
            )
          )}
        </>
      )}
  </Stack>
)
