import React, { useCallback } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { AliceIcon, AliceIconType, Chip } from "@src/ComponentLibrary";
import { ConfigurationItemsConatainer } from "./styledComponents";

const ConfigurationItems = ({
  defaultValidityType = "MANDATORY",
  needsCustomScopes = false,
  needsOrgScopes = false,
  attachment = true, // attachment has to be changed once api
  termsAndCondition = true, // termsAndCondition has to be changed once api
}) => {
  return (
    <ConfigurationItemsConatainer>
      {(defaultValidityType === "MANDATORY" ||
        defaultValidityType === "FIXED") && (
        <Chip
          label={<CalendarMonthIcon fontSize="small" />}
          variant="outlined"
        />
      )}
      {attachment && (
        <Chip label={<AttachFileIcon fontSize="small" />} variant="outlined" />
      )}
      {termsAndCondition && (
        <Chip
          label={<CheckBoxOutlinedIcon fontSize="small" />}
          variant="outlined"
        />
      )}
      {(needsCustomScopes || needsOrgScopes) && (
        <Chip
          label={
            <div className="org-cust-chip">
              {needsOrgScopes && (
                <AliceIcon iconType={AliceIconType.UIDate_From} size={16} />
              )}
              {needsCustomScopes && (
                <AliceIcon iconType={AliceIconType.CUSTOM_SCOPE} size={16} />
              )}
            </div>
          }
          variant="outlined"
        />
      )}
    </ConfigurationItemsConatainer>
  );
};

export default ConfigurationItems;
