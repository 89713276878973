/* eslint-disable max-len */
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Button } from '../button'

const ItemScrollerDirection = {
  LEFT: 'left',
  RIGHT: 'right'
}

const findEdgeScrollPosition = (container, items, direction) => {
  const containerRect = container.getBoundingClientRect()

  let edgeScrollPosition = 0

  for (const item of items) {
    // eslint-disable-next-line no-continue
    if (!item) continue

    const itemRect = item.getBoundingClientRect()
    const leftToContainer = itemRect.left - containerRect.left
    const rightToContainer = itemRect.right - containerRect.right

    if (direction === ItemScrollerDirection.RIGHT) {
      const isNotFullVisible = rightToContainer > 0

      if (isNotFullVisible) {
        edgeScrollPosition = container.scrollLeft + rightToContainer
        break
      }
    } else if (direction === ItemScrollerDirection.LEFT) {
      const isNotFullVisible = leftToContainer < 0 && leftToContainer >= -itemRect.width

      if (isNotFullVisible) {
        edgeScrollPosition = container.scrollLeft + leftToContainer
        break
      }
    }
  }

  return edgeScrollPosition
}

export const ItemScroller = ({ itemWrapperId, children }) => {
  const scrollRef = useRef(null)

  const [ canScrollLeft, setCanScrollLeft ] = useState(false)
  const [ canScrollRight, setCanScrollRight ] = useState(false)
  const [ shouldShowButtons, setShouldShowButtons ] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const {
          scrollLeft,
          scrollWidth,
          clientWidth
        } = scrollRef.current

        setCanScrollLeft(scrollLeft > 0)
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth)
      }
    }

    if (scrollRef.current) {
      handleScroll()
      scrollRef.current.addEventListener('scroll', handleScroll)
    }

    // Check if scrolling is necessary when children change
    const checkScrollNecessity = () => {
      if (scrollRef.current) {
        const { scrollWidth, clientWidth } = scrollRef.current

        setShouldShowButtons(scrollWidth > clientWidth)
      }
    }

    checkScrollNecessity()

    window.addEventListener('resize', checkScrollNecessity)

    return () => {
      if (scrollRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollRef?.current?.removeEventListener('scroll', handleScroll)
      }

      window.removeEventListener('resize', checkScrollNecessity)
    }
  }, [ children ])

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: findEdgeScrollPosition(scrollRef.current, document.getElementById(itemWrapperId).children, direction),
        behavior: 'smooth'
      })
    }
  }

  return (
    <Box
      sx={{
        paddingBlock: 2,
        position: 'relative',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden',
        '.ScrollerBtnLeft, .ScrollerBtnRight': {
          position: 'absolute',
          top: 0,
          bottom: 16,
          padding: 0,
          minWidth: 50,
          zIndex: 1,
          color: 'action.active',
          backgroundColor: 'rgba(255, 255, 255, .83)',
          transition: 'background-color 250ms',
          '&:hover': { backgroundColor: 'rgba(255, 255, 255, .93)' }
        },
        '.ScrollerBtnLeft': {
          left: 0,
          visibility: canScrollLeft ? 'visible' : 'hidden',
          borderRadius: '0 50% 50% 0'
        },
        '.ScrollerBtnRight': {
          right: 0,
          visibility: canScrollRight ? 'visible' : 'hidden',
          borderRadius: '50% 0 0 50%'
        }
      }}
    >
      {shouldShowButtons && (
        <Button
          className="ScrollerBtnLeft"
          variant="tertiary"
          onClick={() => handleScroll(ItemScrollerDirection.LEFT)}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      )}

      <Box
        ref={scrollRef}
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          flex: 1,
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {children}
      </Box>

      {shouldShowButtons && (
        <Button
          className="ScrollerBtnRight"
          variant="tertiary"
          onClick={() => handleScroll(ItemScrollerDirection.RIGHT)}
        >
          <KeyboardArrowRightIcon />
        </Button>
      )}
    </Box>
  )
}

ItemScroller.propTypes = {
  /** html id of the wrapping items element  */
  itemWrapperId: PropTypes.string.isRequired
}
