import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Chip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useOpenTasks } from '../../../hooks'
import { Link } from '../../link'

/**
 * A link that displays a badge if the user has open tasks.<br>
 * If clicked it takes you to "/access/tasks"
 */
export const OpenTasksLink = () => {
  const { t } = useTranslation('componentLibrary')
  const { openTasksCount } = useOpenTasks()

  return (
    <Link
      data-testid="OpenTasksLink"
      href="/access/tasks?status=pending"
      sx={{
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer'
      }}
    >
      <Chip
        size="small"
        label={openTasksCount}
        color="primary"
        sx={{ '&:hover': { cursor: 'pointer' } }}
      />

      <Typography variant="body1" color="text.primary">
        {t('general.openTasksText')}
      </Typography>

      <ChevronRightIcon
        style={{
          backgroundColor: 'grey.75',
          color: 'white',
          borderRadius: '50%'
        }}
      />
    </Link>
  )
}
