import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { LinkOutlined, SupportAgentOutlined } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../../../util'
import { AppCardInfoElements } from '../AppCardInfoElements'
import { AppCardDetailsTable } from './AppCardDetailsTable'

/**
 * Details view for Applications.
 */
export const AppCardDetails = forwardRef((
  {
    id,
    name,
    description,
    url,
    email,
    tableProps = { getResponseData: (data) => data.entitlements },
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const ellipsisSx = {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }

  return (
    <Stack
      data-testid="AppCardDetails"
      sx={mergeSxProps(
        ({ palette }) => ({ padding: 4, border: `1px solid ${palette.grey[20]}` }),
        sx
      )}
      gap={3}
      {...otherProps}
    >
      <Typography as="h2" sx={{ fontSize: '18px', fontWeight: 700 }}>
        {t('appCard.applicationDetails')}
      </Typography>

      <Stack sx={ellipsisSx}>
        <Box as="span" sx={{ fontSize: '14px', fontWeight: 700 }}>{t('general.id')}</Box>

        <Typography noWrap sx={{ fontSize: '14px' }}>{id}</Typography>
      </Stack>

      <Stack sx={ellipsisSx}>
        <Box as="span" sx={{ fontSize: '14px', fontWeight: 700 }}>{t('appCard.name')}</Box>

        <Typography noWrap sx={{ fontSize: '14px' }}>{name}</Typography>
      </Stack>

      <Stack sx={{ maxWidth: '100%' }}>
        <Box as="span" sx={{ fontSize: '14px', fontWeight: 700 }}>{t('appCard.description')}</Box>

        <Typography sx={{ fontSize: '14px', wordWrap: 'break-word' }}>{description}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" gap={8}>
        <AppCardInfoElements url={url} icon={<LinkOutlined fontSize="inherit" color="inherit" />} header={t('appCard.url')} />
        <AppCardInfoElements url={email} icon={<SupportAgentOutlined fontSize="inherit" color="inherit" />} header={t('appCard.support')} />
      </Stack>

      <Typography as="h3" sx={{ fontSize: '14px', fontWeight: 700 }}>
        {t('appCard.entitlements')}
      </Typography>

      <AppCardDetailsTable
        ref={ref}
        id={id}
        preselectedPageSize={6}
        {...tableProps}
      />
    </Stack>
  )
})

AppCardDetails.propTypes = {
  /** The App ID */
  id: PropTypes.string,
  /** The App name */
  name: PropTypes.string,
  /** The App description */
  description: PropTypes.string,
  /** The App URL */
  url: PropTypes.string,
  /** The App support email */
  email: PropTypes.string,
  /**
   * Props to be spread into [NewTable](./?path=/docs/alice-ui-table-newtable--docs) component.
   *
   * Note, that `getResponseData` is defaulted to `(data) => data.entitlements`.
   * If you need to overwrite this property please make sure to add your correct function.
   * In fact, that is required.
   */
  tableProps: PropTypes.object,
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ]),
  /**
   * Props to be spread into [MUI Stack](https://mui.com/material-ui/react-stack/) component.
   */
  otherProps: PropTypes.any
}
