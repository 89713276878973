import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import BulkImpButton from "@components/BulkImpButton";
import BulkImportDialouge from "@pages/newRequest/components/BulkImpFile";

import { FileUploadOutlined } from "@mui/icons-material";

import {
  NewReqheader,
  HeaderTextContanier,
  HeaderTitle,
  HeaderDescription,
} from "@pages/newRequest/styledComponents";

const NewReqHeader = ({ showBulkImport, titleHeader }) => {
  const { t } = useTranslation();
  const [dialogState, setDialogState] = useState(false);

  return (
    <>
      <NewReqheader>
        <HeaderTextContanier>
          <HeaderTitle>{t("new_request")}</HeaderTitle>
          <HeaderDescription>{titleHeader}</HeaderDescription>
        </HeaderTextContanier>
        {showBulkImport && (
          <Box>
            <BulkImpButton
              isSecondary
              title={t("bulk-import")}
              description={t("imp-roles-csv")}
              icon={FileUploadOutlined}
              handleClick={() => setDialogState(true)}
            />
            <BulkImportDialouge
              assign
              dialogState={dialogState}
              setDialogState={setDialogState}
            />
          </Box>
        )}
      </NewReqheader>
    </>
  );
};
export default NewReqHeader;
