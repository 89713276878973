import { Tooltip, AliceIcon, AliceIconType } from "@alice/component_library";
import { ArrowRightRounded, Cancel } from "@mui/icons-material";
import RoleKey from "@Icons/taskpage/rolekey.svg";
import RoleRequest from "@Icons/taskpage/rolerequest.svg";
import EntitleMentIcon from "@Icons/taskpage/AssignEntitelemnet.svg";
import AssignTechnicalUser from "@Icons/taskpage/AssignTechnicalUser.svg";
import AssignTechnicalUserLeft from "@Icons/taskpage/AssignTechnicalUserLeft.svg";
import OrgAdminPermission from "@Icons/taskpage/OrgAdminPermission.svg";
import AprooveRoleDeprovisioning from "@Icons/taskpage/AprooveRoleDeprovisioning.svg";
import UserCreation from "@Icons/taskpage/AprooveUserCreation.svg";
import UserCreationRight from "@Icons/taskpage/AprooveUserRight.svg";
import UserOrganization from "@Icons/taskpage/AprooveUserToOrg.svg";
import UserOrganizationRight from "@Icons/taskpage/UserToOrgRight.svg";
import EntitlementToRole from "@Icons/taskpage/EntitlementToRole.svg";
import { useWidth } from "@utils/hooks";
import { Typography } from "../../../styledComponents";

const useSelectedTask = (taskInfo) => {
  const { taskType, id, affectedData = {} } = taskInfo;
  const {
    user = {},
    role = {},
    homeOrg = {},
    org = {},
    entitlement = {},
  } = affectedData;
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm"].includes(widthPanel);
  let idwithTypeIcon = null;
  let LeftData = null;
  let RightData = null;
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      idwithTypeIcon = (
        <div>
          <RoleRequest />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <RoleKey />
          <Tooltip
            type="ellipsized"
            arrow
            placement="bottom-start"
            title={role?.id}
          >
            <Typography noWrap>{role?.id}</Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <AliceIcon
            iconType={AliceIconType.USER_OUTLINE}
            size={18}
            color="#00000099"
          />
          <Tooltip
            arrow
            placement="bottom-start"
            type="ellipsized"
            title={`${user?.givenname} ${user?.surname}`}
          >
            <Typography noWrap>
              {`${user?.givenname} ${user?.surname}`}
            </Typography>
          </Tooltip>
        </div>
      );

      break;
    case "APPROVE_USER_CREATION":
      idwithTypeIcon = (
        <div>
          <UserCreation />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <AliceIcon
            iconType={AliceIconType.USER_OUTLINE}
            size={18}
            color="#00000099"
          />
          <Tooltip
            arrow
            placement="bottom-start"
            title={`${user?.givenname} ${user?.surname}`}
            type="ellipsized"
          >
            <Typography noWrap>
              {`${user?.givenname} ${user?.surname}`}
            </Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <UserCreationRight />
          <Tooltip
            arrow
            placement="bottom-start"
            title={user?.homeOrgId}
            type="ellipsized"
          >
            <Typography noWrap>{user?.homeOrgId}</Typography>
          </Tooltip>
        </div>
      );
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      idwithTypeIcon = (
        <div>
          <AssignTechnicalUser />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <AssignTechnicalUserLeft />
          <Tooltip
            arrow
            placement="bottom-start"
            title={user?.id}
            type="ellipsized"
          >
            <Typography noWrap>{user?.id}</Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <AliceIcon iconType={AliceIconType.UIApps} color="#00000099" />
          <Tooltip
            arrow
            placement="bottom-start"
            title={homeOrg?.name}
            type="ellipsized"
          >
            <Typography noWrap>{homeOrg?.name}</Typography>
          </Tooltip>
        </div>
      );
      break;
    case "APPROVE_ORG_ADMIN_PERMISSION":
      idwithTypeIcon = (
        <div>
          <OrgAdminPermission />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <OrgAdminPermission />
          <Tooltip
            arrow
            placement="bottom-start"
            title={org?.name}
            type="ellipsized"
          >
            <Typography noWrap>{org?.name}</Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <AliceIcon
            iconType={AliceIconType.USER_OUTLINE}
            size={18}
            color="#00000099"
          />
          <Tooltip
            arrow
            placement="bottom-start"
            title={`${user?.givenname} ${user?.surname}`}
            type="ellipsized"
          >
            <Typography noWrap>
              {`${user?.givenname} ${user?.surname}`}
            </Typography>
          </Tooltip>
        </div>
      );
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      idwithTypeIcon = (
        <div>
          <AprooveRoleDeprovisioning />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <RoleKey />
          <Tooltip
            arrow
            placement="bottom-start"
            title={role?.id}
            type="ellipsized"
          >
            <Typography noWrap>{role?.id}</Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <Cancel fontSize="12px" sx={{ color: "#00000061" }} />}

          <AliceIcon
            iconType={AliceIconType.USER_OUTLINE}
            size={18}
            color="#00000099"
          />
          <Tooltip
            arrow
            placement="bottom-start"
            title={`${user?.givenname} ${user?.surname}`}
            type="ellipsized"
          >
            <Typography noWrap>
              {`${user?.givenname} ${user?.surname}`}
            </Typography>
          </Tooltip>
        </div>
      );
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      idwithTypeIcon = (
        <div>
          <UserOrganization />
          {id}
        </div>
      );
      LeftData = (
        <div className="first-column">
          <AliceIcon
            iconType={AliceIconType.USER_OUTLINE}
            size={18}
            color="#00000099"
          />
          <Tooltip
            arrow
            placement="bottom-start"
            title={`${user?.givenname} ${user?.surname}`}
            type="ellipsized"
          >
            <Typography noWrap>
              {`${user?.givenname} ${user?.surname}`}
            </Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <UserOrganizationRight />
          <Tooltip
            arrow
            placement="bottom-start"
            title={homeOrg?.name}
            type="ellipsized"
          >
            <Typography noWrap>{homeOrg?.name}</Typography>
          </Tooltip>
        </div>
      );
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
      idwithTypeIcon = (
        <div>
          <EntitleMentIcon />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <EntitlementToRole />
          <Tooltip
            arrow
            placement="bottom-start"
            title={entitlement?.entitlementId}
            type="ellipsized"
          >
            <Typography noWrap>{entitlement?.entitlementId}</Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <RoleKey />
          <Tooltip
            arrow
            placement="bottom-start"
            title={role?.id}
            type="ellipsized"
          >
            <Typography noWrap>{role?.id}</Typography>
          </Tooltip>
        </div>
      );
      break;
    default:
      idwithTypeIcon = (
        <div>
          <RoleRequest />
          <Typography noWrap>{id}</Typography>
        </div>
      );
      LeftData = (
        <div className="first-column">
          <RoleKey />
          <Tooltip
            arrow
            placement="bottom-start"
            title={role?.id}
            type="ellipsized"
          >
            <Typography noWrap>{role?.id}</Typography>
          </Tooltip>
        </div>
      );
      RightData = (
        <div className="second-column">
          {!isMobile && <ArrowRightRounded sx={{ color: "#00000061" }} />}
          <AliceIcon
            iconType={AliceIconType.USER_OUTLINE}
            size={18}
            color="#00000099"
          />
          <Tooltip
            arrow
            placement="bottom-start"
            title={`${user?.givenname} ${user?.surname}`}
            type="ellipsized"
          >
            <Typography noWrap>
              {`${user?.givenname} ${user?.surname}`}
            </Typography>
          </Tooltip>
        </div>
      );
      break;
  }
  return {
    LeftData,
    RightData,
  };
};

export default useSelectedTask;
