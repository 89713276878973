import React from "react";
import { Paper, Grid, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Tooltip } from "@alice/component_library";

const MainWrapper = styled(Paper)(({ theme }) => ({
  padding: `8px 0px 8px 20px`,
  backgroundColor: "#F4F4F4",
  boxShadow: "none",
  overflow: "hidden",

  "& .right": {
    minWidth: "48px",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12);",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .rightHeight": {
    height: "48px",
  },
  "& .iconButton": {
    padding: "8px",
  },
  "& .roleName": {
    color: "rgba(0, 0, 0, 0.87)",
    fontStyle: "normal",
    fontWeight: "400",
    fontFamily: "MB Corpo S Text",
    fontSize: "22px",
    lineHeight: "29px",
    letterSpacing: "0.01em",
  },
  "& .roleNameMargin": {
    marginBottom: "6px",
  },
  "& .roleId": {
    color: "rgba(0, 0, 0, 0.38);",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
  },
  "& .addPadding": {
    paddingRight: "44px",
    textAlign: "right",
  },
  "& .isFullWidth": {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: "44px",
    },
  },
  "& .rotateIcon": {
    transform: "rotate(180deg)",
    transition: "transform all ease-in 0.2s",
  },
}));

export default function DynamicRoleCard({
  roleInfo,
  id,
  isOpen,
  onExpand,
  isFullWidth,
}) {
  const onExpandClick = ({ isExpanded, e }) => {
    e.stopPropagation();
    if (typeof onExpand === "function") {
      onExpand({
        id,
        isExpanded,
        e,
      });
    }
  };
  return (
    <MainWrapper>
      <Grid container wrap="nowrap">
        <Grid
          className={isFullWidth && "isFullWidth"}
          item
          xs
          zeroMinWidth
          sx={{ marginRight: "12px" }}
        >
          <Tooltip type="ellipsized" title={roleInfo.roleDefinition.name}>
            <Typography
              className={isFullWidth ? "roleName" : "roleName roleNameMargin"}
              noWrap
            >
              {roleInfo.roleDefinition.name}
            </Typography>
          </Tooltip>
          <Tooltip type="ellipsized" title={roleInfo.roleDefinition.id}>
            <Typography
              className={isFullWidth ? "roleId addPadding" : "roleId"}
              noWrap
            >
              {roleInfo.roleDefinition.id}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid className={isFullWidth ? "right rightHeight" : "right"} item>
          <IconButton
            className="iconButton"
            onClick={(e) => onExpandClick({ e, isExpanded: !isOpen })}
          >
            <ExpandMoreOutlinedIcon
              aria-label={isOpen ? "show less" : "show more"}
              className={isOpen && "rotateIcon"}
            />
          </IconButton>
        </Grid>
      </Grid>
    </MainWrapper>
  );
}
