export const CacheTTL = {
  oneSecond: 1000,
  oneMinute: 60000,
  fiveMinutes: 300000,
  tenMinutes: 600000,
  fifteenMinutes: 900000,
  thirtyMinutes: 1800000,
  oneHour: 3600000,
  twoHours: 7200000,
  sixHours: 21600000,
  twelveHours: 43200000,
  oneDay: 86400000,
  twoDays: 172800000,
  oneWeek: 604800000,
  twoWeeks: 1209600000,
  oneMonth: 2592000000,
  twoMonths: 5184000000,
  sixMonths: 15552000000,
  oneYear: 31536000000
}
