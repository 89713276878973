import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { OpenInNewOutlined } from '@mui/icons-material'
import { mergeSxProps } from '../../../../../util'
import { IdChip } from '../../../../chip'
import { Link } from '../../../../link'
import { Skeleton } from '../../../../skeleton'
import { SubTitle } from './SubTitle'
import { TypographyLineClamp } from '../../../../typography'

/**
 * Simple component to display ID Chip and an ID
 *
 * ##Integrated CLIB-Components <br>
 * - [IdChip](/docs/alice-ui-chip-idchip--docs)
 */
export const IdTitle = forwardRef(({
  size = 'm',
  id,
  title,
  href,
  isIdChipHidden,
  isLink,
  isLoading,
  customIdChip,
  sx = {} || [],
  ...otherProps
}, ref) => {
  const idElm = (
    <TypographyLineClamp
      className="cardId"
      variant={size === 's' ? 'subtitle2' : 'subtitle1'}
    >
      {id}
    </TypographyLineClamp>
  )

  return (
    <Stack
      ref={ref}
      className="cardIdTitle"
      data-testid={`cardIdTitle_${id}`}
      sx={mergeSxProps({
        overflow: 'hidden',
        '.cardIdTitleWrapper': {
          flexFlow: 'row',
          gap: 1,
          alignItems: 'center'
        },
        '.cardIdLink': {
          display: 'contents',
          '.cardIdCopy': {
            opacity: 0,
            transition: 'opacity 250ms',
            fontSize: 'inherit',
            color: 'inherit'
          },
          '&:hover .cardIdCopy': { opacity: 1 }
        }
      }, sx)}
      {...otherProps}
    >
      {customIdChip || (
        <Stack className="cardIdTitleWrapper">
          {!isIdChipHidden && <IdChip size={size} isLoading={isLoading} />}

          {isLoading
            ? (
              <Skeleton
                variant="rectangular"
                width="96px"
                sx={[
                  size === 's' && { height: '16px', marginBlock: '2px' },
                  size === 'm' && { height: '18px', marginBlock: '3px' }
                ]}
              />
            )
            : isLink
              ? (
                <Link
                  className="cardIdLink"
                  href={href}
                  isBlank
                >
                  {idElm}
                  <OpenInNewOutlined className="cardIdCopy" />
                </Link>
              )
              : idElm}
        </Stack>
      )}

      <SubTitle title={title} size={size} isLoading={isLoading} />
    </Stack>
  )
})

IdTitle.propTypes = {
  /** UserId to display */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /** title to display */
  title: PropTypes.string,
  /** Variant of the component */
  size: PropTypes.oneOf([ 's', 'm' ]),
  /** Hides the ID-Chip */
  isIdChipHidden: PropTypes.bool,
  /** If true, User ID is a link to profile page */
  isLink: PropTypes.bool,
  /** Loading state */
  isLoading: PropTypes.bool,
  /** to override id comp */
  customIdChip: PropTypes.bool
}
