export const UserCommunity = {
  DEALER_EMPLOYEE: 'DEALER_EMPLOYEE',
  CORPORATE_CUSTOMER_EMPLOYEE: 'CORPORATE_CUSTOMER_EMPLOYEE',
  CORPORATE_BUSINESS_PARTNER_PERSON: 'CORPORATE_BUSINESS_PARTNER_PERSON',
  DAIMLER_INTERNAL: 'DAIMLER_INTERNAL',
  DAIMLER_EXTERNAL: 'DAIMLER_EXTERNAL',
  DAIMLER_POOLID: 'DAIMLER_POOLID',
  DAIMLER_JOINT_VENTURE_PERSON: 'DAIMLER_JOINT_VENTURE_PERSON',
  SUPPLIER_EMPLOYEE: 'SUPPLIER_EMPLOYEE',
  TECH_USER: 'TECH_USER',
  TEST_USER: 'TEST_USER',
  BOT_USER: 'BOT_USER'
}

export const UserCommunities = Object.values(UserCommunity)
