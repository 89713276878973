import { useState } from 'react'
import PropTypes from 'prop-types'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../util'
import { ListEntry } from './ListEntry'
import { IconWrapper } from '../icon/iconWrapper/IconWrapper'

export const ListGroup = ({
  name,
  items,
  icon: Icon,
  initialOpen = false,
  sx = []
}) => {
  const { t } = useTranslation()
  const [ open, setOpen ] = useState(initialOpen)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton
        key={name}
        data-testid={`ListGroup-${name}`}
        onClick={handleClick}
        sx={mergeSxProps({
          flexGrow: 0,
          color: 'text.primary',
          '&.Mui-selected': {
            borderLeft: 'unset',
            paddingLeft: '16px',
            color: 'primary.dark',
            borderRadius: '4px',
            backgroundColor: 'blue.90'
          },
          '&:hover': { backgroundColor: 'action.hover', borderRadius: '4px' }
        }, sx)}
      >
        {Icon && (
          <IconWrapper
            sx={{ marginRight: '8px' }}
            color={'inherit'}
            size={18}
          >
            <Icon color="inherit" fontSize="inherit" />
          </IconWrapper>
        )}

        <ListItemText
          primary={t(`navTargets.${name}`)}
          primaryTypographyProps={{ variant: 'body2' }}
          sx={{
            '> span': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'inherit'
            }
          }}
        />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" sx={{ ml: 2, minHeight: 'unset !important' }}>
        <List component="div" disablePadding>
          {items.map((target, index) => (
            <ListEntry key={index} {...target} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

ListGroup.propTypes = {
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
