import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { OrgSearch } from '../../../orgElements'
import { AssignmentDialog } from '../dialog/AssignmentDialog'

export const OrganizationAdministratorAssignmentDialog = ({
  userId,
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedOrganizations, setSelectedOrganizations ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.orgs'),
      content: selectedOrganizations.length ? `${selectedOrganizations.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'orgadmin',
        body: { orgAdminPrivileges: selectedOrganizations.map((id) => ({ id })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.orgAdmin') }),
      onRequestSuccess: (response) => onAssigned({ orgAdmin: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsAssignDisabled = () => !(selectedOrganizations && selectedOrganizations.length > 0)

  const handleOrganizationCardClick = (organization, selected) => {
    if (selected && !selectedOrganizations.includes(organization)) {
      setSelectedOrganizations([ ...selectedOrganizations, organization ])
    } else {
      setSelectedOrganizations(selectedOrganizations.filter((org) => org !== organization))
    }
  }

  const content = [
    {
      title: t('adminRoles.select.orgs'),
      render: (
        <OrgSearch
          views={[ 'list' ]}
          interaction={Interaction.MULTI_SELECT}
          isCaching
          preselectedRowIds={selectedOrganizations}
          onCardClick={handleOrganizationCardClick}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="OrganizationAdministratorAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.orgAdmin'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

OrganizationAdministratorAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
