import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../button'
import { Dialog } from '../dialog/Dialog'
import { ConfirmDialogSeverityIcon } from './ConfirmDialogSeverityIcon'

/**
 * This is a unique implementation of a dialog designed specifically for requesting user confirmation.
 *
 * Use this type to reduce the risk that people automatically agree to a warning without realising the consequences.
 */
export const ConfirmDialog = forwardRef((
  {
    isOpen,
    isLoading,
    title,
    severity = 'info',
    cancelButtonText,
    confirmButtonText,
    onCancel = () => {},
    onConfirm = () => {},
    children,
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Dialog
      data-testid="ConfirmDialog"
      ref={ref}
      open={isOpen}
      onClose={onCancel}
      headerSection={title || t(`dialog.confirm.title.${severity}`)}
      footerSection={(
        <>
          <Button
            data-testid="ConfirmDialog-cancel"
            onClick={onCancel}
            variant="secondary"
          >
            {cancelButtonText || t('dialog.confirm.button.cancel')}
          </Button>

          <Button
            data-testid="ConfirmDialog-confirm"
            isLoading={isLoading}
            onClick={onConfirm}
            variant="primary"
          >
            {confirmButtonText || t('dialog.confirm.button.confirm')}
          </Button>
        </>
      )}
      sx={{
        '.DialogContent': {
          display: 'flex',
          alignItems: 'center',
          gap: 3
        }
      }}
      {...otherProps}
    >
      <ConfirmDialogSeverityIcon severity={severity} />

      {children}
    </Dialog>
  )
})

ConfirmDialog.propTypes = {
  /** Determines current state of dialog popup */
  isOpen: PropTypes.bool,
  /** Determines if action is currently loading */
  isLoading: PropTypes.bool,
  /** Overrides the title of the dialog */
  title: PropTypes.string,
  /** Severity of the dialog */
  severity: PropTypes.oneOf([ 'info', 'success', 'warning', 'error' ]),
  /** Overrides the cancel button text */
  cancelButtonText: PropTypes.string,
  /** Overrides the confirm button text */
  confirmButtonText: PropTypes.string,
  /** Called when user closed / cancelled */
  onCancel: PropTypes.func,
  /** Called when user confirmed */
  onConfirm: PropTypes.func
}
