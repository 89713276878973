import React from "react";
import App from "./App";
import "./setupIntercepters";
import reportWebVitals from "./reportWebVitals";
import "./i18nextInit";
import { Provider } from "react-redux";
import toolkitStore from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@alice/component_library";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackPage from "@src/errorFallback";
// import { AliceThemeProvider } from "./CustomThemeProvider/ThemeProvider";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
    <AppProvider>
      <BrowserRouter>
        <Provider store={toolkitStore}>
          <App />
        </Provider>
      </BrowserRouter>
    </AppProvider>
  </ErrorBoundary>
);

reportWebVitals();
