import {
  Grid,
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  IconButton,
} from "@mui/material";
import { stringToBoolean } from "./util";
import { styled } from "@mui/system";
import { Chip } from "@alice/component_library";
import { DataGrid } from "@mui/x-data-grid";

export const TaskPageContainer = styled("div")(({ theme }) => ({
  flexGrow: "1",
  "& #layout-content": {
    position: "relative",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "20px 20px 0px",
    "& .MuiSelect-selectMenu": {
      whiteSpace: "normal",
    },
  },
  "& .MuiSnackbar-root": {
    position: "absolute",
    right: 0,
    // top: 0,
    "& .MuiPaper-root": {
      position: "fixed",
    },
  },
}));

export const HeaderContainer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  marginTop: "24px",
  flexDirection: "row",
  flexWrap: "wrap",
});

export const HeaderTypography = styled((props) => <Typography {...props} />)(
  ({ ...props }) => ({
    color: stringToBoolean(props.isheader) ? "#000" : "#333",
    marginBottom: stringToBoolean(props.isheader) ? "0px" : "32px",
  })
);

export const SubHeaderTypography = styled((props) => <Typography {...props} />)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Text",
    lineHeight: "24px",
    letterSpacing: "0px",
    fontWeight: "400",
    fontSize: "16px",
    color: "rgb(51, 51, 51)",
    margin: "0px 0px 32px",
  })
);

export const FilterHeadTypography = styled(Typography)(({ ...props }) => ({
  color: "rgba(0, 0, 0, 0.60)",
}));

export const AdminSwitchContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  marginBottom: "32px",
}));

export const FilterSortHeader = styled("div")(({ ...props }) => ({
  marginBottom: "10px",
  width: !props.isdesktop && "100%",
}));

export const FilterChip = styled(Chip)(({ ...props }) => ({
  display: "flex",
  height: "32px",
  padding: "0px 14px",
  alignItems: "center",
  gap: "4px",
  borderRadius: "100px",
  border: props.selected
    ? "1px solid  #0078D6"
    : "1px solid  rgba(0, 0, 0, 0.26)",
  color: props.selected ? "#0078D6" : " rgba(0, 0, 0, 0.87)",
  textAlign: "center",
  leadingTrim: "both",
  fontFamily: "MB Corpo S Text",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "19.2px" /* 147.692% */,
  "&:hover": {
    border: "1px solid  #0078D6",
    backgroundColor: "transparent !important",
    color: "#0078D6",
    "& svg": {
      path: {
        fill: "#0078D6",
      },
    },
  },
  "& svg": {
    path: {
      fill: props.selected ? "#0078D6" : "black",
      fillOpacity: props.selected ? 1 : "0.87",
    },
  },
}));
export const TaskFlexContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  width: "100%",
  ".match-in-height": {
    flex: 1,
  },
  // display: "flex",
}));

export const TaskDetailedContainer = styled("div")(({ ...props }) => ({
  position: "relative",
  top: "53px",
  left: props.windowsize.isDesktop && "16%",
  width: props.windowsize.isDesktop ? "calc(100% - 16%)" : "100%",
  minHeight: props.minheight,
}));

export const TaskDetailsContainer = styled("div")(({ ...props }) => ({
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "4px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "#FFF",
  "& .remove-icon-svg": {
    display: "flex",
    justifyContent: "center",
    width: "25px",
    height: "24px",
    alignItems: "center",
  },
}));

export const FilterSelectContainer = styled("div")({
  "& .MuiFormControl-root": {
    width: "100%",
  },
  "& .MuiSelect-icon": {
    right: "20px",
  },
});

export const TaskDetailsHeader = styled("div")(({ theme, ...props }) => ({
  flexWrap: "wrap",
  width: "100%",
  display: "flex",
  padding: "8px 11px 8px 16px",
  alignSelf: "stretch",
  borderRadius: "4px 4px 0px 0px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  position: "relative",
  background: props.bgcolor,
  "& .close-icon": {
    position: "absolute",
    right: "5px",
    top: "10px",
    cursor: "pointer",
  },
  "& .task-icon-id-link": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 24px)",
    },
    "& svg": {
      marginRight: "8px",
    },
  },
  "& .task-id-container": {
    display: "flex",
    gap: "10px 24px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  "& .mr-w-10": {
    [theme.breakpoints.up("sm")]: {
      marginRight: "10px",
      width: "calc(100% - 24px)",
    },
  },
  "& .calender-container": {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "4px",
      minWidth: "18px",
    },
    "& .MuiTypography-root": {
      lineHeight: "20px",
      fontSize: "14px",
    },
  },
  "& .w-100": {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
}));

export const TaskPageTableContainer = styled(Grid)(({ theme, ...props }) => ({
  width: "100%",
  display: "flex",
  position: "relative",
  paddingBottom: "40px",
  ".select-all-label": {
    display: !props.isdesktop && "none",
    position: "absolute",
    top: "69px",
    left: "50px",
    zIndex: 1,
    "& span": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "MB Corpo S Text",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
    },
  },
  ".action-buttons": {
    position: "absolute",
    display: "flex",
    right: "8px",
    top: "63px",
    flexDirection: "row",
    zIndex: 1,
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "16px",
      "& svg": {
        marginRight: "2px",
        path: {
          fill: props.svgcolor,
          fillOpacity: props.svgopacity,
        },
      },
      "& span": {
        fontFamily: "MB Corpo S Text",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
      },
    },
  },
  ".padding-7": {
    paddingRight: "7px",
  },
  "& .MuiTablePagination-actions": {
    marginTop: "0px !important",
    display: !props.isdesktop && props.isdetailsopen && "none",
  },
  "& .MuiTablePagination-select.MuiSelect-select.MuiSelect-standard.MuiInputBase-input:hover":
    {
      background: "transparent !important",
    },
  "> .DataGridSingle-table": {
    width: "252px",
    display: "flex",
    flexDirection: "column-reverse",
    border: "none",
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .ml-7": {
      marginLeft: "7px !important",
    },
    "& .MuiSvgIcon-fontSizeMedium": {
      // width: "18px",
      // height: "18px",
      fontSize: "24px",
    },
    "& .MuiTablePagination-select:hover": {
      background: "#fff !important",
    },
    "& .MuiTablePagination-select:focus": {
      background: "#fff !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      display: "flex",
      // height: "47px",
      position: "relative",
      alignItems: "center",
      gap: "521px",
      alignSelf: "stretch",
      borderRadius: "4px 4px 0px 0px",
      border: "1px solid  rgba(0, 0, 0, 0.12)",
      background: "#F4F4F4",
      zIndex: 0,
      padding: "0 4px",
      [theme.breakpoints.down("sm")]: {
        maxHeight: props.ispending && "100px !important",
        height: props.ispending && "100px !important",
      },
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowX: "hidden",
      height: "auto",
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      // width: "100% !important",
      minHeight: "445px",
      borderRadius: "0px 0px 4px 4px",
      borderRight: "1px solid  rgba(0, 0, 0, 0.12)",
      borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      "& .svg-style svg": {
        marginRight: "4px",
      },
      "& .MuiDataGrid-row.Mui-hovered": {
        backgroundColor: "rgba(0, 120, 214, 0.16) !important",
      },
      "& .MuiDataGrid-row": {
        cursor: "pointer",
        maxWidth: "100%",
        "&:not(:last-child)": {
          borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
        },
        "& .MuiDataGrid-cellCheckbox": {
          alignItems: "start",
        },
        "&::hover": {
          backgroundColor: "rgba(0, 120, 214, 0.16) !important",
        },
        "&::focus": {
          backgroundColor: "rgba(0, 120, 214, 0.16) !important",
        },
      },
      "& .aprooved-icon": {
        marginLeft: "9px",
      },
      "& .column-mobile-container": {
        width: "98%",
        display: "flex",
        flexDirection: "column",
        "& .task-id-mobile": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: "6px",
        },
        "& .task-detail-container": {
          width: "calc(100% - 15px)",
          padding: "6px 0px",
          background: "#F8F8F8",
          borderRadius: "4px",
        },
      },
      "& .task-row-container": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
        paddingLeft: "10px",
        "& .svg-container": {
          display: "flex",
          alignItems: "center",
        },
        "& .remove-icon-svg-": {
          paddingRight: "4px",
          display: "flex",
          alignItems: "center",
          "&.cross": {
            paddingLeft: "7px",
          },
          "& svg": {
            path: {
              fill: "#000 !important",
              fillOpacity: "0.38 !important",
            },
          },
        },
        "& svg": {
          path: {
            fill: "black !important",
            fillOpacity: "0.87 !important",
          },
        },
        "&.svg-container": {
          display: "flex",
          gap: "10px",
        },
        "& .task-gneral-style": {
          overflow: "hidden",
          color: "rgba(0, 0, 0, 0.87)",
          textOverflow: "ellipsis",
          fontFamily: "MB Corpo S Text",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          flex: 1,
          whiteSpace: "nowrap",
          "& a": {
            color: "rgb(0, 120, 214)",
            textDecoration: "none",
          },
        },
        "& .task-id": {
          overflow: "hidden",
          color: "#0078D6",
          paddingLeft: "4px",
        },
        "& .task-row-sub-container": {
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginLeft: "4px",
        },
      },

      "& .MuiDataGrid-virtualScrollerRenderZone": {
        width: "100%",
        padding: props.isdetailsopen ? "0" : "0 6px 0 4px",
      },
    },
    "& .MuiDataGrid-columnHeader": {
      "&:focus": {
        outline: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-cell": {
      padding: "0 !important",
      border: "none !important",
      "&:focus": {
        outline: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
  },
  "> .DataGridSingle-detail": {
    display: "flex",
    flexDirection: "column-reverse",
    border: "none",
    position: props.isdetailsopen ? "absolute" : "relative",
    width: "100%",
    "& .MuiDataGrid-main ": {
      width: "15%",
      display: props.isdetailsopen && !props.isdesktop && "none",
    },
    "& .MuiDataGrid-row.Mui-hovered": {
      backgroundColor: "rgba(0, 120, 214, 0.16) !important",
    },
    "& .MuiDataGrid-row": {
      borderLeft: "5px solid transparent",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-row-selected": {
      background:
        "var(--others-menu-item-enabled-background-pri-16, rgba(0, 120, 214, 0.16))",
      borderLeft: "5px solid #0078D6",
    },
    "& .MuiSvgIcon-fontSizeMedium": {
      width: "18px",
      height: "18px",
    },
    "& .MuiDataGrid-columnHeaders": {
      display: "flex",
      height: "47px",
      alignItems: "center",
      gap: "521px",
      alignSelf: "stretch",
      borderRadius: "4px 4px 0px 0px",
      border: "1px solid  rgba(0, 0, 0, 0.12)",
      background: "#F4F4F4",
      zIndex: 0,
      padding: "0 4px",
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowX: "hidden",
      height: "auto",
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      // width: "100% !important",
      minHeight: "445px",
      borderRadius: "0px 0px 4px 4px",
      borderRight: "1px solid  rgba(0, 0, 0, 0.12)",
      borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      "& .svg-style svg": {
        marginRight: "4px",
      },
      "& .MuiDataGrid-row": {
        cursor: "pointer",
        maxWidth: "100%",
        "&:not(:last-child)": {
          borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
        },
      },
      "& .task-row-container": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
        "& .task-gneral-style": {
          overflow: "hidden",
          color: "rgba(0, 0, 0, 0.87)",
          textOverflow: "ellipsis",
          fontFamily: "MB Corpo S Text",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          flex: 1,
          whiteSpace: "nowrap",
          "& a": {
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.87)",
          },
        },
        "& .task-id": {
          overflow: "hidden",
          color: "#0078D6",
          paddingLeft: "4px",
        },
      },

      "& .MuiDataGrid-virtualScrollerRenderZone": {
        width: "100%",
        padding: props.isdetailsopen ? "0" : "0 6px 0 4px",
      },
    },
    "& .MuiDataGrid-columnHeader": {
      "&:focus": {
        outline: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-cell": {
      border: "none !important",
      "&:focus": {
        outline: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
      marginRight: !props.isdesktop && props.isdetailsopen && "auto",
      "& .MuiToolbar-root": {
        paddingLeft: !props.isdesktop && props.isdetailsopen && 0,
      },
      "& MuiInputBase-root": {
        marginLeft: !props.isdesktop && props.isdetailsopen && 0,
        "& .MuiTablePagination-select": {
          paddingLeft: !props.isdesktop && props.isdetailsopen && 0,
        },
      },
    },
  },
}));

export const TaskContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: props.isdesktop ? "row" : "column",
  width: "100%",
  paddingBottom: "40px",
}));

export const SortContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: "column",
  width: props.isdesktop ? "30%" : "100%",
  marginBottom: "10px",
  marginTop: props.isdesktop ? "0px" : "6px",
  "& #sort-select": {
    "&:focus": {
      backgroundColor: "white",
    },
    "&:active": {
      backgroundColor: "white",
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  "&:hover": {
    background: "rgba(0, 120, 214, 0.16)",
  },
});

export const FilterStack = styled((props) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  />
))(({ ...props }) => ({
  margin: props.isdesktop ? "24px 0px 30px 0px" : "16px 0px",
  flexDirection: props.isdesktop ? "row" : "column",
  flexWrap: "wrap",
  rowGap: "24px",
}));

export const FilterChipContainer = styled((props) => (
  <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" {...props} />
))(({ ...props }) => ({
  paddingTop: "10px",
  "& .MuiChip-icon": {
    marginRight: "1px !important",
    marginLeft: "-3px !important",
  },
  "& .MuiChip-label": {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}));

export const StyledDialog = styled((props) => <Dialog {...props} />)(
  ({ ...props }) => ({})
);

export const StyledDialogTitle = styled((props) => <DialogTitle {...props} />)(
  ({ ...props }) => ({
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "24px 24px 16px",
  })
);

export const StyledDialogContent = styled((props) => (
  <DialogContent {...props} />
))(({ ...props }) => ({
  padding: "24px",
  "& .MuiInputBase-root": {
    padding: "12px 16px",
  },
  "& .input-info": {
    marginBottom: "32px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const StyledDialogContentText = styled((props) => (
  <DialogContentText {...props} />
))(({ ...props }) => ({
  marginBottom: "28px",
}));

export const StyledDialogContentHeader = styled((props) => (
  <Typography variant="h1" {...props} />
))(({ ...props }) => ({
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px",
  marginBottom: "8px",
  fontFamily: "MB Corpo S Title",
  "& span": {
    backgroundColor: "#0078D6 !important",
    marginLeft: "8px",
  },
}));
export const CloseButton = styled((props) => <Button {...props} />)(
  ({ ...props }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#000000",
    padding: "6px",
    minWidth: 36,
    width: 36,
    height: 36,
    "&:hover": {
      backgroundColor: "#000000",
    },
    "& svg": {
      color: "#FFFFFF",
    },
  })
);

export const StyledDialogAction = styled((props) => (
  <DialogActions {...props} />
))(({ ...props }) => ({
  padding: "24px",
}));

export const StickyDataGrid = styled(DataGrid)(({ theme, isdesktop }) => ({
  ...(!isdesktop && {
    "& .MuiDataGrid-columnHeaders": {
      position: "sticky !important",
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.mobileStepper - 1,
    },
    "& .MuiDataGrid-virtualScroller": {
      height: "400px !important",
      overflowY: "scroll !important",
      marginTop: "0 !important",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px #F4F4F4",
        backgroundColor: "#F4F4F4",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "#F4F4F4",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
      },
    },
    "& .MuiDataGrid-main": {
      overflow: "visible",
    },
  }),
}));

export const RejectTableContainer = styled((props) => <DataGrid {...props} />)({
  "& .MuiDataGrid-columnHeaders,.MuiDataGrid-footerContainer": {
    display: "none",
  },
  "& .MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#F4F4F4",
  },
  "& .MuiDataGrid-row": {
    borderBottom: "5px solid #FFFFFF",
    backgroundColor: "#F4F4F4",
  },
  border: "none",
  backgroundColor: "#F4F4F4",
});

export const RejectCard = styled((props) => <Grid container {...props} />)(
  ({ ...props }) => ({
    backgroundColor: props.bgcolor,
    margin: "0 0 10px 0",
    width: "100%",
    position: "relative",
    padding: "10px",
    "& .MuiGrid-item": {
      padding: "5px !important",
      "& svg": {
        minWidth: "18px",
        marginRight: "4px",
      },
      "& > div": {
        display: "flex",
        alignItems: "center",
        minWidth: 0,
        "& > span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    "& #delete-close": {
      position: "absolute",
      top: "-8px",
      right: "-8px",
      display: "none",
    },
    "&:hover": {
      "& > #delete-close": {
        display: "block",
      },
    },
  })
);

export const TaskDetailsAction = styled("div")({
  position: "absolute",
  top: "10px",
  right: "0px",
  zIndex: "100",
});

export const TaskDetailsIconButton = styled((props) => (
  <IconButton {...props} />
))({
  // "& .MuiButtonBase-root.Mui-disabled": {
  //   cursor: "not-allowed",
  //   PointerEvent: "auto",
  // },
});

export const IdSpanContainer = styled("span")({
  margin: "0 3px",
});

export const WorkfowHeader = styled("h2")({
  margin: "0px",
  marginTop: "14px",
  fontFamily: "MB Corpo S Text",
  letterSpacing: "0px",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "24px",
});
