export const CacheKey = {
  // Fallback
  unnamed: 'cache.unnamed',

  // Api
  reportsBilling: 'alice.reports.billing',
  usersTotalCount: 'alice.users.totalCount',
  supportContacts: 'alice.supportcontacts',
  agreements: 'alice.agreements',
  me: 'alice.userData',
  openTasks: 'alice.openTasksCount',

  // Announcements
  seenAnnouncements: 'announcements.seen',

  // Searches
  genericSearchQuery: 'generic.search.query',
  orgSearchQuery: 'org.search.query',
  userSearchQuery: 'user.search.query',
  roleSearchQuery: 'role.search.query',
  applicationSearchQuery: 'application.search.query',
  marketSearchQuery: 'markets.search.query',
  userGroupSearchQuery: 'group.search.query',
  entitlementSearchQuery: 'entitlement.search.query'
}
