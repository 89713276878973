import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { MarketSearch } from '../../../marketElements'
import { AssignmentDialog } from '../dialog/AssignmentDialog'
import { CommunityScope } from '../scope/CommunityScope'

export const IdentStatusAdministratorAssignmentDialog = ({
  userId,
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedMarkets, setSelectedMarkets ] = useState([])
  const [ selectedCommunities, setSelectedCommunities ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.markets'),
      content: selectedMarkets.length ? `${selectedMarkets.length} ${t('general.selected')}` : ''
    },
    {
      title: t('adminRoles.stepper.communities'),
      content: selectedCommunities.length ? `${selectedCommunities.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'identstatusadmin',
        body: {
          identStatusAdminPrivileges: selectedMarkets.flatMap((market) => selectedCommunities.map((community) => ({
            marketCountryCode: market,
            community
          })))
        }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.identStatusAdmin') }),
      onRequestSuccess: (response) => onAssigned({ identStatusAdmin: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsNextDisabled = () => !(selectedMarkets && selectedMarkets.length > 0)
  const handleIsAssignDisabled = () => !(selectedCommunities && selectedCommunities.length > 0)

  const handleMarketCardClick = (country, selected) => {
    if (selected && !selectedMarkets.includes(country)) {
      setSelectedMarkets([ ...selectedMarkets, country ])
    } else {
      setSelectedMarkets(selectedMarkets.filter((market) => market !== country))
    }
  }

  const content = [
    {
      title: t('adminRoles.select.markets'),
      render: (
        <MarketSearch
          views={[ 'list' ]}
          interaction={Interaction.MULTI_SELECT}
          isCaching
          search="large"
          preselectedRowIds={selectedMarkets}
          preselectedView="list"
          onCardClick={handleMarketCardClick}
        />
      )
    },
    {
      title: t('adminRoles.select.communities'),
      render: (
        <CommunityScope
          administratedCommunities={[]}
          selectedCommunity={selectedCommunities}
          setSelectedCommunity={setSelectedCommunities}
          availableCommunities={[
            'DEALER',
            'CORPORATE_CUSTOMER',
            'SUPPLIER'
          ]}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="IdentStatusAdministratorAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.identStatusAdmin'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableNext={handleIsNextDisabled}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

IdentStatusAdministratorAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
