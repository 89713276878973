import { useContext, useEffect, useState } from 'react'
import { UserCommunity } from '../../constants'
import { HttpCode } from '../../data'
import { BASE_URL, checkAuthority } from '../../util'
import { useApp } from '../app/UseApp'
import { useCache } from '../cache/UseCache'
import { UserProviderContext } from './UserProvider'

export const useUser = ({ overrideAuthorized = false } = {}) => {
  const {
    isLoading,
    user,
    statusUser,
    statusAgreements,
    statusOpenTasksCount,
    entitlements,
    statusEntitlements,
    adminRoles,
    statusAdminRoles
  } = useContext(UserProviderContext)

  const { client, environment } = useApp()
  const { clearAllCaches } = useCache()

  const [ isAuthorized, setIsAuthorized ] = useState(false)
  const [ isBotUser, setIsBotUser ] = useState(false)

  useEffect(() => {
    if (!user) return

    setIsBotUser(user?.communities?.includes(UserCommunity.BOT_USER))
  }, [ user ])

  useEffect(() => {
    if (overrideAuthorized) setIsAuthorized(true)

    const statuses = [
      statusUser,
      statusAgreements,
      statusOpenTasksCount,
      statusAdminRoles,
      statusEntitlements
    ]

    const isAnyRequestUnauthorized = statuses.every((status) => status === HttpCode.UNAUTHORIZED || status === HttpCode.FORBIDDEN)
    const isAuthorizedToVisitClient = checkAuthority(client, entitlements, adminRoles)

    setIsAuthorized(!isAnyRequestUnauthorized && isAuthorizedToVisitClient)
  }, [
    overrideAuthorized,
    adminRoles,
    entitlements,
    client,
    statusUser,
    statusAgreements,
    statusOpenTasksCount,
    statusAdminRoles,
    statusEntitlements
  ])

  const logout = () => {
    if (!window) return

    clearAllCaches()
    window.location.href = `${BASE_URL[environment]}/logout`
  }

  return {
    isLoadingUser: isLoading,
    isUserAuthorized: isAuthorized,
    isBotUser,
    user,
    logout
  }
}
