export class PaginationParser {
  static toAlice({ page, size }) {
    return {
      offset: page * size,
      limit: size
    }
  }

  static toNotificationModule({ page, size }) {
    return {
      offset: page * size,
      page_size: size
    }
  }
}
