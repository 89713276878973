import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class OrganizationsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/organizations',
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/organizations/${id}`,
    { ...args }
  )

  static updateById = ({
    id,
    organization,
    ...args
  }) => AliceService.put(
    `/organizations/${id}`,
    mergeObjects(
      { body: organization },
      { ...args }
    )
  )

  static sync = ({
    id,
    report,
    ...args
  }) => AliceService.post(
    `/organizations/${id}/${report}`,
    { ...args }
  )

  static getAllDealerHomeOrgMappingRules = ({
    id,
    ...args
  }) => AliceService.get(
    `/organizations/${id}/dealerhomeorgmappingrules`,
    { ...args }
  )

  static createDealerHomeOrgMappingRules = ({
    id,
    rules,
    ...args
  }) => AliceService.post(
    `/organizations/${id}/dealerhomeorgmappingrules`,
    mergeObjects(
      { body: { rules } },
      { ...args }
    )
  )

  static updateDealerHomeOrgMappingRules = ({
    id,
    rules,
    ...args
  }) => AliceService.put(
    `/organizations/${id}/dealerhomeorgmappingrules`,
    mergeObjects(
      { body: { rules } },
      { ...args }
    )
  )

  static deleteDealerHomeOrgMappingRules = ({
    id,
    ...args
  }) => AliceService.delete(
    `/organizations/${id}/dealerhomeorgmappingrules`,
    { ...args }
  )

  static updateUserManagement = ({
    id,
    enabled,
    ...args
  }) => AliceService.post(
    `/organizations/${id}/${enabled ? 'enableusermanagement' : 'disableusermanagement'}`,
    { ...args }
  )

  static getAllDeleteActions = ({ ...args }) => AliceService.get(
    '/organizations/deleteactions',
    { ...args }
  )

  static getAllDeleteActionsAssignmentInformationById = ({
    id,
    ...args
  }) => AliceService.get(
    `/organizations/${id}/deleteactions/assignmentinformation`,
    { ...args }
  )

  static acceptDeleteActions = ({
    organizationIds,
    ...args
  }) => AliceService.post(
    '/organizations/deleteactions/accept',
    mergeObjects(
      { body: { orgIds: organizationIds } },
      { ...args }
    )
  )

  static discardAllDeleteActions = ({ ...args }) => AliceService.delete(
    '/organizations/deleteactions/discardall',
    { ...args }
  )

  static getAllTransferActions = ({ ...args }) => AliceService.get(
    '/organizations/transferactions',
    { ...args }
  )

  static acceptTransferActions = ({
    organizationIds,
    behavior,
    ...args
  }) => AliceService.post(
    `/organizations/transferactions/accept/${behavior}`,
    mergeObjects(
      { body: { orgIds: organizationIds } },
      { ...args }
    )
  )

  static discardAllTransferActions = ({ ...args }) => AliceService.delete(
    '/organizations/deleteactions/discardall',
    { ...args }
  )

  static getAllSuccessorActions = ({ ...args }) => AliceService.get(
    '/organizations/successoractions',
    { ...args }
  )

  static transferSuccessorActions = ({
    organizationIds,
    ...args
  }) => AliceService.post(
    '/organizations/successoractions/transferall',
    mergeObjects(
      { body: { orgIds: organizationIds } },
      { ...args }
    )
  )

  static discardSuccessorActions = ({
    organizationIds,
    ...args
  }) => AliceService.delete(
    '/organizations/successoractions/discard',
    mergeObjects(
      { body: { orgIds: organizationIds } },
      { ...args }
    )
  )

  static getAllSuccessorActionsAssignmentInformationById = ({
    id,
    ...args
  }) => AliceService.get(
    `/organizations/${id}/successoractions/assignmentinformation`,
    { ...args }
  )
}
