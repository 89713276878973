import { styled } from "@mui/system";
import { Typography } from "@mui/material";

export const CardMainContanier = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "470px",
  borderRadius: "4px",
  rowGap: "24px",
  padding: "24px",
  justifyContent: "space-between",
  border: "1px solid rgba(219, 219, 219, 1)",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const CardTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  flexGrow: 1,
}));

export const CardHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "8px",
}));

export const CardHeaderTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.6)",
});

export const CardTitleTypo = styled((props) => (
  <Typography variant="h4" {...props} />
))(({ theme }) => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
  textOverflow: "ellipsis",
  fontFamily: "MB Corpo S Title Condensed",
  fontSize: "26px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "32px",
  wordBreak: "break-word",
  "& > span": {
    wordBreak: "break-word",
  },
  [theme.breakpoints.down("md")]: {
    whiteSpace: "normal",
  },
}));

export const CardDescriTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.87)",
  wordBreak: "break-word",
});

export const CardDescriSpan = styled("span")({
  fontFamily: "MB Corpo S Text",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.87)",
  wordBreak: "break-word",
});

export const CardIconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "24px",
  alignItems: "flex-end",
}));

export const CardButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
}));

export const RequestSvgCardConatiner = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& .svg-container svg": {
      width: "65.5px",
      height: "65.5px",
    },
  },
  "@media (max-width: 340px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
