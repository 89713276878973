export const useOrgCard = ({
  size = 'xl',
  isList = false,
  isRenderer = false,
  address = isRenderer,
  community = isRenderer,
  communities = isRenderer && [ 1 ],
  orgFormTitle = isRenderer,
  orgForm = isRenderer,
  country = isRenderer,
  orgGroupType = isRenderer,
  status = isRenderer,
  central = isRenderer,
  decentral = isRenderer,
  _isContextShown = true,
  _isAddressShown = size === 'l' || size === 'xl',
  _isCommunityShown = size === 'xl',
  _isOrgTypeShown = size === 'xl',
  _isFlagShown = size === 'l' || size === 'xl',
  _isFocusShown = size === 'xl',
  _isStatusShown = size !== 's',
  _onEditClick
}) => {
  const isGrid = !isList
  const isTiny = size === 's'

  const isContextShown = _isContextShown
  const isAddressShown = _isAddressShown && address
  const isCommunityShown = _isCommunityShown && community && communities.length
  const isOrgTypeShown = _isOrgTypeShown && orgFormTitle && orgForm
  const isFlagShown = _isFlagShown && country
  const isFocusShown = _isFocusShown && orgGroupType
  const isStatusShown = _isStatusShown && status
  const isEditShown = !!_onEditClick

  const isInfoChipsShown = ((size === 'l' || size === 'xl') && (isFlagShown || isFocusShown))
  const isCentralChipsShown = ((size === 's' || size === 'm') && (central || decentral))
  const isDetailsShown = isCommunityShown || isOrgTypeShown
  const isChipsShown = isInfoChipsShown || isCentralChipsShown
  const isActionShown = isEditShown

  const isDividerShown = isGrid && size === 'xl'
  const isDividedHorizontal = isList || (isTiny && (central || decentral))

  const isDividedShown = !!(
    (isList && isContextShown)
    || isEditShown
    || isChipsShown
    || isStatusShown
  )

  // eslint-disable-next-line max-len
  const subgridContentColumns = [ true, isAddressShown, isCommunityShown || isOrgTypeShown, isDividerShown ].filter((entry) => entry).length
  // eslint-disable-next-line max-len
  const subgridContentRows = [ true, isAddressShown, isCommunityShown, isOrgTypeShown, isDividerShown ].filter((entry) => entry).length

  return {
    isTiny,
    isContextShown,
    isAddressShown,
    isCommunityShown,
    isOrgTypeShown,
    isFlagShown,
    isFocusShown,
    isStatusShown,
    isEditShown,
    isInfoChipsShown,
    isCentralChipsShown,
    isDetailsShown,
    isChipsShown,
    isActionShown,
    isDividerShown,
    isDividedHorizontal,
    isDividedShown,
    subgridContentColumns,
    subgridContentRows
  }
}
