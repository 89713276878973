import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { timeout } from '../../../util'
import { GenericContextPopover } from '../../genericContextPopover'
import { UserGroupContext } from './UserGroupContext'

// For dev: timeoutInMS = 3000 -> wait 3 sec before the initial and other api calls are triggered
// For live: Set timeoutInMS to 0 (zero)!
const timeoutInMS = process.env.NODE_ENV === 'development' ? 3000 : 0

export const UserGroupContextPopover = ({
  userGroupData,
  placement,
  children,
  ...otherProps
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    const waitTimeout = async () => {
      if (timeoutInMS > 0) timeout(timeoutInMS)
    }

    if (userGroupData && isOpen) waitTimeout()
  }, [ userGroupData, isOpen ])

  return (
    <GenericContextPopover
      data-testid="UserGroupContextPopover"
      context={<UserGroupContext userGroupData={userGroupData} />}
      placement={placement}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      {...otherProps}
    >
      {children}
    </GenericContextPopover>
  )
}

UserGroupContextPopover.propTypes = {
  /** User group object, that should be displayed */
  userGroupData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    uuid: PropTypes.string,
    owners: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object)
  }),
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ]),
  /** The component that should be hover-able */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
