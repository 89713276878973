import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { mergeSxProps } from '../../util'

/**
 * A generic component to provide dynamic shadow to scrollable boxes
 */
export const ScrollShadow = forwardRef((
  {
    className = '',
    classNameInner = '',
    children,
    sx = {},
    ...otherProps
  },
  ref
) => {
  const [ wrapperRef, setWrapperRef ] = useState(null)
  const [ innerRef, setInnerRef ] = useState(null)
  const [ topIntersected, setTopIntersected ] = useState(false)
  const [ bottomIntersected, setBottomIntersected ] = useState(false)

  useImperativeHandle(ref, () => wrapperRef)

  const calcIntersection = useCallback(() => {
    if (wrapperRef && innerRef) {
      const top = wrapperRef.getBoundingClientRect().top > innerRef.getBoundingClientRect().top
      const bottom = wrapperRef.getBoundingClientRect().bottom < innerRef.getBoundingClientRect().bottom

      top !== topIntersected && setTopIntersected(top)
      bottom !== bottomIntersected && setBottomIntersected(bottom)
    }
  }, [ bottomIntersected, innerRef, topIntersected, wrapperRef ])

  useEffect(() => {
    if (wrapperRef) {
      topIntersected ? wrapperRef.classList.add('top') : wrapperRef.classList.remove('top')
      bottomIntersected ? wrapperRef.classList.add('bottom') : wrapperRef.classList.remove('bottom')
    }
  }, [ topIntersected, bottomIntersected, wrapperRef ])

  useMemo(() => {
    if (innerRef && wrapperRef) calcIntersection()
  }, [ calcIntersection, innerRef, wrapperRef ])

  return (
    <Box
      className={`ScrollShadow ${className}`}
      data-testid="ScrollShadow"
      ref={setWrapperRef}
      sx={mergeSxProps(
        {
          background: 'white',
          overflowY: 'auto',
          transition: 'box-shadow 500ms',
          '&:not(.bottom).top': { boxShadow: '0 10px 10px -10px rgb(0 0 0 / 0.3) inset, 0 0 0 0 transparent inset' },
          '&:not(.top).bottom': { boxShadow: '0 0 0 0 transparent inset, 0 -10px 10px -10px rgb(0 0 0 / 0.3) inset' },
          '&.top.bottom': { boxShadow: '0 10px 10px -10px rgb(0 0 0 / 0.3) inset, 0 -10px 10px -10px rgb(0 0 0 / 0.3) inset' }
        },
        sx
      )}
      onScroll={calcIntersection}
      {...otherProps}
    >
      <div className={`ScrollShadowInner ${classNameInner}`} ref={setInnerRef}>
        {children}
      </div>
    </Box>
  )
})

ScrollShadow.propTypes = {
  /** Custom style */
  sx: PropTypes.object,
  /** Child node */
  children: PropTypes.node
}
