import React from "react";
import { ValidityChip } from "@alice/component_library";
export const ValidFromComp = ({ finalValidFrom }) => {
  return (
    <>
      {finalValidFrom ? (
        <ValidityChip
          size="s"
          expiryDate={new Date(finalValidFrom)}
          sx={{ backgroundColor: "transparent", padding: 0 }}
        />
      ) : (
        "-"
      )}
    </>
  );
};
export const ValidToComp = ({
  finalValidTo,
  finalValidityDaysLeft,
  remainingDaysToWarning,
}) => {
  return (
    <>
      {finalValidTo ? (
        <ValidityChip
          remainingDaysToRed={0}
          sx={
            finalValidityDaysLeft > remainingDaysToWarning
              ? { backgroundColor: "transparent", padding: 0 }
              : {}
          }
          expiryDate={new Date(finalValidTo)}
        />
      ) : (
        "-"
      )}
    </>
  );
};
