import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { DeleteOutlined, EditOutlined, KeyboardTabRounded, StartRounded } from '@mui/icons-material'
import { Divider, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { Button } from '../../button'
import { AliceIcon } from '../../icon'
import { useInteraction } from '../../../hooks'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { CardContent, CardDivided, DetailsList, ExpandBtn, GenericCard, IdTitle } from '../../card/genericCard'
import { DataClassificationChip, ValidityChip } from '../../chip'
import { RoleTypeChip } from '../roleTypeChip/RoleTypeChip'
import { Skeleton } from '../../skeleton'

/** The RoleCard component is a versatile React card component designed to display role related information in a visually
 *  appealing manner. It offers various customization options and is particularly useful in scenarios where role
 *  details need to be presented in different layouts and styles.
 *
 * ## Usage
 *
 * The RoleCard component is designed to be highly customizable and adaptable to different use cases.
 * It extends the functionality of the ``GenericCard`` component, inheriting all its supported properties.
 * For a detailed list of available properties, please consult the [GenericCard documentation](./?path=/docs/alice-ui-card-genericcard--docs).
 *
 * ### Notes
 * - The `variant` prop allows you to choose from different card layouts, providing flexibility based on your
 * specific design requirements.
 * - For full functionality check the prop list below.
 *
 * ### List of integrated CLIB components
 * - [GenericCard](/docs/alice-ui-card-genericcard--docs)
 * - [DataClassificationChip](/docs/alice-ui-chip-dataclassificationchip--docs)
 * - [ValidityChip](/docs/alice-ui-chip-validitychip--docs)
 * - [IdTitle](/docs/alice-ui-card-components-idtitle--docs)
 *  */
export const RoleCard = ({
  variant = 'grid',
  size = 'm',
  data,
  isRoleChipShown: _isRoleChipShown = true,
  isClassificationShown: _isClassificationShown = true,
  isCustomScopeShown: _isCustomScopeShown = size === 'l',
  isOrgScopeShown: _isOrgScopeShown = size === 'l',
  isValidFromToShown: _isValidFromToShown = size === 'l',
  isExpanded = false,
  onDeleteClick: _onDeleteClick, // ({ id, data, e })
  onExpandClick: _onExpandClick, // ({ id, isExpanded, e }) => {},
  onEditClick: _onEditClick, // ({ id, data, e })
  // base card
  isLoading = false,
  isIdLink = true,
  isSelected = false,
  isDisabled = false,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({
    interaction,
    isLoading,
    isDisabled
  })

  // validFrom, validTo, orgScope, customScope could be undefined
  const {
    validFrom,
    validTo,
    orgScope,
    customScope,
    roleDefinition
  } = useMemo(() => data, [ data ])

  const {
    id,
    name,
    roleType,
    isJobTitle,
    isDynamic,
    dataClassification
  } = roleDefinition || data

  // main props
  const isList = variant === 'list'
  const isGrid = !isList

  // display props
  const isRoleChipShown = _isRoleChipShown && (roleType || isJobTitle || isDynamic)
  const isClassificationShown = _isClassificationShown && dataClassification
  const isOrgScopeShown = _isOrgScopeShown && orgScope
  const isCustomScopeShown = _isCustomScopeShown && customScope
  const isValidFromToShown = _isValidFromToShown && (validFrom || validTo)

  const isDeleteShown = !!_onDeleteClick
  const isEditShown = !!_onEditClick
  const isExpandShown = !!_onExpandClick

  const isChipsShown = isRoleChipShown || isClassificationShown
  const isScopesShown = isOrgScopeShown || isCustomScopeShown
  const isActionsShown = isDeleteShown || isEditShown || isExpandShown

  // const isDivider1Shown = isGrid
  const isDivider2Shown = isGrid && (isScopesShown || isValidFromToShown)

  const isDividedShown = !!(isActionsShown)

  // positioning props
  const isContentShifted = isSelectable && !isSelectHidden && isGrid

  const onCardClick = _onCardClick ? ({
    id,
    isSelected,
    e
  }) => {
    _onCardClick({
      id,
      data,
      isSelected,
      e
    })
  } : undefined

  const onEditClick = (e) => {
    e.preventDefault()

    _onEditClick({
      id,
      data,
      e
    })
  }

  const onDeleteClick = (e) => {
    e.preventDefault()

    _onDeleteClick({
      id,
      data,
      e
    })
  }

  const onExpandClick = ({
    isExpanded,
    e
  }) => {
    e.preventDefault()

    _onExpandClick({
      id,
      isExpanded,
      e
    })
  }

  return (
    <GenericCard
      type="roleCard"
      id={id}
      isList={isList}
      isSelected={isSelected}
      interaction={interaction}
      onCardClick={onCardClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isDividedShown={isDividedShown}
      sx={mergeSxProps({
        '.select': {
          display: 'flex',
          alignItems: 'center',
          height: 56,
          marginTop: variant === 'grid' ? '57px' : 'unset', // 24 + 16 * 2 + 1
          alignSelf: variant === 'list' ? 'flex-start' : 'unset'
        }
      }, sx)}
      {...otherProps}
    >
      <CardContent
        isList={isList}
        sx={[
          {
            gridTemplate: `
              'roleInfo roleChips'
              ${(isScopesShown || isValidFromToShown) ? `"${isScopesShown ? 'scopes' : '.'} ${isValidFromToShown ? 'validity' : '.'}"` : ''}
              / 1fr`
          },
          isGrid && {
            gridTemplate: `
              ${isChipsShown && "'roleChips roleChips' 'divider1 divider1'"} 
              'roleInfo roleInfo'
              ${isDivider2Shown ? "'divider2 divider2'" : ''} 
              ${isScopesShown ? "'scopes scopes'" : ''}
              ${isValidFromToShown ? "'validity validity'" : ''}
              / 1fr`
          }
        ]}
      >
        {isChipsShown && (
          <Stack
            sx={[
              {
                gridArea: 'roleChips',
                flexFlow: 'row',
                justifyContent: 'flex-end',
                gap: 2
              },
              isGrid && { justifyContent: 'space-between' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          >
            {isRoleChipShown && (
              <RoleTypeChip
                roleType={roleType}
                isDynamic={isDynamic}
                isJobTitle={isJobTitle}
                isLoading={isLoading}
              />
            )}

            {isClassificationShown && (
              <DataClassificationChip
                size="s"
                type={dataClassification}
                isLoading={isLoading}
              />
            )}
          </Stack>
        )}

        {isGrid && isChipsShown && (
          <Divider
            sx={[
              { gridArea: 'divider1' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        <IdTitle
          size="m"
          id={id}
          title={name}
          isLink={isIdLink}
          href={`/admin/roles/${id}`}
          sx={{ gridArea: 'roleInfo' }}
          isLoading={isLoading}
        />

        {isDivider2Shown && (
          <Divider
            sx={[
              { gridArea: 'divider2' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isScopesShown && (
          <DetailsList
            size={isGrid ? 'm' : 's'}
            isVertical={isGrid}
            isLoading={isLoading}
            data={[
              isOrgScopeShown && {
                label: t('roleElements.orgScope'),
                icon: <AliceIcon iconType="ORG_SCOPE" />,
                text: orgScope.name
              },
              isCustomScopeShown && {
                label: t('roleElements.customScope'),
                icon: <AliceIcon iconType="CUSTOM_SCOPE" />,
                text: customScope.name
              }
            ]}
            sx={{ gridArea: 'scopes' }}
          />
        )}

        {isValidFromToShown && (
          <DetailsList
            size={isGrid ? 'm' : 's'}
            isLoading={isLoading}
            data={[
              {
                label: t('roleElements.validFrom'),
                icon: <StartRounded />,
                text: validFrom
                  ? <ValidityChip expiryDate={new Date(validFrom)} size="s" />
                  : t('roleElements.notProvided'),
                sx: {
                  '.iconWrapper, .iconWrapper + *': {
                    display: 'flex',
                    alignItems: 'center',
                    height: 24
                  }
                }
              },
              {
                label: t('roleElements.validTo'),
                icon: <KeyboardTabRounded />,
                text: validTo
                  ? <ValidityChip expiryDate={new Date(validTo)} />
                  : t('roleElements.notProvided'),
                sx: {
                  '.iconWrapper, .iconWrapper + *': {
                    display: 'flex',
                    alignItems: 'center',
                    height: 24
                  }
                }
              }
            ]}
            sx={{ gridArea: 'validity' }}
          />
        )}
      </CardContent>

      <CardDivided
        isShown={isDividedShown}
        isList={isList}
      >
        {isEditShown && (
          isLoading
            ? (
              <Skeleton
                variant="rounded"
                sx={{
                  width: '40px',
                  height: '28px'
                }}
              />
            )
            : (
              <Button
                size="small"
                sx={{
                  color: 'common.white',
                  fontSize: '20px',
                  minWidth: 'unset',
                  height: 'fit-content'
                }}
                onClick={onEditClick}
              >
                <EditOutlined color="inherit" fontSize="inherit" />
              </Button>
            )
        )}

        {isDeleteShown && (
          isLoading
            ? (
              <Skeleton
                variant="rounded"
                sx={{
                  width: '40px',
                  height: '28px'
                }}
              />
            )
            : (
              <Button
                size="small"
                sx={{
                  color: 'common.white',
                  fontSize: '20px',
                  minWidth: 'unset',
                  height: 'fit-content'
                }}
                onClick={onDeleteClick}
              >
                <DeleteOutlined color="inherit" fontSize="inherit" />
              </Button>
            )
        )}

        {isExpandShown && (
          <ExpandBtn
            isLoading={isLoading}
            isExpanded={isExpanded}
            onClick={onExpandClick}
            sx={{ marginLeft: 'auto' }}
          />
        )}
      </CardDivided>
    </GenericCard>
  )
}

RoleCard.propTypes = {
  /** the entire object provided by the api */
  data: PropTypes.oneOfType([
    PropTypes.shape({
      validFrom: PropTypes.string,
      validTo: PropTypes.string,
      customScope: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      }),
      orgScope: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      roleDefinition: PropTypes.shape({
        customScopes: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string
          })
        ),
        defaultValidityType: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string,
        isDynamic: PropTypes.bool,
        isJobTitle: PropTypes.bool,
        isSelfRequestable: PropTypes.bool,
        isWorkflowBased: PropTypes.bool,
        name: PropTypes.string,
        needsAdditionalSelfRequestApproval: PropTypes.bool,
        needsCustomScopes: PropTypes.bool,
        needsOrgScopes: PropTypes.bool
      })
    }),
    PropTypes.shape({
      customScopes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string
        })
      ),
      defaultValidityType: PropTypes.string,
      defaultValidityDays: PropTypes.number,
      deprovisioning: PropTypes.bool,
      deprovisioningDeptIdDays: PropTypes.number,
      roleType: PropTypes.string,
      dataClassification: PropTypes.string,
      uuid: PropTypes.string,
      isGlobalCentralAvailable: PropTypes.bool,
      assignmentMessage: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      isDynamic: PropTypes.bool,
      isJobTitle: PropTypes.bool,
      isSelfRequestable: PropTypes.bool,
      isWorkflowBased: PropTypes.bool,
      name: PropTypes.string,
      needsAdditionalSelfRequestApproval: PropTypes.bool,
      needsCustomScopes: PropTypes.bool,
      needsOrgScopes: PropTypes.bool,
      accessReview: PropTypes.object
    })
  ]),
  /** Variant of the card */
  variant: PropTypes.oneOf([ 'grid', 'list' ]),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'm', 'l' ]),
  /** if true, DetailsList is shown (scopes, validity) */
  isDetailsShown: PropTypes.bool,
  /** Specifies if the card is expanded or not */
  isExpanded: PropTypes.bool,
  /** Callback function when delete button on the card is clicked */
  onDeleteClick: PropTypes.func,
  /** Callback function when expand button on the card is clicked */
  onExpandClick: PropTypes.func,
  /** Callback function when edit button on the card is clicked */
  onEditClick: PropTypes.func,
  /** Determines if ID of card is a link or not */
  isIdLink: PropTypes.bool,
  /** If true, loading skeletons will be displayed */
  isLoading: PropTypes.bool,
  /** Defines whether the card is selected or not */
  isSelected: PropTypes.bool,
  /** Defines whether the card is disabled or not */
  isDisabled: PropTypes.bool,
  interaction: InteractionPropTypes,
  /**
   * Card event handler
   *
   * @param param { id, isSelected, data, e }
   * @returns {void}
   */
  onCardClick: PropTypes.func,
  sx: PropTypes.any
}
