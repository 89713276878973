import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SplitscreenSharpIcon from '@mui/icons-material/SplitscreenSharp'
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined'
import { CacheKey } from '../../../data'
import { GenericSearch } from '../../search'
import { MarketSearchGridViewRenderer } from './MarketSearchGridViewRenderer'

/**
 * **Market Component**
 *
 * The `MarketSearch` component is a reusable component that provides a search interface for markets.
 * It allows users to search for markets using a specified API endpoint and query string.
 *
 * **Important Note**
 *
 * This component is built upon the foundation of the GenericSearch component, thus inheriting all properties supported by GenericSearch.
 * For a comprehensive list of available properties, please refer to the [GenericSearch documentation](./?path=/docs/alice-ui-search-genericsearch--docs).
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 */
export const MarketSearch = forwardRef((
  {
    title,
    description,
    apiUrl = '/markets',
    search = 'large',
    views = [ 'grid', 'list' ],
    itemSize,
    isIdLink = true,
    isSortHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    preselectedRowIds = [],
    preselectedView,
    preselectedSearch,
    preselectedSort,
    preselectedFilter,
    preselectedRowsPerPage,
    preselectedSearchBehavior,
    preselectedSortBehavior,
    isCaching = false,
    cacheKey = CacheKey.marketSearchQuery,
    onGetResponseData = (data) => data.markets,
    onGetUniqueIdentifier = (data) => data.id || data.country,
    onCardClick,
    rendererProps = {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const createViewConfig = () => {
    const viewConfig = { views: { } }

    if (views.includes('grid')) {
      viewConfig.views.grid = {
        tooltip: t('marketSearch.view.grid'),
        icon: SplitscreenSharpIcon,
        renderer: MarketSearchGridViewRenderer
      }
    }

    if (views.includes('list')) {
      viewConfig.views.list = {
        tooltip: t('marketSearch.view.list'),
        icon: ViewDayOutlinedIcon,
        renderer: MarketSearchGridViewRenderer
      }
    }

    return viewConfig
  }

  const createSearchConfig = () => {
    if (search === 'none') return undefined

    return {
      type: search,
      label: t('marketSearch.search.label'),
      apiFilters: [ 'country', 'name' ],
      preselectedBehavior: preselectedSearchBehavior
    }
  }

  const createFilterConfig = () => undefined

  const createSortConfig = () => {
    if (isSortHidden) return undefined

    return {
      preselectedBehavior: preselectedSortBehavior,
      sorts: [
        {
          value: '',
          label: t('genericSearch.defaultSort')
        },
        {
          value: '+country',
          label: t('marketSearch.sort.+country')
        },
        {
          value: '-country',
          label: t('marketSearch.sort.-country')
        },
        {
          value: '+name',
          label: t('marketSearch.sort.+name')
        },
        {
          value: '-name',
          label: t('marketSearch.sort.-name')
        }
      ]
    }
  }

  return (
    <GenericSearch
      data-testid="MarketSearch"
      ref={ref}
      title={title}
      description={description}
      getUniqueIdentifier={onGetUniqueIdentifier}
      getResponseData={onGetResponseData}
      apiUrl={apiUrl}
      cacheKey={cacheKey}
      searchConfig={createSearchConfig()}
      viewConfig={createViewConfig()}
      filterConfig={createFilterConfig()}
      sortConfig={createSortConfig()}
      preselectedRowIds={preselectedRowIds}
      preselectedView={preselectedView}
      preselectedSearch={preselectedSearch}
      preselectedSort={preselectedSort}
      preselectedFilter={preselectedFilter}
      preselectedRowsPerPage={preselectedRowsPerPage}
      isCaching={isCaching}
      isTopPaginationHidden={isTopPaginationHidden}
      isBottomPaginationHidden={isBottomPaginationHidden}
      onItemClick={onCardClick}
      rendererProps={{
        isIdLink,
        size: itemSize,
        ...rendererProps
      }}
      {...otherProps}
    />
  )
})

MarketSearch.propTypes = {
  /** Adds a title */
  title: PropTypes.string,
  /** Adds a description under the title */
  description: PropTypes.string,
  /** Api-Endpoint to collect required data */
  apiUrl: PropTypes.string,
  /** Type of search. Can be 'none', 'small', 'large' */
  search: PropTypes.oneOf([ 'none', 'small', 'large' ]),
  /** Views that should be displayed. Valid views: 'grid', 'list', 'tiny' */
  views: PropTypes.arrayOf(PropTypes.string),
  /** size-variant for item renderer */
  itemSize: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** Determines whether the card ID is associated with a link */
  isIdLink: PropTypes.bool,
  /** Determines if sort is hidden or not */
  isSortHidden: PropTypes.bool,
  /** Determines if pagination on top of the grid is hidden or not */
  isTopPaginationHidden: PropTypes.bool,
  /** Determines if pagination on bottom of grid is hidden or not */
  isBottomPaginationHidden: PropTypes.bool,
  /** Preselect rows */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** Preselected view */
  preselectedView: PropTypes.string,
  /** Preselected search query */
  preselectedSearch: PropTypes.string,
  /** Preselected sort */
  preselectedSort: PropTypes.string,
  /** Preselected filter */
  preselectedFilter: PropTypes.string,
  /** Preselected rows per page */
  preselectedRowsPerPage: PropTypes.number,
  /** Behavior of the search when preselected */
  preselectedSearchBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the sort when preselected */
  preselectedSortBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Determines if caching is active or not */
  isCaching: PropTypes.bool,
  /** Custom caching key */
  cacheKey: PropTypes.string,
  /** Called by generic search to get data from api response. Could not be needed when using a custom endpoint. */
  onGetResponseData: PropTypes.func,
  /** Called by generic search to get id from a single data set */
  onGetUniqueIdentifier: PropTypes.func,
  /** Callback on card click */
  onCardClick: PropTypes.func,
  /** Reference with helper functions to control the search from outside */
  reference: PropTypes.any,
  /** props directly passed to item renderer */
  rendererProps: PropTypes.object
}
