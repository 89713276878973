import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CancelIcon from "@mui/icons-material/Cancel";

import { AliceIcon, AliceIconType } from "@alice/component_library";

import RoleRequestIcon from "@Icons/taskpage/rolerequest.svg";
import AssignTechnicalUser from "@Icons/taskpage/AssignTechnicalUser.svg";
import EntitlementIcon from "@Icons/taskpage/AssignEntitelemnet.svg";
import ApproveRoleDeprovisioning from "@Icons/taskpage/AprooveRoleDeprovisioning.svg";
import RoleKey from "@Icons/taskpage/rolekey.svg";
import AssignTechnicalUserLeft from "@Icons/taskpage/AssignTechnicalUserLeft.svg";
import EntitlementToRole from "@Icons/taskpage/EntitlementToRole.svg";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import Calender from "@Icons/taskpage/calender.svg";

import { formatDate } from "../../util";
import { CommonColumn, RequestId, StatusCell } from "./CustomColumns";

const column = (requestData, loggedUserId, t, filter, optionType) => {
  const {
    taskType,
    id,
    state,
    recipients,
    creationDate,
    affectedData,
    originator = {},
    aprooverId,
  } = requestData;
  const { role = {}, user = {}, entitlement = {}, homeOrg = {} } = affectedData;
  const { id: roleId } = role;
  const { id: userId, surname, givenname } = user;
  const { entitlementId } = entitlement;
  const { name: homeOrgName } = homeOrg;
  const {
    id: requesterUserId,
    surname: requesterSurname,
    givenname: requesterGivenname,
  } = originator;

  let approvedRecipientList = aprooverId
    ? [{ id: aprooverId, givenname: aprooverId[0], surname: aprooverId[1] }]
    : [];
  let status = "";
  let requestIcon = "";
  let requestId = "";
  let requestedId = "";
  let iconType = "";
  let recipientList = [];
  let connectionIcon = <ArrowRightIcon className="connection-icon" />;
  let requestedIcon = (
    <AliceIcon iconType={AliceIconType.USER_OUTLINE} size={18} />
  );

  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      iconType = <RoleRequestIcon />;
      requestIcon = <RoleKey />;
      requestId = roleId;
      requestedIcon = (
        <AliceIcon iconType={AliceIconType.USER_OUTLINE} size={18} />
      );
      requestedId = `${surname} ${givenname}`;

      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      iconType = <AssignTechnicalUser />;
      requestIcon = <AssignTechnicalUserLeft />;
      requestId = userId;
      requestedIcon = <AliceIcon iconType={AliceIconType.UIApps} size={18} />;
      requestedId = homeOrgName;
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      iconType = <EntitlementIcon />;
      requestIcon = <EntitlementToRole />;
      requestId = entitlementId;
      requestedIcon = <RoleKey />;
      requestedId = roleId;
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      iconType = <ApproveRoleDeprovisioning />;
      requestIcon = <RoleKey />;
      requestId = roleId;
      connectionIcon = (
        <CancelIcon className="connection-icon" sx={{ padding: "5px" }} />
      );
      requestedIcon = (
        <AliceIcon iconType={AliceIconType.USER_OUTLINE} size={18} />
      );
      requestedId = ` ${surname} ${givenname}`;
      break;
    default:
      iconType = <RoleRequestIcon />;
      break;
  }

  switch (state) {
    case "PENDING":
      status = "pending";
      recipientList = recipients;
      break;
    case "COMPLETED":
      status = "approved";
      recipientList = approvedRecipientList;

      break;
    case "REJECTED":
      status = "rejected";
      recipientList = approvedRecipientList;
      break;
    case "FAILED":
      status = "failed";
      recipientList = [];
      break;
    case "EXPIRED":
      status = "expired";
      recipientList = [];
      break;

    default:
      status = "pending";
      recipientList = [];
      break;
  }

  let requestIdColumn = (
    <RequestId
      icon={iconType}
      id={
        <Tooltip arrow placement="bottom-start" title={id} type="ellipsized">
          <Link
            to={`/access/requests/details?taskId=${id}&tasktype=${optionType}&filter=${
              filter === "" ? "All" : filter
            }`}
          >
            {id}
          </Link>
        </Tooltip>
      }
    />
  );

  let requesterColumn = (
    <>
      {requesterSurname && requesterGivenname && (
        <CommonColumn
          icon={<RequesterIcon />}
          data={
            <Tooltip
              arrow
              placement="bottom-start"
              title={`${requesterSurname} ${requesterGivenname}`}
              type="ellipsized"
            >
              {`${requesterSurname} ${requesterGivenname}`}
            </Tooltip>
          }
          highlight={requesterUserId === loggedUserId}
        />
      )}
    </>
  );

  let requestColumn = (
    <CommonColumn
      icon={requestIcon}
      data={
        <Tooltip
          arrow
          placement="bottom-start"
          title={requestId}
          type="ellipsized"
        >
          {requestId}
        </Tooltip>
      }
    />
  );
  let requestedColumn = (
    <div className="request-id">
      {connectionIcon}
      <CommonColumn
        icon={requestedIcon}
        data={
          <Tooltip
            arrow
            placement="bottom-start"
            title={requestedId}
            type="ellipsized"
          >
            {requestedId}
          </Tooltip>
        }
        highlight={userId === loggedUserId}
      />
    </div>
  );
  let requestedMobileColumn = (
    <CommonColumn
      icon={requestedIcon}
      data={
        <Tooltip
          arrow
          placement="bottom-start"
          title={requestedId}
          type="ellipsized"
        >
          {requestedId}
        </Tooltip>
      }
      highlight={userId === loggedUserId}
    />
  );
  let creationDateColumn = (
    <CommonColumn
      icon={<Calender />}
      data={
        <Tooltip
          arrow
          placement="bottom"
          title={`${t("request-date")} DD|MM|YYYY`}
          type="ellipsized"
        >
          {formatDate(new Date(creationDate))}
        </Tooltip>
      } // need to check for formatting the creation date
    />
  );
  let statusColumn = (
    <StatusCell
      state={status}
      recipientList={recipientList}
      title={t("recipients")}
    />
  );

  return {
    requestIdColumn,
    requesterColumn,
    requestColumn,
    requestedColumn,
    creationDateColumn,
    statusColumn,
    connectionIcon,
    requestedMobileColumn,
  };
};

export default column;
