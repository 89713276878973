import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloseButton, Skeleton } from "@alice/component_library";

import { SecondaryButton } from "./button";
import EntitlementsTable from "./EntitlementsTable";
import "../App.css";
import "../Icon.css";
import "./roleDetails.css";

const DetailContainer = styled(Typography)({
  fontSize: "20px",
  color: "#000000",
  fontFamily: "MB Corpo S Text",
  fontWeight: "700",
});

const LabelClass = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: "600",
  marginTop: "6px",
  marginBottom: "6px",
});

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "#444444",
  color: "#ffffff",
  marginRight: "16px",
  marginTop: "16px",
  [theme.breakpoints.down("xs")]: {
    backgroundColor: "#444444",
    color: "#ffffff",
    marginRight: "16px",
    marginTop: "8px",
  },
}));

export default function RoleTable(params) {
  const { setDetailsChange, roleData, isCloseICon = true, loading } = params;
  const { t } = useTranslation();
  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef) {
      gridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [gridRef]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} ref={gridRef}>
      {isCloseICon ? (
        <CloseButton
          onClick={() => setDetailsChange(params.id)}
          sx={{ float: "right" }}
        />
      ) : (
        ""
      )}

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="flex">
          <Grid item xs={10} sm={10} md={11} lg={11}>
            <DetailContainer>{t("role-details")}</DetailContainer>
          </Grid>
        </Grid>
        <Grid className="mb-20">
          <LabelClass
            style={{
              fontFamily: "MB Corpo S Text",
              fontSize: "18px",
              fontWeight: "700",
              color: "#333333",
              fontStyle: "normal",
              letterSpacing: "0px",
              textAlign: "left",
              lineHeight: "28px",
            }}
          >
            {t("role-id")}
          </LabelClass>
          <Typography variant="body2" style={{ lineBreak: "anywhere" }}>
            {loading ? (
              <Skeleton height="42px" variant="text" width="100%" />
            ) : (
              roleData?.id
            )}
          </Typography>
        </Grid>
        <Grid className="mb-20">
          <LabelClass
            style={{
              fontFamily: "MB Corpo S Text",
              fontSize: "18px",
              fontWeight: "700",
              color: "#333333",
              fontStyle: "normal",
              letterSpacing: "0px",
              textAlign: "left",
              lineHeight: "28px",
            }}
          >
            {t("description")}
          </LabelClass>
          <Typography variant="body2" className="word-wrap">
            {loading ? (
              <Skeleton height="42px" variant="text" width="100%" />
            ) : (
              <>
                {roleData?.description === "" ||
                roleData?.description === undefined
                  ? "-"
                  : roleData?.description}
              </>
            )}
          </Typography>
        </Grid>

        {(roleData?.isJobTitle ||
          roleData?.needsOrgScopes ||
          roleData?.needsCustomScopes ||
          roleData?.isDynamic ||
          roleData?.needsAdditionalSelfRequestApproval) && (
          <Grid className="mb-20">
            <LabelClass
              style={{
                fontFamily: "MB Corpo S Text",
                fontSize: "18px",
                fontWeight: "700",
                color: "#333333",
                fontStyle: "normal",
                letterSpacing: "0px",
                textAlign: "left",
                lineHeight: "28px",
              }}
            >
              {t("role-info")}
            </LabelClass>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              className="display-block"
            >
              {roleData?.isJobTitle && (
                <CustomChip size="medium" label={t("job-title")} />
              )}
              {roleData?.needsOrgScopes && (
                <CustomChip size="medium" label={t("org-scope")} />
              )}
              {roleData?.needsCustomScopes && (
                <CustomChip size="medium" label={t("custom-scope")} />
              )}
              {roleData?.isDynamic && (
                <CustomChip size="medium" label={t("dynamic-assignment")} />
              )}
              {roleData?.needsAdditionalSelfRequestApproval && (
                <CustomChip size="medium" label={t("add-approval")} />
              )}
            </Grid>
          </Grid>
        )}

        <EntitlementsTable search heading="entitlements" id={params.id} />
        <div className="closeDetails">
          <SecondaryButton
            label={t("close_details")}
            onButtonClick={() => setDetailsChange(params.id)}
          />
        </div>
      </Grid>
    </Grid>
  );
}
