import React, { useEffect, useState } from "react";
import { useTheme, useMediaQuery, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AliceIcon } from "@alice/component_library";
import { PrimaryButton } from "@components/button";
import { MyDetailsSkelton } from "@components/Skeleton/CommonSkeletons";

import { getProfileViewFields } from "@src/pages/profilePage/helper";
import LabelValue from "@components/LabelValue";
import {
  TabHeaderComponent,
  ItemContainerMyDetails,
} from "../../styledComponents";

const labelStyle = {
  opacity: 1,
  color: "rgba(118,118,118,1)",
  fontFamily: "MB Corpo S Text",
  fontSize: "14px",
  fontWeight: "400",
  fontStyle: "normal",
  letterSpacing: "0.2px",
  lineHeight: "20px",
};

const ValueStyle = {
  opacity: "1",
  color: "rgba(51,51,51,1)",
  fontFamily: "MB Corpo S Text",
  fontSize: "18px",
  fontWeight: "700",
  fontStyle: "normal",
  letterSpacing: "0px",
  textAlign: "left",
  whiteSpace: "pre-wrap",
};

const MyDetailsView = ({ editHandler }) => {
  const { t } = useTranslation();
  const [viewData, setViewData] = useState([]);

  const user = useSelector((state) => state.user.userProfile);
  const orgs = useSelector((state) => state.role.availableOrganizationInfo);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    const dataList = getProfileViewFields(user.data, orgs);
    setViewData(dataList);
  }, [user.data, orgs]);

  if (user.loading) return <MyDetailsSkelton />;

  return (
    <Grid container>
      <TabHeaderComponent container>
        <div>
          <PrimaryButton
            label={
              <>
                <AliceIcon iconType="PENCIL" size={12} />
                <span className="bold16"> {t("edit")}</span>
              </>
            }
            onButtonClick={() => editHandler()}
          />
        </div>
      </TabHeaderComponent>
      <ItemContainerMyDetails container spacing={1}>
        {viewData.map(({ value, label, jsxComponent }) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            sx={{ marginBottom: "14px" }}
          >
            <LabelValue
              label={t(label)}
              value={value === "" || value === undefined ? "-" : value}
              jsxComponent={jsxComponent}
              labelStyles={labelStyle}
              valueStyles={ValueStyle}
            />
          </Grid>
        ))}
      </ItemContainerMyDetails>
    </Grid>
  );
};

export default MyDetailsView;
