import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'
import { TasksService } from './TasksService'
import { WorkflowsService } from './WorkflowsService'

export class UsersService {
  static getAll = ({ ...args }) => AliceService.get(
    '/users',
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/users/${id}`,
    { ...args }
  )

  static updateById = ({
    id,
    user,
    ...args
  }) => AliceService.put(
    `/users/${id}`,
    mergeObjects(
      { body: user },
      { ...args }
    )
  )

  static resetPassword = ({
    id,
    ...args
  }) => AliceService.post(
    `users/${id}/resetpassword`,
    { ...args }
  )

  static getOrganizationById = ({
    id,
    organizationId,
    ...args
  }) => AliceService.get(
    `/users/${id}/organizations/${organizationId}`,
    { ...args }
  )

  static getSupervisorById = ({
    id,
    ...args
  }) => AliceService.get(
    `/users/${id}/supervisor`,
    { ...args }
  )

  static getAgreementsById = ({
    id,
    ...args
  }) => AliceService.get(
    `/users/${id}/agreements`,
    { ...args }
  )

  static acceptAgreementsById = ({
    id,
    acceptToken,
    ...args
  }) => AliceService.post(
    `/users/${id}/agreements`,
    mergeObjects(
      { body: { acceptToken } },
      { ...args }
    )
  )

  static getAllEntitlementsById = ({
    id,
    ...args
  }) => AliceService.get(
    `/users/${id}/entitlements`,
    { ...args }
  )

  static getAllManageAdminRoles = ({
    id,
    ...args
  }) => AliceService.get(
    `/users/${id}/manageadminroles`,
    { ...args }
  )

  static updatePrivilege = ({
    id,
    privilege,
    body,
    ...args
  }) => AliceService.put(
    `/users/${id}/privileges/${privilege}`,
    mergeObjects(
      { body },
      { ...args }
    )
  )

  static deletePrivilege = ({
    id,
    privilege,
    body,
    ...args
  }) => AliceService.delete(
    `/users/${id}/privileges/${privilege}`,
    mergeObjects(
      { body },
      { ...args }
    )
  )

  static updateDescriptionOfAdminCommunityScopes = ({
    id,
    body,
    ...args
  }) => AliceService.put(
    `/users/${id}/updateDescriptionOfAdminCommunityScopes`,
    mergeObjects(
      { body },
      { ...args }
    )
  )

  static getOpenTasksCount = async ({ id }) => {
    const getAllTasks = TasksService.getAll({
      filters: {
        'recipients.id': id,
        state: 'PENDING'
      },
      page: 0,
      pageSize: 1
    })

    const getAllWorkflows = WorkflowsService.getAll({
      filters: {
        'tasks.recipients.id': id,
        'tasks.taskData.state': 'WAITING',
        'workflowData.type': 'ROLE_APPROVAL_WORKFLOW'
      },
      page: 0,
      pageSize: 1
    })

    const allResponses = await AliceService.all([ getAllTasks, getAllWorkflows ])

    let countingTasksAndWorkflows = 0

    allResponses.forEach((response) => countingTasksAndWorkflows += response?.rels?.totalCount || 0)

    return countingTasksAndWorkflows
  }
}
