import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { GenericContextPopover } from '../../genericContextPopover'
import { UserList } from './UserList'

/**
 * Popover to display a list of users
 *
 * Integrated CLIB-Components
 *
 * - [GenericContextPopover](./?path=/docs/alice-ui-genericcontextpopover--docs)
 * - [UserList](./?path=/docs/alice-ui-userelements-userlist--docs)
 */
export const UserListPopover = ({
  users = [],
  title,
  placement,
  isLoading,
  sx = [],
  children
}) => {
  const isScrollable = users?.length > 3

  return (
    <GenericContextPopover
      context={(
        <Stack gap={2} sx={{ maxHeight: '500px', mr: -1 }}>
          {title && (<Typography variant="h5">{title}</Typography>)}

          <UserList
            users={users}
            isLoading={isLoading}
            sx={{
              maxHeight: '100%', overflowY: 'auto', pr: 1
            }}
          />
        </Stack>
      )}
      placement={placement}
      sx={sx}
    >
      {children}
    </GenericContextPopover>
  )
}

UserListPopover.propTypes = {
  /** List of users to be displayed */
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    departmentNumber: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string)
  })),
  /** Optional title for the popover */
  title: PropTypes.string,
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ])
}
