import React from "react";

const ScopeCardRemoveIcon = () => {
  return (
    <span>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        className="remove-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Close">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
              id="Group"
              transform="translate(3.012693, 3.004500)"
              fill-rule="nonzero"
            >
              <g id="IconBtn/Remove/normal">
                <path
                  d="M15.3533067,2.63175 C11.8394786,-0.878796406 6.14537112,-0.877022948 2.63373048,2.63571157 C-0.87791016,6.1484461 -0.87791016,11.8425539 2.63373048,15.3552884 C6.14537112,18.868023 11.8394786,18.8697964 15.3533067,15.35925 C18.8619571,11.8421848 18.8619571,6.14881523 15.3533067,2.63175 Z"
                  id="Shape-path"
                  fill="#C8C8C8"
                ></path>
                <path
                  d="M12.5190567,11.45475 C12.7848991,11.7508501 12.773215,12.2030255 12.4924352,12.4850009 C12.2116554,12.7669764 11.7595337,12.7805818 11.4623067,12.516 L9.12980671,10.1835 C9.05620657,10.1108739 8.93790685,10.1108739 8.86430671,10.1835 L6.53255671,12.516 C6.23647098,12.8016483 5.76739244,12.8016483 5.47130671,12.516 C5.33047393,12.3753238 5.25134172,12.1844319 5.25134172,11.985375 C5.25134172,11.7863181 5.33047393,11.5954262 5.47130671,11.45475 L7.80380671,9.123 C7.83902703,9.08795167 7.85882812,9.04031259 7.85882812,8.990625 C7.85882812,8.94093741 7.83902703,8.89329833 7.80380671,8.85825 L5.47130671,6.52575 C5.20227635,6.22990719 5.21266331,5.77502481 5.49491699,5.49177023 C5.77717068,5.20851564 6.23201344,5.19651856 6.52880671,5.4645 L8.86055671,7.797 C8.89573331,7.83226564 8.94349649,7.85208517 8.99330671,7.85208517 C9.04311693,7.85208517 9.09088011,7.83226564 9.12605671,7.797 L11.4585567,5.4645 C11.7516128,5.17165102 12.2265827,5.17181892 12.5194317,5.46487501 C12.8122807,5.7579311 12.8121128,6.23290101 12.5190567,6.52575 L10.1865567,8.85825 C10.1136097,8.93142558 10.1136097,9.04982442 10.1865567,9.123 L12.5190567,11.45475 Z"
                  id="Shape-path"
                  fill="#FFFFFF"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default ScopeCardRemoveIcon;
