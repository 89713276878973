import { Box, Snackbar } from '@mui/material'
import PropTypes from 'prop-types'
import { Notification } from './Notification'

export const NotificationStack = ({ notifications, onClose }) => {
  const hasNotification = !!notifications.length

  return (
    <Snackbar
      open={hasNotification}
      autoHideDuration={null}
      transitionDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      data-testid="NotificationStack"
    >
      {
        /**
         * Stack does not work here, because Snackbar uses a Grid as Ref internally.
         * For some reason, a Stack is not compatible with a Grid Ref.
         */
      }
      <Box
        sx={{
          display: 'flex', flexDirection: 'column', gap: 1
        }}
      >
        {notifications.map((notification) => (
          <Notification
            key={notification.key}
            notification={notification}
            onClose={onClose}
          />
        ))}
      </Box>
    </Snackbar>
  )
}

NotificationStack.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      severity: PropTypes.oneOf([ 'success', 'info', 'warning', 'error' ]),
      duration: PropTypes.number,
      title: PropTypes.string,
      message: PropTypes.string,
      children: PropTypes.node,
      variant: PropTypes.oneOf([ 'standard', 'outlined', 'filled' ]),
      key: PropTypes.any
    })
  ),
  onClose: PropTypes.func
}
