import { AliceService } from './AliceService'

export class AccessClientConfigurationsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/accessclientconfigurations',
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/accessclientconfigurations/${id}`,
    { ...args }
  )

  static create = ({ ...args }) => AliceService.post(
    '/accessclientconfigurations',
    { ...args }
  )

  static updateById = ({
    id,
    ...args
  }) => AliceService.put(
    `/accessclientconfigurations/${id}`,
    { ...args }
  )

  static deleteById = ({
    id,
    ...args
  }) => AliceService.delete(
    `/accessclientconfigurations/${id}`,
    { ...args }
  )
}
