import React from 'react'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import AddHomeIcon from '@mui/icons-material/AddHome'
import { AliceIcon, AliceIconType } from '../icon'

export const StepperInteraction = {
  NONE: 'none',
  CLICKABLE: 'clickable'
}

export const steps = [
  { title: 'Step 0' },
  { title: 'Step 1' },
  { title: 'Step 2' },
  { title: 'Step 3' },
  { title: 'Step 4' },
  { title: 'Step 5' }
]

export const stepsCustom = [
  { title: 'Step 0' },
  {
    title: 'Step 1',
    content: 'disabled',
    disabled: true
  },
  {
    title: 'Step 2',
    content: 'optional',
    optional: true
  },
  {
    title: 'Step 3',
    content: 'disabled',
    disabled: true
  },
  {
    title: 'Step 4',
    content: 'optional',
    optional: true
  },
  {
    title: 'Step 5'
    // content: 'disabled'
    // disabled: true
  }
]

export const stepsWithIcons = [
  {
    title: 'User',
    content: 'Alice User',
    iconType: 'USER_OUTLINE'
  },
  {
    title: 'Roles',
    content: 'Please select...',
    iconType: <AddHomeIcon />
  },
  {
    title: 'Additional Information',
    content: 'Do some optional Stuff',
    optional: true,
    iconType: <AcUnitIcon />
  },
  {
    title: 'Summary',
    content: 'Check final data',
    iconType: <AliceIcon iconType={AliceIconType.CAT_SITTING} />
  }
]
