import { CircularProgress, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../button'
import { Dialog } from '../../../dialog'
import { Stepper } from '../../../stepper'

export const AssignmentDialog = ({
  isOpen,
  isLoading,
  title,
  content = [],
  stepperConfig = [],
  disableNext = (activeStep) => {},
  disableAssign = () => {},
  onClose = () => {},
  onCancel = () => {},
  onAssign = () => {},
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ activeStep, setActiveStep ] = useState(0)

  const stepperRef = useRef(null)

  const handleBackStep = () => stepperRef.current.back()
  const handleNextStep = () => stepperRef.current.setCompleted()
  const handleStepperStepChange = (step) => setActiveStep(step)

  return (
    <Dialog
      data-testid="AssignmentDialog"
      open={isOpen}
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      headerSection={<Typography variant="h3" component="div">{title}</Typography>}
      footerSection={(
        <Stack direction="row" spacing={1}>
          <Button
            data-testid="AssignmentDialog-cancel"
            onClick={onCancel}
            variant="secondary"
          >
            {t('general.cancel')}
          </Button>

          {stepperConfig.length > 1 && (
            <Button
              data-testid="AssignmentDialog-back"
              onClick={handleBackStep}
              disabled={activeStep <= 0}
            >
              {t('general.back')}
            </Button>
          )}

          {activeStep < stepperConfig.length - 1 ? (
            <Button
              data-testid="AssignmentDialog-next"
              onClick={handleNextStep}
              disabled={disableNext(activeStep)}
            >
              {t('general.next')}
            </Button>
          ) : (
            <Button
              data-testid="AssignmentDialog-assign"
              onClick={onAssign}
              disabled={disableAssign() || isLoading}
            >
              {isLoading ? (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CircularProgress size={16} />
                  <Typography>{t('general.loading')}</Typography>
                </Stack>
              ) : (
                <>{t('general.assign')}</>
              )}
            </Button>
          )}
        </Stack>
      )}
      {...otherProps}
    >
      <Stack spacing={4}>
        <Stepper
          ref={stepperRef}
          steps={stepperConfig}
          onActiveStep={handleStepperStepChange}
          sx={{ marginTop: 2 }}
        />

        <Stack spacing={2}>
          <Typography variant="h4">
            {content[activeStep].title}
          </Typography>

          {content[activeStep].render}
        </Stack>
      </Stack>
    </Dialog>
  )
}

AssignmentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node
  })).isRequired,
  stepperConfig: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node
  })).isRequired,
  disableNext: PropTypes.func,
  disableAssign: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onAssign: PropTypes.func
}
