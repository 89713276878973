import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import rootReducer from "./slice";
import { getEnvironment } from "@alice/component_library";

const isDev = getEnvironment() === "local";
const devToolEnhancer =
  isDev &&
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = configureStore({
  reducer: rootReducer,
  devTools: devToolEnhancer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const useAppDispatch = () => useDispatch();

export default store;
