import { ModeEditOutlined, TodayOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DateUtils } from '../../util'

export const DatePickerDialogHeader = ({
  parentFrom,
  parentTo,
  isRange = false,
  isEditOpen = false,
  setIsEditOpen = (isOpen) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const currentDate = `${DateUtils.formatDate(new Date(), 'short')}`
  const startDate = () => (parentFrom ? `${DateUtils.formatDate(parentFrom, 'short')}` : '')
  const endDate = () => (parentTo ? `${DateUtils.formatDate(parentTo, 'short')}` : '')

  const handleEditClick = () => {
    if (!isEditOpen) {
      setIsEditOpen(true)
    } else {
      setIsEditOpen(false)
    }
  }

  const dateField = () => (
    startDate().length > 1
      ? isRange
        ? `${startDate()} - ${endDate()}`
        : startDate()
      : currentDate
  )

  return (
    <>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.6)'
        }}
      >
        {t('dialog.inputTitle')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          marginTop: 4
        }}
      >
        <Typography
          sx={{
            flex: 1,
            fontWeight: 400,
            fontSize: '22px'
          }}
        >
          {!isEditOpen
            ? dateField()
            : isRange
              ? t('dialog.inputRangeSubTitle')
              : t('dialog.inputSingleSubTitle')}
        </Typography>

        <IconButton onClick={handleEditClick}>
          {!isEditOpen
            ? <ModeEditOutlined color="disabled" />
            : <TodayOutlined color="disabled" />}
        </IconButton>
      </Box>
    </>
  )
}

DatePickerDialogHeader.propTypes = {
  /** determines start date for manual handling */
  parentFrom: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  /** determines end date for manual handling */
  parentTo: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  /** Single Date or Range Date Picker */
  isRange: PropTypes.bool,
  /** The isEditOpen state */
  isEditOpen: PropTypes.bool,
  /**
   * Sets the isEditOpen state
   *
   * @param isOpen
   * @returns {void}
   */
  setIsEditOpen: PropTypes.func
}
