import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";

import PropTypes from "prop-types";
import "../pages/profileOverview/profile.css";
import "../App.css";
import "../Icon.css";
import RoleDetailsOpen from "./rolesData";
import ApplicationDetailsOpen from "../pages/profileOverview/applicationDetails";
import { Inlay } from "@alice/component_library/";

function AddIn(props) {
  const gridRef = useRef(null);
  const { open, loading = false } = props;

  useEffect(() => {
    if (open && gridRef.current) {
      gridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [open]);

  return (
    <Inlay open={open} anchorElement={props.anchorEl}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="role-inlay-grid"
        ref={gridRef}
      >
        {props.isApplication ? (
          <ApplicationDetailsOpen
            width={window.screen.width}
            roleData={[]}
            setDetailsChange={props.onClose}
            applId={props.id}
            applications={props.selectedData}
            supportContact={props.contactData}
          />
        ) : (
          <RoleDetailsOpen
            width={window.screen.width}
            roleData={props.selectedData.roleDefinition}
            setDetailsChange={props.onClose}
            id={props.id}
            loading={loading}
          />
        )}
      </Grid>
    </Inlay>
  );
}

AddIn.propTypes = {
  position: PropTypes.string.isRequired,
  headline: PropTypes.string,
  onClose: PropTypes.func,
  offsetLeft: PropTypes.number,
};

export default AddIn;
