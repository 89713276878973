import React from "react";
import FirstTabComponent from "./FirstTabContent";
const Index = (props) => {
  return (
    <>
      <FirstTabComponent />
    </>
  );
};

Index.propTypes = {};

export default Index;
