import { EmailOutlined } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserCommunities } from '../../../util'
import { Card } from '../../card'
import { AliceIcon, AliceIconType, IconWrapper } from '../../icon'
import { Tooltip } from '../../message'
import { FocusChip } from '../../projectFocus'
import { UserAvatar } from '../UserAvatar'
import { UserContextPopover } from '../UserContext/UserContextPopover'

export const AdminContact = forwardRef((
  {
    userData,
    onClick = (userData) => {},
    userContext = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const completeName = `${userData.givenname} ${userData.surname}`
  const communities = getUserCommunities(userData?.communities)
  const communitiesTranslated = communities.map((community) => t(`userCommunity.${community}`))
  const userGroupType = userData.groupType
  const userId = String(userData.id)

  const Title = ({ sx, children }) => (
    <Typography
      sx={{
        marginTop: 2,
        color: 'grey.60',
        fontSize: 16,
        ...sx
      }}
    >
      {children}
    </Typography>
  )

  const Content = ({ sx, children }) => (
    <Typography
      sx={{
        fontSize: 18,
        fontWeight: 600,
        ...sx
      }}
    >
      {children}
    </Typography>
  )

  const Avatar = () => (
    <UserAvatar
      userId={userId}
      userName={completeName}
      groupType={userData.groupType}
      showGroupType
      showImage={false}
      size="s"
    />
  )

  const CardContent = ({ sx = {} }) => (
    <Stack
      direction="column"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        ...sx
      }}
    >
      <Stack direction="row" spacing={2} sx={{ overflow: 'hidden' }}>
        <Box>
          {userContext ? (
            <UserContextPopover id={userData.id}>
              <Avatar />
            </UserContextPopover>
          ) : (
            <Avatar />
          )}
        </Box>

        <Stack sx={{ overflowX: 'hidden' }}>
          <Stack direction="row" spacing={2}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                wordBreak: 'break-word'
              }}
            >
              {completeName}
            </Typography>

            {userGroupType && (
              <FocusChip showTooltip groupType={userGroupType} />
            )}
          </Stack>

          {userData.description && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{
                marginBottom: 2,
                marginTop: 2
              }}
            >
              <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                {userData.description}
              </Typography>
            </Stack>
          )}

          {userData.mailAddress && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: 2 }}
            >
              <Tooltip title={t('tooltip.clickToSendEmail')} placement="top">
                <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                  <Typography
                    component="a"
                    href={`mailto:${userData.mailAddress}`}
                    sx={{
                      color: 'grey.10', // '#fff'
                      textDecoration: 'none',
                      marginTop: '3px',
                      marginBottom: '3px',
                      display: 'flex'
                    }}
                  >
                    <IconWrapper size={20} color="grey.50"><EmailOutlined /></IconWrapper>

                    <Typography
                      as="span"
                      sx={{ marginLeft: '10px', lineHeight: '20px' }}
                    >
                      {userData.mailAddress}
                    </Typography>
                  </Typography>
                </Typography>
              </Tooltip>
            </Stack>
          )}

          {userData.mobileNumber && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: 2 }}
            >
              <Tooltip
                title={t('tooltip.clickToDialPhoneNumber')}
                placement="top"
              >
                <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                  <Typography
                    component="a"
                    href={`tel:${userData.mobileNumber}`}
                    sx={{
                      color: 'grey.10', // '#fff'
                      textDecoration: 'none',
                      marginTop: '3px',
                      marginBottom: '3px',
                      display: 'flex'
                    }}
                  >
                    <AliceIcon
                      iconType={AliceIconType.MOBILE_PHONE}
                      sx={({ palette }) => ({
                        fill: palette.grey[50],
                        marginLeft: '4px',
                        width: '20px',
                        height: '20px'
                      })}
                    />

                    <span
                      style={{
                        marginLeft: 10,
                        height: 20,
                        fontSize: 16
                      }}
                    >
                      {userData.mobileNumber}
                    </span>
                  </Typography>
                </Typography>
              </Tooltip>
            </Stack>
          )}

          {userData.id && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: 2 }}
            >
              <Tooltip
                title={t('tooltip.clickToCopyToClipboard')}
                placement="top"
              >
                <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                  <Button
                    sx={{
                      color: 'grey.10',
                      textDecoration: 'none',
                      backgroundColor: 'transparent',
                      fontWeight: 'normal',
                      marginTop: 0,
                      marginBottom: 0,
                      padding: 0,
                      display: 'flex',
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                    disableRipple
                    onClick={() => {
                      if (navigator && navigator.clipboard) {
                        navigator.clipboard.writeText(userData.id)
                      }
                    }}
                  >
                    <AliceIcon
                      iconType={AliceIconType.TECH_USER}
                      sx={({ palette }) => ({
                        fill: palette.grey[50],
                        marginLeft: '4px',
                        width: '20px',
                        height: '20px'
                      })}
                    />

                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ textOverflow: 'ellipsis', ml: '10px' }}
                    >
                      <Typography noWrap>{userData.id}</Typography>

                      {userData.departmentNumber && (
                        <Typography noWrap>
                          {`(${userData.departmentNumber})`}
                        </Typography>
                      )}
                    </Stack>
                  </Button>
                </Typography>
              </Tooltip>
            </Stack>
          )}

          {userData.url && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: 2 }}
            >
              <Tooltip
                title={t('tooltip.clickToCopyToClipboard')}
                placement="top"
              >
                <Typography
                  noWrap
                  sx={{ textOverflow: 'ellipsis', display: 'flex' }}
                >
                  <AliceIcon
                    iconType={AliceIconType.TECH_USER}
                    sx={({ palette }) => ({
                      fill: palette.grey[50],
                      marginLeft: '4px',
                      width: '20px',
                      height: '20px'
                    })}
                  />

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ textOverflow: 'ellipsis', ml: '10px' }}
                  >
                    <a href={userData.url} target="_blank" rel="noreferrer">
                      <Typography noWrap>{userData.url}</Typography>
                    </a>
                  </Stack>
                </Typography>
              </Tooltip>
            </Stack>
          )}

          {!!communitiesTranslated.length && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <div>
                <Title>{t('general.community')}</Title>
                <Content>{communitiesTranslated.join(', ')}</Content>
              </div>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )

  return (
    <Card
      data-testid="AdminContact"
      ref={ref}
      sx={sx}
      onClick={() => onClick(userData)}
      {...otherProps}
    >
      <CardContent sx={{ p: 2 }} />
    </Card>
  )
})

AdminContact.propTypes = {
  /** The user to display */
  userData: PropTypes.shape({
    givenname: PropTypes.string,
    surname: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string),
    groupType: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    mailAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    departmentNumber: PropTypes.string,
    url: PropTypes.string,
    market: PropTypes.string,
    applicationId: PropTypes.string
  }).isRequired,
  /**
   * Event that is triggered when a card is clicked
   *
   * @param userData
   * @returns {void}
   */
  onClick: PropTypes.func,
  /** Override the sx prop of the card component */
  sx: PropTypes.object
}
