export const OrgCardDataList = [
  {
    country: 'CH',
    orgForm: 'SUBLOCATION',
    orgGroupType: '1',
    validFromDate: '2018-09-10',
    communities: [
      'DEALER'
    ],
    externalId: 'GP00001740',
    gssnData: {
      updateTimestamp: '2020-07-01T03:36:38Z',
      allowFrom: '2018-09-10',
      adinDefId: {
        id: '01',
        name: 'Main_address'
      },
      brandCodes: [
        {
          id: 'FUSO',
          outletCode: '00101766',
          outletStatus: '99'
        },
        {
          id: 'MB',
          outletCode: '00101766',
          outletStatus: '99'
        }
      ],
      companyForm: {
        id: '0000000003',
        name: 'IndependentCompany'
      },
      companyId: 'GC00101681',
      countryDefId: '879',
      outletCode: '00101766',
      outletId: 'GS00101766',
      outletStatus: '99',
      street1: '234 N/A',
      street2: 'Mhoo 6, Khok Samran'
    },
    id: 'C-Outlet.GP00001740',
    isDaimler: false,
    isUserManagementDisabled: false,
    location: 'Khon Kean',
    name: 'Maxcrane Machinery Company Limited',
    postalCode: '40110',
    street: '234 N/A'
  },
  {
    validFromDate: '2006-08-23',
    preferredLanguage: 'de',
    externalId: 'XY03647662',
    gssnData: {
      updateTimestamp: '2020-01-14T14:30:00Z',
      allowFrom: '2006-08-23',
      adinDefId: {
        id: '01',
        name: 'Main_address'
      },
      brandCodes: [
        {
          id: 'MB',
          outletCode: '91258',
          outletStatus: '27'
        }
      ],
      companyForm: {
        id: '0000000001',
        name: 'Affiliate'
      },
      companyId: 'GC0012436',
      countryDefId: '200',
      languageCode: 'de',
      outletCode: '91258',
      outletId: 'GS0015695',
      outletStatus: '27',
      street1: '.'
    },
    id: 'C-Outlet.XY03647662',
    isDaimler: false,
    isUserManagementDisabled: false,
    location: 'Stuttgart',
    name: 'Daimler FleetBoard GmbH',
    postalCode: '70546',
    street: 'Muster Str'
  },
  {
    country: 'GB',
    validFromDate: '2002-02-02',
    orgForm: 'LEGALENTITY',
    // orgGroupType: '1',
    preferredLanguage: 'en',
    communities: [
      'CORPORATE_CUSTOMER'
    ],
    externalId: '7734',
    faxNumber: '+44 1604 230613',
    gssnData: {
      updateTimestamp: '2004-08-04T18:29:22Z',
      allowFrom: '2002-02-02',
      adinDefId: {
        id: '01',
        name: 'Main_address'
      },
      brandCodes: [
        {
          id: 'MB',
          outletCode: '563',
          outletStatus: '02'
        },
        {
          id: 'FUSO',
          outletCode: '563',
          outletStatus: '02'
        }
      ],
      companyForm: {
        id: '0000000003',
        name: 'IndependentCompany'
      },
      companyId: 'GC0000957',
      countryDefId: '537',
      languageCode: 'en',
      outletCode: '563',
      outletId: 'GS0001584',
      outletStatus: '99',
      street1: 'Bedford Road',
      url: 'http://www.arlington.uk.com/arlington/northampton/'
    },
    id: 'C-Outlet.7734',
    isActive: true,
    isDaimler: false,
    isUserManagementDisabled: false,
    location: 'Northampton',
    mailAddress: 'wayne.bunyan@pendragon.uk.com',
    name: 'Arlington Northampton',
    postalCode: 'NN1 5NS',
    street: 'Bedford Road',
    telephoneNumber: '+44 1604 250151'
  },
  {
    country: 'PH',
    orgForm: 'LEGALENTITY',
    orgGroupType: 'S',
    validFromDate: '2002-02-02',
    preferredLanguage: 'en',
    communities: [ 'CORPORATE_CUSTOMER' ],
    externalId: '24000',
    id: 'C-Outlet.24000',
    isActive: false,
    isDaimler: false,
    isUserManagementDisabled: false,
    location: 'Bacolod City',
    name: 'Pearson Specter Litt LLP',
    postalCode: '6100',
    street: 'Araneta St.Singcang'
  }
]

export const OrgCardData = {
  central: true,
  decentral: true,
  type: 'organizationDto',
  country: 'DE',
  orgGroupType: 'S',
  childIds: [],
  communities: [ 'DEALER' ],
  externalId: 'XY03671045',
  faxNumber: '+49 751 801 690',
  gssnData: {
    updateTimestamp: '2022-03-16T12:44:59Z',
    allowFrom: '2015-10-29',
    adinDefId: {
      id: '01',
      name: 'Main_address'
    },
    brandCodes: [
      {
        id: 'MB',
        outletCode: '24062',
        outletStatus: '02'
      }
    ],
    companyForm: {
      id: '0000000003',
      name: 'IndependentCompany'
    },
    companyId: 'GC0034922',
    countryDefId: '200',
    functionInfos: [
      {
        activity: {
          id: '21',
          name: 'EQV_Sales'
        },
        brandId: 'MB',
        productGroup: {
          id: '000001',
          name: 'Passenger_Car'
        }
      },
      {
        activity: {
          id: '405',
          name: 'DSC_Config_veh'
        },
        brandId: 'MB',
        productGroup: {
          id: '000001',
          name: 'Passenger_Car'
        }
      },
      {
        activity: {
          id: '120',
          name: 'Sales'
        },
        brandId: 'MB',
        productGroup: {
          id: '000001',
          name: 'Passenger_Car'
        }
      },
      {
        activity: {
          id: '863',
          name: 'V_Class_Sales'
        },
        brandId: 'MB',
        productGroup: {
          id: '000001',
          name: 'Passenger_Car'
        }
      },
      {
        activity: {
          id: '862',
          name: 'X_Class_Sales'
        },
        brandId: 'MB',
        productGroup: {
          id: '000001',
          name: 'Passenger_Car'
        }
      }
    ],
    languageCode: 'de',
    outletCode: '24062',
    outletId: 'GS0045691',
    outletStatus: '02',
    street1: 'Am Galgenbühl 3',
    url: 'https://www.mercedes-benz-riess.de'
  },
  id: 'D-Outlet.XY03671045',
  isActive: true,
  isDaimler: false,
  location: 'Isny',
  mailAddress: 'ravensburg@riess-gruppe.de',
  name: 'Autohaus Riess GmbH',
  orgAdminIds: [],
  orgForm: 'SUBLOCATION',
  parentId: 'D-Outlet.XY03669668',
  postalCode: '88316',
  street: 'Am Galgenbühl 3',
  telephoneNumber: '+49 751 801 0'
}
