import React from "react";
import {
  DateHeader,
  GroupItemContainer,
  GroupItemWrapper,
} from "../../../styledComponents";
import ActivityCard from "./ActivityCard";

const RequestTaskTemplate = ({ data, isRequest = false }) => {
  return (
    <GroupItemWrapper
      container
      xs={12}
      sm={12}
      lg={12}
      column={data.length > 1 ? "1fr 1fr" : "1fr"}
      isRequest={isRequest}
    >
      {data.map((group, index) => (
        <div>
          {group.map((item) => (
            <>
              <GroupItemContainer
                key={index}
                className="grouped-item-container"
                margin={item.value ? (data.length > 1 ? 0 : 8) : 0}
                padding={item.value ? (data.length > 1 ? 16 : 0) : 0}
              >
                {item.value && <DateHeader>{item.value}</DateHeader>}
                {!item.value && (
                  <ActivityCard
                    taskInfo={item}
                    id={item.id}
                    isRequest={isRequest}
                  />
                )}
              </GroupItemContainer>
            </>
          ))}
        </div>
      ))}
    </GroupItemWrapper>
  );
};

export default RequestTaskTemplate;
