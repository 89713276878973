import { AliceService } from './AliceService'

export class WorkflowsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/workflows',
    { ...args }
  )

  static getWorkflowDefinition = ({ id, ...args }) => AliceService.get(
    `/workflow-definitions/${id}`,
    { ...args }
  )
}
