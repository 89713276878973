import React from "react";
import RequestDetailsData from "./RequestDetailsData";

const RequestDetails = ({
  requestDetails,
  setIsDetailsOpen,
  windowSize,
  userId,
}) => {
  return (
    <RequestDetailsData
      taskInfo={requestDetails}
      onClose={() => {
        setIsDetailsOpen(false);
      }}
      windowSize={windowSize}
      userId={userId}
    />
  );
};

export default RequestDetails;
