import { Circle } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { Skeleton } from '../../skeleton'

export const StatusChipPlaceholder = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: '65px',
      height: '24px',
      borderRadius: '16px'
    }}
  />
)

/**
 * Status Chip using MuiChip<br>
 * https://mui.com/material-ui/api/chip/
 *
 * ## Description
 *
 * - The StatusChip component is a versatile element that can be used to represent Active, Inactive or Deleted status within application.
 * - The component's appearance may be customized using the `sx` prop to match extra design requirements.
 * - The `type` prop represents the intended status of a chip component accordingly.
 */
export const StatusChip = forwardRef((
  {
    type = 'inactive',
    isLoading = false,
    sx = {} || [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const typeConfig = {
    inactive: {
      color: 'red.95',
      colorIcon: 'error.main'
    },
    active: {
      color: 'green.95',
      colorIcon: 'success.main'
    },
    deleted: {
      color: 'red.95',
      colorIcon: 'error.main'
    }
  }

  return isLoading
    ? (<StatusChipPlaceholder />)
    : (
      <Chip
        data-testid="StatusChip"
        ref={ref}
        label={(
          <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
            {t(`status.${type.toUpperCase()}`)}
          </Typography>
        )}
        variant="outlined"
        size="small"
        icon={(
          <Box className="chipIconWrapper">
            <Circle color="inherit" fontSize="inherit" />
          </Box>
        )}
        sx={mergeSxProps(
          {
            gap: '2px',
            paddingInline: 1,
            backgroundColor: typeConfig[type].color,
            border: '1px solid',
            borderColor: typeConfig[type].colorIcon,
            '.MuiSvgIcon-root': {
              margin: 0, color: 'inherit', fontSize: '10px'
            },
            '.MuiChip-label': { padding: 0 },
            '.chipIconWrapper': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginInline: '0',
              fontSize: '10px',
              color: typeConfig[type].colorIcon
            }
          },
          sx
        )}
        {...otherProps}
      />
    )
})

StatusChip.propTypes = {
  /** Determines chip type and its appropriate styles */
  type: PropTypes.oneOf([ 'active', 'inactive', 'deleted' ]),
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ]),
  /** Loading state. If `true` Placeholder is shown */
  isLoading: PropTypes.bool
}
