import { useState } from "react";
import { DataClassificationChip } from "@alice/component_library";

const DataClassification = ({ type, variant, isMobile }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChipClick = (e) => {
    if (isMobile) {
      e.preventDefault();
      setIsExpanded((prev) => !prev);
    }
  };

  const handleBlur = () => {
    setIsExpanded(false);
  };

  const styles =
    isMobile && isExpanded === false
      ? { "&:hover": { ".MuiChip-label": { maxWidth: "0px !important" } } }
      : {
          "&:hover": {
            ".MuiChip-label": {
              maxWidth: "calc((var(--chipWidth) + 20) * 1px)",
            },
          },
        };

  return (
    <>
      <DataClassificationChip
        type={type}
        variant={variant}
        onClick={handleChipClick}
        onBlur={handleBlur}
        sx={styles}
      />
    </>
  );
};

export default DataClassification;
