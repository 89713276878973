import "../Icon.css";
import "../App.css";
import NoResultsIcon from "../pages/icons/noResultsIcon";
import { useTranslation } from "react-i18next";

const ErrorPage = (props) => {
  const { t } = useTranslation();
  let result = '"' + props.keysearch + '"';
  return (
    <>
      <div className="textcenter-search textError">
        <NoResultsIcon />
        <p className="noSearchText font-weigth-400">
          {t("your-search")}{" "}
          <span className="font-weigth-700 res-wrp">{result} </span>{" "}
          {t("did-not-match-any-records")}
        </p>

        <span className="noSearchSubText">
          {t("try-again-with-different-keywords")}
        </span>
      </div>
    </>
  );
};

export default ErrorPage;
