import { CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const LoadingBoundary = ({
  isLoading,
  fallback,
  sx,
  children
}) => {
  const { t } = useTranslation('componentLibrary')

  return isLoading
    ? (
      <Stack data-testid="LoadingBoundary" gap={2} height="100%" justifyContent="center" alignItems="center" sx={sx}>
        {fallback || (
          <>
            <CircularProgress />
            <Typography fontWeight="bold">{t('general.loading')}</Typography>
          </>
        )}
      </Stack>
    )
    : children
}
