import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./RoleCardV2.module.scss";
import {
  Card,
  DateUtils,
  IconButton,
  IconButtonText,
  OrgContextPopover,
  StateIcon,
  Tooltip,
} from "@alice/component_library";
import { useTranslation } from "react-i18next";
import { blue, green, grey, red, yellow } from "@mercedes-benz/mui5-theme";
import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import ReplayIcon from "@mui/icons-material/Replay";

const DATE_OPTIONS_SHORT = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

/**
 * Role Card V2 component
 */
export const RoleCardV2 = ({
  data,
  state = "none",
  size = "medium",
  hideActions = false,
  isOpen = false,
  isSelected = false,
  onSelect = (data, selected) => {},
  onReapply = (data) => {},
  onModify = (data) => {},
  onDelete = (data) => {},
  onExpand = (data, open) => {},
  daysLeftMessage = "",
  isFromSummarryPage = false,
  isNeedToCalculate = true,
  showModifyButton = false,
  width = "md",
  isDisplayValidity = true,
  showMore = false,
  handleExtendOrReaaplyLink = null,
}) => {
  const { t } = useTranslation("componentLibrary");
  const { t: trans } = useTranslation();

  const [validFromDate, setValidFromDate] = useState(
    isNeedToCalculate ? "-" : data?.displayValidFrom
  );
  const [validToDate, setValidToDate] = useState(
    isNeedToCalculate ? "-" : data?.displayValidTo
  );
  const [validToDaysLeft, setValidToDaysLeft] = useState(
    isNeedToCalculate ? null : data?.validToDaysLeft
  );
  const remainingDaysToWarning = 14;
  const remainingDaysToRed = 0;
  // validFrom, validTo, orgScope, customScope could be undefined
  const {
    validFrom,
    validTo,
    orgScope,
    customScope,
    roleDefinition,
    needsCustomScopes = false,
    needsOrgScopes = false,
  } = useMemo(() => data, [data]);

  useEffect(() => {
    if (!data) return;

    const dateLocals = "en-GB";
    const dateOptions = size === DATE_OPTIONS_SHORT;
    if (isNeedToCalculate) {
      setValidFromDate(
        validFrom
          ? new Date(Date.parse(validFrom)).toLocaleDateString(
              dateLocals,
              dateOptions
            )
          : "-"
      );

      if (validTo) {
        const today = isFromSummarryPage
          ? new Date(Date.parse(data?.validFrom))
          : new Date(Date.now());
        const validToParsed = new Date(Date.parse(validTo));
        const daysBetween = DateUtils.daysBetweenDates(today, validToParsed);

        setValidToDate(
          validToParsed.toLocaleDateString(dateLocals, dateOptions)
        );
        setValidToDaysLeft(daysBetween);
      } else {
        setValidToDate("-");
        setValidToDaysLeft(null);
      }
    }
  }, [data, validFrom, validTo, isFromSummarryPage, size, isNeedToCalculate]);

  const getBackgroundColor = () => {
    switch (state) {
      case "none":
        return grey["95"];
      case "success":
        return green["95"];
      case "info":
        return blue["95"];
      case "warning":
        return yellow["95"];
      case "error":
        return red["95"];
      default:
        return grey["95"];
    }
  };

  const getChipBackgroundColor = () => {
    if (validToDaysLeft === null) return "transparent";
    if (validToDaysLeft <= remainingDaysToRed) return red["80"];
    if (validToDaysLeft <= remainingDaysToWarning) return yellow["80"];
    return "transparent";
  };

  const getSize = () => {
    switch (size) {
      case "small":
        return styles.small;
      case "large":
        return styles.large;
      case "medium":
      default:
        return styles.medium;
    }
  };

  const getHiddenAction = () => (hideActions ? styles.hiddenAction : "");
  const getOpen = () => (isOpen ? styles.open : "");

  const handleCheckboxClick = () => onSelect(data, !isSelected);
  const handleReapplyClick = () => onReapply(data);
  const handleModifyClick = () => onModify(data);
  const handleDeleteClick = () => onDelete(data);
  const handleExpandClick = () => onExpand(data, !isOpen);

  return (
    <Card
      sx={{ backgroundColor: getBackgroundColor() }}
      className={`${
        styles.roleCardV2
      } ${getSize()} ${getHiddenAction()} ${getOpen()}`}
    >
      <Stack
        className={styles.roleCardV2Layout}
        direction={"row"}
        divider={
          !hideActions ? <Divider flexItem orientation="vertical" /> : null
        }
      >
        <div className={`${styles.roleCardV2Container}`}>
          <div className={styles.roleCardV2NameArea}>
            {hideActions && state !== "none" && <StateIcon state={state} />}

            {!hideActions && (
              <Checkbox
                onClick={handleCheckboxClick}
                checked={isSelected}
                sx={{
                  padding: 0,
                  margin: 0,
                }}
              />
            )}

            <div className={`${styles.roleCardV2Header}`}>
              <Tooltip
                placement="top"
                title={roleDefinition?.name}
                type="ellipsized"
              >
                <div className={`${styles.roleCardV2HeaderName}`}>
                  {roleDefinition?.name}
                </div>
              </Tooltip>
              <div
                className={`${styles.roleCardV2HeaderID}`}
                style={{ color: grey[60] }}
              >
                {roleDefinition?.id}
              </div>
            </div>
          </div>

          {!hideActions && (
            <Box className={`${styles.roleCardV2ActionArea}`}>
              {/* Using IconButtonText because otherwise the padding is off. */}
              {handleExtendOrReaaplyLink ? (
                <Link
                  to={`${handleExtendOrReaaplyLink}&mode=${
                    validToDaysLeft < 0 ? "reapply" : "extend"
                  }`}
                >
                  <IconButtonText
                    icon={
                      validToDaysLeft ? <ReplayIcon /> : <EditOutlinedIcon />
                    }
                    text={
                      size === "small"
                        ? ""
                        : validToDaysLeft < 0
                        ? t("roleElements.reapply")
                        : t("roleElements.modify")
                    }
                    color={blue["50"]}
                  />
                </Link>
              ) : (
                <IconButtonText
                  onClick={
                    validToDaysLeft < 0 ? handleReapplyClick : handleModifyClick
                  }
                  icon={validToDaysLeft ? <ReplayIcon /> : <EditOutlinedIcon />}
                  text={
                    size === "small"
                      ? ""
                      : validToDaysLeft < 0
                      ? t("roleElements.reapply")
                      : t("roleElements.modify")
                  }
                  color={blue["50"]}
                />
              )}
            </Box>
          )}

          <div className={`${styles.roleCardV2ContentArea}`}>
            {/* OrgScope */}
            <div className={styles.roleCardV2Scope}>
              <CorporateFareOutlinedIcon fontSize={"small"} />

              {size !== "small" && (
                <div className={styles.roleCardV2ScopeText}>
                  {t("roleElements.orgScope")}
                </div>
              )}
            </div>

            <div className={styles.roleCardV2ScopeName}>
              <div className={styles.roleCardV2ScopeNameText}>
                {needsOrgScopes === true
                  ? orgScope?.name ?? (
                      <Typography
                        style={{
                          color: "goldenrod",
                          fontSize: "14px",
                          fontWeight: "700",
                        }}
                      >
                        {trans("missing-info")}
                      </Typography>
                    )
                  : orgScope?.name ?? "-"}
              </div>

              {orgScope?.name && (
                <Box sx={{ marginBottom: "-6px" }}>
                  <OrgContextPopover id={orgScope?.id}>
                    <InfoOutlinedIcon
                      sx={{ color: blue["50"] }}
                      fontSize={"small"}
                    />
                  </OrgContextPopover>
                </Box>
              )}
            </div>

            {/* CustomScope */}
            <div className={styles.roleCardV2Scope}>
              <AccountTreeOutlinedIcon fontSize={"small"} />

              {size !== "small" && (
                <div className={styles.roleCardV2ScopeText}>
                  {t("roleElements.customScope")}
                </div>
              )}
            </div>

            <div className={styles.roleCardV2ScopeName}>
              <div className={styles.roleCardV2ScopeNameText}>
                {needsCustomScopes === true
                  ? customScope?.name ?? (
                      <Typography
                        style={{
                          color: "goldenrod",
                          fontSize: "14px",
                          fontWeight: "700",
                        }}
                      >
                        {trans("missing-info")}
                      </Typography>
                    )
                  : customScope?.name ?? "-"}
              </div>
            </div>

            {/* Validity */}

            <div
              className={
                styles.roleCardV2Scope + " " + styles.roleCardV2ScopeValidity
              }
            >
              <CalendarMonthOutlinedIcon fontSize={"small"} />

              {size !== "small" && (
                <div className={styles.roleCardV2ScopeText}>
                  {t("roleElements.validity")}
                </div>
              )}
            </div>

            <Stack
              direction={"row"}
              spacing={2}
              className={styles.roleCardV2Scope}
              flexWrap="wrap"
              useFlexGap
            >
              {/* Valid From */}
              <div className={styles.roleCardV2ValidFrom}>
                <div className={styles.roleCardV2ValidFromText}>
                  {t("roleElements.validFrom")}
                </div>

                <div className={styles.roleCardV2ValidFromDate}>
                  {validFromDate}
                </div>
              </div>

              {/* Valid To */}
              <div className={styles.roleCardV2ValidTo}>
                <div className={styles.roleCardV2ValidToText}>
                  {t("roleElements.validTo")}
                </div>

                <div
                  style={{
                    backgroundColor: !isDisplayValidity
                      ? "transparent"
                      : getChipBackgroundColor(),
                  }}
                  className={`${
                    getChipBackgroundColor() === "transparent" ||
                    !isDisplayValidity
                      ? styles.roleCardV2Card
                      : styles.roleCardV2CardNotTransparent
                  }`}
                >
                  {validToDate === "-" || !isDisplayValidity ? (
                    <div className={styles.roleCardV2ValidToDate}>
                      {validToDate}
                    </div>
                  ) : (
                    <>
                      <div className={styles.roleCardV2ValidToDate}>
                        {validToDate}
                      </div>
                      {validToDate !== "_" ? (
                        <>
                          {" "}
                          <div className={styles.roleCardV2CardDivider} />
                          <div className={styles.roleCardV2CardDaysLeft}>
                            <TimerOutlinedIcon fontSize={"small"} />

                            <div>
                              {`${validToDaysLeft >= 0 ? validToDaysLeft : 0}${
                                daysLeftMessage.length
                                  ? daysLeftMessage
                                  : size === "small" &&
                                    width !== "md" &&
                                    width !== "sm" &&
                                    width !== "xs" &&
                                    width !== "lg"
                                  ? t("roleElements.daysLeftShort")
                                  : " " + t("roleElements.daysLeft")
                              }`}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Stack>
          </div>
        </div>
        {showModifyButton && (
          <div className={`${styles.roleCardV2SidebarContainer} `}>
            <div className={styles.roleCardV2DeleteButton}>
              <Stack sx={{ pr: 3, mt: 1 }}>
                {handleExtendOrReaaplyLink ? (
                  <Link to={handleExtendOrReaaplyLink}>
                    <IconButtonText
                      icon={<EditOutlinedIcon />}
                      text={size === "small" ? "" : t("roleElements.modify")}
                      color={blue["50"]}
                    />
                  </Link>
                ) : (
                  <IconButtonText
                    onClick={handleModifyClick}
                    icon={<EditOutlinedIcon />}
                    text={size === "small" ? "" : t("roleElements.modify")}
                    color={blue["50"]}
                  />
                )}
              </Stack>
            </div>
          </div>
        )}
        {!hideActions && (
          <div className={styles.roleCardV2SidebarContainer}>
            <div className={styles.roleCardV2DeleteButton}>
              <IconButton onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </div>

            <div className={`${styles.roleCardV2OpenButton}`}>
              <IconButton onClick={handleExpandClick}>
                <ExpandMoreOutlinedIcon sx={{ color: "black" }} />
              </IconButton>
            </div>
          </div>
        )}
        {showMore && (
          <div className={`${styles.roleCardV2OpenButton}`}>
            <IconButton onClick={handleExpandClick}>
              <ExpandMoreOutlinedIcon sx={{ color: "black" }} />
            </IconButton>
          </div>
        )}
      </Stack>
    </Card>
  );
};

RoleCardV2.propTypes = {
  /** the entire object provided by the api */
  data: PropTypes.shape({
    validFrom: PropTypes.string,
    validTo: PropTypes.string,
    customScope: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    orgScope: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    roleDefinition: PropTypes.shape({
      customScopes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      defaultValidityType: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      isDynamic: PropTypes.bool,
      isJobTitle: PropTypes.bool,
      isSelfRequestable: PropTypes.bool,
      isWorkflowBased: PropTypes.bool,
      name: PropTypes.string,
      needsAdditionalSelfRequestApproval: PropTypes.bool,
      needsCustomScopes: PropTypes.bool,
      needsOrgScopes: PropTypes.bool,
    }),
  }),
  state: PropTypes.oneOf(["success", "info", "warning", "error", "none"]),
  /** size of the role card */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**  hides actions, if you wanna just display the role by itself */
  hideActions: PropTypes.bool,
  /**  defines, if the role card is open or not */
  isOpen: PropTypes.bool,
  /**  defines, if the role card is selected or not */
  isSelected: PropTypes.bool,
  /**  called when the role card got selected */
  onSelect: PropTypes.func,
  /**  called when user clicked on reapply button */
  onReapply: PropTypes.func,
  /**  called when user clicked on modify button */
  onModify: PropTypes.func,
  /**  called when user clicked on delete button */
  onDelete: PropTypes.func,
  /**  called when user clicked on expand button */
  onExpand: PropTypes.func,
};
