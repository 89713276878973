import { userActionTypes, costActionTypes } from "@constants/actionTypes";
const initialState = {
  userData: [],
  selectedUserData: {},
  authData: [],
  userProfile: { loading: false, data: {} },
  contactData: [],
  loggedInUserData: {},
  limit: 0,
  totalCount: 0,
  stUser: "",
  agreementInfo: [],
  acceptToken: "",
  userById: { loading: false, data: {}, error: false },
  adminRoles: {},
};

const UserReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case userActionTypes.FETCH_LOGGEDIN_USER:
      return (state = {
        ...state,
        loggedInUserData: data,
      });
    case userActionTypes.USER_PROFILE_DATA:
      return (state = {
        ...state,
        userProfile: data,
      });
    case userActionTypes.GET_CONTACT_DATA:
      return (state = {
        ...state,
        contactData: data,
      });

    case userActionTypes.REMOVED_STORED_USERS_DATA:
      return (state = {
        ...state,
        userData: [],
      });

    case userActionTypes.STORE_SELECTED_USER:
      return (state = {
        ...state,
        selectedUserData: data ? data : {},
      });
    case costActionTypes.STORE_COST_DATA:
      return (state = {
        ...state,
        costData: data,
      });
    case userActionTypes.STORE_LOGGEDIN_USER:
      return (state = {
        ...state,
        stUser: data,
      });

    case userActionTypes.CLEAR_USER_INFO:
      return (state = {
        ...state,
        userData: [],
        selectedUserData: {},
      });
    case userActionTypes.UPDATE_WORKING_ORGINFO_OF_LOGGIN_USER:
      return (state = {
        ...state,
        loggedInUserData: data,
      });
    case userActionTypes.FETCH_ALL_AGREEMENTS:
      // for (const element of data.agreements) {
      //     element.isAccepted = false;
      // }
      return (state = {
        ...state,
        agreementInfo: data,
        acceptToken: data.acceptToken,
      });
    case userActionTypes.FETCH_USER_BY_ID:
      return (state = {
        ...state,
        userById: data,
      });
    case userActionTypes.USER_ADMIN_ROLES:
      return (state = {
        ...state,
        adminRoles: data,
      });

    default:
      return state;
  }
};
export default UserReducer;
