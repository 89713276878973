import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deepEqual } from '../../../util'
import { Button } from '../../button'
import { Dialog } from '../../dialog'
import { PeoplePicker } from './PeoplePicker'

export const PeoplePickerDialog = ({
  title,
  isOpen,
  isUserShown = true,
  isUserGroupShown = false,
  preselectedUsers,
  preselectedUserGroups,
  otherUserSearchProps = {},
  otherUserGroupSearchProps = {},
  onSave = ({
    userIds,
    users,
    userGroupIds,
    userGroups
  }) => {},
  onCancel = () => {},
  onChange = ({
    isSelected,
    userId,
    userGroupId,
    user,
    userGroup,
    selectedUsers,
    selectedUserGroups
  }) => {},
  isSaveDisabled = false,
  isLoading = false,
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ originalSelectedUsers, setOriginalSelectedUsers ] = useState(preselectedUsers || [])
  const [ originalSelectedUserGroups, setOriginalSelectedUserGroups ] = useState(preselectedUserGroups || [])
  const [ selectedUsers, setSelectedUsers ] = useState(preselectedUsers || [])
  const [ selectedUserGroups, setSelectedUserGroups ] = useState(preselectedUserGroups || [])

  const hasChanges = useMemo(() => {
    if (
      originalSelectedUsers.length !== selectedUsers.length
      || originalSelectedUserGroups.length !== selectedUserGroups.length
    ) return true

    return !deepEqual(
      originalSelectedUsers.sort((a, b) => a.id - b.id),
      selectedUsers.sort((a, b) => a.id - b.id)
    ) || !deepEqual(
      originalSelectedUserGroups.sort((a, b) => a.uuid - b.uuid),
      selectedUserGroups.sort((a, b) => a.uuid - b.uuid)
    )
  }, [ originalSelectedUserGroups, originalSelectedUsers, selectedUserGroups, selectedUsers ])

  useEffect(() => {
    setOriginalSelectedUsers(preselectedUsers || [])
    setSelectedUsers(preselectedUsers || [])
  }, [ preselectedUsers ])

  useEffect(() => {
    setOriginalSelectedUserGroups(preselectedUserGroups || [])
    setSelectedUserGroups(preselectedUserGroups || [])
  }, [ preselectedUserGroups ])

  const handleChange = (data) => {
    setSelectedUsers(data?.selectedUsers)
    setSelectedUserGroups(data?.selectedUserGroups)
    onChange(data)
  }

  const handleSave = () => {
    const users = selectedUsers || []
    const userGroups = selectedUserGroups || []

    setOriginalSelectedUsers(users)
    setOriginalSelectedUserGroups(userGroups)

    onSave({
      userIds: users.map((user) => user.id),
      users,
      userGroupIds: userGroups.map((userGroup) => userGroup.uuid),
      userGroups
    })
  }

  const handleClose = () => {
    setSelectedUsers(originalSelectedUsers)
    setSelectedUserGroups(originalSelectedUserGroups)
    onCancel()
  }

  return (
    <Dialog
      data-testid="PeoplePickerDialog"
      open={isOpen}
      fullWidth
      maxWidth="lg"
      headerSection={title || t('peoplePicker.dialogTitle')}
      footerSection={(
        <Stack direction="row" gap={2}>
          <Button variant="secondary" onClick={handleClose}>{t('peoplePicker.dialogCancel')}</Button>

          <Button
            isLoading={isLoading}
            disabled={isSaveDisabled || !hasChanges}
            onClick={handleSave}
          >
            {t('peoplePicker.dialogSave')}
          </Button>
        </Stack>
      )}
      onClose={handleClose}
    >
      <PeoplePicker
        preselectedUsers={selectedUsers}
        preselectedUserGroups={selectedUserGroups}
        isUserShown={isUserShown}
        isUserGroupShown={isUserGroupShown}
        otherUserSearchProps={otherUserSearchProps}
        otherUserGroupSearchProps={otherUserGroupSearchProps}
        onChange={handleChange}
        {...otherProps}
      />
    </Dialog>
  )
}

PeoplePickerDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  /** display User Search and Accordion, or not */
  isUserShown: PropTypes.bool,
  /** display UserGroup Search and Accordion, or not */
  isUserGroupShown: PropTypes.bool,
  preselectedUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    departmentNumber: PropTypes.string
  })),
  preselectedUserGroups: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  /** Custom UserSearch Props */
  otherUserSearchProps: PropTypes.shape({ disabledIds: PropTypes.arrayOf(PropTypes.string) }),
  /** Custom UserGroupSearch Props */
  otherUserGroupSearchProps: PropTypes.shape({ disabledIds: PropTypes.arrayOf(PropTypes.string) }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  /**
   * Callback when data changes.
   *
   * Note that this function will exclusively utilize the selectedUsers parameter upon clicking ``selectAll``.
   * Consequently, it's important to verify the presence of other parameters before utilizing them.
   * Parameters such as ``userId``, ``isSelected``, and ``user`` are only accessible when the user interacts with the card.
   */
  onChange: PropTypes.func,
  /** determines if the save button is disabled */
  isSaveDisabled: PropTypes.bool,
  /** determines the loading state for save button */
  isLoading: PropTypes.bool
}
