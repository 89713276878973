import React, { useState, memo, useEffect } from "react";
import { Dialog, DialogTitle, DialogActions, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, InputLabel } from "@mui/material";
import { Select } from "@alice/component_library";
import { CloseButton } from "@alice/component_library";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { areArraysSameStructure } from "@src/utils/arrayUtils";
import { getUUID } from "@utils";
import PreviousOrgScopeSearch from "./previousOrdScopeOrgSearch";

const DialogHeader = styled("p")({
  fontSize: "20px",
  fontFamily: "MB CORPO S TEXT",
  fontWeight: 700,
  marginBottom: 0,
});

const SelectOrganizationCheckContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const SpanClassCheck = styled("span")({
  fontFamily: "MB Corpo S Text",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.6)",
  marginBottom: "10px",
});

const getDataByID = (structure, id) => {
  let filteredData = [];

  structure.forEach((item) => {
    if (item.id === id) {
      filteredData = item.selectedScopes;
    }
  });

  return filteredData;
};

const PreviousOrgDialog = memo(
  (props) => {
    const {
      dialog,
      handleDialogClose,
      previousSelectedOrgScope,
      roleId,
      checkIsSelected,
      selectedFullScopes,
      selectedCurrentOrg,
      addSelectedSCope,
    } = props;

    const { t } = useTranslation();
    const [selectedrole, setSelectedRole] = useState("");
    const [selectedRoleLabel, setSelectedRoleLabel] = useState("");
    const [selectedTableInfo, setSelectedTableInfo] = useState([]);
    const [selectedScopes, setSelectedScopes] = useState(
      selectedFullScopes ?? []
    );
    const [options, setOptions] = useState([]);

    const handleChange = (e) => {
      const selectedTableData = getDataByID(
        previousSelectedOrgScope,
        e.target.value
      );
      setSelectedTableInfo(selectedTableData);
      setSelectedRole(e.target.value);
    };

    const handleItemClick = (event) => {
      setSelectedRoleLabel(event.target.textContent);
    };

    const setOrgArrayFromprev = (e) => {
      setSelectedScopes(e);
    };

    const addScopeInfo = () => {
      addSelectedSCope(selectedScopes);
      handleDialogClose();
    };

    useEffect(() => {
      const selectOPtions = previousSelectedOrgScope.map((data) => ({
        value: data.id,
        label: data.name,
        name: data.name,
      }));
      setOptions(selectOPtions);
      setSelectedTableInfo([]);
      setSelectedRoleLabel("");
    }, [roleId, previousSelectedOrgScope]);

    let dialogTitle = t("select_organisation");

    return (
      <Dialog
        open={dialog}
        fullWidth
        maxWidth="lg"
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <DialogHeader>{dialogTitle} </DialogHeader>

          <CloseButton
            onClick={handleDialogClose}
            sx={{ position: "absolute", top: "0", right: "0" }}
          />
        </DialogTitle>
        <FormControl
          sx={{ m: 1, minWidth: "90%", padding: "10px 15px !important" }}
        >
          <SelectOrganizationCheckContainer>
            <SpanClassCheck>{t("please-select-whose-org")}</SpanClassCheck>
            <FormControl sx={{ m: 1, minWidth: 120 }} variant="filled">
              <InputLabel id="demo-simple-select-helper-label">
                {t("select-role")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={selectedRoleLabel}
                label={t("select-role")}
                onChange={handleChange}
                renderValue={(value) => `${value}`}
              >
                <InputLabel value="">
                  <Typography sx={{ paddingLeft: "15px", fontSize: "15px" }}>
                    {t("user_pleaseSelect")}
                  </Typography>
                </InputLabel>
                {options.map(({ value, label }) =>
                  value !== roleId ? (
                    <MenuItem
                      value={value}
                      name={label}
                      onClick={handleItemClick}
                    >
                      {label}
                    </MenuItem>
                  ) : null
                )}
              </Select>
            </FormControl>
          </SelectOrganizationCheckContainer>
          {selectedrole.length && selectedRoleLabel.length ? (
            <PreviousOrgScopeSearch
              classes
              selectedTableInfo={selectedTableInfo}
              selectedFullScopes={selectedFullScopes}
              updateOrgArray={(e) => setOrgArrayFromprev(e)}
              checkIsSelected={checkIsSelected}
              selectedCurrentOrg={selectedCurrentOrg}
              previousOrgScopes={previousSelectedOrgScope}
              roleId={roleId}
            />
          ) : null}
          <DialogActions className=" p-26">
            <SecondaryButton
              onButtonClick={handleDialogClose}
              label={t("cancel")}
            />
            {selectedrole.length ? (
              <PrimaryButton
                label={t("select")}
                onButtonClick={() => addScopeInfo()}
                className="btn-ml-26"
                disabled={areArraysSameStructure(
                  selectedScopes,
                  selectedFullScopes
                )}
              />
            ) : null}
          </DialogActions>
        </FormControl>
      </Dialog>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isAddscopeDisabled === nextProps.isAddscopeDisabled &&
      prevProps.dialog === nextProps.dialog &&
      prevProps.roleId === nextProps.roleId &&
      prevProps.selectedFullScopes === nextProps.selectedFullScopes
    );
  }
);

PreviousOrgDialog.propTypes = {};

export default PreviousOrgDialog;
