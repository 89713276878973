/* eslint-disable max-len */
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { MenuItem, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../button'
import { FilterChip } from '../../chip'
import { InteractionDialog } from '../../dialog'
import { Autocomplete, NewSelect, TextField } from '../../form'
import { PreselectedBehavior } from './GenericSearchConstants'
import { GenericSearchSort } from './GenericSearchSort'

export const GenericSearchFilter = ({
  possibleFilters = {},
  activeFilter = {},
  preselectedFilter = {},
  preselectedFilterBehavior,
  possibleSorts = [],
  activeSort = [],
  preselectedSort,
  preselectedSortBehavior,
  isMobile = false,
  isAllShown = false,
  onSortChange = (value) => {},
  onFilterChange = (filterKey, value) => {},
  onFiltersChange = (value) => {},
  onAllClick = () => {},
  sx = {}
}) => {
  const { t } = useTranslation('componentLibrary')
  const [ isFilterDialogOpen, setIsFilterDialogOpen ] = useState(false)

  const [ editFilters, setEditFilters ] = useState(activeFilter)
  const [ editSort, setEditSort ] = useState(activeSort)

  useEffect(() => setEditFilters(activeFilter), [ activeFilter ])
  useEffect(() => setEditSort(activeSort), [ activeSort ])

  const filterKeys = Object.keys(possibleFilters)
  const isMultipleFilters = filterKeys.length > 1 && !possibleFilters?.single

  const isAllFiltersHidden = preselectedFilterBehavior === PreselectedBehavior.HIDE_FILTER_GROUP
    ? filterKeys.every((filterKey) => !!preselectedFilter[filterKey])
    : false

  const isAllFiltersDisabled = preselectedFilterBehavior === PreselectedBehavior.DISABLE_FILTER_GROUP
    ? filterKeys.every((filterKey) => !!preselectedFilter[filterKey])
    : false

  const getAutoCompleteDefaultValue = (key) => {
    if (!activeFilter[key]) return undefined

    const findFilterLabel = (filter) => possibleFilters[key]?.filters?.find((possibleFilter) => possibleFilter.value === filter)?.label

    return !possibleFilters[key].isMultiselect
      ? findFilterLabel(activeFilter[key][0])
      : activeFilter[key].map((filter) => findFilterLabel(filter))
  }

  const updateFilterAtKey = (filterKey, filterValue) => {
    const newFilter = { ...editFilters }
    newFilter[filterKey] = filterValue

    setEditFilters(newFilter)
  }

  const handleFilterByClick = () => setIsFilterDialogOpen(true)
  const handleFilterAllClick = (filterKey) => onFilterChange(filterKey, preselectedFilterBehavior === 'disableFilter' ? preselectedFilter[filterKey] : [])
  const handleSortChange = (value) => setEditSort(value)

  const handleDesktopFilterChange = (filterKey, filter, isActive, isMultiselect, filterOverwrite) => {
    const singleFilterKey = filterOverwrite || filterKey

    if (isActive) {
      let newValue = activeFilter[singleFilterKey]
        ? [ ...activeFilter[singleFilterKey] ]
        : []

      if (isMultiselect) newValue.push(filter)
      else newValue = [ filter ]

      onFilterChange(singleFilterKey, newValue)
    } else {
      onFilterChange(singleFilterKey, [ ...activeFilter[singleFilterKey] ].filter((value) => value !== filter))
    }
  }

  const handleMobileFilterChange = (filterKey, { target: { value } }) => {
    updateFilterAtKey(filterKey, typeof value === 'string' ? value.split(',') : value)
  }

  const handleMobileSingleFilterChange = (filterKey, value) => {
    updateFilterAtKey(filterKey, typeof value === 'string' ? value.split(',') : value)
  }

  const handleMobileAutocompleteFilterChange = (filterKey, value) => {
    updateFilterAtKey(filterKey, value !== null
      ? Array.isArray(value)
        ? value.map((obj) => obj.value)
        : [ value.value ]
      : [])
  }

  const handleResetClick = () => {
    setEditFilters(activeFilter)
    setEditSort(activeSort)
  }

  const handleOkClick = () => {
    onFiltersChange(editFilters)
    onSortChange(editSort)
    setIsFilterDialogOpen(false)
  }

  const handleCancelClick = () => {
    handleResetClick()
    setIsFilterDialogOpen(false)
  }

  const isFilterDisabled = (filterKey) => (preselectedFilterBehavior === PreselectedBehavior.DISABLE_FILTER_GROUP && !!preselectedFilter[filterKey])
      || (preselectedFilterBehavior === PreselectedBehavior.DISABLE_FILTER && !possibleFilters[filterKey].isMultiselect)

  const isSingleFilterDisabled = (filterKey, value) => preselectedFilterBehavior === PreselectedBehavior.DISABLE_FILTER && preselectedFilter[filterKey].includes(value)

  const getDisabledSingleFilters = (filterKey) => {
    // special case for RoleSearch
    const disabledRoleFilters = editFilters?.isJobTitle?.length ? [ 'isDynamic' ] : editFilters?.isDynamic?.length ? [ 'isJobTitle' ] : []

    if (filterKey === 'single') {
      return disabledRoleFilters
    }

    return preselectedFilterBehavior === PreselectedBehavior.DISABLE_FILTER ? preselectedFilter[filterKey] : []
  }

  return !isAllFiltersHidden ? (
    <Stack data-testid="GenericSearchFilter" gap={0.5} sx={sx}>
      {!isMobile && !!filterKeys.length && (
        <Typography sx={{ fontSize: '14px', color: 'text.secondary' }}>
          {isMultipleFilters ? t('genericSearch.filterBy') : possibleFilters?.single ? possibleFilters?.single.label : possibleFilters[filterKeys[0]].label}
        </Typography>
      )}

      {!isMultipleFilters && !isMobile && (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
        >
          {isAllShown && !isAllFiltersDisabled && (
            <FilterChip
              label={t('general.all')}
              isActive={!Object.keys(activeFilter).length}
              onClick={onAllClick}
            />
          )}

          {possibleFilters[filterKeys[0]]?.filters.map(({
            filterOverwrite,
            value,
            label,
            icon,
            isDisabled
          }, index) => (
            <FilterChip
              key={`${value}-${index}`}
              label={label}
              icon={icon}
              disabled={!!preselectedFilterBehavior || isDisabled}
              onClick={(isActive) => handleDesktopFilterChange(filterKeys[0], value, isActive, possibleFilters[filterKeys[0]].isMultiselect, filterOverwrite)}
              isActive={filterKeys[0] === 'single' ? !!activeFilter[filterOverwrite]?.length : activeFilter[filterKeys[0]]?.includes(value)}
            />
          ))}
        </Stack>
      )}

      {isMultipleFilters && !isMobile && (
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {isAllShown && !isAllFiltersDisabled && (
            <FilterChip
              label={t('general.all')}
              isActive={!Object.keys(activeFilter).length}
              onClick={onAllClick}
            />
          )}

          {filterKeys.map((filterKey, index) => (
            !(preselectedFilterBehavior === PreselectedBehavior.HIDE_FILTER_GROUP && preselectedFilter[filterKey]) && (
              <FilterChip
                key={`${filterKey}-${index}`}
                label={possibleFilters[filterKey].label}
                icon={possibleFilters[filterKey].icon}
                possibleFilters={possibleFilters[filterKey].filters}
                activeFilters={activeFilter[filterKey]}
                disabledFilters={getDisabledSingleFilters(filterKey)}
                disabled={isFilterDisabled(filterKey)}
                isMultiselect={possibleFilters[filterKey].isMultiselect}
                isSearchShown={possibleFilters[filterKey].isSearchShown}
                isAllShown={possibleFilters[filterKey].isAllShown}
                searchLabel={possibleFilters[filterKey].searchLabel}
                allLabel={possibleFilters[filterKey].allLabel}
                filtersPerRow={possibleFilters[filterKey].filtersPerRow}
                onAllClick={() => handleFilterAllClick(filterKey)}
                onChange={(filter, isActive, isMultiselect) => {
                  handleDesktopFilterChange(filterKey, filter, isActive, isMultiselect)
                }}
              />
            )
          ))}
        </Stack>
      )}

      {isMobile && (
        <>
          <Button
            variant="secondary"
            onClick={handleFilterByClick}
            sx={{
              fontSize: '13px',
              p: '4px, 10px, 4px, 10px',
              gap: '2px'
            }}
          >
            <FilterAltOutlinedIcon />

            { t('genericSearch.filterButtonLabel') }
          </Button>

          <InteractionDialog
            isOpen={isFilterDialogOpen}
            title={t('genericSearch.filterDialogTitle')}
            onClose={handleCancelClick}
            onPrimaryClick={handleOkClick}
            primaryButtonText={t('genericSearch.filterDialogPrimary')}
            onSecondaryClick={handleCancelClick}
            secondaryButtonText={t('genericSearch.filterDialogSecondary')}
            onTertiaryClick={handleResetClick}
            tertiaryButtonText={t('genericSearch.filterDialogTertiary')}
            maxWidth="sm"
            fullWidth
          >
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {!!filterKeys.length && filterKeys.map((filterKey) => (
                !(preselectedFilterBehavior === PreselectedBehavior.HIDE_FILTER_GROUP && preselectedFilter[filterKey])
                  && (possibleFilters[filterKey]?.isAutoComplete
                    ? (
                      <Autocomplete
                        key={filterKey}
                        sx={{ width: '100%', '.MuiAutocomplete-endAdornment': { marginRight: '-2.5px' } }}
                        defaultValue={getAutoCompleteDefaultValue(filterKey)}
                        disabled={isFilterDisabled(filterKey)}
                        onChange={(_, value) => handleMobileAutocompleteFilterChange(filterKey, value)}
                        options={possibleFilters[filterKey]?.filters || []}
                        multiple={possibleFilters[filterKey]?.isMultiselect}
                        renderOption={(props, option) => (
                          <MenuItem
                            key={option.value}
                            disabled={isSingleFilterDisabled(filterKey, option.value)}
                            {...props}
                          >
                            {option.renderer || option.label}
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={possibleFilters[filterKey].label}
                          />
                        )}
                      />
                    )
                    : (
                      <NewSelect
                        key={filterKey}
                        items={possibleFilters[filterKey]?.filters}
                        label={possibleFilters[filterKey]?.renderer || possibleFilters[filterKey]?.label}
                        value={editFilters[filterKey] || []}
                        activeFilters={activeFilter}
                        disabledItems={getDisabledSingleFilters(filterKey)}
                        disabled={isFilterDisabled(filterKey)}
                        isFullWidth
                        isCheckboxShown={possibleFilters[filterKey]?.isMultiselect || filterKey === 'single'}
                        isMultiselect={possibleFilters[filterKey]?.isMultiselect || filterKey === 'single'}
                        onChange={filterKey === 'single' ? (event, child) => {
                          handleMobileSingleFilterChange(child.props.name, child.props.value)
                        } : (event) => {
                          handleMobileFilterChange(filterKey, event)
                        }}
                      />
                    )
                  )
              ))}

              {!!possibleSorts.length && (
                <GenericSearchSort
                  variant="filled"
                  activeSort={editSort}
                  possibleSorts={possibleSorts}
                  preselectedSort={preselectedSort}
                  preselectedSortBehavior={preselectedSortBehavior}
                  isFullWidth
                  onChange={handleSortChange}
                />
              )}
            </Stack>
          </InteractionDialog>
        </>
      )}
    </Stack>
  ) : null
}

GenericSearchFilter.propTypes = {
  possibleFilters: PropTypes.object,
  activeFilter: PropTypes.object,
  preselectedFilter: PropTypes.object,
  preselectedFilterBehavior: PropTypes.string,
  possibleSorts: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })),
  activeSort: PropTypes.arrayOf(PropTypes.string),
  preselectedSort: PropTypes.arrayOf(PropTypes.string),
  preselectedSortBehavior: PropTypes.string,
  isMobile: PropTypes.bool,
  isAllShown: PropTypes.bool,
  onSortChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onFiltersChange: PropTypes.func,
  onAllClick: PropTypes.func
}
