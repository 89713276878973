import React, { useEffect, useCallback, useState } from "react";
import { TablePagination } from "@mui/material";
import ReviewCard from "@components/RoleReviewCard/RCardIndex";
import { Grid } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import UsersIcon from "@Icons/accessReview/users.svg";
import CalendarIcon from "@pageIcons/requestApproval/CalenderIcon";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import LinearProgressBarWithValue from "@components/ProgressBar";
import MultiProfileImageComponent from "@components/MultiProfileImageComponent";
import CustomValidityChip from "@components/CustomValidityChip";
import { RoleCardV2GridSkeleton } from "@components/Skeleton/CommonSkeletons";

import { getRoleReviewList, getOverdueCount } from "@src/actions";
import { useTranslation } from "react-i18next";
import { ReviewGridContainer } from "../../styledComponents";
import NoRolesPage from "@components/noRoles";
import DataClassification from "@components/DataClassificationChip";
import { Link, useNavigate } from "react-router-dom";

const GridLayout = ({
  searchValue,
  isAdminVisible,
  onBadgeClick,
  filterValue,
  userId,
  classification,
  sortValue,
  isMobile,
  roleOwnerRoleIds = [],
  isUserHasAdminAccess,
}) => {
  const selectableRowsPerPage = [12, 15, 20];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(0);
  const [reviewViewData, setReviewData] = useState([]);
  // const [pageData, setPageData] = useState();

  const handlePageChange = (event, newPage) => setPage(newPage);
  const handleRowsPerPageChange = (event) => setRowsPerPage(event.target.value);
  const navigate = useNavigate();

  const {
    reviewList: assignedReview = [],
    error,
    loading: reviewLoading,
    pageData = {
      data: [],
      paginationTottalCount: 0,
      filter: "",
      search: "",
      classification: "",
      sort: "",
      isAdminVisible,
      rowsPerPage,
    },
  } = useSelector((state) => state.accessReview?.roleReviewList);

  const getRoleReviewListForPage = useCallback(() => {
    let isNeedToReset = false;
    if (
      rowsPerPage !== pageData?.rowsPerPage ||
      pageData?.sort !== sortValue ||
      pageData?.filter !== filterValue ||
      searchValue !== pageData?.search ||
      pageData?.classification !== classification ||
      pageData?.isAdminVisible !== isAdminVisible
    ) {
      isNeedToReset = true;
    }
    if (
      pageData?.data[page] &&
      pageData?.data[page].length > 0 &&
      !isNeedToReset
    ) {
      return;
    } else {
      const offset = page * rowsPerPage;
      const limit = rowsPerPage;

      dispatch(
        getRoleReviewList(
          filterValue === "all" ? "" : filterValue,
          isUserHasAdminAccess ? (isAdminVisible ? "" : userId) : "",
          searchValue,
          classification,
          sortValue,
          offset,
          limit,
          page,
          isNeedToReset ? [] : pageData?.data,
          assignedReview,
          rowsPerPage,
          roleOwnerRoleIds
        )
      );
    }
  }, [
    dispatch,
    isAdminVisible,
    userId,
    filterValue,
    searchValue,
    page,
    rowsPerPage,
    classification,
    sortValue,
    roleOwnerRoleIds,
  ]);

  useEffect(() => {
    getRoleReviewListForPage();
  }, [getRoleReviewListForPage]);

  useEffect(() => {
    setPage(0);
  }, [
    isAdminVisible,
    userId,
    filterValue,
    searchValue,
    classification,
    sortValue,
  ]);

  const navigateToDetails = (id) => {
    navigate(`/access/accessReview/${id}`);
  };

  // useEffect(() => {
  //   (async () => {
  //     const pageData = await getPageData(page, rowsPerPage, data);
  //     setPageData(pageData);
  //   })();

  //   setCount(data.length);
  // }, [data, page, rowsPerPage]);

  useEffect(() => {
    let selectedData = [];
    const defaultView = isMobile ? "expandable" : "default";
    const getTableData = async (id) => {
      const tableDataResponse = await getOverdueCount(id);
      return tableDataResponse;
    };
    const getTableInfo = async () => {
      if (pageData?.data[page]) {
        selectedData = await Promise.all(
          pageData?.data[page]?.map(async (item) => {
            const roleId = item?.roleId;
            const roleName = item?.roleName;
            const dataClassification = item?.dataClassification?.toLowerCase();
            const startTime = new Date(item?.startTime);
            const deadline = startTime?.setDate(startTime?.getDate() + 28);
            const endTime = item?.endTime;
            const state = item?.state;
            const workflowId = item?.workflowId;
            let overdueCount = 0;
            if (state === "TIMEOUT") {
              overdueCount = await getTableData(workflowId);
            }
            return {
              workflowId: item.workflowId,
              id: roleId,
              name: roleName,
              dataClassification: (
                <DataClassification
                  type={dataClassification}
                  variant={defaultView}
                  isMobile={isMobile}
                />
              ),
              content: [
                {
                  icon: <CalendarIcon />,
                  title: t("role-review.deadline"),
                  chip: (
                    <CustomValidityChip
                      date={
                        state === "COMPLETED" ? endTime ?? deadline : deadline
                      }
                      icon={
                        state === "COMPLETED" ? (
                          <CheckCircleOutlineIcon fontSize="1.2rem" />
                        ) : (
                          <RemoveCircleOutlineIcon fontSize="1.2rem" />
                        )
                      }
                      label={t(
                        state === "COMPLETED"
                          ? "role-review-by-status.done"
                          : "role-review-by-status.overdue"
                      )}
                      state={state}
                      color={state === "COMPLETED" ? "#0078D6" : "#D92121"}
                    />
                  ),
                },
                {
                  icon: <UsersIcon fontSize={"inherit"} />,
                  title: t("role-review.reviewers"),
                  chip: (
                    <>
                      {item?.reviewers?.length ? (
                        <MultiProfileImageComponent
                          data={item?.reviewers}
                          circleSize="32px" // Customize the circle size
                          componentSize={isMobile ? "100px" : "300px"} // Customize the component size
                          fontSize="14px"
                          onBadgeClick={(data) => {
                            onBadgeClick(data);
                          }}
                          gapInbetween="8px"
                          borderWidth="0px"
                          maxImages={3}
                          isneeduserContextPopover={true}
                        />
                      ) : (
                        <>-</>
                      )}
                    </>
                  ),
                },
                {
                  icon: <RotateLeftIcon />,
                  title: t("role-review.status"),
                  chip: (
                    <LinearProgressBarWithValue
                      total={item?.totalAssignments}
                      value={item?.processedAssignments}
                      overdueCount={overdueCount}
                      Filtervalue={state}
                    />
                  ),
                },
              ],
            };
          })
        );
        setReviewData(selectedData);
      }
    };

    getTableInfo();
  }, [pageData, t, isMobile]);

  return (
    <ReviewGridContainer container>
      {reviewLoading ? (
        <RoleCardV2GridSkeleton large={false} hideActions={true} />
      ) : (
        <>
          {reviewViewData?.length ? (
            <>
              <TablePagination
                component="div"
                count={pageData?.paginationTottalCount}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={selectableRowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage={`${t("reviews-per-page")}:`}
                labelDisplayedRows={
                  isMobile
                    ? () => null
                    : ({ from, to, count }) =>
                        `${from} - ${to} ${t("of")} ${count}`
                }
                className={
                  !(pageData?.paginationTottalCount > rowsPerPage)
                    ? "grid-pagination"
                    : ""
                }
              />
              <Grid container spacing={2}>
                {reviewViewData?.map((e, i) => (
                  <Grid
                    sm={12}
                    md={6}
                    lg={6}
                    xxl={4}
                    key={i}
                    item
                    sx={{
                      width: "100%",
                      "& a": {
                        textDecoration: "none",
                        color: "#000000DE",
                        "-webkit-tap-highlight-color": "transparent",
                      },
                      "& .MuiButtonBase-root.MuiChip-root:hover": {
                        backgroundColor: "#000000",
                      },
                    }}
                  >
                    <Link to={`/access/accessReview/${e?.workflowId}`}>
                      <ReviewCard data={e} />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <NoRolesPage
              selfRequestable={true}
              isExpired={false}
              isFromAccessReview={true}
            />
          )}
        </>
      )}
    </ReviewGridContainer>
  );
};

export default GridLayout;
