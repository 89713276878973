import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { Box, Divider, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getClientLink } from '../../../util'
import { AppCard } from '../../appElements'
import { DetailsList, IdTitle } from '../../card/genericCard'
import { DataClassificationChip } from '../../chip'

export const EntitlementContext = ({
  app,
  entitlement,
  isLoading
}) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Stack gap={2}>
      <IdTitle
        isLink
        isLoading={isLoading}
        href={`${getClientLink('admin')}/applications/${app?.id}`}
        id={entitlement?.entitlementId}
        title={entitlement?.displayName}
      />

      <Divider />

      <DetailsList
        variant="grid"
        size="xl"
        isLoading={isLoading}
        data={[
          {
            icon: <DescriptionOutlinedIcon fontSize="small" />,
            label: t('entitlements.type.label'),
            text: t(`entitlements.type.${entitlement?.type}`)
          },
          {
            icon: <DescriptionOutlinedIcon fontSize="small" />,
            label: t('general.description'),
            text: entitlement?.description || 'Test'
          }
        ]}
      />

      <Divider />

      <Box>
        <DataClassificationChip
          size="s"
          type={entitlement?.dataClassification}
          isLoading={isLoading}
        />
      </Box>

      <Divider />

      <AppCard
        data={app || {}}
        variant="grid"
        size="s"
        isContextShown={false}
        isLoading={isLoading}
      />
    </Stack>
  )
}

EntitlementContext.propTypes = {
  /** Application data to display within the context. */
  app: PropTypes.shape({
    logo: PropTypes.string,
    id: PropTypes.string,
    url: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string
  }),
  /** Entitlement data to display within context */
  entitlement: PropTypes.shape({
    uuid: PropTypes.string,
    entitlementId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dataClassification: PropTypes.oneOf([ 'INTERNAL', 'CONFIDENTIAL', 'SECRET' ]).isRequired,
    connectivity: PropTypes.bool,
    type: PropTypes.oneOf([ 'ENTITLEMENT', 'AUTHORIZATION' ])
  }),
  /** Loading state */
  isLoading: PropTypes.bool
}
