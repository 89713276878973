import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  GridViewOutlined,
  ViewHeadlineOutlined,
  CalendarMonthOutlined,
  Devices,
  Business,
  Delete as DeleteIcon,
  LayersOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { GenericSearch, DatePickerDialog } from "@alice/component_library";
import { a11yProps } from "@utils";
import RoleGridViewRender from "@components/RoleGrid/RoleGrid";
import { PrimaryButton } from "@components/button";
import {
  StyledTab,
  StyledTabs,
  StyledPapper,
  TabContainer,
  GenericSearchContainer,
} from "./styledComponents";
import { useLocation } from "react-router-dom";

const formatDateRange = (dateRange) => {
  // Parse 'from' and 'to' dates from the structure
  const fromDate = new Date(dateRange.from);
  const toDate = new Date(dateRange.to);

  // Format 'from' date
  const fromFormatted = `${fromDate.getDate()}/${
    fromDate.getMonth() + 1
  }/${fromDate.getFullYear()}`;

  // Format 'to' date with leading zero for single-digit months
  const toFormatted = `${toDate.getDate()}/${
    toDate.getMonth() + 1 < 10 ? "0" : ""
  }${toDate.getMonth() + 1}/${toDate.getFullYear()}`;

  return `${fromFormatted} - ${toFormatted}`;
};

const hasSameStructure = (obj1, obj2) => {
  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if keys match and values are the same
  for (const key of keys1) {
    // Check if key exists in the other object
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    // Check if the types of corresponding values match
    if (typeof obj1[key] !== typeof obj2[key]) {
      return false;
    }

    // If values are objects, recursively check their structure
    if (typeof obj1[key] === "object") {
      if (!hasSameStructure(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      // Values are primitive types, check if they are equal
      return false;
    }
  }

  // If all checks passed, the structures are the same
  return true;
};

function trackChanges(oldObj, newObj) {
  const changes = { added: [] };

  // Handle empty old object as a special case
  if (Object.keys(oldObj).length === 0) {
    for (const key in newObj) {
      changes.added.push({ key, value: newObj[key] });
    }
    return changes.added[0];
  }

  // Loop through keys in the new object
  for (const key in newObj) {
    // Check for newly added keys (considering empty oldObj)
    if (!oldObj.hasOwnProperty(key)) {
      changes.added.push({ key, value: newObj[key] });
    } else {
      // Check for modifications to existing keys
      if (oldObj[key] !== newObj[key]) {
        changes.added.push({ key, value: newObj[key] });
      }
    }
  }

  return changes.added[0];
}

const DelteButton = ({ label, isDisabled, onButtonClick }) => {
  return (
    <PrimaryButton
      label={
        <Stack direction="row" gap={1}>
          <DeleteIcon />
          {label}
        </Stack>
      }
      disabled={!isDisabled}
      onButtonClick={onButtonClick}
      className="delete-button"
    />
  );
};

const AssignedTabs = ({ value, handleChange, label1, label2 }) => (
  <TabContainer>
    <StyledPapper>
      <StyledTabs
        value={value}
        onChange={(e, v) => handleChange(e, v)}
        indicatorColor="secondary"
        variant="standard"
        TabIndicatorProps={{ style: { background: "#0078D6" } }}
      >
        <StyledTab label={label1} {...a11yProps(0)} />
        <StyledTab label={label2} {...a11yProps(1)} />
      </StyledTabs>
    </StyledPapper>
  </TabContainer>
);

const RoleGenricSearch = forwardRef(
  (
    {
      isExpandable = true,
      onItemClick,
      onFilterChange,
      rolesData,
      onSortChange,
      handleTabChange,
      handleExtendOrReapply,
      onSelectedData,
      enableDeleteButton,
      handleDialogue,
      handleSingleDelete,
      globalRoleIds,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { t: trans } = useTranslation("componentLibrary");
    const views = ["grid", "list"];
    const search = "large";
    const [tab, setTab] = useState(0);
    const [expandedRole, setExpandedRole] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const expRoleFromQuery = queryParams.get("expiringRoles");
    const expRole = expRoleFromQuery ? true : false;
    const defaultDates = {
      from: new Date(),
      to: new Date(new Date().setDate(new Date().getDate() + 14)),
    };
    const defaultDateString = formatDateRange(defaultDates);
    const [expirationDate, setExpirationDate] = useState(
      expRole ? defaultDates : {}
    );
    const [expiredRoleText, setExpiredRolesText] = useState(
      expRole ? defaultDateString : t("expiration-period")
    );
    let expirationDates = {};
    const [open, setOpen] = useState(false);
    const [currentactiveFilter, setCurrentActiveFilter] = useState(
      expRole ? { validity: ["expirationPeriod"] } : {}
    );
    const [currentSort, setCurrentSort] = useState("nameAsc");
    const [currentSearch, setCurrentSearch] = useState("");
    const genericSearchRef = useRef(null);
    const expirationRef = useRef(null);

    const isSortHidden = false;
    const isFilterHidden = tab ? true : false;

    const getRecordsForPage = (
      data,
      pageNumber,
      activeRowsPerPage,
      activeFilter
    ) => {
      let roleData = data;
      const startIndex = pageNumber * activeRowsPerPage;
      const endIndex = (pageNumber + 1) * activeRowsPerPage;
      return roleData.slice(startIndex, endIndex);
    };

    const handleReset = useCallback(() => {
      genericSearchRef?.current?.reset();
      genericSearchRef?.current?.clear();
      genericSearchRef?.current?.refetchSilent();
      genericSearchRef?.current?.setSelectedData([]);
    }, []);

    const handleChange = (e, value) => {
      setTab(value);
      handleTabChange(value);
      handleReset();
    };

    const handleClose = () => {
      if (!Object.keys(expirationDates).length) {
        expirationRef.current.click();
        setExpiredRolesText(t("expiration-period"));
      }
      setOpen(false);
    };

    const expirationPeriodFilter = (dates) => {
      const { activeFilters = {}, activeSort = "nameAsc" } =
        genericSearchRef.current;
      const dateString = formatDateRange(dates);
      setExpiredRolesText(dateString);
      setExpirationDate(dates);
      expirationDates = dates;
      onFilterChange(activeFilters, activeSort, dates, currentSearch);
    };

    const handleExpandClick = ({ id }) => {
      if (id === expandedRole) setExpandedRole(null);
      else setExpandedRole(id);
    };

    const createViewConfig = () => {
      const viewConfig = { views: {} };

      if (views.includes("grid")) {
        viewConfig.views.grid = {
          tooltip: trans("userSearch.view.grid"),
          icon: GridViewOutlined,
          renderer: RoleGridViewRender,
        };
      }

      if (views.includes("list")) {
        viewConfig.views.list = {
          tooltip: trans("userSearch.view.list"),
          icon: ViewHeadlineOutlined,
          renderer: RoleGridViewRender,
        };
      }

      return viewConfig;
    };

    const createSearchConfig = () => {
      if (search === undefined || search === "none") return undefined;
      return {
        type: search,
        label: t("searchbar_label_role"),
        apiFilters: ["id", "name", "description"],
      };
    };

    const createFilterConfig = () => {
      if (isFilterHidden) return undefined;

      return {
        isAllShown: true,
        filters: {
          status: {
            label: t("status"),
            isSearchShown: false,
            isAllShown: false,
            filters: [
              { value: "aRoles", label: t("active-roles") },
              { value: "eRoles", label: t("expired-roles") },
            ],
          },
          validity: {
            label: t("validity"),
            isSearchShown: false,
            isAllShown: false,
            filters: [
              { value: "noValidity", label: t("no-validity-restriction") },
              {
                value: "expirationPeriod",
                label: expiredRoleText,
                renderer: (
                  <>
                    <Stack ref={expirationRef} direction="row" gap={1}>
                      <CalendarMonthOutlined />
                      {expiredRoleText}
                    </Stack>
                  </>
                ),
              },
            ],
          },
          roleType: {
            label: t("role-type"),
            isSearchShown: false,
            isAllShown: false,
            // isMultiselect: true,
            filters: [
              {
                value: "BUSINESS",
                label: t("business"),
                renderer: (
                  <Stack direction="row" gap={1}>
                    <Business />
                    {t("business")}
                  </Stack>
                ),
              },
              {
                value: "PRODUCT",
                label: t("product"),
                renderer: (
                  <Stack direction="row" gap={1}>
                    <Devices />
                    {t("product")}
                  </Stack>
                ),
              },
              {
                value: "ENTITLEMENT",
                label: t("application"),
                renderer: (
                  <Stack direction="row" gap={1}>
                    <LayersOutlined />
                    {t("application")}
                  </Stack>
                ),
              },
              // {
              //   value: "isJobTitle",
              //   label: t("job-title"),
              //   renderer: (
              //     <Stack direction="row" gap={1}>
              //       <BadgeOutlined />
              //       {t("job-title")}
              //     </Stack>
              //   ),
              // },
            ],
          },
        },
      };
    };

    const createSortConfig = () => {
      if (isSortHidden) return undefined;

      if (tab)
        return {
          sorts: [
            {
              value: "nameAsc",
              label: t("title-a-z"),
            },
            {
              value: "nameDsc",
              label: t("title-z-a"),
            },
          ],
        };
      return {
        sorts: [
          {
            value: "validToAsc",
            label: t("shortest-longest"),
          },
          {
            value: "validToDsc",
            label: t("longest-shortest"),
          },
          {
            value: "nameAsc",
            label: t("title-a-z"),
          },
          {
            value: "nameDsc",
            label: t("title-z-a"),
          },
        ],
      };
    };

    useImperativeHandle(
      ref,
      () => ({
        handleReset: () => handleReset(),
      }),
      [handleReset]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = () => {
      const {
        activeFilters = {},
        activePage = 0,
        activeRowsPerPage = 12,
        activeSearch = "",
        activeSort = "nameAsc",
        activeView = "grid",
      } = genericSearchRef.current;
      if (
        !hasSameStructure(activeFilters, currentactiveFilter) ||
        activeSearch !== currentSearch
      ) {
        if (!hasSameStructure(activeFilters, currentactiveFilter)) {
          setCurrentActiveFilter(activeFilters);
          if (activeSearch !== currentSearch) setCurrentSearch(activeSearch);
          const newKey = trackChanges(currentactiveFilter, activeFilters);
          if (Object.keys(activeFilters).length) {
            if (
              newKey?.key === "validity" &&
              newKey?.value[0] === "expirationPeriod"
            ) {
              setOpen(true);
            } else {
              if (
                newKey?.key === "validity" ||
                newKey?.value[0] === "noValidity"
              )
                setExpiredRolesText(t("expiration-period"));
              onFilterChange(
                activeFilters,
                activeSort,
                expirationDate,
                activeSearch
              );
            }
          } else {
            setExpiredRolesText(t("expiration-period"));
            onFilterChange({}, activeSort, expirationDate, activeSearch);
          }
        } else {
          setCurrentSearch(activeSearch);
          onFilterChange(
            currentactiveFilter,
            activeSort,
            expirationDate,
            activeSearch
          );
        }
      }

      if (activeSort !== currentSort) {
        setCurrentSort(activeSort);
        onSortChange(activeSort);
      }

      return getRecordsForPage(rolesData, activePage, activeRowsPerPage);
    };

    const conditionalProps = expRole
      ? {
          parentFrom: defaultDates.from,
          parentTo: defaultDates.to,
        }
      : {};

    const conditionprop = expRole
      ? {
          preselectedFilter: currentactiveFilter,
        }
      : {};

    return (
      <GenericSearchContainer>
        <GenericSearch
          ref={genericSearchRef}
          isLocalSearch={true}
          getResponseData={() => getData()}
          viewConfig={createViewConfig()}
          searchConfig={createSearchConfig()}
          filterConfig={createFilterConfig()}
          sortConfig={createSortConfig()}
          preselectedSort={currentSort}
          isBottomPaginationHidden={true}
          interaction={tab ? "none" : "multiSelect"}
          isMultiselect
          onItemClick={onItemClick}
          customSlot1={
            <AssignedTabs
              value={tab}
              handleChange={handleChange}
              label1={t("manual-assignments")}
              label2={t("dynamic-assignments")}
            />
          }
          customSlot2={
            !tab ? (
              <DelteButton
                label={t("delete-selected")}
                isDisabled={enableDeleteButton}
                onButtonClick={() => handleDialogue(true)}
              />
            ) : null
          }
          localData={rolesData}
          rendererProps={{
            isDynamic: tab,
            isExpandable,
            expandedRole,
            onExpandClick: isExpandable ? handleExpandClick : undefined,
            onSelectedData,
            handleExtendOrReapply,
            handleSingleDelete,
            globalRoleIds,
          }}
          {...conditionprop}
        />
        <DatePickerDialog
          isRange={true}
          open={open}
          onChange={() => console.log("change")}
          onClose={() => handleClose()}
          onConfirm={(dates) => expirationPeriodFilter(dates)}
          {...conditionalProps}
        />
      </GenericSearchContainer>
    );
  }
);

export default RoleGenricSearch;
