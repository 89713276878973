import React from "react";
import SelectedTaskRow from "./SelectedTaskRow";
import {
  StyledDialog,
  StyledDialogTitle,
  CloseButton,
  StyledDialogContent,
  StyledDialogContentText,
} from "../../styledComponents";
import CloseIcon from "@mui/icons-material/Close";

const ResultDialogBox = ({
  showDialog,
  closeDialog,
  resultData,
  approve,
  t,
}) => {
  const successInfo = resultData
    .filter((result) => result.status === "success")
    .map((result) => result.taskData);
  const errorInfo = resultData
    .filter((result) => result.status === "failed")
    .map((result) => result.taskData);
  return (
    <StyledDialog
      open={showDialog}
      onClose={closeDialog}
      fullWidth="true"
      maxWidth="lg"
    >
      <StyledDialogTitle>
        {approve ? t("approve") : t("reject")}
        <CloseButton onClick={closeDialog}>
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        {successInfo.length > 0 && ( // TODO:#FBEDED
          <>
            <StyledDialogContentText>
              {approve
                ? successInfo.length === 1
                  ? t("task-approved-successfully")
                  : t("tasks-approved-successfully")
                : successInfo.length === 1
                ? t("task-rejected-successfully")
                : t("tasks-rejected-successfully")}
            </StyledDialogContentText>
            {successInfo.map((successTask, i) => (
              <React.Fragment key={i}>
                <SelectedTaskRow
                  rows={successTask}
                  handleRemove={() => {}}
                  hideRemove={true}
                  bgColor="#ECF4ED"
                />
              </React.Fragment>
            ))}
          </>
        )}
        {errorInfo.length > 0 && ( // TODO:#FBEDED The following roles could not be deleted due to technical issues. Please try again later:
          <>
            <StyledDialogContentText>
              {approve
                ? errorInfo.length === 1
                  ? t("task-not-approved")
                  : t("tasks-not-approved")
                : errorInfo.length === 1
                ? t("task-not-rejected")
                : t("tasks-not-rejected")}
            </StyledDialogContentText>
            {errorInfo.map((errorTask, i) => (
              <React.Fragment key={i}>
                <SelectedTaskRow
                  rows={errorTask}
                  handleRemove={() => {}}
                  hideRemove={true}
                  bgColor="#FBEDED"
                />
              </React.Fragment>
            ))}
          </>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ResultDialogBox;
