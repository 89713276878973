import { LightTheme } from '@mercedes-benz/mui5-theme'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WorkflowState } from '../../../constants'
import { isEmpty } from '../../../util'
import { ItemScroller } from '../../itemScroller'
import { Message } from '../../message'
import { Stepper, StepperInteraction } from '../../stepper'
import { UserContextPopover, UserId, UserListPopover } from '../../userElements'

const Content = ({ task }) => {
  const { t } = useTranslation('componentLibrary')

  const approverId = task?.approverId
  const recipients = task?.recipients
  const isAutomatically = !recipients && !approverId
  const otherRecipients = recipients ? recipients.length - 1 : 0
  const isMultiple = !approverId && recipients ? recipients.length > 1 : false

  return (
    <Stack
      gap={1}
      direction={isMultiple ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center"
    >
      { isAutomatically
        ? (
          <Typography variant="subtitle2">
            {t(`workflow.state.auto.${task?.state?.toLowerCase()}`)}
          </Typography>
        )
        : (
          <>
            <UserId userId={approverId || recipients[0].id} variant="small" />

            { isMultiple
              ? (
                <Stack gap={0.5} direction="row" alignItems="center">
                  <Typography
                    variant="body"
                    color="text.primary"
                  >
                    { otherRecipients <= 1
                      ? t('workflowHistory.plusOther')
                      : t('workflowHistory.plusOthers', { count: otherRecipients })}
                  </Typography>

                  <UserListPopover users={recipients} title={t('workflowHistory.inCharge')}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <InfoOutlinedIcon fontSize="20px" sx={{ color: 'action.active' }} />
                    </Box>
                  </UserListPopover>
                </Stack>
              )
              : (
                <UserContextPopover id={approverId || recipients[0].id}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <InfoOutlinedIcon fontSize="20px" sx={{ color: 'action.active' }} />
                  </Box>
                </UserContextPopover>
              )}
          </>
        )}
    </Stack>
  )
}

const getStepProps = (task, t) => {
  const { palette } = LightTheme

  let bgClrPre = 'red'
  let clrPre = 'error'
  let title = t(`workflow.state.${task?.state?.toLowerCase()}`)
  let Icon = QuestionMarkIcon

  switch (task?.state) {
    case WorkflowState.APPROVED:
      bgClrPre = 'green'
      clrPre = 'success'
      Icon = CheckIcon
      break
    case WorkflowState.PENDING:
      bgClrPre = 'blue'
      clrPre = 'info'
      Icon = ErrorOutlineIcon
      break
    case WorkflowState.REJECTED:
    case WorkflowState.CANCELED:
    case WorkflowState.DENIED:
      Icon = CloseIcon
      break
    default:
      title = t('workflow.state.unknown')
  }

  const clrMain = palette[clrPre].main
  const clrDark = palette[clrPre].dark
  const bgClr = palette[bgClrPre][90]

  return {
    id: task.id,
    title,
    iconType: <Icon fontSize="20px" />,
    content: <Content task={task} />,
    sx: {
      '--clr': clrMain,
      '--clrActive': clrMain,
      '--iconClr': clrDark,
      '--iconBrClr': clrDark,
      '--iconBgClr': bgClr,
      '--iconClrActive': clrDark,
      '--iconBrClrActive': clrDark,
      '--iconBgClrActive': bgClr
    },
    optional: true
  }
}

/**
 * This component simplifies the display of a workflow's history by handling all operations internally.
 * Only the workflow itself needs to be provided as input.
 */
export const WorkflowHistory = ({ workflow = {} }) => {
  const { t } = useTranslation('componentLibrary')

  if (!workflow.tasks) workflow.tasks = []

  const steps = workflow.tasks
    .sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
    .map((task) => getStepProps(task, t))

  return isEmpty(workflow.tasks)
    ? (
      <Message
        title={t('workflow.empty.title')}
        description={t('workflow.empty.description')}
      />
    )
    : (
      <ItemScroller itemWrapperId="scrollWrapper">
        <Stepper
          id="scrollWrapper"
          interaction={StepperInteraction.NONE}
          steps={steps}
        />
      </ItemScroller>
    )
}

WorkflowHistory.propTypes = {
  /**
   * Represents a single workflow with a single or multiple tasks to be displayed.
   * Workflows are provided by the `/workflows` endpoint.
   */
  workflow: PropTypes.shape({
    definitionKey: PropTypes.string,
    id: PropTypes.string,
    tenantId: PropTypes.string,
    state: PropTypes.string,
    currentTaskId: PropTypes.string,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        state: PropTypes.string.isRequired,
        type: PropTypes.string,
        workflowId: PropTypes.string,
        approverId: PropTypes.string,
        recipients: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            givenname: PropTypes.string,
            surname: PropTypes.string,
            mailAddress: PropTypes.string,
            completeName: PropTypes.string,
            homeOrgId: PropTypes.string,
            communities: PropTypes.arrayOf(PropTypes.string),
            departmentNumber: PropTypes.string,
            isActive: PropTypes.bool,
            plant1: PropTypes.string,
            plant2: PropTypes.string,
            groupType: PropTypes.string
          })
        ),
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        expiryTime: PropTypes.string,
        comment: PropTypes.string
      })
    ).isRequired,
    workflowData: PropTypes.shape({
      roleToUser: PropTypes.shape({
        roleId: PropTypes.string,
        user: PropTypes.shape({
          id: PropTypes.string,
          givenname: PropTypes.string,
          surname: PropTypes.string,
          mailAddress: PropTypes.string,
          completeName: PropTypes.string,
          homeOrgId: PropTypes.string,
          communities: PropTypes.arrayOf(PropTypes.string),
          departmentNumber: PropTypes.string,
          isActive: PropTypes.bool,
          plant1: PropTypes.string,
          plant2: PropTypes.string,
          groupType: PropTypes.string
        }),
        validFrom: PropTypes.string,
        validTo: PropTypes.string,
        orgScopeId: PropTypes.string
      }),
      requester: PropTypes.shape({
        id: PropTypes.string,
        givenname: PropTypes.string,
        surname: PropTypes.string,
        mailAddress: PropTypes.string,
        completeName: PropTypes.string,
        homeOrgId: PropTypes.string,
        communities: PropTypes.arrayOf(PropTypes.string),
        departmentNumber: PropTypes.string,
        isActive: PropTypes.bool,
        plant1: PropTypes.string,
        plant2: PropTypes.string,
        groupType: PropTypes.string
      }),
      type: PropTypes.string
    }),
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    expiryTime: PropTypes.string,
    inRetryState: PropTypes.bool
  }).isRequired
}
