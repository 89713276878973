import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Communication from "@src/services/Communication";
import urlConfig from "@src/services/urlConfig";
import { makeRequests } from "@src/services/SingleRequest";
import {
  setRequestId,
  resendSucess,
  storeSavedRoleRoleData,
  resultLoading,
} from "../slice/newRequestSlice";
import { getUUID } from "@src/utils";

export const fetchAssignableRoles = createAsyncThunk(
  "newRequest/fetchAssignableRoles",
  async (
    {
      userId,
      // isSelfRequestable = false,
      roleType = "",
      search = "",
      isJobTitle = false,
      applicationId = "",
      offset = 0,
      limit = 10, // will be changed once pagination is implemented
    },
    { getState }
  ) => {
    try {
      const { newRequestSlice: { assignableRoles: { data = [] } } = {} } =
        getState();
      const urlSearchParamsForRoles = new URLSearchParams();
      urlSearchParamsForRoles.set("offset", offset);
      urlSearchParamsForRoles.set("limit", limit);
      const filtersForRoles = ["isWorkflowBased==true"];
      if (search) {
        const searchTerm = `*${search.trim().replace(/\s/g, "*")}*`;
        filtersForRoles.push(
          `(name==${searchTerm},id==${searchTerm},description==${searchTerm})`
        );
      }

      if (applicationId)
        filtersForRoles.push(`applicationId==${applicationId}`);

      if (roleType) filtersForRoles.push(`roleType==${roleType}`);
      if (isJobTitle) filtersForRoles.push(`isJobTitle==true`);
      urlSearchParamsForRoles.set("filter", filtersForRoles.join(";"));
      let url = `/gems/users/${userId}/assignableroles?${urlSearchParamsForRoles.toString()}`;

      const assignableRoles = await Communication.get(url);

      const rolesArray =
        offset === 0
          ? assignableRoles.data.roles
          : [...data, ...assignableRoles.data.roles];
      const totalCount = assignableRoles.data.rels.totalCount;
      const hasMore = totalCount > rolesArray.length;

      return {
        roles: rolesArray,
        totalCount: totalCount,
        hasMore: hasMore,
        offset: offset,
      };
    } catch (error) {
      throw new Error("Failed to assignabled roles");
    }
  }
);

export const fetchAssignableRolesForreferenceUser = createAsyncThunk(
  "newRequest/fetchAssignableRolesForreferenceUser",
  async (
    {
      referenceUserUserId,
      roleType = "",
      search = "",
      isJobTitle = false,
      offset = 0,
      limit = 10, // will be changed once pagination is implemented
      userId,
    },
    { getState }
  ) => {
    try {
      const { newRequestSlice: { assignableRoles: { data = [] } } = {} } =
        getState();
      const urlSearchParamsForRoles = new URLSearchParams();
      // urlSearchParamsForRoles.set("offset", offset);
      // urlSearchParamsForRoles.set("limit", limit);
      const filtersForRoles = ["roleDefinition.isWorkflowBased==true"];
      if (search) {
        const searchTerm = `*${search.trim().replace(/\s/g, "*")}*`;
        filtersForRoles.push(
          `(roleDefinition.name==${searchTerm},roleDefinition.id==${searchTerm},roleDefinition.description==${searchTerm})`
        );
      }

      if (roleType)
        filtersForRoles.push(`roleDefinition.roleType==${roleType}`);
      if (isJobTitle) filtersForRoles.push(`roleDefinition.isJobTitle==true`);
      urlSearchParamsForRoles.set("filter", filtersForRoles.join(";"));
      let url = `/gems/users/${referenceUserUserId}/roles?${urlSearchParamsForRoles.toString()}`;

      const assignableRoles = await Communication.get(url);

      const roleData = assignableRoles?.data?.roles.map(
        (item) => item.roleDefinition
      );

      const rolesArray = offset === 0 ? roleData : [...data, ...roleData];
      // const uniqueRolesArray = rolesArray.filter(
      //   (role, index, self) => index === self.findIndex((r) => r.id === role.id)
      // );

      const uniqueRoleId = Array.from(
        new Set(rolesArray.map((role) => role.id))
      );
      const promises = [];
      const chunkQueryParam = [];
      const moduleSize = 5;

      for (let i = 0; i < uniqueRoleId.length; i += moduleSize) {
        const chunk = uniqueRoleId.slice(i, i + moduleSize);
        const formattedChunk =
          chunk.length === 1
            ? `id==${chunk[0]}`
            : chunk.map((id) => `id==${id}`).join(",");
        chunkQueryParam.push(formattedChunk);
      }

      chunkQueryParam.forEach((chunkQuery) => {
        const urlSearchParamsForRoles = new URLSearchParams();
        urlSearchParamsForRoles.set("filter", chunkQuery);
        const url = `/gems/users/${userId}/assignableroles?${urlSearchParamsForRoles.toString()}`;
        const response = Communication.get(url);
        promises.push(response);
      });

      return makeRequests(promises)
        .then(({ responses, errors }) => {
          const asssignableRoles = responses
            .map((item) => item.data.roles)
            .flat();
          return {
            roles: asssignableRoles,
            totalCount: asssignableRoles.length,
            hasMore: false,
            offset: offset,
          };
        })
        .catch((error) => {
          console.log(error, "Error in fetching unique roles");
        });
    } catch (error) {
      console.log(error, "Error in fetchAssignableRolesForreferenceUser");
      throw new Error("Failed to assignabled roles");
    }
  }
);

export const fetchAvailableApplications = createAsyncThunk(
  "availableApplications/fetchAvailableApplications",
  async ({ userId, search = "", offset = 0, limit = 5 }, { getState }) => {
    try {
      const {
        newRequestSlice: { availableApplications: { data = [] } = {} } = {},
      } = getState();
      const urlSearchParamsForApplication = new URLSearchParams();
      urlSearchParamsForApplication.set("offset", offset);
      urlSearchParamsForApplication.set("limit", limit);
      urlSearchParamsForApplication.set("sort", "name");
      if (search) {
        const searchTerm = `*${search.trim().replace(/\s/g, "*")}*`;
        urlSearchParamsForApplication.set(
          "filter",
          `(id==${searchTerm},name==${searchTerm})`
        );
      }
      const url = `/gems/users/${userId}/availableapplications?${urlSearchParamsForApplication.toString()}`;
      const availableapplications = await Communication.get(url);
      const applications =
        offset === 0
          ? availableapplications.data.applications
          : [
              ...data, //...(data[userId] || [])
              ...availableapplications.data.applications,
            ];
      const totalCount = availableapplications.data.rels.totalCount;
      const hasMore = totalCount > applications.length;

      return {
        totalCount: totalCount,
        applications: applications, //{ [userId]: applications },
        offset: offset,
        hasMore: hasMore,
      };
    } catch (error) {
      throw new Error("Failed to available roles");
    }
  }
);

export const fetchEntitlementsOfRole = createAsyncThunk(
  "roleEntitlements/fetchEntitlementsOfRole",
  async ({ roleId }) => {
    try {
      const url = `/gems/roles/${roleId}/entitlements`;
      const entitlements = await Communication.get(url);
      return { roleId: roleId, ...entitlements.data };
    } catch (error) {
      return error;
    }
  }
);

export const fetchApplicationDetails = createAsyncThunk(
  "appDetails/fetchApplicationDetails",
  async ({ applicationId }) => {
    try {
      const url = `/gems/applications/${applicationId}`;
      const applicationDetails = await Communication.get(url);
      return applicationDetails.data;
    } catch (error) {
      return error;
    }
  }
);

export const handleBulkImport = createAsyncThunk(
  "bulkImport/handleBulkImport",
  async ({ file, headLine, encoding, assign }, { rejectWithValue }) => {
    try {
      let url = assign ? urlConfig.bulkAssign : urlConfig.bulkRemoval;
      url += `?withHeadline=${headLine}&fileEncoding=${encoding}`;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file?.name);

      const config = { headers: { "content-type": "multipart/form-data" } };

      const response = await axios.post(url, formData, config);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createRoles = createAsyncThunk(
  "createRoles/saveOnredux",
  async (
    { rolesData, reason, userId, dataId = "", data = [] },
    { dispatch }
  ) => {
    const UUID = getUUID();
    dispatch(setRequestId(UUID));
    dispatch(resultLoading(true));
    try {
      const promises = [];
      rolesData.forEach(async (role) => {
        const {
          id,
          data: {
            roleDefinition: {
              needsCustomScopes,
              needsOrgScopes,
              defaultValidityType,
            },
          },
          configurationInformation: {
            timeRange: {
              isDisabled,
              validity: { from, to },
            },
            scopeData: { data: scopedata = null } = {},
          } = {},
        } = role;

        let dynamicUrl = `/gems/users/${
          dataId.length ? role.userId : userId
        }/roles/${id}`;

        const formattedFrom = isDisabled
          ? null
          : new Date(from).toISOString().split("T")[0];
        const formattedTo = isDisabled
          ? null
          : new Date(to).toISOString().split("T")[0];

        const body = {
          reason: dataId.length ? role.reason : reason,
        };

        if (defaultValidityType !== "FIXED") {
          body.validFrom = formattedFrom;
          body.validTo = formattedTo;
        }

        if (needsCustomScopes && needsOrgScopes) {
          if (Object.keys(scopedata).length !== 0) {
            for (const key in scopedata) {
              const customScopes = scopedata[key]?.customScope;
              if (customScopes) {
                for (const customScope of customScopes) {
                  // Access customScope properties here
                  const { name } = customScope;
                  const customUrl = `${dynamicUrl}/organizations/${key}/customscopes/${name}`;
                  const response = Communication.post(customUrl, body);
                  promises.push(response);
                }
              }
            }
          }
        } else if (!needsCustomScopes && needsOrgScopes) {
          if (Object.keys(scopedata).length !== 0) {
            for (const key in scopedata) {
              // Access customScope properties here
              const customUrl = `${dynamicUrl}/organizations/${key}`;
              const response = Communication.post(customUrl, body);
              promises.push(response);
            }
          }
        } else if (needsCustomScopes && !needsOrgScopes) {
          if (Object.keys(scopedata).length !== 0) {
            for (const key in scopedata) {
              const customScopes = scopedata[key]?.customScope;
              if (customScopes) {
                for (const customScope of customScopes) {
                  // Access customScope properties here
                  const { name } = customScope;
                  const customUrl = `${dynamicUrl}/customscopes/${name}`;
                  const response = Communication.post(customUrl, body);
                  promises.push(response);
                }
              }
            }
          }
        } else if (!needsCustomScopes && !needsOrgScopes) {
          const response = Communication.post(dynamicUrl, body);
          promises.push(response);
        }
      });

      // const results = await Promise.all(promises);
      makeRequests(promises)
        .then(({ responses, errors }) => {
          const finalDataInformation = [];
          responses.forEach((item) => {
            const roleId = item.config.url.match(/roles\/([^/]+)/)[1];
            const orgScope = item.config.url.match(
              /organizations\/([^/]+)/
            )?.[1];
            const customScope = item.config.url.match(
              /customscopes\/([^/]+)/
            )?.[1];
            const role = rolesData.find((role) => role.id === roleId);
            const {
              data: {
                roleDefinition: {
                  needsCustomScopes,
                  needsOrgScopes,
                  defaultValidityType,
                  name,
                  id,
                },
              },
              configurationInformation: {
                timeRange: {
                  isDisabled,
                  validity: { from, to },
                },
                scopeData: { data: scopedata = null } = {},
              },
            } = role;

            const specificScopeData = scopedata && scopedata[orgScope];

            const { customScope: originlCustomScope, ...restScopeData } =
              specificScopeData || {};

            // Add a new customScope array
            const modifiedScopeData = {
              ...restScopeData,
              customScope: specificScopeData ? [{ name: customScope }] : [],
            };

            // Add a new customScope array

            const newItem = {
              roleId: roleId,
              status: item.status,
              orgScope,
              customScope,
              reason: reason,
              userId: userId,
              workflowId: item.data.id,
              rolesData: {
                id,
                data: {
                  roleDefinition: {
                    needsCustomScopes,
                    needsOrgScopes,
                    defaultValidityType,
                    name,
                    id,
                  },
                },
                configurationInformation: {
                  timeRange: {
                    isDisabled,
                    validity: { from, to },
                  },
                  scopeData: scopedata
                    ? { data: { [orgScope]: modifiedScopeData } }
                    : undefined,
                },
              },
            };
            finalDataInformation.push(newItem);
          });

          errors.forEach((item) => {
            const roleId = item.config.url.match(/roles\/([^/]+)/)[1];
            const orgScope = item.config.url.match(
              /organizations\/([^/]+)/
            )?.[1];
            const customScope = item.config.url.match(
              /customscopes\/([^/]+)/
            )?.[1];
            const role = rolesData.find((role) => role.id === roleId);
            const {
              data: {
                roleDefinition: {
                  needsCustomScopes,
                  needsOrgScopes,
                  defaultValidityType,
                  name,
                  id,
                },
              },
              configurationInformation: {
                timeRange: {
                  isDisabled,
                  validity: { from, to },
                },
                scopeData: { data: scopedata = null } = {},
              },
            } = role;

            const specificScopeData = scopedata && scopedata[orgScope];

            const { customScope: originlCustomScope, ...restScopeData } =
              specificScopeData || {};

            // Add a new customScope array
            const modifiedScopeData = {
              ...restScopeData,
              customScope: specificScopeData ? [{ name: customScope }] : [],
            };

            // Add a new customScope array

            const newItem = {
              roleId: roleId,
              status: item.status,
              orgScope,
              customScope,
              rolesData: {
                id,
                reason: reason,
                userId: userId,
                data: {
                  roleDefinition: {
                    needsCustomScopes,
                    needsOrgScopes,
                    defaultValidityType,
                    name,
                    id,
                  },
                },
                configurationInformation: {
                  timeRange: {
                    isDisabled,
                    validity: { from, to },
                  },
                  scopeData: scopedata
                    ? { data: { [orgScope]: modifiedScopeData } }
                    : undefined,
                },
              },
            };
            finalDataInformation.push(newItem);
          });

          if (dataId.length) {
            localStorage.setItem(dataId, JSON.stringify(finalDataInformation));
            dispatch(storeSavedRoleRoleData(finalDataInformation));
          } else {
            localStorage.setItem(UUID, JSON.stringify(finalDataInformation));
            dispatch(storeSavedRoleRoleData(finalDataInformation));
          }
          dispatch(resultLoading(false));
        })
        .catch((error) => {
          console.error("Error:", error);
          dispatch(resultLoading(false));
        });
    } catch (error) {
      console.log(error);
      dispatch(resultLoading(false));
      return error;
    }
  }
);
