import { styled } from "@mui/system";

export const ReviewCardContainer = styled("div")(({ theme, ...props }) => ({
  padding: "16px",
  backgroundColor: "#f4f4f4",
  display: "flex",
  flexDirection: "column",
  rowGap: "15px",
  borderRadius: "4px",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "89vw",
  },
  "& svg": {
    height: "18px",
    width: "18px",
  },
  "& .Role-review-header": {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    flexWrap: "nowrap",
    "& .id": {
      fontFamily: "MB Corpo S Text",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "0.22px",
      display: "flex",
      flex: 1,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      rowGap: "10px",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        width: "55vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "20vw",
      },
      [theme.breakpoints.up("xxl")]: {
        width: "13vw",
      },
      "& span": {
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
        letterSpacing: "0.22px",
        flex: 1,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  "& .Role-review-container": {
    display: "flex",
    alignItems: "center",
    rowGap: "25px",
    flexDirection: "column",
    "& .deadline-title": {
      fontFamily: "MB Corpo S Text",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "0.22px",
      color: "rgba(0, 0, 0, 0.6)",
      display: "flex",
      alignItems: "center",
      columnGap: "10px",
    },
  },
}));
