import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AliceIcon, AliceIconType, UserCard } from "@alice/component_library";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import ApplicationCard from "@components/UserCards/applicationCard";
import {
  SubTopicContainer,
  SubHeader,
  SubHeaderTypography,
  TaskRequestDetailsContainer,
  TaskRequestHeader,
} from "./StyledComponents";
import { RoleCard } from "./RoleCardComponent";
import WorkflowStepper from "../WorkflowStatusStepper/WorkflowStepper";

const AssignEntitlement = ({ taskInfo, isDeeplink, windowSize }) => {
  const { t } = useTranslation();
  const {
    originator = {},
    isWorkFlow,
    state,
    affectedData = {},
    id,
  } = taskInfo;
  const { entitlement = {} } = affectedData;
  const { isMobile } = windowSize;
  return (
    <TaskRequestDetailsContainer disabled={state !== "PENDING"}>
      <TaskRequestHeader>
        {t("task-type-details-header.assign-entitlement-details")}
      </TaskRequestHeader>

      <Grid container spacing={2}>
        <SubTopicContainer
          xs={12}
          sm={12}
          md={6}
          sx={{ width: "37vw", flexBasis: "100%" }}
        >
          <SubHeader>
            <RequesterIcon />
            <SubHeaderTypography>{t("requester")}</SubHeaderTypography>
          </SubHeader>

          <UserCard
            key={id}
            userData={originator}
            size={isMobile ? "small" : "medium"}
            readonly
            sx={{
              flexWrap: "wrap",
              "& .MuiTypography-body2": {
                whiteSpace: "nowrap",
              },
            }}
            isContextShown={true}
          />
        </SubTopicContainer>
        <SubTopicContainer xs={12} sm={12} md={6}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("entitlement")}</SubHeaderTypography>
          </SubHeader>

          <ApplicationCard
            appId={entitlement?.applicationId}
            entitlementName={entitlement?.name}
            entitlementDescription={entitlement?.description}
            dataClassification={entitlement?.dataClassification}
            appCard={false}
            sx={{ height: "80%" }}
          />
        </SubTopicContainer>
      </Grid>

      <Grid container>
        <SubTopicContainer xs={12}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("role")}</SubHeaderTypography>
          </SubHeader>
        </SubTopicContainer>
        <RoleCard taskInfo={taskInfo} isMobile={isMobile} />
      </Grid>
      {isWorkFlow && (
        <WorkflowStepper
          workflowId={id}
          isWorkFlow={isWorkFlow}
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
        />
      )}
    </TaskRequestDetailsContainer>
  );
};

export default AssignEntitlement;
