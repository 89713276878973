import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../constants'
import { UserCard } from '../UserCard'
import { UserSearch } from '../UserSearch'
import { UserGroupSearch } from '../UserGroupSearch'
import { UserGroupCard } from '../UserGroupCard'
import { TabNavigation } from '../../tabNavigation'
import { PeoplePickerAccordion } from './components/PeoplePickerAccordion'

export const PeoplePicker = ({
  isUserShown = true,
  isUserGroupShown = false,
  preselectedUsers = [],
  preselectedUserGroups = [],
  otherUserSearchProps = {},
  otherUserGroupSearchProps = {},
  onChange = ({
    isSelected,
    userId,
    userGroupId,
    user,
    userGroup,
    selectedUsers,
    selectedUserGroups
  }) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedUsers, setSelectedUsers ] = useState(preselectedUsers || [])
  const [ selectedUserGroups, setSelectedUserGroups ] = useState(preselectedUserGroups || [])

  const [ tabIndex, setTabIndex ] = useState(!isUserShown ? 1 : 0)

  const userSearchRef = useRef(null)
  const userGroupSearchRef = useRef(null)

  const disabledUserIds = otherUserSearchProps?.disabledIds
  const disabledUserGroupIds = otherUserGroupSearchProps?.disabledIds

  const toUserIds = (users) => users.map((user) => user.id)
  const toUserGroupIds = (userGroups) => userGroups.map((userGroup) => userGroup.uuid)

  const handleCardClickUser = (userId, isSelected, userObj) => {
    let newSelectedUsers = []

    if (
      otherUserSearchProps?.interaction === Interaction.SINGLE_SELECT
      || otherUserSearchProps?.interaction === Interaction.SINGLE_SELECT_WITHOUT_RADIO
    ) {
      newSelectedUsers = isSelected ? [ userObj ] : []
    } else {
      newSelectedUsers = isSelected
        ? [ ...selectedUsers, userObj ]
        : selectedUsers.filter((user) => user.id !== userId)
    }

    setSelectedUsers(newSelectedUsers)

    onChange({
      isSelected,
      userId,
      user: userObj,
      selectedUsers: newSelectedUsers,
      selectedUserGroups
    })
  }

  const handleCardClickUserGroup = (
    userGroupId,
    isSelected,
    userGroupObj
  ) => {
    let newSelectedUserGroups = []

    if (
      otherUserGroupSearchProps?.interaction === Interaction.SINGLE_SELECT
      || otherUserGroupSearchProps?.interaction === Interaction.SINGLE_SELECT_WITHOUT_RADIO
    ) {
      newSelectedUserGroups = isSelected ? [ userGroupObj ] : []
    } else {
      newSelectedUserGroups = isSelected
        ? [ ...selectedUserGroups, userGroupObj ]
        : selectedUserGroups.filter((userGroup) => userGroup.uuid !== userGroupId)
    }

    setSelectedUserGroups(newSelectedUserGroups)

    onChange({
      isSelected,
      userGroupId,
      userGroup: userGroupObj,
      selectedUserGroups: newSelectedUserGroups,
      selectedUsers
    })
  }

  const handleSelectAllUser = (selectedIds) => {
    // eslint-disable-next-line max-len
    const newSelectedUsers = selectedIds.map((selectedId) => userSearchRef.current.data.find((user) => user.id === selectedId)
      || selectedUsers.find((user) => user.id === selectedId))
      .filter((user) => !!user)

    setSelectedUsers(newSelectedUsers)

    onChange({ selectedUsers: newSelectedUsers, selectedUserGroups })
  }

  const handleSelectAllUserGroup = (selectedIds) => {
    // eslint-disable-next-line max-len
    const newSelectedUserGroups = selectedIds.map((selectedId) => userGroupSearchRef.current.data.find((userGroup) => userGroup.uuid === selectedId)
      || selectedUserGroups.find((userGroup) => userGroup.uuid === selectedId))
      .filter((userGroup) => !!userGroup)

    setSelectedUserGroups(newSelectedUserGroups)

    onChange({ selectedUserGroups: newSelectedUserGroups, selectedUsers })
  }

  const handleDeleteClickUser = (userObject) => {
    const { id: userId } = userObject
    const newSelectedUsers = selectedUsers.filter((user) => user.id !== userId)

    userSearchRef.current.setSelectedData(toUserIds(newSelectedUsers))
    setSelectedUsers(newSelectedUsers)

    onChange({
      isSelected: false,
      userId,
      user: userObject,
      selectedUsers: newSelectedUsers,
      selectedUserGroups
    })
  }

  const handleDeleteClickUserGroup = (userGroupObject) => {
    const { uuid: userGroupId } = userGroupObject
    const newSelectedUserGroups = selectedUserGroups.filter((userGroup) => userGroup.uuid !== userGroupId)

    userGroupSearchRef.current.setSelectedData(toUserGroupIds(newSelectedUserGroups))
    setSelectedUserGroups(newSelectedUserGroups)

    onChange({
      isSelected: false,
      userGroupId,
      userGroup: userGroupObject,
      selectedUserGroups: newSelectedUserGroups,
      selectedUsers
    })
  }

  return (
    <Stack data-testid="PeoplePicker" gap={3}>
      {isUserShown && (
        <PeoplePickerAccordion
          itemType="User"
          selectedItems={selectedUsers}
          getItemCardProps={(user) => ({
            size: 'xs',
            userData: user,
            onDeleteClick: !disabledUserIds?.includes(user.id)
              ? () => handleDeleteClickUser(user)
              : undefined
          })}
          ItemCard={UserCard}
        />
      )}

      {isUserGroupShown && (
        <PeoplePickerAccordion
          itemType="UserGroup"
          selectedItems={selectedUserGroups}
          getItemCardProps={(userGroup) => ({
            size: 's',
            userGroupData: userGroup,
            onDeleteClick: !disabledUserGroupIds?.includes(userGroup.uuid)
              ? () => handleDeleteClickUserGroup(userGroup)
              : undefined
          })}
          ItemCard={UserGroupCard}
        />
      )}

      {isUserShown && isUserGroupShown && (
        <TabNavigation
          onChange={setTabIndex}
          tabLabels={[
            t('peoplePicker.user'),
            t('peoplePicker.userGroup')
          ]}
          sx={{ marginTop: 2 }}
        />
      )}

      <div>
        {isUserShown && (
          <UserSearch
            ref={userSearchRef}
            views={[ 'list' ]}
            interaction={Interaction.MULTI_SELECT}
            preselectedRowIds={toUserIds(selectedUsers)}
            onCardClick={handleCardClickUser}
            onSelectAll={handleSelectAllUser}
            sx={[ tabIndex === 1 && { display: 'none', maxHeight: 0 } ]}
            {...otherUserSearchProps}
          />
        )}

        {isUserGroupShown && (
          <UserGroupSearch
            ref={userGroupSearchRef}
            views={[ 'list' ]}
            interaction={Interaction.MULTI_SELECT}
            preselectedRowIds={toUserGroupIds(selectedUserGroups)}
            onCardClick={handleCardClickUserGroup}
            onSelectAll={handleSelectAllUserGroup}
            sx={[ tabIndex === 0 && { display: 'none', maxHeight: 0 } ]}
            {...otherUserGroupSearchProps}
          />
        )}
      </div>
    </Stack>
  )
}

PeoplePicker.propTypes = {
  /** display User Search and Accordion, or not */
  isUserShown: PropTypes.bool,
  /** display UserGroup Search and Accordion, or not */
  isUserGroupShown: PropTypes.bool,
  /** Preselected Users. This requires ``id``, ``givenname`` and ``surname`` to display correctly */
  preselectedUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    givenname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired
  })),
  /** Preselected UserGroups. This requires ``uuid`` and ``name`` to display correctly */
  preselectedUserGroups: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  /** Custom UserSearch Props */
  otherUserSearchProps: PropTypes.shape({ disabledIds: PropTypes.arrayOf(PropTypes.string) }),
  /** Custom UserGroupSearch Props */
  otherUserGroupSearchProps: PropTypes.shape({ disabledIds: PropTypes.arrayOf(PropTypes.string) }),
  /**
   * Callback when data changes.
   *
   * Note that this function will exclusively utilize the selectedUsers parameter upon clicking ``selectAll``.
   * Consequently, it's important to verify the presence of other parameters before utilizing them.
   * Parameters such as ``userId``, ``isSelected``, and ``user`` are only accessible when the user interacts with the card.
   */
  onChange: PropTypes.func
}
