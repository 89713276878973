import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class AboutMeService {
  static me = ({ ...args }) => AliceService.get(
    '/me',
    { ...args }
  )

  static myAdminRoles = ({
    withAllData,
    ...args
  }) => AliceService.get(
    '/myadminroles',
    mergeObjects(
      { params: { withAllData } },
      { ...args }
    )
  )

  static myadministratedmarkets = ({ ...args }) => AliceService.get(
    '/myadministratedmarkets',
    { ...args }
  )
}
