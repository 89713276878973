import React, { Component, Fragment } from "react";
import "../Icon.css";
import "../App.css";
import NoEntitlementsIcon from "../pages/icons/roles/NoEntitlementsIcon";
import { withTranslation } from "react-i18next";

class NoEntitlementPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <span className="textcenter-search">
          <NoEntitlementsIcon />
          <h3 className="font-weight-600 font-18 m-0">
            {t("role-doesnot-have-entitlements")}{" "}
          </h3>
        </span>
      </Fragment>
    );
  }
}
// export default withRouter(withTranslation()(NoEntitlementPage));
export default withTranslation()(NoEntitlementPage);
