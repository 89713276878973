import React from "react";
import { useTranslation } from "react-i18next";
const NoInfo = (props) => {
  const { t } = useTranslation();
  return (
    <span>
      <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
        <title>
          {props.isUser ? t("user-is-not-logged-in-user") : t("role-not-found")}
        </title>
        <g
          id="Enhancements"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="3-Deeplink_User"
            transform="translate(-447.000000, -332.000000)"
          >
            <g
              id="NoRolesNoUsers"
              transform="translate(447.000000, 332.000000)"
            >
              <circle id="Oval" fill="#C8C8C8" cx="18" cy="18" r="18"></circle>
              <g id="task-list-lock" transform="translate(6.000000, 6.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <rect
                  id="Rectangle"
                  stroke="#444444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  x="12"
                  y="11.75"
                  width="9"
                  height="7.5"
                  rx="1"
                ></rect>
                <path
                  d="M19.5,11.75 L19.5,10.25 C19.5,8.59314575 18.1568542,7.25 16.5,7.25 L16.5,7.25 C14.8431458,7.25 13.5,8.59314575 13.5,10.25 L13.5,11.75"
                  id="Path"
                  stroke="#444444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M16.5,15.154 C16.7071068,15.154 16.875,15.3218932 16.875,15.529 C16.875,15.7361068 16.7071068,15.904 16.5,15.904 C16.2928932,15.904 16.125,15.7361068 16.125,15.529 C16.125,15.3218932 16.2928932,15.154 16.5,15.154"
                  id="Path"
                  stroke="#444444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <line
                  x1="3"
                  y1="6.5"
                  x2="10.5"
                  y2="6.5"
                  id="Path"
                  stroke="#444444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></line>
                <line
                  x1="3"
                  y1="10.25"
                  x2="7.5"
                  y2="10.25"
                  id="Path"
                  stroke="#444444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></line>
                <line
                  x1="3"
                  y1="14"
                  x2="7.5"
                  y2="14"
                  id="Path"
                  stroke="#444444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default NoInfo;
