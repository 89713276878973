import { Children, isValidElement } from 'react'
import { Box, Divider, Stack } from '@mui/material'
import { mergeSxProps } from '../../../../util'

const getValidChildren = (children) => Children.toArray(children).filter((child) => isValidElement(child))

export const CardDivided = ({
  action,
  context,
  isList = false,
  isTiny = false,
  isHorizontal = isList,
  isShown = true,
  isDividerShown = !isTiny || (isTiny && action),
  isContextShown = false,
  children,
  sx = {} || [],
  ...otherProps
}) => {
  const isChildren = !!getValidChildren(children).length
  const isAction = !!getValidChildren(action?.props?.children).length

  return isShown && (
    <Box
      className="divided"
      sx={mergeSxProps([
        {
          gridArea: 'divided',
          display: 'grid',
          gridTemplate: `
            ${isDividerShown ? `"divider ${isAction ? 'divider' : ''}"` : ''}
            "content ${isAction ? 'action' : ''}" 1fr
            / 1fr`,
          gap: 2,
          // overflow: 'hidden',
          '.dividedContent': {
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          },
          '.dividedAction': {
            position: 'relative',
            width: 40,
            height: 40,
            marginBlock: -1,
            '> *': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }
          }
        },
        isHorizontal && { gridTemplate: `"${isDividerShown && !isAction ? 'divider' : ''} ${isChildren ? 'content' : ''} ${isContextShown ? 'context' : ''} ${isAction ? 'divider action' : ''}" / ${isDividerShown && !isAction ? 'auto' : ''} 1fr` },
        isTiny && {
          gap: 1,
          '.dividedAction': { margin: -0.5 }
        },
        (isList || !!action) && { alignItems: 'center' }
      ], sx)}
      {...otherProps}
    >
      {isDividerShown && (
        <Divider
          orientation={isHorizontal ? 'vertical' : 'horizontal'}
          sx={{ gridArea: 'divider' }}
        />
      )}

      {isChildren && (
        <Stack className="dividedContent" sx={{ gridArea: 'content' }}>
          {children}
        </Stack>
      )}

      {isHorizontal && isContextShown && (
        <Box
          className="dividedContext"
          sx={[
            { gridArea: 'context' },
            !isTiny && isChildren && { marginLeft: 1 }
          ]}
        >
          {context}
        </Box>
      )}

      {isAction && (
        <Box className="dividedAction" sx={{ gridArea: 'action' }}>
          {action}
        </Box>
      )}
    </Box>
  )
}
