import {
  Breadcrumbs,
  Typography,
  IconButton,
  styled,
  Tabs,
  Tab,
  Grid,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Button, Dialog } from "@alice/component_library";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StartIcon from "@mui/icons-material/Start";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const SpinnerConatiner = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

export const SpinnerWrapper = styled(Dialog)({});

export const PercentageTableButtonWrapper = styled("div")({
  marginTop: "14px",
});

export const ButtonContainer = styled("div")({
  marginTop: "10px",
  "& button": {
    width: "100%",
  },
});

export const AccessReviewMainContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    margin: "0 20px",
  },
}));

export const AccessReviewContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
}));

export const AccessReviewHeadTypograghy = styled((props) => (
  <Typography variant="h1" {...props} />
))(({ ...props }) => ({
  fontSize: "48px",
  fontWeight: 400,
  lineHeight: "56px",
  marginBottom: "8px",
  fontFamily: "MB Corpo A Text Cond",
}));

export const AccessReviewSubHeadTypograghy = styled((props) => (
  <Typography variant="caption" {...props} />
))(({ ...props }) => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  fontFamily: "MB Corpo S Text",
  color: "#333",
}));

export const AccessReviewHeaderContainer = styled("div")(({ theme }) => ({
  marginTop: "24px",
}));

export const AccessReviewSearchContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginTop: "32px",
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    color: "rgba(0, 0, 0, 0.60)",
    textAlign: "center",
    fontFamily: "MB Corpo S Text",
    fontSize: "14px",
    fontWeight: "700",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
  },
  "&.Mui-selected": {
    color: "#0078D6",
  },
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-indicator": {
    backgroundColor: "#0078D6",
    height: "2px",
  },
}));

export const StyledTabsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
  [theme.breakpoints.up("md")]: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

export const StyledTabsIconContainer = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  "& .view-label": {
    color: "rgba(0, 0, 0, 0.60)",
    /* Body/Body 2 - 14px - MB Corpo S Text */
    fontFamily: "MB Corpo S Text",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
}));

export const StyledTabsLabelContainer = styled("div")(({ theme }) => ({
  "& .label-container": {},
  "& .badge-container": {
    marginLeft: "8px",
    display: "flex",
    padding: "2px 8px",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "10px",
    background: "#0078D6",
    "& .icon-container": {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "MB Corpo S Text",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "16px",
    },
  },
}));

export const FIlterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  // flexWrap: "wrap",
  gap: "20px",
  alignItems: "center",
  justifyContent: "space-between",
  "& #sort": {
    width: "25%",
  },
}));

export const FilterStatusContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  gap: "20px 40px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const GridCards = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "20px 20px 0px",
  },
}));
export const ProgressBar = styled("div")(({ theme }) => ({
  width: "65%",
}));
// role review process styles
export const RoleReviewDetailsContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: "20px 0",
  [theme.breakpoints.down("lg")]: {
    margin: "0 20px",
  },
  "& .sub-detail-header": {
    marginTop: "48px",
    display: "flex",
    gap: "32px 43px",
    flexWrap: "wrap",
    paddingBottom: "32px",
  },
  "& .sub-detail-status": {
    marginBottom: "64px",
  },
  "& .no-data": {
    textAlign: "center",
    marginTop: "50px",
  },
}));

export const StyledLink = styled((props) => <Link {...props} />)(() => ({
  fontFamily: "MB Corpo S Text",
  fontStyle: "normal",
  lineHeight: "24px",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
  fontWeight: 700,
  marginBottom: "32px",
  color: "#0078D6",
  cursor: "pointer",
  "&:hover": { color: "#0078D6" },
  "& svg": {
    fontSize: "14px",
  },
}));

export const BreadcrumbOptions = styled((props) => <Link {...props} />)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "none",
    color: props.active ? "#000000DE" : "#00000099",
    cursor: props.active ? "default" : "pointer",
    "&:hover": { color: props.active ? "#000000DE" : "#00000099" },
  })
);

export const ReviewProcessRoleId = styled((props) => (
  <Typography variant="h1" noWrap {...props} />
))(() => ({
  fontFamily: "MB Corpo A Text Cond",
  marginBottom: "8px",
  width: "calc(100vw - 40px)",
  maxWidth: "-webkit-fill-available",
}));

export const ReviewProcessRoleDescp = styled((props) => (
  <Typography variant="h6" noWrap {...props} />
))(() => ({
  marginBottom: "24px",
  fontSize: "16px",
  lineHeight: "24px",
  width: "calc(100vw - 40px)",
  maxWidth: "-webkit-fill-available",
}));

export const ReviewProcessSubheaders = styled((props) => (
  <Typography {...props} />
))(() => ({
  color: "rgba(0, 0, 0, 0.38)",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
}));

export const OpenReviewsActionButtonContainer = styled("div")(({ theme }) => ({
  paddingLeft: "20px",
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
  left: "25px",
  marginTop: "10px",
  zIndex: 100,
  [theme.breakpoints.up("lg")]: {
    position: "absolute",
  },
  [theme.breakpoints.down("sm")]: {
    display: "grid",
  },
}));

export const OpenReviewsContainer = styled("div")(({ theme }) => ({
  position: "relative",
  "& table thead tr th": {
    "&:last-child": {
      textAlign: "right !important",
    },
    padding: "5px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    cursor: "text !important",
  },
  "& table tbody tr td": {
    padding: "5px !important",
  },
  "& .text-center": {
    textAlign: "center !important",
  },
}));

export const ActionButton = styled((props) => <Button {...props} />)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Text",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    backgroundColor: props.reject ? "#198025" : props.success && "#D92121",
    color: !props.disabled && "#FFF",
    "&:hover": {
      backgroundColor: props.reject ? "#115919" : props.success && "#971717",
    },
    "& svg": {
      color: !props.disabled && "#FFFFFF",
      marginRight: "4px",
    },
  })
);

export const PendingActionCellContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  gap: "10px",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "125px",
  },
  [theme.breakpoints.down("lg")]: {
    marginTop: "20px",
  },
}));

export const DecisionMakerContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "end",
  width: "100%",
  alignItems: "center",
}));

export const StyledIconButtons = styled((props) => (
  <IconButton size="small" {...props} />
))(({ ...props }) => ({
  backgroundColor: props.backgroundcolor,
  width: props.light ? "34px" : "28px",
  height: props.light ? "34px" : "28px",
  cursor: props.light ? "none" : "pointer",
  pointerEvents: props.light ? "none" : "default",
  border: props.light ? "2px solid white" : 0,
  "&:hover": {
    backgroundColor: props.light ? props.backgroundcolor : props.hover,
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: "#00000061",
  },
  "& svg": {
    color: props.light ? "#000000" : props.disabled ? "#00000061" : "#ffffff",
    fontSize: props.light ? "21px" : "18px",
  },
}));

export const MoreDetailsContainer = styled((props) => (
  <Grid container direction="row" alignItems="center" {...props} />
))(() => ({
  marginTop: "32px",
  "& .leftColumn": {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
    "& svg": { color: "#00000099", fontSize: "18px", minWidth: "18px" },
  },
  "& .MuiGrid-root": {
    marginBottom: "24px",
  },
}));

export const MoreDecisionMakerContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "24px 8px",
}));

export const ModificationContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  columnGap: "43px",
  rowGap: "24px",
  flexWrap: "wrap",
  "& .strike-date": {
    textDecoration: "line-through",
    marginLeft: "4px",
    color: "rgba(0, 0, 0, 0.60)",
    textAlign: "center",
    fontFamily: "MB Corpo S Title",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .date-container": {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "rgba(0, 0, 0, 0.60)",
  },
  "& .current-assigned-date": {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    fontFamily: "MB Corpo S Title",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
  },
}));

const ReviewGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginTop: "18px",
  },
  marginBottom: "32px",
  "& .grid-pagination": {
    "& .MuiTablePagination-displayedRows, .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-selectLabel": {
      display: "block",
    },
    "& .MuiToolbar-root": {
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-end",
      },
    },
  },

  "& .MuiTablePagination-root": {
    width: "100%",
  },
  "& .MuiToolbar-root": {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      "& .MuiInputBase-root": {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
  "& .MuiTablePagination-displayedRows,.MuiTablePagination-selectLabel,.MuiTablePagination-spacer":
    {
      //.MuiTablePagination-displayedRows,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  "& .MuiTablePagination-actions": {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      columnGap: "26px",
      marginLeft: 0,
      "& .MuiButtonBase-root:not(.Mui-disabled)": {
        backgroundColor: "rgba(0, 120, 214, 1)",
        color: "#FFF",
      },
    },
  },
}));

const AdminSwitchContainer = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  [theme.breakpoints.up("md")]: {
    marginBottom: "32px",
  },
}));

const AccesReviewHeaderWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  rowGap: "32px",
});

export const ReviewStatusbar = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
}));
export const AssignmentsContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "24px",
  width: "100%",
  alignItems: "center",
  height: "46px",
  padding: "0px 16px",
  "& .assignment-container": {
    display: "flex",
    flexDirection: "row",
    columnGap: "12px",
    width: "100%",
    alignItems: "center",
    "& .assignment-containers": {
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  "& .assignment-containers": {
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export const NewtableContainer = styled("div")(({ theme, ...props }) => ({
  position: "relative",
  marginBottom: "24px",
  "& .MuiCheckbox-colorPrimary": {
    visibility: props.isSelectAll && "hidden",
  },

  "& .table .tableTitle:first-of-type, .tableTitle:first-of-type .MuiButtonBase-root":
    {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        justifyContent: "end",
      },
    },

  "& .table .tableTitle": {
    "&:has(.disable-sort)": {
      pointerEvents: "none",
    },
  },

  "& .tableRow": {
    "> div": {
      backgroundColor: props.isSelectAll && "rgb(214, 233, 248)",
    },
    "& .id-column": {
      display: "flex",
      gap: "8px",
      alignItems: "center",

      [theme.breakpoints.down(500)]: {
        width: "30vw",
      },
      [theme.breakpoints.down(375)]: {
        flexDirection: "column",
        width: "20vw",
      },
      "& .userId": {
        width: "inherit",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "noWrap",
      },
    },

    // "& .tableEntry:last-child ": {
    //   display: "block !important",
    // },

    "& .tableEntry:first-of-type,.tableEntry:first-of-type .MuiButtonBase-root":
      {
        [theme.breakpoints.down("sm")]: {
          padding: 0,
        },
      },

    "& .tableEntry": {
      "& .info-orgScope": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
      },
      "& .data-elipsis": {
        display: "block",
        width: "125px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& .text-center": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },

      "&:nth-of-type(2)": {
        [theme.breakpoints.down("sm")]: {
          "& .MuiAvatar-root": {
            height: "32px",
            width: "32px",
            fontSize: "14px",
          },
          "& .MuiBadge-badge .MuiBox-root": {
            minWidth: "14px",
            minHeight: "14px",
            height: "14px",
            width: "14px",
          },
        },
      },
    },

    "& .custom-context": {
      display: "flex",
    },

    "& .tableExpandContainerInner": {
      border: "1px solid rgba(0, 0, 0, 0.60)",
    },
  },

  "& .MuiTablePagination-root": {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },

  "& .MuiTablePagination-toolbar": {
    // "& .MuiToolbar-root": {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: props.hasPagination ? "flex-end" : "space-between",
    },
    // },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      "& .MuiInputBase-root": {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },

  "& .MuiTablePagination-displayedRows,.MuiTablePagination-selectLabel,.MuiTablePagination-spacer":
    {
      //.MuiTablePagination-displayedRows,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

  "& .MuiTablePagination-actions": {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      columnGap: "26px",
      marginLeft: 0,
      "& .MuiButtonBase-root:not(.Mui-disabled)": {
        backgroundColor: "rgba(0, 120, 214, 1)",
        color: "#FFF",
      },
    },
  },
}));

export const TypographyWithCopyButton = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",
}));

export {
  Typography,
  Grid,
  Chip,
  Breadcrumbs,
  CheckCircleOutlineIcon,
  CancelOutlinedIcon,
  EditOutlinedIcon,
  CheckIcon,
  CloseIcon,
  CalendarMonthOutlinedIcon,
  CallSplitIcon,
  TextsmsOutlinedIcon,
  HighlightOffOutlinedIcon,
  KeyboardTabIcon,
  StartIcon,
  ArrowBackIosIcon,
  AdminSwitchContainer,
  ReviewGridContainer,
  AccesReviewHeaderWrapper,
};
