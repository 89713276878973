import CardContent from "@mui/material/CardContent";
import { CardContainer } from "./Styles";
import { Stack } from "@mui/material";
import TActionButtons from "./TActions";
import { cardCase } from "./CardCase";
import {
  CardDetailHeader,
  CardDetailDescription,
  CardApplicationDetails,
} from "./Styles";
import { useTranslation } from "react-i18next";

const TCard = ({
  taskInfo,
  handleApprove,
  handleReject,
  taskDeepLink = false,
}) => {
  const { t } = useTranslation();
  console.log(useTranslation(), "trans");

  const {
    assignValuLeftSvg,
    assignValueLeft,
    operationalIcon,
    assignValueRightSvg,
    assignValueRight,
    assignedUserId,
    smsIcon,
    rejectReasonIcon,
    rejectReason,
    applicationReasonHeader,
    rejectReasonHeader,
    applicationReason,
    entitlementId,
    cardState,
    detail,
  } = cardCase(taskInfo, t);

  return (
    <CardContainer>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        useFlexGap
        flexWrap="wrap"
      >
        <CardContent
          sx={{
            width: "-webkit-fill-available",
            padding: "0px !important",
          }}
        >
          <CardDetailHeader>
            <div className="head-left">
              <div className="header-svg-left">{assignValuLeftSvg}</div>
              <div className="header-value-left">
                {assignValueLeft}
                {entitlementId && <span>({entitlementId})</span>}
              </div>
            </div>
            <div className="header-svg-operation-icon">{operationalIcon}</div>
            <div className="head-right">
              <div className="header-svg-right">{assignValueRightSvg}</div>
              <div className="header-value-right">
                {assignValueRight}
                {assignedUserId && <span>({assignedUserId})</span>}
              </div>
            </div>
          </CardDetailHeader>

          <CardDetailDescription
            dangerouslySetInnerHTML={{ __html: detail }}
          ></CardDetailDescription>
        </CardContent>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          display: "inherit",
          justifyContent: "space-between",
        }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        useFlexGap
        flexWrap="wrap"
      >
        <CardApplicationDetails>
          {applicationReason && applicationReason.length ? (
            <CardContent
              className="appHead"
              sx={rejectReason && rejectReason.length ? { width: "48%" } : {}}
            >
              <div className="smsIcon">
                {smsIcon}
                <span>{applicationReasonHeader}</span>
              </div>
              <div className="applicationReasonText">{applicationReason}</div>
            </CardContent>
          ) : null}

          {rejectReason && (
            <CardContent
              className="appHead"
              sx={
                applicationReason && applicationReason.length
                  ? { width: "48%" }
                  : { width: "60%" }
              }
            >
              <div className="smsIcon">
                {rejectReasonIcon}
                <span>{rejectReasonHeader}</span>
              </div>
              <div className="applicationReasonText">{rejectReason}</div>
            </CardContent>
          )}
          {cardState === "PENDING" && !taskDeepLink && (
            <CardContent
              className="Button"
              sx={{ alignItems: "end", padding: "0 !important" }}
            >
              <TActionButtons
                onApprove={handleApprove}
                onReject={handleReject}
              />
            </CardContent>
          )}
        </CardApplicationDetails>
      </Stack>
    </CardContainer>
  );
};

export default TCard;
