import React, { memo, useState, useEffect } from "react";
import { OrgSearchGridViewRenderer } from "@alice/component_library";
import { styled } from "@mui/material/styles";
import { Checkbox, FormControlLabel, Badge } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: 13,
    padding: "0 4px",
  },
}));

const PreviousOrgScopeSearch = memo(
  ({ selectedTableInfo, selectedFullScopes, updateOrgArray }) => {
    const { t } = useTranslation();
    const { width } = window.screen;
    const isMobile = width < 420;
    const compact = !isMobile;

    const [selectAll, setSelectAll] = useState(false);
    const [selectedDataArray, setSelectedDataArray] = useState([]);
    const [selectedIntailDataArray, setSelectedIntialDataArrayDataArray] =
      useState([]);
    const [selectedEntry, setSelectedEntry] = useState([]);

    const updateParentComponent = (data) => {
      const concatinatedSelecetdScope = selectedFullScopes.concat([]);
      // Create objects to store IDs from intail data and selected data
      const idsObjIntialData = {};
      const idselectedDataObject = {};

      selectedIntailDataArray.forEach((item) => {
        idsObjIntialData[item.orgId] = true;
      });

      data.forEach((item) => {
        idselectedDataObject[item.orgId] = true;
      });

      // Iterate through selected data, check for additions, and add to result
      data.forEach((item) => {
        if (!idsObjIntialData[item.orgId]) {
          concatinatedSelecetdScope.push(item);
        }
      });

      // Iterate through array2, check for removals, and remove from array1
      selectedIntailDataArray.forEach((item) => {
        if (!idselectedDataObject[item.orgId]) {
          const index = concatinatedSelecetdScope.findIndex(
            (el) => el.orgId === item.orgId
          );
          if (index !== -1) {
            concatinatedSelecetdScope.splice(index, 1);
          }
        }
      });

      const uniqueIds = {};
      const result = concatinatedSelecetdScope.filter((item) => {
        if (!uniqueIds[item.orgId]) {
          uniqueIds[item.orgId] = true;
          return true;
        }
        return false;
      });

      updateOrgArray(result);
    };

    const onSelectAllChange = () => {
      if (selectAll) {
        setSelectedEntry([]);
        setSelectedDataArray([]);
        updateParentComponent([]);
      } else {
        const selectedIds = selectedTableInfo.map((item) => item.orgId);
        setSelectedEntry(selectedIds);
        setSelectedDataArray(selectedTableInfo);
        updateParentComponent(selectedTableInfo);
      }
      setSelectAll(!selectAll);
    };

    useEffect(() => {
      const seletedDataOfPreviousOrg = selectedFullScopes.filter(
        (selectedScope) => {
          return selectedTableInfo.some(
            (tableScope) => tableScope.orgId === selectedScope.orgId
          );
        }
      );
      const selectedIds = seletedDataOfPreviousOrg.map((item) => item.orgId);
      const isSlecetAll =
        selectedIds.length === selectedTableInfo.length ? true : false;
      setSelectedIntialDataArrayDataArray(seletedDataOfPreviousOrg);
      setSelectedDataArray(seletedDataOfPreviousOrg);
      setSelectedEntry(selectedIds);
      updateOrgArray(selectedFullScopes);
      setSelectAll(isSlecetAll);
    }, [selectedTableInfo]);

    useEffect(() => {
      const seletedDataOfPreviousOrg = selectedFullScopes.filter(
        (selectedScope) => {
          return selectedTableInfo.some(
            (tableScope) => tableScope.orgId === selectedScope.orgId
          );
        }
      );
      const selectedIds = seletedDataOfPreviousOrg.map((item) => item.orgId);
      setSelectedIntialDataArrayDataArray(seletedDataOfPreviousOrg);
      setSelectedDataArray(seletedDataOfPreviousOrg);
      setSelectedEntry(selectedIds);
    }, [selectedFullScopes]);

    const onItemClickHandler = (data) => {
      let selectedScopes = selectedDataArray;

      if (data.isSelected) {
        selectedScopes = [
          ...selectedScopes,
          {
            orgScope: data.data.name,
            groupType: data.data.orgForm,
            orgId: data.data.id,
            ...data.data,
          },
        ];

        setSelectedEntry((prevData) => [...prevData, data.id]);
      } else {
        selectedScopes = selectedScopes.filter(
          (scope) => scope.orgId !== data.id
        );

        setSelectedEntry((prevData) => prevData.filter((id) => id !== data.id));
      }
      const isSlecetAll =
        selectedScopes.length === selectedTableInfo.length ? true : false;
      setSelectAll(isSlecetAll);
      setSelectedDataArray(selectedScopes);
      updateParentComponent(selectedScopes);
    };

    return (
      <>
        {selectedEntry.length ? (
          <span
            style={{
              fontFamily: "MB Corpo S Text",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: "rgba(0, 0, 0, 0.6)",
              marginTop: "10px",
              paddingRight: "14px",
            }}
          >
            <StyledBadge badgeContent={selectedEntry.length} color="primary">
              {t("please-select-org-scope")}
            </StyledBadge>
          </span>
        ) : (
          <span
            style={{
              fontFamily: "MB Corpo S Text",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: "rgba(0, 0, 0, 0.6)",
              marginTop: "10px",
            }}
          >
            {t("please-select-org-scope")}
          </span>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <FormControlLabel
            checked={selectAll}
            onChange={() => onSelectAllChange()}
            control={
              <Checkbox
                indeterminate={
                  selectedEntry.length &&
                  selectedEntry.length !== selectedTableInfo.length
                    ? true
                    : false
                }
              />
            }
            label={`${t("select-all")}  `}
            sx={{ marginLeft: "8px" }}
          />
          <OrgSearchGridViewRenderer
            isMobile={compact}
            data={selectedTableInfo ?? []}
            view="list"
            interaction="multiSelect"
            onItemClick={(data) => onItemClickHandler(data)}
            selectedData={selectedEntry}
          />
        </div>
      </>
    );
  }
);

export default PreviousOrgScopeSearch;
