// Constants for new deeplink request page
export const FIRST_STEP = 0;
export const SECOND_STEP = 1;
export const THIRD_STEP = 2;
export const FOURTH_STEP = 3;
export const FIFTH_STEP = 4;

export const MIN_LENGTH_REASON = 20;

export const HOURS_IN_A_DAY = 24;
export const MINUTES_IN_AN_HOUR = 60;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const SECONDS_IN_A_MINUTE = 60;
export const DEFAULT_VALIDITY_DAYS = 180;
export const TEST_DEFAULT_VALIDITY_DAYS = 90;

export const SUCCESS_STATUS_CODE = 200;
export const BAD_REQUEST_STATUS_CODE = 400;
export const UNAUTHORIZED_STATUS_CODE = 401;
export const INTERNAL_SERVER_ERROR_STATUS_CODE = 500;
export const CUSTOM_STATUS_CODE_1 = 300;
export const CUSTOM_STATUS_CODE_2 = 600;
