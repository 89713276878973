import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotification } from '../notification'

export const useCopyToClipboard = ({
  resetTime = 0,
  isNotifyingOnSuccess = false,
  notificationMessageSuccess,
  isNotifyingOnError = true,
  notificationMessageError
}) => {
  const { t } = useTranslation('componentLibrary')
  const { notify } = useNotification()

  const [ isCopied, setIsCopied ] = useState(false)

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setIsCopied(true)

        if (isNotifyingOnSuccess) {
          notify({
            severity: 'success',
            message: notificationMessageSuccess || t('copyToClipboard.success')
          })
        }

        if (resetTime) {
          setTimeout(() => setIsCopied(false), resetTime)
        }
      })
      .catch((error) => {
        if (isNotifyingOnError) {
          notify({
            severity: 'error',
            message: notificationMessageError || t('copyToClipboard.error')
          })
        }

        console.error('Failed to copy:', error)
      })
  }

  return {
    isCopied,
    setIsCopied,
    copyToClipboard
  }
}
