import { Fragment } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { createArrayWithInitialValues } from '../../../util'
import { useInteraction } from '../../../hooks'
import { DefaultGridViewRenderer } from '../../search'
import { AppCard, AppCardDetails, useAppCard } from '../appCard'

/**
 * This component renders a grid view utilizing `AppCard` components.
 * Usage of MUI-Grid with `AppCard` is recommended for optimal layout and responsiveness.
 *
 * Important Notes:
 * - This is a presentation component and does not handle data management.
 *   `selectedData` and `expandedApplication` states should be managed by a parent component.
 * - It is designed to complement the `AppSearch` or `GenericSearch` components.
 * - On mobile devices, the view mode 'tiny' is automatically applied for better usability.
 */
export const AppSearchGridViewRenderer = ({
  view = 'grid',
  size = 'm',
  data: applications,
  selectedData: selectedApplications = [],
  disabledData: disabledApplications = [],
  onItemClick,
  rowsPerPage,
  interaction,
  onGetHref,
  isLoading,
  isIdLink,
  isMobile,
  expandedApplication,
  expandedTableProps = { getResponseData: (data) => data.entitlements },
  onExpandClick,
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  const originalInteraction = interaction
  const expandableSx = { gridColumn: '1 / -1' }
  const isExpandable = onExpandClick

  if (isMobile) view = 'list'
  if (isExpandable && interaction === Interaction.NONE) interaction = Interaction.SINGLE_SELECT_WITHOUT_RADIO

  const isList = view === 'list'

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({ interaction })

  const hasCheckbox = isSelectable && !isSelectHidden

  const mappableApplications = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({ id: index.toString() }))
    : applications

  const isExpanded = (appId) => {
    if (!isExpandable) return false

    return expandedApplication
      && expandedApplication === appId
      && !isLoading
  }

  const getCardClickFunction = () => {
    if (interaction === Interaction.NONE
      || interaction === Interaction.NAVIGATE
      || isLoading
    ) return undefined

    if (onExpandClick
      && !(
        originalInteraction === Interaction.SINGLE_SELECT
        || originalInteraction === Interaction.MULTI_SELECT
        || originalInteraction === Interaction.SINGLE_SELECT_WITHOUT_RADIO
        || originalInteraction === Interaction.MULTI_SELECT_WITHOUT_CHECKBOX
      )
    ) return onExpandClick

    return onItemClick
  }

  const {
    isContextShown: _isContextShown,
    isDescriptionShown: _isDescriptionShown,
    isClassificationShown: _isClassificationShown,
    isStatusShown: _isStatusShown,
    onExpandClick: _onExpandClick,
    onDeleteClick: _onDeleteClick
  } = otherProps || {}

  const {
    isDividedShown,
    subgridContentColumns,
    subgridContentRows
  } = useAppCard({
    size,
    isList: view === 'list',
    isRenderer: true,
    _isContextShown,
    _isDescriptionShown,
    _isClassificationShown,
    _isStatusShown,
    _onExpandClick,
    _onDeleteClick
  })

  return (
    <DefaultGridViewRenderer
      testId="AppSearchGridViewRenderer"
      isList={isList}
      isDividedShown={isDividedShown}
      hasCheckbox={hasCheckbox}
      subgridContentColumns={subgridContentColumns}
    >
      {mappableApplications.map((application) => (
        <Fragment key={application.id}>
          <AppCard
            variant={view}
            size={size}
            data={application}
            interaction={interaction}
            href={onGetHref}
            isLoading={isLoading}
            isSelected={selectedApplications.includes(application.id)}
            isDisabled={disabledApplications.includes(application.id)}
            isIdLink={isIdLink}
            isExpanded={isExpanded(application.id)}
            onCardClick={getCardClickFunction()}
            onExpandClick={onExpandClick}
            isSubgrid
            subgridContentColumns={subgridContentColumns}
            subgridContentRows={subgridContentRows}
            {...otherProps}
          />

          {isExpanded(application.id) && (
            <Box sx={expandableSx}>
              <AppCardDetails
                id={application.id}
                name={application.name}
                description={application.description}
                url={application.url}
                email={application.email}
                tableProps={expandedTableProps}
              />
            </Box>
          )}
        </Fragment>
      ))}
    </DefaultGridViewRenderer>
  )
}

AppSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  /** item size-variant */
  size: PropTypes.oneOf([ 's', 'm', 'l' ]),
  /** Apps to be displayed */
  data: PropTypes.arrayOf(PropTypes.shape({
    /**
     * A base64-encoded image data representing the logo to be displayed.
     * If not provided, a default logo will be used.
     */
    logo: PropTypes.string,
    /** Application ID */
    id: PropTypes.string,
    /** Application url */
    url: PropTypes.string,
    /** Support email */
    email: PropTypes.string,
    /** Application name */
    name: PropTypes.string
  })).isRequired,
  /** Selected apps */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled apps */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Loading state */
  isLoading: PropTypes.bool,
  /** Determines if ID of card is a link or not */
  isIdLink: PropTypes.bool,
  /** True if mobile viewport */
  isMobile: PropTypes.bool,
  /** List of expanded applications */
  expandedApplication: PropTypes.arrayOf(PropTypes.string),
  /**
   * Props to be spread into [NewTable](./?path=/docs/alice-ui-table-newtable--docs) component.
   * Only getting used, when `isExpandable` is set to true and application is expanded.
   *
   * Note, that `getResponseData` is defaulted to `(data) => data.entitlements`.
   * If you need to overwrite this property please make sure to add your correct function.
   * In fact, that is required.
   */
  expandedTableProps: PropTypes.object,
  /** Callback when expand button is clicked */
  onExpandClick: PropTypes.func
}
