import PropTypes from 'prop-types'
import { ListItemButton, Typography } from '@mui/material'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import { IconWrapper } from '../../../icon'

export const NavigationMenuListTitle = ({
  title,
  isExpanded,
  onClick
}) => (
  <ListItemButton
    sx={[
      {
        display: 'flex',
        justifyContent: 'flex-end',
        height: 48,
        borderBottom: '1px solid',
        borderColor: 'divider',
        '.NML_Title': {
          '&Icon': {
            alignItems: 'center',
            justifyContent: 'center',
            width: '42px',
            height: '42px'
          },
          '&Text': {
            position: 'absolute',
            left: 27,
            width: 'var(--textWidth)',
            opacity: isExpanded ? 1 : 0,
            transition: isExpanded ? 'opacity 150ms 100ms' : 'opacity 150ms'
          }
        }
      },
      isExpanded && { pl: '27px' },
      !isExpanded && { justifyContent: 'center' }
    ]}
    onClick={onClick}
  >
    <Typography className="NML_TitleText" variant="h5">{title}</Typography>

    <IconWrapper
      className="NML_TitleIcon"
      size={24}
      color="text.primary"
      sx={[
        { transition: 'transform 250ms', p: '9px' },
        !isExpanded && { transform: 'rotate(180deg)' }
      ]}
    >
      <KeyboardDoubleArrowLeftIcon />
    </IconWrapper>
  </ListItemButton>
)

NavigationMenuListTitle.propTypes = {
  title: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func
}
