import React, { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { blue, grey } from '@mercedes-benz/mui5-theme'
import { Box, Pagination, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useGlobalFilter, usePagination, useResizeColumns, useRowState, useSortBy, useTable } from 'react-table'
import { useRowSelect } from 'react-table/dist/react-table.development'
import { KeyboardArrowDown, KeyboardArrowUp, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { format } from '../../../util'
import { AliceIcon, AliceIconType, IconWrapper } from '../../icon'
import { SearchBarAnimated } from '../../search/searchBarAnimated/SearchBarAnimated' // Can't be shortened
import { ExpandContainer, GlobalFilter, IconLeft, IconRight, IndeterminateCheckboxOrRadiobutton, ItemsPerPageDropdown } from '../subComponents'

/**
 * A data table
 *
 * ref property can be used to get a reference of the react-table.<br>
 * For example these two methods to change selected state
 * - toggleAllRowsSelected
 * - toggleRowSelected
 *
 * Check "tableInstance" for reference
 */
export const Table = forwardRef((
  {
    columns: tableColumns,
    data: tableData,
    manualSortBy = false,
    // controlled from outside (load data with ajax...)
    controlledFetchData = null,
    controlledIsLoading = false,
    controlledOnSearch = () => {},
    controlledOnSearchClearInputfield = () => {},
    // this needs to be set from outside!
    getRowId,
    // in case of a search this needs to be set from outside!
    pageCount: controlledPageCount = 0,
    // in case of a search this needs to be set from outside!
    pageIndex: controlledPageIndex = 0,
    setSelectedRows = () => {},
    // controlled from outside (load data with ajax...)
    onPageUpdate = () => {},
    multiselect = false,
    itemsPerPage = 20,
    selectableItemsPerPage = [ '2', '5', '10', '20', '50' ],
    showItemsPerPageDropdownAtTop = true,
    showItemsPerPageDropdownAtBottom = true,
    showPaginationAtTop = true,
    showPaginationAtBottom = true,
    showRadiobuttonOnSingleSelect = false,
    showSearch = false,
    searchPlaceholder = '',
    showSelectedItemsInfo = false,
    isRowExpanded = (rowData) => ({
      isExpanded: false,
      expandProps: {
        heading: '',
        children: '',
        onClose: () => {},
        contentSx: {}
      }
    }),
    selectedItemsInfoText = {
      single: 'Selected rowId',
      multiple: 'Selected rowId’s'
    },
    infoText = {
      single: 'user shown',
      multiple: 'users shown'
    },
    totalCount = tableData.length,
    selectedRows = {},
    readOnly = false,
    preselectedRowIds = [],
    toolbar = null,
    debug = false,
    noDataPlaceholder = <Typography>No data.</Typography>,
    isRowDisabled = (rowData, id) => {},
    isRowNotSelectable = (rowData, id) => {},
    onSelect = (selectedRows, selectedRowIds) => {},
    onRowClick: _onRowClick = ({
      row,
      isChecked,
      event
    }) => {},
    onToggleAllInPage = ({
      changedRows,
      isChecked
    }) => {},
    ...otherProps
  },
  ref
) => {
  const [ hasClickedUpdate, hasClicked ] = useReducer((x) => x + 1, 0)

  const tableIsControlled = typeof controlledFetchData === 'function'

  // prepare data
  const data = useMemo(() => tableData.map((item) => {
    const elm = {}

    item.forEach((entry, j) => (elm[`col${j}`] = entry))

    return elm
  }), [ tableData ])

  // prepare columns
  const columns = useMemo(() => tableColumns.map((item, i) => ({
    accessor: `col${i}`,
    ...item
  })), [ tableColumns ])

  // select all rows in current page, but ignore disabled and not selectable rows
  const toggleAllPageRowsSelected = (instance, isChecked) => {
    const { page } = instance
    const changedRows = []

    page.forEach((row) => {
      const { disabled, isNotSelectable } = row.getRowProps()
      const isDisabled = (disabled && isNotSelectable) || (disabled) || (isNotSelectable)

      if (!isDisabled && ((row.isSelected && !isChecked) || (!row.isSelected && isChecked))) {
        row.toggleRowSelected()

        changedRows.push(row)
      }
    })

    onToggleAllInPage({
      changedRows,
      isChecked
    })

    hasClicked()
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy,
      initialState: {
        pageIndex: controlledPageIndex || 0,
        pageSize: typeof itemsPerPage === 'number' ? itemsPerPage : 10
      },
      manualPagination: !!tableIsControlled,
      autoResetSelectedRows: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      ...(tableIsControlled && { pageCount: controlledPageCount }),
      getRowId,
      state: { selectedRowPaths: selectedRows },
      stateReducer: (state, action) => {
        // this state reducer prevents memorizing selected row when new data is fetched and `!multiselect`
        if (action.type === 'toggleRowSelected' && Object.keys(state.selectedRowIds).length && !multiselect) {
          const newState = { ...state }

          newState.selectedRowIds = { [action.id]: true }

          return newState
        }

        return state
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowState,
    useRowSelect,
    // eslint-disable-next-line consistent-return
    (hooks) => {
      if (isRowDisabled) {
        hooks.getRowProps.push((prevProps, { row }) => {
          const { id } = row

          return {
            ...prevProps,
            disabled: isRowDisabled(row, id)
          }
        })
      }

      if (isRowNotSelectable) {
        hooks.getRowProps.push((prevProps, { row }) => {
          const { id } = row

          return {
            ...prevProps,
            isNotSelectable: isRowNotSelectable(row, id)
          }
        })
      }

      if (!multiselect && !showRadiobuttonOnSingleSelect) return null

      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox or getToggleAllPageRowsSelectedProps
          Header: ({
            getToggleAllPageRowsSelectedProps,
            ...tableData
          }) => {
            // single select and show radiobutton
            if (!multiselect && showRadiobuttonOnSingleSelect) return null

            let nonDisabledRows = 0
            let selectedNonDisabledRows = 0

            tableData.page.forEach((row) => {
              if (!isRowDisabled(row, row.id) && !isRowNotSelectable(row, row.id)) {
                nonDisabledRows++

                if (row.isSelected) selectedNonDisabledRows++
              }
            })

            const isChecked = nonDisabledRows > 0 && nonDisabledRows === selectedNonDisabledRows
            const isIndeterminate = selectedNonDisabledRows > 0 && selectedNonDisabledRows < nonDisabledRows

            // Show checkbox (multi select)
            return (
              <div>
                <IndeterminateCheckboxOrRadiobutton
                  checked={isChecked}
                  indeterminate={isIndeterminate}
                  onChange={(e) => {
                    toggleAllPageRowsSelected(tableData, e.target.checked)
                  }}
                />
              </div>
            )
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to render a checkbox
          Cell: ({ row }) => {
            const { disabled, isNotSelectable } = row.getRowProps()

            return (
              <Box sx={[ isNotSelectable ? { display: 'none' } : { display: 'block' } ]}>
                <IndeterminateCheckboxOrRadiobutton
                  showRadio={!multiselect && showRadiobuttonOnSingleSelect}
                  disabled={disabled}
                  {...row.getToggleRowSelectedProps()}
                />
              </Box>
            )
          }
        },
        ...columns
      ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      selectedRowIds,
      selectedRowPaths,
      globalFilter,
      sortBy
    },
    toggleAllRowsSelected,
    toggleRowSelected,
    preGlobalFilteredRows,
    setGlobalFilter
  } = tableInstance
  // Hooks the function to execute onSelect when editing via toggleAllRowsSelected
  tableInstance.toggleAllRowsSelected = (...args) => {
    toggleAllRowsSelected(...args)
    hasClicked()
  }

  // Hooks the function to execute onSelect when editing via toggleRowSelected
  tableInstance.toggleRowSelected = (...args) => {
    toggleRowSelected(...args)
    hasClicked()
  }

  useEffect(() => {
    setPageSize(itemsPerPage)
  }, [ itemsPerPage ])

  useEffect(() => {
    onPageUpdate(pageIndex)

    // Using onPageUpdate as a dependency here, would lead to
    // calling onPageUpdate everytime the reference to onPageUpdate changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pageIndex ])

  useImperativeHandle(ref, () => tableInstance)

  useEffect(() => {
    setSelectedRows(selectedRowPaths)
  }, [ setSelectedRows, selectedRowPaths ])

  useEffect(() => {
    const header = columns.find((col) => col.accessor === sortBy[0]?.id)?.key

    if (tableIsControlled) {
      controlledFetchData({
        pageIndex,
        pageSize,
        sortBy: {
          ...sortBy[0],
          header
        }
      })
    }
    // eslint-disable-next-line -- we don't need to trigger useEffect on columns update
  }, [ sortBy, controlledFetchData, pageIndex, pageSize, tableIsControlled ])

  useEffect(() => {
    const allRowIds = multiselect ? preselectedRowIds : preselectedRowIds.slice(0, 1)

    allRowIds.forEach((rowId) => {
      toggleRowSelected(rowId, true)
    })
  }, [ toggleRowSelected, preselectedRowIds, multiselect, data ])

  const prepareSelectedRowData = useCallback((selectedFlatRows) => {
    const allSelectedData = []

    selectedFlatRows.forEach((row) => {
      const rowData = { _rowId: row?.id }

      tableColumns.map((elm, i) => (rowData[elm.key ? elm.key : elm.Header] = row.original[`col${i}`]))

      allSelectedData.push(rowData)
    })

    return allSelectedData
  }, [ tableColumns ])

  useEffect(() => {
    // It should only fire, if there was at least one click
    if (hasClickedUpdate === 0) return

    // get realSelectedFlatRows because default one from react-table is bugged
    const arraySelectedRowIds = Object.keys(selectedRowIds)
    const realSelectedFlatRows = tableInstance.initialRows.filter((obj) => arraySelectedRowIds.includes(obj.id))

    if (realSelectedFlatRows.length > 0) {
      onSelect(prepareSelectedRowData(realSelectedFlatRows), arraySelectedRowIds)
    } else {
      onSelect([], arraySelectedRowIds)
    }
  }, [ hasClickedUpdate ]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRowClick = useCallback((event, row) => {
    event.stopPropagation()

    if (readOnly) return

    _onRowClick({
      row,
      isChecked: !row.isSelected,
      event
    })

    if (multiselect) {
      row.toggleRowSelected()
    } else {
      toggleAllRowsSelected(false)

      !row.isSelected && row.toggleRowSelected()
    }

    hasClicked()
  }, [ _onRowClick, multiselect, readOnly, toggleAllRowsSelected ])

  const [ selectedItemsInfoTextContent, setSelectedItemsInfoTextContent ] = useState('')
  const [ pageIndexInfo, setPageIndexInfo ] = useState('')

  useEffect(() => {
    const selectedItems = Object.keys(selectedRowIds)
    const selectedItemsText = selectedItems.join(', ')

    setSelectedItemsInfoTextContent(selectedItems.length === 0
      ? ''
      : selectedItems.length === 1
        ? `${selectedItemsInfoText.single}: ${selectedItemsText}`
        : selectedItems.length > 5
          ? `${selectedItemsInfoText.multiple}: ${selectedItems.length}`
          : `${selectedItemsInfoText.multiple}: ${selectedItemsText}`)
  }, [ selectedItemsInfoText.multiple, selectedItemsInfoText.single, selectedRowIds ])

  useEffect(() => {
    // Info for the paging:
    const pageIndexFrom = pageIndex * pageSize + 1
    const pageIndexTo = pageIndex * pageSize + page.length

    setPageIndexInfo(pageIndexFrom === pageIndexTo || pageIndexTo === 0
      ? `${format.toIntlNumber(pageIndexTo)} / ${format.toIntlNumber(totalCount)}
        ${rows.length === 1 ? infoText.single : infoText.multiple}`
      : `${format.toIntlNumber(pageIndexFrom)}-${format.toIntlNumber(pageIndexTo)} / ${format.toIntlNumber(totalCount)}
        ${rows.length === 1 ? infoText.single : infoText.multiple}`)
  }, [ infoText.multiple, infoText.single, page.length, pageIndex, pageSize, rows.length, totalCount ])

  return (
    <div
      data-testid="Table"
      className="tableWrapper"
      {...otherProps}
    >
      {debug && (
        <pre>
          <code>
            {JSON.stringify(
              {
                'data count': data.length,
                pageIndex,
                pageSize,
                pageCount,
                controlledPageCount,
                canNextPage,
                canPreviousPage,
                controlledIsLoading,
                selectedRowIds

              },
              null,
              2
            )}
          </code>
        </pre>
      )}

      <Stack
        direction="row"
        gap={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '20px',
          pl: 0
        }}
      >
        <Box
          sx={{ marginTop: 'auto' }}
        >
          {tableIsControlled && (
            <button
              type="button"
              onClick={() => controlledFetchData({
                pageIndex,
                pageSize
              })}
              disabled={controlledIsLoading}
              style={{
                alignSelf: 'end',
                backgroundColor: 'transparent',
                padding: '0',
                margin: '8px 0',
                border: 0,
                width: 24,
                height: 24,
                cursor: 'pointer',
                ...(controlledIsLoading && { cursor: 'not-allowed' })
              }}
            >
              <AliceIcon
                iconType={AliceIconType.LOADING}
                sx={{
                  width: '16px',
                  height: '16px',
                  transform: 'rotateY(180deg)',
                  animation: controlledIsLoading
                    ? 'spin 1.5s linear infinite'
                    : 'none',
                  '@keyframes spin': {
                    '0%': { transform: 'rotate(0deg) rotateY(180deg)' },
                    '100%': { transform: 'rotate(360deg) rotateY(180deg)' }
                  }
                }}
              />
            </button>
          )}
          {toolbar}
        </Box>

        <Stack
          sx={[
            {
              display: 'grid',
              justifyItems: 'flex-end',
              gap: 2,
              gridTemplate: {
                xs: `
                  "searchBar searchBar searchBar"
                  "searchCount searchCount pagination"
                `,
                sm: `
                  "searchBar searchCount pagination"
                `
              }
            }
          ]}
          alignItems="center"
        >
          {showSearch && !tableIsControlled && (
            <Box sx={{ gridArea: 'searchBar' }}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholder={searchPlaceholder}
              />
            </Box>
          )}

          {showSearch && tableIsControlled && (
            <Box sx={{ gridArea: 'searchBar' }}>
              <SearchBarAnimated
                label={searchPlaceholder}
                onChange={controlledOnSearch}
                onClear={controlledOnSearchClearInputfield}
                disabled={controlledIsLoading}
              />
            </Box>
          )}

          {showItemsPerPageDropdownAtTop && (
            <Box sx={{ gridArea: 'searchCount' }}>
              <ItemsPerPageDropdown
                itemsPerPage={pageSize}
                menuItems={selectableItemsPerPage}
                onChange={(pageCount) => setPageSize(parseInt(pageCount, 10))}
                disabled={controlledIsLoading}
              />
            </Box>
          )}

          {showPaginationAtTop && (
            <Stack
              direction="row"
              sx={{
                gridArea: 'pagination',
                justifySelf: 'end',
                alignItems: 'center'
              }}
            >
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage || controlledIsLoading}
                style={{
                  backgroundColor: 'transparent',
                  padding: 5,
                  margin: 0,
                  border: 0,
                  ...(!canPreviousPage && {
                    cursor: 'not-allowed',
                    opacity: 0.5
                  }),
                  ...(controlledIsLoading && { cursor: 'not-allowed' })
                }}
              >
                <IconWrapper><KeyboardArrowLeft /></IconWrapper>
              </button>

              <Typography
                sx={{
                  m: 0,
                  pl: 2,
                  pr: 2,
                  fontSize: 16,
                  userSelect: 'none',
                  whiteSpace: 'nowrap',
                  ...(controlledIsLoading && { opacity: 0.5 })
                }}
                style={{ marginLeft: 0 }}
              >
                {pageCount === 0 ? 0 : pageIndex + 1}

                <span
                  style={{
                    color: grey[20],
                    opacity: 0.4
                  }}
                >
                  /
                  {' '}
                  {pageCount}
                </span>
              </Typography>

              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage || controlledIsLoading}
                style={{
                  backgroundColor: 'transparent',
                  padding: 5,
                  margin: 0,
                  border: 0,
                  ...(!canNextPage && {
                    cursor: 'not-allowed',
                    opacity: 0.5
                  }),
                  ...(controlledIsLoading && { cursor: 'not-allowed' })
                }}
              >
                <IconWrapper><KeyboardArrowRight /></IconWrapper>
              </button>
            </Stack>
          )}
        </Stack>
      </Stack>

      <table
        {...getTableProps()}
        style={{
          width: '100%',
          borderSpacing: 0,
          tableLayout: 'fixed',
          borderBottom: data.length ? `1px solid ${grey[45]}` : 'unset'
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, colNo) => {
                let checkBoxStyles = {}

                if (multiselect && colNo === 0) {
                // first column
                // checkboxes are added to the row
                  checkBoxStyles = {
                    textAlign: 'center',
                    padding: '0 12px',
                    width: '50px'
                  }
                }

                if (showRadiobuttonOnSingleSelect && colNo === 0) {
                  checkBoxStyles = {
                    width: '50px',
                    padding: '0'
                  }
                }

                return (
                  <th
                    key={`headerGroupHeadersCol_${colNo}`}
                    {
                    ...(column.sortable === false
                      ? column.getHeaderProps()
                      : column.getHeaderProps(
                        column.getSortByToggleProps()
                      )) /* sortable, click */
                  }
                    style={{
                      borderTop: `2px solid ${grey[45]}`,
                      borderBottom: `2px solid ${grey[45]}`,
                      color: grey[20],
                      fontWeight: '700',
                      fontSize: 18,
                      textAlign: 'left',
                      padding: '5px 19px',
                      userSelect: 'none',
                      position: 'relative',
                      cursor:
                      column.sortable === false ? 'default' : 'pointer',
                      ...column.style,
                      ...checkBoxStyles
                    }}
                  >
                    {column.render('Header')}

                    {multiselect && colNo === 0 ? null : (
                      <span style={{ marginLeft: 7 }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <IconWrapper size={16}><KeyboardArrowDown /></IconWrapper>
                          ) : (
                            <IconWrapper size={16}><KeyboardArrowUp /></IconWrapper>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        {/* height on tbody acts as minHeight */}
        <tbody
          style={{
            position: 'relative',
            height: controlledIsLoading && !data.length ? 100 : 'unset'
          }}
          {...getTableBodyProps()}
        >
          {tableIsControlled && controlledIsLoading && (
          <TableRow>
            <TableCell
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: grey[90],
                opacity: 0.6,
                borderBottom: !data.length ? `1px solid ${grey[45]}` : 'unset'
              }}
            >
              <Typography>Loading...</Typography>
            </TableCell>
          </TableRow>
          )}

          {page && page.map((row, rowNo) => {
            prepareRow(row)

            const rowProps = row.getRowProps()
            const { disabled, isNotSelectable } = rowProps

            const {
              isExpanded = false,
              expandProps = {}
            } = isRowExpanded(row)

            return (
              <Fragment key={`pageRow_${rowNo}`}>
                <TableRow
                  className={disabled ? 'disabled' : null}
                  onClick={(e) => {
                    if (disabled || isNotSelectable) {
                    // if row is disabled or not selectable prevent further action
                      e.stopPropagation()
                      return
                    }

                    onRowClick(e, row)
                  }}
                  sx={[
                    {
                      '&.disabled': {
                        opacity: 0.38,
                        pointerEvents: 'none'
                      },
                      height: '40px',
                      backgroundColor: row.isSelected ? blue[95] : 'white',
                      '&:nth-of-type(even)': { backgroundColor: row.isSelected ? blue[95] : grey[90] },
                      '&:hover': {
                        backgroundColor: readOnly ? null : blue[95],
                        cursor: readOnly ? null : 'pointer'
                      }
                    },
                    isNotSelectable && { pointerEvents: 'none' }
                  ]}
                >
                  {row.cells.map((cell, cellNo) => {
                    let checkBoxStyles = {}

                    if (multiselect && cellNo === 0) {
                    // first column
                    // checkboxes are added to the row
                      checkBoxStyles = {
                        textAlign: 'center',
                        padding: '5px 0'
                      }
                    }

                    if (showRadiobuttonOnSingleSelect && cellNo === 0) {
                      checkBoxStyles = {
                        textAlign: 'center',
                        padding: '0',
                        width: '50px'
                      }
                    }

                    return (
                      <TableCell
                        key={`rowCells_${cellNo}`}
                        {...cell.getCellProps()}
                        style={{
                          borderBottom: `1px solid ${grey[45]}`,
                          color: grey[20],
                          fontWeight: row.isSelected ? '500' : '100',
                          fontSize: 18,
                          textAlign: 'left',
                          padding: '9px 19px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          ...checkBoxStyles
                        }}
                      >
                        {(cell.value === '' && '-') || cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>

                {isExpanded && expandProps && (
                <TableRow>
                  <TableCell colSpan={row.cells.length}>
                    <ExpandContainer {...expandProps} />
                  </TableCell>
                </TableRow>
                )}
              </Fragment>
            )
          })}
        </tbody>

        {!controlledIsLoading && !data.length && (
          <caption style={{ captionSide: 'bottom' }}>
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100px',
                background: grey[90],
                opacity: 0.6,
                borderBottom: `1px solid ${grey[45]}`
              }}
            >
              {noDataPlaceholder}
            </Stack>
          </caption>
        )}
      </table>

      <Stack
        sx={{
          height: 0,
          '&::after': {
            display: 'block',
            content: '""',
            clear: 'both'
          }
        }}
      >
        &nbsp;
      </Stack>

      {!!data.length && showPaginationAtBottom && (
        <Stack
          gap={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            mt: 3,
            mb: 3,
            position: 'relative'
          }}
        >
          {(data.length > 0 || pageSize > 0 || pageCount > 0) && (
            <Pagination
              page={pageIndex + 1}
              count={pageCount}
              size="small"
              onChange={(e, pageNumber) => gotoPage(pageNumber - 1)}
              sx={{ userSelect: 'none' }}
              disabled={
                pageSize === 0
                || pageCount === 0
                || (tableIsControlled && controlledIsLoading)
              }
              hideNextButton={pageSize === 0 || pageCount === 0}
              hidePrevButton={pageSize === 0 || pageCount === 0}
            />
          )}

          {showSelectedItemsInfo && selectedItemsInfoTextContent && (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                fontSize: '14px'
              }}
            >
              {selectedItemsInfoTextContent}
            </Box>
          )}
        </Stack>
      )}

      {!!data.length && showItemsPerPageDropdownAtBottom && page && infoText && (
        <Stack
          gap={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            mt: 3,
            mb: 3
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              mt: -2,
              color: '#aaa'
            }}
          >
            {pageIndexInfo}
          </Typography>
        </Stack>
      )}
    </div>
  )
})

Table.propTypes = {
  controlledFetchData: PropTypes.func, // ajax
  controlledIsLoading: PropTypes.bool, // ajax
  controlledPageCount: PropTypes.number, // ajax
  /**
   * Header: The title of the column header
   * key: This key is used to return the according data with this key inside the returned object
   * style: A style object which will be set on the column header, example: {color: '#af0', textDecoration: 'underline'}
   * sortable: Defines if the column is sortable on a mouse click
   * Cell: A custom render method to render the content of the cell. Param: "row". (value of the cell = row.value)
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.node.isRequired,
      key: PropTypes.string,
      style: PropTypes.object,
      sortable: PropTypes.bool,
      Cell: PropTypes.func
    })
  ).isRequired,
  /** an array of arrays. The length needs to fit the count of the columns. */
  data: PropTypes.arrayOf(PropTypes.array.isRequired), // .isRequired, // <- not required in case of controlled (ajax) table
  /** select multiple rows. checkboxes will be added at the first column */
  multiselect: PropTypes.bool,
  /** show num items per page */
  itemsPerPage: PropTypes.number,
  /** menu items for the num of items per page */
  selectableItemsPerPage: PropTypes.arrayOf(PropTypes.string),
  /** show num items per page dropdown at top */
  showItemsPerPageDropdownAtTop: PropTypes.bool,
  /** show pagination at top */
  showPaginationAtTop: PropTypes.bool,
  /** show pagination at bottom */
  showPaginationAtBottom: PropTypes.bool,
  /** show checkboxes on each row in single select mode */
  showRadiobuttonOnSingleSelect: PropTypes.bool,
  /** show a search input field to search the data */
  showSearch: PropTypes.bool,
  /** placeholder for the search input field */
  searchPlaceholder: PropTypes.string,
  /** show which rowId’s are selected at the right from the bottom pagination */
  showSelectedItemsInfo: PropTypes.bool,
  /** show info to the right at the pagination at bottom. It is shown only if 'showSelectedItemsInfo' is true. */
  selectedItemsInfoText: PropTypes.shape({
    single: PropTypes.string,
    multiple: PropTypes.string
  }),
  /** show info below pagination at bottom, false = don't show */
  infoText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      single: PropTypes.string.isRequired,
      multiple: PropTypes.string.isRequired
    })
  ]),
  /**
   * If this function is provided an ExpandContainer can be displayed below a row. <br>
   * Row and content is controlled by the function
   *
   * <b>expandProps => subComponents/ExpandContainer</b>
   *
   * @param rowData
   * @returns {{ isExpanded: false, expandProps: {} }}
   */
  isRowExpanded: PropTypes.func,
  /** The total count of records shown in the table. Defaults to data length */
  totalCount: PropTypes.number,
  selectedRows: PropTypes.shape(),
  /**
   *  Set the rowId which should be selected or deselected.
   *
   *  Example: ${'{1: true, 3: false, 6: true}'}:
   *  Select rowId 1 and 6, deselect rowId 3.
   */
  preselectedRowIds: PropTypes.array,
  /**
   * Disables hover effect of rows and the onClick event.
   * Only use with multiselect=false (which is default)
   */
  readOnly: PropTypes.bool,
  /** Allows you to add a component at the top of the table */
  toolbar: PropTypes.node,
  debug: PropTypes.bool,
  /** Replace the default "No data" placeholder */
  noDataPlaceholder: PropTypes.node,
  /**
   * Pass a function to disable certain rows. The function receives two props: rowData and id
   *
   * @param rowData
   * @param id
   * @returns {void}
   */
  isRowDisabled: PropTypes.func,
  /**
   * Must return a unique id
   *
   * @param rowData
   * @returns {void}
   */
  getRowId: PropTypes.func,
  /**
   * This Method is triggered everytime "selectedRowIds" are getting changed
   * - clicking on a row
   * - clicking on toggleAll checkbox in multiselect
   * - calling the "toggleAllRowsSelected" method from ref
   *
   * Note! -> onSelect doesn't pass the event anymore, use onRowClick instead.
   *
   * @param selectedRows
   * @param selectedRowIds
   * @returns {void}
   */
  onSelect: PropTypes.func,
  /**
   * This Event is triggered when clicking on a row
   * -> if not "readOnly"
   *
   * @param row
   * @param isChecked
   * @param event
   * @returns {void}
   */
  onRowClick: PropTypes.func,
  /**
   * This Event is triggered when clicking on the "toggleAllBtn" in multiselect mode
   *
   * @param changedRows
   * @param isChecked
   * @returns {void}
   */
  onToggleAllInPage: PropTypes.func
}
