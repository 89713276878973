import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import AvatarGroup from '@mui/material/AvatarGroup'
import { Box } from '@mui/material'
import { mergeSxProps, user as UserUtils } from '../../../../util'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import { Tooltip } from '../../../message'

/**
 * UserAvatarGroup is a customized component that displays a group of user avatars with optional overflow count.
 * It renders a list of avatars representing users, and if the number of users exceeds the specified maximum limit,
 * it displays a placeholder avatar indicating the count of additional users.
 *
 * ** Note: **
 * - UserAvatarGroup is based on MUI AvatarGroup component. So it takes its props.
 * [See AvatarGroup props](https://mui.com/material-ui/api/avatar-group/#props)
 */
export const UserAvatarGroup = forwardRef(({
  avatars,
  max = 5,
  size = 32,
  sx,
  ...otherProps
}, ref) => {
  const overflow = avatars.length > max
  const visibleAvatars = overflow ? avatars.slice(0, max - 1) : avatars

  const OverflowAvatar = ({ data, index }) => (
    <Box
      sx={{
        position: 'relative',
        marginLeft: '-8px',
        zIndex: index
      }}
    >
      <UserAvatar
        userId={data.id}
        userName={`${data.givenname} ${data.surname}`}
        showImage={UserUtils.is.internalEmployee(data.communities)}
        communities={data.communities}
        sizeStyle={{
          height: `${size}px`,
          width: `${size}px`,
          fontSize: '1rem',
          dotSize: '16px'
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          left: 0,
          border: '1px solid white',
          borderRadius: '100%',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {`+${avatars.length - max + 1}`}
      </Box>
    </Box>
  )

  return (
    <AvatarGroup
      sx={mergeSxProps({
        justifyContent: 'flex-end',
        '.MuiAvatarGroup-avatar': { width: `${size}px`, height: `${size}px` },
        '.MuiAvatar-root': { marginLeft: 'unset', border: '1px solid white' }
      }, sx)}
      spacing={8}
      max={max}
      {...otherProps}
    >
      {visibleAvatars.map((avatar, index) => (
        <Tooltip key={`${avatar.id}-${index}`} title={`${avatar.givenname} ${avatar.surname}`} placement="bottom">
          <UserAvatar
            userId={avatar.id}
            userName={`${avatar.givenname} ${avatar.surname}`}
            showImage={UserUtils.is.internalEmployee(avatar.communities)}
            communities={avatar.communities}
            sizeStyle={{
              height: `${size}px`,
              width: `${size}px`,
              fontSize: '1rem',
              dotSize: '16px'
            }}
            sx={{ zIndex: `${index}` }}
          />
        </Tooltip>
      ))}

      {overflow && (<OverflowAvatar data={avatars.slice(max - 1, max)[0]} index={max - 1} />)}
    </AvatarGroup>
  )
})

UserAvatarGroup.propTypes = {
  /** An array of user objects */
  avatars: PropTypes.arrayOf(PropTypes.object),
  /** Defines the size of the avatar in px */
  size: PropTypes.number,
  /** Defines max quantity of avatars  */
  max: PropTypes.number
}
