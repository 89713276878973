import { OpenInNewOutlined } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AliceIcon, AliceIconType } from '../../icon'
import { Link } from '../../link'
import { Alert } from '../../message'
import { AdminRole } from './AdminRole'
import { AdminRolesAccordion } from './AdminRolesAccordion'
import { AdminRoleVisibleAsSupportContactSwitch } from './switch/AdminRoleVisibleAsSupportContactSwitch'

/**
 * This is a managed list of admin roles.
 *
 * If you prefer not to manually control admin roles, we recommend using our `AdminRoles` component for a more automated approach.
 *
 * List of all available roles given by the API:
 *
 * - apiAdmin
 * - centralHelpdeskAdmin
 * - marketHelpdeskAdmin
 * - applicationHelpdeskAdmin
 * - applicationAdmin
 * - globalCommunityAdmin
 * - globalRoleAssigner
 * - identStatusAdmin
 * - marketAdmin
 * - marketRoleAssigner
 * - organizationRoleAssigner
 * - roleCreator
 * - roleApprover
 * - roleOwner
 * - sharedServiceAdmin
 * - supportAdmin
 * - orgAdmin
 * - visibleAsSupportAdmin
 */
export const AdminRoleList = ({
  userId = '',
  userRoles = {},
  assignedAdminRoles = [],
  unassignedAdminRoles = [],
  activeEntitlements = [],
  isVisibleAsSupportContactSwitchShown = false,
  onRoleUpdate = (role) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const hasAssignedAdminRoles = !!assignedAdminRoles.length
  const hasUnassignedAdminRoles = !!unassignedAdminRoles.length
  const hasActiveEntitlements = !!activeEntitlements.length

  const url = `/access/insights/user/${userId}`

  const isVisibleAsSupportContactSwitchVisible = () => {
    if (!isVisibleAsSupportContactSwitchShown) return false

    for (const assignedAdminRole of assignedAdminRoles) {
      switch (assignedAdminRole) {
        case 'orgAdmin':
        case 'marketAdmin':
        case 'globalCommunityAdmin':
          return true
        default:
          break
      }
    }

    return false
  }

  return (
    <AdminRolesAccordion
      data-testid="AdminRoleList"
      title={t('adminRoles.manageAdminRoles')}
    >
      {/* active roles  */}
      <Stack gap={3} sx={{ mt: 2 }}>
        <Typography variant="h4">{t('adminRoles.activeRoles')}</Typography>

        { isVisibleAsSupportContactSwitchVisible() && (
          <AdminRoleVisibleAsSupportContactSwitch
            userId={userId}
            isVisible={userRoles?.visibleAsSupportContact?.visible || false}
            onChange={onRoleUpdate}
          />
        ) }

        <Stack gap={2}>
          {hasAssignedAdminRoles ? (
            assignedAdminRoles.map((role) => (
              <AdminRole
                key={role}
                userId={userId}
                role={role}
                roleData={userRoles[role]}
                onRoleUpdate={onRoleUpdate}
              />
            ))
          ) : (
            <Alert severity="info">
              {t('adminRoles.noAdminRolesAssigned')}
            </Alert>
          )}
        </Stack>
      </Stack>

      {/* entitlements */}
      {hasActiveEntitlements && (
        <Stack sx={{ mt: 6 }} gap={3}>
          <Typography variant="h4">{t('adminRoles.activeEntitlements')}</Typography>

          <Link
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover > .MuiSvgIcon-root': { opacity: 1 }
            }}
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="subtitle2" sx={{ color: 'grey.45' }}>
              {t('adminRoles.entitlementsDescription')}

              <Typography variant="subtitle2" as="span" sx={{ color: 'blue.45' }}>{t('adminRoles.accessUi')}</Typography>
            </Typography>

            <OpenInNewOutlined
              className="hidden"
              fontSize="inherit"
              color="inherit"
              sx={{
                opacity: 0,
                transition: 'opacity 250ms',
                marginBottom: '1px'
              }}
            />
          </Link>

          <Stack gap={2}>
            {activeEntitlements.map((entitlement) => (
              <Stack
                key={entitlement}
                sx={{
                  flexDirection: 'row',
                  gap: 1,
                  padding: '18px 0 18px 24px',
                  boxShadow: 'none',
                  borderTop: '1px solid',
                  borderBottom: '1px solid',
                  borderInline: 'none',
                  borderColor: 'grey.85'
                }}
              >
                <AliceIcon
                  iconType={AliceIconType.ACTIVE}
                  color="success.main"
                  size={24}
                />

                <Typography variant="body1" sx={{ fontSize: '22px' }}>{t(`entitlements.${entitlement}`)}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}

      {/* inactive roles */}
      { hasUnassignedAdminRoles && (
        <Stack sx={{ mt: 6 }} gap={3}>
          <Typography variant="h4">{t('adminRoles.inactiveRoles')}</Typography>

          <Stack gap={2}>
            {unassignedAdminRoles.map((role) => (
              <AdminRole
                key={role}
                userId={userId}
                role={role}
                roleData={userRoles[role]}
                onRoleUpdate={onRoleUpdate}
              />
            ))}
          </Stack>
        </Stack>
      ) }
    </AdminRolesAccordion>
  )
}

AdminRoleList.propTypes = {
  /** UserId of the current user */
  userId: PropTypes.string.isRequired,
  /** All roles of the user. Object should be passed directly from the API */
  userRoles: PropTypes.object.isRequired,
  /** All assigned admin roles */
  assignedAdminRoles: PropTypes.arrayOf(PropTypes.string),
  /** All unassigned admin roles */
  unassignedAdminRoles: PropTypes.arrayOf(PropTypes.string),
  /** All active entitlements */
  activeEntitlements: PropTypes.arrayOf(PropTypes.string),
  /**
   * If set to true switch will be shown, that toggles the visibility of the user as support contact.
   * Note, that because of API requirements the switch will only we visible if user has one of the roles: ``orgAdmin``, ``globalCommunityAdmin`` or ``marketAdmin``.
   */
  isVisibleAsSupportContactSwitchShown: PropTypes.bool,
  /** Callback when role got updated */
  onRoleUpdate: PropTypes.func
}
