/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { removeClassRecursively } from "@utils/removeClassRecursively";
import { FocusDot, useUser } from "@alice/component_library";
import { getOrgColumns } from "./helper";
import { hasData, isValid } from "@utils/Validator";

import { OrgSearch } from "@alice/component_library";
import "@src/App.css";

const getDataByID = (id, dataArray) => {
  const item = dataArray.find((obj) => obj.key.id === id);
  return item ? item.organizations : [];
};

const isDataExist = (id, dataArray, identifier) => {
  return dataArray.some((item) => item[identifier] == id);
};

const MainContainer = styled("div")(({ theme }) => ({
  "& .label": {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "14px",
    color: "#797979",
  },
  "& .formControl": {
    width: "100%",
  },
  "& .LangformControl": {
    width: "100%",
  },
  "& .table": {
    minWidth: 650,
  },
  "& .headerAdjust": {
    backgroundColor: "black",
  },
  "& .selectEmpty": {
    marginTop: "16px",
  },
  "& .leftbutton": {
    marginBottom: "1rem",
    border: "1px solid black",
    borderRadius: 0,
    textTransform: "none",
    fontSize: 16,
    minWidth: 100,
    cursor: "pointer",
  },
  "& .inputClass": {
    color: "rgba(0, 0, 0, 0.87) ",
    padding: "16px 11px 10px ",
    paddingBottom: "13px ",
  },
  "& .SuborgName": {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      display: "flex",
      width: "330%",
    },
  },
}));

const OrgComponent = memo(
  (params) => {
    const {
      selectedFullScopes,
      required,
      disabled,
      roleId,
      preSelect,
      openHandler,
      searchTerm,
      addRoledata,
      updateOrgArray,
      setOrgSelectedScope,
    } = params;

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [orgValue, setorgValue] = useState("");
    const [selectedDataArray, setSelectedDataArray] = useState([]);
    const [pageOrganizationData, setPageOrganizationData] = useState([]);

    const orgscopeList = useSelector((state) => state.role.orgScopeValues);
    const searchOrgScopeList = useSelector(
      (state) => state.role.searchorgValues
    );
    const selectedUserdata = useSelector(
      (state) => state.user.selectedUserData
    );

    const { user: userUtils } = useUser();

    const [tablehide, setTable] = useState(true);
    const [dropdownData, setDropdownData] = useState([]);
    const selectRef = useRef(null);

    const { width } = window.screen;
    const isMobile = width < 420;

    useEffect(() => {
      setSelectedDataArray(selectedFullScopes);
      updateOrgArray(selectedFullScopes);
    }, [selectedFullScopes]);

    useEffect(() => {
      if (hasData(preSelect) && isValid(preSelect?.orgScope)) {
        setorgValue(preSelect?.orgId);
        setDropdownData([
          {
            label: preSelect?.orgScope,
            value: preSelect?.orgId,
            orgGroupType: preSelect?.groupType,
          },
        ]);
      } else {
        removeClassRecursively(selectRef.current, "Mui-focused");
        setorgValue("");
        setDropdownData([]);
        setOrgSelectedScope({});
      }
    }, [roleId]);

    useEffect(() => {
      if (params.isMode && hasData(preSelect)) {
        params.updateOrgScope(preSelect.orgScope);
        params.updategroupType(preSelect.groupType);
        params.updateOrgId(preSelect.orgId);
      }
    }, [orgscopeList, searchOrgScopeList]);

    const handleOpen = async () => {
      openHandler(true);
      setOpen(true);
      setTable(true);
      setTimeout(() => {
        const el = document.querySelector("#orgSearch #search-field");
        el.focus();
      }, 100);
    };

    const handleClose = () => {
      openHandler(false);
      setOpen(false);
      setTable(false);
    };

    const handleSelect = async (e) => {
      setOpen(true);
      setOpen(false);
    };

    const getPageData = (data) => {
      setPageOrganizationData(data);
      return data;
    };

    const selectAllHandler = (value) => {
      let selectedScopes = selectedDataArray;
      if (value.length) {
        pageOrganizationData.forEach((item) => {
          if (
            value.includes(item.id) &&
            !isDataExist(item.id, selectedScopes, "orgId")
          ) {
            selectedScopes = [
              ...selectedScopes,
              {
                orgScope: item.name,
                groupType: item.orgForm,
                orgId: item.id,
                ...item,
              },
            ];
          } else if (!value.includes(item.id)) {
            selectedScopes = selectedScopes.filter(
              (scope) => scope.orgId !== item.id
            );
          }
        });
      } else {
        pageOrganizationData.forEach((item) => {
          selectedScopes = selectedScopes.filter(
            (scope) => scope.orgId !== item.id
          );
        });
      }
      setSelectedDataArray(selectedScopes);
      updateOrgArray(selectedScopes);
    };

    const getRowIds = () => {
      const disabledIds = selectedFullScopes.map((item) => item.orgId);
      return disabledIds;
    };

    const selectOrghandler = async (id, selected, data) => {
      if (!addRoledata.needsCustomScopes) {
        let selectedScopes = selectedDataArray;
        const value = data?.name;
        const orgid = data.id;
        const orgForm = data?.data?.orgForm ?? "";
        const elem = {
          orgScope: value,
          groupType: orgForm,
          orgId: orgid,
          ...data,
        };

        if (selected) {
          selectedScopes = [...selectedScopes, elem];
          setSelectedDataArray(selectedScopes);
        } else {
          selectedScopes = selectedScopes.filter((item) => item.orgId !== id);
          setSelectedDataArray(selectedScopes);
        }
        updateOrgArray(selectedScopes);
      } else {
        const value = data.name;
        const orgid = data.id;
        const groupType = data.orgForm ?? "";
        setorgValue(value);
        params.updateOrgScope(value);
        params.updategroupType(groupType);
        params.updateOrgId(orgid);
        handleClose();
        setDropdownData([
          {
            label: value,
            value,
            orgGroupType: groupType,
          },
        ]);
      }
    };

    const getAPIURL = () => {
      const userSl = !hasData(selectedUserdata) ? userUtils : selectedUserdata;
      return `/users/${userSl?.id}/roles/${roleId}/assignableorganizations`;
    };

    return (
      <MainContainer className="form-group">
        <FormControl
          variant="filled"
          className="formControl"
          disabled={disabled}
        >
          {open === false ? (
            <React.Fragment>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={orgValue}
                required={required}
                onOpen={handleOpen}
                onClose={handleClose}
                open={open}
                onChange={handleSelect}
                displayEmpty
                className="selectEmpty"
                classes={{ filled: "inputClass" }}
                ref={selectRef}
              >
                {orgValue && orgValue !== "" ? null : (
                  <InputLabel value="">
                    <span>{t("user_pleaseSelect")}</span>
                  </InputLabel>
                )}
                {dropdownData.map((x, index) => (
                  <MenuItem key={index} value={x.value} defaultValue={x.value}>
                    <span>
                      {x.label}
                      {x.orgGroupType ? (
                        <span
                          className="focus-dot-inline"
                          style={{
                            marginRight: "8px",
                            top: "4px",
                            left: "5px",
                          }}
                        >
                          <FocusDot size="small" groupType={x.orgGroupType} />
                        </span>
                      ) : null}
                    </span>
                  </MenuItem>
                ))}
              </Select>
              {orgValue !== "" ||
              (params.selectedFullScopes &&
                params.selectedFullScopes.length > 0)
                ? null
                : params.accessFlag && (
                    <React.Fragment>
                      <br />
                      <span style={{ fontSize: "14px", color: "#000000" }}>
                        {t("please-select-option")}
                      </span>
                    </React.Fragment>
                  )}
            </React.Fragment>
          ) : null}

          {open === true ? (
            <React.Fragment>
              <div>
                {tablehide === true ? (
                  <React.Fragment>
                    <Grid className="mh-40vh mt-20 subOrgTable">
                      <div className="subOrgTable" id="orgSearch">
                        <OrgSearch
                          interaction={
                            !addRoledata.needsCustomScopes
                              ? "multiSelect"
                              : "singleSelect"
                          }
                          apiUrl={getAPIURL()}
                          preselectedSearch={searchTerm}
                          onCardClick={(id, selected, data) =>
                            selectOrghandler(id, selected, data)
                          }
                          onGetResponseData={(data) =>
                            getPageData(data.organizations)
                          }
                          preselectedRowIds={
                            !addRoledata.needsCustomScopes ? getRowIds() : []
                          }
                          onSelectAll={(value) => selectAllHandler(value)}
                          views={["grid", "list"]}
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </FormControl>
      </MainContainer>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.roleId === nextProps.roleId &&
      prevProps.selectedFullScopes === nextProps.selectedFullScopes &&
      prevProps.searchResult === nextProps.searchResult
    );
  }
);

export default OrgComponent;
