import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { RoleSearch } from '../../roleSearch/RoleSearch'
import { AssignmentDialog } from '../dialog/AssignmentDialog'

export const RoleOwnerAssignmentDialog = ({
  userId,
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedRoles, setSelectedRoles ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.roles'),
      content: selectedRoles.length ? `${selectedRoles.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'roleowner',
        body: { roleOwnerPrivileges: selectedRoles.map((roleId) => ({ roleId })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.roleOwner') }),
      onRequestSuccess: (response) => onAssigned({ roleOwner: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsAssignDisabled = () => !(selectedRoles && selectedRoles.length > 0)

  const handleRoleCardClick = (id, selected) => {
    if (selected) setSelectedRoles([ ...selectedRoles, id ])
    else selectedRoles.filter((roleId) => roleId === id)
  }

  const content = [
    {
      title: t('adminRoles.select.roles'),
      render: (
        <RoleSearch
          isCaching
          interaction={Interaction.MULTI_SELECT}
          onCardClick={handleRoleCardClick}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="RoleOwnerAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.roleOwner'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

RoleOwnerAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
