/* eslint-disable camelcase */

import axios from 'axios'
import { adminRolesAllRoles, adminRolesNoRoles, announcementsDataAll, announcementsDataAllActive, appData_A99, appData_ADTEST, appData_AppId, applicationSearch, applicationSearch12, applicationSearchTestQuery, appSupportData_A99, appSupportData_ADTEST, assignableOrgs_d7salice_alice_test_org, entitlement, entitlementsSearch, entitlementsSearchPage2, markets, marketSearch, marketSearch12, orgData_DE_supportcontacts, orgData_E1900, orgData_E9953, orgData_GS00111221, orgData_MI03657353, orgData_S10258630, orgData_supportcontacts, orgData_XY03643781, orgData_XY03671045, orgSearch, orgSearch12, orgSearch5, orgSearch5nextPage, orgSearch5sortAscCountry, orgSearch5sortAscName, orgSearch5sortDescCountry, orgSearch5sortDescName, roleSearch, supervisor_KKELLEN, supervisor_PID1351, userData_D0XH3122, userData_D7VADMIN, userData_jwandel, userData_kkellen, userData_LMACUBA, userData_PID1351, userData_S1SFLUEL, userSearch, userSearch_kevin, userSearchLimit12, userSearchLimit20, userSearchLimit24, groupsSearch12 } from './data'
import { AdminRoles } from './data/AdminRoles'
import { AgreementsAccepted } from './data/Agreements'
import { EntitlementsAlice } from './data/Entitlements'
import { TasksCount } from './data/Tasks'
import { User } from './data/Users'
import { WorkflowsCount } from './data/Workflows'
import kkellenImage from './kkellen.jpeg'

const b64toBlob = (dataURI) => {
  try {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ ab ], { type: 'image/jpeg' })
  } catch {
    return dataURI
  }
}

const requests = {
  '/gems/me': User,
  '/gems/tasks': TasksCount,
  '/gems/workflows': WorkflowsCount,
  [`/gems/users/${User.id}/agreements`]: AgreementsAccepted,
  '/gems/myadminroles': AdminRoles,
  [`/gems/users/${User.id}/entitlements`]: EntitlementsAlice,

  '/gems/users/KKELLEN': userData_kkellen,
  '/gems/users/JWANDEL': userData_jwandel,
  '/gems/users/KKELLEN/organizations/E-9953': orgData_E9953,
  '/gems/users/D0XH3122': userData_D0XH3122,
  '/gems/users/PID1351': userData_PID1351,
  '/gems/users/LMACUBA': userData_LMACUBA,
  '/gems/users/D0XH3122/organizations/D-Outlet.XY03643781': orgData_XY03643781,
  '/images/KKELLEN/teams?defaultImage=false': b64toBlob(kkellenImage),
  '/gems/users?filter=isActive%3D%3Dtrue&limit=10': userSearch,
  '/gems/users?filter=isActive%3D%3Dtrue&limit=12': userSearch,
  '/gems/users?filter=isActive%3D%3Dtrue&limit=20': userSearchLimit20,
  '/gems/users?filter=isActive%3D%3Dtrue&limit=24': userSearchLimit24,
  '/gems/users?offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=(search==*Preselected*)&sort=+name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=isActive==true;community=in=(DEALER_EMPLOYEE,CORPORATE_CUSTOMER_EMPLOYEE);country==DE&sort=+name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=community=in=(DEALER_EMPLOYEE,CORPORATE_CUSTOMER_EMPLOYEE)&sort=+id&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=(search==*Preselected*);isActive==true;community=in=(DEALER_EMPLOYEE,CORPORATE_CUSTOMER_EMPLOYEE);country==DE&sort=-name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=status==ACTIVE&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=status==INACTIVE&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=status==BOTH&offset=0&limit=12': userSearchLimit12,
  '/gems/users?sort=+id&offset=0&limit=12': userSearchLimit12,
  '/gems/users?sort=-id&offset=0&limit=12': userSearchLimit12,
  '/gems/users?sort=+name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?sort=-name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?sort=-name&offset=0&limit=24': userSearchLimit24,
  '/gems/users?filter=status==ACTIVE&sort=+id&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=isActive=true&sort=+name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=(search==*Preselected*);isActive=true&sort=-name&offset=0&limit=12': userSearchLimit12,
  '/gems/users?filter=id==Kevin&offset=0&limit=12': userSearch_kevin,
  '/gems/users?filter=search%3D%3D%27*kevin*%27%3BisActive%3D%3Dtrue&limit=10': userSearch_kevin,
  '/gems/users?filter=search%3D%3D%27*kevin*%27%3BisActive%3D%3Dtrue&limit=20': userSearch_kevin,
  '/gems/organizations/D-Outlet.XY03671045': orgData_XY03671045,
  '/gems/organizations/D-Outlet.GS00111221': orgData_GS00111221,
  '/gems/markets/DE/supportcontacts': orgData_DE_supportcontacts,
  '/gems/supportcontacts': orgData_supportcontacts, // global support contacts
  '/gems/organizations/E-1900': orgData_E1900,
  // Mock data for dealer and supplier for UserCard:
  '/gems/users/D7VADMIN': userData_D7VADMIN,
  '/gems/users/D7VADMIN/organizations/D-Outlet.MI03657353': orgData_MI03657353,
  '/gems/users/S1SFLUEL': userData_S1SFLUEL,
  '/gems/users/S1SFLUEL/organizations/S-10258630': orgData_S10258630,
  '/gems/organizations?sort=+name&offset=0&limit=12': orgSearch12,
  '/gems/organizations?sort=-name&offset=0&limit=12': orgSearch12,
  '/gems/organizations?offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=10': orgSearch,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=5': orgSearch5,
  '/gems/organizations?filter=activationState==ALL&offset=5&limit=5': orgSearch5nextPage,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=5&sort=-country': orgSearch5sortDescCountry,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=5&sort=+country': orgSearch5sortAscCountry,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=5&sort=-name': orgSearch5sortDescName,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=5&sort=+name': orgSearch5sortAscName,
  '/gems/organizations?filter=activationState==ACTIVE&offset=0&limit=10': orgSearch,
  '/gems/organizations?filter=activationState==ALL&offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=community=in=(DEALER,SUPPLIER)&offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=community=in=(DEALER)&offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=community=in=(SUPPLIER)&offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=(search==*Preselected*)&sort=+name&offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=activationState==ACTIVE;community=in=(CORPORATE_CUSTOMER);orgForm=in=(DEALER_GROUP);country==DE&sort=+name&offset=0&limit=12': orgSearch12,
  '/gems/organizations?filter=(search==*Preselected*);activationState==ACTIVE;community=in=(CORPORATE_CUSTOMER);orgForm=in=(DEALER_GROUP);country==DE&sort=-name&offset=0&limit=12': orgSearch12,
  '/gems/users/KKELLEN/supervisor': supervisor_KKELLEN,
  '/gems/users/D0XH3122/supervisor': supervisor_KKELLEN,
  '/gems/users/PID1351/supervisor': supervisor_PID1351,
  '/gems/tasks?filter=recipients.id==MMUSTER;state==PENDING&limit=1': { rels: { totalCount: 1 } },
  '/gems/workflows?filter=tasks.recipients.id==MMUSTER;state==ACTIVE&limit=1': { rels: { totalCount: 1 } },
  '/gems/users/D7SALICE/roles/ALICE_TEST_ORG/assignableorganizations?filter=activationState==ALL&offset=0&limit=10': assignableOrgs_d7salice_alice_test_org,
  '/gems/markets': markets,
  '/abortTest': userSearch,
  '/gems/roles?offset=0&limit=12': roleSearch,
  '/gems/roles?filter=search==**&offset=0&limit=12': roleSearch,
  '/gems/markets?filter=search==**&offset=0&limit=10': marketSearch,
  '/gems/markets?sort=+country&offset=0&limit=12': marketSearch12,
  '/gems/markets?offset=0&limit=12': marketSearch12,
  '/gems/applications': applicationSearch12,
  '/gems/applications?filter=id==**,name==**,url==**,description==**&offset=0&limit=10': applicationSearch,
  '/gems/applications?sort=+id&offset=0&limit=12': applicationSearch12,
  '/gems/applications?offset=0&limit=12': applicationSearch12,
  '/gems/applications?filter=(id==*test*)&sort=-dataClassification&offset=6&limit=3': applicationSearch12,
  '/gems/applications?filter=status==ACTIVE&sort=+id&offset=0&limit=12': applicationSearch12,
  '/gems/applications?sort=+name&offset=0&limit=12': applicationSearch12,
  '/gems/applications?filter=(id==*Test*)&sort=+id&offset=0&limit=12': applicationSearch12,
  '/gems/applications?filter=isActive==false;community=in=(DEALER_EMPLOYEE,CORPORATE_CUSTOMER_EMPLOYEE);country==DE&sort=+id&offset=0&limit=12': applicationSearch12,
  '/gems/applications?filter=id==*Test*&offset=0&limit=12': applicationSearchTestQuery,
  '/gems/users/allRoles/manageadminroles': adminRolesAllRoles,
  '/gems/users/noRoles/manageadminroles': adminRolesNoRoles,
  // Mock data for applications:
  '/gems/applications/AppID': appData_AppId,
  '/gems/applications/ADTEST': appData_ADTEST,
  '/gems/applications/A99': appData_A99,
  '/gems/applications/1/entitlements': entitlementsSearch,
  '/gems/applications/AppID/entitlements': entitlementsSearch,
  '/gems/applications/AppID/entitlements?offset=0&limit=12': entitlementsSearch,
  '/gems/applications/AppID/entitlements?filter=type==ENTITLEMENT;dataClassification=in=(INTERNAL)&offset=0&limit=12': entitlementsSearch,
  '/gems/applications/AppID/entitlements?sort=+displayName&offset=0&limit=12': entitlementsSearch,
  '/gems/applications/A333/entitlements': entitlementsSearch,
  '/gems/applications/ADTEST/entitlements': entitlementsSearch,
  '/gems/users/KKELLEN/entitlements': entitlementsSearch,
  '/gems/applications/AppID/entitlements/EntitlementID': entitlement,
  '/gems/applications/ADTEST/entitlements?offset=0&limit=5': entitlementsSearch,
  '/gems/applications/ADTEST/entitlements?offset=5&limit=5': entitlementsSearchPage2,
  '/gems/roles/RoleId/entitlements': entitlementsSearch,
  '/gems/roles/RoleId/entitlements?offset=0&limit=12': entitlementsSearch,
  '/gems/applications/ADTEST/supportcontacts?offset=0&limit=500': appSupportData_ADTEST,
  '/gems/applications/A99/supportcontacts?offset=0&limit=500': appSupportData_A99,
  '/communication/api/v1/announcements': announcementsDataAll,
  '/communication/api/v1/announcements/active': announcementsDataAllActive,
  // Mock data for groups:
  '/gems/groups?offset=0&limit=12': groupsSearch12
}

let useLocal = true
const token = 'Bearer 000100Df92MnM4oszG9Ndw2BAwRO'
// always use mockData on git pages
if (window.location.origin.includes('pages.git')) {
  useLocal = true
}

export const utilInterceptors = () => {
  if (useLocal) {
    axios.interceptors.request.use((req) => {
      if (req.url !== 'https://reqres.in/api/users?delay=5') {
        // eslint-disable-next-line
        throw {
          message: 'This request was intercepted by axiosInterceptors',
          url: req.url
        }
      } else return req
    })

    axios.interceptors.response.use((resp) => resp, async (error) => {
      if (error.name === 'CanceledError') return Promise.reject(error)

      const data = requests[error.url]

      if (data) return Promise.resolve({ data, status: 200 })
      return Promise.reject(error)
    })
  } else {
    // see setupProxy for which env is used
    axios.interceptors.request.handlers = []

    axios.interceptors.request.use((req) => {
      req.headers.Authorization = token

      return req
    })
  }
}
