import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../../button'
import { Tooltip } from '../../message'

export const GenericSearchViews = ({
  views = [ ],
  activeView = '',
  onChange = () => {},
  sx = {}
}) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Stack
      data-testid="GenericSearchViews"
      direction="row"
      gap={2}
      alignItems="center"
      justifyContent="end"
      sx={sx}
    >
      <Typography>
        {t('genericSearch.views')}
        :
      </Typography>

      <Stack direction="row" gap={1}>
        {Object.entries(views).map(([ key, {
          icon: Icon,
          tooltip
        } ]) => (
          <Tooltip key={key} title={tooltip && tooltip} disabled={!tooltip}>
            <IconButton
              sx={{ color: activeView === key ? 'primary.light' : 'text.primary' }}
              onClick={() => { onChange(key) }}
            >
              <Icon />
            </IconButton>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  )
}

GenericSearchViews.propTypes = {
  views: PropTypes.any, // Cant add custom keys, so it has to be just any
  activeView: PropTypes.string,
  onChange: PropTypes.func
}
