import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { OrgSearch } from '../../../orgElements'
import { RoleSearch } from '../../roleSearch/RoleSearch'
import { AssignmentDialog } from '../dialog/AssignmentDialog'

export const OrganizationRoleAssignerAssignmentDialog = ({
  userId,
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedRoles, setSelectedRoles ] = useState([])
  const [ selectedOrganizations, setSelectedOrganizations ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.roles'),
      content: selectedRoles.length ? `${selectedRoles.length} ${t('general.selected')}` : ''
    },
    {
      title: t('adminRoles.stepper.orgs'),
      content: selectedOrganizations.length ? `${selectedOrganizations.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'orgroleassigner',
        body: {
          orgRoleAssignerPrivileges: selectedRoles.flatMap((role) => selectedOrganizations.map((org) => ({
            roleId: role,
            organizationId: org
          })))
        }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.organizationRoleAssigner') }),
      onRequestSuccess: (response) => onAssigned({ organizationRoleAssigner: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsNextDisabled = () => !(selectedRoles && selectedRoles.length > 0)
  const handleIsAssignDisabled = () => !(selectedOrganizations && selectedOrganizations.length > 0)

  const handleRoleCardClick = (id, selected) => {
    if (selected) setSelectedRoles([ ...selectedRoles, id ])
    else selectedRoles.filter((roleId) => roleId === id)
  }

  const handleOrganizationCardClick = (organization, selected) => {
    if (selected && !selectedOrganizations.includes(organization)) {
      setSelectedOrganizations([ ...selectedOrganizations, organization ])
    } else {
      setSelectedOrganizations(selectedOrganizations.filter((org) => org !== organization))
    }
  }

  const content = [
    {
      title: t('adminRoles.select.roles'),
      render: (
        <RoleSearch
          isCaching
          interaction={Interaction.MULTI_SELECT}
          onCardClick={handleRoleCardClick}
        />
      )
    },
    {
      title: t('adminRoles.select.orgs'),
      render: (
        <OrgSearch
          isCaching
          views={[ 'list' ]}
          interaction={Interaction.MULTI_SELECT}
          preselectedRowIds={selectedOrganizations}
          onCardClick={handleOrganizationCardClick}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="OrganizationRoleAssignerAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.organizationRoleAssigner'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableNext={handleIsNextDisabled}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

OrganizationRoleAssignerAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
