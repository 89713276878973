import isAlphaValidator from 'validator/lib/isAlpha'
import isAlphanumericValidator from 'validator/lib/isAlphanumeric'
import isEmailValidator from 'validator/lib/isEmail'
import isMobilePhoneValidator from 'validator/lib/isMobilePhone'
import isUrlValidator from 'validator/lib/isURL'

export const isEmail = (email) => isEmailValidator(email)

export const isUrl = (url) => {
  const options = {
    protocols: [ 'http', 'https', 'ftp' ],
    require_protocol: false, // Allow URLs without protocols
    require_valid_protocol: true,
    allow_underscores: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false
  }

  const isValidUrl = isUrlValidator(url, options)

  // Check if URL starts with 'www.' after removing protocols
  const urlWithoutProtocol = url.replace(/^(https?:\/\/)?(ftp:\/\/)?/, '')
  const isWwwUrl = urlWithoutProtocol.startsWith('www.')

  return isValidUrl || isWwwUrl
}

export const isPhone = (number) => isMobilePhoneValidator(number.replaceAll(' ', ''))

export const isHttpUrl = (url) => {
  if (url.includes('ftp://')) return false

  return isUrlValidator(url)
}

export const isAlpha = (string) => isAlphaValidator(string, undefined, { ignore: ' ' })

export const isAlphanumeric = (string) => isAlphanumericValidator(string, undefined, { ignore: ' ' })
