import { Divider, Typography, styled } from "@mui/material";
import { ValidityChip } from "@src/ComponentLibrary";
import React from "react";

const StyledContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  alignItems: "center",
  minWidth: "176px",
  "& .right-text-container": {
    display: "flex",
    gap: "2px",
    alignItems: "center",
    color: props?.color ?? "",
  },
}));

const CustomValidityChip = ({
  icon,
  label,
  state = "COMPLETED",
  color = "#0078D6",
  date = new Date(),
  sx = {},
  ...props
}) => {
  return (
    <StyledContainer color={color}>
      <ValidityChip
        size={state === "OPEN" ? "m" : "s"}
        expiryDate={new Date(date)}
        remainingDaysToRed={0}
        sx={
          state !== "OPEN"
            ? {
                background: "transparent",
                color: color,
                "& .MuiDivider-root, .MuiStack-root": { display: "none" },
                "& .MuiTypography-body1": {
                  fontSize: "14px",
                },
                ...sx,
              }
            : {
                "& .MuiTypography-body1": {
                  fontSize: "14px",
                },
                ...sx,
              }
        }
        {...props}
      />
      {state !== "OPEN" ? (
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ marginTop: "5px", marginRight: "6px", height: "16px" }}
          />
          <div className="right-text-container">
            {icon}
            <Typography variant="caption" sx={{ fontSize: "14px" }}>
              {label}
            </Typography>
          </div>
        </>
      ) : null}
    </StyledContainer>
  );
};

export default CustomValidityChip;
