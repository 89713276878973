import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { OrgDialogueCardContainer } from "./styledComponents";

export const OrgDialogueCard = ({ orgName, actionHandler = () => {} }) => {
  return (
    <OrgDialogueCardContainer>
      <span className="text">{orgName}</span>
      <span className="icon" onClick={() => actionHandler()}>
        <HighlightOffIcon />
      </span>
    </OrgDialogueCardContainer>
  );
};
