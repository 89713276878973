import React, { useState, useEffect } from "react";
import { Layout, useUser, usePermission } from "@alice/component_library";
import { useTranslation } from "react-i18next";
import { useQuery } from "@core/hooks";
import { hasData } from "@utils/Validator";
import { isSuperAdmin } from "@utils";
import { useWidth } from "@utils/hooks";
import TasklistDetails from "./components/TasklistTable";
import AdminVisiblitySwicth from "./components/adminVisibilitySwitch";
import FilterComponent from "./components/Filter";
import { CacheStorage, useCache } from "@alice/component_library";

import {
  HeaderContainer,
  HeaderTypography,
  TaskContainer,
  TaskPageContainer,
  SubHeaderTypography,
} from "./styledComponents";
import TaskMenu from "./components/MenuList/Index";
import CustomizedSnackbar from "@src/sharedComponents/SnackBar";

const cacheKey = "TaskPage_UserPreferance";

const TaskPage = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const { user } = useUser();
  const { adminRoles: adminRolesFromstate } = usePermission();
  const adminRoles = adminRolesFromstate ? [...adminRolesFromstate] : [];
  const userUtils = user || {};
  const taskType = query.get("tasktype") ?? "";
  const taskFilter = query.get("filter") ?? "";
  const isAdmin = query.get("isadmin") ?? "";
  const { getCachedItem, setCachedItem, removeCachedItem } = useCache(
    CacheStorage.sessionStorage
  );
  const cacheValue = getCachedItem(cacheKey);

  const widthPanel = useWidth();
  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
      case "xxl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
      }
    }
  }, [widthPanel]);
  const [isAdminVisible, setIsadminVisible] = useState(
    cacheValue?.adminSwitch == null
      ? isAdmin.length &&
        isAdmin === "true" &&
        hasData(adminRoles) &&
        isSuperAdmin(adminRoles, "task")
        ? true
        : false
      : cacheValue?.adminSwitch
  );
  const { isTablet, isDesktop } = windowSize;

  const [searchKeyWord, setSeacrhKeyWord] = useState(
    cacheValue?.searchingKey !== undefined ? cacheValue?.searchingKey : ""
  );
  const [isCollapsable, setIsCollapsed] = useState(false);
  const [searchRef, setSearchRef] = useState(null);
  const [filterValue, setFilterValue] = useState(
    cacheValue?.filterdValue !== undefined
      ? cacheValue?.filterdValue
      : taskFilter.length
      ? taskFilter
      : "PENDING"
  );
  const [sortValue, setSortvalue] = useState(
    cacheValue?.sortedValue !== undefined ? cacheValue.sortedValue : "1"
  );

  const { pathname } = window.location;
  const taskPAgeUrlWithoutParam = window.location.origin + pathname;
  window.history.replaceState({}, "", taskPAgeUrlWithoutParam);

  const [menuType, setMenuType] = useState(
    cacheValue?.taskMenuType == null
      ? taskType.length
        ? taskType
        : "allTasks"
      : cacheValue?.taskMenuType
  );

  const { id: userId } = userUtils;
  const searchHandler = (searchKey, ref) => {
    setCachedItem(cacheKey, {
      ...cacheValue,
      searchingKey: searchKey,
      refe: ref,
    });
    setSeacrhKeyWord(searchKey);
    setSearchRef(ref);
  };

  const filterHandler = (value) => {
    setCachedItem(cacheKey, { ...cacheValue, filterdValue: value });
    setFilterValue(value);
  };

  const adminSwichVisibiltyHandler = (sl) => {
    setCachedItem(cacheKey, { ...cacheValue, adminSwitch: sl });
    setIsadminVisible(sl);
  };

  const sortHandler = (value) => {
    setCachedItem(cacheKey, { ...cacheValue, sortedValue: value });
    setSortvalue(value);
  };

  const setType = (type) => {
    setCachedItem(cacheKey, { ...cacheValue, taskMenuType: type });
    setMenuType(type);
  };

  const collapseState = (state) => {
    setCachedItem(cacheKey, { ...cacheValue, collapsed: state });
    setIsCollapsed(state);
  };

  window.addEventListener("beforeunload", () => {
    removeCachedItem(cacheKey);
  });

  return (
    <TaskPageContainer>
      <Layout>
        <HeaderContainer item xs={12} sm={12}>
          <div>
            <HeaderTypography variant="h1" fontSize="48px" isheader="true">
              {t("tasks")}
            </HeaderTypography>

            <SubHeaderTypography variant="subtitle1">
              {t("recent-request-waitingfor-approval")}
            </SubHeaderTypography>
          </div>

          <AdminVisiblitySwicth
            actionHandler={adminSwichVisibiltyHandler}
            adminRoles={adminRoles}
            adminMode={isAdminVisible}
            translate={t}
          />
        </HeaderContainer>
        <FilterComponent
          actionHandler={searchHandler}
          filterActionHandler={filterHandler}
          windowSize={windowSize}
          translate={t}
          isAdmin={isAdminVisible}
          taskType={taskType}
          taskFilter={filterValue}
          sortHandler={sortHandler}
          sortedValue={cacheValue?.sortedValue}
          searchKeyWord={searchKeyWord}
        />
        <TaskContainer istablet={isTablet} isdesktop={isDesktop}>
          <TaskMenu
            t={t}
            setIsCollapsedMenu={collapseState}
            windowSize={windowSize}
            userId={userId}
            isAdmin={isAdminVisible}
            filter={filterValue}
            search={searchKeyWord}
            actionHandlerForgetType={setType}
            taskType={taskType}
            searchRef={searchRef}
            taskMenuType={cacheValue?.taskMenuType}
            collapsed={cacheValue?.collapsed}
          />
          <TasklistDetails
            windowSize={windowSize}
            isCollapsable={isCollapsable}
            userId={userId}
            filter={filterValue}
            isAdmin={isAdminVisible}
            sortValue={sortValue}
            search={searchKeyWord}
            optionType={menuType}
            translate={t}
            sortedValue={cacheValue?.sortedValue}
          />
        </TaskContainer>
        <CustomizedSnackbar />
      </Layout>
    </TaskPageContainer>
  );
};

export default TaskPage;
