export const stringToColor = (string) => {
  if (typeof string !== 'string') {
    return '#ffffff'
  }

  // Reference: https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
  const hashMultiplier = 5 // Represents the multiplication factor for the hash calculation
  const colorComponentCount = 3 // Represents the number of color components
  const colorValueMask = 0xff // Represents the mask for extracting the color component value
  const colorComponentShift = 8 // Represents the shift value for obtaining individual color components
  const colorValueOffset = 80 // Represents the offset added to the color component value for achieving more muted colors
  const mutedFactor = 1.4 // Division factor for achieving muted colors

  let hash = 0

  for (let i = 0; i < string.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << hashMultiplier) - hash)
  }

  let color = '#'

  for (let i = 0; i < colorComponentCount; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * colorComponentShift)) & colorValueMask
    const mutedValue = Math.floor((value + colorValueOffset) / mutedFactor)
    const colorString = `00${mutedValue.toString(16)}`

    color += colorString.substring(colorString.length - 2)
  }

  return color
}
