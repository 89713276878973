import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

/**
 * A simple button with icon and text<br>
 * Can be used as Toggle Button when providing active state
 */
export const IconButtonText = forwardRef((
  {
    text,
    icon,
    active,
    onClick,
    activeColor = 'blue.45',
    color = 'blue.45',
    enableHover = false,
    sx = [],
    ...otherProps
  },
  ref
) => (
  <Stack
    data-testid="IconButtonText"
    ref={ref}
    onClick={onClick}
    direction="row"
    spacing="6px"
    className={active ? 'active' : null}
    sx={mergeSxProps(
      {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
          cursor: 'pointer',
          '> *': { fontWeight: enableHover ? 'bold' : 'initial' }
        },
        '&.active > *': {
          fontWeight: 'bold',
          color: `${activeColor}`
        },
        '> *': { color }
      },
      sx
    )}
    {...otherProps}
  >
    {icon}

    <Typography className="iconButtonText">{text}</Typography>
  </Stack>
))

IconButtonText.propTypes = {
  /** Text to display */
  text: PropTypes.string.isRequired,
  /** Adds the icon */
  icon: PropTypes.object,
  /** Controls active state of the button */
  active: PropTypes.bool,
  /** onClick event of button */
  onClick: PropTypes.func,
  /** Color when not active */
  color: PropTypes.string,
  /** Color when active */
  activeColor: PropTypes.string,
  /** Enables additional styling on hover over button */
  enableHover: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
