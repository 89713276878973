import { GridViewOutlined, ViewHeadlineOutlined } from '@mui/icons-material'
import BusinessIcon from '@mui/icons-material/Business'
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined'
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined'
import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CacheKey } from '../../../data'
import { COUNTRY_LIST, ORG_COMMUNITIES, ORG_FORMS } from '../../../util'
import { CountryFlag } from '../../icon'
import { GenericSearch } from '../../search'
import { OrgSearchGridViewRenderer } from './OrgSearchGridViewRenderer'

/**
 * **OrgSearch Component**
 *
 * The `OrgSearch` component is a reusable component that provides a search interface for organizations.
 * It allows users to search for organizations using a specified API endpoint and query string.
 *
 * **Important Note**
 *
 * This component is built upon the foundation of the GenericSearch component, thus inheriting all properties supported by GenericSearch.
 * For a comprehensive list of available properties, please refer to the [GenericSearch documentation](./?path=/docs/alice-ui-search-genericsearch--docs).
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 *
 * ** Custom filters, API restrain set **
 *
 * Special case when the response depends directly on the provided filters set. So when in filter popover 'all' button
 * is checked - response will contain results only for provided filters.
 *
 * - `isRestrictedDataHidden` prop must be provided in this case.
 * - `preselectedCommunities` prop must be provided in case communities filter is desired target for API restriction.
 * - `preselectedForms` prop must be provided in case forms filter is desired target for API restriction.
 * - `preselectedStatus` prop must be provided in case status filter is desired target for API restriction.
 * - `preselectedCountry` prop must be provided in case country filter is desired target for API restriction.
 *
 * ** Notes **
 * - It is not mandatory to use all the 'preselected' props simultaneously. It is up to you to decide which filter is
 * going to use API restrictions.
 * - For better understanding of the dependencies check out 'Filter customization' documentation in
 * [GenericSearch](./?path=/docs/alice-ui-search-genericsearch--docs)
 * - Note that it works the same way in all searches.
 *
 * ** API restrain set for communities filter example **
 *
 * ```jsx
 * <OrgSearch
 *    apiUrl={'/organizations'}
 *    isRestrictedDataHidden // sets restriction to show other results except for the predefined ones
 *    preselectedCommunities={[ 'DEALER', 'SUPPLIER' ]} // sets as preselected, defines default 'all' result
 *    communities={[ 'DEALER', 'SUPPLIER' ]} // defines set of filter options that are only displayed
 *    // Other desired/important props
 * />
 * ```
 */
export const OrgSearch = forwardRef((
  {
    title,
    description,
    apiUrl = '/organizations',
    search = 'large',
    views = [ 'grid', 'list' ],
    itemSize,
    forms = ORG_FORMS,
    communities = ORG_COMMUNITIES.filter((community) => community !== 'TEST_ORGANIZATION'),
    countries = COUNTRY_LIST,
    isIdLink = true,
    isFilterHidden,
    isSortHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    preselectedRowIds = [],
    preselectedView,
    preselectedSearch,
    preselectedSort,
    preselectedStatus = 'active',
    preselectedForms,
    preselectedCommunities,
    preselectedCountry,
    preselectedRowsPerPage,
    preselectedSearchBehavior,
    preselectedSortBehavior,
    preselectedFilterBehavior,
    isCaching = false,
    cacheKey = CacheKey.orgSearchQuery,
    onGetResponseData = (data) => data.organizations,
    onGetUniqueIdentifier = (data) => data.id,
    onCardClick,
    rendererProps = {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const createViewConfig = () => {
    const viewConfig = { views: { } }

    if (views.includes('grid')) {
      viewConfig.views.grid = {
        tooltip: t('orgSearch.view.grid'),
        icon: GridViewOutlined,
        renderer: OrgSearchGridViewRenderer
      }
    }

    if (views.includes('list')) {
      viewConfig.views.list = {
        tooltip: t('orgSearch.view.list'),
        icon: ViewHeadlineOutlined,
        renderer: OrgSearchGridViewRenderer
      }
    }

    return viewConfig
  }

  const createSearchConfig = () => {
    if (search === undefined || search === 'none') return undefined

    return {
      type: search,
      label: t('orgSearch.search.label'),
      preselectedBehavior: preselectedSearchBehavior
    }
  }

  const createFilterConfig = () => {
    if (isFilterHidden) return undefined

    return {
      isAllShown: true,
      preselectedBehavior: preselectedFilterBehavior,
      filters: {
        orgForm: {
          isAllShown: true,
          label: t('orgSearch.filter.orgForm.label'),
          searchLabel: t('orgSearch.filter.orgForm.searchLabel'),
          allLabel: t('orgSearch.filter.orgForm.allLabel'),
          icon: <BusinessIcon />,
          isMultiselect: true,
          filters: forms.map((form) => ({
            value: form,
            label: t(`orgForm.${form}`)
          }))
        },
        community: {
          isAllShown: true,
          label: t('orgSearch.filter.community.label'),
          searchLabel: t('orgSearch.filter.community.searchLabel'),
          allLabel: t('orgSearch.filter.community.allLabel'),
          icon: <GroupWorkOutlinedIcon />,
          isMultiselect: true,
          filters: communities.map((community) => ({
            value: community,
            label: t(`orgCommunity.${community}`)
          }))
        },
        country: {
          label: t('orgSearch.filter.country.label'),
          searchLabel: t('orgSearch.filter.country.searchLabel'),
          allLabel: t('orgSearch.filter.country.allLabel'),
          isAutoComplete: true,
          icon: <FlagCircleOutlinedIcon />,
          filters: countries
            .sort((a, b) => (t(`country.${a.value}`) > t(`country.${b.value}`)) - (t(`country.${a.value}`) < t(`country.${b.value}`)))
            .map((country) => ({
              value: country.value,
              label: t(`country.${country.value}`),
              renderer: (
                <Stack direction="row" gap={1}>
                  <CountryFlag countryCode={country.value} />

                  { t(`country.${country.value}`) }
                </Stack>
              )
            }))
        },
        activationState: {
          label: t('orgSearch.filter.activationState.label'),
          isSearchShown: false,
          isAllShown: false,
          filters: [
            { value: 'ACTIVE', label: t('orgSearch.filter.activationState.active') },
            { value: 'INACTIVE', label: t('orgSearch.filter.activationState.inactive') },
            { value: 'DELETED', label: t('orgSearch.filter.activationState.deleted') }
          ]
        }
      }
    }
  }

  const createPreselectedFilter = () => {
    const preselectedFilter = {}

    if (preselectedStatus) preselectedFilter.activationState = [ preselectedStatus.toUpperCase() ]
    if (preselectedCommunities) preselectedFilter.community = preselectedCommunities
    if (preselectedForms) preselectedFilter.orgForm = preselectedForms
    if (preselectedCountry) preselectedFilter.country = [ preselectedCountry.toUpperCase() ]

    return Object.keys(preselectedFilter).length ? preselectedFilter : undefined
  }

  const createSortConfig = () => {
    if (isSortHidden) return undefined

    return {
      preselectedBehavior: preselectedSortBehavior,
      sorts: [
        {
          value: '',
          label: t('genericSearch.defaultSort')
        },
        {
          value: '+name',
          label: t('appSearch.sort.+name')
        },
        {
          value: '-name',
          label: t('appSearch.sort.-name')
        }
      ]
    }
  }

  return (
    <GenericSearch
      data-testid="OrgSearch"
      ref={ref}
      title={title}
      description={description}
      getUniqueIdentifier={onGetUniqueIdentifier}
      getResponseData={onGetResponseData}
      apiUrl={apiUrl}
      cacheKey={cacheKey}
      searchConfig={createSearchConfig()}
      viewConfig={createViewConfig()}
      filterConfig={createFilterConfig()}
      sortConfig={createSortConfig()}
      preselectedRowIds={preselectedRowIds}
      preselectedView={preselectedView}
      preselectedSearch={preselectedSearch}
      preselectedSort={preselectedSort}
      preselectedFilter={createPreselectedFilter()}
      preselectedRowsPerPage={preselectedRowsPerPage}
      isCaching={isCaching}
      isTopPaginationHidden={isTopPaginationHidden}
      isBottomPaginationHidden={isBottomPaginationHidden}
      onItemClick={onCardClick}
      rendererProps={{
        isIdLink,
        size: itemSize,
        ...rendererProps
      }}
      {...otherProps}
    />
  )
})

OrgSearch.propTypes = {
  /** Adds a title */
  title: PropTypes.string,
  /** Adds a description under the title */
  description: PropTypes.string,
  /** Api-Endpoint to collect required data */
  apiUrl: PropTypes.string,
  /** Type of search. Can be 'none', 'small', 'large' */
  search: PropTypes.oneOf([ 'none', 'small', 'large' ]),
  /** Views that should be displayed. Valid views: 'grid', 'list' */
  views: PropTypes.arrayOf(PropTypes.string),
  /** size-variant for item renderer */
  itemSize: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** List of filter organization forms */
  forms: PropTypes.arrayOf(PropTypes.string),
  /** List of filter communities */
  communities: PropTypes.arrayOf(PropTypes.string),
  /** List of filter countries */
  countries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })),
  /** Determines if ID of card is a link or not */
  isIdLink: PropTypes.bool,
  /** Determines if filter is hidden or not */
  isFilterHidden: PropTypes.bool,
  /** Determines if sort is hidden or not */
  isSortHidden: PropTypes.bool,
  /** Determines if pagination on top of the grid is hidden or not */
  isTopPaginationHidden: PropTypes.bool,
  /** Determines if pagination on bottom of grid is hidden or not */
  isBottomPaginationHidden: PropTypes.bool,
  /** Preselect rows */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** Preselected view */
  preselectedView: PropTypes.string,
  /** Preselected search query */
  preselectedSearch: PropTypes.string,
  /** Preselected sort */
  preselectedSort: PropTypes.string,
  /** Preselected status */
  preselectedStatus: PropTypes.oneOf([ 'active', 'inactive', 'deleted' ]),
  /** Preselected forms */
  preselectedForms: PropTypes.arrayOf(PropTypes.string),
  /** Preselected communities */
  preselectedCommunities: PropTypes.arrayOf(PropTypes.string),
  /** Preselected country. 2 character country code is used here. For example -> 'DE' for Germany. */
  preselectedCountry: PropTypes.string,
  /** Preselected rows per page */
  preselectedRowsPerPage: PropTypes.number,
  /** Behavior of the search when preselected */
  preselectedSearchBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the sort when preselected */
  preselectedSortBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the filter when preselected. See: [GenericSearch](/?path=/docs/alice-ui-search-genericsearch--docs) */
  preselectedFilterBehavior: PropTypes.oneOf([ 'disableFilter', 'disableFilterGroup', 'hideFilterGroup' ]),
  /** Determines if caching is active or not */
  isCaching: PropTypes.bool,
  /** Custom caching key */
  cacheKey: PropTypes.string,
  /** Called by generic search to get data from api response. Could not needed of using a custom endpoint. */
  onGetResponseData: PropTypes.func,
  /** Called by generic search to get id from a single data set */
  onGetUniqueIdentifier: PropTypes.func,
  /** Callback on card click */
  onCardClick: PropTypes.func,
  /** Reference with helper functions to control the search from outside */
  reference: PropTypes.any,
  /** determines if GenericSearch should show data that does not belong to the preselected filters */
  isRestrictedDataHidden: PropTypes.bool,
  /** props directly passed to item renderer */
  rendererProps: PropTypes.object
}
