import { SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'
import { AliceIconType, useAliceIconType } from '../useIconType'

/**
 * Store SVG Icon in<br>
 * https://git.daimler.com/Alice/ComponentLibrary/tree/master/ui/icon/assets
 *
 * Export SVG in<br>
 * https://git.daimler.com/Alice/ComponentLibrary/blob/master/ui/icon/assets/index.js
 *
 * Set icon Name in<br>
 * https://git.daimler.com/Alice/ComponentLibrary/blob/master/ui/icon/useIconType.js
 */
export const AliceIcon = forwardRef((
  {
    iconType,
    size = 24,
    color,
    sx = {},
    ...otherProps
  },
  ref
) => {
  const Icon = useAliceIconType(iconType)

  return (
    <SvgIcon
      data-testid="AliceIcon"
      ref={ref}
      component={Icon}
      sx={mergeSxProps({
        width: `var(--iconSize, ${size}px) !important`,
        height: `var(--iconSize, ${size}px) !important`,
        pointerEvents: 'none',
        color: color || 'currentColor'
      }, sx)}
      inheritViewBox
      {...otherProps}
    />
  )
})

AliceIcon.propTypes = {
  /** Can use AliceIconType as reference */
  iconType: PropTypes.oneOf(Object.values(AliceIconType)).isRequired,
  /** Set width and height to value. 64 = 64px */
  size: PropTypes.number,
  /** Sets the color of the icon */
  color: PropTypes.string,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
