import React from "react";
import { useTranslation } from "react-i18next";
import { RoleConfigHead } from "@pages/newRequest/styledComponents";

const Header = () => {
  const { t } = useTranslation();
  return <RoleConfigHead>{t("role-configuration")}</RoleConfigHead>;
};

export default Header;
