import PropTypes from 'prop-types'
import { ListGroup } from './ListGroup'
import { ListLink } from './ListLink'
import { ListLinkSmall } from './ListLinkSmall'

export const ListEntry = ({
  path,
  items,
  size,
  ...otherProps
}) => {
  if (items) {
    return <ListGroup items={items} {...otherProps} />
  }

  if (size === 'small') {
    return <ListLinkSmall path={path} {...otherProps} />
  }

  return <ListLink path={path} {...otherProps} />
}

ListEntry.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
  items: PropTypes.array,
  size: PropTypes.oneOf([ 'small', 'big' ])
}
