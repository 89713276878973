import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const ImageUnavailable = () => {
  const { t } = useTranslation();
  return (
    <span>
      <svg
        // width="80px"
        // height="40px"
        viewBox="0 0 84 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="unavailableLogo appLog"
      >
        <title></title>
        <g
          id="Desktop"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="MyApps" transform="translate(-229.000000, -1990.000000)">
            <g id="Group-7" transform="translate(187.000000, 1944.000000)">
              <g id="Group-4" transform="translate(22.000000, 20.000000)">
                <g id="Group-3" transform="translate(20.000000, 26.000000)">
                  <g id="Group" transform="translate(29.958369, 0.372583)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M20,5 L4,5 L4,19 L13.292,9.706 C13.6824999,9.31561806 14.3155001,9.31561806 14.706,9.706 L20,15.01 L20,5 Z M2,3.993 C2.00380782,3.44655554 2.44555966,3.00435839 2.992,3 L21.008,3 C21.556,3 22,3.445 22,3.993 L22,20.007 C21.9961922,20.5534445 21.5544403,20.9956416 21.008,21 L2.992,21 C2.44397181,20.9994481 2,20.5550285 2,20.007 L2,3.993 Z M8,11 C6.8954305,11 6,10.1045695 6,9 C6,7.8954305 6.8954305,7 8,7 C9.1045695,7 10,7.8954305 10,9 C10,10.1045695 9.1045695,11 8,11 Z"
                      id="Shape"
                      fill="#C8C8C8"
                      fill-rule="nonzero"
                    ></path>
                    <rect
                      id="Rectangle"
                      stroke="#FFFFFF"
                      fill="#C8C8C8"
                      transform="translate(12.000000, 12.000000) rotate(135.000000) translate(-12.000000, -12.000000) "
                      x="-3"
                      y="11"
                      width="30"
                      height="2"
                    ></rect>
                  </g>
                  <text
                    id="Image-unavailable"
                    font-size="10"
                    font-weight="normal"
                    fill="#969696"
                  >
                    <tspan x="0" y="37" textLength="84px">
                      {t("img-unavailable")}
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default withTranslation()(ImageUnavailable);
