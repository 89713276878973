import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  AppCard,
  Card,
  DataClassificationChip,
} from "@alice/component_library";
import { applicationData } from "@actions";
import ImageUnavailable from "@pageIcons/imageUnavailable";

const EntitleImg = styled("img")({
  width: "100px",
  height: "60px",
  background: "white",
  "& .appLog": {
    width: "auto !important",
    height: "auto !important",
  },
});

const EntitleDiv = styled("div")({
  width: "100px",
  height: "60px",
  background: "white",
  "& .appLog": {
    width: "auto !important",
    height: "auto !important",
  },
});

const EntitelName = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  "& .appTitle": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "MB Corpo S Text",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    whiteSpace: "nowrap",
  },
});

const AppSubContainer = styled("div")({
  display: "flex",
  marginBottom: "4px",
  "& .appTitle": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "MB Corpo S Text",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    whiteSpace: "nowrap",
    color: "rgba(0, 0, 0, 0.38)",
  },
});

const AppTittelContainer = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontFamily: "MB Corpo S Text",
  fontSize: "14px !important",
  fontStyle: "normal",
  fontWeight: "700 !important",
  lineHeight: "20px !important",
  whiteSpace: "nowrap",
});

const ApplicationCard = ({
  appId,
  entitlementName = "",
  entitlementDescription = "",
  appCard = false,
  dataClassification = "",
  sx,
}) => {
  const dispatch = useDispatch();
  const {
    name = "",
    description = "",
    logoBase64 = "",
  } = useSelector((state) => state.task.applicationInfo);

  useEffect(() => {
    if (appId) {
      dispatch(applicationData(appId));
    }
    return () => dispatch(applicationData(null));
  }, [dispatch, appId]);

  const cardTitle = appCard ? name : entitlementName;
  const cardDescription = description; //appCard ? description : description;
  const cardSubTitle = appCard ? appId : name;
  return (
    <Card
      sx={{
        padding: "16px",
        backgroundColor: "#F4F4F4",
        borderRadius: "4px",
        flex: 1,
        ...sx,
      }}
      className="appCard"
    >
      {logoBase64 || name || description ? (
        <>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {logoBase64 ? (
                <EntitleImg
                  src={`data:image/png;base64,${logoBase64}`}
                  alt={appId}
                />
              ) : (
                <EntitleDiv>
                  <ImageUnavailable />
                </EntitleDiv>
              )}
            </Grid>
            <Grid item xs zeroMinWidth>
              {cardTitle && (
                <EntitelName title={cardTitle}>
                  <span className="appTitle">{cardTitle}</span>
                </EntitelName>
              )}
              {cardDescription && (
                <AppSubContainer title={cardDescription}>
                  <span className="appTitle">{cardDescription}</span>
                </AppSubContainer>
              )}

              {cardSubTitle && (
                <AppTittelContainer title={cardSubTitle}>
                  {cardSubTitle}
                </AppTittelContainer>
              )}
            </Grid>
          </Grid>
          {dataClassification.length > 0 && (
            <>
              <Divider
                orientation={"horizontal"}
                sx={{ gridArea: "divider" }}
              />
              <Stack
                sx={[
                  {
                    gridArea: "contextualChips",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                    gap: 1,
                    marginTop: "10px",
                  },
                ]}
              >
                <DataClassificationChip
                  type={dataClassification.toLowerCase()}
                  size="s"
                />
              </Stack>
            </>
          )}
        </>
      ) : (
        <AppCard
          data={{
            email: "thisIsAnEmail@mb.com",
            id: "This_Is_An_Id",
            name: "This is a Name",
            url: "https://thisIsAnUrl.com",
          }}
          isLoading={true}
          variant="tiny"
        />
      )}
    </Card>
  );
};
export default ApplicationCard;
