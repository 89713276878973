import React from "react";
import "../App.css";
import {
  FormControlLabel,
  Switch,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const StyledLabel = styled("label")({
  fontFamily: "MB Corpo S Text",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  letterSpacing: "0.2px",
  lineHeight: "20px",
  color: "#767676",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export function InputControl(params) {
  const { name, labelName, type, options, placeholder } = params;
  const { icon, value, onChange, checked, required, disabled } = params;
  const { rows, onOpen, style, className = "form-control" } = params;

  if (type === "text") {
    return (
      <div className="form-group">
        <StyledLabel for={name}>
          {labelName}
          {required ? <span className="m-2">*</span> : null}
          {icon}
        </StyledLabel>
        <input
          type={type}
          className="form-control"
          id={name}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    );
  }
  if (type === "select") {
    return (
      <div className="form-group">
        <StyledFormControl variant="filled" style={style} disabled={disabled}>
          <InputLabel id="demo-simple-select-filled-label">
            {labelName}
          </InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={value}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            onOpen={onOpen}
            inputProps={{
              name: name,
            }}
            disableUnderline={
              params.disableUnderline ? params.disableUnderline : false
            }
          >
            {options.map((x, index) => (
              <MenuItem key={index} value={x.value} defaultValue={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </div>
    );
  }
  if (type === "multi-select") {
    return (
      <div className="form-group">
        <StyledFormControl variant="filled">
          <InputLabel id="demo-mutiple-checkbox-label">{labelName}</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-multi-select-filled-label"
            multiple
            value={value}
            defaultValue={options[0].label}
            onChange={onChange}
            inputProps={{ name: name }}
            renderValue={(selected) =>
              selected.length === 1 && value[0] === "All Communities"
                ? "All Communities"
                : selected.length + " of " + options.length + " Selected "
            }
            MenuProps={MenuProps}
          >
            {options.map((x, index) => (
              <MenuItem key={index} value={x.value} defaultValue={x.value}>
                <Checkbox checked={value.indexOf(x.value) > -1} />
                <ListItemText primary={x.label} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </div>
    );
  }

  if (type === "only-multi-select") {
    return (
      <div className="form-group">
        <StyledFormControl variant="filled" disabled={disabled}>
          <InputLabel id="demo-simple-select-filled-label">
            {labelName}
          </InputLabel>
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            multiple
            value={value}
            onChange={onChange}
            // inputProps={{
            //     name: name
            // }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Placeholder</em>;
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
          >
            {options.map((x, index) => (
              <MenuItem key={index} value={x.value} defaultValue={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </div>
    );
  }

  if (type === "only-multi-select") {
    return (
      <div className="form-group">
        <StyledFormControl variant="filled">
          <InputLabel id="demo-mutiple-checkbox-label">{labelName}</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-multi-select-filled-label"
            multiple
            value={value}
            // defaultValue={options[0].label}
            onChange={onChange}
            inputProps={{ name: name }}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {options.map((x, index) => (
              <MenuItem
                key={index}
                value={x.value}
                // defaultValue={x.value}
              ></MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </div>
    );
  }
  if (type === "text-area") {
    return (
      <div className="form-group">
        <StyledLabel for={name}>
          {labelName}
          {required ? <span className="m-2">*</span> : null}
          {icon}
        </StyledLabel>
        <textarea
          type={type}
          id={name}
          rows={rows ? rows : "3"}
          className={className}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    );
  }
  if (type === "switch") {
    return (
      <div className="form-group">
        <StyledLabel for={name}>
          {labelName}
          {required ? <span className="m-2">*</span> : null}
        </StyledLabel>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={onChange}
              name={name}
              id={name}
            />
          }
          labelPlacement="top"
        />
      </div>
    );
  }
}
