import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select, TextField } from "@alice/component_library";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { Remove_reason } from "@constants/Dropdowns";
import {
  DialogContainer,
  DialogFromContainer,
  StyleDialogTitle,
  StyledDialogContentText,
  StyledDialogContentTextForRemoval,
  DeleteAllCardContainer,
  StyledDialog,
  StyledDialogContentDelete,
  StyledDialogAction,
} from "../../styledComponents";
import { CustomCard } from "../Card/Card";

import CancelIcon from "@mui/icons-material/Cancel";
import { useWidth } from "@utils/hooks";
import RoleCardV3 from "@src/sharedComponents/RoleCardV3/RoleCardV3";

const DeleteDialog = ({
  dialogOpen,
  handleDismiss,
  t,
  actionHandler,
  selectedData,
  isNeedReason,
  deleteRemoveHandler,
}) => {
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");

  const onSelectChange = async (event) => {
    setReason(event.target.value);
  };

  let disabled = true;

  const [isSmall, setIsSmall] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const widthPanel = useWidth();

  const REASON_CHARACTER_LIMIT = 400;

  useEffect(() => {
    switch (widthPanel) {
      case "sm":
      case "xs":
        setIsSmall(true);
        break;
      case "md":
      case "lg":
        setIsSmall(false);
        break;
      case "xl":
        setIsSmall(false);
        break;
      default: {
        setIsSmall(true);
      }
    }
  }, [widthPanel]);

  const deleteSelectedValue = () => {
    setReason("");
    actionHandler(reason, comment);
  };

  const onModalClose = () => {
    setComment("");
    setReason("");
    setSelectedRole(null);
    handleDismiss();
    setSelectedRole(null);
  };

  const removeReason = Remove_reason.map((reason) => ({
    label: t(reason.value),
    value: reason.key,
  }));

  removeReason.push({ label: t("removal-reason.other"), value: "other" });

  const textFieldBox = () => (
    <>
      <TextField
        label={t("please-insert-reason")}
        InputLabelProps={{
          shrink: true,
        }}
        rows={2}
        value={comment}
        fullWidth
        multiline
        variant="filled"
        maxLength={REASON_CHARACTER_LIMIT}
        inputProps={{
          maxLength: REASON_CHARACTER_LIMIT,
        }}
        error={
          comment && comment.length === Number(REASON_CHARACTER_LIMIT)
            ? true
            : false
        }
        onChange={(e) => setComment(e.target.value)}
        onBlur={(e) => setComment(e.target.value.trim())}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "0.8rem",
        }}
      >
        <span>{t("min-20-chars-requeired-info")}</span>
        <span>
          {comment.trim().length} / {REASON_CHARACTER_LIMIT}
        </span>
      </Box>
    </>
  );

  const handleDisable = (comment, reason, isNeedReason) => {
    if (reason === "" && isNeedReason) {
      disabled = true;
    } else if (reason === "other") {
      if (comment.trim().length > 19) disabled = false;
      else disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };

  const RoleCardForDelete = selectedData.length === 1 ? RoleCardV3 : CustomCard;
  return (
    <React.Fragment>
      <StyledDialog
        fullWidth={true}
        maxWidth="md"
        PaperProps={{
          style: { borderRadius: 2 },
        }}
        open={dialogOpen}
        onClose={onModalClose}
      >
        <DialogActions>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => onModalClose()}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              minWidth: 34,
              padding: 0,
            }}
            disableElevation
          >
            <CloseIcon
              sx={{
                margin: ".25em",
              }}
            />
          </Button>
        </DialogActions>

        <StyleDialogTitle>
          {selectedData.length === 1 ? t("delete-role") : t("delete-roles")}
          <StyledDialogContentText>
            {t("delete-confirmation-message")}
          </StyledDialogContentText>
        </StyleDialogTitle>

        <StyledDialogContentDelete>
          {isNeedReason ? (
            <div className="select-contatiner">
              <StyledDialogContentTextForRemoval margin="24px" padding="0px">
                {t("delete-reason")}
              </StyledDialogContentTextForRemoval>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "100%",
                  rowGap: "5px",
                }}
              >
                <DialogContainer container xs={12} sm={12} md={12} lg={12}>
                  <DialogFromContainer variant="filled">
                    <InputLabel id="demo-simple-select-filled-label">
                      {t("user_pleaseSelect")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={reason}
                      required={true}
                      onChange={(e) => onSelectChange(e)}
                      inputProps={{
                        name: removeReason,
                      }}
                    >
                      {removeReason.map((x, index) => (
                        <MenuItem
                          key={index}
                          value={x.value}
                          defaultValue={x.value}
                        >
                          {x.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </DialogFromContainer>
                </DialogContainer>
                <Box>{reason === "other" && textFieldBox()}</Box>
              </Box>
            </div>
          ) : null}

          <StyledDialogContentTextForRemoval
            margin={isNeedReason ? "24px" : "32px"}
          >
            {selectedData.length === 1
              ? t("selected-role")
              : t("selected-roles")}
            {selectedData.length !== 1 ? (
              <span className="counter-span">{selectedData.length}</span>
            ) : (
              ""
            )}
          </StyledDialogContentTextForRemoval>

          <DeleteAllCardContainer>
            {selectedData.map((item) => (
              <div className="role-card-container">
                {selectedData.length !== 1 ? (
                  <CancelIcon
                    className="close-icon"
                    onClick={() => deleteRemoveHandler(item.id)}
                  />
                ) : (
                  <></>
                )}
                <div className="role-card-wrapper">
                  <RoleCardForDelete
                    data={item}
                    hideActions={true}
                    showSelection={false}
                    showMore={false}
                    size={isSmall ? "small" : "large"}
                    onClick={() =>
                      setSelectedRole((prev) =>
                        prev === item.id ? null : item.id
                      )
                    }
                  />
                  {selectedRole === item.id && (
                    <Box sx={{ marginTop: "5px" }}>
                      <RoleCardV3
                        data={item}
                        hideActions={true}
                        showSelection={false}
                        showMore={false}
                        size={isSmall ? "small" : "large"}
                      />
                    </Box>
                  )}
                </div>
              </div>
            ))}
          </DeleteAllCardContainer>
        </StyledDialogContentDelete>
        <StyledDialogAction>
          <SecondaryButton
            label={t("dismiss")}
            onButtonClick={() => onModalClose()}
            className="mr-25"
          />

          <PrimaryButton
            label={
              selectedData.length === 1
                ? t("delete-role")
                : t("delete-count-roles", { count: selectedData.length })
            }
            onButtonClick={() => deleteSelectedValue()}
            className="user-save-button-align pointer"
            disabled={handleDisable(comment, reason, isNeedReason)}
          />
        </StyledDialogAction>
      </StyledDialog>
    </React.Fragment>
  );
};

export default DeleteDialog;
