import urlConfig from "../services/urlConfig";
import Communication from "@src/services/Communication";
import { hasData } from "../utils/Validator";

export const isObjectEmpty = (objectName) => {
  return JSON.stringify(objectName) === "{}";
};

const getTaskUrl = (
  user,
  status,
  search,
  sort = "-",
  isAdmin = false,
  offset = 0,
  limit = 50
) => {
  let taskPath = "alltasks";

  let filter = `(state==${status})`;

  if (!isAdmin) {
    filter = `(recipients.id==${user};state==${status})`;
    taskPath = "tasks";
  }

  let filterData = `${filter}`;
  if (search.trim() !== "") {
    const splitData = search.trim().split(" ");
    let filterQuery = "";

    splitData.forEach((d) => {
      if (d !== "") {
        const id = `id=='*${d}*'`;
        const userId = `affectedData.user.id=='*${d}*'`;
        const givenName = `affectedData.user.givenname=='*${d}*'`;
        const surname = `affectedData.user.surname=='*${d}*'`;
        const orgId = `originator.id=='*${d}*'`;
        const orgGivenName = `originator.givenname=='*${d}*'`;
        const orgSurName = `originator.surname=='*${d}*'`;

        const temp = `(${id},${userId},${givenName},${surname},${orgId},${orgGivenName},${orgSurName})`;
        filterQuery = filterQuery === "" ? temp : `${filterQuery},${temp}`;
      }
    });
    filterData = `${filter};(${filterQuery})`;
  }

  const params = `filter=${filterData}&sort=${sort}creationDate&offset=${offset}&limit=${limit}`;
  return `/gems/${taskPath}?${params}`;
};

const getTaskFilter = (status, wf = false) => {
  const taskStates = {
    PENDING: "WAITING",
    COMPLETED: "APPROVED",
    REJECTED: "DENIED",
    FAILED: "CANCELED",
    EXPIRED: "CANCELED",
  };
  let statusFilter = taskStates[status]
    ? `tasks.taskData.state==${taskStates[status]}`
    : "";
  if (wf) {
    if (status === "COMPLETED") statusFilter += `;(state==COMPLETED)`;
    if (status === "REJECTED")
      statusFilter += `,((state==EXTERNALLY_TERMINATED),(state==INTERNALLY_TERMINATED),(state==COMPLETED))`;
    if (status === "PENDING")
      statusFilter = `((${statusFilter};state==ACTIVE),(state==ACTIVE))`;
    if (status === "EXPIRED") statusFilter += `;(state==SUSPENDED)`; // TODO: check status for expired
    return statusFilter;
  } else return statusFilter;
};

const getWorkFlowUrl = (
  status,
  search,
  user = "",
  isTask = false,
  isAdmin = false,
  offset = 0,
  limit = 500,
  requestPage = false,
  isFromDashboard,
  order
) => {
  const taskRec = `tasks.recipients.id==${user}`;
  const wfRequester = `workflowData.requester.id==${user}`;
  const wfAssigner = `workflowData.roleToUser.user.id==${user}`;
  const sort = `&sort=${order}startTime`;

  const assignees = `${wfRequester},${wfAssigner}`;

  const taskFilter = getTaskFilter(status, requestPage); // (abc)

  let assigneeFilter = isTask ? taskRec : assignees;

  if (isAdmin) assigneeFilter = "";
  let statusFilter = taskFilter;

  if (assigneeFilter !== "" && taskFilter !== "")
    statusFilter = `(${taskFilter});(${assigneeFilter})`;
  else if (assigneeFilter !== "") statusFilter = assigneeFilter;
  let searchFilter = "";
  if (search !== "") {
    const query = `'*${search.trim()}*'`;
    const id = `id==${query}`;
    const userId = `workflowData.requester.id==${query}`;

    const givenName = `workflowData.requester.givenname==${query},workflowData.roleToUser.user.givenname==${query}`;
    const surname = `workflowData.requester.surname==${query},workflowData.roleToUser.user.surname==${query}`;

    const taskGivenName = `tasks.recipients.givenname==${query}`;
    const taskSurname = `tasks.recipients.surname==${query}`;

    searchFilter = `(${id},${userId},${givenName},${surname},${taskGivenName},${taskSurname})`;
  }
  let filter = `${statusFilter}${searchFilter}`; // expecting any one filter
  if (statusFilter !== "" && searchFilter !== "")
    filter = `(${statusFilter});${searchFilter}`;

  filter =
    filter !== ""
      ? `?filter=(${filter})&offset=${offset}&limit=${limit}${
          !isFromDashboard ? sort : ""
        }`
      : `?offset=${offset}&limit=${limit}${!isFromDashboard ? sort : ""}`;
  return `${urlConfig.workflows()}${filter}`;
};

const getWorkFlowUrlWithRejected = (
  status,
  search,
  user = "",
  isTask = false,
  isAdmin = false,
  offset = 0,
  limit = 500
) => {
  const taskRec = `tasks.recipients.id==${user}`;
  const wfRequester = `workflowData.requester.id==${user}`;
  const wfAssigner = `workflowData.roleToUser.user.id==${user}`;

  const assignees = `${wfRequester},${wfAssigner}`;

  let assigneeFilter = isTask ? taskRec : assignees;

  if (isAdmin) assigneeFilter = "";
  let statusFilter = `(${assigneeFilter})`;

  let searchFilter = "";
  if (search !== "") {
    const query = `'*${search.trim()}*'`;
    const id = `id==${query}`;
    const userId = `workflowData.requester.id==${query}`;

    const givenName = `workflowData.requester.givenname==${query},workflowData.roleToUser.user.givenname==${query}`;
    const surname = `workflowData.requester.surname==${query},workflowData.roleToUser.user.surname==${query}`;

    const taskGivenName = `tasks.recipients.givenname==${query}`;
    const taskSurname = `tasks.recipients.surname==${query}`;

    searchFilter = `(${id},${userId},${givenName},${surname},${taskGivenName},${taskSurname})`;
  }

  let filter = `${statusFilter}${searchFilter}`; // expecting any one filter
  if (statusFilter !== "" && searchFilter !== "")
    filter = `(${statusFilter});${searchFilter}`;

  filter = `?filter=(${filter});state==${status}&offset=${offset}&limit=${limit}`;
  return `${urlConfig.workflows()}${filter}`;
};

const stateMap = {
  WAITING: "PENDING",
  APPROVED: "COMPLETED",
  COMPLETED: "COMPLETED",
  DENIED: "REJECTED",
  CANCELED: "FAILED",
  UNKNOWN: "UNKNOWN",
};

const getActiveItem = (d, isTask, user, isAdmin, workFlowState = []) => {
  try {
    const wfTasks = d.tasks || [];
    wfTasks.sort((a, b) => {
      const timeStampa = new Date(a.startTime).getTime();
      const timeStampb = new Date(b.startTime).getTime();
      return timeStampa - timeStampb;
    });

    const activeTask = wfTasks?.find((t) => t.id === d.currentTaskId) ?? {};
    let task = activeTask;
    if (
      isTask &&
      (!hasData(activeTask) ||
        activeTask.recipients?.findIndex((u) => u.id === user) === -1)
    )
      task = !isAdmin
        ? wfTasks.find((t) => t.recipients?.find((u) => u.id === user)) ?? {}
        : {};

    if (!hasData(task) && hasData(wfTasks)) task = wfTasks[wfTasks.length - 1];

    if (isAdmin && workFlowState.length && task.state !== workFlowState[0]) {
      task = {};
    }
    return task;
  } catch (err) {
    console.log(err);
    return {};
  }
};

const getState = (d, task = {}, isTask) => {
  const { tasks = [] } = d;
  if (isTask) return stateMap[task?.state] ?? d.state;
  let state =
    tasks.findIndex((d) => d.state === "DENIED") > -1
      ? "REJECTED"
      : stateMap[task?.state] ?? d.state;
  if (state === "UNKNOWN") state = d.state;
  return state;
};

export const getTaskName = (id) => {
  const rolesApi = `/gems/roles/${id}`;
  return Communication.get(rolesApi)
    .then((reposnse) => reposnse.data)
    .catch((error) => {
      return id;
    });
};

const getItemForTask = (workflowInfo, task, state, type) => {
  try {
    switch (type) {
      case "ENTITLEMENT_TO_ROLE_APPROVAL":
        return {
          creationDate: workflowInfo.startTime,
          expiryDate: workflowInfo.expiryTime,
          lastModifyDate:
            "endTime" in task ? task.endTime : workflowInfo.endTime, // "endTime" in d ? task.endTime : d.endTime
          affectedData: {
            entitlement: {
              entitlementId:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.entitlementId,
              dataClassification:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.dataClassification,
              applicationName:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.applicationName,
              applicationId:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.applicationId,
            },
            role: { ...workflowInfo.workflowData?.entitlementToRole?.role },
          },
          id: workflowInfo.id,
          originator: workflowInfo.workflowData?.requester,
          recipients: task?.recipients ?? [],
          requestReason: workflowInfo.workflowData?.requestReason,
          state:
            workflowInfo.state === "EXTERNALLY_TERMINATED"
              ? "REJECTED"
              : workflowInfo.state === "INTERNALLY_TERMINATED"
              ? "EXPIRED"
              : state,
          taskType:
            workflowInfo.workflowType || workflowInfo.workflowData?.type,
          isWorkFlow: true,
          currentTaskId: workflowInfo.currentTaskId,
          workflowId: task.workflowId,
          aprooverId: task?.approverId ?? "",
        };
      default:
        return {
          creationDate: workflowInfo.startTime,
          expiryDate: workflowInfo.expiryTime,
          lastModifyDate:
            "endTime" in task ? task.endTime : workflowInfo.endTime, // "endTime" in d ? task.endTime : d.endTime
          affectedData: {
            validityFrom:
              workflowInfo.workflowData?.roleToUser?.validFrom?.replace(
                "[UTC]",
                ""
              ) ?? "",
            validityTo:
              workflowInfo.workflowData?.roleToUser?.validTo?.replace(
                "[UTC]",
                ""
              ) ?? "",
            customScopes: workflowInfo.workflowData?.roleToUser?.customScopeName
              ? [
                  {
                    name: workflowInfo.workflowData?.roleToUser
                      ?.customScopeName,
                  },
                ]
              : [],
            orgScopes: workflowInfo.workflowData?.roleToUser?.orgScope
              ? [workflowInfo.workflowData?.roleToUser?.orgScope]
              : [],
            role: {
              description: "",
              id: workflowInfo.workflowData?.roleToUser?.roleId,
            },
            user: workflowInfo.workflowData?.roleToUser?.user,
          },
          id: workflowInfo.id,
          originator: workflowInfo.workflowData?.requester,
          recipients: task?.recipients ?? [],
          requestReason: workflowInfo.workflowData?.requestReason,
          state:
            workflowInfo.state === "EXTERNALLY_TERMINATED"
              ? "REJECTED"
              : workflowInfo.state === "INTERNALLY_TERMINATED"
              ? "EXPIRED"
              : state,
          taskType:
            workflowInfo.workflowType || workflowInfo.workflowData?.type,
          isWorkFlow: true,
          currentTaskId: workflowInfo.currentTaskId,
          workflowId: task.workflowId,
          aprooverId: task?.approverId ?? "",
        };
    }
  } catch (err) {
    console.log(err);
    return {};
  }
};

const getItemForWorkFlow = (workflowInfo, task, state, type) => {
  try {
    switch (type) {
      case "ENTITLEMENT_TO_ROLE_APPROVAL":
        return {
          creationDate: workflowInfo.startTime,
          expiryDate: workflowInfo.expiryTime,
          lastModifyDate:
            "endTime" in task ? task.endTime : workflowInfo.endTime, // "endTime" in d ? task.endTime : d.endTime
          affectedData: {
            entitlement: {
              entitlementId:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.entitlementId,
              dataClassification:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.dataClassification,
              applicationName:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.applicationName,
              applicationId:
                workflowInfo.workflowData?.entitlementToRole?.entitlement
                  ?.applicationId,
            },
            role: { ...workflowInfo.workflowData?.entitlementToRole?.role },
          },
          id: workflowInfo.id,
          originator: workflowInfo.workflowData?.requester,
          recipients: task?.recipients ?? [],
          requestReason: workflowInfo.workflowData?.requestReason,
          state:
            workflowInfo.state === "EXTERNALLY_TERMINATED"
              ? "REJECTED"
              : workflowInfo.state === "INTERNALLY_TERMINATED"
              ? "EXPIRED"
              : state,
          taskType:
            workflowInfo.workflowType || workflowInfo.workflowData?.type,
          isWorkFlow: true,
          currentTaskId: workflowInfo.currentTaskId,
          workflowId: task.workflowId,
          aprooverId: task?.approverId ?? "",
        };
      default:
        return {
          creationDate: workflowInfo.startTime,
          expiryDate: workflowInfo.expiryTime,
          lastModifyDate:
            "endTime" in task ? task.endTime : workflowInfo.endTime,
          affectedData: {
            validityFrom:
              workflowInfo.workflowData?.roleToUser?.validFrom?.replace(
                "[UTC]",
                ""
              ) ?? "",
            validityTo:
              workflowInfo.workflowData?.roleToUser?.validTo?.replace(
                "[UTC]",
                ""
              ) ?? "",
            customScopes: workflowInfo.workflowData?.roleToUser?.customScopeName
              ? [
                  {
                    name: workflowInfo.workflowData?.roleToUser
                      ?.customScopeName,
                  },
                ]
              : [],
            orgScopes: workflowInfo.workflowData?.roleToUser?.orgScope
              ? [workflowInfo.workflowData?.roleToUser?.orgScope]
              : [],
            role: {
              description: "",
              id: workflowInfo.workflowData?.roleToUser?.roleId,
            },
            user: workflowInfo.workflowData?.roleToUser?.user,
          },
          id: workflowInfo?.id,
          originator: workflowInfo?.workflowData?.requester,
          recipients: task?.recipients ?? [],
          requestReason: workflowInfo?.workflowData?.requestReason,
          state: state,
          taskType:
            workflowInfo?.workflowType || workflowInfo?.workflowData?.type,
          isWorkFlow: true,
          currentTaskId: workflowInfo?.currentTaskId,
          workflowId: task.workflowId,
          aprooverId: task?.approverId ?? "",
        };
    }
  } catch (err) {
    console.log(err);
    return {};
  }
};

const convertWorkFlow = (
  data,
  isTask = false,
  user,
  status = "",
  isAdmin = false,
  workFlowState
) => {
  const tasks = [];
  data.forEach((d) => {
    const task = getActiveItem(d, isTask, user, isAdmin, workFlowState);
    const taskType = d.workflowType || d.workflowData?.type;
    // if (!isObjectEmpty(task)) {
    let state = getState(d, task, isTask);
    const item = getItemForTask(d, task, state, taskType);
    if (state === "REJECTED" || state === "COMPLETED")
      item.approvalRejectionReason = task?.comment;
    if (
      d.state === "INTERNALLY_TERMINATED" ||
      d.state === "EXTERNALLY_TERMINATED"
    )
      item.approvalRejectionReason = d.workflowData?.errorState?.errorText;
    if (status === "COMPLETED") {
      if (d?.tasks?.every((task) => task.state === "APPROVED"))
        tasks.push(item);
    } else tasks.push(item);
    // }
  });

  return tasks;
};

const getStateWF = (d, task = {}, isTask) => {
  const { tasks = [] } = d;
  if (isTask) return stateMap[task?.state] ?? "";
  let state =
    tasks.findIndex((d) => d.state === "DENIED") > -1
      ? "REJECTED"
      : stateMap[task?.state] ?? d.state;
  if (
    ["INTERNALLY_TERMINATED", "EXTERNALLY_TERMINATED"].findIndex(
      (value) => value === d.state
    ) > -1
  )
    state = "REJECTED";
  if (state === "UNKNOWN") state = d.state;
  return state;
};

const convertWorkFlowForRequest = (data, isTask = false, status = "") => {
  const tasks = [];
  data.forEach((d) => {
    const taskType = d.workflowType || d.workflowData?.type;
    const task = getActiveItemForWF(d);
    let state = getStateWF(d, task, isTask);
    const item = getItemForWorkFlow(d, task, state, taskType);

    if (state === "REJECTED" || state === "COMPLETED")
      item.approvalRejectionReason = task?.comment;
    if (
      d.state === "INTERNALLY_TERMINATED" ||
      d.state === "EXTERNALLY_TERMINATED"
    )
      item.approvalRejectionReason =
        (d.workflowData?.errorState?.errorText).includes(":")
          ? (d.workflowData?.errorState?.errorText).split(":")[0].trim()
          : (d.workflowData?.errorState?.errorText).trim();
    if (status === "COMPLETED") {
      if (d?.tasks?.every((task) => task.state === "APPROVED"))
        tasks.push(item);
    } else tasks.push(item);
  });

  return tasks;
};

const getRequestsUrl = (
  user,
  search,
  status,
  order,
  limit = 500,
  isFromDashboard = false,
  offset
) => {
  const query = search.trim();
  let url = "/gems/tasks?filter=";
  const aUser = `affectedData.user.id==*${user}*`;
  const orginator = `originator.id==${user}`;
  const sort = `&sort=${order}creationDate`;
  let filterData = `(${aUser},${orginator})`;

  if (query !== "") {
    const splitData = query.split(" ");
    let filterQuery = "";
    splitData.forEach((d) => {
      if (d !== "") {
        const encode = encodeURIComponent(d);
        const id = `id=='*${encode}*'`;
        const givenname = `originator.givenname=='*${encode}*'`;
        const surname = `originator.surname=='*${encode}*'`;
        const aGivenname = `affectedData.user.givenname=='*${encode}*'`;
        const aSurname = `affectedData.user.surname=='*${encode}*'`;
        const filter = `(${id},${givenname},${surname},${aGivenname},${aSurname})`;
        filterQuery = filterQuery === "" ? filter : filterQuery + "," + filter;
      }
    });
    filterData = `${filterData};(${filterQuery})`;
  }
  if (status !== "Show all" && filterData.length) {
    filterData += `;state==${status}`;
  } else if (status !== "Show all" && !filterData.legth) {
    filterData += `state==${status}`;
  }

  if (!filterData.length) {
    url = "/gems/tasks?";
  }

  if (isFromDashboard)
    return `${url}${filterData}&offset=${offset}&limit=${limit}`;
  return `${url}${filterData}${sort}&offset=${offset}&limit=${limit}`;
};

const statusLabelIconMap = {
  COMPLETED: { label: "completed", icon: "overview-active-icon" },
  REJECTED: { label: "rejected", icon: "overview-deactive-icon" },
  PENDING: { label: "pending", icon: "overview-pending-icon" },
  ACTIVE: { label: "pending", icon: "overview-pending-icon" },
  FAILED: { label: "failed", icon: "overview-failed-icon" },
  EXPIRED: { label: "expired", icon: "overview-expired-icon" },
};

const getTaskAndWFFilter = (filter) => {
  const taskStates = {
    PENDING: ["PENDING"],
    APROOVED: ["COMPLETED"],
    REJECTED: ["REJECTED"],
    FAILED: ["FAILED"],
    EXPIRED: ["EXPIRED"],
  };

  const wfStates = {
    taskStates: {
      PENDING: ["WAITING"],
      APROOVED: ["APPROVED"],
      REJECTED: ["DENIED"],
      FAILED: ["CANCELED"],
      EXPIRED: ["CANCELED"],
    },
    wfStates: {
      PENDING: ["ACTIVE"],
      APROOVED: ["COMPLETED"],
      REJECTED: ["COMPLETED", "EXTERNALLY_TERMINATED"],
      FAILED: ["SUSPENDED", "COMPLETED"],
      EXPIRED: ["SUSPENDED", "INTERNALLY_TERMINATED"],
    },
  };

  const taskFilter = taskStates[filter];
  const wfFilterTask = wfStates.taskStates[filter];
  const wfFilter = wfStates.wfStates[filter];

  return { taskFilter, wfFilter, wfFilterTask };
};

const getTaskstructInfo = (type) => {
  let structInfo = "allTasks";
  let countInfo = "tottalCount";
  switch (type) {
    case "APPROVE_ROLE_ASSIGNMENT":
      structInfo = "roleRequest";
      countInfo = "roleRequestCount";
      break;
    case "APPROVE_USER_CREATION":
      structInfo = "userCreation";
      countInfo = "usercreationCount";
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      structInfo = "userToOrganization";
      countInfo = "orgCount";
      break;
    case "APPROVE_ORG_ADMIN_PERMISSION":
      structInfo = "orgAdminPermission";
      countInfo = "orgAdminCount";
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      structInfo = "deProvisionInfo";
      countInfo = "deprovisioningCount";
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      structInfo = "techUserToapplication";
      countInfo = "techUserCount";
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
      structInfo = "entitelementToRole";
      countInfo = "entitileMentCount";
      break;
    default:
      break;
  }

  return { structInfo, countInfo };
};

const getActiveItemForWF = (d) => {
  try {
    const wfTasks = d.tasks || [];
    wfTasks.sort((a, b) => {
      const timeStampa = new Date(a.startTime).getTime();
      const timeStampb = new Date(b.startTime).getTime();
      return timeStampa - timeStampb;
    });

    const activeTask = wfTasks?.find((t) => t.id === d.currentTaskId) ?? {};
    let task = activeTask;
    if (!hasData(task) && hasData(wfTasks)) task = wfTasks[wfTasks.length - 1];

    return task;
  } catch (err) {
    console.log(err);
    return {};
  }
};

const generateStateString = (array, key) =>
  array.map((state) => `${key}==${state}`).join(",");

export {
  getTaskstructInfo,
  getTaskAndWFFilter,
  getTaskUrl,
  getWorkFlowUrl,
  convertWorkFlow,
  convertWorkFlowForRequest,
  getRequestsUrl,
  getWorkFlowUrlWithRejected,
  generateStateString,
};

export { statusLabelIconMap };
