import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

const useFetchData = (fetchDataAction, userId, isLoaded, loading) => {
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(fetchDataAction(userId));
  }, [dispatch, fetchDataAction, userId]);

  useEffect(() => {
    if (!isLoaded && !loading) {
      fetchData();
    }
  }, [fetchData, isLoaded, loading]);

  return fetchData;
};

export default useFetchData;
