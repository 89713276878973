import React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton, SecondaryButton } from "@components/button";
import {
  ResultCardContainer,
  StyledResultSection,
} from "../../styledComponents";
import StatusHeader from "./StatusHeader";
import ResultCard from "./ResultCard";

const ResultSection = ({
  status = "success",
  responseArray = [],
  sendFailedRequests = () => {},
  cancelFailedRequests = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <StyledResultSection>
      <StatusHeader status={status} count={responseArray.length} />
      <ResultCardContainer>
        {responseArray?.map((item) => (
          <ResultCard
            key={item?.roleDetails?.id}
            status={status}
            roleDetails={item?.roleDetails}
            responseResult={item?.response}
          />
        ))}
      </ResultCardContainer>
      {status === "failed" && (
        <div className="action-buttons">
          <SecondaryButton
            label={t("cancel-discard-failed-button")}
            onButtonClick={cancelFailedRequests}
          />
          <PrimaryButton
            label={t("send-failed-requests")}
            onButtonClick={sendFailedRequests}
          />
        </div>
      )}
    </StyledResultSection>
  );
};

export default ResultSection;
