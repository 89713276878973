/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import "../../App.css";

import { Grid } from "@mui/material";
import UsersStep from "./users";
import RolesStep from "./roles";
import CostsStep from "./costs";
import Summary from "./summary";
import { useTranslation } from "react-i18next";
import { withRouter, useQuery } from "../../core/hooks";
import { Stepper, useUser } from "@alice/component_library";

import { storeCostData, clearStoredUserData } from "../../actions/userActions";
import {
  submitData,
  clearRolesData,
  clearsubmitFormData,
} from "../../actions/roleActions";
import { selectedtabIndex } from "../../actions/commonAction";

import moment from "moment";
import { PrimaryButton, SecondaryButton } from "../../sharedComponents/button";
import AccessRequest from "../access/accessRequest";
import { useLocation } from "react-router-dom";
import { Layout } from "@alice/component_library";
import { grey } from "@mercedes-benz/mui5-theme";
import { hasData, isValid } from "../../utils/Validator";
import { useNotification } from "@alice/component_library";

const steps = ["User", "Roles", "Costs", "Summary"];

const SubcontainerStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const SubcontainerStyleGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const ButtonWrapper = styled("div")({
  flexDirection: "row",
  padding: "12px 0px 12px 5px",
  marginTop: 20,
  backgroundColor: grey[95],
});

const HeaderFirstTab = styled(Grid)(({ theme }) => ({
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  display: "flex",
  height: "120px",
  [theme.breakpoints.down("xs")]: {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    height: "155px",
  },
}));

const SubcontainerStyles = styled(Grid)(({ theme }) => ({
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  flexDirection: "column",
  display: "flex",
  height: "120px",
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const StepperContainerStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
    marginBottom: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiStep-horizontal": {
      // to be removed when stepper from CL is fixed
      width: "70px",
      wordBreak: "break-all",
    },
    "& .MuiStepConnector-horizontal": {
      display: "none",
    },
  },
}));

const SubHeaderStyle = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
    marginBottom: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiStep-horizontal": {
      // to be removed when stepper from CL is fixed
      width: "70px",
      wordBreak: "break-all",
    },
    "& .MuiStepConnector-horizontal": {
      display: "none",
    },
  },
}));

const MainContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  marginTop: "15px",
  [theme.breakpoints.down("lg")]: {
    padding: "0 20px",
  },
  "& .new-request-container": {
    marginTop: "30px",
  },
}));

const MainContainerGrid = styled(Grid)({
  flexGrow: 1,
  marginTop: "15px",
  "& .new-request-container": {
    marginTop: "30px",
  },
});

function FirstTabContent(params) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataValue = queryParams.get("selfRequestRoles");
  const stepperRef = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const selectedRolesData = useSelector((state) => state.role.selectedRoles);
  const { user: userUtils } = useUser();
  const [costsField, setcostsField] = useState("");
  const [summaryType, setSummaryType] = useState(0);
  const [userType, setUserType] = useState("myself");

  const dispatch = useDispatch();

  const [submitFlag, setSubmit] = useState(false);

  const logginUser = userUtils;

  const mode = query.get("mode") ?? "";
  const user = query.get("user") ?? logginUser;
  const role = query.get("role") ?? "";
  const accessFlag =
    ["extend", "reapply"].includes(mode) || (isValid(user) && isValid(role));

  const selectedUserdata = useSelector((state) => state.user.selectedUserData);
  const { notify } = useNotification();

  const totalSteps = () => steps.length;

  const resetHandler = (urlUpdate = false) => {
    setSummaryType(0);
    setActiveStep(0);
    setSubmit(false);
    setcostsField("");
    stepperRef?.current?.reset();
    dispatch(clearStoredUserData());
    dispatch(clearRolesData());
    dispatch(clearsubmitFormData());
    if (urlUpdate) navigate("/access/accessRequest/myself");
  };

  useEffect(() => {
    if (mode === "") resetHandler();
  }, [mode]);

  useEffect(() => {
    if (dataValue) {
      setActiveStep(dataValue);
      stepperRef?.current?.setCompleted();
      navigate("/access/accessRequest/myself", { replace: true });
    }
  }, []);

  useEffect(() => {
    return function cleanup() {
      dispatch(clearStoredUserData());
      dispatch(clearRolesData());
      dispatch(clearsubmitFormData());
    };
  }, []);

  if (logginUser === undefined || logginUser === null) return null;

  const onClickselectedUser = (value) => {
    setUserType(value);
    if (userType !== value) resetHandler();
  };

  const newRequest = (e, params) => {
    if (e.target.offsetParent.id || e.target.id === "newRequest") {
      navigate("/access/accessRequest/myself");
    }
  };

  const requestRedirect = (e, params) => {
    dispatch(selectedtabIndex(4));

    navigate("/access/requests");
  };

  const profileRedirect = (e, params) => {
    dispatch(selectedtabIndex(5));
    navigate("/access/profile");
  };

  const updateStep = async (step) => {
    await stepperRef.current.back();
    if (step <= 2) await stepperRef.current.back();
    if (step === 1) await stepperRef.current.back();
    document.getElementById("scroller").scroll(0, 0);
  };

  const handleCostField = (e) => {
    setcostsField(
      e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim()
    );
  };
  const storeCostValue = () => {
    dispatch(storeCostData(costsField));
  };

  const handleNext = () => {
    stepperRef.current.setCompleted();
    document.getElementById("scroller").scroll(0, 0);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      stepperRef.current.back();
    }
    document.getElementById("scroller").scroll(0, 0);
  };

  const getFormatted = (d) => moment(new Date(d)).format("YYYY-MM-DD");

  const requsetStatusUpdater = () => {
    setSubmit(true);
    setSummaryType(1);
    document.getElementById("scroller").scroll(0, 0);
  };
  const handleComplete = () => {
    requsetStatusUpdater();
    stepperRef?.current?.setCompleted();
    const displayUser = !hasData(selectedUserdata)
      ? logginUser
      : selectedUserdata;
    const fullName = !hasData(selectedUserdata)
      ? logginUser.completeName
      : selectedUserdata.givenname + " " + selectedUserdata.surname;

    if (selectedRolesData.length > 0) {
      for (let i = 0; i < selectedRolesData.length; i++) {
        const fromDt = selectedRolesData[i].validFrom
          ? getFormatted(selectedRolesData[i].validFrom)
          : null;
        const toDt = selectedRolesData[i].validTo
          ? getFormatted(selectedRolesData[i].validTo)
          : null;
        if (
          selectedRolesData[i].selectedScopes &&
          selectedRolesData[i].selectedScopes.length > 0
        ) {
          let list = selectedRolesData[i];
          for (let j = 0; j < list.selectedScopes.length; j++) {
            dispatch(
              submitData(
                displayUser.id,
                list.id,
                list.needsOrgScopes,
                list.needsCustomScopes,
                list.selectedScopes[j].orgScope,
                list.selectedScopes[j].orgId,
                list.selectedScopes[j].custScope,
                fromDt,
                toDt,
                costsField,
                fullName,
                list.name,
                list.defaultValidityType
              )
            );
          }
        } else {
          let list = selectedRolesData[i];
          let orgscope = "";
          let custscope = "";
          let orgId = "";
          dispatch(
            submitData(
              displayUser.id,
              list.id,
              list.needsOrgScopes,
              list.needsCustomScopes,
              orgscope,
              orgId,
              custscope,
              fromDt,
              toDt,
              costsField,
              fullName,
              list.name,
              list.defaultValidityType
            )
          );
        }
      }
    }
  };

  const getActionButtons = () => {
    const buttonTemplate = [];
    if (activeStep !== 0)
      buttonTemplate.push(
        <SecondaryButton label={t("previous")} onButtonClick={handleBack} />
      );
    if (activeStep === 3)
      buttonTemplate.push(
        <PrimaryButton
          label={t("submit")}
          onButtonClick={() => handleComplete()}
          className="btn-ml-26"
          disabled={selectedRolesData.length === 0 || costsField.length === 0}
        />
      );
    else
      buttonTemplate.push(
        <PrimaryButton
          label={t("next")}
          onButtonClick={handleNext}
          className="btn-ml-26"
          disabled={
            (activeStep === 0 &&
              userType === "delegated" &&
              !hasData(selectedUserdata)) ||
            (activeStep === 1 && selectedRolesData.length === 0) ||
            (activeStep === 2 && costsField.trim().length < 20)
          }
        />
      );
    return (
      <Grid item className=" bg-clr-darkgrey sticky zI">
        <Grid className="request-responsive">
          {summaryType === 0 && (
            <ButtonWrapper>
              <Layout>
                <SubcontainerStyle className="fr">
                  {buttonTemplate}
                </SubcontainerStyle>
              </Layout>
            </ButtonWrapper>
          )}
        </Grid>
      </Grid>
    );
  };

  const headerLabel = ["user", "roles", "additional_Information", "summary"];

  const stepperConfig = () => {
    const userSl = !hasData(selectedUserdata) ? logginUser : selectedUserdata;
    const roles = selectedRolesData.length > 1 ? t("roles") : t("role");
    let roleSelected = t("user_pleaseSelect");
    if (selectedRolesData.length > 0)
      roleSelected = selectedRolesData.length + " " + roles;

    const steps = [
      {
        title: t("user"),
        content:
          userType === "delegated" && !hasData(selectedUserdata)
            ? t("user_pleaseSelect")
            : `${userSl.surname}, ${userSl.givenname}`,
        iconType: "USER_OUTLINE",
      },
      {
        title: t("roles"),
        content: `${roleSelected}`,
        iconType: "KEYS",
      },
      {
        title: t("additional_Information"),
        iconType: "INFO_BUBBLE",
      },
      {
        title: t("summary"),
        iconType: "UISummary",
      },
    ];

    return steps;
  };

  if (mode !== "" && !accessFlag)
    return navigate("/access/accessRequest/mySelf");

  if (submitFlag)
    return (
      <Layout>
        <HeaderFirstTab className="mln-10">
          <SubcontainerStyles className="mb-104">
            <p className="confirmation_header">
              {t("request_header_newRoleRequest")}
            </p>
            <p className="confirmation_Title">{t("confirmation")}</p>
          </SubcontainerStyles>
        </HeaderFirstTab>
        <Summary type={summaryType} resetHandler={resetHandler} />
      </Layout>
    );

  const getStepper = () => {
    if (summaryType) return null;
    return (
      <StepperContainerStyle style={{ height: "160px", marginTop: "20px" }}>
        <Stepper
          ref={stepperRef}
          onActiveStep={(step) => {
            setActiveStep(step);
          }}
          size="big"
          steps={stepperConfig()}
        />
      </StepperContainerStyle>
    );
  };

  return (
    <>
      {accessFlag ? (
        <AccessRequest saveHandler={requsetStatusUpdater} />
      ) : (
        <MainContainer>
          <Grid container>
            <SubHeaderStyle
              style={{ width: "100%" }}
              className="header-firstTab-1"
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Layout>
                  <HeaderFirstTab className="mln-10">
                    {userType === "import" && (
                      <SubcontainerStyle
                        className="header-text"
                        style={{ marginTop: "-10px" }}
                      >
                        <p className="bulk_header">
                          {t("request_header_newRoleRequest")}
                        </p>

                        <p className="header-text-align">{t("bulk-import")}</p>

                        <p className="font-16 mt-0 mb-0">
                          {t("please-select-file")}
                        </p>
                      </SubcontainerStyle>
                    )}
                    {summaryType ? (
                      <SubcontainerStyle className="mb-104">
                        <p className="confirmation_header">
                          {t("request_header_newRoleRequest")}
                        </p>
                        <p className="confirmation_Title">
                          {t("confirmation")}
                        </p>
                      </SubcontainerStyle>
                    ) : (
                      userType !== "import" && (
                        <SubcontainerStyleGrid item className="header-text">
                          <p className="font-16 mb-0 mt-12 boldText">
                            {t("request_header_newRoleRequest")}{" "}
                            {activeStep + 1}/{totalSteps()}
                          </p>
                          <h1 className="header-text-align">
                            {t(headerLabel[activeStep])}
                          </h1>
                          <p className="font-16 mt-0 mb-0">
                            {activeStep === 0
                              ? t("request_header_userStatement")
                              : activeStep === 1
                              ? t("request_header_roleStatement")
                              : activeStep === 2
                              ? t("request_header_additionalInfoStatement")
                              : t("request_header_summaryStatement")}
                          </p>
                        </SubcontainerStyleGrid>
                      )
                    )}
                  </HeaderFirstTab>
                </Layout>
              </Grid>
            </SubHeaderStyle>
            <Grid item xs={12}>
              <div
                style={{
                  flexGrow: 1,
                  marginTop: "15px",
                  "& .new-request-container": {
                    marginTop: "30px",
                  },
                }}
              >
                <Layout>
                  {getStepper()}
                  <div
                    className="new-request-container"
                    style={{ minHeight: "71vH" }}
                  >
                    {activeStep === 0 && (
                      <UsersStep
                        onClickselectedUser={onClickselectedUser}
                        t={t}
                        notify={notify}
                      />
                    )}
                    {activeStep === 1 && <RolesStep t={t} notify={notify} />}
                    {activeStep === 2 && (
                      <CostsStep
                        handleCostField={handleCostField}
                        costsField={costsField}
                        storeCostValue={storeCostValue}
                      />
                    )}
                    {activeStep === 3 && (
                      <Summary
                        updateStep={updateStep}
                        newRequest={newRequest}
                        profileRedirect={profileRedirect}
                        requestRedirect={requestRedirect}
                        type={summaryType}
                        resetHandler={resetHandler}
                      />
                    )}
                  </div>
                </Layout>
              </div>
            </Grid>
          </Grid>
        </MainContainer>
      )}
      {userType !== "import" && !accessFlag && getActionButtons()}
    </>
  );
}

export default withRouter(FirstTabContent);
