import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createRoles } from "@sliceactions/newRequestActions";
import { resetRequestId } from "@slice/newRequestSlice";
import { Layout, usePermission, useUser } from "@alice/component_library";
import { hasData } from "@utils/Validator";
import { isSuperAdmin } from "@utils";
import {
  DataContainer,
  RoleRequestMainConatiner,
} from "@pages/newRequest/styledComponents";
import NewRequestPage from "@pages/newRequest/components/MainPage";
import RoleSelection from "@pages/newRequest/components/RoleSelection";
import RoleRequestHeader from "@pages/newRequest/components/RoleRequestComponents/header";
import RoleRequestStepper from "@pages/newRequest/components/RoleRequestComponents/RoleRequestStepper";
import UserSelection from "@pages/newRequest/components/UserSelection";
import RoleConfigFooter from "@pages/newRequest/components/RoleRequestComponents/footer";
import RoleConfigComponent from "@pages/newRequest/components/RoleConfiguration";
import ConditionsAndReason from "@pages/newRequest/components/ConditionsAndReason";
import SummaryPage from "@pages/newRequest/components/Summary";
import { isValid } from "@utils/Validator";
import NewRequestDeeplink from "@pages/newRequest/components/Deeplink";
import {
  FIRST_STEP,
  SECOND_STEP,
  THIRD_STEP,
  FOURTH_STEP,
  FIFTH_STEP,
} from "@pages/newRequest/constants";

const updateArray1WithMatchingData = (arr1, arr2) => {
  const idMap = new Map(
    arr2.map((item) => [
      item.id,
      {
        configurationInformation: item?.configurationInformation,
        isConfigured: item?.isConfigured,
      },
    ])
  );

  return arr1.map((item1) => {
    const matchingItem = idMap.get(item1.id);
    // Update data and configurationInfo if matching item exists in arr2
    if (matchingItem) {
      return { ...item1, ...matchingItem }; // Merge item1 with matching data/configurationInfo
    }

    return item1; // Return original item1 if no match
  });
};

const NewRequestComponent = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const navigate = useNavigate();

  const { user } = useUser();
  const { adminRoles: adminRoleSet } = usePermission();

  const userData = user;
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const { requestDataId } = useSelector(
    (state) => state.newRequestSlice.requestId
  );
  const showRequestSomeoneElse =
    hasData(adminRoles) && isSuperAdmin(adminRoles, "request");

  const showRequestForEmployeeToEmployee =
    hasData(adminRoles) && adminRoleSet.has("EMPLOYEE_FOR_EMPLOYEE_MBAG_ADMIN");
  const stepperRef = useRef(null);
  const footerRef = useRef(null);

  const [selectedUser, setSelectedUser] = useState({});
  const [activeStep, setActiveStep] = useState(FIRST_STEP);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [configuredRoles, setConfiguredRoles] = useState({
    cofiguredData: [],
    activeStep: FIRST_STEP,
  });
  const [reason, setReason] = useState("");

  const [searchParams] = useSearchParams();
  const hasQuery = searchParams.get("role") || "";
  const accessFlag = hasQuery.length > FIRST_STEP && isValid(hasQuery);

  const handleReset = useCallback(() => {
    stepperRef?.current?.reset();
    setSelectedRoles([]);
    // setConfiguredRoles({}); //check and add
    setActiveStep(1);
    setReason("");
    setSelectedUser({});
  }, [stepperRef]);

  const getSelectedUser = (user) => {
    setSelectedUser(user);
    stepperRef?.current?.reset();
    setSelectedRoles([]);
  };

  const saveReason = (reasonFromProp) => {
    const currentActiveStep = stepperRef?.current?.activeStep;
    const completedSteps = stepperRef?.current?.completedSteps;
    if (type === "myself" && reasonFromProp !== reason) {
      if (completedSteps.includes(THIRD_STEP)) {
        stepperRef?.current?.reset();
      }
    } else if (type === "delegated" && reasonFromProp !== reason) {
      if (completedSteps.includes(FOURTH_STEP)) {
        stepperRef?.current?.reset();
      }
    }
    setConfiguredRoles((prevState) => ({
      ...prevState,
      activeStep: currentActiveStep,
    }));
    setReason(reasonFromProp);
  };

  useEffect(() => {
    setConfiguredRoles({
      cofiguredData: [],
      activeStep: FIRST_STEP,
    });
    setSelectedRoles([]);
  }, [type]);

  const handleActiveStep = (step) => setActiveStep(step);

  useEffect(() => {
    if (!accessFlag) {
      if (
        (type !== "myself" && type !== "delegated") ||
        (type === "delegated" &&
          !showRequestSomeoneElse &&
          !showRequestForEmployeeToEmployee)
      ) {
        handleReset();
        navigate("/access/accessRequest"); // change URL once redesign is released to /access/accessRequest
      }
    }
  }, [
    navigate,
    showRequestSomeoneElse,
    showRequestForEmployeeToEmployee,
    type,
    handleReset,
  ]);

  const setRoles = (data) => {
    const rolesInfo = updateArray1WithMatchingData(
      data,
      configuredRoles?.cofiguredData
    );
    setSelectedRoles(rolesInfo);
  };

  const isLastStep = () =>
    (type === "myself" && activeStep === FOURTH_STEP) ||
    (type === "delegated" && activeStep === FIFTH_STEP);

  const isRoleConfiguration =
    (type === "myself" && activeStep === SECOND_STEP) ||
    (type === "delegated" && activeStep === THIRD_STEP);

  const isRoleSelection = () =>
    (type === "myself" && activeStep === FIRST_STEP) ||
    (type === "delegated" && activeStep === SECOND_STEP);

  const isConditionAndReason = () =>
    (type === "myself" && activeStep === THIRD_STEP) ||
    (type === "delegated" && activeStep === FOURTH_STEP);

  const isShowSummary = () =>
    (type === "myself" && activeStep === FOURTH_STEP) ||
    (type === "delegated" && activeStep === FIFTH_STEP);

  const isUserSelection = () =>
    type === "delegated" && activeStep === FIRST_STEP;

  const isNextDisabled = () => {
    if (type === "myself") {
      if (activeStep === FIRST_STEP) return !selectedRoles.length;
      if (activeStep === SECOND_STEP) return false; //add proper condtion when role configurtion is completed
      if (activeStep === THIRD_STEP)
        return false; //add proper condtion when terms nd conditions is completed
      else return false;
    } else {
      if (activeStep === FIRST_STEP) return !hasData(selectedUser);
      if (activeStep === 1) return !selectedRoles.length;
      if (activeStep === THIRD_STEP) return false; //add proper condtion when role configurtion is completed
      if (activeStep === FOURTH_STEP)
        return false; //add proper condtion when terms nd conditions is completed
      else return false;
    }
  };

  const createroleDetails = useCallback(
    (rolesData, requestReason, userId) => {
      dispatch(
        createRoles({
          rolesData: rolesData,
          reason: requestReason,
          userId: userId,
        })
      );
    },

    [dispatch]
  );

  useEffect(() => {
    if (requestDataId && requestDataId.length > FIRST_STEP) {
      const dataId = requestDataId;
      dispatch(resetRequestId());
      navigate(`/access/accessRequest/result/${dataId}`);
    }
  }, [dispatch, navigate, requestDataId]);

  const nextAction = () => {
    if (isLastStep()) {
      //add submit logic
      const userId = type === "myself" ? userData?.id : selectedUser?.id;
      createroleDetails(configuredRoles?.cofiguredData, reason, userId);
    } else {
      setActiveStep(stepperRef?.current?.activeStep + 1);
      stepperRef?.current?.setCompleted();
    }
  };

  const previousAction = () => {
    if (activeStep > FIRST_STEP) {
      stepperRef.current.back();
      setActiveStep(stepperRef?.current?.activeStep - 1);
    }
  };

  useEffect(() => {
    if (selectedRoles.length < 1 && type === "myself") {
      stepperRef?.current?.reset();
    } else if (selectedRoles.length < 1 && type === "delegated") {
      stepperRef?.current?.reset();
    }
  }, [selectedRoles, stepperRef]);

  const saveRoleData = (roleData) => {
    const currentActiveStep = stepperRef?.current?.activeStep;
    setConfiguredRoles({
      cofiguredData: roleData,
      activeStep: currentActiveStep,
    });
    const allConfigured = roleData.every((item) => item.isConfigured);
    const completedSteps = stepperRef?.current?.completedSteps;
    if (type === "myself") {
      if (completedSteps.includes(1) && !allConfigured) {
        stepperRef?.current?.reset();
      }
    } else if (type === "delegated" && !allConfigured) {
      if (completedSteps.includes(THIRD_STEP)) {
        stepperRef?.current?.reset();
      }
    }
  };

  return (
    <>
      {accessFlag ? (
        <NewRequestDeeplink />
      ) : (
        <>
          <RoleRequestMainConatiner>
            <Layout>
              {type === "myself" || type === "delegated" ? (
                <>
                  <RoleRequestHeader
                    type={type}
                    userData={type === "myself" ? userData : selectedUser}
                  />
                  <RoleRequestStepper
                    type={type}
                    stepperRef={stepperRef}
                    handleActiveStep={handleActiveStep}
                    selectedRolesLength={selectedRoles?.length}
                    selectedUserName={
                      selectedUser?.completeName ?? selectedUser?.id
                    }
                    initialStep={configuredRoles?.activeStep}
                  />
                  <DataContainer>
                    {isUserSelection() && (
                      <UserSelection
                        getSelectedUser={getSelectedUser}
                        selectedUser={selectedUser}
                      />
                    )}

                    {isRoleSelection() && (
                      <RoleSelection
                        type={type}
                        userData={type === "myself" ? userData : selectedUser}
                        setSelectedRoles={(data) => setRoles(data)}
                        selectedRoles={selectedRoles}
                      />
                    )}
                    {selectedRoles?.length ? (
                      <RoleConfigComponent
                        isDisplay={isRoleConfiguration}
                        footerRef={footerRef}
                        rolesData={selectedRoles}
                        userData={type === "myself" ? userData : selectedUser}
                        // onSaveData={(roleData) => saveRoleData(roleData)}
                        pageIndex={activeStep}
                        saveData={(data) => {
                          saveRoleData(data);
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {isConditionAndReason() && (
                      <ConditionsAndReason
                        data={reason}
                        saveReasonHandler={(reason, reasonStatus) =>
                          saveReason(reason)
                        }
                        footerRef={footerRef}
                      />
                    )}
                    {isShowSummary() && (
                      <SummaryPage
                        requestReason={reason}
                        configuredRoles={configuredRoles?.cofiguredData}
                        selectedUser={type === "delegated" ? selectedUser : {}}
                      />
                    )}
                  </DataContainer>
                </>
              ) : (
                <NewRequestPage />
              )}
            </Layout>
          </RoleRequestMainConatiner>
          {type === "myself" || type === "delegated" ? (
            <RoleConfigFooter
              previousAction={() => previousAction()}
              nextAction={() => nextAction()}
              isPreviousButtonVisible={activeStep !== FIRST_STEP}
              activeStep={activeStep}
              isNextDisabled={isNextDisabled()}
              isLastStep={isLastStep()}
              isRoleConfiguration={isRoleConfiguration}
              isConditionAndReason={
                (type === "myself" && activeStep === THIRD_STEP) ||
                (type === "delegated" && activeStep === FOURTH_STEP)
              }
              data={{ total: selectedRoles?.length }}
              ref={footerRef}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default NewRequestComponent;
