import React from "react";
import { useTranslation } from "react-i18next";
import { LinkButton } from "@alice/component_library";
import { ChevronRight } from "@mui/icons-material";
import { HomeTaskSkeleton } from "@components/Skeleton/CommonSkeletons";
import RequestTaskTemplate from "./RequestTaskTemplate";
import {
  LatestTaskActivity,
  NoTaskContainer,
  TasksContainer,
  TasksHeading,
} from "../../../styledComponents";
import ReloadButton from "@components/reloadButton";

const TaskAndRequest = ({
  heading,
  subHeading,
  linkButtonText,
  noDataText,
  headerIcon,
  noDataIcon: NoDataIcon,
  activityData = [],
  dataFromApi = [],
  fetchData = () => {},
  loading = false,
  isRequest = true,
}) => {
  const { t } = useTranslation();

  const handleIconButtonClick = () => {
    fetchData();
  };

  return (
    <TasksContainer isRequest={isRequest}>
      <div className="header-container">
        {headerIcon}
        <TasksHeading>{t(heading)}</TasksHeading>
        <ReloadButton
          loading={loading}
          handleIconButtonClick={handleIconButtonClick}
        />
      </div>
      <LatestTaskActivity>{t(subHeading)}</LatestTaskActivity>
      {loading ? (
        <HomeTaskSkeleton />
      ) : (
        <>
          {dataFromApi?.length ? (
            <>
              <RequestTaskTemplate data={activityData} isRequest={isRequest} />
              <div className="icon-button">
                <LinkButton
                  isBlank={false}
                  href={`/access/${isRequest ? "requests" : "tasks"}`}
                  size="medium"
                  variant="primary"
                >
                  {t(linkButtonText)}
                  <ChevronRight />
                </LinkButton>
              </div>
            </>
          ) : (
            <NoTaskContainer>
              <NoDataIcon />
              {t(noDataText)}
            </NoTaskContainer>
          )}
        </>
      )}
    </TasksContainer>
  );
};

export default TaskAndRequest;
