import { CircularProgress, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'

export const LoadingPlaceholder = ({ sx = [] }) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Stack
      data-testid="LoadingPlaceholder"
      spacing={2}
      sx={mergeSxProps({ display: 'flex', alignItems: 'center' }, sx)}
    >
      <CircularProgress />
      <Typography fontWeight="bold">{t('general.loading')}</Typography>
    </Stack>
  )
}

LoadingPlaceholder.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
