import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { MarketSearch } from '../../../marketElements'
import { AssignmentDialog } from '../dialog/AssignmentDialog'

export const MarketHelpdeskAdministratorAssignmentDialog = ({
  userId,
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedMarkets, setSelectedMarkets ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.markets'),
      content: selectedMarkets.length ? `${selectedMarkets.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'markethelpdeskadmin',
        body: { marketHelpdeskAdminPrivileges: selectedMarkets.map((marketCountryCode) => ({ marketCountryCode })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.marketHelpdeskAdmin') }),
      onRequestSuccess: (response) => onAssigned({ marketHelpdeskAdmin: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsAssignDisabled = () => !(selectedMarkets && selectedMarkets.length > 0)

  const handleMarketCardClick = (country, selected) => {
    if (selected && !selectedMarkets.includes(country)) {
      setSelectedMarkets([ ...selectedMarkets, country ])
    } else {
      setSelectedMarkets(selectedMarkets.filter((market) => market !== country))
    }
  }

  const content = [
    {
      title: t('adminRoles.select.markets'),
      render: (
        <MarketSearch
          views={[ 'list' ]}
          interaction={Interaction.MULTI_SELECT}
          isCaching
          search="large"
          preselectedRowIds={selectedMarkets}
          preselectedView="list"
          onCardClick={handleMarketCardClick}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="MarketHelpdeskAdministratorAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.marketHelpdeskAdmin'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

MarketHelpdeskAdministratorAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
