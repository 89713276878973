import React from "react";
import { AppSearch, useUser } from "@alice/component_library";
import { ApplicationContainer } from "../../styledComponents";
import { CLService } from "@src/services/CLServices";

const MyApplications = () => {
  const { user = {} } = useUser();
  const logginUser = user?.id;

  const getApplicationData = (data) => {
    return data;
  };

  return (
    <ApplicationContainer>
      <AppSearch
        apiUrl={`/users/${logginUser}/applications`}
        isFilterHidden={true}
        isExpandable
        onCardClick={(data, id) => console.log(data, id, "Checking 67")}
        onGetResponseData={(data) => getApplicationData(data.applications)}
        views={["grid", "list"]}
        expandedTableProps={{
          apiFunction: (config, id) => {
            return CLService.getEntitlement({
              id: id.id,
              offset: config.page * config.pageSize,
              limit: config.pageSize,
              userId: logginUser,
            });
          },
          getResponseData: (data) => {
            return data.entitlements;
          },
          columns: ["entitlementId", "description"],
        }}
      />
    </ApplicationContainer>
  );
};

export default MyApplications;
