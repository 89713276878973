import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AliceIcon, AliceIconType, UserCard } from "@alice/component_library";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import WorkflowStepper from "../WorkflowStatusStepper/WorkflowStepper";
import { RoleCard } from "./RoleCardComponent";
import {
  SubTopicContainer,
  SubHeader,
  SubHeaderTypography,
  TaskRequestDetailsContainer,
  TaskRequestHeader,
} from "./StyledComponents";

const RoleRequest = ({ taskInfo, isDeeplink, windowSize }) => {
  const { t } = useTranslation();
  const {
    originator = {},
    state,
    isWorkFlow,
    affectedData = {},
    id,
  } = taskInfo;
  const { user = {} } = affectedData;
  const { isMobile } = windowSize;

  return (
    <TaskRequestDetailsContainer disabled={state !== "PENDING"}>
      <TaskRequestHeader>
        {t("task-type-details-header.role-request-details")}
      </TaskRequestHeader>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <RequesterIcon />
            <SubHeaderTypography>{t("requester")}</SubHeaderTypography>
          </SubHeader>
          <UserCard
            userData={originator}
            isContextShown={true}
            readonly
            size={isMobile ? "small" : "medium"}
            sx={{ flexWrap: "wrap" }}
          />
        </SubTopicContainer>
      </Grid>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("role")}</SubHeaderTypography>
          </SubHeader>
        </SubTopicContainer>
        <RoleCard taskInfo={taskInfo} isMobile={isMobile} />
      </Grid>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.USER_OUTLINE}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("beneficiary")}</SubHeaderTypography>
          </SubHeader>

          <UserCard
            key={id}
            userData={user}
            isContextShown={true}
            readonly
            size={isMobile ? "small" : "medium"}
            sx={{ flexWrap: "wrap" }}
          />
        </SubTopicContainer>
      </Grid>
      {isWorkFlow && (
        <WorkflowStepper
          workflowId={id}
          isWorkFlow={isWorkFlow}
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
        />
      )}
    </TaskRequestDetailsContainer>
  );
};

export default RoleRequest;
