import PropTypes from 'prop-types'

export const DataClassification = {
  INHERIT: 'INHERIT',
  INTERNAL: 'INTERNAL',
  CONFIDENTIAL: 'CONFIDENTIAL',
  SECRET: 'SECRET'
}

export const DataClassificationKeys = Object.keys(DataClassification)
export const DataClassifications = Object.values(DataClassification).slice(1)

export const DataClassificationPropType = PropTypes.oneOf(DataClassifications)

export const DataClassificationArgType = {
  options: DataClassifications,
  control: 'select',
  description: `
   Data Classification
   
   - **DataClassification.INTERNAL ('INTERNAL')**: Data Classification of type "INTERNAL"
   - **DataClassification.CONFIDENTIAL ('CONFIDENTIAL')**: Data Classification of type "CONFIDENTIAL"
   - **DataClassification.SECRET ('SECRET')**: Data Classification of type "SECRET"
  `
}
