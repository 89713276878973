import PropTypes from 'prop-types'

export const Interaction = {
  NONE: 'none',
  NAVIGATE: 'navigate',
  SINGLE_SELECT: 'singleSelect',
  MULTI_SELECT: 'multiSelect',
  SINGLE_SELECT_WITHOUT_RADIO: 'singleSelectWithoutRadio',
  MULTI_SELECT_WITHOUT_CHECKBOX: 'multiSelectWithoutCheckbox'
}

export const InteractionKeys = Object.keys(Interaction)
export const InteractionValues = Object.values(Interaction)
export const InteractionPropTypes = PropTypes.oneOf(InteractionValues)

export const InteractionArgTypes = {
  options: InteractionValues,
  control: 'select',
  description: `
   Interaction behavior
   
   - **Interaction.NONE ('none')**: The element is purely for display purposes without any interactive functionality.
   - **Interaction.NAVIGATE ('navigate')**: The element behaves as a hyperlink, allowing users to navigate to another page or resource upon interaction.
   - **Interaction.SINGLE_SELECT ('singleSelect')**: Users can select a single option from a list presented within the element using radio buttons.
   - **Interaction.MULTI_SELECT ('multiSelect')**: Users can select multiple options from a list presented within the element using checkboxes.
   - **Interaction.SINGLE_SELECT_WITHOUT_RADIO ('singleSelectWithoutRadio')**: Similar to singleSelect, users can choose a single option from a list, but without the visual representation of radio buttons.
   - **Interaction.MULTI_SELECT_WITHOUT_CHECKBOX ('multiSelectWithoutCheckbox')**: Similar to multiSelect, users can select multiple options from a list, but without the visual representation of checkboxes.
  `
}
