import { styled } from "@mui/system";

export const Header = styled("span")({
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "MB Corpo S Text",
  fontSize: "20px",
  fontWeight: "400",
  display: "flex",
  height: "100%",
  alignItems: "center",
});
