import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material";
import "@src/App.css";
import "./contactsupport.css";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "@actions";
import { Layout } from "@alice/component_library";
import { Card } from "@alice/component_library/";
import LanguageIcon from "@mui/icons-material/Language";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import InternalUserSupport from "../contactSupport/InternalUserSupport";
import { CountryFlag, useUser } from "@alice/component_library";
import ExternalUserSupport from "../contactSupport/ExternalUserSupport";
import DaimlerTruckUserSupport from "../contactSupport/DaimlerTruckUserSupport";
import { TextOnlyTooltip } from "@components/TextOnlyTooltip";
import { getUserCommunities } from "@alice/component_library";
import { useTranslation } from "react-i18next";
import { ContactSupportSkeleted } from "../Skeleton/CommonSkeletons";
import GuideDocumentation from "./guideDocumentation";

const RootContainer = styled("div")({
  flexGrow: 1,
});

const SubContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "0 20px",
  },
}));

const HeadingTitle = styled("span")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "0 20px",
  },
}));

const ToolTipContent = styled("span")(({ theme }) => ({
  width: "80%",
  marginLeft: "50px",
  boxSizing: "border-box",
  maxWidth: "450px",
  [theme.breakpoints.down("xs")]: {
    width: "300px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "300px",
  },
}));

const ContactSupport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.common.isLoading);
  const contactInfo = useSelector((state) => state.user.contactData);
  const { user: userUtils } = useUser();
  const userData = userUtils;
  const communities = getUserCommunities(userData?.communities);

  const sendMail = (mailId) => {
    window.location.assign(`mailto: ${mailId}`);
  };
  useEffect(() => {
    if (userData?.id) dispatch(Actions.getContactList(userData?.id));
  }, [dispatch, userData?.id]);

  return (
    <RootContainer className="mh-95vh">
      <Grid>
        {isLoading === false ? (
          <Layout>
            <SubContainer>
              {contactInfo?.globalSupportContacts?.length > 0 ||
              contactInfo?.marketSupportContacts?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="headerText"
                >
                  <div>
                    <p className="contacts-adjust">
                      {t("contact_page.Contacts")}
                    </p>
                    <p className="contact-Title">
                      {t("contact_page.contact-title")}
                    </p>
                  </div>
                </Grid>
              ) : null}
              {contactInfo?.globalSupportContacts?.length > 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="global-Title">
                      {t("contact_page.global-title")}
                    </p>
                  </Grid>
                  {contactInfo.globalSupportContacts.map((item) => (
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                      <Card
                        sx={{
                          height: "100%",
                          "& p": {
                            whiteSpace: "normal",
                            wordBreak: "breakWord",
                          },
                          "& >div": { height: "100%" },
                        }}
                      >
                        <p className="margbottom-20 contacts-org padding-20">
                          <span className="global-icon displayFlex">
                            <LanguageIcon fontSize="large" />
                            <TextOnlyTooltip title={item.name}>
                              <HeadingTitle className="global-text global-ellipsis">
                                {item.name}
                              </HeadingTitle>
                            </TextOnlyTooltip>
                          </span>
                          <TextOnlyTooltip
                            title={item.description}
                            placement="bottom-start"
                          >
                            <ToolTipContent className="displayFlex global-ellipsis">
                              <span className="global-subtext global-ellipsis">
                                {item.description}
                              </span>
                            </ToolTipContent>
                          </TextOnlyTooltip>
                          {item.mailAddress && item.mailAddress !== "" ? (
                            <span className="displayFlex ml-48 mt-8">
                              <EmailOutlinedIcon />
                              <span
                                className="contacts-mail displayFlex"
                                onClick={() => sendMail(item.mailAddress)}
                              >
                                {item.mailAddress}
                              </span>
                            </span>
                          ) : null}
                          {item.telephoneNumber &&
                          item.telephoneNumber !== "" ? (
                            <span className="displayFlex ml-48 mt-8">
                              <LocalPhoneOutlinedIcon />
                              <span className="contacts-text displayFlex">
                                {item.telephoneNumber}
                              </span>
                            </span>
                          ) : null}
                        </p>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : null}
              {contactInfo &&
              contactInfo.marketSupportContacts &&
              contactInfo.marketSupportContacts.length > 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={11} sm={11} md={12} lg={12}>
                    <p className="market-support">
                      {t("contact_page.market-title")}
                    </p>
                  </Grid>
                  {contactInfo.marketSupportContacts.map((item) => (
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                      <Card
                        sx={{
                          height: "100%",
                          "& p": {
                            whiteSpace: "normal",
                            wordBreak: "breakWord",
                          },
                          "& >div": { height: "100%" },
                        }}
                      >
                        <p className="margbottom-20 contacts-org padding-20">
                          <span className="global-icon displayFlex">
                            <span className="mt-5">
                              <CountryFlag
                                countryCode={item.market}
                                sx={{ width: 30 }}
                              />
                            </span>
                            <TextOnlyTooltip title={item.name}>
                              <HeadingTitle className="global-text global-ellipsis">
                                {item.name}
                              </HeadingTitle>
                            </TextOnlyTooltip>
                          </span>
                          <TextOnlyTooltip
                            title={item.description}
                            placement="bottom-start"
                          >
                            <ToolTipContent className="displayFlex global-ellipsis">
                              <span className="global-subtext global-ellipsis">
                                {item.description}
                              </span>
                            </ToolTipContent>
                          </TextOnlyTooltip>
                          {item.mailAddress && item.mailAddress !== "" ? (
                            <span className="displayFlex ml-48 mt-8">
                              <EmailOutlinedIcon />
                              <span
                                className="contacts-mail displayFlex"
                                onClick={() => this.sendMail(item.mailAddress)}
                              >
                                {item.mailAddress}
                              </span>
                            </span>
                          ) : null}
                          {item.telephoneNumber &&
                          item.telephoneNumber !== "" ? (
                            <span className="displayFlex ml-48 mt-8">
                              <LocalPhoneOutlinedIcon />
                              <span className="contacts-text displayFlex">
                                {item.telephoneNumber}
                              </span>
                            </span>
                          ) : null}
                        </p>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : null}

              {/* Guide & Document is visible only to Internals & externals as of now*/}
              {communities?.some(
                (r) =>
                  [
                    "DAIMLER_EMPLOYEE",
                    "DAIMLER_INTERNAL",
                    "DAIMLER_EXTERNAL",
                  ].indexOf(r) >= 0
              ) ? (
                <GuideDocumentation />
              ) : null}

              {communities?.some(
                (r) =>
                  [
                    "DAIMLER_INTERNAL",
                    "DAIMLER_POOLID",
                    "DAIMLER_JOINT_VENTURE_PERSON",
                    "DAIMLER_EXTERNAL",
                  ].indexOf(r) >= 0
              ) && userData.groupType === "0" ? (
                <InternalUserSupport
                  checking={
                    contactInfo?.marketSupportContacts?.length > 0 ||
                    contactInfo?.globalSupportContacts?.length > 0
                      ? true
                      : false
                  }
                />
              ) : null}
              {(communities?.some(
                (r) =>
                  [
                    "DEALER_EMPLOYEE",
                    "SUPPLIER_EMPLOYEE",
                    "CORPORATE_CUSTOMER_EMPLOYEE",
                    "CORPORATE_BUSINESS_PARTNER_PERSON",
                  ].indexOf(r) >= 0
              ) &&
                userData.groupType === "0") ||
              (communities?.some(
                (r) =>
                  [
                    "DAIMLER_EXTERNAL",
                    "DEALER_EMPLOYEE",
                    "SUPPLIER_EMPLOYEE",
                    "CORPORATE_CUSTOMER_EMPLOYEE",
                    "CORPORATE_BUSINESS_PARTNER_PERSON",
                  ].indexOf(r) >= 0
              ) &&
                userData.groupType === "1") ||
              (communities?.some(
                (r) =>
                  [
                    "DEALER_EMPLOYEE",
                    "SUPPLIER_EMPLOYEE",
                    "CORPORATE_CUSTOMER_EMPLOYEE",
                    "CORPORATE_BUSINESS_PARTNER_PERSON",
                  ].indexOf(r) >= 0
              ) &&
                userData.groupType === "S" &&
                userData.communities.length > 0) ? (
                <ExternalUserSupport
                  checking={
                    contactInfo?.marketSupportContacts?.length > 0 ||
                    contactInfo?.globalSupportContacts?.length > 0
                      ? true
                      : false
                  }
                />
              ) : null}
              {communities?.some(
                (r) =>
                  [
                    "DAIMLER_INTERNAL",
                    "DAIMLER_POOLID",
                    "DAIMLER_JOINT_VENTURE_PERSON",
                    "DAIMLER_EXTERNAL",
                  ].indexOf(r) >= 0
              ) && userData.groupType === "1" ? (
                <DaimlerTruckUserSupport
                  checking={
                    contactInfo?.marketSupportContacts?.length > 0 ||
                    contactInfo?.globalSupportContacts?.length > 0
                      ? true
                      : false
                  }
                />
              ) : null}
            </SubContainer>
          </Layout>
        ) : (
          <ContactSupportSkeleted />
        )}
      </Grid>
    </RootContainer>
  );
};

export default ContactSupport;
