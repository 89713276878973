import { Menu, MenuItem, styled } from "@mui/material";
import { Card } from "@alice/component_library";
const customScrollerStyles = {
  "&::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "16px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },
  "&::-webkit-scrollbar-track:hover": {
    backgroundColor: "#f4f4f4",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
    border: "5px solid #fff",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a0a0a5",
    border: "4px solid #f4f4f4",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};
export const SelectApplicationContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "16px 8px",
  gridTemplateColumns: "repeat(2, 1fr)",
  "& .select-application-chip": {
    "& .select-application-label": {
      color: "#00000099",
      paddingBottom: "8px",
    },
  },

  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

export const AppCardSelected = styled(Card)(() => ({
  padding: "8px",
  background: "#E6F5FF",
  borderRadius: "4px",
  display: "grid",
  gap: "16px",
  gridTemplateColumns: "1fr 60px",
  alignItems: "center",
  "& .remove-icon": {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    height: "100%",
    "& .app-card-header": {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& .id-holder": {
        background: "#00000014",
        padding: "5.5px 4px",
        borderRadius: "2px",
        lineHeight: "16px",
        height: "24px",
        width: "24px",
        minWidth: "24px",
        textAlign: "center",
      },
      "& .app-card-id": {
        color: "#0078D6",
      },
    },
    "& .app-card-name": {
      lineHeight: "32px",
      fontSize: "1.25rem",
    },
  },
  "& .app-details": {
    display: "flex",
    gap: "16px",
    overflow: "hidden",
    "& .header-name": { overflow: "hidden" },
    "& .app-card-header": {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& .id-holder": {
        background: "#00000014",
        padding: "5.5px 4px",
        borderRadius: "2px",
        lineHeight: "16px",
        height: "24px",
        width: "24px",
        minWidth: "24px",
        textAlign: "center",
      },
      "& .app-card-id": {
        color: "#0078D6",
      },
    },
    "& .app-card-name": {
      lineHeight: "32px",
      fontSize: "1.25rem",
    },
  },
}));

export const SelectApplicationPopover = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    padding: "16px 8px",
    [theme.breakpoints.up("sm")]: {
      width: "468px",
    },
  },
}));
export const AppList = styled("div")(({ ...props }) => ({
  ...customScrollerStyles,
  height: "400px",
  overflowY: "auto",
}));

export const StyledMenuItem = styled(MenuItem)(({ ...props }) => ({
  "& .select-application-list": {
    width: "100%",
    "& >.MuiPaper-root": {
      background:
        props?.selectedApplicationId === props?.id
          ? "rgba(0, 120, 214, 0.24)"
          : "transparent",
      padding: "12px 16px",
      borderRadius: 0,
      borderLeft: `4px solid ${
        props?.selectedApplicationId === props?.id ? "#0078D6" : "transparent"
      }`,
      "& >.MuiBox-root": {
        alignItems: "center",
        "& [data-testid=AppLogo]": {
          width: "70px",
          height: "44px",
        },
      },
    },
    marginTop: "8px",
  },
  "&:hover": {
    background: "transparent",
  },
  padding: 0,
}));

export const NoApplicationContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));
