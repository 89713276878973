import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  Dialog,
  Button,
  AliceIcon,
  AliceIconType,
} from "@alice/component_library";
import { Typography } from "@mui/material";
import { DeleteLayoutWrapper } from "@src/pages/newRequest/styledComponents";
import { OrgScopeCard } from "@src/sharedComponents/OrgScopeCard";

const OrgDeletePopup = ({
  data,
  isOpen = false,
  handleClose = () => {},
  handleDelete = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      data-testid="OrgDeleteDialog"
      open={isOpen}
      fullWidth
      maxWidth="md"
      headerSection={t("delete-org-header")}
      footerSection={
        <Stack direction="row" gap={2}>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button onClick={() => handleDelete(data?.id)}>
            {t("delete-org-custom-scope")}
          </Button>
        </Stack>
      }
      onClose={handleClose}
    >
      <DeleteLayoutWrapper>
        <Typography
          variant="body2"
          sx={{ marginTop: "16px", marginBottom: "8px" }}
        >
          {t("delte-confirm-org")}
        </Typography>
        <OrgScopeCard
          name={data?.name}
          icon={<AliceIcon iconType={AliceIconType.UIDate_From} size={16} />}
          isActionVisible={false}
          isWithCustomScope={false}
        />
        <Typography
          variant="body2"
          sx={{ marginTop: "24px", marginBottom: "8px" }}
        >
          {t("delte-confirm-cust-org")}
        </Typography>
        {data?.customScope?.map((scope) => (
          <OrgScopeCard
            name={scope.name}
            icon={<AliceIcon iconType={AliceIconType.CUSTOM_SCOPE} size={16} />}
            isActionVisible={false}
            isWithCustomScope={false}
          />
        ))}
      </DeleteLayoutWrapper>
    </Dialog>
  );
};

export default OrgDeletePopup;
