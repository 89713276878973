import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../../constants'
import { ApplicationsService } from '../../../../../data'
import { NewTable } from '../../../../table'

export const AppCardDetailsTable = forwardRef((
  {
    id,
    visibleColumns = [ 'displayName', 'description' ],
    interaction = Interaction.NONE,
    apiFunction = async ({ ...config }) => ApplicationsService.getAllEntitlementsById({ id, ...config }),
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const titleRenderer = (column) => t(`appCard.${column}`)

  return (
    <NewTable
      data-testid="AppCardDetailsTable"
      ref={ref}
      columns={visibleColumns}
      apiFunction={apiFunction}
      additionalApiFunctionInformation={{ id }}
      interaction={interaction}
      responseDataItemsKey="entitlements"
      rowIdKey="uuid"
      searchConfig={{
        apiFilters: [
          'id'
        ]
      }}
      titleRenderer={titleRenderer}
      {...otherProps}
    />
  )
})

AppCardDetailsTable.propTypes = {
  /** Unique ID */
  id: PropTypes.string.isRequired,
  /** Columns */
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  /** Interaction Props */
  interaction: PropTypes.oneOf([
    Interaction.NONE,
    Interaction.NAVIGATE,
    Interaction.SINGLE_SELECT,
    Interaction.SINGLE_SELECT_WITHOUT_RADIO,
    Interaction.MULTI_SELECT,
    Interaction.MULTI_SELECT_WITHOUT_CHECKBOX
  ]),
  /** Api call */
  apiFunction: PropTypes.func,
  /**
   * Props to be spread into [NewTable](./?path=/docs/alice-ui-table-newtable--docs) component.
   */
  otherProps: PropTypes.any
}
