import { Link } from "react-router-dom";
import { TaskStatusChip } from "@alice/component_library";
import { Tooltip } from "@mui/material";
import { formatDate } from "../../util";
import { dataSelection } from "./iconsAndArrow";
import Calender from "@Icons/taskpage/calender.svg";

export const TaskTableColumns = (t, optionType, filter, isAdmin) => {
  return [
    {
      field: "id",
      headerName: "id",
      flex: 0.3,
      renderCell: (params) => {
        const { roleIdSvg } = dataSelection(params);
        return (
          <div className="task-row-container svg-style">
            <span className="svg-container">{roleIdSvg}</span>
            <span className="task-id task-gneral-style">
              <Tooltip
                arrow
                placement="bottom-start"
                title={params.value}
                type="ellipsized"
              >
                <Link
                  to={`/access/tasks/details?taskId=${params.value}&tasktype=${optionType}&isadmin=${isAdmin}&filter=${filter}`}
                >
                  {params.value}
                </Link>
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      field: "assigningValue",
      headerName: "assigningValue",
      flex: 0.5,
      renderCell: (params) => {
        const {
          assignValueLeft,

          assignValuLeftSvg,
        } = dataSelection(params);
        return (
          <div className="task-row-container svg-style">
            {assignValuLeftSvg}
            <span className="task-requester task-gneral-style">
              <Tooltip
                arrow
                placement="bottom-start"
                title={assignValueLeft}
                type="ellipsized"
              >
                {assignValueLeft}
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      field: "affectedUser",
      headerName: "affectedUser",
      flex: 0.5,
      renderCell: (params) => {
        const { operationalIcon, assignValueRightSvg, assignValueRight } =
          dataSelection(params);
        return (
          <div className="task-row-container">
            <div className="svg-container">
              {operationalIcon}
              {assignValueRightSvg}
            </div>
            <div className="task-row-sub-container svg-style">
              <span className="task-benefficey task-gneral-style">
                <Tooltip
                  arrow
                  placement="bottom-start"
                  title={assignValueRight}
                  type="ellipsized"
                >
                  {assignValueRight}
                </Tooltip>
              </span>
            </div>
          </div>
        );
      },
    },

    {
      field: "dateCreated",
      headerName: "dateCreated",
      width: 150,
      renderCell: (params) => (
        <Tooltip
          arrow
          placement="bottom"
          title={`${t("request-date")} DD|MM|YYYY `}
          type="ellipsized"
        >
          <div className="task-row-container svg-style">
            <Calender />
            <span className="task-date task-gneral-style">
              {formatDate(new Date(params?.row?.creationDate))}
            </span>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "",
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      headerName: "icon",
      renderCell: (params) => {
        let icon = null;
        switch (params.row.state) {
          case "PENDING":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="pending" variant="icon" />
              </div>
            );
            break;
          case "EXPIRED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="expired" variant="icon" />
              </div>
            );
            break;
          case "FAILED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="failed" variant="icon" />
              </div>
            );
            break;
          case "COMPLETED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="approved" variant="icon" />
              </div>
            );
            break;
          case "REJECTED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="rejected" variant="icon" />
              </div>
            );
            break;
          default:
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="pending" variant="icon" />
              </div>
            );
        }
        return <>{icon}</>;
      },
    },
  ];
};
export const TaskMenuColumns = (optionType, filter, isAdmin) => {
  return [
    {
      field: "id",
      headerName: "id",
      flex: 0.3,
      renderCell: (params) => {
        const { roleIdSvg } = dataSelection(params);
        return (
          <div className={`task-row-container svg-style padding-7`}>
            {roleIdSvg}
            <span className="task-id task-gneral-style">
              <Tooltip
                arrow
                placement="bottom-start"
                title={params.value}
                type="ellipsized"
              >
                {params.value}
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];
};

export const TableColumnsMob = (cellInfo) => {
  return [
    {
      field: "id",
      headerName: "id",
      flex: 0.7,
      renderCell: (params) => {
        const {
          roleIdSvg,
          assignValuLeftSvg,
          assignValueLeft,
          operationalIcon,
          assignValueRight,
          assignValueRightSvg,
        } = dataSelection(params);
        const { row } = params;
        let icon = null;
        switch (row.state) {
          case "PENDING":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="pending" variant="icon" />
              </div>
            );
            break;
          case "EXPIRED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="expired" variant="icon" />
              </div>
            );
            break;
          case "FAILED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="failed" variant="icon" />
              </div>
            );
            break;
          case "COMPLETED":
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="approved" variant="icon" />
              </div>
            );
            break;
          case "REJECTED":
            icon = <TaskStatusChip type="rejected" variant="icon" />;
            break;
          default:
            icon = (
              <div className="aprooved-icon">
                <TaskStatusChip type="pending" variant="icon" />
              </div>
            );
        }
        return (
          <div className="column-mobile-container">
            <div className="task-id-mobile">
              <div className="task-row-container svg-style">
                <span className="svg-container">{roleIdSvg}</span>
                <span className="task-id task-gneral-style">
                  {params.value}
                </span>
              </div>
              <div style={{ marginRight: "15px" }}>{icon}</div>
            </div>
            <div className="task-detail-container">
              <div className="task-row-container svg-style">
                {assignValuLeftSvg}
                <span className="task-requester task-gneral-style">
                  {assignValueLeft}
                </span>
                {operationalIcon}
              </div>
              <div className="task-row-container">
                <div className="svg-container">{assignValueRightSvg}</div>
                <div className="task-row-sub-container svg-style">
                  <span className="task-benefficey task-gneral-style">
                    {assignValueRight}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];
};
