import React from "react";

const deActivatedIcon = () => {
  return (
    <span>
      <svg
        width="49px"
        height="48px"
        viewBox="0 0 49 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Deactivation Stopped</title>
        <g
          id="Enhancements"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="1440" transform="translate(-696.000000, -369.000000)">
            <g id="Group-2" transform="translate(501.000000, 293.000000)">
              <g id="Group" transform="translate(0.000000, 76.000000)">
                <g
                  id="DeactivationStopped"
                  transform="translate(195.500000, 0.000000)"
                >
                  <polygon id="Path" points="0 0 48 0 48 48 0 48"></polygon>
                  <g
                    id="password-desktop-approved"
                    transform="translate(4.000000, 6.000000)"
                  >
                    <path
                      d="M10,35.5555556 C9.38666667,35.5555556 8.88888889,35.0577778 8.88888889,34.4444444 C8.88888889,33.8311111 9.38666667,33.3333333 10,33.3333333 L12.7155556,33.3333333 C13.5333333,31.9748148 14.0814815,30.4548148 14.32,28.8888889 L3.33333333,28.8888889 C1.49481481,28.8888889 0,27.3940741 0,25.5555556 L0,3.33333333 C0,1.49481481 1.49481481,0 3.33333333,0 L32.2222222,0 C34.0607407,0 35.5555556,1.49481481 35.5555556,3.33333333 L35.5555556,25.5555556 C35.5555556,27.3940741 34.0607407,28.8888889 32.2222222,28.8888889 L21.2355556,28.8888889 C21.4740741,30.4562963 22.0222222,31.9762963 22.84,33.3333333 L25.5555556,33.3333333 C26.1688889,33.3333333 26.6666667,33.8311111 26.6666667,34.4444444 C26.6666667,35.0577778 26.1688889,35.5555556 25.5555556,35.5555556 L10,35.5555556 Z M20.3066667,33.3333333 C19.6281481,31.9348148 19.1866667,30.4459259 18.9896296,28.8888889 L16.5644444,28.8888889 C16.3674074,30.4459259 15.9259259,31.9348148 15.2474074,33.3333333 L20.3066667,33.3333333 Z M2.22222222,25.5555556 C2.22222222,26.1688889 2.72,26.6666667 3.33333333,26.6666667 L32.2222222,26.6666667 C32.8355556,26.6666667 33.3333333,26.1688889 33.3333333,25.5555556 L33.3333333,22.2222222 L2.22222222,22.2222222 L2.22222222,25.5555556 Z M33.3333333,20 L33.3333333,3.33333333 C33.3333333,2.72 32.8355556,2.22222222 32.2222222,2.22222222 L3.33333333,2.22222222 C2.72,2.22222222 2.22222222,2.72 2.22222222,3.33333333 L2.22222222,20 L33.3333333,20 Z"
                      id="Shape"
                      fill="#969696"
                      fillRule="nonzero"
                    ></path>
                    <circle
                      id="Oval"
                      stroke="#969696"
                      strokeWidth="2"
                      fill="#FFFFFF"
                      cx="29.3333333"
                      cy="25.7777778"
                      r="10.6666667"
                    ></circle>
                    <path
                      d="M27.9232008,30.2222222 C27.7072939,30.2222222 27.5043414,30.1380185 27.352127,29.9868837 L24.2380402,26.5129409 C23.9217365,26.1977168 23.920657,25.6838583 24.2348016,25.3675546 C24.3880955,25.2131811 24.5921276,25.1289774 24.809114,25.1289774 C25.025021,25.1289774 25.2279735,25.2131811 25.3801879,25.364316 L27.8854171,28.2326397 L33.7462111,21.6064556 C33.899505,21.4326505 34.1208097,21.3333333 34.3529096,21.3333333 C34.5504645,21.3333333 34.7404626,21.4056622 34.8894384,21.5362859 C35.2240941,21.8320784 35.2554006,22.3448574 34.9596081,22.6795131 L28.5298993,29.9490999 C28.3820031,30.1153483 28.1704143,30.2146655 27.9480301,30.2222222 C27.9447915,30.2222222 27.927519,30.2222222 27.9232008,30.2222222 Z"
                      id="Path"
                      fill="#969696"
                      fillRule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default deActivatedIcon;
