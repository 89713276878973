import { format } from "date-fns";

export const DATE_OPTIONS_LONG = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const dateMonthFullYear = (date) =>
  new Date(date).toString() !== "Invalid Date"
    ? `${format(date, "dd")} ${format(date, "LLL")} ${format(date, "yyyy")}`
    : "-";
export const dateFullMonthFullYear = (date) =>
  new Date(date).toString() !== "Invalid Date"
    ? `${format(date, "dd")} ${format(date, "LLLL")} ${format(date, "yyyy")}`
    : "-";

function loadServerRows(page, pageSize, data) {
  return new Promise((resolve) => {
    resolve(data.slice(page * pageSize, (page + 1) * pageSize));
  });
}

export const getPageData = (page, pageSize, data) => {
  return loadServerRows(page, pageSize, data);
};

export const DECISION_CONSTANT = {
  MODIFIED: "MODIFIED",
  DELETED: "DELETED",
  CONFIRMED: "CONFIRMED",
  DELETED_EXT: "DELETED_EXT",
  NONE: "NONE",
};

export const formatDate = (date = new Date()) => {
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", {
    month: "2-digit",
  });
  const day = date.toLocaleString("default", { day: "2-digit" });

  return [year, month, day].join("-");
};

export const getAccessReviewUrl = (
  searchKey,
  userId,
  filter,
  classification
) => {
  let roleReviews = "/reviews/access";
  const filterForReviews = [];

  const query = searchKey?.length ? `'*${searchKey.trim()}*'` : "";
  const urlSearchParamsForRoleReviewsURL = new URLSearchParams();
  urlSearchParamsForRoleReviewsURL.set("sort", "-startTime");

  if (query.length > 0) {
    filterForReviews.push(`(roleId==${query},roleName== ${query})`);
  }
  if (userId) {
    filterForReviews.push(`reviewers.id==${userId}`);
  }
  if (filter) {
    filterForReviews.push(`state==${filter}`);
  }
  if (classification) {
    filterForReviews.push(`dataClassification==${classification}`);
  }

  if (filterForReviews.length) {
    if (filterForReviews?.length > 1) {
      urlSearchParamsForRoleReviewsURL.set(
        "filter",
        filterForReviews.join(";")
      );
    } else {
      urlSearchParamsForRoleReviewsURL.set("filter", filterForReviews.join(""));
    }
    roleReviews = `${roleReviews}?${urlSearchParamsForRoleReviewsURL.toString()}`;

    return roleReviews;
  }
};
