export const makeRequests = async (requests) => {
  try {
    const results = await Promise.allSettled(requests);
    const responses = [];
    const errors = [];

    results.forEach((result, index) => {
      if (result.status === "fulfilled") {
        responses.push(result.value);
      } else {
        const errorData = {
          index,
          error: result.reason,
          status: result.reason?.response?.status || null,
          config: result.reason?.config || null,
        };
        errors.push(errorData);
      }
    });

    return { responses, errors };
  } catch (error) {
    // Handle unexpected errors
    console.error("Unexpected error:", error);
    throw error;
  }
};
