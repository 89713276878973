import React from "react";

const NoResultsIcon = () => {
  return (
    <span>
      <svg
        width="49px"
        height="48px"
        viewBox="0 0 49 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="noresults-icon"
      >
        <g
          id="Enhancements"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Artboard" transform="translate(-695.000000, -419.000000)">
            <g id="NoResults" transform="translate(695.500000, 419.000000)">
              <polygon id="Path" points="0 0 48 0 48 48 0 48"></polygon>
              <path
                d="M24,44 C12.954,44 4,35.046 4,24 C4,12.954 12.954,4 24,4 C35.046,4 44,12.954 44,24 C44,35.046 35.046,44 24,44 Z M24,40 C32.836556,40 40,32.836556 40,24 C40,15.163444 32.836556,8 24,8 C15.163444,8 8,15.163444 8,24 C8,32.836556 15.163444,40 24,40 Z M22,30 L26,30 L26,34 L22,34 L22,30 Z M22,14 L26,14 L26,26 L22,26 L22,14 Z"
                id="Shape"
                fill="#C8C8C8"
                fill-rule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default NoResultsIcon;
