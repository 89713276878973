import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../button'
import { Dialog } from '../dialog/Dialog'

/**
 * This is a unique implementation of a dialog designed specifically for requesting user interaction.
 *
 * Use this type when an interaction is needed from the user to continue.
 *
 * ## Note
 * For more complex cases please use [Dialog](/docs/alice-ui-dialog-dialog--docs) component
 */
export const InteractionDialog = forwardRef((
  {
    isOpen,
    isLoading,
    title,
    isFullscreen,
    primaryButtonText,
    secondaryButtonText,
    tertiaryButtonText,
    onPrimaryClick = () => {},
    onSecondaryClick = () => {},
    onTertiaryClick,
    onClose = () => {},
    children,
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const hasTertiary = typeof onTertiaryClick === 'function'

  return (
    <Dialog
      data-testid="InteractionDialog"
      ref={ref}
      open={isOpen}
      onClose={onClose}
      fullscreen={isFullscreen}
      headerSection={title || t('dialog.interaction.title')}
      footerSection={(
        <Stack direction="row" justifyContent={!hasTertiary ? 'end' : 'space-between'} gap={2} sx={{ width: '100%' }}>
          { hasTertiary && (
            <Button
              data-testid="InteractionDialog-tertiary"
              onClick={onTertiaryClick}
              variant="tertiary"
              sx={{ color: 'primary.main' }}
            >
              {tertiaryButtonText || t('dialog.interaction.button.tertiary')}
            </Button>
          )}

          <Stack direction="row" justifyContent="end" gap={1}>
            <Button
              data-testid="InteractionDialog-secondary"
              onClick={onSecondaryClick}
              variant="secondary"
            >
              {secondaryButtonText || t('dialog.interaction.button.secondary')}
            </Button>

            <Button
              data-testid="InteractionDialog-primary"
              isLoading={isLoading}
              onClick={onPrimaryClick}
              variant="primary"
            >
              {primaryButtonText || t('dialog.interaction.button.primary')}
            </Button>
          </Stack>
        </Stack>
      )}
      {...otherProps}
    >
      {children}
    </Dialog>
  )
})

InteractionDialog.propTypes = {
  /** Determines current state of dialog popup */
  isOpen: PropTypes.bool,
  /** Determines the loading state. If enabled primary button is loading. */
  isLoading: PropTypes.bool,
  /** Overrides the title of the dialog */
  title: PropTypes.string,
  /** Sets the dialog to fullscreen */
  isFullscreen: PropTypes.bool,
  /** Overrides the default text of the primary button on the right side */
  primaryButtonText: PropTypes.string,
  /** Overrides the default text of the secondary button on the right side */
  secondaryButtonText: PropTypes.string,
  /** Overrides the default text of the tertiary button on the lift side */
  tertiaryButtonText: PropTypes.string,
  /** Function that getting called when primary button was clicked */
  onPrimaryClick: PropTypes.func,
  /** Function that getting called when secondary button was clicked */
  onSecondaryClick: PropTypes.func,
  /** Function that getting called when tertiary button was clicked */
  onTertiaryClick: PropTypes.func,
  /** Function that getting called when close button was clicked */
  onClose: PropTypes.func
}
