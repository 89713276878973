import { ListItemButton, ListItemIcon, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { getClient } from '../../util'
import { Link } from '../link'

export const ListLinkSmall = ({
  path,
  name,
  icon: Icon,
  sx = []
}) => {
  const { t } = useTranslation()

  const matchPath = useMatch({
    path,
    end: path === ''
      || path === '/'
      || path === `/${getClient()}`
  })

  return (
    <Link
      data-testid={`ListLinkSmall-${name}`}
      key={name}
      href={path}
      sx={sx}
    >
      <ListItemButton
        selected={Boolean(matchPath)}
        sx={{
          justifyContent: 'center',
          flexGrow: 0,
          width: '100%',
          paddingBlock: 2,
          paddingInline: 1,
          '&.Mui-selected': {
            border: 'unset',
            paddingLeft: 1
          }
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {Icon && (
            <ListItemIcon
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'text.primary',
                minWidth: '25px'
              }}
            >
              <Icon />
            </ListItemIcon>
          )}

          <Typography
            sx={{
              fontSize: 13,
              lineHeight: '13px',
              textAlign: 'center',
              pt: 1,
              hyphens: 'auto',
              color: matchPath ? 'blue.45' : 'initial'
            }}
          >
            {t(`navTargets.${name}`)}
          </Typography>
        </Stack>
      </ListItemButton>
    </Link>
  )
}

ListLinkSmall.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
