import { StyledMenuCard, Skeleton } from "./CommonSkeletons";

const MenuCard = ({ rowsNum }) => {
  return (
    <StyledMenuCard>
      <div className="menu-header">
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="circular" width="18px" height="18px" />
      </div>

      {[...Array(rowsNum)].map((el) => (
        <div className="menu-item" key={el}>
          <Skeleton variant="circular" width="18px" height="18px" />
          <Skeleton variant="text" width="60%" />
        </div>
      ))}
    </StyledMenuCard>
  );
};

export default MenuCard;
