import React from "react";
import { SummaryPageContainer } from "../../styledComponents";

const SummaryErrorBoundary = () => {
  return (
    <SummaryPageContainer id="new-request-summary">
      Error while loading the summary page
    </SummaryPageContainer>
  );
};

export default SummaryErrorBoundary;
