import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    scroller.scrollTo("myScrollToElement", {
      duration: 200,
      delay: 40,
      smooth: true,
      containerId: "scroller",
      offset: 0,
    });
  }, [pathname]);
};

export default ScrollToTop;
