import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { ApplicationsService } from '../../../data'
import { useApi } from '../../../hooks'
import { isEmpty, timeout } from '../../../util'
import { ErrorStatusBoundary } from '../../boundary'
import { GenericContextPopover } from '../../genericContextPopover'
import { EntitlementContext } from './EntitlementContext'

// For dev: timeoutInMS = 3000 -> wait 3 sec before the initial and other api calls are triggered
// For live: Set timeoutInMS to 0 (zero)!
const timeoutInMS = process.env.NODE_ENV === 'development' ? 3000 : 0

export const EntitlementContextPopover = ({
  appId,
  entitlementId,
  placement,
  children
}) => {
  const [ isTimeoutDone, setIsTimeoutDone ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  const {
    status: statusEntitlement,
    isFailure: isFailureEntitlement,
    response: responseEntitlement
  } = useApi(
    ApplicationsService.getEntitlementById,
    {
      config: { appId, entitlementId },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone
    }
  )

  const {
    status: statusApp,
    isFailure: isFailureApp,
    response: responseApp
  } = useApi(
    ApplicationsService.getById,
    {
      config: { id: appId },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone
    }
  )

  useEffect(() => {
    const waitTimeout = async () => {
      if (timeoutInMS > 0) await timeout(timeoutInMS)
      setIsTimeoutDone(true)
    }

    if (appId && entitlementId && isOpen) waitTimeout()
  }, [ appId, entitlementId, isOpen ])

  return (
    <GenericContextPopover
      data-testid="EntitlementContextPopover"
      context={(
        <ErrorStatusBoundary
          isError={isFailureEntitlement || isFailureApp}
          status={isFailureEntitlement ? statusEntitlement : statusApp}
        >
          <EntitlementContext
            app={responseApp}
            entitlement={responseEntitlement}
            isLoading={isEmpty(responseApp) || isEmpty(responseEntitlement)}
          />
        </ErrorStatusBoundary>
      )}
      placement={placement}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      {children}
    </GenericContextPopover>
  )
}

EntitlementContextPopover.propTypes = {
  /** App ID */
  appId: PropTypes.string.isRequired,
  /** Entitlement ID */
  entitlementId: PropTypes.string.isRequired,
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ]),
  /** The component that should be hover-able */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
