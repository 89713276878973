import { userActionTypes, costActionTypes } from "../constants/actionTypes";
import { apiBaseUrl } from "../services/baseAPIService";
import { errorInfo, forceReload, LoadingData } from "./commonAction";
import axiosInstance from "@src/services/axiosInstance";
import Communication from "@src/services/Communication";
import { RemoveAssignableRolesData } from "./roleActions";

const actions = {
  storeSelectedUser: (payload) => ({
    type: userActionTypes.STORE_SELECTED_USER,
    data: payload,
  }),
  storeCost: (payload) => ({
    type: costActionTypes.STORE_COST_DATA,
    data: payload,
  }),
  fetchSpecificUser: (payload) => ({
    type: userActionTypes.FETCH_LOGGEDIN_USER,
    data: payload,
  }),
  saveUserProfile: (payload) => ({
    type: userActionTypes.USER_PROFILE_DATA,
    data: payload,
  }),
  storeLoggedIn: (payload) => ({
    type: userActionTypes.STORE_LOGGEDIN_USER,
    data: payload,
  }),
  removeStoredUserData: (payload) => ({
    type: userActionTypes.REMOVED_STORED_USERS_DATA,
    data: payload,
  }),
  clearuserData: () => ({
    type: userActionTypes.CLEAR_USER_INFO,
  }),
  getContactInfo: (payload) => ({
    type: userActionTypes.GET_CONTACT_DATA,
    data: payload,
  }),
  updateWorkingOrgInfoForLoggedInUser: (payload) => ({
    type: userActionTypes.UPDATE_WORKING_ORGINFO_OF_LOGGIN_USER,
    data: payload,
  }),
  fetchAllAggrements: (payload) => ({
    type: userActionTypes.FETCH_ALL_AGREEMENTS,
    data: payload,
  }),
  fetchUserById: (payload) => ({
    type: userActionTypes.FETCH_USER_BY_ID,
    data: payload,
  }),
  adminRoles: (payload) => ({
    type: userActionTypes.USER_ADMIN_ROLES,
    data: payload,
  }),
};

export function getContactList(id) {
  return function (dispatch) {
    dispatch(LoadingData(true));
    return axiosInstance
      .get(apiBaseUrl + "/gems/users/" + id + "/supportcontacts")
      .then((response) => {
        dispatch(LoadingData(false));
        if (response.status === 200 || response.status === 304) {
          dispatch(actions.getContactInfo(response.data));
          return response.data;
        } else {
          dispatch(actions.getContactInfo());
          return response.status;
        }
      })
      .catch((error) => {
        dispatch(LoadingData(false));
        return error && error.response && error.response.status;
      });
  };
}

export function FetchLoggedInUserData(id) {
  return function (dispatch) {
    dispatch(actions.saveUserProfile({ loading: true, data: {} }));
    return axiosInstance
      .get(apiBaseUrl + "/gems/users/" + id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actions.fetchSpecificUser(response.data));
          dispatch(
            actions.saveUserProfile({ loading: false, data: response?.data })
          );
        } else {
          dispatch(actions.fetchSpecificUser());
          return response.status;
        }
      })
      .catch((error) => {
        dispatch(
          actions.saveUserProfile({ loading: false, data: {}, error: true })
        );
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }

        if (
          (error?.response && error?.response?.status === 500) ||
          error?.response?.status === 503
        ) {
          dispatch(errorInfo(error?.response?.status));
        }
        return error && error?.response && error?.response?.status;
      });
  };
}

export const UpdateWorkingOrgInfo = (userId, data) => {
  let Url = apiBaseUrl + "/gems/users/" + userId;
  let postData = data;
  return function (dispatch) {
    return axiosInstance
      .put(Url, postData)
      .then((response) => {
        dispatch(actions.updateWorkingOrgInfoForLoggedInUser(response.data));
        return response.status;
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const FetchUserById = (userId) => {
  const url = `${apiBaseUrl}/gems/users/${userId}`;

  return function (dispatch) {
    dispatch(actions.fetchUserById({ data: {}, loading: true }));
    return axiosInstance
      .get(url)
      .then((response) => {
        dispatch(
          actions.fetchUserById({ data: response?.data, loading: false })
        );
      })
      .catch((error) => {
        dispatch(
          actions.fetchUserById({
            data: {},
            loading: false,
            error: true,
            err: error,
          })
        );
      });
  };
};

export const StoreSelectedUser = (data) => (dispatch) => {
  dispatch(RemoveAssignableRolesData());
  dispatch(storeCostData(""));
  dispatch(actions.storeSelectedUser(data));
};

export const storeCostData = (data) => (dispatch) => {
  dispatch(actions.storeCost(data));
};

export const StoreLoggedInUser = (data) => (dispatch) => {
  dispatch(actions.storeLoggedIn(data));
};
export const RemovedStoredUsersData = () => (dispatch) => {
  dispatch(actions.removeStoredUserData());
};

export const clearStoredUserData = () => (dispatch) => {
  dispatch(actions.clearuserData());
};

export const AdminRoles = (data) => (dispatch) => {
  dispatch(actions.adminRoles(data));
};

export const SaveUserData = (data) => (dispatch) => {
  dispatch(actions.saveUserProfile(data));
};

export default actions;
