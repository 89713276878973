// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding-right: 0 !important;
}

main {
  position: relative;
}

/* Fix Autocomplete styling */
.MuiAutocomplete-option.Mui-focused[aria-selected='true'] {
  background-color: #0078d6 !important;
  color: white;
}

.MuiAutocomplete-option[aria-selected='true'] {
  background-color: #0078d6 !important;
  color: white;
}

.MuiAutocomplete-option[aria-selected='true']:hover {
  background-color: #014880 !important;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: rgba(66, 66, 66, 0.1) !important;
}
/* Fix Autocomplete styling */
`, "",{"version":3,"sources":["webpack://./src/ComponentLibrary/src/theme/global.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA,6BAA6B;AAC7B;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kDAAkD;AACpD;AACA,6BAA6B","sourcesContent":["body {\n  padding-right: 0 !important;\n}\n\nmain {\n  position: relative;\n}\n\n/* Fix Autocomplete styling */\n.MuiAutocomplete-option.Mui-focused[aria-selected='true'] {\n  background-color: #0078d6 !important;\n  color: white;\n}\n\n.MuiAutocomplete-option[aria-selected='true'] {\n  background-color: #0078d6 !important;\n  color: white;\n}\n\n.MuiAutocomplete-option[aria-selected='true']:hover {\n  background-color: #014880 !important;\n}\n\n.MuiAutocomplete-option.Mui-focused {\n  background-color: rgba(66, 66, 66, 0.1) !important;\n}\n/* Fix Autocomplete styling */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
