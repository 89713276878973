import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Layout } from "@alice/component_library";
import TaskDeeplinkSkeleton from "@components/Skeleton/TaskDeeplink";
import CustomizedSnackbar from "@components/SnackBar";
import NoTasks from "@components/noTasks";
import { useWidth } from "@utils/hooks";
import Calender from "@Icons/taskpage/calender.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  BreadcrumbOptions,
  GoBackLink,
  ActionButton,
  TaskDeeplinkContainer,
  TaskDetailContainer,
  TaskIdHeader,
  TaskIdHeaderContainer,
  TaskSubHeader,
  ActionButtonContainer,
  TaskSubHeaderText,
  DeeplinkFooter,
  DeeplinkPaginationContainer,
  TasksByTotal,
} from "./StyledComponent";
import { formatDate } from "./util";
import useDeeplinkTask from "./useDeeplink";

const DeeplinkTasks = ({
  TaskHeaderIcon,
  TaskDetails,
  ApproveRejectBox = <></>,
  isFromTask = true,
}) => {
  const {
    id,
    taskInfo,
    handleApprove,
    selectedIndex,
    handleNextTask,
    handlePreviousTask,
    totalCount,
    storeBreadcrumbInfo,
    loading,
    handleReject,
    isLoaded,
    openDialog,
    closeDialog,
    openTasks,
    breadCrumbUrl,
    breadCrumbLabel,
    isAproove,
    userId,
  } = useDeeplinkTask(isFromTask);

  const { t } = useTranslation();

  const { creationDate, taskType, state, isWorkFlow, workflowId } =
    taskInfo || {};

  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: true,
    isDesktop: false,
  });

  const [taskInfoId, setTaskInfoId] = useState(id);
  const widthPanel = useWidth();

  useEffect(() => {
    setTaskInfoId(id);
  }, [id]);

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
      }
    }
  }, [widthPanel]);

  const breadcrumbs = [
    {
      label: `${t(isFromTask ? "task-overview" : "request-overview")}`,
      path: `/access/${isFromTask ? "tasks" : "requests"}`,
    },
    {
      label: breadCrumbLabel,
      path: `/access/${isFromTask ? "tasks" : "requests"}?${breadCrumbUrl}`,
      saveBreadcrumb: true,
    },
    {
      label: `${t(isFromTask ? "task-id" : "request-id")} ${taskInfoId}`,
      activate: true,
    },
  ];

  return (
    <>
      {taskInfo && (
        <>
          <TaskDeeplinkContainer>
            <Layout>
              {state && isLoaded ? (
                <>
                  <Breadcrumbs separator="/">
                    {breadcrumbs.map((options) => (
                      <BreadcrumbOptions
                        key={options.label}
                        activate={options.activate}
                      >
                        {!options.activate ? (
                          <Link
                            to={options.path}
                            onClick={() => {
                              if (options.saveBreadcrumb) {
                                storeBreadcrumbInfo(taskType, state);
                              }
                            }}
                          >
                            {options.label}
                          </Link>
                        ) : (
                          t(options.label)
                        )}
                      </BreadcrumbOptions>
                    ))}
                  </Breadcrumbs>
                  <GoBackLink
                    to={`/access/${isFromTask ? "tasks" : "requests"}`}
                  >
                    <ChevronLeftIcon color="#0078D6" />
                    {t(isFromTask ? "go-to-task-list" : "go-to-request-list")}
                  </GoBackLink>
                  <TaskDetailContainer>
                    <TaskIdHeaderContainer>
                      <div className="task-header-icon">
                        <TaskHeaderIcon taskType={taskType} size={48} />
                      </div>
                      <div className="task-header-id">
                        <TaskIdHeader>{`${t(
                          isFromTask ? "task-id" : "request-id"
                        )} ${taskInfoId}`}</TaskIdHeader>
                      </div>
                    </TaskIdHeaderContainer>
                    {isFromTask && state === "PENDING" && (
                      <ActionButtonContainer>
                        <ActionButton
                          backgroundcolor="#D92121"
                          hover="#971717"
                          onClick={() => openDialog(false)}
                        >
                          <CancelOutlinedIcon /> {t("reject")}
                        </ActionButton>
                        <ActionButton
                          backgroundcolor="#198025"
                          hover="#115919"
                          onClick={() => openDialog(true)}
                        >
                          <CheckCircleOutlineIcon /> {t("approve")}
                        </ActionButton>
                      </ActionButtonContainer>
                    )}
                  </TaskDetailContainer>
                  <TaskSubHeader>
                    <Calender />
                    <TaskSubHeaderText>
                      {t("created")} {formatDate(new Date(creationDate))}
                    </TaskSubHeaderText>
                  </TaskSubHeader>
                  <TaskDetails
                    taskInfo={taskInfo}
                    deeplink={true}
                    windowSize={windowSize}
                    userId={userId}
                  />
                  {isFromTask && taskInfo && (
                    <ApproveRejectBox
                      open={openTasks}
                      handleClose={closeDialog}
                      t={t}
                      SelectedTaskData={[taskInfo]}
                      approve={isAproove}
                      handleReject={handleReject}
                      handleApprove={handleApprove}
                    />
                  )}
                </>
              ) : (
                <>
                  {!state && isLoaded && !loading ? (
                    <>
                      <NoTasks />
                    </>
                  ) : (
                    <div className="mh-95vh">
                      <TaskDeeplinkSkeleton />
                    </div>
                  )}
                </>
              )}
              <CustomizedSnackbar />
            </Layout>
          </TaskDeeplinkContainer>
          {isFromTask && state === "PENDING" && (
            <DeeplinkFooter>
              <Layout>
                <DeeplinkPaginationContainer>
                  <div className="task-count">
                    <TasksByTotal>
                      {t("task")} {selectedIndex + 1}/
                      {totalCount === 0 ? 1 : totalCount}
                    </TasksByTotal>
                  </div>
                  <div className="task-btn">
                    <Button
                      disabled={selectedIndex < 1}
                      onClick={handlePreviousTask}
                      variant="secondary"
                    >
                      <ChevronLeftIcon
                        opacity={
                          selectedIndex < 1 || selectedIndex === 0
                            ? "0.41"
                            : "0.87"
                        }
                      />
                      {t("previous-task")}
                    </Button>
                    <Button
                      onClick={handleNextTask}
                      disabled={
                        typeof totalCount !== "undefined"
                          ? selectedIndex + 1 === totalCount || totalCount === 0
                          : false
                      }
                      type="primary"
                    >
                      {t("next-task")}
                      <ChevronRightIcon
                        color={
                          selectedIndex + 1 === totalCount || totalCount === 0
                            ? "black"
                            : "white"
                        }
                        opacity={
                          selectedIndex + 1 === totalCount || totalCount === 0
                            ? "0.41"
                            : ""
                        }
                      />
                    </Button>
                  </div>
                </DeeplinkPaginationContainer>
              </Layout>
            </DeeplinkFooter>
          )}
        </>
      )}
    </>
  );
};

export default DeeplinkTasks;
