import React from "react";
import { SideBarContainer } from "@pages/newRequest/styledComponents";
import ConfigurationCard from "@components/ConfigurationCard";

const SideBarNewRequest = ({
  rolesData,
  selectedId,
  configuredRoleId,
  onClickHandler,
  configuredList,
}) => {
  return (
    <SideBarContainer>
      {rolesData.map((data, i) => (
        <ConfigurationCard
          selectedId={selectedId}
          isConfigured={configuredRoleId.has(data?.id)}
          data={data}
          onClick={(id) => onClickHandler(id)}
          consfigureData={configuredList[i]}
        />
      ))}
    </SideBarContainer>
  );
};
export default SideBarNewRequest;
