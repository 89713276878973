import Communication from "@src/services/Communication";
import { concatUniqueByUUID } from "@src/utils/arrayUtils";
import { accessReviewActionTypes } from "../constants/actionTypes";
import { makeRequests } from "@src/services/SingleRequest";

const actions = {
  storeRoleReviewCount: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_COUNT,
    data: payload,
  }),
  storeRoleReviewDoneCount: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_DONE_COUNT,
    data: payload,
  }),
  storeRoleReviewList: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_LIST,
    data: payload,
  }),
  storeRoleReviewDetailData: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_DETAIL_DATA,
    data: payload,
  }),
  storeRoleReviewDetails: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_DETAILS,
    data: payload,
  }),
  storeRoleReviewRoleDetails: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_ROLES_DETAILS,
    data: payload,
  }),

  storeSingleRoleReviewRoleInfo: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_ROLE_DETAILS_SINGLE,
    data: payload,
  }),

  storeDetailsInfoWIthId: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_DETAILS,
    data: payload,
  }),

  handleReviewPop: (payload) => ({
    type: accessReviewActionTypes.ROLE_REVIEW_HANDLE_POPUP,
    data: payload,
  }),

  hasReviewAccess: (payload) => ({
    type: accessReviewActionTypes.HAS_REVIEW_ACCESS,
    data: payload,
  }),

  storeURLProgress: (units, progress) => ({
    type: accessReviewActionTypes.SET_JOB_PROGRESS,
    data: { units, progress },
  }),

  storeURLForData: (payload) => ({
    type: accessReviewActionTypes.SET_JOB_URL,
    data: payload,
  }),
  storeAccessReviewCounts: (payload) => ({
    type: accessReviewActionTypes.FETCH_OPEN_REVIEW,
    data: payload,
  }),

  storeAccessReviewTableData: (payload) => ({
    type: accessReviewActionTypes.SAVE_TABLE_DATA,
    data: payload,
  }),
};

export const getOverdueCount = async (id) => {
  const filterForReviews = [];
  const urlSearchParamsForRoleReviewsURL = new URLSearchParams();
  urlSearchParamsForRoleReviewsURL.set("offset", 0);
  urlSearchParamsForRoleReviewsURL.set("limit", 1);
  filterForReviews.push(`decision==DELETED;reviewer.search=="TE*"`);
  urlSearchParamsForRoleReviewsURL.set("filter", filterForReviews.join(""));
  const reviewApi = `/gems/reviews/access/${id}?${urlSearchParamsForRoleReviewsURL.toString()}`;
  let responseData = {};
  try {
    await Communication.get(reviewApi)
      .then((response) => (responseData = response?.data))
      .catch((err) => {
        throw new Error(err);
      });
  } catch (error) {
    throw new Error(error);
  }

  return responseData?.rels?.totalCount;
};

export const hasReviewAccess = (payload) => (dispatch) =>
  dispatch(actions.hasReviewAccess(payload));

export const getRoleReviewCount = (userId) => (dispatch) => {
  const workflowAPI = `/gems/reviews/access?filter=reviewers.id==${userId};state==OPEN&limit=1`;
  Communication.get(workflowAPI)
    .then(({ data }) =>
      dispatch(
        actions.storeRoleReviewCount({
          reviews: data?.rels?.totalCount,
        })
      )
    )
    .catch((err) => dispatch(actions.storeRoleReviewCount({ reviews: 0 })));
};

export const getRoleReviewDone = (wfId, filter, searchKey) => (dispatch) => {
  const sanitizedSearchKey = searchKey?.trim() || "";
  const query = sanitizedSearchKey ? `*${sanitizedSearchKey}*` : "";

  const decisionFilters =
    filter === "done"
      ? [
          "decision==CONFIRMED",
          "decision==MODIFIED",
          "decision==DELETED",
          "decision==DELETED_EXT",
        ].join(",")
      : "decision==NONE";

  const roleToUserFilters = [
    `roleToUser.user.search==${query}`,
    `roleToUser.orgScope.name==${query}`,
    `roleToUser.customScope.name==${query}`,
  ];

  let workflowAPI = `/gems/reviews/access/${wfId}?offset=0&limit=1&filter=(${decisionFilters})`;
  if (query) workflowAPI = `${workflowAPI};(${roleToUserFilters.join(",")})`;

  const key = filter === "done" ? "doneCount" : "openCount";
  Communication.get(workflowAPI)
    .then(({ data }) => {
      return dispatch(
        actions.storeRoleReviewDoneCount({
          [key]: data?.rels?.totalCount,
        })
      );
    })
    .catch((err) => dispatch(actions.storeRoleReviewDoneCount({ [key]: 0 })));
};

export const getRoleReviewList =
  (
    filter,
    userId,
    searchKey,
    classification,
    sortKey,
    offset = 0,
    limit = 12,
    page = 1,
    pageData,
    reviewData = [],
    rowsPerPage,
    isUserHasAdminAccess
  ) =>
  (dispatch) => {
    try {
      dispatch(
        actions.storeRoleReviewList({
          data: {
            reviewList: reviewData,
            loading: true,
            error: false,
            pageData: {
              data: pageData,
              paginationTottalCount: 0,
              offset: offset,
              limit: limit,
              filter: filter,
              search: searchKey,
              classification: classification,
              sort: sortKey,
              isAdminVisible: true,
              rowsPerPage: 12,
            },
          },
        })
      );
      let roleReviews = "/gems/reviews/access";
      const filterForReviews = [];

      const query = searchKey?.length ? `'*${searchKey.trim()}*'` : "";
      const urlSearchParamsForRoleReviewsURL = new URLSearchParams();
      urlSearchParamsForRoleReviewsURL.set("offset", offset);
      urlSearchParamsForRoleReviewsURL.set("limit", limit);
      urlSearchParamsForRoleReviewsURL.set("sort", "-startTime");
      if (sortKey) {
        urlSearchParamsForRoleReviewsURL.set("sort", sortKey);
      }
      if (query.length > 0) {
        filterForReviews.push(`(roleId==${query},roleName== ${query})`);
      }
      if (userId && isUserHasAdminAccess) {
        filterForReviews.push(`reviewers.id==${userId}`);
      }

      if (filter) {
        filterForReviews.push(`state==${filter}`);
      }

      if (classification) {
        filterForReviews.push(`dataClassification==${classification}`);
      }

      if (filterForReviews.length || sortKey) {
        if (filterForReviews?.length > 1) {
          urlSearchParamsForRoleReviewsURL.set(
            "filter",
            filterForReviews.join(";")
          );
        } else {
          urlSearchParamsForRoleReviewsURL.set(
            "filter",
            filterForReviews.join("")
          );
        }
        roleReviews = `${roleReviews}?${urlSearchParamsForRoleReviewsURL.toString()}`;
      }

      Communication.get(`${roleReviews}`).then((response) => {
        const { data } = response;
        return dispatch(
          actions.storeRoleReviewList({
            data: {
              reviewList: [...reviewData, ...data?.reviews],
              loading: false,
              error: false,

              pageData: {
                data: { ...pageData, [page]: data?.reviews },
                paginationTottalCount: data?.rels?.totalCount,
                offset: offset,
                limit: limit,
                filter: "",
                search: "",
                classification: "",
                sort: sortKey,
                isAdminVisible: userId.length ? false : true,
                rowsPerPage: rowsPerPage,
              },
            },
          })
        );
      });
    } catch (error) {
      return dispatch(
        actions.storeRoleReviewList({
          data: {
            loading: false,
            reviewList: [],
            error: error?.response?.message,
            pageData: {
              data: [],
              paginationTottalCount: 0,
              filter: "",
              search: "",
              classification: "",
              sort: sortKey,
              rowsPerPage: 12,
            },
          },
        })
      );
    }
  };

export const getRoleReviewDetail =
  (wfId, refreshData = false) =>
  (dispatch) => {
    try {
      dispatch(actions.storeRoleReviewDetailData({ loading: true }));
      let roleReviews = `/gems/reviews/access?filter=workflowId==${wfId}`;
      return Communication.get(roleReviews).then((response) => {
        const { data } = response;
        const detailData = data?.reviews?.length ? data?.reviews[0] : [];
        if (!refreshData) dispatch(getRolesDetail([detailData?.roleId]));
        return dispatch(
          actions.storeRoleReviewDetailData({
            loading: false,
            error: "",
            reviewDetail: detailData,
          })
        );
      });
    } catch (error) {
      dispatch(
        actions.storeRoleReviewDetailData({
          error: error?.response?.message,
          loading: false,
          reviewDetail: {},
        })
      );
    }
  };

const getRolesDetail = (data, single = true) => {
  const roleUrl = "/roles";
  return function (dispatch) {
    const urlSearchParamsForWorkFlowURL = new URLSearchParams();
    const formattedUserId = data.map((id) => `id==${id}`);
    urlSearchParamsForWorkFlowURL.set("filter", formattedUserId.join(","));
    if (formattedUserId.length) {
      const allRoles = `/gems${roleUrl}?${urlSearchParamsForWorkFlowURL.toString()}`;
      Communication.get(allRoles)
        .then((response) => {
          try {
            dispatch(
              single
                ? actions.storeSingleRoleReviewRoleInfo({
                    roles: response?.data.roles,
                    loading: false,
                  })
                : actions.storeRoleReviewRoleDetails({
                    roles: response?.data.roles,
                    loading: false,
                  })
            );
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) =>
          dispatch(
            actions.storeRoleReviewRoleDetails({
              roles: [],
              loading: false,
              error: error?.response?.message,
            })
          )
        );
    }
  };
};

export const getRoleDetailsWithId = (
  id,
  type,
  searchValue = "",
  offset = 0,
  limit = 10,
  sortingParams = {},
  rowsPerPage = 10,
  assignmentdata = [],
  pageData = {},
  page = 1
) => {
  return function (dispatch) {
    const query = searchValue?.length ? `'*${searchValue.trim()}*'` : "";
    const urlSearchParamsForReview = new URLSearchParams();
    urlSearchParamsForReview.set("sort", "roleToUser.user.id");
    urlSearchParamsForReview.set("offset", offset);
    urlSearchParamsForReview.set("limit", limit);
    const filtersForReview = [];
    if (type === "done") {
      filtersForReview.push(
        "(decision==CONFIRMED,decision==MODIFIED,decision==DELETED,decision==DELETED_EXT)"
      );
      urlSearchParamsForReview.set("sort", "-processingDate");
    } else {
      filtersForReview.push("decision==NONE");
    }
    if (query.length > 0) {
      filtersForReview.push(
        `(roleToUser.user.search==${query},roleToUser.orgScope.name== ${query},roleToUser.customScope.name==${query} )`
      );
    }
    if (sortingParams?.sortColumn) {
      urlSearchParamsForReview.set("sort", sortingParams?.sortColumn);
    }
    if (filtersForReview?.length) {
      if (filtersForReview?.length > 1) {
        urlSearchParamsForReview.set("filter", filtersForReview.join(";"));
      } else {
        urlSearchParamsForReview.set("filter", filtersForReview.join(""));
      }
    }

    const getRoleAssignmentInfo = `/gems/reviews/access/${id}?${urlSearchParamsForReview.toString()}`;

    dispatch(
      actions.storeDetailsInfoWIthId({ data: { data: [], loading: true } })
    );

    return Communication.get(getRoleAssignmentInfo)
      .then((r) => {
        return dispatch(
          actions.storeDetailsInfoWIthId({
            data: {
              data: concatUniqueByUUID(assignmentdata, r?.data?.assignments),
              loading: false,
              pagination: {
                offset: offset,
                limit: limit,
                totalCount: r?.data?.rels?.totalCount,
              },
              pageData: {
                data: { ...pageData, [page]: r?.data?.assignments },
                sortOrder: sortingParams?.sortDirection,
                sortColumn: sortingParams?.sortColumn,
                rowsPerPage: rowsPerPage,
                filter: type,
                searchValue: "",
              },
            },
          })
        );
      })
      .catch((err) => {
        dispatch(
          actions.storeDetailsInfoWIthId({
            data: [],
            loading: false,
            error: err?.response?.data,
          })
        );
      });
  };
};

export const addReviewDecision = (
  userId,
  wfId,
  uuidParam,
  decisionData,
  search,
  sortOrder,
  isBulk = false,
  selectedIds = []
) => {
  return function (dispatch) {
    if (!isBulk) {
      const apiURL = `/gems/reviews/access/${wfId}/${uuidParam}`;
      return Communication.put(apiURL, decisionData)
        .then((res) => {
          dispatch(getRoleReviewDetail(wfId, true));
          dispatch(
            getRoleDetailsWithId(wfId, "open", search, 0, 10, sortOrder)
          );
          return "success";
        })
        .catch((er) => er?.response?.data?.message ?? er?.message);
    } else {
      const promises = [];
      selectedIds.forEach(async ({ uuid }) => {
        let apiUrl = `/gems/reviews/access/${wfId}/${uuid}`;
        const response = Communication.put(apiUrl, decisionData);
        promises.push(response);
      });

      return makeRequests(promises).then(({ responses, errors }) => {
        dispatch(getRoleReviewDetail(wfId, true));
        return { responses, errors };
      });
    }
  };
};

export const bulkProcessReviews = (
  userId,
  wfId,
  decisionData,
  search,
  sortOrder
) => {
  return function (dispatch) {
    const apiURL = `/gems/reviews/access/${wfId}`;

    return Communication.put(apiURL, decisionData)
      .then((res) => {
        if (res.headers && res.headers.location) {
          localStorage.setItem(`review-${wfId}`, res.headers.location);
          dispatch(actions.storeURLForData(res.headers.location));
        } else {
          dispatch(getRoleReviewDetail(wfId, true));
          dispatch(
            getRoleDetailsWithId(wfId, "open", search, 0, 10, sortOrder)
          );
        }

        return "success";
      })
      .catch((er) => er?.response?.data?.message ?? er?.message);
  };
};

export const managePopup = (data) => {
  return function (dispatch) {
    dispatch(actions.handleReviewPop(data));
  };
};

export const getJobPercentage = (reviewId, url) => {
  return (dispatch) => {
    const jobId = url.split("/").pop();
    const apiURL = `/gems/jobs/${jobId}`;
    Communication.get(apiURL)
      .then((response) => {
        const { totalUnits, processedUnits, failedUnits, skippedUnits } =
          response.data;
        const progress =
          ((processedUnits + failedUnits + skippedUnits) / totalUnits) * 100;
        dispatch(
          actions.storeURLProgress(
            { processedUnits, failedUnits, skippedUnits, totalUnits },
            progress
          )
        );
      })
      .catch((error) => {
        console.error("Error sending url", error);
      });
  };
};

export const DashboardAccessReveiw = (id) => {
  return function (dispatch) {
    dispatch(
      actions.storeAccessReviewCounts({
        rels: { totalCount: 0 },
        reviews: [],
        loading: true,
        isLoaded: false,
      })
    );
    let roleReviews = "/gems/reviews/access";
    const filterForReviews = [`reviewers.id==${id};state==OPEN`];
    const urlSearchParamsForRoleReviewsURL = new URLSearchParams();
    urlSearchParamsForRoleReviewsURL.set("offset", 0);
    urlSearchParamsForRoleReviewsURL.set("limit", 5);
    urlSearchParamsForRoleReviewsURL.set("sort", "startTime");
    urlSearchParamsForRoleReviewsURL.set("filter", filterForReviews.join(";"));
    roleReviews = `${roleReviews}?${urlSearchParamsForRoleReviewsURL.toString()}`;

    return Communication.get(`${roleReviews}`)
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          dispatch(
            actions.storeAccessReviewCounts({
              ...response.data,
              loading: false,
              isLoaded: true,
            })
          );
          return response.data;
        } else {
          dispatch(
            actions.storeAccessReviewCounts({
              rels: { totalCount: 0 },
              reviews: [],
              loading: false,
              isLoaded: true,
            })
          );
          return response.status;
        }
      })
      .catch((error) => {
        dispatch(
          actions.storeAccessReviewCounts({
            rels: { totalCount: 0 },
            reviews: [],
            loading: false,
            isLoaded: true,
          })
        );
        return error && error.response && error.response.status;
      });
  };
};

export const saveTableData = (data) => {
  return function (dispatch) {
    dispatch(actions.storeAccessReviewTableData(data));
  };
};
