import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { FilterChip } from "@alice/component_library";
import SelectReferenceUserDialog from "./SelectReferenceUserDialog";
import SelectedReferenceUserCard from "./SelectedReferenceUserCard";
import { SelectReferenceUserContainer } from "../../styledComponents";
const SelectReferenceUser = ({
  handleSelectReferenceUser,
  selectedReferenceUser = {},
}) => {
  const [open, setOpen] = useState();
  const { t } = useTranslation();
  const chipRef = useRef(null);
  const handleClose = () => setOpen(false);
  return (
    <SelectReferenceUserContainer key={selectedReferenceUser?.id}>
      <div className="select-refernece-user-chip">
        <Typography variant="body2" className="select-reference-user-label">
          {t("filter-by-reference-user")}:
        </Typography>
        <FilterChip
          ref={chipRef}
          key={"filter"}
          label={t("select-reference-user")}
          isActive={!!selectedReferenceUser?.id}
          icon={<PersonOutlineIcon />}
          onClick={() => setOpen((prev) => !prev)}
        />
        {open && (
          <SelectReferenceUserDialog
            selectedReferenceUser={selectedReferenceUser}
            open={open}
            handleSelectReferenceUser={handleSelectReferenceUser}
            handleClose={handleClose}
          />
        )}
      </div>
      {!!selectedReferenceUser.id && (
        <SelectedReferenceUserCard
          selectedReferenceUser={selectedReferenceUser}
          handleSelectReferenceUser={handleSelectReferenceUser}
        />
      )}
    </SelectReferenceUserContainer>
  );
};

export default SelectReferenceUser;
