import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, ListItemButton, Popover, Stack, Typography } from '@mui/material'
import { KeyboardDoubleArrowDown, Menu } from '@mui/icons-material'
import { mergeSxProps } from '../../../../util'
import { NavigationMenuList } from './NavigationMenuList'
import { IconWrapper } from '../../../icon'

export const NavigationMenuPopover = ({
  title,
  items,
  selectedItem,
  onItemClick: _onItemClick = ({ item, itemId }) => {},
  sx
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const cardRef = useRef(null)

  const onItemClick = ({ item, itemId }) => {
    _onItemClick({ item, itemId })
    setIsOpen(false)
  }

  return (
    <>
      <ListItemButton
        ref={cardRef}
        sx={mergeSxProps([
          {
            display: 'flex',
            justifyContent: 'space-between',
            height: 56,
            padding: 2,
            borderRadius: '4px',
            color: isOpen ? 'primary.main' : 'text.primary',
            border: '1px solid',
            borderColor: 'transparent',
            backgroundColor: 'grey.90',
            transition: 'background-color 150ms, color 150ms, border-color 150ms'
          },
          isOpen && { '&, &:hover': { backgroundColor: 'blue.95', borderColor: '#0078D629' } }
        ], sx)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Stack direction="row" gap={1}>
          <IconWrapper size={24}><Menu /></IconWrapper>

          <Typography variant="h6" sx={{ transition: 'color 150ms' }}>
            {selectedItem ? items.find((item) => selectedItem === item.id).label : title}
          </Typography>
        </Stack>

        <IconWrapper
          size={24}
          sx={[
            { transition: 'transform 250ms, color 150ms' },
            !isOpen && { transform: 'rotate(180deg)' }
          ]}
        >
          <KeyboardDoubleArrowDown />
        </IconWrapper>
      </ListItemButton>

      <Popover
        open={isOpen}
        anchorEl={cardRef.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        slotProps={{ paper: { style: { width: '100%', borderRadius: '4px' } } }}
      >
        <Box sx={{ width: '100%', p: 2 }}>
          <NavigationMenuList
            isMobile
            items={items}
            selectedItem={selectedItem}
            onItemClick={onItemClick}
          />
        </Box>
      </Popover>
    </>
  )
}

NavigationMenuPopover.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
  })).isRequired,
  selectedItem: PropTypes.string,
  onItemClick: PropTypes.func
}
