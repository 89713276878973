import React, { useState } from "react";
import {
  AccessReviewHeadTypograghy,
  AccessReviewHeaderContainer,
  AccessReviewSubHeadTypograghy,
  AccesReviewHeaderWrapper,
} from "../styledComponents";
import AdminVisiblitySwicth from "./adminVisibilitySwitch";

const AccessReviewHeader = ({
  isReviewTitle = true,
  adminRoles,
  actionHandler,
  isAdminVisible,
  translate,
}) => {
  return (
    <AccessReviewHeaderContainer>
      {isReviewTitle ? (
        <>
          <AccesReviewHeaderWrapper>
            <div>
              <AccessReviewHeadTypograghy>
                {translate("reviews")}
              </AccessReviewHeadTypograghy>
              <AccessReviewSubHeadTypograghy>
                {translate("reviews-subheader")}
              </AccessReviewSubHeadTypograghy>
            </div>
            <AdminVisiblitySwicth
              actionHandler={actionHandler}
              adminRoles={adminRoles}
              adminMode={isAdminVisible}
              translate={translate}
            />
          </AccesReviewHeaderWrapper>
        </>
      ) : (
        <></>
      )}
    </AccessReviewHeaderContainer>
  );
};

export default AccessReviewHeader;
