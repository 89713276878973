import { accessReviewActionTypes } from "@constants/actionTypes";

const initialState = {
  roleReviewCount: { reviews: 0 },
  hasReviewAccess: false,
  roleReviewDetailsInfo: {
    detailsList: [],
    loading: false,
    error: "",
    pagination: { offset: 0, limit: 10 },
  },
  roleReviewDetailCount: { doneCount: 0, openCount: 0 },
  hasReviewAcces: false,
  roleReviewDetailsInf: {
    detailsList: [],
    loading: false,
    error: "",
    pagination: { offset: 0, limit: 1 },
  },
  roleReviewList: {
    reviewList: [],
    error: "",
    loading: true,
    pageData: {
      data: [],
      paginationTottalCount: 0,
      filter: "",
      search: "",
      classification: "",
      sort: "",
      isAdminVisible: false,
    },
  },
  roleReviewDetailData: {
    loading: false,
    error: "",
    reviewDetail: [],
  },
  roleReviewRolesDetailsInfo: {
    roles: [],
    loading: false,
    error: "",
  },
  roleReviewRolesDetailsInfoWithWf: {
    roles: [],
    loading: false,
    error: "",
  },
  roleReviewRolesMoreDetail: {
    assignmentInfo: {
      data: [],
      loading: false,
      error: "",
      pagination: { offset: 0, limit: 10 },
      pageData: {
        data: [],
        sortOrder: "",
        sortColumn: "",
        rowsPerPage: "",
        filter: "open",
        searchValue: "",
      },
    },
  },
  roleReviewHandlePop: {
    open: false,
    type: "",
    bulkProcessing: false,
    defaultValidityDays: 180,
    defaultValidityType: "",
    selectedId: [],
  },
  jobUrl: "",
  jobStatus: {
    urlProgress: 0,
    units: {
      totalUnits: 0,
      processedUnits: 0,
      failedUnits: 0,
      skippedUnits: 0,
    },
  },
  reviewAssignment: { rels: { totalCount: 0 }, reviews: [], loading: false },
  reviewData: [],
};

const AccessReviewReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case accessReviewActionTypes.SET_JOB_PROGRESS:
      return {
        ...state,
        jobStatus: {
          ...state.jobStatus,
          units: data.units,
          urlProgress: data.progress,
        },
      };
    case accessReviewActionTypes.SET_JOB_URL:
      return {
        ...state,
        jobUrl: data,
      };
    case accessReviewActionTypes.ROLE_REVIEW_DETAILS_LIST:
      return (state = {
        ...state,
        roleReviewDetailsInfo: Object.assign({}, data),
      });

    case accessReviewActionTypes.ROLE_REVIEW_ROLES_DETAILS:
      return (state = {
        ...state,
        roles: Object.assign({}, data),
      });

    case accessReviewActionTypes.ROLE_REVIEW_ROLE_DETAILS_SINGLE:
      return (state = {
        ...state,
        roleReviewRolesDetailsInfoWithWf: Object.assign({}, data),
      });

    case accessReviewActionTypes.ROLE_REVIEW_DETAILS:
      return (state = {
        ...state,
        roleReviewRolesMoreDetail: data?.data,
      });

    case accessReviewActionTypes.ROLE_REVIEW_HANDLE_POPUP:
      return (state = {
        ...state,
        roleReviewHandlePop: Object.assign({}, data),
      });

    case accessReviewActionTypes.ROLE_REVIEW_COUNT:
      return (state = {
        ...state,
        roleReviewCount: Object.assign({}, data),
      });
    case accessReviewActionTypes.ROLE_REVIEW_DONE_COUNT:
      return (state = {
        ...state,
        roleReviewDetailCount: { ...state.roleReviewDetailCount, ...data },
      });
    case accessReviewActionTypes.ROLE_REVIEW_LIST:
      return (state = {
        ...state,
        roleReviewList: data?.data,
      });
    case accessReviewActionTypes.ROLE_REVIEW_DETAIL_DATA:
      return (state = {
        ...state,
        roleReviewDetailData: data,
      });

    case accessReviewActionTypes.HAS_REVIEW_ACCESS:
      return (state = {
        ...state,
        hasReviewAccess: data,
      });

    case accessReviewActionTypes.FETCH_OPEN_REVIEW:
      return (state = {
        ...state,
        reviewAssignment: data,
      });

    case accessReviewActionTypes.SAVE_TABLE_DATA:
      return (state = {
        ...state,
        reviewData: data,
      });

    default:
      return state;
  }
};

export default AccessReviewReducer;
