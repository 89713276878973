import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AliceIcon,
  Button,
  FocusChip,
  LinkButton,
  useUser,
  UserContextPopover,
  UserAvatar,
} from "@alice/component_library";
import { user } from "@alice/component_library";

import { managementValue } from "@utils/managementValue";
import { Box, Divider } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  ProfileDetailsContainer,
  DetailsContainer,
  ProfileDetailsSpan,
  ProfileDetailsSubHeader,
  FocusDetailsContainer,
  SpanMailConatiner,
  ProfileActions,
  ProfileMainContainer,
  ProfileHeaderdetails,
  HeaderTextStyle,
} from "../../styledComponents";

const ProfilePageHeader = (props) => {
  const { t } = useTranslation();

  const profileImageStyle = {
    height: 115,
    width: 115,
    fontSize: "3rem",
    dotSize: "20px",
  };
  const { user: userUtils } = useUser();

  const { id: userId } = userUtils || {};
  const userData = userUtils;

  if (userData === null) return null;
  const { givenname, surname, groupType, departmentNumber } = userData;
  const { managementLevel, mailAddress, plant2 } = userData;

  const SeparatorBar = () => (
    <span style={{ padding: "0 6px", display: "flex", alignItems: "center" }}>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ margin: "4px", height: "16px" }}
      />
    </span>
  );

  const getUserDetails = () => {
    const level = managementLevel ? managementValue(managementLevel) : "";
    return (
      <ProfileDetailsContainer>
        <DetailsContainer>
          <div>
            <ProfileDetailsSpan>
              {`${surname}, ${givenname}`}
              {level !== "" ? `(${level})` : ""}
            </ProfileDetailsSpan>
            <FocusDetailsContainer>
              <FocusChip groupType={groupType} showTooltip />
            </FocusDetailsContainer>
          </div>
          <ProfileDetailsSubHeader>
            <span style={{ display: "flex", alignItems: "center" }}>
              {userId}
              {departmentNumber && <SeparatorBar />}
              <Box sx={{ fontWeight: "700" }}>{departmentNumber}</Box>
              {plant2 && (
                <>
                  <SeparatorBar />
                  {`${t("plant")} ${plant2}`}
                </>
              )}
            </span>
          </ProfileDetailsSubHeader>

          <SpanMailConatiner>{mailAddress}</SpanMailConatiner>
        </DetailsContainer>

        {getProfileActions()}
      </ProfileDetailsContainer>
    );
  };

  const getProfileActions = () => (
    <ProfileActions>
      <LinkButton
        children={
          <>
            <KeyIcon />
            {t("change-password")}
            <ChevronRightIcon />
          </>
        }
        href="https://login.mercedes-benz.com/password/"
        isBlank={true}
        variant="tertiary"
        sx={{ justifyContent: "space-between" }}
      />
      <LinkButton
        children={
          <>
            <AliceIcon
              color="#0078D6"
              iconType="SHARE_EXTERNAL_LINK"
              size={20}
            />
            {t("open-in-mb-inside")}
            <ChevronRightIcon />
          </>
        }
        href="https://mbinside.app.corpintra.net/teams/person/"
        isBlank={true}
        variant="tertiary"
        sx={{ justifyContent: "space-between" }}
      />
    </ProfileActions>
  );

  const getUserImage = () => (
    <div>
      <UserContextPopover id={userId}>
        <UserAvatar
          userId={userId}
          userName={`${givenname} ${surname}`}
          groupType={groupType}
          showGroupType
          sizeStyle={profileImageStyle}
          showImage={user.is.internalEmployee(userData?.communities)}
        />
      </UserContextPopover>
    </div>
  );

  return (
    <ProfileMainContainer>
      <HeaderTextStyle>{t("profile")}</HeaderTextStyle>
      <ProfileHeaderdetails>
        {getUserImage()}
        {getUserDetails()}
      </ProfileHeaderdetails>
    </ProfileMainContainer>
  );
};

export default ProfilePageHeader;
