import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import {
  HeaderAvatar,
  HeaderContainer,
  HeaderTypography,
} from "@pages/newRequest/styledComponents";
import { UserContextPopover } from "@alice/component_library";
import { PersonSearchOutlined } from "@mui/icons-material";
import { useWidth } from "@utils/hooks";

const RoleRequestHeader = ({
  userData = {},
  type = "myself",
  isFromDeeplink = false,
  mode = "extend",
}) => {
  const { id, groupType, completeName } = userData || {};
  const { t } = useTranslation();
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm"].includes(widthPanel);
  const getTranslationKey = (isFromDeeplink, mode, type, id) => {
    if (isFromDeeplink) {
      if (mode === "reapply") {
        return type === "myself"
          ? "reapply-role-for-myself"
          : "reapply-role-for-someone-else";
      } else if (mode === "extend") {
        return type === "myself"
          ? "extend-role-for-myself"
          : "extend-role-for-someone-else";
      } else {
        return type === "myself"
          ? "request-role-for-myself"
          : "request-role-for-selcted-user";
      }
    } else {
      if (type === "myself") {
        return "request-role-for-myself";
      } else {
        return id
          ? "request-role-for-selcted-user"
          : "request-role-for-someone-else";
      }
    }
  };

  const translationKey = getTranslationKey(isFromDeeplink, mode, type, id);

  return (
    <HeaderContainer>
      {id ? (
        <UserContextPopover id={id}>
          <HeaderAvatar
            userId={id}
            userName={completeName}
            size="big"
            showGroupType
            groupType={groupType}
            showImage={true}
            isMobile={isMobile}
          />
        </UserContextPopover>
      ) : (
        <div className="user-search-icon">
          <PersonSearchOutlined />
        </div>
      )}
      <div>
        <HeaderTypography variant={isMobile ? "h3" : "h1"}>
          {t(translationKey, { user: completeName })}
        </HeaderTypography>

        {!isFromDeeplink && (
          <Typography
            variant={isMobile ? "body2" : "body1"}
            sx={{ color: "rgba(51, 51, 51, 1)" }}
          >
            {t(
              type === "myself"
                ? "subheader-for-myself"
                : "subheader-for-someone-else"
            )}
          </Typography>
        )}
      </div>
    </HeaderContainer>
  );
};

export default RoleRequestHeader;
