import { useCallback, useState } from 'react'

export const useExpandDecorator = (Story, { args }) => {
  const [ isExpanded, setIsExpanded ] = useState(false)

  const onExpandClick = useCallback(({
    id,
    isExpanded,
    e
  }) => {
    setIsExpanded(isExpanded)
    console.info('onExpandClick', id, isExpanded, e)
  }, [])

  return Story({
    args: {
      ...args,
      isExpanded,
      onExpandClick
    }
  })
}

export const useSelectDecorator = (Story, { args }) => {
  const [ isSelected, setIsSelected ] = useState(false)

  const onCardClick = useCallback(({
    id,
    isSelected,
    data,
    e
  }) => {
    setIsSelected(isSelected)
    console.info('onCardClick', id, isSelected, data, e)
  }, [])

  return Story({
    args: {
      ...args,
      isSelected,
      onCardClick
    }
  })
}
