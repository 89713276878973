import { Accordion as MuiAccordion, AccordionDetails } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { mergeSxProps } from '../../util'
import { AccordionSummary } from './AccordionSummary'

/**
 * Simple Accordion component based on Material-UI Accordion.
 * See MUI Accordion API [here](https://mui.com/material-ui/react-accordion/#api).
 * This component renders an expandable accordion section with a title and content.
 * It provides all possible options from MUI Accordion API.
 *
 * ### Notes:
 * - `header` prop is created for special complex cases. Where `title` is not enough.
 * - If `header` is provided, there is no need to set `title` & `expandIcon` props, since `header` is
 * expecting AccordionSummary component from CLIB including these props.
 * - If `header` receives 'headless' string, the component will not render AccordionSummary subcomponent hence its title.
 */
export const Accordion = forwardRef((
  {
    title,
    expandIcon,
    defaultExpanded = true,
    children,
    header,
    sx = [],
    ...otherProps
  },
  ref
) => (
  <MuiAccordion
    data-testid="Accordion"
    ref={ref}
    defaultExpanded={defaultExpanded}
    sx={mergeSxProps({ overflow: 'hidden' }, sx)}
    {...otherProps}
  >
    {!header ? (
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls={`${title?.props.children}-content`}
        id={`${title?.props.children}-header`}
        sx={{
          backgroundColor: 'grey.80',
          minHeight: '52px !important',
          height: '52px !important',
          '&:hover': { backgroundColor: 'grey.90' },
          transition: 'background-color 250ms ease'
        }}
      >
        {title}
      </AccordionSummary>
    ) : (header !== 'headless' && header)}

    <AccordionDetails>{children}</AccordionDetails>
  </MuiAccordion>
))

Accordion.propTypes = {
  /** Accordion title */
  title: PropTypes.node,
  /** Determines if the Accordion is expanded by default.
   * If `true`, expands the accordion by default.
   * */
  defaultExpanded: PropTypes.bool,
  /** Expand Icon of the Accordion */
  expandIcon: PropTypes.node,
  /** Accordion Summary node. For Complex headers.
   * Expecting to receive AccordionSummary component from CLIB or string: 'headless' */
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([ 'headless' ])
  ])
}
