import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { getClient } from '../../util'
import { Link } from '../link'

export const ListLink = ({
  path,
  name,
  disabled,
  icon: Icon,
  chip,
  sx = []
}) => {
  const { t } = useTranslation()

  const matchPath = useMatch({
    path,
    end: path === ''
      || path === '/'
      || path === `/${getClient()}`
  })

  return (
    <Link
      data-testid={`ListLink-${name}`}
      key={name}
      href={path}
      sx={sx}
    >
      <ListItemButton
        selected={Boolean(matchPath)}
        disabled={disabled}
        sx={{
          flexGrow: 0,
          width: '100%',
          color: 'text.primary',
          '&.Mui-selected': {
            borderLeft: 'unset',
            paddingLeft: '16px',
            color: 'primary.dark',
            borderRadius: '4px',
            backgroundColor: 'blue.90'
          },
          '&:hover': { backgroundColor: 'action.hover', borderRadius: '4px' }
        }}
      >
        {Icon && (
          <ListItemIcon
            sx={{
              minWidth: '18px',
              fontSize: '18px', // we need this as icons might be from Mui lib
              marginRight: '8px',
              color: 'inherit'
            }}
          >
            <Icon color="inherit" fontSize="inherit" />
          </ListItemIcon>
        )}

        <ListItemText
          primary={t(`navTargets.${name}`)}
          primaryTypographyProps={{ variant: 'body2' }}
          sx={{
            '> span': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'inherit'
            }
          }}
        />

        {chip}
      </ListItemButton>
    </Link>
  )
}

ListLink.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
  chip: PropTypes.node,
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
