export const managementList = [
  "005",
  "004",
  "003",
  "002",
  "001",
  "C",
  "B",
  "A",
];

export const roleMap = {
  "005": "E5",
  "004": "E4",
  "003": "E3",
  "002": "E2",
  "001": "E1",
  C: "E1",
  B: "BOM",
  A: "CEO",
};

export function managementValue(value) {
  return roleMap[value] ?? "";
}
