import PropTypes from 'prop-types'
import { useAsyncDebounce } from 'react-table'
import { SearchBarAnimated } from '../../search'

export const GlobalFilter = ({
  setGlobalFilter,
  asyncDebounceTimeout = 150,
  placeholder = ''
}) => {
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, asyncDebounceTimeout)

  const onClear = () => {
    setGlobalFilter(undefined)
  }

  return (
    <SearchBarAnimated
      label={placeholder}
      onChange={onChange}
      onClear={onClear}
    />
  )
}

GlobalFilter.propTypes = {
  setGlobalFilter: PropTypes.func,
  asyncDebounceTimeout: PropTypes.number,
  placeholder: PropTypes.string
}
