import { Autocomplete as MuiAutocomplete } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

/**
 * The autocomplete is a normal text input enhanced by a panel of suggested options.<br>
 * https://mui.com/material-ui/api/autocomplete/
 */
export const Autocomplete = forwardRef((
  {
    sx = [],
    ...otherProps
  },
  ref
) => (
  <MuiAutocomplete
    data-testid="Autocomplete"
    ref={ref}
    sx={mergeSxProps(
      { '* > svg': { color: 'rgba(0, 0, 0, 0.54)' } },
      sx
    )}
    {...otherProps}
  />
))

Autocomplete.propTypes = {
  /** If `true`, the portion of the selected suggestion that has not been typed by the user, known as the completion string, appears inline after the input cursor in the textbox. The inline completion string is visually highlighted and has a selected state. */
  autoComplete: PropTypes.bool,
  /** If `true`, the first option is automatically highlighted. */
  autoHighlight: PropTypes.bool,
  /** If `true`, the selected option becomes the value of the input when the Autocomplete loses focus unless the user chooses a different option or changes the character string in the input. */
  autoSelect: PropTypes.bool,
  /** Control if the input should be blurred when an option is selected: - `false` the input is not blurred. - `true` the input is always blurred. - `touch` the input is blurred after a touch event. - `mouse` the input is blurred after a mouse event. */
  blurOnSelect: PropTypes.oneOfType([ PropTypes.oneOf([ 'mouse', 'touch' ]), PropTypes.bool ]),
  /** Props applied to the `Chip` element. */
  ChipProps: PropTypes.object,
  /** Override or extend the styles applied to the component. */
  classes: PropTypes.object,
  /** Add className to the element. */
  className: PropTypes.string,
  /** The icon to display in place of the default clear icon. */
  clearIcon: PropTypes.node,
  /** If `true`, the input's text is cleared on blur if no value is selected. */
  clearOnBlur: PropTypes.bool,
  /** If `true`, clear all values when the user presses escape and the popup is closed. */
  clearOnEscape: PropTypes.bool,
  /** Override the default text for the *clear* icon button. */
  clearText: PropTypes.string,
  /** Override the default text for the *close popup* icon button. */
  closeText: PropTypes.string,
  /** The props used for each slot inside. */
  componentsProps: PropTypes.shape({
    clearIndicator: PropTypes.object,
    paper: PropTypes.object,
    popper: PropTypes.object,
    popupIndicator: PropTypes.object
  }),
  /** The default value. Use when the component is not controlled. */
  defaultValue: PropTypes.any,
  /** If `true`, the input can't be cleared. */
  disableClearable: PropTypes.bool,
  /** If `true`, the popup won't close when a value is selected. */
  disableCloseOnSelect: PropTypes.bool,
  /** If `true`, the component is disabled. */
  disabled: PropTypes.bool,
  /** If `true`, will allow focus on disabled items. */
  disabledItemsFocusable: PropTypes.bool,
  /** If `true`, the list box in the popup will not wrap focus. */
  disableListWrap: PropTypes.bool,
  /** If `true`, the `Popper` content will be under the DOM hierarchy of the parent component. */
  disablePortal: PropTypes.bool,
  /** A function that determines the filtered options to be rendered on search. */
  filterOptions: PropTypes.func,
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions: PropTypes.bool,
  /** Force the visibility display of the popup icon. */
  forcePopupIcon: PropTypes.oneOfType([ PropTypes.oneOf([ 'auto' ]), PropTypes.bool ]),
  /** If `true`, the Autocomplete is free solo, meaning that the user input is not bound to provided options. */
  freeSolo: PropTypes.bool,
  /** If `true`, the input will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** The label to display when the tags are truncated (`limitTags`). */
  getLimitTagsText: PropTypes.func,
  /** Used to determine the disabled state for a given option. */
  getOptionDisabled: PropTypes.func,
  /** Used to determine the string value for a given option. It's used to fill the input (and the list box options if `renderOption` is not provided). If used in free solo mode, it must accept both the type of the options and a string. */
  getOptionLabel: PropTypes.func,
  /** If provided, the options will be grouped under the returned string. The groupBy value is also used as the text for group headings when `renderGroup` is not provided. */
  groupBy: PropTypes.func,
  /** If `true`, the component handles the "Home" and "End" keys when the popup is open. It should move focus to the first option and last option, respectively. */
  handleHomeEndKeys: PropTypes.bool,
  /** This prop is used to help implement the accessibility logic. If you don't provide an id it will fall back to a randomly generated one. */
  id: PropTypes.string,
  /** If `true`, the highlight can move to the input. */
  includeInputInList: PropTypes.bool,
  /** The input value. */
  inputValue: PropTypes.string,
  /** Used to determine if the option represents the given value. Uses strict equality by default. ⚠️ Both arguments need to be handled, an option can only match with one value. */
  isOptionEqualToValue: PropTypes.func,
  /** The maximum number of tags that will be visible when not focused. Set `-1` to disable the limit. */
  limitTags: PropTypes.number,
  /** The component used to render the listbox. */
  ListboxComponent: PropTypes.node,
  /** Props applied to the Listbox element. */
  ListboxProps: PropTypes.object,
  /** If `true`, the component is in a loading state. This shows the `loadingText` in place of suggestions (only if there are no suggestions to show, e.g. `options` are empty). */
  loading: PropTypes.bool,
  /** Text to display when in a loading state. */
  loadingText: PropTypes.node,
  /** If `true`, `value` must be an array and the menu will support multiple selections. */
  multiple: PropTypes.bool,
  /** Text to display when there are no options. */
  noOptionsText: PropTypes.node,
  /** Callback fired when the value changes. */
  onChange: PropTypes.func,
  /** Callback fired when the popup requests to be closed. Use in controlled mode (see open). */
  onClose: PropTypes.func,
  /** Callback fired when the highlight option changes. */
  onHighlightChange: PropTypes.func,
  /** Callback fired when the input value changes. */
  onInputChange: PropTypes.func,
  /** Callback fired when the popup requests to be opened. */
  onOpen: PropTypes.func,
  /** If `true`, the component is shown. */
  open: PropTypes.bool,
  /** If `true`, the popup will open on input focus. */
  openOnFocus: PropTypes.bool,
  /** Override the default text for the *open popup* icon button. */
  openText: PropTypes.string,
  /** Array of options. */
  options: PropTypes.array.isRequired,
  /** The component used to render the body of the popup. */
  PaperComponent: PropTypes.elementType,
  /** The component used to position the popup. */
  PopperComponent: PropTypes.elementType,
  /** The component used to position the popup. */
  popupIcon: PropTypes.node,
  /** If `true`, the component becomes readonly. It is also supported for multiple tags where the tag cannot be deleted. */
  readOnly: PropTypes.bool,
  /** Render the group. */
  renderGroup: PropTypes.func,
  /** Render the input. */
  renderInput: PropTypes.func.isRequired,
  /** Render the option, use `getOptionLabel` by default. */
  renderOption: PropTypes.func,
  /** Render the selected value. */
  renderTags: PropTypes.func,
  /** If `true`, the input's text is selected on focus. It helps the user clear the selected value. */
  selectOnFocus: PropTypes.func,
  /** The size of the component. */
  size: PropTypes.oneOf([ 'small', 'medium' ]),
  /** The value of the autocomplete. The value must have reference equality with the option in order to be selected. You can customize the equality behavior with the `isOptionEqualToValue` prop. */
  value: PropTypes.any,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
