import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { OrgCard, IconButtonText } from "@alice/component_library";

import OrgUpdateDialog from "../TabComponents/OrgUpdateDialog";
import { getUUID } from "@utils";
import { hasData } from "@utils/Validator";
import { SetSnackBar } from "@actions/commonAction";
import {
  AdministratorHeaderText,
  OrgHeadingSpan,
  OrgActionHeaderContainer,
  OrgListConatiner,
  CardContainer,
  OrganizationMainConatiner,
} from "../../styledComponents";

const MyOrganizations = ({ isResponsive }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allOrgs = useSelector((state) => state.role.availableOrganizationInfo);
  const user = useSelector((state) => state.user.userProfile);
  const userData = user.data;

  const [editWorkOrg, setEditWorkOrg] = useState(false);
  const [homeOrgAsWorkingOrg, setHomeorgAsWorkingOrg] = useState(false);
  const [otherOrgnaisation, setOtherOrgnaizations] = useState([]);
  const { homeOrgId, workingOrgId, dealerHomeOrgId } = userData;
  const homeOrg = allOrgs.find((d) => d.id === homeOrgId);
  const workOrg = allOrgs.find((d) => d.id === workingOrgId);
  const dealerHomeOrg = allOrgs.find((d) => d.id === dealerHomeOrgId);

  const setHomeorgAsWorkingOrgAction = () => {
    setHomeorgAsWorkingOrg(true);
    setEditWorkOrg(true);
  };

  const closeDialogueAction = () => {
    setHomeorgAsWorkingOrg(false);
    setEditWorkOrg(false);
  };

  useEffect(() => {
    let otherOrg = [];
    allOrgs.forEach((d) => {
      if (![homeOrgId, workingOrgId, dealerHomeOrgId].includes(d.id)) {
        otherOrg.push(d);
      }
    });
    setOtherOrgnaizations(otherOrg);
  }, [allOrgs, homeOrgId, workingOrgId, dealerHomeOrgId]);

  const notificationHandler = (notification = {}) =>
    dispatch(SetSnackBar(notification));

  const CardItem = (props) => (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={6}
      style={{ paddingBottom: "20px" }}
    >
      {props.children}
    </Grid>
  );

  return (
    <OrganizationMainConatiner container>
      <Grid container spacing={2}>
        {hasData(homeOrg) && (
          <CardItem>
            <OrgActionHeaderContainer>
              <OrgHeadingSpan>
                {t("home-org-info.home-organization")}
              </OrgHeadingSpan>
              {typeof workingOrgId === "undefined" || !workingOrgId?.length ? (
                <div>
                  <IconButtonText
                    enableHover
                    icon={<CreateIcon fontSize="small" />}
                    onClick={() => setHomeorgAsWorkingOrgAction()}
                    text={t("set-work-place")}
                  />
                </div>
              ) : (
                <></>
              )}
            </OrgActionHeaderContainer>
            <CardContainer>
              <OrgCard
                onCardClick={function noRefCheck() {}}
                organization={homeOrg}
                variant="grid"
                hasContext={true}
              />
            </CardContainer>
          </CardItem>
        )}
        {hasData(workOrg) && (
          <CardItem>
            <OrgActionHeaderContainer>
              <OrgHeadingSpan>
                {t("home-org-info.work-organization")}
              </OrgHeadingSpan>
              {otherOrgnaisation.length ||
              (workingOrgId.length && workingOrgId !== homeOrgId) ||
              hasData(dealerHomeOrg) ? (
                <div>
                  <IconButtonText
                    enableHover
                    icon={<CreateIcon fontSize="small" />}
                    onClick={() => setEditWorkOrg(!editWorkOrg)}
                    text={t("change")}
                  />
                </div>
              ) : (
                <></>
              )}
            </OrgActionHeaderContainer>
            <CardContainer>
              <OrgCard
                onCardClick={function noRefCheck() {}}
                organization={workOrg}
                variant="grid"
                hasContext={true}
              />
            </CardContainer>
          </CardItem>
        )}
        {hasData(dealerHomeOrg) && (
          <CardItem>
            <OrgActionHeaderContainer>
              <OrgHeadingSpan>
                {t("home-org-info.dealer-home-organization")}
              </OrgHeadingSpan>
            </OrgActionHeaderContainer>
            <CardContainer>
              <OrgCard
                onCardClick={function noRefCheck() {}}
                organization={dealerHomeOrg}
                variant="grid"
                hasContext={true}
              />
            </CardContainer>
          </CardItem>
        )}
      </Grid>
      {hasData(otherOrgnaisation) ? (
        <Grid container key={getUUID()} sx={{ flexDirection: "column" }}>
          <AdministratorHeaderText>
            {t("other-organizations")}
          </AdministratorHeaderText>
          {otherOrgnaisation.map((d, i) => (
            <OrgListConatiner key={i}>
              <OrgCard
                hasContext
                organization={d}
                variant="list"
                isCompactMode={isResponsive}
              />
            </OrgListConatiner>
          ))}
        </Grid>
      ) : null}
      {editWorkOrg && (
        <OrgUpdateDialog
          open={editWorkOrg}
          notificationHandler={notificationHandler}
          closehandler={() => closeDialogueAction()}
          homeOrgAsWorkingOrg={homeOrgAsWorkingOrg}
          isResponsive={isResponsive}
        />
      )}
    </OrganizationMainConatiner>
  );
};

export default MyOrganizations;
