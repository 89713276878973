import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Button, Layout } from "@alice/component_library";
import StatusRoleConfig from "@components/StatusRoleConfiguration";

import {
  MainViewFooterStyle,
  RoleRequestFooterConatiner,
  StyledConfiguredRoleCount,
} from "@pages/newRequest/styledComponents";
import { useWidth } from "@src/utils/hooks";

const RoleConfigFooter = forwardRef(
  (
    {
      isPreviousButtonVisible = true,
      isRoleConfiguration = false,
      isNextDisabled = false,
      previousAction = () => {},
      nextAction = () => {},
      isLastStep = false,
      data = { configured: 6, total: 14 },
      isConditionAndReason,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const widthPanel = useWidth();
    const isMobile = ["xs", "sm"].includes(widthPanel);
    const [footerData, setFooterData] = useState({}); // Use props for initial state
    const [isFooterNextIsVisible, setIsFotterNextIsVisble] = useState(false);

    useImperativeHandle(ref, () => ({
      updateConfigured: (configured) => {
        setFooterData((prevState) => ({
          ...prevState,
          configured: configured,
        })); // Ensure immutability
      },

      updateFooternextVisibility: (value) => {
        setIsFotterNextIsVisble(value);
      },
    }));

    useEffect(() => {
      setFooterData((prevState) => ({
        ...prevState,
        total: data?.total,
      })); // Ensure immutability
    }, [data]);

    const status = useMemo(
      () =>
        isRoleConfiguration && footerData.configured === footerData.total
          ? "success"
          : "failed",
      [isRoleConfiguration, footerData]
    );

    return (
      <RoleRequestFooterConatiner>
        <Layout>
          {isMobile && isRoleConfiguration && (
            <>
              <StatusDisplay
                status={status}
                configured={footerData.configured}
                total={footerData.total}
              />
              <Divider />
            </>
          )}
          <MainViewFooterStyle
            isPreviousButtonVisible={isPreviousButtonVisible}
          >
            {isPreviousButtonVisible ? (
              <Button
                size="medium"
                variant="secondary"
                onClick={() => previousAction()}
              >
                <NavigateBeforeIcon />
                {t("previous")}
              </Button>
            ) : (
              <></>
            )}
            <div className="footer-container">
              {isRoleConfiguration && !isMobile ? (
                <StatusDisplay
                  status={status}
                  configured={footerData.configured}
                  total={footerData.total}
                />
              ) : (
                <></>
              )}

              <Button
                size="medium"
                variant="primary"
                disabled={
                  isNextDisabled ||
                  (isRoleConfiguration &&
                    footerData?.configured < footerData?.total) ||
                  (isConditionAndReason && isFooterNextIsVisible)
                }
                onClick={() => nextAction()}
              >
                {isLastStep ? (
                  <>
                    <ForwardToInboxIcon />
                    {t("submit-request")}
                  </>
                ) : (
                  <>
                    {t("next")}
                    <NavigateNextIcon />
                  </>
                )}
              </Button>
            </div>
          </MainViewFooterStyle>
        </Layout>
      </RoleRequestFooterConatiner>
    );
  }
);
const StatusDisplay = ({ status, configured, total }) => {
  const { t } = useTranslation();

  return (
    <StyledConfiguredRoleCount>
      <div className="rightcheck-icon">
        <StatusRoleConfig status={status} />
      </div>
      <Typography>
        {t("role-configured-total", { configured, total })}
      </Typography>
    </StyledConfiguredRoleCount>
  );
};

export default RoleConfigFooter;
