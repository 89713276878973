import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { JSONExporter } from '../../../util'
import { Button } from '../button/Button'

/**
 * A simple export button to execute a download of either `CSV` or `XLSX` based on file name.
 *
 * Exports:
 * - *CSV*: *.csv
 * - *XLSX*: *.xlsx
 *
 * Inherits properties from: [Button](./?path=/docs/alice-ui-buttons-button--docs)
 */
export const ExportButton = forwardRef(({
  jsonData = [],
  fileName = 'export.csv',
  options = {},
  children,
  ...otherProps
}, ref) => {
  const handleDownload = () => {
    if (fileName.endsWith('.csv')) {
      JSONExporter.asCSV(jsonData, {
        fileName,
        ...options
      })
    }

    if (fileName.endsWith('.xlsx')) {
      JSONExporter.asXLSX(jsonData, {
        fileName,
        ...options
      })
    }
  }

  return (
    <Button
      ref={ref}
      onClick={handleDownload}
      {...otherProps}
    >
      {children}
    </Button>
  )
})

ExportButton.propTypes = {
  /** Array of objects */
  jsonData: PropTypes.array.isRequired,
  /** File name. Needs to contain  */
  fileName: PropTypes.string,
  /**
   *  Export Options
   *
   *  *CSV*
   *
   * - *headers*: Array of CSV headers. Order needs to be the same as the object is. Default: Object Keys
   * - *delimiter*: Custom delimiter between values. Default: ','
   *
   * *XLSX*
   *
   * - *sheetName*: Custom sheet name. Default: 'Sheet'
   */
  options: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string),
    delimiter: PropTypes.string,
    sheetName: PropTypes.string
  })
}
