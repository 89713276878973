import React from "react";
import accessIcon from "./sharedComponents/Icon";

import User from "./pages/user";
import ProfilePage from "./pages/profilePage";

import ControlCenter from "./pages/CC";
import UserDetails from "./pages/CC/user";
import TaskDeeplink from "./pages/taskPage/components/taskDeeplink";
import DetailInfo from "./pages/accessReview/DetailsInfo";

import DeactivationPage from "./deActivation";
import Cookies from "./sharedComponents/cookies";
import ContactSupport from "./sharedComponents/contactSupport/contactSupport.js";
import RequestPage from "./pages/requestPage";
import AccessReview from "./pages/accessReview";
import HomePage from "./pages/HomePage";
import RequestDeeplink from "./pages/requestPage/Components/RequestDeeplink";
import TaskPage from "./pages/taskPage";
import NewRequest from "./pages/newRequest";
import RequestResult from "./pages/newRequest/components/RequestResult";

const defaultRoutes = [
  {
    id: 0,
    path: "/",
    component: <HomePage />,
    isMenu: false,
    order: 0,
  },
  {
    id: 1,
    path: "/access",
    icon: accessIcon("home"),
    name: "Home",
    component: <HomePage />,
    isMenu: true,
    isActive: true,
    order: 1,
  },
  {
    id: 2,
    path: "/access/newRequest/myself",
    component: <User />,
    isMenu: false,
    isActive: true,
  },
  {
    id: 3,
    path: "/access/tasks",
    icon: accessIcon("task"),
    name: "Tasks",
    component: <TaskPage />,
    isMenu: true,
    isActive: true,
    order: 3,
  },
  {
    id: 4,
    path: "/access/requests",
    icon: accessIcon("requests"),
    name: "Requests",
    component: <RequestPage />,
    isMenu: true,
    isActive: true,
    order: 4,
  },
  {
    id: 5,
    path: "/access/accessReview",
    icon: accessIcon("accessReview"),
    name: "access-review",
    component: <AccessReview />,
    isMenu: true,
    isActive: false,
    order: 5,
  },
  {
    id: 6,
    path: "/access/insights",
    icon: accessIcon("controlcenter"),
    name: "control-center",
    component: <ControlCenter />,
    isMenu: true,
    isActive: false,
    order: 6,
  },

  {
    id: 6,
    path: "/access/profile",
    icon: accessIcon("profile"),
    name: "Profile",
    component: <ProfilePage />,
    isMenu: true,
    isActive: true,
    order: 6,
  },
  {
    id: 7,
    path: "/access/support",
    icon: accessIcon("headPhone"),
    name: "Support",
    component: <ContactSupport />,
    isMenu: false,
    order: 7,
  },
  {
    id: 8,
    path: "/access/newRequest/:type",
    component: <User />,
    isMenu: false,
    order: 8,
  },
  {
    id: 10,
    path: "/access/cookies",
    component: <Cookies />,
    isMenu: false,
    order: 10,
  },
  {
    id: 11,
    path: "/access/stopUserDeactivation",
    component: <DeactivationPage />,
    isMenu: false,
    order: 11,
  },
  {
    id: 13,
    path: "/access/tasks/details",
    isMenu: false,
    component: <TaskDeeplink />,
    order: 13,
  },
  {
    id: 14,
    path: "/access/insights/user/:user",
    isMenu: false,
    component: <UserDetails />,
    order: 14,
  },
  {
    id: 15,
    path: "/access/accessReview/:roleReviewId",
    isMenu: false,
    component: <DetailInfo />,
    order: 15,
  },
  {
    id: 16,
    path: "/access/requests/details",
    isMenu: false,
    component: <RequestDeeplink />,
    order: 16,
  },
  {
    id: 17,
    path: "/access/accessRequest",
    icon: accessIcon("newReq"),
    name: "New Request",
    isMenu: true,
    isActive: true,
    component: <NewRequest />,
    order: 2,
  },
  {
    id: 18,
    path: "/access/accessRequest/:type",
    isMenu: false,
    component: <NewRequest />,
    order: 18,
  },
  {
    id: 19,
    path: "/access/accessRequest/result/:dataId",
    isMenu: false,
    component: <RequestResult />,
    order: 18,
  }, // has to be removed

  {
    id: 8,
    path: "/access/profile/:tabName",
    icon: accessIcon("profile"),
    name: "Profile",
    component: <ProfilePage />,
    isMenu: false,
    isActive: true,
    order: 6,
  },
];

export default defaultRoutes;
