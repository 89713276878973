import { KeyboardArrowUpOutlined } from '@mui/icons-material'
import { Box, Divider, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { isEmail } from '../../../util'
import { IconButton } from '../../button'
import { DataClassificationChip } from '../../chip'
import { AppLogo } from '../appLogo/AppLogo'

const ContentLink = ({ href, children }) => (
  <a
    href={isEmail(href) ? `mailto:${href}` : href}
    rel="noreferrer"
    style={{ textDecoration: 'none' }}
  >
    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>{children}</Typography>
  </a>
)

export const AppContext = ({
  appData: {
    id,
    name,
    description,
    url,
    logoBase64,
    dataClassification
  },
  supportData,
  actions
}) => {
  const [ open, setOpen ] = useState(false)
  const [ innerRef, setInnerRef ] = useState(null)
  const [ wrapperRef, setWrapperRef ] = useState(null)
  const [ isExpandable, setIsExpandable ] = useState(false)
  const [ height, setHeight ] = useState(0)
  const initHeight = 72

  useMemo(() => {
    if (innerRef && wrapperRef) {
      // eslint-disable-next-line no-undef
      const observer = new ResizeObserver((entries) => {
        const height = entries[0].target.scrollHeight

        setHeight(height)
      })

      observer.observe(innerRef)
    }
  }, [ innerRef, wrapperRef ])

  useEffect(() => {
    if (innerRef) {
      setIsExpandable(height > initHeight)

      open && innerRef.classList.remove('truncate')
    }
  }, [ wrapperRef, innerRef, open, isExpandable, height ])

  return (
    <>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <AppLogo size="small" logo={logoBase64} sx={{ borderRadius: '4px', overflow: 'hidden' }} />

          {dataClassification && (
            <DataClassificationChip type={dataClassification.toLowerCase()} size="tiny" />
          )}
        </Stack>

        <Typography
          sx={{
            fontSize: 22,
            lineHeight: 'normal',
            letterSpacing: '0.22px'
          }}
        >
          {name}
        </Typography>

        {description && (
          <Stack
            direction="row"
            sx={[
              {
                position: 'relative',
                '.textWrapper': {
                  transition: 'max-height 250ms ease',
                  maxHeight: open ? `${height}px` : '72px',
                  overflow: 'hidden'
                },
                '.truncate': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical'
                }
              }
            ]}
          >
            <Stack
              ref={(ref) => setWrapperRef(ref)}
              className="textWrapper"
              onTransitionEnd={() => {
                !open && innerRef.classList.add('truncate')
              }}
            >
              <Typography
                ref={(ref) => setInnerRef(ref)}
                className="truncate"
                sx={[
                  isExpandable && { width: 'calc(100% - 24px)' }
                ]}
                variant="body1"
              >
                {description}
              </Typography>
            </Stack>

            {isExpandable && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -8,
                  right: -16,
                  width: 'fit-content',
                  height: 'fit-content'
                }}
              >
                <IconButton
                  sx={[
                    {
                      fontSize: '24px',
                      color: open ? 'primary.main' : 'action.active',
                      transition: 'transform 250ms'
                    },
                    !open && { transform: 'rotate(180deg)' }
                  ]}
                  onClick={() => {
                    setOpen(!open)
                  }}
                >
                  <KeyboardArrowUpOutlined fontSize="inherit" color="inherit" />
                </IconButton>
              </Box>
            )}
          </Stack>
        )}
      </Stack>

      <Divider />

      <Stack gap={2}>
        <Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>ID</Typography>

          <Typography variant="subtitle1">{id}</Typography>
        </Stack>

        {url && (
          <Stack>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>URL</Typography>

            <ContentLink href={url}>{url}</ContentLink>
          </Stack>
        )}

        {supportData.length > 0 && (
          <Stack>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>Support</Typography>

            {supportData.map((support) => (
              <ContentLink key={support.id} href={support.mailAddress}>{support.mailAddress}</ContentLink>
            ))}
          </Stack>
        )}
      </Stack>

      {(actions && !!actions.length) && (
        <Stack gap={2}>
          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              minWidth: '100%',
              gap: 2
            }}
          >
            {actions.map(({ icon: Icon, onClick }, index) => (
              <IconButton
                key={index.toString()}
                sx={{
                  fontSize: '24px',
                  color: 'action.active',
                  backgroundColor: 'grey.90',
                  borderRadius: 0,
                  minWidth: '90px',
                  flexBasis: '0px',
                  flexGrow: 1
                }}
                onClick={(e) => {
                  e.stopPropagation()

                  onClick({ id, e })
                }}
              >
                <Icon color="inherit" fontSize="inherit" />
              </IconButton>
            ))}
          </Box>
        </Stack>
      )}
    </>
  )
}

AppContext.propTypes = {
  appData: PropTypes.object.isRequired,
  supportData: PropTypes.array,
  actions: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.node, onClick: PropTypes.func }))
}
