import PropTypes from 'prop-types'
import { blue, grey } from '@mercedes-benz/mui5-theme'
import { KeyboardArrowUpOutlined } from '@mui/icons-material'
import { mergeSxProps } from '../../../../../util'
import { Skeleton } from '../../../../skeleton'
import { IconButton } from '../../../../button'

export const ExpandBtn = ({
  isLoading,
  isExpanded,
  hasSmallClickArea = false,
  onClick = ({ isExpanded, e }) => {},
  sx
}) => (isLoading
  ? (
    <Skeleton
      variant="circular"
      sx={mergeSxProps({
        width: 24,
        height: 24
      }, sx)}
    />
  )
  : (
    <IconButton
      hasSmallClickArea={hasSmallClickArea}
      onClick={(e) => {
        e.stopPropagation()
        onClick({ isExpanded: !isExpanded, e })
      }}
      sx={mergeSxProps([
        {
          fontSize: '24px',
          color: isExpanded ? blue['45'] : grey['45'],
          transition: 'transform 250ms'
        },
        !isExpanded && { transform: 'rotate(180deg)' }
      ], sx)}
    >
      <KeyboardArrowUpOutlined fontSize="inherit" />
    </IconButton>
  ))

ExpandBtn.propTypes = {
  isLoading: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.any
}
