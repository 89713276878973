import React, { useMemo } from "react";
import { Checkbox, Typography, Stack } from "@mui/material";
import {
  DataClassificationChip,
  Tooltip,
  AliceIcon,
  StateIcon,
} from "@alice/component_library";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StartIcon from "@mui/icons-material/Start";
import AttachFile from "@mui/icons-material/AttachFile";
import {
  StyledHeaderContainer,
  RoleCardContentContainer,
  RoleTypeClassification,
} from "./StyledComponents";
import RoleTypeChip from "./RoleTypeChip";
import OrgNameInfo from "./OrgName";
import CustomScopeName from "./CustomScopeName";
import { ValidFromComp, ValidToComp } from "./Validity";

const RoleCardBody = React.memo(
  ({
    roleCardId,
    hideActions,
    handleCheckboxClick,
    size,
    data,
    state = "none",
    isSelected,
    finalValidFrom,
    finalValidTo,
    finalValidityDaysLeft,
    hideCardContent = false,
    showSelection = true,
  }) => {
    const remainingDaysToWarning = 14;
    const { t } = useTranslation();
    const {
      roleDefinition = {},
      customScope = {},
      orgScope = {},
      needsOrgScopes = false,
      needsCustomScopes = false,
      attachments = 0,
    } = useMemo(() => data, [data]);
    const { name: custId } = customScope;
    const { id: orgId, name: orgName } = orgScope;

    const {
      id: roleId,
      name: roleName,
      roleType = "BUSINESS",
      isJobTitle = false,
      dataClassification = "INTERNAL",
      needAttachments = false,
    } = useMemo(() => roleDefinition, [roleDefinition]);

    const contentArray = useMemo(() => {
      const dataChange = [
        {
          id: "orgScope",
          name: t("org-scope"),
          icon: <LanguageIcon />,
          value: (
            <OrgNameInfo
              orgName={orgName}
              orgId={orgId}
              needsOrgScopes={needsOrgScopes}
            />
          ),
        },
        {
          id: "custScope",
          name: t("custom-scope"),
          icon: (
            <AliceIcon
              iconType="CUSTOM_SCOPE"
              size={16}
              sx={{ marginBottom: "5px" }}
            />
          ),
          value: (
            <CustomScopeName
              custId={custId}
              needsCustomScopes={needsCustomScopes}
            />
          ),
        },
      ];

      return dataChange;
    }, [t, orgName, orgId, needsOrgScopes, needsCustomScopes, custId]);

    return (
      <>
        <RoleTypeClassification>
          <Stack direction="row" gap={1} flexWrap={"wrap"}>
            <RoleTypeChip type={roleType} />
            {isJobTitle && <RoleTypeChip type="JOBTITLE" />}
          </Stack>
          <DataClassificationChip
            size="s"
            type={dataClassification.toLowerCase()}
            variant="default"
          />
        </RoleTypeClassification>
        <Divider />
        <StyledHeaderContainer>
          {showSelection && state !== "none" && <StateIcon state={state} />}
          {showSelection && (
            <Checkbox checked={isSelected} onClick={handleCheckboxClick} />
          )}
          <div
            className={`header-container ${
              size === "large" ? "large-variant" : ""
            }`}
          >
            <div className="role-cardv3-header">
              <Typography variant="caption" className="id-holder">
                {t("id")}
              </Typography>
              <Tooltip type="ellipsized" title={roleId}>
                <Typography
                  variant="subtitle1"
                  className="role-cardv3-id"
                  noWrap
                >
                  {roleId}
                </Typography>
              </Tooltip>
            </div>
            <Tooltip type="ellipsized" title={roleName}>
              <Typography variant="body1" className="role-cardv3-name" noWrap>
                {roleName}
              </Typography>
            </Tooltip>
          </div>
        </StyledHeaderContainer>
        {!hideCardContent && (
          <>
            <Divider />
            <RoleCardContentContainer>
              {contentArray.map((ele) => (
                <RoleItem ele={ele} size={size} />
              ))}

              <div className="validity-container">
                <RoleItem
                  ele={{
                    id: "validFrom",
                    name: t("valid_from"),
                    icon: <StartIcon />,
                    value: (
                      <div
                        className={
                          size === "large"
                            ? "custom-width"
                            : "custom-validity-width"
                        }
                      >
                        <ValidFromComp finalValidFrom={finalValidFrom} />
                      </div>
                    ),
                  }}
                />
                <RoleItem
                  ele={{
                    id: "validTo",
                    name: t("valid-to"),
                    icon: <KeyboardTabIcon />,
                    value: (
                      <div className="custom-validity-to-width">
                        <ValidToComp
                          finalValidTo={finalValidTo}
                          finalValidityDaysLeft={finalValidityDaysLeft}
                          remainingDaysToWarning={remainingDaysToWarning}
                        />
                      </div>
                    ),
                  }}
                />
              </div>
              {needAttachments && (
                <RoleItem
                  ele={{
                    id: "attachments",
                    name: t("attachments"),
                    icon: <AttachFile />,
                    value: (
                      <Typography variant="body2" noWrap>
                        {!!attachments ? attachments : "-"}
                      </Typography>
                    ),
                  }}
                  size={size}
                />
              )}
            </RoleCardContentContainer>
          </>
        )}
      </>
    );
  }
);

const RoleItem = React.memo(({ ele, size }) => (
  <div className="content-detail-container">
    {ele.icon}
    <div className={size === "large" ? "name-value-large" : "name-value"}>
      <Typography
        variant="caption"
        className="content-header-name"
        noWrap
        sx={{ width: "130px", minWidth: "130px" }}
      >
        {ele.name}
      </Typography>

      {ele.value}
    </div>
  </div>
));

export default RoleCardBody;
