import { roleActionTypes } from "@constants/actionTypes";
import { getUUID } from "@utils";
const initialState = {
  selectedRoles: [],
  assignableRoles: [],
  entitlements: { loading: false, data: [] },
  custScopeValues: [],
  orgScopeValues: [],
  availableApplications: [],
  receipientid: null,
  newRequest: { loading: false, data: [], error: false, err: {} },
  organizationInfo: [],
  assignedRoles: [],
  expRoles: [],
  assignedApplications: [],
  searchKeyword: "",
  searchorgValues: [],
  availableOrganizationInfo: [],
  userApplications: [],
  applicationsLogo: [],
  userSupportContact: [],
  workingOrganizationInfo: {},
  requiredOrganizationInfo: [],
  applcount: 0,
  appltotalCount: 0,
  modeData: [],
  roleById: { loading: false, data: {}, error: false, err: {} },
  orgById: { loading: false, data: {}, error: false, err: {} },
  previousOrgScopes: [],
  rolesInfoDesktop: {
    tottalCount: 0,
    expiredRoleCount: 0,
    expiringRolesCount: 0,
    rolesDetail: [],
    loading: false,
    isLoaded: false,
  },
};

const roleReducer = (state = initialState, action) => {
  const { type, data, ismul, count } = action;
  switch (type) {
    case roleActionTypes.ROLE_DATA_UPDATE:
      return (state = {
        ...state,
        assignableRoles: data,
      });
    case roleActionTypes.STORE_SEARCH_KEYWORD:
      return (state = {
        ...state,
        searchKeyword: data,
      });

    case roleActionTypes.STORE_EXTEND_DATA:
      return (state = {
        ...state,
        modeData: data,
      });

    case roleActionTypes.GET_AVAILABLE_APPLICATIONS:
      let applData = [];
      if (data && data.applications && data.applications.length > 0) {
        applData =
          data.applications &&
          data.applications.map((x) => ({
            value: x.id,
            label: x.name,
          }));
      }

      return (state = {
        ...state,
        availableApplications: [
          { label: "All Applications", value: "All Applications" },
          ...applData,
        ],
      });

    case roleActionTypes.FETCH_SELECTED_ROLES:
      const key = "id";
      let test = state.selectedRoles.concat(data);
      let filteredItems = [];
      filteredItems = [
        ...new Map(test.map((item) => [item[key], item])).values(),
      ];

      return (state = {
        ...state,
        selectedRoles: filteredItems,
      });

    case roleActionTypes.REMOVE_SELECTED_ROLES:
      let filteredList = state.selectedRoles.filter((item) => item.id !== data);
      let sortedList = state.assignableRoles ? state.assignableRoles : [];
      if (filteredList.length > 0) {
        for (let i = 0; i < sortedList.length; i++) {
          if (sortedList[i].id === data) {
            sortedList[i].selected = false;
          }
        }
      } else {
        for (let i = 0; i < sortedList.length; i++) {
          sortedList[i].selected = false;
        }
      }
      return (state = {
        ...state,
        selectedRoles: filteredList,
        assignableRoles: sortedList,
      });

    case roleActionTypes.FETCH_ROLES_BASED_ON_FILTERS:
      for (const element of data.roles) {
        element.validity = "";
        element.validTo = "";
        element.validFrom = "";
        element.selected = false;
        element.orgScope = "";
        element.customScope = [];
        element.orgScopeauto = [];
        element.selectedScopes = [];
        element.custScope = "";
        element.dialogOpen = false;
      }

      if (state.selectedRoles.length > 0) {
        state.selectedRoles.forEach((selectedObj) => {
          const dataObjIndex = data.roles.findIndex(
            (dataObj) => dataObj.id === selectedObj.id
          );

          if (dataObjIndex >= 0) {
            data.roles[dataObjIndex] = {
              ...data.roles[dataObjIndex],
              ...selectedObj,
            };
          }
        });
      }

      let roleList = [];

      if (data && data.roles && ismul) {
        let key = "id";
        roleList =
          count === 0 ? data.roles : state.assignableRoles.concat(data.roles);
        if (roleList.length > 0) {
          roleList = [
            ...new Map(roleList.map((item) => [item[key], item])).values(),
          ];
        }
      }

      return (state = {
        ...state,
        assignableRoles: data.roles ? data.roles : [],
        count: data.rels.limit,
        totalCount: data.rels.totalCount,
      });

    case roleActionTypes.GET_ORG_SCOPE:
      let orgdata = [];

      orgdata = data.organizations.map((property) => ({
        value: property.id,
        label: property.name,
        groupType: property.orgGroupType,
        community: property.communities,
        country: property.country,
        orgForm: property.orgForm,
      }));

      return (state = {
        ...state,
        orgScopeValues: orgdata,
      });

    case roleActionTypes.SEARCH_ORG_SCOPE:
      let orgsearchdata = [];

      orgsearchdata =
        data.organizations && data.organizations.length > 0
          ? data.organizations.map((property) => ({
              value: property.id,
              label: property.name,
              groupType: property.orgGroupType,
              community: property.communities,
              country: property.country,
              orgForm: property.orgForm,
            }))
          : [];

      return (state = {
        ...state,
        searchorgValues: orgsearchdata,
      });

    case roleActionTypes.GET_ORG_INFO:
      return (state = {
        ...state,
        organizationInfo: data,
      });
    case roleActionTypes.GET_WORK_ORG_INFO:
      return (state = {
        ...state,
        workingOrganizationInfo: data,
      });

    case roleActionTypes.GET_AVAILABLE_ORG_INFO:
      return (state = {
        ...state,
        availableOrganizationInfo: data,
      });

    case roleActionTypes.GET_USER_APPLICATIONS:
      let userApp = data ? data : [];
      if (userApp.applications && userApp.applications.length > 0) {
        for (const element of userApp.applications) {
          element.isOpen = false;
        }
      }

      return (state = {
        ...state,
        userApplications:
          data && data.applications && data.applications.length > 0
            ? userApp.applications
            : [],
        applcount: data.rels.limit,
        appltotalCount: data.rels.totalCount,
      });

    case roleActionTypes.UPDATE_AVAILABLE_APPLICATIONS:
      return (state = {
        ...state,
        userApplications: data,
      });

    case roleActionTypes.GET_APPLICATIONS_LOGO:
      return (state = {
        ...state,
        applicationsLogo: data,
      });

    case roleActionTypes.GET_USER_SUPPORT_CONTACT:
      return (state = {
        ...state,
        userSupportContact: data,
      });

    case roleActionTypes.GET_REQUIRED_ORG_INFO:
      return (state = {
        ...state,
        requiredOrganizationInfo: data,
      });
    case roleActionTypes.FETCH_USER_ASSIGNED_ROLES:
      if (data !== undefined) {
        for (const element of data.roles) {
          element.validity = "";
          element.validTo = "";
          element.validFrom = "";
          element.selected = false;
          element.orgScope = "";
          element.customScope = [];
          element.selectedScopes = [];
          element.orgScopeauto = [];
          element.custScope = "";
          element.isRoleOpen = false;
          element.dialogOpen = false;
          element.isEditOpen = false;
        }

        if (state.selectedRoles.length > 0) {
          let map = new Map(state.selectedRoles.map((o) => [o.id, o]));

          data.roles.forEach(
            (o) => map.has(o.id) && (o.selected = map.get(o.id).selected)
          );
        }

        let roleList = [];
        if (data && data.roles && ismul) {
          let key = "id";
          roleList = state.assignableRoles.concat(data.roles);
          if (roleList.length > 0) {
            roleList = [
              ...new Map(roleList.map((item) => [item[key], item])).values(),
            ];
          }
        }

        return (state = {
          ...state,
          assignableRoles:
            data && data.roles && data.roles.length > 0 && data.roles
              ? ismul
                ? roleList
                : [...data.roles]
              : [],
          count: data.rels && data.rels.limit,
          totalCount: data.rels && data.rels.totalCount,
        });
      } else {
        return (state = {
          ...state,
          assignableRoles: [],
          count: 0,
          totalCount: 0,
          selectedRoles: [],
          previousOrgScopes: [],
          searchKeyword: "",
        });
      }

    case roleActionTypes.GET_ROLE_ENTITLEMENTS:
      return (state = {
        ...state,
        entitlements: data,
      });
    case roleActionTypes.CLEAR_SUBMIT_DATA:
      return (state = { ...state, newRequest: data });

    case roleActionTypes.SUBMIT_DATA:
      const newRequestTemp = {
        loading: data.loading,
        data: state.newRequest.data.concat(data.data),
      };
      return (state = {
        ...state,
        newRequest: newRequestTemp,
      });

    case roleActionTypes.CLEAR_ROLE_INFO:
      return (state = {
        ...state,
        selectedRoles: [],
        assignableRoles: [],
        entitlements: { data: [], loading: false },
        custScopeValues: [],
        orgScopeValues: [],

        availableApplications: [],
        receipientid: null,
        previousOrgScopes: [],
        searchKeyword: "",
      });
    /**
     * Reducer for Profile Page
     */
    case roleActionTypes.FETCH_ALL_ASSIGNED_ROLES:
      for (const element of data.roles) {
        element.id = getUUID();
        element.isRoleOpen = false;
        element.isDelete = false;
      }
      return (state = {
        ...state,
        assignedRoles: [...data.roles],
      });
    case roleActionTypes.REMOVE_STORED_ROLES_DATA:
      return (state = {
        ...state,
        assignedRoles: [],
      });

    case roleActionTypes.REMOVE_STORED_APP_DATA:
      return (state = {
        ...state,
        assignedApplications: [],
      });
    case roleActionTypes.FETCH_ROLE_BY_ID:
      return (state = {
        ...state,
        roleById: data,
      });
    case roleActionTypes.FETCH_ORG_BY_ID:
      return (state = {
        ...state,
        orgById: data,
      });
    case roleActionTypes.UPDATE_PREVIOUS_ORG_SCOPES:
      return (state = {
        ...state,
        previousOrgScopes: data,
      });
    case roleActionTypes.FETCH_EXP_ROLES:
      return (state = {
        ...state,
        rolesInfoDesktop: data,
      });

    default:
      return state;
  }
};
export default roleReducer;
