import { Skeleton, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HttpCode, OrganizationsService } from '../../../data'
import { useApi } from '../../../hooks'
import { timeout } from '../../../util'
import { GenericContextPopover } from '../../genericContextPopover'
import { AliceIconType } from '../../icon'
import { Message, NotAuthorized } from '../../message'
import { OrgContext } from './OrgContext'

// For dev: timeoutInMS = 3000 -> wait 3 sec before the initial and other api calls are triggered
// For live: Set timeoutInMS to 0 (zero)!
const timeoutInMS = process.env.NODE_ENV === 'development' ? 3000 : 0

// LoadingSkeleton is shown as long the request is running to get the user data
const LoadingSkeleton = () => (
  <>
    <Stack gap={1}>
      <Skeleton variant="rounded" sx={{ width: '270px', height: '22px' }} />

      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1,
          alignItems: 'flex-start'
        }}
      >
        <Skeleton variant="circular" sx={{ width: '16px', height: '16px' }} />

        <Stack gap={0.5}>
          <Skeleton variant="rounded" sx={{ width: '220px', height: '16px' }} />

          <Skeleton variant="rounded" sx={{ width: '75px', height: '16px' }} />
        </Stack>
      </Stack>
    </Stack>

    <Skeleton
      variant="rounded"
      sx={{
        borderRadius: '20px',
        height: '36px'
      }}
    />

    <Stack
      sx={{
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center'
      }}
    >
      <Skeleton variant="rounded" sx={{ width: '20px', height: '20px' }} />

      <Stack gap={1}>
        <Skeleton variant="rounded" sx={{ height: '14px', width: '48px' }} />

        <Skeleton variant="rounded" sx={{ height: '20px', width: '175px' }} />
      </Stack>

    </Stack>

    <Stack
      sx={{
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center'
      }}
    >
      <Skeleton variant="rounded" sx={{ width: '20px', height: '20px' }} />

      <Stack gap={1}>
        <Skeleton variant="rounded" sx={{ height: '14px', width: '48px' }} />

        <Skeleton variant="rounded" sx={{ height: '20px', width: '175px' }} />
      </Stack>

    </Stack>

    <Stack
      sx={{
        backgroundColor: 'grey.95',
        borderRadius: '4px',
        gap: 2,
        padding: '8px 16px'
      }}
    >
      <Stack gap={1}>
        <Skeleton variant="rounded" sx={{ height: '14px', width: '48px' }} />

        <Skeleton variant="rounded" sx={{ height: '20px', width: '175px' }} />
      </Stack>

      <Stack gap={1}>
        <Skeleton variant="rounded" sx={{ height: '14px', width: '48px' }} />

        <Skeleton variant="rounded" sx={{ height: '20px', width: '175px' }} />
      </Stack>

      <Stack gap={1}>
        <Skeleton variant="rounded" sx={{ height: '14px', width: '48px' }} />

        <Skeleton variant="rounded" sx={{ height: '20px', width: '175px' }} />
      </Stack>
    </Stack>
  </>
)

/**
 * Make sure to setup an axios interceptor which adds the authorization data to the request.
 * This component displays more information on hover.
 */
export const OrgContextPopover = ({
  id: newId = null,
  organizationId = null, // deprecated prop, will be removed soon
  placement,
  children
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ isTimeoutDone, setIsTimeoutDone ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  // This temporary variable is used as long as the deprecated prop is present in the component.
  const id = newId || organizationId

  const {
    isFailure: isFailureOrganization,
    status: statusOrganization,
    response: responseOrganization,
    error: errorOrganization
  } = useApi(
    OrganizationsService.getById,
    {
      config: { id },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone
    }
  )

  useEffect(() => {
    const waitTimeout = async () => {
      if (timeoutInMS > 0) timeout(timeoutInMS)
      setIsTimeoutDone(true)
    }

    if (id && isOpen) waitTimeout()
  }, [ id, isOpen ])

  return (
    <GenericContextPopover
      data-testid="OrgContextPopover"
      context={
        isFailureOrganization
          ? statusOrganization === HttpCode.UNAUTHORIZED || statusOrganization === HttpCode.FORBIDDEN
            ? <NotAuthorized />
            : (
              <Message
                icon={AliceIconType.INFO_CIRCLE}
                title={t('orgContext.errorTitle')}
                description={errorOrganization?.data?.message}
              />
            )
          : responseOrganization
            ? <OrgContext data={responseOrganization} />
            : <LoadingSkeleton />
      }
      placement={placement}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      {children}
    </GenericContextPopover>
  )
}

OrgContextPopover.propTypes = {
  /** organisationId */
  id: PropTypes.string.isRequired,
  /**
   * organisationId
   *
   * @deprecated Do not use! Use `id` prop instead!
   */
  organizationId: PropTypes.string,
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ]),
  /** The component that should be hover-able */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
