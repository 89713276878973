import { AccordionDetails as MuiAccordionDetails } from '@mui/material'
import { forwardRef } from 'react'

export const AccordionDetails = forwardRef((
  {
    sx = [],
    children,
    ...otherProps
  },
  ref
) => (
  <MuiAccordionDetails
    data-testid="AccordionDetails"
    ref={ref}
    sx={sx}
    {...otherProps}
  >
    {children}
  </MuiAccordionDetails>
))
