import React, { useState, useEffect } from "react";
import { getUUID } from "@utils";
import { FilterData } from "../../util";
import { InputControl } from "@utils/inputControls";
import {
  FilterHeadTypography,
  FilterChip,
  FilterChipContainer,
} from "../../styledComponents";

const Filter = ({
  actionHandler,
  isAdmin,
  translate,
  windowSize,
  taskFilter,
}) => {
  const [seletedFilter, setSelectedFilter] = useState(
    taskFilter && taskFilter.length ? taskFilter : "PENDING"
  );

  const { isMobile, isTablet, isDesktop } = windowSize;

  useEffect(() => {
    actionHandler(seletedFilter);
  }, [seletedFilter, actionHandler]);

  useEffect(() => {
    setSelectedFilter(taskFilter && taskFilter.length ? taskFilter : "PENDING");
  }, [isAdmin, taskFilter]);

  const handleFilterSelection = (filterValue) => {
    setSelectedFilter(filterValue);
  };

  const filterOptions = FilterData.map((task) => ({
    label: translate(task.label),
    value: task.value,
  }));

  return (
    <>
      {isDesktop ? (
        <>
          <FilterHeadTypography variant="caption" display="block">
            {translate("filter-by-status")}:
          </FilterHeadTypography>
          <FilterChipContainer>
            {FilterData.map((item) =>
              (item.isAdmin && isAdmin) || !item.isAdmin ? (
                <FilterChip
                  key={getUUID()}
                  variant="outlined"
                  icon={item.icon}
                  label={translate(item.label)}
                  onClick={() => handleFilterSelection(item.value)}
                  selected={item.value === seletedFilter}
                />
              ) : null
            )}
          </FilterChipContainer>
        </>
      ) : null}
      {isTablet || isMobile ? (
        <InputControl
          type="select"
          name="filter-by-status"
          labelName={translate("filter-by-status")}
          options={filterOptions}
          value={seletedFilter}
          onChange={(e) => handleFilterSelection(e?.target?.value)}
        />
      ) : null}
    </>
  );
};

export default Filter;
