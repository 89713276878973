import { memo, useState } from "react";
import { InputControl } from "@utils/inputControls";
import ArrowIconSelect from "@Icons/ArowIconSelect.svg";
import {
  SortContainer,
  MenuItem,
  Select,
  FormControl,
  Typography,
  SortMobContainer,
} from "../styledComponent";

const SortComponent = memo(
  ({
    sortOptions,
    label,
    handleSortKey,
    sortKey,
    windowSize = { isMobile: false, isTablet: false, isDesktop: true },
  }) => {
    const { isMobile = false, isTablet = false, isDesktop = true } = windowSize;

    return (
      <>
        {isDesktop && (
          <SortContainer id="sort">
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.60)" }}
              variant="caption"
              display="block"
            >
              {label}
            </Typography>
            <FormControl variant="standard">
              <Select
                labelId=""
                IconComponent={ArrowIconSelect}
                id="sort-select"
                onChange={(e) => handleSortKey(e.target.value)}
                defaultValue="1"
                value={sortKey}
              >
                {sortOptions.map((options, i) => (
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "rgba(0, 120, 214, 0.16)",
                      },
                    }}
                    value={options.value}
                    key={i}
                  >
                    {options.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </SortContainer>
        )}
        {(isTablet || isMobile) && (
          <SortMobContainer>
            <InputControl
              type="select"
              name="sort-by"
              labelName={label}
              options={sortOptions}
              value={sortKey}
              onChange={(e) => handleSortKey(e.target.value)}
            />
          </SortMobContainer>
        )}
      </>
    );
  }
);

export default SortComponent;
