import { PREF_LANGUAGE_FULL } from "@constants/fullListDropdown";
import { CountryFlag } from "@alice/component_library";

export const getDefaultValues = (user = {}) => ({
  firstName: user.givenname,
  lastName: user.surname,
  localFName: user.nationalLanguageGivenname,
  localLName: user.nationalLanguageSurname,
  phone: user.telephoneNumber,
  mobile: user.mobileNumber,
  fax: user.faxNumber,
  email: user.mailAddress,
  currentWorkPlace: user.workingOrgId,
  PreferredLang: user.preferredLanguage,
});

export const adminRoleMapping = (key) => {
  const keys = {
    ORGANIZATION_ROLE_ASSIGNER: "organizationRoleAssigner",
    ROLE_CREATOR: "roleCreator",
    ROLE_OWNER: "roleOwner",
    GLOBAL_ROLE_ASSIGNER: "globalRoleAssigner",
    ROLE_APPROVER: "roleApprover",
    APPADMIN: "applicationAdmin",
    ORGADMIN: "orgAdmin",
    MARKETADMIN: "marketAdmin",
    MARKET_CBP_ADMIN: "",
    MARKET_HELPDESK_ADMIN: "marketHelpdeskAdmin",
    MARKET_IDENTSTATUS_ADMIN: "identStatusAdmin",
    MARKET_ROLE_ASSIGNER: "marketRoleAssigner",
    CENTRAL_HELPDESK_ADMIN: "centralHelpdeskAdmin",
    SHARED_SERVICE_ADMIN: "sharedServiceAdmin",
    APP_HELPDESK_ADMIN: "applicationHelpdeskAdmin",
    GROUP_ADMIN: "",
    CENTRAL_CBP_ADMIN: "",
    GLOBAL_COMMUNITY_ADMIN: "globalCommunityAdmin",
  };

  return keys[key];
};

export const getProfileEditFields = (t) => {
  return [
    {
      name: "firstName",
      label: t("first-name"),
      type: "text",
      editKey: "name",
    },
    {
      name: "lastName",
      label: t("last-name"),
      type: "text",
      editKey: "name",
    },
    {
      name: "phone",
      label: t("phone"),
      type: "text",
      editKey: "tel",

      validations: {
        required: true,
        maxLength: {
          value: 30,
          message: t("profile-info.length-validation-message"),
        },
      },
      extraValidation: /^\+?[ \d]*$/,
      errorMessage: t("profile-info.phone-validation-message"),
    },
    {
      name: "localFName",
      label: `${t("local-first-name")} (${t("optional")})`,
      type: "text",
      editKey: "local",
      validations: {},
    },
    {
      name: "localLName",
      label: `${t("local-last-name")} (${t("optional")})`,
      type: "text",
      editKey: "local",
      validations: {},
    },
    {
      name: "mobile",
      label: `${t("mobile")} (${t("optional")})`,
      type: "text",
      editKey: "mobile",
      validations: {
        maxLength: {
          value: 30,
          message: t("profile-info.length-validation-message-mob"),
        },
      },
      extraValidation: /^\+?[ \d]*$/,
      errorMessage: t("profile-info.mobile-validation-message"),
    },

    {
      name: "fax",
      label: `${t("fax")} (${t("optional")})`,
      type: "text",
      editKey: "fax",
    },
    {
      name: "PreferredLang",
      label: t("profile-info.preferred-language"),
      type: "select",
      editKey: "lang",
      options: PREF_LANGUAGE_FULL,
      validations: { required: true },
    },
    {
      name: "email",
      label: `${t("email")}`,
      type: "text",
      editKey: "mail",
      validations: {
        required: true,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
          message: t("profile-info.email-validation-message"),
        },
      },
      errorMessage: t("profile-info.email-validation-message"),
    },
  ];
};

export const getProfileViewFields = (user, org = []) => {
  const {
    nationalLanguageGivenname = "",
    mobileNumber,
    telephoneNumber,
  } = user;
  const { nationalLanguageSurname = "", surname, mailAddress } = user;
  const { givenname, faxNumber, preferredLanguage } = user;
  const getPreferedLanguageLabel =
    PREF_LANGUAGE_FULL.find((d) => d.value === preferredLanguage)?.label ?? "";

  return [
    { label: "name", value: `${surname} ${givenname}`.trim() },
    {
      label: "local-name",
      value: `${nationalLanguageGivenname} ${nationalLanguageSurname}`.trim(),
    },
    { label: "mobile", value: mobileNumber },

    { label: "phone", value: telephoneNumber },
    { label: "fax", value: faxNumber },
    {
      label: "profile-info.preferred-language",
      value: getPreferedLanguageLabel ?? "",
      jsxComponent: getCountryFlag(preferredLanguage),
    },

    { label: "email", value: mailAddress },
  ];
};

export const otherOrgsTableHeader = (t, isMobile) => {
  let columns = [
    {
      Header: t("organization"),
      key: "name",
    },
  ];

  if (!isMobile) {
    columns = columns.concat([
      {
        Header: t("home-org-info.community"),
        key: "community",
        style: { width: "20%" },
      },
      {
        Header: t("group-affiliation"),
        key: "affiliation",
        style: { width: "20%" },
      },
      {
        Header: "",
        key: "logo",
        style: { width: "10%" },
      },
    ]);
  }
  return columns;
};

export const getTabsConfig = (userData = {}, adminRoles) => {
  const { communities = [] } = userData;

  const showColleagues = communities.some((i) =>
    ["DEALER_EMPLOYEE", "SUPPLIER_EMPLOYEE"].includes(i)
  );

  const tabs = [
    {
      label: "overview",
      key: "overview",
      value: 0,
      show: true,
    },
    {
      label: "organizations",
      key: "organizations",
      value: 1,
      show: true,
    },
    {
      label: "administrators",
      key: "administrators",
      value: 2,
      show: true,
    },

    {
      label: "colleagues",
      key: "colleagues",
      value: 3,
      show: showColleagues,
    },

    {
      label: "roles",
      key: "roles",
      value: 4,
      show: true,
    },
    {
      label: "applications",
      key: "applications",
      value: 5,
      show: true,
    },
    {
      label: "admin-roles",
      key: "adminRoles",
      value: 6,
      show: adminRoles,
    },
    {
      label: "Accounts",
      key: "accounts",
      value: 7,
      show: true,
    },
  ];
  return tabs.filter((d) => d.show);
};

export const getAdminRolesConfig = (data, t) => {
  const keyLabel = {
    id: { Header: t("id"), key: "id" },
    name: { Header: t("name"), key: "name" },
    appId: { Header: t("app-id"), key: "id" },
    appName: { Header: t("app-name"), key: "name" },
    orgId: { Header: t("org-id"), key: "id" },
    orgName: { Header: t("org-name"), key: "name" },
    market: { Header: t("market"), key: "marketName" },
    community: { Header: t("orgScopeHeader.community"), key: "community" },
    description: { Header: t("description"), key: "description" },
    roleId: { Header: t("role-id"), key: "roleId" },
    roleName: { Header: t("role-name"), key: "roleName" },
    country: {
      Header: t("country"),
      key: "marketCountryCode",
      style: { width: "120px" },
    },
  };

  let temp = {};
  switch (data.adminRole) {
    case "ORGANIZATION_ROLE_ASSIGNER":
    case "ROLE_CREATOR":
    case "ROLE_OWNER":
    case "GLOBAL_ROLE_ASSIGNER":
    case "ROLE_APPROVER":
      temp = { administratedRoles: ["roleId", "roleName"] };
      break;

    case "APPADMIN":
      temp = { administratedApplications: ["appId", "appName"] };
      break;

    case "ORGADMIN":
      temp = { administratedOrgs: ["orgId", "orgName"] };
      break;

    case "MARKETADMIN":
    case "MARKET_CBP_ADMIN":
    case "MARKET_HELPDESK_ADMIN":
      temp = {
        administratedMarkets: ["market", "community", "description", "country"],
      };
      break;

    case "MARKET_IDENTSTATUS_ADMIN":
      temp = { administratedMarkets: ["market", "community", "country"] };
      break;

    case "MARKET_ROLE_ASSIGNER":
      temp = { administratedRoles: ["roleId", "roleName", "market"] };
      break;

    case "CENTRAL_HELPDESK_ADMIN":
    case "SHARED_SERVICE_ADMIN":
    case "APP_HELPDESK_ADMIN":
    case "GROUP_ADMIN":
    case "CENTRAL_CBP_ADMIN":
    case "GLOBAL_COMMUNITY_ADMIN": //TODO VARIFY WITH ACTUAL,  API NOT READY
      temp = {
        administratedRoles: ["market", "roleId", "roleName", "country"],
        administratedMarkets: ["market", "community", "description", "country"],
        administratedOrgs: ["orgId", "orgName"],
        administratedApplications: ["appId", "appName"],
      };
      break;

    default:
      temp = {};
      break;
  }

  const config = [];

  Object.keys(temp).forEach((e) => {
    if (data[e]) {
      const options = { headers: [], keys: [], key: e };
      temp[e].forEach((k, i) => {
        options.headers.push(keyLabel[k]);
        options.keys.push(keyLabel[k]?.key);
      });
      config.push(options);
    }
  });

  return config;
};

export const getCountryFlag = (languageCode) => {
  const countryFlagStyle = { marginRight: "14px" };
  switch (languageCode) {
    case "af":
      return <CountryFlag sx={countryFlagStyle} countryCode="ZA" />;
    case "sq":
      return <CountryFlag sx={countryFlagStyle} countryCode="AL" />;
    case "am":
      return <CountryFlag sx={countryFlagStyle} countryCode="ET" />;
    case "ar":
      return <CountryFlag sx={countryFlagStyle} countryCode="SA" />;
    case "hy":
      return <CountryFlag sx={countryFlagStyle} countryCode="AM" />;
    case "az":
      return <CountryFlag sx={countryFlagStyle} countryCode="AZ" />;
    case "eu":
      return <CountryFlag sx={countryFlagStyle} countryCode="ES" />;
    case "be":
      return <CountryFlag sx={countryFlagStyle} countryCode="BY" />;
    case "bn":
      return <CountryFlag sx={countryFlagStyle} countryCode="BD" />;
    case "bs":
      return <CountryFlag sx={countryFlagStyle} countryCode="BA" />;
    case "bg":
      return <CountryFlag sx={countryFlagStyle} countryCode="BG" />;
    case "ca":
      return <CountryFlag sx={countryFlagStyle} countryCode="AD" />;
    case "ceb":
      return <CountryFlag sx={countryFlagStyle} countryCode="PH" />;
    case "zh":
      return <CountryFlag sx={countryFlagStyle} countryCode="CN" />;
    case "zh-TW":
      return <CountryFlag sx={countryFlagStyle} countryCode="TW" />;
    case "co":
      return <CountryFlag sx={countryFlagStyle} countryCode="FR" />;
    case "hr":
      return <CountryFlag sx={countryFlagStyle} countryCode="HR" />;
    case "cs":
      return <CountryFlag sx={countryFlagStyle} countryCode="CZ" />;
    case "da":
      return <CountryFlag sx={countryFlagStyle} countryCode="DK" />;
    case "nl":
      return <CountryFlag sx={countryFlagStyle} countryCode="NL" />;
    case "en":
      return <CountryFlag sx={countryFlagStyle} countryCode="GB" />;
    case "eo":
      return <CountryFlag sx={countryFlagStyle} countryCode="EO" />;
    case "et":
      return <CountryFlag sx={countryFlagStyle} countryCode="EE" />;
    case "fil":
      return <CountryFlag sx={countryFlagStyle} countryCode="PH" />;
    case "fi":
      return <CountryFlag sx={countryFlagStyle} countryCode="FI" />;
    case "fr":
      return <CountryFlag sx={countryFlagStyle} countryCode="FR" />;
    case "fy":
      return <CountryFlag sx={countryFlagStyle} countryCode="NL" />;
    case "gl":
      return <CountryFlag sx={countryFlagStyle} countryCode="ES" />;
    case "ka":
      return <CountryFlag sx={countryFlagStyle} countryCode="GE" />;
    case "de":
      return <CountryFlag sx={countryFlagStyle} countryCode="DE" />;
    case "el":
      return <CountryFlag sx={countryFlagStyle} countryCode="GR" />;
    case "gu":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "ht":
      return <CountryFlag sx={countryFlagStyle} countryCode="HT" />;
    case "ha":
      return <CountryFlag sx={countryFlagStyle} countryCode="NG" />;
    case "haw":
      return <CountryFlag sx={countryFlagStyle} countryCode="US" />;
    case "he":
      return <CountryFlag sx={countryFlagStyle} countryCode="IL" />;
    case "hi":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "hmn":
      return <CountryFlag sx={countryFlagStyle} countryCode="LA" />;
    case "hu":
      return <CountryFlag sx={countryFlagStyle} countryCode="HU" />;
    case "is":
      return <CountryFlag sx={countryFlagStyle} countryCode="IS" />;
    case "ig":
      return <CountryFlag sx={countryFlagStyle} countryCode="NG" />;
    case "id":
      return <CountryFlag sx={countryFlagStyle} countryCode="ID" />;
    case "ga":
      return <CountryFlag sx={countryFlagStyle} countryCode="IE" />;
    case "it":
      return <CountryFlag sx={countryFlagStyle} countryCode="IT" />;
    case "ja":
      return <CountryFlag sx={countryFlagStyle} countryCode="JP" />;
    case "jv":
      return <CountryFlag sx={countryFlagStyle} countryCode="ID" />;
    case "kn":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "kk":
      return <CountryFlag sx={countryFlagStyle} countryCode="KZ" />;
    case "km":
      return <CountryFlag sx={countryFlagStyle} countryCode="KH" />;
    case "rw":
      return <CountryFlag sx={countryFlagStyle} countryCode="RW" />;
    case "ko":
      return <CountryFlag sx={countryFlagStyle} countryCode="KR" />;
    case "ku":
      return <CountryFlag sx={countryFlagStyle} countryCode="IQ" />;
    case "ky":
      return <CountryFlag sx={countryFlagStyle} countryCode="KG" />;
    case "lo":
      return <CountryFlag sx={countryFlagStyle} countryCode="LA" />;
    case "la":
      return <CountryFlag sx={countryFlagStyle} countryCode="VA" />;
    case "lv":
      return <CountryFlag sx={countryFlagStyle} countryCode="LV" />;
    case "lt":
      return <CountryFlag sx={countryFlagStyle} countryCode="LT" />;
    case "lb":
      return <CountryFlag sx={countryFlagStyle} countryCode="LU" />;
    case "mk":
      return <CountryFlag sx={countryFlagStyle} countryCode="MK" />;
    case "mg":
      return <CountryFlag sx={countryFlagStyle} countryCode="MG" />;
    case "ms":
      return <CountryFlag sx={countryFlagStyle} countryCode="MY" />;
    case "ml":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "mt":
      return <CountryFlag sx={countryFlagStyle} countryCode="MT" />;
    case "mi":
      return <CountryFlag sx={countryFlagStyle} countryCode="NZ" />;
    case "mr":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "mn":
      return <CountryFlag sx={countryFlagStyle} countryCode="MN" />;
    case "my":
      return <CountryFlag sx={countryFlagStyle} countryCode="MM" />;
    case "ne":
      return <CountryFlag sx={countryFlagStyle} countryCode="NP" />;
    case "no":
      return <CountryFlag sx={countryFlagStyle} countryCode="NO" />;
    case "ny":
      return <CountryFlag sx={countryFlagStyle} countryCode="MW" />;
    case "or":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "ps":
      return <CountryFlag sx={countryFlagStyle} countryCode="AF" />;
    case "fa":
      return <CountryFlag sx={countryFlagStyle} countryCode="IR" />;
    case "pl":
      return <CountryFlag sx={countryFlagStyle} countryCode="PL" />;
    case "pt":
      return <CountryFlag sx={countryFlagStyle} countryCode="PT" />;
    case "pa":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "ro":
      return <CountryFlag sx={countryFlagStyle} countryCode="RO" />;
    case "ru":
      return <CountryFlag sx={countryFlagStyle} countryCode="RU" />;
    case "sm":
      return <CountryFlag sx={countryFlagStyle} countryCode="WS" />;
    case "gd":
      return <CountryFlag sx={countryFlagStyle} countryCode="GB" />;
    case "sr":
      return <CountryFlag sx={countryFlagStyle} countryCode="RS" />;
    case "st":
      return <CountryFlag sx={countryFlagStyle} countryCode="LS" />;
    case "sn":
      return <CountryFlag sx={countryFlagStyle} countryCode="ZW" />;
    case "sd":
      return <CountryFlag sx={countryFlagStyle} countryCode="PK" />;
    case "si":
      return <CountryFlag sx={countryFlagStyle} countryCode="LK" />;
    case "sk":
      return <CountryFlag sx={countryFlagStyle} countryCode="SK" />;
    case "sl":
      return <CountryFlag sx={countryFlagStyle} countryCode="SI" />;
    case "so":
      return <CountryFlag sx={countryFlagStyle} countryCode="SO" />;
    case "es":
      return <CountryFlag sx={countryFlagStyle} countryCode="ES" />;
    case "su":
      return <CountryFlag sx={countryFlagStyle} countryCode="ID" />;
    case "sw":
      return <CountryFlag sx={countryFlagStyle} countryCode="TZ" />;
    case "sv":
      return <CountryFlag sx={countryFlagStyle} countryCode="SE" />;
    case "tg":
      return <CountryFlag sx={countryFlagStyle} countryCode="TJ" />;
    case "ta":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "tt":
      return <CountryFlag sx={countryFlagStyle} countryCode="RU" />;
    case "te":
      return <CountryFlag sx={countryFlagStyle} countryCode="IN" />;
    case "th":
      return <CountryFlag sx={countryFlagStyle} countryCode="TH" />;
    case "tr":
      return <CountryFlag sx={countryFlagStyle} countryCode="TR" />;
    case "tk":
      return <CountryFlag sx={countryFlagStyle} countryCode="TM" />;
    case "uk":
      return <CountryFlag sx={countryFlagStyle} countryCode="UA" />;
    case "ur":
      return <CountryFlag sx={countryFlagStyle} countryCode="PK" />;
    case "ug":
      return <CountryFlag sx={countryFlagStyle} countryCode="CN" />;
    case "uz":
      return <CountryFlag sx={countryFlagStyle} countryCode="UZ" />;
    case "vi":
      return <CountryFlag sx={countryFlagStyle} countryCode="VN" />;
    case "cy":
      return <CountryFlag sx={countryFlagStyle} countryCode="CY" />;
    case "xh":
      return <CountryFlag sx={countryFlagStyle} countryCode="ZA" />;
    case "yi":
      return <CountryFlag sx={countryFlagStyle} countryCode="IL" />;
    case "yo":
      return <CountryFlag sx={countryFlagStyle} countryCode="NG" />;
    case "zu":
      return <CountryFlag sx={countryFlagStyle} countryCode="ZA" />;
    default:
      return null; // Return null for unsupported language codes
  }
};

export const isValidParam = (value, showColleagues) => {
  const menuArrayList = [
    "myProfile",
    "myOrganizations",
    "myRoles",
    "myApplications",
    "myAdministartors",
    "myAdmininstratorRoles",
    "myColleagues",
    "myAccounts",
  ];
  if (value && value.trim().length > 0) {
    if (
      showColleagues
        ? menuArrayList.includes(value.trim())
        : menuArrayList
            .filter((item) => item !== "myColleagues")
            .includes(value.trim())
    ) {
      return true; // Exact word match found
    }
  }
  return false;
};

export const isValidTabName = (tabName, showColleagues) => {
  const menuArrayList = [
    { id: "overview", value: "myProfile" },
    { id: "organizations", value: "myOrganizations" },
    { id: "roles", value: "myRoles" },
    { id: "applications", value: "myApplications" },
    { id: "administrators", value: "myAdministartors" },
    { id: "adminRoles", value: "myAdmininstratorRoles" },
    { id: "colleagues", value: "myColleagues" },
    { id: "accounts", value: "myAccounts" },
  ];
  if (tabName && tabName.trim().length > 0) {
    if (!showColleagues && tabName.trim() === "colleagues") {
      return "myProfile"; // Return myProfile if showColleagues is false and tabName is colleagues
    }
    const foundItem = menuArrayList.find((item) => item.id === tabName.trim());
    if (foundItem) {
      return foundItem.value; // Return the value if id is equal to tabName
    }
  }
  return "myProfile"; // Return default value if no match found
};
