import { isValid } from 'date-fns'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button'
import { DatePicker } from '../datePicker'
import { Dialog } from '../dialog'
import { DatePickerDialogHeader } from './DatePickerDialogHeader'
import { RangeInput } from './RangeInput'

/**
 * This is the base example of DatePickerDialog.
 *
 * To set DatePicker to receive range values add prop `isRange={ true }`
 *
 * Docu for the 'disabledMatcher'
 * https://react-day-picker.js.org/api/types/Matcher
 */
export const DatePickerDialog = forwardRef((
  {
    open,
    onClose,
    onChange,
    onConfirm,
    isRange = false,
    parentFrom = undefined,
    parentTo = undefined,
    disabledMatcher
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const [ dates, setDates ] = useState(null)
  const [ isEditOpen, setIsEditOpen ] = useState(false)

  const { from: dateFrom = '', to: dateTo = '' } = dates || {}

  const validatedFrom = isValid(dateFrom) ? dateFrom : undefined
  const validatedTo = isValid(dateTo) ? dateTo : undefined

  const validatedParentFrom = isValid(parentFrom) ? parentFrom : undefined
  const validatedParentTo = isValid(parentTo) ? parentTo : undefined

  const isButtonDisabled = useMemo(() => (
    isRange
      ? (!!validatedFrom) && (!!validatedTo)
      : !!validatedFrom
  ), [ isRange, validatedFrom, validatedTo ])

  useEffect(() => {
    if (dates && open) onChange(dates)
  }, [ dates, onChange, open ])

  const handleOnClose = useCallback(() => {
    setDates(null)
    setIsEditOpen(false)

    onClose(null)
  }, [ onClose ])

  const handleOnConfirm = useCallback((e) => {
    e.preventDefault()

    onConfirm(dates || { from: parentFrom, to: parentTo })

    setIsEditOpen(false)
    onClose(dates)

    setDates(null)
  }, [ dates, onClose, onConfirm, parentFrom, parentTo ])

  return (
    <Dialog
      data-testid="DatePickerDialog"
      ref={ref}
      open={open}
      onClose={handleOnClose}
      hasCloseButton={false}
      noScroll
      sx={({ palette }) => ({
        '.MuiPaper-root': { padding: 0 },
        '.DialogTitleWrapper': { borderBottom: `1px solid ${palette.divider}` },
        '.MuiDialogTitle-root': { padding: '16px 12px 12px 24px' },
        '.DialogContent': {
          padding: 0,
          margin: 0,
          minHeight: '392px',
          width: '360px'
        },
        '.DialogActions': { padding: '8px 12px 12px' }
      })}
      headerSection={(
        <DatePickerDialogHeader
          parentFrom={validatedFrom || validatedParentFrom}
          parentTo={validatedTo || validatedParentTo}
          isEditOpen={isEditOpen}
          setIsEditOpen={setIsEditOpen}
          isRange={isRange}
        />
      )}
      footerSection={(
        <>
          <Button
            data-testid="DatePickerDialog-cancel"
            variant="tertiary"
            size="small"
            onClick={handleOnClose}
          >
            {t('general.cancel')}
          </Button>

          <Button
            data-testid="DatePickerDialog-confirm"
            variant="tertiary"
            disabled={!isButtonDisabled}
            form="rangeInput"
            type="submit"
            size="small"
            onClick={handleOnConfirm}
          >
            {t('general.ok')}
          </Button>
        </>
      )}
    >
      {!isEditOpen ? (
        <DatePicker
          onChange={setDates}
          parentFrom={validatedFrom || validatedParentFrom}
          parentTo={validatedTo || validatedParentTo}
          isRange={isRange}
          disabledMatcher={disabledMatcher}
        />
      ) : (
        <RangeInput
          onChange={setDates}
          parentFrom={validatedFrom || validatedParentFrom}
          parentTo={validatedTo || validatedParentTo}
          isRange={isRange}
        />
      )}
    </Dialog>
  )
})

DatePickerDialog.propTypes = {
  /** determines current state of dialog popup */
  open: PropTypes.bool.isRequired,
  /** determines mode of datePicker 'range' or 'single' */
  isRange: PropTypes.bool,
  /**
   * onClose Event
   *
   * @returns {void}
   */
  onClose: PropTypes.func.isRequired,
  /**
   * onChange event with state updater func inside
   *
   * @param dates
   * @returns {void}
   */
  onChange: PropTypes.func.isRequired,
  /**
   * onConfirm event
   *
   * @param dates
   * @returns {void}
   */
  onConfirm: PropTypes.func.isRequired,
  /** determines start date for manual handling */
  parentFrom: PropTypes.instanceOf(Date),
  /** determines end date for manual handling */
  parentTo: PropTypes.instanceOf(Date),
  /**
   * A value or a function that matches a day or range
   * https://react-day-picker.js.org/api/types/Matcher
   */
  disabledMatcher: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
    PropTypes.array
  ])
}
