import { openTaskActionTypes } from "../constants/actionTypes";
import { apiBaseUrl, oConf } from "../services/baseAPIService";
import { errorInfo, forceReload } from "./commonAction";
import {
  getTaskUrl,
  getWorkFlowUrl,
  convertWorkFlow,
  getTaskAndWFFilter,
  generateStateString,
} from "../pages/util";
import Communication from "../services/Communication";
import { isWorkFlowEnabled } from "../config";
import { endPoint } from "../constants/endPointTypes";
import urlConfig from "@src/services/urlConfig";
import { hasData } from "@src/utils/Validator";
import { insertSorted } from "@src/utils/arrayUtils";
import instance from "@src/services/axiosInstance";
import { splitWord } from "@src/utils";

// import { mockTasks } from "./mock";

const actions = {
  storeTaskList: (payload) => ({
    type: openTaskActionTypes.STORE_TASK_LIST,
    data: payload,
  }),
  StoreSelectedTaskDetail: (payload) => ({
    type: openTaskActionTypes.STORE_SELECTED_TASK_DETAIL,
    data: payload,
  }),
  StoreSelectedRow: (payload) => ({
    type: openTaskActionTypes.STORE_SELECTED_ROW,
    data: payload,
  }),
  FetchSupervisorData: (payload) => ({
    type: openTaskActionTypes.FETCH_SUPERVISOR_DATA,
    data: payload,
  }),
  StoreSupervisorData: (payload) => ({
    type: openTaskActionTypes.STORE_SUPERVISOR_DATA,
    data: payload,
  }),
  clearData: () => ({
    type: openTaskActionTypes.CLEAR_APPROVAL_DATA,
  }),
  updateNotification: (payload) => ({
    type: openTaskActionTypes.UPDATE_TASK_NOTIFICATION,
    data: payload,
  }),
  storeRejectReason: (payload) => ({
    type: openTaskActionTypes.STORE_REJECT_REASON,
    data: payload,
  }),
  storeOpenTaskCount: (payload) => ({
    type: openTaskActionTypes.STORE_TASK_COUNT,
    data: payload,
  }),
  storeOpenTaskInfo: (payload) => ({
    type: openTaskActionTypes.STORE_TASK_INFO,
    data: payload,
  }),
  storeDeeplinkInfo: (payload) => ({
    type: openTaskActionTypes.STORE_TASK_DEEPLINK,
    data: payload,
  }),
  setCustomizedSnackBar: (payload) => ({
    type: openTaskActionTypes.SET_CUSTOMIZED_SNACKBAR,
    data: payload,
  }),
  saveBreadCrumbInfo: (payload) => ({
    type: openTaskActionTypes.SAVE_BREADCRUMB_DATA,
    data: payload,
  }),
  saveApplicationdata: (payload) => ({
    type: openTaskActionTypes.SAVE_APPLICATION_DATA,
    data: payload,
  }),
  updateCount: (payload) => ({
    type: openTaskActionTypes.UPDATE_COUNT,
    data: payload,
  }),

  storeTaskInfoDeeplink: (payload) => ({
    type: openTaskActionTypes.UPDATE_DEEPLINK_COUNT_INFO,
    data: payload,
  }),

  storeTaskCountDeeplink: (payload) => ({
    type: openTaskActionTypes.UPDATE_DEEPLINK_COUNT_UPDATE,
    data: payload,
  }),
  storeLatestActivity: (payload) => ({
    type: openTaskActionTypes.STORE_LATEST_TASK_ACTIVITY,
    data: payload,
  }),
};

export const StoreSelectedTaskDetail =
  (data, isInternal) => async (dispatch) => {
    if (data && Object.keys(data).length > 0 && isInternal) {
      var updatedInfo = Object.assign({}, data);

      dispatch(actions.StoreSelectedTaskDetail(updatedInfo));
    } else {
      dispatch(actions.StoreSelectedTaskDetail(data));
    }
  };
export const ClearSelectedTaskDetail = () => (dispatch) => {
  dispatch(actions.clearData());
};

export const FetchTaskAndWorkFlow = (
  user,
  state,
  search = "",
  isAdmin = false,
  offset = 0,
  limit = 50,
  append = false,
  taskList = [],
  maxData = 0
) => {
  return function (dispatch) {
    dispatch(actions.storeTaskList({ data: [], loading: true }));
    const sort = "-";
    const taskUrl = getTaskUrl(
      user,
      state,
      search,
      sort,
      isAdmin,
      offset,
      limit
    );
    const workFlowUrl = getWorkFlowUrl(
      state,
      search,
      user,
      true,
      isAdmin,
      offset,
      limit
    );

    let hasActiveRequest = Communication.activeRequests.some(
      (request) => request.endpoint === endPoint.TASK
    );
    if (hasActiveRequest) {
      Communication.cancel(endPoint.TASK);
    }
    const requests = [Communication.get(taskUrl, endPoint.TASK)];
    if (isWorkFlowEnabled) {
      hasActiveRequest = Communication.activeRequests.some(
        (request) => request.endpoint === endPoint.WORKFLOW
      );

      if (hasActiveRequest) {
        Communication.cancel(endPoint.WORKFLOW);
      }
      requests.push(Communication.get(workFlowUrl, endPoint.WORKFLOW));
    }

    Communication.all(requests)
      .then((r) => {
        const data = r.map((d) => d.data);
        const [tasksResp = {}] = data;
        let { tasks = [], rels = { totalCount: 0 } } = tasksResp;
        let result = tasks;
        let { totalCount: taskCount } = rels;
        let workflowCount = 0;

        if (isWorkFlowEnabled) {
          const [, workflowsResp = {}] = data;
          const { workflows = [], rels } = workflowsResp;
          workflowCount = rels.totalCount;
          const WFTaskProcessed = convertWorkFlow(workflows, true, user);

          if (maxData) {
            const taskCount = tasks.length;
            const wFlowCount = workflows.length;
            let maxItemCount = maxData / 2;
            if (taskCount + wFlowCount !== maxData) {
              const pendingTask = maxItemCount - taskCount;
              const pendingWF = maxItemCount - wFlowCount;
              let taskLimit = maxItemCount;
              let wfLimit = maxItemCount;
              if (pendingWF > 0) {
                taskLimit += pendingWF;
              }
              if (pendingTask > 0) {
                wfLimit += pendingTask;
              }
              result = tasks
                .slice(0, taskLimit)
                .concat(WFTaskProcessed.slice(0, wfLimit));
            } else if (taskCount + wFlowCount === maxData) {
              const pendingTask = maxItemCount - taskCount;
              const pendingWF = maxItemCount - wFlowCount;
              let taskLimit = maxItemCount;
              let wfLimit = maxItemCount;
              if (pendingWF > 0) {
                taskLimit += pendingWF;
              }
              if (pendingTask > 0) {
                wfLimit += pendingTask;
              }
              result = tasks
                .slice(0, taskLimit)
                .concat(WFTaskProcessed.slice(0, wfLimit));
            }
          } else {
            result = tasks.concat(WFTaskProcessed);
          }
          result = result.sort(
            (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
          );
        }
        let finalResult = result;
        if (append) finalResult = [...taskList, ...result];
        Communication.removeActiveRequest(endPoint.TASK);
        if (isWorkFlowEnabled) {
          Communication.removeActiveRequest(endPoint.WORKFLOW);
        }
        dispatch(
          actions.storeTaskList({
            data: finalResult,
            taskCount: taskCount,
            workflowCount: workflowCount,
            adminMode: isAdmin,
            status: state,
            searchWord: search,
            loading: false,
            isLoaded: true,
          })
        );
        if (state === "PENDING" && !isAdmin) {
          dispatch(
            actions.updateNotification({
              count: workflowCount + taskCount,
              isLoaded: true,
            })
          );
        }
      })
      .catch((e) => {
        Communication.removeActiveRequest(endPoint.TASK);
        Communication.removeActiveRequest(endPoint.WORKFLOW);
        e.code !== "ERR_CANCELED" &&
          dispatch(
            actions.storeTaskList({ data: [], loading: false, error: true })
          );
      });
  };
};

export const submitApprove = (id) => {
  var postData = {};

  let Url = apiBaseUrl + "/gems/tasks/" + id + "/approve";

  return function (dispatch) {
    return instance
      .post(Url, postData, oConf)
      .then((res) => {
        // if(res.status === 204){
        //     dispatch(actions.clearData());
        // }
        return res.status;
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};
export const rejectApprove = (id, reason) => {
  var postData = {
    reason: reason,
  };
  let Url = apiBaseUrl + "/gems/tasks/" + id + "/reject";

  return function (dispatch) {
    return instance
      .post(Url, postData, oConf)
      .then((res) => {
        // if(res.status === 204){
        //     dispatch(actions.clearData());
        // }
        return res.status;
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const StoreSelectedRow = (data) => (dispatch) => {
  dispatch(actions.StoreSelectedRow(data));
};
export function FetchSupervisorData(id) {
  return function (dispatch) {
    return instance
      .get(apiBaseUrl + "/gems/users/" + id, oConf)
      .then((response) => {
        if (response.status === 200) {
          dispatch(actions.FetchSupervisorData(response.data));
          return response.status;
        } else {
          dispatch(actions.FetchSupervisorData());
          return response.status;
        }
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }

        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
}

export const getTaskCounts = (
  isAdmin,
  user,
  filter = "",
  search = "",
  taskListCounts
) => {
  return function (dispatch) {
    const taskURL = isAdmin ? "/alltasks" : "/tasks";
    const workFlowURL = "/workflows";
    const { taskFilter, wfFilter, wfFilterTask } =
      filter !== "All" ? getTaskAndWFFilter(filter) : {};

    const keysForCount = [
      "roleRequestCount",
      "workFlowCount",
      "entitileMentTaskCount",
      "entitileMentWorkflowCount",
      "techUserCount",
      "orgAdminCount",
      "deprovisioningCount",
      "usercreationCount",
      "orgCount",
    ];

    const taskStatus = taskFilter && generateStateString(taskFilter, "state");
    const WFtaskState =
      wfFilterTask && generateStateString(wfFilterTask, "tasks.taskData.state");
    const WFStateTask = wfFilter && generateStateString(wfFilter, "state");

    const urlSearchParamsForTaskURL = new URLSearchParams();
    const filtersForTaskURL = [];

    const urlSearchParamsForWorkFlowURL = new URLSearchParams();
    const urlSearchParamsForEntitleMentWorkFlowURL = new URLSearchParams();
    const filtersForWorkFlowURL = ["workflowData.type==ROLE_APPROVAL_WORKFLOW"];

    const query = search.length ? `'*${search.trim()}*'` : "";
    filtersForTaskURL.push(
      `taskType=in=(APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT,APPROVE_ROLE_ASSIGNMENT)`
    );
    if (!isAdmin) filtersForTaskURL.push(`recipients.id==${user}`);
    if (filter.length > 0 && filter !== "All")
      filtersForTaskURL.push(taskStatus);

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else {
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${query},affectedData.user.surname==${query},originator.id==${query},originator.givenname==${query},originator.surname==${query})`
        );
      }
    }

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(" "));
      }
    }

    urlSearchParamsForTaskURL.set("offset", 0);
    urlSearchParamsForTaskURL.set("limit", 1);
    const roleRequsetTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_APPLICATIONROLE_TO_ROLE)`
    );

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const entitlementRequsetTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_TECHUSER_TO_APPLICATION)`
    );
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const assignTechUserTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_ORG_ADMIN_PERMISSION)`
    );

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const orgAminPermissionTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(0, 1, `taskType=in=(APPROVE_ROLE_DEPROVISIONING)`);
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const deprovisioningTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(0, 1, `taskType=in=(APPROVE_USER_CREATION)`);
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const userCreationTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(
      0,
      1,
      `taskType=in=(APPROVE_USER_TO_ORGANIZATION)`
    );
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }
    const userOrganizationTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    filtersForTaskURL.splice(0, 1);
    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
      }
    }

    if (!isAdmin) filtersForWorkFlowURL.push(`tasks.recipients.id==${user}`);
    if (filter.length > 0 && filter !== "All") {
      const workFlowFilter = `((${WFtaskState});(${WFStateTask}))`;

      filtersForWorkFlowURL.push(workFlowFilter);
    }

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;
        if (isAdmin) {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query},tasks.recipients.givenname==${givenNameQuery},tasks.recipients.surname==${surNameQuery},tasks.recipients.id==${query})`
          );
        } else {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query})`
          );
        }
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        if (isAdmin) {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query},tasks.recipients.givenname==${givenNameQuery},tasks.recipients.surname==${surNameQuery},tasks.recipients.id==${query})`
          );
        } else {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query})`
          );
        }
      } else {
        if (isAdmin) {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${query},workflowData.roleToUser.user.surname==${query},workflowData.requester.id==${query},workflowData.requester.givenname==${query},workflowData.requester.surname==${query},workflowData.requester.surname==${query},tasks.recipients.givenname==${query},tasks.recipients.surname==${query},tasks.recipients.id==${query})`
          );
        } else {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${query},workflowData.roleToUser.user.surname==${query},workflowData.requester.id==${query},workflowData.requester.givenname==${query},workflowData.requester.surname==${query},workflowData.requester.surname==${query})`
          );
        }
      }
    }
    const filterForEntitleWorkFlowUrl = filtersForWorkFlowURL.map((item) =>
      item.replace(
        "workflowData.type==ROLE_APPROVAL_WORKFLOW",
        "workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL"
      )
    );
    if (filtersForWorkFlowURL.length) {
      if (filtersForWorkFlowURL.length > 1) {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join(";")
        );
        urlSearchParamsForEntitleMentWorkFlowURL.set(
          "filter",
          filterForEntitleWorkFlowUrl.join(";")
        );
      } else {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join("")
        );
        urlSearchParamsForEntitleMentWorkFlowURL.set(
          "filter",
          filterForEntitleWorkFlowUrl.join("")
        );
      }
    }

    urlSearchParamsForWorkFlowURL.set("offset", 0);
    urlSearchParamsForWorkFlowURL.set("limit", 0);
    urlSearchParamsForEntitleMentWorkFlowURL.set("offset", 0);
    urlSearchParamsForEntitleMentWorkFlowURL.set("limit", 0);

    const allWorkFlowUrl = `/gems${workFlowURL}?${urlSearchParamsForWorkFlowURL.toString()}`;
    const entitleMentWorkFlowUrl = `/gems${workFlowURL}?${urlSearchParamsForEntitleMentWorkFlowURL.toString()}`;

    const requests = [Communication.get(roleRequsetTaskURL)];
    requests.push(Communication.get(allWorkFlowUrl));
    requests.push(Communication.get(entitleMentWorkFlowUrl));
    requests.push(Communication.get(entitlementRequsetTaskURL));
    requests.push(Communication.get(assignTechUserTaskURL));
    requests.push(Communication.get(orgAminPermissionTaskURL));
    requests.push(Communication.get(deprovisioningTaskURL));
    requests.push(Communication.get(userCreationTaskURL));
    requests.push(Communication.get(userOrganizationTaskURL));

    Communication.all(requests).then((r) => {
      const countObject = {};
      dispatch(
        actions.storeOpenTaskCount({
          data: {
            tottalCount: 0,
            roleRequestCount: 0,
            entitileMentCount: 0,
            techUserCount: 0,
            orgAdminCount: 0,
            deprovisioningCount: 0,
            usercreationCount: 0,
            orgCount: 0,
            dashboardTottalCount: 0,
          },
        })
      );
      r.forEach((d, i) => {
        countObject[keysForCount[i]] = d?.data?.rels?.totalCount;
      });
      const {
        tottalCount,
        entitileMentWorkflowCount,
        entitileMentTaskCount,
        ...rest
      } = countObject;
      const {
        roleRequestCount,
        techUserCount,
        orgAdminCount,
        deprovisioningCount,
        usercreationCount,
        orgCount,
      } = rest;
      const roleRequestTottalCount =
        roleRequestCount +
        entitileMentTaskCount +
        entitileMentWorkflowCount +
        techUserCount +
        orgAdminCount +
        deprovisioningCount +
        usercreationCount +
        orgCount;
      const updatedData = {
        ...rest,
        entitileMentCount: entitileMentTaskCount + entitileMentWorkflowCount,
        roleRequestCount:
          rest.roleRequestCount >= 500
            ? 500 + rest.workFlowCount
            : rest.roleRequestCount + rest.workFlowCount,
        tottalCount:
          roleRequestTottalCount >= 500
            ? 500 + rest.workFlowCount
            : roleRequestTottalCount + rest.workFlowCount,
        dashboardTottalCount:
          isAdmin || filter !== "PENDING" || search.trim().length
            ? taskListCounts?.dashboardTottalCount
            : roleRequestTottalCount >= 500
            ? 500 + rest.workFlowCount
            : roleRequestTottalCount + rest.workFlowCount,
        filter: filter,
      };
      dispatch(actions.storeOpenTaskCount({ data: updatedData }));
    });
  };
};

export const getTaskDetails = (
  isAdmin,
  user,
  type,
  filter = "",
  search = "",
  offset,
  limit,
  taskList,
  workFLowList,
  tottalCount,
  topPage,
  sortValue
) => {
  return async (dispatch) => {
    let countDiffWorkFlow = 0;
    const taskURL = isAdmin ? "/alltasks" : "/tasks";
    const workFlowURL = "/workflows";
    const { taskFilter, wfFilter, wfFilterTask } =
      filter !== "All" ? getTaskAndWFFilter(filter) : {};

    const taskStatus = taskFilter && generateStateString(taskFilter, "state");
    const WFtaskState =
      wfFilterTask && generateStateString(wfFilterTask, "tasks.taskData.state");

    const WFStateTask = wfFilter && generateStateString(wfFilter, "state");

    const urlSearchParamsForTaskURL = new URLSearchParams();
    const filtersForTaskURL = [];

    const urlSearchParamsForWorkFlowURL = new URLSearchParams();
    const filtersForWorkFlowURL = [];

    const query = search.length ? `'*${search.trim()}*'` : "";
    let structureInfo = "allTasks";

    switch (type) {
      case "APPROVE_ROLE_ASSIGNMENT":
        filtersForTaskURL.push(
          `taskType=in=(APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT,APPROVE_ROLE_ASSIGNMENT)`
        );
        filtersForWorkFlowURL.push(
          "(workflowData.type==ROLE_APPROVAL_WORKFLOW)"
        );
        structureInfo = "roleRequest";
        break;
      case "APPROVE_USER_CREATION":
        filtersForTaskURL.push(`taskType=in=(APPROVE_USER_CREATION)`);
        structureInfo = "userCreation";
        break;
      case "APPROVE_USER_TO_ORGANIZATION":
        filtersForTaskURL.push(`taskType=in=(APPROVE_USER_TO_ORGANIZATION)`);
        structureInfo = "userToOrganization";
        break;
      case "APPROVE_ORG_ADMIN_PERMISSION":
        filtersForTaskURL.push(`taskType=in=(APPROVE_ORG_ADMIN_PERMISSION)`);
        structureInfo = "orgAdminPermission";
        break;
      case "APPROVE_ROLE_DEPROVISIONING":
        filtersForTaskURL.push(`taskType=in=(APPROVE_ROLE_DEPROVISIONING)`);
        structureInfo = "deProvisionInfo";
        break;
      case "APPROVE_TECHUSER_TO_APPLICATION":
        filtersForTaskURL.push(`taskType=in=(APPROVE_TECHUSER_TO_APPLICATION)`);
        structureInfo = "techUserToapplication";
        break;
      case "APPROVE_APPLICATIONROLE_TO_ROLE":
        filtersForTaskURL.push(`taskType=in=(APPROVE_APPLICATIONROLE_TO_ROLE)`);
        filtersForWorkFlowURL.push(
          "(workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL)"
        );
        structureInfo = "entitelementToRole";
        break;
      default:
        filtersForWorkFlowURL.push(
          "(workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL,workflowData.type==ROLE_APPROVAL_WORKFLOW)"
        );
        break;
    }

    if (!isAdmin) filtersForTaskURL.push(`recipients.id==${user}`);
    if (filter.length > 0 && filter !== "All")
      filtersForTaskURL.push(taskStatus);

    if (query.length > 0) {
      if (search.includes(",")) {
        const [givenName, surName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else if (search.trim().trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${givenNameQuery},affectedData.user.surname==${surNameQuery},originator.id==${query},originator.givenname==${givenNameQuery},originator.surname==${surNameQuery})`
        );
      } else {
        filtersForTaskURL.push(
          `(id==${query},affectedData.user.id==${query},affectedData.user.givenname==${query},affectedData.user.surname==${query},originator.id==${query},originator.givenname==${query},originator.surname==${query})`
        );
      }
    }

    if (filtersForTaskURL.length) {
      if (filtersForTaskURL.length > 1) {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(";"));
      } else {
        urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(" "));
      }
    }
    const sortFilterValueTsk =
      parseInt(sortValue) === 1 ? "-creationDate" : "+creationDate";
    urlSearchParamsForTaskURL.set("sort", sortFilterValueTsk);
    urlSearchParamsForTaskURL.set("offset", offset.taskOffset);
    urlSearchParamsForTaskURL.set(
      "limit",
      type === "APPROVE_ROLE_ASSIGNMENT" ||
        type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
        type === "allTasks"
        ? limit / 2
        : limit
    );

    const roleRequsetTaskURL = `/gems${taskURL}?${urlSearchParamsForTaskURL.toString()}`;

    if (!isAdmin) filtersForWorkFlowURL.push(`tasks.recipients.id==${user}`);
    if (filter.length > 0 && filter !== "All") {
      const workFlowFilter = `((${WFtaskState});(${WFStateTask}))`;

      filtersForWorkFlowURL.push(workFlowFilter);
    }

    if (query.length > 0) {
      if (search.includes(",")) {
        const [surName, givenName] = search.split(",");
        const surNameQuery = `'*${surName.trim()}*'`;
        const givenNameQuery = `'*${givenName.trim()}*'`;
        if (isAdmin) {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query},tasks.recipients.givenname==${givenNameQuery},tasks.recipients.surname==${surNameQuery},tasks.recipients.id==${query})`
          );
        } else {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query})`
          );
        }
      } else if (search.trim().includes(" ")) {
        const [givenName, surName] = splitWord(search);
        const surNameQuery = `${surName.trim()}`;
        const givenNameQuery = `${givenName.trim()}`;
        if (isAdmin) {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query},tasks.recipients.givenname==${givenNameQuery},tasks.recipients.surname==${surNameQuery},tasks.recipients.id==${query})`
          );
        } else {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${givenNameQuery},workflowData.roleToUser.user.surname==${surNameQuery},workflowData.requester.id==${query},workflowData.requester.givenname==${givenNameQuery},workflowData.requester.surname==${surNameQuery},workflowData.requester.surname==${query})`
          );
        }
      } else {
        if (isAdmin) {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${query},workflowData.roleToUser.user.surname==${query},workflowData.requester.id==${query},workflowData.requester.givenname==${query},workflowData.requester.surname==${query},workflowData.requester.surname==${query},tasks.recipients.givenname==${query},tasks.recipients.surname==${query},tasks.recipients.id==${query})`
          );
        } else {
          filtersForWorkFlowURL.push(
            `(id==${query},workflowData.roleToUser.user.id==${query},workflowData.roleToUser.user.givenname==${query},workflowData.roleToUser.user.surname==${query},workflowData.requester.id==${query},workflowData.requester.givenname==${query},workflowData.requester.surname==${query},workflowData.requester.surname==${query})`
          );
        }
      }
    }

    if (filtersForWorkFlowURL.length) {
      if (filtersForWorkFlowURL.length > 1) {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join(";")
        );
      } else {
        urlSearchParamsForWorkFlowURL.set(
          "filter",
          filtersForWorkFlowURL.join("")
        );
      }
    }
    const sortFilterValueWf =
      parseInt(sortValue) === 1 ? "-startTime" : "+startTime";
    urlSearchParamsForWorkFlowURL.set("sort", sortFilterValueWf);
    urlSearchParamsForWorkFlowURL.set("offset", offset.workFlow);
    urlSearchParamsForWorkFlowURL.set("limit", limit / 2);

    const allWorkFlowUrl = `/gems${workFlowURL}?${urlSearchParamsForWorkFlowURL.toString()}`;

    const requests =
      tottalCount.taskListTottalCount > offset.taskOffset
        ? [Communication.get(roleRequsetTaskURL)]
        : [];

    if (
      (type === "APPROVE_ROLE_ASSIGNMENT" ||
        type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
        type === "allTasks") &&
      tottalCount.workFlowTottalCount > offset.workFlow
    )
      requests.push(Communication.get(allWorkFlowUrl));
    if (requests.length) {
      dispatch(actions.storeOpenTaskInfo({ loading: true }));
      Communication.all(requests)
        .then((r) => {
          const data = r.map((d) => d.data);
          let result = [];
          let taskCount = 0;
          if (tottalCount.taskListTottalCount > offset.taskOffset) {
            const [tasksResp = {}] = data;
            let { tasks = [], rels = { totalCount: 0 } } = tasksResp;
            taskCount = rels?.totalCount;
            result = tasks;
          }
          if (
            (type === "APPROVE_ROLE_ASSIGNMENT" ||
              type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
              type === "allTasks") &&
            tottalCount.workFlowTottalCount > offset.workFlow
          ) {
            let workflowsResp = {};
            if (tottalCount.taskListTottalCount > offset.taskOffset) {
              [, workflowsResp = {}] = data;
            } else {
              [workflowsResp = {}] = data;
            }
            const { workflows = [], rels } = workflowsResp;
            dispatch(
              actions.storeOpenTaskInfo({
                data: [...workflows, ...workFLowList],
                isLoaded: true,
                paginationInfo: {
                  offset: offset.workFlow,
                  limit: limit / 2,
                },
                fetchInfo: {
                  totalCount: rels.totalCount,
                },
                structureInfo: "workflowInfo",
              })
            );
            const WFTaskProcessed = convertWorkFlow(
              workflows,
              true,
              user,
              filter,
              isAdmin,
              wfFilterTask
            );
            if (WFTaskProcessed.length !== workflows.length) {
              countDiffWorkFlow = workflows.length - WFTaskProcessed.length;
            }
            result = result.concat(WFTaskProcessed);
          }
          result.forEach((newTask) =>
            insertSorted(taskList, newTask, parseInt(sortValue) !== 1)
          );
          dispatch(
            actions.storeOpenTaskInfo({
              data: taskList,
              paginationInfo: {
                offset: offset.taskOffset,
                limit:
                  type === "APPROVE_ROLE_ASSIGNMENT" ||
                  type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
                  type === "allTasks"
                    ? limit / 2
                    : limit,
                currentPage: topPage,
              },
              fetchInfo: {
                totalCount: taskCount,
              },
              isLoaded: true,
              structureInfo: structureInfo,
              errorInfo: { error: false, errorMessage: "" },
              loading: false,
            })
          );
          dispatch(actions.updateCount(countDiffWorkFlow));
        })
        .catch((err) => {
          actions.storeOpenTaskInfo({
            errorInfo: { error: true, errorMessage: err },
            loading: false,
          });
        });
    }
  };
};
export const loadWorkFlow = (id, userId) => async (dispatch) => {
  const url = `${urlConfig.workflowsById(id)}`;
  dispatch(
    actions.storeDeeplinkInfo({
      loading: true,
      isLoaded: false,
    })
  );
  Communication.get(url)
    .then((resp) => {
      if (resp.data && hasData(resp?.data?.workflows)) {
        const { workflows = [] } = resp?.data;
        const WFTaskProcessed = convertWorkFlow(workflows, true, userId);
        const selectedWorkflow = WFTaskProcessed[0];
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: selectedWorkflow,
            workFlowData: workflows[0],
          })
        );
        const { state, taskType } = selectedWorkflow;
        if (state === "PENDING") {
          dispatch(
            loadTaskCountDeeplink(
              id,
              taskType,
              userId,
              24,
              24,
              0,
              24,
              [],
              [],
              0,
              true,
              false,
              selectedWorkflow,
              workflows[0]
            )
          );
        }
      } else {
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: {},
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        actions.storeDeeplinkInfo({
          loading: false,
          isLoaded: false,
        })
      );
    });
};
export const loadTask = (id, userId) => async (dispatch) => {
  const url = urlConfig.taskById(id); // urlConfig.taskById(id);
  dispatch(
    actions.storeDeeplinkInfo({
      loading: true,
      isLoaded: false,
    })
  );
  Communication.get(url)
    .then((resp) => {
      if (resp.data && hasData(resp.data.tasks)) {
        const [task = {}] = resp?.data?.tasks;
        const { state, taskType } = task;
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: task,
          })
        );

        if (state === "PENDING") {
          dispatch(
            loadTaskCountDeeplink(
              id,
              taskType,
              userId,
              24,
              24,
              0,
              24,
              [],
              [],
              0,
              true,
              false,
              task
            )
          );
        }
      } else {
        dispatch(
          actions.storeDeeplinkInfo({
            loading: false,
            isLoaded: true,
            selectedTask: {},
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        actions.storeDeeplinkInfo({
          loading: false,
          isLoaded: false,
        })
      );
    });
};

export const approveTasks =
  (id, comment = "") =>
  async (dispatch) => {
    const payload = comment.length ? { reason: comment } : {};
    return await Communication.post(urlConfig.approveTask(id), payload)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          return response?.status;
        }
      })
      .catch((e) => {
        return e?.response?.status;
      });
  };

export const rejectTasks = (id, comment) => async (dispatch) => {
  const payload = { reason: comment };
  return await Communication.post(urlConfig.rejectTask(id), payload)
    .then((response) => {
      if (response.status === 204 || response.status === 200) {
        return response.status;
      }
    })
    .catch((e) => {
      return e?.response?.status;
    });
};

export const SetCustomisedSnackBar = (data) => (dispatch) => {
  dispatch(actions.setCustomizedSnackBar(data));
};

export const saveBreadCrumbData = (data) => (dispatch) => {
  dispatch(actions.saveBreadCrumbInfo(data));
};

export const applicationData = (id) => (dispatch) => {
  if (id) {
    const url = urlConfig.application(id);
    Communication.get(url).then((response) => {
      const [application = {}] = response?.data?.applications;
      dispatch(actions.saveApplicationdata(application));
    });
  } else {
    dispatch(actions.saveApplicationdata({}));
  }
  // dispatch(actions.applicationData(data));
};

export const loadTaskCountDeeplink =
  (
    id,
    type,
    user,
    taskCount = 24,
    workflowCount = 24,
    offset = 0,
    limit = 24,
    workFlowList = [],
    taskList = [],
    tottalCount = 0,
    isFetch = true,
    isUpdate = false,
    selectedTask,
    selectedWorkflow = {}
  ) =>
  async (dispatch) => {
    try {
      if (isFetch) {
        let taskDataReturn = [];
        await dispatch(
          actions.storeDeeplinkInfo({
            loading: true,
            isLoaded: false,
          })
        );
        const taskUrl = "/tasks";
        const workflowURL = "/workflows";
        const urlSearchParamsForTaskURL = new URLSearchParams();
        const filtersForTaskURL = [];

        const urlSearchParamsForWorkFlowURL = new URLSearchParams();
        const filtersForWorkFlowURL = [];

        filtersForTaskURL.push(`recipients.id==${user}`);

        switch (type) {
          case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
          case "APPROVE_ROLE_ASSIGNMENT":
          case "ROLE_APPROVAL_WORKFLOW":
            filtersForTaskURL.push(
              `taskType=in=(APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT,APPROVE_ROLE_ASSIGNMENT)`
            );
            filtersForWorkFlowURL.push(
              "(workflowData.type==ROLE_APPROVAL_WORKFLOW)"
            );
            break;
          case "APPROVE_APPLICATIONROLE_TO_ROLE":
            filtersForTaskURL.push(
              `taskType=in=(APPROVE_APPLICATIONROLE_TO_ROLE)`
            );
            filtersForWorkFlowURL.push(
              "(workflowData.type==ENTITLEMENT_TO_ROLE_APPROVAL)"
            );
            break;
          case "APPROVE_TECHUSER_TO_APPLICATION":
            filtersForTaskURL.push(
              `taskType=in=(APPROVE_TECHUSER_TO_APPLICATION)`
            );
            break;
          case "APPROVE_ORG_ADMIN_PERMISSION":
            filtersForTaskURL.push(
              `taskType=in=(APPROVE_ORG_ADMIN_PERMISSION)`
            );
            break;
          case "APPROVE_USER_CREATION":
            filtersForTaskURL.push(`taskType=in=(APPROVE_USER_CREATION)`);
            break;
          case "APPROVE_USER_TO_ORGANIZATION":
            filtersForTaskURL.push(
              `taskType=in=(APPROVE_USER_TO_ORGANIZATION)`
            );
            break;
          case "All":
            break;
          default:
            filtersForTaskURL.push(
              `taskType=in=(APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT,APPROVE_ROLE_ASSIGNMENT)`
            );
            break;
        }
        const { taskFilter, wfFilterTask } = getTaskAndWFFilter("PENDING");

        const taskStatus =
          taskFilter && generateStateString(taskFilter, "state");
        const WFtaskState =
          wfFilterTask &&
          generateStateString(wfFilterTask, "tasks.taskData.state");

        filtersForTaskURL.push(taskStatus);

        const workFlowFilter = `((` + WFtaskState + `))`;
        filtersForWorkFlowURL.push(`tasks.recipients.id==${user}`);
        filtersForWorkFlowURL.push(workFlowFilter);

        urlSearchParamsForTaskURL.set("sort", "-creationDate");
        urlSearchParamsForTaskURL.set("offset", offset);
        urlSearchParamsForTaskURL.set("limit", limit);

        if (filtersForTaskURL.length) {
          if (filtersForTaskURL.length > 1) {
            urlSearchParamsForTaskURL.set(
              "filter",
              filtersForTaskURL.join(";")
            );
          } else {
            urlSearchParamsForTaskURL.set("filter", filtersForTaskURL.join(""));
          }
        }

        if (filtersForWorkFlowURL.length) {
          if (filtersForWorkFlowURL.length > 1) {
            urlSearchParamsForWorkFlowURL.set(
              "filter",
              filtersForWorkFlowURL.join(";")
            );
          } else {
            urlSearchParamsForWorkFlowURL.set(
              "filter",
              filtersForWorkFlowURL.join("")
            );
          }
        }

        const taskURL = `/gems${taskUrl}?${urlSearchParamsForTaskURL.toString()}`;
        const requests = taskCount > offset ? [Communication.get(taskURL)] : [];
        if (
          (type === "APPROVE_ROLE_ASSIGNMENT" ||
            type === "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT" ||
            type === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
            type === "ROLE_APPROVAL_WORKFLOW") &&
          workflowCount > offset
        ) {
          urlSearchParamsForWorkFlowURL.set("offset", offset);
          urlSearchParamsForWorkFlowURL.set("limit", limit);
          const allWorkFlowUrl = `/gems${workflowURL}?${urlSearchParamsForWorkFlowURL.toString()}`;
          requests.push(Communication.get(allWorkFlowUrl));
        }

        if (requests && requests.length) {
          await Communication.all(requests).then(async (r) => {
            const countObject = {};
            const dataObject = {};
            r.forEach((d, i) => {
              countObject[d?.data.tasks ? "taskCount" : "workFlowCount"] =
                d?.data?.rels?.totalCount;
              dataObject[d?.data.tasks ? "taskData" : "workFlowData"] = d?.data
                .tasks
                ? d?.data?.tasks
                : d?.data?.workflows;
            });

            dataObject["taskData"] =
              dataObject && dataObject["taskData"]
                ? [...dataObject["taskData"], ...taskList]
                : [...taskList]; //[...dataObject["taskData"], ...taskList];
            let taskResult = dataObject["taskData"];
            let taskCount = countObject["taskCount"];
            if (
              (type === "APPROVE_ROLE_ASSIGNMENT" ||
                type === "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT" ||
                type === "ROLE_APPROVAL_WORKFLOW") &&
              workflowCount > offset
            ) {
              const WFTaskProcessed = convertWorkFlow(
                dataObject["workFlowData"],
                true,
                user,
                "",
                false
              );

              taskResult = [...dataObject["taskData"], ...WFTaskProcessed];

              dataObject["workFlowData"] = [
                ...dataObject["workFlowData"],
                ...workFlowList,
              ];
              taskCount =
                countObject["taskCount"] + countObject["workFlowCount"];
            } else {
              dataObject["workFlowData"] = workFlowList;
              countObject["workflowCount"] = workflowCount;
            }

            if (!countObject.hasOwnProperty("taskCount")) {
              countObject["taskCount"] = taskCount;
            }

            if (tottalCount > taskCount) {
              taskCount = tottalCount;
            }

            if (isUpdate) {
              taskResult = taskResult
                .filter((task) => task.id !== id)
                .sort(
                  (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
                );
              if (id.length) {
                taskCount--;
              }
              const index = taskResult.findIndex(
                (task) => task.id !== selectedTask.id
              );
              if (index !== -1) {
                taskResult.splice(index - 1, 1);
              }
              if (
                Object.keys(selectedTask).length !== 0 &&
                id !== selectedTask?.id
              ) {
                taskResult.unshift(selectedTask);
              }
            } else {
              taskResult = taskResult.sort(
                (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
              );

              if (id !== selectedTask?.id) {
                const index = taskResult.findIndex(
                  (task) => task.id !== selectedTask.id
                );
                if (index !== -1) {
                  taskResult.splice(index - 1, 1);
                }
                if (Object.keys(selectedTask).length !== 0) {
                  taskResult.unshift(selectedTask);
                }
              }
            }

            const data = {
              taskResult: {
                tasks: taskResult,
                count: taskCount,
                taskType: type,
              },
              taskData: dataObject,
              countInfo: countObject,
              paginationInfo: {
                fetchedCount: offset + limit,
              },
            };

            if (!isUpdate) {
              await dispatch(
                actions.storeDeeplinkInfo({
                  loading: false,
                  isLoaded: true,
                  selectedTask: selectedTask,
                  workFlowData: selectedWorkflow,
                })
              );
            } else {
              await dispatch(
                actions.storeDeeplinkInfo({
                  loading: false,
                  isLoaded: true,
                  selectedTask: id === selectedTask?.id ? {} : selectedTask,
                })
              );
            }

            await dispatch(actions.storeTaskInfoDeeplink(data));

            taskDataReturn = taskResult;
          });
          return taskDataReturn;
        } else {
          if (!isUpdate) {
            await dispatch(
              actions.storeDeeplinkInfo({
                loading: false,
                isLoaded: true,
                selectedTask: selectedTask,
              })
            );
          } else {
            const task = taskList
              .filter((task) => task.id !== id)
              .sort(
                (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
              );

            if (id !== selectedTask?.id) {
              const index = task.findIndex(
                (task) => task.id !== selectedTask.id
              );
              if (index !== -1) {
                task.splice(index - 1, 1);
              }
              if (Object.keys(selectedTask).length !== 0) {
                task.unshift(selectedTask);
              }
            }

            dispatch(
              actions.storeTaskCountDeeplink({
                task: task,
                count: tottalCount - 1,
              })
            );
            await dispatch(
              actions.storeDeeplinkInfo({
                loading: false,
                isLoaded: true,
                selectedTask: id === selectedTask?.id ? {} : selectedTask,
              })
            );
          }
        }
      } else {
        const task = taskList.filter((task) => task.id !== id);
        dispatch(
          actions.storeTaskCountDeeplink({
            task: task,
            count: tottalCount - 1,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

export const GetLatestTaskActiviy = (userId) => (dispatch) => {
  const urlSearchParamsForTask = new URLSearchParams();
  const urlSearchParamsForWf = new URLSearchParams();
  urlSearchParamsForTask.set("offset", 0);
  urlSearchParamsForTask.set("limit", 15);
  urlSearchParamsForTask.set("sort", "-lastModifyDate");
  urlSearchParamsForTask.set(
    "filter",
    `recipients.id==${userId};((state==REJECTED),(state==FAILED),(state==EXPIRED),(state==COMPLETED))`
  );
  urlSearchParamsForWf.set("offset", 0);
  urlSearchParamsForWf.set("limit", 15);
  urlSearchParamsForWf.set(
    "filter",
    `(workflowData.type==ROLE_APPROVAL_WORKFLOW);tasks.recipients.id==${userId};(state==COMPLETED)`
  );
  urlSearchParamsForWf.set("sort", "-tasks.endTime");

  const taskUrl = `/gems/tasks?${urlSearchParamsForTask.toString()}`;
  const wfUrl = `/gems/workflows?${urlSearchParamsForWf.toString()}`;
  const requests = [Communication.get(taskUrl), Communication.get(wfUrl)];
  dispatch(
    actions.storeLatestActivity({
      loading: true,
      latestTaskActivity: [],
      isLoaded: false,
    })
  );
  return Communication.all(requests)
    .then((res) => {
      const [taskRes, workflowRes] = res;
      const workflows = workflowRes?.data?.workflows;
      const tasks = taskRes?.data?.tasks;

      const WFTaskProcessed = convertWorkFlow(workflows, true);
      const result = [...tasks, ...WFTaskProcessed]
        .sort(
          (a, b) => new Date(b?.lastModifyDate) - new Date(a?.lastModifyDate)
        )
        .slice(0, 15);

      dispatch(
        actions.storeLatestActivity({
          loading: false,
          latestTaskActivity: result,
          isLoaded: true,
        })
      );
      return result;
    })
    .catch((er) => {
      dispatch(
        actions.storeLatestActivity({
          loading: false,
          latestTaskActivity: [],
          isLoaded: true,
        })
      );
      return er && er.response && er.response.status;
    });
};
