import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@alice/component_library";
import {
  fetchEntitlementsOfRole,
  fetchApplicationDetails,
} from "@sliceactions/newRequestActions";
import {
  RoleDetailsContainer,
  RoleDetailsHeaderContainer,
  RoleIdNameContainer,
} from "./styledComponents";
import ConfigurationItems from "./ConfigurationItems";
import Entitlements from "./Entitlements";
import Attachments from "./Attachments";
import Description from "./Description";

const RoleDetails = forwardRef(
  ({ role, showClose = false, onClose = () => {} }, ref) => {
    const {
      name,
      id,
      defaultValidityType = "MANDATORY",
      needsCustomScopes = false,
      needsOrgScopes = false,
      attachment = false, // attachment has to be changed once api
      termsAndCondition = false, // termsandconditions has to be changed once api
      description,
      additionalInfo = "Additional Information lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.",
      templateFile = "www.applicationname.com/templatefile",
    } = role;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { entitlements = {}, loading: entitlementLoading = false } =
      useSelector((state) => state.newRequestSlice.roleEntitlements);
    const [appIds, setAppIds] = useState([]);

    useEffect(() => {
      const roleIdEntitlement = entitlements[id];
      if (!roleIdEntitlement) dispatch(fetchEntitlementsOfRole({ roleId: id }));
      else {
        const uniqueIds = [
          ...new Set(roleIdEntitlement.map((item) => item.applicationId)),
        ];
        setAppIds(uniqueIds);
        if (uniqueIds.length) {
          uniqueIds.forEach((appId) => {
            dispatch(fetchApplicationDetails({ applicationId: appId }));
          });
        }
      }
    }, [dispatch, entitlements, id]);

    return (
      <RoleDetailsContainer testId="role-detail-container" ref={ref}>
        <div className={showClose ? "detail-header" : ""}>
          <RoleDetailsHeaderContainer>
            <RoleIdNameContainer>
              <div className="role-cardv3-header">
                <Typography variant="caption" className="id-holder">
                  {t("id")}
                </Typography>
                <Tooltip type="ellipsized" title={id}>
                  <Typography
                    variant="subtitle1"
                    className="role-cardv3-id"
                    noWrap
                  >
                    {id}
                  </Typography>
                </Tooltip>
              </div>
              <Tooltip type="ellipsized" title={name}>
                <Typography variant="body1" className="role-cardv3-name" noWrap>
                  {name}
                </Typography>
              </Tooltip>
            </RoleIdNameContainer>
            <ConfigurationItems
              defaultValidityType={defaultValidityType}
              needsCustomScopes={needsCustomScopes}
              needsOrgScopes={needsOrgScopes}
              attachment={attachment}
              termsAndCondition={termsAndCondition}
            />
          </RoleDetailsHeaderContainer>
          {showClose && (
            <IconButton
              sx={{ color: "#000000DE", width: "24px", height: "24px" }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <Divider />
        <Description description={description} appIds={appIds} />
        {attachment && (
          <>
            <Divider />
            <Attachments
              additionalInfo={additionalInfo}
              templateFile={templateFile}
            />
          </>
        )}
        <Divider />
        <Entitlements
          entitlements={entitlements[id]}
          entitlementLoading={entitlementLoading}
        />
      </RoleDetailsContainer>
    );
  }
);

export default RoleDetails;
