import PropTypes from 'prop-types'
import { useUserCard, UserCard } from '../UserCard'
import { useInteraction } from '../../../hooks'
import { InteractionPropTypes } from '../../../constants'
import { createArrayWithInitialValues } from '../../../util'
import { DefaultGridViewRenderer } from '../../search'

/**
 * This component renders a grid view using `UserCard` components.
 * The use of MUI-Grid with `UserCard` is preferred to achieve an optimal layout and responsive design.
 *
 * Important Notes:
 * - This component is purely for presentation and does not handle data fetching or state management.
 *   States like `selectedUser` should be managed by a parent component.
 * - It is intended to work in conjunction with components such as `UserSearch` or `GenericSearch` for displaying user data.
 * - On mobile devices, a 'list' view mode is automatically adopted to enhance usability.
 */
export const UserSearchGridViewRenderer = ({
  view = 'grid',
  size = 'm',
  data: users,
  selectedData: selectedUsers = [],
  disabledData: disabledUsers = [],
  onItemClick,
  rowsPerPage,
  interaction,
  onGetHref,
  isAdminRolesOverwritten = false, // Testing only
  isIdLink = true,
  isLoading = false,
  isMobile = false,
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  if (isMobile) view = 'list'

  const isList = view === 'list'

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({ interaction })

  const mappableUsers = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({ id: index.toString() }))
    : users

  const {
    isContextShown: _isContextShown,
    isEmailShown: _isEmailShown,
    isPhoneShown: _isPhoneShown,
    isCommunityShown: _isCommunityShown,
    isOrganisationShown: _isOrganisationShown,
    isInfoChipShown: _isInfoChipShown,
    isStatusShown: _isStatusShown,
    onDeleteClick: _onDeleteClick
  } = otherProps || {}

  const {
    isDividedShown,
    subgridContentColumns,
    subgridContentRows
  } = useUserCard({
    size,
    isList: view === 'list',
    isRenderer: true,
    isSubgrid: true,
    _isContextShown,
    _isEmailShown,
    _isPhoneShown,
    _isCommunityShown,
    _isOrganisationShown,
    _isInfoChipShown,
    _isStatusShown,
    _onDeleteClick
  })

  const hasCheckbox = isSelectable && !isSelectHidden

  return (
    <DefaultGridViewRenderer
      testId="UserSearchGridViewRenderer"
      isList={isList}
      isDividedShown={isDividedShown}
      hasCheckbox={hasCheckbox}
      subgridContentColumns={subgridContentColumns}
    >
      {mappableUsers.map((user) => (
        <UserCard
          key={user.id}
          variant={view === 'tiny' ? 'list' : view}
          size={view === 'tiny' ? 's' : size}
          userData={user}
          interaction={interaction}
          href={onGetHref}
          isIdLink={isIdLink}
          isAdminRolesOverwritten={isAdminRolesOverwritten}
          isSelected={selectedUsers.includes(user.id)}
          isDisabled={disabledUsers.includes(user.id)}
          isLoading={isLoading}
          onCardClick={onItemClick}
          isSubgrid
          subgridContentColumns={subgridContentColumns}
          subgridContentRows={subgridContentRows}
          {...otherProps}
        />
      ))}
    </DefaultGridViewRenderer>
  )
}

UserSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  /** item size-variant */
  size: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** Users to be displayed */
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    departmentNumber: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Selected users */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled user cards */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  /** Loading state */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** True if mobile viewport */
  isMobile: PropTypes.bool
}
