import React from "react";
import { Tooltip } from "@mui/material";

export const TextOnlyTooltip = (props) => {
  return (
    <Tooltip
      title={props.title}
      position="bottom"
      // arrow
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "black",
            color: "white",
            border: "1px solid grey",
            cursor: "default",
          },
        },
        arrow: {
          sx: {
            "&:before": {
              border: "1px solid grey",
            },
            color: "white",
          },
        },
      }}
    >
      {props.children}
    </Tooltip>
  );
};
