export const sxStyles = ({ palette }) => ({
  width: '360px',
  '.rdp': {
    '--rdp-cell-size': '48px',
    margin: '0 12px'
  },
  '.rdp-day': {
    height: '40px',
    width: '40px',
    border: 'none',
    overflow: 'unset',
    background: 'var(--dpDayBg, transparent) !important',
    transition: 'background 150ms',
    '.dayWrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      borderRadius: '50%',
      width: '40px',
      top: 0,
      bottom: 0,
      color: `var(--dpDayWrapperClr, ${palette.text.primary})`,
      border: 'var(--dpDayWrapperBorderSize, 1px) solid var(--dpDayWrapperBorderClr, transparent)',
      background: 'var(--dpDayWrapperBg, transparent)',
      transition: 'background 150ms, color 150ms, border 150ms'
    }
  },
  '.rdp-day_range_start, .rdp-day_range_end, .singleSelected': {
    '--dpDayWrapperClr': 'white',
    '--dpDayWrapperBg': palette.primary.main,
    '&:hover': { '--dpDayWrapperBg': palette.primary.dark }
  },
  '.rdp-day_range_start .dayWrapper': { left: 0 },
  '.rdp-day_range_end .dayWrapper': { right: 0 },
  '.rdp-day:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_range_middle):not(.singleSelected):not(.rdp-day_today)': {
    width: '44px',
    margin: '4px',
    '&:hover': { '--dpDayWrapperBg': palette.action.hover }
  },
  '.rdp-day_range_start:not(.rdp-day_range_end)': {
    width: '44px',
    marginLeft: '4px'
  },
  '.rdp-day_range_end:not(.rdp-day_range_start)': {
    width: '44px',
    marginRight: '4px'
  },
  '.rdp-day_range_middle': {
    width: '48px',
    '--dpDayWrapperClr': 'black',
    '&:hover': { '--dpDayWrapperBg': palette.action.hover }
  },
  '.rdp-day_today:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_disabled)': {
    '--dpDayWrapperBorderClr': palette.primary.main,
    '--dpDayWrapperClr': palette.primary.main,
    '--dpDayWrapperBg': 'white',
    '&:hover': { '--dpDayWrapperBg': palette.blue[95] }
  },
  '.rdp-day_today': {
    '&.rdp-day_range_start, &.rdp-day_range_end, &.singleSelected': {
      '--dpDayWrapperBg': 'white',
      '--dpDayWrapperClr': palette.primary.main,
      '--dpDayWrapperBorderSize': '4px',
      '--dpDayWrapperBorderClr': palette.primary.main,
      '&:hover': { '--dpDayWrapperBg': palette.blue[95] }
    }
  },
  '.rdp-day_disabled': {
    pointerEvents: 'none',
    '--dpDayWrapperClr': palette.text.disabled,
    '&.rdp-day_today': { '--dpDayWrapperBorderClr': palette.text.disabled }
  },
  '.rangeSelected': { '--dpDayBg': palette.blue[95] },
  '.rdp-head_row': {
    display: 'flex'
  },
  '.rdp-head_cell': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#000000',
    opacity: 0.6,
    height: 'var(--rdp-cell-size)',
    width: 'var(--rdp-cell-size)',
  }
})
