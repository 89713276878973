export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, 0);
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const year = date.getFullYear();

  return `${day} | ${month} | ${year}`;
};

export const getTaskType = (type, t) => {
  let taskType = t("menuBar.allTasks");
  switch (type) {
    case "APPROVE_ROLE_ASSIGNMENT":
      taskType = t("taskType.ROLE_ASSIGNMENT");
      break;
    case "APPROVE_USER_CREATION":
      taskType = t("taskType.APPROVE_USER_CREATION");
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      taskType = t("taskType.APPROVE_USER_TO_ORGANIZATION");
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      taskType = t("taskType.APPROVE_ROLE_DEPROVISIONING");
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      taskType = t("taskType.APPROVE_TECHUSER_TO_APPLICATION");
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
      taskType = t("taskType.APPLICATIONROLE_TO_ROLE");
      break;
    default:
      taskType = t("menuBar.allTasks");
      break;
  }

  return taskType;
};
