import { styled, Typography } from "@mui/material";
import { Tooltip } from "@src/ComponentLibrary";
import { Link } from "react-router-dom";

export const CardDetailsHeader = styled("div")(({ theme, ...props }) => ({
  flexWrap: "nowrap",
  width: "100%",
  display: "flex",
  padding: "8px 11px 8px 16px",
  alignItems: "normal",
  alignSelf: "stretch",
  borderRadius: "4px 4px 0px 0px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  height: "100%",
  justifyContent: "space-between",
  background: props.bgcolor,
  [theme.breakpoints.down("sm")]: {
    alignItems: "baseline",
    flexWrap: "nowrap",
  },
  "& .request-container": {
    display: "flex",
    flexDirection: "row",
    columnGap: "24px",
    rowGap: "5px",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    "& .request-icon-id": {
      display: "flex",
      alignItems: "center",
      columnGap: "8px",
      "& .request-icon": {
        display: "flex",
        alignItems: "center",
      },
      "& .request-id": {
        display: "flex",
        alignItems: "center",
        columnGap: "7px",
      },
    },

    "& .Request-header-date": {
      display: "flex",
      alignItems: "center",
      columnGap: "5px",
      "& .request-info": {
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "14px",
        },
      },
    },
  },
  "& .request-close-icon-lg": {
    cursor: "pointer",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .request-close-icon-sm": {
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export const RequestidHeader = styled((props) => (
  <Tooltip type="ellipsized" title={props.tooltip}>
    <Typography variant="h6" {...props} />{" "}
  </Tooltip>
))(({ theme }) => ({
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "28px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "10vw",
  [theme.breakpoints.down("md")]: {
    maxWidth: "28vw",
  },
}));
export const RequestidLink = styled((props) => <Link {...props} />)(
  ({ theme }) => ({
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
    fontFamily: "MB Corpo S Text",
    textDecoration: "none",
    cursor: "pointer",
    color: "rgb(0, 120, 214)",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // maxWidth: "11vw",
    [theme.breakpoints.down("md")]: {
      maxWidth: "30vw",
    },
  })
);
