import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Stack,
  Card,
  Skeleton,
} from "./CommonSkeletons";

const TableRowsLoader = ({
  rowsNum = 10,
  columnCount = 4,
  showTableHeader = true,
  taskpageHeader = false,
  dataGrid = false,
  sx = {},
}) => {
  return (
    <TableContainer sx={sx}>
      <Table
        sx={{
          ...(dataGrid ? { border: "1px solid rgba(0, 0, 0, 0.12)" } : {}),
        }}
      >
        {showTableHeader && (
          <TableHead component={dataGrid && Card}>
            <TableRow
              sx={
                dataGrid
                  ? {}
                  : {
                      borderTop: "2px solid rgb(118, 118, 118)",
                      borderBottom: "2px solid rgb(118, 118, 118)",
                      background: "white",
                      color: "rgb(51, 51, 51)",
                    }
              }
            >
              {dataGrid ? (
                taskpageHeader ? (
                  <>
                    <TableCell colSpan={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width="18px"
                          height="18px"
                        />
                        <Skeleton animation="wave" variant="text" width="20%" />
                      </Stack>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Stack direction="row" justifyContent="end" spacing={2}>
                        <Skeleton animation="wave" variant="text" width="20%" />
                        <Skeleton animation="wave" variant="text" width="20%" />
                      </Stack>
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    colSpan={columnCount}
                    sx={{ height: "49px" }}
                  ></TableCell>
                )
              ) : (
                <>
                  {[...Array(columnCount)].map((row, index) => (
                    <TableCell key={index}>
                      <Skeleton animation="wave" variant="text" />
                    </TableCell>
                  ))}
                </>
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {[...Array(rowsNum)].map((row, index) => (
            <TableRow
              key={index}
              sx={
                dataGrid
                  ? {}
                  : {
                      "&:nth-of-type(even)": {
                        backgroundColor: "rgb(244, 244, 244)",
                      },
                      "& .MuiTableCell-root ": {
                        borderBottom: "1px solid rgb(118, 118, 118)",
                      },
                    }
              }
            >
              {[...Array(columnCount)].map((col, i) => (
                <TableCell key={i}>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableRowsLoader;
