import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'
import { BadgeOutlined, ContentCopy, EmailOutlined, LocalPhoneOutlined } from '@mui/icons-material'
import { IconButton } from '../../../button'
import { Skeleton } from '../../../skeleton'

const UserCardContactDetailsPlaceholder = ({
  gap,
  isEmailShown,
  isMobileShown,
  isIDShown
}) => (
  <Stack gap={gap}>
    {isEmailShown && (<Skeleton variant="rectangular" width="100%" height="1.275rem" />)}
    {isMobileShown && (<Skeleton variant="rectangular" width="100%" height="1.275rem" />)}
    {isIDShown && (<Skeleton variant="rectangular" width="100%" height="1.275rem" />)}
  </Stack>
)

export const UserCardContactDetails = ({
  user: {
    id,
    mailAddress,
    mobileNumber,
    departmentNumber
  },
  gap = 1,
  isLoading,
  isEmailShown,
  isMobileShown,
  isIDShown,
  isDepartmentShown,
  isIconShown,
  isCopyShown,
  variant = 'default'
}) => {
  const handleEmailCopy = () => navigator.clipboard.writeText(mailAddress)

  if (isLoading) {
    return (
      <UserCardContactDetailsPlaceholder
        gap={gap}
        isEmailShown={isEmailShown}
        isMobileShown={isMobileShown}
        isIDShown={isIDShown}
      />
    )
  }

  return (
    <Stack gap={gap}>
      {isEmailShown && (
        <Stack
          direction="row"
          sx={[
            {
              fontSize: '24px',
              alignItems: 'center',
              gap: 1
            },
            variant !== 'tiny' && { 'svg, img': { fontSize: '20px' } },
            variant === 'tiny' && { fontSize: '18px', gap: 1 }
          ]}
        >
          {isIconShown && <EmailOutlined color="text.primary" fontSize="inherit" />}
          <Typography noWrap sx={[ variant === 'tiny' && { fontSize: '14px' } ]}>{mailAddress}</Typography>

          {isCopyShown && (
            <IconButton onClick={handleEmailCopy} sx={{ width: '24px', height: '24px' }} color="primary">
              <ContentCopy fontSize="small" />
            </IconButton>
          )}
        </Stack>
      )}

      {isMobileShown && (
        <Stack
          direction="row"
          sx={[
            {
              fontSize: '24px',
              alignItems: 'center',
              gap: 1
            },
            variant !== 'tiny' && { 'svg, img': { fontSize: '20px' } },
            variant === 'tiny' && { fontSize: '18px', gap: 1 }
          ]}
        >
          {isIconShown && <LocalPhoneOutlined color="text.primary" fontSize="inherit" />}

          <Box sx={{
            overflow: 'hidden', textOverflow: 'ellipsis', width: '100%'
          }}
          >
            <Typography
              noWrap
              sx={[ variant === 'tiny' && { fontSize: '14px' } ]}
            >
              {mobileNumber}
            </Typography>
          </Box>
        </Stack>
      )}

      {isIDShown && (
        <Stack direction="row" gap={1}>
          {isIconShown && <BadgeOutlined color="text.primary" sx={{ fontSize: '20px' }} />}

          <Stack direction="row" gap={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography noWrap sx={[ variant === 'tiny' && { fontSize: '14px' } ]}>
              {id}
              {isDepartmentShown && departmentNumber && (
              <>
                &nbsp;(
                {departmentNumber}
                )
              </>
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

UserCardContactDetails.propTypes = {
  /** contact details variant */
  variant: PropTypes.oneOf([ 'default', 'tiny' ])
}
