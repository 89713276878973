import { Grid, Typography, Accordion, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const ProfileLayout = styled("div")({
  "& .sticky-component": {
    position: "sticky",
    top: "0",
  },
  "@media (max-width: 1200px)": {
    padding: "0 25px",
  },
});
export const ProfileResponsiveBox = styled("div")({
  "& .profileMenu-contaitner": {
    position: "absolute",
    width: "100%",
    zIndex: "999",
    background: "white",
    padding: "14px",
    display: "none",
    "&.side-bar-open": {
      display: "block",
    },
    "& .side-menu-container": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      "& .profile-menu-item": {
        background: "white",
        "&:not(:last-child)": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
      },
    },
  },
  "@media (max-width: 850px)": {
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "1px",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
      top: "0",
      left: 0,
    },
  },
});

export const ProfileResponsiveMenu = styled("div")({
  background: "rgba(244, 244, 244, 1)",
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  borderRadius: "4px",
  padding: "10px 6px 4px 16px",
  "& .menu-icon-text-container": {
    display: "flex",
  },
  "& .text-content": {
    paddingLeft: "14px",
    fontFamily: "MB Corpo S Title",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
  },
  "& .arrow-icon-container": {
    position: "absolute",
    right: "10px",
    top: "24px",
    transition: "transform 0.3s ease",
  },
  "&.menu-sticky": {
    width: "14%",
    "&.menu-active": {
      background: "rgba(244, 244, 244, 1)",
    },
  },
  "&.menu-active": {
    background: "rgba(0, 120, 214, 0.16)",
    border: "rgba(0, 120, 214, 0.16)",
    color: "rgba(0, 120, 214, 1)",
    "&.sticky": {
      background: "rgba(244, 244, 244, 1)",
    },
    "& .text-content": {
      color: "rgba(0, 120, 214, 1)",
    },
    "& .arrow-icon-container": {
      transform: "rotate(180deg)",
      top: "18px",
    },
  },
});

export const ProfileMenuItems = styled((props) => (
  <a direction="row" spacing={2} alignContent="center" {...props} />
))(({ ...props }) => ({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "12px 20px",
  cursor: "pointer",

  backgroundColor: props.selected && "#f3f3f3",
  borderLeft: props.selected ? "5px solid #0078D6" : "5px solid #fff",
  "&:hover": {
    backgroundColor: "#f3f3f3",
    color: "#000",
    borderLeft: props.selected ? "5px solid #0078D6" : "5px solid #f3f3f3",
  },
}));

export const ProfileMenuItemsHeader = styled(Typography)({
  color: "#000000DE",
  fontWeight: "400",
  size: "16px",
  lineHeight: "18px",
  marginLeft: "15px",
});

export const ProfileMenuItemIcon = styled("div")({
  position: "relative",
  display: "inline-flex",
  width: "24px",
  height: "24px",
});

export const ProfileMenuCard = styled((props) => <Grid item {...props} />)(
  ({ ...props }) => ({
    height: "fit-content",
    padding: 0,
    boxShadow: 0,
    border: "1px solid #ddd",
    marginRight: "10px",
    borderRadius: "4px",
    width: props.isCollapsed ? "75px" : "100%",
    "& .profile-menu-item:not(:last-child)": {
      borderBottom: "1px solid #ddd",
    },
  })
);

export const ProfileMenuCardContent = styled((props) => (
  <Stack
    direction="row"
    spacing={2}
    justifyContent={props.iscollapsed ? "center" : "space-between"}
    {...props}
  />
))({
  position: "relative",
  padding: "12px 20px",
  borderBottom: "1px solid #ddd",
});

export const ProfileMenuCardTitle = styled(Typography)({
  userSelect: "none",
  color: "#000",
  fontSize: 20,
  fontWeight: "700",
  lineHeight: "28px",
});

export const TabHeaderComponent = styled(Grid)(({ theme }) => ({
  paddingBottom: "20px",
  justifyContent: "space-between",
  alignContent: "center",
  "& button": {
    "@media (min-width: 900px)": {
      position: "absolute",
      top: "32px",
      right: "0",
    },
    "@media (max-width: 850px)": {
      // top: "88px",
    },
  },
  "& .tabHeader": {
    fontFamily: "MB Corpo A Title Cond",
    fontSize: "26px",
    color: "#333333",
  },
  "& .bold16": {
    marginLeft: "5px",
  },
}));

export const ItemContainerMyDetails = styled(Grid)({
  justifyContent: "space-between",
  "& .detailItemXS": {
    width: "100%",
    paddingBottom: "30px",
  },
  "& .detailItem": {
    flexBasis: "40%",
    paddingBottom: "30px",
  },
});

export const TabContainer = styled("div")({
  "@media (min-width: 850px)": {
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "1px",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
      top: "0",
      left: 0,
    },
  },
});

export const ContentContainer = styled(Grid)({
  position: "relative",
});

export const SubIconTitle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    marginLeft: "-20px",
  },
}));

export const AdminSwitch = styled("div")(({ theme }) => ({
  marginBottom: "38px",
  marginTop: "18px",
  display: "flex",
}));

export const RespAdminContainer = styled("span")({
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  color: "#000000",
  fontWeight: 400,
  marginLeft: "20px",
});

export const AdminSwitchContainer = styled("div")({
  display: "flex",
  paddingRight: "20px",
});

export const TableWrapper = styled("div")({
  marginBottom: "20px",
});

export const StyledAccordian = styled(Accordion)({
  borderTop: "1px solid rgb(230, 230, 230)",
  boxShadow: "none",
});

export const AdminActiveRoles = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& .MuiAccordion-rounded": {
    borderTop: "none",
  },
  "& .MuiAccordionSummary-content p": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "32px",
  },
  "& .accordion-outer": {
    width: "100%",
    "& .accordion-main .Mui-disabled": {
      opacity: 1,
    },
    "& .accordion-main.Mui-expanded": {
      borderBottom: "none",
    },
    "& .accordion-main": {
      borderBottom: "1px solid #DBDBDB",
      background: "#fff",
      "& .icon-title": {
        display: "flex",
        fontSize: "22px",
      },
      "& .accordion-table": {
        display: "flex",
        flexDirection: "column",
        "& > span": {
          fontSize: "26px",
          fontWeight: "400",
          fontFamily: "MB Corpo A Title Cond",
        },
      },
    },
    "& .Mui-expanded.accordion-title.disable": {
      background: "#fff",
    },
    "& .accordion-title": {
      minHeight: "auto",
      padding: "0px 24px",
      "& .MuiAccordionSummary-content": {
        margin: "12px 0px",
      },
    },
    "& .accordion-content": {
      padding: "30px 24px 0px 24px",
    },
    "& .accordion-noContent": {
      padding: "10px 50px 40px 24px",
    },
  },
});

export const AdminRolesOuter = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& > h2": {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "14px",
    margin: "0",
    fontFamily: "MB Corpo S Title",
    color: "rgba(0, 0, 0, 1)",
    marginTop: "14px",
    marginBottom: "14px",
  },
});

export const TabHeaderStyled = styled("p")({
  fontFamily: "'MB Corpo S Title'",
  fontSize: "32px",
  lineHeight: "40px",
  color: "rgba(0, 0, 0, 1)",
  padding: "32px 0 28px 0",
  fontWeight: 400,
  margin: 0,
});

export const AdministratorHeaderText = styled("div")({
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "14px",
  margin: "0",
  fontFamily: "MB Corpo S Title",
  color: "rgba(0, 0, 0, 1)",
  marginBottom: "24px",
  marginTop: "26px",
});

export const OrgHeadingSpan = styled("span")({
  fontFamily: "MB Corpo S Text",
  lineHeight: "20px",
  fontWeight: "700",
  fontSize: "16px",
  padding: "4px 8px",

  backgroundColor: "rgba(248, 248, 248, 1)",
  width: "fit-content",
  borderRadius: "4px",
  color: "rgba(0, 0, 0, 0.6)",
});

export const OrgActionHeaderContainer = styled("div")({
  marginBottom: "15px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .action-container": {
    display: "flex",
    "& div": {
      marginRight: "10px",
    },
  },
});

export const OrgListConatiner = styled("div")({
  marginBottom: "14px",
});

export const MainContainer = styled(Grid)(({ theme }) => ({
  overflow: "hidden",
  "&.inputItemXS": {
    width: "100%",
    paddingBottom: "30px",
  },
  "&.inputItem": {
    flexBasis: "50%",
    paddingRight: "20px",
  },
  "&.error": {
    "& .MuiFilledInput-underline.Mui-focused:after": {
      borderBottom: "2px solid red",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .MuiFilledInput-input:after": {
      borderBottom: "2px solid red",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .MuiFilledInput-input": {
      borderBottom: "2px solid red",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#E6E6E6",
    borderRadius: 0,
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: "0",
  },
  "& input::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: "0",
  },

  [theme.breakpoints.down("md")]: {
    "& .MuiBox-root": {
      textOverflow: "ellipsis",
      maxWidth: "100px",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiBox-root": {
      textOverflow: "ellipsis",
      maxWidth: "255px",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));

export const PersonalInfoContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: "20px",
  justifyContent: "space-between",
  alignContent: "center",
  width: "50%%",
  display: "flex",
  flexWrap: "wrap",
  boxSizing: "border-box",
  alignItems: "flex-end",
  "& .tabHeader": {
    fontFamily: "MB Corpo A Title Cond",
    fontSize: "26px",
    color: "#333333",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "20px",
    },
  },
  "@media (max-width: 850px)": {
    marginTop: "-30px",
    marginRight: "8px",
  },
  "@media (min-width: 850px)": {
    position: "absolute",
    top: "32px",
    right: "27px",
  },
}));

export const MailAddressContainer = styled(Grid)(({ theme }) => ({
  flexWrap: "nowrap",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    display: "block",
    flexWrap: "wrap",
  },
  "& .inputGroup": {
    paddingBottom: "30px",
  },
}));

export const EmailWarningContainer = styled("div")({
  "& span": {
    color: "#00000099",
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "MB Corpo S Text",
  },
});

export const OrganizationMainConatiner = styled(Grid)({
  marginTop: "14px",
});

export const AccountsMainContainer = styled("div")(({ theme }) => ({
  flexGrow: "1",
  paddingTop: "14px",
}));

export const AccountHeadText = styled("div")(({ theme }) => ({
  color: "#000",
  fontFamily: "MB Corpo S Title",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
}));

export const PaperClass = styled("p")({
  fontSize: "16px ",
  fontWeight: "400",
  lineHeight: "24px",
  marginTop: "14px",
  fontFamily: "MB Corpo S Text",
  color: "#000",
  letterSpacing: "0px",
  textAlign: "left",
});

export const AccountButtonContainer = styled(Grid)({
  marginTop: "14px",
});

export const ApplicationContainer = styled(Grid)({
  marginTop: "14px",
});

export const ProfileDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "20px",
  flexWrap: "wrap",
  rowGap: "16px",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
    paddingLeft: "1px",
  },
}));

export const DetailsContainer = styled("div")({
  marginTop: "10px",
  textAlign: "left",
  maxWidth: "500px",
  "& .name-container": {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "4px",
  },
});

export const ProfileDetailsSpan = styled("span")(({ theme }) => ({
  fontSize: "48px",
  fontWeight: "400",
  color: "#333333",
  paddingRight: "10px",
  fontFamily: "MB Corpo A Title Cond",
  wordBreak: "break-word",
  lineHeight: "48px",
  marginBottom: "4px",
}));

export const ProfileDetailsSubHeader = styled("div")(({ theme }) => ({
  marginBottom: "10px",
  [theme.breakpoints.down("xs")]: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "10px 0 5px",
  },
}));

export const FocusDetailsContainer = styled("span")(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  top: "-6px",
  " & div": {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "15px",
  },
}));

export const SpanMailConatiner = styled("span")(({ theme }) => ({
  display: "inline-block",
  color: "#0078D6",
  fontSize: "16px",
  fontWeight: "700",
}));

export const ProfileActions = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
  [theme.breakpoints.down("xs")]: {
    marginTop: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiButton-root": {
      padding: "6px 0 !important",
    },
  },
}));

export const ProfileMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "center",
  rowGap: "2rem",
  paddingTop: "40px",
  paddingBottom: "40px",
  [theme.breakpoints.down("xs")]: {
    paddingBottom: "1px",
  },
}));

export const ProfileHeaderdetails = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  flexWrap: "wrap",
}));

export const HeaderTextStyle = styled("div")(({ theme }) => ({
  color: "#000",
  fontFamily: "MB Corpo A Text Cond",
  fontSize: "48px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "56px",
}));

export const CardContainer = styled("div")(({ theme }) => ({
  height: "calc(100% - 32px)",
  "& .cardIdTitle": {
    display: "grid",
    overflow: "hidden",
    gridTemplateRows: "auto 1fr",
  },
  "& .MuiStack-root:nth-child(1):has([data-testid='CountryFlag'])": {
    flexWrap: "wrap",
  },
  "& .MuiPaper-root": {
    height: "100%",
  },
}));

export const OrganizatoinHeaders = styled(Typography)({
  fontFamily: "MB Corpo S Title",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
  marginBottom: "32px",
});
