import { Button } from "@alice/component_library";
import { Typography } from "@mui/material";
import { TAButtons } from "./Styles";
import { useTranslation } from "react-i18next";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const style = {
  fontFamily: "MB Corpo S Text",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
};

const TActionButtons = ({ onReject, onApprove }) => {
  const { t } = useTranslation();
  return (
    <>
      <TAButtons svgcolor="white" svgopacity="1">
        <Button
          variant="contained"
          sx={{
            ...style,
            backgroundColor: "#D92121",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#971717",
            },
          }}
          onClick={onReject}
        >
          <CancelOutlinedIcon />
          <Typography variant="caption" display="block">
            {t("reject")}
          </Typography>
        </Button>
        <Button
          sx={{
            ...style,
            backgroundColor: "#198025",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#115919",
            },
          }}
          onClick={onApprove}
        >
          <CheckCircleOutlineIcon />
          <Typography variant="caption" display="block">
            {t("approve")}
          </Typography>
        </Button>
      </TAButtons>
    </>
  );
};
export default TActionButtons;
