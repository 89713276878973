import PropTypes from 'prop-types'
import { Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CheckCircleOutline, RemoveCircleOutline, TextSnippetOutlined } from '@mui/icons-material'
import { DataClassificationPropType, EntitlementTypePropType, Interaction, InteractionPropTypes } from '../../../constants'
import { useInteraction } from '../../../hooks'
import { getClientLink, mergeSxProps, repeatString } from '../../../util'
import { AppCard } from '../../appElements'
import { CardContent, CardDivided, ContextBtn, DetailItemType, DetailsList, GenericCard, IdTitle, useDetailItem } from '../../card/genericCard'
import { DataClassificationChip } from '../../chip'
import { IconWrapper } from '../../icon'
import { EntitlementContextPopover } from '../entitlementContext/EntitlementContextPopover'

/**
 * A card that displays information about an entitlement
 *
 * **Important Note**:
 *
 * It extends the functionality of the GenericCard component, inheriting all its supported properties.
 * For a detailed list of available properties, please consult the [GenericCard documentation](./?path=/docs/alice-ui-card-genericcard--docs).
 */
export const EntitlementCard = ({
  variant = 'grid',
  size = 'm',
  entitlement = {},
  app = {},
  isContextShown = true,
  isDescriptionShown: _isDescriptionShown = size === 'l',
  isTypeShown: _isTypeShown = size === 'l',
  isAppRoleShown: _isAppRoleShown = false,
  isAppShown: _isAppShown = size === 'm' || (size === 'l' && variant === 'grid'),
  isClassificationShown: _isClassificationShown = true,
  // base card
  isLoading = false,
  isIdLink = true,
  isSelected = false,
  isDisabled = false,
  isLogoShown = true,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const { getDetailItem } = useDetailItem()
  const { palette } = useTheme()

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({
    interaction,
    isLoading,
    isDisabled
  })

  const {
    uuid: id,
    entitlementId,
    displayName,
    description,
    dataClassification,
    type,
    applicationId,
    roleId
  } = entitlement || {}

  // main props
  const isList = variant === 'list'
  const isGrid = !isList

  const isAppRole = applicationId && roleId

  // display props
  const isDividerShown = isGrid && size === 'l'
  const isDescriptionShown = _isDescriptionShown && description
  const isTypeShown = _isTypeShown && type
  const isAppRoleShown = _isAppRoleShown // todo: check logic

  const isAppShown = _isAppShown && app?.id
  const isClassificationShown = _isClassificationShown && dataClassification

  const isDetailsListShown = isDescriptionShown || isTypeShown || isAppRoleShown

  const isDividedShown = !!(
    (isList && isContextShown)
    || isClassificationShown
  )

  // positioning props
  const isContentShifted = isSelectable && !isSelectHidden && isGrid

  const detailsList = [
    isTypeShown && {
      text: type,
      label: 'Type',
      icon: <TextSnippetOutlined />
    },
    isAppRoleShown && {
      text: (
        <IconWrapper size={20} color={palette[isAppRole ? 'success' : 'error'].main}>
          {isAppRole
            ? <CheckCircleOutline />
            : <RemoveCircleOutline />}
        </IconWrapper>
      ),
      label: 'Application Role'
    }
  ].filter((entry) => entry)

  const onCardClick = _onCardClick ? ({
    id,
    e,
    isSelected
  }) => {
    _onCardClick({
      id,
      e,
      isSelected,
      data: entitlement
    })
  } : undefined

  const context = (
    <ContextBtn
      ContextPopover={EntitlementContextPopover}
      appId={app.id}
      entitlementId={entitlementId}
      isLoading={isLoading}
    />
  )

  return (
    <GenericCard
      type="entitlementCard"
      id={id}
      isList={isList}
      interaction={interaction}
      onCardClick={onCardClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isDividedShown={isDividedShown}
      sx={mergeSxProps({
        '.select': {
          display: 'flex',
          alignItems: 'center',
          height: 56
        }
      }, sx)}
      {...otherProps}
    >
      <CardContent
        context={context}
        isList={isList}
        isContextShown={isContextShown}
        sx={[
          { gridTemplate: `'id ${isDescriptionShown ? 'description' : ''} ${isDetailsListShown ? repeatString(detailsList.length, 'detailsList') : ''} ${isAppShown ? 'app' : ''}' / 1fr ${isDescriptionShown ? '1fr' : ''} ${isDetailsListShown ? repeatString(detailsList.length, '1fr') : ''} ${isAppShown ? '1fr' : ''}` },
          isGrid && {
            gridTemplate: `
             'id context'
             ${isDividerShown ? "'divider divider'" : ''}
             ${isDescriptionShown ? '"description description"' : ''}
             ${isDetailsListShown ? '"detailsList detailsList"' : ''}
             ${isAppShown ? '"app app"' : ''}
             / 1fr`
          }
        ]}
      >
        <IdTitle
          isLink={isIdLink}
          isLoading={isLoading}
          href={`${getClientLink('admin')}/applications/${app.id}/entitlements/${entitlement.entitlementId}`}
          id={entitlementId}
          title={displayName}
          sx={{ gridArea: 'id' }}
        />

        {isDividerShown && (
          <Divider
            sx={[
              { gridArea: 'divider' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isDescriptionShown && (
          <DetailsList
            data={[
              getDetailItem({
                type: DetailItemType.DESCRIPTION,
                text: description
              })
            ]}
            isLoading={isLoading}
            sx={[
              { gridArea: 'description' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isDetailsListShown && (
          <DetailsList
            data={detailsList}
            isSubgrid={isList}
            isLoading={isLoading}
            sx={[
              { gridArea: 'detailsList' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isAppShown && (
          <AppCard
            data={app}
            variant="grid"
            size="s"
            isLogoShown={isLogoShown}
            isContextShown={false}
            isLoading={isLoading}
            sx={[
              { gridArea: 'app', backgroundColor: 'common.white' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}
      </CardContent>

      <CardDivided
        isShown={isDividedShown}
        isList={isList}
        isContextShown={isContextShown}
        context={context}
      >
        {isClassificationShown && (
          <DataClassificationChip
            size="s"
            type={dataClassification}
            isLoading={isLoading}
          />
        )}
      </CardDivided>
    </GenericCard>
  )
}

EntitlementCard.propTypes = {
  /** Application data to display within the card. */
  app: PropTypes.shape({
    /**
     * A base64-encoded image data representing the logo to be displayed.
     * If not provided, a default logo will be used.
     */
    logo: PropTypes.string,
    /** Application ID */
    id: PropTypes.string,
    /** Application url */
    url: PropTypes.string,
    /** Support email */
    email: PropTypes.string,
    /** Application name */
    name: PropTypes.string
  }),
  /** Entitlement data to display within the card */
  entitlement: PropTypes.shape({
    uuid: PropTypes.string,
    entitlementId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dataClassification: DataClassificationPropType.isRequired,
    connectivity: PropTypes.bool,
    type: EntitlementTypePropType
  }).isRequired,
  isContextShown: PropTypes.bool,
  isDescriptionShown: PropTypes.bool,
  isTypeShown: PropTypes.bool,
  isAppRoleShown: PropTypes.bool,
  isAppShown: PropTypes.bool,
  isClassificationShown: PropTypes.bool,
  // base props
  /** Variant of the card */
  variant: PropTypes.oneOf([ 'grid', 'list' ]),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'm', 'l' ]),
  /** Loading state. If `true` placeholder will be shown */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note:
   * Even if `isIdLink` is true,
   * visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Specifies if the card is selected or not */
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubgrid: PropTypes.bool,
  interaction: InteractionPropTypes,
  /** Callback function when card gets clicked */
  onCardClick: PropTypes.func,
  sx: PropTypes.any
}
