import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Stepper } from "@alice/component_library";
import { useWidth } from "@utils/hooks";

const RoleRequestStepper = ({
  type,
  handleActiveStep,
  stepperRef,
  selectedRolesLength = 0,
  selectedUserName = "",
  initialStep = 0,
  isFromDeeplink = false,
}) => {
  const { t } = useTranslation();
  const widthPanel = useWidth();
  const isMobile = ["xs"].includes(widthPanel);
  const createStepsConfig = useCallback(() => {
    const steps = [
      {
        content: selectedRolesLength
          ? `${selectedRolesLength} ${t(
              selectedRolesLength > 1 ? "roles" : "role"
            )}`
          : t("user_pleaseSelect"),
        iconType: !isMobile ? "KEYS" : undefined,
        title: t("role-selection"),
      },
      {
        iconType: !isMobile ? <SettingsOutlinedIcon /> : undefined,
        title: t("role-configuration"),
      },
      {
        iconType: !isMobile ? <LibraryAddCheckOutlinedIcon /> : undefined,
        title: t("conditions-reason"),
      },
      {
        iconType: !isMobile ? <SummarizeOutlinedIcon /> : undefined,
        title: t("summary"),
      },
    ];

    return isFromDeeplink
      ? steps.slice(1)
      : type === "myself"
      ? steps
      : [
          {
            content: selectedUserName ?? t("user_pleaseSelect"),
            iconType: !isMobile ? <PersonOutlineOutlinedIcon /> : undefined,
            title: t("user-selection"),
          },
          ...steps,
        ];
  }, [
    isFromDeeplink,
    isMobile,
    selectedRolesLength,
    selectedUserName,
    t,
    type,
  ]);
  return (
    <div className="stepper-container">
      <Stepper
        ref={stepperRef}
        onActiveStep={handleActiveStep}
        size="big"
        steps={createStepsConfig()}
        initialStep={initialStep}
        mobileBreakpoint="sm"
      />
    </div>
  );
};

export default RoleRequestStepper;
