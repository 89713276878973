import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../util'

export const CustomGrid = forwardRef((
  {
    children,
    sx = [],
    ...otherProps
  },
  ref
) => (
  <Grid
    data-testid="CustomGrid"
    ref={ref}
    sx={mergeSxProps({ p: 3 }, sx)}
    container
    {...otherProps}
  >
    <Grid item xs={0} xl={2} />

    <Grid item xs={12} xl={8}>
      {children}
    </Grid>

    <Grid item xs={0} xl={2} />
  </Grid>
))

CustomGrid.propTypes = {
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
