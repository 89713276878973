import { overViewActionTypes } from "@constants/actionTypes";
const initialState = {
  requestCountInfo: {
    tottalCount: 0,
    roleRequestCount: 0,
    entitileMentCount: 0,
    techUserCount: 0,
    orgAdminCount: 0,
    deprovisioningCount: 0,
    usercreationCount: 0,
    orgCount: 0,
  },
  requests: {
    data: [],
    loading: false,
    error: false,
    isLoaded: false,
  },
  taskInfo: {
    loading: false,
    errorInfo: { error: false, errorMessage: "" },
    allTasks: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    roleRequest: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    workflowInfo: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    deProvisionInfo: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    techUserToapplication: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    entitelementToRole: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
  },
  notification: { count: 0, isLoaded: false },
  recentRequestActivity: {
    loading: false,
    recentRequestActivity: [],
    isLoaded: false,
  },
  requestDeeplink: {
    loading: false,
    isLoaded: false,
    selectedTask: {},
  },
};

const RequestOverViewReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case overViewActionTypes.FETCH_REQUEST_OVERVIEWS:
      return (state = {
        ...state,
        requests: data,
      });
    case overViewActionTypes.FETCH_RECENT_REQUEST:
      return (state = {
        ...state,
        recentRequestActivity: data,
      });
    case overViewActionTypes.STORE_REQUEST_DEEPLINK:
      return (state = {
        ...state,
        requestDeeplink: data,
      });
    case overViewActionTypes.UPDATE_REQUEST_NOTIFICATION:
      return (state = {
        ...state,
        notification: data,
      });
    case overViewActionTypes.SET_REQUEST_COUNT:
      return (state = {
        ...state,
        requestCountInfo: data.data,
      });
    case overViewActionTypes.SET_OVERVIEW_TASK_INFO:
      return (state = {
        ...state,
        taskInfo: {
          ...state.taskInfo,
          [data.structureInfo]: {
            ...state.taskInfo[data.structureInfo],
            data: data.data,
            paginationInfo: data.paginationInfo,
            fetchInfo: data.fetchInfo,
            isLoaded: false,
          },
          errorInfo: data.errorInfo,
          loading: data.loading,
        },
      });

    default:
      return state;
  }
};
export default RequestOverViewReducer;
