import { ManageAccountsRounded, MemoryRounded, SmartToyRounded } from '@mui/icons-material'
import { Avatar, Badge, Box } from '@mui/material'
import axios from 'axios'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import { UserCommunity } from '../../../../constants'
import { getContrastColor, getEnvironment, getSecialCommunityForIcon, getUserCommunities, stringToColor } from '../../../../util'
import { FocusDot } from '../../../projectFocus'
import { Skeleton } from '../../../skeleton'

const getSize = (size) => {
  switch (size) {
    case 's':
      return {
        height: 40,
        width: 40,
        fontSize: '1rem',
        dotSize: '16px'
      }
    case 'l':
      return {
        height: 64,
        width: 64,
        fontSize: '1.5rem',
        dotSize: '20px'
      }
    case 'm':
    default:
      return {
        height: 56,
        width: 56,
        fontSize: '1.25rem',
        dotSize: '18px'
      }
  }
}

const getNameObject = (name) => {
  if (name === undefined || name === '') return { firstName: '0', lastName: '0' }

  const arr = name.split(' ').filter((ele) => ele !== '')
  return { firstName: arr[0], lastName: arr[1] || '' } // Fix if no lastName is provided
}

const stringAvatar = (name, parentSx) => {
  const { firstName } = getNameObject(name)
  let lastName

  if (getNameObject(name).lastName) lastName = getNameObject(name).lastName
  else lastName = getNameObject(name).firstName.substr(1)

  return {
    sx: {
      ...parentSx,
      bgcolor: stringToColor(name),
      border: '1px solid white',
      color: getContrastColor(stringToColor(name))
    },
    children: `${firstName[0]}${lastName[0]}`
  }
}

const isUserId = (userId) => {
  if (!userId) return false

  return !(userId.length > 8 || userId.includes(' ') || userId.includes('-') || userId.includes('_'))
}

// eslint-disable-next-line consistent-return
const Icon = ({ community, sx }) => {
  switch (community) {
    case UserCommunity.TECH_USER:
      return (<MemoryRounded sx={sx} />)
    case UserCommunity.BOT_USER:
      return (<SmartToyRounded sx={sx} />)
    case UserCommunity.TEST_USER:
    default:
      return (<ManageAccountsRounded sx={sx} />)
  }
}

/* Component to display a user avatar or placeholder if no image is provided */
export const UserAvatar = forwardRef((
  {
    groupType,
    showGroupType,
    showImage = false,
    size = 'm',
    sizeStyle = getSize(size),
    userData,
    userId,
    userName,
    communities: _communities,
    isLoading = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const env = getEnvironment()
  const communities = getUserCommunities(_communities)
  const showIcon = !!getSecialCommunityForIcon(communities)

  // Make it compatible with older releases
  if (userData) {
    userId = userData?.id
    userName = userData?.completeName
    groupType = userData?.groupType
  } else {
    userId = userId ?? ''
    userName = userName ?? ''
  }

  const validGroupTypes = [ 0, 1, '0', '1', 'S', 's' ]
  if (validGroupTypes.includes(groupType)) groupType = groupType.toString()

  const [ image, setImage ] = useState(null)

  useEffect(() => {
    if (groupType && groupType !== '0') return // do nothing because he is not a MBAG user

    // enable call for userImage only for certain env
    const showInEnv = [ 'git', 'int', 'test', 'prod' ]

    if (showImage && isUserId(userId) && showInEnv.includes(env)) {
      axios
        .get(`/images/${userId}/teams?defaultImage=false`, { responseType: 'blob' })
        .then((resp) => { if (resp?.data?.type === 'image/jpeg') setImage(URL.createObjectURL(resp.data)) })
        .catch((error) => console.log(error))
    }
  }, [ userId, env, showImage, groupType ])

  return (
    <Box
      data-testid="UserAvatar"
      ref={ref}
      {...otherProps}
    >
      {isLoading ? (
        <Skeleton
          variant="circular"
          sx={[
            size === 's' && { width: '40px', height: '40px' },
            size === 'm' && { width: '56px', height: '56px' },
            size === 'l' && { width: '64px', height: '64px' }
          ]}
        />
      ) : (
        <>
          {/* No data and no picture */}
          {!userId && !image && (
            <Avatar
              {...stringAvatar('0 0', {
                ...sizeStyle,
                ...(sx)
              })}
            />
          )}

          {/* Data but no picture */}
          {userId && !image && (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                showGroupType && groupType !== undefined ? (
                  <FocusDot groupType={groupType} sizeStyle={sizeStyle.dotSize} />
                ) : null
              }
            >
              {showIcon
                ? (
                  <Avatar
                    {...stringAvatar(userName, {
                      ...sizeStyle,
                      ...(sx)
                    })}
                  >
                    <Icon community={getSecialCommunityForIcon(communities)} sx={{ color: 'black', fontSize: '40px' }} />
                  </Avatar>
                )
                : (
                  <Avatar
                    {...stringAvatar(userName, {
                      ...sizeStyle,
                      ...(sx)
                    })}
                  />
                )}
            </Badge>
          )}

          {/* Data and picture */}
          {userId && image && (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                showGroupType && groupType !== undefined ? (
                  <FocusDot groupType={groupType} sizeStyle={sizeStyle.dotSize} />
                ) : null
              }
            >
              <Avatar
                src={image}
                sx={{
                  ...sizeStyle,
                  ...(sx)
                }}
              />
            </Badge>
          )}
        </>
      )}
    </Box>
  )
})

UserAvatar.propTypes = {
  /** User data object (deprecated, do not use anymore) */
  userData: PropTypes.object,
  /** User name */
  userName: PropTypes.string.isRequired,
  /** User ID */
  userId: PropTypes.string.isRequired,
  /** Group type: 0, 1, 'S' */
  groupType: PropTypes.oneOf([ '0', '1', 'S', 0, 1 ]),
  /** Determines whether the group type indicator should be shown or not */
  showGroupType: PropTypes.bool,
  /** Size of the avatar */
  size: PropTypes.oneOf([ 's', 'm', 'l' ]),
  /** Custom styles for the size of the avatar */
  sizeStyle: PropTypes.shape({
    height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    fontSize: PropTypes.string,
    dotSize: PropTypes.string
  }),
  /** Determines whether the image should be shown or not */
  showImage: PropTypes.bool,
  /** Loading state */
  isLoading: PropTypes.bool,
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
