import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Breadcrumbs, Grid } from "@mui/material";

import {
  UserAvatar,
  UserContextPopover,
  useUser,
} from "@alice/component_library";
import { FocusChip, Inlay, Card } from "@alice/component_library";
import { Layout } from "@alice/component_library";
import AccessRequestSkeleton from "@components/Skeleton/AccessRequestSkeleton";
import { managementValue, managementList } from "@utils/managementValue";
import {
  ResetFetchRoleById,
  FetchRoleByID,
  FetchOrgByID,
  ResetOrgByID,
  CheckRoleAvailability,
} from "@actions/roleActions";
import { FetchUserById } from "@actions/userActions";
import RoleEditOptions from "../user/roleEditOptions";
import NoInfoIcon from "@pageIcons/NoRolesNoUsers";
import Comments from "../user/costs";

import { PrimaryButton } from "@components/button";
import { submitData } from "@actions/roleActions";

import { user } from "@alice/component_library";
import { grey } from "@mercedes-benz/mui5-theme";

import { hasData } from "@utils/Validator";
import { useQuery } from "@core/hooks";
import "@root/src/App.css";
import "@root/src/Icon.css";
import "../user/summary.css";
import { DateFormatter, Formats, isValidDate } from "@utils/dateFormat";
import { DayLabel } from "@components/sharedComponentTranslation";

import { RoleCardV2 } from "@components/roleElements/RoleCardV2";
import { useWidth } from "@utils/hooks";
import { styled } from "@mui/material/styles";

const CommonSubRoleStyle = {
  margin: "0 20px",
};

const StyledSubRoleGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: CommonSubRoleStyle,
}));

const StyledSubRoleCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down("md")]: CommonSubRoleStyle,
}));

const StyledBreadCrumbContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "20px 20px",
  },
}));

const StyledBreadCrumb = styled(Breadcrumbs)(({ theme }) => ({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "14px",
  color: "#212121",
}));

const InvalidRoleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  minHeight: "200px",
}));

const SubRoleEditContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
  "& .MuiBox-root .css-1jqg7x9": {
    display: "none",
  },
}));

const ButtonWrapper = styled("div")({
  flexDirection: "row",
  padding: "12px 0px 12px 5px",
  marginTop: "0px",
  backgroundColor: grey[95],
});

const StyledSubSubmitButton = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingRight: "20px",
  },
}));

const StyledSubExtends = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const StyledsubSelectRole = styled("p")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginLeft: "20px",
  },
}));

function AccessRequest(props) {
  const { user: userUtils } = useUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const dayLabel = DayLabel();
  const logginUser = userUtils;
  const userloggedin = logginUser?.id;
  const queryUser = query.get("user") ?? userloggedin;
  const role = query.get("role") ?? "";
  const mode = query.get("mode") ?? "";
  const orgScope = query.get("orgScope") ?? "";
  const custScope = query.get("custScope") ?? "";
  const validFromUrl = query.get("validFrom") ?? "";

  const roleById = useSelector((state) => state.role.roleById);
  const orgById = useSelector((state) => state.role.orgById);
  const userById = useSelector((state) => state.user.userById);
  const hasCCAccess = useSelector((state) => state.controlCenter.hasCCAccess);
  const [showInlay, setShowInaly] = useState(false);
  const [comment, setComment] = useState("");
  const [validity, setValidity] = useState({});
  const [scopes, setScopes] = useState([]);
  const [validScope, setValidScope] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [roleInfo, setRoleInfo] = useState({});
  const inlayRefComponent = useRef(null);
  const isInsights = location?.pathname === "/access/accessRequest/insights";
  const isExtend = mode === "extend";
  const isReapply = mode === "reapply";
  const isExtendorReApply = isExtend || isReapply;

  const validFromUrlDecoded =
    validFromUrl.length && isValidDate(decodeURI(validFromUrl), "YYYY-MM-DD")
      ? validFromUrl
      : "";

  useEffect(() => {
    document.getElementById("scroller").scroll(0, 0);
    return () => {
      dispatch(ResetFetchRoleById());
    };
  }, []);

  useEffect(() => {
    if (queryUser.toLowerCase() !== userUtils.id?.toLowerCase()) {
      if (hasCCAccess) dispatch(FetchUserById(queryUser));
    }
    const fetchData = () => {
      dispatch(FetchRoleByID(queryUser, role, !isInsights));
      if (orgScope) {
        dispatch(FetchOrgByID(queryUser, role, orgScope));
      } else {
        dispatch(ResetOrgByID());
      }
    };

    const fetchRoleAvailability = async () => {
      const result = await CheckRoleAvailability(
        queryUser,
        role,
        orgScope,
        custScope
      );
      if (result?.length > 0) fetchData();
    };
    if (isExtendorReApply) fetchRoleAvailability();
    else fetchData();
  }, [
    dispatch,
    orgScope,
    queryUser,
    userUtils.id,
    hasCCAccess,
    isInsights,
    role,
    custScope,
    isExtendorReApply,
  ]);

  useEffect(() => {
    if (showInlay && inlayRefComponent.current) {
      inlayRefComponent.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [showInlay]);

  useEffect(() => {
    const scopeItem = {};
    const data = roleInfo;
    let isValidOrg = false;
    let isValidCust = false;
    setValidScope(false);
    setScopes([]);
    if (hasData(orgById?.data)) {
      const { name = "", id = "", orgGroupType = "" } = orgById?.data;
      scopeItem.orgScope = name;
      scopeItem.orgId = id;
      scopeItem.groupType = orgGroupType;
      isValidOrg = true;
    }

    if (data?.needsCustomScopes) {
      const customScope = data?.customScopes?.find((d) => d.name === custScope);
      if (hasData(customScope)) {
        scopeItem.custScope = customScope?.name ?? "";
        isValidCust = true;
      }
    }
    if (hasData(scopeItem)) setScopes([scopeItem]);

    let tempFlag = false;
    if (data?.needsOrgScopes && data?.needsCustomScopes) {
      if (isValidOrg && isValidCust) tempFlag = true;
    } else if (data?.needsOrgScopes && data?.needsCustomScopes === false) {
      if (isValidOrg) tempFlag = true;
    } else if (data?.needsOrgScopes === false && data?.needsCustomScopes) {
      if (isValidCust) tempFlag = true;
    } else if (
      data?.needsOrgScopes === false &&
      data?.needsCustomScopes === false
    ) {
      tempFlag = true;
    }
    setValidScope(tempFlag);
  }, [orgById?.data, custScope, roleInfo, roleInfo.id]);

  useEffect(() => {
    const { data } = roleById;
    setRoleInfo(data);
  }, [roleById]);

  const widthPanel = useWidth();

  useEffect(() => {
    switch (widthPanel) {
      case "sm":
      case "xs":
        setIsSmall(true);
        break;
      default: {
        setIsSmall(false);
      }
    }
  }, [widthPanel]);

  const getLoader = () => <AccessRequestSkeleton />;
  if (roleById.loading || orgById.loading || userById.loading) {
    return getLoader();
  }

  const isInvalidRole = () => !hasData(roleInfo);

  if (userUtils === undefined || userUtils === null) return null;

  const getBreadCrumb = () => {
    const defaultStyle = {
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "400",
      fontFamily: "MB Corpo S Text",
      color: "black",
    };
    const items = [{ label: "navTargets.Home", path: "" }];

    if (isExtendorReApply) {
      items.push({
        label: isInsights ? "navTargets.control-center" : "navTargets.Profile",
        path: isInsights ? `insights` : "profile/roles",
      });
      if (isInsights) {
        items.push({
          label: queryUser,
          path: `insights/user/${queryUser}`,
        });
      }
      items.push({
        label: isExtend ? "modify-role" : "reapply-button",
        path: "",
      });
    } else items.push({ label: "access-request", path: "" });

    const count = items?.length - 1;
    return (
      <StyledBreadCrumbContainer>
        <StyledBreadCrumb aria-label="breadcrumb">
          {items.map((d, i) => (
            <>
              {count !== i ? (
                <Link
                  style={{
                    ...defaultStyle,
                    color: count === i ? "#707070" : "#212121",
                    cursor: count === i ? "default" : "pointer",
                    textDecoration: "none",
                  }}
                  to={`/access/${d.path}`}
                >
                  {t(d.label)}
                </Link>
              ) : (
                t(d.label)
              )}
            </>
          ))}
        </StyledBreadCrumb>
      </StyledBreadCrumbContainer>
    );
  };

  const getCard = (template) => (
    <Grid container spacing={2}>
      <StyledSubRoleGrid item xs={12} sm={12} md={12} lg={6}>
        <p className="select_role">{t("selected-user")}</p>

        <Grid className="m-0 role-grid-panel role-grid-panel">
          <Card>{template}</Card>
        </Grid>
      </StyledSubRoleGrid>
    </Grid>
  );

  const getUser = () => {
    let reqUser = userUtils;
    if (queryUser.toLowerCase() !== userUtils.id?.toLowerCase())
      if (isExtendorReApply) reqUser = userById?.data;
      else reqUser = null;
    return reqUser;
  };
  const getUserCard = () => {
    const reqUser = getUser();
    if (!hasData(reqUser)) {
      return getCard(
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <NoInfoIcon width={24} height={24} isUser={true} />
          <div> {t("user-is-not-logged-in-user")} </div>
        </div>
      );
    }

    const { level = "", departmentNumber = "", groupType, id } = reqUser;
    return getCard(
      <Grid className="p-10">
        <div style={{ display: "flex" }}>
          <div>
            <UserContextPopover id={id}>
              <UserAvatar
                userId={id}
                userName={`${reqUser.givenname}, ${reqUser.surname}`}
                groupType={reqUser.groupType}
                showGroupType={true}
                showImage={user.is.internalEmployee(reqUser?.communities)}
              />
            </UserContextPopover>
          </div>
          <div className="mt-6 ml-20 font-16-mob">
            <div className="clr-black font-20 flex user_name">
              <span className="pr-1">{`${reqUser.givenname}, ${reqUser.surname}`}</span>
              {managementList.includes(level)
                ? " (" + managementValue(level) + ")"
                : ""}
              <FocusChip groupType={groupType} showTooltip />
            </div>
            <div className="Reapply_subTitle">{reqUser.mailAddress}</div>
            <div className="Reapply_subTitle">
              {departmentNumber ? id + " (" + departmentNumber + ")" : id}
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  const getValidity = () => {
    const {
      defaultValidityType,
      defaultValidityDays = 180,
      istimeFrame,
    } = roleInfo;

    if (hasData(validity)) return validity;
    let validFrom = null;
    let validTo = null;
    if (["FIXED", "OPTIONAL", "MANDATORY"].includes(defaultValidityType)) {
      validFrom = DateFormatter.format(new Date());
      if (
        validFromUrlDecoded?.length &&
        !isReapply &&
        defaultValidityType !== "FIXED"
      ) {
        validFrom = DateFormatter.format(new Date(validFromUrlDecoded));
      }
      validTo = DateFormatter.add(new Date(), {
        type: "days",
        validity: defaultValidityDays,
      });
    }
    setValidity({ validFrom, validTo, istimeFrame });
    return { validFrom, validTo };
  };

  const updateHandler = ({ validFrom, validTo, scopes, istimeFrame }) => {
    setValidity({ validFrom, validTo, istimeFrame });
    setScopes(scopes);
    setShowInaly(false);
    setValidScope(true);
  };

  const getInlay = () => {
    const { data = {} } = roleById;
    const { istimeFrame } = validity;
    data.selectedScopes = validScope ? scopes : [];
    data.istimeFrame =
      istimeFrame === undefined ? data.istimeFrame : istimeFrame;

    return (
      <Inlay open={showInlay} anchorElement={null} ref={inlayRefComponent}>
        <RoleEditOptions
          setEditOptionsChange={() => setShowInaly(false)}
          id={data.id}
          isAddRoleOpen={false}
          addRoledata={data}
          accessFlag
          isReapply={isReapply}
          isMode
          updateHandler={(d) => updateHandler(d)}
          validity={validity}
          preSelect={!validScope ? scopes[0] : {}}
        />
      </Inlay>
    );
  };

  const getInavalidRole = () => (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <StyledSubRoleCard>
          <InvalidRoleContainer>
            <NoInfoIcon width={24} height={24} />
            <div> {t("role-not-found")} </div>
          </InvalidRoleContainer>
        </StyledSubRoleCard>
      </Grid>
    </Grid>
  );

  const getRoleCard = () => {
    const { data } = roleById;

    if (isInvalidRole() || !hasData(getUser())) {
      return getInavalidRole();
    }
    const { validFrom, validTo } = getValidity();
    data.selectedScopes =
      scopes.length > 0 ? scopes : [{ name: data.name, id: data.id }];
    data?.selectedScopes?.forEach((d, i) => {
      const cardDetails = {};
      cardDetails.roleDefinition = { name: data.name, id: data.id };
      cardDetails.validFrom = isExtend ? d.validFrom ?? validFrom : validFrom;
      cardDetails.validTo = validTo;
      cardDetails.needsOrgScopes = data.needsOrgScopes;
      cardDetails.needsCustomScopes = data.needsCustomScopes;
      if (data.needsOrgScopes) {
        cardDetails.orgScope = { name: d?.orgScope, id: d?.orgId };
      }
      if (data.needsCustomScopes) {
        cardDetails.customScope = { name: d?.custScope };
      }
      d.cardDetails = cardDetails;
    });
    return (
      <Grid container spacing={2}>
        {data?.selectedScopes.map((selectedScopes, i) => (
          <>
            <StyledSubRoleGrid
              item
              xs={12}
              sm={12}
              md={i === 0 ? 12 : 6}
              lg={i === 0 ? 12 : 6}
            >
              <RoleCardV2
                data={selectedScopes.cardDetails}
                hideActions={true}
                size={isSmall ? "small" : "medium"}
                daysLeftMessage={dayLabel}
                showModifyButton={
                  i === 0
                    ? !validScope || isInvalidRole() || !hasData(getUser())
                      ? isReapply || !mode.length
                        ? true
                        : false
                      : true
                    : false
                }
                onModify={(item) => setShowInaly(!showInlay)}
              />
            </StyledSubRoleGrid>
            {i === 0 ? (
              <SubRoleEditContainer item lg={12} xs={12} xl={12} md={12}>
                {showInlay ? getInlay() : null}
              </SubRoleEditContainer>
            ) : null}
          </>
        ))}
      </Grid>
    );
  };

  const getComments = () => {
    return (
      <Comments
        handleCostField={(e) => {
          setComment(
            e?.target?.value?.trim().length > 0
              ? e?.target?.value
              : e?.target?.value.trim()
          );
        }}
        costsField={comment}
        disabledFlag={isInvalidRole() || !hasData(getUser())}
      />
    );
  };

  const handleSubmit = () => {
    const { validFrom, validTo } = validity;
    const format = Formats.YYYYMMDDH;
    let fromDate = validFrom ? DateFormatter.format(validFrom, format) : null;
    let toDate = validTo ? DateFormatter.format(validTo, format) : null;
    const reqUser = getUser();
    const fullName = reqUser?.givenname + " " + reqUser?.surname;
    const { needsOrgScopes, needsCustomScopes, id, name } = roleInfo;
    const { defaultValidityType } = roleInfo;
    if (hasData(scopes)) {
      for (let i = 0; i < scopes?.length; i++) {
        dispatch(
          submitData(
            reqUser.id,
            id,
            needsOrgScopes,
            needsCustomScopes,
            scopes[i]?.orgScope,
            scopes[i]?.orgId,
            scopes[i]?.custScope,
            scopes[i]?.cardDetails.validFrom
              ? DateFormatter.format(scopes[i]?.cardDetails.validFrom, format)
              : null,
            toDate,
            comment,
            fullName,
            name,
            defaultValidityType
          )
        );
      }
    } else {
      dispatch(
        submitData(
          reqUser.id,
          id,
          needsOrgScopes,
          needsCustomScopes,
          "",
          "",
          "",
          fromDate,
          toDate,
          comment,
          fullName,
          name,
          defaultValidityType
        )
      );
    }
    props.saveHandler();
  };

  const getActionButton = () => {
    return (
      <Grid item className=" bg-clr-darkgrey sticky zI">
        <ButtonWrapper>
          <Layout>
            <StyledSubSubmitButton className="fr">
              <PrimaryButton
                label={t("submit")}
                onButtonClick={() => handleSubmit()}
                className="btn-ml-26"
                disabled={
                  !validScope ||
                  isInvalidRole() ||
                  !hasData(getUser()) ||
                  comment.trim().length < 20
                }
              />
            </StyledSubSubmitButton>
          </Layout>
        </ButtonWrapper>
      </Grid>
    );
  };

  const getHeader = () => {
    let header = "request_header_newRoleRequest";
    let subHeader = "Confirmation";
    if (mode === "extend") {
      header = "modify-role-banner-title";
      subHeader = "modify-role-banner-subtitle";
    } else if (mode === "reapply") {
      header = "reapply-banner-title";
      subHeader = "reapply-banner-subtitle";
    }

    return (
      <StyledSubExtends className="header-firstTab">
        <Grid item className="header-text">
          <div className="header-text">
            <p className="mb-0 mt-12 Reapply_Title ">{t(header)}</p>
            <p className="mt-0 Reapply_subTitle">{t(subHeader)}</p>
          </div>
        </Grid>
      </StyledSubExtends>
    );
  };

  return (
    <>
      <Grid item sx={{ flexGrow: 1 }}>
        <Layout>
          {getHeader()}
          {getBreadCrumb()}
          {getUserCard()}

          <Grid container className="mt-16">
            <StyledsubSelectRole className="select_role">
              {t("selected_roles")}
            </StyledsubSelectRole>
            {getRoleCard()}
          </Grid>
          {getComments()}
        </Layout>
      </Grid>
      {getActionButton()}
    </>
  );
}

export default AccessRequest;
