import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DeviceHubOutlined, HighlightOffOutlined } from '@mui/icons-material'
import { Divider, Typography } from '@mui/material'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { getFineGranularUserCommunity, getSecialCommunityForIcon, getUserCommunities, mergeSxProps, repeatString, USER_SPECIAL_COMMUNITIES } from '../../../util'
import { IconButton } from '../../button'
import { CardContent, CardDivided, ContextBtn, DetailItemType, DetailsList, GenericCard, useDetailItem } from '../../card/genericCard'
import { CardInfoChip, StatusChip } from '../../chip'
import { UserAvatarInfo } from '../UserAvatar'
import { useInteraction } from '../../../hooks'
import { UserContextPopover } from '../UserContext'
import { useUserCard } from './Components'

/** The UserCard component is a versatile React card component designed to display user information in a visually
 *  appealing manner. It offers various customization options and is particularly useful in scenarios where user
 *  details need to be presented in different layouts and styles.
 *
 * ## Usage
 *
 * The UserCard component is designed to be highly customizable and adaptable to different use cases.
 * It extends the functionality of the ``GenericCard`` component, inheriting all its supported properties.
 * For a detailed list of available properties, please consult the [GenericCard documentation](./?path=/docs/alice-ui-card-genericcard--docs).
 *
 * ### Notes
 * - The `variant` prop allows you to choose from different card layouts, providing flexibility based on your
 * specific design requirements.
 * - The `userCardInfoChip` is intended to take dynamic component CardInfoChip (See links below)
 * - The `contactData` prop takes an array of objects. Where each object should contain icon, content and label.<br>
 * Example:
 *
 * ```jsx
 * {
 *   icon: EmailOutlined, // You should import EmailOutlined from its source
 *   content: UserCardData.mailAddress,
 *   label: 'E-Mail'
 * }
 * ```
 * - For full functionality check the prop list below.
 *
 * ### List of integrated CLIB components
 * - [GenericCard](/docs/alice-ui-card-genericcard--docs)
 * - [UserAvatarInfo](/docs/alice-ui-userelements-useravatar-useravatarinfo--docs)
 * - [InfoList](/docs/alice-ui-card-genericcard-components-infolist--docs)
 * - [StatusChip](/docs/alice-ui-chip-statuschip--docs)
 * - [CardInfoChip](/docs/alice-ui-chip-cardinfochip--docs)
 *  */
export const UserCard = ({
  variant = 'grid',
  size = 'm',
  userData,
  isContextShown: _isContextShown,
  isEmailShown: _isEmailShown,
  isPhoneShown: _isPhoneShown,
  isCommunityShown: _isCommunityShown,
  isOrganisationShown: _isOrganisationShown,
  isInfoChipShown: _isInfoChipShown,
  isStatusShown: _isStatusShown,
  isAdminRolesOverwritten,
  showDetailedCommunity,
  additionalData = [],
  customInfoChip: getCustomInfoChip = (userData) => null,
  onDeleteClick: _onDeleteClick, // { id, data: userData, e }
  // base card
  subgridContentColumns = 1,
  subgridContentRows = 1,
  isLoading = false,
  isIdLink = true,
  isSelected = false,
  isDisabled = false,
  isSubgrid = false,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')
  const { getDetailItem } = useDetailItem()

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({
    interaction,
    isLoading,
    isDisabled
  })

  const {
    id,
    isActive,
    communities = [],
    mailAddress,
    mobileNumber,
    departmentNumber,
    homeOrgName
  } = userData || {}

  // main props
  const isList = variant === 'list'
  const isGrid = !isList

  const status = isActive !== undefined ? isActive ? 'active' : 'inactive' : null
  const userCommunities = getUserCommunities(communities)
  const CustomInfoChip = getCustomInfoChip(userData)
  // eslint-disable-next-line max-len
  const isCompanyNameShown = !userCommunities.map((community) => USER_SPECIAL_COMMUNITIES.includes(community)).some((foundCommunity) => foundCommunity)

  const {
    isTiny,
    isContextShown,
    isEmailShown,
    isPhoneShown,
    isCommunityShown,
    isOrganisationShown,
    isInfoChipShown,
    isStatusShown,
    isDividerShown,
    isDeleteShown,
    isContactListShown,
    isDetailsShown,
    isDividedHorizontal,
    isDividedShown
  } = useUserCard({
    size,
    isList,
    isSubgrid,
    mailAddress,
    mobileNumber,
    additionalData,
    communities,
    homeOrgName,
    isCompanyNameShown,
    departmentNumber,
    CustomInfoChip,
    status,
    _isContextShown,
    _isEmailShown,
    _isPhoneShown,
    _isCommunityShown,
    _isOrganisationShown,
    _isInfoChipShown,
    _isStatusShown,
    _onDeleteClick
  })

  const isContentShifted = isSelectable && !isSelectHidden && isGrid

  const userCommunity = () => {
    const userCommunities = []

    getUserCommunities(communities)
      ?.forEach((community) => {
        if (community === 'TECH_USER' || community === 'TEST_USER') {
          const fineCommunity = getFineGranularUserCommunity(communities, community)

          fineCommunity && showDetailedCommunity
            ? userCommunities.push(t(`userCommunity.${fineCommunity}`))
            : userCommunities.push(t(`userCommunity.${community}`))
        } else {
          userCommunities.push(t(`userCommunity.${community}`))
        }
      })

    return userCommunities.join(', ')
  }

  const contactList = [
    isEmailShown && getDetailItem({
      type: DetailItemType.EMAIL,
      text: mailAddress
    }),
    isPhoneShown && getDetailItem({
      type: DetailItemType.PHONE,
      text: mobileNumber
    }),
    ...additionalData
  ].filter((entry) => entry)

  const detailsList = [
    isCommunityShown && getDetailItem({
      type: DetailItemType.USER_COMMUNITY,
      text: userCommunity(),
      iconProps: { name: getSecialCommunityForIcon(communities) || communities[0] }
    }),
    isOrganisationShown && getDetailItem({
      type: DetailItemType.ORGANIZATION,
      text: homeOrgName
    })
  ].filter((entry) => entry)

  const onCardClick = _onCardClick ? ({
    id,
    isSelected,
    e
  }) => {
    _onCardClick({
      id,
      data: userData,
      isSelected,
      e
    })
  } : undefined

  const onDeleteClick = (e) => {
    e.preventDefault()

    _onDeleteClick({
      id,
      data: userData,
      e
    })
  }

  const context = (
    <ContextBtn
      ContextPopover={UserContextPopover}
      id={id}
      isLoading={isLoading}
      showDetailedCommunity={showDetailedCommunity}
    />
  )

  return (
    <GenericCard
      type="userCard"
      id={id}
      isList={isList}
      isTiny={isTiny}
      isSelected={isSelected}
      interaction={interaction}
      onCardClick={onCardClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isDividedShown={isDividedShown}
      isDividedHorizontal={isDividedHorizontal}
      isSubgrid={isSubgrid}
      subgridContentColumns={subgridContentColumns}
      subgridContentRows={subgridContentRows}
      sx={mergeSxProps([
        isGrid && !isTiny && {
          '.select': {
            display: 'flex',
            alignItems: 'center',
            height: 64
          }
        }
      ], sx)}
      {...otherProps}
    >
      <CardContent
        context={context}
        isList={isList}
        isContextShown={isContextShown}
        isDividedHorizontal={isDividedHorizontal}
        sx={[
          { gridTemplate: `'avatarInfo ${isContactListShown ? repeatString(contactList.length, 'contactList') : ''} ${isDetailsShown ? repeatString(detailsList.length, 'detailsList') : ''}' / 1fr ${isContactListShown ? repeatString(contactList.length, '1fr') : ''} ${isDetailsShown ? repeatString(detailsList.length, '1fr') : ''}` },
          isGrid && {
            gridTemplate: `
              'avatarInfo context'
              ${isDividerShown ? "'divider divider'" : ''}
              ${isContactListShown ? repeatString(contactList.length, '"contactList contactList"') : ''}
              ${isDetailsShown ? repeatString(detailsList.length, '"detailsList detailsList"') : ''}
              / 1fr`
          }
        ]}
      >
        <UserAvatarInfo
          user={userData}
          size={!isTiny ? 'l' : 's'}
          isIdLink={isIdLink}
          isLoading={isLoading}
          isAdminRolesOverwritten={isAdminRolesOverwritten}
          isGroupTypeShown
          isSurnameFirst
          sx={{ gridArea: 'avatarInfo' }}
        />

        {isDividerShown && (
          <Divider
            sx={[
              { gridArea: 'divider' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isContactListShown && (
          <DetailsList
            data={contactList}
            size="s"
            isLoading={isLoading}
            isVertical={isGrid}
            isSubgrid
            sx={[
              { gridArea: 'contactList' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isDetailsShown && (
          <DetailsList
            data={detailsList}
            size={size === 'xl' ? 'm' : 's'}
            isLoading={isLoading}
            isVertical={isGrid}
            isSubgrid
            sx={[
              { gridArea: 'detailsList' },
              isGrid && size === 'm' && { marginTop: -1 },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}
      </CardContent>

      <CardDivided
        isShown={isDividedShown}
        isList={isList}
        isTiny={isTiny}
        isHorizontal={isDividedHorizontal}
        isContextShown={isContextShown}
        context={context}
        action={isDeleteShown && (
          <IconButton
            hasSmallClickArea={isTiny}
            onClick={onDeleteClick}
            sx={{ fontSize: '24px' }}
          >
            <HighlightOffOutlined fontSize="inherit" />
          </IconButton>
        )}
      >
        {isInfoChipShown && (
          CustomInfoChip || (
            <CardInfoChip
              icon={<DeviceHubOutlined fontSize="inherit" />}
              contentText={(
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    color: 'text.primary',
                    lineHeight: '14px'
                  }}
                >
                  {departmentNumber}
                </Typography>
              )}
              isLoading={isLoading}
            />
          )
        )}

        {isStatusShown && (
          <StatusChip
            type={status}
            isLoading={isLoading}
            sx={{ marginLeft: 'auto' }}
          />
        )}
      </CardDivided>
    </GenericCard>
  )
}

UserCard.propTypes = {
  /** User object, that should be displayed */
  userData: PropTypes.shape({
    id: PropTypes.string,
    isActive: PropTypes.bool,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    mailAddress: PropTypes.string,
    mobileNumber: PropTypes.string,
    companyName: PropTypes.string,
    departmentNumber: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    communities: PropTypes.arrayOf(PropTypes.string)
  }),
  /** Defines whether to show context or not. */
  isContextShown: PropTypes.bool,
  /** Defines whether to show email or not. */
  isEmailShown: PropTypes.bool,
  /** Defines whether to show phone or not. */
  isPhoneShown: PropTypes.bool,
  /** Defines whether to show community or not. */
  isCommunityShown: PropTypes.bool,
  /** Defines whether to show Organisation or not. */
  isOrganisationShown: PropTypes.bool,
  /** Specifies if info chip is shown or not */
  isInfoChipShown: PropTypes.bool,
  /** Specifies if status chip is shown or not */
  isStatusShown: PropTypes.bool,
  isAdminRolesOverwritten: PropTypes.bool,
  /** Determines whether to show detailed community or not. */
  showDetailedCommunity: PropTypes.bool,
  /** to add additional data to existing infoList */
  additionalData: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      content: PropTypes.string,
      label: PropTypes.string
    })
  ),
  /**
   * to override default infoChip
   *
   * @param userData
   * @return node
   */
  customInfoChip: PropTypes.func,
  /** Special case only for xs size. Defines whether card is deletable or not.  */
  onDeleteClick: PropTypes.func,
  // base props
  /** Variant of the card */
  variant: PropTypes.oneOf([ 'grid', 'list' ]),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** Loading state. If `true` placeholder will be shown */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note:
   * Even if `isIdLink` is true,
   * visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Specifies if the card is selected or not */
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubgrid: PropTypes.bool,
  interaction: InteractionPropTypes,
  /** Callback function when card gets clicked */
  onCardClick: PropTypes.func,
  sx: PropTypes.any
}
