import { memo, useCallback, useState } from "react";
import { AliceIcon, AliceIconType, Badge } from "@alice/component_library";
import { blue } from "@mercedes-benz/mui5-theme";
import {
  TaskMenuItemIcon,
  TaskMenuItems,
  TaskMenuItemsBadge,
  TaskMenuItemsHeader,
} from "./StyledComponents";
import { getUUID } from "@src/utils";
import { InputControl } from "@utils/inputControls";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import RoleRequestIcon from "@Icons/taskpage/taskMenu/RoleRequest.svg";
import AssignTechUserIcon from "@Icons/taskpage/taskMenu/AssignTechUser.svg";
import OrgAdminPermissionIcon from "@Icons/taskpage/taskMenu/OrgAdminPermission.svg";
import AssignEntitlementIcon from "@Icons/taskpage/taskMenu/AssignEntitlement.svg";

const Icon = ({ iconType, size = 26 }) => (
  <AliceIcon
    iconType={iconType}
    size={size}
    sx={{ pointerEvents: "none", cursor: "not-allowed" }}
    color={blue[45]}
  />
);

const TaskList = memo(
  ({
    counts,
    isCollapsed,
    t,
    actionHandler,
    taskType,
    windowSize,
    taskMenuType,
  }) => {
    const { isMobile, isTablet, isDesktop } = windowSize;

    const configMenuList = [
      {
        id: "allTasks",
        title: t("menuBar.allTasks"),
        badge: counts.tottalCount,
        // icon: <Icon iconType={AliceIconType.ALL_TASKS} />,
        icon: (
          <EventNoteOutlinedIcon
            sx={{ color: "rgb(0, 120, 214)", fontSize: "1.7rem" }}
          />
        ),
      },
      {
        id: "APPROVE_ROLE_ASSIGNMENT",
        title: t("taskType.ROLE_ASSIGNMENT"),
        badge: counts.roleRequestCount,
        icon: <RoleRequestIcon />,
      },
      {
        id: "APPROVE_USER_CREATION",
        title: t("taskType.APPROVE_USER_CREATION"),
        badge: counts.usercreationCount,
        icon: <Icon iconType={AliceIconType.USER_CREATE} />,
      },
      {
        id: "APPROVE_USER_TO_ORGANIZATION",
        title: t("taskType.APPROVE_USER_TO_ORGANIZATION"),
        badge: counts.orgCount,
        icon: <Icon iconType={AliceIconType.ASSIGN_USER_ORG} />,
      },
      {
        id: "APPROVE_ORG_ADMIN_PERMISSION",
        title: t("taskType.ORG_ADMIN_PERMISSION"),
        badge: counts.orgAdminCount,
        icon: <OrgAdminPermissionIcon />,
      },
      {
        id: "APPROVE_ROLE_DEPROVISIONING",
        title: t("taskType.APPROVE_ROLE_DEPROVISIONING"),
        badge: counts.deprovisioningCount,
        icon: <Icon iconType={AliceIconType.ROLE_DEPRO} />,
      },
      {
        id: "APPROVE_TECHUSER_TO_APPLICATION",
        title: t("taskType.APPROVE_TECHUSER_TO_APPLICATION"),
        badge: counts.techUserCount,
        icon: <AssignTechUserIcon />,
      },
      {
        id: "APPROVE_APPLICATIONROLE_TO_ROLE",
        title: t("taskType.APPLICATIONROLE_TO_ROLE"),
        badge: counts.entitileMentCount,
        icon: <AssignEntitlementIcon />,
      },
    ];

    const [selectedId, setSelectedId] = useState(
      taskMenuType !== undefined
        ? taskMenuType
        : taskType.length
        ? taskType
        : "allTasks"
    );

    const selectedTask = useCallback(
      (id) => {
        setSelectedId(id);
        actionHandler(id);
      },
      [actionHandler]
    );

    return (
      <>
        {isDesktop && (
          <>
            {configMenuList.map((item, index) => (
              <TaskMenuItems
                className="task-menu-item"
                onClick={() => selectedTask(item.id)}
                key={getUUID()}
                selected={selectedId === item.id}
              >
                <TaskMenuItemIcon>
                  {item.icon}
                  <TaskMenuItemsBadge>
                    {item.badge > 0 && (
                      <Badge
                        filled={selectedId === item.id}
                        style={{
                          backgroundColor:
                            selectedId === item.id ? "#0078D6" : "#FFFFFF",
                        }}
                      >
                        {item.badge}
                      </Badge>
                    )}
                  </TaskMenuItemsBadge>
                </TaskMenuItemIcon>

                {!isCollapsed && (
                  <TaskMenuItemsHeader key={index}>
                    {item.title}
                  </TaskMenuItemsHeader>
                )}
              </TaskMenuItems>
            ))}
          </>
        )}
        {(isTablet || isMobile) && (
          <InputControl
            type="select"
            name="filter-by-taskTypes"
            labelName={t("menuBar.taskTypes")}
            options={configMenuList.map((configMenu) => {
              return {
                value: configMenu.id,
                label: configMenu.title,
              };
            })}
            value={selectedId}
            onChange={(e) => selectedTask(e?.target?.value)}
          />
        )}
      </>
    );
  }
);

export default TaskList;
