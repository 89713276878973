import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { DataClassification, DataClassificationPropType, EntitlementTypePropType, InteractionPropTypes } from '../../../constants'
import { createArrayWithInitialValues, getClientLink } from '../../../util'
import { EntitlementCard } from '../entitlementCard/EntitlementCard'

/**
 * This component renders a grid view using `EntitlementCard` components.
 * The use of MUI-Grid with `EntitlementCard` is preferred to achieve an optimal layout and responsive design.
 *
 * Important Notes:
 * - This component is purely for presentation and does not handle data fetching or state management.
 *   States like `selectedEntitlements` should be managed by a parent component.
 * - It is intended to work in conjunction with components such as `EntitlementSearch` or `GenericSearch` for displaying entitlement data.
 * - On mobile devices, a 'list' view mode is automatically adopted to enhance usability.
 */
export const EntitlementSearchGridViewRenderer = ({
  view = 'grid',
  size = 'm',
  app,
  data: entitlements,
  selectedData = [],
  disabledData = [],
  onItemClick,
  rowsPerPage,
  interaction,
  onGetHref,
  isIdLink = true,
  isLoading,
  isMobile,
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  if (isMobile) view = 'list'

  const isApp = !!Object.keys(app).length

  const mappableEntitlements = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({
      uuid: index.toString(),
      entitlementId: index.toString(),
      displayName: index.toString(),
      description: index.toString(),
      dataClassification: DataClassification.INTERNAL
    }))
    : entitlements

  return (
    <Box
      data-testid="EntitlementSearchGridViewRenderer"
      sx={[
        {
          display: 'grid',
          gap: 2,
          overflow: 'auto'
        },
        view === 'list' && { gridTemplateColumns: 'minmax(min-content, 1fr)' },
        view === 'grid' && {
          gridTemplateColumns: 'repeat(3, minmax(min-content, 1fr))',
          gridAutoFlow: 'row dense'
        }
      ]}
    >
      {mappableEntitlements.map((entitlement) => (
        <EntitlementCard
          key={entitlement.uuid}
          variant={view}
          size={size}
          app={isApp
            ? app
            : {
              id: entitlement.applicationId,
              name: entitlement.applicationName,
              uuid: entitlement.applicationUuid
            }}
          entitlement={entitlement}
          interaction={interaction}
          href={isApp
            ? onGetHref
            : `${getClientLink('admin')}/applications/${entitlement.applicationId}/entitlements/${entitlement.entitlementId}`}
          isLoading={isLoading}
          isSelected={selectedData.includes(entitlement.uuid)}
          isDisabled={disabledData.includes(entitlement.uuid)}
          isIdLink={isIdLink}
          onCardClick={onItemClick}
          {...otherProps}
        />
      ))}
    </Box>
  )
}

EntitlementSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  /** item size-variant */
  size: PropTypes.oneOf([ 'm', 'l' ]),
  data: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    entitlementId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dataClassification: DataClassificationPropType.isRequired,
    connectivity: PropTypes.bool,
    type: EntitlementTypePropType
  })),
  /**
   * Application to get the entitlements from.
   * Also required for Card display.
   */
  app: PropTypes.shape({
    /**
     * A base64-encoded image data representing the logo to be displayed.
     * If not provided, a default logo will be used.
     */
    logo: PropTypes.string,
    /** Application ID */
    id: PropTypes.string,
    /** Application url */
    url: PropTypes.string,
    /** Support email */
    email: PropTypes.string,
    /** Application name */
    name: PropTypes.string
  }),
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Selected users */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled user cards */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  /** Loading state */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** True if mobile viewport */
  isMobile: PropTypes.bool
}
