import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { IconWrapper } from '../../icon'
import { Chip } from '../chip/Chip'

export const RoleCheckCategory = {
  SECURITY: 'security',
  ADMINISTRATION: 'administration',
  FUNCTIONAL: 'functional'
}

const CategoryIcon = ({ category }) => {
  switch (category) {
    case RoleCheckCategory.SECURITY:
      return <ShieldOutlinedIcon />
    case RoleCheckCategory.ADMINISTRATION:
      return <ManageAccountsOutlinedIcon />
    case RoleCheckCategory.FUNCTIONAL:
    default:
      return <SettingsOutlinedIcon />
  }
}

/**
 * A chip that specifically indicates the category of a role.
 */
export const RoleCheckCategoryChip = forwardRef(({ category, sx }, ref) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Chip
      ref={ref}
      variant="outlined"
      size="small"
      label={(
        <Typography color="text.primary" fontSize="14px" lineHeight="20px">
          {t(`role.checkCategory.${category}`)}
        </Typography>
      )}
      icon={(
        <IconWrapper
          color="text.primary"
          size="16px"
        >
          <CategoryIcon category={category} />
        </IconWrapper>
      )}
      sx={mergeSxProps(
        {
          gap: 1,
          px: 1,
          borderRadius: '4px',
          borderColor: 'grey.70',
          color: 'text.primary',
          '.MuiChip-icon': { mx: 0 },
          '.MuiSvgIcon-root': { m: 0, color: 'inherit' },
          '.MuiChip-label': { p: 0 }
        },
        sx
      )}
    />
  )
})

RoleCheckCategory.propTypes = {
  /** Category of the chip */
  category: PropTypes.oneOf(Object.values(RoleCheckCategory)).isRequired,
  sx: PropTypes.any
}
