import { Tooltip as MuiTooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'

/**
 * This is a special styled Tooltip. You can use all available properties described here: https://mui.com/material-ui/api/tooltip/<br>
 * Use the type 'default' for simple text tooltips and type 'ellipsized' to display text that was ellipsized.
 */
export const Tooltip = forwardRef(({
  title = 'Tooltip Title',
  type = 'default',
  placement = 'top',
  arrow = true,
  children,
  disabled = false,
  sx = [],
  ...otherProps
}, ref) => {
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    if (disabled) setIsOpen(false)
  }, [ disabled ])

  const tooltipProps = {
    tooltip: {
      sx: {
        backgroundColor: 'white',
        border: '1px solid black',
        color: 'grey.20',
        padding: '6px 12px',
        fontSize: '16px',
        fontWeight: 'normal'
      }
    },
    arrow: {
      sx: {
        color: 'white',
        '&::before': { border: '1px solid black' }
      }
    }
  }

  const ellipsizedProps = {
    tooltip: {
      sx: {
        padding: '1px 6px',
        fontSize: '12px',
        bgcolor: 'grey.20',
        color: 'grey.85'
      }
    },
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: { offset: [ 50, -10 ] }
        }
      ]
    }
  }

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <MuiTooltip
      data-testid="Tooltip"
      ref={ref}
      sx={sx}
      open={isOpen}
      title={title}
      arrow={type === 'ellipsized' ? false : arrow}
      componentsProps={type === 'ellipsized' ? ellipsizedProps : tooltipProps}
      placement={type === 'ellipsized' ? 'top' : placement}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      onOpen={handleOpen}
      onClose={handleClose}
      {...otherProps}
    >
      {children}
    </MuiTooltip>
  )
})

Tooltip.propTypes = {
  /** Tooltip title. Zero-length titles string, undefined, null and false are never displayed. */
  title: PropTypes.string,
  /** Switches the styling between simple text tooltips an ellipsized text */
  type: PropTypes.oneOf([ 'default', 'ellipsized' ]),
  /** Describes the placement of Tooltip, relative to Children */
  placement: PropTypes.oneOf([ 'top', 'bottom', 'left', 'right' ]),
  /** If true an Arrow is added to Tooltip */
  arrow: PropTypes.bool,
  /**
   * Disables tooltip interaction. If `true` the tooltip will be non-interactive and won't appear on interaction
   * events such as hover, focus etc.
   * */
  disabled: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
