import React from "react";

import {
  BreadcrumbOptions,
  Breadcrumbs,
  StyledLink,
  ArrowBackIosIcon,
} from "../styledComponents";

const BreadCrumbHeader = ({ breadCrumbOptions, goBackLabel, goBackLink }) => {
  return (
    <>
      <Breadcrumbs separator="/">
        {breadCrumbOptions?.map((options, i) => (
          <BreadcrumbOptions
            key={i}
            to={options?.link}
            active={options?.active}
          >
            {options?.label}
          </BreadcrumbOptions>
        ))}
      </Breadcrumbs>
      <StyledLink to={goBackLink}>
        <ArrowBackIosIcon />
        {goBackLabel}
      </StyledLink>
    </>
  );
};

export default BreadCrumbHeader;
