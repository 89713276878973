import React, { useState } from "react";
import { useNotification } from "@alice/component_library";
import DropZone from "@components/DropZone";

const BulkImpWrapper = ({ t, uploadHint, files, setFiles }) => {
  const [fileError, setFileError] = useState(null);

  const { notify } = useNotification();

  const handleFileChosen = async (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        resolve(fileReader.result);
      };

      fileReader.onerror = () => {
        notify({
          severity: "error",
          message: t("error-in-reading-file"),
        });

        reject(fileReader.result);
      };
      fileReader.readAsText(file);
    });

  const readAllFiles = async (files) => {
    const results = await Promise.all(
      files.map(async (file) => {
        const fileContents = await handleFileChosen(file);

        return { file, content: fileContents };
      })
    );
    return results;
  };

  const handleFileChange = (files) => {
    if (files === null) {
      setFiles(null);
      setFileError(null);
      return;
    }

    if (files.length > 1) {
      notify({
        severity: "error",
        message: t("one-csv-at-a-time"),
      });
      return;
    }

    const file = files[0];
    if (!file.name.endsWith(".csv")) {
      notify({
        severity: "error",
        message: t("only-csv-allowed"),
      });
      setFiles(null);
      return;
    }

    readAllFiles(files).then((res) => {
      if (res.length > 0) {
        let resFile = res[0];
        setFiles(resFile);
      }
    });
  };

  return (
    <DropZone
      files={files}
      handleFileChange={handleFileChange}
      error={fileError}
      uploadHint={uploadHint}
    />
  );
};

export default BulkImpWrapper;
