import { getVersion } from '../../../../util'
import { CacheMemory } from './CacheMemory'
import { CacheTTL } from './CacheTTL'
import { CacheStorage } from './CacheStorage'

const CACHE_PREFIX = 'cache.'
const CACHE_VERSION_FALLBACK = 'version_fallback'

export class CacheService {
  static memoryStorage = new CacheMemory()

  static getCacheKey = (key) => CACHE_PREFIX + key

  static now = () => new Date(Date.now()).getTime()

  static getStorage = (storageType) => {
    switch (storageType) {
      case CacheStorage.localStorage:
        return localStorage
      case CacheStorage.sessionStorage:
        return sessionStorage
      case CacheStorage.memory:
      default:
        return CacheService.memoryStorage
    }
  }

  static get = (key, { storage = CacheStorage.memory }) => {
    const prefixedKey = CacheService.getCacheKey(key)
    const item = CacheService.getStorage(storage).getItem(prefixedKey)

    if (!item) return null

    const {
      expires,
      version,
      data
    } = JSON.parse(item)

    if (version !== getVersion() || !expires || expires <= CacheService.now()) {
      CacheService.remove(prefixedKey, { storage })
      return null
    }

    return data
  }

  static set = (
    key,
    value,
    {
      storage = CacheStorage.memory,
      ttl = CacheTTL.oneHour,
      overrideExisting = true
    }
  ) => {
    const prefixedKey = CacheService.getCacheKey(key)
    const currentCachedItem = CacheService.get(prefixedKey, { storage })

    if (!overrideExisting && currentCachedItem) return

    CacheService.getStorage(storage).setItem(
      prefixedKey,
      JSON.stringify({
        expires: CacheService.now() + ttl,
        version: getVersion() || CACHE_VERSION_FALLBACK,
        data: value
      })
    )
  }

  static has = (key, { storage = CacheStorage.memory }) => !!CacheService.get(CacheService.getCacheKey(key), storage)

  static remove = (key, { storage = CacheStorage.memory }) => CacheService.getStorage(storage).removeItem(CacheService.getCacheKey(key))

  static clear = ({ storage = CacheStorage.memory }) => {
    const cache = CacheService.getStorage(storage)
    const removeKeys = []

    for (let i = 0; i < cache.length; i++) {
      const key = cache.key(i)

      if (key.startsWith(CACHE_PREFIX)) {
        removeKeys.push(key)
      }
    }

    removeKeys.forEach((key) => cache.removeItem(key))
  }

  static clearAll = () => {
    CacheService.clear({ storage: CacheStorage.localStorage })
    CacheService.clear({ storage: CacheStorage.sessionStorage })
    CacheService.clear({ storage: CacheStorage.memory })
  }
}
