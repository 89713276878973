import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Message } from '../../message'

export const AnnouncementsEmpty = () => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Box
      sx={{
        width: 1,
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Message
        icon={<ThumbUpAltOutlinedIcon sx={{ fontSize: '3rem' }} />}
        title={<Typography variant="h5">{t('announcements.empty.title')}</Typography>}
        description={(
          <Typography
            variant="body"
            color="grey.500"
            sx={{ textAlign: 'center' }}
          >
            {t('announcements.empty.description')}
          </Typography>
        )}
      />
    </Box>
  )
}
