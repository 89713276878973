import { Button, Grid, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const TaskDeeplinkContainer = styled("div")(({ theme }) => ({
  flexGrow: "1",
  padding: "20px 0",
  "& #layout-content": {
    position: "relative",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "20px 20px 0px",
  },
  "& .MuiSnackbar-root": {
    position: "absolute",
    right: 0,
    // top: 0,
    "& .MuiPaper-root": {
      position: "fixed",
    },
  },
}));

export const BreadcrumbOptions = styled("span")(({ ...props }) => ({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24x",
  color: "rgba(0, 0, 0, 0.87)",
  "& a": {
    color: "rgba(0, 0, 0, 0.6)",
    textDecoration: "none",
  },
}));

export const GoBackLink = styled(Link)({
  fontSize: "13px",
  fontWeight: "700",
  lineHeight: "24x",
  color: "#0078D6",
  fontFamily: "MB Corpo S Text",
  textDecoration: "none",
  marginTop: "3.5px",
  cursor: "pointer",
  display: "flex",
  "&:hover": { color: "#0078D6" },
});

export const TaskIdHeaderContainer = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  columnGap: "5px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
  "& svg": {
    fontSize: "48px",
  },
}));

export const TaskDetailContainer = styled((props) => (
  <Stack direction="row" useFlexGap flexWrap="wrap" {...props} />
))(({ theme, ...props }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "36px",
  [theme.breakpoints.down("sm")]: {
    rowGap: "10px",
    marginTop: "15px",
  },
}));

export const TaskIdHeader = styled((props) => (
  <Typography variant="h1" {...props} />
))(({ ...props }) => ({
  fontSize: "48px",
  marginBottom: 0,
}));

export const TaskSubHeader = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "56px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "20px 10px",
  },
  marginTop: "4px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  flexWrap: "wrap",
  columnGap: "5px",
  marginBottom: "30px",
  "& p:nth-of-type(1)": {
    marginRight: "16px",
  },
}));

export const TaskSubHeaderText = styled((props) => (
  <Typography variant="body2" {...props} />
))(({ theme, ...props }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: props.validuntil && "8px",
    marginRight: props.daysleft && "8px",
  },
}));

export const ValidUntilDate = styled("span")({
  fontWeight: "bold",
  marginLeft: "10px",
});

export const VerticalDivider = styled("span")(({ ...props }) => ({
  color: "#0000001F",
  margin: "0px 6px",
}));

export const ActionButtonContainer = styled("div")(({ ...props }) => ({
  "& button:nth-of-type(2)": {
    marginLeft: "16px",
  },
}));
export const ActionButton = styled((props) => <Button {...props} />)(
  ({ ...props }) => ({
    color: "#FFFFFF",
    backgroundColor: props.backgroundcolor,
    padding: "4px 10px",
    "&:hover": {
      backgroundColor: props.hover,
    },
    "& svg": {
      marginRight: "4px",
      path: {
        fill: "#FFFFFF",
      },
    },
  })
);

export const DeeplinkFooter = styled((props) => <Grid {...props} />)({
  backgroundColor: "#F4F4F4",
  position: "sticky",
  bottom: 0,
  zIndex: 1,
});

export const DeeplinkPaginationContainer = styled("div")(({ theme }) => ({
  flexGrow: "1",
  padding: "20px 0",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  rowGap: "5px",
  columnGap: "15px",
  justifyContent: "end",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "0px 20px",
  },
  "& .task-btn": {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "10px",
    [theme.breakpoints.down("sm")]: {
      columnGap: "5px",
      justifyContent: "center",
      rowGap: "5px",
    },
  },
}));

export const TasksByTotal = styled(({ theme, ...props }) => (
  <Typography variant="h1" {...props} />
))(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "700",
  marginRight: "5px",
  marginBottom: "0px",
  fontFamily: "MB Corpo S Text",
  [theme.breakpoints.down("sm")]: {
    margin: "0px",
  },
}));

export const IdSpanContainer = styled("span")({
  margin: "0 3px",
});
