import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Tooltip } from '../../message'
import { CardInfoChip } from '../cardInfoChip/CardInfoChip'

/**
 * New style Card Chip
 */
export const ClientChip = forwardRef((
  {
    icon,
    client,
    sx = {} || [],
    isLoading = false,
    ...otherProps
  },
  ref
) => {
  const clientUpperCased = client.charAt(0).toUpperCase() + client.slice(1)
  const clientShort = client.slice(0, 3).toUpperCase()

  return (
    <Tooltip title={clientUpperCased}>
      <CardInfoChip
        ref={ref}
        icon={icon}
        contentText={clientShort}
        sx={sx}
        isLoading={isLoading}
        {...otherProps}
      />
    </Tooltip>
  )
})

ClientChip.propTypes = {
  /**
   * Desired  icon
   * example: `<DeviceHubOutlined color="inherit" fontSize="inherit" />`
   * */
  icon: PropTypes.node,
  /** Client of the chip */
  client: PropTypes.string.isRequired,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ]),
  /** Loading state. If `true` Placeholder is shown */
  isLoading: PropTypes.bool
}
