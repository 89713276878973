import { Typography } from '@mui/material'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../util'

/**
 * `display: '-webkit-box'` cuts text on word level.
 * Therefor it's just added when more than one line.
 */
export const TypographyLineClamp = forwardRef(({
  sx,
  lineClamp = 1,
  children,
  ...otherProps
}, ref) => (
  <Typography
    ref={ref}
    sx={mergeSxProps(
      {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      lineClamp > 1 && {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lineClamp
      },
      lineClamp === 1 && { whiteSpace: 'nowrap' },
      sx
    )}
    {...otherProps}
  >
    {children}
  </Typography>
))
