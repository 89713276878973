import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import { Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CacheKey, CacheStorage } from '../../../data'
import { useCache } from '../../../hooks'
import { Button, LinkButton } from '../../button'

export const BotUserPage = () => {
  const { t } = useTranslation('componentLibrary')
  const { removeCachedItem } = useCache(CacheStorage.localStorage)

  const refreshLoad = () => {
    removeCachedItem(CacheKey.me)

    window.location.reload(true)
  }

  return (
    <Paper
      data-testid="BotUserPage"
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        height: '100vh',
        padding: 2,
        textAlign: 'center'
      }}
    >
      <div>
        <Typography component="span" sx={{ fontSize: '3rem', fontWeight: 'bold' }}>Alice </Typography>
        {' '}
        <Typography component="span" sx={{ fontSize: '3rem' }}>for </Typography>
        {' '}
        <Typography component="span" variant="h1">Mercedes-Benz Group AG</Typography>
      </div>

      <SmartToyOutlinedIcon sx={{ fontSize: '64px' }} />

      <Typography variant="h3">{t('message.botUserTitle')}</Typography>
      <Typography>{t('message.botUserDescription')}</Typography>

      <Stack
        sx={{
          flexFlow: 'row',
          gap: 1,
          marginTop: 1
        }}
      >
        <Button variant="secondary" onClick={refreshLoad}>{t('general.refresh')}</Button>
        <LinkButton variant="primary" href="mailto:alice-support@mercedes-benz.com">{t('general.contactSupport')}</LinkButton>
      </Stack>
    </Paper>
  )
}
