/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Paper, Tabs, Tab, Breadcrumbs, Typography } from "@mui/material";

import { Layout } from "@alice/component_library";
import { a11yProps, getUUID } from "@utils";
import { hasData } from "@utils/Validator";
import Communication from "@services/Communication";
import urlConfig from "@services/urlConfig";
import { MyUserDetails } from "@actions/controlCenterActions";
import TabPanel from "@components/TabPanel";
import ProfileHeader from "./componnents/ProfileHeader";
import Applications from "../profileOverview/myApplications";
import {
  AccessRightsDiv,
  MainContainerCC,
  Usersubcontainer,
  StyledPaperContainer,
  RolesContainer,
  ContainerCC,
} from "./styledComponents";
import UserDetailsSkeleton from "@src/sharedComponents/Skeleton/UserDetailsSkeleton";
import RolesPage from "../myRoles/Roles";

const UserDetails = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  // const userUtils = useSelector(state => state.util);
  const getRolesMapped = (roles = []) =>
    (roles || []).map((d) => ({ ...d, id: getUUID() }));

  const hasCCAccess = useSelector((state) => state.controlCenter.hasCCAccess);
  const [userDetails, setUserDetails] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);
  const [userLoading, setuserLoading] = useState(false);
  const [dataPrepare, setDataPrepare] = useState(true);
  const { user } = params;
  const myUsers = useSelector((state) => state.controlCenter.users);

  useEffect(() => {
    const userSelected = myUsers?.data.find((d) => d.id === user);
    setUserDetails(userSelected);
    if (hasData(userSelected)) {
      if (userSelected?.roles.length !== userSelected?.rolerels?.totalCount) {
        updateRoleInfo(user, myUsers?.data);
      } else {
        setRoleList(getRolesMapped(userSelected?.roles));
        setDataPrepare(false);
      }
    } else checkAccess();
    document.getElementById("scroller").scroll(0, 0);
  }, []);

  useEffect(() => {
    if (
      hasData(roleList) &&
      hasData(userDetails) &&
      !hasData(userDetails?.roles)
    ) {
      const user = { ...userDetails };
      user.roles = roleList;
      setUserDetails(user);
    }
  }, [roleList, userDetails]);

  const updateRoleInfo = (userId, data) => {
    Communication.get(urlConfig.roles(userId))
      .then((res) => {
        if (hasData(res.data)) {
          const index = myUsers?.data.findIndex((d) => d.id === userId);
          if (index !== -1) {
            const updateUserInfo = {
              ...data[index],
              roles: res?.data?.roles,
            };
            const updateDataInfo = [
              ...data.slice(0, index),
              updateUserInfo,
              ...data.slice(index + 1),
            ];

            MyUserDetails({
              data: updateDataInfo,
              isLoaded: true,
              search: myUsers?.seacrh,
              totalCount: myUsers?.totalCount,
            });
            setUserDetails(updateUserInfo);
            setRoleList(getRolesMapped(updateUserInfo?.roles));
          }
          setRoleLoading(false);
          setDataPrepare(false);
        }
      })
      .catch(errorHandler);
  };

  const checkAccess = () => {
    if (hasCCAccess) {
      setRoleLoading(true);
      setuserLoading(true);
      getUser();
      getRole();
    } else navigateToHome();
  };

  const getUser = () => {
    Communication.get(urlConfig.user(user))
      .then((res) => {
        if (hasData(res.data)) setUserDetails(res.data);
        else navigateToHome();
        setuserLoading(false);
      })
      .catch(errorHandler);
  };

  const getRole = () => {
    Communication.get(urlConfig.roles(user))
      .then((res) => {
        if (hasData(res.data)) {
          setRoleList(getRolesMapped(res?.data?.roles));
          setRoleLoading(false);
          setDataPrepare(false);
        }
      })
      .catch(errorHandler);
  };

  const errorHandler = (e) => {
    navigateToHome();
  };

  const navigateToHome = () => navigate("/access");

  const handleChange = (e, value) => {
    setValue(value);
  };

  const getBreadCrumbs = () => {
    return (
      <Grid className="mb-20">
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <span
            onClick={() => {
              navigate("/access/insights");
            }}
            style={{
              color: "#333333",
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: "700",
            }}
          >
            {t("navTargets.control-center")}
          </span>

          <Typography style={{ fontSize: "12px" }}>{user}</Typography>
        </Breadcrumbs>
      </Grid>
    );
  };

  if (userLoading || dataPrepare) return <UserDetailsSkeleton />;

  const handleUserRoleDelete = (listToRemove) => {
    if (listToRemove.length) {
      const newRoles = roleList?.filter(
        (item) => !listToRemove.some((id) => id === item.id)
      );
      if (myUsers.isLoaded) {
        const index = myUsers.data.findIndex((d) => d.id === userDetails.id);
        if (index >= 0 && index < myUsers.data.length) {
          if (
            myUsers.data[index] &&
            myUsers.data[index].hasOwnProperty("roles")
          ) {
            myUsers.data[index].roles = newRoles;
            dispatch(MyUserDetails(myUsers));
          }
        }
      }
    }
  };

  return (
    <ContainerCC>
      <MainContainerCC container className="mh-95vh">
        <Layout>
          {getBreadCrumbs()}
          <Usersubcontainer>
            <ProfileHeader isUser userDetails={userDetails} />
          </Usersubcontainer>
          <AccessRightsDiv>{t("access-rights")}</AccessRightsDiv>

          <StyledPaperContainer>
            <Tabs
              value={value}
              onChange={(e, v) => handleChange(e, v)}
              indicatorColor="secondary"
              variant="standard"
              TabIndicatorProps={{ style: { background: "#0078D6" } }}
              className="tabsRoot"
            >
              <Tab
                label={t("roles")}
                {...a11yProps(0)}
                className="tabItem"
                classes={value === 0 ? {} : { selected: "selectedTab" }}
              />
              <Tab
                label={t("applications")}
                {...a11yProps(1)}
                className="tabItem"
                classes={value === 0 ? {} : { selected: "selectedTab" }}
              />
            </Tabs>
          </StyledPaperContainer>
          <TabPanel value={value} index={0} key={getUUID()}>
            <RolesContainer>
              <RolesPage
                assignedRoles={roleList?.sort((a, b) =>
                  a?.roleDefinition?.name.localeCompare(b.roleDefinition.name)
                )}
                loading={roleLoading}
                isInsights
                userId={user}
                fetchData={getRole}
              />
            </RolesContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Applications user={userDetails} />
          </TabPanel>
        </Layout>
      </MainContainerCC>
    </ContainerCC>
  );
};

export default withTranslation()(UserDetails);
