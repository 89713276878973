import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Step, StepButton, StepContent, StepLabel, Stepper as MuiStepper, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { clamp, createArrayWithInitialValues, mergeSxProps } from '../../util'
import { StepIcon } from './Components'
import { MobileStepper } from './MobileStepper'
import { StepperInteraction } from './Stepper.data'

/**
 * When using the ref prop you can control the stepper from the parent component.<br>
 * Following entries are available in ref object:
 *
 * **back()**<br>
 * - Used to go back one step.
 *
 * **next()**<br>
 * - Used to go to the next step (even if current is not completed). Use for debugging.
 *
 * **setCompleted()**<br>
 * - Change the state of a step. Excepts three variables:
 *  - value (true or false, default is true) adds|deletes a step to completed state.
 *  - step (number, default is the currentStep) to select the step
 *  - move (bool, default is true) to set a move to the next/previous step after setCompleted
 *
 * **setSkipped()**<br>
 * - Change the state of a step. Used for optional steps. Excepts three variables:
 *  - value (true or false, default is true) adds|deletes a step to skipped state.
 *  - step (number, default is the currentStep) to select the step
 *  - move (bool, default is true) to set a move to the next/previous step after setSkipped
 *
 * **reset()**<br>
 * - Reset the stepper <br>
 *
 * **activeStep**<br>
 * - internal activeStep state
 *
 * **completedSteps**<br>
 * - list of completed step ids (indexes)
 *
 * **skippedSteps**<br>
 * - list of skipped step ids (indexes)
 *
 * **isDone**<br>
 * - true when stepper is completed -> every step is either completed or skipped
 */
export const Stepper = forwardRef((
  {
    steps: _steps = [],
    initialStep = 0,
    size = 'small',
    orientation = 'horizontal',
    interaction = StepperInteraction.CLICKABLE,
    mobileBreakpoint = 'sm',
    isMobile: _isMobile = false,
    isNonLinear = false,
    disabled = false,
    onAllStepsCompleted = () => {},
    onActiveStep = (activeStep) => {},
    sx = {},
    ...otherProps
  },
  ref
) => {
  const [ steps, setSteps ] = useState(_steps)
  const [ activeStep, setActiveStep ] = useState(0)
  const [ isDone, setIsDone ] = useState(false)
  const [ firstUncompletedStep, setFirstUncompletedStep ] = useState(0)
  const [ skipped, setSkipped ] = useState(new Set())
  const [ completed, setCompleted ] = useState(new Set())

  const { breakpoints } = useTheme()

  const isMobile = useMediaQuery(breakpoints.down(mobileBreakpoint)) || _isMobile

  const style = mergeSxProps(
    ({ palette }) => ({
      // ## General Style ##
      '.MuiStepLabel-root': {
        gap: 'var(--stepperGap)',
        '.MuiStepLabel-label': {
          margin: 0,
          color: isMobile ? `var(--clr, ${palette.primary.main})` : `var(--clr, ${palette.text.secondary})`,
          '&.Mui-active': { '--clr': isMobile ? `var(--clrActive, ${palette.primary.main})` : `var(--clrActive, ${palette.text.secondary})` },
          '&.Mui-disabled': { '--clr': `var(--clrDisabled, ${palette.text.disabled})` }
        },
        '.MuiStepLabel-iconContainer': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid',
          color: `var(--iconClr, ${palette.blue[45]})`,
          borderColor: `var(--iconBrClr, ${palette.blue[45]})`,
          backgroundColor: 'var(--iconBgClr, white)',
          borderRadius: '100px',
          width: 'var(--stepperIconSize)',
          height: 'var(--stepperIconSize)',
          '&.Mui-active': {
            '--iconClr': 'var(--iconClrActive, white)',
            '--iconBgClr': `var(--iconBgClrActive, ${palette.blue[45]})`
          },
          '&.Mui-disabled': {
            '--iconClr': `var(--iconClrDisabled, ${palette.text.disabled})`,
            '--iconBrClr': `var(--iconBrClrDisabled, ${palette.text.disabled})`,
            '--iconBgClr': 'var(--iconBgClrDisabled, white)'
          }
        }
      },
      // ## Horizontal Style ##
      '&.MuiStepper-horizontal': {
        '.MuiStep-root': {
          display: 'flex',
          flexFlow: 'column',
          gap: 0.5
        },
        '.MuiStepConnector-horizontal': {
          display: isMobile ? 'none' : 'block',
          top: 'var(--stepperConnectorTop)',
          left: 'calc(-50% + var(--stepperConnectorHorInline))',
          right: 'calc(50% + var(--stepperConnectorHorInline))',
          '&::after': {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'var(--stepperLineClr)',
            transition: 'background-color 250ms',
            content: "''"
          }
        },
        '.stepContent': {
          display: 'flex',
          justifyContent: isMobile ? 'start' : 'center'
        }
      },
      // ## Vertical Style ##
      '&.MuiStepper-vertical': {
        '.MuiStepLabel-iconContainer': { padding: 0 },
        '.MuiStepConnector-vertical': {
          display: isMobile ? 'none' : 'block',
          position: 'relative',
          marginLeft: 'var(--stepperConnectorTop)'
        },
        '.MuiStepContent-root': {
          position: 'relative',
          marginLeft: 'var(--stepperConnectorTop)',
          paddingLeft: 'var(--stepperMobileContentGap)'
        }
      },
      '.MuiStep-root.active': { '--stepperLineClr': palette.blue[45] }
    }),
    size === 'small' && {
      '--stepperGap': '8px',
      '--stepperIconSize': '32px',
      '--stepperConnectorTop': '16px',
      '--stepperMobileContentGap': '24px',
      '--stepperConnectorHorInline': '48px'
    },
    size === 'big' && {
      '--stepperGap': '16px',
      '--stepperIconSize': '48px',
      '--stepperConnectorTop': '24px',
      '--stepperMobileContentGap': '40px',
      '--stepperConnectorHorInline': '80px'
    },
    sx
  )

  const handleReset = useCallback(() => {
    const clampedInitialStep = clamp(initialStep, 0, steps?.length || 0)
    const initSkipped = new Set()

    const initComplete = clampedInitialStep
      ? new Set(createArrayWithInitialValues(clampedInitialStep))
      : new Set()

    for (let index = 0; index <= steps.length; index++) {
      const nextStep = steps[index]

      if (!initComplete.has(index) && nextStep?.disabled) {
        if (nextStep.completed) {
          initComplete.add(index)
        } else {
          initSkipped.add(index)
        }
      }
    }

    setActiveStep(clampedInitialStep)
    setSkipped(initSkipped)
    setCompleted(initComplete)
    setIsDone(false)

    // eslint-disable-next-line
  }, [ initialStep, steps ])

  const isStepSkipped = useCallback((step) => skipped.has(step), [ skipped ])
  const isStepComplete = useCallback((step) => completed.has(step), [ completed ])
  const isStepDisabled = useCallback((step) => steps[step]?.disabled, [ steps ])

  const getFirstUncompletedStep = useCallback(() => {
    for (let index = 0; index <= steps.length; index++) {
      if (!isStepComplete(index) && !steps[index]?.optional && !steps[index]?.disabled) {
        setFirstUncompletedStep(index)
        break
      }
    }
  }, [ isStepComplete, steps ])

  const getNextEnabledStep = useCallback((startStep) => {
    let nextEnabledStep

    for (let index = startStep; index <= steps.length; index++) {
      const nextStep = steps[index]

      if (nextStep && !nextStep?.disabled) {
        nextEnabledStep = index
        break
      }
    }

    return nextEnabledStep
  }, [ steps ])

  const getPrevEnabledStep = useCallback((startStep) => {
    let nextEnabledStep

    // eslint-disable-next-line for-direction
    for (let index = startStep; index >= 0; index--) {
      const nextStep = steps[index]

      if (nextStep && !nextStep?.disabled) {
        nextEnabledStep = index
        break
      }
    }

    return nextEnabledStep
  }, [ steps ])

  const handleNext = useCallback(() => {
    const nextStepIndex = getNextEnabledStep(activeStep + 1)

    if (nextStepIndex && nextStepIndex <= steps.length) setActiveStep(nextStepIndex)
  }, [ activeStep, getNextEnabledStep, steps.length ])

  const handleBack = useCallback(() => {
    const prevStepIndex = getPrevEnabledStep(activeStep - 1)

    if (prevStepIndex >= 0) setActiveStep(prevStepIndex)
  }, [ activeStep, getPrevEnabledStep ])

  const handleStepClick = (stepIndex) => () => {
    if (!isNonLinear) {
      // check if there is an optional not completed step before the clicked one
      // if yes -> set skipped
      for (let index = 0; index < stepIndex; index++) {
        if (steps[index]?.optional && !completed.has(index)) {
          skipped.add(index)
        }
      }
    }

    setActiveStep(stepIndex)
  }

  const handleSkip = useCallback((isSkipped = true, step = activeStep, move = true) => {
    setSkipped(() => {
      const newSkipped = new Set(skipped)

      if (isSkipped) {
        newSkipped.add(step)
        completed.delete(step)
      } else {
        newSkipped.delete(step)
      }

      return newSkipped
    })

    if (move) {
      isSkipped ? handleNext() : handleBack()
    }
  }, [ activeStep, completed, handleBack, handleNext, skipped ])

  const handleComplete = useCallback((isCompleted = true, step = activeStep, move = true) => {
    setCompleted(() => {
      const newCompleted = new Set(completed)

      if (isCompleted) {
        newCompleted.add(step)
        skipped.delete(step)
      } else {
        newCompleted.delete(step)
      }

      return newCompleted
    })

    if (move) {
      isCompleted ? handleNext() : handleBack()
    }
  }, [ activeStep, completed, handleBack, handleNext, skipped ])

  useMemo(() => {
    handleReset()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSteps(_steps.filter((step) => !!step))
  }, [ _steps ])

  useEffect(() => {
    if (ref) onActiveStep(activeStep)

    // eslint-disable-next-line
  }, [ ref, activeStep ])

  useEffect(() => {
    getFirstUncompletedStep()

    if (completed.size === steps.length - skipped.size) {
      setIsDone(true)
      onAllStepsCompleted()
    }

    // eslint-disable-next-line
  }, [ completed.size, skipped.size, steps.length ])

  useImperativeHandle(ref, () => ({
    reset: handleReset,
    next: handleNext,
    back: handleBack,
    setCompleted: handleComplete,
    setSkipped: handleSkip,
    activeStep,
    completedSteps: [ ...completed ],
    skippedSteps: [ ...skipped ],
    isDone
  }), [ handleReset, handleNext, handleBack, handleComplete, handleSkip, activeStep, completed, skipped, isDone ])

  return (
    <MuiStepper
      data-testid="Stepper"
      alternativeLabel={orientation !== 'vertical'}
      nonLinear={isNonLinear}
      orientation={orientation}
      activeStep={activeStep}
      sx={style}
      {...otherProps}
    >
      {isMobile
        ? (
          <MobileStepper
            activeStep={activeStep}
            steps={steps}
            isStepComplete={isStepComplete}
            isStepSkipped={isStepSkipped}
            isStepDisabled={isStepDisabled}
            firstUncompletedStep={firstUncompletedStep}
            isMobile={isMobile}
            isDone={isDone}
            onStepClick={handleStepClick(activeStep)}
            interaction={interaction}
            disabled={disabled}
            isNonLinear={isNonLinear}
          />
        )
        : (
          steps.map((step, index) => {
            const stepProps = {
              completed: isStepComplete(index),
              disabled: disabled // stepper is disabled
                || step.disabled // step is disabled
                || !(isNonLinear
                  || index <= activeStep // not disabled if step is active or before
                  || isStepComplete(index) // not disabled if step is completed
                  || (isStepComplete(index - 1) && !isStepDisabled(index - 1)) // not disabled if prev step is completed and not disabled
                  || (isStepSkipped(index - 1) && !isStepDisabled(index - 1)) // not disabled if prev step is skipped and not disabled
                  || (step.optional && firstUncompletedStep > index)), // not disabled if step is optional
              sx: mergeSxProps({ minWidth: '150px' }, step.sx)
            }

            const labelProps = {
              id: step?.id || index,
              icon: <StepIcon
                step={step}
                index={index}
                steps={steps}
                isMobile={isMobile}
                isDone={isDone}
                activeStep={activeStep}
                isStepComplete={isStepComplete}
              />
            }

            if (isStepSkipped(index)) stepProps.completed = false

            const stepContent = (
              <Typography className="stepContent" variant="body2" component="div">
                {step.content || '\u00a0'}
              </Typography>
            )

            return (
              <Step key={`${step.title}-${index}`} className={index <= activeStep ? 'active' : ''} {...stepProps}>
                {interaction === StepperInteraction.NONE
                  ? (
                    <StepLabel {...labelProps}>
                      <Typography variant="subtitle2">
                        {step.title || '\u00a0'}
                      </Typography>
                    </StepLabel>
                  )
                  : (
                    <StepButton onClick={handleStepClick(index)} {...labelProps}>
                      <Typography variant="subtitle2">
                        {step.title || '\u00a0'}
                      </Typography>
                    </StepButton>
                  )}

                {orientation === 'horizontal'
                  ? stepContent
                  : <StepContent>{stepContent}</StepContent>}
              </Step>
            )
          })
        )}
    </MuiStepper>
  )
})

Stepper.propTypes = {
  /**
   * **title**: The title of the step<br>
   * **content**: The content below the title<br>
   * **iconType**: The icon name.
   * See UI/Icon for names.
   * Alternatively, you can pass the node directly<br>
   * **optional**: Is this step optional?<br>
   * **disabled**: Is this step disabled?
   */
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.node.isRequired,
        content: PropTypes.node,
        iconType: PropTypes.any,
        optional: PropTypes.bool,
        disabled: PropTypes.bool
      }),
      PropTypes.bool
    ])
  ).isRequired,
  /** defines the size of the stepper */
  size: PropTypes.oneOf([ 'small', 'big' ]),
  /** defines orientation of the stepper */
  orientation: PropTypes.oneOf([ 'horizontal', 'vertical' ]),
  /** the breakpoint the mobile stepper will appear */
  mobileBreakpoint: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ]),
  /** to force mobile version */
  isMobile: PropTypes.bool,
  /** if true the steps can be completed in any order */
  isNonLinear: PropTypes.bool,
  /**
   * Is called if all steps are completed or skipped
   *
   * @returns {void}
   */
  onAllStepsCompleted: PropTypes.func,
  /**
   * Is called when clicked on a step with the step #
   *
   * @param activeStep
   * @returns {void}
   */
  onActiveStep: PropTypes.func,
  /** All steps are disabled */
  disabled: PropTypes.bool,
  /** Initial or starting step that the stepper should display when it is first rendered */
  initialStep: PropTypes.number
}
