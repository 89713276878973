import * as Icons from './assets'

const AliceIconType = {
  CAT_SITTING: 'CAT_SITTING',
  CONNECT_DEVICE_CANCEL: 'CONNECT_DEVICE_CANCEL',
  CUSTOMER_SUPPORT_QUESTION: 'CUSTOMER_SUPPORT_QUESTION',
  EARTH_BLOCK: 'EARTH_BLOCK',
  ASSIGN_USER_ORG: 'ASSIGN_USER_ORG',
  ORG_ADMIN: 'ORG_ADMIN',
  ROLE_DEPRO: 'ROLE_DEPRO',
  ROLE_ENTITLEMENT: 'ROLE_ENTITLEMENT',
  ROLE_REQ: 'ROLE_REQ',
  TECH_USER: 'TECH_USER',
  USER_CREATE: 'USER_CREATE',
  USER_REVIEW_MARKET: 'USER_REVIEW_MARKET',
  USER_REVIEW_ORG: 'USER_REVIEW_ORG',
  USER_OUTLINE: 'USER_OUTLINE',
  INFO_CIRCLE: 'INFO_CIRCLE',
  INFO_BUBBLE: 'INFO_BUBBLE',
  IRIS_SCAN_APPROVED: 'IRIS_SCAN_APPROVED',
  MEGAPHONE: 'MEGAPHONE',
  MOBILE_PHONE: 'MOBILE_PHONE',
  PHONE: 'PHONE',
  TASK_LIST_PEN: 'TASK_LIST_PEN',
  HEADPHONES_CUSTOMER_SUPPORT: 'HEADPHONES_CUSTOMER_SUPPORT',
  SEARCH: 'SEARCH',
  CHECK_CIRCLE: 'CHECK_CIRCLE',
  API_DOWN: 'API_DOWN',
  NO_RESULT: 'NO_RESULT',
  INITIAL_SEARCH: 'INITIAL_SEARCH',
  HOMEORG: 'HOMEORG',
  DEALERORG: 'DEALERORG',
  CURRENTWORKPLACE: 'CURRENTWORKPLACE',
  LAYOUT_MODULE: 'LAYOUT_MODULE',
  LIST_BULLETS: 'LIST_BULLETS',
  EDIT_MB: 'EDIT_MB',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DEALER: 'DEALER',
  CORPORATE_CUSTOMER: 'CORPORATE_CUSTOMER',
  EMPLOYEE: 'EMPLOYEE',
  SUPPLIER: 'SUPPLIER',
  CORPORATE_BUSINESS_PARTNER: 'CORPORATE_BUSINESS_PARTNER',
  BUSINESS_PARTNER: 'CORPORATE_BUSINESS_PARTNER',
  TEST_ORGANIZATION: 'TEST_ORGANIZATION',
  GROUP: 'GROUP',
  WORLD: 'WORLD',
  LEGALENTITY: 'LEGALENTITY',
  SUBLOCATION: 'SUBLOCATION',
  MAINLOCATION: 'MAINLOCATION',
  DAIMLER_COMPANY: 'DAIMLER_COMPANY',
  SUPPLIER_ORGANIZATION: 'SUPPLIER_ORGANIZATION',
  KEYS: 'KEYS',
  SUMMARY: 'UISummary',
  VALIDITY: 'VALIDITY',
  CUSTOM_SCOPE: 'CUSTOM_SCOPE',
  REQUEST_REASON: 'REQUEST_REASON',
  REQUEST_OVERVIEW: 'REQUEST_OVERVIEW',
  HORIZONTAL_MORE_MENU: 'HORIZONTAL_MORE_MENU',
  PENCIL: 'PENCIL',
  ACTION_BUILDING_ADD: 'ACTION_BUILDING_ADD',
  ACTION_BUILDING_CHECK: 'ACTION_BUILDING_CHECK',
  ACTION_BUILDING_WARNING: 'ACTION_BUILDING_WARNING',
  NEUTRAL_ACTIONS_WARNING: 'NEUTRAL_ACTIONS_WARNING',
  NEUTRAL_ACTIONS_CHECK: 'NEUTRAL_ACTIONS_CHECK',

  // orgForm
  ORG_MPC: 'ORG_MPC',
  ORG_GENERAL_DISTRIBUTOR: 'ORG_GENERAL_DISTRIBUTOR',
  ORG_MARKET_AREA: 'ORG_MARKET_AREA',
  ORG_LEGALENTITY: 'ORG_LEGALENTITY',
  ORG_SUBLOCATION: 'ORG_SUBLOCATION',
  ORG_TEAM: 'ORG_TEAM',
  ORG_DAIMLER_COMPANY: 'ORG_DAIMLER_COMPANY',
  ORG_UNKNOWN: 'ORG_UNKNOWN',
  ORG_PARTNER_ORGANIZATION: 'ORG_PARTNER_ORGANIZATION',
  ORG_SUPPLIER_ORGANIZATION: 'ORG_SUPPLIER_ORGANIZATION',
  ORG_USER_SEARCH_MPC_AND_GD: 'ORG_USER_SEARCH_MPC_AND_GD',
  ORG_USER_SEARCH_DAIMLER_COMPANY: 'ORG_USER_SEARCH_DAIMLER_COMPANY',
  ORG_USER_SEARCH_DEALER_GROUP: 'ORG_USER_SEARCH_DEALER_GROUP',
  ORG_USER_SEARCH_MARKET_AREA: 'ORG_USER_SEARCH_MARKET_AREA',
  ORG_USER_SEARCH_PARTNER_ORGANIZATION: 'ORG_USER_SEARCH_PARTNER_ORGANIZATION',
  ORG_USER_SEARCH_SUPPLIER_ORGANIZATION: 'ORG_USER_SEARCH_SUPPLIER_ORGANIZATION',

  // orgCommunity
  ORG_DEALER: 'ORG_DEALER',
  ORG_CORPORATE_CUSTOMER: 'ORG_CORPORATE_CUSTOMER',
  ORG_EMPLOYEE: 'ORG_EMPLOYEE',
  ORG_SUPPLIER: 'ORG_SUPPLIER',
  ORG_CORPORATE_BUSINESS_PARTNER: 'ORG_CORPORATE_BUSINESS_PARTNER',
  ORG_TEST_ORGANIZATION: 'ORG_TEST_ORGANIZATION',
  ORG_TECH_USER: 'ORG_TECH_USER',
  ORG_USER_SEARCH_DEALER: 'ORG_USER_SEARCH_DEALER',
  ORG_USER_SEARCH_EMPLOYEE: 'ORG_USER_SEARCH_EMPLOYEE',
  ORG_USER_SEARCH_EXTERNAL: 'ORG_USER_SEARCH_EXTERNAL',
  ORG_USER_SEARCH_ISP: 'ORG_USER_SEARCH_ISP',
  ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION: 'ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION',
  ORG_USER_SEARCH_INTERNAL_COMPANIES: 'ORG_USER_SEARCH_INTERNAL_COMPANIES',
  ORG_USER_SEARCH_SUPPLIER: 'ORG_USER_SEARCH_SUPPLIER',
  ORG_USER_SEARCH_TECH_USER: 'ORG_USER_SEARCH_TECH_USER',

  UIApps: 'UIApps',
  UIDate_From: 'UIDate_From',
  UIGroup: 'UIGroup',
  UIHome: 'UIHome',
  UIInfo: 'UIInfo',
  UIMarkets: 'UIMarkets',
  UINewReq: 'UINewReq',
  UIOrg: 'UIOrg',
  UIProfile: 'UIProfile',
  UIReports: 'UIReports',
  UIRoles: 'UIRoles',
  UITasks: 'UITasks',
  UIUsers: 'UIUsers',
  UIWorkflow: 'UIWorkflow',
  UIReapply: 'UIReapply',
  UISummary: 'UISummary',
  StepperCheckmark: 'StepperCheckmark',

  // GEMS2DRD
  DRDtoGEMS: 'DRDtoGEMS',
  GEMStoDRD: 'GEMStoDRD',
  LIST_REMOVE: 'LIST_REMOVE',
  LIST_ADD: 'LIST_ADD',
  BULK: 'BULK',
  SHARE_EXTERNAL_LINK: 'SHARE_EXTERNAL_LINK',

  // import 27.11.2023
  CREDIT_CARD_PHONE_CALL: 'CREDIT_CARD_PHONE_CALL',
  ARROW_CIRCLE_LEFT: 'ARROW_CIRCLE_LEFT',
  ARROW_CIRCLE_RIGHT: 'ARROW_CIRCLE_RIGHT',
  ARROW_CIRCLE_UP: 'ARROW_CIRCLE_UP',
  ARROW_CIRCLE_DOWN: 'ARROW_CIRCLE_DOWN',
  MESSAGES_USER_CHECK: 'MESSAGES_USER_CHECK',
  NETWORK_USER: 'NETWORK_USER',
  TASK_LIST_TEXT: 'TASK_LIST_TEXT',
  TRANSLATE: 'TRANSLATE',
  IMAGE_FILE: 'IMAGE_FILE',
  NAVIGATION_MENU_4: 'NAVIGATION_MENU_4',
  ROLE_REMOVE: 'ROLE_REMOVE',

  // import 24.05.2024
  ORG_SCOPE: 'ORG_SCOPE',

  // Nav Icons
  NEW_REQUEST: 'NEW_REQUEST',
  NEW_REQUEST_FILLED: 'NEW_REQUEST_FILLED',
  ROLE: 'ROLE',
  CHILD_ROLE: 'CHILD_ROLE'
}

const iconMapping = {
  CAT_SITTING: Icons.CatSitting,
  CONNECT_DEVICE_CANCEL: Icons.ConnectDeviceCancel,
  CUSTOMER_SUPPORT_QUESTION: Icons.CustomerSupportQuestion,
  EARTH_BLOCK: Icons.EarthBlock,
  ASSIGN_USER_ORG: Icons.AssignUserOrg,
  ORG_ADMIN: Icons.OrgAdmin,
  ROLE_DEPRO: Icons.RoleDepro,
  ROLE_ENTITLEMENT: Icons.RoleEntitlement,
  ROLE_REQ: Icons.RoleReq,
  TECH_USER: Icons.Tech_User,
  USER_CREATE: Icons.UserCreate,
  USER_REVIEW_MARKET: Icons.UserReviewMarket,
  USER_REVIEW_ORG: Icons.UserReviewOrg,
  USER_OUTLINE: Icons.UserOutline,
  INFO_CIRCLE: Icons.InfoCircle,
  INFO_BUBBLE: Icons.InfoBubble,
  IRIS_SCAN_APPROVED: Icons.IrisScanApproved,
  MEGAPHONE: Icons.Megaphone,
  MOBILE_PHONE: Icons.MobilePhone,
  PHONE: Icons.Phone,
  TASK_LIST_PEN: Icons.TaskListPen,
  HEADPHONES_CUSTOMER_SUPPORT: Icons.CustomerSupport,
  SEARCH: Icons.Search,
  CHECK_CIRCLE: Icons.CheckCircle,
  API_DOWN: Icons.APIDown,
  NO_RESULT: Icons.NoResult,
  INITIAL_SEARCH: Icons.InitialSearch,
  HOMEORG: Icons.HomeOrg,
  DEALERORG: Icons.DealerOrg,
  CURRENTWORKPLACE: Icons.CurrentWorkplace,
  LAYOUT_MODULE: Icons.LayoutModule,
  LIST_BULLETS: Icons.ListBullets,
  EDIT_MB: Icons.EditMb,
  ACTIVE: Icons.Active,
  INACTIVE: Icons.Inactive,
  DEALER: Icons.Dealer,
  CORPORATE_CUSTOMER: Icons.CorporateCustomer,
  EMPLOYEE: Icons.DaimlerInternal,
  SUPPLIER: Icons.Supplier,
  CORPORATE_BUSINESS_PARTNER: Icons.BusinessPartner,
  TEST_ORGANIZATION: Icons.TestUser,
  GROUP: Icons.Group,
  WORLD: Icons.World,
  LEGALENTITY: Icons.Legalentity,
  SUBLOCATION: Icons.Sublocation,
  MAINLOCATION: Icons.Mainlocation,
  DAIMLER_COMPANY: Icons.DaimlerInternal,
  SUPPLIER_ORGANIZATION: Icons.Supplier,
  KEYS: Icons.Keys,
  LIST_ADD: Icons.ListAdd,
  LIST_REMOVE: Icons.ListRemove,
  BULK: Icons.Bulk,
  SHARE_EXTERNAL_LINK: Icons.ShareExternalLink,
  VALIDITY: Icons.Validity,
  CUSTOM_SCOPE: Icons.CustomScope,
  REQUEST_REASON: Icons.RequestReason,
  REQUEST_OVERVIEW: Icons.RequestOverview,
  HORIZONTAL_MORE_MENU: Icons.HorizontalMenu,
  PENCIL: Icons.Pencil,
  ACTION_BUILDING_ADD: Icons.ActionBuildingAdd,
  ACTION_BUILDING_CHECK: Icons.ActionBuildingCheck,
  ACTION_BUILDING_WARNING: Icons.ActionBuildingWarning,
  NEUTRAL_ACTIONS_WARNING: Icons.NeutralActionsWarning,

  // orgForm
  ORG_MPC: Icons.OrgDealer,
  ORG_GENERAL_DISTRIBUTOR: Icons.OrgCorpCust,
  ORG_MARKET_AREA: Icons.OrgDaimlerJointVenture,
  ORG_LEGALENTITY: Icons.OrgLegalEntity,
  ORG_SUBLOCATION: Icons.OrgSubLocation,
  ORG_TEAM: Icons.OrgPoolUser,
  ORG_DAIMLER_COMPANY: Icons.OrgDaimlerExternal,
  ORG_UNKNOWN: Icons.CustomerSupportQuestion,
  ORG_PARTNER_ORGANIZATION: Icons.OrgDaimlerJointVenture,
  ORG_SUPPLIER_ORGANIZATION: Icons.OrgSupplier,
  ORG_USER_SEARCH_MPC_AND_GD: Icons.OrgUserSearchMPCAndGD,
  ORG_USER_SEARCH_DAIMLER_COMPANY: Icons.OrgUserSearchDaimlerCompany,
  ORG_USER_SEARCH_DEALER_GROUP: Icons.OrgUserSearchDealerGroup,
  ORG_USER_SEARCH_MARKET_AREA: Icons.OrgUserSearchMarketArea,
  ORG_USER_SEARCH_PARTNER_ORGANIZATION: Icons.OrgUserSearchPartnerOrganization,
  ORG_USER_SEARCH_SUPPLIER_ORGANIZATION: Icons.OrgUserSearchSupplierOrganization,
  // orgUserCommunity
  ORG_DEALER: Icons.OrgDealer,
  ORG_CORPORATE_CUSTOMER: Icons.OrgCorpCust,
  ORG_EMPLOYEE: Icons.OrgDaimlerInternal,
  ORG_SUPPLIER: Icons.OrgSupplier,
  ORG_CORPORATE_BUSINESS_PARTNER: Icons.OrgBusinessPartner,
  ORG_TEST_ORGANIZATION: Icons.OrgTestUser,
  ORG_TECH_USER: Icons.OrgTechUser,
  ORG_USER_SEARCH_DEALER: Icons.OrgUserSearchDealer,
  ORG_USER_SEARCH_EMPLOYEE: Icons.OrgUserSearchEmployee,
  ORG_USER_SEARCH_EXTERNAL: Icons.OrgUserSearchExternal,
  ORG_USER_SEARCH_ISP: Icons.OrgUserSearchISP,
  ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION: Icons.OrgUserSearchCorporateCustomerOrganization,
  ORG_USER_SEARCH_INTERNAL_COMPANIES: Icons.OrgUserSearchInternalCompanies,
  ORG_USER_SEARCH_SUPPLIER: Icons.OrgUserSearchSupplier,
  ORG_USER_SEARCH_TECH_USER: Icons.OrgUserSearchTechUser,
  // UI Icons
  UIApps: Icons.UIApps,
  UIDate_From: Icons.UIDate_From,
  UIGroup: Icons.UIGroup,
  UIHome: Icons.UIHome,
  UIInfo: Icons.UIInfo,
  UIMarkets: Icons.UIMarkets,
  UINewReq: Icons.UINewReq,
  UIOrg: Icons.UIOrg,
  UIProfile: Icons.UIProfile,
  UIReports: Icons.UIReports,
  UIRoles: Icons.UIRoles,
  UITasks: Icons.UITasks,
  UIUsers: Icons.UIUsers,
  UIWorkflow: Icons.UIWorkflow,
  UIReapply: Icons.UIReapply,
  UISummary: Icons.UISummary,

  // Stepper
  StepperCheckmark: Icons.StepperCheckmark,

  // GEMS2DRD
  GEMStoDRD: Icons.GEMStoDRD,
  DRDtoGEMS: Icons.DRDtoGEMS,

  // import 27.11.2023
  NEUTRAL_ACTIONS_CHECK: Icons.NeutralActionsCheck,
  CREDIT_CARD_PHONE_CALL: Icons.CreditCardPhoneCall,
  ARROW_CIRCLE_LEFT: Icons.ArrowCircleLeft,
  ARROW_CIRCLE_RIGHT: Icons.ArrowCircleRight,
  ARROW_CIRCLE_UP: Icons.ArrowCircleUp,
  ARROW_CIRCLE_DOWN: Icons.ArrowCircleDown,
  MESSAGES_USER_CHECK: Icons.MessagesPeopleUserCheck,
  NETWORK_USER: Icons.NetworkUser,
  TASK_LIST_TEXT: Icons.TaskListText,
  TRANSLATE: Icons.Translate,
  IMAGE_FILE: Icons.ImageFile,
  NAVIGATION_MENU_4: Icons.NavigationMenu4,
  ROLE_REMOVE: Icons.RoleRemove,

  // import 24.05.2024
  ORG_SCOPE: Icons.OrgScope,

  // Nav Icons
  NEW_REQUEST: Icons.NewRequest,
  NEW_REQUEST_FILLED: Icons.NewRequestFilled,
  ROLE: Icons.Role,
  CHILD_ROLE: Icons.ChildRole
}

const useAliceIconType = (type) => iconMapping[type]

export { useAliceIconType, AliceIconType }
