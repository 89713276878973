import React from "react";
import {
  Skeleton,
  Stack,
  PaginationSkeleton,
  StyledDetailTabLoader,
} from "./CommonSkeletons";
import TableRowsLoader from "./TableRowsLoader";

export const RoleReviewSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" height={56} width={200} />
      <Skeleton variant="text" height={36} width="40%" />
      <Skeleton
        variant="rounded"
        width={150}
        height={32}
        sx={{ borderRadius: "100px", margin: "30px 0" }}
      />
      <Stack direction="row" alignItems="center" gap="30px" flexWrap="wrap">
        <Stack direction="column">
          <Skeleton variant="text" width={100} />

          <Skeleton
            variant="rounded"
            width={150}
            sx={{ borderRadius: "100px" }}
          />
        </Stack>
        <Stack direction="column">
          <Skeleton variant="text" width={100} />

          <Stack direction="row">
            <Skeleton variant="circular" width={32} height={34} />
            <Skeleton
              variant="circular"
              width={32}
              height={34}
              sx={{ marginLeft: "-8px" }}
            />
            <Skeleton
              variant="circular"
              width={32}
              height={34}
              sx={{ marginLeft: "-8px" }}
            />
            <Skeleton
              variant="circular"
              width={32}
              height={34}
              sx={{ marginLeft: "-8px" }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Skeleton variant="text" width={100} sx={{ marginTop: "30px" }} />
      <Skeleton
        variant="rounded"
        sx={{ borderRadius: "100px", marginBottom: "30px", paddingTop: "10px" }}
      />
    </>
  );
};

export const DetailTabLoader = () => {
  return (
    <StyledDetailTabLoader>
      <PaginationSkeleton />
      <TableRowsLoader rowsNum={10} />
    </StyledDetailTabLoader>
  );
};
