import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { EjectOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Accordion, AccordionSummary } from '../../accordion'
import { Badge } from '../../badge'
import { IconWrapper } from '../../icon'

const Icon = ({ size = 16 }) => (
  <IconWrapper
    size={size}
    color="blue.45"
    sx={{ transform: 'rotate(-90deg)' }}
  >
    <EjectOutlined />
  </IconWrapper>
)

const MenubarTitle = ({
  children,
  onCollapse,
  isCollapsed,
  ...props
}) => (
  <Stack
    direction="row"
    spacing={2}
    justifyContent={isCollapsed ? 'center' : 'space-between'}
    sx={{ position: 'relative', padding: '20px' }}
  >
    {!isCollapsed && (
      <Typography
        sx={{
          userSelect: 'none',
          color: '#000',
          fontSize: 22,
          fontWeight: 'bold'
        }}
        {...props}
      >
        {children}
      </Typography>
    )}

    <Box
      onClick={onCollapse}
      style={{
        cursor: 'pointer',
        height: 22,
        width: 22,
        ...(isCollapsed && { transform: 'rotate(180deg)' })
      }}
    >
      <Icon size={24} />
    </Box>
  </Stack>
)

const MenubarRow = ({
  id,
  icon,
  title,
  badge = null,
  selectedId,
  onClick = () => {},
  isCollapsed = false
}) => {
  const Icon = ({ children }) => (
    <div style={{
      width: 36, height: 36, pointerEvents: 'none'
    }}
    >
      {children}
    </div>
  )

  const renderBadge = !!(
    badge && ((typeof badge === 'number' && badge > 0) || typeof badge === 'object')
  )

  const BadgeFull = ({ children }) => {
    if (typeof children === 'number') {
      return <Badge filled={selectedId === id}>{children}</Badge>
    }

    return <span>{children}</span>
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      sx={{
        height: isCollapsed ? 65 : 50,
        fontSize: 14,
        fontWeight: selectedId === id ? 'bold' : 'normal',
        boxShadow: 0,
        bgcolor: selectedId === id ? '#e9e9e9' : '#fff',
        '&:hover': {
          bgcolor: '#e9e9e9',
          color: '#000'
        }
      }}
      style={{
        padding: isCollapsed ? '0 12px' : '0 30px 0 30px',
        userSelect: 'none',
        cursor: 'pointer'
      }}
      onClick={() => onClick(id)}
    >
      {isCollapsed && icon && (
        <>
          <Icon>{icon}</Icon>

          {renderBadge && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                marginRight: 12,
                marginTop: -16
              }}
            >
              <BadgeFull>{badge}</BadgeFull>
            </div>
          )}
        </>
      )}

      {!isCollapsed && (
        <>
          {icon && <Icon>{icon}</Icon>}

          {title && (
            <div style={{
              fontSize: 14, flexGrow: 1, lineHeight: '16px'
            }}
            >
              {title}
            </div>
          )}

          {renderBadge && (
            <div>
              <BadgeFull>{badge}</BadgeFull>
            </div>
          )}
        </>
      )}
    </Stack>
  )
}

/**
 * A collapsible Menubar
 */
export const Menubar = ({
  config,
  onClick = (itemId) => {},
  collapsed,
  onCollapse = (isCollapsed) => {},
  selected = ''
}) => {
  const [ isCollapsed, setIsCollapsed ] = useState(collapsed || false)
  const [ selectedId, setSelectedId ] = useState(selected)

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed)
    onCollapse(isCollapsed)
  }

  const handleClick = (itemId) => {
    setSelectedId(itemId)
    onClick(itemId)
  }

  return (
    <Card
      data-testid="Menubar"
      sx={{
        height: 'auto',
        padding: 0,
        boxShadow: 0,
        border: '1px solid #ddd',
        ...(isCollapsed && { width: 75 })
      }}
    >
      {config.title && (
        <MenubarTitle
          isCollapsed={isCollapsed}
          onCollapse={handleCollapse}
          onClick={() => handleClick(config.id)}
        >
          {config.title}
        </MenubarTitle>
      )}

      {config.items && config.items.map((item, i) => (
        <div key={`Menubar_${i}`}>
          {!item.items && ( // has no children
            <Box
              sx={{
                backgroundColor: '#fff',
                borderBottom: 0,
                position: 'relative'
              }}
            >
              <MenubarRow
                id={item.id}
                icon={item.icon}
                title={item.title}
                badge={item.badge}
                selectedId={selectedId}
                onClick={() => handleClick(item.id)}
                isCollapsed={isCollapsed}
              />
            </Box>
          )}

          {!!item.items?.length && (
            <div>
              <Accordion
                disabled={item.disabled}
                defaultExpanded={item.opened}
                sx={{
                  boxShadow: 0,
                  border: 0,
                  borderTop: '1px solid #ddd',
                  '.MuiAccordionDetails-root': { padding: 0 }
                }}
                header={
                  (!isCollapsed && item.title) ? (
                    <AccordionSummary
                      expandIcon={<IconWrapper size={32}><KeyboardArrowDown /></IconWrapper>}
                      sx={{
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        boxShadow: 0,
                        paddingRight: 4,
                        paddingLeft: '20px',
                        '&:hover': {
                          bgcolor: '#f3f3f3',
                          color: '#000'
                        }
                      }}
                    >
                      <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                  ) : (
                    'headless'
                  )
                }
              >
                <Box sx={{ backgroundColor: '#fff', borderBottom: 0 }}>
                  {!item.disabled && item.items.map((child, j) => (
                    <Fragment key={`MenubarItem_${i}_${j}`}>
                      <MenubarRow
                        id={child.id}
                        icon={child.icon}
                        title={child.title}
                        badge={child.badge}
                        selectedId={selectedId}
                        onClick={() => handleClick(child.id)}
                        isCollapsed={isCollapsed}
                      />
                    </Fragment>
                  ))}
                </Box>
              </Accordion>
            </div>
          )}
        </div>
      ))}
    </Card>
  )
}

Menubar.propTypes = {
  /**
   * Event that's called on Item click
   *
   * @param itemId
   * @returns {void}
   */
  onClick: PropTypes.func,
  /**
   * Called when the menu is collapsed or expanded.
   *
   * @param isCollapsed
   * @returns {void}
   */
  onCollapse: PropTypes.func,
  /** If the menubar should be collapsed at start */
  collapsed: PropTypes.bool,
  /** ID of preselected item. Default is empty */
  selected: PropTypes.string,
  /** A config object */
  config: PropTypes.shape({
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
    icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    opened: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        opened: PropTypes.bool,
        title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
        badge: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
        icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
            badge: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
            icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ])
          })
        )
      })
    )
  }).isRequired
}
