import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Chip, Typography } from '@mui/material'
import { CalendarMonthOutlined, CheckCircleOutlined, ErrorOutlineOutlined, HighlightOffOutlined, TimerOutlined } from '@mui/icons-material'
import { mergeSxProps } from '../../../util'
import { Skeleton } from '../../skeleton'

/**
 * Status Chip using MuiChip<br>
 * https://mui.com/material-ui/api/chip/
 */
export const TaskStatusChip = forwardRef((
  {
    type,
    variant = 'long',
    isLoading = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const typeConfig = {
    pending: {
      color: 'blue.85',
      colorIcon: 'blue.40',
      icon: <TimerOutlined />
    },
    approved: {
      color: 'green.85',
      colorIcon: 'green.45',
      icon: <CheckCircleOutlined />
    },
    expired: {
      color: 'grey.70',
      colorIcon: 'grey.50',
      icon: <CalendarMonthOutlined />
    },
    failed: {
      color: 'yellow.80',
      colorIcon: 'yellow.45',
      icon: <ErrorOutlineOutlined />
    },
    rejected: {
      color: 'red.80',
      colorIcon: 'red.45',
      icon: <HighlightOffOutlined />
    }
  }

  return isLoading
    ? (
      <Skeleton
        variant="rounded"
        sx={{
          width: 100,
          height: 24,
          borderRadius: 50
        }}
      />
    )
    : (
      <Chip
        data-testid="TaskStatusChip"
        ref={ref}
        label={variant === 'long' ? (
          <Typography variant="subtitle2">
            {t(`statusChip.${type}`)}
          </Typography>
        ) : null}
        variant="filled"
        size="small"
        icon={typeConfig[type].icon}
        sx={mergeSxProps(
          ({ palette }) => ({
            gap: variant === 'long' ? 1 : 'unset',
            paddingInline: 1,
            color: variant !== 'icon' ? palette.text.primary : typeConfig[type].colorIcon,
            backgroundColor: variant !== 'icon' ? typeConfig[type].color : 'transparent',
            '.MuiSvgIcon-root': { margin: 0, color: 'inherit' },
            '.MuiChip-label': { padding: 0 }
          }),
          sx
        )}
        {...otherProps}
      />
    )
})

TaskStatusChip.propTypes = {
  /** status type */
  type: PropTypes.oneOf([ 'pending', 'approved', 'expired', 'failed', 'rejected' ]),
  /** status variant */
  variant: PropTypes.oneOf([ 'long', 'short', 'icon' ]),
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
