export const UserCardData = {
  country: 'DE',
  groupType: '0',
  departmentNumber: 'ITP/TI',
  givenname: 'Kevin',
  homeOrgId: 'E-9953',
  homeOrgName: 'Mercedes-Benz Tech Innovation GmbH',
  id: 'KKELLEN',
  mailAddress: 'kevin.kellenberger@mercedes-benz.com',
  mobileNumber: '+49 176 30907259',
  surname: 'Kellenberger',
  communities: [
    'DAIMLER_EMPLOYEE',
    'DAIMLER_INTERNAL'
  ],
  completeName: 'Kevin Kellenberger',
  isActive: true,
  plant1: '415',
  plant2: '415'
}

export const UserCardTechUser = {
  country: 'WOR',
  groupType: '0',
  givenname: 'TechUser',
  homeOrgId: 'D-World',
  homeOrgName: 'World (D)',
  id: 'ALICE_TechUser',
  mailAddress: 'no-reply@mercedes-benz.com',
  surname: 'ALICE_TechUser',
  communities: [
    'TECH_USER',
    'TECH_USER__SYSTEM'
  ],
  completeName: 'TechUser ALICE_TechUser',
  isActive: true
}

export const UserCardTechTestUser = {
  country: 'WOR',
  groupType: '0',
  givenname: 'TestTech',
  homeOrgId: 'D-World',
  homeOrgName: 'World (D)',
  id: 'ALICE_TestTech',
  mailAddress: 'no-reply@mercedes-benz.com',
  surname: 'ALICE_TestTech',
  communities: [ 'TECH_USER', 'TECH_USER__SYSTEM', 'TEST_USER', 'TEST_USER__ORG' ],
  completeName: 'TechUser ALICE_TestTech',
  isActive: true
}

export const UserCardTestUser = {
  country: 'WOR',
  groupType: '0',
  givenname: 'TestUser',
  homeOrgId: 'D-World',
  homeOrgName: 'World (D)',
  id: 'ALICE_TestUser',
  mailAddress: 'no-reply@mercedes-benz.com',
  surname: 'ALICE_TestUser',
  communities: [ 'TEST_USER', 'DEALER_EMPLOYEE', 'TEST_USER__APP', 'CORPORATE_CUSTOMER_EMPLOYEE' ],
  completeName: 'TechUser ALICE_TestUser',
  isActive: true
}

export const UserCardBotUser = {
  country: 'WOR',
  groupType: '0',
  givenname: 'BotUser',
  homeOrgId: 'D-World',
  homeOrgName: 'World (D)',
  id: 'ALICE_BotUser',
  mailAddress: 'no-reply@mercedes-benz.com',
  surname: 'ALICE_BotUser',
  communities: [ 'BOT_USER' ],
  completeName: 'TechUser ALICE_BotUser',
  isActive: true
}
