import { Box } from '@mui/material'
import { repeatString } from '../../../../util'

export const DefaultGridViewRenderer = ({
  isList,
  isDividedShown,
  hasCheckbox,
  testId,
  subgridContentColumns,
  children
}) => {
  const isGrid = !isList

  return (
    <Box
      data-testid={testId}
      sx={[
        {
          display: 'grid',
          gap: 2,
          overflow: 'auto'
        },
        isList && { gridTemplate: `"${hasCheckbox ? 'select' : ''} ${repeatString(subgridContentColumns, 'content')} ${isDividedShown ? 'divided' : ''}" / ${hasCheckbox ? 'auto' : ''} ${repeatString(subgridContentColumns, 'minmax(200px, 1fr)')}` },
        isGrid && {
          gridTemplateColumns: 'repeat(3, minmax(min-content, 1fr))',
          gridAutoFlow: 'row dense'
        }
      ]}
    >
      {children}
    </Box>
  )
}
