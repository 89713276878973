import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUser } from "@alice/component_library";
import { FetchAllAssignedRoles } from "@actions";
import RolesPage from "@pages/myRoles/Roles";

const MyRolesTab = React.memo(() => {
  const { user } = useUser();
  const assignedRoles = useSelector((state) => state.role.assignedRoles);

  const dispatch = useDispatch();
  const userData = user;

  const fetchData = useCallback(
    async () => {
      const { id } = userData || {};
      if (id) {
        await dispatch(
          FetchAllAssignedRoles({
            userId: id,
          })
        );
      }
    },
    [dispatch, userData] //value
  );

  useEffect(() => {
    fetchData();
  }, [userData]); //value

  return (
    <RolesPage
      assignedRoles={assignedRoles?.sort((a, b) =>
        a?.roleDefinition?.name.localeCompare(b.roleDefinition.name)
      )}
      userId={userData?.id}
      fetchData={fetchData}
    />
  );
});

export default MyRolesTab;
