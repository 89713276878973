import React from "react";
import { Grid, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { FocusDot } from "@alice/component_library";
import ScopeCardRemoveIcon from "@pageIcons/roles/scopeCardRemoveIcon";
import CustomScopeIcon from "@pageIcons/landing/customScopeIcon.svg";
import {
  AliceIcon,
  AliceIconType,
  IconButtonText,
} from "@alice/component_library";

const CustomCard = styled(Card)({
  borderRadius: "0px",
  padding: "24px",
  boxShadow: "none !important",
  border: "1px solid #C8C8C8 !important",
  height: "100%",
  "& .mt6": {
    marginTop: "-6px",
  },
  "& .ml7": {
    marginLeft: "7px",
  },
});

const CloseIconContainer = styled(Grid)({
  float: "right",
  position: "relative",
  bottom: "11.5px",
  right: "12.5px",
});

const OrgScopeContainer = styled(Grid)({
  display: "flex",
  alignItems: "flex-start",
});

const ScopeCard = (props) => {
  const { customScope, orgScope, id, removeScopes, groupType, orgId } = props;

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <CloseIconContainer onClick={() => removeScopes(id)}>
        <ScopeCardRemoveIcon />
      </CloseIconContainer>

      <CustomCard>
        {orgScope && (
          <OrgScopeContainer item lg={12} md={12} sm={12}>
            <div className="mt12">
              <IconButtonText
                icon={
                  <AliceIcon
                    iconType={AliceIconType.UIDate_From}
                    size={22}
                    color="#000000"
                    className="font-iconxsmall"
                  />
                }
              />
            </div>

            <div className="ml-16 mt6">
              <div className="font-weight-700">
                <span>
                  {orgScope}
                  <span className="focus-dot-inline ml-10">
                    <FocusDot size="small" groupType={groupType} />
                  </span>
                </span>
              </div>

              <div className="font-16 font-weight-400">{orgId}</div>
            </div>
          </OrgScopeContainer>
        )}

        {customScope && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className={
              orgScope
                ? "font-16 font-weight-400 mt-16 flex"
                : "font-16 font-weight-400  flex "
            }
          >
            <Grid>
              <IconButtonText
                icon={
                  <IconButtonText
                    icon={<CustomScopeIcon viewBox="0 0 28 28" />}
                    color="#000000"
                    className="font-iconxsmall"
                  />
                }
              />
            </Grid>
            <Grid item lg={11} className="font-16 mt6 ml7">
              {customScope ? customScope : ""}
            </Grid>
          </Grid>
        )}
      </CustomCard>
    </Grid>
  );
};
export default ScopeCard;
