import CloseIcon from '@mui/icons-material/Close'
import { Box, Divider, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from '../../../util'
import { IconButton } from '../../button'
import { CardContent, CardDivided, GenericCard } from '../../card/genericCard'
import { Chip } from '../../chip'
import { Tooltip } from '../../message'

export const GenericSearchActiveFilters = ({
  possibleFilters = {},
  activeFilters = {},
  preselectedFilter = {},
  preselectedFilterBehavior,
  isSingleFilter,
  onDelete = (key, filter) => {},
  onClear = () => {},
  sx = {}
}) => {
  const { t } = useTranslation('componentLibrary')
  const [ isAllPreselected, setIsAllPreselected ] = useState(false)

  const activeFilterArray = useMemo(() => {
    const filters = []
    let isAnyFilterEnabled = false

    if (isEmpty(possibleFilters)) return filters

    Object.keys(activeFilters)
      .forEach((filterKey) => {
        activeFilters[filterKey].forEach((activeFilter) => {
          const singleFilter = isSingleFilter
            ? possibleFilters[filterKey]
            : possibleFilters[filterKey].filters.find(({ value }) => value === activeFilter)
          const isDisabled = !!preselectedFilterBehavior && preselectedFilter[filterKey]?.includes(singleFilter.value)

          if (!isDisabled) isAnyFilterEnabled = true

          filters.push({
            filterKey,
            isDisabled,
            value: singleFilter?.value || '',
            label: singleFilter?.label || '',
            icon: possibleFilters[filterKey]?.icon
          })
        })
      })

    setIsAllPreselected(!isAnyFilterEnabled)

    return filters

    // Avoids stack overflow without losing caching of heavy operation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ possibleFilters, activeFilters ])

  return !!activeFilterArray.length && (
    <GenericCard
      data-testid="GenericSearchActiveFilter"
      isList
      isDividedShown
      sx={sx}
    >
      <CardContent
        isList
        sx={{
          gridTemplate: `
            "applied-filters"
            "content"`
        }}
      >
        <Typography
          variant="body2"
          sx={{
            gridArea: 'applied-filters',
            color: 'text.secondary'
          }}
        >
          {t('genericSearch.appliedFilters')}
        </Typography>

        <Stack
          direction="row"
          gap={1}
          flexWrap="wrap"
          sx={{
            gridArea: 'content',
            width: '100%'
          }}
        >
          {activeFilterArray.map(({
            filterKey,
            icon,
            isDisabled,
            label,
            value
          }) => (
            <Chip
              key={`${filterKey}-${value}`}
              sx={{
                padding: '0px 14px',
                cursor: 'default',
                'svg, img': {
                  width: '18px',
                  height: '18px',
                  color: 'text.disabled'
                },
                '.MuiChip-deleteIcon': {
                  width: '20px',
                  height: '20px',
                  '&:hover': { color: 'common.black' }
                }
              }}
              onDelete={!isDisabled ? () => onDelete(filterKey, value) : undefined}
              icon={icon}
              label={label}
              variant="outlined"
            />
          ))}
        </Stack>
      </CardContent>

      <CardDivided
        isList
        action={!isAllPreselected && (
          <Tooltip placement="bottom" title={t('genericSearch.clearFilters')}>
            <IconButton size="small" onClick={onClear} sx={{ color: 'text.primary' }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      />
    </GenericCard>
  )
}

GenericSearchActiveFilters.propTypes = {
  possibleFilters: PropTypes.object,
  activeFilters: PropTypes.object,
  preselectedFilter: PropTypes.object,
  preselectedFilterBehavior: PropTypes.string,
  onDelete: PropTypes.func,
  onClear: PropTypes.func
}
