import React from 'react'
import { KeyboardArrowUp } from '@mui/icons-material'
import { IconWrapper } from '../../icon'

export const IconLeft = () => (
  <div
    style={{
      cursor: 'pointer',
      height: 16,
      width: 16,
      transform: 'rotate(-90deg)'
    }}
  >
    <IconWrapper><KeyboardArrowUp /></IconWrapper>
  </div>
)

export const IconRight = () => (
  <div
    style={{
      cursor: 'pointer',
      height: 16,
      width: 16,
      transform: 'rotate(90deg)'
    }}
  >
    <IconWrapper><KeyboardArrowUp /></IconWrapper>
  </div>
)
