import {
  Box,
  Chip as MuiChip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import {
  CopyButton,
  GenericContextPopover,
  Tooltip,
  UserAvatar,
  UserContextPopover,
  mergeSxProps,
} from "@alice/component_library";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DecisionCell from "./DecisionCell";
import { useTranslation } from "react-i18next";
import { TypographyWithCopyButton } from "../styledComponents";

export const getTitle = (key, filterValue) => {
  const { t } = useTranslation();

  switch (key) {
    case "roleToUser.user.surname":
      return (
        <Tooltip type="ellipsized" title={t("last-name")}>
          {t("last-name")}
        </Tooltip>
      );
    case "roleToUser.user.id":
      return (
        <Tooltip type="ellipsized" title={t("id")}>
          {t("id")}
        </Tooltip>
      );
    case "roleToUser.user.givenname":
      return (
        <Tooltip type="ellipsized" title={t("first-name")}>
          {t("first-name")}
        </Tooltip>
      );
    case "roleToUser.validFrom":
      return (
        <Tooltip
          type="ellipsized"
          className="disable-sort"
          title={t("valid_from")}
        >
          {t("valid_from")}
        </Tooltip>
      );
    case "roleToUser.validTo":
      return (
        <Tooltip
          type="ellipsized"
          className="disable-sort"
          title={t("valid-to")}
        >
          {t("valid-to")}
        </Tooltip>
      );
    case "decision":
      return (
        <Tooltip
          type="ellipsized"
          title={t("decision")}
          className="disable-sort"
        >
          {t("decision")}
        </Tooltip>
      );
    case "roleToUser.orgScope.name":
      return (
        <Tooltip
          type="ellipsized"
          title={t("org-scope")}
          className="disable-sort"
        >
          {t("org-scope")}
        </Tooltip>
      );
    case "roleToUser.customScope.name":
      return (
        <Tooltip
          type="ellipsized"
          title={t("custom-scope")}
          className="disable-sort"
        >
          {t("custom-scope")}
        </Tooltip>
      );
    default:
      break;
  }
};

export const dataRenderer = (key, data) => {
  const id = data?.uuid;
  const { roleToUser } = data;
  const {
    user = {},
    orgScope = {},
    customScope = {},
    validityFrom,
    validityTo,
  } = roleToUser;
  const lastName = (
    <>
      <UserContextPopover id={user.id}>
        <UserAvatar
          size="s"
          userId={user.id}
          userName={`${user.givenname} ${user.surname}`}
          showImage={true}
        />
      </UserContextPopover>
      {user?.surname ? (
        <span className="data-elipsis">
          <Tooltip type="ellipsized" title={user?.surname}>
            {user?.surname}
          </Tooltip>
        </span>
      ) : (
        <>-</>
      )}
    </>
  );

  const idColumn = (
    <span className="id-column">
      <UserContextPopover id={user.id}>
        <UserAvatar
          size="s"
          userId={user.id}
          userName={`${user.givenname} ${user.surname}`}
          showImage={true}
          groupType={user.groupType}
          showGroupType
        />
      </UserContextPopover>
      <span className="userId">
        {user.id ? (
          <Tooltip type="ellipsized" title={user.id}>
            {user.id}
          </Tooltip>
        ) : (
          <>-</>
        )}
      </span>
    </span>
  );

  const fristName = (
    <>
      {user?.givenname ? (
        <span className="data-elipsis">
          <Tooltip type="ellipsized" title={user?.givenname}>
            {user?.givenname}
          </Tooltip>
        </span>
      ) : (
        <>-</>
      )}
    </>
  );

  const organsizationScope = (
    <>
      {orgScope?.name ? (
        <span className="info-orgScope">
          <Tooltip type="ellipsized" title={orgScope?.name}>
            <span className="data-elipsis">{orgScope?.name}</span>
          </Tooltip>
          <CustomContextPopover orgScope={orgScope} />
        </span>
      ) : (
        <> - </>
      )}
    </>
  );

  const customScopeRow = (
    <>
      {customScope?.name ? (
        <span className="data-elipsis">
          <Tooltip type="ellipsized" title={customScope?.name}>
            {customScope?.name}
          </Tooltip>
        </span>
      ) : (
        <>-</>
      )}
    </>
  );

  const validFromRow = <>{validityFrom}</>;

  const validToRow = <>{validityTo}</>;

  switch (key) {
    case "roleToUser.user.surname":
      return lastName;
    case "roleToUser.user.id":
      return idColumn;
    case "roleToUser.user.givenname":
      return fristName;
    case "roleToUser.validFrom":
      return validFromRow;
    case "roleToUser.validTo":
      return validToRow;
    case "roleToUser.orgScope.name":
      return organsizationScope;
    case "roleToUser.customScope.name":
      return customScopeRow;
    default:
      break;
  }
};

export const actionbuttonRenderer = (
  key,
  data,
  rolesDataInfo,
  roleReviewId,
  disabled
) => {
  const decisionCell = (
    <DecisionCell
      row={data}
      disabled={disabled}
      rolesDataInfo={rolesDataInfo}
      roleReviewId={roleReviewId}
    />
  );

  return decisionCell;
};

export const ValidityChip = ({
  size = "m",
  formattedExpiryDate,
  remainingDaysToWarning = 14,
  remainingDaysToRed = 0,
  sx = [],
  daysLeft,
  ...otherProps
}) => {
  const { t } = useTranslation("componentLibrary");

  const chipBgColor = () => {
    if (daysLeft <= remainingDaysToRed) return "red.80";
    if (daysLeft <= remainingDaysToWarning) return "yellow.80";
    return "white";
  };

  const Label = () => (
    <>
      <Typography as="span" className="chipDateWrapper">
        {formattedExpiryDate}
      </Typography>

      {size !== "s" && (
        <>
          <Divider orientation="vertical" sx={{ height: "16px" }} />

          <Stack direction="row" alignItems="center" gap="2px">
            <Box className="chipIconWrapper">
              <TimerOutlinedIcon fontSize="inherit" />
            </Box>

            <Typography as="span">
              {`${Math.max(daysLeft, 0)} ${t("validityChip.daysLeft")}`}
            </Typography>
          </Stack>
        </>
      )}
    </>
  );

  return (
    <MuiChip
      data-testid="ValidityChip"
      size="small"
      label={<Label />}
      sx={mergeSxProps(
        {
          color: "black",
          backgroundColor: chipBgColor(),
          border: "unset",
          paddingInline: "8px",
          ".MuiChip-label": {
            display: "flex",
            alignItems: "center",
            gap: "6px",
            p: "0",
          },
          ".chipDateWrapper": {
            fontWeight: 700,
            fontSize: "14px",
          },
          ".chipIconWrapper": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "16px",
            aspectRatio: "1 / 1",
            marginInline: "0",
            fontSize: "16px",
          },
        },
        sx
      )}
      {...otherProps}
    />
  );
};

export const CustomContextPopover = ({ orgScope }) => {
  const { t } = useTranslation();
  return (
    <GenericContextPopover
      context={
        <>
          <Typography variant="h6">{t("org-name")}</Typography>
          <TypographyWithCopyButton
            variant="body1"
            className="org-custom-context"
          >
            {orgScope?.name} <CopyButton text={orgScope?.name} />
          </TypographyWithCopyButton>
          <Typography variant="h6">{t("org-id")}</Typography>
          <TypographyWithCopyButton
            variant="body1"
            className="org-custom-context"
          >
            {orgScope?.id} <CopyButton text={orgScope?.id} />
          </TypographyWithCopyButton>
        </>
      }
      className="custom-context"
    >
      <InfoOutlinedIcon color="primary" fontSize="16px" />
    </GenericContextPopover>
  );
};
