import React from "react";
import { DECISION_CONSTANT } from "../utils";

import {
  ActionButton,
  OpenReviewsActionButtonContainer,
  Typography,
  HighlightOffOutlinedIcon,
  CheckCircleOutlineIcon,
} from "../styledComponents";

const ActionButtonComponent = ({
  handleConfirmAction,
  isButtonDisabled,
  buttonConfirmTextRef,
  buttonDeleteTextRef,
}) => {
  const { CONFIRMED, DELETED } = DECISION_CONSTANT;

  return (
    <OpenReviewsActionButtonContainer>
      <ActionButton
        onClick={() => handleConfirmAction(CONFIRMED)}
        reject="true"
        disabled={isButtonDisabled}
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" display="block">
          {buttonConfirmTextRef}
        </Typography>
      </ActionButton>
      <ActionButton
        onClick={() => handleConfirmAction(DELETED)}
        success="true"
        disabled={isButtonDisabled}
      >
        <HighlightOffOutlinedIcon />
        <Typography variant="caption" display="block">
          {buttonDeleteTextRef}
        </Typography>
      </ActionButton>
    </OpenReviewsActionButtonContainer>
  );
};

export default React.forwardRef(ActionButtonComponent);
