import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class RolesService {
  static getAll = ({ ...args }) => AliceService.get(
    '/roles',
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/roles/${id}`,
    { ...args }
  )

  static getRoleAssigners = ({
    id,
    ...args
  }) => AliceService.get(
    `/roles/${id}/globalroleassigners`,
    { ...args }
  )

  static create = ({
    role,
    ...args
  }) => AliceService.post(
    '/roles',
    mergeObjects(
      { body: { ...role } },
      { ...args }
    )
  )

  static updateById = ({
    id,
    role,
    ...args
  }) => AliceService.put(
    `roles/${id}`,
    mergeObjects(
      { body: role },
      { ...args }
    )
  )

  static addGroupPrivilegeToRole = ({
    roleId,
    groupId,
    privilege,
    ...args
  }) => AliceService.post(
    `/roles/${roleId}/${privilege}/${groupId}`,
    mergeObjects(
      { ...args }
    )
  )

  static addEntitlement = ({
    roleId,
    applicationId,
    entitlementId,
    ...args
  }) => AliceService.post(
    `/roles/${roleId}/applications/${applicationId}/entitlements/${entitlementId}`,
    { ...args }
  )

  static getEntitlements = ({
    roleId,
    ...args
  }) => AliceService.get(
    `/roles/${roleId}/entitlements`,
    { ...args }
  )
}
