import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { TypographyLineClamp } from '../typography'
import { mergeSxProps } from '../../util'
import { Tooltip } from '../message'

export const OverflowTooltip = ({
  content,
  lineClamp = 1,
  sx = {} || [],
  ...otherProps
}) => {
  const ref = useRef(null)

  const [ isContentOverflow, setIsContentOverflow ] = useState(false)

  const checkOverflow = () => {
    const { width: elmWidth, height: elmHeight } = getComputedStyle(ref.current)
    const { width: shadowElmWidth, height: shadowElmHeight } = getComputedStyle(ref.current, '::after')

    setIsContentOverflow(
      parseInt(shadowElmWidth, 10) > parseInt(elmWidth, 10)
      || parseInt(shadowElmHeight, 10) > parseInt(elmHeight, 10)
    )
  }

  useEffect(() => {
    checkOverflow()
  }, [ content ])

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      checkOverflow()
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [ ref.current?.textContent ])

  return (
    <Tooltip title={content} disabled={!isContentOverflow}>
      <TypographyLineClamp
        ref={ref}
        lineClamp={lineClamp}
        data-text={content}
        sx={mergeSxProps({
          position: 'relative',
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            visibility: 'hidden',
            content: 'attr(data-text)'
          }
        }, sx)}
        {...otherProps}
      >
        {content}
      </TypographyLineClamp>
    </Tooltip>
  )
}

OverflowTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  sx: PropTypes.any
}
