import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { Badge } from "@alice/component_library";
import NoRolesIcon from "@Icons/newRequestPage/noRolesSelected.svg";
import SelectedRoleCards from "@pages/newRequest/components/RoleSelection/SelectedRoleCards";
import {
  NoRolesSelectedContainer,
  NoRolesSelectedText,
  SelectedRolesContainer,
  SelectedRolesHeader,
} from "@pages/newRequest/styledComponents";
import { useWidth } from "@utils/hooks";
import AccordianContainer from "./AccordianSelected";

const SelectedRolesSections = ({ selectedRoles, handleRemove }) => {
  const { t } = useTranslation();
  const widthPanel = useWidth();

  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: true,
    isDesktop: false,
  });

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
      }
    }
  }, [widthPanel]);

  const RoleSelectionInfo = ({ selectedRoles }) => {
    return (
      <div>
        {selectedRoles.length ? (
          <div className="selected-role-grid">
            {selectedRoles.map((role) => (
              <SelectedRoleCards role={role} handleRemove={handleRemove} />
            ))}
          </div>
        ) : (
          <NoRolesSelectedContainer>
            <NoRolesIcon />
            <NoRolesSelectedText>{t("no-roles-selected")}</NoRolesSelectedText>
          </NoRolesSelectedContainer>
        )}
      </div>
    );
  };

  return (
    <>
      {!windowSize.isMobile ? (
        <SelectedRolesContainer>
          <SelectedRolesHeader>
            {t("selected_roles")}
            {!!selectedRoles?.length && (
              <Badge id="selected-roles-count" filled>
                {selectedRoles?.length}
              </Badge>
            )}
          </SelectedRolesHeader>
          <Divider />
          <RoleSelectionInfo selectedRoles={selectedRoles} />
        </SelectedRolesContainer>
      ) : (
        <AccordianContainer
          children={<RoleSelectionInfo selectedRoles={selectedRoles} />}
        />
      )}
    </>
  );
};

export default SelectedRolesSections;
