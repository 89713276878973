import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { AppCard } from "@alice/component_library";
import { hasData } from "@utils/Validator";
import { DescriptionContainer } from "./styledComponents";
import { AppListSkeleton } from "../Skeleton/CommonSkeletons";
const Description = ({ description, appIds = [] }) => {
  const { t } = useTranslation();
  const { appData = {}, loading = false } = useSelector(
    (state) => state.newRequestSlice.applicationDetails
  );
  return (
    <DescriptionContainer
      testId="role-detail-description"
      twosections={!!(appIds.length < 2)}
    >
      <div>
        <Typography variant="caption" noWrap className="caption-header">
          {t("description")}
        </Typography>
        <Typography variant="body2" className="description">
          {description}
        </Typography>
      </div>
      {loading ? (
        <AppListSkeleton listLength={1} showDivider={false} />
      ) : (
        <>
          {!!appIds.length && (
            <div>
              <Typography variant="caption" noWrap className="caption-header">
                {t("application(s)")}
              </Typography>
              <div className="app-grid">
                {appIds.map(
                  (id) =>
                    hasData(appData[id]) && (
                      <AppCard
                        data={{ ...appData[id], logo: appData[id]?.logoBase64 }}
                        key={id}
                        size="s"
                        variant="tiny"
                      />
                    )
                )}
              </div>
            </div>
          )}
        </>
      )}
    </DescriptionContainer>
  );
};

export default Description;
