import PropTypes from 'prop-types'
import { useOrgCard } from '../OrgCard'
import { InteractionPropTypes } from '../../../constants'
import { useInteraction } from '../../../hooks'
import { createArrayWithInitialValues } from '../../../util'
import { DefaultGridViewRenderer } from '../../search'
import { OrgCard } from '../OrgCard/OrgCard'

/**
 * This component renders a grid view using `OrgCard` components.
 * The use of MUI-Grid with `OrgCard` is preferred to achieve an optimal layout and responsive design.
 *
 * Important Notes:
 * - This component is purely for presentation and does not handle data fetching or state management.
 *   States such as `selectedOrg` should be managed by a parent component.
 * - It is intended to work in conjunction with components like `OrgSearch` or `GenericSearch` for displaying organization data.
 * - On mobile devices, a 'compact' view mode is automatically adopted to enhance usability.
 */
export const OrgSearchGridViewRenderer = ({
  view = 'grid',
  size = 'xl',
  data: organizations,
  selectedData: selectedOrganizations = [],
  disabledData: disabledOrganizations = [],
  interaction,
  onGetHref,
  onItemClick,
  rowsPerPage,
  isIdLink = true,
  isLoading,
  isMobile,
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  if (isMobile) view = 'list'

  const isList = view === 'list'

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({ interaction })

  const hasCheckbox = isSelectable && !isSelectHidden

  const mappableOrganizations = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({ id: index.toString() }))
    : organizations

  const {
    isContextShown: _isContextShown,
    isAddressShown: _isAddressShown,
    isCommunityShown: _isCommunityShown,
    isOrgTypeShown: _isOrgTypeShown,
    isFlagShown: _isFlagShown,
    isFocusShown: _isFocusShown,
    isStatusShown: _isStatusShown,
    onEditClick: _onEditClick
  } = otherProps || {}

  const {
    isDividedShown,
    subgridContentColumns,
    subgridContentRows
  } = useOrgCard({
    size,
    isList,
    isRenderer: true,
    _isContextShown,
    _isAddressShown,
    _isCommunityShown,
    _isOrgTypeShown,
    _isFlagShown,
    _isFocusShown,
    _isStatusShown,
    _onEditClick
  })

  return (
    <DefaultGridViewRenderer
      testId="OrgSearchGridViewRenderer"
      isList={isList}
      isDividedShown={isDividedShown}
      hasCheckbox={hasCheckbox}
      subgridContentColumns={subgridContentColumns}
    >
      {mappableOrganizations.map((organization) => (
        <OrgCard
          key={organization.id}
          variant={view === 'tiny' ? 'list' : view}
          size={view === 'tiny' ? 's' : size}
          organization={organization}
          interaction={interaction}
          href={onGetHref}
          isIdLink={isIdLink}
          isLoading={isLoading}
          isContextShown
          isSelected={selectedOrganizations.includes(organization.id)}
          isDisabled={disabledOrganizations.includes(organization.id)}
          onCardClick={onItemClick}
          isSubgrid
          subgridContentColumns={subgridContentColumns}
          subgridContentRows={subgridContentRows}
          {...otherProps}
        />
      ))}
    </DefaultGridViewRenderer>
  )
}

OrgSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  /** item size-variant */
  size: PropTypes.oneOf([ 's', 'm', 'l', 'xl' ]),
  /** Org to be displayed */
  data: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string,
    orgForm: PropTypes.string,
    validFromDate: PropTypes.string,
    preferredLanguage: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string),
    externalId: PropTypes.string,
    gssnData: PropTypes.shape({
      updateTimestamp: PropTypes.string,
      allowFrom: PropTypes.string,
      adinDefId: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      brandCodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          outletCode: PropTypes.string,
          outletStatus: PropTypes.string
        })
      ),
      companyForm: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      companyId: PropTypes.string,
      countryDefId: PropTypes.string,
      functionInfos: PropTypes.arrayOf(
        PropTypes.shape({
          activity: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          }),
          brandId: PropTypes.string,
          productGroup: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      ),
      languageCode: PropTypes.string,
      outletCode: PropTypes.string,
      outletId: PropTypes.string,
      outletStatus: PropTypes.string,
      street1: PropTypes.string
    }),
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isDaimler: PropTypes.bool,
    isUserManagementDisabled: PropTypes.bool,
    location: PropTypes.string,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string
  })).isRequired,
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Selected orgs */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled orgs */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  /** Determines if ID of card is a link or not */
  isIdLink: PropTypes.bool,
  /** Loading state */
  isLoading: PropTypes.bool,
  /** True if mobile viewport */
  isMobile: PropTypes.bool,
  /** Represents type of organization. */
  type: PropTypes.oneOf([ null, 'home', 'workplace', 'dealerhome', 'parent' ])
}
