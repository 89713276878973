import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { de, enGB, enUS, es, fr, hu, it, ja, ko, nl, pl, pt, ru, tr, zhCN } from 'date-fns/locale'
import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'
import { mergeSxProps } from '../../../util'
import { DatePickerDialog } from '../../datePickerDialog/DatePickerDialog'

const locales = {
  en: enGB || enUS || undefined,
  de,
  es,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  ru,
  tr,
  'zh-cn': zhCN
}

const CustomDialogPopper = ({
  isOpen,
  setIsOpen,
  setValue,
  selectedDate,
  preselectedDate,
  disabledMatcher
}) => (
  <DatePickerDialog
    onClose={() => setIsOpen(false)}
    onChange={() => {
      // should be here as a placeholder
    }}
    onConfirm={(values) => setValue(values ? values.from : null)}
    open={isOpen}
    parentFrom={selectedDate || preselectedDate}
    disabledMatcher={disabledMatcher}
  />
)

/**
 * The `DateInput` component is a versatile React component that provides a date input field with
 * an integrated date picker dialog. It offers several customization options through its props, making
 * it easy to tailor the date input to your specific needs.
 *
 * ## Usage
 * The `DateInput` component can be integrated into an application to provide a user-friendly date
 * input field with a date picker dialog. You can customize its appearance, preselect a date, and
 * handle selected date changes using the provided props. It also supports localization for date
 * formatting, making it a versatile component for date-related interactions in your application.
 *
 * ### Notes
 * - `DateInput` is a wrapper over MUI [DatePicker](https://mui.com/x/api/date-pickers/date-picker/#props)
 *   component. So it can take all the original MUI props.
 * - Docu for the `disabledMatcher` prop:
 *   [Matcher Documentation](https://daypicker.dev/api/type-aliases/Matcher)
 */
export const DateInput = forwardRef(({
  inputLabel = 'Select Date',
  disabled = false,
  onChange: setValue = (value) => {},
  value: selectedDate,
  preselectedDate = null,
  variant = 'filled',
  fullWidth = false,
  sx,
  error,
  helperText,
  disabledMatcher,
  slotPropsTextField,
  slotPropsPopper,
  isPickerButtonDisabled = false,
  ...otherProps
}, ref) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const currentLocale = localStorage?.getItem('i18nextLng' || 'en-US')

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[currentLocale]}>
      <DatePicker
        data-testid="DateInput"
        ref={ref}
        desktopModeMediaQuery="@media (min-width: 0px)"
        label={inputLabel}
        disableOpenPicker={isPickerButtonDisabled}
        disabled={disabled}
        slotProps={{
          textField: {
            variant,
            fullWidth,
            error,
            helperText,
            ...slotPropsTextField
          },
          popper: {
            isOpen,
            setIsOpen,
            setValue,
            selectedDate,
            preselectedDate,
            disabledMatcher,
            ...slotPropsPopper
          }
        }}
        value={selectedDate || preselectedDate}
        onChange={(value) => setValue(value)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        slots={{ popper: CustomDialogPopper }}
        sx={mergeSxProps({ '.MuiInputAdornment-root': { paddingRight: 1 } }, sx)}
        {...otherProps}
      />
    </LocalizationProvider>
  )
})

DateInput.propTypes = {
  /** The label content. */
  inputLabel: PropTypes.string,
  /** If `true`, the component is disabled. */
  disabled: PropTypes.bool,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.<br>
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /** Determines preselected date to be shown within input field */
  preselectedDate: PropTypes.instanceOf(Date),
  /** Determines currently selected date to be shown within input field */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  /** The variant to use. */
  variant: PropTypes.oneOf([ 'filled', 'outlined', 'standard' ]),
  /** The system prop that allows to set full width on dateInput */
  fullWidth: PropTypes.bool,
  /** The system prop that allows defining system overrides as well as additional CSS styles. */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([ PropTypes.func, PropTypes.object, PropTypes.bool ])
    ),
    PropTypes.func,
    PropTypes.object
  ]),
  /**
   * A value or a function that matches a day or range
   * https://react-day-picker.js.org/api/types/Matcher
   */
  disabledMatcher: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
    PropTypes.array
  ]),
  /** If true, the label is displayed in an error state. */
  error: PropTypes.bool,
  /** The helper text content. */
  helperText: PropTypes.node,
  /** MUI TextField props */
  slotPropsTextField: PropTypes.object,
  /** MUI Popper props */
  slotPropsPopper: PropTypes.object,
  /** Determines whether to show date picker button 'calendar' in text field. */
  isPickerButtonDisabled: PropTypes.bool
}
