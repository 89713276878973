import { GridViewOutlined, ViewHeadlineOutlined } from '@mui/icons-material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DataClassification, DataClassificationPropType, EntitlementType, EntitlementTypePropType } from '../../../constants'
import { CacheKey } from '../../../data'
import { GenericSearch } from '../../search'
import { EntitlementSearchGridViewRenderer } from './EntitlementSearchGridViewRenderer'

/**
 * **EntitlementSearch Component**
 *
 * The `EntitlementSearch` component is a reusable component that provides a search interface for users.
 * It allows users to search for users using a specified API endpoint and query string.
 *
 * **Important Note**
 *
 * This component is built upon the foundation of the GenericSearch component, thus inheriting all properties supported by GenericSearch.
 * For a comprehensive list of available properties, please refer to the [GenericSearch documentation](./?path=/docs/alice-ui-search-genericsearch--docs).
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 *
 * ** Note **
 * - As EntitlementSearch can use 2 different endpoints (applications, roles) make sure to provide correct `apiUrl`.
 * For more info see RoleEntitlementsSearch example below.
 */
export const EntitlementSearch = forwardRef((
  {
    title,
    description,
    app = {},
    apiUrl = `/applications/${app.id}/entitlements`,
    search = 'large',
    views = [ 'grid', 'list' ],
    itemSize,
    isIdLink = true,
    isSelectHidden = false,
    isFilterHidden,
    isSortHidden,
    isSelectAllHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    preselectedRowIds = [],
    preselectedView,
    preselectedSearch,
    preselectedSort,
    preselectedRowsPerPage,
    preselectedType,
    preselectedDataClassification,
    preselectedSortBehavior,
    preselectedSearchBehavior,
    preselectedFilterBehavior,
    isCaching = false,
    cacheKey = CacheKey.entitlementSearchQuery,
    onGetResponseData = (data) => data.entitlements,
    onGetUniqueIdentifier = (data) => data.uuid,
    onCardClick,
    rendererProps = {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const createViewConfig = () => {
    const viewConfig = { views: { } }

    if (views.includes('grid')) {
      viewConfig.views.grid = {
        tooltip: t('entitlementSearch.view.grid'),
        icon: GridViewOutlined,
        renderer: EntitlementSearchGridViewRenderer
      }
    }

    if (views.includes('list')) {
      viewConfig.views.list = {
        tooltip: t('entitlementSearch.view.list'),
        icon: ViewHeadlineOutlined,
        renderer: EntitlementSearchGridViewRenderer
      }
    }

    return viewConfig
  }

  const createSearchConfig = () => {
    if (search === undefined || search === 'none') return undefined

    return {
      type: search,
      label: t('entitlementSearch.search.label'),
      preselectedBehavior: preselectedSearchBehavior,
      apiFilters: [ 'uuid', 'entitlementId', 'displayName', 'description' ]
    }
  }

  const createSortConfig = () => {
    if (isSortHidden) return undefined

    return {
      preselectedBehavior: preselectedSortBehavior,
      sorts: [
        {
          value: '',
          label: t('genericSearch.defaultSort')
        },
        {
          value: '+displayName',
          label: t('entitlementSearch.sort.+displayName')
        },
        {
          value: '-displayName',
          label: t('entitlementSearch.sort.-displayName')
        }
      ]
    }
  }

  const createFilterConfig = () => {
    if (isFilterHidden) return undefined

    return {
      isAllShown: false,
      preselectedBehavior: preselectedFilterBehavior,
      filters: {
        type: {
          label: t('entitlements.type.label'),
          isSearchShown: false,
          isAllShown: false,
          isMultiselect: false,
          filters: [
            {
              label: t(`entitlements.type.${EntitlementType.ENTITLEMENT}`),
              value: EntitlementType.ENTITLEMENT
            },
            {
              label: t(`entitlements.type.${EntitlementType.AUTHORIZATION}`),
              value: EntitlementType.AUTHORIZATION
            }
          ]
        },
        dataClassification: {
          label: t('dataClassification.label'),
          isAllShown: false,
          isMultiselect: false,
          filters: [
            {
              value: DataClassification.INTERNAL,
              label: t(`dataClassification.${DataClassification.INTERNAL}`),
              icon: <CorporateFareIcon />
            },
            {
              value: DataClassification.CONFIDENTIAL,
              label: t(`dataClassification.${DataClassification.CONFIDENTIAL}`),
              icon: <GppGoodOutlinedIcon />
            },
            {
              value: DataClassification.SECRET,
              label: t(`dataClassification.${DataClassification.SECRET}`),
              icon: <LockOutlinedIcon />
            }
          ]
        }
      }
    }
  }

  const createPreselectedFilter = () => {
    const preselectedFilter = {}

    if (preselectedType) preselectedFilter.type = [ preselectedType ]
    if (preselectedDataClassification) preselectedFilter.dataClassification = [ preselectedDataClassification ]

    return Object.keys(preselectedFilter).length ? preselectedFilter : undefined
  }

  return (
    <GenericSearch
      data-testid="EntitlementSearch"
      ref={ref}
      title={title}
      description={description}
      getUniqueIdentifier={onGetUniqueIdentifier}
      getResponseData={onGetResponseData}
      apiUrl={apiUrl}
      cacheKey={cacheKey}
      searchConfig={createSearchConfig()}
      viewConfig={createViewConfig()}
      filterConfig={createFilterConfig()}
      sortConfig={createSortConfig()}
      preselectedRowIds={preselectedRowIds}
      preselectedView={preselectedView}
      preselectedSearch={preselectedSearch}
      preselectedSort={preselectedSort}
      preselectedFilter={createPreselectedFilter()}
      preselectedRowsPerPage={preselectedRowsPerPage}
      isCaching={isCaching}
      isSelectAllHidden={isSelectAllHidden}
      isTopPaginationHidden={isTopPaginationHidden}
      isBottomPaginationHidden={isBottomPaginationHidden}
      onItemClick={onCardClick}
      rendererProps={{
        isIdLink,
        size: itemSize,
        app,
        ...rendererProps
      }}
      {...otherProps}
    />
  )
})

EntitlementSearch.propTypes = {
  /** Adds a title */
  title: PropTypes.string,
  /** Adds a description under the title */
  description: PropTypes.string,
  /**
   * Application to get the entitlements from.
   * Also required for Card display.
   * In case EntitlementSearch is based on roles endpoint, `app` must not be provided
   */
  app: PropTypes.shape({
    /**
     * A base64-encoded image data representing the logo to be displayed.
     * If not provided, a default logo will be used.
     */
    logo: PropTypes.string,
    /** Application ID */
    id: PropTypes.string,
    /** Application url */
    url: PropTypes.string,
    /** Support email */
    email: PropTypes.string,
    /** Application name */
    name: PropTypes.string
  }),
  /** Api-Endpoint to collect required data
   * Default: '/applications/<app-id>/entitlements'.
   *
   * In case Search is used for roles entitlements make sure to provide the correct apiUrl: '/roles/<role-id>/entitlements'
   * */
  apiUrl: PropTypes.string,
  /** Type of search. Can be 'none', 'small', 'large' */
  search: PropTypes.oneOf([ 'none', 'small', 'large' ]),
  /** Views that should be displayed. */
  views: PropTypes.arrayOf(PropTypes.oneOf([ 'grid', 'list' ])),
  /** size-variant for item renderer */
  itemSize: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** List of filter organization forms */
  forms: PropTypes.arrayOf(PropTypes.string),
  /** Determines if chip is hidden or not */
  isChipHidden: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Determines if filter is hidden or not */
  isFilterHidden: PropTypes.bool,
  /** Determines if sort is hidden or not */
  isSortHidden: PropTypes.bool,
  /** Determines if pagination on top of the grid is hidden or not */
  isTopPaginationHidden: PropTypes.bool,
  /** Determines if pagination on bottom of grid is hidden or not */
  isBottomPaginationHidden: PropTypes.bool,
  /** Preselect rows */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** Preselected view */
  preselectedView: PropTypes.string,
  /** Preselected search query */
  preselectedSearch: PropTypes.string,
  preselectedSort: PropTypes.oneOf([ '+displayName', '-displayName' ]),
  /** Preselected rows per page */
  preselectedRowsPerPage: PropTypes.number,
  /** Behavior of the search when preselected */
  preselectedSearchBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the filter when preselected. See: [GenericSearch](/?path=/docs/alice-ui-search-genericsearch--docs) */
  preselectedFilterBehavior: PropTypes.oneOf([ 'disableFilter', 'disableFilterGroup', 'hideFilterGroup' ]),
  /** Type filter preselection */
  preselectedType: EntitlementTypePropType,
  /** Data classification filter preselection */
  preselectedDataClassification: DataClassificationPropType,
  /** Determines if caching is active or not */
  isCaching: PropTypes.bool,
  /** Custom caching key */
  cacheKey: PropTypes.string,
  /** Called by generic search to get data from api response. Could not be needed when using a custom endpoint. */
  onGetResponseData: PropTypes.func,
  /** Called by generic search to get id from a single data set */
  onGetUniqueIdentifier: PropTypes.func,
  /** Callback on card click */
  onCardClick: PropTypes.func,
  /** Reference with helper functions to control the search from outside */
  reference: PropTypes.any,
  /** props directly passed to item renderer */
  rendererProps: PropTypes.object
}
