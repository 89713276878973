/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, FormControl } from "@mui/material";
import "./profile.css";
import { styled } from "@mui/material/styles";
import { ApplicationCardSkeleton } from "@components/Skeleton/CommonSkeletons";

import MyApplicationCard from "./myApplicationCard";
import {
  SearchAssignedApplications,
  GetUserApplications,
  GetUserEntitelments,
  GetUserSupportContact,
  updateAvailableApps,
} from "@actions/roleActions";
import { LoadingData } from "@actions/commonAction";
import { Inlay } from "@alice/component_library";
import NoRolesPage from "@components/noRoles";
import NoResults from "@components/Noresults";
import { SearchBar, useUser } from "@alice/component_library";
import ApplicationDetailsOpen from "./applicationDetails";
import { useWidth } from "@utils/hooks";

const MainContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  color: "#000 !important",
  "& .paperClass": {
    paddingLeft: "10px !important",
    fontSize: "18px !important",
    fontWeight: "bolder",
    lineHeight: "42px",
  },
  "& .paperClassText": {
    fontSize: "18px !important",
    fontWeight: 400,
    lineHeight: "42px",
  },
  "& .boxShadow": {
    boxShadow: "none",
  },

  "& .search": {
    marginTop: "26px",
  },
  "& .tabsRoot": { borderBottom: "1px solid #E8E8E8" },

  "& .tabItem": {
    "&.Mui-selected .MuiTab-wrapper": {
      color: "#333333",
      "&.Mui-selected .MuiTab-wrapper": {
        color: "#333333",
      },
      "& .MuiTab-wrapper": {
        opacity: "1",
        color: "#767676",
        fontFamily: "MB Corpo S Text",
        fontSize: "16px",
        fontWeight: "700",
        fontStyle: "normal",
        letterSpacing: "0.4px",
        textAlign: "center",
        lineHeight: "24px",
        textTransform: "capitalize",
      },
      minWidth: "unset",
    },
  },
}));

function MyApplications(props) {
  const { t } = useTranslation();
  const widthPanel = useWidth();
  const { roles, user } = props;
  const [search, setSearch] = React.useState("");
  // const [selectedTab, setSelectedTab] = React.useState(0);
  const [isLoading, setisLoad] = React.useState(false);

  const [searchFlag, setSearchFlag] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const [numCols, setNumCols] = useState(1);
  const [activeCard, setActiveCard] = useState(null);

  const [tiles, setTiles] = useState(null);
  const [selRoleData, setSelRoleData] = useState(null);
  const [contactData, setContactData] = useState({});
  const [target, setTarget] = useState(null);
  const { user: userdata } = useUser();

  let loginData = userdata;
  let logginUser = userdata?.id;

  if (user) {
    loginData = user;
    logginUser = user.id;
  }
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const userApplications = useSelector((state) => state.role.userApplications);

  const userSupportContact = useSelector(
    (state) => state.role.userSupportContact
  );

  const [data, setData] = React.useState(userApplications);

  const fetchAllDetails = async () => {
    await dispatch(GetUserApplications(loginData.id));
    await setData(userApplications);
    setisLoad(false);
  };

  const onCloseInlay = async () => {
    let applData = userApplications;

    for (var i = 0; i < applData.length; i++) {
      applData[i].isOpen = false;
    }

    await setData(applData);

    await setOpen(false);
    await setActiveCard(null);
    await setSelRoleData(null);
    await setActiveCardIndex(null);
    await setTarget(null);
  };

  useEffect(() => {
    switch (widthPanel) {
      case "sm":
        setNumCols(2);
        break;
      case "md":
        setNumCols(3);
        break;
      case "lg":
        setNumCols(3);
        break;
      case "xl":
        setNumCols(3);
        break;
      default: {
        setNumCols(1);
      }
    }
  }, [widthPanel]);

  useEffect(() => {
    (async () => {
      const loginId =
        loginData && Object.keys(loginData).length !== 0
          ? loginData.id
          : localStorage.getItem("user");
      await setisLoad(true);
      if (loginId !== null) dispatch(GetUserSupportContact(loginId));
    })();
  }, []);

  useEffect(() => {
    const addInIndex =
      activeCard !== null
        ? activeCard + (numCols - (activeCard % numCols))
        : null;

    let result =
      userApplications &&
      userApplications.map((appl, i) => {
        return (
          <Grid
            key={i}
            item
            xs={12}
            sm={6}
            md={4}
            xl={4}
            sx={{ display: "grid" }}
          >
            <MyApplicationCard
              logginUser={logginUser}
              data={appl}
              key={i}
              index={i}
              onClick={DetailsOpen}
              className="noClick"
            />
          </Grid>
        );
      });

    if (addInIndex) {
      result.splice(
        addInIndex,
        0,

        <Grid key="inlay" item xs={12}>
          <Inlay
            anchorElement={anchorEl}
            open={Boolean(anchorEl)}
            // onClose={() => onCloseInlay(selRoleData && selRoleData.id)}
          >
            <ApplicationDetailsOpen
              width={window.screen.width}
              isClose={true}
              roleData={[]}
              setDetailsChange={onCloseInlay}
              applId={selRoleData && selRoleData.id}
              applications={selRoleData}
              supportContact={contactData}
            />
          </Inlay>
        </Grid>
      );
    }

    setTiles(result);
  }, [
    data,
    roles,
    activeCard,
    numCols,
    open,
    selRoleData,
    target,
    widthPanel,
    setSelRoleData,
    userApplications,
    setContactData,
    contactData,
  ]);

  useEffect(() => {
    fetchAllDetails();
  }, []);

  const updateLocalData = async () => {
    await dispatch(LoadingData(true));
    const test = userApplications;

    setData(test);
    await dispatch(LoadingData(false));
  };

  useEffect(() => {
    (async () => {
      setData(props.userApplications);
    })();
  }, [userApplications]);

  const searchResults = async (search) => {
    setSearch(search);
    setSearchFlag(true);
    await setisLoad(true);
    await dispatch(SearchAssignedApplications(loginData.id, search));
    await onCloseInlay();
    await updateLocalData();
    await setisLoad(false);
  };

  const DetailsOpen = async (index, elem, event, elemRef) => {
    let applData = userApplications;
    for (var i = 0; i < applData.length; i++) {
      if (applData[i].id === elem.id) {
        if (applData[i].isOpen === true && activeCardIndex !== null) {
          applData[i].isOpen = false;
          setOpen(false);
          setActiveCard(null);
          setSelRoleData(null);
          setTarget(null);
          setActiveCardIndex(null);
          setAnchorEl(event.currentTarget);
        } else {
          applData[i].isOpen = !userApplications[i].isOpen;
          setOpen(true);
          setActiveCard(index);
          setSelRoleData(applData[i]);
          setTarget(elemRef.current);
          setActiveCardIndex(index);
          setAnchorEl(elemRef.current);
        }
      } else {
        applData[i].isOpen = false;
      }
    }

    await setData(applData);
    await dispatch(LoadingData(true));

    let contactSupportData =
      userSupportContact &&
      userSupportContact.applicationSupportContacts &&
      userSupportContact.applicationSupportContacts.filter(
        (x) => x.applicationId === elem.id
      );
    setContactData(contactSupportData);

    const loginId =
      loginData && Object.keys(loginData).length !== 0
        ? loginData.id
        : localStorage.getItem("user");

    await dispatch(updateAvailableApps(applData));
    await dispatch(GetUserEntitelments(loginId, elem.id));
    await dispatch(LoadingData(false));

    setContactData(contactSupportData);
  };

  return (
    <MainContainer>
      <Grid container style={{ marginBottom: "26px !important" }}>
        <Grid container className="request-responsive">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "26px" }}
            className="Search_bar"
          >
            <FormControl className="width-100p" variant="filled">
              <SearchBar
                label={
                  window.screen.width >= 768
                    ? t("application-search")
                    : t("search_in_table")
                }
                id="standard-searchfield-app"
                onSearch={(e) => searchResults(e)}
                onClear={() => searchResults("")}
              />
            </FormControl>
          </Grid>

          {isLoading ? (
            <ApplicationCardSkeleton />
          ) : (
            <Grid container style={{ margin: "40px 0" }}>
              {userApplications && userApplications.length > 0 ? (
                <Grid container spacing={1} className="subcontainer">
                  {tiles}
                </Grid>
              ) : userApplications.length === 0 ? (
                search.length <= 0 ? (
                  <NoRolesPage appl={true} />
                ) : (
                  searchFlag === true && (
                    <React.Fragment>
                      <NoResults keysearch={search} searchFlag={searchFlag} />
                    </React.Fragment>
                  )
                )
              ) : null}
            </Grid>
          )}
        </Grid>
      </Grid>
    </MainContainer>
  );
}
export default MyApplications;
