import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

import { TextField } from "@alice/component_library";
import "@src/App.css";

const RootContainer = styled("div")({
  flexGrow: 1,
  "& .MuiInput-underline:below": {
    borderBottom: "2px solid red !important",
  },
});

const SubContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginLeft: "20px",
    paddingRight: "20px",
  },
}));

const SubTextField = styled(Grid)(({ theme }) => ({
  width: "100%",
  "& > div": {
    width: "100%",
  },
  "& .multilineColor": {
    fontSize: "16px",
    color: "#0078D6 !important",
  },
  "& .multilineColornormal": {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  "& .info": {
    display: "flex",
    justifyContent: "space-between",
  },
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
    marginRight: "20px",
  },
}));

function Costs(props) {
  const { t } = useTranslation();

  const [comment, setComment] = useState("");
  const REASON_CHARACTER_LIMIT = 400;

  return (
    <RootContainer>
      <SubContainer
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="mb-16"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="add_info Reason_info"
        >
          {t("role_add_info_reason")}
        </Grid>
        <SubTextField item className="mrm-1">
          <TextField
            label={t("enter-app-reason")}
            maxLength={REASON_CHARACTER_LIMIT}
            error={comment.length === Number(REASON_CHARACTER_LIMIT)}
            id={
              comment.length === Number(REASON_CHARACTER_LIMIT)
                ? "filled-multiline-static"
                : "standard-error"
            }
            rows={4}
            multiline
            variant="filled"
            className="mt-20 mb-16 text-area font-10 costs"
            inputProps={{
              maxLength: REASON_CHARACTER_LIMIT,
              className:
                comment.length === Number(REASON_CHARACTER_LIMIT)
                  ? "multilineColor"
                  : "multilineColornormal",
            }}
            onChange={(e) => {
              const trimmedValue =
                e.target.value.trim().length > 0
                  ? e.target.value
                  : e.target.value.trim();
              setComment(trimmedValue);
              props.handleCostField(e);
            }}
            onBlur={props.storeCostValue}
            value={props.costsField}
            disabled={props.disabledFlag}
            fullWidth={true}
            sx={{ width: "100%" }}
          />
          <div className="info">
            <span>{t("min-20-chars-requeired-info")}</span>
            <span>
              {comment.trim().length} / {REASON_CHARACTER_LIMIT}
            </span>
          </div>
        </SubTextField>
      </SubContainer>
    </RootContainer>
  );
}

export default Costs;
