import { useCallback, useContext, useEffect, useState } from 'react'
import { Entitlement } from '../../constants'
import { UserProviderContext } from './UserProvider'

export const usePermission = ({ overwriteIfSharedServiceAdmin = false } = {}) => {
  const {
    isLoadingEntitlements,
    isLoadingAdminRoles,
    entitlements,
    mutateEntitlements,
    adminRoles,
    mutateAdminRoles
  } = useContext(UserProviderContext)

  const [ isAdmin, setIsAdmin ] = useState(false)

  useEffect(() => setIsAdmin(!!adminRoles?.size), [ adminRoles ])

  const hasPermission = useCallback((entitlement = null) => {
    if (!entitlement) return true

    return (overwriteIfSharedServiceAdmin && entitlements?.has(Entitlement.SHARED_SERVICE_ADMIN))
      || entitlements?.has(entitlement)
  }, [ entitlements, overwriteIfSharedServiceAdmin ])

  const hasOneOfPermission = useCallback((entitlements = []) => entitlements.some((entitlement) => hasPermission(entitlement)), [ hasPermission ])

  return {
    isLoadingPermissions: isLoadingEntitlements || isLoadingAdminRoles,
    isAdmin,
    hasPermission,
    hasOneOfPermission,
    permissions: entitlements,
    setPermissions: mutateEntitlements,
    entitlements, /* Legacy impl. remove when permissions are 100% entitlements */
    setEntitlements: mutateEntitlements,
    adminRoles, /* Legacy impl. remove when permissions are 100% entitlements */
    setAdminRoles: mutateAdminRoles
  }
}
