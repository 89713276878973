import React from "react";

import { CircularLoader } from "../utils/loader";

const Loader = (props) => {
  const minHeight = props.minHeight || "240px";
  return (
    <div
      style={{
        width: "100%",
        minHeight,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <CircularLoader {...props} />
    </div>
  );
};

Loader.propTypes = {};

export default Loader;
