import { useState } from "react";
import { SearchBar } from "@alice/component_library";
import { AccessReviewSearchContainer } from "../../styledComponents";

const MainPageHeader = ({ translate, search, onChange, onSearch, onClear }) => {
  const [searchValue, setSearchValue] = useState(search);

  const onChangeValue = (e) => {
    const searchTerm = e.target.value;
    setSearchValue(searchTerm);
  };

  return (
    <AccessReviewSearchContainer>
      <SearchBar
        ref={{
          current: "[Circular]",
        }}
        fullWidth
        label={translate("review-search-label")}
        onChange={onChangeValue}
        onClear={onClear}
        onSearch={onChange}
        showIcons
        size="normal"
        theme="dark"
        value={searchValue}
      />
    </AccessReviewSearchContainer>
  );
};

export default MainPageHeader;
