/**
 * userId: returns a string with upper case chars
 * toIntlNumber: returns a localized number as string
 */
export const format = {
  userId: (value) => `${value}`.toUpperCase(),
  toIntlNumber: (value) => new Intl.NumberFormat(window?.localStorage?.i18nextLng || 'en').format(value)
}

export const formatNumber = ({
  num,
  lang = window?.localStorage?.i18nextLng || 'en-GB',
  isShort,
  isCurrency
}) => new Intl.NumberFormat(lang, {
  notation: isShort ? 'compact' : undefined,
  style: isCurrency ? 'currency' : undefined,
  currency: 'EUR',
  maximumFractionDigits: 0
}).format(num)

export const formatToLocalDate = (date) => new Intl.DateTimeFormat(localStorage?.getItem('i18nextLng') || 'en-US').format(date)

export const camelCaseToWords = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1')

  return result.charAt(0).toUpperCase() + result.slice(1)
}

export class Formatter {
  static keepAlphanumeric(str) {
    return str.replace(/[^a-zA-Z0-9]/g, '')
  }

  static keepAlphanumericWithSpace(str) {
    return str.replace(/[^a-zA-Z0-9\s]/g, '')
  }

  static keepAlphanumericUnderscoreAndDash(str) {
    return str.replace(/[^a-zA-Z0-9\-_]/g, '')
  }
}
