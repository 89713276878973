import { Day, useDayPicker } from 'react-day-picker'

// Styles
import styles from './Row.module.scss'

export const Row = ({ dates, displayMonth }) => {
  const { classNames } = useDayPicker()

  return (
    <tr className={`${classNames.row} ${styles.row}`}>
      {dates.map((date, id) => (
        <td
          className={`${classNames.cell} ${styles.rowCell}`}
          key={id}
          role="presentation"
        >
          <Day date={date} displayMonth={displayMonth} />
        </td>
      ))}
    </tr>
  )
}
