import { useTranslation } from 'react-i18next'
import { Divider, Stack } from '@mui/material'
import { DescriptionOutlined, OpenInNew } from '@mui/icons-material'
import { getClientLink } from '../../../util'
import { UserGroupAvatarInfo } from '../UserAvatar'
import { DetailsList } from '../../card/genericCard'
import { Link } from '../../link'
import { UserGroupList } from '../../userElements/UserGroupCard/components/UserGroupList' // do not shorten

export const UserGroupContext = ({
  userGroupData,
  isLoading
}) => {
  const { t } = useTranslation('componentLibrary')

  const description = [
    {
      icon: <DescriptionOutlined color="inherit" fontSize="inherit" />,
      text: userGroupData?.description ? userGroupData?.description : t('userGroupCard.descriptionPlaceholder'),
      label: t('userGroupCard.descriptionLabel')
    }
  ]

  return (
    <Stack gap={2}>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <UserGroupAvatarInfo
          name={userGroupData.name}
          variant="grid"
          size={'l'}
          isLoading={isLoading}
        />

        <Link
          isUsingRouterIfPossible={false}
          isBlank
          href={`${getClientLink('admin')}/user-groups/${userGroupData.uuid}`}
        >
          <OpenInNew sx={{ fontSize: '18px' }} />
        </Link>
      </Stack>

      <Divider orientation="horizontal" flexItem />

      <DetailsList
        data={description}
        variant="grid"
        size="m"
        isLoading={isLoading}
        isLabelShown
        sx={{ overflow: 'hidden' }}
      />

      <Divider orientation="horizontal" flexItem />

      <UserGroupList
        groupOwners={userGroupData?.owners}
        groupUsers={userGroupData?.users}
        isLoading={isLoading}
        isStacked
        maxAvatars={12}
      />
    </Stack>
  )
}
