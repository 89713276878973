import React, { useState, useRef, useEffect } from "react";
import { Grid, TextField, Typography, FormLabel } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import { styled } from "@mui/system";
import { debounce } from "lodash";

// const StyledTextField = styled(TextField)({
//   '& .MuiInputBase-root': {
//     backgroundColor: 'rgba(0, 0, 0, 0.04)', // Background color of the text field
//     borderRadius: '4px', // Border radius to match the filled text box style
//   },
//   '& .MuiFilledInput-underline:before': {
//     borderBottom: 'none', // Remove underline
//   },
// });

const StyledLabel = styled(FormLabel)({
  position: "absolute",
  transformOrigin: "left top",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "calc(133% - 24px)",
  letterSpacing: "0px",
  lineHeight: "1.4375em",
  fontWeight: "400",
  fontSize: "13px",
  padding: "0px",
  display: "block",
  top: "20px",
  left: "13px",
});

const MainContainer = styled(Grid)(({ monthMargin, isDisabled }) => ({
  marginBottom: "30px",
  "& .MuiFormHelperText-root": {
    position: "absolute",
    width: "240px",
    top: "58px",
    marginLeft: "0px",
  },

  "& .month-container": {
    width: "31px",
    "& .MuiFormControl-root": {
      width: "30px",
    },
    "& .MuiInputBase-root": {
      paddingRight: "0",
      width: "31px",
    },
    "& [data-field='month']": {
      borderTopRightRadius: "0px",
      borderTopLeftRadius: "0px",
      "&:hover": {
        backgroundColor: isDisabled
          ? "rgba(0, 0, 0, 0.12)"
          : "rgba(0, 0, 0, 0.06)",
      },
      "& .MuiInputBase-input": {
        width: "30px",
        paddingLeft: monthMargin,
        "&::placeholder": {
          fontSize: "13px",
        },
      },
      "&:after": {
        width: "75% !important",
      },
    },
  },

  "& .day-continer": {
    width: "38px",
    "& .MuiFormControl-root": {
      width: "46px",
    },
    "& .MuiInputBase-root": {
      padding: "0px",
      width: "38px",
    },
    "& [data-field='day']": {
      borderTopRightRadius: "0px",
      "&:hover": {
        backgroundColor: isDisabled
          ? "rgba(0, 0, 0, 0.12)"
          : "rgba(0, 0, 0, 0.06)",
      },
      "& .MuiInputBase-input": {
        width: "22px",
        "&::placeholder": {
          fontSize: "13px",
        },
      },
      "&:after": {
        width: "68% !important",
        left: "5px",
      },
    },
  },

  "& .year-container": {
    "& [data-field='year']": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      "&:hover": {
        backgroundColor: isDisabled
          ? "rgba(0, 0, 0, 0.12)"
          : "rgba(0, 0, 0, 0.06)",
      },
      "& .MuiInputBase-input": {
        width: "40px",
        paddingLeft: "3px",
        "&::placeholder": {
          fontSize: "13px",
        },
      },
      "&:after": {
        width: "80% !important",
      },
    },
  },

  "& .icon-container": {
    width: "150px",
    pointerEvents: "none",
    "& .MuiInputBase-root": {
      pointerEvents: "none",
      borderTopLeftRadius: "0px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
      },
    },
    "& .MuiSvgIcon-root": {
      pointerEvents: isDisabled ? "none" : "all",
    },
  },
}));

const isValidDateString = (dateString) => {
  // Regular expression for date format (YYYY-MM-DD)
  var datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

  // Check if the dateString matches the pattern
  return datePattern.test(dateString);
};

const formatDate = (dateString) => {
  if (typeof dateString === "undefined") {
    return null;
  } else if (dateString instanceof Date) {
    return dateString; // No need to format if it's already a Date object
  }

  if (!isValidDateString(dateString)) {
    return null;
  }
  // Check if the date string is in the format dd/mm/yyyy
  const isDMYFormat = dateString.match(/^\d{2}\/\d{2}\/\d{4}$/);

  if (isDMYFormat) {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  } else {
    return new Date(dateString);
  }
};

const CustomTextFieldGroup = ({
  isDisabled,
  label,
  handleCalenderClick,
  dateString,
  onUpdate,
  error,
  errorMessage,
  isInvalid,
}) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const yearRef = useRef(null);

  useEffect(() => {
    if (!error) {
      // Formatting the date string
      const formattedDate = formatDate(dateString);
      if (!formattedDate) {
        return;
      }

      // Extracting day, month, and year
      const formattedDay = ("0" + formattedDate.getDate()).slice(-2);
      const formattedMonth = ("0" + (formattedDate.getMonth() + 1)).slice(-2);
      const formattedYear = formattedDate.getFullYear();
      // Setting the extracted values to state
      setDay(formattedDay);
      setMonth(formattedMonth);
      setYear(formattedYear);
    }
  }, [dateString, isInvalid]);

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      onUpdate(date);
    }, 500);

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [day, month, year]);

  const handleKeyDown = (event) => {
    const targetClass = event.target.offsetParent.getAttribute("data-field");
    const valueLength = event.target.value.length;
    const cursorPosition = event.target.selectionStart;
    const inputSelectionStart = event.target.selectionEnd;

    switch (event.key) {
      case "ArrowLeft":
        if (cursorPosition === 0) {
          switch (targetClass) {
            case "month":
              if (valueLength === 1) {
                setMonth("0" + month);
              }
              dayRef.current.focus();
              break;
            case "year":
              monthRef.current.focus();

              break;
            default:
              break;
          }
        }
        break;
      case "ArrowRight":
        if (cursorPosition === valueLength) {
          switch (targetClass) {
            case "day":
              if (valueLength === 1) {
                setDay("0" + day);
              }
              monthRef.current.focus();
              break;
            case "month":
              if (valueLength === 1) {
                setMonth("0" + month);
              }
              yearRef.current.focus();
              break;
            default:
              break;
          }
        }
        break;
      case "Backspace":
        if (cursorPosition === 0) {
          switch (targetClass) {
            case "month":
              if (cursorPosition === inputSelectionStart) {
                dayRef.current.focus();
              }
              break;
            case "year":
              if (cursorPosition === inputSelectionStart) {
                monthRef.current.focus();
                setTimeout(() => {
                  monthRef.current.setSelectionRange(valueLength, valueLength);
                }, 0);
              }
              break;
            default:
              break;
          }
        }
        break;
      default:
        break;
    }
  };

  const handleDayFocus = () => {
    // monthRef.current.focus();
    // yearRef.current.focus()
  };

  const handleMonthFocus = () => {
    // dayRef.current.focus();
    // yearRef.current.focus()
  };

  const handleYearFocus = () => {
    // monthRef.current.focus();
    // dayRef.current.focus();
  };

  const handleInput = (event, maxLength) => {
    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;

    // Get the value before and after the cursor position
    const valueBeforeCursor = inputValue.substring(0, selectionStart);
    const valueAfterCursor = inputValue.substring(selectionStart);

    // Check if the new character is a number
    const newChar = valueAfterCursor[0];
    if (!isNaN(parseInt(newChar))) {
      // Replace the existing value at the cursor position
      const newValue =
        valueBeforeCursor + newChar + valueAfterCursor.substring(1);
      return newValue.replace(/\D/g, "").substring(0, maxLength);
    } else {
      return inputValue;
    }
  };

  const handleDayChange = (event) => {
    const cursorPosition = event.target.selectionStart;
    let inputValue = event.target.value;
    let cusrsorPositionUpdate = cursorPosition;

    if (inputValue.length === 3 && cursorPosition == 1) {
      cusrsorPositionUpdate = 1;
    } else if (inputValue.length === 3 && cursorPosition == 3) {
      cusrsorPositionUpdate = 2;
    }

    // Remove non-numeric characters
    inputValue = inputValue.replace(/\D/g, "");

    // Limit day to 1 or 2 digits and maximum of 31
    inputValue = inputValue.slice(0, 2);
    const parsedDay = parseInt(inputValue, 10);
    if (parsedDay > 31) inputValue = "31";

    setDay(inputValue);

    setTimeout(() => {
      dayRef.current.setSelectionRange(
        cusrsorPositionUpdate,
        cusrsorPositionUpdate
      );
    }, 0);

    // Automatically move focus to month input field after completing day
    if (inputValue.length === 2 && cusrsorPositionUpdate >= 2) {
      monthRef.current.focus();
      setTimeout(() => {
        monthRef.current.setSelectionRange(0, 0);
      }, 0);
    }
  };

  const handleMonthChange = (event) => {
    let inputValue = event.target.value;
    const cursorPosition = event.target.selectionStart;
    let cusrsorPositionUpdate = cursorPosition;

    if (inputValue.length === 3 && cursorPosition == 1) {
      cusrsorPositionUpdate = 1;
    } else if (inputValue.length === 3 && cursorPosition == 3) {
      cusrsorPositionUpdate = 2;
    }

    // Remove non-numeric characters
    inputValue = inputValue.replace(/\D/g, "");

    // Limit month to 1 or 2 digits and maximum of 12
    inputValue = inputValue.slice(0, 2);
    const parsedMonth = parseInt(inputValue, 10);
    if (parsedMonth > 12) inputValue = "12";

    setMonth(inputValue);

    setTimeout(() => {
      monthRef.current.setSelectionRange(
        cusrsorPositionUpdate,
        cusrsorPositionUpdate
      );
    }, 0);

    // Automatically move focus to year input field after completing month
    if (inputValue.length === 2 && cusrsorPositionUpdate >= 2) {
      yearRef.current.focus();
      setTimeout(() => {
        yearRef.current.setSelectionRange(0, 0);
      }, 0);
    }

    if (day !== "") {
      const parsedDay = parseInt(day, 10);
      const lastDayOfMonth = new Date(year, parsedMonth, 0).getDate();
      if (parsedDay > lastDayOfMonth) setDay(String(lastDayOfMonth));
    }

    // If month input is cleared, move focus back to day input
    if (!inputValue) {
      dayRef.current.focus();
    }
  };

  const handleYearChange = (event) => {
    let inputValue = event.target.value;
    const cursorPosition = event.target.selectionStart;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/\D/g, "");

    // Limit year to 4 digits
    inputValue = inputValue.slice(0, 4);

    const parsedYear = parseInt(inputValue, 10);

    // Check if the year is a leap year
    const isLeapYear =
      (parsedYear % 4 === 0 && parsedYear % 100 !== 0) ||
      parsedYear % 400 === 0;

    setYear(inputValue);

    setTimeout(() => {
      yearRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);

    // Automatically move focus to month input field after completing year
    if (!inputValue) {
      monthRef.current.focus();
    }
    // Adjust day value if it exceeds the maximum days in the entered month
    if (month !== "" && day !== "") {
      const parsedDay = parseInt(day, 10);
      const parsedMonth = parseInt(month, 10);
      const lastDayOfMonth = new Date(parsedYear, parsedMonth, 0).getDate();

      // Adjust maximum days in February for leap years
      if (parsedMonth === 2 && isLeapYear) {
        if (parsedDay > 29) setDay("29");
      } else {
        if (parsedDay > lastDayOfMonth) setDay(String(lastDayOfMonth));
      }
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <MainContainer
        item
        xs={12}
        monthMargin={month.length ? "4px" : "2px"}
        isDisabled={isDisabled}
      >
        <StyledLabel color="info">{label}</StyledLabel>
        <Grid container alignItems="center">
          <div className="textbox-container day-continer">
            <TextField
              className="day"
              helperText={
                <>
                  <p style={{ margin: 0 }}>DD/MM/YYYY</p>
                  {errorMessage.length ? errorMessage : ""}
                </>
              }
              error={error}
              variant="filled"
              value={day}
              onChange={handleDayChange}
              inputRef={dayRef}
              disabled={isDisabled}
              placeholder="DD"
              onFocus={handleDayFocus}
              onKeyDown={handleKeyDown}
              InputProps={{
                maxLength: 2,
                endAdornment: (
                  <span
                    style={{
                      position: "absolute",
                      right: "-1px",
                      top: "25px",
                    }}
                  >
                    /
                  </span>
                ),
                "data-field": "day",
              }}
            />
          </div>
          <div className="textbox-container month-container">
            <TextField
              error={error}
              variant="filled"
              value={month}
              onChange={handleMonthChange}
              inputRef={monthRef}
              onFocus={handleMonthFocus}
              onKeyDown={handleKeyDown}
              disabled={isDisabled}
              placeholder="MM"
              InputProps={{
                maxLength: 2,
                endAdornment: (
                  <span
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "25px",
                    }}
                  >
                    /
                  </span>
                ),
                "data-field": "month",
              }}
            />
          </div>
          <div className="textbox-container year-container">
            <TextField
              error={error}
              variant="filled"
              value={year}
              onChange={handleYearChange}
              inputRef={yearRef}
              onFocus={handleYearFocus}
              onKeyDown={handleKeyDown}
              disabled={isDisabled}
              placeholder="YYYY"
              InputProps={{
                maxLength: 4,
                "data-field": "year",
              }}
            />
          </div>
          <div className="icon-container">
            <TextField
              error={error}
              variant="filled"
              disabled={isDisabled}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <TodayIcon
                    onClick={() => handleCalenderClick()}
                    style={{ cursor: "pointer" }}
                  />
                ),
              }}
            />
          </div>
        </Grid>
      </MainContainer>
    </Grid>
  );
};

export default CustomTextFieldGroup;
