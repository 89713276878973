import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { IconButton as MuiIconButton } from '@mui/material'
import { mergeSxProps } from '../../../util'

/**
 * A simple icon button.<br>
 * For more documentation see: https://mui.com/material-ui/api/icon-button/
 */
export const IconButton = forwardRef((
  {
    color = 'primary',
    hasSmallClickArea = false,
    children,
    sx = {} || [],
    ...otherProps
  },
  ref
) => (
  <MuiIconButton
    data-testid="IconButton"
    ref={ref}
    color={color}
    sx={mergeSxProps([ hasSmallClickArea && { padding: 0.5 } ], sx)}
    {...otherProps}
  >
    {children}
  </MuiIconButton>
))

IconButton.propTypes = {
  /** The icon to display */
  children: PropTypes.node.isRequired,
  /** Event on click */
  onClick: PropTypes.func,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
