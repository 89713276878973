export const EntitlementsAlice = {
  rels: {
    totalCount: 9,
    offset: 0,
    limit: 500
  },
  entitlements: [
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: '6c510e65-b89e-4e91-a210-6ca52506c79c',
      entitlementId: 'ADMIN_ROLE_ASSIGNMENT',
      type: 'ENTITLEMENT',
      description: 'Assignment of high-privileged or "non-public" Admin Roles to users which are not migrated to Alice business roles',
      informationOwners: [
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michail',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'BELENKM',
          mailAddress: 'michail.belenki@mercedes-benz.com',
          surname: 'Belenki',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michail Belenki',
          isActive: true,
          plant1: '100',
          plant2: '096'
        },
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michael',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'KOKOSCM',
          mailAddress: 'michael.kokoschka@mercedes-benz.com',
          surname: 'Kokoschka',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michael Kokoschka',
          isActive: true,
          plant1: '100',
          plant2: '096'
        }
      ],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: 'b281c570-b23a-4c05-8d51-62a29bdf8889',
      entitlementId: 'AUDIT_LOG',
      type: 'ENTITLEMENT',
      description: 'Read the Audit Log.',
      informationOwners: [
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michail',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'BELENKM',
          mailAddress: 'michail.belenki@mercedes-benz.com',
          surname: 'Belenki',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michail Belenki',
          isActive: true,
          plant1: '100',
          plant2: '096'
        },
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michael',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'KOKOSCM',
          mailAddress: 'michael.kokoschka@mercedes-benz.com',
          surname: 'Kokoschka',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michael Kokoschka',
          isActive: true,
          plant1: '100',
          plant2: '096'
        }
      ],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: '401c8250-833f-4008-b1c3-86486e63e245',
      entitlementId: 'BILLING',
      type: 'ENTITLEMENT',
      description: 'Read billing data',
      informationOwners: [
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michail',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'BELENKM',
          mailAddress: 'michail.belenki@mercedes-benz.com',
          surname: 'Belenki',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michail Belenki',
          isActive: true,
          plant1: '100',
          plant2: '096'
        },
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michael',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'KOKOSCM',
          mailAddress: 'michael.kokoschka@mercedes-benz.com',
          surname: 'Kokoschka',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michael Kokoschka',
          isActive: true,
          plant1: '100',
          plant2: '096'
        }
      ],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: 'd5978997-57f8-404a-a440-cbf13c690e80',
      entitlementId: 'CONNECTOR_CONFIGURATION',
      type: 'ENTITLEMENT',
      description: 'Collection of configurative tasks for Alice Connectors and Connector Framework.',
      informationOwners: [
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michail',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'BELENKM',
          mailAddress: 'michail.belenki@mercedes-benz.com',
          surname: 'Belenki',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michail Belenki',
          isActive: true,
          plant1: '100',
          plant2: '096'
        },
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michael',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'KOKOSCM',
          mailAddress: 'michael.kokoschka@mercedes-benz.com',
          surname: 'Kokoschka',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michael Kokoschka',
          isActive: true,
          plant1: '100',
          plant2: '096'
        }
      ],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: 'b4456920-92ee-4e1c-a53a-5011c28b7b6b',
      entitlementId: 'CORE_CONFIGURATION',
      type: 'ENTITLEMENT',
      description: 'Collection of configurative tasks for Alice Core.',
      informationOwners: [
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michail',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'BELENKM',
          mailAddress: 'michail.belenki@mercedes-benz.com',
          surname: 'Belenki',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michail Belenki',
          isActive: true,
          plant1: '100',
          plant2: '096'
        },
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michael',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'KOKOSCM',
          mailAddress: 'michael.kokoschka@mercedes-benz.com',
          surname: 'Kokoschka',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michael Kokoschka',
          isActive: true,
          plant1: '100',
          plant2: '096'
        }
      ],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: '4b7a4b67-22c4-4d40-97b2-77bbf72ec013',
      entitlementId: 'FUNCTIONAL_ADMINISTRATION',
      type: 'ENTITLEMENT',
      description: 'More or less everything possible in Access, Identity & Admin UI.',
      informationOwners: [
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michail',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'BELENKM',
          mailAddress: 'michail.belenki@mercedes-benz.com',
          surname: 'Belenki',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michail Belenki',
          isActive: true,
          plant1: '100',
          plant2: '096'
        },
        {
          groupType: '0',
          departmentNumber: 'ITD/SI',
          givenname: 'Michael',
          homeOrgId: 'E-0400',
          homeOrgName: 'Mercedes-Benz Group AG',
          id: 'KOKOSCM',
          mailAddress: 'michael.kokoschka@mercedes-benz.com',
          surname: 'Kokoschka',
          communities: [
            'DAIMLER_EMPLOYEE',
            'DAIMLER_INTERNAL'
          ],
          completeName: 'Michael Kokoschka',
          isActive: true,
          plant1: '100',
          plant2: '096'
        }
      ],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: '663414ec-41dd-4461-a274-05dd2dc78cd0',
      entitlementId: 'ReadOnlyAccessClientConfiguration',
      type: 'ENTITLEMENT',
      description: 'Read Only Privileges for the Alice Access API Configurations.',
      informationOwners: [],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: 'c1700a15-4507-4e47-8e38-068103294aad',
      entitlementId: 'ReadWriteAccessClientConfiguration',
      type: 'ENTITLEMENT',
      description: 'Read & Write Privileges for the Alice Access API Configurations.',
      informationOwners: [],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    },
    {
      applicationId: 'ALICE',
      applicationName: 'Alice',
      uuid: '49fb9a34-737a-4f88-b729-66720a40945b',
      entitlementId: 'Start_Authorization',
      type: 'AUTHORIZATION',
      description: 'Start Authorization',
      informationOwners: [],
      dataClassification: 'CONFIDENTIAL',
      dataClassificationInherited: true,
      connectivity: false
    }
  ]
}
