import { controlCenterActionTypes } from "@constants/actionTypes";

const initialState = {
  hasCCAccess: false,
  users: { isLoaded: false, data: [] },
};
const ControlCenterReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case controlCenterActionTypes.HAS_CC_ACCESS:
      return (state = {
        ...state,
        hasCCAccess: data,
      });
    case controlCenterActionTypes.MY_USER_DETAILS:
      return (state = {
        ...state,
        users: data,
      });

    default:
      return state;
  }
};
export default ControlCenterReducer;
