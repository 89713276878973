import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFetchRequest } from "@src/actions";
import MuiDataGrid from "@components/DataGrid";
import { getTaskstructInfo } from "@src/pages/util";
// import { tableStyle } from "./style";
import SkeletonOverlay from "@components/Skeleton/SkeletonOverlay";
import TaskPageSkeleton from "@components/Skeleton/TaskPageSkeleton";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RequestDetails from "../Details";
import column from "./columns";
import { NoDataFound } from "../../styledComponents";

const columns = (userId, t, filter, optionType) => [
  {
    field: "id",
    headerName: "",
    sortable: false,
    width: 148,
    maxWidth: 148,
    renderCell: ({ row }) => {
      const { requestIdColumn } = column(row, userId, t, filter, optionType);
      return requestIdColumn;
    },
  },
  {
    field: "requester",
    headerName: "",
    sortable: false,
    flex: 0.2,
    renderCell: ({ row }) => {
      const { requesterColumn } = column(row, userId, t, filter, optionType);
      return requesterColumn;
    },
  },
  {
    field: "request",
    headerName: "",
    sortable: false,
    flex: 0.3,
    renderCell: ({ row }) => {
      const { requestColumn } = column(row, userId, t, filter, optionType);
      return requestColumn;
    },
  },
  {
    field: "requested",
    headerName: "",
    sortable: false,
    flex: 0.3,
    renderCell: ({ row }) => {
      const { requestedColumn } = column(row, userId, t, filter, optionType);
      return requestedColumn;
    },
  },
  {
    field: "creationDate",
    headerName: "",
    flex: 0.3,
    sortable: false,
    renderCell: ({ row }) => {
      const { creationDateColumn } = column(row, userId, t, filter, optionType);
      return creationDateColumn;
    },
  },
  {
    field: "status",
    headerName: "",
    width: 100,
    maxWidth: 100,
    sortable: false,
    renderCell: ({ row }) => {
      const { statusColumn } = column(row, userId, t, filter, optionType);
      return statusColumn;
    },
  },
];

const detailColumns = (userId, t, filter, optionType) => [
  {
    field: "id",
    headerName: "",
    sortable: false,
    flex: 0.3,
    renderCell: ({ row }) => {
      const { requestIdColumn } = column(row, userId, t, filter, optionType);
      return requestIdColumn;
    },
  },
];

const mobileColumns = (userId, t, filter, optionType) => [
  {
    field: "id",
    headerName: "",
    sortable: false,
    flex: 0.2,

    renderCell: ({ row }) => {
      const {
        requestIdColumn,
        statusColumn,
        requestColumn,
        requestedMobileColumn,
        requesterColumn,
        connectionIcon,
      } = column(row, userId, t, filter, optionType);
      return (
        <div className="task-row-container">
          <div className="id-status-container">
            {requestIdColumn} {statusColumn}
          </div>
          <div className="task-card-container">
            {requesterColumn}
            <div className="display-flex">
              {requestColumn}
              <span style={{ marginLeft: "auto" }}>{connectionIcon}</span>
            </div>
            {requestedMobileColumn}
          </div>
        </div>
      );
    },
  },
];
function loadServerRows(page, pageSize, data) {
  return new Promise((resolve) => {
    resolve(data.slice(page * pageSize, (page + 1) * pageSize));
  });
}

const RequestTable = ({
  isDetailsOpen,
  rowClick,
  translation,
  search,
  filter,
  optionType,
  sortValue,
  userId,
  windowSize,
  setSelectedRow,
  setIsDetailsOpen,
  selectedRow,
}) => {
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });

  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(48);
  const [loading, setLoading] = useState(false);
  const { structInfo, countInfo } = getTaskstructInfo(optionType);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const taskList = useSelector((state) => state.overview.taskInfo[structInfo]);
  const loadingApi = useSelector((state) => state.overview.taskInfo.loading);

  const workFlowInfo = useSelector(
    (state) => state.overview.taskInfo["workflowInfo"]
  );

  const rowCount = useSelector(
    (state) => state.overview.requestCountInfo[countInfo]
  );

  const data = taskList.data;

  useEffect(() => {
    setPaginationModel((prevPageInfo) => ({ ...prevPageInfo, page: 0 }));
  }, [optionType, filter, search, sortValue]);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const newRows = await loadServerRows(
        paginationModel.page,
        paginationModel.pageSize,
        data
      );

      if (!active) {
        return;
      }
      setRows(newRows);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page, paginationModel.pageSize, data]);

  const getTaskAndWorkFlow = useCallback(() => {
    let limit =
      optionType === "APPROVE_ROLE_ASSIGNMENT" ||
      optionType === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
      optionType === "allTasks"
        ? 96
        : 48;
    setLimit(limit);

    dispatch(
      getFetchRequest(
        optionType,
        userId,
        filter.status,
        filter.role,
        search,
        { taskOffset: 0, workFlow: 0 },
        limit,
        [],
        [],
        {
          taskListTottalCount: limit,
          workFlowTottalCount: limit,
        },
        0,
        sortValue
      )
    );
  }, [dispatch, userId, filter, search, optionType, sortValue]);

  useEffect(() => {
    getTaskAndWorkFlow();
  }, [getTaskAndWorkFlow, search]);

  const handlePagination = (params, event, detatils) => {
    const currentPage =
      taskList.paginationInfo.currentPage > params.page
        ? taskList.paginationInfo.currentPage
        : params.page;
    setPaginationModel({ page: params.page, pageSize: params.pageSize });

    const taskLimit =
      optionType === "APPROVE_ROLE_ASSIGNMENT" ||
      optionType === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
      optionType === "allTasks"
        ? limit / 2
        : limit;

    const taskOffset = taskList.paginationInfo.offset + taskLimit;
    const workflowOffset = workFlowInfo.paginationInfo.offset + limit / 2;
    if (taskList.paginationInfo.currentPage < params.page) {
      dispatch(
        getFetchRequest(
          optionType,
          userId,
          filter.status,
          filter.role,
          search,
          { taskOffset: taskOffset, workFlow: workflowOffset },
          limit,
          taskList.data,
          workFlowInfo.data,
          {
            taskListTottalCount: taskList.fetchInfo.totalCount,
            workFlowTottalCount: workFlowInfo.fetchInfo.totalCount,
          },
          currentPage,
          sortValue
        )
      );
    }
  };

  const { isDesktop, isTablet, isMobile } = windowSize;
  return (
    <>
      {isDetailsOpen && !isDesktop && (
        <div className="request-details-action">
          <IconButton
            title="go to previous task"
            disabled={selectedIndex === 0}
            onClick={() => {
              const index = rows?.findIndex(
                (t) => t?.id === data[selectedIndex]?.id
              );
              if (index === 0) {
                setPaginationModel((prevPageInfo) => ({
                  ...prevPageInfo,
                  page: prevPageInfo.page - 1,
                }));
              }
              setSelectedIndex(selectedIndex - 1);
              setSelectedRow(data[selectedIndex - 1]);
            }}
          >
            <NavigateBeforeIcon fontSize="small" />
          </IconButton>

          <IconButton
            title="go to next task"
            disabled={selectedIndex === rowCount - 1}
            onClick={() => {
              const { page, pageSize } = paginationModel;
              const index = rows?.findIndex(
                (t) => t?.id === data[selectedIndex]?.id
              );
              if (index === pageSize - 1) {
                setPaginationModel((prevPageInfo) => ({
                  ...prevPageInfo,
                  page: prevPageInfo.page + 1,
                }));
                handlePagination({
                  page: page + 1,
                  pageSize: pageSize,
                });
              }
              setSelectedIndex(selectedIndex + 1);
              setSelectedRow(data[selectedIndex + 1]);
            }}
          >
            <NavigateNextIcon fontSize="small" />
          </IconButton>
        </div>
      )}
      {rows?.length === 0 ? (
        <NoDataFound>{translation("no_data_found")}</NoDataFound>
      ) : null}

      <MuiDataGrid
        {...data}
        paginationModel={paginationModel}
        rows={rows}
        autoHeight
        pagination
        columns={
          isDesktop && !isMobile && !isTablet
            ? isDetailsOpen
              ? [
                  detailColumns(
                    userId,
                    translation,
                    filter.status,
                    optionType
                  )[0],
                ]
              : columns(userId, translation, filter.status, optionType)
            : mobileColumns(userId, translation, filter.status, optionType)
        }
        rowClick={(params) => {
          rowClick(params);
          const selectedTaskIndex = data?.findIndex(
            (t) => t?.id === params?.id
          );
          setSelectedIndex(selectedTaskIndex);
        }}
        rowHeight={isDesktop ? 42 : 130}
        className={
          !isDetailsOpen ? "DataGridSingle-table" : "DataGridSingle-detail"
        }
        slotProps={{
          pagination: {
            labelRowsPerPage: `${translation("request-per-page")}:`,
          },
        }}
        loading={loading}
        localeText={{
          noRowsLabel: translation("no_data_found"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} ${translation("of")} ${count}`,
          },
        }}
        onPaginationModelChange={handlePagination}
        paginationMode="server"
        rowCount={rowCount}
      />
      <SkeletonOverlay isOpen={loadingApi}>
        <TaskPageSkeleton
          showAdminSwitch={false}
          filterSize={2}
          chipNumber={[5, 3]}
          menuCount={4}
          dataGrid={true}
          taskpageHeader={false}
          columnCount={isDesktop ? 5 : 1}
          windowSize={windowSize}
        />
      </SkeletonOverlay>
      {isDetailsOpen && (
        <div
          className={`request-details ${
            isDesktop && paginationModel.pageSize === 20
              ? "page20-min-height"
              : isDesktop && paginationModel.pageSize === 15
              ? "page15-min-height"
              : isDesktop
              ? "page10-min-height"
              : ""
          }`}
        >
          <RequestDetails
            key={selectedRow?.id}
            requestDetails={selectedRow}
            setIsDetailsOpen={setIsDetailsOpen}
            windowSize={windowSize}
            userId={userId}
          />
        </div>
      )}
    </>
  );
};

export default RequestTable;
