import { IconButton, Snackbar, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { SetCustomisedSnackBar } from "@actions";
import { AliceIcon, AliceIconType } from "@src/ComponentLibrary";
import { useCallback, useEffect } from "react";

const CustomizedSnackbar = () => {
  const dispatch = useDispatch();
  const snackBarInfo = useSelector((state) => state?.task?.customizedSnackbar);
  const handleClose = useCallback(() => {
    dispatch(SetCustomisedSnackBar({}));
  }, [dispatch]);
  const { show = false, message = "", severity } = snackBarInfo;
  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);
  return (
    <StyledSnackbar
      open={show}
      severity={severity}
      onClose={() => handleClose()}
      message={
        <div className="main-content">
          {show && (
            <AliceIcon
              color={severity === "success" ? "#198025" : "#D92121"}
              iconType={
                severity === "success"
                  ? AliceIconType.CHECK_CIRCLE
                  : AliceIconType.UIGroup
              }
            />
          )}
          {message}
        </div>
      }
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => handleClose()}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

const StyledSnackbar = styled((props) => (
  <Snackbar
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    autoHideDuration={5000}
    {...props}
  />
))(({ ...props }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: props.severity === "success" ? "#ECF4ED" : "#FBEDED",
    color: "#1C3D20",
  },
  "& button": {
    color: "#1C3D20",
  },
  "& .main-content": {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "12px",
    },
    "& span": {
      color: "#0078D6",
    },
  },
}));

export default CustomizedSnackbar;
