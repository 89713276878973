import { Skeleton } from '../../../../skeleton'
import { OverflowTooltip } from '../../../../overflowTooltip/OverflowTooltip'

export const SubTitle = ({
  title,
  size,
  isLoading
}) => (
  isLoading
    ? (
      <Skeleton
        variant="rectangular"
        width="150px"
        sx={[
          size === 's' && { height: '16px', marginBlock: '4px' },
          size === 'm' && { height: '20px', marginBlock: '6px' }
        ]}
      />
    )
    : <OverflowTooltip content={title} variant={size === 'm' ? 'bodyLarge' : 'body1'} />
)
