import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, List, ListItemText, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Done } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { changeUILanguage, LANGUAGES } from '../../../util'
import { CountryFlag } from '../../icon'
import { Dialog } from '../../dialog'
import { Button } from '../../button'

/**
 * Language List Dialog is used in the Appbar for the UserAvatarMenu in mobile view.
 */
export const LanguageListDialog = ({
  open,
  onChange = () => {},
  onClose
}) => {
  const { i18n, t } = useTranslation('componentLibrary')

  const [ selectedLanguage, setSelectedLanguage ] = useState(localStorage?.getItem('i18nextLng') || 'en')
  // i18n.language.includes(lang)
  const availableLanguages = useRef([])

  const { breakpoints } = useTheme()

  useMemo(() => {
    const lang = [ ...LANGUAGES ]

    // empty object adds divider to  list
    lang.splice(2, 0, {})

    availableLanguages.current = lang
  }, [])

  const onItemSelect = (language) => {
    setSelectedLanguage(language)
  }

  const onSaveLanguage = () => {
    changeUILanguage(i18n, selectedLanguage)
    onChange(selectedLanguage)
    onClose()
  }

  const isSelected = (lang) => selectedLanguage === lang

  return (
    <Dialog
      open={open}
      onClose={onClose}
      headerSection={t('general.language')}
      footerSection={(
        <>
          <Button variant="secondary" onClick={onClose}>Close</Button>
          <Button variant="primary" onClick={onSaveLanguage}>Save Language</Button>
        </>
      )}
      sx={{
        '.MuiDialog-paper': {
          [breakpoints.down('sm')]: {
            '&:not(.MuiDialog-paperFullScreen)': {
              minWidth: 'unset',
              margin: 'unset'
            },
            '&.MuiDialog-paperScrollPaper': { maxHeight: '70vh' }
          }
        },
        '.DialogContent': { padding: '0 16px' }
      }}
    >
      <List
        sx={{
          maxHeight: '80vh',
          width: '286px',
          padding: 0
        }}
      >
        {availableLanguages.current.map((ele, i) => {
          if (i === 2) return <Divider key="divider" />

          return (
            <MenuItem
              key={ele.value}
              onClick={() => onItemSelect(ele.value)}
              selected={isSelected(ele.value)}
              sx={{
                gap: 1,
                padding: '12px 16px',
                '&.Mui-selected': {
                  borderRadius: '4px',
                  backgroundColor: 'blue.90',
                  color: 'primary.dark'
                }
              }}
            >
              <CountryFlag
                countryCode={ele.flag}
                sx={{
                  width: '30px',
                  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                }}
              />

              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{ele.label}</ListItemText>

              {isSelected(ele.value) && (
                <Box
                  sx={{
                    display: 'flex',
                    color: 'inherit',
                    fontSize: '18px'
                  }}
                >
                  <Done color="inherit" fontSize="inherit" />
                </Box>
              )}
            </MenuItem>
          )
        })}
      </List>
    </Dialog>
  )
}

LanguageListDialog.propTypes = {
  /** If set to true, the dialog is open' */
  open: PropTypes.bool,
  /**
   * Event on lang change
   *
   * @param language
   * @returns {void}
   */
  onChange: PropTypes.func,
  /**
   * Event on dialog close
   *
   * @returns {void}
   */
  onClose: PropTypes.func.isRequired
}
