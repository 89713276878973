import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'
import { Button } from '../../button'
import { AliceIcon } from '../../icon'

/**
 * This is a generic message component.
 */
export const Message = forwardRef((
  {
    icon,
    title,
    description,
    button,
    onButtonClick = () => {},
    sx = [],
    ...otherProps
  },
  ref
) => {
  const bigText = 'h4'
  const smallText = 'h6'

  return (
    <Stack
      data-testid="Message"
      ref={ref}
      sx={mergeSxProps(
        {
          display: 'flex',
          alignItems: 'center'
        },
        sx
      )}
      spacing={2}
      {...otherProps}
    >
      {icon ? typeof icon === 'string' ? (
        <AliceIcon iconType={icon} size={48} color="#c8c8c8 !important" />
      ) : icon : null}

      {title ? typeof title === 'string' ? (
        <Typography
          variant={bigText}
          sx={{ wordWrap: 'break-word', textAlign: 'center' }}
        >
          {title}
        </Typography>
      ) : title : null}

      {description ? typeof description === 'string' ? (
        <Typography
          variant={smallText}
          sx={{ wordWrap: 'break-word', textAlign: 'center' }}
        >
          {description}
        </Typography>
      ) : description : null}

      {button ? typeof button === 'string' ? (
        <Button
          variant="tertiary"
          sx={{ color: 'primary.main' }}
          onClick={onButtonClick}
        >
          { button }
        </Button>
      ) : button : null}
    </Stack>
  )
})

Message.propTypes = {
  /**
   * Icon to display.<br>
   * Can be JSX or a valid string from the AliceIconTypes.
   */
  icon: PropTypes.node,
  /**
   * Title is displayed larger than description and directly below the icon.<br>
   * For individual styling you can pass JSX.
   */
  title: PropTypes.node,
  /**
   * Description is displayed below the title.<br>
   * For individual styling you can pass JSX.
   */
  description: PropTypes.node,
  /**
   * Button below the description. <br>
   * For individual styling you can pass JSX.
   */
  button: PropTypes.node,
  /**
   * Function that's getting called when clicking on the button. <br>
   * Note, if a custom JSX-Button is used this function will never be called.
   */
  onButtonClick: PropTypes.func,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
