import React, { useState } from "react";
import { Button } from "@alice/component_library";
import { Typography } from "@mui/material";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { approveTasks, rejectTasks } from "@actions/openTaskActions";
import ApproveRejectBox from "../DialogBox";
import ResultDialogBox from "../DialogBox/ResultBox";

const style = {
  fontFamily: "MB Corpo S Text",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
};

const TaskActionButtons = ({
  isPrimary,
  translate,
  selectedRows,
  handleRemove,
  refreshData,
}) => {
  const [open, setOpen] = useState(false);
  const [approve, setApprove] = useState(false);
  const [quickApproveStatus, setQuickApproveStatus] = useState([]);
  const [isResultDialogueOpen, setIsResultDialogueOpen] = useState(false);

  const handleApproveReject = (status) => {
    setApprove(status === "approve" ? true : false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closeDialog = () => {
    setIsResultDialogueOpen(false);
    setQuickApproveStatus(false);
  };
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const handleReject = async (rejectReason) => {
    if (selectedRows.length > 0) {
      let rejectCount = 0;
      const responseArray = selectedRows.map(async (element) => {
        const { id, isWorkFlow, currentTaskId } = element;
        let rejectInfo = {
          id: id,
          status: "failed",
          taskData: element,
        };
        const rejectedStatus = await dispatch(
          await rejectTasks(isWorkFlow ? currentTaskId : id, rejectReason)
        );
        if (rejectedStatus === 204 || rejectedStatus === 200) {
          rejectCount += 1;
          return { ...rejectInfo, status: "success" };
        } else {
          return { ...rejectInfo, status: "failed" };
        }
      });
      const resolvedResults = await Promise.all(responseArray);
      setQuickApproveStatus(resolvedResults);
      refreshData(rejectCount);
      setOpen(false);
      setIsResultDialogueOpen(true);
    }
  };
  const handleApprove = async (aprooveReason) => {
    if (selectedRows.length > 0) {
      let sucessCount = 0;
      const responseArray = selectedRows.map(async (element) => {
        const { id, isWorkFlow, currentTaskId } = element;
        let approveInfo = {
          id: id,
          status: "failed",
          taskData: element,
        };
        const approvedStatus = await dispatch(
          await approveTasks(isWorkFlow ? currentTaskId : id, aprooveReason)
        );
        if (approvedStatus === 204 || approvedStatus === 200) {
          sucessCount += 1;
          return { ...approveInfo, status: "success" };
        } else {
          return { ...approveInfo, status: "failed" };
        }
      });
      const resolvedResults = await Promise.all(responseArray);

      setQuickApproveStatus(resolvedResults);
      refreshData(sucessCount);
      setOpen(false);
      setIsResultDialogueOpen(true);
    }
  };
  return (
    <div className="action-buttons">
      <Button
        onClick={() => handleApproveReject("reject")}
        disabled={isPrimary ? false : true}
        sx={{
          ...style,
          backgroundColor: isPrimary ? "#D92121" : "rgba(0, 0, 0, 0.12)",
          paddingRight: "16px",
          color: isPrimary ? "#FFF" : "rgba(0, 0, 0, 0.38)",
          "&:hover": {
            backgroundColor: isPrimary ? "#971717" : "rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <CancelOutlinedIcon />
        <Typography variant="caption" display="block">
          {translate("reject")}
        </Typography>
      </Button>
      <Button
        disabled={isPrimary ? false : true}
        sx={{
          ...style,
          backgroundColor: isPrimary ? "#198025" : "rgba(0, 0, 0, 0.12)",
          color: isPrimary ? "#FFF" : "rgba(0, 0, 0, 0.38)",
          paddingRight: "16px",
          "&:hover": {
            backgroundColor: isPrimary ? "#115919" : "rgba(0, 0, 0, 0.12)",
          },
        }}
        onClick={() => handleApproveReject("approve")}
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" display="block">
          {translate("approve")}
        </Typography>
      </Button>
      {selectedRows.length > 0 && (
        <ApproveRejectBox
          open={open}
          approve={approve}
          handleClose={handleClose}
          t={translate}
          SelectedTaskData={selectedRows}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleRemove={handleRemove}
        />
      )}
      {quickApproveStatus.length > 0 && (
        <ResultDialogBox
          showDialog={isResultDialogueOpen}
          closeDialog={closeDialog}
          resultData={quickApproveStatus}
          t={translate}
          approve={approve}
        />
      )}
    </div>
  );
};

export default TaskActionButtons;
