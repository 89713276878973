import { GppGoodOutlined, LockOutlined, QuestionMark } from '@mui/icons-material'
import { Box, Chip as MuiChip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { AliceIcon } from '../../icon'
import { Tooltip } from '../../message'
import { Skeleton } from '../../skeleton'

/**
 * The DataClassificationChip component is an Alice chip designed to display different levels
 * of data classification. This chip component is highly versatile and can be customized to fit
 * various scenarios. It visually represents the classification of data, such as "secret,"
 * "confidential," and "internal," using icons and labels. The component is fully responsive and
 * interactive, providing an optimal user experience.
 *
 * ### Usage
 *
 * The DataClassificationChip component can be used in your application to visually
 * represent the classification of data. The component appearance may be customized by adjusting
 * `variant`, `sx`, `size` and `type` props.
 */
export const DataClassificationChip = forwardRef((
  {
    variant = 'default',
    type = 'secret',
    size = 'm',
    isLoading = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  type = type.toLowerCase()

  const [ innerRef, setInnerRef ] = useState(null)
  const [ wrapperRef, setWrapperRef ] = useState(null)

  useImperativeHandle(ref, () => wrapperRef)

  useMemo(() => {
    if (variant === 'expandable' && innerRef && wrapperRef) {
      // eslint-disable-next-line no-undef
      const observer = new ResizeObserver((entries) => {
        const { width } = entries[0].contentRect

        wrapperRef.style.setProperty('--chipWidth', width)
      })

      observer.observe(innerRef)
    }
  }, [ variant, innerRef, wrapperRef ])

  const getIcon = () => {
    switch (type) {
      case 'confidential':
        return <GppGoodOutlined htmlColor="black" fontSize="inherit" />
      case 'internal':
        return <AliceIcon color="black" iconType="ORG_DAIMLER_COMPANY" size={size === 'm' ? 13 : 10} />
      case 'secret':
        return <LockOutlined htmlColor="black" fontSize="inherit" />
      default:
        return <QuestionMark htmlColor="black" fontSize="inherit" />
    }
  }

  const getLabel = () => {
    switch (type) {
      case 'confidential':
        return 'Confidential'
      case 'internal':
        return 'Internal'
      case 'secret':
        return 'Secret'
      default:
        return t('general.unknown')
    }
  }

  const styles = {
    color: 'white',
    background: 'black',
    border: 'none',
    paddingInline: '4px',
    userSelect: 'none',
    '.MuiChip-label': variant === 'tooltip'
      ? { display: 'none' }
      : {
        p: '0',
        display: 'flex',
        justifyContent: 'flex-end',
        whiteSpace: 'nowrap',
        transition: 'max-width 250ms',
        maxWidth: (variant === 'default') ? 'unset' : 0,
        overflow: 'hidden'
      },
    '.chipIconWrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      borderRadius: '50%',
      width: size === 'm' ? '24px' : '16px',
      aspectRatio: '1 / 1',
      marginInline: '0',
      fontSize: size === 'm' ? '16px' : '10px'
    },
    '.chipInner': variant === 'tooltip' ? { display: 'none' } : { p: '0 10px' },
    '&:hover': { '.MuiChip-label': { maxWidth: 'calc((var(--chipWidth) + 20) * 1px)' } }
  }

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height={size === 'm' ? '32px' : '24px'}
        sx={{
          maxWidth: variant !== 'tooltip' && variant !== 'expandable' ? '125px' : size === 'm' ? '32px' : '24px',
          borderRadius: variant !== 'tooltip' && variant !== 'expandable' ? '16px' : '100%'
        }}
      />
    )
  }

  return (
    <Tooltip
      title={getLabel()}
      placement="bottom"
      disabled={variant !== 'tooltip'}
    >
      <MuiChip
        data-testid="DataClassificationChip"
        ref={(ref) => setWrapperRef(ref)}
        sx={mergeSxProps(styles, sx)}
        variant="filled"
        size={size === 'm' ? 'medium' : 'small'}
        icon={(
          <Box className="chipIconWrapper">
            {getIcon()}
          </Box>
        )}
        label={(
          <Typography
            variant="body2"
            className="chipInner"
            ref={(ref) => setInnerRef(ref)}
          >
            {getLabel()}
          </Typography>
        )}
        {...otherProps}
      />
    </Tooltip>
  )
})

DataClassificationChip.propTypes = {
  /** Determines chip style */
  variant: PropTypes.oneOf([ 'default', 'expandable', 'tooltip' ]),
  /** Determines a data classification */
  type: PropTypes.oneOfType([
    PropTypes.oneOf([ 'secret', 'confidential', 'internal' ]),
    PropTypes.string
  ]),
  /** Determines desired chip size */
  size: PropTypes.oneOf([ 's', 'm' ]),
  /** Determines loading state */
  isLoading: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
