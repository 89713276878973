import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import AuthoriztionErrorIcon from "@Icons/newRequestPage/AuthorizationError.svg";
import { ErrorContainer } from "../../styledComponents";
import { Layout } from "@src/ComponentLibrary";

const ErrorPage = ({ header, subHeader }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <ErrorContainer>
        <Typography variant="h1">{t(header)}</Typography>
        <AuthoriztionErrorIcon />
        <Typography variant="body1">{t(subHeader)}</Typography>
      </ErrorContainer>
    </Layout>
  );
};

export default ErrorPage;
