import React from "react";
import {
  StyledDeleteCard,
  StyledDeleteCardContent,
} from "../../styledComponents";

export const CustomCard = ({ data, icon, color, onClick }) => {
  return (
    <StyledDeleteCard
      height={"28px"}
      backgroundColor={color?.length ? color : "#F4F4F4"}
      className="custom-template-card"
      onClick={onClick}
    >
      <StyledDeleteCardContent>
        {icon}
        <div className="wl-50">
          <p className="heading">{data?.roleDefinition?.name}</p>
        </div>
        <div className="wl-50">
          <p className="heading-id"> {data?.roleDefinition?.id}</p>
        </div>
      </StyledDeleteCardContent>
    </StyledDeleteCard>
  );
};
