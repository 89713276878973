import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/system";

import {
  Dialog,
  Button,
  SearchBar,
  OrgCard,
  useNotification,
  NoSearchResult,
} from "@alice/component_library";
import { Typography } from "@mui/material";

import Communication from "@services/Communication";
import urlConfig from "@services/urlConfig";
import Loader from "@components/Loader";
import { SaveUserData } from "@actions";
import { GetOrgInfoIndividualWorkingUser } from "@actions";

const SearchContainer = styled("div")({
  margin: "14px 0px",
});

const TypographyContainer = styled("div")({
  margin: "14px 0px",
  "& .orgCaption": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.6)",
  },
});

const CardCOntainer = styled("div")({
  marginBottom: "14px",
});

const StyledDialogue = styled(Dialog)({
  "& .MuiPaper-root": {
    minWidth: "70%",
  },
});

const OrgUpdateDialog = ({
  closehandler,
  open,
  notificationHandler,
  isResponsive,
  homeOrgAsWorkingOrg,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orgs = useSelector((state) => state.role.availableOrganizationInfo);
  const user = useSelector((state) => state.user.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [orgData, setOrgdata] = useState([]);
  const userData = user.data;
  const { workingOrgId } = userData;
  const { notify } = useNotification();

  const memorizedValue = useMemo(() => {
    return search;
  }, [search]);

  useEffect(() => {
    setSearch(memorizedValue);
  }, [memorizedValue]);

  const getOrgData = (data, value) => {
    const fileterdOrgCard = value.length
      ? data.filter((x) => x.id.includes(value) || x.name.includes(value))
      : orgs;
    setOrgdata(fileterdOrgCard);
  };

  useEffect(() => {
    getOrgData(orgs, searchValue);
  }, [orgs, searchValue]);

  const searchHandler = (value) => {
    setSearchValue(value);
  };

  const onSubmit = () => {
    setIsLoading(true);
    const { id, homeOrgId } = userData;
    const { surname, givenname, nationalLanguageSurname } = userData;
    const { faxNumber, mobileNumber, nationalLanguageGivenname } = userData;
    const { mailAddress, telephoneNumber, preferredLanguage } = userData;

    const reqData = {
      workingOrgId: selectedId,
      surname,
      givenname,
      nationalLanguageGivenname,
      nationalLanguageSurname,
      telephoneNumber,
      mobileNumber,
      faxNumber,
      mailAddress,
      preferredLanguage,
      homeOrgId,
    };

    Communication.put(urlConfig.user(id), reqData)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(SaveUserData({ data: resp.data }));
          notify({
            severity: "success",
            message: t("updating-working-org-success-message"),
          });
          dispatch(GetOrgInfoIndividualWorkingUser(id, selectedId));
          closehandler();
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        notify({
          severity: "error",
          message: t("updating-working-org-failed-message"),
        });
      });
  };

  return (
    <StyledDialogue
      footerSection={
        !isLoading && (
          <>
            <Button variant="secondary" onClick={() => closehandler()}>
              {t("cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => onSubmit()}
              disabled={homeOrgAsWorkingOrg ? false : !selectedId.length}
            >
              {t("change")}
            </Button>
          </>
        )
      }
      headerSection={t("change-work-place")}
      onClose={() => closehandler()}
      open={open}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SearchContainer>
            <TypographyContainer>
              <Typography variant="caption" className="orgCaption">
                {t("select-current-work-place")}
              </Typography>
            </TypographyContainer>
            <SearchBar
              label={t("search-org-id-org-name")}
              onSearch={(e) => searchHandler(e)}
              onClear={() => searchHandler("")}
              onChange={(e) => {
                setSearch(e?.target?.value);
              }}
              showIcons
              fullWidth={true}
              size="normal"
              theme="dark"
            />
          </SearchContainer>
          {orgData.length > 1 ? (
            <>
              {orgData.map((item) =>
                workingOrgId !== item.id ? (
                  <CardCOntainer>
                    <OrgCard
                      interaction="singleSelect"
                      onCardClick={(data) => setSelectedId(data.id)}
                      organization={item}
                      isSelected={selectedId === item.id}
                      variant="list"
                      isCompactMode={isResponsive}
                    />
                  </CardCOntainer>
                ) : (
                  <></>
                )
              )}
            </>
          ) : (
            <NoSearchResult query={searchValue} sx={{ height: "200px" }} />
          )}
        </>
      )}
    </StyledDialogue>
  );
};

export default OrgUpdateDialog;
