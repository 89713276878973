import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button as MuiButton } from '@mui/material'
import { mergeSxProps } from '../../../util'
import { Skeleton } from '../../skeleton'

/**
 * A edged IconButton.
 *
 * For more documentation see: https://mui.com/material-ui/api/button/.<br>
 * All props from the button are available as usual.
 */
export const IconButtonEdged = forwardRef((
  {
    variant = 'primary',
    icon,
    isLoading,
    sx = {},
    ...otherProps
  },
  ref
) => (isLoading
  ? <Skeleton variant="rounded" sx={mergeSxProps({ width: 40, height: 28 }, sx)} />
  : (
    <MuiButton
      data-testid="IconButtonEdged"
      ref={ref}
      variant="contained"
      color="primary"
      size="small"
      disableElevation
      sx={mergeSxProps(
        {
          minWidth: 'unset',
          fontSize: 20
        },
        sx
      )}
      {...otherProps}
    >
      {icon}
    </MuiButton>
  )))

IconButtonEdged.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Add some additional styling to the button */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
