import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { Dialog, Button, UserSearch } from "@alice/component_library";

const SelectReferenceUserDialog = ({
  selectedReferenceUser,
  open,
  handleClose,
  handleSelectReferenceUser,
}) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(selectedReferenceUser);
  return (
    <Dialog
      ata-testid="OrgDeleteDialog"
      open={open}
      fullWidth
      maxWidth="lg"
      headerSection={t("select-reference-user")}
      footerSection={
        <Stack direction="row" gap={2}>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            disabled={!!!selectedUser.id}
            onClick={() => {
              handleSelectReferenceUser(selectedUser);
              handleClose();
            }}
          >
            {t("add-reference-user")}
          </Button>
        </Stack>
      }
      onClose={handleClose}
    >
      <UserSearch
        interaction="singleSelect"
        isAdminRolesOverwritten
        onCardClick={(id, selected, data) => {
          if (!selected) setSelectedUser({});
          else setSelectedUser(data);
        }}
        preselectedRowIds={[selectedUser?.id]}
        views={"list"}
        search={"large"}
        size="l"
        isSortHidden={false}
        rendererProps={{
          size: "l",
          isOrganisationShown: true,
          isCommunityShown: true,
          isEmailShown: false,
        }}
      />
    </Dialog>
  );
};

export default SelectReferenceUserDialog;
