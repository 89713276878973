import RoleRequest from "./RoleRequest";
import UserCreation from "./UserCreation";
import OrgAdminPerission from "./OrgAdminPermission";
import DeprovisioningReview from "./DeprovisioningReview";
import AssignTechuser from "./AssignTechuser";
import AssignEntitlement from "./AssignEntitlement";
import AssignUserToOrg from "./AssignUserToOrg";
const TaskRequestDetails = ({ taskInfo, isDeeplink, windowSize }) => {
  const { taskType } = taskInfo;
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      return (
        <RoleRequest
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
          windowSize={windowSize}
        />
      );
    case "APPROVE_USER_CREATION":
      return <UserCreation taskInfo={taskInfo} windowSize={windowSize} />;
    case "APPROVE_USER_TO_ORGANIZATION":
      return <AssignUserToOrg taskInfo={taskInfo} windowSize={windowSize} />;
    case "APPROVE_ORG_ADMIN_PERMISSION":
      return <OrgAdminPerission taskInfo={taskInfo} windowSize={windowSize} />;
    case "APPROVE_ROLE_DEPROVISIONING":
      return (
        <DeprovisioningReview taskInfo={taskInfo} windowSize={windowSize} />
      );
    case "APPROVE_TECHUSER_TO_APPLICATION":
      return <AssignTechuser taskInfo={taskInfo} windowSize={windowSize} />;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      return (
        <AssignEntitlement
          taskInfo={taskInfo}
          windowSize={windowSize}
          isDeeplink={isDeeplink}
        />
      );
    default:
      return (
        <RoleRequest
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
          windowSize={windowSize}
        />
      );
  }
};

export default TaskRequestDetails;
