import axios from 'axios'
import { isEmpty } from '../../util'

const PAGINATION_LIMIT = 500

export class HttpService {
  static toUrl = (baseUrl = '', url = '/') => {
    if (!url.startsWith('/')) url = `/${url}`

    return `${baseUrl}${url}`
  }

  static removeEmptyParams = ({ params }) => {
    for (const key of Object.keys(params)) {
      if (isEmpty(params[key])) {
        delete params[key]
      }
    }
  }

  static get = async (url, config = {}) => {
    try {
      HttpService.removeEmptyParams(config || {})

      const response = await axios.get(url, config)
      return response.data
    } catch (err) {
      if (err.code === 'ERR_CANCELED') throw err

      throw err.response
    }
  }

  static post = async (url, config = {}) => {
    try {
      HttpService.removeEmptyParams(config)

      const response = await axios.post(url, config?.body, config)
      return response.data
    } catch (err) {
      if (err.code === 'ERR_CANCELED') throw err

      throw err.response
    }
  }

  static put = async (url, config = {}) => {
    try {
      HttpService.removeEmptyParams(config)

      const response = await axios.put(url, config?.body, config)
      return response.data
    } catch (err) {
      if (err.code === 'ERR_CANCELED') throw err

      throw err.response
    }
  }

  static delete = async (url, config = {}) => {
    try {
      const response = await axios.delete(url, { ...config, data: config.body })
      return response.data
    } catch (err) {
      if (err.code === 'ERR_CANCELED') throw err

      throw err.response
    }
  }

  static patch = async (url, config = {}) => {
    try {
      HttpService.removeEmptyParams(config)

      const response = await axios.patch(url, config?.body, config)
      return response.data
    } catch (err) {
      if (err.code === 'ERR_CANCELED') throw err

      throw err.response
    }
  }

  static head = async (url, config = {}) => {
    try {
      HttpService.removeEmptyParams(config)

      const response = await axios.head(url, config)
      return response.headers
    } catch (err) {
      if (err.code === 'ERR_CANCELED') throw err

      throw err.response
    }
  }

  static all = async (requests) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await axios.all(requests)
    } catch (err) {
      console.error(err)

      // without response on purpose
      throw err
    }
  }

  static allSettled = async (requests) => Promise.allSettled(requests)

  static unpaginate = async (serviceFunction, dataKey, config = {}) => {
    const data = []

    let rels = {}

    config.pageSize = PAGINATION_LIMIT

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await serviceFunction(config)

      data.push(...response[dataKey])

      config.page += 1
      rels = response.rels
    } while (rels.limit + rels.offset < rels.totalCount)

    return data
  }
}
