import React from "react";
import { EditHandlerWrapper } from "@pages/newRequest/styledComponents";
import EditHandlerHeader from "./editScreenHeader";
import EditorScreenBody from "./editScreenBody";

const MainEditScreen = ({
  data,
  userid,
  saveDataToParent,
  pageIndex,
  combinedData = [],
  isFromDeeplink = false,
  isExtend = false,
  isReapply = false,
}) => {
  const saveData = (id, data) => {
    saveDataToParent(id, data);
  };

  return (
    <EditHandlerWrapper isFromDeeplink={isFromDeeplink}>
      <EditHandlerHeader headerData={data} />
      <EditorScreenBody
        data={data}
        userid={userid}
        saveInfo={(id, data) => saveData(id, data)}
        pageIndex={pageIndex}
        combinedData={combinedData}
        isFromDeeplink={isFromDeeplink}
        isExtend={isExtend}
        isReapply={isReapply}
      />
    </EditHandlerWrapper>
  );
};

export default MainEditScreen;
