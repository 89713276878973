import RightArrow from "@Icons/taskpage/right-arrow.svg";
import RoleKey from "@Icons/taskpage/rolekey.svg";
import RoleRequest from "@Icons/taskpage/rolerequest.svg";
import User from "@Icons/taskpage/user.svg";
import EntitleMentIcon from "@Icons/taskpage/AssignEntitelemnet.svg";
import AssignTechnicalUser from "@Icons/taskpage/AssignTechnicalUser.svg";
import AssignTechnicalUserLeft from "@Icons/taskpage/AssignTechnicalUserLeft.svg";
import AssignTechnicalUserRight from "@Icons/taskpage/AssignTechnicalRight.svg";
import OrgAdminPermission from "@Icons/taskpage/OrgAdminPermission.svg";
import AprooveRoleDeprovisioning from "@Icons/taskpage/AprooveRoleDeprovisioning.svg";
import RemoveIcon from "@Icons/taskpage/RemoveIcon.svg";
import UserCreation from "@Icons/taskpage/AprooveUserCreation.svg";
import UserCreationLeft from "@Icons/taskpage/AprooveUserLeft.svg";
import UserCreationRight from "@Icons/taskpage/AprooveUserRight.svg";
import UserOrganization from "@Icons/taskpage/AprooveUserToOrg.svg";
import UserOrganizationRight from "@Icons/taskpage/UserToOrgRight.svg";
import EntitlementToRole from "@Icons/taskpage/EntitlementToRole.svg";

export const dataSelection = (taskType) => {
  let roleIdSvg = "";
  let assignValueLeft = "";
  let assignValueRight = "";
  let assignValuLeftSvg = "";
  let assignValueRightSvg = "";
  let operationalIcon = "";

  operationalIcon = (
    <div className="remove-icon-svg-">
      <RightArrow />
    </div>
  );

  switch (taskType?.row?.taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      roleIdSvg = <RoleRequest />;
      assignValueLeft = taskType?.row?.affectedData?.role?.id;
      assignValueRight =
        taskType?.row?.affectedData?.user?.givenname +
        " " +
        taskType?.row?.affectedData?.user?.surname;
      assignValuLeftSvg = <RoleKey />;
      assignValueRightSvg = <User />;
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      roleIdSvg = <EntitleMentIcon />;
      assignValueLeft = taskType?.row?.affectedData?.entitlement?.entitlementId;
      assignValueRight = taskType?.row?.affectedData?.role?.id;
      assignValuLeftSvg = <EntitlementToRole />;
      assignValueRightSvg = <RoleKey />;
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      roleIdSvg = <AssignTechnicalUser />;
      assignValueLeft = taskType?.row?.affectedData?.user?.id;
      assignValueRight = taskType?.row?.affectedData?.appId;
      assignValuLeftSvg = <AssignTechnicalUserLeft />;
      assignValueRightSvg = <AssignTechnicalUserRight />;
      break;
    case "APPROVE_ORG_ADMIN_PERMISSION":
      roleIdSvg = <OrgAdminPermission />;
      assignValueLeft = taskType?.row?.affectedData?.org?.name;
      assignValueRight =
        taskType?.row?.affectedData?.user?.givenname +
        " " +
        taskType?.row?.affectedData?.user?.surname;
      assignValuLeftSvg = <OrgAdminPermission />;
      assignValueRightSvg = <User />;
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      roleIdSvg = <AprooveRoleDeprovisioning />;
      assignValueLeft = taskType?.row?.affectedData?.role?.id;
      assignValueRight =
        taskType?.row?.affectedData?.user?.givenname +
        " " +
        taskType?.row?.affectedData?.user?.surname;
      assignValuLeftSvg = <RoleKey />;
      assignValueRightSvg = <User />;
      operationalIcon = (
        <div className="remove-icon-svg- cross">
          <RemoveIcon />
        </div>
      );
      break;
    case "APPROVE_USER_CREATION":
      roleIdSvg = <UserCreation />;
      assignValueLeft =
        taskType?.row?.affectedData?.user?.givenname +
        " " +
        taskType?.row?.affectedData?.user?.surname;
      assignValueRight = taskType?.row?.affectedData?.user?.homeOrgId;
      assignValuLeftSvg = <UserCreationLeft />;
      assignValueRightSvg = <UserCreationRight />;
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      roleIdSvg = <UserOrganization />;
      assignValueLeft =
        taskType?.row?.affectedData?.user?.givenname +
        " " +
        taskType?.row?.affectedData?.user?.surname;
      assignValueRight = taskType?.row?.affectedData?.homeOrg?.name;
      assignValuLeftSvg = <User />;
      assignValueRightSvg = <UserOrganizationRight />;
      break;
    default:
      roleIdSvg = <RoleRequest />;
  }

  return {
    roleIdSvg,
    assignValueLeft,
    assignValueRight,
    assignValuLeftSvg,
    assignValueRightSvg,
    operationalIcon,
  };
};
