import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import ReorderIcon from "@mui/icons-material/Reorder";
import {
  StyledTab,
  StyledTabs,
  StyledTabsContainer,
  StyledTabsIconContainer,
  StyledTabsLabelContainer,
} from "../../styledComponents";
import { getSessionStorage, setSessionStorage } from "@src/utils";

const TabComponent = React.memo(
  ({
    selectedTab,
    onChange,
    tabs,
    layoutSelector = false,
    sx,
    layoutHandler = () => {},
  }) => {
    const reviewView = getSessionStorage("review-view");
    const [view, setView] = useState(reviewView ?? "table");
    const { t } = useTranslation();

    const handleTabChange = useCallback(
      (event, newValue) => {
        // Ensure that onChange is a stable function to prevent unnecessary re-renders
        onChange(newValue);
      },
      [onChange]
    );

    const handleIconClick = (value) => {
      setView(value);
      setSessionStorage("review-view", value);
      layoutHandler(value);
    };

    return (
      <>
        <StyledTabsContainer sx={{ ...sx }}>
          <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Horizontal tabs example"
          >
            {tabs.map((tab, index) => (
              <StyledTab
                key={index}
                label={
                  <StyledTabsLabelContainer>
                    <span className="label-container">{tab.label}</span>
                    {tab?.number && tab?.number !== 0 ? (
                      <div className="badge-container">
                        <span className="icon-container">{tab.number}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </StyledTabsLabelContainer>
                }
              />
            ))}
          </StyledTabs>
          {layoutSelector && (
            <StyledTabsIconContainer>
              <span className="view-label">{t("view")}:</span>
              {/* Icons */}
              <IconButton onClick={() => handleIconClick("grid")}>
                <GridViewIcon
                  sx={{
                    color: view === "grid" ? "#0078D6" : "rgba(0,0,0,0.54)",
                  }}
                />
              </IconButton>
              <IconButton onClick={() => handleIconClick("table")}>
                <ReorderIcon
                  sx={{
                    color: view === "table" ? "#0078D6" : "rgba(0,0,0,0.54)",
                  }}
                />
              </IconButton>
            </StyledTabsIconContainer>
          )}
        </StyledTabsContainer>
      </>
    );
  }
);

export default TabComponent;
