import { InputControl } from "@utils/inputControls";
import { GenericSearchFilter } from "@components/SortingChip";
import { FiltersContainer } from "../styledComponent";
import { Fragment } from "react";

const FilterComponent = ({
  filterState,
  handleFilter,
  filterConfig,
  windowSize = {},
}) => {
  const { isMobile = false, isTablet = false, isDesktop = true } = windowSize;
  return (
    <>
      <FiltersContainer isdesktop={isDesktop}>
        {filterConfig.map((filterConfig) => (
          <Fragment key={filterConfig.id}>
            {isDesktop && (
              <GenericSearchFilter
                key={filterConfig.id}
                activeFilter={filterState[filterConfig.id]}
                filters={filterConfig?.filters}
                label={filterConfig?.label}
                onChange={(filter) => handleFilter(filter, filterConfig.id)}
              />
            )}
            {(isTablet || isMobile) && (
              <InputControl
                type="select"
                name={filterConfig.id}
                labelName={filterConfig?.label}
                options={filterConfig?.filters.map((ele) => {
                  ele.value = ele?.filter;
                  return ele;
                })}
                value={filterState[filterConfig.id]}
                onChange={(e) =>
                  handleFilter(e?.target?.value, filterConfig.id)
                }
              />
            )}
          </Fragment>
        ))}
      </FiltersContainer>
    </>
  );
};

export default FilterComponent;
