import axios from 'axios'
import { abortableTimeout, sanitizeSearchQuery } from '../../../../util'

export const ApiAdapter = {
  async getData(
    signal,
    cachedItem,
    isCaching,
    apiBaseUrl,
    apiUrl,
    controllerRef,
    activePage,
    activeRowsPerPage,
    activeSort,
    filterConfig,
    activeFilters,
    activeSearch,
    searchConfig,
    preselectedFilter,
    isRestrictedDataHidden
  ) {
    const isMatchingCachedRequest = (cachedData, url) => {
      if (!isCaching || !cachedData) return false

      return apiUrl === cachedData.apiUrl && url === cachedData.url
    }

    const buildFilterQueryParam = () => {
      const filters = []
      const searchFilters = []

      if (activeSearch !== '') {
        if (!searchConfig) searchConfig = { apiFilters: [ 'search' ] }
        else if (!searchConfig?.apiFilters) searchConfig.apiFilters = [ 'search' ]

        searchConfig.apiFilters.forEach((apiFilter) => {
          searchFilters.push(`${apiFilter}==*${sanitizeSearchQuery(activeSearch)}*`)
        })

        if (searchFilters.length) filters.push(`(${searchFilters.join(',')})`)
      }

      if (filterConfig?.filters) {
        Object.entries(activeFilters).forEach(([ key, values ]) => {
          if (values.length > 0 && !filterConfig?.filters[key]?.dontInclude) {
            filterConfig?.filters[key]?.isMultiselect
              ? filters.push(`${key}=in=(${values.join(',')})`)
              : filters.push(`${key}==${values[0]}`)
          }
        })
      }

      if (filterConfig?.filters && preselectedFilter && isRestrictedDataHidden) {
        Object.entries(preselectedFilter).forEach(([ key, values ]) => {
          if (values.length > 0 && activeFilters[key].length < 1) {
            filterConfig?.filters[key]?.isMultiselect
              ? filters.push(`${key}=in=(${values.join(',')})`)
              : filters.push(`${key}==${values[0]}`)
          }
        })
      }

      // Special case for OrgSearch
      if (filterConfig?.filters?.activationState && !activeFilters?.activationState?.length) {
        filters.push('activationState==ALL')
      }

      if (!filters.length) return null

      return `filter=${filters.join(';')}`
    }

    const buildSortQueryParam = () => (activeSort !== '' ? `sort=${activeSort}` : null)

    const buildPaginationQueryParams = () => `offset=${activePage * activeRowsPerPage}&limit=${activeRowsPerPage}`

    const buildQueryParams = () => {
      const queryParams = []

      const filterString = buildFilterQueryParam()
      if (filterString) queryParams.push(filterString)

      const sortString = buildSortQueryParam()
      if (sortString) queryParams.push(sortString)

      const paginationString = buildPaginationQueryParams()
      if (paginationString) queryParams.push(paginationString)

      if (!queryParams.length) return ''

      return `?${queryParams.join('&')}`
    }

    const buildUrl = () => `${apiBaseUrl}${apiUrl}${buildQueryParams()}`

    const url = buildUrl()

    if (isMatchingCachedRequest(cachedItem, url)) {
      return abortableTimeout(200, controllerRef.current)
        .then(() => cachedItem.data)
        .catch(() => console.warn(`Cache aborted: Requested url ${url}`))
    }

    const response = await axios.get(url, { signal: controllerRef.current.signal })

    return {
      data: response?.data,
      url
    }
  }
}
