import { useTranslation } from "react-i18next";

export const MonthLabel = () => {
  const { t } = useTranslation();
  return t("months");
};

export const DayLabel = () => {
  const { t } = useTranslation();
  return t("days");
};

export const DayLeftLabel = () => {
  const { t } = useTranslation();
  return t("days-left");
};
