import { Box, Dialog as MuiDialog, DialogTitle, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { CloseButton } from '../../button'
import { ScrollShadow } from '../../scrollShadow'

const StyledMuiDialog = styled(MuiDialog)(({ theme: { breakpoints } }) => ({
  '.MuiDialog-paper': {
    borderRadius: '4px',
    paddingBottom: '20px',
    [breakpoints.down('sm')]: {
      '&:not(.MuiDialog-paperFullScreen)': {
        minWidth: '100%',
        margin: 'auto 0 0 0'
      },
      '&.MuiDialog-paperScrollPaper': { maxHeight: '100vh' }
    }
  },
  '.DialogTitleWrapper': {
    display: 'flex',
    justifyContent: 'space-between'
  },
  '.MuiDialogTitle-root': { flex: 1, padding: '24px 24px 16px' },
  '.DialogContent': { padding: '0 24px', marginBottom: '20px' },
  '.DialogActions': {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
    padding: '8px 24px 0'
  }
}))

/**
 * The Dialog component is a versatile component used for displaying modal dialog boxes.
 * It serves as a container for presenting important information or requesting user input in a focused and attention-grabbing manner.
 *
 * ## Example
 * The Dialog component offers various customization options, including a `footerSection` prop that can receive a node.
 * This allows you to add action buttons or any other desired functionality to the dialog. See all possible props below.
 * Moreover, Dialog component utilizes all MUI Dialog props such as: `sx`, `PaperProps` and so on.
 *
 * ### ConfirmDialog Example
 *
 * The ConfirmDialog is a specific implementation of the Dialog component.
 * It demonstrates the usage of the `footerSection` prop to include action buttons.
 * However, the `footerSection` is not limited to just buttons and can accommodate other elements as well.
 *
 *
 * ### Usage
 * To use the Dialog component, follow these steps:
 * 1. Import the Dialog component into your project.
 * 2. Set up the necessary dependencies and ensure the component is accessible in your codebase.
 * 3. Implement the Dialog component with the appropriate content and functionality. (See props below)
 * 4. Customize the behavior of the Dialog component to suit your application's needs.
 * 5. Utilize the `headerSection` prop to add Dialog title.
 * 6. Set `hasCloseButton` prop to `false` in case of getting rid of close button in the header.
 * 7. Utilize the `footerSection` prop to add action buttons or any other desired elements for achieving the desired result.
 */
export const Dialog = forwardRef((
  {
    open = false,
    onClose = () => {},
    headerSection,
    footerSection,
    fullscreen = false,
    hasCloseButton = true,
    noScroll = false,
    contentSx = {},
    children,
    ...otherProps
  },
  ref
) => (
  <StyledMuiDialog
    data-testid="Dialog"
    ref={ref}
    onClose={onClose}
    open={open}
    fullScreen={fullscreen}
    scroll={noScroll ? 'body' : 'paper'}
    {...otherProps}
  >
    <Box className="DialogTitleWrapper">
      <DialogTitle>
        {typeof headerSection === 'string'
          ? (
            <Typography variant="h5" component="div">
              {headerSection}
            </Typography>
          )
          : headerSection}
      </DialogTitle>

      {hasCloseButton && (
        <CloseButton
          data-testid="Dialog-close"
          size="medium"
          onClick={onClose}
          sx={({ palette }) => ({
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '0 0 0 4px',
            transition: 'opacity 250ms',
            ':hover': { backgroundColor: 'black', opacity: 1 - palette.action.activatedOpacity }
          })}
        />
      )}
    </Box>

    {!noScroll
      ? <ScrollShadow classNameInner="DialogContent" sx={contentSx}>{children}</ScrollShadow>
      : <Box className="DialogContent noScroll" sx={contentSx}>{children}</Box>}

    {footerSection && <Box className="DialogActions">{footerSection}</Box>}
  </StyledMuiDialog>
))

Dialog.propTypes = {
  /** Determines current state of dialog popup */
  open: PropTypes.bool,
  /** onClose Event */
  onClose: PropTypes.func,
  /** A node that appears in dialog header section */
  headerSection: PropTypes.node,
  /** A node that appears in dialog footer section */
  footerSection: PropTypes.node,
  /** Determines fullscreen mode of dialog */
  fullscreen: PropTypes.bool,
  /** Determines scroll mode of dialog */
  noScroll: PropTypes.bool,
  /** Determines presence of close button */
  hasCloseButton: PropTypes.bool,
  /** Props for DialogContent component wrapping the content */
  contentProps: PropTypes.object
}
