import styled from "styled-components";

import { Box } from "@mui/material";
import { CreateButton } from "@alice/component_library";

const StyledCreateButton = styled(CreateButton)`
  ${(props) =>
    props.isSecondary &&
    `
    border: 1px solid #00000080;
    background-color: transparent;
    color: #000000;
    svg {
        color: #0000008A;
      }
    &:hover {
      background-color: transparent;
      .MuiBox-root {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    .MuiBox-root {
      background-color: rgba(0, 0, 0, 0.12);
    }
      
  `}
`;

const BulkImpButton = ({
  icon,
  title = "",
  handleClick,
  isSecondary,
  description = "",
}) => {
  return (
    <>
      <Box>
        <StyledCreateButton
          isSecondary={isSecondary}
          description={description}
          icon={icon}
          onClick={handleClick}
          title={title}
        />
      </Box>
    </>
  );
};

export default BulkImpButton;
