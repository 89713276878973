import React, { useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Layout } from "@alice/component_library";

import ProfileHeader from "./componnents/ProfileHeader";
import UserTable from "./componnents/UserTable";
import { ControlCenterWrapper } from "./styledComponents";

const ControlCenter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasCCAccess = useSelector((state) => state.controlCenter.hasCCAccess);

  useEffect(() => {
    if (!hasCCAccess) navigate("/access");
  }, [navigate, hasCCAccess]);

  return (
    <ControlCenterWrapper>
      <Layout>
        <ProfileHeader />
        <UserTable t={t} navigate={navigate} />
      </Layout>
    </ControlCenterWrapper>
  );
};

export default withTranslation()(memo(ControlCenter));
