import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StartIcon from "@mui/icons-material/Start";
import ReplayIcon from "@mui/icons-material/Replay";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ExpandMore } from "@mui/icons-material";
import {
  AliceIcon,
  AliceIconType,
  DataClassificationChip,
  Tooltip,
  DateUtils,
  IconButton,
} from "@alice/component_library";
import RoleTypeChip from "./RoleTypeChip";
import {
  OrgCustValidityContainer,
  RoleCardHeader,
  RoleGenericCard,
} from "./StyledComponents";
import { PrimaryButton } from "../button";
import OrgNameInfo from "./OrgName";
import CustomScopeName from "./CustomScopeName";
import { ValidFromComp, ValidToComp } from "./Validity";

const RoleCardList = ({
  id,
  data = {},
  size = "m",
  variant = "list",
  hideActions = true,
  isOpen = false,
  showMore = true,
}) => {
  const { t } = useTranslation();
  const remainingDaysToWarning = 14;
  const {
    roleDefinition,
    orgScope = {},
    customScope = {},
    validTo,
    validFrom,
    attachments = 0,
  } = data;
  const {
    id: roleId,
    name: roleName,
    roleType = "BUSINESS",
    isJobTitle = false,
    dataClassification = "INTERNAL",
    needsOrgScopes = false,
    needsCustomScopes = false,
    needAttachments = false,
  } = roleDefinition;
  const validToDaysLeft = validTo
    ? DateUtils.daysBetweenDates(
        new Date(Date.now()),
        new Date(Date.parse(validTo))
      )
    : null;
  let finalValidFrom = validFrom;
  let finalValidTo = validTo;
  let finalValidityDaysLeft = validToDaysLeft;
  const { name: custId } = customScope;
  const { id: orgId, name: orgName } = orgScope;

  const dataChange = [
    {
      id: "scope",
      value: [
        {
          icon: <AliceIcon iconType={AliceIconType.UIDate_From} size={16} />,
          renderer: (
            <OrgNameInfo
              orgName={orgName}
              orgId={orgId}
              needsOrgScopes={needsOrgScopes}
            />
          ),
        },
        {
          icon: <AliceIcon iconType={AliceIconType.CUSTOM_SCOPE} size={16} />,
          renderer: (
            <CustomScopeName
              custId={custId}
              needsCustomScopes={needsCustomScopes}
            />
          ),
        },
      ],
    },

    {
      id: "validity",
      value: [
        {
          icon: <StartIcon />,
          renderer: <ValidFromComp finalValidFrom={finalValidFrom} />,
        },
        {
          icon: <KeyboardTabIcon />,
          renderer: (
            <ValidToComp
              finalValidTo={finalValidTo}
              finalValidityDaysLeft={finalValidityDaysLeft}
              remainingDaysToWarning={remainingDaysToWarning}
            />
          ),
        },
        // {
        //   icon: <AttachFile />,
        //   renderer: (
        //     <Typography variant="body2" noWrap>
        //       {!!attachments ? attachments : "-"}
        //     </Typography>
        //   ),
        //   id: "attachments",
        // },
      ],
    },
  ];
  return (
    <RoleGenericCard id={`role-card-list-${id}-${size}`} variant={variant}>
      <div
        className={
          hideActions ? "role-list-container" : "role-list-card-container"
        }
      >
        <div className="role-details">
          <RoleCardHeader>
            <div className="role-id-container">
              <Typography variant="caption" className="id-holder">
                {t("id")}
              </Typography>
              <Tooltip type="ellipsized" title={roleId}>
                <Typography
                  variant="subtitle1"
                  className="role-cardv3-id"
                  noWrap
                >
                  {roleId}
                </Typography>
              </Tooltip>
            </div>
            <div className="role-card-header">
              <RoleTypeChip type={roleType} />
              {isJobTitle && <RoleTypeChip type="JOBTITLE" />}
              <DataClassificationChip
                size="s"
                type={dataClassification.toLowerCase()}
                variant="default"
              />
            </div>
          </RoleCardHeader>
          <Tooltip type="ellipsized" title={roleName}>
            <Typography variant="body1" className="role-cardv3-name" noWrap>
              {roleName}
            </Typography>
          </Tooltip>
          <OrgCustValidityContainer>
            {dataChange.map((el) => (
              <div
                key={el.id}
                style={{
                  display: "grid",
                  gridTemplateColumns:
                    el.id === "validity"
                      ? "120px 1fr"
                      : "repeat(auto-fit, minmax(0, 1fr))",
                  gap: "16px",
                }}
              >
                {el.value.map((a) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {a.icon}
                    {a.renderer}
                  </div>
                ))}
              </div>
            ))}
          </OrgCustValidityContainer>
        </div>
        {!hideActions && (
          <>
            <Divider orientation="vertical" />
            <div style={{ display: "flex", gap: "16px" }}>
              <PrimaryButton
                style={{ padding: "4px 10px", minWidth: 0 }}
                label={
                  finalValidityDaysLeft < 0 ? (
                    <ReplayIcon />
                  ) : (
                    <EditOutlinedIcon />
                  )
                }
              />
              <PrimaryButton
                style={{ padding: "4px 10px", minWidth: 0 }}
                label={<DeleteOutlineOutlinedIcon />}
              />
            </div>
            {showMore && (
              <IconButton
                // onClick={(e) => handleExpandClick({ e, isExpanded: !isOpen })}
                className="expand-more"
              >
                <ExpandMore className={isOpen ? "open" : ""} />
              </IconButton>
            )}
          </>
        )}
      </div>
    </RoleGenericCard>
  );
};

RoleCardList.prototype = {
  variant: PropTypes.oneOf(["grid", "list"]),
  size: PropTypes.oneOf(["m", "l"]),
  showMore: PropTypes.bool,
  isOpen: PropTypes.bool,
  hideActions: PropTypes.bool,
};
export default RoleCardList;
