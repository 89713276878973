import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Button } from '../../button'
import { AliceIcon, AliceIconType } from '../../icon'

/**
 * A page to display when the API is not available
 */
export const MaintenancePage = () => {
  const { t } = useTranslation('componentLibrary')

  const refreshLoad = () => {
    window.location.reload(true)
  }

  return (
    <Paper
      data-testid="MaintenancePage"
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        height: '100vh',
        padding: 2,
        textAlign: 'center'
      }}
    >
      <div>
        <Typography component="span" sx={{ fontSize: '3rem', fontWeight: 'bold' }}>Alice </Typography>
        {' '}
        <Typography component="span" sx={{ fontSize: '3rem' }}>for </Typography>
        {' '}
        <Typography component="span" variant="h1">Mercedes-Benz Group AG</Typography>
      </div>

      <AliceIcon size={48} iconType={AliceIconType.CONNECT_DEVICE_CANCEL} />

      <Typography variant="h3">{t('maintenance.title')}</Typography>

      <div>
        <Typography>{t('maintenance.hint1')}</Typography>
        <Typography>{t('maintenance.hint2')}</Typography>
      </div>

      <Button
        variant="secondary"
        id="showRequest"
        onClick={() => refreshLoad()}
        sx={{ marginTop: 1 }}
      >
        {t('general.refresh')}
      </Button>
    </Paper>
  )
}
