import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { changeUILanguage, LANGUAGES } from '../../../util'

/**
 * This is a simple select with all available languages for Alice.
 *
 * If you just use the Component without the prop "onChange", it will manage the UI language itself.<br>
 * If you use the "onChange" prop it will overwrite the onChange function of the component.
 */
export const LanguageSelect = ({ value, onChange }) => {
  const { t, i18n } = useTranslation('componentLibrary')

  let currentLanguage

  if (value || value === '') {
    currentLanguage = value
  } else {
    currentLanguage = i18n.language
  }

  const handleChangeLanguage = (event) => {
    if (onChange) {
      onChange(event)
    } else {
      changeUILanguage(i18n, event.target.value)
    }
  }

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel>{t('startup.selectLanguage')}</InputLabel>

      <Select
        value={currentLanguage}
        onChange={handleChangeLanguage}
        MenuProps={{ disableScrollLock: true }}
      >
        {LANGUAGES.map((ele) => (
          <MenuItem key={ele.value} value={ele.value}>
            <ListItemText>{ele.label}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

LanguageSelect.propTypes = {
  /** Use this with onChange to make it a controlled component */
  value: PropTypes.string,
  /** If you want to do the language switching yourself, otherwise the component will handle the change of language */
  onChange: PropTypes.func
}
