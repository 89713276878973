import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Card } from "@alice/component_library";

export const RoleCardContainer = styled(Card)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 100px",
  columnGap: "24px",
  padding: "16px",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#F8F8F8",
}));

export const CardDetailContainer = styled("div")(({ theme }) => ({
  display: "grid",
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 1fr",
  },
  gap: "16px",
  alignItems: "center",
  overflow: "hidden",
}));

export const CardIdContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  overflow: "hidden",
}));

export const CardValidityContainer = styled("div")(({ theme }) => ({
  display: "grid",
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 2fr",
  },
  gap: "16px",
  alignItems: "center",
  "& .custom-validity-width": {
    [theme.breakpoints.down("lg")]: {
      padding: "0 8px",
    },
  },
  "& .role-item": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "8px",
    overflow: "hidden",
  },
}));

export const CardButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "24px",
}));

export const CardIdIcon = styled(Typography)({
  background: "#00000014",
  padding: "5.5px 4px",
  borderRadius: "2px",
  lineHeight: "16px",
  fontSize: "12px",
  height: "24px",
  width: "24px",
  minWidth: "24px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const CardIdTypo = styled(Typography)({
  color: "#0078D6",
  fontFamily: "MB Corpo S Text",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const CardNameTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "32px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
