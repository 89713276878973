import StatusCheckSuccess from "@Icons/newRequestPage/StatusCheckSuccess.svg";
import StatusCheckError from "@Icons/newRequestPage/StatusCheckError.svg";

const StatusRoleConfig = ({ status = "failed" }) => {
  return (
    <>{status === "success" ? <StatusCheckSuccess /> : <StatusCheckError />}</>
  );
};

export default StatusRoleConfig;
