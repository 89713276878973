import { Grid } from "@mui/material";
import {
  RequestSubHeader,
  RequestSubHeaderTypography,
  RequestDetailsContainer,
  RequestHeader,
  RSubTopicContainer,
} from "@pages/requestPage/styledComponents";
import { UserCard, AliceIcon, AliceIconType } from "@alice/component_library";
import { useTranslation } from "react-i18next";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import TechnicalUserIcon from "@Icons/taskpage/taskDetails/TechnicalUser.svg";
import ApplicationCard from "@src/sharedComponents/UserCards/applicationCard";
import WorkFlowData from "../RequestWorkflow/WorkFlowData";

const AssignTechToApp = ({ taskInfo, loggedInUser, windowSize }) => {
  const { t } = useTranslation();
  const {
    originator = {},
    state,
    affectedData = {},
    isWorkFlow,
    id,
  } = taskInfo;
  const { user = {}, appId } = affectedData;
  if (user) {
    user.givenname = user?.id;
    user.surname = "Techuser";
  }
  const { id: requesterId } = originator;
  const { isMobile } = windowSize;

  return (
    <RequestDetailsContainer>
      <RequestHeader>
        {t("task-type-details-header.assign-technical-user-details")}
      </RequestHeader>
      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <RequesterIcon />
            <RequestSubHeaderTypography>
              {t("requester")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>

          <UserCard
            userData={originator}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
            className={loggedInUser === requesterId ? "highlight-card" : ""}
          />
        </RSubTopicContainer>
      </Grid>

      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <TechnicalUserIcon />
            <RequestSubHeaderTypography>
              {t("technical_user")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>

          <UserCard
            userData={user}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
          />
        </RSubTopicContainer>
      </Grid>
      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>
              {t("application")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>
          <div className="application-card">
            <ApplicationCard appId={appId} appCard={true} />
          </div>
        </RSubTopicContainer>
      </Grid>
      {(state === "PENDING" || isWorkFlow) && (
        <WorkFlowData
          workflowId={id}
          isWorkFlow={isWorkFlow}
          taskInfo={taskInfo}
          isDeeplink={false}
        />
      )}
    </RequestDetailsContainer>
  );
};

export default AssignTechToApp;
