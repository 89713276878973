import { Box, Divider, Drawer, List, Stack, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useApp, useOpenTasks, usePermission } from '../../../hooks'
import { getClient, mergeSxProps } from '../../../util'
import { ListEntry } from '../ListEntry'
import { SupportLink } from '../SupportLink'
import { Footer } from '../../footer'
import { TasksLink } from '../TasksLink'

const drawerWidth = { small: 85, big: 240 }

/**
 * A simple drawer to navigate the pages.
 *
 * Note: there is a top space to fit the header in real application
 */
export const NavDrawer = ({
  children,
  navTargets,
  showSupport = false,
  isNavHidden = false,
  isFooterShown = false,
  size = 'big',
  sx = [],
  ...otherProps
}) => {
  const { isMobileDrawerOpen, setIsMobileDrawerOpen } = useApp()
  const { hasOneOfPermission } = usePermission()
  const { openTasksCount } = useOpenTasks()

  const accessClient = getClient() === 'access'

  // const isTaskButtonShown = !!totalCount && mobileDrawer && !accessClient
  const isTaskButtonShown = !!openTasksCount && isMobileDrawerOpen && !accessClient

  const handleDrawerToggle = () => setIsMobileDrawerOpen(!isMobileDrawerOpen)

  const permissionFilteredNavTargets = useMemo(() => navTargets.filter((navTarget) => {
    if (!navTarget?.requiredEntitlement || !navTarget?.requiredEntitlement?.length) return true

    if (Array.isArray(navTarget.items)) {
      navTarget.items = navTarget.items.filter((childNavTarget) => {
        if (!childNavTarget?.requiredEntitlement || !childNavTarget?.requiredEntitlement?.length) return true

        return hasOneOfPermission(childNavTarget.requiredEntitlement)
      })
    }

    return hasOneOfPermission(navTarget.requiredEntitlement)
  }), [ hasOneOfPermission, navTargets ])

  const nav = (
    <List
      component="nav"
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyItems: 'flex-start',
        height: '100%',
        padding: size !== 'small' && 2
      }}
    >
      <Toolbar />

      {isTaskButtonShown && (
        <TasksLink size={size} totalCount={openTasksCount} />
      )}

      {permissionFilteredNavTargets.map((target, index) => {
        switch (size) {
          case 'small':
            // only show leaves
            if (!target.items) return <ListEntry key={index} size={size} {...target} />

            return null
          default:
            return <ListEntry key={index} size={size} {...target} />
        }
      })}

      {showSupport && (
        <Stack>
          {size !== 'small' && (
            <Divider flexItem sx={{ marginInline: '16px' }} />
          )}

          <SupportLink size={size} />
        </Stack>
      )}

      {(isFooterShown && size !== 'small') && (
        <Footer isNavFooter />
      )}
    </List>
  )

  return (
    <Box data-testid="NavDrawer" sx={{ display: 'flex' }}>
      {!isNavHidden && (
        <Box sx={{ width: { sm: drawerWidth[size] } }}>
          {/* Mobile drawer */}
          <Drawer
            data-testid="NavDrawer-Mobile"
            sx={mergeSxProps(
              {
                display: { xs: 'block', sm: 'none' },
                '.MuiDrawer-paper': {
                  width: drawerWidth[size],
                  boxSizing: 'border-box'
                }
              },
              sx
            )}
            variant="temporary"
            open={isMobileDrawerOpen}
            onClose={handleDrawerToggle}
            // better open performance on mobile.
            ModalProps={{ keepMounted: true }}
            {...otherProps}
          >
            {nav}
          </Drawer>

          {/* Desktop drawer */}
          <Drawer
            data-testid="NavDrawer-Desktop"
            variant="permanent"
            open
            sx={mergeSxProps(
              {
                display: { xs: 'none', sm: 'block' },
                '.MuiDrawer-paper': {
                  width: drawerWidth[size],
                  backgroundColor: 'grey.90'
                }
              },
              sx
            )}
            {...otherProps}
          >
            {nav}
          </Drawer>
        </Box>
      )}

      {/* Content */}
      <Stack
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          width: { sm: `calc(100% - ${drawerWidth[size]}px)` }
        }}
      >
        <Toolbar />
        {children}
      </Stack>
    </Box>
  )
}

NavDrawer.propTypes = {
  /** Array with all the navTargets */
  navTargets: PropTypes.array.isRequired,
  /** Display small or big navDrawer */
  size: PropTypes.oneOf([ 'small', 'big' ]),
  /** Display Link to support item */
  showSupport: PropTypes.bool,
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
