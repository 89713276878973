import { useTranslation } from 'react-i18next'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { Switch } from '../../../form'

export const AdminRoleVisibleAsSupportContactSwitch = ({
  userId,
  isVisible,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'visibleassupportcontact'
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.visibleAsSupportContact') }),
      onRequestSuccess: (response) => onChange({ visibleAsSupportContact: response })
    }
  )

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'visibleassupportcontact'
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.visibleAsSupportContact') }),
      onRequestSuccess: (response) => onChange({ visibleAsSupportContact: response })
    }
  )

  const handleChange = (event, value) => (value ? executeAssign() : executeDelete())

  return (
    <Switch
      label={t('adminRoles.visibleAsSupportContactSwitch.label')}
      helperText={t('adminRoles.visibleAsSupportContactSwitch.helper')}
      checked={isVisible}
      disabled={isLoadingAssign || isLoadingDelete}
      onChange={handleChange}
    />
  )
}
