import { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Popover, Typography, useMediaQuery } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { usePermission } from '../../hooks'
import { checkAuthority, CLIENTS, getClient, getClientLink, mergeSxProps } from '../../util'
import { Tooltip } from '../message'
import { ClientText } from './ClientText'
import { CardContent, GenericCard } from '../card/genericCard'
import { Interaction } from '../../constants'
import { IconWrapper } from '../icon/iconWrapper/IconWrapper'

/**
 * The AppSwitcher requires the ReduxStore of the ComponentLibrary.<br>
 * So it can check what clients the user is allowed to see.
 *
 * Here in the Showcase you are allowed to see all clients.
 */
export const AppSwitcher = forwardRef((
  {
    disabled = false,
    tooltip = '',
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const { permissions, adminRoles } = usePermission()

  const [ isMenuOpen, setIsMenuOpen ] = useState(false)
  const [ appBarRef, setAppBarRef ] = useState(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onAppSwitcherPress = () => {
    // Currently the AppSwitch doesn't come directly from the AppBar.
    // That's why we can't get its ref as parameter. Without effecting other ui's
    setAppBarRef(document.getElementById('aliceHeader'))

    setIsMenuOpen(true)
  }

  const handleClose = () => setIsMenuOpen(false)
  const isClientSelected = (client) => client.toLowerCase() === getClient()
  const getEntries = () => CLIENTS.filter((client) => checkAuthority(client, permissions, adminRoles))

  // User can switch, if he is authorized to view more than 1 client and disabled is not true
  const canSwitchClient = !disabled && getEntries().length > 1

  return (
    <>
      <Tooltip data-testid="AppSwitcher-tooltip" title={tooltip} arrow>
        { /* Box-Wrapper allows Tooltip even if button is disabled */}
        <Box>
          <Button
            data-testid="AppSwitcher-open"
            onClick={onAppSwitcherPress}
            disabled={!canSwitchClient}
            sx={mergeSxProps({ color: 'common.white' }, sx)}
            {...otherProps}
          >
            <ClientText />

            <IconWrapper
              color="common.white"
              size={24}
            >
              {!canSwitchClient
                ? null
                : (
                  <KeyboardArrowDown
                    sx={[
                      { transition: 'transform 250ms ease' },
                      isMenuOpen && { transform: 'rotate(180deg)' }
                    ]}
                  />
                )}
            </IconWrapper>
          </Button>
        </Box>
      </Tooltip>

      <Popover
        ref={ref}
        data-testid="AppSwitcher"
        anchorEl={appBarRef}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          '.MuiPopover-paper': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 1,
            backgroundColor: 'common.white',
            padding: 2,
            borderRadius: '0 0 4px 4px'
          }
        }}
        disableScrollLock
      >
        {getEntries().map((client) => (
          <GenericCard
            data-testid={`AppSwitcher-client-${client}`}
            interaction={Interaction.NAVIGATE}
            variant="grid"
            key={client}
            component="a"
            target="_blank"
            href={getClientLink(client.toLowerCase())}
            isSelected={isClientSelected(client)}
            onCardClick={(id, isSelected, e) => console.log(id, isSelected, e)}
            sx={[
              {
                textDecoration: 'none',
                '.MuiBox-root': { gap: 0.5 }
              },
              !isMobile && { maxWidth: '230px' }
            ]}
          >
            <CardContent>
              <ClientText
                showIcon={false}
                sx={{
                  fontSize: '1rem',
                  color: isClientSelected(client) ? 'primary.main' : 'common.black',
                  pl: 0
                }}
                client={client}
              />

              <Typography
                variant="caption"
                sx={{
                  color: isClientSelected(client) ? 'text.primary' : 'text.secondary',
                  lineHeight: '16px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {t(`aliceAppBar.clientDescription.${client.toLowerCase()}`)}
              </Typography>
            </CardContent>
          </GenericCard>
        ))}
      </Popover>
    </>
  )
})

AppSwitcher.propTypes = {
  /** Disable the dropdown and only show the client name */
  disabled: PropTypes.bool,
  /** Add a tooltip to the AppSwitcher */
  tooltip: PropTypes.string,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
