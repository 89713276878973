export const AdminRoles = {
  adminRoles: [
    { adminRole: 'APPADMIN' },
    { adminRole: 'ROLE_APPROVER' },
    { adminRole: 'ROLE_OWNER' },
    { adminRole: 'CONNECTOR_CONFIGURATION_ADMIN' },
    { adminRole: 'CORE_CONFIGURATION_ADMIN' },
    { adminRole: 'ADMIN_ROLE_ASSIGNMENT_ADMIN' },
    { adminRole: 'AUDIT_LOG' },
    { adminRole: 'BILLING' },
    { adminRole: 'FUNCTIONAL_ADMINISTRATION' }
  ]
}
