import { AliceIconPng } from '../../../_assets'

export const FlagNotFound = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 342">
    <path fill="SteelBlue" d="M 0 0 h 513 v 342 H 0 z" />
    <path fill="IndianRed" d="M 85.5 57.5 h 342 v 228 H 85.5 z" />
    <path fill="SeaGreen" d="M 151 114 h 211 v 114 H 151 z" />
    <path fill="LavenderBlush" d="M 0 166 h 513 v 10 H 0 z" />
    <path fill="LavenderBlush" d="M 251.5 57.5 h 10 v 228 H 251.5 z" />
    <image href={AliceIconPng} x="178" y="94" height="154" />
  </svg>
)
