import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { convertWebServiceToConsumableWebService } from '../../../../util'

export const WebServicesScope = ({
  userData,
  selectedWebServices,
  setSelectedWebServices,
  availableWebServices = [
    'SDB',
    'IAGTOOL',
    'INTERNAL',
    'IDM',
    'SELF_SERVICE',
    'UMAS'
  ]
}) => {
  const { t } = useTranslation('componentLibrary')

  const alreadySelectedWebServices = useMemo(() => {
    const selected = []

    Object.keys(userData)
      .forEach((key) => {
        if (userData[key]) selected.push(convertWebServiceToConsumableWebService(key))
      })

    return selected
  }, [ userData ])

  const handleChange = (event) => {
    if (event.target.checked) {
      // add to array
      const newScope = [ ...selectedWebServices ]

      newScope.push(event.target.name)
      setSelectedWebServices(newScope)
    } else {
      // remove from array
      const newScope = [ ...selectedWebServices ]
      const filtered = newScope.filter((ele) => ele !== event.target.name)

      setSelectedWebServices(filtered)
    }
  }

  return (
    <Stack data-testid="WebServicesScope">
      <FormGroup>
        {availableWebServices.map((ele) => (
          <FormControlLabel
            data-testid={`WebServicesScope-${ele}`}
            key={ele}
            control={(
              <Checkbox
                checked={selectedWebServices.includes(ele) || alreadySelectedWebServices.includes(ele)}
                disabled={alreadySelectedWebServices.includes(ele)}
                onChange={handleChange}
                name={ele}
              />
            )}
            label={t(`webServices.${ele}`)}
          />
        ))}
      </FormGroup>
    </Stack>
  )
}

WebServicesScope.propTypes = {
  userData: PropTypes.object,
  selectedWebServices: PropTypes.array,
  setSelectedWebServices: PropTypes.func,
  availableWebServices: PropTypes.array
}
