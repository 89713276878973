import React from "react";

const NoAvailableApps = () => {
  return (
    <span>
      <svg
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>NoApplications</title>
        <g
          id="Desktop"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="MyApps" transform="translate(-696.000000, -954.000000)">
            <g id="Group-4" transform="translate(546.500000, 954.000000)">
              <g
                id="NoApplications"
                transform="translate(149.500000, 0.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                <g
                  id="layers-off"
                  transform="translate(10.000000, 11.000000)"
                  stroke="#C8C8C8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                >
                  <path
                    d="M3.07101784,5.41349301 L0.414063051,6.78241585 C0.159700839,6.91414433 -5.52658057e-16,7.17669572 -5.52658057e-16,7.46314384 C-5.52658057e-16,7.74959197 0.159700839,8.01214336 0.414063051,8.14387184 L7.92820497,12.0341017"
                    id="Path"
                  ></path>
                  <path
                    d="M17.982321,13.1155507 L27.5859369,8.15133869 C27.8402992,8.01961021 28,7.75705882 28,7.4706107 C28,7.18416257 27.8402992,6.92161118 27.5859369,6.7898827 L14.8810885,0.214075169 C14.3283716,-0.0713583898 13.6716284,-0.0713583898 13.1189115,0.214075169 L9.78496213,1.93891795"
                    id="Path"
                  ></path>
                  <path
                    d="M21.4556516,17.8582461 L27.5859369,14.687323 C27.8402992,14.5555945 28,14.2930431 28,14.006595 C28,13.7201469 27.8402992,13.4575955 27.5859369,13.325867 L22.5868796,10.733625"
                    id="Path"
                  ></path>
                  <path
                    d="M5.41312037,10.733625 L0.414063051,13.3196446 C0.159700839,13.4513731 2.21063223e-15,13.7139245 2.21063223e-15,14.0003726 C2.21063223e-15,14.2868208 0.159700839,14.5493722 0.414063051,14.6811006 L13.1189115,21.2531747 C13.6218725,21.5134101 14.2142536,21.5375611 14.7367294,21.3191319"
                    id="Path"
                  ></path>
                  <path
                    d="M24.9289822,22.5872523 L27.5859369,21.2183294 C27.8402992,21.086601 28,20.8240496 28,20.5376014 C28,20.2511533 27.8402992,19.9886019 27.5859369,19.8568734 L22.5868796,17.2671203"
                    id="Path"
                  ></path>
                  <path
                    d="M5.41312037,17.2671203 L0.414063051,19.8494066 C0.159700839,19.9811351 0,20.2436865 0,20.5301346 C0,20.8165827 0.159700839,21.0791341 0.414063051,21.2108626 L13.1189115,27.7829367 C13.6716284,28.0683702 14.3283716,28.0683702 14.8810885,27.7829367 L18.2150379,26.0580939"
                    id="Path"
                  ></path>
                  <line
                    x1="3.7330787"
                    y1="2.54161694e-05"
                    x2="24.2669213"
                    y2="28.0007199"
                    id="Path"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default NoAvailableApps;
