import { Avatar, Box, Card, FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Cookie, Environment } from '../../../constants'
import { DEV_TOKEN_LINK, getEnvironment } from '../../../util'
import { Button } from '../../button'
import { TextField } from '../../form'
import { AliceIcon, AliceIconType } from '../../icon'
import { Alert } from '../../message'

export const LoginPage = ({
  overwriteLogo,
  onLogin = ({ userId, token }) => {}
}) => {
  const [ tokenLogin, setTokenLogin ] = useState(false)

  const isGems = window.location.origin.includes('gems')

  const passwordToToken = (user, password) => {
    const encoded = btoa(`${user}:${password}`)

    return `Basic ${encoded}`
  }

  const getValidBearerToken = (token) => {
    const regex = /Bearer [a-zA-Z0-9]{28}/
    let tokenToTest = token

    if (!token.includes('Bearer')) {
      tokenToTest = `Bearer ${token}`
    }

    const regexResult = tokenToTest.match(regex)

    if (regexResult) {
      return regexResult[0]
    }

    return null
  }

  const saveUserLocal = (user, token) => {
    Cookies.set(Cookie.USER_ID, user)
    Cookies.set(Cookie.BEARER_TOKEN, token)

    onLogin({ userId: user, token })
  }

  const handlePasswordLogin = (user, password) => {
    if (user !== '' && password !== '') {
      saveUserLocal(user, passwordToToken(user, password))
    }
  }

  const handleTokenLogin = (user, token) => {
    const bearerToken = getValidBearerToken(token)

    if (bearerToken) {
      saveUserLocal(user, bearerToken)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    const userInput = data.get('user')
    const passwordInput = data.get('password')

    if (tokenLogin) {
      handleTokenLogin(userInput, passwordInput)
    } else {
      handlePasswordLogin(userInput, passwordInput)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        mt: 0,

        backgroundColor: '#2D3748'
      }}
    >
      <Card
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      >
        {overwriteLogo || (
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <AliceIcon iconType={AliceIconType.ORG_DAIMLER_COMPANY} />
          </Avatar>
        )}

        <Typography variant="h5">Local Login</Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="user"
            label="User"
            name="user"
            autoComplete="user"
            autoFocus
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={tokenLogin ? 'Bearertoken' : 'Password'}
            type="password"
            id="password"
            autoComplete="current-password"
          />

          {!isGems && (
            <Card sx={{ mt: 2, borderRadius: '10px' }}>
              <Alert severity="info">
                Use the login with bearer token if you want to connect to
                DEV/INT/PROD
                {' '}
              </Alert>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  p: 1
                }}
              >
                <FormControlLabel
                  control={(
                    <Switch
                      id="tokenLogin"
                      name="tokenLogin"
                      checked={tokenLogin}
                      onChange={(event) => {
                        setTokenLogin(event.target.checked)
                      }}
                    />
                  )}
                  label="Login with token"
                />

                <Button
                  variant="secondary"
                  onClick={() => {
                    window.open(DEV_TOKEN_LINK, '_blank').focus()
                  }}
                >
                  Get token
                </Button>
              </Stack>
            </Card>
          )}

          <Button
            variant="primary"
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

export const LocalLogin = ({ children, overwriteLogo }) => {
  const [ localUser, setLocalUser ] = useState(Cookies.get(Cookie.USER_ID))
  const [ localToken, setLocalToken ] = useState(Cookies.get(Cookie.BEARER_TOKEN))

  const isLoggedIn = localUser || localToken
  const isLocal = [ Environment.LOCAL, Environment.GEMS ].includes(getEnvironment())
  const isForcedEnabled = window?.__env?.ENABLE_LOCAL_LOGIN === 'true'
  const isLocalLoginShown = (isLocal || isForcedEnabled) && !isLoggedIn

  const handleLogin = ({ userId, token }) => {
    setLocalUser(userId)
    setLocalToken(token)
  }

  return isLocalLoginShown ? <LoginPage overwriteLogo={overwriteLogo} onLogin={handleLogin} /> : children
}

// overwrites the default login page logo
LocalLogin.propTypes = { overwriteLogo: PropTypes.node }
