import { ArrowDropDownSharp, ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { add, format, setYear } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDayPicker, useNavigation } from 'react-day-picker'
import { IconButton } from '../../button'
import { Dropdown } from '../Dropdown/Dropdown'

// Styles
import classes from './Caption.module.scss'

export const Caption = ({ displayMonth, onSelect }) => {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false)
  const { goToMonth } = useNavigation()

  const {
    onMonthChange,
    handleGoToMonth,
    selected,
    mode,
    locale
  } = useDayPicker()

  const isRange = mode === 'range'

  useEffect(() => {
    if (handleGoToMonth) {
      goToMonth(handleGoToMonth)
    }

    // goToMonth is changed during run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ handleGoToMonth ])

  const handlePreviousClick = () => {
    handleMonthChange(add(displayMonth, { months: -1 }))
  }

  const handleNextClick = () => {
    handleMonthChange(add(displayMonth, { months: 1 }))
  }

  const handleMonthChange = (newMonth) => {
    goToMonth(newMonth)
    onMonthChange?.(newMonth)
  }

  const handleDropdownClick = (year, months) => {
    const targetDate = new Date(year, months, 1, 0, 0, 0, 0)
    const selectedDateFrom = selected?.from

    goToMonth(targetDate)

    !isRange && onSelect(setYear(selectedDateFrom, year))
  }

  return (
    <div className={`${classes.caption} ${isDropdownOpen ? classes.open : ''}`}>
      <Dropdown onClick={handleDropdownClick} setDropdown={setIsDropdownOpen}>
        <Box
          className={classes.captionDate}
          sx={({ palette, typography }) => ({
            color: palette.text.secondary,
            ...typography.body2
          })}
        >
          {format(displayMonth, 'LLLL', { locale })}
          {' '}
          {format(displayMonth, 'y', { locale })}

          <ArrowDropDownSharp />
        </Box>
      </Dropdown>

      {/* hidden on open */}
      <div className={classes.captionNavButtons}>
        <IconButton color="secondary" onClick={handlePreviousClick}>
          <ChevronLeftOutlined />
        </IconButton>

        <IconButton color="secondary" onClick={handleNextClick}>
          <ChevronRightOutlined />
        </IconButton>
      </div>
    </div>
  )
}
