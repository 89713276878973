import { Chip as MuiChip } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

/**
 * Generic Chip with Alice styling.
 *
 * https://mui.com/material-ui/api/chip/
 */
export const Chip = forwardRef((
  {
    variant = 'filled',
    state = 'none',
    isHovered = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const getChipColorTheme = (hover) => {
    switch (state) {
      case 'none':
        return hover ? 'grey.40' : 'common.black'
      case 'success':
        return hover ? 'green.70' : 'green.80'
      case 'info':
        return hover ? 'blue.70' : 'blue.80'
      case 'warning':
        return hover ? 'yellow.70' : 'yellow.80'
      case 'error':
        return hover ? 'red.70' : 'red.80'
      default:
        return 'grey.10'
    }
  }

  const styles = {
    filled: {
      color: state === 'none' ? 'common.white' : 'common.black',
      backgroundColor: getChipColorTheme(),
      '&:hover': { backgroundColor: getChipColorTheme(isHovered), color: 'white' }
    },
    outlined: {
      color: state === 'none' ? 'black' : getChipColorTheme(),
      backgroundColor: 'white',
      border: `1px solid ${getChipColorTheme()}`
    }
  }

  return (
    <MuiChip
      data-testid="Chip"
      ref={ref}
      variant={variant}
      sx={mergeSxProps(
        styles[variant],
        sx
      )}
      {...otherProps}
    />
  )
})

Chip.propTypes = {
  /** Chip label */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  /** Variant of the chip */
  variant: PropTypes.oneOf([ 'filled', 'outlined' ]),
  /** State of chip that effects its color fill */
  state: PropTypes.oneOf([ 'success', 'info', 'warning', 'error', 'none' ]),
  /** If true hover state is set to true */
  isHovered: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
