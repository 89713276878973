import React from "react";
import FilterComponent from "./Filter";
import SearchComponent from "./SearchComponent";
import { SortFilterConatianer } from "../styledComponent";
import SortComponent from "./Sort";

const Filters = ({ searchProps, filterProps, sortProps, windowSize = {} }) => {
  return (
    <>
      <SearchComponent {...searchProps} />
      <SortFilterConatianer windowsize={windowSize}>
        <FilterComponent {...filterProps} windowSize={windowSize} />
        <SortComponent {...sortProps} windowSize={windowSize} />
      </SortFilterConatianer>
    </>
  );
};

export default Filters;
