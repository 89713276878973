import React from "react";

const RemoveCardIcon = (props) => {
  const color = props.color || "#000000";
  return (
    <span>
      <svg
        width="32px"
        height="24px"
        viewBox="0 0 30 24"
        className="remove-icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <title>{t("icons.remove")}</title> */}
        <g
          id="IconBtn/Remove/normal"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M20.488,3.515 C15.8028959,-1.16572855 8.21075254,-1.16336394 3.52856502,3.5202821 C-1.1536225,8.20392814 -1.1536225,15.7960719 3.52856502,20.4797179 C8.21075254,25.1633639 15.8028959,25.1657285 20.488,20.485 C25.1662006,15.7955797 25.1662006,8.20442031 20.488,3.515 Z M16.709,15.279 C17.0634566,15.6738001 17.0478778,16.2767006 16.6735047,16.6526679 C16.2991316,17.0286352 15.6963027,17.0467758 15.3,16.694 L12.19,13.584 C12.0918665,13.4871652 11.9341335,13.4871652 11.836,13.584 L8.727,16.694 C8.33221902,17.0748644 7.70678098,17.0748644 7.312,16.694 C7.12422296,16.5064317 7.01871334,16.2519092 7.01871334,15.9865 C7.01871334,15.7210908 7.12422296,15.4665683 7.312,15.279 L10.422,12.17 C10.4689604,12.1232689 10.4953619,12.0597501 10.4953619,11.9935 C10.4953619,11.9272499 10.4689604,11.8637311 10.422,11.817 L7.312,8.707 C6.95329286,8.31254292 6.96714213,7.70603308 7.34348038,7.32836031 C7.71981862,6.95068753 8.32627563,6.93469141 8.722,7.292 L11.831,10.402 C11.8779021,10.4490209 11.9415864,10.4754469 12.008,10.4754469 C12.0744136,10.4754469 12.1380979,10.4490209 12.185,10.402 L15.295,7.292 C15.6857415,6.9015347 16.3190347,6.90175856 16.7095,7.29250001 C17.0999653,7.68324147 17.0997414,8.31653468 16.709,8.707 L13.599,11.817 C13.5017373,11.9145674 13.5017373,12.0724326 13.599,12.17 L16.709,15.279 Z"
            id="Shape"
            fill={color}
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </span>
  );
};
export default RemoveCardIcon;
