export const Entitlement = {
  SHARED_SERVICE_ADMIN: 'SHARED_SERVICE_ADMIN',
  CORE_CONFIGURATION: 'CORE_CONFIGURATION',
  CONNECTOR_CONFIGURATION: 'CONNECTOR_CONFIGURATION',
  ADMIN_ROLE_ASSIGNMENT: 'ADMIN_ROLE_ASSIGNMENT',
  AUDIT_LOG: 'AUDIT_LOG',
  BILLING: 'BILLING',
  FUNCTIONAL_ADMINISTRATION: 'FUNCTIONAL_ADMINISTRATION',
  ACCESS_CLIENT_READ: 'ReadOnlyAccessClientConfiguration',
  ACCESS_CLIENT_READ_WRITE: 'ReadWriteAccessClientConfiguration',
  ANNOUNCEMENT_READ: 'AnnouncementsReadAccess',
  ANNOUNCEMENT_READ_WRITE: 'AnnouncementsReadWriteAccess'
}

export const Entitlements = Object.values(Entitlement)
