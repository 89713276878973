import DragHandleIcon from '@mui/icons-material/DragHandle'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { IconWrapper } from '../../icon'
import { Chip } from '../chip/Chip'

export const RoleCriticality = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
}

const criticalityToState = (criticality) => {
  switch (criticality) {
    case RoleCriticality.HIGH:
      return 'error'
    case RoleCriticality.MEDIUM:
      return 'warning'
    case RoleCriticality.LOW:
    default:
      return 'info'
  }
}

const CriticalityIcon = ({ criticality }) => {
  switch (criticality) {
    case RoleCriticality.HIGH:
      return <KeyboardDoubleArrowUpIcon />
    case RoleCriticality.MEDIUM:
      return <DragHandleIcon />
    case RoleCriticality.LOW:
    default:
      return <KeyboardDoubleArrowDownIcon />
  }
}

/**
 * A chip that specifically indicates the criticality of a role.
 */
export const RoleCriticalityChip = forwardRef(({ criticality, sx }, ref) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Chip
      ref={ref}
      variant="filled"
      size="small"
      state={criticalityToState(criticality)}
      label={(
        <Typography color="text.primary" fontSize="14px" lineHeight="20px">
          {t(`role.criticality.${criticality}`)}
        </Typography>
      )}
      icon={(
        <IconWrapper
          color="text.primary"
          size="16px"
        >
          <CriticalityIcon criticality={criticality} />
        </IconWrapper>
      )}
      sx={mergeSxProps(
        {
          gap: 1,
          px: 1,
          borderRadius: '4px',
          color: 'text.primary',
          '.MuiChip-icon': { mx: 0 },
          '.MuiSvgIcon-root': { m: 0, color: 'inherit' },
          '.MuiChip-label': { p: 0 }
        },
        sx
      )}
    />
  )
})

RoleCriticality.propTypes = {
  /** Criticality of the chip */
  criticality: PropTypes.oneOf(Object.values(RoleCriticality)).isRequired,
  sx: PropTypes.any
}
