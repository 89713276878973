export class FileExporter {
  static download(data, {
    fileName = 'unnamed',
    mimeType = 'application/octet-stream'
  }) {
    let blob

    if (typeof data === 'string') {
      blob = new Blob([ data ], { type: mimeType })
    } else if (data instanceof Blob) {
      blob = data
    } else if (data instanceof ArrayBuffer) {
      blob = new Blob([ data ], { type: mimeType })
    } else if (Array.isArray(data) || typeof data === 'object') {
      blob = new Blob([ JSON.stringify(data, null, 2) ], { type: mimeType })
    } else {
      throw new Error('Unsupported data type')
    }

    const url = URL.createObjectURL(blob)

    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = fileName
    anchor.click()

    URL.revokeObjectURL(url)
  }
}
