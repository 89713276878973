import { Badge, TextField } from "@alice/component_library";
import Popups from "@components/CustomPopups/Popups";
import CustomDatePicker from "@components/DatePicker";
import DisabledByDefaultSharpIcon from "@mui/icons-material/DisabledByDefaultSharp";
import StatusBar from "./Content";
import Dropdown from "./ReviewDropdown";
import { DECISION_CONSTANT } from "../utils";

const ReviewPopup = ({
  data,
  setComment,
  comment,
  reason,
  setReason,
  subHeader,
  menuItems,
  reviewType,
  validFrom,
  validTo,
  setValidFrom,
  setValidTo,
  t,
}) => {
  const REASON_CHARACTER_LIMIT = 400;

  const { defaultValidityType } = data;

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const textFieldBox = () => (
    <>
      <TextField
        label={
          reviewType !== DECISION_CONSTANT.CONFIRMED
            ? t("please-insert-reason")
            : t("reason-if-interested")
        }
        maxLength={REASON_CHARACTER_LIMIT}
        error={
          comment && comment.length === Number(REASON_CHARACTER_LIMIT)
            ? true
            : false
        }
        InputLabelProps={{
          shrink: true,
        }}
        value={comment}
        rows={2}
        multiline
        variant="filled"
        inputProps={{
          maxLength: REASON_CHARACTER_LIMIT,
        }}
        onChange={(e) =>
          setComment(
            e.target.value.trim().length > 0
              ? e.target.value
              : e.target.value.trim()
          )
        }
        onBlur={(e) => setComment(e.target.value.trim())}
        fullWidth={true}
      />
      <div className="input-info">
        <span>
          {reviewType !== DECISION_CONSTANT.CONFIRMED
            ? t("min-20-chars-requeired-info")
            : comment
            ? t("min-20-chars-requeired-info")
            : ""}
        </span>
        <span>
          {comment.trim().length} / {REASON_CHARACTER_LIMIT}
        </span>
      </div>
    </>
  );
  const selectedAssignmentHeader = (
    <div>
      {data?.assignments?.length > 1 ? (
        <>{t("selected-assignments")}</>
      ) : (
        <>{t("selected-assignment")}</>
      )}
      <Badge filled style={{ backgroundColor: "#0078D6", marginLeft: "5px" }}>
        {data?.assignments?.length}
      </Badge>
    </div>
  );
  const newData = {
    state: data?.state,
    icon: (
      <DisabledByDefaultSharpIcon
        fontSize="large"
        sx={{ borderRadius: "20px" }}
      />
    ),
    header_description: subHeader,
    reason: t("reason"),
    selected_assignments: selectedAssignmentHeader,
    content:
      data?.assignments?.length > 0 ? (
        <StatusBar data={data?.assignments} reviewType={reviewType} />
      ) : null,
    dropdown:
      reviewType !== DECISION_CONSTANT.DELETED ? (
        <> {textFieldBox()} </>
      ) : (
        <Dropdown
          dropdown_label={t("please-select")}
          menuItems={menuItems}
          reason={reason}
          handleChange={handleChange}
        />
      ),
    selectedreason: reason === "other" ? <>{textFieldBox()}</> : <></>,
    validityHeader:
      reviewType === DECISION_CONSTANT.MODIFIED ? "Validity" : null,
    validity: (
      <>
        {reviewType === "MODIFIED" && (
          <div className="validity-holder">
            <CustomDatePicker
              id="validFrom"
              disablePast={true}
              value={validFrom}
              inputLabel={t("valid_from")}
              selectedDate={(date) => {
                setValidFrom(new Date(date));
              }}
              isDisabled={defaultValidityType === "FIXED"}
              preselectedDate={validFrom}
              slotPropsTextField={{
                onKeyDown: (e) => {
                  e.preventDefault();
                },
              }}
            />
            <CustomDatePicker
              id="validTo"
              disablePast={true}
              value={validTo}
              inputLabel={t("valid-to")}
              selectedDate={(date) => setValidTo(new Date(date))}
              preselectedDate={validTo}
              helperText={
                new Date(validTo) <= new Date() ||
                new Date(validTo).getTime() <= new Date(validFrom).getTime()
                  ? t("enter-valid-to")
                  : ""
              }
              error={
                new Date(validTo) <= new Date() ||
                new Date(validTo).getTime() <= new Date(validFrom).getTime()
              }
              slotPropsTextField={{
                onKeyDown: (e) => {
                  e.preventDefault();
                },
              }}
              isDisabled={defaultValidityType === "FIXED"}
            />
          </div>
        )}
      </>
    ),
  };

  return <Popups data={newData} />;
};
export default ReviewPopup;
