import { Box, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getFineGranularUserCommunity } from '../../../../util'
import { Tooltip } from '../../../message'

export const UserCardCommunityChip = ({
  communities = [],
  isSelected,
  isSmall,
  sx
}) => {
  const { t } = useTranslation('componentLibrary')

  const fineCommunity = communities?.length ? getFineGranularUserCommunity(communities) : null

  return fineCommunity
    ? (
      <Tooltip
        title={t(`userCommunity.${fineCommunity}`)}
        sx={{
          position: 'absolute',
          top: isSmall ? 16 : 24,
          right: 14,
          ...sx
        }}
      >
        <Box>
          <Chip
            sx={
              [
                { fontSize: 'typography.body2' },
                !isSelected && {
                  backgroundColor: 'black',
                  color: 'white'
                },
                isSelected && {
                  backgroundColor: 'white',
                  color: 'black'
                }
              ]
            }
            size="small"
            variant="filled"
            label={t(`userCommunity.shorthand.${fineCommunity}`)}
          />
        </Box>
      </Tooltip>
    ) : null
}
