import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Link } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AttachmentsContainer } from "./styledComponents";
const Attachments = ({ additionalInfo, templateFile }) => {
  const { t } = useTranslation();
  return (
    <AttachmentsContainer>
      <Typography noWrap variant="subtitle2" className="subtitle-header">
        {t("attachments")}
      </Typography>

      <Typography noWrap variant="caption" className="caption-header">
        {t("additional-information")}
      </Typography>
      <Typography variant="body2" className="description">
        {additionalInfo}
      </Typography>

      <div className="template-file-container">
        <Typography noWrap variant="caption" className="caption-header">
          {t("template-file")}
        </Typography>
        <Link
          // href={templateFile}
          underline="none"
          download
          variant="body2"
          className="template-file-link"
        >
          <span className="link-text">{templateFile}</span>
          <ContentCopyIcon fontSize="1rem" />
        </Link>
      </div>
    </AttachmentsContainer>
  );
};

export default Attachments;
