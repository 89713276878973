import React, { useState, useEffect } from "react";
import styled from "styled-components";
import isValid from "date-fns/isValid";

import DateTextField from "./DateField/CustomIput";
import { DatePickerDialog } from "@alice/component_library";

const StyledDatePickerContainer = styled("div")({
  position: "relative",
  "& .react-datepicker-popper": {
    display: "none",
  },
  "& .react-datepicker-wrapper": {
    width: "100%",
  },
});

const StyledTextField = styled(DateTextField)({
  width: "100%",
  "& .MuiInputAdornment-root": {
    cursor: "pointer",
  },
});

const StyledWrapper = styled("div")({
  "& .MuiSvgIcon-root ": {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: "5px",
  },
});

const CustomDatePicker = ({
  disablePast,
  preselectedDate,
  isDisabled,
  inputLabel,
  selectedDate,
  value,
  id,
  onChangeOfInput,
  addtimeFrame,
  isErrorHandler,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(preselectedDate || "");
  const [dateFieldValue, setDateFieldValue] = useState(preselectedDate || "");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (addtimeFrame === false) {
      isErrorHandler(false);
      setIsError(false);
      setErrorMessage("");
      setInputValue(new Date());
    }
  }, [addtimeFrame]);

  useEffect(() => {
    setIsError(false);
    setErrorMessage("");
  }, [id]);

  useEffect(() => {
    if (!props.error) {
      setDateFieldValue(inputValue);
    }
  }, [inputValue, props]);

  useEffect(() => {
    setInputValue(preselectedDate || "");
  }, [preselectedDate]);

  const changeValue = (date) => {
    if (date !== null && typeof date !== "undefined") {
      isErrorHandler(false);
      setIsError(false);
      setErrorMessage("");
      selectedDate(new Date(date));
      setInputValue(new Date(date));
    }
  };

  const handleDateChange = async (date) => {
    await selectedDate(new Date(date));
    if (date) {
      if (isValid(date)) {
        setInputValue(new Date(date));
        setIsError(false);
        setErrorMessage("");
        isErrorHandler(false);
      } else {
        setIsError(true);
        setErrorMessage("Enter valid date");
        isErrorHandler(true);
      }
    }
  };

  const handleCalenderClick = () => {
    setIsOpen(true);
  };

  const dateRangePickerValue = inputValue || preselectedDate;

  return (
    <div>
      <DatePickerDialog
        key={`${id}_${new Date().getTime()}`}
        onClose={() => setIsOpen(false)}
        onChange={() => {
          // should be here as a placeholder
        }}
        onConfirm={(values) => changeValue(values ? values.from : null)}
        open={isOpen}
        parentFrom={
          isValid(dateRangePickerValue) ? dateRangePickerValue : new Date()
        }
        disabledMatcher={
          disablePast
            ? {
                before: new Date(),
              }
            : {}
        }
      />
      <StyledWrapper>
        <StyledDatePickerContainer>
          <StyledTextField
            error={props.error || isError}
            errorMessage={
              props.helperText.length ? props.helperText : errorMessage
            }
            isDisabled={isDisabled}
            dateString={dateFieldValue}
            label={inputLabel}
            handleCalenderClick={() => handleCalenderClick()}
            onUpdate={(value) => handleDateChange(value)}
            isInvalid={isError}
          />
        </StyledDatePickerContainer>
      </StyledWrapper>
    </div>
  );
};
export default React.memo(CustomDatePicker);
