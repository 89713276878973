import CloseIcon from '@mui/icons-material/Close'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Stack, Typography } from '@mui/material'
import { IconButton } from '../../button'

export const DropzoneFiles = ({
  files = [],
  error,
  onDelete = (fileName) => {}
}) => {
  const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(2)

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: 2,
        flexWrap: 'wrap'
      }}
    >
      {files.map(({ file }, index) => (
        <Stack
          key={`${file.name}${index}`}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: 'common.white',
            width: '250px',
            padding: '6px 8px 6px 16px',
            borderRadius: '2px',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.10)'
          }}
        >
          <Stack sx={{ display: 'grid', overflow: 'hidden' }}>
            <Typography
              variant="subtitles"
              sx={{
                lineHeight: '20px',
                fontWeight: 700,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {file.name}
            </Typography>

            {!error
              ? (
                <Typography
                  variant="caption"
                  lineHeight="16px"
                  color="text.disabled"
                >
                  {bytesToMegaBytes(file.size)}
                  MB
                </Typography>
              )
              : (
                <Stack
                  alignItems="center"
                  gap={0.5}
                  direction="row"
                  color="error.main"
                >
                  <ReportProblemIcon sx={{ width: '12px', height: '12px' }} />

                  <Typography
                    variant="caption"
                    lineHeight="16px"
                  >
                    {error}
                  </Typography>
                </Stack>
              )}
          </Stack>

          <IconButton
            size="small"
            color="primary.main"
            onClick={() => onDelete(file.name)}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  )
}
