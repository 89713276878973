import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { NavigationMenuListItem } from './NavigationMenuListItem'
import { NavigationMenuListTitle } from './NavigationMenuListTitle'
import { mergeSxProps } from '../../../../util'

export const NavigationMenuList = ({
  title,
  items,
  isMobile,
  selectedItem,
  onItemClick,
  sx = {} || []
}) => {
  const [ isExpanded, setIsExpanded ] = useState(true)

  return (
    <Box
      component="nav"
      aria-label={title}
      sx={mergeSxProps([
        {
          '--collapsedWidth': '74px',
          '--expandedWidth': '265px',
          '--textWidth': 'calc(var(--expandedWidth) - var(--collapsedWidth))',
          display: 'inline-block',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '4px',
          width: !isMobile ? 'var(--expandedWidth)' : '100%',
          overflow: 'hidden',
          boxSizing: 'content-box',
          flexShrink: 0
        },
        !isMobile && {
          maxWidth: isExpanded ? 'var(--expandedWidth)' : 'var(--collapsedWidth)',
          transition: 'max-width 250ms'
        }
      ], sx)}
    >
      {title && (
        <NavigationMenuListTitle
          title={title}
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      )}

      {items.map((item) => (
        <NavigationMenuListItem
          key={item.id}
          item={item}
          isExpanded={isExpanded}
          isSelected={selectedItem === item.id}
          onClick={() => onItemClick({ item, itemId: item.id })}
        />
      ))}
    </Box>
  )
}

NavigationMenuList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired
  })).isRequired,
  selectedItem: PropTypes.string,
  isMobile: PropTypes.bool,
  onItemClick: PropTypes.func
}
