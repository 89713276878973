import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import DevicesIcon from "@mui/icons-material/Devices";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { AliceIcon, Chip, AliceIconType } from "@alice/component_library";
import { RoleTypeChipContainer } from "./StyledComponents";

function RoleTypeChip({
  type,
  state = "info",
  variant = "filled",
  sx = {},
  ...otherProps
}) {
  const { t } = useTranslation();
  let label = "";
  let icon = <></>;

  switch (type) {
    case "ENTITLEMENT":
      label = "application";
      icon = <AliceIcon iconType={AliceIconType.UIApps} size={16} />;
      break;
    case "BUSINESS":
      label = "business";
      icon = <BusinessIcon />;
      break;
    case "PRODUCT":
      label = "product";
      icon = <DevicesIcon />;
      break;
    case "JOBTITLE":
      label = "job-title";
      icon = <BadgeOutlinedIcon />;
      break;

    default:
      label = "product";
      icon = <DevicesIcon />;
      break;
  }

  return (
    <Chip
      label={
        <RoleTypeChipContainer>
          <span className="role-type-icon">{icon}</span>
          <Typography sx={{ padding: "2px 4px 2px 8px" }} variant="body2">
            {t(label)}
          </Typography>
        </RoleTypeChipContainer>
      }
      state={state}
      variant={variant}
      sx={{
        height: "24px",
        "& .MuiChip-label": { padding: "0 4px" },
        "&:hover": { color: "rgba(0, 0, 0, 0.87)" },
        ...sx,
      }}
      {...otherProps}
    />
  );
}

export default RoleTypeChip;
