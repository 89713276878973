import { Box, Chip, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getGroupTypeAsName, mergeSxProps } from '../../../util'
import { Tooltip } from '../../message'

/**
 * This component displays the groupType of a user or organisation in a chip.
 */
export const FocusChip = forwardRef((
  {
    groupType,
    showTooltip = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  if (groupType === undefined) return null

  const groupTypeString = groupType.toString()?.toUpperCase()
  const title = t(`groupType.${groupType}`)
  let label = getGroupTypeAsName(groupType)?.short

  if (groupTypeString === 'S') {
    label = (
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80px'
        }}
      >
        <span style={{ color: 'white' }}>MBAG</span>
        <span style={{ color: 'black' }}>DTAG</span>
      </Stack>
    )
  }

  const content = (
    <Chip
      label={label}
      size="small"
      sx={mergeSxProps(
        [
          { display: 'flex', px: '4px' },
          groupTypeString === '0' && {
            backgroundColor: 'black',
            color: 'white',
            ':hover': { backgroundColor: 'black' }
          },
          groupTypeString === '1' && {
            backgroundColor: 'grey.85',
            color: 'black',
            ':hover': { backgroundColor: 'grey.85' }
          },
          groupTypeString === 'S' && (({ palette }) => ({ backgroundImage: `linear-gradient(120deg, black 52%, ${palette.grey[85]} 48%)` }))
        ],
        sx
      )}
    />
  )

  return (
    <Box
      data-testid="FocusChip"
      ref={ref}
      {...otherProps}
    >
      { showTooltip ? (
        <Tooltip
          title={title}
          placement="bottom"
          arrow
        >
          {content}
        </Tooltip>
      ) : content }
    </Box>
  )
})

FocusChip.propTypes = {
  /**
   * The groupType to display.
   *
   * 0=MBAG, 1=DTAG, S=Both
   */
  groupType: PropTypes.oneOf([ '0', '1', 'S', 0, 1 ]).isRequired,
  /** Display tooltip */
  showTooltip: PropTypes.bool,
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
