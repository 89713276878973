import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge, UserContextPopover } from "@alice/component_library";
import EnvelopeIcon from "@Icons/homePage/envelope.svg";
import { updateGreeting } from "../utils";
import {
  GreetingContainer,
  GreetingHeader,
  GreetingUserAvtar,
  HeaderContainer,
  OverviewCard,
  OverviewContainer,
  Typography,
  Stack,
} from "../styledComponents";

const Header = ({
  userData, //userData
  hasReviewAccess, //check if user has review access
  hasTasksAccess, // check if user has task access
  openTasks, // open pending tasks count
  reviewTasks, // open reviews count
  expiringRoles, // expiring roles count
}) => {
  const { id, groupType, completeName, givenname } = userData;
  const { t } = useTranslation();

  const openToDos =
    (hasTasksAccess ? openTasks : 0) +
    (hasReviewAccess ? reviewTasks : 0) +
    expiringRoles;

  const overviewData = [
    {
      count: openTasks,
      label: t("tasks"),
      id: "tasks",
      link: "/access/tasks",
      show: hasTasksAccess,
    },
    {
      count: reviewTasks,
      label: t("reviews"),
      id: "reviews",
      link: "/access/accessReview",
      show: hasReviewAccess,
    },
    {
      count: expiringRoles,
      label: t("expiring-roles"),
      id: "expiring-roles",
      link: "/access/profile?category=myRoles",
      show: true,
    },
  ];

  return (
    <HeaderContainer>
      <GreetingContainer>
        <UserContextPopover id={id}>
          <GreetingUserAvtar
            userId={id}
            userName={completeName}
            size="big"
            showGroupType
            groupType={groupType}
            showImage={true}
          />
        </UserContextPopover>
        <div>
          <GreetingHeader>
            {t(`${updateGreeting()}`)} {givenname}!
          </GreetingHeader>
          <Typography variant="body1">{t("home-page-subHeader")}</Typography>
        </div>
      </GreetingContainer>
      <OverviewCard>
        <OverviewContainer>
          <div className="envelop-container">
            <EnvelopeIcon />
            {!!openToDos && (
              <Badge filled className="todo-badge">
                {openToDos}
              </Badge>
            )}
          </div>
          <div className="open-todo-details">
            <Typography noWrap variant="body1" className="overview-text">
              {t("overview")}
            </Typography>
            <Typography noWrap variant="h4" className="todo-text">
              {t("open-to-dos-count", {
                count: openToDos,
              })}
            </Typography>
            {!!openToDos && (
              <Stack className="overview-detail">
                {overviewData.map((ele) => (
                  <Fragment key={ele.id}>
                    {ele.show && (
                      <Stack className="detailed-container">
                        <Badge className="badge-count" filled>
                          {ele.count}
                        </Badge>
                        <Link to={ele.link}>
                          <Typography noWrap variant="body1">
                            {ele.label}
                          </Typography>
                        </Link>
                      </Stack>
                    )}
                  </Fragment>
                ))}
              </Stack>
            )}
          </div>
        </OverviewContainer>
      </OverviewCard>
    </HeaderContainer>
  );
};

export default Header;
