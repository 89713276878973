import React, { useState } from "react";
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogContentText,
  StyledDialogContentHeader,
  StyledDialogAction,
  CloseButton,
} from "../../styledComponents";
import { Badge, TextField } from "@alice/component_library";
import { PrimaryButton, SecondaryButton } from "@src/sharedComponents/button";
import CloseIcon from "@mui/icons-material/Close";
import SelectedTaskTable from "./SelectedTaskRow";

const REASON_CHARACTER_LIMIT = 400;

function ApproveRejectBox({
  open,
  handleClose,
  t,
  SelectedTaskData,
  approve,
  handleReject,
  handleApprove,
  handleRemove,
}) {
  const [rejectReason, setRejectReason] = useState("");

  return (
    <StyledDialog
      open={open}
      onClose={() => {
        setRejectReason("");
        handleClose();
      }}
      fullWidth={true}
      maxWidth="lg"
    >
      <StyledDialogTitle>
        {approve ? t("approve") : t("reject")}
        <CloseButton
          onClick={() => {
            setRejectReason("");
            handleClose();
          }}
        >
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>
          {approve
            ? SelectedTaskData.length === 1
              ? t("confirm-approve-question")
              : t("approve-confirmation-all-task")
            : SelectedTaskData.length === 1
            ? t("confirm-reject-question")
            : t("confirm-reject-all-question")}
        </StyledDialogContentText>

        <>
          <StyledDialogContentHeader>
            {!approve ? t("reject-reason") : t("requestApproval_reason")}
          </StyledDialogContentHeader>
          <TextField
            placeholder={
              !approve ? t("insert-reject-reason") : t("reason-if-interested")
            }
            maxLength={REASON_CHARACTER_LIMIT}
            error={
              rejectReason &&
              rejectReason.length === Number(REASON_CHARACTER_LIMIT)
                ? true
                : false
            }
            value={rejectReason}
            rows={2}
            multiline
            variant="filled"
            inputProps={{
              maxLength: REASON_CHARACTER_LIMIT,
            }}
            onChange={(e) =>
              setRejectReason(
                e.target.value.trim().length > 0
                  ? e.target.value
                  : e.target.value.trim()
              )
            }
            onBlur={(e) => setRejectReason(e.target.value.trim())}
            fullWidth={true}
          />
          <div className="input-info">
            <span>{!approve ? t("min-20-chars-requeired-info") : ""}</span>
            <span>
              {rejectReason.trim().length} / {REASON_CHARACTER_LIMIT}
            </span>
          </div>
        </>

        <StyledDialogContentHeader>
          {SelectedTaskData.length === 1
            ? t("selected-task")
            : t("selected-tasks")}
          <Badge filled>{SelectedTaskData.length}</Badge>
        </StyledDialogContentHeader>
        {SelectedTaskData.length > 0 &&
          SelectedTaskData.map((task) => (
            <React.Fragment key={task.id}>
              <SelectedTaskTable
                rows={task}
                handleRemove={handleRemove}
                hideRemove={SelectedTaskData.length === 1}
                key={task.id}
              />
            </React.Fragment>
          ))}
      </StyledDialogContent>
      <StyledDialogAction>
        <SecondaryButton
          onButtonClick={() => {
            setRejectReason("");
            handleClose();
          }}
          label={t("dismiss")}
        />
        {approve ? (
          <PrimaryButton
            label={
              SelectedTaskData.length === 1
                ? t("apporve-count-task", { count: SelectedTaskData.length })
                : t("apporve-count-tasks", { count: SelectedTaskData.length })
            }
            onButtonClick={() => {
              handleApprove(rejectReason);
              setRejectReason("");
              handleClose();
            }}
          />
        ) : (
          <PrimaryButton
            disabled={rejectReason.trim().length < 20}
            label={
              SelectedTaskData.length === 1
                ? t("reject-count-task", { count: SelectedTaskData.length })
                : t("reject-count-tasks", { count: SelectedTaskData.length })
            }
            onButtonClick={() => {
              handleReject(rejectReason);
              setRejectReason("");
              handleClose();
            }}
          />
        )}
      </StyledDialogAction>
    </StyledDialog>
  );
}

export default ApproveRejectBox;
