/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "@src/App.css";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useTranslation } from "react-i18next";

import OutsideClickHandler from "react-outside-click-handler";
import { SearchBar, Table } from "@alice/component_library";

import { hasData, isValid } from "@utils/Validator";
import NoResults from "@components/Noresults";

const MainContainer = styled("div")(({ theme }) => ({
  "& .label": {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "14px",
    color: "#797979",
  },
  "& .formControl": {
    width: "100%",
  },
  "& .LangformControl": {
    width: "100%",
  },
  "& .table": {
    minWidth: 650,
  },
  "& .headerAdjust": {
    backgroundColor: "black",
  },
  "& .selectEmpty": {
    marginTop: "16px",
  },
  "& .leftbutton": {
    marginBottom: "1rem",
    border: "1px solid black",
    borderRadius: 0,
    textTransform: "none",
    fontSize: 16,
    minWidth: 100,
    cursor: "pointer",
  },
  "& .inputClass": {
    color: "rgba(0, 0, 0, 0.87) ",
    padding: "16px 11px 10px ",
    paddingBottom: "13px ",
  },
}));

export default function Scopes(params) {
  const { required, disabled } = params;
  const { t } = useTranslation();
  const [Inputvalue, setValue] = React.useState("");
  const rowsPerPage = 10;
  const page = 0;
  const [open, setOpen] = React.useState(false);
  const [custValue, setCustValue] = React.useState("");
  const custScopeList = params.addRoledata.customScopes;
  const [tablehide, setTable] = React.useState(true);
  const [tableData, setTableData] = React.useState(custScopeList);
  const [dropdownData, setDropdownData] = React.useState([]);

  const cust_scope_header = [
    {
      Header: t("custScopeHeader.cust_scope"),
      key: t("custScopeHeader.cust_scope"),
    },
    {
      Header: t("custScopeHeader.description"),
      key: t("custScopeHeader.description"),
    },
  ];

  useEffect(() => {
    (async () => {
      await setTableData(custScopeList);
      let RefData = custScopeList;
      const data = RefData.map((item) => ({
        label: item.name,
        value: item.name,
      }));

      await setDropdownData(data);
    })();
  }, [custScopeList]);

  const handleChange = async (e) => {
    await setValue(e);

    const data = custScopeList.map(({ name }) => ({
      label: name,
      value: name,
    }));
    let tableData = [...custScopeList];
    if (e !== "") {
      tableData = tableData.filter(
        ({ name }) => name?.toLowerCase()?.indexOf(e.toLowerCase()) > -1
      );
    }
    await setTableData(tableData);
    await setDropdownData(data);
  };

  // useEffect(() => {
  //   if (params.resetFlag === true) {
  //     setCustValue("");
  //   }
  // }, [params.resetFlag]);

  useEffect(() => {
    if (hasData(params.preSelect) && isValid(params.preSelect?.custScope)) {
      setCustValue(params.preSelect?.custScope);
      params.updateCustScope(params.preSelect?.custScope);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setTable(true);
    setTableData(custScopeList);
  };
  const handleClose = () => {
    setOpen(false);
    setTable(false);
  };

  const handleSelect = async (e) => {
    setOpen(true);

    await setCustValue(e.target.value);
    setOpen(false);
  };

  const selectRow = (data) => {
    // setSelectedRow(index);
    const row = tableData[data[0]?._rowId] || {};
    let value = row.name;

    setCustValue(value);
    params.updateCustScope(value);
    setOpen(false);
    setTable(false);
  };

  const handleOut = (e) => {
    if (e && Object.keys(e.target.dataset).length === 0) {
      setOpen(false);
    }
  };

  const tableCustData = () => {
    let finalArr = [];
    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let temp = [];
        temp.push(d.name);
        temp.push(d.description);

        finalArr.push(temp);
      });
    }
    return finalArr;
  };

  const custTable = tableCustData();

  return (
    <OutsideClickHandler onOutsideClick={handleOut}>
      <MainContainer className="form-group">
        <FormControl
          variant="filled"
          className={
            params.prefLang && params.prefLang === true
              ? "LangformControl"
              : "formControl"
          }
          disabled={disabled}
        >
          {open === false ? (
            <React.Fragment>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={custValue}
                required={required}
                onOpen={handleOpen}
                onClose={handleClose}
                open={open}
                onChange={handleSelect}
                displayEmpty
                className="selectEmpty"
                classes={{ filled: "inputClass" }}
              >
                {custValue && custValue !== "" ? null : (
                  <MenuItem value="">
                    {" "}
                    <span>{t("user_pleaseSelect")}</span>
                  </MenuItem>
                )}
                {dropdownData.map((x, index) => (
                  <MenuItem key={index} value={x.value} defaultValue={x.value}>
                    {x.label}
                  </MenuItem>
                ))}
              </Select>
              {custValue !== "" ||
              (params.selectedFullScopes &&
                params.selectedFullScopes.length > 0)
                ? null
                : params.accessFlag && (
                    <React.Fragment>
                      <br />

                      <span style={{ fontSize: "14px", color: "#000000" }}>
                        {t("please-select-option")}
                      </span>
                    </React.Fragment>
                  )}
            </React.Fragment>
          ) : null}

          {open === true ? (
            <React.Fragment>
              <div style={{ marginTop: "20px", width: "100%" }}>
                <FormControl
                  variant="filled"
                  style={{ display: "flex", flexGrow: 1 }}
                >
                  <SearchBar
                    label={t("name")}
                    variant="filled"
                    onSearch={(e) => handleChange(e)}
                    onClear={() => handleChange("")}
                    fullWidth
                  />
                </FormControl>

                {tablehide === true ? (
                  <React.Fragment>
                    {hasData(custTable) ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Table
                          columns={cust_scope_header}
                          data={custTable}
                          itemsPerPage={10}
                          onSelect={(row, index) =>
                            selectRow(row, page * rowsPerPage + index)
                          }
                          infoText={{
                            single: t("record-shown"),
                            multiple: t("records-shown"),
                          }}
                        />
                      </Grid>
                    ) : (
                      <React.Fragment>
                        <NoResults keysearch={Inputvalue} searchFlag={true} />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </FormControl>
      </MainContainer>
    </OutsideClickHandler>
  );
}
