import { Card, Skeleton, Grid, Stack } from "./CommonSkeletons";

const RoleCardPanel = () => {
  return (
    <Grid container spacing={2} sx={{ marginTop: "20px" }}>
      {[...Array(12)].map((ele) => (
        <Grid item xs={12} md={6} xxl={4} key={ele}>
          <Card sx={{ padding: "20px" }}>
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="text" width={150} />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="text" width={150} />
              </Stack>
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default RoleCardPanel;
