import { styled } from "@mui/system";

export const CustomCardContainer = styled("div")(({ ...props }) => ({
  width: "-webkit-fill-available",
  background: "#f4f4f4",
  "& .remove-icon-svg": {
    display: "flex",
    justifyContent: "center",
    width: "25px",
    height: "24px",
    alignItems: "center",
  },
}));

export const CustomCardDetailHeader = styled("div")(({ theme, ...props }) => ({
  [theme.breakpoints.down("sm")]: {
    marginBottom: "25px",
  },
  display: "flex",
  flexWrap: "wrap",
  width: "-webkit-fill-available",
  "& .custom-head-left": {
    display: "flex",
    columnGap: "3px",
    "& .custom-header-svg-left": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "baseline",
      },
    },
    "& .custom-header-value-left": {
      display: "flex",
      flexWrap: "wrap",
      wordBreak: "break-all",
      margin: "0 3px",
      "& span": {
        margin: "0 3px",
      },
    },
  },
  "& .custom-header-svg-operation-icon": {
    display: "flex",
  },
  "& .custom-head-right": {
    display: "flex",
    columnGap: "3px",
    "& .custom-header-svg-right": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "baseline",
      },
    },
    "& .custom-header-value-right": {
      margin: "0 4px",
      wordBreak: "break-all",
      "& span": {
        marginLeft: "2px",
      },
    },
  },
  "& .remove-icon-svg-": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
}));

export const CustomCardDetailDescription = styled("div")(({ ...props }) => ({
  display: "flex",
  wordBreak: "break-all",
  color: "rgba(0, 0, 0, 0.60)",
  flexWrap: "wrap",
  flexDirection: "row",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  "& strong": {
    margin: "0 5px",
  },
}));

export const CustomCardApplicationDetails = styled("div")(
  ({ theme, ...props }) => ({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    "& .hidden": {
      visibility: "hidden",
    },
    "& .custom-appHead": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .custom-Button": {
      display: "flex",
      flex: "1",
      justifyContent: "flex-end",
      "& button": {
        height: "31px",
      },
    },
    "& .custom-smsIcon": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: "400",
      "& span": {
        marginLeft: "5px",
      },
    },
    "& .custom-applicationReasonText": {
      wordBreak: "break-word",
    },
  })
);
