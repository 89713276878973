import React from "react";
import { useSelector } from "react-redux";
import { AliceIcon, AliceIconType } from "@alice/component_library";
import { GetRecentRequestActiviy } from "@actions";
import NoRequestsIcon from "@Icons/homePage/noRequestActivity.svg";
import TaskAndRequest from "./sharedComponents/TaskAndRequest";
import useFetchData from "./sharedComponents/useFetchData";
import useFormatAndChunkData from "./sharedComponents/useFormatAndChunkData";

const RecentRequest = ({ userData = {} }) => {
  const userId = userData.id;
  const { loading, recentRequestActivity, isLoaded } = useSelector(
    (state) => state.overview.recentRequestActivity
  );

  const fetchData = useFetchData(
    GetRecentRequestActiviy,
    userId,
    isLoaded,
    loading
  );
  const data = useFormatAndChunkData(recentRequestActivity);

  return (
    <TaskAndRequest
      heading="requests"
      subHeading="recent-request-activity"
      noDataText="no-request-activity"
      linkButtonText="go-to-requests"
      headerIcon={
        <AliceIcon
          iconType={AliceIconType.REQUEST_OVERVIEW}
          size={16}
          color="#00000099"
        />
      }
      noDataIcon={NoRequestsIcon}
      activityData={data}
      dataFromApi={recentRequestActivity}
      fetchData={fetchData}
      loading={loading}
      isLoaded={isLoaded}
      isRequest={true}
    />
  );
};

export default RecentRequest;
