import PropTypes from 'prop-types'
import { Avatar } from '@mui/material'
import { Groups } from '@mui/icons-material'
import { Skeleton } from '../../../../skeleton'
import { getContrastColor, stringToColor } from '../../../../../util'

export const UserGroupAvatar = ({
  name,
  size = 's',
  isLoading,
}) => {
  const avatarSize = (size === 's' || size === 'xs') ? 40 : 64

  return (
    isLoading
      ? (
        <Skeleton
          variant="circular"
          sx={{
            height: avatarSize,
            width: avatarSize
          }}
        />
      )
      : (
        <Avatar
          sx={{
            backgroundColor: stringToColor(name),
            border: '1px solid white',
            color: getContrastColor(stringToColor(name)),
            height: avatarSize,
            width: avatarSize,
            fontSize: (size === 'xs' || size === 's') ? '24px' : '40px'
          }}
        >
          <Groups fontSize="inherit" />
        </Avatar>
      )
  )
}

UserGroupAvatar.propTypes = {
  /** Defines contrast background & icon colors */
  name: PropTypes.string.isRequired,
  /** Size of the avatar */
  size: PropTypes.oneOf([ 's', 'm', 'l' ]),
  /** Loading state */
  isLoading: PropTypes.bool,
}
