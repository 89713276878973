import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { IdentStatusAdministratorAssignmentDialog } from '../assignment/IdentStatusAdministratorAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'id'
const COLUMNS = [ 'marketCountryCode', 'marketName', 'community' ]

const convertIdToApiObject = (id) => {
  const splitId = id.split(';')

  return {
    marketCountryCode: splitId[0],
    community: splitId[1]
  }
}

export const IdentStatusAdminAccordion = ({
  userId,
  isAssigned,
  administratedMarkets,
  onChange = (newRole) => {}
}) => {
  administratedMarkets = administratedMarkets.map((market) => ({
    ...market,
    id: `${market.marketCountryCode};${market.community}`
  }))

  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedMarkets, setSelectedMarkets ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'identstatusadmin',
        body: { identStatusAdminPrivileges: selectedMarkets.map(convertIdToApiObject) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.identStatusAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedMarkets = administratedMarkets
          .filter((administrated) => selectedMarkets
            .map(convertIdToApiObject)
            .every(({ marketCountryCode, community }) => !(administrated.community === community && administrated.marketCountryCode === marketCountryCode)))

        const identStatusAdmin = { admin: false }

        if (newAdministratedMarkets.length) {
          identStatusAdmin.admin = true
          identStatusAdmin.administratedMarkets = newAdministratedMarkets
        }

        handleDeselectAll()
        onChange({ identStatusAdmin })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'marketCountryCode':
        return t('general.countryCode')
      case 'marketName':
        return t('general.market')
      case 'community':
        return t('general.community')
      case 'description':
        return t('general.description')
      default:
        return key
    }
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedMarkets(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="IdentStatusAdminAccordion"
        title={t('adminRoles.identStatusAdmin')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {administratedMarkets?.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={administratedMarkets}
            titleRenderer={titleRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                isDeleteDisabled={!selectedMarkets.length}
                isDeleteLoading={isLoadingDelete}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <IdentStatusAdministratorAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

IdentStatusAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedMarkets: PropTypes.array,
  onChange: PropTypes.func
}
