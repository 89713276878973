import React, { useState } from "react";
import { FileUploadOutlinedIcon } from "@mui/icons-material";
import { useNotification, Dropzone } from "@alice/component_library";
import { DropZoneContainer } from "@src/pages/newRequest/styledComponents";

const AttachmentDropzone = () => {
  const [files, setFiles] = useState(null);
  const [fileError, setFileError] = useState(null);

  const { notify } = useNotification();

  const handleFileChosen = async (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = () => {
        notify({
          severity: "error",
          message: "Error occured",
        });

        reject();
      };
      fileReader.readAsText(file);
    });

  const readAllFiles = async (files) => {
    const results = await Promise.all(
      files.map(async (file) => {
        const fileContents = await handleFileChosen(file);

        return { file, content: fileContents };
      })
    );

    return results;
  };

  const handleFileChange = (files) => {
    if (files === null) {
      setFiles(null);
      setFileError(null);

      return;
    }

    readAllFiles(files).then((res) => setFiles(res));
  };

  return (
    <DropZoneContainer text="Drop PDF file(s) or">
      <div className="svg-container">
        <FileUploadOutlinedIcon />
      </div>
      <Dropzone files={files} onChange={handleFileChange} error={fileError} />
    </DropZoneContainer>
  );
};

export default AttachmentDropzone;
