import React, { useState } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";

import urlConfig from "@services/urlConfig";
import FileBulkImport from "@components/FileBulkImport";
import Loader from "@components/Loader";
import { PrimaryButton } from "@components/button";
import CompletedRequest from "@Icons/active_access.svg";
import RejectedRequest from "@Icons/deactive.svg";

const LoaderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  minHeight: "100px",
  background: "#e5f1fb",
  padding: "24px",
  margin: "12px 0",
});

const LoaderStatusContainer = styled("div")({
  paddingLeft: "24px",
  display: "flex",
  flexDirection: "column",
  wordBreak: "break-word",
});

const StatusIconContatiner = styled("div")({
  display: "flex",
  flexBasis: "8%",
  maxWidth: "80px",
  justifyContent: "center",
  "& span": {
    height: "40px",
    width: "40px",
    backgroundSize: "36px",
  },
});

const StatusHeaderContatiner = styled("div")({
  fontWeight: 700,
  fontSize: "16px",
  wordBreak: "break-word",
});

const TemplateHeader = styled("div")(({ theme }) => ({
  "&.container": {
    minHeight: "40vh",
    [theme.breakpoints.down("md")]: {
      padding: "0 20px",
    },
  },
  "& .Select_user": {
    width: "100%",
  },
  "& .Select_user .MuiTypography-root.MuiTypography-h5": {
    fontFamily: "MB Corpo S Text",
    fontSize: "18px",
    fontWeight: "700",
    fontStyle: "normal",
    letterspacing: "0px",
    textAlign: "left",
    lineHeight: "28px",
  },
  "& .Select_user_title .MuiTypography-root.MuiTypography-body1": {
    opacity: "1",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "0px",
    textAlign: "left",
    lineHeight: "24px",
  },
}));

const BulkAssign = (props) => {
  const { assign, submitHandler = () => {} } = props;

  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const [errMesage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [importId, setImportId] = useState("");

  const bulkImport = async (file, headLine, encoding) => {
    submitHandler(true);
    await setFileName(file.name);
    await setStatus("loading");

    let url = assign ? urlConfig.bulkAssign : urlConfig.bulkRemoval;
    url += `?withHeadline=${headLine}&fileEncoding=${encoding}`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file?.name);
    const config = { headers: { "content-type": "multipart/form-data" } };
    axios
      .post(url, formData, config)
      .then((res) => {
        setStatus("success");
        setImportId(res?.data?.importId);
      })
      .catch((err) => {
        setStatus("error");
        setErrorMessage(err?.response?.data?.message);
      });
  };

  const getLoading = () => (
    <>
      <LoaderContainer>
        <div>
          <Loader inline minHeight="60px" />
        </div>
        <LoaderStatusContainer>
          {t("importing-CSV-file")} {fileName}
        </LoaderStatusContainer>
      </LoaderContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {t("loader-wait")}
      </div>
    </>
  );

  const getSuccess = () => (
    <LoaderContainer style={{ backgroundColor: "#e8f2e9" }}>
      <StatusIconContatiner>
        <span className="active-icon-task">
          <CompletedRequest />
        </span>
      </StatusIconContatiner>
      <LoaderStatusContainer>
        <StatusHeaderContatiner>
          {t("successful-import")}
        </StatusHeaderContatiner>
        <div style={{ wordBreak: "break-word" }}>
          {t("success-import-p1")} {`"${fileName}"`} {t("success-import-p2")}{" "}
          <br />
          {t("success-import-p3")} <b>{importId ? importId : null}</b>.
        </div>
      </LoaderStatusContainer>
    </LoaderContainer>
  );

  const resetHandler = () => {
    setFileName("");
    setErrorMessage("");
    setStatus("");
  };

  const getResetButton = () => (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <PrimaryButton
        label={t("import-new-file")}
        onButtonClick={() => resetHandler()}
      />
    </div>
  );

  const getFail = () => (
    <LoaderContainer style={{ backgroundColor: "#fbe8e8" }}>
      <StatusIconContatiner>
        <span className="task-deactive-icon">
          <RejectedRequest />
        </span>
      </StatusIconContatiner>
      <LoaderStatusContainer>
        <StatusHeaderContatiner>
          {t("error-import")} {fileName}
        </StatusHeaderContatiner>
        <div style={{ wordBreak: "break-word" }}>{errMesage}</div>
      </LoaderStatusContainer>
    </LoaderContainer>
  );

  const getTemplate = () => {
    let template = "";

    switch (status) {
      case "loading":
        template = getLoading();
        break;
      case "success":
        template = getSuccess();
        break;
      case "error":
        template = getFail();
        break;
      default:
        return (
          <TemplateHeader className={assign ? "container" : {}}>
            <Grid className="Select_user Select_user_title Select_user_Focus">
              <FileBulkImport isAdd={assign} submitHandler={bulkImport} />
            </Grid>
          </TemplateHeader>
        );
    }
    return (
      <TemplateHeader className={assign ? "container" : {}}>
        <div style={{ fontWeight: 700, fontSize: "16px" }}>{t("import")}</div>
        {template}
        {assign && ["success", "error"].includes(status)
          ? getResetButton()
          : null}
      </TemplateHeader>
    );
  };

  return getTemplate();
};
export default BulkAssign;
