import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import StickyBox from "react-sticky-box";

import { Box, useMediaQuery } from "@mui/material";
import { ContentContainer } from "./styledComponents";
import { hasData } from "@utils/Validator";
import ProfilePageHeader from "./Components/ProfileHeader";

import {
  GetOrgInfoIndividualUser,
  GetAvailableOrgInfolUser,
  GetOrgInfoIndividualWorkingUser,
  FetchLoggedInUserData,
} from "@actions";
import { Layout, useUser } from "@alice/component_library";
import { useWidth } from "@utils/hooks";
import {
  ProfileLayout,
  ProfileMenuCard,
  ProfileMenuCardContent,
  ProfileMenuCardTitle,
  ProfileResponsiveMenu,
  ProfileResponsiveBox,
} from "./styledComponents";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import LeftDoubleArrowIcon from "@Icons/taskpage/taskMenu/LeftDoubleArrow.svg";
import ProfileSideMenu from "./Components/SideMenu";
import ProfileTabPage from "./Components/Tabs";
import { isValidParam, isValidTabName } from "./helper";

const ProfilePage = () => {
  const targetRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { tabName } = useParams();
  const paramValue = queryParams.get("category");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.user.userProfile);
  const { data: userData = {} } = userProfile;
  const { communities = [] } = userData;

  const showColleagues = communities.some((i) =>
    ["DEALER_EMPLOYEE", "SUPPLIER_EMPLOYEE"].includes(i)
  );

  const [selectedTab, setSelectedTab] = useState(
    isValidParam(paramValue, showColleagues)
      ? paramValue
      : isValidTabName(tabName, showColleagues)
  );

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const widthPanel = useWidth();
  const isResponsive = useMediaQuery("(max-width: 850px)");

  const homeOrg = useSelector((state) => state.role.organizationInfo);

  const admin = useSelector((state) => state.user.adminRoles);

  const { user } = useUser();
  const userUtils = user;

  const { id: userId } = userUtils || {};
  const adminRoles = admin?.all;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update state based on whether the target element is in viewport
        setIsVisible(entry.intersectionRatio > 0);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0, // When 0% of target is visible
      }
    );

    // Start observing the target element
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Clean up the observer when component unmounts
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []); // Only run once on component mount

  useEffect(() => {
    if (!hasData(userData)) dispatch(FetchLoggedInUserData(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    fetchAllDetails();
  }, [userData]);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedTab, isResponsive]);

  useEffect(() => {
    const currentPath = window.location.pathname
      .split("/")
      .filter((segment) => segment !== tabName)
      .join("/");
    const newUrl = `${currentPath}?category=${selectedTab}`;

    navigate(newUrl, { replace: true });
  }, [navigate, selectedTab, tabName]);

  const fetchAllDetails = () => {
    if (hasData(userData) && !hasData(homeOrg)) {
      const { homeOrgId, workingOrgId } = userData;
      if (homeOrgId) dispatch(GetOrgInfoIndividualUser(userId, homeOrgId));
      if (workingOrgId)
        dispatch(GetOrgInfoIndividualWorkingUser(userId, workingOrgId));
      dispatch(GetAvailableOrgInfolUser(userId));
    }
  };
  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
      case "xxl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
      }
    }
  }, [widthPanel]);

  const setCollapseMenu = (value) => {
    setIsCollapsed(value);
  };

  return (
    <Layout>
      <ProfileLayout>
        <ProfilePageHeader />
        <Box sx={{ width: 1, marginBottom: "50px" }}>
          <Box display="flex" columnGap={"24px"}>
            {isResponsive ? (
              <></>
            ) : (
              <Box sx={{ width: isCollapsed ? "75px" : "400px" }}>
                <StickyBox offsetTop={90} offsetBottom={20}>
                  <ProfileMenuCard iscollapsed={isCollapsed}>
                    <ProfileMenuCardContent iscollapsed={isCollapsed}>
                      {!isCollapsed && (
                        <ProfileMenuCardTitle>
                          {t("categories")}
                        </ProfileMenuCardTitle>
                      )}
                      <LeftDoubleArrowIcon
                        onClick={() => setCollapseMenu(!isCollapsed)}
                        style={{
                          transform: isCollapsed ? "rotate(180deg)" : "",
                          cursor: "pointer",
                        }}
                      />
                    </ProfileMenuCardContent>
                    <ProfileSideMenu
                      isCollapsed={isCollapsed}
                      t={t}
                      selectedValue={selectedTab}
                      actionHandler={(value) => setSelectedTab(value)}
                      windowSize={windowSize}
                      preSelectedValue={selectedTab}
                      showColleagues={showColleagues}
                      adminRoles={adminRoles}
                    />
                  </ProfileMenuCard>
                </StickyBox>
              </Box>
            )}
            <Box sx={{ width: "100%" }}>
              <ContentContainer className="content">
                {isResponsive ? (
                  <>
                    <ProfileResponsiveBox ref={targetRef}>
                      <Box gridColumn="span 12" sx={{ paddingTop: "14px" }}>
                        <ProfileResponsiveMenu
                          className={isOpen ? `menu-active` : ""}
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <div className="menu-icon-text-container">
                            <div className="menu-icon">
                              <MenuIcon />
                            </div>
                            <div className="text-content">
                              {t(`profileTabHeading.${selectedTab}`)}
                            </div>
                          </div>
                          <div className="arrow-icon-container">
                            <KeyboardDoubleArrowDownIcon />
                          </div>
                        </ProfileResponsiveMenu>
                        <div
                          className={
                            isOpen
                              ? `side-bar-open profileMenu-contaitner`
                              : "profileMenu-contaitner"
                          }
                        >
                          <ProfileSideMenu
                            isCollapsed={isCollapsed}
                            t={t}
                            selectedValue={selectedTab}
                            actionHandler={(value) => setSelectedTab(value)}
                            windowSize={windowSize}
                            preSelectedValue={selectedTab}
                            showColleagues={showColleagues}
                            adminRoles={adminRoles}
                          />
                        </div>
                      </Box>
                    </ProfileResponsiveBox>
                  </>
                ) : (
                  <></>
                )}
                <ProfileTabPage
                  tabName={selectedTab}
                  isResponsive={isResponsive}
                  isCollapsed={isCollapsed}
                />
              </ContentContainer>
            </Box>
          </Box>
        </Box>
      </ProfileLayout>
    </Layout>
  );
};

export default ProfilePage;
