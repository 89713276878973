import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { TextField } from '../../../form'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { GlobalCommunityAdministratorAssignmentDialog } from '../assignment/GlobalCommunityAdministratorAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'community'
const COLUMNS = [ 'community', 'description' ]

const getAdministratedCommunitiesChanges = (administratedCommunities, updatedAdministratedCommunities) => {
  if (!administratedCommunities || !updatedAdministratedCommunities) return []

  const changes = []

  administratedCommunities.forEach(({
    community,
    description
  }, index) => {
    const {
      community: actualCommunity,
      description: actualDescription
    } = updatedAdministratedCommunities[index] || {}

    if (community === actualCommunity && description !== actualDescription) {
      changes.push({
        communityScope: actualCommunity,
        description: actualDescription
      })
    }
  })

  return changes
}

export const GlobalCommunityAdminAccordion = ({
  userId,
  isAssigned,
  administratedCommunities,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ currentAdministratedCommunities, setCurrentAdministratedCommunities ] = useState(administratedCommunities)
  const [ selectedCommunities, setSelectedCommunities ] = useState([])

  useEffect(
    () => setCurrentAdministratedCommunities([ ...administratedCommunities ]),
    [ administratedCommunities ]
  )

  const updateDescription = (market, community, value) => {
    const newCurrentAdministratedCommunities = structuredClone(currentAdministratedCommunities)

    for (let i = 0; i < newCurrentAdministratedCommunities.length; i++) {
      if (newCurrentAdministratedCommunities[i].community === community) {
        newCurrentAdministratedCommunities[i] = {
          community: newCurrentAdministratedCommunities[i].community,
          description: value
        }
      }
    }

    setCurrentAdministratedCommunities(newCurrentAdministratedCommunities)
  }

  const tableColumns = useMemo(() => ([
    {
      key: 'community',
      Header: t('general.community'),
      Cell: ({ value }) => (
        <Typography>{t(`communities.${value}`)}</Typography>
      )
    },
    {
      key: 'description',
      Header: t('general.description'),
      Cell: ({ value: _value, row }) => {
        const [ value, setValue ] = useState(_value || '')

        const handleClick = (e) => e.stopPropagation()
        const handleChange = (e) => setValue(e.target.value)

        const handleBlur = (e) => {
          updateDescription(
            row.values.col0,
            e.target.value
          )
        }

        return (
          <TextField
            size="small"
            variant="outlined"
            style={{
              marginTop: '5px',
              backgroundColor: '#fff'
            }}
            placeholder={t('adminRoles.addDescription')}
            fullWidth
            hiddenLabel
            value={value}
            onClick={handleClick}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )
      }
    },
    {
      key: 'search',
      Header: '',
      style: { width: '0' },
      Cell: ({ value }) => (
        <Box sx={{ width: '0 !important', overflow: 'hidden' }}>
          {value}
          ,
        </Box>
      )
    }
  ]), [ t, updateDescription ])

  const tableData = useMemo(() => administratedCommunities.map((community) => [
    community.community,
    community.description,
    t(`communities.${community.community}`)
  ]), [ t, administratedCommunities ])

  const {
    isLoading: isLoadingSave,
    execute: executeSave
  } = useApi(
    UsersService.updateDescriptionOfAdminCommunityScopes,
    {
      config: {
        id: userId,
        body: { adminCommunityScopes: getAdministratedCommunitiesChanges(administratedCommunities, currentAdministratedCommunities) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.update', { adminRole: t('adminRoles.globalCommunityAdmin') })
    }
  )

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'globalcommunityadmin',
        body: { globalCommunityAdminPrivileges: selectedCommunities.map((community) => ({ community })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.globalCommunityAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedCommunities = administratedCommunities
          .filter((administrated) => selectedCommunities
            .every((selected) => administrated.community !== selected))

        const globalCommunityAdmin = { admin: false }

        if (newAdministratedCommunities.length) {
          globalCommunityAdmin.admin = true
          globalCommunityAdmin.administratedCommunities = newAdministratedCommunities
        }

        handleDeselectAll()
        onChange({ globalCommunityAdmin })
      }
    }
  )

  const DescriptionTextField = ({ row }) => {
    const [ value, setValue ] = useState(row.description || '')

    const handleClick = (e) => e.stopPropagation()
    const handleChange = (e) => setValue(e.target.value)

    const handleBlur = (e) => {
      updateDescription(
        row.marketCountryCode,
        row.community,
        e.target.value
      )
    }

    return (
      <TextField
        size="small"
        variant="outlined"
        style={{
          marginTop: '5px',
          backgroundColor: '#fff'
        }}
        placeholder={t('adminRoles.addDescription')}
        fullWidth
        hiddenLabel
        value={value}
        onClick={handleClick}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    )
  }

  const titleRenderer = (key) => {
    switch (key) {
      case 'community':
        return t('general.community')
      case 'description':
        return t('general.description')
      default:
        return key
    }
  }

  const entryRenderer = (key, row) => {
    switch (key) {
      case 'description':
        return <DescriptionTextField row={row} />
      default:
        return row[key]
    }
  }

  const handleSave = () => executeSave()
  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedCommunities(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.community))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.community), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="GlobalCommunityAdminAccordion"
        title={t('adminRoles.globalCommunityAdmin')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {currentAdministratedCommunities?.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={currentAdministratedCommunities}
            titleRenderer={titleRenderer}
            entryRenderer={entryRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                onSave={handleSave}
                isSaveLoading={isLoadingSave}
                isDeleteLoading={isLoadingDelete}
                isDeleteDisabled={!selectedCommunities.length}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <GlobalCommunityAdministratorAssignmentDialog
        userId={userId}
        administratedCommunities={administratedCommunities}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

GlobalCommunityAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedCommunities: PropTypes.array,
  onChange: PropTypes.func
}
