import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const ControlCenterWrapper = styled(Grid)(({ theme }) => ({
  minHeight: "95vh",
  paddingTop: "40px",
  fontFamily: "MB Corpo S Text",
  [theme.breakpoints.down("lg")]: {
    padding: "40px 40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0px",
  },

  "& .MuiTypography-body1": {
    fontFamily: "MB Corpo S Text",
  },
}));

export const SeperateDotContainer = styled("span")({
  // padding: "0 12px",
  display: "flex",
  alignItems: "center",
});

export const SeperateDot = styled("span")({
  backgroundColor: "#969696",
  borderRadius: "50%",
  height: "5px",
  width: "5px",
  display: "inline-block",
});

export const ProfileNameContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.down("xs")]: {
    justifyContent: "center",
  },
}));

export const ProfileNameText = styled("span")({
  fontSize: "48px",
  fontWeight: "400",
  color: "#333333",
  paddingRight: "10px",
  fontFamily: "MB Corpo A Title Cond",
  display: "inline-flex",
  lineHeight: "44px",
});

export const MarginRightSpan = styled("span")({
  marginRight: "10px",
});

export const ProfileSubHeaderContainer = styled("div")(({ theme }) => ({
  padding: "20px 0 5px",
  display: "inline-flex",
  alignItems: "baseline",
  fontSize: "16px",
  "& .user-Info-tags": {
    display: "flex",
    marginLeft: "5px",
    alignItems: "center",
    flexFlow: "wrap",
    "& span": {
      marginRight: "5px",
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: "5px 0",
  },
  [theme.breakpoints.down("xs")]: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "-20px",
  },
}));

export const SpanIconContainer = styled("span")(({ theme }) => ({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "18px",
  color: (props) => props.color,
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  "& svg": { marginLeft: "5px", fontSize: "18px" },
  cursor: "pointer",
  [theme.breakpoints.down("xs")]: {
    marginRight: "1px",
  },
}));

export const RoleSpan = styled("span")(({ theme }) => ({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "18px",
  color: "#0078D6",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  "& svg": { marginLeft: "5px", fontSize: "18px" },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    width: "90% !important",
  },
}));

export const RoleLabelSpan = styled("span")({
  display: "flex",
  alignItems: "center",
});

export const SpanDownArrow = styled("span")({
  width: 0,
  height: 0,
  borderTop: "8px solid #0078D6",
  borderLeft: "5px  solid transparent",
  borderRight: "5px solid transparent",
  margin: "0 8px",
});

export const ProfileDetailsWrapper = styled("div")({
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "20px",
  flexWrap: "wrap",
});

export const DetailsContainer = styled("div")({
  marginTop: "10px",
  display: "flex",
  flexDirection: "column",
});

export const ProfileHeaderMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "center",
  paddingBottom: "20px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
    paddingLeft: "20px",
  },
}));

export const LoadMoreContainer = styled("div")({
  display: "flex !important",
  flexDirection: "column",
  alignItems: "center",
});

export const SpanCount = styled("span")({
  display: "flex !important",
  justifyContent: "center",
});

export const PopUpTitleStyle = styled("div")({
  paddingTop: "20px",
  fontFamily: "MB Corpo A Title Condensed",
  color: "#000000",
});

export const PopUpContentStyle = styled("div")({
  fontSize: "14px",
  lineHeight: "20px",
  color: "#000000",
});

export const BulkRemoveButtonContainer = styled("div")(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "10px 0",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    padding: "10px 20px",
  },
  "& .bulk-upload-header": {
    fontSize: "26px",
    fontWeight: "400",
    color: "#333333",
    fontFamily: "MB Corpo A Title Cond",
    lineHeight: "32px",
  },
}));

export const RemoveButtonContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "10px 0",
  },
  [theme.breakpoints.down("xs")]: {
    margin: "10px 10px",
  },
}));

export const SearchBarContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

export const TabbleContainer = styled("div")(({ theme }) => ({
  "& > div > div": {
    display: "none",
  },
  "& table": {
    padding: "40px 0",
    border: "none !important",
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

export const AccessRightsDiv = styled("div")(({ theme }) => ({
  fontSize: "26px",
  fontWeight: "400",
  color: "#333333",
  fontFamily: "MB Corpo A Title Cond",
  lineHeight: "32px",
  paddingTop: "20px",
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

export const MainContainerCC = styled(Grid)({
  paddingTop: "40px",
  fontFamily: "MB Corpo S Text",
  "& .MuiTypography-body1": {
    fontFamily: "MB Corpo S Text",
  },
});

export const ContainerCC = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    margin: "0px 20px 0 20px",
  },
}));

export const Usersubcontainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

export const StyledPaperContainer = styled(Grid)(({ theme }) => ({
  boxShadow: "none",
  "& .tabsRoot": {
    borderBottom: "1px solid #E8E8E8",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
    },
  },
  "& .selectedTab": {
    color: "#333333 !important",
  },
  "& .tabItem": {
    "&.Mui-selected .MuiTab-wrapper": {
      color: "#0078D6",
    },
    "& .MuiTab-wrapper": {
      opacity: "1",
      color: "#767676",
      fontFamily: "MB Corpo S Text",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0.4px",
      textAlign: "center",
      lineHeight: "24px",
      textTransform: "capitalize",
    },
    minWidth: "unset",
  },
}));

export const RolesContainer = styled("div")(() => ({
  marginTop: "26px",
  position: "relative",
}));
