import { styled } from "@mui/system";
import { Divider, Stack, Typography, Grid } from "@mui/material";
import { UserAvatar, Card } from "@alice/component_library";

export const HomePageContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    margin: "20px 20px 0px",
  },
  flexGrow: 1,
}));

export const HomeRequestWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "24px",
  marginBottom: "24px",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "2fr 1fr",
  },
}));

export const AccessReviewWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  overflow: "hidden",
  borderBottom: "1px solid rgba(219, 219, 219, 1)",
  "@media (max-width: 1024px)": {
    overflowX: "auto",
  },
}));

export const AccessReviewGraphContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100%",
});

export const DateConatiner = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
}));

export const DateMonthLabel = styled("span")(({ theme }) => ({
  fontFamily: "MB Corpo S Text",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
}));

export const DateMonthContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 7px",
}));

export const DateDatesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "13px",
  textAlign: "center",
  fontFamily: '"MB Corpo S Text"',
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.5px",
}));

export const Styledspan = styled("span")(({ theme, isFirst, isSpan12 }) => ({
  fontWeight: isFirst ? 700 : 400,
  width: isSpan12 ? "3%" : "12%",
}));

export const StyledDivider = styled(Divider)({
  height: "40px",
});

export const DataGraphContainer = styled("div")({
  position: "relative",
  minHeight: "150px",
  marginTop: "6px",
  width: "100%",
});

export const BlueIndicatorForCurrent = styled("div")({
  backgroundColor: "#E6F5FF",
  height: "100%",
  position: "absolute",
  width: "25px",
  borderTop: "2px solid #0078D6",
});

export const GreyLineIndicator = styled("div")({
  display: "flex",
});

export const DataGraphDataContainer = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  left: "6px",
  top: "54px",
  "& .item-container": {
    display: "flex",
    alignItems: "flex-end",
    borderBoottom: "1px solid rgba(219, 219, 219, 1)",
    borderTop: "1px solid rgba(219, 219, 219, 1)",
    height: "64px",
  },
  "& .title-container": {
    width: "230px",
    paddingRight: "14px",
    "& p": {
      margin: 0,
    },
    "& .title": {
      overflow: "hidden",
      color: "#0078D6",
      textOverflow: "ellipsis",
      fontFamily: '"MB Corpo S Text"',
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20px",
      "& a": {
        textDecoration: "none",
        color: "#0078D6",
        whiteSpace: "nowrap",
        textoverflow: "ellipsis",
        width: "100%",
      },
    },
    "& .title-id": {
      overflow: "hidden",
      color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
      textOverflow: "ellipsis",
      fontFamily: '"MB Corpo S Text"',
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      whiteSpace: "nowrap",
      textoverflow: "ellipsis",
      width: "100%",
    },
  },
});

export const InnerGraphContainer = styled("div")({
  left: "4px",
  position: "relative",
  height: "64px",
  borderTop: "1px solid rgba(219, 219, 219, 1)",
  display: "flex",
  alignItems: "flex-end",
  paddingBottom: "10px",
});

export const AccesReviewDeadlineWrapper = styled("div")({
  border: "1px solid rgba(219, 219, 219, 1)",
  padding: "24px",
  marginBottom: "24px",
  borderRadius: "4px",
  "& .table": {
    marginTop: "18px",
    border: 0,
    borderBottom: "1px solid #e0e0e0",
    borderRadius: 0,
    "& .tableTitle": {
      background: "white",
    },
    "& .tableTitleWrapper": {
      borderColor: "white",
      "& .tableTitle": {
        minHeight: "24px",
        padding: 0,
        color: "rgba(0, 0, 0, 0.60)",
      },
    },
    "& .tableEntryWrapper": {
      borderInline: 0,
    },
    "& .tableRow": {
      "& .tableEntry:nth-of-type(2)": {
        backgroundColor: "rgba(248, 248, 248, 1)",
      },
    },
    "& .tableEntry": {
      overflow: "hidden",
      "& div": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& .percentage": {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
      },
    },
    "& .progress": {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
    },
    //id
    "& .item-container": {
      display: "flex",
      alignItems: "flex-end",
      borderBoottom: "1px solid rgba(219, 219, 219, 1)",
      // borderTop: "1px solid rgba(219, 219, 219, 1)",
      // height: "64px",
    },
    "& .title-container": {
      width: "230px",
      paddingRight: "14px",
      "& p": {
        margin: 0,
      },
      "& .title": {
        overflow: "hidden",
        color: "#0078D6",
        textOverflow: "ellipsis",
        fontFamily: '"MB Corpo S Text"',
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
        "& a": {
          textDecoration: "none",
          color: "#0078D6",
          whiteSpace: "nowrap",
          textoverflow: "ellipsis",
          width: "100%",
        },
      },
      "& .title-id": {
        overflow: "hidden",
        color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
        textOverflow: "ellipsis",
        fontFamily: '"MB Corpo S Text"',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        whiteSpace: "nowrap",
        textoverflow: "ellipsis",
        width: "100%",
      },
    },
  },
});

export const AccessReviewHeaderContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& .main-header": {
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.87)",
    textOverflow: "ellipsis",
    fontFamily: '"MB Corpo S Title"',
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
  },
  "& .sub-header": {
    display: "flex",
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.60)",
    textOverflow: "ellipsis",
    fontFamily: '"MB Corpo S Text"',
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    alignItems: "center",
    "& .icon-container": {
      display: "flex",
      marginRight: "8px",
      alignItems: "center",
    },
  },
});

export const AccessReviewFooterContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: "29px",
  "& .count-container": {
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.60)",
    textAlign: "right",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "MB Corpo S Text",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    marginRight: "8px",
  },
});

export const AccessreviewNoDataContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& svg": {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      width: "100%",
    },
  },
}));

export const HeaderContainer = styled((props) => (
  <Stack direction="column" {...props} />
))(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
  },
  marginTop: "58px",
  gap: "26px",
  justifyContent: "space-between",
  margin: "58px 0 48px 0",
}));

export const GreetingContainer = styled((props) => (
  <Stack
    direction="column"
    columnGap="32px"
    alignItems="flex-start"
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
  },
}));

export const GreetingUserAvtar = styled((props) => (
  <UserAvatar
    sizeStyle={{
      height: "112px",
      width: "112px",
      fontSize: "3rem",
      dotSize: "28px",
    }}
    {...props}
  />
))(() => ({}));

export const GreetingHeader = styled((props) => (
  <Typography variant="h1" {...props} />
))(() => ({
  marginBottom: 0,
  fontFamily: "MB Corpo A Text Cond",
}));

export const OverviewCard = styled((props) => <Card {...props} />)(
  ({ theme }) => ({
    padding: "16px",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  })
);

export const OverviewContainer = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "128px 1fr",
  overflow: "hidden",
  alignItems: "center",
  "& .envelop-container": {
    position: "relative",
    "& .todo-badge": {
      position: "absolute",
      top: "46%",
      left: "55%",
      background: "#0078D6 !important",
      padding: "0px 12px !important",
    },
  },
  "& .open-todo-details": {
    minWidth: 0,
  },
  "& .overview-text": {
    color: "#00000099",
  },
  "& .to-text": {
    paddingRight: "20px",
    fontStyle: "MB CORPO S TITLE",
  },
  "& .overview-detail": {
    flexDirection: "row",
    gap: "24px",
    flexWrap: "wrap",
    marginTop: "16px",
  },
  "& .detailed-container": {
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    minWidth: 0,
    "& a": { textDecoration: "none", color: "#0078D6", overflow: "hidden" },
    "& .badge-count": {
      minWidth: "fit-content !important",
      fontFamily: "MB Corpo S Text !important",
      fontSize: "12px !important",
      fontWeight: "700 !important",
      padding: "0px 12px !important",
    },
  },
}));

export const RequestContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  border: "1px solid #DBDBDB",
  borderRadius: "4px",
  padding: "24px",
  rowGap: "24px",
  minHeight: "388px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: "15px",
  },
}));

export const NewRequsetLabel = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  alignItems: "center",
}));

export const NewReqLabel = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "400",
});

export const NewReqHeader = styled(Typography)({
  fontFamily: "MB Corpo S Title",
  fontSize: "26px",
  lineHeight: "32px",
  fontWeight: "400",
});

export const UserDetailStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "2px",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
}));

export const MyselfTypo = styled(Typography)({
  color: "#000000",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "400",
  textAlign: "center",
  width: "100%",
});

export const UserNameTypo = styled(Typography)({
  color: "#000000",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "700",
  whiteSpace: "nowrap",
  wordBreak: "break-word",
  overflow: "hidden",
  textAlign: "center",
  width: "100%",
  textOverflow: "ellipsis",
});
export const UserMailTypo = styled(Typography)({
  color: "#000000",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "400",
  whiteSpace: "nowrap",
  wordBreak: "break-word",
  overflow: "hidden",
  textAlign: "center",
  width: "100%",
  textOverflow: "ellipsis",
  "& a": {
    textDecoration: "none",
    color: "#000000",
  },
});
export const UseridTypo = styled(Typography)({
  color: "#000000",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: "400",
  whiteSpace: "nowrap",
  wordBreak: "break-word",
  overflow: "hidden",
  textAlign: "center",
  width: "100%",
  textOverflow: "ellipsis",
});

export const RequestCardContainer = styled("div")(({ theme }) => ({
  // padding: "42px 18px",
  margin: "0 24px",
  height: "100%",
  display: "grid",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "42px 0px",
  },
}));

export const ReqButtonContiner = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
}));

//tasks section
export const TasksContainer = styled("div")(({ ...props }) => ({
  borderRadius: "4px",
  padding: "24px",
  height: "100%",
  border: "1px solid #DBDBDB",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  marginBottom: !props?.isRequest && "24px",
  "& .grouped-container": {
    overflow: "hidden",
    maxHeight: "304px",
  },
  "& .grouped-item-container": {
    marginBottom: "8px",
  },
  "& .icon-button": {
    marginTop: "24px",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .header-container": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));
export const NoTaskContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& svg": {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      width: "100%",
    },
  },
}));
export const TasksHeading = styled((props) => (
  <Typography variant="body1" {...props} />
))(() => ({
  color: "rgba(0, 0, 0, 0.60)",
}));

export const LatestTaskActivity = styled((props) => (
  <Typography variant="h4" {...props} />
))(() => ({
  marginBottom: "24px",
}));

export const DateHeader = styled((props) => (
  <Typography variant="caption" {...props} />
))(() => ({
  color: "rgba(0, 0, 0, 0.60)",
  marginBottom: "8px",
  paddingLeft: "8px",
}));

export const CardLink = styled((props) => <Card {...props} />)(
  ({ theme, ...props }) => ({
    padding: "8px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: props.isRequest ? "2fr 2fr 2fr 1fr" : "2fr 2fr 1fr",
    marginBottom: "8px",
    height: "40px",
    gap: "16px",
    "& .right-data,.left-data": {
      overflow: "hidden",
      "& svg": {
        minWidth: "25px",
      },
    },
    "& .first-column": {
      display: "flex",
      gap: "4px",
      alignItems: "center",
      width: "100%",
    },
    "& .second-column": {
      display: "flex",
      gap: "4px",
      alignItems: "center",
      width: "100%",
      "& > *:first-of-type": {
        marginLeft: "8px",
        marginRight: "4px",
      },
    },
    "& .right-items": {
      display: "flex",
      marginLeft: "auto",
      "& .user-avatar-status": {
        display: "flex",
        marginRight: "16px",
        gap: "4px",
        // alignItems: "center",
        width: "56px",
        "& .task-chip": {
          padding: "0px",
          marginLeft: "auto",
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
          },
        },
      },
      "& .MuiIconButton-root": {
        padding: 0,
      },
    },
    "& .first-item-container": {
      overflow: "hidden",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "2fr 1fr",
      "& .first-item-container": {
        display: "none",
      },
      "& .left-data": {
        maxWidth: "100%",
        "& .first-column svg:nth-of-type(3)": {
          display: "none",
        },
      },
      "& .right-data": {
        display: "none",
      },
    },
  })
);

export const GroupItemContainer = styled("div")(({ ...props }) => ({
  marginTop: `${props.margin}px`,
  paddingTop: `${props.padding}px`,
}));

export const GroupItemWrapper = styled("div")(({ ...props }) => ({
  display: "grid",
  gridTemplateColumns: props.column,
  gridColumnGap: "24px",
  height: props.isRequest ? "329px" : "auto",
  flexGrow: 1,
}));

export const ExpiringRolesContainer = styled("div")(({ theme }) => ({
  border: "1px solid #DBDBDB",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
  padding: "24px",
}));

export const ExpiringRolesDetails = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
}));

export const ExpiringRolesHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  alignItems: "center",
}));

export const RoleDescriptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "baseline",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const RCardV3sContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
}));

export const ExpRolesChipContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  "& .chip-container": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
}));

export const ExpRolesFooterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  columnGap: "8px",
}));

export const ExpRolesEmptyContaniner = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  "& svg": {
    "& g": {
      display: "contents",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      width: "100%",
    },
  },
}));

export const ExpiringRolesProfileTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
});

export const ExpRolesFooterTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "16px",
});

export const ExpiringRolesTypo = styled(Typography)({
  fontFamily: "MB Corpo S Title",
  fontSize: "26px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "32px",
  "& span.MuiTypography-root": {
    backgroundColor: "red",
  },
});

export const ExpRolesEmptyTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
});

export const RoleDescriptionTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
});

export const StyledSpan = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
});

export const ExpiringCardWrapper = styled("div")(({ theme }) => ({
  marginBottom: "24px",
}));

export { Typography, Stack };
