import { Box } from '@mui/material'
import { Skeleton } from '../../skeleton'
import { mergeSxProps } from '../../../util'

export const IconWrapper = ({
  children,
  color,
  size,
  isLoading,
  sx,
  ...otherProps
}) => (
  <Box
    className="iconWrapper"
    sx={mergeSxProps({
      '--iconSize': `${size}px`,
      display: 'flex',
      color,
      transition: 'color 150ms',
      '.MuiSvgIcon-root, .MuiSkeleton-root': { width: size, height: size }
    }, sx)}
    {...otherProps}
  >
    {isLoading
      ? <Skeleton variant="rounded" />
      : children}
  </Box>
)
