import PropTypes from 'prop-types'
import React, { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { usePermission } from '../../../hooks'
import { Table } from '../../table'
import { convertToOrgTableData, getOrgTableColumnDefinitions, getOrgTableRowId, orgTableDefaultVisibleColumns } from '../OrgUtils'

/**
 * Table implementation specifically suited for organizations.
 * Supports all properties, that the `Table` component also supports
 */
export const OrgTable = forwardRef((
  {
    data = [],
    visibleColumns = orgTableDefaultVisibleColumns,
    multiselect,
    compact,
    tableRef,
    isIDLink,
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')
  const { isAdmin } = usePermission()

  const convertToTableData = useCallback(() => convertToOrgTableData({
    tableData: data,
    compact,
    visibleColumns
  }), [ data, compact, visibleColumns ])

  const getColumns = useCallback(() => getOrgTableColumnDefinitions({
    compact,
    t,
    visibleColumns,
    localUserHasAnAdminRole: isAdmin,
    isIDLink
  }), [ compact, t, visibleColumns, isAdmin, isIDLink ])

  return (
    <Table
      data-testid="OrgTable"
      ref={ref}
      data={convertToTableData()}
      columns={getColumns()}
      multiselect={multiselect}
      showPaginationAtTop={!compact}
      showItemsPerPageDropdownAtTop={!compact}
      getRowId={getOrgTableRowId}
      {...otherProps}
    />
  )
})

OrgTable.propTypes = {
  /** Data (API Organization Model) that should be displayed. */
  data: PropTypes.arrayOf(PropTypes.shape({
    country: PropTypes.string,
    orgForm: PropTypes.string,
    validFromDate: PropTypes.string,
    validToDate: PropTypes.string,
    preferredLanguage: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string),
    externalId: PropTypes.string,
    gssnData: PropTypes.shape({
      updateTimestamp: PropTypes.string,
      allowFrom: PropTypes.string,
      allowTo: PropTypes.string,
      adinDefId: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      brandCodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          outletCode: PropTypes.string,
          outletStatus: PropTypes.string
        })
      ),
      companyForm: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      companyId: PropTypes.string,
      countryDefId: PropTypes.string,
      languageCode: PropTypes.string,
      outletCode: PropTypes.string,
      outletId: PropTypes.string,
      outletStatus: PropTypes.string,
      street1: PropTypes.string
    }),
    id: PropTypes.string,
    isActive: PropTypes.bool,
    isDaimler: PropTypes.bool,
    isUserManagementDisabled: PropTypes.bool,
    location: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string
  })),
  /** Callback, when a row got clicked */
  onRowClick: PropTypes.func,
  /** All visible columns */
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  /** Determines whether multiselect is enabled or not */
  multiselect: PropTypes.bool,
  /** Determine whether radio buttons are shown in when using single select or not */
  showRadiobuttonOnSingleSelect: PropTypes.bool,
  /** Determine whether table is in compact mode or not */
  compact: PropTypes.bool,
  /** A list of all preselected row ids */
  preselectedRowIds: PropTypes.array,
  /** Function that getting called to check whether a row is disabled or not */
  isRowDisabled: PropTypes.func
}
