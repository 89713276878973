import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import { mergeSxProps } from '../../../util'
import defaultLogo from './mbWhiteDefault.png'
import { Skeleton } from '../../skeleton'

/**
 * ## Description
 * The AppLogo component is designed to display an application logo in a customizable card format.<br>
 * This component is well-suited for showcasing logos in various sizes.
 *
 * ### Usage
 *
 * To use the AppLogo component, simply import it and render it with desired props which are
 * fully described in the prop section.
 *
 * ### Notes
 *
 * - The AppLogo component automatically calculates the dominant color of the logo image
 * to provide a complementary background color for the card.
 */
export const AppLogo = forwardRef((
  {
    logo,
    size = 'm',
    sx = [],
    isLoading = false,
    ...otherProps
  },
  ref
) => {
  const [ imageBgColor, setImageBgColor ] = useState('transparent')

  const dataURL = logo ? `data:image/png;base64,${logo}` : defaultLogo

  useEffect(() => {
    // Load the image to an HTMLImageElement
    const image = new Image()

    image.src = dataURL

    image.onload = () => {
      // Create a canvas element to draw the image
      const canvas = document.createElement('canvas')

      canvas.width = image.width
      canvas.height = image.height

      const context = canvas.getContext('2d')

      context.drawImage(image, 0, 0, image.width, image.height)

      // Get the image data
      const imageData = context.getImageData(0, 0, image.width, image.height).data

      // Calculate the dominant color based on the image data (we might need to use more advanced color calculating technique)
      // Here we just take the average color of the first pixel.
      const dominantColor = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`

      setImageBgColor(dominantColor)
    }
  }, [ dataURL, logo ])

  return (
    <Box
      data-testid="AppLogo"
      ref={ref}
      sx={mergeSxProps(
        () => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: size !== 's' ? 56 : 44,
          width: size !== 's' ? 89 : 70,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '4px',
          background: imageBgColor,
          overflow: 'hidden',
          '> img': {
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover'
          }
        }),
        sx
      )}
      {...otherProps}
    >
      {isLoading
        ? <Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} />
        : <img src={dataURL} alt="App Logo" />}
    </Box>
  )
})

AppLogo.propTypes = {
  /**
   * A base64-encoded image data representing the logo to be displayed.
   * If not provided, a default logo will be used.
   */
  logo: PropTypes.string,
  /** Determines the size of the app logo */
  size: PropTypes.oneOf([ 's', 'm' ]),
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
