import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateInput, Switch } from "@alice/component_library";
import StatusRoleConfig from "@components/StatusRoleConfiguration";
import { ValidityContainer } from "@pages/newRequest/styledComponents";

const ValidityComponet = ({
  id,
  isDisabled,
  validity = { from: undefined, to: undefined },
  onValidityChange,
  defaultValidity,
  validityType,
  isOptional = true,
  pageIndex,
  isExtend = false,
}) => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState({
    from: validity?.from || new Date(), // Use predefined or default date
    to:
      validity?.to ||
      new Date(new Date().getTime() + defaultValidity * 24 * 60 * 60 * 1000),
  });
  const [isValidated, setisValidated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [disable, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  const handleFromDateChange = (value) => {
    const newFromDate = new Date(value);
    if (toDateRange !== null && newFromDate > toDateRange) {
      return; // Prevent invalid state update
    }
    setDateRange({ ...dateRange, from: newFromDate });
  };

  const handleToDateChange = (value) => {
    const newToDate = new Date(value);
    if (fromDateRange !== null && newToDate < fromDateRange) {
      return; // Prevent invalid state update
    }
    setDateRange({ ...dateRange, to: newToDate });
  };

  const isSucess = () => {
    let isValidatedVar = false;
    if (disable && validityType === "OPTIONAL") {
      isValidatedVar = true;
    } else if (
      validityType === "MANDATORY" ||
      validityType === "OPTIONAL" ||
      validityType === "FIXED"
    ) {
      if (areDatesValid) {
        isValidatedVar = true;
      } else {
        isValidatedVar = false;
      }
    } else {
      isValidatedVar = false;
    }

    setisValidated(isValidatedVar && !hasError ? true : false);
    onValidityChange({
      timeRange: {
        validity: dateRange,
        isDisabled: disable || false,
        validated: isValidatedVar && !hasError ? true : false,
      },
    });
  };

  const areDatesValid = () => {
    // Check for both dates being valid and a valid range
    return (
      !isNaN(dateRange?.from?.getTime()) &&
      !isNaN(dateRange?.to?.getTime()) &&
      dateRange?.from <= dateRange?.to
    );
  };

  useEffect(() => {
    setDisabled(isDisabled ?? false);
    setDateRange({
      from: validity?.from || new Date(), // Use predefined or default date
      to:
        validity?.to ||
        new Date(new Date().getTime() + defaultValidity * 24 * 60 * 60 * 1000),
    });
  }, [id]);

  useEffect(() => {
    isSucess();
  }, [dateRange, disable, validityType, hasError, pageIndex]);

  const handleChange = (event) => {
    setDisabled(!event.target.checked); // Update disabled state based on checked
  };

  useEffect(() => {
    isSucess();
  }, []);

  const fromDateRange = dateRange.from;
  const toDateRange = dateRange.to;

  return (
    <div key={id}>
      <ValidityContainer>
        <div className="header-container">
          <span className="title">{t("validity")}</span>
          <StatusRoleConfig status={isValidated ? "success" : "failed"} />
        </div>
        {isOptional && (
          <div className="switch-container">
            <Switch
              label={t("add-validity")}
              onChange={handleChange}
              checked={!disable}
            />
          </div>
        )}
        <div className="date-container">
          <div className="date-input">
            <DateInput
              ref={{
                current: "[Circular]",
              }}
              inputLabel={t("valid_from")}
              onChange={(value) => handleFromDateChange(value)}
              value={fromDateRange}
              disabled={disable || validityType === "FIXED" || isExtend}
              disabledMatcher={{ before: new Date() }}
              disablePast={true}
              maxDate={toDateRange}
              onError={(error, value) => {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Set time components to 0
                const comparisonDate = new Date(value);
                comparisonDate.setHours(0, 0, 0, 0); // Set time components to 0

                if (
                  (comparisonDate < currentDate ||
                    comparisonDate > toDateRange ||
                    error?.length > 0) &&
                  !isExtend
                ) {
                  setHasError(true);
                } else {
                  setHasError(false);
                }
              }}
              error={isExtend ? false : undefined}
            />
          </div>
          <div className="date-input">
            <DateInput
              ref={{
                current: "[Circular]",
              }}
              inputLabel={t("valid-to")}
              onChange={(value) => handleToDateChange(value)}
              minDate={fromDateRange}
              value={toDateRange}
              disabledMatcher={{ before: new Date() }}
              disablePast={true}
              disabled={disable || validityType === "FIXED"}
              onError={(error, value) => {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Set time components to 0
                const comparisonDate = new Date(value);
                comparisonDate.setHours(0, 0, 0, 0); // Set time components to 0

                if (comparisonDate < fromDateRange || error?.length > 0) {
                  setHasError(true);
                } else {
                  setHasError(false);
                }
              }}
            />
          </div>
        </div>
      </ValidityContainer>
    </div>
  );
};

export default ValidityComponet;
