import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { CloseButton } from '../../button'

export const ExpandContainer = ({
  heading,
  onClose,
  children,
  contentSx = {}
}) => (
  <Box
    sx={{
      display: 'flex',
      flexFlow: 'column',
      gap: 4,
      position: 'relative',
      padding: 3,
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.60)',
      borderRadius: '4px'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography variant="h6" component="div">{heading}</Typography>
      <CloseButton onClick={() => onClose()} />
    </Box>

    <Box sx={contentSx}>{children}</Box>
  </Box>
)

ExpandContainer.propTypes = {
  heading: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node
}
