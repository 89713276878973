import {
  FormControl,
  Popover,
  DialogContentText,
  Paper,
  Tabs,
  Tab,
  CardContent,
  Card,
  Grid,
  Dialog,
  DialogTitle,
} from "@mui/material";

import { styled } from "@mui/system";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const StyledDialog = styled(Dialog)({
  borderRadius: "2px",
});

export const StyledPapper = styled(Paper)({
  boxShadow: "none",
  "& .MuiTabs-flexContainer": {
    display: "flex",
    alignItems: "center",
  },
});

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #E8E8E8",
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  "&.MuiTab-root.Mui-selected ": {
    color: "#0078D6",
  },
  "&.MuiTab-root": {
    opacity: "1",
    color: "#767676",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontWeight: "700",
    fontStyle: "normal",
    letterSpacing: "0.4px",
    textAlign: "center",
    lineHeight: "24px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
  },
  minWidth: "unset",
}));

export const TabContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    position: "relative",
  },
  position: "absolute",
  width: "100%",
}));

export const DialogContainer = styled(Grid)({
  display: "flex",
  width: "100%",
});

export const DialogFromContainer = styled(FormControl)({
  display: "flex",
  width: "100%",
});

export const StyleDialogTitle = styled(DialogTitle)({
  padding: "12px 24px",
  fontFamily: "MB Corpo S Title ",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "28px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const StyleDialogTitleForResult = styled(DialogTitle)({
  padding: "12px 24px 0px",
  fontFamily: "MB Corpo S Title ",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "28px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const StyledDialogContentText = styled(DialogContentText)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    paddingTop: props?.paddingSize?.length ? props.paddingSize : "16px",
    color: "rgba(0, 0, 0, 0.6)",
  })
);

export const StyledDialogContentTextForRemoval = styled(DialogContentText)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Title",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    margin: `${props.margin} 0px 8px 0px`,
    padding: props?.padding?.length ? props.padding : "0px 32px 0px 24px",
    "& .counter-span": {
      background: "#0078D6",
      borderRadius: "10px",
      width: "23px",
      height: "20px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "8px",
      fontFamily: "MB Corpo S Text",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      color: "rgba(255, 255, 255, 0.87)",
    },
    "& .icon-span": {
      alignItems: "center",
      display: "flex",
      marginRight: "10px",
    },
  })
);

export const StyledDialogContentTextForRemovalForResult = styled(
  DialogContentText
)(({ theme, ...props }) => ({
  fontFamily: "MB Corpo S Title",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  color: "#000000",
  marginTop: props.minMargin,
  marginBottom: "8px",
  "& .counter-span": {
    background: "#0078D6",
    borderRadius: "10px",
    width: "23px",
    height: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "8px",
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.87)",
  },
  "& .icon-span": {
    alignItems: "center",
    display: "flex",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const StyledDialogContent = styled(DialogContent)({
  padding: "0px 32px 24px 24px",
});

export const StyledDialogContentDelete = styled(DialogContent)({
  padding: "0px",
  "& .select-contatiner": {
    padding: "0px 32px 0px 24px",
  },
});

export const StyledDialogAction = styled(DialogActions)({
  padding: "0px",
  margin: "32px 32px 28px 0px",
});

export const StyledDeleteCard = styled(Card)(({ ...props }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "8px",
  marginTop: "8px",
  background: props.backgroundColor,
  borderRadius: "4px",
  cursor: "pointer",
}));

export const StyledDeleteCardContent = styled(CardContent)({
  display: "flex",
  fontFamily: "MB Corpo S Text",
  padding: "2px 8px 2px 8px !important",
  color: "rgba(0, 0, 0, 0.87)",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  "& span": {
    display: "flex",
    alignItems: "center",
    paddingRight: "10px",
  },
  "& .heading": {
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "0px",
    overflow: "hidden",
    whiteSpace: "noWrap",
    textOverflow: "ellipsis",
    marginRight: "10px",
  },
  "& .heading-id": {
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    margin: "0px",
    color: "rgba(0, 0, 0, 0.6)",
    overflow: "hidden",
    whiteSpace: "noWrap",
    textOverflow: "ellipsis",
  },
  "& .wl-50": {
    width: "45%",
  },
});

export const DeleteAllCardContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "& .role-card-wrapper": {
    padding: "0px 32px 0px 24px",
    " &:hover": {
      "& > .custom-template-card": {
        background: "rgba(0, 141, 252, 0.08)",
        "& > .close-icon": {
          display: "block",
        },
      },
    },
  },
  "& .role-card-container": {
    position: "relative",
    " &:hover": {
      "& > .close-icon": {
        display: "block",
      },
      "& > .role-card-wrapper > .custom-template-card": {
        background: "rgba(0, 141, 252, 0.08)",
        "& > .close-icon": {
          display: "block",
        },
      },
    },
  },
  " & > .MuiPaper-elevation": {
    backgroundColor: props?.backGroundColor?.length
      ? props?.backGroundColor
      : "",
  },
  "& .close-icon": {
    position: "absolute",
    right: "18px",
    top: "0px",
    display: "none",
  },
}));

export const StyledInlayGrid = styled(Grid)({
  display: "flex",
  "& .role-inlay-grid": {
    width: "100%",
  },
  "& .MuiBox-root": {
    width: "100%",
    "& > span": {
      display: "none !important",
    },
  },
});

export const StyledDivHeaderForDate = styled("div")({
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  " & .selected-date": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon-container": {
      cursor: "pointer",
    },
  },
  "& .select-date-hader": {
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  "& .date-style": {
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "32px",
    lineHeight: "40px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  "& .selected-from": {
    marginRight: "5px",
  },
  "& .selected-to": {
    marginLeft: "5px",
  },
});

export const StyledPopover = styled(Popover)({
  "& .MuiPaper-root": {
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    width: "360px",
    marginTop: "10px",
    "& .rdp": {
      margin: 0,
      "&-table": {
        "& tr": {
          justifyContent: "space-around",
        },
      },
    },
  },
  "& .footer-button": {
    fontFamily: "MB Corpo S Text",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "24px",
    color: "#0078D6",
    cursor: "pointer",
  },
  "& .date-with-text-box": {
    display: "flex",
    padding: "26px 12px 26px 24px",
    "& .errorInfo": {
      color: "#D92121",
    },
    "& .MuiFormHelperText-root": {
      marginTop: "5px",
    },
    "& .MuiTextField-root": {
      margin: "0 12px 0 0",
    },
  },
  "& .button-container": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "15.5px",
    marginRight: "15.5px",
    "& .cnacel-button": {
      marginRight: "18px",
    },
  },
});

export const StyledDelteMessageContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
});

export const GenericSearchContainer = styled("div")({
  "& .MuiBox-root:has(>.delete-button)": {
    width: "100%",
    order: -1,
  },
  "& .MuiBox-root:has(label)": {
    flexWrap: "wrap",
  },
});
