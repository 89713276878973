import { openTaskActionTypes } from "@constants/actionTypes";
const initialState = {
  openTaskCountInfo: {
    tottalCount: 0,
    roleRequestCount: 0,
    entitileMentCount: 0,
    techUserCount: 0,
    orgAdminCount: 0,
    deprovisioningCount: 0,
    usercreationCount: 0,
    orgCount: 0,
    dashboardTottalCount: 0,
  },
  taskInfo: {
    loading: false,
    errorInfo: { error: false, errorMessage: "" },
    allTasks: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    roleRequest: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    workflowInfo: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    userCreation: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    userToOrganization: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    orgAdminPermission: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    deProvisionInfo: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    techUserToapplication: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
    entitelementToRole: {
      data: [],
      paginationInfo: {},
      fetchInfo: {},
      isLoaded: false,
    },
  },
  taskDeepLinkInfo: {
    loading: false,
    isLoaded: false,
    selectedTask: {},
    workFlowData: {},
    count: 0,
    relativeTask: {
      taskResult: {
        tasks: [],
        count: 0,
      },
      taskData: [],
      countInfo: [],
      paginationInfo: {
        fetchedCount: 0,
      },
    },
  },
  applicationInfo: {},
  breadcrumbInfo: {},
  customizedSnackbar: {},
  openTaskData: [],
  storeSelectedTaskDetail: [],
  selectedRow: 0,
  supervisorData: [],
  taskList: {
    data: [],
    taskCount: 0,
    workflow: 0,
    loading: false,
    error: false,
    isLoaded: false,
  },
  confFromBreadcrumb: {},
  notification: { count: 0, isLoaded: false },
  rejectReason: "",
  latestTaskActivity: {
    loading: false,
    isLoaded: false,
    latestTaskActivity: [],
  },
};
const OpenTaskReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case openTaskActionTypes.STORE_TASK_LIST:
      return (state = {
        ...state,
        taskList: Object.assign({}, data),
      });
    case openTaskActionTypes.STORE_REJECT_REASON:
      return (state = {
        ...state,
        rejectReason: data,
      });
    case openTaskActionTypes.STORE_TASK_COUNT:
      return (state = {
        ...state,
        openTaskCountInfo: data.data,
      });
    case openTaskActionTypes.UPDATE_COUNT:
      return (state = {
        ...state,
        openTaskCountInfo: {
          ...state.openTaskCountInfo,
          tottalCount: state.openTaskCountInfo.tottalCount - data,
          roleRequestCount: state.openTaskCountInfo.roleRequestCount - data,
        },
      });
    case openTaskActionTypes.STORE_TASK_INFO:
      return (state = {
        ...state,
        taskInfo: {
          ...state.taskInfo,
          [data.structureInfo]: {
            ...state.taskInfo[data.structureInfo],
            data: data.data,
            paginationInfo: data.paginationInfo,
            fetchInfo: data.fetchInfo,
            isLoaded: false,
          },
          errorInfo: data.errorInfo,
          loading: data.loading,
        },
      });
    case openTaskActionTypes.UPDATE_DEEPLINK_COUNT_INFO:
      return (state = {
        ...state,
        taskDeepLinkInfo: {
          ...state.taskDeepLinkInfo,
          relativeTask: data,
        },
      });
    case openTaskActionTypes.UPDATE_DEEPLINK_COUNT_UPDATE:
      return (state = {
        ...state,
        taskDeepLinkInfo: {
          ...state.taskDeepLinkInfo,
          relativeTask: {
            ...state.taskDeepLinkInfo.relativeTask,
            taskResult: {
              ...state.taskDeepLinkInfo.relativeTask.taskResult,
              tasks: data.task,
              count: data.count,
            },
          },
        },
      });

    case openTaskActionTypes.STORE_TASK_DEEPLINK:
      return {
        ...state,
        taskDeepLinkInfo: {
          ...state.taskDeepLinkInfo,
          loading: data.loading,
          isLoaded: data.isLoaded,
          selectedTask: data.selectedTask,
          workFlowData: data.workFlowData,
        },
      };
    case openTaskActionTypes.SAVE_BREADCRUMB_DATA:
      return {
        ...state,
        breadcrumbInfo: data,
      };
    case openTaskActionTypes.SET_CUSTOMIZED_SNACKBAR:
      return {
        ...state,
        customizedSnackbar: data,
      };
    case openTaskActionTypes.SAVE_APPLICATION_DATA:
      return { ...state, applicationInfo: data };
    case openTaskActionTypes.STORE_SELECTED_TASK_DETAIL:
      data.reqDtlsclicked = false;
      data.reqHistoryclicked = false;
      data.removeClick = false;
      data.rejectClick = false;
      data.isdetailsOpen = false;
      data.accepted = false;
      data.isEdit = false;
      data.reason = "";
      data.cancel = false;
      data.editReason = "";

      return (state = {
        ...state,
        storeSelectedTaskDetail:
          data &&
          data !== undefined &&
          Object.keys(data).length !== 0 &&
          Object.keys(data).length > 12
            ? [data]
            : [],
      });
    case openTaskActionTypes.CLEAR_APPROVAL_DATA:
      return (state = {
        ...state,
        storeSelectedTaskDetail: [],
      });

    case openTaskActionTypes.STORE_SELECTED_ROW:
      return (state = {
        ...state,
        selectedRow: data,
      });

    case openTaskActionTypes.STORE_TASK_CONFIG_FROM_BREADCRUMB:
      return (state = {
        ...state,
        confFromBreadcrumb: data,
      });
    case openTaskActionTypes.FETCH_SUPERVISOR_DATA:
      return (state = {
        ...state,
        supervisorData: data,
      });
    case openTaskActionTypes.UPDATE_TASK_NOTIFICATION:
      return (state = {
        ...state,
        notification: data,
      });
    case openTaskActionTypes.STORE_LATEST_TASK_ACTIVITY:
      return (state = {
        ...state,
        latestTaskActivity: data,
      });
    default:
      return state;
  }
};
export default OpenTaskReducer;
