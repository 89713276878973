import React from "react";
import { GenericSearchFilter } from "@components/SortingChip";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { InputControl } from "@utils/inputControls";

const FilterStatus = ({
  isMobile,
  isTablet,
  isDesktop,
  filter,
  setFilter,
  filterStatus,
}) => {
  const handleFilterChange = (newFilter) => {
    filterStatus(newFilter);
    setFilter(newFilter);
  };
  const { t } = useTranslation();

  const filterConfig = {
    filterState: filter,
    key: "dataClassification",
    label: t("filter-by-status") + ":",
    filters: [
      {
        filter: "all",
        label: t("role-review-by-status.all"),
      },
      {
        filter: "OPEN",
        label: t("role-review-by-status.open"),
        icon: RadioButtonUncheckedIcon,
      },
      {
        filter: "COMPLETED",
        label: t("role-review-by-status.done"),
        icon: CheckCircleOutlineIcon,
      },
      {
        filter: "TIMEOUT",
        label: t("role-review-by-status.overdue"),
        icon: RemoveCircleOutlineIcon,
      },
    ],
  };

  return (
    <>
      {isDesktop && (
        <GenericSearchFilter
          activeFilter={filter}
          filters={filterConfig?.filters}
          label={filterConfig?.label}
          onChange={handleFilterChange}
          isNeedToReset={false}
        />
      )}
      {(isTablet || isMobile) && (
        <InputControl
          type="select"
          name={filterConfig.key}
          labelName={filterConfig?.label}
          options={filterConfig?.filters.map((ele) => {
            ele.value = ele?.filter;
            return ele;
          })}
          value={filter}
          onChange={(e) => handleFilterChange(e?.target?.value)}
        />
      )}
    </>
  );
};

export default FilterStatus;
