import React from "react";
import {
  PageHeaderContainer,
  PageHeaderTypography,
  PageSubheaderTypography,
} from "./styledComponent";
import Filters from "./Filters";

const PageHeader = ({
  mainHeading,
  subHeading,
  searchProps,
  filterProps,
  sortProps,
  windowSize,
}) => {
  return (
    <>
      <PageHeaderContainer>
        <div>
          <PageHeaderTypography>{mainHeading}</PageHeaderTypography>
          <PageSubheaderTypography>{subHeading}</PageSubheaderTypography>
        </div>
      </PageHeaderContainer>
      <Filters
        filterProps={filterProps}
        searchProps={searchProps}
        sortProps={sortProps}
        windowSize={windowSize}
      />
    </>
  );
};

export default PageHeader;
