import React from "react";
import { Switch } from "@alice/component_library";

const SwitchButton = ({ clickHandler, checked }) => {
  return (
    <Switch
      checked={checked}
      onChange={(e) => clickHandler(e.target.checked)}
      inputProps={{ "aria-label": "Super admin control" }}
    />
  );
};

SwitchButton.propTypes = {};

export default SwitchButton;
