export const useAppCard = ({
  size = 'm',
  isList = false,
  isRenderer = false,
  description = isRenderer,
  dataClassification = isRenderer,
  assignmentStatus = isRenderer,
  _isContextShown = size !== 'm',
  _isDescriptionShown = size === 'l',
  _isClassificationShown = size !== 's',
  _isStatusShown = size === 'l',
  _onExpandClick,
  _onDeleteClick
}) => {
  const isTiny = size === 's'

  const isContextShown = _isContextShown
  const isDescriptionShown = _isDescriptionShown && description
  const isClassificationShown = _isClassificationShown && dataClassification
  const isStatusShown = _isStatusShown && assignmentStatus
  const isExpandShown = !!_onExpandClick
  const isDeleteShown = !!_onDeleteClick

  const isChipsShown = isClassificationShown || isStatusShown
  const isActionShown = isExpandShown || isDeleteShown

  const isDividedHorizontal = isList || (isTiny && isActionShown)

  const isDividedShown = !!(
    (isList && isContextShown)
    || isChipsShown
    || isExpandShown
    || isDeleteShown
  )

  const subgridContentLength = [ true, isDescriptionShown ].filter((entry) => entry).length

  return {
    isTiny,
    isContextShown,
    isDescriptionShown,
    isClassificationShown,
    isStatusShown,
    isExpandShown,
    isDeleteShown,
    isChipsShown,
    isActionShown,
    isDividedHorizontal,
    isDividedShown,
    subgridContentColumns: subgridContentLength,
    subgridContentRows: subgridContentLength
  }
}
