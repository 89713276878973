import { Snackbar } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Alert } from '../alert/Alert'

/**
 * This component displays a global message for a given time.<br>
 * Expects a useState getter and setter as params.
 */
export const DisplayMessage = forwardRef((
  {
    snackBar,
    setSnackBar,
    sx = [],
    ...otherProps
  },
  ref
) => snackBar && (
  <Snackbar
    data-testid="DisplayMessage"
    ref={ref}
    sx={sx}
    open
    autoHideDuration={snackBar.duration || 1000}
    onClose={() => setSnackBar(undefined)}
    {...otherProps}
  >
    <Alert
      onClose={() => setSnackBar(undefined)}
      severity={snackBar.severity || 'success'}
      sx={{
        width: '100%',
        color: 'white'
      }}
      variant="filled"
    >
      {snackBar.message}
    </Alert>
  </Snackbar>
))

DisplayMessage.propTypes = {
  /**
   * data used to display the message<br>
   * useState getter
   *
   * **message** is required
   */
  snackBar: PropTypes.shape({
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf([ 'error', 'warning', 'info', 'success' ]),
    duration: PropTypes.number
  }),
  /**
   * function to update display data<br>
   * useState setter
   */
  setSnackBar: PropTypes.func,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
