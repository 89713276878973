import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Typography, Box } from "@mui/material";
import { blue } from "@mercedes-benz/mui5-theme";
import { OrgContextPopover } from "@alice/component_library";
const OrgNameInfo = ({ orgName, orgId, needsOrgScopes }) => {
  const { t } = useTranslation();
  return (
    <>
      {orgName ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            minWidth: 0,
          }}
          id={`org-name-id-info`}
        >
          <Typography
            variant="body2"
            noWrap
            sx={{ whiteSpace: "nowrap !important" }}
          >
            {orgName}
          </Typography>
          <Box sx={{ marginBottom: "-8px" }}>
            <OrgContextPopover id={orgId}>
              <InfoOutlined sx={{ color: blue["50"] }} fontSize={"small"} />
            </OrgContextPopover>
          </Box>
        </Box>
      ) : (
        <Typography
          variant="body2"
          noWrap
          sx={needsOrgScopes ? { color: "#E8BD00" } : {}}
        >
          {needsOrgScopes ? t("missing-info") : "-"}
        </Typography>
      )}
    </>
  );
};

OrgNameInfo.prototype = {
  orgName: PropTypes.string,
  OrgId: PropTypes.string,
  needsOrgScopes: PropTypes.bool,
};

export default OrgNameInfo;
