import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DashboardAccessReveiw } from "@src/actions";
import { useWidth } from "@utils/hooks";
import { useUser } from "@alice/component_library";
import {
  AccesReviewDeadlineWrapper,
  AccessreviewNoDataContainer,
} from "../../styledComponents";
import AccessReviewGraph from "./AccessreviewGraph";
import AccessReviewHeader from "./Header";
import AccesReviewFooter from "./Footer";
import NoData from "./Icons/NoData.svg";
import AccessReviewTable from "./AccessReviewTable";
import { ReviewsHome } from "@components/Skeleton/CommonSkeletons";

const DeadLineComponent = () => {
  const dispatch = useDispatch();
  const [dataForcomponent, setDataForComponent] = useState([]);
  const { t } = useTranslation();
  const widthPanel = useWidth();
  const { user } = useUser();
  const userData = user ?? {};

  const {
    rels: { totalCount: reveiwTotalCount = 0 },
    reviews = [],
    loading,
    isLoaded,
  } = useSelector((state) => state.accessReview?.reviewAssignment);

  const fetchData = useCallback(() => {
    const userId = userData?.userId || userData?.id;
    dispatch(DashboardAccessReveiw(userId));
  }, [dispatch, userData?.id, userData?.userId]);

  useEffect(() => {
    if (!isLoaded && !loading) {
      fetchData();
    }
  }, [fetchData, isLoaded, loading]);

  const getProccesedData = useCallback((data) => {
    const dataForcomponent = data.map((item) => {
      const startTime = new Date(item?.startTime);
      const deadline = new Date(startTime.getTime() + 28 * 24 * 60 * 60 * 1000);
      const percentage = Math.ceil(
        (item.processedAssignments / item?.totalAssignments) * 100
      );

      return {
        count: {
          processedAssignments: item.processedAssignments,
          totalAssignments: item?.totalAssignments,
        },
        workflowId: item?.workflowId,
        title: item?.roleName,
        id: item?.roleId,
        endDate: deadline.toISOString().split("T")[0],
        percentage: isNaN(percentage)
          ? item.processedAssignments === 0 && item?.totalAssignments === 0
            ? 100
            : null
          : percentage,
      };
    });
    setDataForComponent(dataForcomponent);
  }, []);

  useEffect(() => {
    getProccesedData(reviews);
  }, [reviews, getProccesedData]);

  return (
    <>
      <>
        <AccesReviewDeadlineWrapper>
          <AccessReviewHeader loading={loading} fetchData={fetchData} />
          {loading ? (
            <ReviewsHome />
          ) : (
            <>
              {dataForcomponent.length ? (
                <>
                  {["xs", "sm"].includes(widthPanel) ? (
                    <AccessReviewTable
                      componentData={dataForcomponent}
                      count={reveiwTotalCount}
                    />
                  ) : (
                    <AccessReviewGraph
                      componentData={dataForcomponent}
                      count={reveiwTotalCount}
                    />
                  )}
                  <AccesReviewFooter count={reveiwTotalCount} />
                </>
              ) : (
                <AccessreviewNoDataContainer>
                  <NoData />
                  {t("no-upcoming-deadline")}
                </AccessreviewNoDataContainer>
              )}
            </>
          )}
        </AccesReviewDeadlineWrapper>
      </>
    </>
  );
};

export default DeadLineComponent;
