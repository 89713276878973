import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Tooltip } from '@mui/material'
import BaseTypography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserCommunities, mergeSxProps, USER_COMMUNITY_ICONS } from '../../../util'
import { Button, CloseButton, IconButtonText } from '../../button'
import { Card } from '../../card'
import { AliceIcon } from '../../icon'

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <DialogTitle
        sx={{
          pt: 5,
          py: 5,
          pb: 2
        }}
      >
        {t('marketSupportContact.confirmDeleteTitle')}
      </DialogTitle>

      <DialogContent sx={{ px: 5 }}>
        <CloseButton
          onClick={onClose}
          sx={{
            position: 'absolute', top: 0, right: 0
          }}
        />

        <DialogContentText>
          {t('marketSupportContact.confirmDeleteText')}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ px: 5, pb: 5 }}>
        <Stack direction="row" spacing={3}>
          <Button variant="secondary" onClick={onClose}>
            {t('general.cancel')}
          </Button>

          <Button variant="primary" onClick={onConfirm}>
            {t('general.confirm')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export const MarketSupportContact = ({
  userData,
  onClick = (userData) => {},
  onEdit,
  onDelete,
  sx = []
}) => {
  const { t } = useTranslation('componentLibrary')
  const [ dialogOpen, setDialogOpen ] = useState(false)

  const completeName = userData.name
  const phone = userData.telephoneNumber || userData.mobileNumber

  let communities
  if (userData.communities && userData.communities.length > 0) {
    communities = getUserCommunities(userData?.communities)
  }

  const Typography = ({
    children,
    sx,
    ...others
  }) => (
    // temporary fix for mbrdi until the mb theme is correctly integrated
    <BaseTypography
      {...others}
      sx={{ fontFamily: 'MB Corpo S Text !important', ...sx }}
    >
      {children}
    </BaseTypography>
  )

  const Title = ({ sx, children }) => (
    <Typography
      sx={{
        color: 'grey.45',
        fontSize: 14,
        ...sx
      }}
    >
      {children}
    </Typography>
  )

  const Content = ({ sx, children }) => (
    <Typography
      sx={{
        fontSize: 18,
        fontWeight: 600,
        ...sx
      }}
    >
      {children}
    </Typography>
  )

  return (
    <Card
      data-testid="MarketSupportContact"
      sx={mergeSxProps(
        {
          px: '26px',
          pt: '32px',
          pb: '20px'
        },
        sx
      )}
      onClick={() => onClick(userData)}
    >
      <Stack
        sx={mergeSxProps(
          {
            display: 'flex',
            height: '100%',
            overflowX: 'hidden'
          },
          sx
        )}
        spacing={3}
      >
        {/* Username and description */}
        <Stack spacing={1}>
          <Tooltip title={completeName} placement="top" arrow>
            <Box>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                {completeName}
              </Typography>
            </Box>
          </Tooltip>

          {userData.description && (
            <Typography noWrap sx={{ textOverflow: 'ellipsis', fontSize: 14 }}>
              {userData.description}
            </Typography>
          )}
        </Stack>

        {/* Contact info */}
        <Grid container gap={2}>
          <Grid
            item
            xs={12}
            sm={5}
            sx={{ pl: '0 !important', pt: '0 !important' }}
          >
            {/* Communities */}
            <Stack spacing={1}>
              <Title>{t('general.community')}</Title>

              {communities && communities.length ? (
                <Stack direction="row" spacing={2}>
                  {communities.map((community, index) => (
                    <Tooltip
                      key={`${community}-${index}`}
                      title={t(`userCommunity.${community}`)}
                      placement="top"
                      arrow
                    >
                      <Box sx={{ height: 24 }}>
                        <AliceIcon
                          iconType={USER_COMMUNITY_ICONS[community]}
                          size={24}
                        />
                      </Box>
                    </Tooltip>
                  ))}
                </Stack>
              ) : (
                <Content>-</Content>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ pt: '0 !important' }}>
            {/* email */}
            <Stack spacing={1}>
              <Title>{t('general.email')}</Title>
              <Content>{userData.mailAddress || '-'}</Content>
            </Stack>
          </Grid>

          <Grid item xs={12} sm="auto" sx={{ pt: '0 !important' }}>
            {/* phone */}
            <Stack spacing={1}>
              <Title>{t('general.phone')}</Title>
              <Content>{phone || '-'}</Content>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5} sx={{ pl: '0 !important' }}>
            {/* url */}
            <Stack spacing={1}>
              <Title>URL</Title>
              <Content>{userData.url || '-'}</Content>
            </Stack>
          </Grid>
        </Grid>

        {(onEdit || onDelete) && (
          <Stack direction="row" spacing={8} sx={{ pt: 1 }}>
            {onEdit && (
              <IconButtonText
                text={t('general.edit')}
                icon={<EditIcon style={{ width: '16px' }} />}
                color="black"
                enableHover
                onClick={() => onEdit(userData)}
              />
            )}

            {onDelete && (
              <IconButtonText
                text={t('general.delete')}
                icon={<ClearIcon style={{ width: '18px' }} />}
                color="black"
                enableHover
                onClick={() => setDialogOpen(true)}
              />
            )}
          </Stack>
        )}
      </Stack>

      <ConfirmDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
        onConfirm={() => {
          setDialogOpen(false)
          onDelete(userData)
        }}
      />
    </Card>
  )
}

MarketSupportContact.propTypes = {
  /** The user to display */
  userData: PropTypes.shape({
    givenname: PropTypes.string,
    surname: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string),
    groupType: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    mailAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    departmentNumber: PropTypes.string,
    url: PropTypes.string,
    market: PropTypes.string,
    applicationId: PropTypes.string
  }).isRequired,
  /**
   * Event that is triggered when a card is clicked
   *
   * @param userData
   * @returns {void}
   */
  onClick: PropTypes.func,
  /**
   * When set the edit button will appear.<br>
   * When edit button is clicked this event will be executed
   *
   * @param userData
   * @returns {void}
   */
  onEdit: PropTypes.func,
  /**
   * When set the delete button will appear.<br>
   * When delete button is clicked this event will be executed
   *
   * @param userData
   * @returns {void}
   */
  onDelete: PropTypes.func,
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
