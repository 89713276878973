import React from "react";
import { Divider } from "@mui/material";

import {
  Tooltip,
  UserAvatar,
  UserContextPopover,
} from "@alice/component_library";
import {
  StyledIconButtons,
  CheckCircleOutlineIcon,
  HighlightOffOutlinedIcon,
  EditOutlinedIcon,
  CheckIcon,
  CloseIcon,
  PendingActionCellContainer,
  DecisionMakerContainer,
} from "../styledComponents";
import { useDispatch } from "react-redux";
import { managePopup } from "@src/actions";
import { DECISION_CONSTANT } from "../utils";
import { useTranslation } from "react-i18next";

const { MODIFIED, DELETED, CONFIRMED, DELETED_EXT } = DECISION_CONSTANT;
const DecisionCell = ({
  row,
  disabled = false,
  rolesDataInfo = {},
  roleReviewId = "",
}) => {
  const { reviewer = {} } = row;
  const { id: reviewerId = "", completeName } = reviewer;
  const { defaultValidityDays = 180, defaultValidityType = "" } = rolesDataInfo;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleActions = (e, type) => {
    e.stopPropagation();
    dispatch(
      managePopup({
        open: true,
        type: type,
        selectedId: [row],
        bulkProcessing: false,
        defaultValidityDays: defaultValidityDays,
        defaultValidityType: defaultValidityType,
      })
    );
  };
  return (
    <div style={{ display: "flex" }}>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          marginRight: "12px",
          ...(row?.decision === "NONE"
            ? { display: { xs: "none", lg: "block" } }
            : {}),
        }}
      />
      {(!row?.decision || row?.decision === "NONE") && (
        <PendingActionCellContainer>
          <StyledIconButtons
            onClick={(e) => handleActions(e, CONFIRMED)}
            backgroundcolor="#198025"
            hover="#115919"
            disabled={disabled}
          >
            <CheckIcon />
          </StyledIconButtons>
          <StyledIconButtons
            onClick={(e) => handleActions(e, MODIFIED)}
            backgroundcolor="#0078D6"
            hover="#005495"
            disabled={disabled}
          >
            <EditOutlinedIcon />
          </StyledIconButtons>
          <StyledIconButtons
            onClick={(e) => handleActions(e, DELETED)}
            backgroundcolor="#D92121"
            hover="#971717"
            disabled={disabled}
          >
            <CloseIcon />
          </StyledIconButtons>
        </PendingActionCellContainer>
      )}
      {row?.decision !== "NONE" && (
        <DecisionMakerContainer>
          {reviewerId && (
            <>
              <UserContextPopover id={reviewerId}>
                <UserAvatar
                  sizeStyle={{
                    height: "34px",
                    width: "34px",
                    fontSize: "16px",
                  }}
                  userId={reviewerId}
                  userName={completeName}
                  showImage={true}
                />
              </UserContextPopover>
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: "0 12px" }}
              />
            </>
          )}
          {row?.decision === CONFIRMED && (
            <Tooltip title={t("confirmed")} type="ellipsized">
              <div>
                <StyledIconButtons light="true" backgroundcolor="#BCE3C1">
                  <CheckCircleOutlineIcon />
                </StyledIconButtons>
              </div>
            </Tooltip>
          )}
          {row?.decision === DELETED && (
            <Tooltip title={t("removed")} type="ellipsized">
              <div>
                <StyledIconButtons light="true" backgroundcolor="#FFB7B7">
                  <HighlightOffOutlinedIcon />
                </StyledIconButtons>
              </div>
            </Tooltip>
          )}
          {row?.decision === DELETED_EXT && (
            <Tooltip title={t("removed")} type="ellipsized">
              <div>
                <StyledIconButtons light="true" backgroundcolor="#FFB7B7">
                  <HighlightOffOutlinedIcon />
                </StyledIconButtons>
              </div>
            </Tooltip>
          )}
          {row?.decision === MODIFIED && (
            <Tooltip title={t("modified")} type="ellipsized">
              <div>
                <StyledIconButtons light="true" backgroundcolor="#BBBBBB">
                  <EditOutlinedIcon />
                </StyledIconButtons>
              </div>
            </Tooltip>
          )}
        </DecisionMakerContainer>
      )}
    </div>
  );
};

export default DecisionCell;
