import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  AliceIcon,
  Button,
  Card,
  Tooltip,
  UserAvatar,
  UserContextPopover,
} from "@alice/component_library";

import {
  RequestContainer,
  NewRequsetLabel,
  NewReqLabel,
  NewReqHeader,
  RequestCardContainer,
  ReqButtonContiner,
  UserDetailStyle,
  MyselfTypo,
  UserNameTypo,
  UserMailTypo,
  UseridTypo,
} from "../../styledComponents";

const SelfRequestCard = ({ userData }) => {
  const { id, groupType, completeName, givenname, surname, mailAddress } =
    userData;
  const { t } = useTranslation();
  const mailtoLink = `mailto:${mailAddress}`;

  return (
    <>
      <RequestContainer>
        <Box>
          <NewRequsetLabel>
            <AliceIcon iconType="UINewReq" size={20} />
            <NewReqLabel>{t("new_request")}</NewReqLabel>
          </NewRequsetLabel>
          <NewReqHeader>{t("req-myself")}</NewReqHeader>
        </Box>
        <RequestCardContainer>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              rowGap: "16px",
              justifyContent: "center",
              alignItems: "center",
              height: "220px",
            }}
          >
            <MyselfTypo noWrap>{t("icons.myself")}</MyselfTypo>
            <Stack direction="row" columnGap="32px" alignItems="center">
              <UserContextPopover id={id}>
                <UserAvatar
                  showImage={true}
                  userId={id}
                  userName={completeName}
                  size="big"
                  showGroupType
                  groupType={groupType}
                  sizeStyle={{
                    height: "56px",
                    width: "56px",
                    fontSize: "1.5rem",
                    dotSize: "18px",
                  }}
                />
              </UserContextPopover>
            </Stack>
            <UserDetailStyle>
              <Tooltip type="ellipsized" title={`${surname}, ${givenname}`}>
                <UserNameTypo>{`${surname}, ${givenname}`}</UserNameTypo>
              </Tooltip>
              <Tooltip type="ellipsized" title={mailAddress}>
                <UserMailTypo>
                  <a href={mailtoLink}>{mailAddress}</a>
                </UserMailTypo>
              </Tooltip>
              <Tooltip type="ellipsized" title={id}>
                <UseridTypo>{id}</UseridTypo>
              </Tooltip>
            </UserDetailStyle>
          </Card>
        </RequestCardContainer>
        <ReqButtonContiner>
          <Link to={"/access/accessRequest/myself"}>
            <Button
              children={
                <>
                  {t("request-for-myself")}
                  <ChevronRightIcon />
                </>
              }
              variant="primary"
            />
          </Link>
        </ReqButtonContiner>
      </RequestContainer>
    </>
  );
};

export default SelfRequestCard;
