import { blue, grey } from '@mercedes-benz/mui5-theme'
import { ContentCopyOutlined, OpenInNewOutlined } from '@mui/icons-material'
import { Box, Link, Stack, Typography } from '@mui/material'
import { getUrlPart, isEmail, isUrl } from '../../../../util'
import { IconButton } from '../../../button'

export const AppCardInfoElements = ({
  url,
  icon,
  header
}) => {
  const isUrlValid = url ? isEmail(url) || isUrl(url) : false

  return (
    <Stack
      data-testid="AppCardInfoElements"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      sx={[
        {
          padding: 1,
          width: '100%'
        },
        isUrlValid && {
          '&:hover': { background: grey['85'], transition: 'background 250ms ease' },
          '&:hover .MuiSvgIcon-root': { visibility: 'visible' }
        }
      ]}
    >
      <Stack
        direction="row"
        gap={2}
        alignItems="flex-end"
      >
        <Box
          sx={({ palette }) => ({
            color: palette.text.primary,
            fontSize: '24px',
            height: '24px'
          })}
        >
          {icon}
        </Box>

        <Stack>
          <Typography variant="caption" color="grey.60" as="span">{header}</Typography>

          { isUrlValid ? (
            <Link
              sx={{
                textDecoration: 'none',
                color: blue['45'],
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover > .MuiSvgIcon-root': { opacity: 1 }
              }}
              href={isEmail(url) ? `mailto:${url}` : url}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="subtitle2" as="span">
                {isEmail(url) ? url : getUrlPart(url)?.host}
              </Typography>

              <OpenInNewOutlined
                className="hidden"
                fontSize="inherit"
                color="inherit"
                sx={{ opacity: 0, transition: 'opacity 250ms' }}
              />
            </Link>
          ) : <Typography variant="subtitle2" as="span">-</Typography>}
        </Stack>
      </Stack>

      { isUrlValid && (
        <IconButton
          sx={{
            fontSize: '24px',
            color: grey['45'],
            '&:hover': { color: blue['45'] }
          }}
          onClick={() => navigator.clipboard.writeText(url)}
        >
          <ContentCopyOutlined fontSize="inherit" color="inherit" sx={{ visibility: 'hidden', transition: 'visibility 250ms' }} />
        </IconButton>
      )}
    </Stack>
  )
}
