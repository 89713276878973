import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { useTranslation } from "react-i18next";
import { CloseButton } from "@alice/component_library";

const MainConatinerDialogue = styled(Dialog)({
  "& .paper": {
    minWidth: "550px",
  },
  "& .papersm": {
    minWidth: "340px",
  },
  "& .buttonWrap": {
    float: "right !important",
    right: "20px !important",
    position: "absolute !important",
    paddingRight: "46px !important",
  },
  "& .lh-42": {
    lineHeight: "42px",
  },
  "& .p-24": {
    padding: "24px",
  },
  "& .doitoutlined": {
    border: "none !important",
    borderBottom: "1px solid black !important",
    padding: "0px !important",
    height: "15px !important",
    fontSize: "16px !important",
    fontWeight: 400,
    marginTop: "15px !important",
    minWidth: "50px !important",
    "&:hover": {
      color: "#707070 !important",
      backgroundColor: "transparent !important",
      borderBottom: "1px solid #707070 !important",
    },
  },
});

const RoleDeletePopup = (props) => {
  const { t } = useTranslation();
  const {
    handleClose,
    setEditOptionsChange,
    deleteHandler,
    open,
    roleInfo,
    width,
  } = props;
  const selScopes = roleInfo.selectedScopes;
  return (
    <MainConatinerDialogue
      open={open}
      onClose={() => handleClose(roleInfo.id)}
      classes={width >= 415 ? { paper: "paper" } : { paper: "papersm" }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="p-24">
        <p className="font-42 font-weight-400 m-0 mb-16 lh-42">
          {t("attention-needed")}
        </p>
        <p className="font-18 font-weight-400 mb-0 mt-10">
          {t("you-are-deleting-role") +
            ' "' +
            roleInfo.name +
            '"  ' +
            t("including-following-scopes")}
        </p>
        <CloseButton
          onClick={() => handleClose(roleInfo.id)}
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            borderRadius: "2px",
            color: "#FFF",
            backgroundColor: "rgb(0, 0, 0);",
            "&:hover": {
              backgroundColor: "rgb(0, 0, 0)",
            },
          }}
        />
      </DialogTitle>
      <DialogContent className="p-26 pt-0">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className=" mb-28 word-wrap h-120 auto-scroll lh-8"
        >
          {selScopes.map((item) => {
            return (
              <p className="font-18 font-weight-600">
                <span>{item.orgScope !== undefined ? item.orgScope : ""}</span>
                <span>
                  {item.custScope !== undefined && item.orgScope !== undefined
                    ? " - "
                    : ""}
                </span>
                <span>
                  {item.custScope !== undefined ? item.custScope : ""}
                </span>
              </p>
            );
          })}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="mt-48">
          <Grid className=" font-18 mb-10">
            {t("edit-role-to-delete-single-scope")}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="delete-button-allignment p-26">
        <Grid item lg={12} md={12} xs={12} sm={12} className="flex mb-6">
          <Grid item lg={3} md={3} xs={3} sm={3}>
            <span>
              <SecondaryButton
                label={t("cancel")}
                onButtonClick={() => handleClose(roleInfo.id)}
                classes={{ outlined: "doitoutlined" }}
                disableElevation
              />
            </span>
          </Grid>
          <Grid item lg={9} md={9} xs={9} sm={9} className="buttonWrap">
            <SecondaryButton
              label={t("edit-role")}
              onButtonClick={() => setEditOptionsChange(roleInfo.id)}
              className="profile-change-password-button-align mr-16 5"
            />
            <PrimaryButton
              label={t("delete-all")}
              onButtonClick={() => deleteHandler()}
              className="user-save-button-align pointer mrn-38"
              autoFocus
            />
          </Grid>
        </Grid>
      </DialogActions>
    </MainConatinerDialogue>
  );
};
export default RoleDeletePopup;
