import React from "react";
import { DateInput } from "@alice/component_library";

const CustomDatePicker = ({
  disablePast,
  preselectedDate,
  isDisabled,
  inputLabel,
  selectedDate,
  value,
  id,
  ...props
}) => {
  const changeValue = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      selectedDate(new Date(date));
    }
  };

  return (
    <DateInput
      key={`${id}_${new Date().getTime()}`}
      data-id={id}
      ref={{
        current: "[Circular]",
      }}
      disabled={isDisabled}
      disabledMatcher={
        disablePast
          ? {
              before: new Date(),
            }
          : {}
      }
      inputLabel={inputLabel}
      preselectedDate={new Date(preselectedDate)}
      onChange={(date) => changeValue(date)}
      value={new Date(value)}
      disablePast={disablePast ? true : false}
      onError={(error, value) => {
        console.log("error", error);
      }}
      {...props}
    />
  );
};

export default React.memo(CustomDatePicker);
