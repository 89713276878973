import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import DevicesIcon from "@mui/icons-material/Devices";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { LayersOutlined } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Skeleton } from "@alice/component_library";
import CommonFilters from "@components/CommonFilters";
import SelectApplication from "@components/SelectApplicationsInfiniteScrolling";
import SearchComponent from "@components/PageHeader/Filters/SearchComponent";
import SelectReferenceUser from "./SelectReferenceUser";
import ModalFilters from "@src/sharedComponents/ModalFilters";
import ActiveFilterTab from "@src/sharedComponents/ModalFilters/ActiveFilterBar";

const RoleFilter = ({
  filter,
  handleFilter,
  searchKey,
  handleSearch,
  userId,
  selectedApplication,
  handleSelectApplication,
  selectedReferenceUser,
  handleSelectReferenceUser,
  totalCount,
  loading = false,
  showCopyReferenceUser = false,
  isAppliCationDisabled = false,
}) => {
  const { t } = useTranslation();
  const [value, setValues] = useState(filter);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const filters = [
    { value: "all", label: t("all") },
    { value: "BUSINESS", label: t("business"), icon: <BusinessIcon /> },
    { value: "PRODUCT", label: t("product"), icon: <DevicesIcon /> },
    {
      value: "ENTITLEMENT",
      label: t("application"),
      icon: <LayersOutlined />,
    },
    {
      value: "JOBTITLE",
      label: t("job-title"),
      icon: <BadgeOutlinedIcon />,
    },
  ];

  useEffect(() => {
    setValues(filter);
  }, [filter]);

  const handleDeleteFilter = (value) => {
    const filterValues = filter.filter((ele) => ele !== value).join();
    handleFilter(filterValues.length > 0 ? filterValues : "all");
  };

  const handleApply = () => {
    console.log("Apply");
    handleFilter(value);
  };

  const handleCancel = () => {
    setValues(filter);
  };

  const handleFilterChange = useCallback((ele) => {
    let selectedFilter = [...value];
    if (
      (ele === "JOBTITLE" || ele === "BUSINESS") &&
      (selectedFilter.includes("JOBTITLE") ||
        selectedFilter.includes("BUSINESS")) &&
      selectedFilter.length === 1
    ) {
      const newSelectedFilter = [...selectedFilter, ele];
      selectedFilter = newSelectedFilter;
    } else {
      selectedFilter = [ele];
    }

    setValues(selectedFilter);
  });

  return (
    <div className="role-grid-container sticky-role-filter">
      <SearchComponent
        label={t("searchbar_label_role")}
        searchKey={searchKey}
        handleSearch={handleSearch}
      />
      <div className="filter-container">
        {isMobile ? (
          <>
            <ModalFilters
              children={
                <CommonFilters
                  label={`${t("filter-by-role-type")}:`}
                  filters={filters}
                  filter={value}
                  handleFilter={handleFilterChange}
                />
              }
              handleReset={() => handleFilter("all")}
              handleApply={() => handleApply()}
              handleCancel={() => handleCancel()}
            />
            <ActiveFilterTab
              activeFilters={filters}
              filter={filter}
              onDelete={(value) => handleDeleteFilter(value)}
              onClear={() => handleFilter("all")}
            />
          </>
        ) : (
          <CommonFilters
            label={`${t("filter-by-role-type")}:`}
            filters={filters}
            filter={filter}
            handleFilter={handleFilter}
          />
        )}
        <SelectApplication
          userId={userId}
          handleSelectApplication={handleSelectApplication}
          selectedApplication={selectedApplication}
          isAppliCationDisabled={isAppliCationDisabled}
        />
        {showCopyReferenceUser && (
          <SelectReferenceUser
            selectedReferenceUser={selectedReferenceUser}
            handleSelectReferenceUser={handleSelectReferenceUser}
          />
        )}
      </div>
      <Divider />
      <Typography
        variant="body2"
        sx={{
          padding: "24px 0",
          display: "flex",
          gap: "8px",
          justifyContent: "end",
        }}
      >
        {t("roles_shown")} :
        {loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <b>{totalCount}</b>
        )}
      </Typography>
    </div>
  );
};

export default RoleFilter;
