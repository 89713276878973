import RightArrow from "@Icons/taskpage/right-arrow.svg";
import RoleKey from "@Icons/taskpage/rolekey.svg";
import RoleRequest from "@Icons/taskpage/rolerequest.svg";
import User from "@Icons/taskpage/user.svg";
import EntitleMentIcon from "@Icons/taskpage/AssignEntitelemnet.svg";
import AssignTechnicalUser from "@Icons/taskpage/AssignTechnicalUser.svg";
import AssignTechnicalUserLeft from "@Icons/taskpage/AssignTechnicalUserLeft.svg";
import AssignTechnicalUserRight from "@Icons/taskpage/AssignTechnicalRight.svg";
import AprooveRoleDeprovisioning from "@Icons/taskpage/AprooveRoleDeprovisioning.svg";
import RemoveIcon from "@Icons/taskpage/RemoveIcon.svg";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import RejectReason from "@Icons/taskpage/rejectReason.svg";

export const RequestCase = (taskInfo, t) => {
  const {
    affectedData,
    taskType,
    requestReason,
    state,
    approvalRejectionReason,
  } = taskInfo;
  const { role, user, entitlement, homeOrg } = affectedData;
  // const { id: roleId } = role
  let roleIdSvg = "";
  let customSvgLeft = "";
  let customValueLeft = "";
  let customOperationalIcon = "";
  let customSvgRight = "";
  let customValueRight = "";
  let customAssignId = "";
  let entitlementId;
  let smsIcon, applicationReasonHeader, applicationReason;
  let rejectReason,
    rejectReasonHeader =
      state === "COMPLETED" ? t("approval-reason") : t("reject-reasons");
  let rejectReasonIcon;
  let cardState = "";
  customOperationalIcon = (
    <div className="remove-icon-svg-">
      <RightArrow />
    </div>
  );
  let detail = "";
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      roleIdSvg = <RoleRequest />;
      customSvgLeft = <RoleKey />;
      customValueLeft = role?.id;
      customSvgRight = <User />;
      customValueRight = user?.givenname + " " + user?.surname;
      customAssignId = user?.id;
      const adminValue = `<strong>${customValueLeft}</strong>`;
      const roleVAlue = `<strong>${customValueRight}(${customAssignId})</strong>`;
      detail = t("assign-role-description", {
        role: adminValue,
        beneficiary: roleVAlue,
      });
      smsIcon = <TextsmsOutlinedIcon sx={{ fontSize: "1.3rem" }} />;
      applicationReasonHeader = t("request-reason");
      applicationReason = requestReason;
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      roleIdSvg = <EntitleMentIcon />;
      customValueLeft = entitlement?.entitlementId;
      customValueRight = role?.id;
      customSvgLeft = <EntitleMentIcon />;
      customSvgRight = <RoleKey />;
      customAssignId = "";
      entitlementId = entitlement?.applicationId;
      const entitlementLeft = `<strong>${customValueLeft}(${entitlementId})</strong>`;
      const entitlementRole = `<strong>${customValueRight}</strong>`;
      detail = t("entitlements-description", {
        entitlement: entitlementLeft,
        role: entitlementRole,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      roleIdSvg = <AssignTechnicalUser />;
      customValueLeft = user?.id;
      customValueRight = homeOrg?.name;
      customSvgLeft = <AssignTechnicalUserLeft />;
      customSvgRight = <AssignTechnicalUserRight />;
      const techUser = `<strong>${customValueLeft}</strong>`;
      const application = `<strong>${customValueRight}</strong>`;
      detail = t("tech-user-description", {
        techUser: techUser,
        application: application,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      roleIdSvg = <AprooveRoleDeprovisioning />;
      customValueLeft = role?.id;
      customValueRight = user?.givenname + " " + user?.surname;
      customSvgLeft = <AprooveRoleDeprovisioning />;
      customSvgRight = <User />;
      const deproviseRole = `<strong>${customValueLeft}</strong>`;
      const deproviseUser = `<strong>${customValueRight}</strong>`;
      detail = t("deprovise-role-description", {
        user: deproviseRole,
        organisation: deproviseUser,
      });
      customOperationalIcon = (
        <div className="remove-icon-svg">
          <RemoveIcon />
        </div>
      );
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    default:
      roleIdSvg = <RoleRequest />;
  }

  return {
    roleIdSvg,
    customSvgLeft,
    customValueLeft,
    customOperationalIcon,
    customSvgRight,
    customValueRight,
    customAssignId,
    smsIcon,
    applicationReasonHeader,
    applicationReason,
    entitlementId,
    cardState,
    rejectReason,
    rejectReasonIcon,
    rejectReasonHeader,
    detail,
  };
};
