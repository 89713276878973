import { Grow } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from '../../ui/message/alert/Alert' // No ... This can't be shortened and no this cant be fixed in any other way

const ANIMATION_DURATION = 300

/**
 * Notification Component should be used together with `useNotification` hook.
 */
export const Notification = ({
  notification: {
    severity,
    title,
    message,
    children,
    variant,
    duration,
    key
  },
  onClose
}) => {
  const [ isOpen, setIsOpen ] = useState(true)

  const handleClose = useCallback(() => {
    setIsOpen(false)

    setTimeout(() => {
      onClose(key)
    }, ANIMATION_DURATION)
  }, [ key, onClose ])

  useEffect(() => {
    setTimeout(() => {
      handleClose()
    }, duration || 4000)
  }, [ handleClose, duration ])

  return (
    <Grow in={isOpen} timeout={ANIMATION_DURATION}>
      <Alert
        key={key}
        variant={variant}
        title={title}
        severity={severity || 'info'}
        onClose={handleClose}
        data-testid={`Notification-${key}`}
        sx={[ variant === 'outlined' && { backgroundColor: 'common.white' } ]}
      >
        {message}
        {children}
      </Alert>
    </Grow>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    severity: PropTypes.oneOf([ 'success', 'info', 'warning', 'error' ]),
    duration: PropTypes.number,
    title: PropTypes.string,
    message: PropTypes.string,
    children: PropTypes.node,
    variant: PropTypes.oneOf([ 'standard', 'outlined', 'filled' ]),
    key: PropTypes.any
  }),
  onClose: PropTypes.func
}
