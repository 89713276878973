import { Box, CircularProgress, Slide, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../button'
import { Dialog } from '../../dialog'
import { AgreementPanel } from './AgreementPanel'

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

/**
 * The AgreementsDialog component is a modal that is designed to prompt the user to accept agreements.
 * It automatically opens when there are one or more agreements that have not been agreed upon. <br>
 * The dialog provides a user-friendly interface where the user can review the agreements and take the necessary actions to accept them.
 */
export const AgreementsDialog = ({
  agreements,
  isLoading,
  onAccept = () => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ open, setOpen ] = useState(false)

  useEffect(() => {
    // check if there is a non accepted agreement
    if (Array.isArray(agreements)) {
      agreements.forEach((ele) => {
        if (ele.isAccepted === false) {
          setOpen(true)
        }
      })
    }
  }, [ agreements ])

  const handleAcceptAgreements = () => {
    onAccept()
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      headerSection={(
        <Stack
          direction="row"
          sx={{ display: 'flex', alignItems: 'center' }}
          spacing={1}
        >
          <Typography fontSize="3rem" fontWeight="bold">Alice</Typography>
          <Typography fontSize="3rem">{' for '}</Typography>
          <Typography variant="h1" sx={{ mb: 0 }}>Mercedes-Benz Group AG</Typography>
        </Stack>
      )}
      footerSection={agreements && (
        <Button variant="primary" isLoading={isLoading} onClick={handleAcceptAgreements}>
          {t('startup.agreeAndContinue')}
        </Button>
      )}
      fullscreen
      hasCloseButton={false}
      TransitionComponent={Transition}
    >
      {!agreements ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack
          sx={{
            border: '1px solid',
            borderColor: 'grey.80',
            p: 3
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>{t('startup.termsAndConditions')}</Typography>
          <Typography>{t('startup.agreeStatement')}</Typography>

          <ul>
            {agreements
              && agreements.map((ele, i) => (ele.isAccepted === false ? (
                <li key={i}>{ele.title}</li>
              ) : null))}
          </ul>

          {agreements
            && agreements.map((ele, i) => (ele.isAccepted === false ? (
              <AgreementPanel title={ele.title} text={ele.text} key={i} />
            ) : null))}
        </Stack>
      )}
    </Dialog>
  )
}

AgreementsDialog.propTypes = {
  /** Contains the user agreements. If the application store is set up properly, you will find the user agreements under `state.util.userAgreements` */
  agreements: PropTypes.array,
  /** A function to accept all agreements in the backend. See the `ComponentLibrary` actions.acceptUserAgreements for reference. */
  onAccept: PropTypes.func.isRequired
}
