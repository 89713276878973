import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useState } from 'react'
import { DayContent, DayPicker } from 'react-day-picker'
import { DateUtils } from '../../util'
import { Caption } from './Caption/Caption'
import { sxStyles } from './DatePicker.style'
import { Row } from './Row/Row'

// Styles
import 'react-day-picker/dist/style.css'

/**
 * This Component is a Custom React DayPicker.
 * https://react-day-picker.js.org/api/
 *
 * Docu for the 'disabledMatcher'
 * https://react-day-picker.js.org/api/types/Matcher
 *
 * To set DatePicker to receive range values add prop `isRange`
 *
 * To control values from outside the DatePicker component, set `parentFrom` and/or `parentTo` props to the desired values. <br>
 * The implementation of `parentFrom` & `parentTo` may vary. The important thing that they should represent RFC2822 Date Format (e.g. Tue Nov 05 1985 06:23:20 GMT+0530 (IST))
 */
export const DatePicker = forwardRef((
  {
    onChange,
    parentFrom,
    parentTo,
    isRange = false,
    disabledMatcher
  },
  ref
) => {
  const [ selected, setSelected ] = useState()

  useEffect(() => {
    setSelected({ from: parentFrom, to: parentTo })
  }, [ parentFrom, parentTo ])

  const onSelect = (dates) => {
    setSelected(dates)

    isRange ? onChange(dates) : onChange({ from: dates })
  }

  return (
    <Box data-testid="DatePicker" ref={ref} sx={sxStyles}>
      <DayPicker
        mode={isRange ? 'range' : 'single'}
        handleGoToMonth={parentFrom}
        locale={DateUtils.getCurrentLocale()}
        selected={selected}
        onSelect={onSelect}
        components={{
          DayContent: (props) => (
            <div className="dayWrapper">
              <DayContent {...props} />
            </div>
          ),
          Caption: (props) => (<Caption {...props} onSelect={onSelect} />),
          Row
        }}
        modifiersClassNames={{ selected: isRange ? 'rangeSelected' : 'singleSelected' }}
        disabled={disabledMatcher}
      />
    </Box>
  )
})

DatePicker.propTypes = {
  /**
   * onChange event with state updater func inside
   *
   * @param values
   * @returns {void}
   */
  onChange: PropTypes.func.isRequired,
  /** determines start date for manual handling */
  parentFrom: PropTypes.instanceOf(Date),
  /** determines end date for manual handling */
  parentTo: PropTypes.instanceOf(Date),
  /** determines mode of datePicker 'range' or 'single' */
  isRange: PropTypes.bool,
  /**
   * A value or a function that matches a day or range
   * https://react-day-picker.js.org/api/types/Matcher
   */
  disabledMatcher: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
    PropTypes.array
  ])
}
