import PropTypes from 'prop-types'
import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { Table } from '../table/Table'

import 'regenerator-runtime'

/**
 * A Table with predefined params. So all params from Table are also available here.
 *
 * It's designed to be connected with a backend api and then handle lots of things automatically.<br>
 * Like fetching data or calculating navigation
 *
 * Since only the displayed page is loaded, sorting just works within the current page.
 *
 * The `AjaxTable` component exposes functions to its `tableRef`, allowing external control of the table behavior.
 * Two key functions provided are:
 *
 * - `refetch`: Forces a data refetch without changing any parameters. Note that this could lead to issues if data is deleted. Use with caution, preferably when data is added or edited.
 * - `reset`: Forces a reset of all parameters except `pageSize`. This function resets the page to 1 (0) and triggers a data refetch afterward. It is recommended for use when deleting data.
 *
 * Example usage:
 *
 * ```js
 * const tableRef = useRef(null)
 *
 * const handleAdd = () => {
 *   axios.get('').then(() => {
 *     // Successfully added or edited data and some checks
 *     tableRef.current.refetch() // This will refetch the table on the current page
 *   })
 * }
 *
 * const handleDelete = () => {
 *    axios.delete('').then(() => {
 *     // Successfully delete data and some checks
 *     tableRef.current.reset() // This will reset the table to page 0
 *   })
 * }
 *
 * // ... Other configuration for AjaxTable
 *
 * return (
 *  <>
 *    <Button onClick={handleAdd}>Add</Button>
 *    <Button onClick={handleDelete}>Delete</Button>
 *
 *    <AjaxTable
 *      tableRef={tableRef}
 *      { Other configuration props go here }
 *    />
 *  </>
 * )
 * ```
 */
export const AjaxTable = ({
  itemsPerPage: _itemsPerPage = 5,
  columns,
  asyncDebounceTimeout = 500, // keyUp timeout
  fetchData,
  onSearch = (searchString) => {},
  onSelect = (data, allSelectedIds, event) => {},
  getRowId,
  infoText,
  searchPlaceholder = 'Search here...',
  debug = false,
  showSearch = true,
  multiselect = true,
  preselectedRowIds,
  tableRef,
  ...otherProps
}) => {
  const tableInstance = useRef(null)

  useImperativeHandle(tableRef, () => ({
    refetch: () => handleRefetch(),
    reset: () => handleReset(),
    ...tableInstance.current
  }))

  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ itemsPerPage ] = useState(_itemsPerPage)
  const [ pageCount, setPageCount ] = useState(0)
  const [ totalCount, setTotalCount ] = useState(0) // all data items
  const [ selectedRows, setSelectedRows ] = useState({})

  const handleRefetch = () => _fetchData({
    pageSize: itemsPerPage,
    pageIndex: tableInstance?.current?.state?.pageIndex || 0
  })

  const handleReset = () => {
    dataFromSearchResult.current = null

    _fetchData({ pageSize: itemsPerPage, pageIndex: 0 })

    if (tableInstance.current) {
      tableInstance.current.gotoPage(0)
    }
  }

  // on a search the data of the search is saved here:
  const dataFromSearchResult = useRef(null)

  // Will be called initially
  const _fetchData = useCallback(async ({
    pageSize = 5,
    pageIndex = 0,
    sortBy
  }) => {
    const startRow = pageSize * pageIndex
    const endRow = startRow + pageSize

    // if a search is active:
    // no need to wait... return the page of the search result
    if (dataFromSearchResult.current) {
      setData(dataFromSearchResult.current.slice(startRow, endRow))
      return
    }

    setLoading(true)

    fetchData(pageSize, pageIndex, startRow, endRow, sortBy)
      .then(([ total, data ]) => {
        const pageCount = total && Math.ceil(Number(total) / pageSize)

        setLoading(false)

        setPageCount(pageCount)
        setTotalCount(total)
        setData(data)
      })
      .catch((e) => console.error('Error:', e))
  }, [ fetchData ])

  const _onSearch = useAsyncDebounce(async (searchString) => {
    // is called when the user enters something in the search input field
    console.log(`# AJAXTABLE search for: ${searchString}`)
    setLoading(true)

    const data = await onSearch(searchString)
    const _pageSize = !!data?.length && Math.ceil(data.length / itemsPerPage)

    dataFromSearchResult.current = data

    setLoading(false)
    setPageCount(_pageSize)
    setTotalCount(data?.length)

    if (tableInstance.current) {
      tableInstance.current.gotoPage(0)
    }

    setData(data.slice(0, itemsPerPage))
  }, asyncDebounceTimeout)

  const onSearchClearInputfield = () => handleReset()

  return (
    <Table
      data-testid="AjaxTable"
      columns={columns}
      data={data}
      ref={tableInstance}
      manualSortBy
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      controlledFetchData={_fetchData}
      controlledIsLoading={loading}
      controlledOnSearch={_onSearch}
      controlledOnSearchClearInputfield={onSearchClearInputfield}
      getRowId={getRowId}
      pageCount={pageCount}
      totalCount={totalCount}
      itemsPerPage={itemsPerPage}
      searchPlaceholder={searchPlaceholder}
      onSelect={onSelect}
      infoText={infoText}
      debug={debug}
      multiselect={multiselect}
      showSearch={showSearch}
      preselectedRowIds={preselectedRowIds}
      {...otherProps}
    />
  )
}

AjaxTable.propTypes = {
  /**
   * Header: The title of the column header
   * key: This key is used to return the according data with this key inside the returned object
   * style: A style object which will be set on the column header, example: {color: '#af0', textDecoration: 'underline'}
   * sortable: Defines if the column is sortable on a mouse click
   * Cell: A custom render method to render the content of the cell. Param: "row". (value of the cell = row.value)
   */
  columns: PropTypes.array.isRequired,
  /** show num items per page */
  itemsPerPage: PropTypes.number,
  /** menu items for the num of items per page */
  selectableItemsPerPage: PropTypes.arrayOf(PropTypes.string),
  /** Timeout in ms to wait after keyUp to trigger "onSearch" callback to prevent multiple calls at once. */
  asyncDebounceTimeout: PropTypes.number,
  /** param: (rowData). Must return an unique id */
  getRowId: PropTypes.func.isRequired,
  /**
   *  Set the rowId which should be selected or deselected.
   *
   *  Example: ${'{1: true, 3: false, 6: true}'}:
   *  Select rowId 1 and 6, deselect rowId 3.
   */
  preselectedRowIds: PropTypes.array,
  /**
   * param: (pageSize, pageIndex, startRow, endRow, sortBy).
   *
   * On header click `sortBy` returns an object: `{id: string, desc: bool, header: string}`
   * where `id` represents a column number, `desc` - sort order, `header` - header name.
   * So with `sortBy` param it is possible to define sorting functionality in the parent
   * component.
   *
   * Needs to return an array: [total, data.slice(startRow, endRow)]
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * param: (searchString).
   *
   * Needs to return an array: [total, data.slice(startRow, endRow)]
   */
  onSearch: PropTypes.func,
  /**
   * This Method is triggered everytime "selectedRowIds" are getting changed
   * - clicking on a row
   * - clicking on toggleAll checkbox in multiselect
   *
   * Note! -> onSelect doesn't pass the event anymore, use onRowClick instead.
   *
   * @param selectedRows
   * @param selectedRowIds
   * @returns {void}
   */
  onSelect: PropTypes.func,
  /**
   * This Event is triggered when clicking on a row
   * -> if not "readOnly"
   *
   * @param row
   * @param isChecked
   * @param event
   * @returns {void}
   */
  onRowClick: PropTypes.func,
  /**
   * This Event is triggered when clicking on the "toggleAllBtn" in multiselect mode
   *
   * @param changedRows
   * @param isChecked
   * @returns {void}
   */
  onToggleAllInPage: PropTypes.func,
  /** Defines whether table is multiselect */
  multiselect: PropTypes.bool
}
