import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Badge, UserCard } from "@alice/component_library";
import ViewSwitch from "@components/ViewSwitch";
import { useWidth } from "@utils/hooks";
import { ConfiguredRolesHeader } from "../../styledComponents";

const Header = ({
  requestReason,
  view,
  setView,
  configuredRoles = [],
  selectedUser = {},
}) => {
  const { t } = useTranslation();
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm"].includes(widthPanel);
  // const count = configuredRoles.length;
  const totalCards = configuredRoles?.reduce((acc, roleData) => {
    try {
      // Check for scope data existence
      const hasScopeData = roleData?.configurationInformation?.scopeData?.data;
      if (hasScopeData) {
        for (const itemData of Object.values(hasScopeData)) {
          // Check for customscope existence in each scope data
          if (itemData?.customScope?.length)
            acc += itemData?.customScope?.length;
          else acc += 1;
        }
      } else acc += 1;
      return acc;
    } catch (error) {
      console.log("Error calculating total cards:", error);
      return acc;
    }
  }, 0);

  useEffect(() => {
    if (isMobile) setView("grid");
  }, [isMobile, setView]);

  return (
    <>
      <Typography noWrap variant="h2" className="summary-header">
        {t("summary")}
      </Typography>
      {!!selectedUser?.id && (
        <>
          <Typography noWrap variant="h3">
            {t("user")}
          </Typography>
          <Grid container sx={{ margin: "8px 0 64px 0" }}>
            <Grid item xs={12} md={6} lg={4}>
              <UserCard userData={selectedUser} readOnly size="medium" />
            </Grid>
          </Grid>
        </>
      )}
      <Typography noWrap variant="h3" className="request-reason-header">
        {t("request-reason")}
      </Typography>
      <Typography noWrap variant="body1" className="request-reason">
        {requestReason}
      </Typography>
      <ConfiguredRolesHeader>
        <Typography noWrap variant="h3">
          {t(totalCards > 1 ? "configured-roles" : "configured-role")}
        </Typography>
        {!!totalCards && (
          <Badge className="badge-count" filled>
            {totalCards}
          </Badge>
        )}
        {!isMobile && (
          <ViewSwitch
            viewLabel={`${t("view")}:`}
            viewConfig={[
              {
                icon: GridViewIcon,
                tooltip: t("view-config.grid"),
                id: "grid",
              },
              {
                icon: ReorderIcon,
                tooltip: t("view-config.list"),
                id: "list",
              },
            ]}
            activeView={view}
            onChange={(view) => setView(view)}
          />
        )}
      </ConfiguredRolesHeader>
    </>
  );
};

export default Header;
