export const EnvironmentUrl = {
  PRODUCTION: 'alice.mercedes-benz.com',
  INTEGRATION: 'alice-int.mercedes-benz.com',
  TEST: 'alice-test.mercedes-benz.com',
  DEVELOPMENT: 'alice-dev.app.corpintra.net',
  GEMS: 'gems',
  GEMS_K8S: 'localk8s.infra',
  LOCAL: 'localhost',
  LOCAL_IPV4: '127.0.0.1',
  LOCAL_IPV6: '[::1]'
}
