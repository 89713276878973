import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { AliceIcon, AliceIconType, Tooltip } from "@alice/component_library";
import RoleRequestIcon from "@Icons/newRequestPage/RoleRequest.svg";
import { useWidth } from "@utils/hooks";
import {
  IdContainer,
  ResultRoleCard,
  RoleIdContainer,
  ResponseIdContainer,
  WorkflowIdContainer,
  ScopeContainer,
} from "../../styledComponents";
import { STATUS_OPTIONS } from "./utils";

const WorkflowId = ({ status, responseId }) => {
  return (
    <>
      {status === "success" && (
        <WorkflowIdContainer>
          <RoleRequestIcon />
          <Link to={`/access/requests/details?taskId=${responseId}`}>
            <Typography variant="body1">{responseId}</Typography>
          </Link>
        </WorkflowIdContainer>
      )}
    </>
  );
};

const ResultCard = ({
  status = "success",
  roleDetails = {},
  responseResult,
}) => {
  const { bgColor } = STATUS_OPTIONS[status];

  const {
    id: roleId = "A-001-ABH-PORTAL-KBV-ACCESS-USER",
    name: roleName = "A-001-ABH-PORTAL-KBV-ACCESS-USER",
    needsCustomScopes = true,
    needsOrgScopes = true,
  } = roleDetails || {};

  const {
    responseId = "W2305251",
    orgScope = "MTU Asia Pte. Ltd. Regional Headquarter",
    customScope = "product-monitoring",
  } = responseResult || {}; // define the prop name

  const { t } = useTranslation();
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm"].includes(widthPanel);

  return (
    <ResultRoleCard bgColor={bgColor}>
      {isMobile && status === "success" && (
        <>
          <WorkflowId status={status} responseId={responseId} />
          <Divider sx={{ margin: "12px 0" }} />
        </>
      )}
      <ResponseIdContainer singleColumn={status !== "success"}>
        <RoleIdContainer>
          <IdContainer>
            <Typography variant="caption">{t("id")}</Typography>
          </IdContainer>
          <Tooltip title={roleId} type="ellipsized">
            <Typography className="role-id" variant="subtitle1" noWrap>
              {roleId}
            </Typography>
          </Tooltip>
        </RoleIdContainer>
        {!isMobile && <WorkflowId status={status} responseId={responseId} />}
      </ResponseIdContainer>
      <Tooltip type="ellipsized" title={roleName}>
        <Typography variant="body2" noWrap className="role-name">
          {roleName}
        </Typography>
      </Tooltip>
      {(needsCustomScopes || needsOrgScopes) && (
        <ScopeContainer>
          {isMobile && <Divider />}
          {needsOrgScopes && (
            <div className="orgScope">
              <AliceIcon
                iconType={AliceIconType.UIDate_From}
                size={16}
                color="#00000099"
              />
              {!!orgScope ? (
                <Tooltip type="ellipsized" title={orgScope}>
                  <Typography variant="body2" noWrap>
                    {orgScope}
                  </Typography>
                </Tooltip>
              ) : (
                "-"
              )}
            </div>
          )}
          {needsCustomScopes && (
            <div className="orgScope">
              <AliceIcon
                iconType={AliceIconType.CUSTOM_SCOPE}
                size={16}
                color="#00000099"
              />
              {!!customScope ? (
                <Tooltip type="ellipsized" title={customScope}>
                  <Typography variant="body2" noWrap>
                    {customScope}
                  </Typography>
                </Tooltip>
              ) : (
                "-"
              )}
            </div>
          )}
        </ScopeContainer>
      )}
    </ResultRoleCard>
  );
};

export default ResultCard;
