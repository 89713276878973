import React from 'react'
import { blue, green, grey, LightTheme, red, yellow } from '@mercedes-benz/mui5-theme'
import '@mercedes-benz/typeface-mb-corpo-a'

// Fonts
import '@mercedes-benz/typeface-mb-corpo-s'
import { createTheme, CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

// Styling
import './global.css'

export const AliceThemeProvider = ({ children }) => {
  // Colors
  LightTheme.palette.background = { default: '#fff' }
  LightTheme.palette.grey = grey
  LightTheme.palette.blue = blue
  LightTheme.palette.green = green
  LightTheme.palette.yellow = yellow
  LightTheme.palette.red = red

  // adaptation based on official colors
  // todo: focus missing + designTalk to make official
  LightTheme.palette.interaction = {
    default: 'rgb(255, 255, 255)', // white
    default2: 'rgb(250, 250, 250)',
    hover: 'rgb(245, 245, 245)', //
    hover2: 'rgb(240, 240, 240)',
    selected: 'rgb(214, 233, 248)',
    selected2: 'rgb(209, 228, 243)',
    selectedHover: 'rgb(194, 223, 245)',
    selectedHover2: 'rgb(189, 218, 240)',
    error: 'rgb(255, 237, 237)',
    error2: 'rgb(250, 232, 232)',
    errorHover: 'rgb(252, 222, 222)',
    errorHover2: 'rgb(247, 218, 218)'
  }

  // Breakpoints
  LightTheme.breakpoints = {
    values: {
      ...LightTheme.breakpoints.values,
      xxl: 2500
    },
    keys: [ ...LightTheme.breakpoints.keys, 'xxl' ]
  }

  // Typography
  LightTheme.typography = {
    ...LightTheme.typography,
    bodyLarge: {
      fontFamily: 'MB Corpo S Text',
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '32px'
    }
  }

  LightTheme.components = {
    MuiAppBar: { styleOverrides: { colorPrimary: { backgroundColor: '#000' } } },
    MuiCheckbox: { styleOverrides: { root: { '&.MuiCheckbox-indeterminate': { color: '#0078D6' } } } },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            '&.Mui-disabled': {
              color: '#0078D6',
              opacity: 0.3
            }
          }
        }
      }
    },
    // Can be removed when https://github.com/mui/material-ui/issues/37904 is fixed
    MuiStack: { defaultProps: { useFlexGap: true } },
    ...LightTheme.components
  }

  return (
    <ThemeProvider theme={createTheme(LightTheme)}>
      <CssBaseline>
        <StyledEngineProvider injectFirst>
          {children}
        </StyledEngineProvider>
      </CssBaseline>
    </ThemeProvider>
  )
}
