import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined'
import { Box, Checkbox, MenuItem, MenuList, Popover, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '../../form'
import { Message } from '../../message'

const margin = 16
const minHeight = 300

/**
 * filter = {
 *   label: 'Continent',
 *   value: 'continent',
 *   icon: <></>
 * }
 */
export const FilterPopover = ({
  isOpen,
  anchorEl,
  possibleFilters = [],
  activeFilters = [],
  hiddenFilters = [],
  disabledFilters = [],
  isSearchShown = true,
  isAllShown = true,
  searchLabel,
  allLabel,
  isMultiselect = false,
  filtersPerRow = 6,
  maxFilterRows = 3,
  onAllClick = () => {},
  onChange = (value, isActive, isMultiselect) => {},
  onClose = () => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const searchRef = useRef(null)
  const allRef = useRef(null)
  const contentRef = useRef(null)

  const [ searchQuery, setSearchQuery ] = useState('')
  const [ popoverMaxHeight, setPopoverMaxHeight ] = useState(null)

  const [ contentMaxHeight, setContentMaxHeight ] = useState(null)
  const [ contentHeight, setContentHeight ] = useState(null)
  const [ contentWidth, setContentWidth ] = useState(null)

  const isExceedingMaxFilterRows = useMemo(
    () => possibleFilters.length > filtersPerRow * maxFilterRows,
    [ possibleFilters, filtersPerRow, maxFilterRows ]
  )

  const updateContentSize = useCallback(() => {
    if (!contentRef?.current) return

    setContentWidth(contentRef.current.offsetWidth)
    setContentHeight(contentRef.current.offsetHeight)
  }, [ contentRef ])

  const updateContentMaxHeight = useCallback((maxPopoverHeight) => {
    // Adding Popover Top / Bottom Padding
    let contentHeightOffset = margin * 2

    // Adding height of the search bar and the bottom margin of it
    if (searchRef?.current) contentHeightOffset += searchRef.current.offsetHeight + margin

    // Adding height of the all checkbox and the bottom margin of it
    if (allRef?.current) contentHeightOffset += allRef.current.offsetHeight + margin

    const contentHeight = maxPopoverHeight - contentHeightOffset

    setContentMaxHeight(contentHeight)
  }, [ searchRef, allRef ])

  const updatePopoverHeight = useCallback(() => {
    if (!anchorEl) return

    const windowHeight = window.innerHeight
    const anchorRect = anchorEl.getBoundingClientRect()
    const maxPopoverHeight = windowHeight - anchorRect.bottom - margin
    const newMaxPopoverHeight = maxPopoverHeight > minHeight ? maxPopoverHeight : minHeight

    setPopoverMaxHeight(newMaxPopoverHeight)
    updateContentMaxHeight(newMaxPopoverHeight)
  }, [ anchorEl, updateContentMaxHeight ])

  useEffect(() => {
    updatePopoverHeight()

    window.addEventListener('resize', updatePopoverHeight)

    return () => {
      window.removeEventListener('resize', updatePopoverHeight)
    }
  }, [ updatePopoverHeight ])

  const filteredFilters = useMemo(
    () => possibleFilters.filter((filter) => (filter.label.toLowerCase().includes(searchQuery.toLowerCase()) || filter.value.toLowerCase().includes(searchQuery.toLowerCase()))),
    [ possibleFilters, searchQuery ]
  )

  const handleAllClick = () => onAllClick()

  const isAllSelected = () => {
    if (activeFilters.length <= 0) return true

    for (let i = 0; i < possibleFilters.length; i++) {
      if (activeFilters.includes(possibleFilters[i].value)) return false
    }

    return true
  }

  const handleSearchChange = (event) => {
    if (!contentWidth) updateContentSize()

    setSearchQuery(event.target.value)
  }

  return (
    <Popover
      data-testid="FilterPopover"
      open={isOpen}
      onClose={onClose}
      marginThreshold={16} /* Otherwise console is getting spammed with falsy errors. */
      keepMounted /* Needs to be mounted. Otherwise, there are jumps on first open. */
      sx={{ marginTop: 1, borderRadius: '4px' }}
      anchorEl={anchorEl}
      disablePortal
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      slotProps={{ paper: { sx: { maxHeight: popoverMaxHeight || '100%' } } }}
    >
      <Stack sx={{ minWidth: '200px', p: 2 }} gap={2}>
        {isSearchShown && (
          <TextField
            ref={searchRef}
            variant="standard"
            label={searchLabel || t('general.search')}
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
          />
        )}

        {isAllShown && (
          <MenuItem
            ref={allRef}
            sx={{ minWidth: '200px', width: 'fit-content' }}
            selected={isAllSelected()}
            onClick={handleAllClick}
          >
            <Checkbox checked={isAllSelected()} />

            {allLabel || t('general.all')}
          </MenuItem>
        )}

        <Box
          ref={contentRef}
          sx={{
            maxHeight: contentMaxHeight ? `${contentMaxHeight}px` : undefined,
            height: contentHeight ? `${contentHeight}px` : undefined,
            width: contentWidth ? `${contentWidth}px` : undefined,
            overflow: 'auto'
          }}
        >
          {filteredFilters.length
            ? (
              <MenuList
                sx={[
                  isExceedingMaxFilterRows
                    ? {
                      display: 'flex',
                      flexDirection: 'column'
                    }
                    : {
                      display: 'grid',
                      gridAutoFlow: 'column',
                      gridTemplateColumns: `repeat(${Math.floor(filteredFilters.length / filtersPerRow)}, 1fr)`,
                      gridTemplateRows: `repeat(${filteredFilters.length > filtersPerRow ? filtersPerRow : filteredFilters.length}, 1fr)`,
                      columnGap: 2
                    }
                ]}
              >
                {filteredFilters.map((filter) => (
                  !hiddenFilters.includes(filter.value) && (
                    <MenuItem
                      key={filter.value}
                      disabled={disabledFilters.includes(filter.value)}
                      selected={activeFilters.includes(filter.value)}
                      onClick={() => onChange(filter.value, !activeFilters.includes(filter.value), isMultiselect)}
                    >
                      {isMultiselect && <Checkbox checked={activeFilters.includes(filter.value)} />}
                      {filter.renderer || filter.label}
                    </MenuItem>
                  )
                ))}
              </MenuList>
            )
            : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Message
                  icon={<ContentPasteSearchOutlinedIcon sx={{ fontSize: '2rem' }} />}
                  title={<Typography variant="h5">{t('filterChip.noResultsTitle')}</Typography>}
                  description={(
                    <Typography
                      variant="body"
                      color="grey.500"
                      sx={{ textAlign: 'center' }}
                    >
                      {t('filterChip.noResultsDescription')}
                    </Typography>
                  )}
                />
              </Box>
            )}
        </Box>
      </Stack>
    </Popover>
  )
}

FilterPopover.propTypes = {
  /** Defines if the popover is open */
  isOpen: PropTypes.bool,
  /** Anchor of the popover */
  anchorEl: PropTypes.any,
  /** Popover search label */
  searchLabel: PropTypes.string,
  /** Popover all button label */
  allLabel: PropTypes.string,
  /** Popover multiselect */
  isMultiselect: PropTypes.bool,
  /** Defines if search should be shown in popover */
  isSearchShown: PropTypes.bool,
  /** Defines if all button should be shown in popover */
  isAllShown: PropTypes.bool,
  /** All possible filters in popover */
  possibleFilters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      renderer: PropTypes.node
    })
  ),
  /** All active filters in popover. Should be the value of the filter */
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  /** Defines the number of filters per row in popover */
  filtersPerRow: PropTypes.number,
  /** All button click callback */
  onAllClick: PropTypes.func,
  /** On change callback */
  onChange: PropTypes.func,
  /** On close callback */
  onClose: PropTypes.func
}
