import { Typography, Stack, Grid, styled } from "@mui/material";

export const TaskMenuCard = styled((props) => <Grid item {...props} />)(() => ({
  height: "fit-content",
  padding: 0,
  boxShadow: 0,
  border: "1px solid #ddd",
  marginTop: "52px",
  borderRadius: "4px",
  "& .task-menu-item:not(:last-child)": {
    borderBottom: "1px solid #ddd",
  },
}));

export const TaskMenuCardTitle = styled(Typography)({
  userSelect: "none",
  color: "#000",
  fontSize: 20,
  fontWeight: "700",
  lineHeight: "28px",
});

export const TaskMenuCardContent = styled((props) => (
  <Stack
    direction="row"
    spacing={2}
    justifyContent={props.iscollapsed ? "center" : "space-between"}
    {...props}
  />
))({
  position: "relative",
  padding: "12px 20px",
  borderBottom: "1px solid #ddd",
});

export const TaskMenuItems = styled((props) => (
  <Stack direction="row" spacing={2} alignContent="center" {...props} />
))(({ ...props }) => ({
  position: "relative",
  padding: "12px 20px",
  cursor: "pointer",
  alignItems: "center",
  backgroundColor: props.selected && "#f3f3f3",
  borderLeft: props.selected ? "5px solid #0078D6" : "5px solid #fff",
  "&:hover": {
    backgroundColor: "#f3f3f3",
    color: "#000",
    borderLeft: props.selected ? "5px solid #0078D6" : "5px solid #f3f3f3",
  },
}));

export const TaskMenuItemIcon = styled("div")({
  position: "relative",
  display: "inline-flex",
});

export const TaskMenuItemsHeader = styled(Typography)({
  color: "#000000DE",
  fontWeight: "400",
  size: "16px",
  lineHeight: "18px",
  marginLeft: "25px !important",
  overflowWrap: "anywhere",
});

export const TaskMenuItemsBadge = styled("div")({
  position: "absolute",
  transform: "scale(1) translate(50%, -30%)",
  top: 0,
  right: 0,
});
