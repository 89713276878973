import PropTypes from 'prop-types'

export const EntitlementType = {
  ENTITLEMENT: 'ENTITLEMENT',
  AUTHORIZATION: 'AUTHORIZATION'
}

export const EntitlementTypeKeys = Object.keys(EntitlementType)
export const EntitlementTypes = Object.keys(EntitlementType)

export const EntitlementTypePropType = PropTypes.oneOf(EntitlementTypes)

export const EntitlementTypeArgType = {
  options: EntitlementTypes,
  control: 'select',
  description: `
   Entitlement Type
   
   - **EntitlementType.ENTITLEMENT ('ENTITLEMENT')**: The Entitlement is of type "ENTITLEMENT"
   - **EntitlementType.AUTHORIZATION ('AUTHORIZATION')**: The Entitlement is of type "AUTHORIZATION"
  `
}
