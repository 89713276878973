import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class ApplicationsService {
  static getAll = ({ ...args }) => {
    return AliceService.get(
      '/applications',
      { ...args }
    )
  }

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/applications/${id}`,
    { ...args }
  )

  static updateById = ({
    id,
    application,
    ...args
  }) => AliceService.put(
    `applications/${id}`,
    mergeObjects(
      { body: application },
      { ...args }
    )
  )

  static getEntitlementById = ({
    appId,
    entitlementId,
    ...args
  }) => AliceService.get(
    `/applications/${appId}/entitlements/${entitlementId}`,
    { ...args }
  )

  static getAllEntitlementsById = ({
    id,
    ...args
  }) => AliceService.get(
    `/applications/${id}/entitlements`,
    { ...args }
  )

  static getAllSupportContacts = ({
    id,
    ...args
  }) => AliceService.get(
    `/applications/${id}/supportcontacts`,
    { ...args }
  )

  static createEntitlement = ({
    id,
    entitlement,
    ...args
  }) => AliceService.post(
    `/applications/${id}/entitlements`,
    mergeObjects(
      { body: { ...entitlement } },
      { ...args }
    )
  )
}
