import { Button, Dialog, DialogTitle, Typography, Box } from "@mui/material";
import { Card, UserAvatar } from "@alice/component_library";
import { styled } from "@mui/system";

const customScrollerStyles = {
  "&::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "16px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },
  "&::-webkit-scrollbar-track:hover": {
    backgroundColor: "#f4f4f4",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
    border: "5px solid #fff",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a0a0a5",
    border: "4px solid #f4f4f4",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

export const RoleConfigHead = styled("h2")({
  fontFamily: "MB Corpo A Text Cond",
  fontSize: "40px",
  fontWeight: "400",
  lineHeight: "40px",
});

export const MainViewHeaderStyle = styled("div")({
  display: "flex",
  padding: "16px 32px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  gap: "40px",
  alignSelf: "stretch",
  borderRadius: "0px 4px 0px 0px",
  borderBottom: "1px solid  #DBDBDB",
  background: "var(--mbGrey-97, #FAFAFA)",
  height: "69px",
});

export const ConfiguredRoleCount = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
});

export const MainViewContainer = styled("div")(({ theme }) => ({
  borderRadius: "4px 0px 0px 4px",
  border: "1px solid var(--others-divider, #DBDBDB)",
  display: "grid",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "35% 65%",
  },
}));

export const SideBarContainer = styled("div")({
  display: "flex",
  padding: "0px 8px",
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: 0,
});

export const SideBarWrapper = styled("div")({
  minHeight: "100vH",
  maxHeight: "162vH",
  overflow: "hidden",
  overflowY: "auto",
  borderRadius: "4px 0px 0px 4px",
  borderRight: "1px solid #DBDBDB",
  paddingBottom: "10px",
  ...customScrollerStyles,
});

export const MainviewContainer = styled("div")({
  position: "relative",
});

export const RoleRequestFooterConatiner = styled("div")(({ theme }) => ({
  position: "sticky",
  bottom: 0,
  zIndex: 1000,
  background: "rgba(244, 244, 244, 1)",
  [theme.breakpoints.down("lg")]: {
    padding: "20px 20px 0px",
  },
}));
export const StyledConfiguredRoleCount = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "8px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "12px",
  },
}));
export const MainViewFooterStyle = styled("div")(
  ({ isPreviousButtonVisible }) => ({
    display: "flex",
    padding: "10px 0",
    flexDirection: "row",
    justifyContent: !isPreviousButtonVisible ? "flex-end" : "space-between",
    alignItems: "flex-end",
    gap: "40px",
    alignSelf: "stretch",
    // borderRadius: "0px 4px 0px 0px",
    // borderTop: "1px solid  #DBDBDB",
    // borderRight: "1px solid  #DBDBDB",
    // background: "var(--mbGrey-97, #FAFAFA)",
    height: "69px",
    "& .footer-container": {
      display: "flex",
      alignItems: "center",
      "& p": {
        color: "var(--text-secondary-inactive, rgba(0, 0, 0, 0.60))",
        fontFamily: "MB Corpo S Text",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        padding: "0 24px 0 8px",
      },
      "& div": {
        display: "flex",
      },
    },
  })
);

export const NewReqMainPage = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
  paddingBottom: "10vh",
}));

export const NewReqCards = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "26px",
  rowGap: "26px",
  flexWrap: "wrap",
}));

export const NewReqheader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  flexWrap: "wrap",
}));

export const HeaderTextContanier = styled("div")(({ theme }) => ({
  margin: "24px 0px 32px 0px",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
}));

export const HeaderTitle = styled((props) => (
  <Typography variant="h1" {...props} />
))(({ ...props }) => ({
  fontFamily: "MB Corpo A Text Cond",
  fontWeight: "400",
  fontSize: "48px",
  lineHeight: "56px",
  marginBottom: "0px",
}));

export const HeaderDescription = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  wordBreak: "break-word",
});

export const StyledDialog = styled((props) => <Dialog {...props} />)(
  ({ theme, ...props }) => ({
    [theme.breakpoints.up("md")]: {
      "& .MuiPaper-root.MuiDialog-paper": {
        minWidth: "848px",
      },
    },
  })
);

export const CloseButton = styled((props) => <Button {...props} />)(
  ({ ...props }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#000000",
    padding: "6px",
    minWidth: 36,
    width: 36,
    height: 36,
    "&:hover": {
      backgroundColor: "#000000",
    },
    "& svg": {
      color: "#FFFFFF",
    },
  })
);

export const StyledDialogTitle = styled((props) => <DialogTitle {...props} />)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Title",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "24px 24px 16px",
  })
);

export const FileSelectContainer = styled("div")(({ theme }) => ({
  padding: "24px",
}));

export const FileSelectTexts = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  padding: "0 0 24px 0",
}));

export const FooterContainer = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const BulkImportOptions = styled("div")(({ files, theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
  padding: "16px 0px 24px 0px",
  ...(files === null && {
    opacity: "0.5",
    pointerEvents: "none",
  }),
}));

export const ImportOptionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px 24px",
  rowGap: "16px",
}));

export const FileSelectTitle = styled((props) => (
  <Typography variant="h6" {...props} />
))(({ ...props }) => ({
  fontFamily: "MB Corpo S Title",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 1)",
}));

export const FileSelectDescription = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.6)",
  wordBreak: "break-word",
});

export const HeadlineTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 1)",
});

export const ImportOptionsTitle = styled((props) => (
  <Typography variant="h6" {...props} />
))(({ ...props }) => ({
  fontFamily: "MB Corpo S Title",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 1)",
}));

export const HeadlineSwitchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  padding: "0px 16px 0px 24px",
}));

export const SwitchTypo = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(51, 51, 51, 1)",
  letterSpacing: "0.2px",
  wordBreak: "break-word",
});

export const FileEncodingContainer = styled("div")(({ theme }) => ({
  padding: "0px 24px",
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "end",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100vw",
  },
}));

export const FileEncodingTitle = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 1)",
});

export const BulkImportFooter = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "14px 24px",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  columnGap: "15px",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    justifyContent: "end",
    rowGap: "15px",
  },
}));

export const LoaderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  minHeight: "100px",
  background: "#e5f1fb",
  padding: "24px",
  margin: "12px 0",
});

export const LoaderStatusContainer = styled("div")({
  paddingLeft: "24px",
  display: "flex",
  flexDirection: "column",
  wordBreak: "break-word",
});

export const StatusIconContatiner = styled("div")({
  display: "flex",
  flexBasis: "8%",
  maxWidth: "80px",
  justifyContent: "center",
  "& span": {
    height: "40px",
    width: "40px",
    backgroundSize: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.active-icon-task svg": {
      color: "#6EA046",
      fontSize: "1.75rem",
    },
    "&.task-deactive-icon svg": {
      color: "#9F1924",
      fontSize: "1.75rem",
    },
  },
});

export const StatusHeaderContatiner = styled("div")({
  fontWeight: 700,
  fontSize: "16px",
  wordBreak: "break-word",
});

export const HelpDocAndSampleFileContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  marginBottom: "10px",
  "& .sampleFile,.helpDoc": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
    "& svg": {
      color: "#0078D6",
    },
  },
}));

export const FooterDescription = styled(Typography)({
  fontFamily: "MB Corpo S Text",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "rgba(0, 0, 0, 0.6)",
  wordBreak: "break-word",
});
export const RoleRequestMainConatiner = styled("div")(({ theme }) => ({
  flexGrow: "1",
  padding: "20px 0",
  [theme.breakpoints.down("lg")]: {
    margin: "0 20px",
    "& .MuiStepper-root": {
      flexWrap: "wrap",
    },
    "& .MuiStepConnector-root": {
      display: "none",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .stepper-container": {
      border: "1px solid #0000001F",
      padding: "14px",
    },
  },
}));

//#region header

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "32px",
  margin: "48px 0",
  "& .user-search-icon": {
    border: "2px solid #00000099",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    height: "65px",
    width: "65px",
    minWidth: "65px",
    minHeight: "65px",
    [theme.breakpoints.up("md")]: {
      height: "112px",
      width: "112px",
      minWidth: "112px",
      minHeight: "112px",
    },
    "& svg": {
      color: "#00000099",
      [theme.breakpoints.up("md")]: {
        fontSize: "3.4rem",
      },
      fontSize: "2.4rem",
    },
  },
}));

export const HeaderAvatar = styled((props) => (
  <UserAvatar
    sizeStyle={{
      height: props.isMobile ? "65px" : "112px",
      width: props.isMobile ? "65px" : "112px",
      fontSize: props.isMobile ? "1.5rem" : "3rem",
      dotSize: props.isMobile ? "18px" : "28px",
    }}
    {...props}
  />
))(() => ({}));
export const HeaderTypography = styled((props) => <Typography {...props} />)(
  () => ({
    marginBottom: 0,
    fontFamily: "MB Corpo A Text Cond",
  })
);
//#endregion
export const Footer = styled("div")({
  backgroundColor: "#F4F4F4",
  position: "sticky",
  bottom: 0,
  zIndex: 1,
});
export const DataContainer = styled("div")(() => ({
  flex: 1,
  padding: "40px 0",
}));
//#region role selection styles
export const RoleSelectionHeader = styled((props) => (
  <Typography variant="h2" {...props} />
))(() => ({
  fontWeight: 400,
}));

export const RolesContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr minmax(0, 1fr)",
  gridAutoRows: "1fr",
  gap: "26px",
  padding: "20px 0",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  "& .role-grid-container": {
    "& .filter-container": {
      display: "grid",
      gap: "24px",
      margin: "24px 0",
    },

    "& .role-grid": {
      display: "grid",
      overflow: "hidden",
      gap: "24px 26px",
      gridAutoFlow: "dense",

      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      "& .role-inlay": {
        gridColumn: "1 / -1",
        overflow: "hidden",
        "& >[data-testid=Inlay] >.MuiCollapse-root >.MuiCollapse-wrapper >.MuiCollapse-wrapperInner":
          {
            "& >.MuiGrid-root": {
              border: "1px solid #DBDBDB",
              borderRadius: "4px",
            },
            "& >.MuiBox-root span": {
              textShadow: "white 0px 0px, #DBDBDB 0px -2px 0px !important",
            },
          },
        // "& >.MuiBox-root": {
        //   width: "100%",
        //   "& > span": {
        //     display: "none !important",
        //   },
        // },
      },
    },
    "& .roles-container": {
      overflowY: "auto",
      position: "relative",
      // ...customScrollerStyles,
      "& >hr": {
        position: "sticky",
        top: "1px",
      },
    },
    "& .sticky-role-filter": {
      position: "sticky",
      top: "0px",
      paddingTop: "16px",
      zIndex: "100",
      background: "#FFFFFF",
    },
  },
}));

export const NoRolesContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "40px",
}));

export const SelectedRolesContainer = styled((props) => <Box {...props} />)(
  ({ ...props }) => ({
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "16px",
    maxHeight: "calc(100vh - 184px)",
    height: "calc(100vh - 184px)",
    position: "sticky",
    top: "16px",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    "& .selected-role-grid": {
      height: "100%",
      overflowY: "auto",
      marginTop: "24px",
      flex: 1,
      ...customScrollerStyles,
    },
  })
);

export const SelectedRolesHeader = styled((props) => (
  <Typography varinat="h4" {...props} />
))(() => ({
  fontSize: "1.625rem",
  fontFamily: "MB Corpo S Title",
  paddingBottom: "16px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "& #selected-roles-count": {
    backgroundColor: "#0078D6 !important",
    fontSize: "12px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    fontFamily: "MB Corpo S Text !important",
    lineHeight: "16px !important",
  },
}));

export const NoRolesSelectedContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  gap: "27px",
  padding: "24px 0",
}));

export const NoRolesSelectedText = styled((props) => (
  <Typography variant="body1" {...props} />
))(() => ({}));

export const SelectedRolesCard = styled(Card)(() => ({
  padding: "16px",
  marginBottom: "16px",
  "& .role-card": {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "1fr 60px",
    alignItems: "center",
    overflow: "hidden",
    "& .header-container": {
      overflow: "hidden",

      "& .role-cardv3-header": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        "& .id-holder": {
          background: "#00000014",
          padding: "5.5px 4px",
          borderRadius: "2px",
          lineHeight: "16px",
          height: "24px",
          width: "24px",
          minWidth: "24px",
          textAlign: "center",
        },
        "& .role-cardv3-id": {
          color: "#0078D6",
        },
      },
      "& .role-cardv3-name": {
        lineHeight: "32px",
        fontSize: "1.25rem",
      },
    },
    "& .remove-icon": {
      display: "flex",
      gap: "16px",
      alignItems: "center",
    },
  },
}));

export const MainViewWrapper = styled("div")(({ theme }) => ({
  overflow: "hidden",
}));

//#endregion

//#region conditions and Reason

export const CandRMainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

export const CandRHeader = styled((props) => (
  <Typography variant="h2" {...props} />
))(({ ...props }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "MB Corpo A Text Condensed",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "48px",
}));

export const ConditionMainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "72px",
});

export const ConditionHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const CandRHeaderTypo = styled((props) => (
  <Typography variant="h3" {...props} />
))(({ ...props }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "MB Corpo S Title",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "40px",
}));

export const ConditionCheckBoxs = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "0 0 0 14px",
});

export const TandCMainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

export const CheckboxLabel = styled(Typography)({
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
  wordBreak: "break-word",
  "& > a": {
    color: "#0078D6",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    textDecorationLine: "underline",
  },
});

export const RequestMainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

export const RequestReasonHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ReasonFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  fontSize: "0.8rem",
  padding: "0px 8px",
  "& > span": {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "MB Corpo S Text",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
});

//#endregion

//#region success failure
export const SuccessFailureContainer = styled("div")(({ theme }) => ({
  flexGrow: "1",
  // padding: "20px 0",
  [theme.breakpoints.down("lg")]: {
    margin: "20px 20px 0px",
  },
}));
export const ResultHederContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "25px",
  marginTop: "58px",
  marginBottom: "48px",
  paddingTop: "24px",
}));

export const ResultHeader = styled((props) => (
  <Typography variant="h1" {...props} />
))(() => ({}));

export const StatusHeaderContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "& .status-icon": {
    color: props.color,
    fontSize: "2rem",
  },
  "& .badge-count": {
    padding: "0 12px !important",
  },
}));

export const ResultRoleCard = styled("div")(({ ...props }) => ({
  padding: "16px",
  backgroundColor: props.bgColor,
  borderRadius: "4px",
  overflow: "hidden",
  display: "grid",
  "& .role-name": {
    lineHeight: "32px",
    whiteSpace: "nowrap",
    fontSize: "1.5rem",
  },
}));

export const RoleIdContainer = styled("div")(() => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  overflow: "hidden",
  "& .role-id": {
    color: "#0078D6",
  },
}));

export const ResponseIdContainer = styled("div")(({ theme, ...props }) => ({
  display: "grid",
  justifyContent: "space-between",
  alignItems: "center",
  gridTemplateColumns: props.singleColumn ? "1fr" : "2fr 1fr",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

export const WorkflowIdContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  marginLeft: "auto",
  "& a": {
    color: "#0078D6",
    textDecoration: "none",
  },
}));

export const ScopeContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "16px",
  marginTop: "8px",
  overflow: "hidden",
  "& .orgScope": {
    display: "flex",
    gap: "8px",
    overflow: "hidden",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

export const ResultCardContainer = styled("div")(() => ({
  display: "grid",
  gap: "16px",
  marginTop: "16px",
  marginLeft: "40px",
  overflow: "hidden",
}));

export const StyledResultSection = styled("div")(({ theme }) => ({
  marginBottom: "40px",
  "& .action-buttons": {
    display: "flex",
    gap: "8px",
    justifyContent: "flex-end",
    marginTop: "24px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

//#endregion

export const EditHandlerWrapper = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "138vH",
  minHeight: "88vH",
  overflowY: "auto",
  overflowX: "hidden",
  ...customScrollerStyles,
  padding: props.isFromDeeplink ? "10px" : "0px",
  [theme.breakpoints.up("md")]: {
    padding: "32px",
  },
}));

//#region Edit Screen
export const EditScreenHeaderWrapper = styled("div")({
  display: "grid",
  width: "100%",
  flexDirection: "column",
  paddingBottom: "32px",
  borderBottom: "1px solid #DBDBDB",
  "& .header-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& .role-type": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "10px",
    },
  },
  "& .header-details": {
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "center",
    flexDirection: "column",
    "& .id-container": {
      display: "flex",
      alignItems: "cneter",
      overflow: "hidden",
    },
    "& .id-info": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "8px",
    },
    "& .role-id": {
      overflow: "hidden",
      color: "#0078D6",
      textOverflow: "ellipsis",
      fontFamily: "MB Corpo S Text",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
      marginLeft: "8px",
      whiteSpace: "nowrap",
    },
    "& .role-name": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "MB Corpo A Text Cond",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "48px",
      display: "block",
      width: "100%",
      alignItems: "flex-start",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& svg": {
      cursor: "pointer",
      minWidth: "20px",
    },
  },
});

export const IdContainer = styled("div")({
  display: "flex",
  width: "24px",
  height: "24px",
  minWidth: "24px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
  background: "rgba(0, 0, 0, 0.08)",
  "& span": {
    color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
    textAlign: "center",
    fontFamily: "MB Corpo S Text",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
});

export const ValidityContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "32px",
  flexDirection: "column",
  "& .header-container": {
    display: "flex",
    justifyContent: "space-between",
    "& .title": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "MB Corpo S Title",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "32px",
    },
  },
  "& .date-container": {
    display: "flex",
    [theme.breakpoints.down("lg")]: { flexDirection: "column" },
    "& .date-input": {
      marginRight: "26px",
      marginTop: "16px",
    },
  },
  "& .switch-container": {
    marginTop: "16px",
  },
}));

export const AttachmentConatiner = styled("div")({
  display: "flex",
  marginTop: "48px",
  flexDirection: "column",
  "& .header-container": {
    display: "flex",
    justifyContent: "space-between",
    "& .title": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "MB Corpo S Title",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "32px",
    },
  },
  "& .body-conatiner": {
    "& .sub-text": {
      color: "rgba(0, 0, 0, 0.60)",
      fontFamily: "MB Corpo S Text",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16px",
    },
    "& .contents": {
      marginTop: "16px",
      marginBottom: "24px",
    },
    "& .content-text": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "MB Corpo S Text",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
    },
    "& .uploader": {
      "& a": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        color: "#0078D6",
        textOverflow: "ellipsis",
        fontFamily: "MB Corpo S Text",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        textDecoration: "none",
        "& svg": {
          marginLeft: "8px",
        },
      },
    },
  },
});

export const DropZoneContainer = styled("div")(({ text, icon, theme }) => ({
  marginTop: "24px",
  position: "relative",
  "& .svg-container": {
    position: "absolute",
    left: "42%",
  },
  "& .MuiPaper-root": {
    borderRadius: "4px",
    border: "1px dashed var(--primary-main, #0078D6)",
    background: "rgba(0, 120, 214, 0.10)",
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiStack-root": {
    height: "90px",
    "&::after": {
      backgroundImage: `url(${icon})`,
    },
  },
  "& .MuiTypography-root": {
    visibility: "hidden",
  },
  "& button": {
    position: "relative",
    width: "100%",
    background: "inherit",
    display: "flex",
    alignItems: "flex-end",
    right: "-50px",
    color: "#0078D6",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    textDecorationLine: "underline",
    "&:hover": {
      background: "inherit",
    },
    "&::before": {
      fontFamily: "MB Corpo S Text",
      color: "rgba(0, 0, 0, 0.87)",
      content: `"${text}"`,
      position: "absolute",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      top: "6px",
      left: "5%",
      pointerEvents: "none",
    },
  },
}));

export const TermsandConditionContainer = styled("div")({
  marginTop: "48px",
});

export const OrgScopeContainer = styled("div")({
  marginTop: "48px",
});

export const Orgscope = styled("div")({
  marginTop: "48px",
});

export const OrgscopeHeader = styled("div")({
  color: " rgba(0, 0, 0, 0.87)",
  fontFamily: "MB Corpo S Title",
  fontSize: "26px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "32px",
});

export const OrgHeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const OrganizationWrapper = styled("div")({
  display: "flex",
  marginTop: "16px",
  flexDirection: "column",
});

export const OrganizationWrapperContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "8px",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
}));

export const CustomScopeContentWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  width: "90%",
});

export const ScopeWrapper = styled("div")(({ isGreyedout }) => ({
  borderRadius: "4px",
  background: isGreyedout ? "#F8F8F8" : "transparent",
  width: "100%",
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  padding: "8px",
}));

export const DeleteLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
}));

export const RoleConfigMainComponent = styled("div")(({ isDisplay }) => ({
  display: isDisplay ? "grid" : "none",
}));

// #region summary Page
export const SummaryPageContainer = styled("div")(({ theme }) => ({
  display: "grid",
  "& .summary-header": {
    fontWeight: 400,
    marginBottom: "40px",
    wordBreak: "break-word",
    [theme.breakpoints.down("md")]: {
      wordBreak: "break-all",
    },
  },
  "& .request-reason-header": {
    marginBottom: "8px",
  },
  "& .request-reason": {
    marginBottom: "64px",
  },
  "& .badge-count": {
    padding: "0px 12px !important",
  },
}));

export const ConfiguredRolesHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  marginBottom: "25px",
}));

export const SummaryCardContainer = styled("div")(({ theme, ...props }) => ({
  display: "grid",
  gridTemplateColumns: props.view === "list" ? "1fr" : "1fr 1fr",
  gap: props.view === "list" ? "24px" : "26px",
  marginBottom: "40px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));
//#end region

//#region Reference user
export const SelectReferenceUserContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "16px 8px",
  gridTemplateColumns: "repeat(2, 1fr)",
  "& .select-refernece-user-chip .select-reference-user-label": {
    color: "#00000099",
    paddingBottom: "8px",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

export const ReferenceUserCard = styled("div")(({ theme }) => ({
  padding: "8px",
  background: "#E6F5FF",
  borderRadius: "4px",
  display: "grid",
  gap: "16px",
  gridTemplateColumns: "1fr 52px",
  alignItems: "center",
  "& .remove-icon": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    height: "100%",
  },
  "& .user-details": {
    display: "flex",
    gap: "16px",
    overflow: "hidden",
    alignItems: "center",
    "& .header-name": { overflow: "hidden" },
    "& .user-card-header": {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& .id-holder": {
        background: "#00000014",
        padding: "5.5px 4px",
        borderRadius: "2px",
        lineHeight: "16px",
        height: "24px",
        width: "24px",
        minWidth: "24px",
        textAlign: "center",
      },
      "& .user-card-id": {
        color: "#0078D6",
      },
    },
    "& .user-card-name": {
      lineHeight: "32px",
      fontSize: "1.25rem",
    },
  },
}));
//#endregion

export const NewRequestDeeplinkContainer = styled("div")(({ theme }) => ({
  flexGrow: "1",
  padding: "20px 0",
  [theme.breakpoints.down("lg")]: {
    margin: "0 20px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .stepper-container": {
      border: "1px solid #0000001F",
      padding: "14px",
    },
  },
}));

export const ErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "32px",
  marginTop: "100px",
  marginBottom: "30px",
  padding: "16px",
}));

export const MainEditScreenContainer = styled("div")(({ theme }) => ({
  border: "1px solid #DBDBDB",
  borderRadius: "4px",
}));
