import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./assets/locales/en/translation.json";
import translationAR from "./assets/locales/ge/translation.json";
import translationCHS from "./assets/locales/chs/translation.json";
import translationESP from "./assets/locales/esp/translation.json";
import translationFRA from "./assets/locales/fra/translation.json";
import translationHUN from "./assets/locales/hun/translation.json";
import translationITA from "./assets/locales/ita/translation.json";
import translationJPN from "./assets/locales/jpn/translation.json";
import translationKOR from "./assets/locales/kor/translation.json";
import translationNLD from "./assets/locales/nld/translation.json";
import translationPLK from "./assets/locales/plk/translation.json";
import translationPTG from "./assets/locales/ptg/translation.json";
import translationRUS from "./assets/locales/rus/translation.json";
import translationTRK from "./assets/locales/trk/translation.json";

import translationUS from "@alice/component_library/src/data/i18n/en_US.json";
import translationDE from "@alice/component_library/src/data/i18n/de_DE.json";
import translationZH from "@alice/component_library/src/data/i18n/zh_CN.json";
import translationNL from "@alice/component_library/src/data/i18n/nl_NL.json";
import translationFR from "@alice/component_library/src/data/i18n/fr_FR.json";
import translationHU from "@alice/component_library/src/data/i18n/hu_HU.json";
import translationIT from "@alice/component_library/src/data/i18n/it_IT.json";
import translationJA from "@alice/component_library/src/data/i18n/ja_JP.json";
import translationKO from "@alice/component_library/src/data/i18n/ko_KR.json";
import translationPL from "@alice/component_library/src/data/i18n/pl_PL.json";
import translationPT from "@alice/component_library/src/data/i18n/pt_PT.json";
import translationRU from "@alice/component_library/src/data/i18n/ru_RU.json";
import translationES from "@alice/component_library/src/data/i18n/es_ES.json";
import translationTR from "@alice/component_library/src/data/i18n/tr_TR.json";

const fallbackLng = ["en"];
const availableLanguages = [
  "en",
  "de",
  "zh",
  "es",
  "fr",
  "hu",
  "it",
  "ja",
  "ko",
  "nl",
  "pl",
  "pt",
  "ru",
  "tr",
];

const resources = {
  en: {
    translation: translationEN,
    componentLibrary: translationUS,
  },

  de: {
    translation: translationAR,
    componentLibrary: {
      ...translationDE,
      ...{
        statusChip: {
          pending: "Anstehend",
          approved: "Genehmigt",
          expired: "Abgelaufen",
          failed: "Fehlgeschlagen",
          rejected: "Abgelehnt",
        },
      },
    },
  },

  zh: {
    translation: translationCHS,
    componentLibrary: translationZH,
  },
  es: {
    translation: translationESP,
    componentLibrary: translationES,
  },
  fr: {
    translation: translationFRA,
    componentLibrary: translationFR,
  },
  hu: {
    translation: translationHUN,
    componentLibrary: translationHU,
  },
  it: {
    translation: translationITA,
    componentLibrary: translationIT,
  },
  ja: {
    translation: translationJPN,
    componentLibrary: translationJA,
  },
  ko: {
    translation: translationKOR,
    componentLibrary: translationKO,
  },
  nl: {
    translation: translationNLD,
    componentLibrary: translationNL,
  },
  pl: {
    translation: translationPLK,
    componentLibrary: translationPL,
  },
  pt: {
    translation: translationPTG,
    componentLibrary: translationPT,
  },
  ru: {
    translation: translationRUS,
    componentLibrary: translationRU,
  },
  tr: {
    translation: translationTRK,
    componentLibrary: translationTR,
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["translation", "componentLibrary"],
    defaultNS: "translation",
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
