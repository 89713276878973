export const apiBaseUrl = "";

export const oConf =
  process.env.NODE_ENV !== "production"
    ? {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    : {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
