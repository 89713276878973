import PropTypes from 'prop-types'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { NavigationMenuList } from './components/NavigationMenuList'
import { NavigationMenuPopover } from './components/NavigationMenuPopover'

/**
 * Custom in-page navigation menu
 *
 * ###Note
 * - Renders in mobile mode when below MD breakpoint
 * - or when `isMobile` is set
 */
export const NavigationMenu = ({
  title,
  items,
  selectedItem,
  isMobile: _isMobile = false,
  onItemClick = ({ item, itemId }) => {},
  ...otherProps
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) || _isMobile
  const MenuElm = isMobile ? NavigationMenuPopover : NavigationMenuList

  return (
    <MenuElm
      title={title}
      items={items}
      selectedItem={selectedItem}
      onItemClick={onItemClick}
      {...otherProps}
    />
  )
}

NavigationMenu.propTypes = {
  /**
   * Title for the menu.<br>
   * List renders without the title if undefined.<br>
   * With `isMobile` set to true, `selectedItem` label with be displayed.
   */
  title: PropTypes.string,
  /**
   * List of items.<br>
   * Icon needs to be a ReactElement. `{ icon: SomeIcon }`
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired
  })).isRequired,
  /** ID of the selected item */
  selectedItem: PropTypes.string,
  /** forces mobile layout */
  isMobile: PropTypes.bool,
  /** Callback, when item got clicked. */
  onItemClick: PropTypes.func
}
