import React from "react";

const EditIcon = (props) => {
  const color = props.color || "#000000";

  return (
    <span>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 25"
        className="edit-icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="IconBtn/Edit/normal"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C23.9928352,5.37555305 18.624447,0.00716484761 12,0 Z M6.036,17.078 L7.152,14.286 C7.1777688,14.2233388 7.21546736,14.1662815 7.263,14.118 L13.155,8.226 C13.3532477,8.03818719 13.6637523,8.03818719 13.862,8.226 L15.538,9.9 C15.6312522,9.99425768 15.6836931,10.121409 15.684,10.254 C15.684,10.3863525 15.631469,10.5132945 15.538,10.607 L9.645,16.5 C9.59741999,16.5483917 9.54017624,16.5862134 9.477,16.611 L6.686,17.728 C6.49966226,17.7983136 6.28949462,17.7551124 6.146,17.617 C6.00491011,17.4754621 5.96165346,17.2635045 6.036,17.078 Z M17.77,8.378 L16.932,9.215 C16.7367501,9.41019097 16.4202499,9.41019097 16.225,9.215 L14.55,7.538 C14.354809,7.34275007 14.354809,7.02624993 14.55,6.831 L15.388,5.993 C15.7034682,5.67656188 16.1321751,5.49910388 16.579,5.49999662 L16.579,5.49999662 C17.2600419,5.50078756 17.8736481,5.91146806 18.134033,6.54076798 C18.3944179,7.1700679 18.2503782,7.89423892 17.769,8.376 L17.77,8.378 Z"
            id="Shape"
            fill={color}
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </span>
  );
};
export default EditIcon;
