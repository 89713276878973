import React from "react";
import { useSelector } from "react-redux";
import {
  Layout,
  useUser,
  usePermission,
  useOpenTasks,
} from "@alice/component_library";
import Header from "./components/Header";
import DeadLineComponent from "./components/AccessReviewDeadLines";
import MyselfRoleRequest from "./components/RequestCard";
import LatestTask from "./components/ActivityComp/LatestTask";
import RecentRequest from "./components/ActivityComp/RecentRequests";
import { HomeRequestWrapper, HomePageContainer } from "./styledComponents";
import ExpiringRoles from "./components/ExpiringRoles";

const HomePage = () => {
  const { user: userData } = useUser();
  const { adminRoles: adminRoleSet = new Set() } = usePermission();
  const { openTasksCount: openTasks } = useOpenTasks();
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const {
    role: { rolesInfoDesktop },
    accessReview: { hasReviewAccess, reviewAssignment },
    task: {
      openTaskCountInfo: { dashboardTottalCount = 0 },
      taskInfo: {
        allTasks: { paginationInfo = {} },
      },
    },
  } = useSelector((state) => state);

  const {
    rels: { totalCount: reveiwTotalCount = 0 },
  } = reviewAssignment;

  const { expiringRolesCount = 0 } = rolesInfoDesktop;

  return (
    <Layout>
      <HomePageContainer>
        {userData?.id && (
          <>
            <Header
              userData={userData}
              openTasks={
                paginationInfo?.limit ? dashboardTottalCount : openTasks //filter === "PENDING"
              }
              hasReviewAccess={hasReviewAccess}
              hasTasksAccess={!!adminRoles?.length}
              reviewTasks={reveiwTotalCount}
              expiringRoles={expiringRolesCount}
            />
            {!!adminRoles?.length && <LatestTask userData={userData} />}
            {hasReviewAccess && <DeadLineComponent />}

            <HomeRequestWrapper>
              <RecentRequest userData={userData} />
              <MyselfRoleRequest userData={userData} />
            </HomeRequestWrapper>
            <ExpiringRoles />
          </>
        )}
      </HomePageContainer>
    </Layout>
  );
};

export default HomePage;
