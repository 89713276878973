import React from "react";
import { Dialog, Button, UserCard } from "@alice/component_library";
import { styled } from "@mui/material";

export const UserCardWrapper = styled("div")({
  marginTop: "10px",
});

const RecipientDialogue = ({ userList, isOpen, toggleDialogue, t }) => {
  return (
    <Dialog
      open={isOpen}
      footerSection={
        <>
          <Button variant="secondary" onClick={(e) => toggleDialogue(e)}>
            {t("close")}
          </Button>
        </>
      }
      headerSection={t("recipients")}
      onClose={(e) => toggleDialogue(e)}
    >
      {userList?.length > 0 &&
        userList?.map((userInfo) => {
          return (
            <UserCardWrapper>
              <UserCard
                userData={userInfo}
                isContextShown
                variant="grid"
                size="s"
                sx={{ marginTop: "10px" }}
              />
            </UserCardWrapper>
          );
        })}
    </Dialog>
  );
};

export default RecipientDialogue;
