import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LayersOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FilterChip } from "@alice/component_library";
import FilterPopover from "./FilterPopover";
import { SelectApplicationContainer } from "./styledComponent";
import SelectedAppCard from "./SelectedAppCard";

const SelectApplication = ({
  userId,
  handleSelectApplication,
  selectedApplication,
  isAppliCationDisabled = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();

  const chipRef = useRef(null);
  return (
    <SelectApplicationContainer>
      <div className="select-application-chip">
        <Typography variant="body2" className="select-application-label">
          {t("filter-by-application")}:
        </Typography>
        <FilterChip
          ref={chipRef}
          key={"filter"}
          label={t("select-application")}
          isActive={!!selectedApplication?.id}
          icon={<LayersOutlined />}
          deleteIcon={
            <KeyboardArrowDownIcon
              sx={{
                transition: "all 0.1s ease-in",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          }
          onClick={() => setOpen((prev) => !prev)}
          onDelete={() => setOpen((prev) => !prev)}
          disabled={isAppliCationDisabled}
        />
        {open && (
          <FilterPopover
            userId={userId}
            open={open}
            handleClose={() => setOpen(false)}
            anchorEl={chipRef?.current}
            selectedApplication={selectedApplication}
            handleSelectApplication={handleSelectApplication}
          />
        )}
      </div>

      {selectedApplication?.id && (
        <SelectedAppCard
          selectedApplication={selectedApplication}
          handleSelectApplication={handleSelectApplication}
        />
      )}
    </SelectApplicationContainer>
  );
};

export default SelectApplication;
