import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { AssignmentDialog } from '../dialog/AssignmentDialog'
import { WebServicesScope } from '../scope/WebServicesScope'

export const ApiAdministratorAssignmentDialog = ({
  userId,
  consumableWebservices = [],
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedWebServices, setSelectedWebServices ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.webServices'),
      content: selectedWebServices.length ? `${selectedWebServices.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'apiadmin',
        body: { apiAdminPrivileges: selectedWebServices.map((apiConsumer) => ({ apiConsumer })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.apiAdmin') }),
      onRequestSuccess: (response) => onAssigned({ apiAdmin: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsAssignDisabled = () => !(selectedWebServices && selectedWebServices.length > 0)

  const content = [
    {
      title: t('adminRoles.select.webServices'),
      render: (
        <WebServicesScope
          userData={consumableWebservices}
          selectedWebServices={selectedWebServices}
          setSelectedWebServices={setSelectedWebServices}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="ApiAdministratorAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.apiAdmin'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

ApiAdministratorAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  consumableWebservices: PropTypes.object,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
