import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Card } from "@alice/component_library/";
import "./contactsupport.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PrimaryButton } from "@components/button";

function DaimlerTruckUserSupport({ checking }) {
  const { t } = useTranslation();

  const handlerInquiryHelpLink = () => {
    const url =
      "https://daimlertruck.service-now.com/sp?id=sc_cat_item&sys_id=74f00e051b8160502f8a3113dd4bcbce&referrer=popular_items";
    window.open(url, "_blank");
  };

  const handlerIncidentLink = () => {
    const url =
      "https://daimlertruck.service-now.com/sp?id=sc_cat_item&sys_id=062eec1f1b0c605093b43113dd4bcbf0&referrer=popular_items";
    window.open(url, "_blank");
  };

  const getButtonLabel = (label) => (
    <>
      <span className="button-Text">{t(label)}</span>
      <ChevronRightIcon />
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <p className={checking ? "central-support" : "central-supports"}>
          {t("contact_page.alice-central-support")}
        </p>
        <p className="central-Subsupport">
          {t("contact_page.alice-central-title")}
        </p>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <p className="padding-20 flexDirection">
              <span className="circle">
                <InfoOutlinedIcon fontSize="large" />
              </span>

              <span className="contact-centerl mt-16">
                {t("contact_page.Inquiry-Help")}
              </span>
              <span className="contact-centerl-2 mt-10 mh-60">
                {t("contact_page.inquiry-question")}
              </span>
              <span className="mt-65">
                <PrimaryButton
                  label={getButtonLabel("contact_page.get-help")}
                  onButtonClick={() => handlerInquiryHelpLink()}
                />
              </span>
            </p>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <p className="padding-20 flexDirection">
              <span className="circle">
                <WarningAmberOutlinedIcon fontSize="large" />
              </span>
              <span className="contact-centerl mt-16">
                {t("contact_page.Incident")}
              </span>
              <span className="contact-centerl-2 mt-10 mh-80">
                {t("contact_page.incident-unplanned")}
              </span>
              <span className="mt-43">
                <PrimaryButton
                  label={getButtonLabel("contact_page.report-incident")}
                  onButtonClick={() => handlerIncidentLink()}
                />
              </span>
            </p>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DaimlerTruckUserSupport;
