import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SmartIcon } from '../../../card'
import { getSecialCommunityForIcon, getUserCommunities, mergeSxProps, USER_SPECIAL_COMMUNITIES } from '../../../../util'
import { AliceIcon } from '../../../icon'
import { Skeleton } from '../../../skeleton'

const Label = ({ children, sx }) => <Typography variant="caption" sx={mergeSxProps({ color: 'text.secondary', lineHeight: 1.3 }, sx)}>{children}</Typography>

const DetailItem = ({
  size,
  variant,
  icon,
  label,
  text,
  isLoading
}) => (
  <Stack
    className="detailItem"
    sx={[
      {
        flexDirection: 'row',
        gap: 1,
        alignItems: 'end'
      },
      variant === 'list' && { maxWidth: '248px' }
    ]}
  >
    {isLoading
      ? (
        <Skeleton
          variant="rounded"
          sx={[
            { width: '20px', height: '20px' },
            size === 'xl' && { margin: '8px' }
          ]}
        />
      )
      : <Box sx={{ lineHeight: 0, marginBottom: '3px' }}>{icon}</Box>}

    <Stack sx={{ display: 'grid', overflow: 'hidden' }}>
      {size === 'xl' && <Label>{label}</Label>}

      {isLoading
        ? <Skeleton variant="rectangular" sx={{ width: '120px', height: '20px' }} />
        : (
          <Typography
            variant="body2"
            sx={{
              color: 'text.primary',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {text}
          </Typography>
        )}
    </Stack>
  </Stack>
)

export const UserCardCommunityDetails = ({
  communities: _communities = [],
  community,
  size,
  companyName,
  variant,
  isLoading,
  sx = {} || []
}) => {
  const { t } = useTranslation('componentLibrary')

  const communities = getUserCommunities(_communities)
  // eslint-disable-next-line max-len
  const isCompanyNameShown = !communities.map((community) => USER_SPECIAL_COMMUNITIES.includes(community)).some((foundCommunity) => foundCommunity)

  return (
    <Stack sx={mergeSxProps(size === 'xl' ? { gap: 2 } : { gap: 1 }, sx)}>
      {!!communities.length && (
        <DetailItem
          isLoading={isLoading}
          size={size}
          variant={variant}
          icon={<SmartIcon type="userCommunity" name={getSecialCommunityForIcon(communities) || communities[0]} />}
          label={t('general.community')}
          text={community}
        />
      )}

      {(companyName && isCompanyNameShown) && (
        <DetailItem
          isLoading={isLoading}
          size={size}
          variant={variant}
          icon={<AliceIcon iconType="ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION" color="primary.main" size={16} />}
          label={t('general.organization')}
          text={companyName}
        />
      )}
    </Stack>
  )
}
