import React, { useCallback, useMemo, forwardRef } from "react";
import { Card, DateUtils } from "@alice/component_library";
import { blue, green, grey, red, yellow } from "@mercedes-benz/mui5-theme";
import RoleCardBody from "./RoleCardContent";
import RoleCardV3Footer from "./RoleCardV3Footer";

export const RoleCardV3 = forwardRef(
  (
    {
      id,
      data = {},
      hideActions = false,
      state = "none",
      isSelected = false,
      showModifyButton = false,
      showSelection = true,
      isOpen = false,
      size = "small",
      isFromSummarryPage = false,
      isNeedToCalculate = true,
      isDisplayValidity = true,
      onSelect = (data) => {},
      onReapply = (data) => {},
      onModify = (data) => {},
      onDelete = (data) => {},
      onExpand = (data, open) => {},
      showMore = true,
      handleExtendOrReapply = null,
      ExtendOrReapplyButton = false,
      hideCardContent = false,
      sx = {},
    },
    ref
  ) => {
    const {
      roleDefinition = {},
      validFrom,
      validTo,
    } = useMemo(() => data, [data]);
    const { id: roleId } = roleDefinition;

    const onCardClick = useCallback(
      (e) => {
        e.stopPropagation();

        const targetTag = e.target.tagName.toLowerCase();
        const targetParentTag = e.target.parentElement.tagName.toLowerCase();

        if (
          typeof onSelect === "function" &&
          targetTag !== "button" &&
          targetTag !== "svg" &&
          targetTag !== "a" &&
          targetParentTag !== "button" &&
          targetParentTag !== "svg" &&
          targetParentTag !== "a"
        ) {
          onSelect({
            id,
            e,
            isSelected: !isSelected,
            data,
          });
        }
      },
      [onSelect, id, isSelected, data]
    );
    const onExpandClick = ({ isExpanded, e }) => {
      e.stopPropagation();

      if (typeof onExpand === "function") {
        onExpand({
          id,
          isExpanded,
          e,
        });
      }
    };
    const handleReapplyClick = () => onReapply(data);
    const handleModifyClick = () => onModify(data);
    const handleDeleteClick = () => onDelete(data);
    const validToDaysLeft = validTo
      ? DateUtils.daysBetweenDates(
          new Date(Date.now()),
          new Date(Date.parse(validTo))
        )
      : null;
    let finalValidFrom = validFrom;
    let finalValidTo = validTo;
    let finalValidityDaysLeft = validToDaysLeft;

    if (isNeedToCalculate) {
      finalValidFrom = validFrom ?? null;
      if (validTo) {
        const today = isFromSummarryPage
          ? new Date(Date.parse(data?.validFrom))
          : new Date(Date.now());
        const validToParsed = new Date(Date.parse(validTo));
        finalValidityDaysLeft = DateUtils.daysBetweenDates(
          today,
          validToParsed
        );
        finalValidTo = validToParsed;
      } else {
        finalValidTo = null;
        finalValidityDaysLeft = null;
      }
    }
    const getBackgroundColor = () => {
      switch (state) {
        case "none":
          return grey["95"];
        case "success":
          return green["95"];
        case "info":
          return blue["95"];
        case "warning":
          return yellow["95"];
        case "error":
          return red["95"];
        default:
          return grey["95"];
      }
    };
    return (
      <Card
        sx={{
          padding: "16px 16px 8px 16px",
          borderRadius: "4px",
          height: "100%",
          border: isOpen && "4px solid  rgba(0, 120, 214, 0.16)",
          backgroundColor: isSelected
            ? "rgba(0, 120, 214, 0.16)"
            : getBackgroundColor(),
          display: "flex",
          flexDirection: "column",
          ...{ sx },
        }}
        ref={ref}
      >
        <RoleCardBody
          roleCardId={roleId}
          handleCheckboxClick={onCardClick}
          data={data}
          isSelected={isSelected}
          hideActions={hideActions}
          isFromSummarryPage={isFromSummarryPage}
          isNeedToCalculate={isNeedToCalculate}
          isDisplayValidity={isDisplayValidity}
          finalValidFrom={finalValidFrom}
          finalValidTo={finalValidTo}
          finalValidityDaysLeft={finalValidityDaysLeft}
          size={size}
          state={state}
          hideCardContent={hideCardContent}
          showSelection={showSelection}
        />
        <RoleCardV3Footer
          validToDaysLeft={validToDaysLeft}
          isOpen={isOpen}
          hideActions={hideActions}
          showMore={showMore}
          showModifyButton={showModifyButton}
          handleExtendOrReapply={handleExtendOrReapply}
          finalValidFrom={finalValidFrom}
          finalValidTo={finalValidTo}
          finalValidityDaysLeft={finalValidityDaysLeft}
          handleReapplyClick={handleReapplyClick}
          handleModifyClick={handleModifyClick}
          handleDeleteClick={handleDeleteClick}
          handleExpandClick={onExpandClick}
          size={size}
          ExtendOrReapplyButton={ExtendOrReapplyButton}
        />
      </Card>
    );
  }
);

export default React.memo(RoleCardV3);
