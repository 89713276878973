import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import { Layout } from "@alice/component_library";
import Header from "./Header";
import ResultSection from "./ResultSection";
import { SuccessFailureContainer } from "../../styledComponents";
import { createRoles } from "@sliceactions/newRequestActions";
import { useSelector } from "react-redux";
import { NewRequestResultSkeleton } from "@src/sharedComponents/Skeleton/CommonSkeletons";
import {
  BAD_REQUEST_STATUS_CODE,
  CUSTOM_STATUS_CODE_1,
  CUSTOM_STATUS_CODE_2,
  INTERNAL_SERVER_ERROR_STATUS_CODE,
  SUCCESS_STATUS_CODE,
  UNAUTHORIZED_STATUS_CODE,
} from "@pages/newRequest/constants";

const RequestResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataId } = useParams();
  const [componentData, setComponentData] = useState([]);

  const { savedData } = useSelector(
    (state) => state.newRequestSlice.requestData
  );
  const resultLoading = useSelector(
    (state) => state.newRequestSlice.resultLoading
  );

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(dataId));
    setComponentData(data);
  }, [dataId, savedData]);

  useEffect(() => {
    if (!resultLoading) {
      const data = JSON.parse(localStorage.getItem(dataId));
      if (data === null) {
        navigate("/access/accessRequest");
      } else {
        const hasErrorStatus = data.some((item) => {
          const status = item.status;
          return (status >= 500 && status < 600) || status === 401;
        });

        if (!hasErrorStatus) {
          localStorage.removeItem(dataId);
        }
      }
    }
  }, [resultLoading]);

  const successfulRoles = [];
  const blockedRoles = [];
  const failedRoles = [];
  const roleToResend = [];

  componentData?.forEach((role) => {
    const { workflowId, customScope, rolesData, orgScope, status } = role;
    const { configurationInformation } = rolesData;
    const orgScopeName =
      configurationInformation?.scopeData?.data[orgScope]?.name;
    const { roleDefinition } = rolesData.data;
    const responeData = {
      responseId: workflowId,
      orgScope: orgScopeName,
      customScope: customScope,
    };

    if (status >= SUCCESS_STATUS_CODE && status < CUSTOM_STATUS_CODE_1) {
      successfulRoles.push({
        roleDetails: roleDefinition,
        response: responeData,
      });
    } else if (
      status >= BAD_REQUEST_STATUS_CODE &&
      status < INTERNAL_SERVER_ERROR_STATUS_CODE &&
      status !== UNAUTHORIZED_STATUS_CODE
    ) {
      blockedRoles.push({
        roleDetails: roleDefinition,
        response: responeData,
      });
    } else if (
      (status >= INTERNAL_SERVER_ERROR_STATUS_CODE &&
        status < CUSTOM_STATUS_CODE_2) ||
      status === UNAUTHORIZED_STATUS_CODE
    ) {
      roleToResend.push(rolesData);
      failedRoles.push({
        roleDetails: roleDefinition,
        response: responeData,
      });
    } else if (status === null) {
      roleToResend.push(rolesData);
      failedRoles.push({
        roleDetails: roleDefinition,
        response: responeData,
      });
    }
  });

  const sendFailedRequests = () => {
    dispatch(
      createRoles({
        rolesData: roleToResend,
        dataId: dataId,
      })
    );
  };

  const cancelFailedRequests = () => {
    localStorage.removeItem(dataId);
    navigate("/access/accessRequest");
  };

  return (
    <SuccessFailureContainer>
      <Layout>
        {resultLoading ? (
          <NewRequestResultSkeleton />
        ) : (
          <>
            {(!!blockedRoles.length ||
              !!failedRoles.length ||
              !!successfulRoles.length) && (
              <Header
                successfulRoles={successfulRoles}
                failedRoles={failedRoles}
                blockedRoles={blockedRoles}
              />
            )}
            {!!blockedRoles.length && (
              <>
                <ResultSection status="blocked" responseArray={blockedRoles} />
                {(!!failedRoles.length || !!successfulRoles.length) && (
                  <Divider sx={{ margin: "40px 0" }} />
                )}
              </>
            )}
            {!!failedRoles.length && (
              <>
                <ResultSection
                  status="failed"
                  responseArray={failedRoles}
                  sendFailedRequests={() => sendFailedRequests()}
                  cancelFailedRequests={cancelFailedRequests}
                />
                {!!successfulRoles.length && (
                  <Divider sx={{ margin: "40px 0" }} />
                )}
              </>
            )}
            {!!successfulRoles.length && (
              <ResultSection status="success" responseArray={successfulRoles} />
            )}
          </>
        )}
      </Layout>
    </SuccessFailureContainer>
  );
};

export default RequestResult;
