import { styled } from "@mui/material";
import { GenericCard } from "@alice/component_library/src/ui/card/genericCard";

export const StyledHeaderContainer = styled("div")(() => ({
  padding: "16px 0 8px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "& .header-container": {
    minWidth: 0,
  },
  "& .role-cardv3-header": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    "& .id-holder": {
      background: "#00000014",
      padding: "5.5px 4px",
      borderRadius: "2px",
      lineHeight: "16px",
      height: "24px",
      width: "24px",
      minWidth: "24px",
      textAlign: "center",
    },
    "& .role-cardv3-id": {
      color: "#0078D6",
    },
  },
  "& .large-variant": {
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    "& .role-cardv3-header": {
      width: "70%",
    },
  },
  "& .role-cardv3-name": {
    lineHeight: "32px",
    fontSize: "1.25rem",
  },
}));

export const RoleCardContentContainer = styled("div")(() => ({
  padding: "16px 0",
  "& .content-header-name": {
    // marginLeft: "24px",
    color: "rgba(0, 0, 0, 0.60)",
    lineHeight: "16px",
  },
  "& .name-value": {
    minWidth: 0,
  },
  "& .name-value-large": {
    display: "flex",
    gap: "12px",
  },
  "& .custom-width": {
    width: "91px",
  },
  "& .custom-validity-width": {
    minWidth: "80px",
  },
  "& .custom-validity-to-width": {
    minWidth: "170px",
  },
  "& .validity-container": {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "27px",
    marginBottom: "16px",
  },
  "& .content-detail-container": {
    display: "flex",
    alignItems: "end",
    gap: "8px",
    minWidth: 0,
    marginBottom: "16px",
    "& .MuiTypography-body2": {
      whiteSpace: "nowrap",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      marginBottom: "4px",
    },
  },
}));

export const RoleCardActionsContainer = styled("div")(() => ({
  display: "flex",
  marginTop: "8px",
  padding: "8px 0",
  justifyContent: "space-between",
  alignItems: "center",
  "& .action-button-container": {
    gap: "16px",
    display: "flex",
    "& .action-buttons": {
      padding: "4px 10px",
      minWidth: "40px",
      "& .MuiSvgIcon-root": {
        fontSize: "1.25rem",
      },
    },
  },
  "& .expand-more": {
    marginLeft: "auto",
    transition: "all ease-in 0.2s",
    "& .open": {
      transform: "rotate(180deg)",
    },
  },
}));

export const RoleTypeChipContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "& .role-type-icon": {
    display: "flex",
    width: "18px",
    height: "18px",
    padding: "4px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "60px",
    background: "#FFF",
    "& .MuiSvgIcon-root": {
      fontSize: "0.8rem",
      color: "#0078D6",
    },
  },
}));

export const RoleTypeClassification = styled("div")(() => ({
  marginBottom: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // flexWrap: "wrap",
  rowGap: "10px",
  flexGrow: 1,
}));

//#region role card list
export const RoleGenericCard = styled(GenericCard)(() => ({
  background: "#F8F8F8",
  "& .role-cardv3-name": {
    lineHeight: "32px",
    fontSize: "1.25rem",
    marginBottom: "8px",
    width: "fit-content",
  },
  "& .role-list-card-container": {
    display: "grid",
    gap: "24px",
    alignItems: "center",
    gridTemplateColumns: "1fr auto 104px", //40px
    overflow: "hidden",
  },
  "& .role-list-container": {
    display: "grid",
    gap: "24px",
    alignItems: "center",
    gridTemplateColumns: "1fr",
  },
  "& .expand-more": {
    marginLeft: "auto",
    transition: "all ease-in 0.2s",
    "& .open": {
      transform: "rotate(180deg)",
    },
  },
  "& .role-details": {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}));
export const RoleCardHeader = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 0.5fr",
  alignItems: "flex-start",
  marginBottom: "8px",
  "& .role-card-header": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginLeft: "auto",
    flexWrap: "wrap",
  },
  "& .role-id-container": {
    display: "flex",
    gap: "8px",
    "& .id-holder": {
      background: "#00000014",
      padding: "5.5px 4px",
      borderRadius: "2px",
      lineHeight: "16px",
      height: "24px",
      width: "24px",
      minWidth: "24px",
      textAlign: "center",
    },
    "& .role-cardv3-id": {
      color: "#0078D6",
    },
  },
}));

export const OrgCustValidityContainer = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "32px",
}));

//#end region
