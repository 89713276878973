import axios from "axios";
import axiosInstance from "./services/axiosInstance";
import { getCookie, getEnvironment } from "@alice/component_library";

const isLocal = () => ["local", "gems"].includes(getEnvironment());

// TODO Proxy: Remove after infrastructure changes
axios.interceptors.request.use((req) => {
  req.url = req.url.replace("/gems/", "/alice-proxy-v2/gems/");
  req.url = req.url.replace("/alice-proxy/config", "/alice-proxy-v2/config");
  req.url = req.url.replace("/images/", "/alice-proxy-v2/images/");
  if (isLocal()) {
    req.headers.Authorization = getCookie("token");
  }
  return req;
});

// TODO Proxy: Remove after infrastructure changes
axiosInstance.interceptors.request.use((req) => {
  req.url = req.url.replace("/gems/", "/alice-proxy-v2/gems/");
  req.url = req.url.replace("/alice-proxy/config", "/alice-proxy-v2/config");
  req.url = req.url.replace("/images/", "/alice-proxy-v2/images/");
  if (isLocal()) {
    req.headers.Authorization = getCookie("token");
  }
  return req;
});
