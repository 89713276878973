import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { createArrayWithInitialValues } from '../../../util'
import { RoleCard } from '../roleCard/RoleCard'
import { InteractionPropTypes } from '../../../constants'

/**
 * This component renders a grid view using `UserCard` components.
 * The use of MUI-Grid with `UserCard` is preferred to achieve an optimal layout and responsive design.
 *
 * Important Notes:
 * - This component is purely for presentation and does not handle data fetching or state management.
 *   States like `selectedUser` should be managed by a parent component.
 * - It is intended to work in conjunction with components such as `UserSearch` or `GenericSearch` for displaying user data.
 * - On mobile devices, a 'list' view mode is automatically adopted to enhance usability.
 */
export const RoleSearchGridViewRenderer = ({
  view = 'grid',
  size = 'm',
  data: roles,
  selectedData: selectedUsers = [],
  disabledData: disabledUsers = [],
  onItemClick,
  rowsPerPage,
  interaction,
  onGetHref,
  isIdLink = true,
  isLoading,
  isMobile,
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  if (isMobile) view = 'list'

  const gridSx = [
    {
      display: 'grid',
      gap: 2,
      gridAutoFlow: 'dense'
    },
    view === 'grid' && { gridTemplateColumns: 'repeat(3, 1fr)' },
    view === 'list' && { gridTemplateColumns: 'repeat(1, 1fr)' }
  ]

  const mappableUsers = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({ id: index.toString() }))
    : roles

  return (
    <Box data-testid="RoleSearchGridViewRenderer" sx={gridSx}>
      {mappableUsers.map((role) => (
        <RoleCard
          key={role.id}
          variant={view}
          size={size}
          data={role}
          interaction={interaction}
          href={onGetHref}
          isIdLink={isIdLink}
          isSelected={selectedUsers.includes(role.id)}
          isDisabled={disabledUsers.includes(role.id)}
          isLoading={isLoading}
          onCardClick={onItemClick}
          {...otherProps}
        />
      ))}
    </Box>
  )
}

RoleSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'm', 'l' ]),
  /** Users to be displayed */
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    departmentNumber: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Selected users */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled user cards */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  /** Loading state */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** True if mobile viewport */
  isMobile: PropTypes.bool
}
