import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class ActionsService {
  static dataDispatcherConfigurationsFullSync = ({ ...args }) => AliceService.post(
    '/actions/operations/dataDispatcherConfigurationsFullSync',
    { ...args }
  )

  static dataDispatcherFullSync = ({ ...args }) => AliceService.post(
    '/actions/operations/dataDispatcherFullSync',
    { ...args }
  )

  static checkUserNameIndexes = ({ ...args }) => AliceService.get(
    '/actions/operations/checkUserNameIndexes',
    { ...args }
  )

  static createMissingUserNameIndexes = ({ ...args }) => AliceService.post(
    '/actions/operations/createMissingUserNameIndexes',
    { ...args }
  )

  static cleanupOrphanedUserNameIndexes = ({ ...args }) => AliceService.post(
    '/actions/operations/cleanupOrphanedUserNameIndexes',
    { ...args }
  )

  static refreshUserSearchIndexes = ({ ...args }) => AliceService.post(
    '/actions/operations/refreshUserSearchIndexes',
    { ...args }
  )

  static countOrgsMarkedAsDeleted = ({ ...args }) => AliceService.get(
    '/actions/operations/countOrgsMarkedAsDeleted',
    { ...args }
  )

  static cleanupOrgsMarkedAsDeleted = ({ ...args }) => AliceService.post(
    '/actions/operations/cleanupOrgsMarkedAsDeleted',
    { ...args }
  )

  static cleanupMissingEmployeeUsers = ({ ...args }) => AliceService.post(
    '/actions/operations/cleanupMissingEmployeeUsers',
    { ...args }
  )

  static alice2Drd = ({ ...args }) => AliceService.post(
    '/actions/operations/gems2DrdSync',
    { ...args }
  )

  static reregisterQueue = ({
    queueType,
    ...args
  }) => AliceService.post(
    '/actions/operations/reregisterQueue',
    mergeObjects(
      { body: { queueType } },
      { ...args }
    )
  )
}
