export const removeClassRecursively = (element, className) => {
  if (element && element.classList.contains(className)) {
    element.classList.remove(className);
  }

  Array.from(element?.childNodes ?? [])
    .filter((node) => node.nodeType === Node.ELEMENT_NODE)
    .forEach((childNode) => {
      removeClassRecursively(childNode, className);
    });
};
