import React from "react";
import { RoleConfigMainComponent } from "../../styledComponents";
import Header from "@pages/newRequest/components/RoleConfiguration/header";
import MainView from "@pages/newRequest/components/RoleConfiguration/mainView";

const RoleConfigComponent = ({
  footerRef,
  rolesData,
  userData,
  pageIndex,
  saveData = () => {},
  isDisplay,
}) => {
  return (
    <RoleConfigMainComponent isDisplay={isDisplay}>
      <Header />
      <MainView
        footerRef={footerRef}
        rolesData={rolesData}
        userid={userData?.id}
        pageIndex={pageIndex}
        saveRoleData={(data) => saveData(data)}
      />
    </RoleConfigMainComponent>
  );
};

export default RoleConfigComponent;
