import { GridViewOutlined, ViewHeadlineOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CacheKey } from '../../../data'
import { GenericSearch } from '../../search'
import { RoleSearchGridViewRenderer } from './RoleSearchGridViewRenderer'

/**
 * **RoleSearch Component**
 *
 * The `RoleSearch` component is a reusable component that provides a search interface for users.
 * It allows users to search for users using a specified API endpoint and query string.
 *
 * **Important Note**
 *
 * This component is built upon the foundation of the GenericSearch component, thus inheriting all properties supported by GenericSearch.
 * For a comprehensive list of available properties, please refer to the [GenericSearch documentation](./?path=/docs/alice-ui-search-genericsearch--docs).
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 *
 * ** Notes **
 * - Sorting in RoleSearch is currently unavailable
 *
 * **Preselected Filters**
 *
 * Unlike in other search components in RoleSearch component preselection of filters is united into one config object:
 * `preselectedFilters`. Note that filters: `isJobTitle` & `isDynamic` are impossible to preselect simultaneously due
 * to API restrictions. In case they are set together, preselection falls back to isJobTitle: 'true'.
 *
 * **Possible preselected filters**
 *
 * ```js
 * preselectedFilters : {
 *   needsOrgScopes: 'true',
 *   isJobTitle: 'true', // impossible to use with isDynamic simultaneously due to API restrictions
 *   needsCustomScopes: 'true',
 *   isDynamic: 'true', // impossible to use with isJobTitle simultaneously due to API restrictions
 *   isSelfRequestable: 'true'
 * }
 * ```
 */
export const RoleSearch = forwardRef((
  {
    title,
    description,
    apiUrl = '/roles',
    search = 'large',
    views = [ 'grid', 'list' ],
    itemSize,
    isIdLink = true,
    isSelectHidden = false,
    isFilterHidden,
    isSortHidden,
    isSelectAllHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    preselectedRowIds = [],
    preselectedView,
    preselectedSearch,
    preselectedFilters,
    preselectedRowsPerPage,
    preselectedSearchBehavior,
    preselectedFilterBehavior,
    isCaching = false,
    cacheKey = CacheKey.userSearchQuery,
    onGetResponseData = (data) => data.roles,
    onGetUniqueIdentifier = (data) => data.id,
    onCardClick,
    rendererProps = {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')
  const [ activeFilters, setActiveFilters ] = useState()

  const createViewConfig = () => {
    const viewConfig = { views: { } }

    if (views.includes('grid')) {
      viewConfig.views.grid = {
        tooltip: t('roleSearch.view.grid'),
        icon: GridViewOutlined,
        renderer: RoleSearchGridViewRenderer
      }
    }

    if (views.includes('list')) {
      viewConfig.views.list = {
        tooltip: t('roleSearch.view.list'),
        icon: ViewHeadlineOutlined,
        renderer: RoleSearchGridViewRenderer
      }
    }

    return viewConfig
  }

  const createSearchConfig = () => {
    if (search === undefined || search === 'none') return undefined

    return {
      type: search,
      label: t('roleSearch.label'),
      preselectedBehavior: preselectedSearchBehavior
    }
  }

  const createFilterConfig = () => {
    if (isFilterHidden) return undefined

    return {
      isAllShown: false,
      preselectedBehavior: preselectedFilterBehavior,
      filters: {
        single: {
          label: 'Filter by:',
          filters: [
            {
              filterOverwrite: 'needsOrgScopes',
              label: t('roleSearch.filter.orgScope'),
              isSearchShown: false,
              isAllShown: false,
              value: 'true'
            },
            {
              filterOverwrite: 'needsCustomScopes',
              label: t('roleSearch.filter.customScope'),
              isSearchShown: false,
              isAllShown: false,
              value: 'true'
            },
            {
              filterOverwrite: 'isDynamic',
              label: t('roleSearch.filter.dynamicRole'),
              isSearchShown: false,
              isAllShown: false,
              value: 'true',
              isDisabled: !!activeFilters?.isJobTitle?.length
            },
            {
              filterOverwrite: 'isSelfRequestable',
              label: t('roleSearch.filter.selfRequestable'),
              isSearchShown: false,
              isAllShown: false,
              value: 'true'
            },
            {
              filterOverwrite: 'isJobTitle',
              label: t('roleSearch.filter.jobTitle'),
              isSearchShown: false,
              isAllShown: false,
              value: 'true',
              isDisabled: !!activeFilters?.isDynamic?.length
            }
          ]
        }
      }
    }
  }

  const createPreselectedFilter = () => {
    const preselectedFilter = {}

    if (preselectedFilters?.needsOrgScopes) preselectedFilter.needsOrgScopes = [ preselectedFilters.needsOrgScopes ]
    if (preselectedFilters?.needsCustomScopes) preselectedFilter.needsCustomScopes = [ preselectedFilters.needsCustomScopes ]
    if (preselectedFilters?.isDynamic && !preselectedFilters?.isJobTitle) preselectedFilter.isDynamic = [ preselectedFilters.isDynamic ]
    if (preselectedFilters?.isSelfRequestable) preselectedFilter.isSelfRequestable = [ preselectedFilters.isSelfRequestable ]
    if (preselectedFilters?.isJobTitle && !preselectedFilters?.isDynamic) preselectedFilter.isJobTitle = [ preselectedFilters.isJobTitle ]
    if (preselectedFilters?.isJobTitle && preselectedFilters?.isDynamic) preselectedFilter.isJobTitle = [ preselectedFilters.isJobTitle ]

    return Object.keys(preselectedFilter).length ? preselectedFilter : undefined
  }

  return (
    <GenericSearch
      data-testid="RoleSearch"
      ref={ref}
      title={title}
      description={description}
      getUniqueIdentifier={onGetUniqueIdentifier}
      getResponseData={onGetResponseData}
      apiUrl={apiUrl}
      cacheKey={cacheKey}
      searchConfig={createSearchConfig()}
      viewConfig={createViewConfig()}
      filterConfig={createFilterConfig()}
      preselectedRowIds={preselectedRowIds}
      preselectedView={preselectedView}
      preselectedSearch={preselectedSearch}
      preselectedFilter={createPreselectedFilter()}
      preselectedRowsPerPage={preselectedRowsPerPage}
      isCaching={isCaching}
      isSelectAllHidden={isSelectAllHidden}
      isTopPaginationHidden={isTopPaginationHidden}
      isBottomPaginationHidden={isBottomPaginationHidden}
      onItemClick={onCardClick}
      onActiveFiltersChange={(activeFilters) => setActiveFilters(activeFilters)}
      rendererProps={{
        isIdLink,
        size: itemSize,
        ...rendererProps
      }}
      {...otherProps}
    />
  )
})

RoleSearch.propTypes = {
  /** Adds a title */
  title: PropTypes.string,
  /** Adds a description under the title */
  description: PropTypes.string,
  /** Api-Endpoint to collect required data */
  apiUrl: PropTypes.string,
  /** Type of search. Can be 'none', 'small', 'large' */
  search: PropTypes.oneOf([ 'none', 'small', 'large' ]),
  /** Views that should be displayed. */
  views: PropTypes.arrayOf(PropTypes.oneOf([ 'grid', 'list' ])),
  /** size-variant for item renderer */
  itemSize: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** List of filter organization forms */
  forms: PropTypes.arrayOf(PropTypes.string),
  /** Determines if chip is hidden or not */
  isChipHidden: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Determines if filter is hidden or not */
  isFilterHidden: PropTypes.bool,
  /** Determines if sort is hidden or not */
  isSortHidden: PropTypes.bool,
  /** Determines if pagination on top of the grid is hidden or not */
  isTopPaginationHidden: PropTypes.bool,
  /** Determines if pagination on bottom of grid is hidden or not */
  isBottomPaginationHidden: PropTypes.bool,
  /** Preselect rows */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** Preselected view */
  preselectedView: PropTypes.string,
  /** Preselected search query */
  preselectedSearch: PropTypes.string,
  /** Preselected filters. Each Object key takes string 'true' as its value */
  preselectedFilters: PropTypes.shape({
    needsOrgScopes: PropTypes.string,
    needsCustomScopes: PropTypes.string,
    isDynamic: PropTypes.string,
    isSelfRequestable: PropTypes.string,
    isJobTitle: PropTypes.string
  }),
  /** Preselected rows per page */
  preselectedRowsPerPage: PropTypes.number,
  /** Behavior of the search when preselected */
  preselectedSearchBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the filter when preselected. See: [GenericSearch](/?path=/docs/alice-ui-search-genericsearch--docs) */
  preselectedFilterBehavior: PropTypes.oneOf([ 'disableFilter', 'disableFilterGroup', 'hideFilterGroup' ]),
  /** Determines if caching is active or not */
  isCaching: PropTypes.bool,
  /** Custom caching key */
  cacheKey: PropTypes.string,
  /** Called by generic search to get data from api response. Could not be needed when using a custom endpoint. */
  onGetResponseData: PropTypes.func,
  /** Called by generic search to get id from a single data set */
  onGetUniqueIdentifier: PropTypes.func,
  /** Callback on card click */
  onCardClick: PropTypes.func,
  /** Reference with helper functions to control the search from outside */
  reference: PropTypes.any,
  /** props directly passed to item renderer */
  rendererProps: PropTypes.object
}
