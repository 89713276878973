import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Divider, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { getLink, repeatString } from '../../../util'
import { useInteraction } from '../../../hooks'
import { CardContent, CardDivided, ContextBtn, DetailItemType, DetailsList, GenericCard, IdTitle, useDetailItem } from '../../card/genericCard'
import { StatusChip } from '../../chip'
import { IconButton } from '../../button'
import { OrgContextPopover } from '../OrgContext/OrgContextPopover'
import { useOrgCard, OrgCardContextualChips } from './components'

/**
 * A card that displays the information about an organization
 *
 * **Important Note**:<br>
 * This Card is based on the [GenericCard](./?path=/docs/alice-ui-card-genericcard--docs).<br>
 * Therefore, all its props can be used as well.<br>
 */
export const OrgCard = ({
  variant = 'grid',
  size = 'xl',
  organization = {},
  isContextShown: _isContextShown,
  isAddressShown: _isAddressShown,
  isCommunityShown: _isCommunityShown,
  isOrgTypeShown: _isOrgTypeShown,
  isFlagShown: _isFlagShown,
  isFocusShown: _isFocusShown,
  isStatusShown: _isStatusShown,
  onEditClick: _onEditClick, // ({ id, data, e })
  type,
  // base card
  subgridContentColumns = 1,
  subgridContentRows = 1,
  isLoading = false,
  isIdLink = true,
  isSelected = false,
  isDisabled = false,
  isSubgrid = false,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')
  const { getDetailItem } = useDetailItem()

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({ interaction })

  const {
    id,
    name,
    street,
    postalCode,
    location,
    country,
    gssnData,
    externalId,
    communities,
    orgForm,
    orgGroupType: _orgGroupType,
    isActive,
    central,
    decentral
  } = organization

  const addressParts = [
    street,
    postalCode,
    location,
    country
  ]

  // main props
  const isList = variant === 'list'
  const isGrid = !isList

  const displayId = gssnData?.outletId || externalId || id
  const address = addressParts.filter((x) => typeof x === 'string' && x.length).join(', ')
  const community = communities ? t(`orgCommunity.${communities[0]}`) : null
  const orgFormTitle = orgForm && t(`orgForm.${orgForm}`)
  const orgGroupType = _orgGroupType?.toString()
  const status = isActive !== undefined ? isActive ? 'active' : 'inactive' : null

  const {
    isTiny,
    isContextShown,
    isAddressShown,
    isCommunityShown,
    isOrgTypeShown,
    isFlagShown,
    isFocusShown,
    isStatusShown,
    isEditShown,
    isInfoChipsShown,
    isCentralChipsShown,
    isDetailsShown,
    isChipsShown,
    isDividerShown,
    isDividedHorizontal,
    isDividedShown
  } = useOrgCard({
    size,
    isList,
    address,
    community,
    communities,
    orgFormTitle,
    orgForm,
    country,
    orgGroupType,
    status,
    central,
    decentral,
    _isContextShown,
    _isAddressShown,
    _isCommunityShown,
    _isOrgTypeShown,
    _isFlagShown,
    _isFocusShown,
    _isStatusShown,
    _onEditClick
  })

  const isContentShifted = isGrid && isSelectable && !isSelectHidden

  const detailsList = [
    isCommunityShown && getDetailItem({
      type: DetailItemType.ORG_COMMUNITY,
      text: community,
      iconProps: { name: communities[0] }
    }),
    isOrgTypeShown && getDetailItem({
      type: DetailItemType.ORG_FORM,
      text: orgFormTitle,
      iconProps: { name: orgForm }
    })
  ].filter((entry) => entry)

  const getOrganizationType = (type) => {
    switch (type) {
      case 'dealerhome':
        return t('orgItem.dealerHomeOrg')
      case 'workplace':
        return t('orgItem.workingOrg')
      case 'parent':
        return t('orgItem.parent')
      case 'home':
        return t('general.homeOrg')
      default:
        return null
    }
  }

  const onCardClick = _onCardClick ? ({
    id,
    e,
    isSelected
  }) => {
    _onCardClick({
      id,
      isSelected,
      data: organization,
      e
    })
  } : undefined

  const onEditClick = (e) => {
    e.preventDefault()

    _onEditClick({
      id,
      data: organization,
      e
    })
  }

  const context = (
    <ContextBtn
      ContextPopover={OrgContextPopover}
      id={id}
      isLoading={isLoading}
    />
  )

  return (
    <GenericCard
      type="orgCard"
      id={id}
      isList={isList}
      isTiny={isTiny}
      interaction={interaction}
      onCardClick={onCardClick}
      isSelected={isSelected}
      isLoading={isLoading}
      isDividedShown={isDividedShown}
      isDividedHorizontal={isDividedHorizontal}
      isSubgrid={isSubgrid}
      subgridContentColumns={subgridContentColumns}
      subgridContentRows={subgridContentRows}
      sx={[
        isGrid && !isTiny && {
          '.select': {
            display: 'flex',
            alignItems: 'center',
            height: 56
          }
        }
      ]}
      {...otherProps}
    >
      <CardContent
        context={context}
        isList={isList}
        isContextShown={isContextShown}
        isDividedHorizontal={isDividedHorizontal}
        sx={[
          { gridTemplate: `'idTitle ${isAddressShown ? 'address' : ''} ${isDetailsShown ? repeatString(detailsList.length, 'detailsList') : ''}' / 1fr ${isAddressShown ? '1fr' : ''} ${isDetailsShown ? repeatString(detailsList.length, '1fr') : ''}` },
          isGrid && {
            gridTemplate: `
              "idTitle context"
              ${isDividerShown ? '"divider divider"' : ''}
              ${isAddressShown ? '"address address"' : ''}
              ${isDetailsShown ? repeatString(detailsList.length, '"detailsList detailsList"') : ''}
              / 1fr`
          }
        ]}
      >
        <IdTitle
          isLink={isIdLink}
          isLoading={isLoading}
          id={displayId}
          title={name}
          size={size === 'l' || size === 'xl' ? 'm' : size}
          href={getLink('orgDetails', { orgId: id })}
          customIdChip={getOrganizationType(type) && (
            <Typography
              variant="caption"
              sx={{
                p: '4px 8px',
                backgroundColor: 'blue.90',
                width: 'fit-content',
                borderRadius: '4px'
              }}
            >
              {getOrganizationType(type)}
            </Typography>
          )}
          sx={{ gridArea: 'idTitle' }}
        />

        {isDividerShown && (
          <Divider
            sx={[
              { gridArea: 'divider' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isAddressShown && (
          <DetailsList
            data={[ getDetailItem({
              type: DetailItemType.ADDRESS,
              text: address
            }) ]}
            size="s"
            isLoading={isLoading}
            isSubgrid
            sx={[
              { gridArea: 'address' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}

        {isDetailsShown && (
          <DetailsList
            data={detailsList}
            size={variant === 'grid' ? 'm' : 's'}
            isVertical
            isLoading={isLoading}
            isSubgrid={isGrid}
            sx={[
              { gridArea: 'detailsList' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}
      </CardContent>

      <CardDivided
        isShown={isDividedShown}
        isList={isList}
        isTiny={isTiny}
        isHorizontal={isDividedHorizontal}
        isContextShown={isContextShown}
        context={context}
        action={
          isEditShown && (
            <IconButton
              onClick={onEditClick}
              hasSmallClickArea={isTiny}
              sx={{ fontSize: '24px' }}
            >
              <Edit fontSize="inherit" color="inherit" />
            </IconButton>
          )
        }
      >
        {isChipsShown && (
          <OrgCardContextualChips
            central={central}
            decentral={decentral}
            country={country}
            orgGroupType={orgGroupType}
            isTiny={isTiny}
            isLoading={isLoading}
            isInfoChipsShown={isInfoChipsShown}
            isCentralChipsShown={isCentralChipsShown}
            isFlagShown={isFlagShown}
            isFocusShown={isFocusShown}
            sx={{ gridArea: 'contextualChips' }}
          />
        )}

        {isStatusShown && (
          <StatusChip
            type={status}
            isLoading={isLoading}
            sx={{ gridArea: 'statusChip' }}
          />
        )}
      </CardDivided>
    </GenericCard>
  )
}

OrgCard.propTypes = {
  /** Organization data to display within the card */
  organization: PropTypes.shape({
    country: PropTypes.string,
    orgForm: PropTypes.string,
    validFromDate: PropTypes.string,
    preferredLanguage: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string),
    externalId: PropTypes.string,
    gssnData: PropTypes.shape({
      updateTimestamp: PropTypes.string,
      allowFrom: PropTypes.string,
      adinDefId: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      brandCodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          outletCode: PropTypes.string,
          outletStatus: PropTypes.string
        })
      ),
      companyForm: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      }),
      companyId: PropTypes.string,
      countryDefId: PropTypes.string,
      functionInfos: PropTypes.arrayOf(
        PropTypes.shape({
          activity: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          }),
          brandId: PropTypes.string,
          productGroup: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      ),
      languageCode: PropTypes.string,
      outletCode: PropTypes.string,
      outletId: PropTypes.string,
      outletStatus: PropTypes.string,
      street1: PropTypes.string
    }),
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isDaimler: PropTypes.bool,
    isUserManagementDisabled: PropTypes.bool,
    location: PropTypes.string,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string
  }).isRequired,
  /** Defines whether to show context or not. */
  isContextShown: PropTypes.bool,
  /** Defines whether to show Address or not. */
  isAddressShown: PropTypes.bool,
  /** Defines whether to show Community or not. */
  isCommunityShown: PropTypes.bool,
  /** Defines whether to show Organization Type or not. */
  isOrgTypeShown: PropTypes.bool,
  /** Defines whether to show Flag Chip or not. */
  isFlagShown: PropTypes.bool,
  /** Defines whether to show Focus Chip or not. */
  isFocusShown: PropTypes.bool,
  /** Defines whether to show Status or not. */
  isStatusShown: PropTypes.bool,
  /** Slot for custom chips placed in the chip area (Flag, Focus Chip, Status Chip) */
  customChipSlot: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), () => null ]),
  /** Callback function when edit button is clicked. If present, the edit button will be displayed */
  onEditClick: PropTypes.func,
  /** Represents type of organization. */
  type: PropTypes.oneOf([ null, 'home', 'workplace', 'dealerhome', 'parent' ]),
  // base props
  /** Variant of the card */
  variant: PropTypes.oneOf([ 'grid', 'list' ]),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** Loading state. If `true` placeholder will be shown */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note:
   * Even if `isIdLink` is true,
   * visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Specifies if the card is selected or not */
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubgrid: PropTypes.bool,
  interaction: InteractionPropTypes,
  /** Callback function when card gets clicked */
  onCardClick: PropTypes.func,
  sx: PropTypes.any
}
