import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Badge, Box, IconButton, Stack, Toolbar, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Close, Menu } from '@mui/icons-material'
import { MBLogoSvg } from '../../../_assets'
import { useApp, useOpenTasks } from '../../../hooks'
import { mergeSxProps } from '../../../util'
import { AnnouncementsButton } from '../../announcement'
import { EnvironmentChip } from '../../chip'
import { OpenTasksButton } from '../../openTasks'

export const AliceAppBar = forwardRef((
  {
    contentLeft,
    contentRight,
    environment = false,
    showOpenTasks = false,
    showAnnouncements = false, // Set default to false until announcements API arrives
    sx = [],
    ...otherProps
  },
  ref
) => {
  const {
    environment: currentEnvironment,
    isMobileDrawerOpen,
    setIsMobileDrawerOpen
  } = useApp()

  const { openTasksCount } = useOpenTasks()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const environmentChipAttributes = {}

  const isOpenTasksButtonShown = showOpenTasks && !isMobile

  const handleDrawerToggle = () => setIsMobileDrawerOpen(!isMobileDrawerOpen)

  if (environment && typeof environment === 'string') {
    environmentChipAttributes.label = environment // sets a custom label
  }

  return (
    <AppBar
      data-testid="AliceAppBar"
      ref={ref}
      id="aliceHeader"
      sx={mergeSxProps(
        {
          position: 'fixed',
          display: 'flex',
          zIndex: (theme) => theme.zIndex.drawer + 1
        },
        sx
      )}
      {...otherProps}
    >
      <Toolbar sx={{ px: '8px !important', overflow: 'hidden' }}>
        <Stack
          sx={{
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'start',
              overflow: 'hidden',
              alignItems: 'center'
            }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                ml: 1,
                display: { sm: 'none' },
                color: 'common.white',
                fontSize: '24px'
              }}
            >
              {!isMobileDrawerOpen && openTasksCount && (
                <Badge
                  variant="dot"
                  color="primary"
                  componentsProps={{
                    badge: {
                      sx: {
                        height: '10px',
                        width: '10px',
                        borderRadius: '50%',
                        top: '6px',
                        right: '3px',
                        border: '3px solid',
                        borderColor: 'common.black',
                        boxSizing: 'content-box'
                      }
                    }
                  }}
                >
                  <Menu color="inherit" fontSize="inherit" />
                </Badge>
              )}

              {!isMobileDrawerOpen && !openTasksCount && <Menu color="inherit" fontSize="inherit" />}

              {isMobileDrawerOpen && <Close color="inherit" fontSize="inherit" />}

            </IconButton>

            {contentLeft}

            {environment && currentEnvironment !== 'prod' && (
              <EnvironmentChip
                sx={{
                  marginBottom: '16px',
                  marginLeft: '-2px',
                  marginRight: 0
                }}
                {...environmentChipAttributes}
              />
            )}
          </Stack>

          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 42,
              height: 42,
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              display: {
                xs: 'none',
                sm: 'flex'
              }
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                display: 'block'
              }}
              src={MBLogoSvg}
              alt="Mercedes-Benz Logo"
            />
          </Box>

          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'end',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              minHeight: '56px'
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                gap: 2
              }}
            >
              {showAnnouncements && <AnnouncementsButton />}
              {isOpenTasksButtonShown && <OpenTasksButton />}

              {contentRight}
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
})

AliceAppBar.propTypes = {
  /** Content to be displayed at the start of the AppBar. Usually the AppSwitcher */
  contentLeft: PropTypes.node,
  /** Content to be displayed at the end of the AppBar. */
  contentRight: PropTypes.node,
  /**
   * Show type of environment to the right of contentLeft.
   *
   * [true = show environment, false = hide, string = show string]
   */
  environment: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  /** Displays the open tasks button on the right hand side of the AppBar. */
  showOpenTasks: PropTypes.bool,
  /** Enables announcements button */
  showAnnouncements: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
