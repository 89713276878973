import { styled } from "@mui/system";

export const PopupCardHeader = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  "& .Popup-header": {
    padding: "24px 56px 16px 24px",
    width: "100%",
    fontFamily: "MB Corpo S Title",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "28px",
  },
}));
export const PopupCardContainer = styled("div")(({ ...props }) => ({
  // padding: "0px 24px",
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
  "& .validity-holder": {
    display: "flex",
    gap: "16px",
  },
  "& .header_description": {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .review-reason": {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    "& .header-text": {
      fontFamily: "MB Corpo S Text",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
    },
    "& .input-info": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "0.8rem",
    },
  },
  "& .selected-assignments": {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    "& .selected-assignments-title": {
      fontFamily: "MB Corpo S Text",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
    },
  },
}));
export const PopupCardButtons = styled("div")(({ ...props }) => ({
  display: "flex",
  padding: "8px 32px 28px 32px",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  columnGap: "8px",
  alignSelf: "stretch",
  fontFamily: "MB Corpo S Text",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
}));
