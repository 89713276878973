import { createContext, useEffect, useState, useMemo } from 'react'
import { Environment } from '../../constants'
import { AliceThemeProvider } from '../../theme'
import { LocalLogin, Startup } from '../../ui'
import { getClient, getEnvironment } from '../../util'
import { NotificationProvider } from '../notification'
import { OpenTasksProvider } from '../openTasks/OpenTasksProvider'
import { UserProvider } from '../user'

export const AppProviderContext = createContext({})

export const AppProvider = ({ children, overwriteLogo }) => {
  const [ client, setClient ] = useState(getClient())
  const [ environment, setEnvironment ] = useState(getEnvironment())
  const [ isMobileDrawerOpen, setIsMobileDrawerOpen ] = useState(false)
  const [ isMaintenance, setIsMaintenance ] = useState(false)

  useEffect(() => {
    if (environment !== Environment.PRODUCTION && !document.title.startsWith('[')) {
      const currentTitle = document.title
      document.title = `[${environment.toUpperCase()}] ${currentTitle}`
    }
  }, [ environment ])

  return (
    <AppProviderContext.Provider
      value={useMemo(() => ({
        client,
        setClient,
        environment,
        setEnvironment,
        isMobileDrawerOpen,
        setIsMobileDrawerOpen,
        isMaintenance,
        setIsMaintenance
      }), [
        client,
        environment,
        isMobileDrawerOpen,
        isMaintenance
      ])}
    >
      <AliceThemeProvider>
        <NotificationProvider>
          <LocalLogin overwriteLogo={overwriteLogo}>
            <UserProvider>
              <OpenTasksProvider>
                <Startup>
                  {children}
                </Startup>
              </OpenTasksProvider>
            </UserProvider>
          </LocalLogin>
        </NotificationProvider>
      </AliceThemeProvider>
    </AppProviderContext.Provider>
  )
}
