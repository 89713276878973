import { GridViewOutlined, ViewHeadlineOutlined } from '@mui/icons-material'
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined'
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined'
import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CacheKey } from '../../../data'
import { COUNTRY_LIST, USER_COMMUNITIES } from '../../../util'
import { CountryFlag } from '../../icon'
import { GenericSearch } from '../../search'
import { UserSearchGridViewRenderer } from './UserSearchGridViewRenderer'

/**
 * **UserSearch Component**
 *
 * The `UserSearch` component is a reusable component that provides a search interface for users.
 * It allows users to search for users using a specified API endpoint and query string.
 *
 * **Important Note**
 *
 * This component is built upon the foundation of the GenericSearch component, thus inheriting all properties supported by GenericSearch.
 * For a comprehensive list of available properties, please refer to the [GenericSearch documentation](./?path=/docs/alice-ui-search-genericsearch--docs).
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 *
 * ** Note **
 * - Sorting in UserSearch is currently unavailable
 */
export const UserSearch = forwardRef((
  {
    title,
    description,
    apiUrl = '/users',
    search = 'large',
    views = [ 'grid', 'list' ],
    itemSize,
    communities = USER_COMMUNITIES,
    countries = COUNTRY_LIST,
    isIdLink = true,
    isAdminRolesOverwritten = false, // Testing only
    isSelectable = false,
    isFilterHidden,
    isSortHidden,
    isSelectAllHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    preselectedRowIds = [],
    preselectedView,
    preselectedSearch,
    preselectedSort, // TODO: Use when sort is back in the API
    preselectedStatus,
    preselectedCommunities,
    preselectedCountry,
    preselectedRowsPerPage,
    preselectedSearchBehavior,
    preselectedSortBehavior, // TODO: Use when sort is back in the API
    preselectedFilterBehavior,
    isCaching = false,
    cacheKey = CacheKey.userSearchQuery,
    onGetResponseData = (data) => data.users,
    onGetUniqueIdentifier = (data) => data.id,
    onCardClick,
    showDetailedCommunity = false,
    rendererProps = {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const createViewConfig = () => {
    const viewConfig = { views: { } }

    if (views.includes('grid')) {
      viewConfig.views.grid = {
        tooltip: t('userSearch.view.grid'),
        icon: GridViewOutlined,
        renderer: UserSearchGridViewRenderer
      }
    }

    if (views.includes('list')) {
      viewConfig.views.list = {
        tooltip: t('userSearch.view.list'),
        icon: ViewHeadlineOutlined,
        renderer: UserSearchGridViewRenderer
      }
    }

    return viewConfig
  }

  const createSearchConfig = () => {
    if (search === undefined || search === 'none') return undefined

    return {
      type: search,
      label: t('userSearch.search.label'),
      preselectedBehavior: preselectedSearchBehavior
    }
  }

  const createFilterConfig = () => {
    if (isFilterHidden) return undefined

    return {
      isAllShown: true,
      preselectedBehavior: preselectedFilterBehavior,
      filters: {
        community: {
          isAllShown: true,
          label: t('userSearch.filter.community.label'),
          searchLabel: t('userSearch.filter.community.searchLabel'),
          allLabel: t('userSearch.filter.community.allLabel'),
          icon: <GroupWorkOutlinedIcon />,
          isMultiselect: true,
          filters: communities.map((community) => ({
            value: community,
            label: t(`userCommunity.${community}`)
          }))
        },
        country: {
          label: t('userSearch.filter.country.label'),
          searchLabel: t('userSearch.filter.country.searchLabel'),
          allLabel: t('userSearch.filter.country.allLabel'),
          isAutoComplete: true,
          icon: <FlagCircleOutlinedIcon />,
          filters: countries
            .sort((a, b) => (t(`country.${a.value}`) > t(`country.${b.value}`)) - (t(`country.${a.value}`) < t(`country.${b.value}`)))
            .map((country) => ({
              value: country.value,
              label: t(`country.${country.value}`),
              renderer: (
                <Stack direction="row" gap={1}>
                  <CountryFlag countryCode={country.value} />

                  { t(`country.${country.value}`) }
                </Stack>
              )
            }))
        },
        isActive: {
          label: t('userSearch.filter.isActive.label'),
          isSearchShown: false,
          isAllShown: false,
          filters: [
            { value: 'true', label: t('userSearch.filter.isActive.active') },
            { value: 'false', label: t('userSearch.filter.isActive.inactive') }
          ]
        }
      }
    }
  }

  // TODO: Use when sort is back in the API
  // const createSortConfig = () => {
  //   if (isSortHidden) return undefined
  //
  //   return {
  //     preselectedBehavior: preselectedSortBehavior,
  //     sorts: [
  //       {
  //         value: '',
  //         label: t('genericSearch.defaultSort')
  //       },
  //       {
  //         value: '+surname',
  //         label: t('userSearch.sort.+name')
  //       },
  //       {
  //         value: '-surname',
  //         label: t('userSearch.sort.-name')
  //       }
  //     ]
  //   }
  // }

  const createPreselectedFilter = () => {
    const preselectedFilter = {}

    if (preselectedStatus) preselectedFilter.isActive = [ preselectedStatus ]
    if (preselectedCommunities) preselectedFilter.community = preselectedCommunities
    if (preselectedCountry) preselectedFilter.country = [ preselectedCountry ]

    return Object.keys(preselectedFilter).length ? preselectedFilter : undefined
  }

  return (
    <GenericSearch
      data-testid="UserSearch"
      ref={ref}
      title={title}
      description={description}
      getUniqueIdentifier={onGetUniqueIdentifier}
      getResponseData={onGetResponseData}
      apiUrl={apiUrl}
      cacheKey={cacheKey}
      searchConfig={createSearchConfig()}
      viewConfig={createViewConfig()}
      filterConfig={createFilterConfig()}
      // sortConfig={createSortConfig()} // TODO: Use when sort is back in the API
      preselectedRowIds={preselectedRowIds}
      preselectedView={preselectedView}
      preselectedSearch={preselectedSearch}
      // preselectedSort={preselectedSort} // TODO: Use when sort is back in the API
      preselectedFilter={createPreselectedFilter()}
      preselectedRowsPerPage={preselectedRowsPerPage}
      isCaching={isCaching}
      isSelectAllHidden={isSelectAllHidden}
      isTopPaginationHidden={isTopPaginationHidden}
      isBottomPaginationHidden={isBottomPaginationHidden}
      onItemClick={onCardClick}
      rendererProps={{
        isIdLink,
        isAdminRolesOverwritten,
        showDetailedCommunity,
        size: itemSize,
        ...rendererProps
      }}
      {...otherProps}
    />
  )
})

UserSearch.propTypes = {
  /** Adds a title */
  title: PropTypes.string,
  /** Adds a description under the title */
  description: PropTypes.string,
  /** Api-Endpoint to collect required data */
  apiUrl: PropTypes.string,
  /** Type of search. Can be 'none', 'small', 'large' */
  search: PropTypes.oneOf([ 'none', 'small', 'large' ]),
  /** Views that should be displayed. */
  views: PropTypes.arrayOf(PropTypes.oneOf([ 'grid', 'list' ])),
  /** size-variant for item renderer */
  itemSize: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** List of filter organization forms */
  forms: PropTypes.arrayOf(PropTypes.string),
  /** List of filter communities */
  communities: PropTypes.arrayOf(PropTypes.string),
  /** List of filter countries */
  countries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })),
  /** Determines if chip is hidden or not */
  isChipHidden: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Determines if filter is hidden or not */
  isFilterHidden: PropTypes.bool,
  /** Determines if sort is hidden or not */
  isSortHidden: PropTypes.bool,
  /** Determines if pagination on top of the grid is hidden or not */
  isTopPaginationHidden: PropTypes.bool,
  /** Determines if pagination on bottom of grid is hidden or not */
  isBottomPaginationHidden: PropTypes.bool,
  /** Preselect rows */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** Preselected view */
  preselectedView: PropTypes.string,
  /** Preselected search query */
  preselectedSearch: PropTypes.string,
  /** Preselected sort */
  // preselectedSort: PropTypes.string, // TODO: Use when sort is back in the API
  /** Preselected status */
  preselectedStatus: PropTypes.oneOf([ 'true', 'false' ]),
  /** Preselected communities */
  preselectedCommunities: PropTypes.arrayOf(PropTypes.string),
  /** Preselected country. 2 character country code is used here. For example -> 'DE' for Germany. */
  preselectedCountry: PropTypes.string,
  /** Preselected rows per page */
  preselectedRowsPerPage: PropTypes.number,
  /** Behavior of the search when preselected */
  preselectedSearchBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the sort when preselected */
  // preselectedSortBehavior: PropTypes.oneOf([ 'disable' ]), // TODO: Use when sort is back in the API
  /** Behavior of the filter when preselected. See: [GenericSearch](/?path=/docs/alice-ui-search-genericsearch--docs) */
  preselectedFilterBehavior: PropTypes.oneOf([ 'disableFilter', 'disableFilterGroup', 'hideFilterGroup' ]),
  /** Determines if caching is active or not */
  isCaching: PropTypes.bool,
  /** Custom caching key */
  cacheKey: PropTypes.string,
  /** Called by generic search to get data from api response. Could not be needed when using a custom endpoint. */
  onGetResponseData: PropTypes.func,
  /** Called by generic search to get id from a single data set */
  onGetUniqueIdentifier: PropTypes.func,
  /** Callback on card click */
  onCardClick: PropTypes.func,
  /** Determines whether to show detailed community or not. */
  showDetailedCommunity: PropTypes.bool,
  /** Reference with helper functions to control the search from outside */
  reference: PropTypes.any,
  /** props directly passed to item renderer */
  rendererProps: PropTypes.object
}
