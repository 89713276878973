import { mergeObjects } from '../../../util'
import { NotificationsModuleService } from './NotificationsModuleService'

export class NotificationsConfigurationsService {
  static getAll = ({ ...args }) => NotificationsModuleService.get(
    '/config/',
    { ...args }
  )

  static update = ({
    configuration,
    ...args
  }) => NotificationsModuleService.put(
    '/config/',
    mergeObjects(
      { body: configuration },
      { ...args }
    )
  )

  static updateAll = async ({
    updates = [],
    ...args
  }) => {
    const requests = updates.map((update) => NotificationsConfigurationsService.update({
      configuration: {
        key: update.key,
        type: update.type,
        value: update.value
      },
      ...args
    }))

    return NotificationsModuleService.all(requests)
  }

  static create = ({
    configuration,
    ...args
  }) => NotificationsModuleService.post(
    '/config/',
    mergeObjects(
      { body: configuration },
      { ...args }
    )
  )

  static getByPropertyKey = ({
    propertyKey,
    ...args
  }) => NotificationsModuleService.get(
    `/config/${propertyKey}/`,
    { ...args }
  )

  static deleteByPropertyKey = ({
    propertyKey,
    ...args
  }) => NotificationsModuleService.delete(
    `/config/${propertyKey}/`,
    { ...args }
  )
}
