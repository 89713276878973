import { useState, useEffect, useCallback } from "react";
import AddIn from "@components/Inlay";
import { getTaskName } from "@pages/util";
import { RoleCard as RoleCardComponent } from "@alice/component_library";
import { DayLabel } from "@components/sharedComponentTranslation";
import { RSubTopicContainer } from "../../styledComponents";

export const RoleCard = ({ isMobile, taskInfo }) => {
  const { isWorkFlow, affectedData } = taskInfo;
  const { role, orgScopes, customScopes, validityFrom, validityTo } =
    affectedData;

  const dayLabel = DayLabel();

  const [openInlay, setOpenInlay] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const setTaskinfo = useCallback(
    async (cardDetails) => {
      if (role?.id && isWorkFlow) {
        setLoading(true);
        const taskName = await getTaskName(role?.id);
        cardDetails.roleDefinition = taskName;
        setLoading(false);

        setCardInfo(cardDetails);
      } else setCardInfo(cardDetails);
    },
    [isWorkFlow, role]
  );

  useEffect(() => {
    const cardDetails = {
      roleDefinition: role,
      validFrom: validityFrom,
      validTo: validityTo?.includes("T")
        ? validityTo?.split("T")[0]
        : validityTo,
    };

    if (orgScopes?.length > 0) {
      orgScopes.forEach((ele) => {
        cardDetails.orgScope = { name: ele.name, id: ele.id };
      });
    }

    if (customScopes?.length > 0) {
      customScopes.forEach((ele) => {
        cardDetails.customScope = { name: ele.name };
      });
    }
    setTaskinfo(cardDetails);
  }, [role, orgScopes, customScopes, setTaskinfo, validityFrom, validityTo]);

  return (
    <>
      <RSubTopicContainer xs={12} key={role?.id} marginTop>
        <RoleCardComponent
          data={cardInfo}
          variant="grid"
          size="l"
          onExpandClick={(e) => {
            setOpenInlay(!openInlay);
          }}
          isExpanded={openInlay}
          isLoading={loading}
        />
      </RSubTopicContainer>
      {openInlay && (
        <RSubTopicContainer xs={12} marginTop>
          <AddIn
            open={openInlay}
            id={role?.id}
            selectedData={cardInfo}
            onClose={(e) => {
              setOpenInlay(false);
            }}
          />
        </RSubTopicContainer>
      )}
    </>
  );
};
