import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

/**
 * The `Layout` component provides a simple grid system to properly space content on large screensizes, such as 2k and 4k resolutions. It is recommended to use the `AliceThemeProvider` alongside this component to ensure the correct breakpoints are applied. <br>
 * The `Layout` component is designed to help create consistent and responsive layouts within your application. By utilizing its grid system, you can easily organize and arrange your content to adapt to different screen sizes.
 */
export const Layout = ({ children }) => {
  const xlBorder = 2
  const xlContent = 12 - 2 * xlBorder

  // Maybe required later
  // const xxlBorder = 2
  // const xxlContent = 12 - 2 * xxlBorder

  return (
    <Grid container>
      <Grid
        item
        xs={0}
        lg={1}
        xl={xlBorder}
        // xxl={xxlBorder}
      />

      <Grid
        item
        xs={12}
        lg={10}
        xl={xlContent}
        // xxl={xxlContent}
        id="layout-content"
      >
        {children}
      </Grid>

      <Grid
        item
        xs={0}
        lg={1}
        xl={xlBorder}
        // xxl={xxlBorder}
      />
    </Grid>
  )
}

Layout.propTypes = {
  /** The content of the component. */
  children: PropTypes.node
}
