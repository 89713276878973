import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { Tooltip } from '../../message'

export const FocusDot = forwardRef((
  {
    groupType: _groupType,
    size = 'medium',
    sizeStyle,
    sx = {} || [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  if (_groupType === undefined) return null

  let groupType
  const validGroupTypes = [ 0, 1, '0', '1', 'S', 's' ]

  if (validGroupTypes.includes(_groupType)) {
    groupType = _groupType.toString()?.toUpperCase()
  }

  const title = t(`groupType.${groupType}`)

  const sizeMap = {
    small: 16,
    medium: 18,
    big: 20
  }

  const newSize = sizeStyle || sizeMap[size]

  return groupType && (
    <Tooltip
      data-testid="FocusDot"
      ref={ref}
      title={title}
      placement="bottom"
      arrow
      {...otherProps}
    >
      <Box
        sx={mergeSxProps([
          {
            minWidth: newSize,
            minHeight: newSize,
            width: newSize,
            height: newSize,
            borderRadius: '200px',
            border: '1px solid white'
          },
          groupType === '0' && { backgroundColor: 'black' },
          groupType === '1' && { backgroundColor: 'grey.85' },
          groupType === 'S' && (({ palette }) => ({
            transform: 'rotateZ(120deg)',
            backgroundImage: `linear-gradient(0deg, black 50%, ${palette.grey[85]} 50%)`
          }))
        ], sx)}
      />
    </Tooltip>
  )
})

FocusDot.propTypes = {
  /**
   * groupType to display
   *
   * 0=MBAG, 1=DTAG, S=Both
   */
  groupType: PropTypes.oneOf([ '0', '1', 'S', 0, 1 ]),
  /** Size of the dot */
  size: PropTypes.oneOf([ 'small', 'medium', 'big' ]),
  /** Overrides the size with this custom size */
  sizeStyle: PropTypes.string
}
