/**
 * Action Types for components
 */
export const userActionTypes = {
  STORE_SELECTED_USER: "STORE_SELECTED_USER",
  FETCH_LOGGEDIN_USER: "FETCH_LOGGEDIN_USER",
  STORE_LOGGEDIN_USER: "STORE_LOGGEDIN_USER",
  REMOVED_STORED_USERS_DATA: "REMOVED_STORED_USERS_DATA",
  CLEAR_USER_INFO: "CLEAR_USER_INFO",
  USER_PROFILE_DATA: "USER_PROFILE_DATA",
  USER_LIST_IMAGES: "USER_LIST_IMAGES",
  GET_CONTACT_DATA: "GET_CONTACT_DATA",
  UPDATE_WORKING_ORGINFO_OF_LOGGIN_USER:
    "UPDATE_WORKING_ORGINFO_OF_LOGGIN_USER",
  FETCH_ALL_AGREEMENTS: "FETCH_ALL_AGREEMENTS",
  ACCEPT_ALL_AGREEMENTS: "ACCEPT_ALL_AGREEMENTS",
  FETCH_USER_BY_ID: "FETCH_USER_BY_ID",
  USER_ADMIN_ROLES: "USER_ADMIN_ROLES",
};

export const costActionTypes = {
  STORE_COST_DATA: "STORE_COST_DATA",
};
export const openTaskActionTypes = {
  STORE_TASK_INFO: "STORE_TASK_INFO",
  STORE_TASK_COUNT: "STORE_TASK_COUNT",
  STORE_LATEST_TASK_ACTIVITY: "STORE_LATEST_TASK_ACTIVITY",
  STORE_TASK_DEEPLINK: "STORE_TASK_DEEPLINK",
  SAVE_BREADCRUMB_DATA: "SAVE_BREADCRUMB_DATA",
  STORE_SELECTED_TASK_DETAIL: "STORE_SELECTED_TASK_DETAIL",
  STORE_SELECTED_ROW: "STORE_SELECTED_ROW",
  CLEAR_APPROVAL_DATA: "CLEAR_APPROVAL_DATA",
  STORE_SUPERVISOR_DATA: "STORE_SUPERVISOR_DATA",
  FETCH_SUPERVISOR_DATA: "FETCH_SUPERVISOR_DATA",
  STORE_TASK_LIST: "STORE_TASK_LIST",
  STORE_TASK_CONFIG_FROM_BREADCRUMB: "STORE_TASK_CONFIG_FROM_BREADCRUMB",
  UPDATE_TASK_NOTIFICATION: "UPDATE_TASK_NOTIFICATION",
  STORE_REJECT_REASON: "STORE_REJECT_REASON",
  SET_CUSTOMIZED_SNACKBAR: "SET_CUSTOMIZED_SNACKBAR",
  SAVE_APPLICATION_DATA: "SAVE_APPLICATION_DATA",
  UPDATE_COUNT: "UPDATE_COUNT",
  UPDATE_DEEPLINK_COUNT_INFO: "UPDATE_DEEPLINK_COUNT_INFO",
  UPDATE_DEEPLINK_COUNT_UPDATE: "UPDATE_DEEPLINK_COUNT_UPDATE",
};
export const overViewActionTypes = {
  FETCH_REQUEST_OVERVIEWS: "FETCH_REQUEST_OVERVIEWS",
  FETCH_ASSIGNED_ROLES: "FETCH_ASSIGNED_ROLES",
  UPDATE_REQUEST_NOTIFICATION: "UPDATE_REQUEST_NOTIFICATION",
  SET_REQUEST_COUNT: "SET_REQUEST_COUNT",
  SET_OVERVIEW_TASK_INFO: "SET_OVERVIEW_TASK_INFO",
  FETCH_RECENT_REQUEST: "FETCH_RECENT_REQUEST",
  STORE_REQUEST_DEEPLINK: "STORE_REQUEST_DEEPLINK",
};

export const roleActionTypes = {
  FETCH_SELECTED_ROLES: "FETCH_SELECTED_ROLES",
  FETCH_ROLES_BASED_ON_FILTERS: "FETCH_ROLES_BASED_ON_FILTERS",
  ROLE_DATA_UPDATE: "ROLE_DATA_UPDATE",
  FETCH_USER_ASSIGNED_ROLES: "FETCH_USER_ASSIGNED_ROLES",
  FETCH_ALL_ASSIGNED_ROLES: "FETCH_ALL_ASSIGNED_ROLES",
  FETCH_EXP_ROLES: "FETCH_EXP_ROLES",
  GET_ROLE_ENTITLEMENTS: "GET_ROLE_ENTITLEMENTS",
  GET_ORG_SCOPE: "GET_ORG_SCOPE",
  GET_ORG_INFO: "GET_ORG_INFO",
  SUBMIT_DATA: "SUBMIT_DATA",
  CLEAR_SUBMIT_DATA: "CLEAR_SUBMIT_DATA",
  GET_AVAILABLE_APPLICATIONS: "GET_AVAILABLE_APPLICATIONS",
  GET_USER_APPLICATIONS: "GET_USER_APPLICATIONS",
  GET_APPLICATIONS_LOGO: "GET_APPLICATIONS_LOGO",
  GET_USER_ENTITELMENTS: "GET_USER_ENTITELMENTS",
  GET_USER_SUPPORT_CONTACT: "GET_USER_SUPPORT_CONTACT",
  CLEAR_ROLE_INFO: "CLEAR_ROLE_INFO",
  REMOVE_SELECTED_ROLES: "REMOVE_SELECTED_ROLES",
  REMOVE_STORED_ROLES_DATA: "REMOVE_STORED_ROLES_DATA",
  REMOVE_STORED_APP_DATA: "REMOVE_STORED_APP_DATA",
  STORE_SEARCH_KEYWORD: "STORE_SEARCH_KEYWORD",
  SEARCH_ORG_SCOPE: "SEARCH_ORG_SCOPE",
  GET_AVAILABLE_ORG_INFO: "GET_AVAILABLE_ORG_INFO",
  GET_WORK_ORG_INFO: "GET_WORK_ORG_INFO",
  GET_REQUIRED_ORG_INFO: "GET_REQUIRED_ORG_INFO",
  UPDATE_AVAILABLE_APPLICATIONS: "UPDATE_AVAILABLE_APPLICATIONS",
  STORE_EXTEND_DATA: "STORE_EXTEND_DATA",
  FETCH_ROLE_BY_ID: "FETCH_ROLE_BY_ID",
  FETCH_ORG_BY_ID: "FETCH_ORG_BY_ID",
  UPDATE_PREVIOUS_ORG_SCOPES: "UPDATE_PREVIOUS_ORG_SCOPES",
};

export const commonActionTypes = {
  LOADING: "LOADING",
  TAB_INDEX: "TAB_INDEX",
  API_DOWN: "API_DOWN",
  REFRESH_PAGE: "REFRESH_PAGE",
  SET_SNACKBAR: "SET_SNACKBAR",
};
export const profileActionTypes = {
  GET_ORG_COLLEAGUES: "GET_ORG_COLLEAGUES",
  GET_WORKPLACE_COLLEAGUES: "GET_WORKPLACE_COLLEAGUES",
  GET_ADMIN_INFO: "GET_ADMIN_INFO",
  GET_VISIBLE_SUPORT: "GET_VISIBLE_SUPORT",
  GET_MORE_ADMIN_INFO: "GET_MORE_ADMIN_INFO",
};
export const controlCenterActionTypes = {
  HAS_CC_ACCESS: "HAS_CC_ACCESS",
  MY_USER_DETAILS: "MY_USER_DETAILS",
};

export const landingPageActionTypes = {
  TASK_COUNT: "TASK_COUNT",
  REQUEST_COUNT: "REQUEST_COUNT",
};

export const accessReviewActionTypes = {
  ROLE_REVIEW_COUNT: "ROLE_REVIEW_COUNT",
  ROLE_REVIEW_DONE_COUNT: "ROLE_REVIEW_DONE_COUNT",
  ROLE_REVIEW_LIST: "ROLE_REVIEW_LIST",
  ROLE_REVIEW_DETAIL_DATA: "ROLE_REVIEW_DETAIL_DATA",
  ROLE_REVIEW_ROLES_DETAILS: "ROLE_REVIEW_ROLES_DETAILS",
  ROLE_REVIEW_DETAILS: "ROLE_REVIEW_DETAILS",
  ROLE_REVIEW_ROLE_DETAILS_SINGLE: "ROLE_REVIEW_ROLE_DETAILS_SINGLE",
  ROLE_REVIEW_HANDLE_POPUP: "ROLE_REVIEW_HANDLE_POPUP",
  ADD_REVIEW_RESPONSE: "ADD_REVIEW_RESPONSE",
  HAS_REVIEW_ACCESS: "HAS_REVIEW_ACCESS",
  SET_JOB_PROGRESS: "SET_JOB_PROGRESS",
  SET_JOB_URL: "SET_JOB_URL",
  FETCH_OPEN_REVIEW: "FETCH_OPEN_REVIEW",
  SAVE_TABLE_DATA: "SAVE_TABLE_DATA",
};
