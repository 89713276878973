import PropTypes from 'prop-types'
import { createArrayWithInitialValues } from '../../../util'
import { useInteraction } from '../../../hooks'
import { DefaultGridViewRenderer } from '../../search'
import { UserGroupCard } from '../UserGroupCard/UserGroupCard'
import { useUserGroupCard } from '../UserGroupCard'

/**
 * This component renders a grid view using `UserCard` components.
 * The use of MUI-Grid with `UserCard` is preferred to achieve an optimal layout and responsive design.
 *
 * Important Notes:
 * - This component is purely for presentation and does not handle data fetching or state management.
 *   States like `selectedUser` should be managed by a parent component.
 * - It is intended to work in conjunction with components such as `UserSearch` or `GenericSearch` for displaying user data.
 * - On mobile devices, a 'list' view mode is automatically adopted to enhance usability.
 */
export const UserGroupSearchGridViewRenderer = ({
  view = 'grid',
  size = 's',
  data: groups,
  selectedData: selectedGroups = [],
  disabledData: disabledGroups = [],
  onItemClick,
  rowsPerPage,
  interaction,
  onGetHref,
  isAdminRolesOverwritten = false, // Testing only
  isLoading,
  isMobile,
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  if (isMobile) view = 'list'

  const isList = view === 'list'

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({ interaction })

  const hasCheckbox = isSelectable && !isSelectHidden

  const mappableGroups = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({ id: index.toString() }))
    : groups

  const {
    isContextShown: _isContextShown,
    isDescriptionShown: _isDescriptionShown,
    isUserGroupListShown: _isUserGroupListShown,
    onDeleteClick: _onDeleteClick
  } = otherProps || {}

  const {
    isDividedShown,
    subgridContentColumns,
    subgridContentRows
  } = useUserGroupCard({
    size,
    isList: view === 'list',
    isRenderer: true,
    _isContextShown,
    _isDescriptionShown,
    _isUserGroupListShown,
    _onDeleteClick
  })

  return (
    <DefaultGridViewRenderer
      testId="UserGroupSearchGridViewRenderer"
      isList={isList}
      isDividedShown={isDividedShown}
      hasCheckbox={hasCheckbox}
      subgridContentColumns={subgridContentColumns}
    >
      {mappableGroups.map((userGroup, index) => (
        <UserGroupCard
          key={`${userGroup.uuid}-${index}`}
          variant={view}
          size={size}
          userGroupData={userGroup}
          interaction={interaction}
          href={onGetHref}
          isSelected={selectedGroups.includes(userGroup.uuid)}
          isDisabled={disabledGroups.includes(userGroup.uuid)}
          isLoading={isLoading}
          onCardClick={onItemClick}
          isSubgrid
          subgridContentColumns={subgridContentColumns}
          subgridContentRows={subgridContentRows}
          {...otherProps}
        />
      ))}
    </DefaultGridViewRenderer>
  )
}

UserGroupSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  size: PropTypes.oneOf([ 's', 'm' ]),
  /** User groups to be displayed */
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    uuid: PropTypes.string,
    owners: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object)
  })).isRequired,
  /**
   * Interaction behavior of the Card.
   *
   * - *none*: The card is purely for display purposes without any interactive functionality.
   * - *navigate*: The card behaves as a hyperlink, allowing users to navigate to another page or resource upon interaction.
   * - *singleSelect*: Users can select a single option from a list presented within the card using radio buttons.
   * - *multiSelect*: Users can select multiple options from a list presented within the card using checkboxes.
   * - *singleSelectWithoutRadio*: Similar to singleSelect, users can choose a single option from a list, but without the visual representation of radio buttons.
   * - *multiSelectWithoutCheckbox*: Similar to multiSelect, users can select multiple options from a list, but without the visual representation of checkboxes.
   */
  interaction: PropTypes.string,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Selected users */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled user cards */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  /** Loading state */
  isLoading: PropTypes.bool,
  /** True if mobile viewport */
  isMobile: PropTypes.bool
}
