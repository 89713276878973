import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { mergeSxProps } from '../../util'

const LinkRouter = (props) => <Link {...props} component={RouterLink} />

export const Breadcrumbs = forwardRef((
  {
    startIndex = 0,
    path,
    lastSegmentSx,
    linkSegmentSx
  },
  ref
) => {
  const location = useLocation()
  const pathnames = path?.split('/').filter((segment) => segment) || location.pathname.split('/').filter((segment) => segment)

  return (
    <Stack
      data-testid="Breadcrumbs"
      ref={ref}
      direction="row"
      aria-label="breadcrumb"
    >
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const capitalizedSegment = `${value.charAt(0).toUpperCase()}${value.slice(1)}`

        return last ? (
          <Typography
            variant="button"
            key={to}
            sx={lastSegmentSx}
          >
            {capitalizedSegment}
          </Typography>
        ) : (
          index >= startIndex && (
            <Stack direction="row" key={to}>
              <Typography className="linkSegment" variant="button" sx={mergeSxProps({ color: 'text.secondary' }, linkSegmentSx)}>
                <LinkRouter
                  color="inherit"
                  sx={{
                    position: 'relative',
                    textDecoration: 'none',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: 0,
                      height: '2px',
                      bottom: '2px',
                      left: 0,
                      backgroundColor: linkSegmentSx?.color || 'text.secondary',
                      transition: 'width 0.3s ease'
                    },
                    '&:hover::before': { width: '100%', transition: 'width 0.3s ease' }
                  }}
                  to={to}
                >
                  {capitalizedSegment}
                </LinkRouter>
              </Typography>

              <NavigateNextIcon
                sx={{ color: 'grey.75', alignSelf: 'center' }}
                fontSize="small"
              />
            </Stack>
          )
        )
      })}
    </Stack>
  )
})

Breadcrumbs.propTypes = {
  /** Represents an index of a route segment
   * (excluding base route) from which
   * Breadcrumbs should start */
  startIndex: PropTypes.number,
  /** Custom path for specific use cases */
  path: PropTypes.string,
  /** Custom style of last segment in Breadcrumbs line */
  lastSegmentSx: PropTypes.object,
  /** Custom style of link segments in Breadcrumbs line */
  linkSegmentSx: PropTypes.object
}
