import { Client, Environment, EnvironmentUrl } from '../../constants'
import { CLIENT_PORT, CLIENT_URL, ENVIRONMENT_LINK } from '../AliceConstants'

export const getEnvironment = () => {
  if (window?.__env?.OVERWRITE_ENVIRONMENT) {
    return window.__env.OVERWRITE_ENVIRONMENT
  }

  if (window.location.hostname.includes(EnvironmentUrl.GEMS_K8S)) {
    return Environment.GEMS
  }

  switch (window.location.hostname) {
    case EnvironmentUrl.PRODUCTION:
      return Environment.PRODUCTION
    case EnvironmentUrl.INTEGRATION:
      return Environment.INTEGRATION
    case EnvironmentUrl.TEST:
      return Environment.TEST
    case EnvironmentUrl.DEVELOPMENT:
      return Environment.DEVELOPMENT
    case EnvironmentUrl.GEMS:
      return Environment.GEMS
    case EnvironmentUrl.LOCAL:
    case EnvironmentUrl.LOCAL_IPV4:
    case EnvironmentUrl.LOCAL_IPV6:
      return Environment.LOCAL
    default:
      // Fallback to prod if something went wrong. Not breaking prod as main priority.
      return Environment.PRODUCTION
  }
}

export const getEnvironmentLink = (environment = 'prod', client = 'config') => {
  const upperCaseEnv = environment.toUpperCase()

  let link = ENVIRONMENT_LINK[upperCaseEnv]

  // Fallback to prod if env is not known
  if (!link) return ENVIRONMENT_LINK.PROD

  if (upperCaseEnv === 'LOCAL' || upperCaseEnv === 'GEMS') {
    link = `${link}:${CLIENT_PORT[client.toUpperCase()]}`
  }

  return link
}

export const getClient = () => {
  const client = window.location.pathname.split('/')[1].toLowerCase()

  switch (client) {
    case Client.ADMIN:
    case Client.CONFIG:
    case Client.IDENTITY:
      return client
    default:
      return Client.ACCESS
  }
}

export const getClientLink = (client = 'config', env = null) => {
  const upperCaseClient = client.toUpperCase()

  if (window?.__env && window?.__env[`CLIENT_LINK_${upperCaseClient}`] && !env) {
    return window.__env[`CLIENT_LINK_${upperCaseClient}`]
  }

  const link = getEnvironmentLink(
    env || getEnvironment().toUpperCase(),
    upperCaseClient
  )

  return `${link}/${CLIENT_URL[upperCaseClient]}`
}

export const getLink = (type, params = {}) => {
  let baseUrl = `${window.location.origin}/identity`

  if (window?.__env?.CLIENT_LINK_IDENTITY) {
    baseUrl = window?.__env?.CLIENT_LINK_IDENTITY
  }

  switch (type) {
    case 'userDetails':
      // <origin>/identity/User/<userId>
      // https://alice-dev.app.corpintra.net/identity/Users/userId
      if (!params.userId) {
        console.error('utils.getLink: userDetails: param userId is missing!')
        return '#ERROR'
      }

      return `${baseUrl}/Users/${params.userId}`
    case 'orgDetails':
      // <origin>/identity/organizations/<technicalId>
      // https://alice-dev.app.corpintra.net/identity/organizations/orgId
      if (!params.orgId) {
        console.error('utils.getLink: orgDetails: param orgId is missing!')
        return '#ERROR'
      }

      return `${baseUrl}/organizations/${params.orgId}`
    case 'marketDetails':
      // <origin>/identity/markets/<technicalId>
      // https://alice-dev.app.corpintra.net/identity/markets/marketId
      if (!params.marketId) {
        console.error('utils.getLink: marketDetails: param marketId is missing!')
        return '#ERROR'
      }

      return `${baseUrl}/markets/${params.marketId}`
    case 'mbTeams':
      // <origin>/teams/person/<userId>
      // https://teams.app.corpintra.net/teams/person/userID
      if (!params.userId) {
        console.error('utils.getLink: mbTeams: param userId is missing!')
        return '#ERROR'
      }
      return `https://teams.app.corpintra.net/teams/person/${params.userId}`
    default:
      console.error('utils.getLink: No type provided!')
      return '#ERROR'
  }
}

/**
 * TODO: Replace with Env-Variables
 */
export const getDefaultWorkflowDefinition = () => {
  switch (getEnvironment()) {
    case Environment.DEVELOPMENT:
    case Environment.GEMS:
    case Environment.TEST:
    case Environment.LOCAL:
      return '209'
    case Environment.INTEGRATION:
      return '36'
    case Environment.PRODUCTION:
    default:
      return '1'
  }
}
