export const PREF_LANGUAGE_FULL = [
  {
    label: "English (EN)",
    value: "en",
  },
  {
    label: "German (DE)",
    value: "de",
  },
  {
    label: "Abkhazian (AB)",
    value: "ab",
  },
  {
    label: "Afar (AA)",
    value: "aa",
  },
  {
    label: "Afrikaans (AF)",
    value: "af",
  },
  {
    label: "Akan (AK)",
    value: "ak",
  },
  {
    label: "Albanian (SQ)",
    value: "sq",
  },
  {
    label: "Amharic (AM)",
    value: "am",
  },
  {
    label: "Arabic (AR)",
    value: "ar",
  },
  {
    label: "Aragonese (AN)",
    value: "an",
  },
  {
    label: "Armenian (HY)",
    value: "hy",
  },
  {
    label: "Assamese (AS)",
    value: "as",
  },
  {
    label: "Avaric (AV)",
    value: "av",
  },
  {
    label: "Avestan (AE)",
    value: "ae",
  },

  {
    label: "Aymara (AY)",
    value: "ay",
  },
  {
    label: "Azerbaijani (AZ)",
    value: "az",
  },
  {
    label: "Bambara (BM)",
    value: "bm",
  },
  {
    label: "Bashkir (BA)",
    value: "ba",
  },

  {
    label: "Basque (EU)",
    value: "eu",
  },
  {
    label: "Bengali  (BN)",
    value: "bn",
  },
  {
    label: "Bhutani (DZ)",
    value: "dz",
  },
  {
    label: "Bihari (BH)",
    value: "bh",
  },
  {
    label: "Bislama (BI)",
    value: "bi",
  },
  {
    label: "Bosnian (BS)",
    value: "bs",
  },
  {
    label: "Breton (BR)",
    value: "br",
  },
  {
    label: "Bulgarian (BG)",
    value: "bg",
  },
  {
    label: "Burmese (MY)",
    value: "my",
  },
  {
    label: "Byelorussian (BE)",
    value: "be",
  },
  {
    label: "Cambodian (KM)",
    value: "km",
  },
  {
    label: "Catalan (CA)",
    value: "ca",
  },
  {
    label: "Chamorro (CH)",
    value: "ch",
  },
  {
    label: "Chechen (CE)",
    value: "ce",
  },
  {
    label: "Chinese  (ZH)",
    value: "zh",
  },
  {
    label: "Chichewa  (NY)",
    value: "ny",
  },
  {
    label: "Church Slavic (CU)",
    value: "cu",
  },
  {
    label: "Chuvash (CV)",
    value: "cv",
  },
  {
    label: "Croatian (HR)",
    value: "hr",
  },
  {
    label: "Corsican (CO)",
    value: "co",
  },
  {
    label: "Cornish (KW)",
    value: "kw",
  },
  {
    label: "Cree (CR)",
    value: "cr",
  },
  {
    label: "Czech (CS)",
    value: "cs",
  },
  {
    label: "Danish (DA)",
    value: "da",
  },
  {
    label: "Divehi (DV)",
    value: "dv",
  },
  {
    label: "Dutch (NL)",
    value: "nl",
  },
  {
    label: "Esperanto (EO)",
    value: "eo",
  },
  {
    label: "Estonian (ET)",
    value: "et",
  },
  {
    label: "Ewe (EE)",
    value: "ee",
  },
  {
    label: "Faeroese (FO)",
    value: "fo",
  },
  {
    label: "Farsi (FA)",
    value: "fa",
  },
  {
    label: "Fiji (FJ)",
    value: "fj",
  },
  {
    label: "Finnish (FI)",
    value: "fi",
  },
  {
    label: "French (FR)",
    value: "fr",
  },
  {
    label: "Frisian (FY)",
    value: "fy",
  },
  {
    label: "Fulah (FF)",
    value: "ff",
  },
  {
    label: "Galician (GL)",
    value: "gl",
  },
  {
    label: "Gaelic  (GD)",
    value: "gd",
  },
  {
    label: "Ganda (LG)",
    value: "lg",
  },

  {
    label: "Georgian  (KA)",
    value: "ka",
  },
  {
    label: "Greek (EL)",
    value: "el",
  },
  {
    label: "Greenlandic (KL)",
    value: "kl",
  },
  {
    label: "Guarani (GN)",
    value: "gn",
  },
  {
    label: "Gujarati (GU)",
    value: "gu",
  },
  {
    label: "Hausa (HA)",
    value: "ha",
  },
  {
    label: "Haitian (HT)",
    value: "ht",
  },
  {
    label: "Herero (HZ)",
    value: "hz",
  },
  {
    label: "Hebrew (HE)",
    value: "he",
  },
  {
    label: "Hindi (HI)",
    value: "hi",
  },
  {
    label: "Hiri Motu (HO)",
    value: "ho",
  },
  {
    label: "Hungarian (HU)",
    value: "hu",
  },

  {
    label: "Icelandic (IS)",
    value: "is",
  },
  {
    label: "Ido (IO)",
    value: "io",
  },
  {
    label: "Indonesian (ID)",
    value: "id",
  },
  {
    label: "Igbo (IG)",
    value: "ig",
  },
  {
    label: "Interlingua (IA)",
    value: "ia",
  },
  {
    label: "Interlingue (IE)",
    value: "ie",
  },
  {
    label: "Inuktitut (IU)",
    value: "iu",
  },
  {
    label: "Inupiak (IK)",
    value: "ik",
  },
  {
    label: "Irish (GA)",
    value: "ga",
  },
  {
    label: "Italian (IT)",
    value: "it",
  },
  {
    label: "Japanese (JA)",
    value: "ja",
  },
  {
    label: "Javanese (JV)",
    value: "jv",
  },
  {
    label: "Kannada (KN)",
    value: "kn",
  },
  {
    label: "Kanuri (KR)",
    value: "kr",
  },
  {
    label: "Kashmiri (KS)",
    value: "ks",
  },
  {
    label: "Kazakh (KK)",
    value: "kk",
  },
  {
    label: "Kinyarwanda  (RW)",
    value: "rw",
  },
  {
    label: "Kirghiz (KY)",
    value: "ky",
  },
  {
    label: "Kirundi  (RN)",
    value: "rn",
  },
  {
    label: "Kikuyu  (KI)",
    value: "ki",
  },
  {
    label: "Kuanyama  (KJ)",
    value: "kj",
  },

  {
    label: "Kongo  (KG)",
    value: "kg",
  },
  {
    label: "Korean (KO)",
    value: "ko",
  },
  {
    label: "Komi (KV)",
    value: "kv",
  },
  {
    label: "Kurdish (KU)",
    value: "ku",
  },
  {
    label: "Laothian (LO)",
    value: "lo",
  },
  {
    label: "Latin (LA)",
    value: "la",
  },

  {
    label: "Latvian (LV)",
    value: "lv",
  },
  {
    label: "Limburgish (LI)",
    value: "li",
  },
  {
    label: "Lingala (LN)",
    value: "ln",
  },
  {
    label: "Lithuanian (LT)",
    value: "lt",
  },
  {
    label: "Limburgan (LI)",
    value: "li",
  },
  {
    label: "Luba-Katanga (LU)",
    value: "lu",
  },

  {
    label: "Luxembourgish (LB)",
    value: "lb",
  },
  {
    label: "Macedonian (MK)",
    value: "mk",
  },
  {
    label: "Malagasy (MG)",
    value: "mg",
  },
  {
    label: "Malay (MS)",
    value: "ms",
  },
  {
    label: "Malayalam (ML)",
    value: "ml",
  },
  {
    label: "Maltese (MT)",
    value: "mt",
  },
  {
    label: "Manx  (GV)",
    value: "gv",
  },
  {
    label: "Maori (MI)",
    value: "mi",
  },
  {
    label: "Marathi (MR)",
    value: "mr",
  },
  {
    label: "Marshallese (MH)",
    value: "mh",
  },
  {
    label: "Moldavian (MO)",
    value: "mo",
  },
  {
    label: "Mongolian (MN)",
    value: "mn",
  },
  {
    label: "Nauru (NA)",
    value: "na",
  },
  {
    label: "Navajo (NV)",
    value: "nv",
  },
  {
    label: "North Ndebele (ND)",
    value: "nd",
  },
  {
    label: "South Ndebele (NR)",
    value: "nr",
  },
  {
    label: "Ndonga (NG)",
    value: "ng",
  },
  {
    label: "Nepali (NE)",
    value: "ne",
  },
  {
    label: "Northern Sami (SE)",
    value: "se",
  },
  {
    label: "Norwegian Bokmål (NB)",
    value: "nb",
  },

  {
    label: "Norwegian (NO)",
    value: "no",
  },
  {
    label: "Norwegian Nynorsk (NN)",
    value: "nn",
  },
  {
    label: "Occitan (OC)",
    value: "oc",
  },
  {
    label: "Ojibwa (OJ)",
    value: "oj",
  },
  {
    label: "Oriya (OR)",
    value: "or",
  },
  {
    label: "Oromo  (OM)",
    value: "om",
  },
  {
    label: "Ossetian (OS)",
    value: "os",
  },
  {
    label: "Pali (PI)",
    value: "pi",
  },
  {
    label: "Pashto (PS)",
    value: "ps",
  },

  {
    label: "Polish (PL)",
    value: "pl",
  },
  {
    label: "Portuguese (PT)",
    value: "pt",
  },
  {
    label: "Punjabi (PA)",
    value: "pa",
  },
  {
    label: "Quechua (QU)",
    value: "qu",
  },
  {
    label: "Rhaeto-Romance (RM)",
    value: "rm",
  },
  {
    label: "Romanian (RO)",
    value: "ro",
  },
  {
    label: "Russian (RU)",
    value: "ru",
  },
  {
    label: "Samoan (SM)",
    value: "sm",
  },
  {
    label: "Sangro (SG)",
    value: "sg",
  },
  {
    label: "Sanskrit (SA)",
    value: "sa",
  },
  {
    label: "Sardinian (SC)",
    value: "sc",
  },
  {
    label: "Serbian (SR)",
    value: "sr",
  },
  {
    label: "Sesotho (ST)",
    value: "st",
  },
  {
    label: "Setswana (TN)",
    value: "tn",
  },
  {
    label: "Shona (SN)",
    value: "sn",
  },
  {
    label: "Sichuan Yi (II)",
    value: "ii",
  },

  {
    label: "Sindhi (SD)",
    value: "sd",
  },
  {
    label: "Sinhalese (SI)",
    value: "si",
  },
  {
    label: "Siswati (SS)",
    value: "ss",
  },
  {
    label: "Slovak (SK)",
    value: "sk",
  },
  {
    label: "Slovenian (SL)",
    value: "sl",
  },
  {
    label: "Somali (SO)",
    value: "so",
  },
  {
    label: "Spanish (ES)",
    value: "es",
  },
  {
    label: "Sundanese (SU)",
    value: "su",
  },
  {
    label: "Swahili (SW)",
    value: "sw",
  },
  {
    label: "Swedish (SV)",
    value: "sv",
  },
  {
    label: "Tagalog (TL)",
    value: "tl",
  },
  {
    label: "Tajik (TG)",
    value: "tg",
  },
  {
    label: "Tahitian (TY)",
    value: "ty",
  },
  {
    label: "Tamil (TA)",
    value: "ta",
  },
  {
    label: "Tatar (TT)",
    value: "tt",
  },
  {
    label: "Telugu (TE)",
    value: "te",
  },
  {
    label: "Thai (TH)",
    value: "th",
  },
  {
    label: "Tibetan (BO)",
    value: "bo",
  },

  {
    label: "Tigrinya (TI)",
    value: "ti",
  },
  {
    label: "Tonga (TO)",
    value: "to",
  },
  {
    label: "Tsonga (TS)",
    value: "ts",
  },
  {
    label: "Turkish (TR)",
    value: "tr",
  },
  {
    label: "Turkmen (TK)",
    value: "tk",
  },
  {
    label: "Twi (TW)",
    value: "tw",
  },
  {
    label: "Uighur (UG)",
    value: "ug",
  },
  {
    label: "Ukrainian (UK)",
    value: "uk",
  },
  {
    label: "Urdu (UR)",
    value: "ur",
  },
  {
    label: "Uzbek (UZ)",
    value: "uz",
  },
  {
    label: "Venda (VE)",
    value: "ve",
  },
  {
    label: "Vietnamese (VI)",
    value: "vi",
  },
  {
    label: "Volapük (VO)",
    value: "vo",
  },
  {
    label: "Walloon (WA)",
    value: "wa",
  },
  {
    label: "Welsh (CY)",
    value: "cy",
  },
  {
    label: "Wolof (WO)",
    value: "wo",
  },
  {
    label: "Xhosa (XH)",
    value: "xh",
  },
  {
    label: "Yiddish (YI)",
    value: "yi",
  },
  {
    label: "Yoruba (YO)",
    value: "yo",
  },
  {
    label: "Zulu (ZU)",
    value: "zu",
  },
  {
    label: "Zhuang (ZA)",
    value: "za",
  },
];

export const ORG_TYPE_LIST = [
  { value: "ALL_TYPES", label: "All Types" },
  { value: "WORLD", label: "World" },
  { value: "CONTINENT", label: "Continent" },
  { value: "COUNTRY", label: "Country" },
  { value: "MPC", label: "MPC" },
  { value: "GENERAL_DISTRIBUTOR", label: "General Distributor" },
  { value: "DEALER_GROUP", label: "Dealer" },
  { value: "MARKET_AREA", label: "Market Area" },
  { value: "LEGALENTITY", label: "Legal Entity" },
  { value: "SUBLOCATION", label: "Sub Location" },
  { value: "MAINLOCATION", label: "Main Location" },
  { value: "LOCATION", label: "Location" },
  { value: "DAIMLER_COMPANY", label: "Daimler Company" },
  { value: "UNKNOWN", label: "Unknown" },
  { value: "PARTNER_ORGANIZATION", label: "Partner" },
  { value: "GROUP", label: "Group" },
  { value: "SUPPLIER_ORGANIZATION", label: "Supplier" },
];
