import React, { useEffect, useState } from "react";
import ValidityComponet from "./EditscreenBodyComponents/ValidityComponent";
import AttachmentComponent from "./EditscreenBodyComponents/AttachmentsComponent";
import TermsAndCondition from "./EditscreenBodyComponents/TermsandCondition";
import OrganizationCustomScopeContainer from "./EditscreenBodyComponents/OrganizationandCustomscope";

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

const EditorScreenBody = ({
  data,
  userid,
  saveInfo,
  pageIndex,
  combinedData,
  isFromDeeplink = false,
  isExtend = false,
  isReapply = false,
}) => {
  const roleData = data;
  const {
    id,
    data: { roleDefinition = {} },
  } = data;
  const {
    needsCustomScopes,
    needsOrgScopes,
    defaultValidityType,
    needTermsAndCondition = false,
    needAttachmentComponent = false,
    defaultValidityDays,
  } = roleDefinition;
  const defaultValidity = defaultValidityDays || 180;
  const [roeleConfigInformation, setRoledataInformation] = useState(
    roleData?.configurationInformation || {}
  );

  const saveInformation = (data) => {
    const updatedConfiguration = isEmptyObject(roeleConfigInformation)
      ? { ...roleData.configurationInformation } ?? {}
      : roeleConfigInformation;

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // Check if key exists in configurationInformation
        if (updatedConfiguration.hasOwnProperty(key)) {
          // Same key: merge values (assuming objects)
          updatedConfiguration[key] = {
            ...updatedConfiguration[key],
            ...data[key],
          };
        } else {
          // New key: append the data
          updatedConfiguration[key] = data[key];
        }
      }
    }

    let isTimeRandConfigured = false;
    let isScopeisConfigured = false;

    if (needsCustomScopes || needsOrgScopes) {
      isScopeisConfigured = updatedConfiguration?.scopeData?.isValidated;
    } else {
      isScopeisConfigured = true;
    }

    isTimeRandConfigured = updatedConfiguration?.timeRange?.validated;

    setRoledataInformation({
      ...updatedConfiguration,
      isConfigured: isScopeisConfigured && isTimeRandConfigured,
    });
  };

  useEffect(() => {
    const updatedData = {
      ...roleData,
      configurationInformation: roeleConfigInformation,
    };
    saveInfo(id, {
      ...updatedData,
      isConfigured: roeleConfigInformation?.isConfigured,
    });
  }, [roeleConfigInformation]);

  useEffect(() => {
    setRoledataInformation(roleData?.configurationInformation || {});
  }, [id, roleData?.configurationInformation]);

  return (
    <>
      <ValidityComponet
        validity={roleData?.configurationInformation?.timeRange?.validity}
        isDisabled={roleData?.configurationInformation?.timeRange?.isDisabled}
        id={id}
        isOptional={defaultValidityType === "OPTIONAL" ? true : false}
        defaultValidity={defaultValidity}
        validityType={defaultValidityType}
        onValidityChange={(data) => saveInformation(data)}
        pageIndex={pageIndex}
        isExtend={isExtend}
        isReapply={isReapply}
      />
      {needAttachmentComponent && <AttachmentComponent />}
      {needTermsAndCondition && <TermsAndCondition />}
      {(needsCustomScopes || needsOrgScopes) && (
        <OrganizationCustomScopeContainer
          scopeData={roeleConfigInformation?.scopeData?.data ?? {}}
          data={roleDefinition}
          combinedData={combinedData}
          id={id}
          userid={userid}
          changeValue={(data) => saveInformation(data)}
          isFromDeeplink={isFromDeeplink}
          isExtend={isExtend}
          isReapply={isReapply}
        />
      )}
    </>
  );
};

export default EditorScreenBody;
