import React, { forwardRef, isValidElement, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControl, InputLabel, MenuItem, Radio, Select as MuiSelect, Stack } from '@mui/material'

/**
 * Mui Select wrapper with a simplified api
 *
 * https://mui.com/material-ui/api/select/
 */
export const NewSelect = forwardRef((
  {
    items = [],
    value = [],
    activeFilters = {},
    disabledItems = [],
    label,
    variant = 'filled',
    menuProps,
    isCheckboxShown,
    isRadioShown,
    isFullWidth,
    isMultiselect,
    isNative,
    onChange = (event) => {},
    sx = [],
    ...otherProps
  },
  ref
) => {
  const [ selectedItems, setSelectedItems ] = useState([])

  useEffect(() => {
    const activeFilterKeys = Object.keys(activeFilters).filter((key) => Array.isArray(activeFilters[key]) && activeFilters[key].includes('true'))

    items[0]?.filterOverwrite && setSelectedItems(activeFilterKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeFilters ])

  const itemsRenderer = (selected) => {
    if (typeof selected === 'string') return selected

    const selectedItems = []

    let isLabelComponent = false

    selected.forEach((select) => {
      items.forEach((item) => {
        if (typeof item === 'string' && select === item) selectedItems.push(item)

        if (typeof item === 'object' && (select === item.value || select === item?.filterOverwrite)) {
          if (isValidElement(item.label)) isLabelComponent = true

          selectedItems.push(item.label)
        }
      })
    })

    return isLabelComponent
      ? (
        <Stack direction="row" gap={1}>
          { selectedItems }
        </Stack>
      )
      : selectedItems.join(', ')
  }

  const toggleSelected = (e, filterOverwrite) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(filterOverwrite)) {
        return prevSelected.filter((i) => i !== filterOverwrite)
      }

      return [ ...prevSelected, filterOverwrite ]
    })
  }

  return (
    <FormControl fullWidth={isFullWidth} sx={sx} variant={variant}>
      <InputLabel>{label}</InputLabel>

      <MuiSelect
        data-testid="NewSelect"
        ref={ref}
        value={items[0]?.filterOverwrite ? selectedItems || [] : value}
        variant={variant}
        renderValue={itemsRenderer}
        multiple={isMultiselect}
        native={isNative}
        onChange={onChange}
        defaultValue=""
        MenuProps={{
          disableScrollLock: true,
          sx: {
            '.Mui-selected': {
              backgroundColor: 'blue.95',
              color: 'common.black'
            },
            '.Mui-selected:hover': {
              backgroundColor: 'blue.90',
              color: 'common.black'
            }
          },
          ...menuProps
        }}
        {...otherProps}
      >
        { items.map((item) => {
          const { filterOverwrite } = item
          const itemValue = typeof item === 'string' ? item : item.value
          const itemLabel = typeof item === 'string' ? item : item.label
          const isItemDisabled = filterOverwrite ? disabledItems.includes(filterOverwrite) : disabledItems.includes(typeof item === 'string' ? item : item.value)
          const isItemChecked = filterOverwrite ? selectedItems.includes(filterOverwrite) : value.includes(typeof item === 'string' ? item : item.value)

          return (
            <MenuItem
              key={`${itemLabel}-${itemValue}`}
              value={filterOverwrite && isItemChecked ? [] : itemValue}
              name={filterOverwrite}
              disabled={isItemDisabled}
              onClick={(e) => toggleSelected(e, filterOverwrite)}
            >
              { isCheckboxShown && isMultiselect && (<Checkbox checked={isItemChecked} />) }
              { isRadioShown && !isMultiselect && (<Radio checked={isItemChecked} />) }

              { itemLabel }
            </MenuItem>
          )
        })}
      </MuiSelect>
    </FormControl>
  )
})

NewSelect.propTypes = {
  /** Items of the select. Can be a array of strings or and array of objects. */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.any,
        renderer: PropTypes.any
      })
    ])
  ),
  /** Current value of the select */
  value: PropTypes.arrayOf(PropTypes.string),
  /** A list of active filters */
  activeFilters: PropTypes.object,
  /** Displayed label */
  label: PropTypes.string,
  /** Variant */
  variant: PropTypes.oneOf([ 'filled', 'standard', 'outlined' ]),
  /** Enables checkboxes in front of dropdown labels */
  isCheckboxShown: PropTypes.bool,
  /** Enables radio buttons in front of dropdown labels */
  isRadioShown: PropTypes.bool,
  /** Enables full width */
  isFullWidth: PropTypes.bool,
  /** Enables multi selection of items */
  isMultiselect: PropTypes.bool,
  /** Enables native */
  isNative: PropTypes.bool,
  /** On change callback */
  onChange: PropTypes.func,
  /** Custom styles */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
