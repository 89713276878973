import { useTheme, useMediaQuery } from "@mui/material";

export function useWidth() {
  const theme = useTheme();

  const matches = {
    xs: useMediaQuery(theme.breakpoints.up("xs")),
    sm: useMediaQuery(theme.breakpoints.up("sm")),
    md: useMediaQuery(theme.breakpoints.up("md")),
    lg: useMediaQuery(theme.breakpoints.up("lg")),
    xl: useMediaQuery(theme.breakpoints.up("xl")),
    xxl: useMediaQuery(theme.breakpoints.up("xxl")),
  };

  return (
    (matches.xxl && "xxl") ||
    (matches.xl && "xl") ||
    (matches.lg && "lg") ||
    (matches.md && "md") ||
    (matches.sm && "sm") ||
    "xs"
  );
}
