import React, { Component } from "react";
import { isMobile } from "react-device-detect";

import "@src/App.css";
import "@src/Icon.css";
import "./users.css";

import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CircularLoader } from "@utils/loader";
import { managementValue, managementList } from "@utils/managementValue";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  LoadingData,
  RemovedStoredUsersData,
  StoreSelectedUser,
  RemoveAssignableRolesData,
  selectedtabIndex,
  SetSnackBar,
} from "@actions";

import { hasData } from "@utils/Validator";

import { isSuperAdmin, getUUID } from "@utils";
import { UserSearch } from "@alice/component_library";
import { UserContextPopover, UserAvatar } from "@alice/component_library";
import { AliceIcon, AliceIconType } from "@alice/component_library";
import { IconButtonText } from "@alice/component_library";

import { Button } from "@alice/component_library";
import { user } from "@alice/component_library";
import BulkAssign from "./bulkAssignRemoval";
import UserProfileSection from "./userProfileSection";

const MainContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    padding: "40px 40px",
  },
  "& .container": {
    flexDirection: "inherit",
    marginBottom: "40px",
  },
}));

const SubContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 10px",
  },
}));

const SubContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 10px",
  },
  "& .Select_user": {
    width: "100%",
  },
  "& .Select_user .MuiTypography-root.MuiTypography-h5": {
    fontFamily: "MB Corpo S Text",
    fontSize: "18px",
    fontWeight: "700",
    fontStyle: "normal",
    letterspacing: "0px",
    textAlign: "left",
    lineHeight: "28px",
  },
  "& .Select_user_title .MuiTypography-root.MuiTypography-body1": {
    opacity: "1",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "0px",
    textAlign: "left",
    lineHeight: "24px",
  },
}));

const SomeOneUser = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const BulkImportSpan = styled("span")(({ theme }) => ({
  color: "#333333",
  fontWeight: "400",
  fontFamily: "MB Corpo S Text",
  fontSize: "18px !important",
}));

const BulkIconSpan = styled("span")(({ theme }) => ({
  width: "60px",
  height: "60px",
  backgroundColor: "#ffffff",
  border: "2px solid #0078d6",
  borderRadius: "50%",
  paddingLeft: "15px",
  paddingTop: "15px",
  marginTop: "20px",
}));

const CSVFileCOntainer = styled("span")(({ theme }) => ({
  color: "#333333",
  fontWeight: "700",
  fontFamily: "MB Corpo S Text",
  fontSize: "18px !important",
  marginTop: "20px",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    textAlign: "-webkit-center",
  },
}));

const SelectedRoleAlign = styled("span")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "baseline",
    "& div": {
      flexDirection: "column",
    },
  },
}));

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "myself",
      removeflag: false,
      redirect: false,
      userKey: getUUID(),
    };
  }

  componentDidMount() {
    this.props.actions.selectedtabIndex(3);

    if (window.location.href.indexOf("myself") > -1) {
      this.setState({ selected: "myself" });
      this.props.onClickselectedUser("myself");
    } else if (window.location.href.indexOf("delegated") > -1) {
      this.setState({ selected: "delegated" });
      this.props.onClickselectedUser("delegated");
    } else if (window.location.href.indexOf("import") > -1) {
      this.setState({ selected: "import" });
      this.props.onClickselectedUser("import");
    }
  }

  onClickselectedUser = (sl, isImport = false) => {
    const val = sl === "myself";
    this.props.actions.selectedtabIndex(3);
    this.props.onClickselectedUser(sl);
    const path = "/access/accessRequest/";

    if (isImport) {
      this.props.actions.StoreSelectedUser();
      window.history.replaceState("import", "import", `${path}import`);
      this.setState({ selected: "import" });
      return;
    }

    this.setState({ selected: val ? "myself" : "delegated" });
    if (val) {
      this.props.actions.StoreSelectedUser();
      window.history.replaceState("myself", "myself", `${path}myself`);
      this.setState({ removeflag: false });
      return;
    }
    window.history.replaceState("delegated", "delegated", `${path}delegated`);
  };

  OnClickRemoveUser = (element) => {
    this.setState({ userKey: getUUID() });
    const { t } = this.props;
    const { notify } = this.props;
    notify({
      severity: "success",
      message: t("user_removed_successfully"),
    });

    this.props.actions.StoreSelectedUser();
    this.props.actions.RemoveAssignableRolesData();
  };

  selectCard = (data) => {
    const { selectedUserdata } = this.props;
    if (hasData(selectedUserdata) && data.id === selectedUserdata.id) {
      this.props.actions.StoreSelectedUser({});
    } else this.props.actions.StoreSelectedUser(data);
  };

  getLoader = () => (
    <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularLoader />
    </div>
  );

  getText = (active, label) => (
    <div className={!active ? "font-18 m-0 clr-grey" : "font-18 m-0"}>
      {label}
    </div>
  );

  getBulkImport = () => {
    const { t } = this.props;
    const { selected } = this.state;

    return (
      <>
        {!isMobile ? (
          <SelectedRoleAlign>
            <span
              className={"selected-role-align"}
              onClick={() => this.onClickselectedUser("import", 1)}
              style={
                selected === "import"
                  ? {
                      backgroundColor: "rgba(248, 248, 248, 1)",
                    }
                  : { opacity: 0.3 }
              }
            >
              <BulkImportSpan>{t("bulk-import")}</BulkImportSpan>
              <BulkIconSpan
                style={
                  selected === "import"
                    ? { borderColor: "#0078D6" }
                    : { borderColor: "#000000", opacity: 5.5 }
                }
              >
                <IconButtonText
                  text=""
                  icon={
                    <AliceIcon
                      iconType={AliceIconType.BULK}
                      size={30}
                      color={selected === "import" ? "#0078D6" : "#000000"}
                    />
                  }
                />
              </BulkIconSpan>
              <CSVFileCOntainer>{t("bulk-file")}</CSVFileCOntainer>
            </span>
          </SelectedRoleAlign>
        ) : (
          <></>
        )}
      </>
    );
  };

  getUserSelected = (users, type, isSomeOne = false) => {
    const { t } = this.props;
    const { givenname, surname, groupType, managementLevel = "", id } = users;
    const { mailAddress, departmentNumber, communities } = users;
    const { selected } = this.state;
    const label = isSomeOne ? "user_someOneElse" : "user_mySelf";
    const active = selected === type;

    const level =
      managementLevel !== "" && managementList.includes(managementLevel)
        ? " (" + managementValue(managementLevel) + ")"
        : "";
    const userName = `${surname}, ${givenname} ${level}`;
    const department = departmentNumber ? `${id} (${departmentNumber})` : id;

    return (
      <div
        className="selected-role-align user-detail-card"
        style={{
          backgroundColor: active ? "rgba(248, 248, 248, 1)" : "",
        }}
        onClick={() => this.onClickselectedUser(type)}
      >
        {this.getText(active, t(label))}
        {Object.keys(users).length ? (
          active ? (
            <span className="mt-20">
              <UserContextPopover id={id}>
                <UserAvatar
                  userId={id}
                  userName={`${givenname} ${surname}`}
                  groupType={groupType}
                  showGroupType
                  size="big"
                  showImage={user.is.internalEmployee(communities)}
                />
              </UserContextPopover>
            </span>
          ) : (
            <span className="mt-20">
              <UserAvatar
                userId={id}
                userName={`${givenname} ${surname}`}
                groupType={groupType}
                showGroupType
                size="big"
                showImage={user.is.internalEmployee(communities)}
                sizeStyle={{
                  height: 64,
                  width: 64,
                  fontSize: "1.5rem",
                  dotSize: "20px",
                  opacity: active ? "" : 0.5,
                }}
              />
            </span>
          )
        ) : (
          <span
            className="mt-20 someone"
            style={
              active
                ? { borderColor: "#0078D6" }
                : { borderColor: "#000000", opacity: 0.3 }
            }
          >
            <IconButtonText
              text=""
              icon={
                <AliceIcon
                  iconType={AliceIconType.USER_CREATE}
                  size={40}
                  sx={
                    active
                      ? { fill: "#0078D6" }
                      : {
                          fill: "#000000",
                          blur: 0.5,
                        }
                  }
                />
              }
              active={isSomeOne}
              style={{
                fill: active ? "#0078D6" : "#000000",
              }}
            />
          </span>
        )}

        {!hasData(users) ? (
          <div className="mt-20">
            <span className="font-weight-700 mbs">
              {this.getText(active, t("user_pleaseSelect"))}
            </span>
          </div>
        ) : (
          <div className="text-align-center font-normal mt-20">
            <span className="Apply-role-Title">
              {this.getText(active, userName)}
            </span>
            <span className="Apply-role-header">
              {this.getText(active, mailAddress)}
              {this.getText(active, department)}
            </span>

            {isSomeOne && hasData(users) ? (
              <Button
                variant="secondary"
                className="font-16 mt-16"
                onClick={() => this.OnClickRemoveUser(users)}
              >
                {t("user_remove")}
              </Button>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  getProfileImage = (users, type, isSomeOne = false) => {
    const { t } = this.props;
    const { givenname, surname, managementLevel = "", id } = users;
    const { mailAddress, departmentNumber } = users;
    const { selected } = this.state;
    const label = isSomeOne ? "user_someOneElse" : "user_mySelf";
    const active = selected === type;

    const level =
      managementLevel !== "" && managementList.includes(managementLevel)
        ? " (" + managementValue(managementLevel) + ")"
        : "";
    const userName = `${surname}, ${givenname} ${level}`;
    const department = departmentNumber ? `${id} (${departmentNumber})` : id;

    return (
      <div
        className="selected-role-align user-detail-card"
        style={{
          backgroundColor: active ? "rgba(248, 248, 248, 1)" : "",
        }}
        onClick={() => this.onClickselectedUser(type)}
      >
        {this.getText(active, t(label))}
        <UserProfileSection users={users} />
        <div className="text-align-center font-normal mt-20">
          <span className="Apply-role-Title">
            {this.getText(active, userName)}
          </span>
          <span className="Apply-role-header">
            {this.getText(active, mailAddress)}
            {this.getText(active, department)}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { selected, userKey } = this.state;
    const { t, selectedUserdata, userUtils = {} } = this.props;
    const { userData = {} } = userUtils;

    if (userData === undefined || userData === null) return this.getLoader();

    return (
      <MainContainer>
        <SubContainer>
          <div className="header-title">{t("user_apply_role_for")}</div>
        </SubContainer>

        <SomeOneUser className="selected-user">
          {this.getProfileImage(userData, "myself")}
          {hasData(userUtils.adminRoles) &&
          isSuperAdmin(userUtils.adminRoles, "request")
            ? this.getUserSelected(selectedUserdata, "delegated", true)
            : null}
          {window.screen.width > 420 &&
          isSuperAdmin(userUtils?.adminRoles ?? [], "bulkImport")
            ? this.getBulkImport()
            : null}
        </SomeOneUser>

        {selected === "delegated" && (
          <SubContainerGrid>
            <Grid
              className="Select_user Select_user_title Select_user_Focus"
              key={userKey}
            >
              <UserSearch
                interaction="singleSelect"
                onCardClick={(id, selected, data) => this.selectCard(data)}
                preselectedRowIds={[selectedUserdata.id]}
              />
            </Grid>
          </SubContainerGrid>
        )}
        {selected === "import" &&
        isSuperAdmin(userUtils?.adminRoles ?? [], "bulkImport") ? (
          <BulkAssign assign />
        ) : null}
      </MainContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedUserdata: state.user.selectedUserData,
    logginUser: state.user.loggedInUserData,
    isLoading: state.common.isloading,
    count: state.user.count,
    totalCount: state.user.totalCount,
    stUser: state.user.stUser,
    userUtils: state.util,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        LoadingData: LoadingData,
        RemovedStoredUsersData: RemovedStoredUsersData,
        StoreSelectedUser: StoreSelectedUser,
        RemoveAssignableRolesData: RemoveAssignableRolesData,
        selectedtabIndex: selectedtabIndex,
        SetSnackBar: SetSnackBar,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
