import React from "react";
import "@src/App.css";
import "@src/Icon.css";
import "./roles.css";
import _ from "lodash";
import { Grid, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { InputControl } from "@utils/inputControls";
import dynamicSort from "@utils/sorting";
import RoleDetailsOpen from "@components/rolesData";

import RoleCardPanel from "@components/Skeleton/RoleCardPanel";
import RoleEditOptions from "./roleEditOptions";
import { ROLE_OPTIONS } from "@constants/Dropdowns";
import RolesPanelCard from "./roles-panel-card";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "@actions";
import NoResults from "@components/Noresults";
import NoRolesPage from "@components/noRoles";
import RoleDeletePopup from "./roleDeletePopup";
import { IconButtonText } from "@alice/component_library";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@pageIcons/roles/editIcon";
import RemoveCardIcon from "@pageIcons/roles/removeCardIcon";
import { blue } from "@mercedes-benz/mui5-theme";
import { Inlay } from "@alice/component_library/";
import { SearchBar } from "@alice/component_library";
import { TextOnlyTooltip } from "@components/TextOnlyTooltip";
import AutoCompleteWithLazyLoading from "@src/sharedComponents/AutoCompleteWithLazyLoading";
import DevicesIcon from "@mui/icons-material/Devices";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CommonFilters from "@src/sharedComponents/CommonFilters";
import { LayersOutlined } from "@mui/icons-material";

const MainContainer = styled("div")(({ theme }) => ({
  "& .root": {
    flexGrow: 1,
  },
  "& .margin": {
    margin: theme.spacing(1),
  },
  "& .selecteRole": {
    marginTop: "20px",
    marginLeft: "4px",
  },
  "& .subcontainer": {
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
    },
  },
  "& .subdropdown": {
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
      padding: "0px",
    },
  },
  "& .subSelfReq": {
    [theme.breakpoints.down("md")]: {
      margin: "0 15px 20px 10px",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "10px",
      marginRight: "25px",
    },
  },
  "& .subdetails": {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-30px",
      paddingTop: "10px",
      flexFlow: "wrap",
    },
  },
  "& .subTextField": {
    [theme.breakpoints.down("md")]: {
      width: "100.5%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "101.5%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "101.5%",
    },
  },
  "& .subcontainerRole": {
    [theme.breakpoints.down("md")]: {
      marginRight: "-15px",
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiStack-root": {
        // to be removed when role card heading issue fixed from CL
        "& p": {
          "&:nth-of-type(1)": {
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
          },
        },
      },
    },
  },
  "& .inlayContiner": {
    [theme.breakpoints.down("md")]: {
      margin: "0 20px",
    },
  },
  "& .subRemove": {
    "& .MuiStack-root": {
      [theme.breakpoints.down("xs")]: {
        marginLeft: "27px !important",
        marginRight: "10px !important",
      },
    },
  },
  "& .subSelfDropdow": {
    [theme.breakpoints.down("xs")]: {
      textOverflow: "ellipsis",
      maxWidth: "345px",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.detailButtonRef = [];
    this.editButtonRef = [];
    this.state = {
      roleDetails: [],
      searchFlag: false,
      id: "",
      roleData: [],
      showMaxLimitMsg: false,
      width: 0,
      limit: 12,
      offset: 0,
      search: "",
      filterValue: "",
      applicationValue: "",
      showMoreLoading: false,
      searchKey: "",
      filter: ["all"],
      formData: {
        rolefilterBy: {
          label: "All Applications",
          value: "All Applications",
        },
        roleOptions: ROLE_OPTIONS[0].value,
      },
      anchorEl: null,
      selectUserId: "",
      isMenuOpenState: false,
    };
  }

  async componentWillReceiveProps(nextProps) {
    if (
      (nextProps.selectedRoles && nextProps.selectedRoles.length > 0) ||
      nextProps.assignableRoles
    ) {
      await this.setState({
        roleDetails: nextProps.selectedRoles,
        roleData: nextProps.assignableRoles,
      });
    } else {
      await this.setState({ roleDetails: [], roleData: [] });
    }
  }
  async componentWillMount() {
    const { userUtils = {}, selectedUserdata, searchKeyword } = this.props;
    this.setState({ searchKey: searchKeyword });
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    const selfRequestable = displayUser.id === logginUser.id ? true : false;
    const roleOption =
      displayUser.id === logginUser.id
        ? "Self requestable"
        : ROLE_OPTIONS[0].value;
    await this.setState({
      formData: _.set({ ...this.state.formData }, "roleOptions", roleOption),
    });
    await this.props.actions.LoadingData(true);
    if (this.props.selectedRoles.length === 0) {
      await this.props.actions.InitialFetchUsersAssignedRolesData(
        displayUser.id,
        this.state.offset,
        this.state.limit,
        selfRequestable,
        false,
        this.state.filter
      );
    }
    this.setState({ selectUserId: displayUser.id });
    // await this.props.actions.getAvailableApplications(displayUser.id);
    await this.setState({
      roleData: this.props.assignableRoles,
      roleDetails: this.props.selectedRoles,
    });
    await this.props.actions.LoadingData(false);
  }
  loadMore = async () => {
    await this.setState({ searchFlag: false });
    await this.setState({ showMoreLoading: true });
    const { formData, search, offset, limit, filterValue, applicationValue } =
      this.state;
    const offsetValue = offset;
    const limitValue = limit + 12;
    const { userUtils = {}, selectedUserdata } = this.props;
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    const roleOption =
      displayUser.id === logginUser.id
        ? "Self requestable"
        : !filterValue.length
        ? ROLE_OPTIONS[0].value
        : filterValue;
    await this.props.actions.FetchRolesBasedOnSearch(
      displayUser.id,
      offsetValue,
      limitValue,
      search,
      formData,
      false,
      0,
      this.state.filter
    );
    await this.setState({
      offset: offsetValue,
      limit: limitValue,
      roleData: this.props.assignableRoles,
      roleDetails: this.props.selectedRoles,
    });
    await this.setState({
      formData: _.set({ ...this.state.formData }, "roleOptions", roleOption),
    });

    if (applicationValue.length) {
      await this.setState({
        formData: _.set(
          { ...this.state.formData },
          "rolefilterBy",
          applicationValue
        ),
      });
    }
    await this.setState({ showMoreLoading: false });
  };
  loadLess = async () => {
    await this.setState({ searchFlag: false });
    await this.setState({ showMoreLoading: true });
    const { formData, search, offset, filterValue, applicationValue } =
      this.state;
    const offsetValue = offset;
    const limitValue = 12;

    const { userUtils = {}, selectedUserdata } = this.props;
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    const roleOption =
      displayUser.id === logginUser.id
        ? "Self requestable"
        : !filterValue.length
        ? ROLE_OPTIONS[0].value
        : filterValue;
    await this.props.actions.FetchRolesBasedOnSearch(
      displayUser.id,
      offsetValue,
      limitValue,
      search,
      formData,
      false,
      0,
      this.state.filter
    );
    await this.setState({
      offset: offsetValue,
      limit: limitValue,
      roleData: this.props.assignableRoles,
      roleDetails: this.props.selectedRoles,
      showMaxLimitMsg: false,
    });
    await this.setState({
      formData: _.set({ ...this.state.formData }, "roleOptions", roleOption),
    });
    if (applicationValue.length) {
      await this.setState({
        formData: _.set(
          { ...this.state.formData },
          "rolefilterBy",
          applicationValue
        ),
      });
    }
    await this.setState({ showMoreLoading: false });
  };

  showAll = async () => {
    const { userUtils = {}, selectedUserdata } = this.props;
    await this.setState({ showMoreLoading: true });
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    const { formData, search, offset } = this.state;
    await this.setState({ searchFlag: false });
    this.props.actions.LoadingData(true);
    await this.props.actions.FetchRolesBasedOnSearch(
      displayUser.id,
      offset,
      500,
      search,
      formData,
      false,
      0,
      this.state.filter
    );
    this.props.actions.LoadingData(false);
    await this.setState({
      roleData: this.props.assignableRoles,
      showMaxLimitMsg: true,
    });
    await this.setState({ showMoreLoading: false });
  };

  updateDimensions = async () => {
    await this.setState({ width: window.screen.width });
  };
  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    document.getElementById("scroller").scroll(0, 0);
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  uniqueByKeepLast(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
  setDetailsChange = async (value, index) => {
    let data = [...this.state.roleDetails];
    for (var i = 0; i < data.length; i++) {
      if (data[i].id === value) {
        data[i].isRoledetailsOpen = !data[i].isRoledetailsOpen;
        data[i].isRequestDetailsOpen = false;
        this.setState({ anchorEl: this.detailButtonRef[index] });
      } else {
        data[i].isRequestDetailsOpen = false;
        data[i].isRoledetailsOpen = false;
      }
    }

    await this.setState({ roleDetails: data, id: value });

    let filteredData = data.filter((elem) => elem.id === value);
    if (filteredData && filteredData.length > 0) {
      await this.props.actions.GetRolesEntitlementData(filteredData[0].id);
    } else {
      await this.props.actions.StoreSearchKeyword();
    }
  };
  setEditOptionsChange = async (value, index) => {
    let data = [...this.state.roleDetails];

    for (var i = 0; i < data.length; i++) {
      if (data[i].id === value) {
        data[i].isRequestDetailsOpen = !data[i].isRequestDetailsOpen;
        data[i].isRoledetailsOpen = false;
        data[i].dialogOpen = false;
        this.setState({ anchorEl: this.editButtonRef[index] });
      } else {
        data[i].isRequestDetailsOpen = false;
        data[i].isRoledetailsOpen = false;
        data[i].dialogOpen = false;
      }
    }

    await this.setState({ roleDetails: data, id: value });
  };
  handleClose = async (value) => {
    let data = [...this.state.roleDetails];
    for (var i = 0; i < data.length; i++) {
      if (data[i].id === value) {
        data[i].dialogOpen = false;
        await this.setState({ roleDetails: data, id: value });
      }
    }
  };
  handleDeleteHandler = async (value) => {
    let data = [...this.state.roleDetails];
    for (var i = 0; i < data.length; i++) {
      if (data[i].id === value) {
        data[i].dialogOpen = true;
        await this.setState({ roleDetails: data, id: value });
      }
    }
  };
  removeSelectedRole = async (value) => {
    const { notify } = this.props;
    const { t } = this.props;
    notify({
      severity: "success",
      message: t("role_removed_successfully"),
    });
    let data = [...this.state.roleDetails];
    for (var i = 0; i < data.length; i++) {
      if (data[i].id === value) {
        data[i].selected = !data[i].selected;
        data[i].dialogOpen = false;
        data[i].selectedScopes = [];
        await this.setState({ roleDetails: data, id: value });
        await this.props.actions.removeselectedRoles(data[i].id);
        await this.props.actions.updatePreviousOrgScope(
          this.props.previousOrgScopes.filter((obj) => {
            return obj.key.id !== value;
          })
        );
      }
    }
  };
  onHandleChange = async (event) => {
    let keyword = event.target === undefined ? event : event.target.value;
    await this.setState({ search: keyword, searchFlag: false, limit: 12 });
  };
  handleKeyPress = async (event) => {
    const { formData, offset, limit } = this.state;
    if (event.keyCode === 0 && event.key === "Enter") {
      const keyword = event.target.value;
      await this.setState({ searchFlag: true });
      const { userUtils = {}, selectedUserdata } = this.props;
      const logginUser = userUtils.userData;
      const displayUser = _.isEmpty(selectedUserdata)
        ? logginUser
        : selectedUserdata;

      await this.setState({ search: keyword });
      await this.props.actions.LoadingData(true);
      await this.props.actions.FetchRolesBasedOnSearch(
        displayUser.id,
        offset,
        limit,
        keyword,
        formData,
        false,
        0,
        this.state.filter
      );
      await this.setState({
        roleData: this.props.assignableRoles,
        roleDetails: this.props.selectedRoles,
        showMaxLimitMsg: false,
      });
      await this.props.actions.LoadingData(false);
    }
  };
  clearSearchData = async () => {
    const { formData, offset, limit } = this.state;
    await this.setState({ search: "" });
    const { userUtils = {}, selectedUserdata } = this.props;
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    this.props.actions.LoadingData(true);
    await this.props.actions.FetchRolesBasedOnSearch(
      displayUser.id,
      offset,
      limit,
      "",
      formData,
      false,
      0,
      this.state.filter
    );
    this.props.actions.LoadingData(false);
  };

  applyFilter = async () => {
    const { userUtils = {}, selectedUserdata } = this.props;
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    const { formData, search, offset, limit } = this.state;
    await this.setState({ searchFlag: true });
    this.props.actions.LoadingData(true);
    await this.props.actions.FetchRolesBasedOnSearch(
      displayUser.id,
      offset,
      limit,
      search,
      formData,
      false,
      0,
      this.state.filter
    );
    this.props.actions.LoadingData(false);
  };
  setApplicationValueValue = async (name, value) => {
    const { formData } = this.state;
    let selectedAppName = value;
    await this.setState({ limit: 12 });
    if (selectedAppName === undefined) {
      selectedAppName = {
        label: "All Applications",
        value: "All Applications",
      };
      await this.setState(
        { formData: _.set({ ...formData }, name, selectedAppName) },
        () => {
          this.applyFilter();
        }
      );
    } else {
      // const res = this.props.availApplications.find(
      //   (x) => x.label === selectedAppName
      // );
      // this.setState({ applicationValue: res });
      // const id = res === undefined ? "" : res.value;

      await this.setState(
        { formData: _.set({ ...formData }, name, selectedAppName) },
        () => {
          this.applyFilter();
        }
      );
    }
  };

  truncatedString = (name) => {
    if (name.length > 17) {
      return name.substring(0, 17) + "...";
    } else {
      return name;
    }
  };

  setRoleData = (roleData) => {
    this.setState({ roleData });
  };

  onSelectChange = (name) => async (e) => {
    const { formData } = this.state;
    await this.setState({ filterValue: e.target.value });
    await this.setState({ limit: 12 });
    await this.setState(
      {
        formData: _.set({ ...formData }, name, e.target && e.target.value),
      },
      () => {
        this.applyFilter();
      }
    );
  };
  searchSubmit = async (search = "") => {
    this.setState({ searchKey: search });
    const { formData, offset, limit } = this.state;
    const { userUtils = {}, selectedUserdata } = this.props;
    const logginUser = userUtils.userData;
    const displayUser = _.isEmpty(selectedUserdata)
      ? logginUser
      : selectedUserdata;

    this.props.actions.LoadingData(true);
    await this.props.actions.StoreSearchKeyword(search);
    await this.setState({ searchFlag: search !== "", search });
    await this.props.actions.FetchRolesBasedOnSearch(
      displayUser.id,
      offset,
      limit,
      search,
      formData,
      false,
      0,
      this.state.filter
    );
    await this.setState({
      roleData: this.props.assignableRoles,
      roleDetails: this.props.selectedRoles,
      showMaxLimitMsg: false,
    });
    await this.props.actions.LoadingData(false);
  };

  getNodaData = (selfRequestable) => {
    const { search, searchFlag } = this.state;
    return search !== "" ? (
      <NoResults keysearch={search} searchFlag={searchFlag} />
    ) : (
      <NoRolesPage selfRequestable={selfRequestable} />
    );
  };

  handleSearchChange = (event) => {
    this.setState({ searchKey: event.target.value });
  };

  handleFilter = async (ele) => {
    let selectedFilter = [...this.state.filter];
    if (
      (ele === "JOBTITLE" || ele === "BUSINESS") &&
      (selectedFilter.includes("JOBTITLE") ||
        selectedFilter.includes("BUSINESS")) &&
      selectedFilter.length === 1
    ) {
      const newSelectedFilter = [...selectedFilter, ele];
      selectedFilter = newSelectedFilter;
    } else {
      selectedFilter = [ele];
    }

    this.setState({ ...this.state, filter: selectedFilter, offset: 0 }, () => {
      this.applyFilter();
    });
  };

  render() {
    const { roleDetails, formData } = this.state;
    const { t, isLoading, availApplications } = this.props;

    const dropdownApplications = availApplications.sort(dynamicSort("label"));
    const allApps = [
      { label: "All Applications", value: "All Applications" },
      ...dropdownApplications,
    ];

    const response = this.uniqueByKeepLast(allApps, (it) => it.value);
    const defaultProps = {
      options: response,
      getOptionLabel: (option) => option.label,
    };
    const displayUser = _.isEmpty(this.props.selectedUserdata)
      ? this.props.logginUser
      : this.props.selectedUserdata;
    const selfRequestable =
      displayUser.id === this.props.logginUser.id ? true : false;
    let rolesOption = ROLE_OPTIONS.map((lang) => ({
      label: t(lang.label),
      value: lang.value,
    }));
    const { roleData } = this.state;
    const filters = [
      { value: "all", label: t("all") },
      { value: "BUSINESS", label: t("business"), icon: <BusinessIcon /> },
      { value: "PRODUCT", label: t("product"), icon: <DevicesIcon /> },
      {
        value: "ENTITLEMENT",
        label: t("application"),
        icon: <LayersOutlined />,
      },
      {
        value: "JOBTITLE",
        label: t("job-title"),
        icon: <BadgeOutlinedIcon />,
      },
    ];

    return (
      <MainContainer className="rolesroot">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} className="subcontainer">
            <p className="select_role">{t("selected_roles")}</p>
          </Grid>
          {roleDetails.length > 0 ? (
            roleDetails.map((elem, index) => {
              this.detailButtonRef[index] = React.createRef();
              this.editButtonRef[index] = React.createRef();
              return (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={
                      elem.isRequestDetailsOpen || elem.isRoledetailsOpen
                        ? "container selected-role-box mt-16 bg-clr-grey subcontainer"
                        : "container selected-role-box  mt-16 subcontainer"
                    }
                  >
                    <Grid
                      item
                      xs={10}
                      sm={5}
                      md={5}
                      lg={6}
                      elem
                      className="font-20 text-center-mob"
                    >
                      {" "}
                      {elem.name && elem.name.length > 20 ? (
                        <TextOnlyTooltip title={elem.name} placement="top">
                          <span>
                            {elem.name}
                            {elem.selectedScopes.length > 1
                              ? " (" + elem.selectedScopes.length + ")"
                              : ""}
                          </span>
                        </TextOnlyTooltip>
                      ) : (
                        <span>
                          {elem.name}
                          {elem.selectedScopes.length > 1
                            ? " (" + elem.selectedScopes.length + ")"
                            : ""}
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={7}
                      lg={6}
                      className="flex justify-content-flex subdetails"
                    >
                      <Grid
                        className="font-16 mr-10 cursor-pointer"
                        ref={(ref) => (this.detailButtonRef[index] = ref)}
                      >
                        <IconButtonText
                          text={t("details")}
                          icon={<InfoIcon viewBox="0 0 24 24" />}
                          onClick={() => this.setDetailsChange(elem.id, index)}
                          active={elem.isRoledetailsOpen}
                          color="#000000"
                        />
                      </Grid>
                      <Grid
                        className="font-16 mx-10 cursor-pointer"
                        ref={(ref) => (this.editButtonRef[index] = ref)}
                      >
                        <IconButtonText
                          text={t("edit")}
                          icon={
                            <EditIcon
                              viewBox="0 0 24 24"
                              color={
                                elem.isRequestDetailsOpen ? blue[45] : "#000000"
                              }
                            />
                          }
                          onClick={() =>
                            this.setEditOptionsChange(elem.id, index)
                          }
                          active={elem.isRequestDetailsOpen}
                          color="#000000"
                        />
                      </Grid>
                      <Grid className="font-16 mx-10 cursor-pointer subRemove">
                        <IconButtonText
                          text={t("remove")}
                          icon={
                            <RemoveCardIcon
                              viewBox="0 0 24 24"
                              color="#000000"
                            />
                          }
                          onClick={
                            elem.selectedScopes.length > 1
                              ? () => this.handleDeleteHandler(elem.id)
                              : () => this.removeSelectedRole(elem.id)
                          }
                          color="#000000"
                          className="subRemove"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="inlayContiner">
                    <Inlay
                      open={elem.isRequestDetailsOpen}
                      anchorElement={this.state.anchorEl}
                    >
                      <RoleEditOptions
                        setEditOptionsChange={() =>
                          this.setEditOptionsChange(elem.id)
                        }
                        id={elem.id}
                        isAddRoleOpen={elem.isAddRoleOpen}
                        addRoledata={elem}
                      />
                    </Inlay>
                    <Inlay
                      open={elem.isRoledetailsOpen}
                      anchorElement={this.state.anchorEl}
                    >
                      <RoleDetailsOpen
                        width={this.state.width}
                        roleData={elem}
                        setDetailsChange={() => this.setDetailsChange(elem.id)}
                        id={this.state.id}
                      />
                    </Inlay>
                  </div>
                  {elem.dialogOpen && (
                    <RoleDeletePopup
                      handleClose={() => this.handleClose(elem.id)}
                      setEditOptionsChange={() =>
                        this.setEditOptionsChange(elem.id)
                      }
                      deleteHandler={() => this.removeSelectedRole(elem.id)}
                      open={elem.dialogOpen}
                      roleInfo={elem}
                      width={this.state.width}
                    />
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="selected-role-box mb-16 subcontainer"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="font-20"
                style={{ marginLeft: "-1rem" }}
              >
                {t("please-select-roles")}
              </Grid>{" "}
            </Grid>
          )}
          <br />
          <br />
          <Grid item className="Search_bar subcontainer">
            <FormControl className="width-100p" variant="filled">
              <SearchBar
                label={t("searchbar_label_role")}
                onSearch={(d) => this.searchSubmit(d)}
                onClear={() => this.searchSubmit("")}
                variant="filled"
                autoComplete="off"
                fullWidth
                value={this.state.searchKey ?? ""}
                onChange={this.handleSearchChange}
              />
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="filterUnVisible subdropdown"
              style={{ paddingRight: "7px" }}
            >
              <AutoCompleteWithLazyLoading
                userId={this.state.selectUserId}
                searchLabel={t("applications")}
                actionHandler={(value) =>
                  this.setApplicationValueValue("rolefilterBy", value)
                }
                isMenuOpen={(value) =>
                  this.setState({ isMenuOpenState: value })
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="subcontainer"
              style={{ zIndex: this.state.isMenuOpenState ? -1 : 0 }}
            >
              {/* <div className="subSelfDropdow"> */}
              <InputControl
                type="select"
                name="roleOptions"
                labelName={t("role-options")}
                onChange={this.onSelectChange("roleOptions")}
                options={rolesOption}
                disabled={selfRequestable}
                value={
                  selfRequestable
                    ? "Self requestable"
                    : formData.roleOptions !== undefined
                    ? formData.roleOptions
                    : ""
                }
                style={{
                  margin: "20px 0px 8px",
                }}
              />
              {/* </div> */}
            </Grid>
            <Grid item xs={12} className="subcontainer">
              <CommonFilters
                label={`${t("filter-by-role-type")}:`}
                filters={filters}
                filter={this.state.filter}
                handleFilter={this.handleFilter}
                sx={{
                  marginTop: "8px",
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.25rem",
                  },
                  "& .MuiChip-root": {
                    zIndex: this.state.isMenuOpenState ? -1 : 0,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="subcontainer">
            {isLoading ? (
              <div className="mh-60vh">
                <RoleCardPanel />
              </div>
            ) : roleData.length > 0 ? (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className="mt-30 subcontainerRole"
                style={{ wordBreak: "break-all" }}
              >
                <RolesPanelCard
                  roledata={roleData}
                  setRoleData={(e) => this.setRoleData(e)}
                  showAll={this.showAll}
                  loadMore={this.loadMore}
                  loadLess={this.loadLess}
                  showMaxLimitMsg={this.state.showMaxLimitMsg}
                  showMoreButton={this.state.showMoreLoading}
                />
              </Grid>
            ) : (
              <div>{this.getNodaData(selfRequestable)}</div>
            )}
          </Grid>
        </Grid>
      </MainContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedRoles: state.role.selectedRoles,
    selectedUserdata: state.user.selectedUserData,
    logginUser: state.user.loggedInUserData,
    assignableRoles: state.role.assignableRoles,
    isLoading: state.common.isloading,
    availApplications: state.role.availableApplications,
    userUtils: state.util,
    previousOrgScopes: state.role.previousOrgScopes,
    searchKeyword: state.role.searchKeyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        LoadingData: Actions.LoadingData,
        selectedRoles: Actions.selectedRoles,
        InitialFetchUsersAssignedRolesData:
          Actions.InitialFetchUsersAssignedRolesData,
        GetRolesEntitlementData: Actions.GetRolesEntitlementData,
        RemoveRolesEntitlementData: Actions.RemoveRolesEntitlementData,
        FetchRolesBasedOnSearch: Actions.FetchRolesBasedOnSearch,
        getAvailableApplications: Actions.getAvailableApplications,
        removeselectedRoles: Actions.removeselectedRoles,
        StoreSearchKeyword: Actions.StoreSearchKeyword,
        SetSnackBar: Actions.SetSnackBar,
        updatePreviousOrgScope: Actions.updatePreviousOrgSCopeRoleData,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
