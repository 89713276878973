import { LightTheme } from '@mercedes-benz/mui5-theme'
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

const getDeviceConfig = (width) => {
  const breakPoints = LightTheme.breakpoints.values

  if (width < breakPoints.sm) {
    return 'xs'
  } if (width < breakPoints.md) {
    return 'sm'
  } if (width < breakPoints.lg) {
    return 'md'
  } if (width < breakPoints.xl) {
    return 'lg'
  } if (width < breakPoints.xxl) {
    return 'xl'
  } if (width >= breakPoints.xxl) {
    return 'xxl'
  }

  return 'error'
}

export const GetBreakpoint = () => {
  const [ brkPnt, setBrkPnt ] = useState(() => getDeviceConfig(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = () => { setBrkPnt(getDeviceConfig(window.innerWidth)) }

    window.addEventListener('resize', calcInnerWidth)

    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return (
    <Box><Typography>{`Current breakpoint is: ${brkPnt}`}</Typography></Box>
  )
}
