import { useContext } from 'react'
import { AppProviderContext } from './AppProvider'

export const useApp = () => {
  const {
    client,
    setClient,
    environment,
    setEnvironment,
    isMobileDrawerOpen,
    setIsMobileDrawerOpen,
    isMaintenance,
    setIsMaintenance
  } = useContext(AppProviderContext)

  return {
    client,
    setClient,
    environment,
    setEnvironment,
    isMobileDrawerOpen,
    setIsMobileDrawerOpen,
    isMaintenance,
    setIsMaintenance
  }
}
