import { Grid } from "@mui/material";
import {
  SubTopicContainer,
  SubHeader,
  SubHeaderTypography,
  TaskRequestDetailsContainer,
  TaskRequestHeader,
} from "./StyledComponents";
import { UserCard, AliceIcon, AliceIconType } from "@alice/component_library";
import { useTranslation } from "react-i18next";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import TechnicalUserIcon from "@Icons/taskpage/taskDetails/TechnicalUser.svg";
import ApplicationCard from "@src/sharedComponents/UserCards/applicationCard";

const AssignTechuser = ({ taskInfo, windowSize }) => {
  const { t } = useTranslation();
  const { originator = {}, state, affectedData = {}, id } = taskInfo;
  const { user = {}, appId } = affectedData;
  if (user) {
    user.givenname = user?.id;
    user.surname = "Techuser";
  }
  const { isMobile } = windowSize;

  return (
    <TaskRequestDetailsContainer disabled={state !== "PENDING"}>
      <TaskRequestHeader>
        {t("task-type-details-header.assign-technical-user-details")}
      </TaskRequestHeader>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <RequesterIcon />
            <SubHeaderTypography>{t("requester")}</SubHeaderTypography>
          </SubHeader>

          <UserCard
            userData={originator}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
          />
        </SubTopicContainer>
      </Grid>

      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <TechnicalUserIcon />
            <SubHeaderTypography>{t("technical_user")}</SubHeaderTypography>
          </SubHeader>

          <UserCard
            key={id}
            userData={user}
            userContext
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
          />
        </SubTopicContainer>
      </Grid>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("application")}</SubHeaderTypography>
          </SubHeader>
          <div className="application-card">
            <ApplicationCard appId={appId} appCard={true} />
          </div>
        </SubTopicContainer>
      </Grid>
    </TaskRequestDetailsContainer>
  );
};

export default AssignTechuser;
