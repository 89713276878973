import React from "react";

const Detail = () => {
  return (
    <span>
      <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
        <g
          id="Icons"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g transform="translate(-3047.000000, -384.000000)" id="Failed">
            <g transform="translate(3042.000000, 379.000000)">
              <g id="delete-1" transform="translate(5.000000, 5.000000)">
                <circle
                  id="Oval"
                  fill="#BBBBBB"
                  cx="11.25"
                  cy="11.25"
                  r="11.25"
                ></circle>
                <path
                  d="M15,18 C12.7943704,18 11,16.2056296 11,14 C11,11.7943704 12.7943704,10 15,10 C17.2056296,10 19,11.7943704 19,14 C19,16.2056296 17.2056296,18 15,18 Z M15,11 C13.3457143,11 12,12.3457143 12,14 C12,15.6542857 13.3457143,17 15,17 C16.6542857,17 18,15.6542857 18,14 C18,12.3457143 16.6542857,11 15,11 Z"
                  id="Shape"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M15,16.2222222 C14.7546667,16.2222222 14.5555556,16.0231111 14.5555556,15.7777778 L14.5555556,14 C14.5555556,13.7546667 14.7546667,13.5555556 15,13.5555556 C15.2453333,13.5555556 15.4444444,13.7546667 15.4444444,14 L15.4444444,15.7777778 C15.4444444,16.0231111 15.2453333,16.2222222 15,16.2222222 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <circle
                  id="Oval"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  cx="15"
                  cy="12.4444444"
                  r="1"
                ></circle>
                <path
                  d="M7.4375,8 C7.196,8 7,7.776 7,7.5 C7,7.224 7.196,7 7.4375,7 L13.5625,7 C13.804,7 14,7.224 14,7.5 C14,7.776 13.804,8 13.5625,8 L7.4375,8 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M7.4,11 C7.1792,11 7,10.776 7,10.5 C7,10.224 7.1792,10 7.4,10 L10.6,10 C10.8208,10 11,10.224 11,10.5 C11,10.776 10.8208,11 10.6,11 L7.4,11 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M7.42857143,14 C7.192,14 7,13.776 7,13.5 C7,13.224 7.192,13 7.42857143,13 L9.57142857,13 C9.808,13 10,13.224 10,13.5 C10,13.776 9.808,14 9.57142857,14 L7.42857143,14 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M6.375,16 C5.61661111,16 5,15.4234286 5,14.7142857 L5,5.28571429 C5,4.57657143 5.61661111,4 6.375,4 L12.8705,4 C13.2377778,4 13.5824444,4.13371429 13.8421667,4.376 L15.5972778,6.01714286 C15.857,6.26 16,6.58285714 16,6.92628571 L16,8.28571429 C16,8.52228571 15.7946667,8.71428571 15.5416667,8.71428571 C15.2886667,8.71428571 15.0833333,8.52228571 15.0833333,8.28571429 L15.0833333,6.92628571 C15.0833333,6.81371429 15.0344444,6.70342857 14.9495,6.62342857 L13.1943889,4.98228571 C13.1076111,4.90171429 12.9927222,4.85714286 12.8705,4.85714286 L6.375,4.85714286 C6.122,4.85714286 5.91666667,5.04914286 5.91666667,5.28571429 L5.91666667,14.7142857 C5.91666667,14.9508571 6.122,15.1428571 6.375,15.1428571 L9.58333333,15.1428571 C9.83633333,15.1428571 10.0416667,15.3348571 10.0416667,15.5714286 C10.0416667,15.808 9.83633333,16 9.58333333,16 L6.375,16 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default Detail;
