import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AliceIcon, Button } from "@alice/component_library";

import {
  CardMainContanier,
  CardTextContainer,
  CardHeaderContainer,
  CardHeaderTypo,
  CardTitleTypo,
  CardDescriTypo,
  CardDescriSpan,
  CardIconContainer,
  CardButtonContainer,
  RequestSvgCardConatiner,
} from "./styles";

const NewReqCard = ({
  requestTitle = "",
  buttonLabel = "",
  reqDescriptionFor = "",
  requestIcon,
  path,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <CardMainContanier>
        <CardTextContainer>
          <Box>
            <CardHeaderContainer>
              <AliceIcon
                iconType="UINewReq"
                size={16}
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              />
              <CardHeaderTypo>{t("new_request")}</CardHeaderTypo>
            </CardHeaderContainer>
            <Box>
              <CardTitleTypo>{requestTitle}</CardTitleTypo>
            </Box>
          </Box>
          <CardDescriTypo>
            {t("select-config-roles")}
            <CardDescriSpan>{reqDescriptionFor}</CardDescriSpan>.
          </CardDescriTypo>
        </CardTextContainer>
        <RequestSvgCardConatiner>
          <CardIconContainer className="svg-container">
            {requestIcon}
          </CardIconContainer>
          <CardButtonContainer>
            <Link to={path}>
              <Button
                children={
                  <>
                    {buttonLabel}
                    <ChevronRightIcon />
                  </>
                }
                variant="primary"
              />
            </Link>
          </CardButtonContainer>
        </RequestSvgCardConatiner>
      </CardMainContanier>
    </>
  );
};

export default NewReqCard;
