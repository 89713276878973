import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  AliceIcon,
  AliceIconType,
  FocusChip,
  UserContextPopover,
  UserAvatar,
  useUser,
  usePermission,
  user as userUtils,
} from "@alice/component_library";
import { Popover } from "@mui/material";
import {
  SeperateDotContainer,
  SeperateDot,
  ProfileNameContainer,
  ProfileNameText,
  MarginRightSpan,
  ProfileSubHeaderContainer,
  SpanIconContainer,
  RoleSpan,
  RoleLabelSpan,
  SpanDownArrow,
  ProfileDetailsWrapper,
  DetailsContainer,
  ProfileHeaderMainContainer,
} from "../../styledComponents";

import { managementValue } from "@utils/managementValue";
import { Authorizations, adminLabelMap } from "@utils";

const ProfileHeader = ({ isUser = false, userDetails = {} }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user = {} } = useUser();
  const { adminRoles: adminRoleSet } = usePermission();
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];
  let userData = user ?? {};
  let userId = user?.id ?? "";

  if (userDetails?.id) {
    userData = userDetails;
    userId = userDetails.id;
  }

  const {
    givenname,
    surname,
    groupType,
    departmentNumber,
    managementLevel,
    plant2,
  } = userData ?? {
    givenname: "",
    surname: "",
    groupType: "",
    departmentNumber: "",
    managementLevel: "",
    plant2: "",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileImageStyle = {
    height: 115,
    width: 115,
    fontSize: "3rem",
    dotSize: "20px",
  };

  if (userData === null) return null;

  const getNameAndChip = () => {
    const level = managementLevel ? managementValue(managementLevel) : "";

    return (
      <ProfileNameContainer>
        <ProfileNameText>{`${surname}, ${givenname}`}</ProfileNameText>
        <ProfileNameText>
          {" "}
          {level !== "" ? <MarginRightSpan>{level}</MarginRightSpan> : null}
          <FocusChip groupType={groupType} showTooltip />
        </ProfileNameText>
      </ProfileNameContainer>
    );
  };

  const getDepartment = () => {
    const activeObj = {
      label: "active",
      icon: AliceIconType.CHECK_CIRCLE,
      color: "#21A330",
      size: 14,
    };
    const inActiveObj = {
      label: "inactive",
      icon: AliceIconType.UIGroup,
      color: "#CD5C5C",
      size: 20,
    };
    const obj = userDetails?.isActive ? activeObj : inActiveObj;
    return (
      <ProfileSubHeaderContainer>
        {isUser ? (
          <SpanIconContainer color={obj.color}>
            {t(obj.label)}{" "}
            <AliceIcon
              size={obj.size}
              iconType={obj.icon}
              viewBox="0 0 28 28"
              color={obj.color}
            />
          </SpanIconContainer>
        ) : null}
        <span className="user-Info-tags">
          {userId}
          {departmentNumber && (
            <>
              <SeperateDotContainer /> <SeperateDot />
            </>
          )}
          {departmentNumber}
          {plant2 && (
            <>
              <SeperateDotContainer /> <SeperateDot />
              {`${t("plant")} ${plant2}`}
            </>
          )}
        </span>
      </ProfileSubHeaderContainer>
    );
  };

  const getAdminLabel = () => {
    if (isUser) return null;
    const roles = adminRoles?.filter((d) => Authorizations["cc"].includes(d));
    return (
      <RoleSpan>
        <RoleLabelSpan onClick={(e) => handleClick(e)}>
          {`${t("cc-admin-roles")} (${roles?.length})`}
          <SpanDownArrow />
        </RoleLabelSpan>
        <Popover
          id="simple-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <div style={{ padding: "10px 22px", maxHeight: "200px" }}>
            {roles?.map((d) => (
              <div style={{ padding: "5px 0", cursor: "default" }}>
                {t(`adminRolesTitle.${adminLabelMap[d]}`)}
              </div>
            ))}
          </div>
        </Popover>
      </RoleSpan>
    );
  };

  const getUserDetails = () => (
    <ProfileDetailsWrapper>
      <DetailsContainer>
        {getNameAndChip()}
        {getDepartment()}
        {getAdminLabel()}
      </DetailsContainer>
    </ProfileDetailsWrapper>
  );

  const getUserImage = () => (
    <div>
      <UserContextPopover id={userId}>
        <UserAvatar
          userId={userId}
          userName={`${givenname} ${surname}`}
          groupType={groupType}
          showGroupType
          sizeStyle={profileImageStyle}
          showImage={userUtils.is.internalEmployee(userData?.communities)}
        />
      </UserContextPopover>
    </div>
  );

  return (
    <ProfileHeaderMainContainer>
      {getUserImage()}
      {getUserDetails()}
    </ProfileHeaderMainContainer>
  );
};

export default memo(ProfileHeader);
