import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import RoleInlay from "@components/RoleDetails/RoleInlay";
import RoleCardV3 from "@components/RoleCardV3/RoleCardV3";
import { RoleCardV3GridSkeleton } from "@components/Skeleton/CommonSkeletons";
import NoRolesIcon from "@Icons/newRequestPage/NoRoles.svg";
import { NoRolesContainer } from "../../styledComponents";

const RoleGridSection = ({
  roles,
  selectedRoles,
  handleSelect,
  mesureRef,
  loading,
  hasMore,
}) => {
  const { t } = useTranslation();
  const [expandedRole, setExpandedRole] = useState(null);
  const [anchorEl, setAnchorEle] = useState(null);

  const isExpanded = (roleId) => {
    return expandedRole && expandedRole === roleId;
  };
  const handleExpandClick = ({ id, e }) => {
    if (id === expandedRole) {
      setExpandedRole(null);
      setAnchorEle(null);
    } else {
      setExpandedRole(id);
      setAnchorEle(e.currentTarget);
    }
  };

  return (
    <div>
      {roles.length > 0 && (
        <>
          <div className="role-grid">
            {roles?.map((role, index) => (
              <>
                <RoleCardV3
                  ref={
                    !loading && hasMore && index === roles.length - 1
                      ? mesureRef
                      : undefined
                  }
                  data={{ roleDefinition: role }}
                  id={role?.id}
                  hideCardContent={true}
                  hideActions={true}
                  isSelected={
                    selectedRoles.filter((curr) => curr?.id === role.id)[0]
                      ?.isSelected
                  }
                  onSelect={handleSelect}
                  isOpen={isExpanded(role?.id)}
                  onExpand={handleExpandClick}
                />
                {isExpanded(role?.id) && (
                  <Box className="role-inlay">
                    <RoleInlay
                      open={isExpanded(role?.id)}
                      selectedData={role}
                      anchorElement={anchorEl}
                      id={role?.id}
                      onClose={(e) =>
                        handleExpandClick({
                          e,
                          isExpanded: !isExpanded(role?.id),
                        })
                      }
                    />
                  </Box>
                )}
              </>
            ))}
          </div>
        </>
      )}
      {loading && (
        <RoleCardV3GridSkeleton
          gridLength={10}
          showMore={true}
          hideActions={true}
          showBody={false}
          sx={{ marginTop: "24px" }}
        />
      )}
      {!loading && !!!roles.length && (
        <NoRolesContainer>
          <NoRolesIcon />
          <Typography variant="body1">{t("no-roles_avilable")}</Typography>
        </NoRolesContainer>
      )}
    </div>
  );
};

export default RoleGridSection;
