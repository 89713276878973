import { controlCenterActionTypes } from "../constants/actionTypes";

const HasCCAccess = (payload) => ({
  type: controlCenterActionTypes.HAS_CC_ACCESS,
  data: payload,
});

const MyUserDetails = (payload) => ({
  type: controlCenterActionTypes.MY_USER_DETAILS,
  data: payload,
});

export { HasCCAccess, MyUserDetails };
