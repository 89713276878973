import React from "react";
import styled from "styled-components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Chip } from "@mui/material";

const WarningIcon = () => {
  return (
    <InfoOutlinedIcon sx={{ fontSize: "48px", color: "rgb(200, 200, 200)" }} />
  );
};

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: 28px;
  }

  & .MuiChip-label {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const MyChip = (props) => <StyledChip {...props} clickable={false} />;

export { WarningIcon, MyChip };
