import { useTranslation } from "react-i18next";
import { usePermission } from "@alice/component_library";
import NewSelfReqIcon from "@Icons/newRequestPage/NewSelfReqIcon.svg";
import NewSomeElseReqIcon from "@Icons/newRequestPage/NewSomeElseReqIcon.svg";
import { isSuperAdmin } from "@src/utils";
import { hasData } from "@utils/Validator";
import NewReqCard from "@components/NewRequestCard";
import NewReqHeader from "@pages/newRequest/components/MainPage/header";
import {
  NewReqMainPage,
  NewReqCards,
} from "@pages/newRequest/styledComponents";

const NewRequestPage = ({ handleClick }) => {
  const { t } = useTranslation();

  const { adminRoles: adminRoleSet } = usePermission();
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const showRequestSomeoneElse =
    hasData(adminRoles) && isSuperAdmin(adminRoles, "request");
  const showBulkImport =
    hasData(adminRoles) && isSuperAdmin(adminRoles ?? [], "bulkImport");

  const showRequestForEmployeeToEmployee =
    hasData(adminRoles) && adminRoleSet.has("EMPLOYEE_FOR_EMPLOYEE_MBAG_ADMIN");

  const getTheHeaderTitle = () => {
    if (showRequestSomeoneElse || showRequestForEmployeeToEmployee) {
      if (showBulkImport) {
        return t("new-req-description");
      } else {
        return t("new-req-description-without-bulk");
      }
    } else {
      return t("new-req-yourself");
    }
  };

  return (
    <>
      <NewReqMainPage>
        <NewReqHeader
          showBulkImport={showBulkImport}
          titleHeader={getTheHeaderTitle()}
        />
        <NewReqCards>
          <NewReqCard
            requestTitle={t("request-roles-for-myself")}
            buttonLabel={t("request-for-myself")}
            requestFor={t("icons.myself")}
            reqDescriptionFor={t("yourself")}
            requestIcon={<NewSelfReqIcon />}
            path={"/access/accessRequest/myself"}
          />
          {(showRequestSomeoneElse || showRequestForEmployeeToEmployee) && (
            <NewReqCard
              requestTitle={t("request-roles-for-someone-else")}
              buttonLabel={t("request-for-some-else")}
              reqDescriptionFor={t("someone-else-description")}
              requestIcon={<NewSomeElseReqIcon />}
              handleClick={handleClick}
              path={"/access/accessRequest/delegated"}
            />
          )}
        </NewReqCards>
      </NewReqMainPage>
    </>
  );
};

export default NewRequestPage;
