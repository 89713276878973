import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { AdminRoleAccordion } from '../AdminRoleAccordion'

export const CentralHelpdeskAdminAccordion = ({
  userId,
  isAssigned,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'centralhelpdeskadmin'
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.centralHelpdeskAdmin') }),
      onRequestSuccess: (response) => {
        onChange({ centralHelpdeskAdmin: response })
      }
    }
  )

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'centralhelpdeskadmin'
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.centralHelpdeskAdmin') }),
      onRequestSuccess: (response) => {
        onChange({ centralHelpdeskAdmin: response })
      }
    }
  )

  const handleAssign = () => executeAssign()
  const handleDelete = () => executeDelete()

  return (
    <AdminRoleAccordion
      data-testid="CentralHelpdeskAdminAccordion"
      title={t('adminRoles.centralHelpdeskAdmin')}
      isRemovable
      isActive={isAssigned}
      isLoading={isLoadingAssign || isLoadingDelete}
      onAssignClick={handleAssign}
      onRemoveClick={handleDelete}
    />
  )
}

CentralHelpdeskAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  onChange: PropTypes.func
}
