import { useCallback, useContext } from 'react'
import { NotificationContext } from './NotificationProvider'

export const useNotification = ({
  initialDuration = 4000,
  initialVariant = 'filled'
} = {}) => {
  const notificationContext = useContext(NotificationContext)

  const notify = useCallback(({
    severity,
    duration = initialDuration,
    message = '',
    variant = initialVariant
  }) => notificationContext.notify({
    severity,
    duration,
    message,
    variant
  }), [ notificationContext, initialDuration ])

  return { notify }
}
