import React from "react";
import { Link } from "react-router-dom";
import { Divider, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ExpandMore } from "@mui/icons-material";

import { Button, Tooltip } from "@alice/component_library";
import { RoleCardActionsContainer } from "./StyledComponents";
import { useTranslation } from "react-i18next";

const RoleCardV3Footer = React.memo(
  ({
    isOpen,
    hideActions,
    showMore,
    showModifyButton,
    handleExtendOrReapply,
    ExtendOrReapplyButton,
    finalValidFrom,
    finalValidTo,
    finalValidityDaysLeft,
    handleReapplyClick,
    handleModifyClick,
    handleDeleteClick,
    handleExpandClick,
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {(!hideActions || showMore || showModifyButton) && (
          <>
            <Divider />
            <RoleCardActionsContainer>
              {(!hideActions || showModifyButton) && (
                <>
                  <div className="action-button-container">
                    {ExtendOrReapplyButton ? (
                      handleExtendOrReapply ? (
                        <Tooltip
                          type="ellipsized"
                          title={
                            finalValidityDaysLeft < 0
                              ? t("reapply-button")
                              : t("extend-button")
                          }
                        >
                          <Link
                            to={`${handleExtendOrReapply}&mode=${
                              finalValidityDaysLeft < 0 ? "reapply" : "extend"
                            }`}
                          >
                            <Button
                              size="medium"
                              variant="primary"
                              className="action-buttons"
                            >
                              {finalValidityDaysLeft < 0 ? (
                                <ReplayIcon />
                              ) : (
                                <EditOutlinedIcon />
                              )}
                            </Button>
                          </Link>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          type="ellipsized"
                          title={
                            finalValidityDaysLeft < 0
                              ? t("reapply-button")
                              : t("extend-button")
                          }
                        >
                          <Button
                            size="medium"
                            variant="primary"
                            className="action-buttons"
                            onClick={
                              finalValidityDaysLeft < 0
                                ? handleReapplyClick
                                : handleModifyClick
                            }
                          >
                            {finalValidityDaysLeft < 0 ? (
                              <ReplayIcon />
                            ) : (
                              <EditOutlinedIcon />
                            )}
                          </Button>
                        </Tooltip>
                      )
                    ) : null}
                    {!showModifyButton && (
                      <Tooltip type="ellipsized" title={t("icons.delete")}>
                        <Button
                          size="medium"
                          variant="primary"
                          className="action-buttons"
                          onClick={handleDeleteClick}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </>
              )}
              {showMore && (
                <IconButton
                  onClick={(e) => handleExpandClick({ e, isExpanded: !isOpen })}
                  className="expand-more"
                >
                  <ExpandMore className={isOpen ? "open" : ""} />
                </IconButton>
              )}
            </RoleCardActionsContainer>
          </>
        )}
      </>
    );
  }
);

export default RoleCardV3Footer;
