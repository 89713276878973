import React, { useState, useCallback, useEffect } from "react";
import { getSessionStorage } from "@utils";
import RoleViewComponent from "./Layout/RoleViewTabContent";
import MainPageHeader from "./Header";
import TabComponent from "./TabComponent";
import { CacheStorage, useCache } from "@alice/component_library";
import { debounce } from "lodash";

const AccessReviewMainPage = ({
  isAdminVisible,
  translate,
  cacheKey,
  windowSize,
}) => {
  const { getCachedItem, setCachedItem, removeCachedItem } = useCache(
    CacheStorage.sessionStorage
  );
  const isMobile = windowSize.isMobile;
  const isTablet = windowSize.isTablet;
  const isDesktop = windowSize.isDesktop;
  const cacheValue = getCachedItem(cacheKey);
  const reviewView = getSessionStorage("review-view");
  const [layoutstyle, setLayoutstyle] = useState(reviewView ?? "table");
  const [search, setSearch] = useState(
    cacheValue?.searching !== undefined ? cacheValue?.searching : ""
  );
  const [searchValue, setSearchValue] = useState(
    cacheValue?.searching !== undefined ? cacheValue?.searching : ""
  );
  const [selectedTab, setSelectedTab] = useState(0);

  const reviewSearch = (s) => {
    setCachedItem(cacheKey, { ...cacheValue, searching: s });
  };

  const onChange = (searchTerm) => {
    if (searchTerm.trim().length !== 0) {
      handleSearch(searchTerm);
    } else {
      onClear();
    }
  };

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      reviewSearch(searchTerm);
      setSearchValue(searchTerm);
    }, 300), // Debounce for 300 milliseconds
    [setSearchValue, reviewSearch]
  );

  const onClear = () => {
    reviewSearch("");
    setSearch("");
    setSearchValue("");
  };

  const layoutHandler = (view) => {
    setLayoutstyle(view);
  };

  useEffect(() => {
    const defaultView = isMobile || isTablet ? "grid" : reviewView ?? "table";
    layoutHandler(defaultView);
  }, [isMobile, isTablet, isDesktop]);

  const handleTabChange = useCallback(
    (newValue) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  const tabs = [{ label: translate("role-reviews") }];

  window.addEventListener("beforeunload", () => {
    removeCachedItem(cacheKey);
  });

  return (
    <>
      <MainPageHeader
        search={search}
        onChange={onChange}
        translate={translate}
        onClear={onClear}
      />

      <TabComponent
        selectedTab={selectedTab}
        onChange={handleTabChange}
        tabs={tabs}
        layoutHandler={(view) => layoutHandler(view)}
        layoutSelector={!isMobile && !isTablet}
      />
      <RoleViewComponent
        layout={layoutstyle}
        isAdminVisible={isAdminVisible}
        searchValue={searchValue}
        cacheKey={cacheKey}
        isMobile={isMobile}
        isTablet={isTablet}
        isDesktop={isDesktop}
      />
    </>
  );
};

export default AccessReviewMainPage;
