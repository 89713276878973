import React, { Fragment, memo } from "react";
import { Box } from "@mui/material";
import { createArrayWithInitialValues } from "@alice/component_library";
import RoleCardV3 from "@components/RoleCardV3/RoleCardV3";
import { RoleCardV3GridSkeleton } from "@components/Skeleton/CommonSkeletons";
import { hasData } from "@utils/Validator";

import Inlay from "@components/Inlay";
import DynamicRoleCard from "./DynamicRoleCard";

export const RoleGridViewRender = memo(
  ({
    view,
    data: roles,
    selectedData: selectedRoles = [],
    onItemClick,
    rowsPerPage,
    isLoading,
    isExpandable,
    isMobile,
    expandedRole,
    onExpandClick,
    isDynamic,
    onSelectedData,
    handleExtendOrReapply,
    handleSingleDelete,
    globalRoleIds,
  }) => {
    const gridSx = [
      {
        display: "grid",
        gap: 2,
        gridAutoFlow: "dense",
      },
      view === "grid" && { gridTemplateColumns: "repeat(2, 1fr)" },
      (view === "list" || isMobile) && {
        gridTemplateColumns: "repeat(1, 1fr)",
      },
    ];

    const expandableSx = {
      gridColumn: "1 / -1",
      "& .MuiBox-root": {
        width: "100%",
        "& > span": {
          display: "none !important",
        },
      },
    };
    const mappableRoles = isLoading
      ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({
          id: index.toString(),
        }))
      : roles;

    const isExpanded = (roleId) => {
      if (!isExpandable) return false;

      return expandedRole && expandedRole === roleId && !isLoading;
    };
    onSelectedData(selectedRoles);

    const shouldShowButton = (role) => {
      const isInsightsPath =
        window.location.pathname.includes("/access/insights/");
      const hasRole =
        globalRoleIds?.includes(role?.roleDefinition?.id) ||
        role?.roleDefinition?.isSelfRequestable;

      return !isInsightsPath ? hasRole : true;
    };
    return (
      <Box sx={gridSx}>
        {mappableRoles.map((role) => (
          <Fragment key={role?.id}>
            {isDynamic ? (
              <DynamicRoleCard
                roleInfo={role}
                isFullWidth={view === "grid" || isMobile ? false : true}
                isOpen={isExpanded(role?.id)}
                onExpand={onExpandClick}
                id={role?.id}
              />
            ) : (
              <RoleCardV3
                data={role}
                id={role?.id}
                size={view === "grid" || isMobile ? "small" : "large"}
                isSelected={selectedRoles.includes(role?.id)}
                isOpen={isExpanded(role?.id)}
                onSelect={onItemClick}
                onExpand={onExpandClick}
                onDelete={() => handleSingleDelete(true, [role])}
                handleExtendOrReapply={`${handleExtendOrReapply}&role=${
                  role?.roleDefinition?.id
                }${
                  hasData(role.orgScope) ? `&orgScope=${role.orgScope.id}` : ""
                }${
                  hasData(role.customScope)
                    ? `&custScope=${role.customScope.name}`
                    : ""
                }${
                  role.hasOwnProperty("validFrom")
                    ? `&validFrom=${encodeURI(role.validFrom)}`
                    : ""
                }`}
                ExtendOrReapplyButton={shouldShowButton(role)}
              />
            )}
            {isExpanded(role?.id) && (
              <Box sx={expandableSx}>
                <Inlay
                  open={isExpanded(role?.id)}
                  selectedData={role}
                  id={role?.roleDefinition?.id}
                  onClose={(e) =>
                    onExpandClick({ e, isExpanded: !isExpanded(role?.id) })
                  }
                />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    );
  }
);

export default RoleGridViewRender;
