import { DECISION_CONSTANT } from "../utils";
const { MODIFIED, CONFIRMED, DELETED } = DECISION_CONSTANT;
const getStatusColor = (action) => {
  switch (action) {
    case DELETED:
      return "#FBEDED";
    case CONFIRMED:
      return "#ECF4ED";
    case MODIFIED:
      return "#F4F4F4";
    default:
      return "#ECF4ED";
  }
};

const getReviewBgColor = (action) => {
  const backgroundColor = getStatusColor(action);

  return backgroundColor;
};

export default getReviewBgColor;
