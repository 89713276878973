import { Stack, Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { mergeSxProps } from '../../util'

/**
 * Easy-to-use Tab Navigation Component based on MUI
 *
 * ###CSS Classes
 * - tabNav
 * - tabNavTab
 */
export const TabNavigation = ({
  initialIndex = 0,
  tabLabels,
  onChange = (tabIndex) => {},
  sx = {} || [],
  children,
  ...otherProps
}) => {
  tabLabels = tabLabels.filter((label) => !!label)

  const [ tabIndex, setTabIndex ] = useState(initialIndex)

  const handleChange = (e, newValue) => {
    setTabIndex(newValue)
    onChange(newValue)
  }

  return (
    <Stack gap={2}>
      {tabLabels.length > 1 && (
        <Tabs
          className="tabNav"
          data-testid="TabNavigation"
          value={tabIndex}
          onChange={handleChange}
          sx={mergeSxProps({
            borderBottom: 1,
            borderColor: 'divider'
          }, sx)}
          {...otherProps}
        >
          {tabLabels.map((label, index) => (
            <Tab
              data-testid={`TabNavigation-${index}`}
              className="tabNavTab"
              key={`tab_${label}-${index}`}
              label={label}
            />
          ))}
        </Tabs>
      )}

      {children ? (typeof children === 'function' ? children(tabIndex) : children) : null}
    </Stack>
  )
}

TabNavigation.propTypes = {
  /** list of labels for tab creation */
  tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * @param tabIndex {string}
   * @returns {void}
   */
  onChange: PropTypes.func
}
