export const updateGreeting = () => {
  let greeting = "good-morning";
  const now = new Date();
  const hours = now.getHours();

  if (hours < 12) {
    greeting = "good-morning";
  } else if (hours < 17) {
    greeting = "good-afternoon";
  } else {
    greeting = "good-evening";
  }
  return greeting;
};
