import DeeplinkTasks from "@components/TaskDeeplink";
import RequestHeaderIcon from "../Details/RequestHeaderIcon";
import RequestDetailsData from "../Details/RequestDetailsData";

const RequestDeeplink = () => {
  return (
    <DeeplinkTasks
      TaskHeaderIcon={RequestHeaderIcon}
      TaskDetails={RequestDetailsData}
      isFromTask={false}
    />
  );
};

export default RequestDeeplink;
