import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
export const STATUS_OPTIONS = {
  success: {
    icon: TaskAltIcon,
    headingKey: "success-status-header",
    subheadingKey: "success-status-subheader",
    color: "#198025",
    bgColor: "#ECF4ED",
  },
  failed: {
    icon: WarningAmberRoundedIcon,
    headingKey: "failed-status-header",
    color: "#E8BD00",
    subheadingKey: "failed-status-subheader",
    bgColor: "#FEF5CC",
  },
  blocked: {
    icon: CancelOutlinedIcon,
    headingKey: "blocked-status-header",
    subheadingKey: "blocked-status-subheader",
    color: "#D92121",
    bgColor: "#FFDBDB",
  },
};
