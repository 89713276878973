/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "@src/App.css";
import "@src/Icon.css";
import { styled } from "@mui/material/styles";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { getUUID } from "@utils";
import PreviousOrgDialog from "./previousOrgDialogue";
import { Button } from "@alice/component_library";

import { Typography, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { CloseButton } from "@alice/component_library";
import "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateRoleData,
  selectedRoles,
  updatePreviousOrgSCopeRoleData,
} from "@actions/roleActions";

import { areArraysSameStructure } from "@src/utils/arrayUtils";
import moment from "moment";
import OrgComponent from "./OrgComponent";
import CustomScope from "./custScope";
import ScopeCard from "./scopeCard";

import "../../Circle.css";
import { hasData } from "@utils/Validator";
import { useQuery } from "@core/hooks";
import TimeCircle from "@Icons/time-circle.svg";
import CustomDatePicker from "@components/DatepickerCC";
import { useNotification } from "@alice/component_library";

const SelectOrganizationContainer = styled("div")({
  marginTop: "31px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "& .tbody": {
    fontWeight: 700,
    fontSize: "16px",
  },
  "& .adjustcontent": {
    opacity: "1",
    color: "#000000",
    fontFamily: "Daimler CS",
    fontSize: "16px",
    fontWeight: "700",
    fontStyle: "normal",
    letterSpacing: "0px",
    textAlign: "left",
    lineHeight: "21px",
  },
  "& .preScopeButton": {
    "& img": {
      marginRight: "5px",
    },
  },
});

const NeedCustomScopeTypography = styled(Typography)({
  opacity: "1",
  color: "#000000",
  fontFamily: "Daimler CS",
  fontSize: "16px",
  fontWeight: "700",
  fontStyle: "normal",
  letterSpacing: "0px",
  textAlign: "left",
  lineHeight: "21px",
  paddingTop: "31px",
  "& .tbody": {
    fontWeight: 700,
    fontSize: "16px",
  },
});

const MainContainer = styled(Grid)({
  "& .detail": {
    fontSize: "20px",
    color: "#000000",
    paddingBottom: "10px",
    fontFamily: "MB Corpo S Text",
    fontWeight: "700",
  },
  "& .detailText": {
    fontSize: "16px",
    color: "#000000",
    whiteSpace: "pre-line",
    lineBreak: "anywhere",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },
  "& .CustomeDatePickerContaitner": {
    display: "flex",
    width: "320px",
    marginTop: "14px",
    "& .ToContainer": {
      paddingLeft: "0",
    },
  },
});

const AddScopeButton = styled(PrimaryButton)({
  marginTop: "5px",
});

export default function RoleEditOptions(params) {
  const { t } = useTranslation();
  const [isDrodownOpen, setIsDropDown] = useState(false);
  const { setEditOptionsChange, isAddRoleOpen, addRoledata } = params;
  const { isMode = false, selectCard, width, preSelect } = params;
  const query = useQuery();
  const mode = query.get("mode") ?? "";
  const validFromUrl = query.get("validFrom") ?? "";
  const orgScopes = query.get("orgScope") ?? "";
  const custScope = query.get("custScope") ?? "";
  const istimeFrame =
    addRoledata.istimeFrame === undefined ? true : addRoledata.istimeFrame;
  const [addtimeFrame, setTimeFrame] = useState(istimeFrame);
  const [addScopeDisabled, setAddScopeDisabled] = useState(true);
  const defaultValidType = addRoledata.defaultValidityType;
  const [resetFlag, setResetFlag] = useState(false);
  const [isErrorInCalender, setIsErrorInCalender] = useState(false);
  const defaultValidDays =
    addRoledata.defaultValidityDays === undefined ||
    addRoledata.defaultValidityDays === 0
      ? 180
      : addRoledata.defaultValidityDays;
  const [validityDays, setDefaultValidityDates] = useState(defaultValidDays);
  const validFromUrlDecoded =
    validFromUrl.length &&
    moment(decodeURI(validFromUrl), "YYYY-MM-DD", true).isValid()
      ? new Date(validFromUrl)
      : "";

  const { notify } = useNotification();
  const maincontainerRef = useRef(null);

  let fromDate = new Date();
  if (
    validFromUrlDecoded.toString().trim().length &&
    defaultValidType !== "FIXED" &&
    mode !== "reapply"
  ) {
    if (
      orgScopes.length &&
      orgScopes === addRoledata?.selectedScopes[0]?.orgId &&
      addRoledata?.selectedScopes?.length === 1
    ) {
      fromDate = new Date(validFromUrlDecoded);
    } else if (
      orgScopes.length &&
      (orgScopes !== addRoledata?.selectedScopes[0]?.orgId ||
        addRoledata?.selectedScopes?.length >= 1)
    ) {
      fromDate = new Date();
    } else {
      fromDate = new Date(validFromUrlDecoded);
    }
  }

  let toDate = null;

  if (
    defaultValidType === "FIXED" ||
    defaultValidType === "MANDATORY" ||
    defaultValidType === "OPTIONAL"
  ) {
    toDate = new Date(
      new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
    );
  }

  const [selectedDateValidFrom, setSelectedDateValidFrom] = useState(
    new Date(fromDate)
  );
  const selectedRolesData = useSelector((state) => state.role.selectedRoles);
  const previousOrgData = useSelector((state) => state.role.previousOrgScopes);
  const [selectedDateValidUntil, setSelectedDateValidUntil] = useState(toDate);
  const [preSelectedDateValidUntil, setPreSelectedDateValidUntil] =
    useState(toDate);

  const [preSelectedInputValue, setPreSelectedInputValue] = useState(
    new Date(fromDate)
  );
  const [groupType, setGroupType] = useState("");
  const [activeKey, setActiveKey] = useState(getUUID());
  const roleData = useSelector((state) => state.role.assignableRoles);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [dialog, setDialog] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [cust, setCust] = useState("");
  const [org, setOrg] = useState("");
  const [orgArray, setOrgArray] = useState([]);

  const [selectedFullScopes, SetSelectedScopes] = useState(
    addRoledata.selectedScopes ?? []
  );
  const [deSelectData, SetDeSelectData] = useState({});
  const [orgSelectedData, setOrgSelectedScope] = useState({});

  const handleDialogClose = () => {
    setDialog(false);
    setOrgArray([]);
    setOrgSelectedScope({});
  };

  const isPreviousOrgScopeDispaly = () => {
    const previousOrgScopes = selectedRolesData.filter(
      (item) => item.needsOrgScopes && !item.needsCustomScopes
    );
    return (
      addRoledata.needsOrgScopes &&
      !addRoledata.needsCustomScopes &&
      previousOrgScopes.length &&
      !(
        previousOrgScopes.length === 1 &&
        previousOrgScopes[0].id === addRoledata.id
      )
    );
  };

  useEffect(() => {
    if (maincontainerRef.current) {
      maincontainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [maincontainerRef]);

  useEffect(() => {
    if (isMode) {
      if (params?.validity?.validFrom) {
        setSelectedDateValidFrom(params?.validity?.validFrom);
        setPreSelectedInputValue(new Date(params?.validity?.validFrom));
      }

      if (params?.validity?.validTo)
        setSelectedDateValidUntil(params?.validity?.validTo);
      setPreSelectedDateValidUntil(params?.validity?.validTo);
    }
  }, [params.validity]);

  useEffect(() => {
    const { validFrom } =
      selectedRolesData.find(({ id }) => id === params.id) || {};
    const { validTo } =
      selectedRolesData.find(({ id }) => id === params.id) || {};
    if (typeof validFrom !== "undefined") {
      setSelectedDateValidFrom(new Date(validFrom));
      setPreSelectedInputValue(new Date(validFrom));
    }
    if (typeof validTo !== "undefined") {
      setSelectedDateValidUntil(new Date(validTo));
      setPreSelectedDateValidUntil(new Date(validTo));
    }
  }, [selectedRolesData]);

  useEffect(() => {
    const deslectStruct = orgArray.reduce((orgInfo, { orgId }) => {
      orgInfo[orgId] = false;
      return orgInfo;
    }, {});
    setResetFlag(true);
    SetDeSelectData(deslectStruct);
    setAddScopeDisabled(true);
    setOrgArray([]);
    SetSelectedScopes(addRoledata.selectedScopes ?? []);
  }, [params.id, addRoledata?.selectedScopes]);

  useEffect(() => {
    const istimeFrame =
      addRoledata.istimeFrame === undefined ? true : addRoledata.istimeFrame;

    const seletectedInfo = selectedRolesData.find(
      (item) => item.id === params?.id
    );
    let toDate = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;

    if (
      defaultValidType === "FIXED" ||
      defaultValidType === "MANDATORY" ||
      defaultValidType === "OPTIONAL"
    ) {
      toDate = new Date(
        new Date().getTime() + defaultValidDays * 24 * 60 * 60 * 1000
      );
    }
    let fromDate = new Date();
    if (
      validFromUrlDecoded.toString().trim().length &&
      defaultValidType !== "FIXED" &&
      mode !== "reapply"
    ) {
      if (
        orgScopes.length &&
        orgScopes === addRoledata?.selectedScopes[0]?.orgId &&
        addRoledata?.selectedScopes?.length === 1
      ) {
        fromDate = new Date(validFromUrlDecoded);
      } else if (
        orgScopes.length &&
        (orgScopes !== addRoledata?.selectedScopes[0]?.orgId ||
          addRoledata?.selectedScopes?.length >= 1)
      ) {
        fromDate = new Date();
      } else {
        fromDate = new Date(validFromUrlDecoded);
      }
    }

    if (seletectedInfo) {
      setSelectedDateValidFrom(
        seletectedInfo?.validFrom
          ? new Date(seletectedInfo?.validFrom)
          : new Date(fromDate)
      );
      setPreSelectedInputValue(
        seletectedInfo?.validFrom
          ? new Date(seletectedInfo?.validFrom)
          : new Date(fromDate)
      );
      setSelectedDateValidUntil(
        seletectedInfo?.validTo
          ? new Date(seletectedInfo?.validTo)
          : new Date(toDate)
      );
      setPreSelectedDateValidUntil(
        seletectedInfo?.validTo
          ? new Date(seletectedInfo?.validTo)
          : new Date(toDate)
      );
      setTimeFrame(
        seletectedInfo?.validFrom !== null &&
          seletectedInfo?.validFrom.length &&
          seletectedInfo?.validTo !== null &&
          seletectedInfo?.validTo.length
          ? true
          : istimeFrame
      );
    } else {
      setTimeFrame(istimeFrame);
      setSelectedDateValidFrom(new Date(fromDate));
      setPreSelectedInputValue(new Date(fromDate));
      setSelectedDateValidUntil(new Date(toDate));
      setPreSelectedDateValidUntil(new Date(toDate));
    }
  }, [params.id, defaultValidDays]);

  useEffect(() => {
    if (defaultValidType === "FIXED" && mode === "reapply") {
      setSelectedDateValidUntil(
        new Date(
          new Date(selectedDateValidFrom).getTime() +
            validityDays * 24 * 60 * 60 * 1000
        )
      );
      setPreSelectedDateValidUntil(
        new Date(
          new Date(selectedDateValidFrom).getTime() +
            validityDays * 24 * 60 * 60 * 1000
        )
      );
    }
  }, [selectedDateValidFrom, defaultValidDays, mode]);

  useEffect(() => {
    if (addtimeFrame) {
      const seletedInfo = selectedRolesData.find(
        (item) => item.id === params?.id
      );
      if (
        selectedDateValidFrom == "Invalid date" ||
        (selectedDateValidFrom != "Invalid date" &&
          new Date(selectedDateValidFrom) < new Date())
      ) {
        if (seletedInfo) {
          setSelectedDateValidFrom(
            seletedInfo?.validFrom
              ? new Date(seletedInfo?.validFrom)
              : new Date()
          );
          setPreSelectedInputValue(
            seletedInfo?.validFrom
              ? new Date(seletedInfo?.validFrom)
              : new Date()
          );
        } else {
          setSelectedDateValidFrom(new Date());
          setPreSelectedInputValue(new Date());
        }
      }
      if (
        selectedDateValidUntil == "Invalid date" ||
        (selectedDateValidFrom != "Invalid date" &&
          new Date(selectedDateValidUntil) < new Date())
      ) {
        if (seletedInfo) {
          setSelectedDateValidUntil(
            seletedInfo?.validTo
              ? new Date(seletedInfo?.validTo)
              : new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
          );
          setPreSelectedDateValidUntil(
            seletedInfo?.validTo
              ? new Date(seletedInfo?.validTo)
              : new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
          );
        } else {
          setSelectedDateValidUntil(
            new Date(new Date().getTime() + validityDays * 24 * 60 * 60 * 1000)
          );
          setPreSelectedDateValidUntil(
            new Date(new Date().getTime() + validityDays * 24 * 60 * 60 * 1000)
          );
        }
      }
    }
  }, [addtimeFrame]);

  const handleChange = (e) => {
    setTimeFrame(e.target.checked);
  };

  const updateCustScope = async (cust) => {
    if (cust) {
      await setCust(cust);
      const disable = await isAddScopeDisabled({ custSl: cust, orgSl: orgId });
      await setAddScopeDisabled(disable);
    }
  };

  const updateOrgScope = (org) => {
    setOrg(org);
  };

  const updategroupType = (group) => {
    setGroupType(group);
  };

  const updateOrgId = async (id) => {
    setOrgId(id);
    const disable = await isAddScopeDisabled({ orgSl: id, custSl: cust });
    await setAddScopeDisabled(disable);
  };

  const GetSelectedScopes = (info = []) => {
    setIsDropDown(false);
    setResetFlag(false);
    let selectedScopes = [...selectedFullScopes];

    if (addRoledata.needsCustomScopes && addRoledata.needsOrgScopes) {
      let elem = {
        orgScope: org ? org : "",
        custScope: cust ? cust : "",
        groupType: groupType,
        orgId: orgId,
      };
      selectedScopes = [...selectedScopes, elem];
    } else if (addRoledata.needsOrgScopes) {
      if (orgArray.length || info.length) {
        if (info.length) {
          selectedScopes = info;
        } else {
          selectedScopes = orgArray;
        }
        setOrgArray([]);
      } else {
        if (org.length && groupType.length && orgId.length) {
          let elem = { orgScope: org, groupType: groupType, orgId: orgId };
          selectedScopes = [...selectedScopes, elem];
        } else {
          selectedScopes = [];
        }
      }
    } else if (addRoledata.needsCustomScopes) {
      let elem = { custScope: cust };
      selectedScopes = [...selectedScopes, elem];
    } else {
      selectedScopes = [...selectedScopes];
    }
    SetSelectedScopes(selectedScopes);
    setActiveKey(getUUID());
    setResetFlag(true);
    setOrgId("");
    setCust("");
    setAddScopeDisabled(true);
    setOrgSelectedScope({});
  };

  const removeSelectedScope = async (id, orgId) => {
    let selectedScopes = [...selectedFullScopes];
    for (var i = 0; i < selectedScopes.length; i++) {
      if (selectedScopes[i].id === id) {
        selectedScopes.splice(i, 1);
      }
    }

    for (var i = 0; i < orgArray.length; i++) {
      if (orgArray[i].id === id) {
        orgArray.splice(i, 1);
      }
    }
    await SetSelectedScopes(selectedScopes);
  };

  const addSelectedRole = async () => {
    let data = [...roleData];

    for (var i = 0; i < data.length; i++) {
      if (data[i].id === addRoledata.id) {
        data[i].selectedScopes = selectedFullScopes;
        if (addtimeFrame === true) {
          let validDates =
            moment(new Date(selectedDateValidFrom)).format("DD MMM YYYY") +
            " - " +
            moment(new Date(selectedDateValidUntil)).format("DD MMM YYYY");
          data[i].validFrom = selectedDateValidFrom;
          data[i].validTo = selectedDateValidUntil;
          if (data[i].validFrom !== null && data[i].validTo !== null) {
            data[i].validity = validDates;
          }
          data[i].defaultValidityDays = validityDays;
        } else {
          data[i].validFrom = null;
          data[i].validTo = null;
          data[i].validity = null;
        }
        data[i].istimeFrame = addtimeFrame;
      }
    }

    await dispatch(updateRoleData(data));
    selectCard(params.id);
    notify({
      severity: "success",
      message: t("role_added_successfully"),
    });
  };

  const updateValues = async () => {
    const { needsCustomScopes, needsOrgScopes } = addRoledata;
    selectedFullScopes.forEach((scope, index) => {
      scope.validFrom = addtimeFrame
        ? (needsOrgScopes && !needsCustomScopes && orgScopes === scope.orgId) ||
          (needsCustomScopes &&
            !needsOrgScopes &&
            custScope === scope.custScope) ||
          (needsOrgScopes &&
            orgScopes === scope.orgId &&
            needsCustomScopes &&
            custScope === scope.custScope)
          ? !isNaN(validFromUrlDecoded)
            ? validFromUrlDecoded.toString().trim().length
              ? validFromUrlDecoded
              : selectedDateValidFrom
            : new Date()
          : new Date()
        : null;
      if (scope.validFrom === validFromUrlDecoded) {
        //Keeping original assignment at top
        selectedFullScopes.splice(index, 1);
        selectedFullScopes.unshift(scope);
      }
    }); //Adaption from original Valid To Date if adding additional Org/Custom Scopes ALICE-6558

    if (isMode) {
      params.updateHandler({
        validFrom: addtimeFrame
          ? (needsOrgScopes &&
              !needsCustomScopes &&
              orgScopes === selectedFullScopes[0].orgId) ||
            (needsCustomScopes &&
              !needsOrgScopes &&
              custScope === selectedFullScopes[0].custScope) ||
            (needsOrgScopes &&
              orgScopes === selectedFullScopes[0].orgId &&
              needsCustomScopes &&
              custScope === selectedFullScopes[0].custScope) ||
            (!addRoledata.needsOrgScopes && !addRoledata.needsCustomScopes)
            ? !isNaN(validFromUrlDecoded) &&
              mode !== "reapply" &&
              defaultValidType !== "FIXED"
              ? validFromUrlDecoded.toString().trim().length
                ? validFromUrlDecoded
                : selectedDateValidFrom
              : selectedDateValidFrom
            : needsOrgScopes && orgScopes.length
            ? new Date()
            : selectedDateValidFrom
          : null,
        validTo: addtimeFrame ? selectedDateValidUntil : null,
        scopes: selectedFullScopes,
        istimeFrame: addtimeFrame,
      });
      return;
    }
    let data = [...selectedRolesData];
    for (var i = 0; i < data.length; i++) {
      if (data[i].id === addRoledata.id) {
        data[i].selectedScopes = selectedFullScopes;
        if (addtimeFrame === true) {
          let validDates =
            moment(new Date(selectedDateValidFrom)).format("DD MMM YYYY") +
            " - " +
            moment(new Date(selectedDateValidUntil)).format("DD MMM YYYY");
          data[i].validFrom = selectedDateValidFrom;
          data[i].validTo = selectedDateValidUntil;
          if (data[i].validFrom !== null && data[i].validTo !== null) {
            data[i].validity = validDates;
          }
          data[i].defaultValidityDays = validityDays;
        } else {
          data[i].validFrom = null;
          data[i].validTo = null;
          data[i].validity = null;
        }
        data[i].istimeFrame = addtimeFrame;
      }
    }

    await dispatch(selectedRoles(data));
    await setEditOptionsChange(params.name);
  };

  const handleDateChange = (date) => {
    const previousDate = selectedDateValidFrom;
    if (defaultValidType === "FIXED" && mode === "reapply") {
      setSelectedDateValidFrom(new Date(date));
    } else {
      if (dateChangeIsValid(date)) {
        setSelectedDateValidFrom(new Date(date));
      } else {
        setSelectedDateValidFrom(previousDate);
      }
    }
    setSelectedDateValidFrom(new Date(date));
  };

  const handleDateChangeValidUntil = async (date) => {
    checkDateChangeValid(date);
  };

  const dateChangeIsValid = (date) => {
    const dateTo =
      selectedDateValidUntil.toString() !== "Invalid date"
        ? new Date(selectedDateValidUntil)
        : new Date().getTime() + validityDays * 24 * 60 * 60 * 1000;
    const dateFrom = moment(new Date(date));
    return dateFrom.isBefore(dateTo);
  };

  const checkDateChangeValid = async (date) => {
    await setSelectedDateValidUntil(new Date(date));
    const date1 = new Date(selectedDateValidFrom);
    const date2 = new Date(date.toString());
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setDefaultValidityDates(diffDays);
  };

  let isDisable = false;
  let dateValidUntill = selectedDateValidUntil;
  let prevSelectedDate = preSelectedInputValue;

  const checkvalidate = () => {
    if (addtimeFrame === true) {
      if (
        new URLSearchParams(window.location.search).get("mode") === "extend" &&
        defaultValidType !== "FIXED"
      ) {
        const dateString = new URLSearchParams(window.location.search).get(
          "validFrom"
        );
        if (dateString && dateString.length) {
          const parts = dateString.split("-");
          const year = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const day = parseInt(parts[2]);
          const date = new Date(year, month, day);
          return (
            new Date(selectedDateValidFrom) >
              new Date(selectedDateValidUntil) ||
            new Date(selectedDateValidFrom) ===
              new Date(selectedDateValidUntil) ||
            new Date(selectedDateValidFrom).setHours(0, 0, 0, 0) === date ||
            new Date(dateValidUntill).setHours(0, 0, 0, 0) <
              new Date(prevSelectedDate).setHours(0, 0, 0, 0)
          );
        } else {
          return (
            new Date(selectedDateValidFrom) >
              new Date(selectedDateValidUntil) ||
            new Date(selectedDateValidFrom) ===
              new Date(selectedDateValidUntil) ||
            new Date(dateValidUntill).setHours(0, 0, 0, 0) <
              new Date(prevSelectedDate).setHours(0, 0, 0, 0)
          );
        }
      } else {
        return (
          new Date(selectedDateValidFrom) > new Date(selectedDateValidUntil) ||
          new Date(selectedDateValidFrom) ===
            new Date(selectedDateValidUntil) ||
          new Date(selectedDateValidFrom).setHours(0, 0, 0, 0) <
            new Date(preSelectedInputValue).setHours(0, 0, 0, 0) ||
          new Date(dateValidUntill).setHours(0, 0, 0, 0) <
            new Date(prevSelectedDate).setHours(0, 0, 0, 0)
        );
      }
    } else {
      return false;
    }
  };

  if (addRoledata.needsOrgScopes && addRoledata.needsCustomScopes) {
    if (selectedFullScopes && selectedFullScopes.length > 0) {
      for (let i = 0; i < selectedFullScopes.length; i++) {
        if (
          selectedFullScopes[i].custScope === "" ||
          selectedFullScopes[i].orgScope === "" ||
          checkvalidate()
        ) {
          isDisable = true;
        }
      }
    } else isDisable = true;
  } else if (addRoledata.needsOrgScopes || addRoledata.needsCustomScopes) {
    if (selectedFullScopes && selectedFullScopes.length > 0 && !checkvalidate())
      isDisable = false;
    else isDisable = true;
  } else {
    if (checkvalidate()) isDisable = true;
    else isDisable = false;
  }

  const isAddScopeDisabled = ({ orgSl = "", custSl = "" }) => {
    const { needsCustomScopes, needsOrgScopes } = addRoledata;
    const isBoth = needsOrgScopes && needsCustomScopes;
    const orgCustValidation = isBoth && orgSl !== "" && custSl !== "";
    const orgValidation = needsOrgScopes && !needsCustomScopes && orgSl !== "";

    const custValidation =
      !needsOrgScopes && needsCustomScopes && custSl !== "";
    const isSelected = selectedFullScopes.filter((d) => {
      const isValidOrg = d.orgId === orgSl;
      const isValidCust = d.custScope === custSl;

      if (
        (isBoth && isValidOrg && isValidCust) ||
        (!needsOrgScopes && needsCustomScopes && isValidCust) ||
        (needsOrgScopes && !needsCustomScopes && isValidOrg)
      )
        return true;
      return false;
    });

    const isDisabled = !(
      (orgCustValidation || orgValidation || custValidation) &&
      isSelected.length === 0
    );
    if (isDisabled) {
      if (
        (isBoth && orgCustValidation) ||
        (!isBoth && (custValidation || orgValidation))
      )
        notify({
          severity: "warning",
          message: t("scope-already-selected"),
        });
    }
    return isDisabled;
  };

  const getCalander = () => {
    return (
      defaultValidType !== "NEVER" && (
        <>
          <h3 className="mb-0 time_frame">{t("time-frame")}</h3>
          {defaultValidType === "OPTIONAL" && (
            <FormControlLabel
              value="addTimeframe"
              control={
                <Checkbox
                  checked={addtimeFrame}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
              }
              label={t("add-timeframe")}
              labelPlacement="end"
            />
          )}
          <Grid container spacing={2} item xs={12} sm={12} md={10} lg={10}>
            <Grid item className="filterUnVisible CustomeDatePickerContaitner">
              <CustomDatePicker
                id={params?.id}
                disablePast={
                  validFromUrlDecoded.toString().trim().length &&
                  mode !== "reapply"
                    ? false
                    : true
                }
                value={
                  (addRoledata.needsOrgScopes &&
                    !addRoledata.needsCustomScopes &&
                    orgScopes === selectedFullScopes[0]?.orgId) ||
                  (addRoledata.needsCustomScopes &&
                    !addRoledata.needsOrgScopes &&
                    custScope === selectedFullScopes[0]?.custScope) ||
                  (addRoledata.needsOrgScopes &&
                    orgScopes === selectedFullScopes[0]?.orgId &&
                    addRoledata.needsCustomScopes &&
                    custScope === selectedFullScopes[0]?.custScope) ||
                  (!addRoledata.needsOrgScopes &&
                    !addRoledata.needsCustomScopes)
                    ? !isNaN(validFromUrlDecoded) &&
                      isMode &&
                      mode !== "reapply" &&
                      defaultValidType !== "FIXED"
                      ? validFromUrlDecoded.toString().trim().length
                        ? validFromUrlDecoded
                        : selectedDateValidFrom
                      : selectedDateValidFrom
                    : addtimeFrame === false
                    ? new Date()
                    : selectedDateValidFrom.toString() !== "Invalid date"
                    ? selectedDateValidFrom
                    : new Date()
                }
                inputLabel={t("valid_from")}
                selectedDate={(date) => handleDateChange(date)}
                preselectedDate={
                  (addRoledata.needsOrgScopes &&
                    !addRoledata.needsCustomScopes &&
                    orgScopes === selectedFullScopes[0]?.orgId) ||
                  (addRoledata.needsCustomScopes &&
                    !addRoledata.needsOrgScopes &&
                    custScope === selectedFullScopes[0]?.custScope) ||
                  (addRoledata.needsOrgScopes &&
                    orgScopes === selectedFullScopes[0]?.orgId &&
                    addRoledata.needsCustomScopes &&
                    custScope === selectedFullScopes[0]?.custScope) ||
                  (!addRoledata.needsOrgScopes &&
                    !addRoledata.needsCustomScopes)
                    ? !isNaN(validFromUrlDecoded) &&
                      isMode &&
                      mode !== "reapply" &&
                      defaultValidType !== "FIXED"
                      ? validFromUrlDecoded.toString().trim().length
                        ? validFromUrlDecoded
                        : preSelectedInputValue
                      : selectedDateValidFrom
                    : addtimeFrame === false
                    ? new Date()
                    : preSelectedInputValue.toString() !== "Invalid date"
                    ? preSelectedInputValue
                    : new Date()
                }
                isDisabled={
                  (mode !== "reapply" || addtimeFrame === false) &&
                  (defaultValidType === "FIXED" ||
                    addtimeFrame === false ||
                    (params.isMode && mode.length))
                }
                helperText={checkvalidate() ? t("enter-valid-start-date") : ""}
                error={checkvalidate()}
                addtimeFrame={addtimeFrame}
                isErrorHandler={(value) => setIsErrorInCalender(value)}
                // sx={{ width: "100%" }}
              />

              {new URLSearchParams(window.location.search).get("mode") ===
                "extend" &&
              !validFromUrlDecoded.toString().trim().length &&
              defaultValidType === "FIXED" ? (
                <span className="startDateMessage">
                  {t("extend-role-startDate-message")}
                </span>
              ) : null}
            </Grid>
            <Grid
              item
              className="filterUnVisible CustomeDatePickerContaitner ToContainer"
            >
              <CustomDatePicker
                id={params?.id}
                inputLabel={t("valid_to")}
                isDisabled={
                  defaultValidType === "FIXED" || addtimeFrame === false
                    ? true
                    : false
                }
                disablePast={true}
                selectedDate={(date) => handleDateChangeValidUntil(date)}
                preselectedDate={
                  addtimeFrame === false
                    ? new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
                    : preSelectedDateValidUntil != "Invalid date"
                    ? preSelectedDateValidUntil
                    : new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
                }
                value={
                  addtimeFrame === false
                    ? new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
                    : selectedDateValidUntil != "Invalid date"
                    ? selectedDateValidUntil
                    : new Date().getTime() + validityDays * 24 * 60 * 60 * 1000
                }
                helperText={
                  new Date(selectedDateValidFrom) >
                    new Date(selectedDateValidUntil) ||
                  new Date(selectedDateValidFrom) ===
                    new Date(selectedDateValidUntil) ||
                  new Date(dateValidUntill).setHours(0, 0, 0, 0) <
                    new Date(prevSelectedDate).setHours(0, 0, 0, 0)
                    ? t("enter-valid-end-date")
                    : ""
                }
                error={
                  new Date(selectedDateValidFrom) >
                    new Date(selectedDateValidUntil) ||
                  new Date(selectedDateValidFrom) ===
                    new Date(selectedDateValidUntil) ||
                  new Date(dateValidUntill).setHours(0, 0, 0, 0) <
                    new Date(prevSelectedDate).setHours(0, 0, 0, 0)
                }
                addtimeFrame={addtimeFrame}
                isErrorHandler={(value) => setIsErrorInCalender(value)}
                // sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </>
      )
    );
  };

  const getScopeCards = () => {
    let dataList = selectedFullScopes;

    if (!hasData(dataList)) return null;

    return (
      <div>
        {!isMode && (
          <>
            <h3 className="mb-0 font-16">{t("selected-scopes")}</h3>
            <p className="font-16">
              {t("scope-configuration-results-in-separate-role-request")}
            </p>
          </>
        )}

        <Grid container spacing={2} className="mt-16">
          {dataList.map((element, i) => {
            return (
              <ScopeCard
                key={element.id}
                removeScopes={() =>
                  removeSelectedScope(element.id, element.orgId)
                }
                groupType={element.groupType}
                customScope={element.custScope}
                orgScope={element.orgScope}
                orgId={element.orgId}
              />
            );
          })}
        </Grid>
      </div>
    );
  };

  const getScopeOptions = () => (
    <>
      <Typography className="font-16 mt-16">
        {t("desired-scope-selection")}
      </Typography>
      {addRoledata.needsOrgScopes ? (
        <React.Fragment>
          <SelectOrganizationContainer>
            <Typography classes={{ body1: "tbody" }} className="adjustcontent">
              1.{" "}
              <span className="adjustcontent" style={{ marginLeft: "0px" }}>
                {t("select_organisation")}
              </span>
            </Typography>
            {isPreviousOrgScopeDispaly() ? (
              <Button
                type="primary"
                className="preScopeButton"
                onClick={() => setDialog(true)}
              >
                <img src={TimeCircle} alt={"timecircle"} />
                {t("add-prev-used-org")}
              </Button>
            ) : null}
          </SelectOrganizationContainer>
          <OrgComponent
            roleId={addRoledata.id}
            addRoledata={addRoledata}
            updateOrgArray={(e) => setOrgArray(e)}
            updateOrgScope={(e) => updateOrgScope(e)}
            updategroupType={updategroupType}
            updateOrgId={(e) => updateOrgId(e)}
            accessFlag={params.accessFlag}
            selectedFullScopes={selectedFullScopes}
            isMode={params.isMode}
            preSelect={hasData(selectedFullScopes) ? {} : preSelect}
            searchTerm={searchTerm}
            setSearchTerm={(value) => setSearchTerm(value)}
            deSelectInfo={deSelectData}
            setOrgSelectedScope={(value) => setOrgSelectedScope(value)}
            orgSelectedScopePage={orgSelectedData}
            openHandler={(e) => setIsDropDown(e)}
          />
          {isPreviousOrgScopeDispaly() ? (
            <PreviousOrgDialog
              dialog={dialog}
              handleDialogClose={handleDialogClose}
              previousSelectedOrgScope={selectedRolesData.filter(
                (item) => item.needsOrgScopes && !item.needsCustomScopes
              )}
              selectedFullScopes={selectedFullScopes}
              roleId={addRoledata.id}
              checkIsSelected={(orgValue) =>
                isAddScopeDisabled({ orgSl: orgValue, custSl: "" })
              }
              addSelectedSCope={(orgInfo, tableInfo) =>
                GetSelectedScopes(orgInfo)
              }
            />
          ) : null}
        </React.Fragment>
      ) : null}
      {addRoledata.needsCustomScopes ? (
        <React.Fragment>
          <NeedCustomScopeTypography classes={{ body1: "tbody" }}>
            {addRoledata.needsCustomScopes && addRoledata.needsOrgScopes
              ? 2
              : 1}
            .{" "}
            <span style={{ marginLeft: "0px" }}>
              {t("select_custom_scope")}
            </span>
          </NeedCustomScopeTypography>

          <CustomScope
            roleId={addRoledata.id}
            addRoledata={addRoledata}
            updateCustScope={(e) => updateCustScope(e)}
            resetFlag={resetFlag}
            accessFlag={params.accessFlag}
            selectedFullScopes={selectedFullScopes}
            preSelect={hasData(selectedFullScopes) ? {} : preSelect}
          />
        </React.Fragment>
      ) : null}
      <br />
      <AddScopeButton
        label={t("add-scopes")}
        onButtonClick={() => GetSelectedScopes()}
        disabled={
          isDrodownOpen
            ? addScopeDisabled &&
              areArraysSameStructure(orgArray, selectedFullScopes)
            : addRoledata.needsOrgScopes && !addRoledata.needsCustomScopes
            ? true
            : addScopeDisabled
        }
      />
    </>
  );

  return (
    <MainContainer item xs={12} sm={12} md={12} lg={12} ref={maincontainerRef}>
      <CloseButton
        onClick={() => setEditOptionsChange(params.id)}
        sx={{ float: "right" }}
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={width < 415 ? "p-16" : "p-10"}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} className="flex">
          <Grid item xs={10} sm={10} md={11} lg={11} className="detail">
            {t("request-options")}
          </Grid>
        </Grid>

        <Grid>
          <Typography className="detailText" variant="body1">
            {addRoledata.description}
          </Typography>
          {getCalander()}
          {addRoledata &&
          (addRoledata.needsOrgScopes || addRoledata.needsCustomScopes) ? (
            <React.Fragment key={activeKey}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <NeedCustomScopeTypography classes={{ body1: "tbody" }}>
                  {t("role-scope-configuration")}
                </NeedCustomScopeTypography>
                {getScopeOptions()}
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid>{getScopeCards()}</Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="mr-8 dpl"
            style={{
              display: "block",
              padding: "40px 0px",
            }}
          >
            {isAddRoleOpen === true ? (
              <PrimaryButton
                label={t("add-role")}
                onButtonClick={addSelectedRole}
                className="fr wid"
                disabled={isErrorInCalender ? true : isDisable}
              />
            ) : (
              <PrimaryButton
                label={t("save-options")}
                onButtonClick={updateValues}
                className="fr wid"
                disabled={isErrorInCalender ? true : isDisable}
              />
            )}

            <SecondaryButton
              label={t("dismiss")}
              onButtonClick={() => setEditOptionsChange(params.id)}
              params={params.name}
              className="fr mr-26 mrw ml-10"
            />
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
}
