import { commonActionTypes } from "../constants/actionTypes";

const actions = {
  LoadSpinner: (payload) => ({
    type: commonActionTypes.LOADING,
    data: payload,
  }),
  selectedtabIndex: (payload) => ({
    type: commonActionTypes.TAB_INDEX,
    data: payload,
  }),
  errorInfo: (payload) => ({
    type: commonActionTypes.API_DOWN,
    data: payload,
  }),
  forceReload: (payload) => ({
    type: commonActionTypes.REFRESH_PAGE,
    data: payload,
  }),
  setSnackBar: (payload) => ({
    type: commonActionTypes.SET_SNACKBAR,
    data: payload,
  }),
};
/**Fetching all Users information */
export const LoadingData = (data) => (dispatch) => {
  dispatch(actions.LoadSpinner(data));
};
export const selectedtabIndex = (data) => (dispatch) => {
  dispatch(actions.selectedtabIndex(data));
};

export const errorInfo = (data) => (dispatch) => {
  dispatch(actions.errorInfo(data));
};

export const forceReload = (data) => (dispatch) => {
  dispatch(actions.forceReload(data));
};

export const SetSnackBar = (data) => (dispatch) => {
  dispatch(actions.setSnackBar(data));
};
