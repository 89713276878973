import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridViewOutlined, ViewHeadlineOutlined } from "@mui/icons-material";
import { GenericSearch, CacheKey } from "@alice/component_library";
import { CustomOrgCardViewRenderer } from "./CustomViewGridRenderer";

export const CustomOrgGenericSeacrh = forwardRef(
  (
    {
      title,
      description,
      data,
      search = "large",
      views = ["grid", "list"],
      isSortHidden,
      preselectedRowOrgIds = [],
      preselectedView = "list",
      preselectedSearch,
      preselectedSort,
      preselectedRowsPerPage,
      preselectedSearchBehavior,
      preselectedSortBehavior,
      onGetUniqueIdentifier = (data) => data?.id,
      onCardClick,
      ...otherProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    const createViewConfig = () => {
      const viewConfig = { views: {} };

      if (views.includes("grid")) {
        viewConfig.views.grid = {
          tooltip: t("appSearch.view.grid"),
          icon: GridViewOutlined,
          renderer: CustomOrgCardViewRenderer,
        };
      }

      if (views.includes("list")) {
        viewConfig.views.list = {
          tooltip: t("appSearch.view.list"),
          icon: ViewHeadlineOutlined,
          renderer: CustomOrgCardViewRenderer,
        };
      }

      return viewConfig;
    };

    const createSearchConfig = () => {
      if (search === undefined || search === "none") return undefined;

      return {
        type: search,
        label: t("search-cutom-scope"),
        apiFilters: ["description", "name"],
        preselectedBehavior: preselectedSearchBehavior,
      };
    };

    const createSortConfig = () => {
      if (isSortHidden) return undefined;

      return {
        preselectedBehavior: preselectedSortBehavior,
        sorts: [
          {
            value: "+name",
            label: t("sort.customscopeA-Z"),
          },
          {
            value: "-name",
            label: t("sort.customscopeZ-A"),
          },
        ],
      };
    };

    return (
      <GenericSearch
        data-testid="CustomOrgsearch"
        ref={ref}
        localData={data}
        title={title}
        description={description}
        searchConfig={createSearchConfig()}
        viewConfig={createViewConfig()}
        sortConfig={createSortConfig()}
        preselectedRowIds={preselectedRowOrgIds}
        preselectedView={preselectedView}
        preselectedSearch={preselectedSearch}
        preselectedSort={preselectedSort}
        preselectedRowsPerPage={preselectedRowsPerPage}
        isBottomPaginationHidden={true}
        onItemClick={onCardClick}
        getResponseData={(data) => data.data}
        {...otherProps}
      />
    );
  }
);
