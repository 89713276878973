import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isLoading: false,
    selectedTab: 1,
    apiDown: false,
    refreshFlag: false,
    accessRedirect: false,
    snackBar: {},
  },
  reducers: {
    loadSpinner: (state, action) => {
      state.isLoading = action.payload.data;
    },
    selectedtabIndex: (state, action) => {
      state.selectedTab = action.payload.data;
    },
    errorInfo: (state, action) => {
      state.apiDown = action.payload.data;
    },
    forceReload: (state, action) => {
      state.refreshFlag = action.payload.data;
    },
    setSnackBar: (state, action) => {
      state.snackBar = action.payload.data;
    },
  },
});

export const {
  loadSpinner,
  selectedtabIndex,
  errorInfo,
  forceReload,
  setSnackBar,
} = commonSlice.actions;
export default commonSlice.reducer;
