import { Checkbox, MenuItem, Select as MuiSelect, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

/**
 * Mui Select Wrapper
 *
 * https://mui.com/material-ui/api/select/
 */
export const Select = forwardRef((
  {
    children,
    value,
    MenuProps: parentMenuProps,
    variant = 'filled',
    multiple = false,
    selectables,
    enableCheckbox = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const MenuProps = {
    disableScrollLock: true,
    sx: {
      '.Mui-selected': {
        backgroundColor: 'blue.95',
        color: 'common.black'
      },
      '.Mui-selected:hover': {
        backgroundColor: 'blue.90',
        color: 'common.black'
      }
    },
    ...parentMenuProps
  }

  const getItem = (selectable) => (typeof selectable === 'object' ? selectable.item : selectable)
  const getRenderer = (selectable) => (typeof selectable === 'object' ? selectable.itemRenderer : selectable)

  return (
    <MuiSelect
      data-testid="Select"
      ref={ref}
      MenuProps={MenuProps}
      multiple={multiple}
      native={false}
      variant={variant}
      value={value}
      sx={mergeSxProps(
        { svg: { right: '-2px' } },
        sx
      )}
      {...otherProps}
    >
      {!children && selectables
        // If no children are given and checkboxes are enabled -> else render children
        ? selectables.map((selectable) => (
          <MenuItem key={getItem(selectable)} value={getItem(selectable)}>
            {enableCheckbox && <Checkbox checked={value.indexOf(getItem(selectable)) > -1} />}

            {typeof selectable === 'object'
              ? getRenderer(selectable)
              : <Typography>{getRenderer(selectable)}</Typography>}
          </MenuItem>
        ))
        : children}
    </MuiSelect>
  )
})

Select.propTypes = {
  ...MuiSelect.propTypes,
  children: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  MenuProps: PropTypes.object,
  variant: PropTypes.oneOf([ 'filled', 'outlined', 'standard' ]),
  multiple: PropTypes.bool,
  /**
   * @returns {Event}
   */
  onChange: PropTypes.func,
  /**
   * Render the selected value. You can only use it when the `native` prop is `false` (default).
   * @param {any} value
   * @returns {ReactNode}
   */
  renderValue: PropTypes.func,
  /**
   * A list of all selectables, that the multiselect should display.
   *
   * Keep in mind: That prop should only be used, when enableCheckbox and multiple are true.<br>
   * The key depends on the object given as prop:
   * - If this prop is an array of strings, the string will be the key.
   * - If this prop is an array of { item, itemRenderer } this item will be the key.
   */
  selectables: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        item: PropTypes.any,
        itemRenderer: PropTypes.any
      })
    ])
  ),
  /**
   * Enables checkboxes.<br>
   * This requires the multiple and selectables props being set.
   */
  enableCheckbox: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
