import { ViewHeadlineOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CacheKey } from '../../../data'
import { useUser } from '../../../hooks'
import { GenericSearch } from '../../search'
import { UserGroupSearchGridViewRenderer } from './UserGroupSearchGridViewRenderer'

/**
 * **UserGroupSearch Component**
 *
 * The `UserGroupSearch` component is a reusable component that provides a search interface for users.
 * It allows users to search for users groups using a specified API endpoint and query string.
 *
 * **Important Note**
 *
 * This component is built upon the foundation of the GenericSearch component, thus inheriting all properties supported by GenericSearch.
 * For a comprehensive list of available properties, please refer to the [GenericSearch documentation](./?path=/docs/alice-ui-search-genericsearch--docs).
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 *
 */
export const UserGroupSearch = forwardRef((
  {
    title,
    description,
    apiUrl = '/groups',
    search = 'large',
    views = [ 'list' ],
    size,
    isIdLink = true,
    isAdminRolesOverwritten = false, // Testing only
    isSelectable = false,
    isFilterHidden,
    isSortHidden,
    isSelectAllHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    preselectedRowIds = [],
    preselectedView,
    preselectedSearch,
    preselectedSort, // TODO: Use when sort is back in the API
    isPreselectedUsedByMe,
    isPreselectedOwnedByMe,
    preselectedRowsPerPage,
    preselectedSearchBehavior,
    preselectedSortBehavior, // TODO: Use when sort is back in the API
    preselectedFilterBehavior,
    isCaching = false,
    cacheKey = CacheKey.userGroupSearchQuery,
    onGetResponseData = (data) => data.groupsDto,
    onGetUniqueIdentifier = (data) => data.uuid,
    onCardClick,
    showDetailedCommunity = false,
    rendererProps = {
      isIdLink,
      isAdminRolesOverwritten // Testing only
    },
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')
  const { user } = useUser()

  const createViewConfig = () => {
    const viewConfig = { views: {} }

    if (views.includes('list')) {
      viewConfig.views.list = {
        tooltip: t('userGroupSearch.view.list'),
        icon: ViewHeadlineOutlined,
        renderer: UserGroupSearchGridViewRenderer
      }
    }

    return viewConfig
  }

  const createSearchConfig = () => {
    if (search === undefined || search === 'none') return undefined

    return {
      type: search,
      label: t('userGroupSearch.search.label'),
      apiFilters: [ 'uuid', 'name', 'owners.search', 'users.search' ],
      preselectedBehavior: preselectedSearchBehavior
    }
  }

  const createFilterConfig = () => {
    if (isFilterHidden) return undefined

    return {
      isAllShown: true,
      preselectedBehavior: preselectedFilterBehavior,
      filters: {
        single: {
          label: 'Filter by:',
          filters: [
            {
              filterOverwrite: 'users.id',
              value: user?.id,
              label: t('userGroupSearch.filter.usedByMe.label')
            },
            {
              filterOverwrite: 'owners.id',
              value: user?.id,
              label: t('userGroupSearch.filter.ownedByMe.label')
            }
          ]
        }
      }
    }
  }

  const createPreselectedFilter = () => {
    const preselectedFilter = {}

    if (isPreselectedUsedByMe) preselectedFilter['users.id'] = [ user?.id ]
    if (isPreselectedOwnedByMe) preselectedFilter['owners.id'] = [ user?.id ]

    return Object.keys(preselectedFilter).length ? preselectedFilter : undefined
  }

  return (
    <GenericSearch
      data-testid="UserGroupSearch"
      ref={ref}
      title={title}
      description={description}
      getUniqueIdentifier={onGetUniqueIdentifier}
      getResponseData={onGetResponseData}
      apiUrl={apiUrl}
      cacheKey={cacheKey}
      searchConfig={createSearchConfig()}
      viewConfig={createViewConfig()}
      filterConfig={createFilterConfig()}
      // sortConfig={createSortConfig()} // TODO: Use when sort is back in the API
      preselectedRowIds={preselectedRowIds}
      preselectedView={preselectedView}
      preselectedSearch={preselectedSearch}
      // preselectedSort={preselectedSort} // TODO: Use when sort is back in the API
      preselectedFilter={createPreselectedFilter()}
      preselectedRowsPerPage={preselectedRowsPerPage}
      isCaching={isCaching}
      isSelectAllHidden={isSelectAllHidden}
      isTopPaginationHidden={isTopPaginationHidden}
      isBottomPaginationHidden={isBottomPaginationHidden}
      onItemClick={onCardClick}
      rendererProps={{ ...rendererProps, size }}
      {...otherProps}
    />
  )
})

UserGroupSearch.propTypes = {
  /** Adds a title */
  title: PropTypes.string,
  /** Adds a description under the title */
  description: PropTypes.string,
  /** Api-Endpoint to collect required data */
  apiUrl: PropTypes.string,
  /** Type of search. Can be 'none', 'small', 'large' */
  search: PropTypes.oneOf([ 'none', 'small', 'large' ]),
  /** Views that should be displayed. */
  views: PropTypes.arrayOf(PropTypes.oneOf([ 'grid', 'list' ])),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl', 'xxl' ]),
  /** List of filter organization forms */
  forms: PropTypes.arrayOf(PropTypes.string),
  /** Determines if chip is hidden or not */
  isChipHidden: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Determines if filter is hidden or not */
  isFilterHidden: PropTypes.bool,
  /** Determines if sort is hidden or not */
  isSortHidden: PropTypes.bool,
  /** Determines if pagination on top of the grid is hidden or not */
  isTopPaginationHidden: PropTypes.bool,
  /** Determines if pagination on bottom of grid is hidden or not */
  isBottomPaginationHidden: PropTypes.bool,
  /** Preselect rows */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** Preselected view */
  preselectedView: PropTypes.string,
  /** Preselected search query */
  preselectedSearch: PropTypes.string,
  /** Preselected sort */
  preselectedSort: PropTypes.string, // TODO: Use when sort is back in the API
  /** Preselected Used by me */
  isPreselectedUsedByMe: PropTypes.bool,
  /** Preselected Owned by me */
  isPreselectedOwnedByMe: PropTypes.bool,
  /** Preselected rows per page */
  preselectedRowsPerPage: PropTypes.number,
  /** Behavior of the search when preselected */
  preselectedSearchBehavior: PropTypes.oneOf([ 'disable' ]),
  /** Behavior of the sort when preselected */
  // preselectedSortBehavior: PropTypes.oneOf([ 'disable' ]), // TODO: Use when sort is back in the API
  /** Behavior of the filter when preselected. See: [GenericSearch](/?path=/docs/alice-ui-search-genericsearch--docs) */
  preselectedFilterBehavior: PropTypes.oneOf([ 'disableFilter', 'disableFilterGroup', 'hideFilterGroup' ]),
  /** Determines if caching is active or not */
  isCaching: PropTypes.bool,
  /** Custom caching key */
  cacheKey: PropTypes.string,
  /** Called by generic search to get data from api response. Could not be needed when using a custom endpoint. */
  onGetResponseData: PropTypes.func,
  /** Called by generic search to get id from a single data set */
  onGetUniqueIdentifier: PropTypes.func,
  /** Callback on card click */
  onCardClick: PropTypes.func,
  /** Determines whether to show detailed community or not. */
  showDetailedCommunity: PropTypes.bool,
  /** Reference with helper functions to control the search from outside */
  reference: PropTypes.any
}
