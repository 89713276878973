import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAssignableRoles,
  fetchAvailableApplications,
  fetchEntitlementsOfRole,
  handleBulkImport,
  fetchApplicationDetails,
  fetchAssignableRolesForreferenceUser,
} from "@sliceactions/newRequestActions";

const newRequestSlice = createSlice({
  name: "newRequest",
  initialState: {
    referenceUserData: {
      userId: "",
    },
    assignableRoles: {
      data: [],
      error: false,
      loading: false,
      isLoaded: false,
      offset: 0,
      hasMore: false,
      totalCount: 0,
    },
    availableApplications: {
      data: [],
      error: false,
      loading: false,
      isLoaded: false,
      offset: 0,
      hasMore: false,
      totalCount: 0,
    },
    roleEntitlements: {
      entitlements: {},
      error: false,
      loading: false,
    },
    applicationDetails: {
      appData: {},
      error: false,
      loading: false,
    },
    bulkImportState: {
      status: "",
      error: "",
      importId: "",
    },
    requestData: {
      rolesData: [],
      reason: "",
      userId: "",
      savedData: [],
    },
    requestId: {
      requestDataId: "",
      resendSucess: false,
    },
    resultLoading: false,
  },

  reducers: {
    resetBulkImpState: (state) => {
      state.bulkImportState.status = "";
      state.bulkImportState.error = "";
      state.bulkImportState.importId = "";
    },
    resetAvailableApplications: (state) => {
      state.availableApplications.data = [];
    },
    resetAssignableRoles: (state) => {
      state.assignableRoles.data = [];
    },
    setRequestId: (state, action) => {
      state.requestId.requestDataId = action.payload;
    },
    resetRequestId: (state) => {
      state.requestId.requestDataId = "";
    },
    resendSucess: (state, action) => {
      state.requestId.resendSucess = action.payload;
    },
    saveRoleData: (state, action) => {
      state.requestData = action.payload;
    },
    resultLoading: (state, action) => {
      state.resultLoading = action.payload;
    },
    storeSavedRoleRoleData: (state, action) => {
      state.requestData.savedData = action.payload;
    },
    saveReferenceUserData: (state, action) => {
      state.referenceUserData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch assignable roles
      .addCase(fetchAssignableRoles.pending, (state) => {
        state.assignableRoles.loading = true;
        state.assignableRoles.error = false;
        state.assignableRoles.isLoaded = false;
      })
      .addCase(fetchAssignableRoles.fulfilled, (state, action) => {
        state.assignableRoles.data = action.payload.roles;
        state.assignableRoles.totalCount = action.payload.totalCount;
        state.assignableRoles.hasMore = action.payload.hasMore;
        state.assignableRoles.offset = action.payload.offset;
        // state.assignableRoles.data = action.payload.data;
        state.assignableRoles.error = false;
        state.assignableRoles.isLoaded = true;
        state.assignableRoles.loading = false;
      })
      .addCase(fetchAssignableRoles.rejected, (state) => {
        state.assignableRoles.error = true;
        state.assignableRoles.loading = false;
        state.assignableRoles.isLoaded = true;
      })
      //fetch avialable applications
      .addCase(fetchAvailableApplications.pending, (state) => {
        state.availableApplications.loading = true;
        state.availableApplications.error = false;
        state.availableApplications.isLoaded = false;
      })
      .addCase(fetchAvailableApplications.fulfilled, (state, action) => {
        state.availableApplications.data = action.payload.applications;
        state.availableApplications.totalCount = action.payload.totalCount;
        state.availableApplications.hasMore = action.payload.hasMore;
        state.availableApplications.offset = action.payload.offset;
        state.availableApplications.error = false;
        state.availableApplications.isLoaded = true;
        state.availableApplications.loading = false;
      })
      .addCase(fetchAvailableApplications.rejected, (state) => {
        state.availableApplications.error = true;
        state.availableApplications.loading = false;
        state.availableApplications.isLoaded = true;
      })
      //fetch entitlements of roles
      .addCase(fetchEntitlementsOfRole.pending, (state) => {
        state.roleEntitlements.loading = true;
        state.roleEntitlements.error = false;
      })
      .addCase(fetchEntitlementsOfRole.fulfilled, (state, action) => {
        const { payload } = action;
        state.roleEntitlements.entitlements[payload.roleId] =
          payload.entitlements;
        state.roleEntitlements.error = false;
        state.roleEntitlements.loading = false;
      })
      .addCase(fetchEntitlementsOfRole.rejected, (state) => {
        state.roleEntitlements.error = true;
        state.roleEntitlements.loading = false;
      })
      //fetch application details
      .addCase(fetchApplicationDetails.pending, (state) => {
        state.applicationDetails.loading = true;
        state.applicationDetails.error = false;
      })
      .addCase(fetchApplicationDetails.fulfilled, (state, action) => {
        const { payload } = action;
        state.applicationDetails.appData[payload.id] = payload;
        state.applicationDetails.error = false;
        state.applicationDetails.loading = false;
      })
      .addCase(fetchApplicationDetails.rejected, (state) => {
        state.applicationDetails.error = true;
        state.applicationDetails.loading = false;
      })
      .addCase(handleBulkImport.pending, (state) => {
        state.bulkImportState.status = "loading";
      })
      .addCase(handleBulkImport.fulfilled, (state, action) => {
        state.bulkImportState.status = "success";
        state.bulkImportState.importId = action.payload.importId;
      })
      .addCase(handleBulkImport.rejected, (state, action) => {
        state.bulkImportState.status = "error";
        state.bulkImportState.error = action.payload.message;
      })
      //fetching role for reference user
      .addCase(fetchAssignableRolesForreferenceUser.pending, (state) => {
        state.assignableRoles.data = [];
        state.assignableRoles.loading = true;
        state.assignableRoles.error = false;
        state.assignableRoles.isLoaded = false;
      })
      .addCase(
        fetchAssignableRolesForreferenceUser.fulfilled,
        (state, action) => {
          state.assignableRoles.data = action.payload.roles;
          state.assignableRoles.totalCount = action.payload.totalCount;
          state.assignableRoles.hasMore = action.payload.hasMore;
          state.assignableRoles.offset = action.payload.offset;
          // state.assignableRoles.data = action.payload.data;
          state.assignableRoles.error = false;
          state.assignableRoles.isLoaded = true;
          state.assignableRoles.loading = false;
        }
      )
      .addCase(fetchAssignableRolesForreferenceUser.rejected, (state) => {
        state.assignableRoles.error = true;
        state.assignableRoles.loading = false;
        state.assignableRoles.isLoaded = true;
      });
  },
});

export const {
  resetBulkImpState,
  resetAvailableApplications,
  resetAssignableRoles,
  setRequestId,
  resetRequestId,
  resendSucess,
  saveRoleData,
  storeSavedRoleRoleData,
  resultLoading,
  saveReferenceUserData,
} = newRequestSlice.actions;

export default newRequestSlice.reducer;
