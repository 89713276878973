import { useDispatch } from "react-redux";
import { FetchTaskAndWorkFlow } from "@actions/openTaskActions";
import moment from "moment";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, 0);
  const month = (date.getMonth() + 1).toString().padStart(2, 0);
  const year = date.getFullYear();

  return `${day} | ${month} | ${year}`;
};

export const formatDate2 = (date) => {
  return moment(date).format("DD MMMM YYYY");
};

export const FilterData = [
  { id: 1, value: "All", label: "all", icon: "" },
  {
    id: 1,
    value: "PENDING",
    label: "pending",
    icon: <TimerOutlinedIcon sx={{ fontSize: "18px" }} />,
    isAdmin: false,
  },
  {
    id: 2,
    value: "APROOVED",
    label: "approved",
    icon: <CheckCircleOutlinedIcon sx={{ fontSize: "18px" }} />,
    isAdmin: false,
  },
  {
    id: 3,
    value: "EXPIRED",
    label: "expired",
    icon: <CalendarMonthIcon sx={{ fontSize: "18px" }} />,
    isAdmin: false,
  },
  {
    id: 4,
    value: "FAILED",
    label: "failed",
    icon: <ErrorOutlineIcon sx={{ fontSize: "18px" }} />,
    isAdmin: false,
  },
  {
    id: 5,
    value: "REJECTED",
    label: "rejected",
    icon: <CancelOutlinedIcon sx={{ fontSize: "18px" }} />,
    isAdmin: false,
  },
];

export function useFetchWorkflow(
  userId,
  search = "",
  isAdmin = false,
  state = "PENDING"
) {
  const dispatch = useDispatch();
  dispatch(
    FetchTaskAndWorkFlow(userId, state, search, true, 0, 100, false, [], 100)
  );

  return true;
}

export const TaskTypes = {
  role: [
    "APPROVE_ROLE_ASSIGNMENT",
    "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT",
    "ROLE_APPROVAL_WORKFLOW", // as confirmed with J.K workflow tasks will display with role requests
  ],
  createUser: ["APPROVE_USER_CREATION"],
  deProvision: ["APPROVE_ROLE_DEPROVISIONING"],
  userOrg: ["APPROVE_USER_TO_ORGANIZATION"],
  orgAdmin: ["APPROVE_ORG_ADMIN_PERMISSION"],
  appRole: ["APPROVE_APPLICATIONROLE_TO_ROLE"],
  techUser: ["APPROVE_TECHUSER_TO_APPLICATION"],
};

export const getTaskType = (type, t) => {
  let taskType = t("menuBar.allTasks");
  switch (type) {
    case "APPROVE_ROLE_ASSIGNMENT":
      taskType = t("taskType.ROLE_ASSIGNMENT");
      break;
    case "APPROVE_USER_CREATION":
      taskType = t("taskType.APPROVE_USER_CREATION");
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      taskType = t("taskType.APPROVE_USER_TO_ORGANIZATION");
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      taskType = t("taskType.APPROVE_ROLE_DEPROVISIONING");
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      taskType = t("taskType.APPROVE_TECHUSER_TO_APPLICATION");
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
      taskType = t("taskType.APPLICATIONROLE_TO_ROLE");
      break;
    default:
      taskType = t("menuBar.allTasks");
      break;
  }

  return taskType;
};

export const getTaskDateInfoText = (task, t) => {
  const updatedDate = new Date(task.lastModifyDate) ?? new Date();
  const expiredDate = new Date(task.expiryDate) ?? new Date();
  const createdDate = new Date(task.creationDate) ?? new Date();
  const { state } = task;
  let status = "pending";
  let stateText = t("created");
  switch (state) {
    case "PENDING":
      stateText = `${t("created")} ${formatDate(createdDate)}`;
      status = "pending";
      break;
    case "COMPLETED":
      stateText = `${t("aprooved-date")} ${formatDate(updatedDate)}`;
      status = "approved";
      break;
    case "REJECTED":
      stateText = `${t("rejected-date")} ${formatDate(updatedDate)}`;
      status = "rejected";
      break;
    case "FAILED":
      stateText = `${t("failed-date")} ${formatDate(createdDate)}`;
      status = "failed";
      break;
    case "EXPIRED":
      stateText = `${t("expired-date")} ${formatDate(expiredDate)}`;
      status = "expired";
      break;
    default:
      stateText = `${t("created")} ${formatDate(createdDate)}`;
      status = "pending";
      break;
  }
  return { stateText, status };
};

export const stringToBoolean = (str) => {
  return str.toLowerCase() === "true" || str.toLowerCase() === "yes";
};
