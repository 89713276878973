import React from "react";

const NoTasksIcon = () => {
  return (
    <span>
      <svg
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        version="1.1"
        className="notasks-icon"
      >
        {/* <title>Task not found</title> */}
        <g
          id="Enhancements"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="455" transform="translate(-696.000000, -344.000000)">
            <g id="Group" transform="translate(550.500000, 344.000000)">
              <g id="TaskNotFound" transform="translate(145.500000, 0.000000)">
                <polygon id="Path" points="0 0 48 0 48 48 0 48"></polygon>
                <g
                  id="app-window-search-1"
                  transform="translate(4.000000, 4.000000)"
                >
                  <path
                    d="M3.7,35 C1.65924444,35 0,33.3183333 0,31.25 L0,3.75 C0,1.68166667 1.65924444,0 3.7,0 L33.3,0 C35.3407556,0 37,1.68166667 37,3.75 L37,13.75 C37,14.44 36.4474667,15 35.7666667,15 C35.0858667,15 34.5333333,14.44 34.5333333,13.75 L34.5333333,10 L2.46666667,10 L2.46666667,31.25 C2.46666667,31.94 3.0192,32.5 3.7,32.5 L13.5666667,32.5 C14.2474667,32.5 14.8,33.06 14.8,33.75 C14.8,34.44 14.2474667,35 13.5666667,35 L3.7,35 Z M34.5333333,7.5 L34.5333333,3.75 C34.5333333,3.06 33.9808,2.5 33.3,2.5 L3.7,2.5 C3.0192,2.5 2.46666667,3.06 2.46666667,3.75 L2.46666667,7.5 L34.5333333,7.5 Z"
                    id="Shape"
                    fill="#969696"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M38.7368421,40 C38.4,40 38.0816842,39.8686316 37.8442105,39.6294737 L32.2964211,34.0833684 C30.5263158,35.4593684 28.3503158,36.2105263 26.1052632,36.2105263 C20.5338947,36.2105263 16,31.6766316 16,26.1052632 C16,20.5338947 20.5338947,16 26.1052632,16 C31.6766316,16 36.2105263,20.5338947 36.2105263,26.1052632 C36.2105263,28.352 35.4593684,30.528 34.0833684,32.2981053 L39.6311579,37.8442105 C40.1229474,38.336 40.1229474,39.1376842 39.6311579,39.6311579 C39.392,39.8686316 39.0736842,40 38.7368421,40 Z M26.1052632,18.5263158 C21.9267368,18.5263158 18.5263158,21.9267368 18.5263158,26.1052632 C18.5263158,30.2837895 21.9267368,33.6842105 26.1052632,33.6842105 C30.2837895,33.6842105 33.6842105,30.2837895 33.6842105,26.1052632 C33.6842105,21.9267368 30.2837895,18.5263158 26.1052632,18.5263158 Z"
                    id="Shape"
                    fill="#969696"
                    fill-rule="nonzero"
                  ></path>
                  <line
                    x1="24"
                    y1="24"
                    x2="29"
                    y2="29"
                    id="Path"
                    stroke="#969696"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <line
                    x1="24"
                    y1="29"
                    x2="29"
                    y2="24"
                    id="Path"
                    stroke="#969696"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default NoTasksIcon;
