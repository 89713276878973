import * as React from "react";
import { FilterChip } from "@src/ComponentLibrary";
import { Box, Typography } from "@mui/material";
//for single select give single value in filter
//handle filter change (value) => setFilter([value])
//for multi select give multi values in filter
//handle filter change (value) => setFilter([..filter,value])
const CommonFilters = ({
  label = "",
  filter = ["all"],
  handleFilter,
  filters = [],
  sx,
}) => {
  return (
    <Box sx={{ ...sx, display: "flex", gap: "8px", flexDirection: "column" }}>
      <Typography variant="body2" sx={{ color: "#00000099" }}>
        {label}
      </Typography>
      <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
        {filters.map((ele) => (
          <FilterChip
            key={ele.value}
            label={ele.label}
            icon={ele.icon}
            isActive={filter.includes(ele.value)} // Check for active state
            onClick={() => handleFilter(ele.value)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CommonFilters;
