import React, { useState } from "react";
import MyDetailsView from "../TabComponents/myProfile";
import MyProfileEdit from "../TabComponents/myProfileEdit";

const MyProfileTab = () => {
  const [editMode, setEditMode] = useState(false);

  const editHandler = () => {
    setEditMode(!editMode);
  };

  return editMode ? (
    <MyProfileEdit editHandler={editHandler} />
  ) : (
    <MyDetailsView editHandler={editHandler} />
  );
};

export default MyProfileTab;
