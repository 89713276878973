import { Checkbox, Radio } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useRef } from 'react'

export const IndeterminateCheckboxOrRadiobutton = forwardRef((
  {
    indeterminate,
    showRadio,
    disabled,
    checked,
    onChange,
    style,
    title
  },
  ref
) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef
  const styleSx = {
    padding: 0,
    opacity: '1 !important'
  }

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [ resolvedRef, indeterminate ])

  return showRadio ? (
    // Show radiobutton (single select)
    <Radio
      ref={resolvedRef}
      size="small"
      sx={styleSx}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      style={style}
      title={title}
    />
  ) : (
    <Checkbox
      ref={resolvedRef}
      indeterminate={indeterminate}
      size="small"
      sx={styleSx}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      style={style}
      title={title}
    />
  )
})

IndeterminateCheckboxOrRadiobutton.propTypes = {
  indeterminate: PropTypes.bool,
  showRadio: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string
}
