import { styled } from "@mui/system";

export const OrgCardContainer = styled("div")({
  display: "flex",
  padding: "4px 4px 4px 8px",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
  borderRadius: "4px",
  border: "1px solid rgba(0, 0, 0, 0.50)",
  background: "#FFF",
  cursor: "pointer",
  width: "100%",
  marginTop: "8px",
  "& .text-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "2px",
    background: "rgba(0, 120, 214, 0.10)",
    "& svg": {
      fontSize: "16px",
    },
  },
  "& .container-text": {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    marginLeft: "8px",
  },
  "& .icon-container": {
    display: "flex",
  },
});
