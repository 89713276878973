import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Card } from "@alice/component_library/";
import "./contactsupport.css";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PrimaryButton } from "@components/button";

function InternalUserSupport({ checking }) {
  const { t } = useTranslation();

  const handlerInquiryHelpLink = () => {
    const url =
      "https://servicenow.i.mercedes-benz.com/esc?id=sc_cat_item&sys_id=74f00e051b8160502f8a3113dd4bcbce&sysparm_variables=eyJzZXJ2aWNlX29mZmVyaW5nX3NlbGVjdCI6ImM0M2UxNzEyZGIzYWUwNTA0ODlmMjk0NTA1OTYxOWQ4Iiwic2VsZWN0X3RlbXBsYXRlIjoiYjYxY2JiOTkxYmFmNDk1NGQ0ZTM5OGVjOGI0YmNiMTEiLCJzaG9ydF9kZXNjcmlwdGlvbiI6IkhlbHAgVGVtcGxhdGUgZm9yIEFsaWNlIFVJIiwiZGVzY3JpcHRpb24iOiJEZXNjcmliZSBIZWxwIHRvcGljOiJ9";
    window.open(url, "_blank");
  };

  const handlerIncidentLink = () => {
    const url =
      "https://servicenow.i.mercedes-benz.com/esc?id=sc_cat_item&sys_id=062eec1f1b0c605093b43113dd4bcbf0&sysparm_variables=eyJjaG9vc2VfaXNzdWVfcmVsYXRlZCI6ImFwcGxpY2F0aW9uIHNlcnZpY2UiLCJ1X3NlcnZpY2VfaW5zdGFuY2UiOiJlZDUxMjM5YjFiZmFhODE0NjZmNWRkYjY5YjRiY2IxYyIsInNlbGVjdF90ZW1wbGF0ZSI6ImM0Y2Y4NGQxMWJkYjQxOTAwNWM4Nzc3YjhiNGJjYjZiIiwic2hvcnRfZGVzY3JpcHRpb24iOiJBbGljZSBHZW5lcmFsIEluY2lkZW50IFRlbXBsYXRlIiwiZGVzY3JpcHRpb24iOiIjIyBEZXNjcmliZSB5b3VyIEFsaWNlIGlzc3VlICMjXHJcblxyXG5EZXNjcmlwdGlvbjpcclxuIiwiZGV0YWlsZWRfZGVzY3JpcHRpb24iOiIjIyBEZXNjcmliZSB5b3VyIEFsaWNlIGlzc3VlICMjXHJcblxyXG5EZXNjcmlwdGlvbjpcclxuIiwiaW1wYWN0IjoiMyIsInVyZ2VuY3kiOiIzIn0%3D";
    window.open(url, "_blank");
  };

  const handlerFeedbackLink = () => {
    const url =
      "https://servicenow.i.mercedes-benz.com/esc?id=sc_cat_item&sys_id=74f00e051b8160502f8a3113dd4bcbce&sysparm_variables=eyJzZXJ2aWNlX29mZmVyaW5nX3NlbGVjdCI6ImM0M2UxNzEyZGIzYWUwNTA0ODlmMjk0NTA1OTYxOWQ4Iiwic2VsZWN0X3RlbXBsYXRlIjoiNmIwYzIzOTAxYjljZDkxMGEwMTk5N2QxOWI0YmNiOTEiLCJzaG9ydF9kZXNjcmlwdGlvbiI6IlN1cHBvcnQgRmVlZGJhY2siLCJkZXNjcmlwdGlvbiI6IkRvIHlvdSBoYXZlIGNvbXBsYWludHMgb3IgcHJhaXNlIGZvciBvdXIgc3VwcG9ydD9cclxuU2VuZCB1cyB5b3VyIGNvbmNlcm5zIG9yIGZlZWRiYWNrIVxyXG5cclxuUHJhaXNlIFsgXVxyXG5Dcml0aWNpc20gWyBdXHJcblRpY2tldCByZWxhdGlvbiBbIE5yXVxyXG5cclxuRmVlZGJhY2s6XHJcblxyXG5cclxuXHJcblxyXG4ifQ%3D%3D";
    window.open(url, "_blank");
  };

  const getButtonLabel = (id, label) => (
    <>
      <span className="button-Text">{t(label)}</span>
      <ChevronRightIcon />
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <p className={checking ? "central-support" : "central-supports"}>
          {t("contact_page.alice-central-support")}
        </p>
        <p className="central-Subsupport">
          {t("contact_page.alice-central-title")}
        </p>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <p className="padding-20 flexDirection">
              <span className="circle">
                <InfoOutlinedIcon fontSize="large" />
              </span>

              <span className="contact-centerl mt-16 ">
                {t("contact_page.Inquiry-Help")}
              </span>
              <span
                className={"contact-centerl-2 mt-10"}
                style={{ minHeight: "100px" }}
              >
                {t("contact_page.inquiry-question")}
              </span>
              <span>
                <PrimaryButton
                  label={getButtonLabel("arrowIcon", "contact_page.get-help")}
                  onButtonClick={() => handlerInquiryHelpLink()}
                />
              </span>
            </p>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <p className="padding-20 flexDirection">
              <span className="circle">
                <WarningAmberOutlinedIcon fontSize="large" />
              </span>
              <span className="contact-centerl mt-16">
                {t("contact_page.Incident")}
              </span>
              <span
                className={"contact-centerl-2 mt-10"}
                style={{ minHeight: "100px" }}
              >
                {t("contact_page.incident-unplanned")}
              </span>
              <span>
                <PrimaryButton
                  label={getButtonLabel(
                    "arrowIcon",
                    "contact_page.report-incident"
                  )}
                  onButtonClick={() => handlerIncidentLink()}
                />
              </span>
            </p>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <p className="padding-20 flexDirection">
              <span className="circle">
                <AnnouncementOutlinedIcon fontSize="large" />
              </span>
              <span className="contact-centerl mt-16">
                {t("contact_page.feedback")}
              </span>
              <span
                className={"contact-centerl-2 mt-10"}
                style={{ minHeight: "100px" }}
              >
                {t("contact_page.complaints-feedback")}
              </span>

              <span>
                <PrimaryButton
                  label={getButtonLabel(
                    "arrowIcon",
                    "contact_page.give-feedback"
                  )}
                  onButtonClick={() => handlerFeedbackLink()}
                />
              </span>
            </p>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InternalUserSupport;
