import React from "react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { mergeSxProps, Skeleton } from "@alice/component_library";
import {
  DetailItemType,
  DetailsList,
  GenericCard,
  useDetailItem,
} from "@alice/component_library/src/ui/card/genericCard";
import { Header } from "./styledComponents";

export const CustomCardPlaceholder = ({ variant = "grid" }) => (
  <Stack direction={variant === "grid" ? "column" : "row"} gap={2}>
    <Skeleton
      variant="rectangular"
      sx={{
        flexShrink: 0,
        height: variant !== "tiny" ? "144px" : "72px",
        aspectRatio: variant !== "grid" ? "16 / 9" : "unset",
      }}
    />

    <Stack
      direction="column"
      gap={1}
      sx={{ width: variant === "list" ? "30%" : "70%" }}
    >
      <Skeleton variant="rectangular" width="85%" height="2rem" /> {/* ID */}
      <Skeleton
        variant="rectangular"
        width="100%"
        height="typography.p.fontSize"
      />{" "}
      {/* Name */}
    </Stack>

    {variant === "list" && (
      <Stack direction="column" gap={2} sx={{ width: "30%" }}>
        <Stack gap={1}>
          <Skeleton variant="rectangular" width="85%" height="2rem" />{" "}
          {/* Info Title */}
          <Skeleton
            variant="rectangular"
            width="100%"
            height="typography.p.fontSize"
          />{" "}
          {/* Info Name */}
        </Stack>

        <Stack gap={1}>
          <Skeleton variant="rectangular" width="85%" height="2rem" />{" "}
          {/* Info Title */}
          <Skeleton
            variant="rectangular"
            width="100%"
            height="typography.p.fontSize"
          />{" "}
          {/* Info Name */}
        </Stack>
      </Stack>
    )}
  </Stack>
);

export const CustomOrgCard = ({
  data,
  variant = "grid",
  size = "m", // sizeVariant
  isSelected = false,
  isLoading = false,
  onCardClick: _onCardClick, // ({ id, isSelected, data, e }) => {}
  sx = {} || [],
  ...otherProps
}) => {
  const { getDetailItem } = useDetailItem();

  const { t } = useTranslation("");

  const { id, name, detail } = data;

  const isList = variant === "list";
  const isGrid = variant === "grid";

  const datailListdata = [
    getDetailItem({
      type: DetailItemType.DESCRIPTION,
      text: detail,
      lineClamp: 2,
      icon: DescriptionOutlinedIcon,
    }),
  ];

  const onCardClick = _onCardClick
    ? ({ name, isSelected, e }) => {
        _onCardClick({
          id,
          isSelected,
          data,
          e,
        });
      }
    : undefined;

  return (
    <GenericCard
      testId={`Customa-card-${id}`}
      id={id}
      variant={variant}
      onCardClick={onCardClick}
      isSelected={isSelected}
      isLoading={isLoading}
      contentSx={mergeSxProps(
        [
          {
            gridArea: "content",
            display: "grid",
            gap: 2,
          },
          isGrid && { gridTemplate: "'header' 'content' / 1fr auto" },
          isList && { gridTemplate: "'header content' / 1fr auto " },
        ],
        sx
      )}
      sx={{ display: "flex" }}
      {...otherProps}
    >
      {isLoading ? (
        <CustomCardPlaceholder variant={variant} />
      ) : (
        <>
          <Box sx={{ gridArea: "header" }}>
            <Header>{name}</Header>
          </Box>
          <Stack sx={{ gridArea: "content" }} gap={2}>
            <DetailsList data={datailListdata} />
          </Stack>
        </>
      )}
    </GenericCard>
  );
};
