export const tableData = (data = []) => {
  const tableData = [];
  data.forEach((d) => {
    let temp = [];
    temp.push(d.entitlementId);
    temp.push(d.applicationName);
    temp.push(d.description ?? "");
    tableData.push(temp);
  });
  return tableData;
};
