import { Stack } from '@mui/material'
import React from 'react'
import { HttpCode } from '../../../data'
import { InternalServerError, NotAuthorized, NotFound, UnknownError } from '../../message'

const httpErrorComponent = {
  [HttpCode.UNAUTHORIZED]: <NotAuthorized />,
  [HttpCode.FORBIDDEN]: <NotAuthorized />,
  [HttpCode.NOT_FOUND]: <NotFound />,
  [HttpCode.INTERNAL_SERVER_ERROR]: <InternalServerError />,
  unknown: <UnknownError />
}

export const ErrorStatusBoundary = ({
  isError,
  status,
  fallback,
  sx,
  children
}) => (
  isError
    ? (
      <Stack data-testid="ErrorStatusBoundary" height="100%" justifyContent="center" alignItems="center" sx={sx}>
        {httpErrorComponent[status] || fallback || httpErrorComponent.unknown}
      </Stack>
    )
    : children
)
