import React from "react";
import "../Icon.css";
import "../App.css";
import { useTranslation } from "react-i18next";
import NoAppsIcon from "../pages/icons/profile/noAvailableApps";

const NoRolesPage = (props) => {
  const {
    selfRequestable,
    isExpired,
    appl,
    isFiltered,
    isFromAccessReview = false,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="textcenter-search textError">
        {appl === true ? (
          <NoAppsIcon />
        ) : (
          <span className="no-roles-found"></span>
        )}
      </div>
      <div className="textcenter-search">
        {appl === true ? (
          <h3 className="noSearchText">{t("no-applications_available")}</h3>
        ) : (
          <>
            {isFromAccessReview ? (
              <h3 className="noSearchText">{t("no-role-reviews-available")}</h3>
            ) : (
              <h3 className="noSearchText">{t("no-roles_avilable")} </h3>
            )}
          </>
        )}
        {!isFromAccessReview ? (
          <span className="noSearchSubText">
            {appl === true
              ? t("no-applications-message")
              : selfRequestable
              ? isExpired
                ? t("no-roles-message")
                : t("donot-have access-to roles")
              : isFiltered
              ? t("no-filtered-roles-message")
              : t("this-user-does-not-have-access-to-any-roles-currently")}
          </span>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default NoRolesPage;
