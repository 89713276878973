import { ArrowDownward } from '@mui/icons-material'
import { Box, Icon, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { getContrastColor } from '../../util'
import { Layout } from '../layout'

export const PageBanner = ({
  backgroundColor = 'blue.30',
  icon: IconComponent = ArrowDownward,
  text = 'Missing prop text',
  title = 'Missing prop title',
  disableLayout = false,
  intro
}) => {
  const textColor = getContrastColor(backgroundColor)
  const iconSize = '3.5rem !important'
  const iconHeight = '4rem'

  const content = (
    <Stack
      direction="row"
      sx={{ justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Stack spacing={1}>
        {intro && (
          <Typography
            sx={{
              fontSize: 16, color: textColor, fontWeight: 'bold'
            }}
          >
            {intro}
          </Typography>
        )}

        <Typography component="h1" sx={{ fontSize: 42, color: textColor }}>{title}</Typography>
        <Typography sx={{ color: textColor }}>{text}</Typography>
      </Stack>

      <Icon
        sx={{
          color: textColor,
          fontSize: iconSize,
          height: iconHeight,
          '> *': { fontSize: iconSize }
        }}
      >
        <IconComponent />
      </Icon>
    </Stack>
  )

  return (
    <Box data-testid="PageBanner" sx={{ backgroundColor, p: 3 }}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {disableLayout ? <>{content}</> : <Layout>{content}</Layout>}
    </Box>
  )
}

PageBanner.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.object,
  intro: PropTypes.string,
  backgroundColor: PropTypes.string,
  disableLayout: PropTypes.bool
}
