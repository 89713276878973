import React, { useState } from "react";
import { DataClassificationChip, Tooltip } from "@alice/component_library";
import RoleTypeChip from "@components/RoleCardV3/RoleTypeChip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DetailDialogue from "./detailPopup";
import {
  EditScreenHeaderWrapper,
  IdContainer,
} from "@pages/newRequest/styledComponents";

const EditHandlerHeader = ({ headerData }) => {
  const {
    id,
    data: { roleDefinition = {} },
  } = headerData;
  const [open, setOpen] = useState(false);

  const OpenDialogue = () => {
    setOpen(true);
  };

  const closeDialogue = () => {
    setOpen(false);
  };

  return (
    <EditScreenHeaderWrapper>
      <div className="header-container">
        <div className="role-type">
          <RoleTypeChip type={roleDefinition?.roleType} />
          {roleDefinition?.isJobTitle && <RoleTypeChip type="JOBTITLE" />}
        </div>
        <DataClassificationChip
          size="s"
          type={roleDefinition?.dataClassification?.toLowerCase()}
          variant="default"
        />
      </div>
      <div className="header-details">
        <div className="id-info">
          <div className="id-container">
            <IdContainer>
              <span>ID</span>
            </IdContainer>
            <Tooltip title={id} type="ellipsized">
              <span className="role-id">{id}</span>
            </Tooltip>
          </div>
          <InfoOutlinedIcon onClick={() => OpenDialogue()} />
        </div>
        <Tooltip title={roleDefinition?.name} type="ellipsized">
          <div className="role-name">{roleDefinition?.name}</div>
        </Tooltip>
      </div>
      <DetailDialogue
        isOpen={open}
        closeHandler={() => closeDialogue()}
        role={headerData}
      />
    </EditScreenHeaderWrapper>
  );
};

export default EditHandlerHeader;
