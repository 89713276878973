import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

/**
 * Wrapper for [Mui TextField](https://mui.com/material-ui/api/text-field/)
 */
export const Input = (
  {
    className = '',
    variant = 'filled',
    sx = {},
    ...other
  }
) => (
  <TextField
    className={`Input ${className}`}
    variant={variant}
    sx={[
      {
        '.MuiInputBase-root': {
          display: 'inline-flex',
          gap: 1
        },
        '.MuiFilledInput-root': {
          overflow: 'hidden',
          '&, &::before': { borderRadius: '2px' }
        }
      },
      ...(Array.isArray(sx) ? sx : [ sx ])
    ]}
    {...other}
  />
)

Input.propTypes = {
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  /** The default value. Use when the component is not controlled. */
  defaultValue: PropTypes.any,
  /** If `true`, the component is disabled. */
  disabled: PropTypes.bool,
  /** If `true`, the label is displayed in an error state. */
  error: PropTypes.bool,
  /** If `true`, the input will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** The helper text content. */
  helperText: PropTypes.node,
  /** The label content. */
  label: PropTypes.node,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.<br>
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /** If `true`, the label is displayed as required and the `input` element is required. */
  required: PropTypes.bool,
  /** The system prop that allows defining system overrides as well as additional CSS styles. */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([ PropTypes.func, PropTypes.object, PropTypes.bool ])
    ),
    PropTypes.func,
    PropTypes.object
  ]),
  /** The value of the `input` element, required for a controlled component. */
  value: PropTypes.any,
  /** The variant to use. */
  variant: PropTypes.oneOf([ 'filled', 'outlined', 'standard' ])
}
