import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const genericSx = { fontSize: '42px' }

export const ConfirmDialogSeverityIcon = forwardRef((
  {
    severity,
    ...otherProps
  },
  ref
) => {
  const getIcon = () => {
    switch (severity) {
      case 'success':
        return <TaskAltOutlinedIcon sx={{ color: 'success.main', ...genericSx }} />
      case 'warning':
        return <WarningAmberIcon sx={{ color: 'warning.main', ...genericSx }} />
      case 'error':
        return <ErrorOutlineOutlinedIcon sx={{ color: 'error.main', ...genericSx }} />
      case 'info':
      default:
        return <InfoOutlinedIcon sx={{ color: 'info.main', ...genericSx }} />
    }
  }

  return (
    <Box
      data-testid="ConfirmDialogSeverityIcon"
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...otherProps}
    >
      {getIcon()}
    </Box>
  )
})

ConfirmDialogSeverityIcon.propTypes = { severity: PropTypes.oneOf([ 'success', 'info', 'warning', 'error' ]) }
