import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  useNotification,
  useUser,
  usePermission,
} from "@alice/component_library";
import { SecondaryButton, PrimaryButton } from "@components/button";
import { addReviewDecision, bulkProcessReviews, managePopup } from "@actions";
import { hasData } from "@utils/Validator";
import { isSuperAdmin } from "@utils";
import { DECISION_CONSTANT } from "../utils";
import ReviewPopup from "./ReviewPopup";
import { Remove_reason } from "@constants/Dropdowns";
import { formatDate } from "../utils";

const { MODIFIED, DELETED, CONFIRMED } = DECISION_CONSTANT;

const CustomPopUps = forwardRef(
  ({ workflowId, t, search = "", sortOrder, tableRef, resetHandler }, ref) => {
    const {
      open,
      type,
      bulkProcessing = false,
      defaultValidityDays = 180,
      defaultValidityType = {},
      selectedId = [],
      countInfo = 0,
    } = useSelector((state) => state?.accessReview?.roleReviewHandlePop);

    const { user } = useUser();
    const { adminRoles: adminRolesSet } = usePermission();
    const adminRoles = adminRolesSet.values() || [...adminRolesSet];
    const { id: userId } = user;

    const dispatch = useDispatch();
    const { notify } = useNotification();
    const data = selectedId.length === 1 ? selectedId[0] : {};

    const handleClose = () => {
      setReason("");
      setComment("");
      setValidFrom();
      setValidTo();
      dispatch(
        managePopup({
          open: false,
          type: "",
          bulkProcessing: false,
          defaultValidityDays: 180,
          defaultValidityType: "",
          selectedId: [],
        })
      );
    };
    const [comment, setComment] = useState("");

    const [reason, setReason] = useState("");

    const [validFrom, setValidFrom] = useState(new Date());
    const [validTo, setValidTo] = useState(new Date());

    const oldValidFrom = data?.roleToUser?.validFrom;
    const oldValidTo = data?.roleToUser?.validTo;

    useEffect(() => {
      if (type === DECISION_CONSTANT.MODIFIED) {
        const currentDate = new Date();
        const newDate = currentDate.getDate() + defaultValidityDays;
        const calculatedDate = currentDate.setDate(newDate);
        if (
          defaultValidityType === "FIXED" ||
          (oldValidTo && new Date(oldValidTo).getTime() <= new Date().getTime())
        ) {
          setValidFrom(new Date());
          setValidTo(new Date(calculatedDate));
        } else {
          setValidFrom(oldValidFrom ? new Date(oldValidFrom) : new Date());
          setValidTo(
            oldValidTo ? new Date(oldValidTo) : new Date(calculatedDate)
          );
        }
      }
    }, [
      oldValidFrom,
      oldValidTo,
      defaultValidityType,
      defaultValidityDays,
      type,
      data,
    ]);

    const handleSubmit = async () => {
      const wfId = workflowId;
      const uuid = data?.uuid;
      let decisionData = {
        reason:
          type === DELETED
            ? reason === "other"
              ? comment.trim()
              : reason
            : comment.trim(),
        decision: type,
      };
      if (type === MODIFIED) {
        if (
          oldValidFrom
          // new Date(oldValidFrom).getTime() !== new Date(validFrom).getTime()
        ) {
          decisionData.validFrom = formatDate(validFrom);
        }
        if (
          oldValidTo
          // new Date(oldValidTo).getTime() !== new Date(validTo).getTime()
        ) {
          decisionData.validTo = formatDate(validTo);
        }
      }
      const result = await dispatch(
        addReviewDecision(
          hasData(adminRoles) &&
            isSuperAdmin(adminRoles, "accessReviewAdminMode")
            ? ""
            : userId,
          wfId,
          uuid,
          decisionData,
          search,
          sortOrder
        )
      );
      if (result === "success") {
        notify({
          severity: "success",
          message: t(
            type === DECISION_CONSTANT.CONFIRMED
              ? "assignment-confirm-success"
              : type === DECISION_CONSTANT.DELETED
              ? "assignment-remove-success"
              : "assignment-modification-success"
          ),
        });
        tableRef.current.reload();
      } else {
        notify({
          severity: "error",
          message: t(
            type === DECISION_CONSTANT.CONFIRMED
              ? "assignment-confirm-failure"
              : type === DECISION_CONSTANT.DELETED
              ? "assignment-remove-failure"
              : "assignment-modification-failure",
            {
              error: result,
            }
          ),
        });
      }
      handleClose();
    };

    const handleBulkProcess = async () => {
      if (bulkProcessing) {
        const decisionData = {
          reason:
            type === DECISION_CONSTANT.DELETED
              ? reason === "other"
                ? comment.trim()
                : reason
              : comment.trim(),
          decision: type,
        };
        let result = "";
        if (!selectedId.length) {
          result = await dispatch(
            bulkProcessReviews(
              hasData(adminRoles) &&
                isSuperAdmin(adminRoles, "accessReviewAdminMode")
                ? ""
                : userId,
              workflowId,
              decisionData,
              search,
              sortOrder
            )
          );
        } else if (selectedId.length) {
          result = await dispatch(
            addReviewDecision(
              hasData(adminRoles) &&
                isSuperAdmin(adminRoles, "accessReviewAdminMode")
                ? ""
                : userId,
              workflowId,
              "",
              decisionData,
              search,
              sortOrder,
              true,
              selectedId
            )
          );

          const { errors, responses } = result;
          if (responses.length) {
            notify({
              severity: "success",
              message: t(
                type === DECISION_CONSTANT.CONFIRMED
                  ? "assignment-confirm-success"
                  : type === DECISION_CONSTANT.DELETED
                  ? "assignment-remove-success"
                  : "assignment-modification-success"
              ),
            });
            resetHandler();
            tableRef.current.reload();
          }

          if (errors.length) {
            notify({
              severity: "error",
              message: t(
                type === DECISION_CONSTANT.CONFIRMED
                  ? "assignment-confirm-failure"
                  : type === DECISION_CONSTANT.DELETED
                  ? "assignment-remove-failure"
                  : "assignment-modification-failure",
                {
                  error: result,
                }
              ),
            });
          }
        }
        handleClose();
      }
    };

    const menuItems = Remove_reason.map((reason) => ({
      label: t(reason.value),
      value: reason.key,
    }));

    menuItems.push({ label: t("removal-reason.other"), value: "other" });
    let primaryButtonLabel = "";
    let heading = "";
    let subHeader = "";
    let disabled = true;

    switch (type) {
      case MODIFIED:
        primaryButtonLabel = t("modify-assignment");
        heading = t("modify-role-assignment");
        subHeader = t("modification-process-subheader");
        if (comment && comment?.trim().length > 19) disabled = false;
        else disabled = true;
        break;
      case CONFIRMED:
        if (bulkProcessing) {
          primaryButtonLabel = t("confirm-count-assignments", {
            count: countInfo,
          });
          subHeader = t("selected-process-confirm-count-subheader", {
            count: countInfo,
          });
          heading = t("confirm-role-assignments");
        } else {
          primaryButtonLabel =
            selectedId.length === 1
              ? t("confirm-assignment")
              : t("confirm-count-assignments", { count: selectedId.length });
          subHeader =
            selectedId.length === 1
              ? t("confirmation-process-subheader")
              : t("selected-process-confirm-subheader");
          heading = t("confirm-role-assignment");
        }
        if (comment) {
          if (comment?.trim()?.length > 19) disabled = false;
          else disabled = true;
        } else disabled = false;
        break;
      case DELETED:
        if (bulkProcessing) {
          primaryButtonLabel = t("remove-count-assignments", {
            count: countInfo,
          });
          subHeader = t("selected-process-remove-count-subheader", {
            count: countInfo,
          });
          heading = t("remove-role-assignments");
        } else {
          primaryButtonLabel =
            selectedId.length === 1
              ? t("remove-assignment")
              : t("remove-count-assignments", { count: selectedId.length });
          subHeader = t("removal-process-subheader");
          heading = t("remove-role-assignment");
        }

        if (!reason) disabled = true;
        else if (reason === "other") {
          if (comment && comment?.trim().length > 19) disabled = false;
          else disabled = true;
        } else disabled = false;
        break;

      default:
        break;
    }
    const handleDisabled = () => {
      const oldValidFrom = data?.roleToUser?.validFrom ?? null;
      const oldValidTo = data?.roleToUser?.validTo ?? null;
      return type === DECISION_CONSTANT.MODIFIED
        ? disabled ||
            // new Date(validFrom) <= new Date() ||
            new Date(validTo) <= new Date() ||
            new Date(validTo).getTime() <= new Date(validFrom).getTime() ||
            (oldValidTo &&
              new Date(oldValidTo).getTime() === new Date(validTo).getTime() &&
              oldValidFrom &&
              new Date(oldValidFrom).getTime() ===
                new Date(validFrom).getTime())
        : disabled;
    };

    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          headerSection={heading}
          noScroll={true}
          footerSection={
            <>
              <SecondaryButton
                onButtonClick={handleClose}
                label={t("dismiss")}
              />
              <PrimaryButton
                label={primaryButtonLabel}
                onButtonClick={() =>
                  bulkProcessing ? handleBulkProcess() : handleSubmit()
                }
                disabled={handleDisabled()}
              />
            </>
          }
        >
          <ReviewPopup
            data={{
              state: type,
              assignments: selectedId,
              defaultValidityDays: defaultValidityDays,
              defaultValidityType: defaultValidityType,
            }}
            comment={comment}
            setComment={setComment}
            reason={reason}
            setReason={setReason}
            subHeader={subHeader}
            menuItems={menuItems}
            reviewType={type}
            t={t}
            validFrom={validFrom}
            validTo={validTo}
            setValidFrom={setValidFrom}
            setValidTo={setValidTo}
          />
        </Dialog>
      </>
    );
  }
);

export default CustomPopUps;
