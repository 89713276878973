import { Box } from '@mui/material'
import { mergeSxProps } from '../../../../util'

export const CardContent = ({
  context,
  isList = false,
  isContextShown = false,
  isDividedHorizontal = isList,
  children,
  sx = {} || [],
  ...otherProps
}) => (
  <Box
    className="content"
    sx={mergeSxProps([
      {
        gridArea: 'content',
        display: 'grid',
        gap: 2
        // overflow: 'hidden'
      },
      isList && {
        alignItems: 'center',
        columnGap: 4
      }
    ], sx)}
    {...otherProps}
  >
    {children}

    {!isDividedHorizontal && isContextShown && (
      <Box className="contentContext" sx={{ gridArea: 'context' }}>
        {context}
      </Box>
    )}
  </Box>
)
