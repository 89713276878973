import { memo } from "react";
import { useTranslation } from "react-i18next";
import { AliceIcon, AliceIconType, Badge } from "@alice/component_library";
import { InputControl } from "@utils/inputControls";
import { getUUID } from "@src/utils";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  TaskMenuItemIcon,
  TaskMenuItems,
  TaskMenuItemsBadge,
  TaskMenuItemsHeader,
  TaskMenuCard,
  TaskMenuCardContent,
  TaskMenuCardTitle,
} from "./StyledComponents";

const Icon = ({ iconType, size = 26 }) => (
  <AliceIcon
    iconType={iconType}
    size={size}
    sx={{ pointerEvents: "none", cursor: "not-allowed" }}
    color="rgb(0, 120, 214)"
  />
);

const TaskMenu = memo(
  ({
    isCollapsed,
    handleCollapse,
    requestType,
    collapseState,
    menuHeader,
    setType,
    type = "allTasks",
    configMenuList = [
      {
        id: "allTasks",
        title: "menuBar.allTasks",
        badge: 0,
      },
    ],
    windowSize = { isDesktop: true, isMobile: false, isTablet: false },
  }) => {
    const { t } = useTranslation();
    const { isDesktop = true, isMobile = false, isTablet = false } = windowSize;
    return (
      <>
        {isDesktop && (
          <TaskMenuCard xs={isCollapsed ? 1 : 3}>
            <TaskMenuCardContent iscollapsed={isCollapsed}>
              {!isCollapsed && (
                <TaskMenuCardTitle>{menuHeader} </TaskMenuCardTitle>
              )}

              <KeyboardDoubleArrowLeftIcon
                onClick={() => {
                  collapseState(!isCollapsed);
                  handleCollapse(!isCollapsed);
                }}
                style={{
                  transform: isCollapsed ? "rotate(180deg)" : "",
                  cursor: "pointer",
                }}
              />
            </TaskMenuCardContent>
            {configMenuList.map((item, index) => (
              <TaskMenuItems
                className="task-menu-item"
                onClick={() => {
                  requestType(item.id);
                  setType(item.id);
                }}
                key={getUUID()}
                selected={type === item.id}
              >
                <TaskMenuItemIcon>
                  {typeof item?.icon?.$$typeof === "symbol" ? (
                    item.icon
                  ) : (
                    <Icon
                      iconType={
                        typeof item.icon === "string"
                          ? item.icon
                          : AliceIconType.ALL_TASKS
                      }
                    />
                  )}
                  <TaskMenuItemsBadge>
                    {item.badge > 0 && (
                      <Badge
                        filled={type === item.id}
                        style={{
                          backgroundColor:
                            type === item.id ? "#0078D6" : "#FFFFFF",
                        }}
                      >
                        {item.badge}
                      </Badge>
                    )}
                  </TaskMenuItemsBadge>
                </TaskMenuItemIcon>

                {!isCollapsed && (
                  <TaskMenuItemsHeader key={index}>
                    {t(item.title)}
                  </TaskMenuItemsHeader>
                )}
              </TaskMenuItems>
            ))}
          </TaskMenuCard>
        )}

        {(isTablet || isMobile) && (
          <InputControl
            type="select"
            name="filter-by-taskTypes"
            labelName={menuHeader}
            options={configMenuList.map((configMenu) => {
              return {
                value: configMenu.id,
                label: t(configMenu.title),
              };
            })}
            value={type}
            onChange={(e) => setType(e?.target?.value)}
          />
        )}
      </>
    );
  }
);

export default TaskMenu;
