import { profileActionTypes } from "../constants/actionTypes";
import axiosInstance from "@src/services/axiosInstance";
import { apiBaseUrl } from "../services/baseAPIService";
import urlConfig from "../services/urlConfig";
import { AdminRoles } from "./userActions";
import Communication from "@src/services/Communication";

const actions = {
  GetOrgColleagues: (payload) => ({
    type: profileActionTypes.GET_ORG_COLLEAGUES,
    data: payload,
  }),
  GetWorkPlaceColleagues: (payload) => ({
    type: profileActionTypes.GET_WORKPLACE_COLLEAGUES,
    data: payload,
  }),
  GetAdminInfo: (payload) => ({
    type: profileActionTypes.GET_ADMIN_INFO,
    data: payload,
  }),

  GetVisibleSupport: (payload) => ({
    type: profileActionTypes.GET_VISIBLE_SUPORT,
    data: payload,
  }),

  GetAdminDetails: (payload) => ({
    type: profileActionTypes.GET_MORE_ADMIN_INFO,
    data: payload,
  }),
};

export const GetAdminMoreInfo = (id) => {
  const apiBaseUrlFor = `${window.location.protocol}//${window.location.host}`;
  let url = `${apiBaseUrlFor}/${urlConfig.manageMyAdminRoles(id)}`;
  return function (dispatch) {
    dispatch(actions.GetAdminDetails({ loading: true }));
    return axiosInstance
      .get(url)
      .then((res) => {
        dispatch(actions.GetAdminDetails({ loading: false }));
        if (res.data) {
          dispatch(actions.GetAdminDetails({ loading: false, data: res.data }));
        }
      })
      .catch((e) => {
        actions.GetAdminDetails({ loading: false });
      });
  };
};

export const GetVisibleSupportStatus = () => {
  let url = `${apiBaseUrl}${urlConfig.myAdminRoles()}?withAllData=true`;
  return function (dispatch) {
    dispatch(actions.GetVisibleSupport({ loading: true, isLoaded: false }));
    return axiosInstance
      .get(url)
      .then((res) => {
        dispatch(actions.GetVisibleSupport({ loading: false }));
        if (res.data) {
          const { visibleAsSupportContact, adminRoles: detailedAdminRoles } =
            res.data;
          let adminRoles = {
            active: res?.data?.adminRoles.map((d) => d.adminRole),
            all: res.data?.adminRoles,
          };
          dispatch(
            GetVisibleSupports({
              visible: visibleAsSupportContact?.visible,
              adminRoles: detailedAdminRoles,
              loading: false,
              isLoaded: true,
            })
          );
          dispatch(AdminRoles(adminRoles));
        }
      })
      .catch((e) => {
        dispatch(actions.GetVisibleSupport({ loading: false, isLoaded: true }));
      });
  };
};

export const UpdateVisibleSupport = (t, id, notify) => {
  let url = `${apiBaseUrl}/gems/users/${id}/privileges/visibleassupportcontact`;
  return function (dispatch) {
    dispatch(actions.GetVisibleSupport({ loading: true }));
    return axiosInstance
      .put(url)
      .then((response) => {
        dispatch(
          actions.GetVisibleSupport({
            visible: response.data?.visible,
            loading: false,
          })
        );
        notify({
          severity: "success",
          message: t("visibility-enabled"),
        });
      })

      .catch((error) => {
        dispatch(actions.GetVisibleSupport({ visible: true, loading: false }));
        notify({
          severity: "error",
          message: t("admin-roles-failed"),
        });
      });
  };
};

export const RemoveVisibleSupports = (t, id, notify) => {
  let url = `${apiBaseUrl}/gems/users/${id}/privileges/visibleassupportcontact`;
  return function (dispatch) {
    dispatch(actions.GetVisibleSupport({ loading: true }));
    return axiosInstance
      .delete(url)
      .then((response) => {
        dispatch(actions.GetVisibleSupport({ visible: false, loading: false }));
        notify({
          severity: "error",
          message: t("visibility-disabled"),
        });
      })
      .catch((error) => {
        dispatch(actions.GetVisibleSupport({ visible: false, loading: false }));
        notify({
          severity: "error",
          message: t("admin-roles-failed"),
        });
      });
  };
};

/**Fetching all Users information */
export const GetOrganizationColleagues =
  (
    orgId,
    searchKey,
    offset = 0,
    limit = 12,
    page = 1,
    pageData,
    orgCollData = [],
    rowsPerPage
  ) =>
  (dispatch) => {
    try {
      dispatch(
        actions.GetOrgColleagues({
          data: {
            orgColleagueData: orgCollData,
            loading: true,
            error: false,
            pageData: {
              data: pageData,
              paginationTottalCount: 0,
              offset: offset,
              limit: limit,
              search: searchKey,
              rowsPerPage: 12,
            },
          },
        })
      );
      const query = searchKey?.length ? `'*${searchKey.trim()}*'` : "";
      const urlSearchParamsForOrgCollegueURL = new URLSearchParams();
      urlSearchParamsForOrgCollegueURL.set("offset", offset);
      urlSearchParamsForOrgCollegueURL.set("limit", limit);
      let url = `${apiBaseUrl}/gems/organizations/${orgId}/users`;
      if (query !== "") {
        url += `?offset=${offset}&limit=${limit}&filter=(id==${query},givenname==${query},surname==${query})`;
      } else {
        url += `?offset=${offset}&limit=${limit}`;
      }
      Communication.get(`${url}`).then((response) => {
        const { data } = response;
        return dispatch(
          actions.GetOrgColleagues({
            data: {
              orgColleagueData: [
                ...(orgCollData?.length ? orgCollData : []),
                ...data?.users,
              ],
              loading: false,
              error: false,
              pageData: {
                data: { ...pageData, [page]: data?.users },
                paginationTottalCount: data?.rels?.totalCount,
                offset: offset,
                limit: limit,
                search: "",
                rowsPerPage: rowsPerPage,
              },
            },
          })
        );
      });
    } catch (error) {
      return dispatch(
        actions.GetOrgColleagues({
          data: {
            loading: false,
            orgColleagueData: [],
            error: error?.response?.message,
            pageData: {
              data: [],
              paginationTottalCount: 0,
              search: "",
              rowsPerPage: 12,
            },
          },
        })
      );
    }
  };

export const GetWorkPlaceColleagues =
  (
    orgId,
    searchKey,
    offset = 0,
    limit = 12,
    page = 1,
    pageData,
    WorkCollData = [],
    rowsPerPage
  ) =>
  (dispatch) => {
    try {
      dispatch(
        actions.GetWorkPlaceColleagues({
          data: {
            workColleagueData: WorkCollData,
            loading: true,
            error: false,
            pageData: {
              data: pageData,
              paginationTottalCount: 0,
              offset: offset,
              limit: limit,
              search: searchKey,
              rowsPerPage: 12,
            },
          },
        })
      );
      const query = searchKey?.length ? `'*${searchKey.trim()}*'` : "";
      const urlSearchParamsForWorkCollegueURL = new URLSearchParams();
      urlSearchParamsForWorkCollegueURL.set("offset", offset);
      urlSearchParamsForWorkCollegueURL.set("limit", limit);
      let url = `${apiBaseUrl}/gems/organizations/${orgId}/users`;
      if (query !== "") {
        url += `?offset=${offset}&limit=${limit}&filter=(id==${query},givenname==${query},surname==${query})`;
      } else {
        url += `?offset=${offset}&limit=${limit}`;
      }
      Communication.get(`${url}`).then((response) => {
        const { data } = response;
        return dispatch(
          actions.GetWorkPlaceColleagues({
            data: {
              workColleagueData: [
                ...(WorkCollData?.length ? WorkCollData : []),
                ...data?.users,
              ],
              loading: false,
              error: false,
              pageData: {
                data: { ...pageData, [page]: data?.users },
                paginationTottalCount: data?.rels?.totalCount,
                offset: offset,
                limit: limit,
                search: "",
                rowsPerPage: rowsPerPage,
              },
            },
          })
        );
      });
    } catch (error) {
      return dispatch(
        actions.GetWorkPlaceColleagues({
          data: {
            loading: false,
            workColleagueData: [],
            error: error?.response?.message,
            pageData: {
              data: [],
              paginationTottalCount: 0,
              search: "",
              rowsPerPage: 12,
            },
          },
        })
      );
    }
  };

export const GetMyContactAdministrator = (userId) => {
  let Url = `${apiBaseUrl}/gems/users/${userId}/responsibleadministrators`;

  return function (dispatch) {
    dispatch(actions.GetAdminInfo({ loading: true, data: {} }));
    return axiosInstance
      .get(Url)
      .then((response) => {
        const { data = {} } = response;
        const orgAdmin = data.organizationAdministrators ?? [];

        const parentOrgAdminInfo = data.parentOrganizationAdministrators ?? [];
        const groupAdmin = data.groupAdministrators ?? [];
        const marketAdmin = data.marketAdministrators ?? [];
        const marketCBPAdmin = data.marketCBPAdministrators ?? [];
        const globalCommunity = data.globalCommunityAdministrators ?? [];
        const globalCBPAdmin = data.centralCBPAdministrators ?? [];

        const org = [...orgAdmin, ...parentOrgAdminInfo, ...groupAdmin].map(
          (d) => ({ ...d, id: d.userId })
        );
        const market = [...marketAdmin, ...marketCBPAdmin].map((d) => ({
          ...d,
          id: d.userId,
        }));
        const global = [...globalCommunity, ...globalCBPAdmin].map((d) => ({
          ...d,
          id: d.userId,
        }));
        dispatch(
          actions.GetAdminInfo({
            loading: false,
            data: { org, market, global },
          })
        );
      })
      .catch((error) => {
        dispatch(
          actions.GetAdminInfo({
            loading: false,
            data: {},
            error: true,
            err: error,
          })
        );
      });
  };
};

export const GetVisibleSupports = (data) => (dispatch) => {
  dispatch(actions.GetVisibleSupport(data));
};
