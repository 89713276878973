import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { getLink } from '../../../util'
import { CardContent, GenericCard, IdTitle } from '../../card/genericCard'
import { CountryFlag } from '../../icon'
import { Skeleton } from '../../skeleton'
import { Interaction, InteractionPropTypes } from '../../../constants'

const Placeholder = () => (
  <>
    <Skeleton
      data-testid="MarketCard-Placeholder"
      variant="rounded"
      sx={{
        gridArea: 'flag',
        height: '100%',
        aspectRatio: '48 / 32'
      }}
    >
      <CountryFlag
        countryCode="de"
        sx={{
          height: 40,
          width: 'unset',
          svg: { height: '100%' }
        }}
      />
    </Skeleton>

    <Box
      sx={{
        gridArea: 'code',
        color: 'primary.main',
        width: 40
      }}
    >
      <Skeleton
        variant="rectangular"
        width="20px"
        height="1.25rem"
      />
    </Box>

    <Skeleton
      variant="rectangular"
      sx={{
        gridArea: 'name',
        width: 300,
        height: '1.25rem'
      }}
    />
  </>
)

/**
 * A card that displays information about a market
 *
 * **Important Note**:
 *
 * It extends the functionality of the GenericCard component, inheriting all its supported properties.
 * For a detailed list of available properties, please consult the [GenericCard documentation](./?path=/docs/alice-ui-card-genericcard--docs).
 */
export const MarketCard = ({
  market = {},
  isLoading = false,
  isIdLink = true,
  isSelected = false,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const onCardClick = _onCardClick ? ({
    id,
    e,
    isSelected
  }) => {
    _onCardClick({
      id,
      e,
      isSelected,
      data: market
    })
  } : undefined

  return (
    <GenericCard
      id={market.country}
      data-testid={`MarketCard-${market.country}`}
      isList
      isTiny
      interaction={interaction}
      isSelected={isSelected}
      isLoading={isLoading}
      onCardClick={onCardClick}
      sx={sx}
      {...otherProps}
    >
      <CardContent
        isList
        sx={{
          gridTemplate: [ "'flag code' 'flag name' / auto 1fr", "'flag code name' / auto auto 1fr" ],
          gap: '0 16px'
        }}
      >
        {!isLoading
          ? (
            <>
              <CountryFlag
                countryCode={market.country}
                sx={{
                  gridArea: 'flag',
                  height: 40,
                  width: 'unset',
                  borderRadius: '4px',
                  'svg, img': { height: '100%' }
                }}
              />

              <IdTitle
                isLink={isIdLink}
                isIdChipHidden
                href={getLink('marketDetails', { marketId: market.country })}
                id={market.country}
                sx={{ gridArea: 'code' }}
              />

              <Typography
                component="span"
                variant="subtitle1"
                noWrap
                sx={{
                  gridArea: 'name',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {market.name}
              </Typography>
            </>
          )
          : <Placeholder />}
      </CardContent>
    </GenericCard>
  )
}

MarketCard.propTypes = {
  /** Market data to display within the card */
  market: PropTypes.shape({
    country: PropTypes.string.isRequired,
    name: PropTypes.string,
    preferredLanguage: PropTypes.string,
    maxTestUserAmount: PropTypes.number,
    maxMarketSupportContacts: PropTypes.number
  }),
  interaction: InteractionPropTypes,
  /** Defines whether the card is selected or not */
  isSelected: PropTypes.bool,
  /** Determines whether the card ID is associated with a link */
  isIdLink: PropTypes.bool,
  /** Defines whether the card is loading or not */
  isLoading: PropTypes.bool,
  /** Callback on card click */
  onCardClick: PropTypes.func
}
