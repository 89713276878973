import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { mergeSxProps } from '../../../util'
import { Button } from '../../button'
import { Card } from '../../card'
import { DropzoneFiles } from './DropzoneFiles'

/**
 * Dropzone component allows users to upload files by either dragging and dropping files onto the designated area or
 * by clicking to select files. This component offers flexibility by allowing customization of accepted file types,
 * multiple file uploads, and triggering events when files are added or removed.
 *
 * ### Note:
 * - All functionality for reading files & error handling should be provided in wrapper component.
 * - A list of possible common MIME types could be found [here](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
 * - A list of unique MIME type specifiers could be found [here](https://www.thoughtco.com/mime-types-by-content-type-3469108)
 *
 * [See more](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file) about the `<input type='file'>` element.
 *
 * ## How to use:
 *
 * - Functionality for reading files & error handling should be provided in wrapper component
 *
 * ### Example
 *
 * ```jsx
 * const WrapperDropzoneExample => {
 *       const [ files, setFiles ] = useState(null)
 *       const [ fileError, setFileError ] = useState(null)
 *
 *       const { notify } = useNotification()
 *
 *       const handleFileChosen = async (file) => new Promise((resolve, reject) => {
 *         const fileReader = new FileReader()
 *         fileReader.onload = () => {
 *           resolve(fileReader.result)
 *         }
 *
 *         fileReader.onerror = () => {
 *           notify({
 *             severity: 'error',
 *             message: 'Error occured'
 *           })
 *
 *           reject()
 *         }
 *         fileReader.readAsText(file)
 *       })
 *
 *       const readAllFiles = async (files) => {
 *         const results = await Promise.all(files.map(async (file) => {
 *           const fileContents = await handleFileChosen(file)
 *
 *           return { file, content: fileContents }
 *         }))
 *
 *         return results
 *       }
 *
 *       const handleFileChange = (files) => {
 *         if (files === null) {
 *           setFiles(null)
 *           setFileError(null)
 *
 *           return
 *         }
 *
 *         readAllFiles(files).then((res) => setFiles(res))
 *       }
 *
 *       return (
 *         <Dropzone files={files} onChange={handleFileChange} error={fileError}/>
 *       )
 *     }
 * ```
 */
export const Dropzone = ({
  files = [],
  error,
  fileTypes = [],
  uploadHint,
  isMultiple = false,
  sx,
  onChange = (files) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ isDragActive, setIsDragActive ] = useState(false)

  const acceptedFileTypes = fileTypes.length && fileTypes.join(',')

  const inputRef = useRef(null)

  const handleFileChange = (files) => {
    onChange(files)
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(Array.from(e.dataTransfer.files))
      // Setting files in input properly
      inputRef.current.files = e.dataTransfer.files
    }
  }

  const handleChange = (e) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      handleFileChange(Array.from(e.target.files))
    }
  }

  const handleDelete = (fileName) => {
    const attachments = inputRef.current.files
    // eslint-disable-next-line no-undef
    const filteredFileBuffer = new DataTransfer()

    // Iterating over added files
    for (let i = 0; i < attachments.length; i++) {
      // Excluding file in specified index
      if (fileName !== attachments[i].name) filteredFileBuffer.items.add(attachments[i])
    }

    // cleaning input properly after deletion
    inputRef.current.files = filteredFileBuffer.files

    // setting new array of files
    onChange(filteredFileBuffer.files.length ? Array.from(filteredFileBuffer.files) : null)
  }

  const handleClick = () => inputRef.current.click()

  return (
    <Card
      sx={mergeSxProps([
        isDragActive && {
          border: '1px dashed',
          borderColor: 'primary.main',
          backgroundColor: 'blue.95'
        }
      ], sx)}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '265px'
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          multiple={isMultiple}
          accept={fileTypes.length ? acceptedFileTypes : '*/*'}
          onChange={handleChange}
        />

        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}
        >
          {files && !isDragActive
            ? <DropzoneFiles files={files} error={error} onDelete={(fileName) => handleDelete(fileName)} />
            : isDragActive
              ? <Typography variant="h6" color="primary.main">{t('form.dropzone.dropHint')}</Typography>
              : (
                <>
                  <Typography>{uploadHint || t('form.dropzone.uploadHint')}</Typography>
                  <Button onClick={handleClick}>{t('form.dropzone.uploadButton')}</Button>
                </>
              )}
        </Stack>
      </Stack>
    </Card>
  )
}

Dropzone.propTypes = {
  /** An array of chosen files */
  files: PropTypes.oneOfType([ PropTypes.array, () => null ]),
  /** File error message to be displayed after upload */
  error: PropTypes.string,
  /** Determines file types that are allowed to be uploaded */
  fileTypes: PropTypes.array,
  /** Text displayed above browse button */
  uploadHint: PropTypes.string,
  /** Determines whether dropzone allow multiple files upload */
  isMultiple: PropTypes.bool,
  /** onChange event
   *
   * @param files
   * @returns {void}
   * */
  onChange: PropTypes.func.isRequired
}
