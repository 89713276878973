import {
  Typography,
  Grid,
  styled,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
export const PageHeaderContainer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  marginTop: "24px",
  flexDirection: "row",
  flexWrap: "wrap",
});

export const PageHeaderTypography = styled((props) => (
  <Typography variant="h1" {...props} />
))(() => ({
  fontFamily: "MB Corpo A Text Cond",
  color: "#000",
  marginBottom: "0px",
}));

export const PageSubheaderTypography = styled((props) => (
  <Typography variant="subtitle1" {...props} />
))(() => ({
  fontFamily: "MB Corpo S Text",
  lineHeight: "24px",
  letterSpacing: "0px",
  fontWeight: "400",
  fontSize: "16px",
  color: "rgb(51, 51, 51)",
  margin: "0px 0px 32px",
}));
// export const AdminSwitchContainer = styled("div")(({ ...props }) => ({
//   display: "flex",
//   alignItems: "center",
//   marginLeft: "auto",
//   marginBottom: "32px",
// }));

export const FiltersContainer = styled("div")(({ ...props }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: !props.isdesktop && "100%",
}));

export const SortFilterConatianer = styled("div")(({ ...props }) => {
  const { windowsize = {} } = props;
  const { isDesktop = true } = windowsize;
  return {
    display: "flex",
    gap: "24px 16px",
    flexWrap: "wrap",
    marginTop: isDesktop ? "24px" : "16px",
    marginBottom: !isDesktop && "16px",
    width: "100%",
    justifyContent: "space-between",
  };
});
export const SortContainer = styled("div")({
  width: "30%",
  display: "flex",
  flexDirection: "column",
  // marginLeft: "auto",
  "& .MuiSelect-icon": {
    right: "20px",
  },
  "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
    backgroundColor: "transparent !important",
  },
});

export const SortMobContainer = styled("div")({
  width: "100%",
});
export { MenuItem, Select, FormControl, Typography, Grid };
