import AliceIconPng from './alice-icon.png'
import AliceLogoPng from './alice-logo.png'
import AliceHeaderPng from './alice_header.png'
import BannerPng from './banner.png'
import MBLogoSvg from './mb-logo.svg'
import MBStarSvg from './mb_star.svg'

export {
  AliceHeaderPng,
  AliceLogoPng,
  AliceIconPng,
  BannerPng,
  MBLogoSvg,
  MBStarSvg
}
