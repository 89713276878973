import React from "react";
import {
  Stack,
  Skeleton,
  UserCardLoading,
  Grid,
  RoleCardV2Skeleton,
  StyledTaskDetail,
} from "./CommonSkeletons";

const TaskDeeplinkSkeleton = () => {
  return (
    <>
      <Stack direction="row" sx={{ paddingTop: { xs: "40px", lg: "0px" } }}>
        <Skeleton variant="text" width={50} /> /
        <Skeleton variant="text" width={50} /> /
        <Skeleton variant="text" width={50} />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
        sx={{ marginTop: "36px" }}
      >
        <Stack direction="row" gap="30px 16px" flexWrap="wrap">
          <Skeleton variant="rectangular" height={48} width={48} />
          <Stack direction="column">
            <Skeleton variant="text" height={48} width="200px" />
            <Skeleton variant="text" width="200px" />
          </Stack>
        </Stack>
        <Stack direction="row" gap="16px" flexWrap="wrap">
          <Skeleton variant="rectangular" height={32} width={100} />
          <Skeleton variant="rectangular" height={32} width={80} />
        </Stack>
      </Stack>
      <StyledTaskDetail>
        <div className="task-header">
          <Skeleton
            variant="rounded"
            width={100}
            sx={{ borderRadius: "100px" }}
          />
        </div>
        <div className="task-detail-header">
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="80%" />
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Skeleton variant="rectangular" width={100} height={30} />
            <Skeleton variant="rectangular" width={100} height={30} />
          </Stack>
        </div>
        <div className="task-detail-padding">
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width={100} sx={{ margin: "20px 0px" }} />
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <UserCardLoading />
            </Grid>
          </Grid>
          <Skeleton variant="text" width={100} sx={{ margin: "20px 0px" }} />
          <RoleCardV2Skeleton large={false} />
        </div>
      </StyledTaskDetail>
    </>
  );
};

export default TaskDeeplinkSkeleton;
