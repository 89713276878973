import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Switch,
  Link,
  useNotification,
} from "@alice/component_library";
import {
  Box,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import massImport from "@assets/documents/MassImport.pdf";
import sampleCSV from "@assets/documents/Example CSV.csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  StyledDialog,
  CloseButton,
  StyledDialogTitle,
  FileSelectContainer,
  FileSelectTexts,
  FileSelectTitle,
  FileSelectDescription,
  BulkImportOptions,
  ImportOptionsContainer,
  ImportOptionsTitle,
  HeadlineTypo,
  HeadlineSwitchContainer,
  SwitchTypo,
  FileEncodingContainer,
  FileEncodingTitle,
  FooterContainer,
  ButtonContainer,
  BulkImportFooter,
  FooterDescription,
  LoaderContainer,
  LoaderStatusContainer,
  StatusIconContatiner,
  StatusHeaderContatiner,
  HelpDocAndSampleFileContainer,
} from "../../styledComponents";

import Loader from "@components/Loader";
import BulkImpWrapper from "./BulkImpWrapper";
import BulkUploadFileBar from "@components/BulkUploadFileBar";
import { CheckCircle, Cancel, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { handleBulkImport } from "@sliceactions/newRequestActions";
import { resetBulkImpState } from "@slice/newRequestSlice";

const BulkImportDialouge = ({ dialogState, setDialogState, assign }) => {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const dispatch = useDispatch();
  const [header, setHeader] = useState(false);
  const [encoding, setEncoding] = useState("UTF-8");
  const { status, error, importId } = useSelector(
    (state) => state?.newRequestSlice?.bulkImportState
  );
  const [files, setFiles] = useState(null);
  const fileName = files?.file?.name;

  useEffect(() => {
    if (status === "success") {
      notify({
        severity: "success",
        message: t("file-imp-success"),
      });
    } else if (status === "error") {
      notify({
        severity: "error",
        message: t("file-not-imp"),
      });
    }
  }, [status, t]);

  const handleClose = () => {
    dispatch(resetBulkImpState());
    setFiles(null);
    setDialogState(false);
    setHeader(false);
    setEncoding("UTF-8");
  };

  const handleFile = () => {
    dispatch(resetBulkImpState());
    setFiles(null);
    setHeader(false);
    setEncoding("UTF-8");
  };

  const handleBulkImportClick = () => {
    if (files && files?.file) {
      dispatch(
        handleBulkImport({
          file: files?.file,
          headLine: header,
          encoding: encoding,
          assign: assign,
        })
      );
    }
  };

  const getLoading = () => (
    <>
      <LoaderContainer>
        <Box>
          <Loader inline minHeight="60px" />
        </Box>
        <LoaderStatusContainer>
          {t("importing-CSV-file")} {fileName}
        </LoaderStatusContainer>
      </LoaderContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {t("loader-wait")}
      </Box>
    </>
  );

  const getSuccess = () => (
    <LoaderContainer style={{ backgroundColor: "#e8f2e9" }}>
      <StatusIconContatiner>
        <span className="active-icon-task">
          <CheckCircle />
        </span>
      </StatusIconContatiner>
      <LoaderStatusContainer>
        <StatusHeaderContatiner>
          {t("successful-import")}
        </StatusHeaderContatiner>
        <Box sx={{ wordBreak: "break-word" }}>
          {t("success-import-p1")} {`"${fileName}"`} {t("success-import-p2")}
          <br />
          {t("success-import-p3")} <b>{importId ? importId : null}</b>.
        </Box>
      </LoaderStatusContainer>
    </LoaderContainer>
  );

  const getFail = () => (
    <LoaderContainer style={{ backgroundColor: "#fbe8e8" }}>
      <StatusIconContatiner>
        <span className="task-deactive-icon">
          <Cancel />
        </span>
      </StatusIconContatiner>
      <LoaderStatusContainer>
        <StatusHeaderContatiner>
          {t("error-import")} {fileName}
        </StatusHeaderContatiner>
        <Box style={{ wordBreak: "break-word" }}>{error}</Box>
      </LoaderStatusContainer>
    </LoaderContainer>
  );

  const getTemplate = () => {
    let template = "";
    switch (status) {
      case "loading":
        template = getLoading();
        break;
      case "success":
        template = getSuccess();
        break;
      case "error":
        template = getFail();
        break;
      default:
        return;
    }
    return template;
  };

  return (
    <>
      <StyledDialog open={dialogState}>
        <StyledDialogTitle>
          {t("bulk-import")}
          <CloseButton onClick={handleClose}>
            <Close />
          </CloseButton>
        </StyledDialogTitle>
        <DialogContent dividers={true} sx={{ padding: "0px" }}>
          {status ? (
            <Box sx={{ padding: "20px" }}>{getTemplate()}</Box>
          ) : (
            <>
              <FileSelectContainer>
                <FileSelectTexts>
                  <FileSelectTitle>{t("select-file")}</FileSelectTitle>
                  <FileSelectDescription>
                    {t("select-file-description")}
                  </FileSelectDescription>
                </FileSelectTexts>
                <Box>
                  <HelpDocAndSampleFileContainer>
                    <Link
                      isBlank
                      href={massImport}
                      isUsingRouterIfPossible={false}
                      className="helpDoc"
                    >
                      {t("help-document")}
                      <InfoOutlinedIcon />
                    </Link>
                    <Link
                      isBlank
                      href={sampleCSV}
                      download
                      isUsingRouterIfPossible={false}
                      className="sampleFile"
                    >
                      {t("download_CSV_example_file")}
                      <FileDownloadOutlinedIcon />
                    </Link>
                  </HelpDocAndSampleFileContainer>
                  {files !== null ? (
                    <BulkUploadFileBar files={files} handleFile={handleFile} />
                  ) : (
                    <BulkImpWrapper
                      t={t}
                      uploadHint={t("csv-file-or-browse")}
                      files={files}
                      setFiles={setFiles}
                    />
                  )}
                </Box>
              </FileSelectContainer>
              <BulkImportOptions files={files}>
                <ImportOptionsContainer>
                  <ImportOptionsTitle>
                    {t("add-import-options")}
                  </ImportOptionsTitle>
                  <HeadlineTypo>{t("headline")}</HeadlineTypo>
                  <HeadlineSwitchContainer>
                    <Switch
                      checked={header}
                      onChange={(e) => setHeader(e.target.checked)}
                    />
                    <SwitchTypo>{t("switch-description")}</SwitchTypo>
                  </HeadlineSwitchContainer>
                </ImportOptionsContainer>
                <FileEncodingContainer>
                  <FileEncodingTitle>
                    {t("source_file_encoding")}
                  </FileEncodingTitle>
                  <Box>
                    <FormControl className="radioGroup">
                      <RadioGroup
                        row
                        value={encoding}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px 16px",
                          rowGap: "6px",
                        }}
                      >
                        <FormControlLabel
                          value="UTF-8"
                          control={
                            <Radio
                              style={{ marginRight: "5px" }}
                              onChange={(e) => setEncoding(e.target.value)}
                            />
                          }
                          label="UTF-8"
                          sx={{
                            "& .MuiTypography-root": {
                              color: "rgba(0, 0, 0, 0.87)",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="Cp1252"
                          control={
                            <Radio
                              style={{ marginRight: "5px" }}
                              onChange={(e) => setEncoding(e.target.value)}
                            />
                          }
                          label={`CP1252 (${t("windows")})`}
                          sx={{
                            "& .MuiTypography-root": {
                              color: "rgba(0, 0, 0, 0.87)",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </FileEncodingContainer>
              </BulkImportOptions>{" "}
            </>
          )}
        </DialogContent>
        <FooterContainer>
          <BulkImportFooter>
            <FooterDescription>{t("attention_info")}</FooterDescription>
            <ButtonContainer>
              <Button
                size="medium"
                variant="secondary"
                sx={{ margin: "0px 8px" }}
                onClick={handleClose}
              >
                {t("cancel")}
              </Button>
              {status ? (
                <Button
                  size="medium"
                  variant="primary"
                  sx={{ textWrap: "nowrap" }}
                  onClick={() => handleFile()}
                >
                  {t("import-new-file")}
                </Button>
              ) : (
                <Button
                  disabled={!files?.file}
                  size="medium"
                  variant="primary"
                  sx={{ textWrap: "nowrap" }}
                  onClick={handleBulkImportClick}
                >
                  {t("assign_roles")}
                </Button>
              )}
            </ButtonContainer>
          </BulkImportFooter>
        </FooterContainer>
      </StyledDialog>
    </>
  );
};

export default BulkImportDialouge;
