import React from "react";
import { Divider } from "@mui/material";
import {
  Tooltip,
  UserAvatar,
  UserContextPopover,
} from "@alice/component_library";
import {
  Typography,
  Grid,
  Chip,
  CalendarMonthOutlinedIcon,
  CallSplitIcon,
  TextsmsOutlinedIcon,
  MoreDetailsContainer,
  HighlightOffOutlinedIcon,
  EditOutlinedIcon,
  KeyboardTabIcon,
  StartIcon,
  MoreDecisionMakerContainer,
  ModificationContainer,
} from "../styledComponents";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import { grey, red, green } from "@mercedes-benz/mui5-theme";
import {
  DECISION_CONSTANT,
  dateFullMonthFullYear,
  dateMonthFullYear,
  DATE_OPTIONS_LONG,
} from "../utils";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import DecisionCell from "./DecisionCell";
import { CustomContextPopover, ValidityChip } from "./TableData";

const MoreDetails = ({
  row,
  disabled = false,
  needsOrgScopes = true,
  needsCustomScopes = true,
  disableActions = false,
}) => {
  const { t } = useTranslation();
  const { decision, processingDate, reason, roleToUser, reviewer } = row;
  const {
    user = {},
    orgScope = {},
    customScope = {},
    validTo,
    validFrom,
    newValidFrom,
    newValidTo,
  } = roleToUser;
  const org = {
    icon: null,
    label: t("org-scope"),

    content: (
      <div className="org-custom-context">
        <TypographyData text={orgScope?.name} />
        <CustomContextPopover orgScope={orgScope} />
      </div>
    ),
  };

  const cust = {
    icon: null,
    label: t("custom-scope"),
    content: <TypographyData text={customScope?.name} />,
  };

  const moreDetailsinMobile = [
    {
      icon: null,
      label: t("id"),
      content: <TypographyData text={user?.id} />, //
    },
    {
      icon: null,
      label: t("fullName"),
      content: <UserData user={user} />,
    },

    {
      icon: null,
      label: t("valid_from"),
      content: validFrom ? (
        <ValidityChip
          remainingDaysToRed={0}
          size="s"
          formattedExpiryDate={new Date(validFrom).toLocaleDateString(
            undefined,
            DATE_OPTIONS_LONG
          )}
        />
      ) : (
        <TypographyData text="-" />
      ),
    },
    {
      icon: null,
      label: t("valid-to"),
      content: validTo ? (
        <ValidityChip
          remainingDaysToRed={0}
          size="s"
          formattedExpiryDate={new Date(validTo).toLocaleDateString(
            undefined,
            DATE_OPTIONS_LONG
          )}
        />
      ) : (
        <TypographyData text="-" />
      ),
    },
  ];
  if (needsCustomScopes && needsOrgScopes) {
    moreDetailsinMobile.splice(2, 0, org);
    moreDetailsinMobile.splice(3, 0, cust);
  } else if (needsOrgScopes) {
    moreDetailsinMobile.splice(2, 0, org);
  } else if (needsCustomScopes) {
    moreDetailsinMobile.splice(2, 0, cust);
  }
  const reviewDetails =
    decision === DECISION_CONSTANT.NONE
      ? moreDetailsinMobile
      : [
          {
            icon: <CallSplitIcon />,
            label: t("decision"),
            content: (
              <>
                <Chip
                  label={
                    decision === DECISION_CONSTANT.MODIFIED
                      ? t("modified")
                      : decision === DECISION_CONSTANT.DELETED ||
                        decision === DECISION_CONSTANT.DELETED_EXT
                      ? t("removed")
                      : t("confirmed")
                  }
                  variant="filled"
                  size="small"
                  icon={
                    decision === DECISION_CONSTANT.MODIFIED ? (
                      <EditOutlinedIcon />
                    ) : decision === DECISION_CONSTANT.DELETED ||
                      decision === DECISION_CONSTANT.DELETED_EXT ? (
                      <HighlightOffOutlinedIcon />
                    ) : (
                      <CheckCircleOutlineOutlined />
                    )
                  }
                  sx={({ palette }) => ({
                    gap: 1,
                    paddingInline: 1,
                    color: palette.text.primary,
                    backgroundColor:
                      decision === DECISION_CONSTANT.MODIFIED
                        ? grey[70]
                        : decision === DECISION_CONSTANT.DELETED ||
                          decision === DECISION_CONSTANT.DELETED_EXT
                        ? red[80]
                        : green[85],
                    ".MuiSvgIcon-root": { margin: 0, color: "inherit" },
                    ".MuiChip-label": {
                      padding: 0,
                      fontFamily: "MB Corpo S Text",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "20px",
                    },
                  })}
                />
              </>
            ), //
          },
          {
            icon: <EditOutlinedIcon />,
            label: t("modification"),
            content:
              decision === DECISION_CONSTANT.MODIFIED ? (
                <ModificationContainer>
                  <div>
                    <div className="date-container">
                      <StartIcon />
                      {t("valid_from")}
                    </div>
                    {newValidFrom && newValidFrom !== validFrom ? (
                      <>
                        <span className="current-assigned-date">
                          {dateMonthFullYear(new Date(newValidFrom))}
                        </span>
                        <span className="strike-date">
                          {dateMonthFullYear(new Date(validFrom))}
                        </span>
                      </>
                    ) : validFrom ? (
                      <span className="current-assigned-date">
                        {dateMonthFullYear(new Date(validFrom))}
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <div className="date-container">
                      <KeyboardTabIcon />
                      {t("valid-to")}
                    </div>
                    {newValidTo && validTo !== newValidTo ? (
                      <>
                        <span className="current-assigned-date">
                          {dateMonthFullYear(new Date(newValidTo))}
                        </span>
                        {validTo ? (
                          <span className="strike-date">
                            {dateMonthFullYear(new Date(validTo))}
                          </span>
                        ) : null}
                      </>
                    ) : validTo ? (
                      <span className="current-assigned-date">
                        {dateMonthFullYear(new Date(validTo))}
                      </span>
                    ) : null}
                  </div>
                </ModificationContainer>
              ) : (
                "_"
              ),
          },
          {
            icon: <TextsmsOutlinedIcon />,
            label: t("reason"),
            content: <TypographyData text={reason} />,
          },
          {
            icon: <RequesterIcon />,
            label: t("decision-maker"),
            content: <UserData user={reviewer} />,
          },
          {
            icon: <CalendarMonthOutlinedIcon />,
            label: t("date-of-decision"),
            content: (
              <TypographyData
                text={dateFullMonthFullYear(new Date(processingDate))}
              />
            ),
          },
        ];

  return (
    <>
      <div style={{ width: "100%" }}>
        <MoreDetailsContainer>
          {reviewDetails.map((ele, i) => {
            return (
              <>
                <Grid item xs={6} sm={6} md={4} lg={3} key={i}>
                  <div className="leftColumn">
                    {ele.icon}
                    <Tooltip type="ellipsized" title={ele.label}>
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.60)",
                        }}
                      >
                        {ele.label}
                      </Typography>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={8} lg={9}>
                  {ele.content}
                </Grid>
              </>
            );
          })}
        </MoreDetailsContainer>
        {decision === DECISION_CONSTANT.NONE && !disableActions && (
          <>
            <Divider />
            <DecisionCell row={row} disabled={disabled} />
          </>
        )}
      </div>
    </>
  );
};
const UserData = ({ user }) => (
  <MoreDecisionMakerContainer>
    <UserContextPopover id={user?.id}>
      <UserAvatar
        userId={user?.id}
        userName={user?.id}
        showImage={true}
        size="small"
        showGroupType
        groupType={user?.groupType}
      />
    </UserContextPopover>
    <Typography
      sx={{
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
      }}
    >
      {user?.completeName}
    </Typography>
    {/* <InfoOutlinedIcon /> */}
  </MoreDecisionMakerContainer>
);

const TypographyData = ({ text }) => (
  <Typography
    sx={{
      fontSize: "18px",
      lineHeight: "24px",
      color: "#000000",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {text ? text : "-"}
  </Typography>
);

export default MoreDetails;
