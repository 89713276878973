import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, useUser } from "@alice/component_library";
import { useQuery } from "@core/hooks";
import {
  fetchUserAssignableOrgScopeById,
  fetchUserAssignableRoleById,
  fetchUserAssignedRoleById,
  fetchUserById,
} from "@sliceactions/UserActions";
import { createRoles } from "@sliceactions/newRequestActions";
import RoleRequestHeader from "@pages/newRequest/components/RoleRequestComponents/header";
import RoleRequestStepper from "@pages/newRequest/components/RoleRequestComponents/RoleRequestStepper";
import { NewRequestDeeplinkSkeleton } from "@components/Skeleton/CommonSkeletons";
import {
  FIRST_STEP,
  SECOND_STEP,
  THIRD_STEP,
  MIN_LENGTH_REASON,
} from "@pages/newRequest/constants";
import RoleConfigFooter from "../RoleRequestComponents/footer";
import ErrorPage from "./ErrorPage";
import RoleConfiguration from "./RoleConfiguration";
import ConditionsAndReason from "../ConditionsAndReason";
import SummaryPage from "../Summary";
import { getConfiguredRole } from "./util";
import { NewRequestDeeplinkContainer } from "../../styledComponents";
const NewRequestDeeplink = () => {
  const query = useQuery();
  const { user = {} } = useUser();
  const dispatch = useDispatch();
  const stepperRef = useRef(null);
  const footerRef = useRef(null);
  const location = useLocation();
  const hasCCAccess = useSelector((state) => state.controlCenter.hasCCAccess);
  const { data: fetchedUserById = {}, loading } = useSelector(
    (state) => state.userSlice.userById
  );

  const {
    data: userAssignedRoleById = {},
    loading: userAssignedRoleByIdLoading = false,
  } = useSelector((state) => state.userSlice.userAssignedRoleById);
  const {
    data: userAssignableRoleById = {},
    loading: userAssignableRoleIdLoading,
  } = useSelector((state) => state.userSlice.userAssignableRoleById);
  const {
    data: userAssignableOrgScope = {},
    loading: userAssignableOrgScopeLoading,
  } = useSelector((state) => state.userSlice.userAssignableOrgScope);

  const queryUser = query.get("user");
  const role = query.get("role") ?? "";
  const mode = query.get("mode") ?? "";
  const orgScope = query.get("orgScope") ?? "";
  const custScope = query.get("custScope") ?? "";
  const validFromUrl = query.get("validFrom") ?? "";
  const isInsights = location?.pathname.includes(
    "/access/accessRequest/insights"
  ); // checking if the page is control center page
  const isExtend = mode === "extend";
  const isReapply = mode === "reapply";
  const isExtendorReApply = isExtend || isReapply;
  const validUserId = queryUser && isInsights ? fetchedUserById?.id : user?.id;

  const [activeStep, setActiveStep] = useState(FIRST_STEP);
  const [reason, setReason] = useState("");
  const [allConfigured, setAllConfigured] = useState(false);
  const [configurationData, setConfigurationData] = useState({});

  const fetchRoleDetails = useCallback(() => {
    dispatch(
      fetchUserAssignableRoleById({
        userId: validUserId,
        roleId: role,
        isSelfRequestable: isInsights ? false : true,
      })
    );
  }, [dispatch, isInsights, role, validUserId]);

  const fetchRoleAvailability = useCallback(async () => {
    dispatch(
      fetchUserAssignedRoleById({
        userId: validUserId,
        roleId: role,
        orgScope: orgScope,
        custScope: custScope,
      })
    );
  }, [custScope, dispatch, orgScope, validUserId, role]);

  useEffect(() => {
    if (
      queryUser &&
      queryUser?.toLowerCase() !== user?.id?.toLowerCase() &&
      isInsights
    ) {
      if (hasCCAccess) dispatch(fetchUserById({ userId: queryUser }));
    }
  }, [dispatch, hasCCAccess, isInsights, queryUser, user?.id]);

  useEffect(() => {
    if (userAssignedRoleById?.assignmentId) fetchRoleDetails();
  }, [fetchRoleDetails, userAssignedRoleById]);

  useEffect(() => {
    // Check if the user is valid based on insights mode or user mode
    const isUserValid =
      ((validUserId || fetchedUserById?.id) && isInsights) ||
      (user?.id && !isInsights);
    const isExtendOrReapply = isExtendorReApply;

    // Fetch role availability if it's an extend or reapply request
    if (isUserValid) {
      if (isExtendOrReapply) {
        fetchRoleAvailability();
      } else if (role) {
        fetchRoleDetails();
      }
    }
  }, [
    fetchRoleAvailability,
    fetchRoleDetails,
    fetchedUserById?.id,
    isExtendorReApply,
    isInsights,
    role,
    user?.id,
    validUserId,
  ]);

  useEffect(() => {
    if (userAssignableRoleById?.id && orgScope && !userAssignedRoleById?.id) {
      dispatch(
        fetchUserAssignableOrgScopeById({
          orgId: orgScope,
          userId: isInsights ? fetchedUserById.id : user?.id,
          roleId: role,
          isSelfRequestable: isInsights ? false : true,
        })
      );
    }
  }, [
    dispatch,
    fetchedUserById.id,
    isInsights,
    orgScope,
    role,
    user?.id,
    userAssignableRoleById,
    userAssignedRoleById?.id,
  ]);
  // Check if the next button should be disabled based on the active step
  const isNextDisabled = () => {
    if (activeStep === FIRST_STEP) return !allConfigured;
    if (activeStep === SECOND_STEP) return reason.length < MIN_LENGTH_REASON;
  };
  // Move to the previous step in the stepper
  const previousAction = () => {
    if (activeStep > FIRST_STEP) {
      stepperRef?.current?.back();
      setActiveStep(stepperRef?.current?.activeStep - 1);
    }
  };
  // Move to the next step in the stepper
  const nextAction = () => {
    if (activeStep === THIRD_STEP) {
      const userId = isInsights ? fetchedUserById.id : user?.id;
      createroleDetails([configurationData], reason, userId);
    } else {
      setActiveStep(stepperRef?.current?.activeStep + 1);
      stepperRef?.current?.setCompleted();
    }
  };
  // Get the configured role based on the user's role data and url parameters
  const configuredRole = useMemo(() => {
    return getConfiguredRole({
      userAssignableRoleById: userAssignableRoleById,
      isExtend: isExtend,
      userAssignedRoleById: userAssignedRoleById,
      userAssignableOrgScope: userAssignableOrgScope,
      custScope: custScope,
      validFromUrl: validFromUrl,
    });
  }, [
    userAssignableRoleById,
    isExtend,
    userAssignedRoleById,
    userAssignableOrgScope,
    custScope,
    validFromUrl,
  ]);
  // Save the role data from the child component and reset the stepper if the role is not configured
  const saveRoleData = (id, data) => {
    const isConfigured = data?.configurationInformation?.isConfigured;
    setConfigurationData(data);
    setAllConfigured(isConfigured);
    const completedSteps = stepperRef?.current?.completedSteps;
    if (completedSteps.includes(FIRST_STEP) && !isConfigured) {
      stepperRef?.current?.reset();
    }
  };
  // Save the reason from the child component, check if the reason is less than 20 characters and set the stepper to incomplete
  const saveReasonHandler = (reasonFromChild) => {
    const completedSteps = stepperRef?.current?.completedSteps;
    if (
      completedSteps.includes(SECOND_STEP) &&
      reasonFromChild?.length < MIN_LENGTH_REASON
    ) {
      stepperRef?.current?.setCompleted(false, 1, false);
    }
    setReason(reasonFromChild);
  };

  const createroleDetails = useCallback(
    (rolesData, requestReason, userId) => {
      dispatch(
        createRoles({
          rolesData: rolesData,
          reason: requestReason,
          userId: userId,
        })
      );
    },

    [dispatch]
  );
  // handle active step of stepper
  const handleActiveStep = (step) => setActiveStep(step);

  // Check if the user is invalid based on insights mode or user mode
  const isUserValid =
    ((validUserId || fetchedUserById?.id) && isInsights) ||
    (user?.id && !isInsights);

  // Check if the role is unavailable based on insights mode or user mode
  const isRoleUnavailable =
    (!userAssignedRoleById?.assignmentId && isExtendorReApply) ||
    !userAssignableRoleById.id;

  return (
    <>
      {userAssignedRoleByIdLoading ||
      userAssignableRoleIdLoading ||
      userAssignableOrgScopeLoading ||
      loading ? (
        <NewRequestDeeplinkSkeleton />
      ) : (
        <>
          {!isUserValid || isRoleUnavailable ? (
            <ErrorPage
              header={!isUserValid ? "incorrect-user-id" : "role-not-available"}
              subHeader={
                !isUserValid
                  ? "user-id-incorrect-subheader"
                  : "role-not-available-subheader"
              }
            />
          ) : (
            <>
              <NewRequestDeeplinkContainer>
                <Layout>
                  <RoleRequestHeader
                    userData={
                      !!queryUser && isInsights ? fetchedUserById : user
                    }
                    type={isInsights ? "delegated" : "myself"}
                    isFromDeeplink={true}
                    mode={mode}
                  />
                  <RoleRequestStepper
                    handleActiveStep={handleActiveStep}
                    stepperRef={stepperRef}
                    type={isInsights ? "delegated" : "myself"}
                    isFromDeeplink={true}
                  />

                  <RoleConfiguration
                    roleDetails={configuredRole}
                    isExtend={isExtend}
                    isReapply={isReapply}
                    saveRoleData={saveRoleData}
                    isDisplay={activeStep === FIRST_STEP}
                  />

                  {activeStep === SECOND_STEP && (
                    <ConditionsAndReason
                      isTermRequired={false}
                      data={reason}
                      saveReasonHandler={saveReasonHandler}
                      footerRef={footerRef}
                    />
                  )}
                  {activeStep === THIRD_STEP && (
                    <SummaryPage
                      requestReason={reason}
                      configuredRoles={[configurationData]}
                      selectedUser={isInsights ? fetchedUserById : {}}
                    />
                  )}
                </Layout>
              </NewRequestDeeplinkContainer>
              <RoleConfigFooter
                isPreviousButtonVisible={activeStep !== FIRST_STEP}
                isNextDisabled={isNextDisabled()}
                isLastStep={activeStep === THIRD_STEP}
                previousAction={() => previousAction()}
                nextAction={() => nextAction()}
                ref={footerRef}
                data={{ configure: FIRST_STEP }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewRequestDeeplink;
