import { DateFormatter, isValidDate } from "@utils/dateFormat";
import {
  DEFAULT_VALIDITY_DAYS,
  HOURS_IN_A_DAY,
  MILLISECONDS_IN_A_SECOND,
  MINUTES_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
} from "@pages/newRequest/constants";

export const getFromDate = (isExtend, userAssignedRoleById, validFromUrl) => {
  if (isExtend) {
    return new Date(userAssignedRoleById?.validFrom ?? new Date());
  }
  const today = new Date().setHours(0, 0, 0, 0);
  const validFromUrlDecoded =
    validFromUrl.length && isValidDate(decodeURI(validFromUrl), "YYYY-MM-DD")
      ? DateFormatter.format(new Date(validFromUrl))
      : "";

  const validFromDate = new Date(validFromUrlDecoded).setHours(0, 0, 0, 0);
  return validFromDate >= today && validFromUrl
    ? new Date(validFromUrlDecoded)
    : new Date();
};

export const getToDate = (isExtend, fromDate, roleDefinition) => {
  const defaultValidityDays =
    roleDefinition?.defaultValidityDays ?? DEFAULT_VALIDITY_DAYS;
  const from = isExtend ? new Date() : fromDate;
  return new Date(
    new Date(from).getTime() +
      defaultValidityDays *
        HOURS_IN_A_DAY *
        MINUTES_IN_AN_HOUR *
        SECONDS_IN_A_MINUTE *
        MILLISECONDS_IN_A_SECOND
  );
};

export const getOrgScope = (userAssignedRoleById, userAssignableOrgScope) => {
  return userAssignedRoleById?.orgScope?.id
    ? userAssignedRoleById?.orgScope
    : userAssignableOrgScope?.id
    ? userAssignableOrgScope
    : {};
};

export const getScopeDataKey = (
  userAssignedRoleById,
  userAssignableOrgScope
) => {
  return userAssignedRoleById?.orgScope?.id ?? userAssignableOrgScope?.id ?? 0;
};

export const getConfiguredRole = ({
  userAssignableRoleById,
  isExtend,
  userAssignedRoleById,
  userAssignableOrgScope,
  custScope,
  validFromUrl,
}) => {
  const roleDefinition = userAssignableRoleById?.id
    ? userAssignableRoleById
    : {};

  const fromDate = getFromDate(isExtend, userAssignedRoleById, validFromUrl);
  const toDate = getToDate(isExtend, fromDate, roleDefinition);
  const orgScope = getOrgScope(userAssignedRoleById, userAssignableOrgScope);
  const scopeDataKey = getScopeDataKey(
    userAssignedRoleById,
    userAssignableOrgScope
  );

  const configurationInformation = {
    timeRange: {
      validity: {
        from: fromDate,
        to: toDate,
      },
    },
  };

  configurationInformation.scopeData = {
    data: {
      [scopeDataKey]: {
        ...orgScope,
        customScope: [userAssignedRoleById?.customScope ?? { name: custScope }],
      },
    },
  };

  return {
    data: roleDefinition.id ? { roleDefinition } : {},
    id: roleDefinition.id,
    configurationInformation,
  };
};
