import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Tooltip } from "@alice/component_library";

import {
  FileBarMain,
  BulkFileContanier,
  FileMainContainer,
  FileIconContainer,
  FileNameTypo,
  FileUploadDate,
} from "./styles";

const BulkUploadFileBar = ({ files, handleFile }) => {
  const today = new Date();

  const dateFormatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const formattedDate = dateFormatter.format(today);

  const timeFormatter = new Intl.DateTimeFormat("en-GB", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedTime = timeFormatter.format(today).slice(0, -3);

  const dateTime = `${formattedDate} / ${formattedTime}`;
  const { t } = useTranslation();

  return (
    <>
      <FileBarMain>
        <BulkFileContanier>
          <FileMainContainer>
            <FileIconContainer>
              <InsertDriveFileOutlinedIcon
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              />
              <Tooltip
                arrow
                placement="top"
                title={files?.file?.name}
                type="ellipsized"
              >
                <FileNameTypo>{files?.file?.name}</FileNameTypo>
              </Tooltip>
            </FileIconContainer>
            <Box>
              <FileUploadDate>{`${t("uploaded")} ${dateTime}`}</FileUploadDate>
            </Box>
          </FileMainContainer>
          <FileIconContainer>
            <Divider
              sx={{
                backgroundColor: "var(--others-divider, #DBDBDB)",
                width: "1px",
                height: "100%",
              }}
            />
            <Button
              onClick={handleFile}
              sx={{ minWidth: 0, padding: 0, borderRadius: "15px" }}
            >
              <HighlightOffOutlinedIcon
                sx={{
                  color: "rgba(0, 120, 214, 1)",
                  width: "25px",
                  height: "24px",
                }}
              />
            </Button>
          </FileIconContainer>
        </BulkFileContanier>
      </FileBarMain>
    </>
  );
};

export default BulkUploadFileBar;
