import React from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CircularProgressComponent } from "@components/CircluarProgress";
import { NewTable, Button } from "@alice/component_library";
import {
  SpinnerConatiner,
  SpinnerWrapper,
  PercentageTableButtonWrapper,
  ButtonContainer,
} from "../styledComponents";

const ProgressComponent = ({
  isOPen,
  key,
  tottalCount,
  onRedirect,
  onCloseHadler,
  units,
  progress,
}) => {
  const { t } = useTranslation();
  return (
    <SpinnerWrapper
      key={key}
      open={isOPen}
      footerSection=""
      headerSection=""
      onClose={function noRefCheck() {}}
      noScroll={true}
      hasCloseButton={false}
    >
      <p>{t("bulk-process-in-progress")}</p>
      <Button size="medium" variant="tertiary" onClick={() => onRedirect()}>
        {t("go-to-access-reviews")}
        <ChevronRightIcon />
      </Button>
      <SpinnerConatiner>
        <CircularProgressComponent value={progress} variant={"determinate"} />
      </SpinnerConatiner>
      <PercentageTableButtonWrapper>
        <NewTable
          isLocal
          hidePagination={true}
          localRows={[
            {
              Type: "Proccessed Assignments",
              Count: `${units.processedUnits}/${tottalCount}`,
            },
            {
              Type: "Skipped Assignments",
              Count: `${units.skippedUnits}/${tottalCount}`,
            },
            {
              Type: "Failed Assignaments",
              Count: `${units.failedUnits}/${tottalCount}`,
            },
          ]}
        />
        <ButtonContainer>
          <Button
            isLoading={progress < 100 ? true : false}
            size="medium"
            variant="primary"
            onClick={() => onCloseHadler()}
          >
            {t("close")}
          </Button>
        </ButtonContainer>
      </PercentageTableButtonWrapper>
    </SpinnerWrapper>
  );
};

export default ProgressComponent;
