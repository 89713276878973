import {
  Grid,
  Layout,
  RoleCardV2Skeleton,
  Skeleton,
  Stack,
  StyledLayout,
  UserCardLoading,
} from "./CommonSkeletons";

const AccessRequestSkeleton = () => {
  return (
    <StyledLayout>
      <Layout>
        <Skeleton
          variant="text"
          width="200px"
          sx={{ paddingTop: { xs: "40px", lg: "0px" } }}
        />
        <Skeleton variant="text" width="200px" sx={{ margin: "30px 0" }} />
        <Stack direction="row">
          {[...Array(3)].map((ele) => (
            <>
              <Skeleton variant="text" width={50} key={ele} /> /
            </>
          ))}
        </Stack>

        <Skeleton variant="text" width={50} />
        <Skeleton variant="text" width={50} sx={{ margin: "20px 0" }} />
        <Grid container>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <UserCardLoading />
          </Grid>
        </Grid>
        <Skeleton variant="text" width={50} sx={{ margin: "20px 0" }} />
        <Grid container>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <RoleCardV2Skeleton large={false} hideActions />
          </Grid>
        </Grid>

        <Skeleton
          variant="rectangular"
          height={100}
          sx={{ margin: "20px 0" }}
        />

        <Skeleton
          variant="rectangular"
          height={20}
          width={100}
          sx={{ marginLeft: "auto" }}
        />
      </Layout>
    </StyledLayout>
  );
};

export default AccessRequestSkeleton;
