import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { createArrayWithInitialValues } from '../../../../util'
import { Skeleton } from '../../../skeleton'
import { Table } from '../../../table/table/Table' // Cant be shortened

/**
 * Table renderer optimized for usage within [GenericSearch](.//?path=/docs/alice-ui-search-genericsearch--docs)
 *
 * **Note:**
 * This component renders a [Table](./?path=/docs/alice-ui-table-table--docs) and therefor is able to handle all of it's properties.
 */
export const TableViewRenderer = ({
  columns,
  data,
  selectedData = [],
  rowsPerPage,
  isMultiselect,
  isLoading: _isLoading,
  isForceLoading,
  dataConverter = (data) => data,
  getRowId = (rowData) => rowData.col0,
  onItemClick,
  onAllClick,
  ...otherProps
}) => {
  const isLoading = _isLoading || isForceLoading

  const handleRowClick = ({
    isChecked,
    row
  }) => {
    onItemClick({
      id: row.id,
      isSelected: isChecked,
      data: row
    })
  }

  const handleAllClick = ({ changedRows, isChecked }) => {
    onAllClick({
      changedRows: changedRows.map((row) => row.id),
      isChecked
    })
  }

  const mappableData = isLoading || isForceLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ([ index ]))
    : data

  const loadingColumns = columns.map((column) => {
    const loadingColumn = { ...column }

    loadingColumn.Header = (
      <Skeleton variant="rectangular" width="100%" height={15} sx={{ marginTop: '5px', marginBottom: '-20px' }}>
        <Typography>{column.Header}</Typography>
      </Skeleton>
    )

    loadingColumn.Cell = (
      <Skeleton variant="rectangular" width="100%" height={38}>
        <Typography>{column.key}</Typography>
      </Skeleton>
    )

    return loadingColumn
  })

  const handleGetRowIdLoading = ({ col0 }) => col0

  return (
    <Table
      columns={isLoading ? loadingColumns : columns}
      data={isLoading ? mappableData : dataConverter(data)}
      getRowId={isLoading ? handleGetRowIdLoading : getRowId}
      itemsPerPage={rowsPerPage}
      preselectedRowIds={selectedData}
      multiselect={isMultiselect}
      onRowClick={handleRowClick}
      onToggleAllInPage={handleAllClick}
      showItemsPerPageDropdownAtTop={false}
      showItemsPerPageDropdownAtBottom={false}
      showPaginationAtTop={false}
      showPaginationAtBottom={false}
      showSearch={false}
      showSelectedItemsInfo={false}
      showRadiobuttonOnSingleSelect={false}
      {...otherProps}
    />
  )
}

TableViewRenderer.propTypes = {
  /** Columns */
  columns: PropTypes.array.isRequired,
  /** Data of the columns */
  data: PropTypes.array.isRequired,
  /** Selected data */
  selectedData: PropTypes.array,
  /** Rows per page */
  rowsPerPage: PropTypes.number,
  /** Defines if table is multiselect or not */
  isMultiselect: PropTypes.bool,
  /** Defines if data is loading */
  isLoading: PropTypes.bool,
  /** Outside loading state */
  isForceLoading: PropTypes.bool,
  /** Converts into a form that can be used for the table */
  dataConverter: PropTypes.func,
  /** Gets the row id from a object */
  getRowId: PropTypes.func,
  /** Callback when item gets clicked */
  onItemClick: PropTypes.func,
  /** Callback when all select gets clicked */
  onAllClick: PropTypes.func
}
