import { styled } from "@mui/system";
import { Typography } from "@mui/material";

export const FileBarMain = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const BulkFileContanier = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderRadius: "4px",
  backgroundColor: "rgba(0, 120, 214, 0.10)",
  alignItems: "flex-start",
  padding: "8px",
  flex: "1 0 0",
  justifyContent: "space-between",
}));

export const FileIconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "8px",
  height: "100%",
}));

export const FileMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  columnGap: "8px",
  rowGap: "5px",
}));

export const FileNameTypo = styled(Typography)(({ theme }) => ({
  color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "467px",
  [theme.breakpoints.down("md")]: {
    width: "50vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "46vw",
  },
}));

export const FileUploadDate = styled(Typography)({
  color: "var(--text-secondary-inactive, rgba(0, 0, 0, 0.60))",
  fontFamily: "MB Corpo S Text",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
});
