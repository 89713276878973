import { memo, useCallback, useState } from "react";
import { AliceIcon, AliceIconType } from "@alice/component_library";
import { blue } from "@mercedes-benz/mui5-theme";
import {
  ProfileMenuItems,
  ProfileMenuItemsHeader,
  ProfileMenuItemIcon,
} from "../../styledComponents";
import { getUUID } from "@src/utils";

import MyOrganizationIcon from "./Icons/organization.svg";
import MyAdminiStartorIcon from "./Icons/myadministrators.svg";
import MyAdministartiveRoleIcons from "./Icons/myadministratorroles.svg";
import MyColleaguesIcon from "./Icons/myColleagues.svg";

import KeysIcon from "./Icons/keys.svg";
import ProfileIcon from "./Icons/profile.svg";
import MyAccountsIcon from "./Icons/myaccounts.svg";
import MyApplicationIcon from "./Icons/myapplication.svg";

const Icon = ({ iconType, size = 26 }) => (
  <AliceIcon
    iconType={iconType}
    size={size}
    sx={{ pointerEvents: "none", cursor: "not-allowed" }}
    color={blue[45]}
  />
);

const ProfileSideMenu = memo(
  ({
    isCollapsed,
    t,
    actionHandler,
    selectedValue,
    preSelectedValue,
    showColleagues,
    adminRoles,
  }) => {
    const configMenuList = [
      {
        id: "myProfile",
        title: t("my_profile"),
        icon: <ProfileIcon />,
        show: true,
      },
      {
        id: "myOrganizations",
        title: t("my-organizations"),
        icon: <MyOrganizationIcon />,
        show: true,
      },
      {
        id: "myRoles",
        title: t("my-roles"),
        icon: <KeysIcon />,
        show: true,
      },
      {
        id: "myApplications",
        title: t("my-applications"),
        icon: <MyApplicationIcon />,
        show: true,
      },
      {
        id: "myAdministartors",
        title: t("my-administrators"),
        icon: <MyAdminiStartorIcon />,
        show: true,
      },
      {
        id: "myAdmininstratorRoles",
        title: t("profileTabHeading.myAdmininstratorRoles"),
        icon: <MyAdministartiveRoleIcons />,
        show: adminRoles?.length ? true : false,
      },
      {
        id: "myColleagues",
        title: t("my-colleagues"),
        icon: <MyColleaguesIcon />,
        show: showColleagues,
      },
      {
        id: "myAccounts",
        title: t("accounts"),
        icon: <MyAccountsIcon />,
        show: true,
      },
    ];

    const [selectedId, setSelectedId] = useState(
      preSelectedValue !== undefined
        ? preSelectedValue
        : selectedValue?.length
        ? selectedValue
        : "myProfile"
    );

    const selectedTask = useCallback(
      (e, id) => {
        e.preventDefault();
        setSelectedId(id);
        actionHandler(id);
      },
      [actionHandler]
    );

    return (
      <div className="side-menu-container">
        {configMenuList
          .filter((item) => item.show === true)
          .map((item, index) => (
            <div className="profile-menu-item" data-value={item.id}>
              <ProfileMenuItems
                href={`/access/profile?category=${item.id}`}
                onClick={(e) => selectedTask(e, item.id)}
                key={getUUID()}
                selected={selectedId === item.id}
                data-value={item.id}
              >
                <ProfileMenuItemIcon data-value={item.id}>
                  {item.icon}
                </ProfileMenuItemIcon>

                {!isCollapsed && (
                  <ProfileMenuItemsHeader key={index} data-value={item.id}>
                    {item.title}
                  </ProfileMenuItemsHeader>
                )}
              </ProfileMenuItems>
            </div>
          ))}
      </div>
    );
  }
);

export default ProfileSideMenu;
