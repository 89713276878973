import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchAssignableRoles,
  fetchAssignableRolesForreferenceUser,
} from "@sliceactions/newRequestActions";
import { resetAssignableRoles } from "@slice/newRequestSlice";
import {
  RoleSelectionHeader,
  RolesContainer,
} from "@pages/newRequest/styledComponents";
import RoleFilter from "@pages/newRequest/components/RoleSelection/RoleFilter";
import RoleGridSection from "@pages/newRequest/components/RoleSelection/RoleGridSection";
import SelectedRolesSections from "@pages/newRequest/components/RoleSelection/SelectedRolesSections";
import { isSuperAdmin } from "@utils";
import { usePermission } from "@alice/component_library";
const limit = 10;

const RoleSelection = ({ userData, setSelectedRoles, selectedRoles, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sentinelRef = useRef(null);
  const observerRef = useRef(null);
  const targetRef = useRef(null);
  const [filter, setFilter] = useState(["all"]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedApplication, setSelectedApplication] = useState({});
  const [selectedReferenceUser, setSelectedReferenceUser] = useState({});

  const { id: userId } = userData || {};
  const {
    data: roles = [],
    loading,
    hasMore,
    offset,
    totalCount,
  } = useSelector((state) => state.newRequestSlice.assignableRoles);
  const { adminRoles: adminRoleSet } = usePermission();

  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const showCopyReferenceUser =
    isSuperAdmin(adminRoles, "cc") && type !== "myself"; // this condition has to be checked with PO // this condition has to be checked with PO

  const handleSearchChange = useCallback(
    (e) => {
      if (e === searchQuery) return;
      setSearchQuery(e);
      dispatch(resetAssignableRoles());
    },
    [dispatch, searchQuery]
  );

  const handleFilter = useCallback(
    (ele) => {
      if (Array.isArray(ele)) {
        setFilter(ele);
      } else {
        let selectedFilter = [...filter];
        if (
          (ele === "JOBTITLE" || ele === "BUSINESS") &&
          (selectedFilter.includes("JOBTITLE") ||
            selectedFilter.includes("BUSINESS")) &&
          selectedFilter.length === 1
        ) {
          const newSelectedFilter = [...selectedFilter, ele];
          selectedFilter = newSelectedFilter;
        } else {
          selectedFilter = [ele];
        }

        setFilter(selectedFilter);
      }
      dispatch(resetAssignableRoles());
    },
    [dispatch, filter]
  );
  const handleSelect = (data) => {
    if (!data?.isSelected)
      setSelectedRoles(selectedRoles.filter((curr) => curr?.id !== data.id));
    else
      setSelectedRoles([
        ...selectedRoles,
        {
          id: data.id,
          data: data.data,
          isSelected: data.isSelected,
        },
      ]);
  };
  const handleSelectApplication = useCallback(
    (app) => {
      setSelectedApplication(app);
      // dispatch(resetAssignableRoles());
    },
    [] //dispatch
  );

  const handleSelectReferenceUser = useCallback((user) => {
    setSelectedApplication({});
    setSelectedReferenceUser(user);
  }, []);

  const handleRemove = (role) => {
    setSelectedRoles(selectedRoles.filter((el) => el?.id !== role?.id));
  };

  const fetchRoleDetails = useCallback(
    (shift) => {
      const roleType = filter?.filter(
        (el) => el !== "JOBTITLE" && el !== "all"
      );
      const isJobTitle = filter?.includes("JOBTITLE");
      dispatch(
        fetchAssignableRoles({
          userId: userId,
          roleType: roleType.length ? roleType[0] : "",
          isJobTitle: isJobTitle,
          offset: shift,
          search: searchQuery,
          applicationId: selectedApplication?.id,
        })
      );
    },
    [dispatch, filter, searchQuery, selectedApplication?.id, userId]
  );

  useEffect(() => {
    if (!Object.keys(selectedReferenceUser).length) {
      fetchRoleDetails(0);
    }
  }, [
    dispatch,
    filter,
    searchQuery,
    userId,
    selectedApplication.id,
    fetchRoleDetails,
    selectedReferenceUser,
  ]);

  const fetchRoleDetailsForReferenceUser = useCallback(
    (shift) => {
      const roleType = filter?.filter(
        (el) => el !== "JOBTITLE" && el !== "all"
      );
      const isJobTitle = filter?.includes("JOBTITLE");

      dispatch(
        fetchAssignableRolesForreferenceUser({
          referenceUserUserId: selectedReferenceUser?.id,
          roleType: roleType.length ? roleType[0] : "",
          isJobTitle: isJobTitle,
          offset: shift,
          search: searchQuery,
          userId: userId,
        })
      );
    },
    [dispatch, filter, searchQuery, selectedReferenceUser]
  );

  useEffect(() => {
    if (Object.keys(selectedReferenceUser).length > 0) {
      fetchRoleDetailsForReferenceUser(0);
    }
  }, [
    dispatch,
    filter,
    searchQuery,
    userId,
    selectedReferenceUser,
    fetchRoleDetailsForReferenceUser,
  ]);

  const intersectionObserverCallback = useCallback(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore && !loading) {
          if (Object.keys(selectedReferenceUser).length > 0) {
            fetchRoleDetailsForReferenceUser(offset + limit);
          } else {
            fetchRoleDetails(offset + limit);
          }
        }
      });
    },
    [
      hasMore,
      fetchRoleDetailsForReferenceUser,
      fetchRoleDetails,
      offset,
      limit,
      selectedReferenceUser,
      loading,
    ]
  );

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      intersectionObserverCallback
    );

    const targetElement = targetRef.current;
    if (targetElement) {
      observerRef.current.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observerRef.current.unobserve(targetElement);
      }
    };
  }, [intersectionObserverCallback]);

  return (
    <>
      <RoleSelectionHeader>{t("role-selection")}</RoleSelectionHeader>
      <RolesContainer>
        <div className="role-grid-container">
          <RoleFilter
            type={type}
            userId={userId}
            filter={filter}
            handleFilter={handleFilter}
            searchKey={searchQuery}
            handleSearch={handleSearchChange}
            handleSelectApplication={handleSelectApplication}
            selectedApplication={selectedApplication}
            selectedReferenceUser={selectedReferenceUser}
            handleSelectReferenceUser={handleSelectReferenceUser}
            totalCount={totalCount}
            loading={loading}
            showCopyReferenceUser={showCopyReferenceUser}
            isAppliCationDisabled={
              Object.keys(selectedReferenceUser).length > 0
            }
          />
          <RoleGridSection
            roles={roles}
            selectedRoles={selectedRoles}
            handleSelect={handleSelect}
            loading={loading}
            hasMore={hasMore}
            mesureRef={sentinelRef}
          />
          <div id="target" ref={targetRef}></div>
        </div>
        <SelectedRolesSections
          selectedRoles={selectedRoles}
          handleRemove={handleRemove}
        />
      </RolesContainer>
    </>
  );
};

export default RoleSelection;
