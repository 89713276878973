import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { getClient, mergeSxProps } from '../../util'
import AliceLogo from '../../_assets/img/alice-icon.png'

/** Client Text */
export const ClientText = forwardRef((
  {
    showIcon = true,
    client,
    sx = [],
    ...otherProps
  },
  ref
) => {
  // todo change avatar depending on the client
  const clientName = getClient()

  return (
    <Box
      data-testid="ClientText"
      ref={ref}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        !showIcon && { justifyContent: 'start' }
      ]}
      {...otherProps}
    >
      {showIcon && <img src={AliceLogo} alt="Alice Logo" width="24" height="24" />}

      <Typography
        variant={'subtitle1'}
        sx={mergeSxProps(
          {
            color: 'white',
            fontSize: '22px',
            pl: 0.5,
            textTransform: 'capitalize'
          },
          sx
        )}
      >
        {client || clientName}
      </Typography>
    </Box>
  )
})

ClientText.propTypes = {
  /** Client string */
  client: PropTypes.string
}

// You can set the client to a specific client (see util/constant/CLIENTS)
// Or you can leave it free, and it will default to the current active client
