import { useState, memo, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  TaskMenuCard,
  TaskMenuCardContent,
  TaskMenuCardTitle,
} from "./StyledComponents";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import TaskList from "./TaskList";
import { getTaskCounts } from "@src/actions";

const TaskMenu = memo(
  ({
    t,
    setIsCollapsedMenu,
    userId,
    isAdmin,
    filter,
    search,
    actionHandlerForgetType,
    windowSize,
    taskType,
    taskMenuType,
    collapsed,
  }) => {
    const [isCollapsed, setIsCollapsed] = useState(
      collapsed !== undefined ? collapsed : false
    );
    const taskListCounts = useSelector((state) => state.task.openTaskCountInfo);
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = windowSize;

    const setCollapseMenu = (value) => {
      setIsCollapsed(value);
    };

    const setType = (value) => {
      actionHandlerForgetType(value);
    };

    const getTaskAndWorkFlowCount = useCallback(() => {
      dispatch(getTaskCounts(isAdmin, userId, filter, search, taskListCounts));
    }, [dispatch, userId, isAdmin, filter, search]);

    useEffect(() => {
      getTaskAndWorkFlowCount();
    }, [getTaskAndWorkFlowCount, search]);

    useEffect(() => {
      setIsCollapsedMenu(isCollapsed);
    }, [isCollapsed, setIsCollapsedMenu]);

    return (
      <>
        {isDesktop && (
          <TaskMenuCard
            iscollapsed={isCollapsed}
            xs={isCollapsed ? 1 : 3}
            sm={isCollapsed ? 1 : 3}
            md={isCollapsed ? 1 : 3}
            lg={isCollapsed ? 1 : 3}
          >
            <TaskMenuCardContent iscollapsed={isCollapsed}>
              {!isCollapsed && (
                <TaskMenuCardTitle>{t("menuBar.taskTypes")} </TaskMenuCardTitle>
              )}
              <KeyboardDoubleArrowLeftIcon
                onClick={() => setCollapseMenu(!isCollapsed)}
                style={{
                  transform: isCollapsed ? "rotate(180deg)" : "",
                  cursor: "pointer",
                }}
              />
            </TaskMenuCardContent>
            <TaskList
              counts={taskListCounts}
              isCollapsed={isCollapsed}
              t={t}
              taskType={taskType}
              actionHandler={(value) => setType(value)}
              windowSize={windowSize}
              taskMenuType={taskMenuType}
            />
          </TaskMenuCard>
        )}
        {(isTablet || isMobile) && (
          <TaskList
            counts={taskListCounts}
            isCollapsed={isCollapsed}
            t={t}
            taskType={taskType}
            actionHandler={(value) => setType(value)}
            windowSize={windowSize}
          />
        )}
      </>
    );
  }
);

export default TaskMenu;
