import { Cached } from "@mui/icons-material";
import { IconButton } from "@alice/component_library";

const ReloadButton = ({ loading, handleIconButtonClick }) => {
  return (
    <IconButton disabled={loading} onClick={handleIconButtonClick}>
      <Cached
        sx={{
          color: "rgba(0, 0, 0, 0.54)",
          transform: "rotateY(180deg)",
          animation: loading ? "spin 1.5s linear infinite" : "none",
          "@keyframes spin": {
            "0%": { transform: "rotate(0deg) rotateY(180deg)" },
            "100%": { transform: "rotate(360deg) rotateY(180deg)" },
          },
        }}
      />
    </IconButton>
  );
};

export default ReloadButton;
