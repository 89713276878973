import { useTranslation } from "react-i18next";
import { UserSearch } from "@alice/component_library";

const UserSelection = ({ selectedUser, getSelectedUser }) => {
  const { t } = useTranslation();

  return (
    <>
      <UserSearch
        title={t("user-selection")}
        interaction="singleSelect"
        isAdminRolesOverwritten
        onCardClick={(id, selected, data) => {
          if (!selected) getSelectedUser({});
          else getSelectedUser(data);
        }}
        preselectedRowIds={[selectedUser?.id]}
        views={"list"}
        search={"large"}
        size="l"
        isSortHidden={false}
        rendererProps={{
          size: "l",
          isOrganisationShown: true,
          isCommunityShown: true,
          isEmailShown: false,
        }}
      />
    </>
  );
};

export default UserSelection;
