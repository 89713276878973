import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionSummary } from '../../accordion'
import { Button } from '../../button'
import { AliceIcon, AliceIconType } from '../../icon'

export const AdminRoleAccordion = ({
  title,
  children,
  isActive,
  isLoading,
  isRemovable,
  onAssignClick,
  onRemoveClick,
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ expanded, setExpanded ] = useState(false)

  const handleChange = useCallback(() => {
    if (children) setExpanded((prevState) => !prevState)
  }, [ children ])

  const handleRemoveClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (typeof onRemoveClick === 'function') {
      onRemoveClick(e)
    }
  }

  const handleAssignClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (typeof onAssignClick === 'function') {
      onAssignClick(e)
    }
  }

  return (
    <Accordion
      data-testid="AdminRoleAccordion"
      expanded={expanded}
      onChange={handleChange}
      sx={{
        boxShadow: 'none',
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderInline: 'none',
        borderColor: 'grey.85',
        '&:before': { display: 'none' },
        '&:hover': {
          backgroundColor: 'white',
          cursor: children ? 'pointer' : 'auto !important'
        }
      }}
      header={(
        <AccordionSummary
          expandIcon={children ? <ExpandMoreIcon /> : <Box sx={{ width: '25px' }} />}
          sx={{
            minHeight: 'fit-content !important',
            height: 'fit-content !important',
            pl: 3,
            '&:hover': { cursor: children ? 'pointer' : 'auto !important' }
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Stack
                direction="row"
                gap={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                {isActive ? (
                  <AliceIcon
                    iconType={AliceIconType.ACTIVE}
                    color="success.main"
                    size={24}
                  />
                ) : (
                  <AliceIcon
                    iconType={AliceIconType.CLOSE}
                    color="grey.55"
                    size={18}
                  />
                )}

                <Typography
                  sx={{
                    fontSize: '22px',
                    fontWeight: 400
                  }}
                >
                  {title}
                </Typography>
              </Stack>
            </Grid>

            <Grid item>
              {(isActive && isRemovable) ? (
                <Button
                  data-testid="AdminRoleAccordion-remove"
                  variant="secondary"
                  isLoading={isLoading}
                  onClick={handleRemoveClick}
                >
                  {t('general.remove')}
                </Button>
              ) : (
                <Button
                  data-testid="AdminRoleAccordion-assign"
                  variant="secondary"
                  isLoading={isLoading}
                  onClick={handleAssignClick}
                >
                  {t('general.assign')}
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
      )}
      {...otherProps}
    >
      {children}
    </Accordion>
  )
}
