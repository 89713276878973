import {
  Card,
  Layout,
  RoleCard,
  Skeleton,
  UserCard,
} from "@alice/component_library";
import {
  Grid,
  Stack,
  styled,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Box,
} from "@mui/material";

export const FilterChipSkeleton = ({ filterSize = 1, chipNumber = [3] }) => (
  <>
    <Stack
      direction="row"
      flexWrap="wrap"
      gap="20px 50px"
      sx={{ paddingBottom: "30px" }}
    >
      {[...Array(filterSize)].map((ele, i) => {
        return (
          <Stack direction="column" spacing={1} key={ele}>
            <Skeleton width={100} variant="text" height={20} />
            <Stack direction="row" spacing={2} flexWrap="wrap">
              {[...Array(chipNumber[i])].map((row) => (
                <Skeleton
                  variant="rectangular"
                  height={32}
                  width={100}
                  sx={{ borderRadius: "100px" }}
                  key={row}
                />
              ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  </>
);

export const PageHeaderSkeleton = ({ showAdminSwitch }) => (
  <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="end"
      flexWrap="wrap"
      rowGap="20px"
    >
      <div>
        <Skeleton variant="text" width={200} height="56px" />
        <Skeleton variant="text" width={150} height="24px" />
      </div>
      {showAdminSwitch && (
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{ marginLeft: "auto" }}
        >
          <span>
            <Skeleton variant="text" width={100} height="24px" />
            <Skeleton variant="text" width={100} height="24px" />
          </span>
          <span>
            <Skeleton
              variant="rectangular"
              width={100}
              height={26}
              sx={{ borderRadius: "100px" }}
            />
          </span>
        </Stack>
      )}
    </Stack>

    <Skeleton variant="rectangular" height="56px" sx={{ margin: "24px 0" }} />
  </>
);

export const FilterWithSorting = ({
  filterSize = 1,
  chipNumber = [3],
  showSorting = true,
  sx = {},
}) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    // flexWrap="wrap"
    sx={{ padding: "20px 0", ...sx }}
  >
    <FilterChipSkeleton filterSize={filterSize} chipNumber={chipNumber} />
    {showSorting && (
      <div>
        <Skeleton
          variant="text"
          width={50}
          height={20}
          sx={{ marginBottom: "16px" }}
        />
        <Skeleton variant="text" width={250} height={36} />
      </div>
    )}
  </Stack>
);

export const PaginationSkeleton = () => (
  <Stack
    direction="row"
    spacing={2}
    justifyContent="end"
    alignItems="center"
    sx={{ marginBottom: "15px" }}
  >
    <Skeleton variant="text" width="10%" height="24px" />
    <Skeleton variant="text" width="2%" height="24px" />
    <Skeleton variant="text" width="10%" height="24px" />

    <Skeleton variant="circular" width="18px" height="18px" />
    <Skeleton variant="circular" width="18px" height="18px" />
  </Stack>
);

export const ProfileHeaderSkeleton = () => (
  <Stack
    direction={{ xs: "column", sm: "row" }}
    spacing={2}
    alignItems="center"
    flexWrap="wrap"
    justifyContent={{ xs: "center", sm: "start" }}
  >
    <Skeleton variant="circular" width={115} height={115} />
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
        <Skeleton variant="rectangular" width={300} height={44} />
        <Skeleton variant="rectangular" width={55} height={24} />
      </Stack>
      <Skeleton variant="rectangular" width={75} height={24} />
      <Skeleton variant="rectangular" width={300} height={24} />
    </Stack>
  </Stack>
);

export const ProfileHeaderWithLayoutSkeleton = ({ children }) => (
  <>
    <StyledLayout>
      <Layout>
        <ProfileHeaderSkeleton />
        {children}
      </Layout>
    </StyledLayout>
  </>
);

export const MyDetailsSkelton = () => {
  return (
    <Grid
      container
      sx={{ marginTop: 0, marginLeft: 0, width: "100%", padding: "30px 0" }}
    >
      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton variant="text" width={200} height={36} />
          <Skeleton variant="reactangular" width={80} height={36} />
        </Stack>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={9}
        spacing={{ md: 2 }}
        sx={{ paddingTop: "20px" }}
      >
        {[...Array(10)].map((ele) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={ele}>
            <Skeleton variant="text" width="60%" height={20} />
            <Skeleton variant="text" width="100%" height={36} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const UserCardGridWithHeading = () => (
  <Grid container spacing={2} sx={{ padding: "30px 0" }}>
    <Grid item xs={12}>
      <Skeleton variant="text" width={150} height={24} />
    </Grid>
    {[...Array(12)].map((ele) => (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={ele}>
        <UserCardLoading />
      </Grid>
    ))}
  </Grid>
);

export const OrgCardSkeleton = ({ sx = {} }) => (
  <Card sx={{ padding: "16px", ...sx }}>
    <Stack direction="column" gap="16px">
      <Skeleton variant="rounded" height={28} width={100} />
      <Stack direction="column">
        <Skeleton variant="text" height={32} width={200} />
        <Stack direction="row" gap="16px" alignItems="center">
          <Skeleton variant="rectangular" width={14} height={14} />
          <Skeleton variant="text" width={180} height={24} />
        </Stack>
      </Stack>
      <Stack direction="column">
        {[...Array(2)].map((ele) => (
          <Stack direction="row" key={ele} gap="16px" alignItems="center">
            <Skeleton variant="rectangular" width={14} height={14} />
            <Skeleton variant="text" width={100} height={20} />
          </Stack>
        ))}
      </Stack>
      <Skeleton
        variant="rounded"
        height={28}
        width="100%"
        sx={{ borderRadius: "20px" }}
      />
    </Stack>
  </Card>
);

export const AdminRolesSkeleton = () => (
  <>
    <Skeleton variant="text" width={100} height={50} />
    <Stack
      direction="row"
      alignItems="center"
      gap="20px"
      sx={{ marginBottom: "20px" }}
    >
      <Skeleton
        variant="rounded"
        width={44}
        height={24}
        sx={{ borderRadius: "20px" }}
      />
      <Skeleton variant="text" width="40%" height={50} />
    </Stack>
    {[...Array(10)].map((ele) => (
      <StyledAccordionSkeleton>
        <Skeleton
          variant="text"
          width={250}
          height={38}
          sx={{ marginTop: "4px" }}
          key={ele}
        />
      </StyledAccordionSkeleton>
    ))}
  </>
);

export const ApplicationCardSkeleton = ({ gridLength = 10, ...props }) => (
  <Grid container spacing={2} sx={{ margin: "40px 0" }} {...props}>
    {[...Array(gridLength)].map((ele) => (
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <Card
          sx={{
            padding: "8px",
            height: "100px",
            display: "flex",
            flexDirection: "row",
            columnGap: "20px",
            justifyContent: "space-around",
          }}
        >
          <Skeleton height="85px" variant="rectangular" width="40%" />
          <Stack justifyContent="space-between" sx={{ width: "70%" }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </Stack>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export const RoleCardV2Skeleton = ({ large = false, hideActions = false }) => {
  return (
    <Card sx={{ padding: "8px" }}>
      <Stack
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
      >
        <StyledRoleGrid large={large}>
          <Stack
            direction={large ? "row" : "column"}
            alignItems={large ? "center" : "flex-start"}
            justifyContent="space-between"
            sx={{ gridArea: "name" }}
          >
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={100} />
          </Stack>
          <div className="role-card-name-id">
            {!hideActions ? (
              <Skeleton variant="circular" width={18} height={18} />
            ) : (
              <Skeleton variant="rectangular" width={50} />
            )}
            {large && <Skeleton variant="text" width={70} height={24} />}
          </div>
          <div className="role-card-details">
            {[...Array(3)].map((ele) => (
              <>
                <div className="details-flex" key={ele}>
                  <Skeleton variant="circular" height={18} width={18} />
                  {large && <Skeleton variant="text" height={24} width={100} />}
                </div>
                <Skeleton variant="text" height={24} width={100} />
              </>
            ))}
          </div>
        </StyledRoleGrid>

        {!hideActions && (
          <RoleCardAction large={large}>
            <Skeleton variant="circular" width={18} height={18} />
            <Skeleton
              variant="circular"
              width={18}
              height={18}
              sx={{ alignSelf: "end" }}
            />
          </RoleCardAction>
        )}
      </Stack>
    </Card>
  );
};

export const RoleCardV2GridSkeleton = ({
  isFullWidth = false,
  large = false,
  hideActions = false,
}) => {
  return (
    <Grid container spacing={2}>
      {[...Array(10)].map((ele) => (
        <Grid
          item
          xs={12}
          sm={12}
          xl={isFullWidth ? 12 : 6}
          xxl={isFullWidth ? 12 : 6}
          md={isFullWidth ? 12 : 6}
          key={ele}
        >
          <RoleCardV2Skeleton large={large} hideActions={hideActions} />
        </Grid>
      ))}
    </Grid>
  );
};

export const ConfirmationSkeleton = () => (
  <StyledLayout>
    <Skeleton
      variant="rectangular"
      width={62}
      height={64}
      sx={{ marginTop: "20px" }}
    />
    <Skeleton
      variant="text"
      width="19rem"
      height={28}
      sx={{ margin: "20px 0" }}
    />
    <Skeleton
      variant="text"
      width="19rem"
      height={20}
      sx={{ marginTop: "16px" }}
    />
    <Skeleton
      variant="text"
      width="5rem"
      height={20}
      sx={{ marginBottom: "16px" }}
    />
    <Skeleton
      variant="text"
      width="19rem"
      height={20}
      sx={{ marginTop: "16px" }}
    />
    <Skeleton
      variant="text"
      width="5rem"
      height={20}
      sx={{ marginBottom: "16px" }}
    />
    <Stack direction="row" gap="30px">
      <Skeleton variant="rectangular" width={100} height={36} />
      <Skeleton variant="rectangular" width={70} height={36} />
    </Stack>
  </StyledLayout>
);

export const ContactSupportSkeleted = () => (
  <StyledLayout>
    <Layout>
      <Skeleton
        variant="text"
        height={48}
        width="50%"
        sx={{ marginBottom: "16px" }}
      />
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="text" width="35%" />
      <Skeleton variant="text" width="30%" sx={{ margin: "20px 0" }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Card sx={{ padding: "16px" }}>
            <Stack direction="row" gap="20px">
              <Skeleton variant="rectangular" width={36} height={36} />
              <Stack direction="column">
                <Skeleton variant="text" width="10rem" />
                <Skeleton variant="text" width="10rem" />
                {[...Array(2)].map(() => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="24px"
                    sx={{ marginBottom: "10px" }}
                  >
                    <Skeleton variant="rectangular" width={24} height={24} />
                    <Skeleton variant="text" width="8rem" />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <Skeleton
        variant="text"
        height={48}
        width="40%"
        sx={{ marginBottom: "16px", marginTop: "30px" }}
      />
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="text" width="35%" />
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              padding: "16px",
              marginTop: "16px",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={64}
              height={64}
              sx={{ marginBottom: "16px" }}
            />
            <Skeleton variant="text" width="5rem" />
            <Skeleton variant="text" width="5rem" sx={{ marginTop: "5px" }} />
            <Skeleton
              variant="text"
              width="5rem"
              sx={{ marginBottom: "16px" }}
            />
            <Skeleton
              variant="rectangular"
              width={100}
              height={24}
              sx={{ marginBottom: "16px" }}
            />
          </Card>
        </Grid>
      </Grid>
    </Layout>
  </StyledLayout>
);

export const UserCardLoading = () => (
  <UserCard
    isLoading={true}
    userData={{
      communities: [
        "DAIMLER_EMPLOYEE",
        "DAIMLER_INTERNAL",
        "TEST_USER",
        "TEST_USER__ORG",
      ],
      companyId: "9953",
      companyName: "Mercedes-Benz Tech Innovation GmbH",
      completeName: "KEVIN KELLENBERGER",
      contractor: false,
      country: "DE",
      dealerHomeOrgState: "AUTOMATIC",
      departmentNumber: "ITP/TSS",
      employeeType: "Employee",
      financialServiceConsultant: false,
      givenname: "Kevin",
      groupType: "0",
      homeOrgId: "E-9953",
      id: "KKELLEN",
      isActive: true,
      isSuspended: false,
      mailAddress: "kevin.kellenberger@daimler.com",
      managementLevel: "999",
      mobileNumber: "+49 176 30907259",
      nationalLanguageGivenname: "kevin",
      nationalLanguageSurname: "kellenberger",
      plant1: "415",
      plant2: "415",
      preferredLanguage: "en",
      salesPerson: false,
      sourceProcess: "EWD-S",
      structureId: "H00803060900099",
      supervisor: {
        givenname: "Michael",
        id: "JWANDEL",
        surname: "Meiers",
      },
      supervisorId: "JWANDEL",
      surname: "Kellenberger",
      workingOrgId: "E-9953",
    }}
  />
);

export const RoleCardV3Skeleton = ({
  hideActions = false,
  showMore = true,
  showModifyButton = false,
  size = "small",
  showBody = true,
  showSelection = true,
}) => {
  const reusableContent = (
    <div className="content-detail">
      <Skeleton
        variant="rounded"
        width={24}
        height={24}
        sx={{ marginBottom: "4.5px" }}
      />
      <div className={size === "large" ? "key-value" : ""}>
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={150} />
      </div>
    </div>
  );
  return (
    <RoleCardV3StyledCard
      sx={{
        padding: "16px 16px 8px 16px",
        borderRadius: "4px",
        backgroundColor: "#F8F8F8",
      }}
    >
      <div className="role-card-header">
        <Skeleton
          variant="rounded"
          width={100}
          sx={{ borderRadius: "100px" }}
          height={24}
        />
        <Skeleton
          variant="rounded"
          width={100}
          sx={{ borderRadius: "100px" }}
          height={24}
        />
      </div>
      <Divider />
      <div className="role-card-role-id">
        {showSelection && <Skeleton variant="rounded" width={24} height={24} />}
        <div className={size === "large" ? "role-detail" : ""}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={150} />
        </div>
      </div>
      {showBody ? (
        <>
          <Divider />
          <div className="role-body-detail">
            {reusableContent}
            {reusableContent}
            <div className="role-validity">
              {reusableContent}
              {reusableContent}
            </div>
          </div>
        </>
      ) : null}
      {(!hideActions || showMore || showModifyButton) && (
        <>
          <Divider />
          <div className="role-footer">
            {!hideActions && (
              <div className="button-container">
                <Skeleton variant="rounded" width={40} height={24} />
                {!showModifyButton && (
                  <Skeleton variant="rounded" width={40} height={24} />
                )}
              </div>
            )}
            {showMore && (
              <Skeleton
                variant="rounded"
                sx={{ marginLeft: "auto" }}
                width={24}
                height={24}
              />
            )}
          </div>
        </>
      )}
    </RoleCardV3StyledCard>
  );
};

export const RoleCardV3GridSkeleton = ({
  isFullWidth = false,
  hideActions = false,
  showMore = true,
  showModifyButton = false,
  size = "small",
  gridLength = 10,
  showBody = true,
  showSelection = true,
  ...props
}) => {
  return (
    <Grid container spacing={2} {...props}>
      {[...Array(gridLength)].map((ele) => (
        <Grid
          item
          xs={12}
          sm={12}
          xl={isFullWidth ? 12 : 6}
          xxl={isFullWidth ? 12 : 6}
          md={isFullWidth ? 12 : 6}
          key={ele}
        >
          <RoleCardV3Skeleton
            size={size}
            hideActions={hideActions}
            showModifyButton={showModifyButton}
            showMore={showMore}
            showBody={showBody}
            showSelection={showSelection}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const HomeTaskSkeleton = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "30px",
        "& .task-button": {
          marginTop: "24px",
          marginLeft: "auto",
        },
      }}
    >
      {[...Array(6)].map((el) => (
        <TaskCard key={el}>
          <Grid container className="grid-container">
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" gap="4px">
                <Skeleton
                  variant="circular"
                  sx={{ minWidth: "18px" }}
                  width={18}
                  height={18}
                />
                <Skeleton variant="text" width="8rem" />
              </Stack>
            </Grid>
            <Grid sx={{ display: { xs: "none", lg: "block" } }} item xs={6}>
              <Stack direction="row" alignItems="center" gap="4px">
                <Skeleton
                  variant="circular"
                  sx={{ minWidth: "18px" }}
                  width={18}
                  height={18}
                />
                <Skeleton variant="text" width="8rem" />
              </Stack>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            alignItems="center"
            sx={{ marginLeft: "auto", color: "#0000001F" }}
          >
            <Skeleton
              variant="circular"
              width={18}
              height={18}
              sx={{ marginRight: "8px" }}
            />
            |
            <Skeleton
              variant="circular"
              width={18}
              height={18}
              sx={{ marginLeft: "8px", marginRight: "16px" }}
            />
            <Skeleton variant="circular" width={18} height={18} />
          </Stack>
        </TaskCard>
      ))}
      <div className="task-button">
        <Skeleton variant="rectangular" width="100px" height="30px" />
      </div>
    </Grid>
  );
};

export const ReviewsHome = () => {
  return (
    <>
      <AccessReviewHome>
        <div></div>
        <div className="dates">
          {[...Array(4)].map((el) => (
            <Skeleton variant="rectangular" width={24} height={24} />
          ))}
        </div>
      </AccessReviewHome>
      <AccessReview>
        {[...Array(4)].map((el) => (
          <AccessReviewHome>
            <div>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="80%" />
            </div>
            <Skeleton variant="text" width="80%" />
          </AccessReviewHome>
        ))}
      </AccessReview>
    </>
  );
};

export const ExpiringRolesHome = ({ isDownMd }) => {
  return (
    <>
      {!isDownMd && (
        <>
          {[...Array(5)].map(() => (
            <Card
              sx={{
                display: "grid",
                padding: "16px",
                gap: "16px",
                gridTemplateColumns: "auto auto auto auto auto",
              }}
            >
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Card>
          ))}
        </>
      )}
    </>
  );
};

export const AppListSkeleton = ({ listLength = 5, showDivider = true }) => {
  return (
    <>
      {[...Array(listLength)].map(() => (
        <AppListContainer>
          {showDivider && <Divider />}
          <div className="app-list-card">
            <Skeleton variant="rectangular" width={70} height={44} />
            <div>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
          </div>
        </AppListContainer>
      ))}
    </>
  );
};

export const NewRequestResultSkeleton = () => {
  return (
    <>
      <>
        <StatusHeader>
          <Skeleton width="80%" height={56} variant="text" />
          <Skeleton width={150} height={150} variant="rectangular" />
          <Skeleton width="50%" height={56} variant="text" />
        </StatusHeader>
        <IdContainer>
          <Skeleton width={32} height={32} variant="rectangular" />
          <Skeleton width="20%" height={30} variant="text" />
        </IdContainer>

        <Skeleton
          sx={{ marginLeft: "40px" }}
          width="40%"
          height={56}
          variant="text"
        />
        <RoleCardContainer>
          {[...Array(5)].map((e) => (
            <RoleCard
              key={e}
              data={{
                customScope: {
                  description: "Only car relevant scope",
                  name: "cars relevant scope",
                },
                orgScope: {
                  id: "C-Outlet.DE00002179",
                  name: "Mercedes-Benz AG Niederlassung Nürnberg, Großkunden",
                },
                roleDefinition: {
                  dataClassification: "INTERNAL",
                  defaultValidityType: "OPTIONAL",
                  description:
                    "Alice testrole with orgscope\r\n\r\n\r\nthis has two lines blank above",
                  id: "ROLE_WITH_ORG_SCOPE",
                  isDynamic: false,
                  isJobTitle: false,
                  isSelfRequestable: true,
                  isWorkflowBased: true,
                  name: "Alice_Test_Org",
                  needsAdditionalSelfRequestApproval: false,
                  needsCustomScopes: false,
                  needsOrgScopes: true,
                  roleType: "PRODUCT",
                },
                validTo: "2024-08-01T18:29:59.999Z",
              }}
              sx={{ marginBottom: "8px" }}
              isIdLink
              isLoading
              size="l"
              variant="list"
            />
          ))}
        </RoleCardContainer>
      </>
    </>
  );
};

export const NewRequestDeeplinkSkeleton = () => {
  return (
    <Layout>
      <DeeplinkConatiner>
        <Skeleton variant="circular" width={112} height={112} />
        <div>
          <Skeleton variant="text" width="50%" height={56} />
          <Skeleton variant="text" width="50%" height={24} />
        </div>
      </DeeplinkConatiner>
      <LoadingStepper />
      <Skeleton
        variant="text"
        height={48}
        width="200px"
        sx={{ margin: "32px 0" }}
      />
      <SkeletonBoxContainer>
        <div className="skeleton-box">
          <Skeleton
            variant="rounded"
            width={100}
            height={24}
            sx={{ borderRadius: "50px" }}
          />

          <Skeleton
            variant="rounded"
            width={100}
            height={24}
            sx={{ borderRadius: "50px" }}
          />
        </div>
        <Skeleton variant="text" width={200} height={36} />
        <Skeleton variant="text" width={200} height={36} />
        <Divider sx={{ margin: "20px 0" }} />
        <Skeleton variant="text" width={200} height={36} />
        <div className="second-box">
          <Skeleton variant="rectangular" width={100} height={24} />
          <Skeleton variant="rectangular" width={100} height={24} />
        </div>
        <Divider sx={{ margin: "20px 0" }} />
        <Skeleton variant="text" width={200} height={36} />
        <Skeleton variant="text" width={200} height={36} />
        <Skeleton
          variant="text"
          width={180}
          height={36}
          sx={{ marginLeft: "20px" }}
        />
      </SkeletonBoxContainer>
    </Layout>
  );
};

const steps = ["Step 1", "Step 2", "Step 3"];

const LoadingStepper = () => {
  return (
    <Stepper activeStep={-1}>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel
            sx={{ display: "flex", flexDirection: "column" }}
            icon={<Skeleton variant="circular" width={50} height={50} />}
          >
            <Skeleton width={100} />
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default LoadingStepper;

//styled components for skeletons

export const StyledLayout = styled("div")(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down("lg")]: {
    margin: "0 20px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "40px 0",
  },
}));

export const StyledOverlay = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 64,
  width: "100%",
  height: "100%",
  background: "white",
  zIndex: "1000",
  [theme.breakpoints.up("sm")]: {
    width: "calc(100% - 85px)",
    left: 85,
  },
}));

export const StyledRoleGrid = styled("div")(({ ...props }) => ({
  display: "grid",
  padding: "8px 17px",
  marginRight: "4px",
  width: "100%",
  height: "100%",
  gap: "28.5px",
  gridTemplate: props.large
    ? `"name name name"
    "content content action"
    "content content action"`
    : `"name name action" 0fr
      "content content content" 1fr
      "content content content" 1fr
      / 1fr 1fr 1fr`,
  "& .role-card-details": {
    gridArea: "content",
    display: "grid",
    gridTemplateColumns: "auto minmax(120px, 1fr)",
    gap: "9.5px 24px",
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  "& .role-card-name-id": {
    gridArea: "action",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: props.large ? "end" : "flex-start",
    gap: "6px",
  },
  "& .details-flex": {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
}));

export const RoleCardAction = styled("div")(({ ...props }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr 1fr",
  gap: "0 0",
  marginLeft: "6px",
  padding: props.large ? "12px 0px" : "8px 0",
}));

export const StyledMenuCard = styled("div")(() => ({
  border: "1px solid rgba(0, 0, 0, 0.12)",
  "& .menu-header": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "12px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .menu-item": {
    padding: "12px 20px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
}));

export const StyledAccordionSkeleton = styled("div")(() => ({
  height: "48px",
  borderBottom: "1px solid rgb(230, 230, 230)",
  borderTop: "1px solid rgb(230, 230, 230)",
  marginBottom: "16px",
  verticalAlign: "middle",
}));

export const StyledTaskDetail = styled("div")(() => ({
  borderRadius: "4px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "rgb(255, 255, 255)",
  marginTop: "30px",
  "& .task-header": {
    borderRadius: "4px 4px 0px 0px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "10px",
  },
  "& .task-detail-header": {
    background: "rgb(244, 244, 244)",
    padding: "20px 16px",
  },
  "& .task-detail-padding": {
    padding: "24px 16px",
  },
}));

export const StyledDetailTabLoader = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
}));

export const RoleCardV3StyledCard = styled(Card)(() => ({
  "& .role-card-header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  "& .role-card-role-id": {
    display: "flex",
    padding: "16px 0 8px",
    gap: "16px",
    alignItems: "center",
  },
  "& .role-detail": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .role-body-detail": {
    padding: "16px 0",
  },
  "& .role-validity": {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "27px",
    rowGap: "16px",
  },
  "& .role-footer": {
    display: "flex",
    marginTop: "8px",
    padding: "8px 0",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .button-container": {
    gap: "16px",
    display: "flex",
  },
  "& .key-value": {
    display: "flex",
    gap: "20px",
  },
  "& .content-detail": {
    display: "flex",
    alignItems: "end",
    gap: "8px",
    marginBottom: "16px",
  },
}));

export const TaskCard = styled(Card)(({ theme }) => ({
  padding: "8px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  marginBottom: "8px",
  height: "40px",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    "& .grid-container": {
      width: "calc(90% - 100px)",
    },
  },
}));

export const AccessReviewHome = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 3fr",
  "& .dates": {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    marginBottom: "5px",
  },
}));

export const AccessReview = styled("div")(() => ({
  borderTop: "1px solid rgb(219, 219, 219)",
}));

export const AppListContainer = styled("div")(() => ({
  marginTop: "8px",
  "& .app-list-card": {
    padding: "12px 16px",
    display: "grid",
    gridTemplateColumns: "70px 1fr",
    gap: "16px",
  },
}));

export const RoleCardContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  paddingLeft: "40px",
  margin: "20px 0",
}));

export const IdContainer = styled("div")(() => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
}));
export const StatusHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  alignItems: "center",
  justifyContent: "center",
}));

export const DeeplinkConatiner = styled("div")(() => ({
  display: "grid",
  gap: "32px",
  gridTemplateColumns: "112px 1fr",
  margin: "48px 0",
  alignItems: "center",
}));

export const SkeletonBoxContainer = styled(Box)(() => ({
  border: "1px solid #DBDBDB",
  borderRadius: "4px",
  padding: "32px",
  marginBottom: "32px",
  "& .skeleton-box": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  "& .second-box": { display: "flex", gap: "32px" },
}));

export {
  Card,
  Layout,
  Skeleton,
  Grid,
  Stack,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  UserCard,
};
