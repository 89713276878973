import { AliceService } from './AliceService'

export class DataDispatcherConfigurationsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/datadispatcherconfigurations',
    { ...args }
  )

  static create = ({ ...args }) => AliceService.post(
    '/datadispatcherconfigurations',
    { ...args }
  )

  static updateById = ({
    id,
    ...args
  }) => AliceService.put(
    `/datadispatcherconfigurations/${id}`,
    { ...args }
  )

  static deleteById = ({
    id,
    ...args
  }) => AliceService.delete(
    `/datadispatcherconfigurations/${id}`,
    { ...args }
  )
}
