export const DEV_TOKEN_LINK = 'https://alice-dev.app.corpintra.net/alice-proxy/config'

export const ENVIRONMENTS = [ 'dev', 'test', 'int', 'prod' ]

export const ENVIRONMENT_LINK = {
  LOCAL: 'http://localhost',
  GEMS: 'http://gems',
  DEV: 'https://alice-dev.app.corpintra.net',
  TEST: 'https://alice-test.mercedes-benz.com',
  INT: 'https://alice-int.mercedes-benz.com',
  PROD: 'https://alice.mercedes-benz.com'
}

export const CLIENT_PORT = {
  CONFIG: '3000',
  IDENTITY: '3001',
  ACCESS: '3002',
  ADMIN: '3003'
}

export const CLIENT_URL = {
  CONFIG: 'config',
  IDENTITY: 'identity',
  ACCESS: 'access',
  ADMIN: 'admin'
}

export const CLIENTS = [ 'Access', 'Identity', 'Admin', 'Config' ]

export const CLIENT_HOME = [ '/access', '/config', '/identity', '/admin' ]

export const AllAdminRoles = [
  'apiAdmin',
  'centralHelpdeskAdmin',
  'marketHelpdeskAdmin',
  'globalCommunityAdmin',
  'identStatusAdmin',
  'marketAdmin',
  'roleCreator',
  'applicationAdmin',
  'applicationHelpdeskAdmin',
  'roleOwner',
  'roleApprover',
  'globalRoleAssigner',
  'marketRoleAssigner',
  'organizationRoleAssigner',
  'orgAdmin'
]

export const AllUserEntitlements = [
  'sharedServiceAdmin',
  'connectorConfigurationAdmin',
  'coreConfigurationAdmin',
  'adminRoleAssignmentAdmin',
  'auditLog',
  'billing',
  'functionalAdministration'
]

export const AvailableAdminRoles = [ ...AllAdminRoles ]

export const LANGUAGES = [
  {
    label: 'English (EN)',
    value: 'en',
    flag: 'gb'
  },
  {
    label: 'German (DE)',
    value: 'de',
    flag: 'de'
  },
  {
    label: 'Chinese (ZH)',
    value: 'zh',
    flag: 'cn'
  },
  {
    label: 'Dutch (NL)',
    value: 'nl',
    flag: 'nl'
  },
  {
    label: 'French (FR)',
    value: 'fr',
    flag: 'fr'
  },
  {
    label: 'Hungarian (HU)',
    value: 'hu',
    flag: 'hu'
  },
  {
    label: 'Italian (IT)',
    value: 'it',
    flag: 'it'
  },
  {
    label: 'Japanese (JA)',
    value: 'ja',
    flag: 'jp'
  },
  {
    label: 'Korean (KO)',
    value: 'ko',
    flag: 'kr'
  },
  {
    label: 'Polish (PL)',
    value: 'pl',
    flag: 'pl'
  },
  {
    label: 'Portuguese (PT)',
    value: 'pt',
    flag: 'pt'
  },
  {
    label: 'Russian (RU)',
    value: 'ru',
    flag: 'ru'
  },
  {
    label: 'Spanish (ES)',
    value: 'es',
    flag: 'es'
  },
  {
    label: 'Turkish (TR)',
    value: 'tr',
    flag: 'tr'
  }
]

export const USER_COMMUNITIES = [
  'DEALER_EMPLOYEE',
  'CORPORATE_CUSTOMER_EMPLOYEE',
  'CORPORATE_BUSINESS_PARTNER_PERSON',
  'DAIMLER_INTERNAL',
  'DAIMLER_EXTERNAL',
  'DAIMLER_POOLID',
  'TEST_USER',
  'DAIMLER_JOINT_VENTURE_PERSON',
  'TECH_USER',
  'SUPPLIER_EMPLOYEE',
  'BOT_USER'
]

export const USER_COMMON_COMMUNITIES = [
  'DEALER_EMPLOYEE',
  'CORPORATE_CUSTOMER_EMPLOYEE',
  'CORPORATE_BUSINESS_PARTNER_PERSON',
  'DAIMLER_EMPLOYEE',
  'DAIMLER_INTERNAL',
  'DAIMLER_EXTERNAL',
  'DAIMLER_POOLID',
  'DAIMLER_JOINT_VENTURE_PERSON',
  'SUPPLIER_EMPLOYEE'
]

export const USER_SPECIAL_COMMUNITIES = [ 'TEST_USER', 'TECH_USER', 'BOT_USER' ]

export const USER_COMMUNITY_ICONS = {
  DEALER_EMPLOYEE: 'DEALER',
  CORPORATE_CUSTOMER_EMPLOYEE: 'CORPORATE_CUSTOMER',
  CORPORATE_BUSINESS_PARTNER_PERSON: 'CORPORATE_BUSINESS_PARTNER',
  DAIMLER_INTERNAL: 'EMPLOYEE',
  DAIMLER_EXTERNAL: 'ORG_DAIMLER_COMPANY',
  DAIMLER_POOLID: 'ORG_TEAM',
  TEST_USER: 'ORG_TEST_ORGANIZATION',
  DAIMLER_JOINT_VENTURE_PERSON: 'ORG_PARTNER_ORGANIZATION',
  TECH_USER: 'ORG_TECH_USER',
  SUPPLIER_EMPLOYEE: 'SUPPLIER'
}

export const USER_COMMUNITIES_TRANS = {
  DEALER_EMPLOYEE: 'user_community_dealer',
  CORPORATE_CUSTOMER_EMPLOYEE: 'user_community_isp',
  CORPORATE_BUSINESS_PARTNER_PERSON: 'user_community_business_partner',
  DAIMLER_INTERNAL: 'user_community_internal',
  DAIMLER_EXTERNAL: 'user_community_external',
  DAIMLER_POOLID: 'user_community_pool_id',
  TEST_USER: 'user_community_test_user_all',
  DAIMLER_JOINT_VENTURE_PERSON: 'user_community_joint_venture',
  TECH_USER: 'user_community_tech_user_all',
  SUPPLIER_EMPLOYEE: 'user_community_supplier'
}

export const ORG_COMMUNITIES = [
  'DEALER',
  'CORPORATE_CUSTOMER',
  'EMPLOYEE',
  'SUPPLIER',
  'CORPORATE_BUSINESS_PARTNER',
  'TEST_ORGANIZATION'
]

export const ORG_COMMUNITIES_TRANS = {
  DEALER: 'org_community_dealer',
  CORPORATE_CUSTOMER: 'org_community_isp',
  EMPLOYEE: 'org_community_employee',
  SUPPLIER: 'org_community_supplier',
  CORPORATE_BUSINESS_PARTNER: 'org_community_business_partner',
  TEST_ORGANIZATION: ''
}

export const ORG_FORMS = [
  'WORLD',
  'CONTINENT',
  'COUNTRY',
  'MPC',
  'GENERAL_DISTRIBUTOR',
  'DEALER_GROUP',
  'MARKET_AREA',
  'LEGALENTITY',
  'SUBLOCATION',
  'MAINLOCATION',
  'TEAM',
  'LOCATION',
  'DAIMLER_COMPANY',
  'PARTNER_ORGANIZATION',
  'GROUP',
  'SUPPLIER_ORGANIZATION'
]

export const ORG_FORM_TRANS = {
  WORLD: 'org_type_world',
  CONTINENT: 'org_type_continent',
  COUNTRY: 'org_type_country',
  MPC: 'org_typ_mpc',
  GENERAL_DISTRIBUTOR: 'org_type_general_distributor',
  DEALER_GROUP: 'org_type_dealer_group',
  MARKET_AREA: 'org_type_market_area',
  LEGALENTITY: 'org_type_legal_entity',
  SUBLOCATION: 'org_type_sub_location',
  MAINLOCATION: 'org_type_main_location',
  TEAM: 'org_type_team',
  LOCATION: '',
  DAIMLER_COMPANY: 'org_type_internal',
  UNKNOWN: '',
  PARTNER_ORGANIZATION: 'org_type_business_partner',
  GROUP: '',
  SUPPLIER_ORGANIZATION: 'org_type_supplier'
}

export const BASE_URL = {
  dev: 'https://alice-dev.app.corpintra.net',
  test: 'https://alice-test.mercedes-benz.com',
  int: 'https://alice-int.mercedes-benz.com',
  prod: 'https://alice.mercedes-benz.com',
  local: 'http://localhost:3000'
}

export const TASK_TYPES = [
  'APPROVE_APPLICATIONROLE_TO_ROLE',
  'APPROVE_ORG_ADMIN_PERMISSION',
  'APPROVE_ROLE_ASSIGNMENT',
  'APPROVE_ROLE_DEPROVISIONING',
  'APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT',
  'APPROVE_TECHUSER_TO_APPLICATION',
  'APPROVE_USER_CREATION',
  'APPROVE_USER_TO_ORGANIZATION',
  'USER_REVIEW_MARKET',
  'USER_REVIEW_ORGANIZATION'
]

export const LANGU_COOKIE_NAME = 'alice_ui_language'

export const COUNTRY_LIST = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Aland' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BQ', label: 'Bonaire' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'VG', label: 'British Virgin Islands' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CP', label: 'Clipperton Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curacao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'CD', label: 'Democratic Republic of the Congo' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'TL', label: 'East Timor' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'CI', label: 'Ivory Coast' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar (Burma)' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'AN', label: 'Netherlands Antilles' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'KP', label: 'North Korea' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn Islands' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'CG', label: 'Republic of the Congo' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthelemy' },
  { value: 'SH', label: 'Saint Helena' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'KR', label: 'South Korea' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UM', label: 'U.S. Minor Outlying Islands' },
  { value: 'VI', label: 'U.S. Virgin Islands' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' }
]

export const DISPLAY_VALUE_MAP = {
  DAIMLER_INTERNAL: 'user_community_internal',
  DAIMLER_EXTERNAL: 'user_community_external',
  DAIMLER_POOLID: 'user_community_pool_id',
  DAIMLER_JOINT_VENTURE_PERSON: 'user_community_joint_venture',
  DEALER_EMPLOYEE: 'user_community_dealer',
  CORPORATE_CUSTOMER_EMPLOYEE: 'user_community_isp',
  CORPORATE_BUSINESS_PARTNER_PERSON: 'user_community_business_partner',
  SUPPLIER_EMPLOYEE: 'user_community_supplier',
  TECH_USER__DELEGATED: 'user_community_tech_user_delegated',
  TECH_USER__SYSTEM: 'user_community_tech_user_system',
  TECH_USER__GLOBAL: 'user_community_tech_user_global',
  TEST_USER__ORG: 'user_community_test_user_organisation',
  TEST_USER__APP: 'user_community_test_user_application',
  'DAIMLER_EXTERNAL, DAIMLER_EMPLOYEE': 'user_community_external',
  'DAIMLER_POOLID, DAIMLER_EMPLOYEE': 'user_community_pool_id',
  'DAIMLER_JOINT_VENTURE_PERSON, DAIMLER_EMPLOYEE':
    'user_community_joint_venture',
  'TEST_USER__ORG, DEALER_EMPLOYEE':
    'user_community_test_user_organisation_dealer',
  'TEST_USER__ORG, SUPPLIER_EMPLOYEE':
    'user_community_test_user_organisation_supplier',
  'TEST_USER__ORG, CORPORATE_CUSTOMER_EMPLOYEE':
    'user_community_test_user_organisation_isp',
  'TEST_USER__ORG, CORPORATE_BUSINESS_PARTNER_PERSON':
    'user_community_test_user_organisation_business_partner',
  'TEST_USER__APP, DEALER_EMPLOYEE':
    'user_community_test_user_application_dealer',
  'TEST_USER__APP, CORPORATE_CUSTOMER_EMPLOYEE':
    'user_community_test_user_application_isp',
  DAIMLER_EMPLOYEE: 'user_community_external',
  EMPLOYEE: 'org_community_employee',
  DEALER: 'org_community_dealer',
  CORPORATE_CUSTOMER: 'org_community_isp',
  CORPORATE_BUSINESS_PARTNER: 'org_community_business_partner',
  SUPPLIER: 'org_community_supplier',
  DAIMLER_COMPANY: 'org_type_internal',
  LEGALENTITY: 'org_type_legal_entity',
  MAINLOCATION: 'org_type_main_location',
  SUBLOCATION: 'org_type_sub_location',
  WORLD: 'org_type_world',
  CONTINENT: 'org_type_continent',
  COUNTRY: 'org_type_country',
  MARKET_AREA: 'org_type_market_area',
  DEALER_GROUP: 'org_type_dealer_group',
  GENERAL_DISTRIBUTOR: 'org_type_general_distributor',
  MPC: 'org_typ_mpc',
  PARTNER_ORGANIZATION: 'org_type_business_partner',
  SUPPLIER_ORGANIZATION: 'org_type_supplier',
  TEAM: 'org_type_team',
  IndependentCompany: 'company_form_independent_company',
  Affiliate: 'company_form_affiliate',
  TECH_USER: 'user_community_tech_user_all',
  TEST_USER: 'user_community_test_user_all'
}
