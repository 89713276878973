export const Client = {
  CONFIG: 'config',
  IDENTITY: 'identity',
  ACCESS: 'access',
  ADMIN: 'admin'
}

export const ClientsKeys = Object.keys(Client)
export const ClientsValues = Object.values(Client)

export const Clients = ClientsValues
