import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Accordion } from '../../accordion'

export const AdminRolesAccordion = forwardRef(({
  children,
  title
}, ref) => (
  <Accordion
    data-testid="AdminRolesAccordion"
    ref={ref}
    title={title && <Typography variant="h6">{title}</Typography>}
  >
    {children}
  </Accordion>
))

AdminRolesAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}
