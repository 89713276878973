export const getOrgColumns = (isMobile) => {
  if (isMobile) return ["name"];

  return [
    "orgForm",
    "name",
    "id",
    "groupType",
    "community",
    "city",
    "country",
    "status",
  ];
};
