import { Box, Divider, Popover, Stack } from '@mui/material'
import { isValid } from 'date-fns'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../button'
import { DatePicker } from '../datePicker'
import { DatePickerDialogHeader } from '../datePickerDialog/DatePickerDialogHeader'
import { RangeInput } from '../datePickerDialog/RangeInput'

/**
 * This is the base example of DatePickerPopover.
 *
 * To set DatePicker to receive range values add prop `isRange={ true }`
 *
 * Docu for the 'disabledMatcher'
 * https://react-day-picker.js.org/api/types/Matcher
 */
export const DatePickerPopover = forwardRef((
  {
    open,
    onClose,
    onChange,
    onConfirm,
    isRange = false,
    parentFrom = undefined,
    parentTo = undefined,
    disabledMatcher,
    anchorEl = null
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const [ dates, setDates ] = useState(null)
  const [ isEditOpen, setIsEditOpen ] = useState(true)

  const { from: dateFrom = '', to: dateTo = '' } = dates || {}

  const validatedFrom = isValid(dateFrom) ? dateFrom : undefined
  const validatedTo = isValid(dateTo) ? dateTo : undefined

  const validatedParentFrom = isValid(parentFrom) ? parentFrom : undefined
  const validatedParentTo = isValid(parentTo) ? parentTo : undefined

  const isButtonDisabled = useMemo(() => (
    isRange
      ? (!!validatedFrom) && (!!validatedTo)
      : !!validatedFrom
  ), [ isRange, validatedFrom, validatedTo ])

  useEffect(() => {
    if (dates && open) onChange(dates)
  }, [ dates, onChange, open ])

  const handleOnClose = useCallback(() => {
    setDates(null)

    onClose(null)
  }, [ onClose ])

  const handleOnConfirm = useCallback((e) => {
    e.preventDefault()

    onConfirm(dates || { from: parentFrom, to: parentTo })

    onClose(dates)

    setDates(null)
  }, [ dates, onClose, onConfirm, parentFrom, parentTo ])

  return (
    <Popover
      data-testid="DatePickerPopover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      marginThreshold={16} /* Otherwise console is getting spammed with falsy errors. */
      keepMounted /* Needs to be mounted. Otherwise, there are jumps on first open. */
      disablePortal
      disableScrollLock
      sx={{ marginTop: 1 }}
    >
      <Box sx={{ p: '16px 12px 12px 24px' }}>
        <DatePickerDialogHeader
          parentFrom={validatedFrom || validatedParentFrom}
          parentTo={validatedTo || validatedParentTo}
          isEditOpen={isEditOpen}
          setIsEditOpen={setIsEditOpen}
          isRange={isRange}
        />
      </Box>

      <Divider sx={{ marginInline: -2 }} />

      {!isEditOpen
        ? (
          <DatePicker
            onChange={setDates}
            parentFrom={validatedFrom || validatedParentFrom}
            parentTo={validatedTo || validatedParentTo}
            isRange={isRange}
            disabledMatcher={disabledMatcher}
          />
        )
        : (
          <RangeInput
            onChange={setDates}
            parentFrom={validatedFrom || validatedParentFrom}
            parentTo={validatedTo || validatedParentTo}
            isRange={isRange}
          />
        )}

      <Stack
        sx={{
          flexDirection: 'row',
          gap: 2,
          p: '8px 12px 12px',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          data-testid="DatePickerDialog-cancel"
          variant="tertiary"
          size="small"
          onClick={handleOnClose}
        >
          {t('general.cancel')}
        </Button>

        <Button
          data-testid="DatePickerDialog-confirm"
          variant="tertiary"
          disabled={!isButtonDisabled}
          form="rangeInput"
          type="submit"
          size="small"
          onClick={handleOnConfirm}
        >
          {t('general.ok')}
        </Button>
      </Stack>
    </Popover>
  )
})

DatePickerPopover.propTypes = {
  /** determines current state of dialog popup */
  open: PropTypes.bool.isRequired,
  /** determines mode of datePicker 'range' or 'single' */
  isRange: PropTypes.bool,
  /**
   * onClose Event
   *
   * @returns {void}
   */
  onClose: PropTypes.func.isRequired,
  /**
   * onChange event with state updater func inside
   *
   * @param dates
   * @returns {void}
   */
  onChange: PropTypes.func.isRequired,
  /**
   * onConfirm event
   *
   * @param dates
   * @returns {void}
   */
  onConfirm: PropTypes.func.isRequired,
  /** determines start date for manual handling */
  parentFrom: PropTypes.instanceOf(Date),
  /** determines end date for manual handling */
  parentTo: PropTypes.instanceOf(Date),
  /**
   * A value or a function that matches a day or range
   * https://react-day-picker.js.org/api/types/Matcher
   */
  disabledMatcher: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
    PropTypes.array
  ]),
  /** Anchor element. Defines position of the Popover element */
  anchorEl: PropTypes.instanceOf(Element)
}
