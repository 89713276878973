import React, { useEffect, useCallback, useState, useRef, memo } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NewTable, DateUtils } from "@alice/component_library";
import { useWidth } from "@utils/hooks";
import { CLService } from "@src/services/CLServices";
import { areArraysEqual } from "@src/utils/arrayUtils";
import { managePopup } from "@src/actions";
import ActionButtonComponent from "./ActionButtons";
import { NewtableContainer } from "../styledComponents";
import {
  actionbuttonRenderer,
  dataRenderer,
  getTitle,
  ValidityChip,
} from "./TableData";
import MoreDetails from "./MoreDetailsComponent";
import CustomPopUps from "../RoleReviews";

const DATE_OPTIONS_LONG = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const ReviewTable = ({
  searchValue,
  filterValue,
  roleReviewId,
  rolesDataInfo = {},
  countInfo,
  reviewStatus,
  disableActions = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filter, setFilter] = useState(
    filterValue === "done"
      ? {
          decision:
            "CONFIRMED,decision==MODIFIED,decision==DELETED,decision==DELETED_EXT",
        }
      : { decision: "NONE" }
  );
  const [expandedIds, setExpandedIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTabledata] = useState([]);
  const [currentSelectedIds, setCurrentSelectedIds] = useState([]);
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm", "md"].includes(widthPanel);
  const tableRef = useRef(null);
  const buttonChildRef = useRef(null);
  const { needsCustomScopes = true, needsOrgScopes = true } = rolesDataInfo;
  const query = searchValue?.length ? `'*${searchValue.trim()}*'` : "";

  const columns = disableActions
    ? [
        "roleToUser.user.surname",
        "roleToUser.user.givenname",
        "roleToUser.validFrom",
        "roleToUser.validTo",
      ]
    : [
        "roleToUser.user.surname",
        "roleToUser.user.givenname",
        "roleToUser.validFrom",
        "roleToUser.validTo",
        "decision",
      ];

  useEffect(() => {
    tableRef.current.setPageIndex(0);
    const searchFilters = query.length
      ? {
          "(roleToUser.user.search": `${query},roleToUser.orgScope.name==${query},roleToUser.customScope.name==${query})`,
        }
      : {};

    const decisionFilters =
      filterValue === "done"
        ? {
            "(decision":
              "CONFIRMED,decision==MODIFIED,decision==DELETED,decision==DELETED_EXT)",
          }
        : { decision: "NONE" };

    setFilter(query.length ? { ...filter, ...searchFilters } : decisionFilters);
  }, [query]);

  useEffect(() => {
    const currenTableDataIds = selectedRows.map((item) => item.uuid);
    tableRef.current.updateSelection(currenTableDataIds, true);
    tableRef.current.setPageIndex(0);
    const decisionFilters =
      filterValue === "done"
        ? {
            "(decision":
              "CONFIRMED,decision==MODIFIED,decision==DELETED,decision==DELETED_EXT)",
          }
        : { decision: "NONE" };
    const searchFilters = query.length
      ? {
          "(roleToUser.user.search": `${query},roleToUser.orgScope.name==${query},roleToUser.customScope.name==${query})`,
        }
      : {};
    setFilter({ ...decisionFilters, ...searchFilters });
  }, [filterValue]);

  useEffect(() => {
    if (isMobile && tableData?.length > 0) {
      setExpandedIds(tableData?.map((obj) => obj.uuid));
    } else if (filterValue === "done") {
      setExpandedIds(
        tableData
          ?.filter(
            (assignment) =>
              assignment.decision === "DELETED" ||
              assignment.decision === "MODIFIED" ||
              assignment.decision === "CONFIRMED" ||
              assignment.decision === "DELETED_EXT"
          )
          .map((obj) => obj.uuid)
      );
    } else {
      setExpandedIds([]);
    }
  }, [filterValue, isMobile, tableData]);

  useEffect(() => {
    setSelectedRows([]);
    if (tableRef?.current) {
      tableRef?.current?.setFilters(filter);
    }
  }, [filter]);

  if (needsCustomScopes && needsOrgScopes) {
    columns.splice(2, 0, "roleToUser.orgScope.name");
    columns.splice(3, 0, "roleToUser.customScope.name");
  } else if (needsOrgScopes) {
    columns.splice(2, 0, "roleToUser.orgScope.name");
  } else if (needsCustomScopes) {
    columns.splice(2, 0, "roleToUser.customScope.name");
  }

  const getResponseData = (data) => {
    setTabledata(
      tableData.concat(
        data.assignments.filter(
          (assigmanetData) =>
            !tableData.find(
              (tableAssignment) => tableAssignment.uuid === assigmanetData.uuid
            )
        )
      )
    );

    data.assignments.forEach((record) => {
      const fromDataValidity = new Date(
        record?.roleToUser?.newValidFrom ?? record.roleToUser.validFrom
      ).toLocaleDateString(undefined, DATE_OPTIONS_LONG);
      const toDataValidity = new Date(
        record?.roleToUser?.newValidTo ?? record.roleToUser.validTo
      ).toLocaleDateString(undefined, DATE_OPTIONS_LONG);
      const daysLefttoValidity = DateUtils.daysBetweenDates(
        new Date(),
        new Date(record?.roleToUser?.newValidTo ?? record.roleToUser.validTo)
      );
      const validityChipForFromDate =
        record?.roleToUser?.newValidFrom || record.roleToUser.validFrom ? (
          <ValidityChip
            sx={{
              "& .MuiDivider-root, .MuiStack-root": { display: "none" },
              background: record?.roleToUser?.newValidFrom
                ? "#BBBBBB"
                : "white",
              "& .chipDateWrapper": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              pointerEvents: "none",
            }}
            formattedExpiryDate={fromDataValidity}
          />
        ) : (
          <span className="text-center">-</span>
        );
      const validityChipForToDate =
        record?.roleToUser?.newValidTo || record.roleToUser.validTo ? (
          <ValidityChip
            remainingDaysToRed={0}
            sx={{
              "& .MuiDivider-root, .MuiStack-root": { display: "none" },
              pointerEvents: "none",
              ...(record?.roleToUser?.newValidTo
                ? { background: "#BBBBBB" }
                : {}),
            }}
            formattedExpiryDate={toDataValidity}
            daysLeft={daysLefttoValidity}
          />
        ) : (
          <span className="text-center">-</span>
        );
      record.roleToUser.validityFrom = validityChipForFromDate;
      record.roleToUser.validityTo = validityChipForToDate;
    });
    return data.assignments;
  };

  const getExpandedRow = ({ row, id }) => {
    return {
      heading: t("details"),
      children: (
        <MoreDetails
          row={row}
          disabled={isSelected(row.uuid)}
          needsCustomScopes={needsCustomScopes}
          needsOrgScopes={needsOrgScopes}
          disableActions={disableActions}
        />
      ),
      contentSx: {},
    };
  };

  const handleActions = useCallback(
    (type) => {
      dispatch(
        managePopup({
          open: true,
          type: type,
          bulkProcessing: selectedRows.length === 0 || selectedRows.length > 1,
          selectedId: selectedRows,
          countInfo:
            selectedRows.length === 0 ? countInfo : selectedRows.length, // add when selected all
        })
      );
    },
    [countInfo, dispatch, selectedRows]
  );

  const onRowClick = ({ data, id, isSelected }) => {
    if (isSelected) {
      setSelectedRows([...selectedRows, data]);
    } else {
      setSelectedRows(selectedRows.filter((obj) => obj.uuid !== id));
    }
  };

  const onSelectionChange = (data) => {
    if (!areArraysEqual(data, currentSelectedIds)) {
      setCurrentSelectedIds(data);
      setSelectedRows(tableData.filter((item) => data.includes(item.uuid)));
    }
  };

  const resetSelectedids = () => {
    setSelectedRows([]);
    tableRef.current.updateSelection(currentSelectedIds, true);
    setCurrentSelectedIds([]);
  };

  const isSelected = (id) => selectedRows.some((item) => item.uuid === id);

  const dataRendenrerHandler = (
    key,
    row,
    rolesDataInfo,
    roleReviewId,
    disableActions
  ) => {
    return key === "decision" && !disableActions
      ? actionbuttonRenderer(
          key,
          row,
          rolesDataInfo,
          roleReviewId,
          isSelected(row.uuid) || disableActions
        )
      : dataRenderer(key, row, rolesDataInfo, roleReviewId);
  };

  return (
    <>
      <NewtableContainer isSelectAll={false}>
        {filterValue === "open" && countInfo > 0 && !disableActions ? (
          <>
            <ActionButtonComponent
              ref={buttonChildRef}
              handleConfirmAction={(type) => handleActions(type)}
              isButtonDisabled={disableActions}
              translate={t}
              buttonConfirmTextRef={
                selectedRows.length === 0
                  ? t("confirm-all-assignments")
                  : t(
                      selectedRows.length === 1
                        ? "confirm-count-assignment"
                        : "confirm-count-assignments",
                      {
                        count: selectedRows.length,
                      }
                    )
              }
              buttonDeleteTextRef={
                selectedRows.length === 0
                  ? t("remove-all-assignments")
                  : t(
                      selectedRows.length === 1
                        ? "remove-count-assignment"
                        : "remove-count-assignments",
                      {
                        count: selectedRows.length,
                      }
                    )
              }
            />
          </>
        ) : null}

        <NewTable
          ref={tableRef}
          columns={
            isMobile
              ? [
                  "roleToUser.user.id",
                  filterValue === "done" && !disableActions
                    ? "decision"
                    : "roleToUser.validFrom",
                ]
              : columns
          }
          entryRenderer={(key, row) =>
            dataRendenrerHandler(
              key,
              row,
              rolesDataInfo,
              roleReviewId,
              disableActions
            )
          }
          getResponseData={(data) => getResponseData(data)}
          titleRenderer={(key) => getTitle(key)}
          onRowClick={onRowClick}
          onSelectionChange={(data) => onSelectionChange(data)}
          interaction={
            filterValue === "open" && countInfo > 0 && !disableActions
              ? "multiSelect"
              : ""
          }
          // disabledIds={disableActions ? tableData?.map((obj) => obj.uuid) : []}
          apiFunction={(config) => {
            config.filters = { ...config.filters, ...filter };
            return CLService.getAccessReviewDetail({
              id: roleReviewId,
              ...config,
            });
          }}
          noDataPlaceholder={
            <Typography>
              {reviewStatus === "ACTIVE"
                ? t("no-done-assignments")
                : t("no-open-assignments")}
            </Typography>
          }
          selectablePageSizes={[12, 15, 20]}
          rowIdKey="uuid"
          responseDataItemsKey="assignments"
          preselectedSearch={query}
          {...(expandedIds.length
            ? {
                expandIds: { expandedIds },
                getRowExpandProps: getExpandedRow,
              }
            : {})}
        />
        <CustomPopUps
          workflowId={roleReviewId}
          t={t}
          search={searchValue}
          sortOrder={""}
          tableRef={tableRef}
          resetHandler={resetSelectedids}
        />
      </NewtableContainer>
    </>
  );
};

const NewTableRenderer = (prevProps, nextProps) => {
  return (
    nextProps.searchValue === prevProps.searchValue &&
    nextProps.roleReviewId === prevProps.roleReviewId &&
    nextProps.filterValue === prevProps.filterValue &&
    nextProps.countInfo === prevProps.countInfo &&
    nextProps.rolesDataInfo === prevProps.rolesDataInfo
  );
};

export default memo(ReviewTable, NewTableRenderer);
