import { useState } from 'react'
import { useInteraction } from '../../../hooks'

export const useSearchExpandDecorator = (Story, { args }) => {
  const [ expandedApplication, setExpandedApplication ] = useState(null)

  const onExpandClick = ({ id }) => {
    if (id === expandedApplication) setExpandedApplication(null)
    else setExpandedApplication(id)
  }

  return Story({
    args: {
      ...args,
      expandedApplication,
      onExpandClick
    }
  })
}

export const useSearchSelectDecorator = (Story, { args }) => {
  const [ selectedData, setSelectedData ] = useState([])

  const { isMultiselect } = useInteraction({ interaction: args.interaction })

  const onCardClick = ({ id }) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData.filter((data) => data !== id))
    } else if (isMultiselect) {
      setSelectedData([ ...selectedData, id ])
    } else {
      setSelectedData([ id ])
    }
  }

  return Story({
    args: {
      ...args,
      selectedData,
      onCardClick
    }
  })
}
