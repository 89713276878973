import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'
import { Button } from '../button/Button'

/**
 * Simple implementation of a create button.
 *
 * Properties of [Button-Component](./?path=/docs/alice-ui-buttons-button--docs) are also available.
 */
export const CreateButton = forwardRef((
  {
    icon: Icon,
    isFullWidth = false,
    onClick = () => {},
    sx = [],
    title,
    description,
    ...otherProps
  },
  ref
) => (
  <Button
    data-testid="CreateButton"
    ref={ref}
    sx={mergeSxProps(
      {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 2,
        padding: '14px 16px',
        height: '100%',
        borderRadius: '4px',
        width: isFullWidth ? '100%' : undefined,
        '&:hover .iconBox': { backgroundColor: 'text.disabled' },
        '&:disabled .iconBox': { backgroundColor: 'text.disabled' }
      },
      sx
    )}
    variant="primary"
    onClick={onClick}
    {...otherProps}
  >
    <Box
      className="iconBox"
      sx={{
        backgroundColor: 'primary.dark',
        borderRadius: '50%',
        height: '48px',
        width: '48px',
        padding: '10px',
        transition: 'background-color 250ms ease'
      }}
    >
      <Icon sx={{ width: '28px', height: '28px' }} />
    </Box>

    <Stack sx={{ alignItems: 'flex-start' }}>
      <Typography variant="subtitle1">
        {title}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          display: '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: isFullWidth ? '100%' : '160px',
          lineHeight: '16px',
          textAlign: 'start'
        }}
      >
        {description}
      </Typography>
    </Stack>
  </Button>
))

CreateButton.propTypes = {
  /** Icon to display */
  icon: PropTypes.any,
  /** Defines if the button is using full width or not */
  isFullWidth: PropTypes.bool,
  /** onClick callback */
  onClick: PropTypes.func,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ]),
  /** Title to display */
  title: PropTypes.string,
  /** Description to display */
  description: PropTypes.string
}
