import React from "react";
import ReactDOM from "react-dom";
import { StyledOverlay } from "./CommonSkeletons";

const SkeletonOverlay = ({ isOpen, children }) => {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <StyledOverlay>{children}</StyledOverlay>,
    document.body
  );
};

export default SkeletonOverlay;
