import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import { usePermission } from '../../../../hooks'
import { getLink, mergeSxProps, user as UserUtils } from '../../../../util'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import { IdTitle } from '../../../card/genericCard'

export const UserAvatarInfo = ({
  user: {
    id,
    givenname,
    surname,
    groupType,
    communities
  } = {},
  size = 'l',
  isIdLink = true,
  isLoading = false,
  isSurnameFirst = false,
  isGroupTypeShown = false,
  isAdminRolesOverwritten = false,
  sx = {} || []
}) => {
  const { isAdmin } = usePermission()

  return (
    <Stack
      className="userAvatarInfo"
      sx={mergeSxProps(
        [
          {
            flexDirection: 'row',
            gap: 2,
            height: 'fit-content',
            alignItems: 'center',
            overflow: 'hidden'
          }
        ],
        sx
      )}
    >
      <UserAvatar
        userId={id}
        userName={`${givenname} ${surname}`}
        size={size}
        groupType={groupType}
        showImage={UserUtils.is.internalEmployee(communities)}
        showGroupType={isGroupTypeShown}
        communities={communities}
        isLoading={isLoading}
      />

      <IdTitle
        id={id}
        title={isSurnameFirst ? `${surname}, ${givenname}` : `${givenname} ${surname}`}
        size={size === 'l' ? 'm' : size}
        href={getLink('userDetails', { userId: id })}
        isLoading={isLoading}
        isLink={isIdLink && (isAdmin || isAdminRolesOverwritten)}
      />
    </Stack>
  )
}

UserAvatarInfo.propTypes = {
  /** User to display */
  user: PropTypes.shape({
    id: PropTypes.string,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    departmentNumber: PropTypes.string
  }),
  /** Variant of the component */
  size: PropTypes.oneOfType([ PropTypes.string, PropTypes.oneOf([ 's', 'm', 'l' ]) ]),
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note: Even if `isIdLink` is true, visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Loading state */
  isLoading: PropTypes.bool,
  /** Places the surname in front of the given name */
  isSurnameFirst: PropTypes.bool,
  /** Displays group type at user avatar */
  isGroupTypeShown: PropTypes.bool
}
