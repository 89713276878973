export const SortOrder = {
  ASCENDING: '+',
  DESCENDING: '-'
}

export class SortParser {
  static toAlice({ sort, order = SortOrder.ASCENDING }) {
    return { sort: `${order}${sort}` }
  }

  static toNotificationModule({ sort, order = SortOrder.ASCENDING }) {
    return {
      sort_direction: order === SortOrder.ASCENDING ? 'ASC' : 'DESC',
      sort_by: sort
    }
  }
}
