import { Typography } from "@mui/material";
import {
  CardDetailsHeader,
  RequestidHeader,
  RequestidLink,
} from "./CardStyledComponent";
import { Tooltip } from "@alice/component_library";

const BgColor = {
  PENDING: "#E6F5FF",
  ACTIVE: "#E6F5FF",
  APPROVED: "#E9F6EA",
  COMPLETED: "#E9F6EA",
  EXPIRED: "#DBDBDB",
  SUSPENDED: "#DBDBDB",
  FAILED: "#FFFAE6",
  REJECTED: "#FFEDED;",
  EXTERNALLY_TERMINATED: "#FFEDED;",
  INTERNALLY_TERMINATED: "#FFEDED;",
};

const CardStatusHeader = ({
  t,
  requestheadericon,
  requestName,
  windowSize,
  statusChip,
  calender_icon,
  close_icon,
  onClose,
  taskInfo,
  RequestInfoText,
  deeplink,
}) => {
  const { state } = taskInfo;

  return (
    <CardDetailsHeader bgcolor={BgColor[state]} windowsize={windowSize}>
      <div className="request-container">
        {!deeplink && (
          <>
            <div className="request-icon-id">
              <div className="request-icon">{requestheadericon}</div>
              <div className="request-id">
                <RequestidHeader tooltip={requestName}>
                  {requestName}
                </RequestidHeader>
                <RequestidLink
                  to={`/access/requests/details?taskId=${taskInfo.id}`}
                >
                  {taskInfo.id}
                </RequestidLink>
              </div>
            </div>
            <div onClick={onClose} className="request-close-icon-sm">
              <span>{close_icon}</span>
            </div>
          </>
        )}
        <div className="RequestDetails-header-chip">{statusChip}</div>
        {!deeplink && (
          <div className="Request-header-date">
            {calender_icon}
            <div className="request-info">
              <Typography variant="body1">{RequestInfoText}</Typography>
            </div>
          </div>
        )}
      </div>
      {!deeplink && (
        <div onClick={onClose} className="request-close-icon-lg">
          <span>{close_icon}</span>
        </div>
      )}
    </CardDetailsHeader>
  );
};

export default CardStatusHeader;
