/**
 * Wraps the functions of a map to the Api of LocalStorage and SessionStorage
 * Results in less handling of storage types in the useCache hook
 */
export class CacheMemory {
  constructor() {
    this.cache = new Map()
    this.length = this.cache.size
  }

  key(index) { return Array.from(this.cache.keys())[index] }

  getItem(key) { return this.cache.get(key) }

  setItem(key, value) {
    this.cache.set(key, value)
    this.length = this.cache.size
  }

  removeItem(key) {
    this.cache.delete(key)
    this.length = this.cache.size
  }

  clear() {
    this.cache.clear()
    this.length = this.cache.size
  }
}
