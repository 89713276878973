import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  AliceIcon,
  AliceIconType,
  Table,
  UserCard,
} from "@alice/component_library";
import {
  SubTopicContainer,
  SubHeader,
  SubHeaderTypography,
  TaskRequestDetailsContainer,
  TaskRequestHeader,
} from "./StyledComponents";
import { RoleCard } from "./RoleCardComponent";

const DeprovisioningReview = ({ taskInfo, windowSize }) => {
  const { t } = useTranslation();
  const { state, affectedData = {}, id } = taskInfo;
  const { user = {} } = affectedData;
  const { isMobile } = windowSize;

  let headerConfig = [
    {
      key: t("attribute_name"),
      Header: t("attribute_name"),
    },
    {
      key: t("old_value"),
      Header: t("old_value"),
    },
    {
      key: t("new_name"),
      Header: t("new_name"),
    },
  ];

  let data = [
    ["dcxDeptld", user?.oldDeptId, user?.newDeptId],
    ["Department", user?.oldDepartmentNumber, user?.newDepartmentNumber],
    [
      "SUPERVISOR",
      user.oldSupervisor?.givenname + " " + user.oldSupervisor?.surname,
      user.newSupervisor?.givenname + " " + user.newSupervisor?.surname,
    ],
  ];
  return (
    <TaskRequestDetailsContainer disabled={state !== "PENDING"}>
      <TaskRequestHeader>
        {t("task-type-details-header.deprovisioning-review-details")}
      </TaskRequestHeader>
      <Grid container>
        <SubTopicContainer xs={12}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("role")}</SubHeaderTypography>
          </SubHeader>
        </SubTopicContainer>
        <RoleCard taskInfo={taskInfo} isMobile={isMobile} />
      </Grid>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.USER_OUTLINE}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("affected-user")}</SubHeaderTypography>
          </SubHeader>
          {/* <BeneficiaryCard
            userData={user}
            isTechUser={taskType === "APPROVE_TECHUSER_TO_APPLICATION"}
          /> */}

          <UserCard
            userData={user}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
          />

          <Grid container>
            <SubTopicContainer
              xs={12}
              sx={{ width: "37vw", flexBasis: "100%" }}
            >
              <SubHeader>
                <AliceIcon
                  iconType={AliceIconType.INFO_CIRCLE}
                  size={18}
                  sx={{ pointerEvents: "none", cursor: "not-allowed" }}
                  color={"#00000099"}
                />
                <SubHeaderTypography>
                  {t("additional-information")}
                </SubHeaderTypography>
              </SubHeader>
              <Table
                columns={headerConfig}
                data={data}
                infoText={{ multiple: t("entries-shown") }}
              />
            </SubTopicContainer>
          </Grid>
        </SubTopicContainer>
      </Grid>
    </TaskRequestDetailsContainer>
  );
};

export default DeprovisioningReview;
