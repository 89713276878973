import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DataClassificationChip, Tooltip } from "@alice/component_library";
import LinearProgressBarWithValue from "@components/ProgressBar";
import MultiProfileImageComponent from "@components/MultiProfileImageComponent";
import CustomValidityChip from "@components/CustomValidityChip";

export const getColumnName = (filterValue) => {
  const { t } = useTranslation();

  switch (filterValue) {
    case "all":
      return t("role-review.timestamp");
    case "OPEN":
    case "TIMEOUT":
      return t("role-review.deadline");
    case "COMPLETED":
      return t("role-review.completiondate");
    default:
      return t("role-review.timestamp");
  }
};

export const getTitle = (key, filterValue) => {
  const { t } = useTranslation();

  switch (key) {
    case "roleId":
      return t("role-id");
    case "roleName":
      return t("role-name");
    case "dataClassification":
      return t("classification");
    case "startTime":
      return getColumnName(filterValue);
    case "reviewers.id":
      return <span className="disable-sort">{t("role-review.reviewers")}</span>;
    case "processedAssignments":
      return t("role-review.status");
    default:
      break;
  }
};

export const dataRenderer = (
  key,
  data,
  handleRowClick,
  overdue,
  done,
  onBadgeClick
) => {
  const id = data?.workflowId;
  const startTime = new Date(data?.startTime);
  const state = data?.state;
  let overdueCount = 0;
  const deadline =
    data?.state === "COMPLETED"
      ? "endTime" in data
        ? new Date(data?.endTime)
        : "_"
      : startTime?.setDate(startTime?.getDate() + 28);
  const roleId = (
    <Link
      to={`/access/accessReview/${id}`}
      style={{ textDecoration: "none", color: "rgb(0, 121, 214)" }}
    >
      <span className="data-elipsis">
        <Tooltip
          type="ellipsized"
          title={data?.roleId}
          onClick={() => handleRowClick({ id: id })}
        >
          {data?.roleId}
        </Tooltip>
      </span>
    </Link>
  );
  const roleName = (
    <span className="data-elipsis">
      <Tooltip
        type="ellipsized"
        title={data?.roleName}
        onClick={() => handleRowClick({ id: id })}
      >
        {data?.roleName}
      </Tooltip>
    </span>
  );
  const dataClassification = (
    <DataClassificationChip
      type={data?.dataClassification?.toLowerCase()}
      variant="default" //expandable
    />
  );
  const deadLineComp =
    deadline !== "_" ? (
      <CustomValidityChip
        date={deadline}
        icon={
          state === "COMPLETED" ? (
            <CheckCircleOutlineIcon fontSize="1.2rem" />
          ) : (
            <RemoveCircleOutlineIcon fontSize="1.2rem" />
          )
        }
        label={state === "COMPLETED" ? done : overdue}
        state={state}
        color={state === "COMPLETED" ? "#0078D6" : "#D92121"}
        onClick={() => handleRowClick({ id: id })}
        sx={{
          pointerEvents: "none",
        }}
      />
    ) : (
      <span style={{ display: "flex", justifyContent: "center" }}>
        {deadline}
      </span>
    );
  const roleReviewers = (
    <MultiProfileImageComponent
      data={data?.reviewers}
      circleSize="32px" // Customize the circle size
      componentSize="100%" // Customize the component size
      fontSize="14px"
      onBadgeClick={(data) => {
        onBadgeClick(data);
      }}
      gapInbetween="15px"
      borderWidth="0px"
      maxImages={3}
      isneeduserContextPopover={true}
    />
  );

  const progressBar = (
    <LinearProgressBarWithValue
      total={data?.totalAssignments}
      value={data?.processedAssignments}
      overdueCount={overdueCount}
      Filtervalue={state}
      sx={{ width: "100px" }}
      onClick={() => handleRowClick({ id: id })}
    />
  );

  switch (key) {
    case "roleId":
      return roleId;
    case "roleName":
      return roleName;
    case "dataClassification":
      return dataClassification;
    case "startTime":
      return deadLineComp;
    case "reviewers.id":
      return roleReviewers;
    case "processedAssignments":
      return progressBar;
    default:
      break;
  }
};
