import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, SnackbarContent, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { SetSnackBar } from "@actions";
import { getUUID } from "@utils";

const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
  width: "96%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
  "& .success": {
    color: "white",
    backgroundColor: "#4caf50",
  },
  "& .error": {
    color: "black",
    backgroundColor: "#A04747",
  },
  "& .warning": {
    color: "#fff",
    backgroundColor: "#ff9800",
  },
}));

export default function SnackBar(props) {
  const snackBar = useSelector((d) => d.common?.snackBar);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(SetSnackBar({}));

  const { show, severity = "success", message } = snackBar;

  return (
    <div>
      <CustomSnackBar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={5000}
        open={show}
        onClose={() => handleClose()}
        key={getUUID()}
        severity={severity}
      >
        <SnackbarContent
          message={message}
          className={severity}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          }
          style={{ position: "absolute", bottom: "20px" }}
        ></SnackbarContent>
      </CustomSnackBar>
    </div>
  );
}
