import PropTypes from 'prop-types'
import { forwardRef } from 'react'

export const Badge = forwardRef((
  {
    filled = false,
    children,
    style = {},
    ...otherProps
  },
  ref
) => {
  const additionalStyle = filled
    ? { backgroundColor: '#212121', color: '#fff' }
    : {
      backgroundColor: '#fff',
      color: '#000',
      outline: '1px solid rgba(33,33,33,0.8)'
    }

  return (
    <span
      data-testid="Badge"
      ref={ref}
      style={{
        fontWeight: 'bold',
        fontSize: '80%',
        borderRadius: '10em',
        minWidth: '1.5em',
        padding: '2px 8px',
        textAlign: 'center',
        userSelect: 'none',
        ...additionalStyle,
        ...style
      }}
      {...otherProps}
    >
      {children}
    </span>
  )
})

Badge.propTypes = {
  /**
   * Filled makes the background black with no border.
   *
   * Default is white with a black border
   */
  filled: PropTypes.bool,
  /** Just like sx, but default JSS */
  style: PropTypes.object,
  /** Content to be displayed in the badge */
  children: PropTypes.node
}
