import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { AliceIcon, AliceIconType } from '../../icon'

/**
 * A simple SearchBar
 *
 * If ``ref`` is provided -> following methods are available
 * - getQuery - get current query
 */
export const SearchBar = forwardRef((
  {
    label,
    value,
    onSearch = (value) => {},
    onChange = (event) => {},
    onClear,
    FormProps = {},
    InputProps = {},
    size = 'normal',
    theme = 'dark',
    preselectQuery = '',
    fullWidth = false,
    showIcons = true,
    disabled = false,
    sx = {}
  },
  ref
) => {
  const [ query, setQuery ] = useState(preselectQuery)

  const sxFilledInput = {}
  let sxInputLabel = {}

  useImperativeHandle(ref, () => ({
    getQuery: () => query,
    setQuery: (value) => setQuery(value)
  }))

  const handleChange = (event) => {
    if (value === undefined) {
      setQuery(event.target.value)
    }

    onChange(event)
  }

  const handleSearch = (event) => {
    event.preventDefault()

    if (value === undefined) {
      onSearch(query)
    } else {
      onSearch(value)
    }
  }

  const handleClear = (event) => {
    event.target.value = ''

    if (value === undefined) {
      setQuery('')
    }

    onClear(event)
  }

  if (size === 'small') {
    sxFilledInput.input = {
      fontSize: '14px',
      pt: '9px'
    }

    sxInputLabel = { fontSize: '14px' }
  }

  if (theme === 'bright') {
    sxFilledInput.backgroundColor = '#fff'
  }

  return (
    <Box sx={sx}>
      <form
        data-testid="SearchBar"
        style={fullWidth ? { width: '100%' } : null}
        onSubmit={handleSearch}
      >
        <FormControl
          variant="filled"
          size={size === 'small' ? 'small' : 'medium'}
          fullWidth={fullWidth}
          disabled={disabled}
          {...FormProps}
        >
          {label && size !== 'small' && (
            <InputLabel htmlFor="search-field" sx={sxInputLabel}>
              {label}
            </InputLabel>
          )}

          <FilledInput
            id="search-field"
            onChange={handleChange}
            placeholder={!!label && size === 'small' ? label : null}
            value={value || query}
            componentsProps={{ input: { sx: { backgroundColor: 'grey.200', '&:-webkit-autofill': { WebkitBoxShadow: '0 0 0 30px #F2F2F2 inset !important' } } } }}
            endAdornment={showIcons && (
              <Stack direction="row">
                {onClear && !!(value || query) && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClear}
                      size={size === 'small' ? size : 'medium'}
                      disabled={disabled}
                    >
                      <AliceIcon
                        iconType={AliceIconType.CLOSE}
                        color="black"
                        size={size === 'small' ? 12 : 16}
                      />
                    </IconButton>
                  </InputAdornment>
                )}

                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSearch}
                    size={size === 'small' ? size : 'medium'}
                    disabled={disabled}
                  >
                    <AliceIcon
                      iconType={AliceIconType.SEARCH}
                      color="black"
                      size={size === 'small' ? 16 : 24}
                    />
                  </IconButton>
                </InputAdornment>
              </Stack>
            )}
            {...InputProps}
            sx={sxFilledInput}
          />
        </FormControl>
      </form>
    </Box>
  )
})

SearchBar.propTypes = {
  /** Label to displayed in searchbar */
  label: PropTypes.string,
  /** Make the searchbar a controlled component. OnChange is now required. */
  value: PropTypes.string,
  /**
   * Event that is triggered on magnifying glass click or enter press
   *
   * @param value
   * @returns {void}
   */
  onSearch: PropTypes.func,
  /**
   * Event that is triggered after input changed
   *
   * @param Event
   * @returns {void}
   */
  onChange: PropTypes.func,
  /**
   * Event that is triggered after clear button is pressed.<br>
   * If used the clearButton will appear
   *
   * @param Event
   * @returns {void}
   */
  onClear: PropTypes.func,
  /** Props to customize the FormControl */
  FormProps: PropTypes.object,
  /** Props to customize the FilledInput */
  InputProps: PropTypes.object,
  size: PropTypes.oneOf([ 'small', 'normal' ]),
  theme: PropTypes.oneOf([ 'bright', 'dark' ]),
  /** Initial query value */
  preselectQuery: PropTypes.string,
  fullWidth: PropTypes.bool,
  /** Show "search" and "clear" icons */
  showIcons: PropTypes.bool,
  disabled: PropTypes.bool
}
