import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { Divider } from "@mui/material";
import {
  IconButton,
  TaskStatusChip,
  Tooltip,
  UserAvatar,
  UserContextPopover,
} from "@alice/component_library";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import { CardLink, Typography } from "../../../styledComponents";
import useSelectedTask from "./useSelectedTask";

const ActivityCard = ({ taskInfo, isRequest = false }) => {
  const { LeftData, RightData } = useSelectedTask(taskInfo);
  const { aprooverId, state, id, originator = {} } = taskInfo;
  const { surname: requesterSurname, givenname: requesterGivenname } =
    originator;
  let status = "";
  switch (state) {
    case "PENDING":
      status = "pending";
      break;
    case "COMPLETED":
      status = "approved";
      break;
    case "REJECTED":
      status = "rejected";
      break;
    case "FAILED":
      status = "failed";
      break;
    case "EXPIRED":
      status = "expired";
      break;
    default:
      status = "approved";
      break;
  }
  return (
    <CardLink isRequest={isRequest}>
      {isRequest && (
        <div className="first-item-container">
          <div className="first-column">
            <RequesterIcon />
            <Tooltip
              arrow
              placement="bottom-start"
              title={`${requesterSurname} ${requesterGivenname}`}
              type="ellipsized"
            >
              <Typography noWrap>
                {`${requesterSurname} ${requesterGivenname}`}
              </Typography>
            </Tooltip>
          </div>
        </div>
      )}
      <div className="left-data">{LeftData}</div>
      <div className="right-data">{RightData}</div>

      <div className="right-items">
        <div className="user-avatar-status">
          {aprooverId && (
            <>
              <UserContextPopover id={aprooverId}>
                <UserAvatar
                  userId={aprooverId}
                  userName={aprooverId}
                  showImage
                  sizeStyle={{
                    height: "24px",
                    width: "24px",
                    fontSize: "0.7rem",
                  }}
                />
              </UserContextPopover>
              <Divider orientation="vertical" />
            </>
          )}
          <TaskStatusChip className="task-chip" type={status} variant="icon" />
        </div>
        <Link
          to={`/access/${
            isRequest ? "requests" : "tasks"
          }/details?taskId=${id}`}
        >
          <IconButton>
            <ChevronRight />
          </IconButton>
        </Link>
      </div>
    </CardLink>
  );
};

export default ActivityCard;
