import RightArrow from "@Icons/taskpage/right-arrow.svg";
import RoleKey from "@Icons/taskpage/rolekey.svg";
import RoleRequest from "@Icons/taskpage/rolerequest.svg";
import User from "@Icons/taskpage/user.svg";
import EntitleMentIcon from "@Icons/taskpage/AssignEntitelemnet.svg";
import AssignTechnicalUser from "@Icons/taskpage/AssignTechnicalUser.svg";
import AssignTechnicalUserLeft from "@Icons/taskpage/AssignTechnicalUserLeft.svg";
import AssignTechnicalUserRight from "@Icons/taskpage/AssignTechnicalRight.svg";
import OrgAdminPermission from "@Icons/taskpage/OrgAdminPermission.svg";
import AprooveRoleDeprovisioning from "@Icons/taskpage/AprooveRoleDeprovisioning.svg";
import RemoveIcon from "@Icons/taskpage/RemoveIcon.svg";
import UserCreation from "@Icons/taskpage/AprooveUserCreation.svg";
import UserCreationLeft from "@Icons/taskpage/AprooveUserLeft.svg";
import UserCreationRight from "@Icons/taskpage/AprooveUserRight.svg";
import UserOrganization from "@Icons/taskpage/AprooveUserToOrg.svg";
import UserOrganizationRight from "@Icons/taskpage/UserToOrgRight.svg";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import RejectReason from "@Icons/taskpage/rejectReason.svg";

export const cardCase = (taskInfo, t) => {
  const {
    affectedData,
    taskType,
    requestReason,
    state,
    approvalRejectionReason,
  } = taskInfo;
  const { role, user, entitlement, homeOrg, org, appId } = affectedData;
  // const { id: roleId } = role
  let roleIdSvg = "";
  let assignValueLeft = "";
  let assignValueRight = "";
  let assignValuLeftSvg = "";
  let assignValueRightSvg = "";
  let operationalIcon = "";
  let assignedUserId = "";
  let entitlementId;
  let detailKey1, detailKey2, detailKey3, detailKey4;
  let smsIcon, applicationReasonHeader, applicationReason;
  let rejectReason,
    rejectReasonHeader =
      state === "COMPLETED" ? t("approval-reason") : t("reject-reasons");
  let rejectReasonIcon;
  let cardState = "";
  operationalIcon = (
    <div className="remove-icon-svg-">
      <RightArrow />
    </div>
  );
  let detail = "";
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      roleIdSvg = <RoleRequest />;
      assignValueLeft = role?.id;
      assignValueRight = user?.givenname + " " + user?.surname;
      assignValuLeftSvg = <RoleKey />;
      assignValueRightSvg = <User />;
      assignedUserId = user?.id;
      const adminValue = `<strong>${assignValueLeft}</strong>`;
      const roleVAlue = `<strong>${assignValueRight} (${assignedUserId}) </strong>`;
      detail = t("assign-role-description", {
        role: adminValue,
        beneficiary: roleVAlue,
      });
      smsIcon = <TextsmsOutlinedIcon sx={{ fontSize: "1.3rem" }} />;
      applicationReasonHeader = t("request-reason");
      applicationReason = requestReason;
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      roleIdSvg = <EntitleMentIcon />;
      assignValueLeft = entitlement?.entitlementId;
      assignValueRight = role?.id;
      assignValuLeftSvg = <EntitleMentIcon />;
      assignValueRightSvg = <RoleKey />;
      assignedUserId = "";
      entitlementId = entitlement?.applicationId;
      const entitlementLeft = `<strong>${assignValueLeft} (${entitlementId}) </strong>`;
      const entitlementRole = `<strong>${assignValueRight}</strong>`;
      detail = t("entitlements-description", {
        entitlement: entitlementLeft,
        role: entitlementRole,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      roleIdSvg = <AssignTechnicalUser />;
      assignValueLeft = user?.id;
      assignValueRight = appId;
      assignValuLeftSvg = <AssignTechnicalUserLeft />;
      assignValueRightSvg = <AssignTechnicalUserRight />;
      const techUser = `<strong>${assignValueLeft}</strong>`;
      const application = `<strong>${assignValueRight}</strong>`;
      detail = t("tech-user-description", {
        techUser: techUser,
        application: application,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_ORG_ADMIN_PERMISSION":
      roleIdSvg = <OrgAdminPermission />;
      assignValueLeft = org?.name;
      assignValueRight = user?.givenname + " " + user?.surname;
      assignValuLeftSvg = <OrgAdminPermission />;
      assignValueRightSvg = <User />;
      const orgAdmin = `<strong>${assignValueLeft}</strong>`;
      const orgAdminUser = `<strong>${assignValueRight}</strong>`;
      detail = t("organization-admin-description", {
        organisation: orgAdmin,
        beneficiary: orgAdminUser,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      roleIdSvg = <AprooveRoleDeprovisioning />;
      assignValueLeft = role?.id;
      assignValueRight = user?.givenname + " " + user?.surname;
      assignValuLeftSvg = <AprooveRoleDeprovisioning />;
      assignValueRightSvg = <User />;
      const deproviseRole = `<strong>${assignValueLeft}</strong>`;
      const deproviseUser = `<strong>${assignValueRight}</strong>`;
      detail = t("deprovise-role-description", {
        role: deproviseRole,
        user: deproviseUser,
      });
      operationalIcon = (
        <div className="remove-icon-svg">
          <RemoveIcon />
        </div>
      );
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_USER_CREATION":
      roleIdSvg = <UserCreation />;
      assignValueLeft = user?.givenname + " " + user?.surname;
      assignValueRight = user?.homeOrgId;
      assignValuLeftSvg = <UserCreationLeft />;
      assignValueRightSvg = <UserCreationRight />;
      const userValue = `<strong>${assignValueLeft}</strong>`;
      const organisationValue = `<strong>${assignValueRight}</strong>`;
      detail = t("user-creation-description", {
        user: userValue,
        organisation: organisationValue,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      roleIdSvg = <UserOrganization />;
      assignValueLeft = user?.givenname + " " + user?.surname;
      assignValueRight = homeOrg?.name;
      assignValuLeftSvg = <User />;
      assignValueRightSvg = <UserOrganizationRight />;
      const userLeft = `<strong>${assignValueLeft}</strong>`;
      const organisation = `<strong>${assignValueRight}</strong>`;
      detail = t("assign-user-description", {
        user: userLeft,
        organisation: organisation,
      });
      rejectReasonIcon = <RejectReason />;
      cardState = state;
      rejectReason = approvalRejectionReason;
      break;
    default:
      roleIdSvg = <RoleRequest />;
  }

  return {
    roleIdSvg,
    assignValueLeft,
    assignValueRight,
    assignValuLeftSvg,
    assignValueRightSvg,
    operationalIcon,
    assignedUserId,
    detailKey1,
    detailKey2,
    detailKey3,
    detailKey4,
    smsIcon,
    applicationReasonHeader,
    applicationReason,
    entitlementId,
    cardState,
    rejectReason,
    rejectReasonIcon,
    rejectReasonHeader,
    detail,
  };
};
