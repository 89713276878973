export const generatedDateArray = () => {
  const today = new Date();
  const next28Days = new Date(today.getTime() + 28 * 24 * 60 * 60 * 1000); // Add 28 days to today

  const dateArray = {};

  while (today <= next28Days) {
    const dateString = today.toISOString().split("T")[0];

    // Update the dateArray for the current month (create if needed)
    dateArray[
      new Date(dateString).toLocaleDateString("default", { month: "long" })
    ] = (
      dateArray[
        new Date(dateString).toLocaleDateString("default", { month: "long" })
      ] || []
    ).concat(dateString);

    // Update date for the next iteration
    today.setDate(today.getDate() + 1);

    // Exit if we've exceeded 28 days
    if (today.getTime() > next28Days.getTime()) {
      break;
    }
  }

  return dateArray;
};
