import SearchComponent from "./search";
import Filter from "./filter";
import {
  FilterSortHeader,
  FilterStack,
  SortContainer,
} from "../../styledComponents";
import Sort from "./sort";

const FilterComponent = ({
  actionHandler,
  windowSize,
  translate,
  filterActionHandler,
  isAdmin,
  taskFilter,
  sortHandler,
  sortedValue,
  searchKeyWord,
}) => {
  const searchHandler = (searchKey, searchRef) => {
    actionHandler(searchKey, searchRef);
  };

  const filterHandler = (value) => {
    filterActionHandler(value);
  };

  const sortActionHandler = (value) => {
    sortHandler(value);
  };

  const { isDesktop } = windowSize;
  return (
    <>
      <SearchComponent
        onSearchHandler={searchHandler}
        windowSize={windowSize}
        translate={translate}
        searchKeyWord={searchKeyWord}
        actionHandler={actionHandler}
        isAdmin={isAdmin}
      />
      <FilterStack isdesktop={isDesktop}>
        <FilterSortHeader isdesktop={isDesktop}>
          <Filter
            translate={translate}
            actionHandler={(value) => filterHandler(value)}
            isAdmin={isAdmin}
            windowSize={windowSize}
            taskFilter={taskFilter}
          />
        </FilterSortHeader>
        <SortContainer isdesktop={isDesktop}>
          <Sort
            translate={translate}
            windowSize={windowSize}
            sortHandler={sortActionHandler}
            sortedValue={sortedValue}
          />
        </SortContainer>
      </FilterStack>
    </>
  );
};

export default FilterComponent;
