import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@alice/component_library";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ConfiguredRoleCount } from "@pages/newRequest/styledComponents";

const MainViewHeader = ({
  selectedCount,
  completedCount,
  onNext,
  onPrevious,
  isNextDisabled,
  isPreviousDisbaled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        size="medium"
        variant="secondary"
        onClick={onPrevious}
        disabled={isPreviousDisbaled}
      >
        <NavigateBeforeIcon />
        {t("previous-role")}
      </Button>
      <ConfiguredRoleCount>
        {t("role")} {completedCount}/{selectedCount}
      </ConfiguredRoleCount>
      <Button
        size="medium"
        variant="primary"
        onClick={onNext}
        disabled={isNextDisabled}
      >
        {t("next-role")}
        <NavigateNextIcon />
      </Button>
    </>
  );
};

export default MainViewHeader;
