import { FormControl, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const ItemsPerPageDropdown = ({
  itemsPerPage,
  menuItems,
  onChange,
  disabled = false,
  ...props
}) => {
  const [ itemsToShowCount, setItemsToShowCount ] = useState(itemsPerPage)

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10)

    onChange(value)
    setItemsToShowCount(value)
  }

  return (
    <FormControl
      size="small"
      sx={{
        m: 0,
        minWidth: '70px'
      }}
      {...props}
    >
      <Select
        MenuProps={{ disableScrollLock: true }}
        value={itemsToShowCount}
        onChange={handleChange}
        displayEmpty
        disabled={disabled}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          fontSize: 16,
          svg: { marginRight: 0 },
          fieldset: { border: 0 }
        }}
      >
        {menuItems.map((item, i) => (
          <MenuItem value={item} key={`MenuItem_${i}`}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

ItemsPerPageDropdown.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
