import { CircularProgress, Paper, Stack, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Cookie, Environment } from '../../../constants'
import { useApp } from '../../../hooks'
import { Button } from '../../button'
import { AliceIcon, AliceIconType } from '../../icon'

/**
 * A page to display when the user is not authorized.
 */
export const NotAuthorizedPage = () => {
  const { t } = useTranslation('componentLibrary')
  const { environment } = useApp()

  const [ loading, setLoading ] = useState(true)

  const refreshLoad = () => {
    if (environment === Environment.LOCAL) {
      Cookies.remove(Cookie.BEARER_TOKEN)
      Cookies.remove(Cookie.USER_ID)
    }

    window.location.reload(true)
  }

  const sendMail = () => {
    window.location.assign('mailto:alice-support@mercedes-benz.com')
  }

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2 * 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [ setLoading ])

  return (
    <Paper
      data-testid="NotAuthorizedPage"
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        height: '100vh',
        padding: 2,
        textAlign: 'center'
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div>
            <Typography component="span" sx={{ fontSize: '3rem', fontWeight: 'bold' }}>Alice </Typography>
            {' '}
            <Typography component="span" sx={{ fontSize: '3rem' }}>for </Typography>
            {' '}
            <Typography component="span" variant="h1">Mercedes-Benz Group AG</Typography>
          </div>

          <AliceIcon size={48} iconType={AliceIconType.EARTH_BLOCK} />

          <Typography variant="h3">{t('notAuthorized.title')}</Typography>

          <Typography>{t('notAuthorized.hint')}</Typography>

          <Stack
            sx={{
              flexFlow: 'row',
              gap: 1,
              marginTop: 1
            }}
          >
            <Button
              variant="secondary"
              id="showRequest"
              onClick={() => refreshLoad()}
            >
              {t('general.refresh')}
            </Button>

            <Button
              variant="primary"
              id="newRequest"
              onClick={() => sendMail()}
            >
              {t('general.contactSupport')}
            </Button>
          </Stack>
        </>
      )}
    </Paper>
  )
}
