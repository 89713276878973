import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import {
  CountryFlag,
  Table,
  Message,
  useNotification,
  NewTable,
  useUser,
  usePermission,
} from "@alice/component_library";
import { WarningIcon } from "@components/Styled";
import { Switch } from "@components/Buttons";
import {
  UpdateVisibleSupport,
  RemoveVisibleSupports,
  GetVisibleSupportStatus,
} from "@actions";
import { AdminRolesSkeleton } from "@components/Skeleton/CommonSkeletons";
import { adminLabelMap, getUUID, Authorizations } from "@utils";
import { hasData } from "@utils/Validator";
import { getAdminRolesConfig } from "../../helper";

import {
  SubIconTitle,
  AdminSwitch,
  RespAdminContainer,
  AdminSwitchContainer,
  TableWrapper,
  StyledAccordian,
  AdminActiveRoles,
  AdminRolesOuter,
} from "../../styledComponents";

const MyAdminRoles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useUser();
  const userUtils = user;
  const { adminRoles: adminRoleSet } = usePermission();
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const {
    visible = false,
    adminRolesInfo = [],
    loading = false,
  } = useSelector((state) => state.profile.visibleSupport);

  const { notify } = useNotification();

  const showVisbility = hasData(
    adminRoles?.filter((d) => Authorizations["visibilityAdmin"].includes(d))
  );

  useEffect(() => {
    if (adminRolesInfo?.length === 0) {
      dispatch(GetVisibleSupportStatus());
    }
  }, [dispatch, adminRolesInfo]);

  const clickHandler = async (checked) => {
    if (checked) {
      await dispatch(UpdateVisibleSupport(t, userUtils?.id, notify));
    } else {
      await dispatch(RemoveVisibleSupports(t, userUtils?.id, notify));
    }
  };

  const titleRenders = (key, c) => {
    return c?.headers[key]?.Header;
  };

  const getAccordian = (adminInfo) => {
    const { adminRole: d } = adminInfo;

    const config = getAdminRolesConfig(adminInfo, t);
    const valid = hasData(config);
    return (
      <StyledAccordian className="accordion-main" disabled={!valid}>
        <AccordionSummary
          className="accordion-title"
          expandIcon={valid ? <ExpandMoreIcon /> : null}
        >
          <Typography>
            <SubIconTitle className="icon-title">
              {t(`adminRolesTitle.${adminLabelMap[d]}`)}
            </SubIconTitle>
          </Typography>
        </AccordionSummary>

        {valid && config ? (
          <AccordionDetails className="accordion-content">
            <TableWrapper className="accordion-table">
              {config?.map((c) => (
                <>
                  <NewTable
                    isLocal={true}
                    titleRenderer={(key) => titleRenders(key, c)}
                    localRows={adminInfo[c.key]?.map((k) =>
                      c.keys?.map(
                        (m) =>
                          (["marketCountryCode"].includes(m) ? (
                            <CountryFlag countryCode={k[m]} />
                          ) : (
                            k[m]
                          )) ?? "-"
                      )
                    )}
                  />
                </>
              ))}
            </TableWrapper>
          </AccordionDetails>
        ) : null}
      </StyledAccordian>
    );
  };

  return (
    <AdminRolesOuter>
      {loading ? (
        <AdminRolesSkeleton />
      ) : (
        <>
          {showVisbility && (
            <>
              <h2>{t("adminRolesTab.active-roles")}</h2>
              <AdminSwitch>
                <AdminSwitchContainer>
                  <Switch checked={visible} clickHandler={clickHandler} />
                  <RespAdminContainer>
                    {t("responsible-administrator")}
                  </RespAdminContainer>
                </AdminSwitchContainer>
              </AdminSwitch>
            </>
          )}
          {hasData(adminRolesInfo) ? (
            <AdminActiveRoles>
              {adminRolesInfo?.map((d) => (
                <div key={getUUID} className="accordion-outer">
                  {getAccordian(d)}
                </div>
              ))}
            </AdminActiveRoles>
          ) : (
            <Message
              icon={<WarningIcon />}
              title={t("adminRolesTab.no-admin-found")}
              description={t("adminRolesTab.no-admin-found-content")}
            />
          )}
        </>
      )}
    </AdminRolesOuter>
  );
};

export default MyAdminRoles;
