import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { UserAvatarGroup } from '../../UserAvatar'
import { Skeleton } from '../../../skeleton'
import { mergeSxProps } from '../../../../util'

export const UserGroupList = ({
  groupOwners,
  groupUsers,
  isLoading,
  isStacked = false,
  maxAvatars = 5,
  size,
  sx
}) => {
  const { t } = useTranslation('componentLibrary')

  const placeholderArray = [ 1, 2, 3, 4, 5 ]

  const groupSkeleton = (
    <Stack gap={1.5}>
      <Skeleton variant="rounded" sx={{ width: '75px', height: '16px' }} />

      <Stack sx={{ flexDirection: 'row', marginLeft: '8px' }}>
        {placeholderArray.map((item, index) => (
          <Skeleton
            key={index}
            variant="circular"
            sx={{
              width: `${size}px`,
              height: `${size}px`,
              marginLeft: '-8px'
            }}
          />
        ))}
      </Stack>
    </Stack>
  )

  return (
    <Stack
      sx={mergeSxProps({
        flexFlow: isStacked ? 'column' : 'row',
        gap: 3
      }, sx)}
    >
      {isLoading
        ? (
          <>
            {groupSkeleton}
            {groupSkeleton}
          </>
        )
        : (
          <>
            {!!groupOwners.length && (
              <Stack sx={{ gap: '6px', width: '130px' }}>
                <Typography variant="caption">{t('userGroupCard.context.ownersLabel')}</Typography>

                <UserAvatarGroup size={size} avatars={groupOwners} max={maxAvatars} />
              </Stack>
            )}

            {!!groupUsers.length && (
              <Stack sx={{ gap: '6px', width: '130px' }}>
                <Typography variant="caption">{t('userGroupCard.context.usersLabel')}</Typography>

                <UserAvatarGroup size={size} avatars={groupUsers} max={maxAvatars} />
              </Stack>
            )}
          </>
        )}
    </Stack>
  )
}
