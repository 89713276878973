export const useUserGroupCard = ({
  size = 'm',
  isList = false,
  isRenderer = false,
  description = isRenderer,
  owners = isRenderer,
  users = isRenderer,
  _isContextShown = true,
  _isDescriptionShown = true,
  _isUserGroupListShown = true,
  _onDeleteClick
}) => {
  const isTiny = size === 's'

  const isDeleteShown = !!_onDeleteClick
  const isContextShown = _isContextShown && size === 's' && !isDeleteShown
  const isDescriptionShown = _isDescriptionShown && description && size !== 's'
  const isUserGroupListShown = _isUserGroupListShown && (owners || users) && size !== 's'

  const isActionShown = isDeleteShown

  const isDividedHorizontal = isList || (isTiny && isActionShown)

  const isDividedShown = !!(
    (isList && isContextShown)
    || isUserGroupListShown
    || isDeleteShown
  )

  const subgridContentLength = [ true, isDescriptionShown ].filter((entry) => entry).length

  return {
    isTiny,
    isContextShown,
    isDescriptionShown,
    isUserGroupListShown,
    isDeleteShown,
    isActionShown,
    isDividedHorizontal,
    isDividedShown,
    subgridContentColumns: subgridContentLength,
    subgridContentRows: subgridContentLength
  }
}
