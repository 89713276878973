import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RemoveAssignedRoleData } from "@actions";
import { formatDatewithSlash } from "@utils/dateFormat";
import RoleGenricSearch from "./RoleGenericSearch";
import DeleteDialog from "./components/DialogBox";
import ResultDialog from "./components/DeleleteResult";
import { GetVisibleSupportStatus } from "@src/actions";

const RolesPage = React.memo(
  ({ assignedRoles, userId, fetchData, isInsights = false }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const genericRef = useRef();
    const [rolesComponentData, setRolesComponentData] = useState(assignedRoles);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [roleDeleteStatus, setRoledeleteStatus] = useState({ info: [] });
    const [value, setValue] = useState(0);
    const [dailogue, setDailogue] = useState(false);

    const [selectedData, setSelectedData] = useState([]);
    const date = formatDatewithSlash(Date());

    const { adminRolesInfo = [] } = useSelector(
      (state) => state.profile.visibleSupport
    );

    const globalRoleAssigner = adminRolesInfo?.find(
      (roleInfo) => roleInfo.adminRole === "GLOBAL_ROLE_ASSIGNER"
    );

    const globalRoleIds = globalRoleAssigner
      ? globalRoleAssigner?.administratedRoles?.map((role) => role.roleId)
      : [];

    useEffect(() => {
      if (adminRolesInfo?.length === 0) {
        dispatch(GetVisibleSupportStatus());
      }
    }, [dispatch, adminRolesInfo]);

    useEffect(() => {
      setRolesComponentData(assignedRoles);
    }, [assignedRoles]);

    const getDataFilterData = (
      activeFilters = {},
      activeSort,
      dates = {},
      search = ""
    ) => {
      let finalData = assignedRoles;
      if (Object.keys(activeFilters).length) {
        finalData = assignedRoles
          .filter((role) => {
            return Object.entries(activeFilters).every(([key, value]) => {
              if (Array.isArray(value) && value.length > 0) {
                // Check if array is non-empty
                if (key === "status") {
                  return value[0] === "aRoles"
                    ? role.validTo >= date || role.validTo === undefined
                    : role.validTo < date;
                } else if (key === "roleType") {
                  return value[0].includes(role.roleDefinition[key]);
                } else if (key === "validity") {
                  return value[0] === "noValidity"
                    ? !("validTo" in role)
                    : Object.keys(dates).length
                    ? new Date(role.validTo) >= new Date(dates.from) &&
                      new Date(role.validTo) <= new Date(dates.to)
                    : true;
                }
              } else if (Array.isArray(value) && value.length === 0) {
                return true;
              } else {
                return true;
              }
            });
          })
          .map((role) => {
            // Append additional key-value pairs to the filtered users
            Object.entries(activeFilters).forEach(([key, value]) => {
              if (!role[key] && value.length) {
                role[key] = value[0];
              }
            });
            return role;
          });
      }
      let dataToRender = finalData;
      if (search?.length) {
        dataToRender = finalData
          ?.filter(
            (item) =>
              item?.roleDefinition?.name
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.roleDefinition?.id
                ?.toLowerCase()
                .includes(search?.toLowerCase()) ||
              item?.roleDefinition?.description
                ?.toLowerCase()
                .includes(search?.toLowerCase())
          )
          .map((role) => {
            role["description"] = search.toLowerCase();
            return role;
          });
      }
      getSortData(activeSort, dataToRender, true);
    };

    const getSortData = useCallback(
      (activeSort, data = [], isneedData = false) => {
        const dataToRender = isneedData ? [...data] : [...rolesComponentData];
        if (activeSort === "nameAsc") {
          dataToRender.sort((a, b) =>
            a?.roleDefinition?.name.localeCompare(b.roleDefinition.name)
          );
        } else if (activeSort === "nameDsc") {
          dataToRender.sort((a, b) =>
            b.roleDefinition?.name.localeCompare(a.roleDefinition?.name)
          );
        } else if (activeSort === "validToAsc") {
          const today = new Date();
          dataToRender.sort((a, b) => {
            const aDays = a.validTo
              ? Math.ceil((new Date(a.validTo) - today) / (1000 * 60 * 24))
              : NaN;
            const bDays = b.validTo
              ? Math.ceil((new Date(b.validTo) - today) / (1000 * 60 * 24))
              : NaN;

            if (isNaN(aDays)) return 1;
            if (isNaN(bDays)) return -1;

            return aDays - bDays;
          });
        } else if (activeSort === "validToDsc") {
          const today = new Date();
          dataToRender.sort((a, b) => {
            const aDays = a.validTo
              ? Math.ceil((new Date(a.validTo) - today) / (1000 * 60 * 24))
              : NaN;
            const bDays = b.validTo
              ? Math.ceil((new Date(b.validTo) - today) / (1000 * 60 * 24))
              : NaN;

            if (isNaN(aDays)) return 1;
            if (isNaN(bDays)) return -1;

            return bDays - aDays;
          });
        }

        dataToRender.forEach((item, index) => {
          item[activeSort] = index + 1;
        });
        setRolesComponentData(dataToRender);
      },
      [rolesComponentData]
    );

    const handleDialogue = useCallback(
      (open) => {
        setDailogue(open);
        setSelectedRoles(
          rolesComponentData.filter((el) => selectedData.includes(el.id))
        );
      },
      [rolesComponentData, selectedData]
    );
    const handleSingleDelete = (open, selectedItem) => {
      setDailogue(open);
      setSelectedRoles(selectedItem);
    };
    const deleteRoleSelected = useCallback(
      async (reason, data) => {
        if (data.length) {
          let sucessStatusInfo = [];

          setRoledeleteStatus({ info: [] });

          const results = await Promise.allSettled(
            data.map(async (roles) => {
              const {
                roleDefinition = {},
                customScope = {},
                orgScope,
                id: roleId,
              } = roles;
              const { id, needsCustomScopes, needsOrgScopes } = roleDefinition;
              const { name: customScopeName } = customScope;
              let deleteInfo = {
                id: id,
                status: "failed",
                roleId: roleId,
                item: roles,
              };

              const deleteStatus = await dispatch(
                await RemoveAssignedRoleData(
                  userId,
                  id,
                  reason,
                  needsCustomScopes,
                  needsOrgScopes,
                  customScopeName,
                  orgScope?.id
                )
              );

              // const deleteStatus = 204;

              if (deleteStatus === 204 || deleteStatus === 200) {
                deleteInfo["status"] = "success";
              } else if (deleteStatus === 403) {
                deleteInfo["status"] = "access-denied";
              } else {
                deleteInfo["status"] = "failed";
              }

              setRoledeleteStatus((prevState) => ({
                ...prevState,
                info: [
                  ...prevState["info"].filter(
                    (item) => item.roleId !== roles.id
                  ),
                  deleteInfo,
                ],
              }));
              sucessStatusInfo = [...sucessStatusInfo, deleteInfo];
              return deleteInfo;
            })
          );
          if (
            results
              .map((el) => el.value)
              .some((res) => res.status === "success")
          ) {
            fetchData();
            genericRef?.current?.handleReset();
          }
          setDailogue(false);
          sucessStatusInfo = [];
        }
      },
      [dispatch, userId]
    );

    return (
      <>
        <RoleGenricSearch
          ref={genericRef}
          rolesData={rolesComponentData.filter(
            (el) => el.roleDefinition.isDynamic === !!value
          )}
          onFilterChange={(activeStatus, activeSortData, dates, search) =>
            getDataFilterData(activeStatus, activeSortData, dates, search)
          }
          onSortChange={getSortData}
          handleTabChange={(value) => setValue(value)}
          handleExtendOrReapply={`/access/accessRequest/${
            isInsights ? "insights" : "mySelf"
          }?user=${userId}`}
          onSelectedData={(selectedRoles) => {
            setSelectedData(selectedRoles);
          }}
          enableDeleteButton={!!selectedData.length}
          handleDialogue={handleDialogue}
          handleSingleDelete={handleSingleDelete}
          globalRoleIds={globalRoleIds}
        />
        {dailogue && (
          <DeleteDialog
            dialogOpen={dailogue}
            handleDismiss={() => {
              setDailogue(false);
            }}
            t={t}
            actionHandler={(reason, comment) => {
              const deletionReason =
                reason === "other" && comment?.trim() ? comment.trim() : reason;
              deleteRoleSelected(deletionReason, selectedRoles);
            }}
            selectedData={selectedRoles}
            isNeedReason={true}
            deleteRemoveHandler={(id) => {
              const selected = [...selectedRoles];
              let updatedDeletedArray = selected?.filter(
                (item) => item.id !== id
              );
              setSelectedRoles(updatedDeletedArray);
            }}
          />
        )}
        <ResultDialog
          dialogOpen={roleDeleteStatus?.info?.length}
          handleDismiss={() => setRoledeleteStatus({ info: [] })}
          t={t}
          infoData={roleDeleteStatus.info}
        />
      </>
    );
  }
);

export default RolesPage;
