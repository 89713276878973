import { Grid } from "@mui/material";
import { AliceIcon, AliceIconType, UserCard } from "@alice/component_library";
import { useTranslation } from "react-i18next";
// import WorkflowStepper from "../WorkflowStatusStepper/WorkflowStepper";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
// import BeneficiaryCard from "@src/sharedComponents/UserCards/userCard";
import {
  RequestSubHeader,
  RequestSubHeaderTypography,
  RequestDetailsContainer,
  RequestHeader,
  RSubTopicContainer,
} from "@pages/requestPage/styledComponents";
import WorkFlowData from "../RequestWorkflow/WorkFlowData";
import { RoleCard } from "./RoleCardComponent";

const RoleRequestType = ({
  taskInfo,
  windowSize,
  loggedInUser,
  isDeeplink,
}) => {
  const { t } = useTranslation();
  const {
    originator = {},
    state,
    isWorkFlow,
    affectedData = {},
    id,
  } = taskInfo;
  const { user = {} } = affectedData;

  const { isMobile } = windowSize;
  const { id: requesterId } = originator;
  const { id: userId } = user;

  return (
    <RequestDetailsContainer>
      <RequestHeader>
        {t("task-type-details-header.role-request-details")}
      </RequestHeader>
      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <RequesterIcon />
            <RequestSubHeaderTypography>
              {t("requester")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>

          <UserCard
            userData={originator}
            readonly
            size={isMobile ? "small" : "medium"}
            sx={{ flexWrap: "wrap" }}
            className={loggedInUser === requesterId ? "highlight-card" : ""}
            isContextShown={true}
          />
        </RSubTopicContainer>
      </Grid>
      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>{t("role")}</RequestSubHeaderTypography>
          </RequestSubHeader>
        </RSubTopicContainer>
        <RoleCard taskInfo={taskInfo} isMobile={isMobile} />
      </Grid>
      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.USER_OUTLINE}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>
              {t("beneficiary")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>
          {/* <BeneficiaryCard
            userData={user}
            isTechUser={taskType === "APPROVE_TECHUSER_TO_APPLICATION"}
          /> */}

          <UserCard
            userData={user}
            readonly
            size={isMobile ? "small" : "medium"}
            sx={{ flexWrap: "wrap" }}
            className={loggedInUser === requesterId ? "highlight-card" : ""}
            isContextShown={true}
          />
        </RSubTopicContainer>
      </Grid>

      {(state === "PENDING" || isWorkFlow) && (
        <WorkFlowData
          workflowId={id}
          isWorkFlow={isWorkFlow}
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
        />
      )}
    </RequestDetailsContainer>
  );
};

export default RoleRequestType;
