import PropTypes from 'prop-types'
import { ApiAdminAccordion } from './accordion/ApiAdminAccordion'
import { ApplicationAdminAccordion } from './accordion/ApplicationAdminAccordion'
import { ApplicationHelpdeskAdminAccordion } from './accordion/ApplicationHelpdeskAdminAccordion'
import { CentralHelpdeskAdminAccordion } from './accordion/CentralHelpdeskAdminAccordion'
import { GlobalCommunityAdminAccordion } from './accordion/GlobalCommunityAdminAccordion'
import { GlobalRoleAssignerAccordion } from './accordion/GlobalRoleAssignerAccordion'
import { IdentStatusAdminAccordion } from './accordion/IdentStatusAdminAccordion'
import { MarketAdminAccordion } from './accordion/MarketAdminAccordion'
import { MarketHelpdeskAdminAccordion } from './accordion/MarketHelpdeskAdminAccordion'
import { MarketRoleAssignerAccordion } from './accordion/MarketRoleAssignerAccordion'
import { OrganizationAdminAccordion } from './accordion/OrganizationAdminAccordion'
import { OrganizationRoleAssignerAccordion } from './accordion/OrganizationRoleAssignerAccordion'
import { RoleApproverAccordion } from './accordion/RoleApproverAccordion'
import { RoleCreatorAccordion } from './accordion/RoleCreatorAccordion'
import { RoleOwnerAccordion } from './accordion/RoleOwnerAccordion'
import { SharedServiceAdminAccordion } from './accordion/SharedServiceAdminAccordion'
import { SupportAdminAccordion } from './accordion/SupportAdminAccordion'

export const AdminRole = ({
  userId,
  role,
  roleData,
  onRoleUpdate
}) => {
  switch (role) {
    case 'globalCommunityAdmin':
      return (
        <GlobalCommunityAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedCommunities={roleData?.administratedCommunities || []}
        />
      )
    case 'marketAdmin':
      return (
        <MarketAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedMarkets={roleData?.administratedMarkets || []}
        />
      )
    case 'apiAdmin':
      return (
        <ApiAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          consumableWebservices={roleData?.consumableWebservices || {}}
        />
      )
    case 'applicationAdmin':
      return (
        <ApplicationAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedApplications={roleData?.administratedApplications || []}
        />
      )
    case 'applicationHelpdeskAdmin':
      return (
        <ApplicationHelpdeskAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedApplications={roleData?.administratedApplications || []}
        />
      )
    case 'globalRoleAssigner':
      return (
        <GlobalRoleAssignerAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedRoles={roleData?.administratedRoles || []}
        />
      )
    case 'roleApprover':
      return (
        <RoleApproverAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedRoles={roleData?.administratedRoles || []}
          administratedGroupRoles={roleData?.administratedRolesByGroup || []}
        />
      )
    case 'roleOwner':
      return (
        <RoleOwnerAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedRoles={roleData?.administratedRoles || []}
          administratedGroupRoles={roleData?.administratedRolesByGroup || []}
        />
      )
    case 'organizationRoleAssigner':
      return (
        <OrganizationRoleAssignerAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedRoles={roleData?.administratedRoles || []}
        />
      )
    case 'marketRoleAssigner':
      return (
        <MarketRoleAssignerAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedRoles={roleData?.administratedRoles || []}
        />
      )
    case 'marketHelpdeskAdmin':
      return (
        <MarketHelpdeskAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedMarkets={roleData?.administratedMarkets || []}
        />
      )
    case 'identStatusAdmin':
      return (
        <IdentStatusAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedMarkets={roleData?.administratedMarkets || []}
        />
      )
    case 'orgAdmin':
      return (
        <OrganizationAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
          administratedOrgs={roleData?.administratedOrgs || []}
        />
      )
    case 'centralHelpdeskAdmin':
      return (
        <CentralHelpdeskAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
        />
      )
    case 'roleCreator':
      return (
        <RoleCreatorAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
        />
      )
    case 'sharedServiceAdmin':
      return (
        <SharedServiceAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
        />
      )
    case 'supportAdmin':
      return (
        <SupportAdminAccordion
          userId={userId}
          isAssigned={!!roleData?.admin}
          onChange={onRoleUpdate}
        />
      )
    default:
      return null
  }
}

AdminRole.propTypes = {
  userId: PropTypes.string,
  role: PropTypes.string,
  roleData: PropTypes.object,
  onRoleUpdate: PropTypes.func
}
