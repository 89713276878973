import React from "react";
import { useTranslation } from "react-i18next";
import ReviewIcon from "@src/pages/HomePage/components/AccessReviewDeadLines/Icons/ReviewIcon.svg";
import { AccessReviewHeaderContainer } from "../../styledComponents";
import ReloadButton from "@components/reloadButton";

const AccessReviewHeader = ({ loading, fetchData }) => {
  const { t } = useTranslation();
  return (
    <AccessReviewHeaderContainer>
      <div className="sub-header">
        <div className="icon-container">
          <ReviewIcon />
        </div>
        <div className="review-text">{t("reviews")}</div>
        <ReloadButton loading={loading} handleIconButtonClick={fetchData} />
      </div>
      <div className="main-header">{t("upcoming-deadlines")}</div>
    </AccessReviewHeaderContainer>
  );
};

export default AccessReviewHeader;
