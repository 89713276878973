import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { MarketHelpdeskAdministratorAssignmentDialog } from '../assignment/MarketHelpdeskAdministratorAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'marketCountryCode'
const COLUMNS = [ 'marketCountryCode', 'marketName' ]

export const MarketHelpdeskAdminAccordion = ({
  userId,
  isAssigned,
  administratedMarkets,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedMarkets, setSelectedMarkets ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'markethelpdeskadmin',
        body: { marketHelpdeskAdminPrivileges: selectedMarkets.map((marketCountryCode) => ({ marketCountryCode })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.marketHelpdeskAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedRoles = administratedMarkets
          .filter((administrated) => selectedMarkets
            .every((selected) => administrated.marketCountryCode !== selected))

        const marketHelpdeskAdmin = { admin: false }

        if (newAdministratedRoles.length) {
          marketHelpdeskAdmin.admin = true
          marketHelpdeskAdmin.administratedMarkets = newAdministratedRoles
        }

        handleDeselectAll()
        onChange({ marketHelpdeskAdmin })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'marketCountryCode':
        return t('general.countryCode')
      case 'marketName':
        return t('general.market')
      default:
        return key
    }
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedMarkets(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.marketCountryCode))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.marketCountryCode), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="MarketHelpdeskAdminAccordion"
        title={t('adminRoles.marketHelpdeskAdmin')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {administratedMarkets.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={administratedMarkets}
            titleRenderer={titleRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                isDeleteDisabled={!selectedMarkets.length}
                isDeleteLoading={isLoadingDelete}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <MarketHelpdeskAdministratorAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

MarketHelpdeskAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedMarkets: PropTypes.array,
  onChange: PropTypes.func
}
