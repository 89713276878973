import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { TextField } from '../../../form'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { MarketAdministratorAssignmentDialog } from '../assignment/MarketAdministratorAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'id'
const COLUMNS = [ 'marketCountryCode', 'marketName', 'community', 'description' ]

const convertIdToApiObject = (id) => {
  const splitId = id.split(';')

  return {
    marketCountryCode: splitId[0],
    community: splitId[1]
  }
}

const getAdministratedMarketsChanges = (administratedMarkets, updatedAdministratedMarkets) => {
  if (!administratedMarkets || !updatedAdministratedMarkets) return []

  const changes = []

  administratedMarkets.forEach(
    ({
      community,
      marketCountryCode,
      description
    }, index) => {
      const {
        community: actualCommunity,
        marketCountryCode: actualMarketCountryCode,
        description: actualDescription
      } = updatedAdministratedMarkets[index]

      if (
        community === actualCommunity
        && marketCountryCode === actualMarketCountryCode
        && description !== actualDescription
      ) {
        changes.push({
          communityScope: actualCommunity,
          market: marketCountryCode,
          description: actualDescription
        })
      }
    }
  )

  return changes
}

export const MarketAdminAccordion = ({
  userId,
  isAssigned,
  administratedMarkets,
  onChange = (newRole) => {}
}) => {
  const transformedAdministratedMarkets = useMemo(() => administratedMarkets.map((market) => ({
    ...market,
    id: `${market.marketCountryCode};${market.community}`
  })), [ administratedMarkets ])

  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ currentAdministratedMarkets, setCurrentAdministratedMarkets ] = useState(transformedAdministratedMarkets)
  const [ selectedMarkets, setSelectedMarkets ] = useState([])

  useEffect(() => setCurrentAdministratedMarkets([ ...transformedAdministratedMarkets ]), [ transformedAdministratedMarkets ])

  const updateDescription = (market, community, value) => {
    const newCurrentAdministratedMarkets = structuredClone(currentAdministratedMarkets)

    for (let i = 0; i < newCurrentAdministratedMarkets.length; i++) {
      if (
        newCurrentAdministratedMarkets[i].community === community
          && newCurrentAdministratedMarkets[i].marketCountryCode === market
      ) {
        newCurrentAdministratedMarkets[i] = {
          ...newCurrentAdministratedMarkets[i],
          ...{ description: value }
        }
      }
    }

    setCurrentAdministratedMarkets(newCurrentAdministratedMarkets)
  }

  const {
    isLoading: isLoadingSave,
    execute: executeSave
  } = useApi(
    UsersService.updateDescriptionOfAdminCommunityScopes,
    {
      config: {
        id: userId,
        body: { adminCommunityScopes: getAdministratedMarketsChanges(transformedAdministratedMarkets, currentAdministratedMarkets) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.update', { adminRole: t('adminRoles.marketAdmin') })
    }
  )

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'marketadmin',
        body: { marketAdminPrivileges: selectedMarkets.map(convertIdToApiObject) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.marketAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedMarkets = transformedAdministratedMarkets
          .filter((administrated) => selectedMarkets
            .map(convertIdToApiObject)
            .every(({ marketCountryCode, community }) => !(administrated.community === community && administrated.marketCountryCode === marketCountryCode)))

        const marketAdmin = { admin: false }

        if (newAdministratedMarkets.length) {
          marketAdmin.admin = true
          marketAdmin.administratedMarkets = newAdministratedMarkets
        }

        handleDeselectAll()
        onChange({ marketAdmin })
      }
    }
  )

  const DescriptionTextField = ({ row }) => {
    const [ value, setValue ] = useState(row.description || '')

    const handleClick = (e) => e.stopPropagation()
    const handleChange = (e) => setValue(e.target.value)

    const handleBlur = (e) => {
      updateDescription(
        row.marketCountryCode,
        row.community,
        e.target.value
      )
    }

    return (
      <TextField
        size="small"
        variant="outlined"
        style={{
          marginTop: '5px',
          backgroundColor: '#fff'
        }}
        placeholder={t('adminRoles.addDescription')}
        fullWidth
        hiddenLabel
        value={value}
        onClick={handleClick}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    )
  }

  const titleRenderer = (key) => {
    switch (key) {
      case 'marketCountryCode':
        return t('general.countryCode')
      case 'marketName':
        return t('general.market')
      case 'community':
        return t('general.community')
      case 'description':
        return t('general.description')
      default:
        return key
    }
  }

  const entryRenderer = (key, row) => {
    switch (key) {
      case 'description':
        return <DescriptionTextField row={row} />
      default:
        return row[key]
    }
  }

  const handleSave = () => executeSave()
  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedMarkets(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="MarketAdminAccordion"
        title={t('adminRoles.marketAdmin')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {currentAdministratedMarkets?.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={currentAdministratedMarkets}
            titleRenderer={titleRenderer}
            entryRenderer={entryRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                onSave={handleSave}
                isSaveLoading={isLoadingSave}
                isDeleteLoading={isLoadingDelete}
                isDeleteDisabled={!selectedMarkets.length}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <MarketAdministratorAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

MarketAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedMarkets: PropTypes.array,
  onChange: PropTypes.func
}
