export const DetailItemType = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  ADDRESS: 'address',
  USER_COMMUNITY: 'userCommunity',
  ORG_COMMUNITY: 'orgCommunity',
  ORG_FORM: 'orgForm',
  ORGANIZATION: 'organization',
  EMAIL: 'eMail',
  PHONE: 'phone'
}
