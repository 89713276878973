import PropTypes from 'prop-types'
import { AccountTreeOutlined, FlagCircleOutlined, GroupsOutlined, HandshakeOutlined, JoinInnerOutlined, LanguageOutlined, ManageAccountsOutlined, ManageAccountsRounded, MemoryRounded, PeopleAltOutlined, PersonPinOutlined, PinDropOutlined, PublicOutlined, QuestionMarkOutlined, RoomOutlined, ShareLocationOutlined, SmartToyRounded, WorkspacesOutlined } from '@mui/icons-material'
import { AliceIcon, IconWrapper } from '../../../../icon'
import { ORG_COMMUNITIES, ORG_FORMS, USER_COMMUNITIES, USER_SPECIAL_COMMUNITIES } from '../../../../../util'

export const SmartIcon = ({
  type,
  name,
  color = 'primary.main',
  size = 16,
  sx
}) => {
  const unknownIcon = <QuestionMarkOutlined fontSize="inherit" />

  let icon

  const userCommunity = (name) => {
    switch (name) {
      case 'CORPORATE_BUSINESS_PARTNER_PERSON':
        return <HandshakeOutlined />
      case 'CORPORATE_CUSTOMER_EMPLOYEE':
        return <AliceIcon iconType="ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION" sx={sx} />
      case 'DAIMLER_EMPLOYEE':
        return <AliceIcon iconType="ORG_USER_SEARCH_EMPLOYEE" color={color} size={size} sx={sx} />
      case 'DAIMLER_EXTERNAL':
        return <AliceIcon iconType="ORG_USER_SEARCH_EXTERNAL" color={color} size={size} sx={sx} />
      case 'DAIMLER_INTERNAL':
        return <AliceIcon iconType="ORG_USER_SEARCH_INTERNAL_COMPANIES" color={color} size={size} sx={sx} />
      case 'DAIMLER_JOINT_VENTURE_PERSON':
        return <JoinInnerOutlined fontSize="inherit" />
      case 'DAIMLER_POOLID':
        return <GroupsOutlined fontSize="inherit" />
      case 'DEALER_EMPLOYEE':
        return <AliceIcon iconType="ORG_USER_SEARCH_DEALER" color={color} size={size} sx={sx} />
      case 'SUPPLIER_EMPLOYEE':
        return <AliceIcon iconType="ORG_USER_SEARCH_SUPPLIER" color={color} size={size} sx={sx} />
      case 'BOT_USER':
        return <SmartToyRounded fontSize="inherit" />
      case 'TECH_USER':
      case 'TECH_USER__SYSTEM':
      case 'TECH_USER__GLOBAL':
      case 'TECH_USER__DELEGATED':
        return <MemoryRounded fontSize="inherit" />
      case 'TEST_USER':
      case 'TEST_USER__ORG':
      case 'TEST_USER__APP':
        return <ManageAccountsRounded fontSize="inherit" />
      default:
        return unknownIcon
    }
  }

  const orgCommunity = (key) => {
    switch (key) {
      case 'DEALER':
        return <AliceIcon iconType="ORG_USER_SEARCH_DEALER" color={color} size={size} sx={sx} />
      case 'CORPORATE_CUSTOMER':
        return <AliceIcon iconType="ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION" color={color} size={size} sx={sx} />
      case 'EMPLOYEE':
        return <AliceIcon iconType="ORG_USER_SEARCH_EMPLOYEE" color={color} size={size} sx={sx} />
      case 'SUPPLIER':
        return <AliceIcon iconType="ORG_USER_SEARCH_SUPPLIER" color={color} size={size} sx={sx} />
      case 'CORPORATE_BUSINESS_PARTNER':
        return <HandshakeOutlined fontSize="inherit" />
      case 'TEST_ORGANIZATION':
        return <ManageAccountsOutlined fontSize="inherit" />
      default:
        return unknownIcon
    }
  }

  const orgForm = (name) => {
    switch (name) {
      case 'CONTINENT':
        return <PublicOutlined fontSize="inherit" />
      case 'COUNTRY':
        return <FlagCircleOutlined fontSize="inherit" />
      case 'DAIMLER_COMPANY':
        return <AliceIcon iconType="ORG_USER_SEARCH_DAIMLER_COMPANY" color={color} size={size} sx={sx} />
      case 'DEALER_GROUP':
        return <AliceIcon iconType="ORG_USER_SEARCH_DEALER_GROUP" color={color} size={size} sx={sx} />
      case 'GENERAL_DISTRIBUTOR':
        return <PersonPinOutlined fontSize="inherit" />
      case 'GROUP':
        return <WorkspacesOutlined fontSize="inherit" />
      case 'LEGALENTITY':
        return <AccountTreeOutlined fontSize="inherit" />
      case 'LOCATION':
        return <RoomOutlined fontSize="inherit" />
      case 'MAINLOCATION':
        return <PinDropOutlined fontSize="inherit" />
      case 'MARKET_AREA':
        return <AliceIcon iconType="ORG_USER_SEARCH_MARKET_AREA" color={color} size={size} sx={sx} />
      case 'MPC':
        return <AliceIcon iconType="ORG_USER_SEARCH_MPC_AND_GD" color={color} size={size} sx={sx} />
      case 'PARTNER_ORGANIZATION':
        return <AliceIcon iconType="ORG_USER_SEARCH_PARTNER_ORGANIZATION" color={color} size={size} sx={sx} />
      case 'SUBLOCATION':
        return <ShareLocationOutlined fontSize="inherit" />
      case 'SUPPLIER_ORGANIZATION':
        return <AliceIcon iconType="ORG_USER_SEARCH_SUPPLIER_ORGANIZATION" color={color} size={size} sx={sx} />
      case 'TEAM':
        return <PeopleAltOutlined fontSize="inherit" />
      case 'WORLD':
        return <LanguageOutlined fontSize="inherit" />
      default:
        return unknownIcon
    }
  }

  switch (type) {
    case 'userCommunity':
      icon = userCommunity(name)
      break
    case 'orgCommunity':
      icon = orgCommunity(name)
      break
    case 'orgForm':
      icon = orgForm(name)
      break
    default:
      icon = unknownIcon
  }

  return <IconWrapper color={color} size={size} sx={sx}>{ icon }</IconWrapper>
}

SmartIcon.propTypes = {
  type: PropTypes.oneOf([ 'userCommunity', 'orgCommunity', 'orgForm' ]),
  name: PropTypes.oneOf([ ...USER_COMMUNITIES, ...ORG_COMMUNITIES, ...ORG_FORMS, ...USER_SPECIAL_COMMUNITIES ]),
  color: PropTypes.string,
  size: PropTypes.number
}
