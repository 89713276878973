import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import {
  CardActions,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconButtonText } from "@alice/component_library";
import { Card } from "@components/RoleCard/Card";

/**
 * A component to display and manage RoleData
 */
export const RoleCard = ({
  roleData = {},
  roleConfig = {},
  onAddClick = () => {},
  onEditClick = () => {},
  onInfoClick = () => {},
  onDeleteClick = () => {},
}) => {
  const { t } = useTranslation("componentLibrary");

  const onCardClick = (event) => {
    if (roleConfig.selected) {
      const editButton = event.currentTarget.nextSibling.lastChild.firstChild;

      editButton.click();
    } else {
      const addButton = event.currentTarget.nextSibling.lastChild;

      addButton.click();
    }
  };

  return (
    <Card
      data-testid="RoleCard"
      className={roleConfig.selected ? "active" : ""}
      sx={{
        ".iconButtonText": { color: "black" },
        "&:hover": {
          backgroundColor: "grey.30",
          cursor: "pointer",
          color: "white",
          ".iconButtonText": { color: "white" },
        },
        // on selected
        "&.active": {
          backgroundColor: "grey.5",
          color: "white",
          ".iconButtonText": { color: "white" },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "grey.30",
          },
        },
      }}
    >
      <Stack
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <CardContent onClick={onCardClick} sx={{ px: 3, py: 2 }}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={roleData.name}
                enterDelay={1000}
                enterNextDelay={1000}
                arrow
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 18,
                    lineHeight: "28px",
                  }}
                  noWrap
                >
                  {roleData.name}
                </Typography>
              </Tooltip>

              {roleConfig.selected && (
                <CheckCircleIcon
                  fontSize="small"
                  sx={{
                    color: "green.40",
                    backgroundColor: "common.white",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Stack>

            {roleData.description && (
              <Tooltip
                title={roleData.description}
                enterDelay={1000}
                enterNextDelay={1000}
                placement="top"
                arrow
              >
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    wordBreak: "break-word",
                    whiteSpace: "break-spaces",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {roleData.description}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        </CardContent>

        <CardActions
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            "&:hover": { cursor: "default" },
          }}
        >
          <IconButtonText
            text={t("roleElements.details")}
            icon={<InfoIcon />}
            active={roleConfig.infoActive}
            onClick={onInfoClick}
          />

          {roleConfig.selected ? (
            <Stack direction="row" spacing={2}>
              <IconButtonText
                id="editButton"
                className="editButton"
                text={t("roleElements.editOptions")}
                icon={<EditIcon />}
                active={roleConfig.editActive}
                onClick={onEditClick}
              />
              <IconButtonText
                text={t("roleElements.remove")}
                icon={<CancelIcon />}
                onClick={onDeleteClick}
              />
            </Stack>
          ) : (
            <IconButtonText
              text={t("roleElements.addRole")}
              icon={<AddCircleIcon />}
              active={roleConfig.addActive}
              onClick={onAddClick}
            />
          )}
        </CardActions>
      </Stack>
    </Card>
  );
};

RoleCard.propTypes = {
  /** RoleData to display */
  roleData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  /** Data to manage the state of the buttons and the card */
  roleConfig: PropTypes.shape({
    addActive: PropTypes.bool,
    infoActive: PropTypes.bool,
    editActive: PropTypes.bool,
    selected: PropTypes.bool,
  }).isRequired,
  /**
   * Function to be executed on Details click
   *
   * @param {Event}
   * @returns {void}
   */
  onInfoClick: PropTypes.func.isRequired,
  /**
   * Function to be executed on Add click
   *
   * @param {Event}
   * @returns {void}
   */
  onAddClick: PropTypes.func,
  /**
   * Function to be executed on Edit click
   *
   * @param {Event}
   * @returns {void}
   */
  onEditClick: PropTypes.func,
  /**
   * Function to be executed on Remove click
   *
   * @param {Event}
   * @returns {void}
   */
  onDeleteClick: PropTypes.func,
};
