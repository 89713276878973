import { format } from 'date-fns'
import { de, enGB, enUS, es, fr, hu, it, ja, ko, nl, pl, pt, ru, tr, zhCN } from 'date-fns/locale'

const locales = {
  en: enGB || enUS || undefined,
  de,
  es,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  ru,
  tr,
  'zh-cn': zhCN
}

export class DateUtils {
  static formatDate(date, variant) {
    let dateFormat

    if (variant === 'short') {
      dateFormat = 'PP'
    } else {
      dateFormat = 'PPP'
    }

    return format(date, dateFormat, { locale: DateUtils.getCurrentLocale() })
  }

  static daysBetweenDates(dateFrom, dateTo) {
    const oneDay = 1000 * 60 * 60 * 24

    return Math.round((dateTo - dateFrom) / oneDay)
  }

  static getCurrentLocale() {
    const currentLocale = localStorage?.getItem('i18nextLng') || 'en'

    return locales[currentLocale]
  }
}
