import React from "react";
import { FilterChip } from "@alice/component_library";
import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { AliceIcon } from "@alice/component_library";

export const GenericSearchFilter = ({
  activeFilter = "",
  label = "",
  filters = [],
  onChange = () => {},
  sx = {},
  isNeedToReset = true,
}) => {
  const handleFilterChange = (filter, isActive) => {
    if (!isActive && activeFilter !== "" && isNeedToReset) {
      onChange("");
      return;
    } else if (!isActive && activeFilter !== "") {
      return;
    }

    onChange(filter);
  };

  return (
    <Stack gap={0.5} sx={{ ...sx }}>
      <Typography
        sx={{
          fontSize: "14px",
          color: "text.secondary",
        }}
      >
        {label}
      </Typography>

      <Stack direction="row" flexWrap="wrap" gap={1}>
        {filters.map(({ filter, label, icon: Icon, isalice, size = 13 }) => (
          <FilterChip
            key={filter}
            label={label}
            icon={
              Icon ? (
                !isalice ? (
                  <Icon fontSize="small" />
                ) : (
                  <AliceIcon
                    color="black"
                    iconType={Icon}
                    size={size}
                    sx={{ margin: "1px" }}
                  />
                )
              ) : null
            }
            onClick={(isActive) => handleFilterChange(filter, isActive)}
            isActive={activeFilter === filter}
          />
        ))}
      </Stack>
    </Stack>
  );
};

GenericSearchFilter.propTypes = {
  activeFilter: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.any,
    })
  ),
  onChange: PropTypes.func,
};
