import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Chip, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Accordion, AccordionSummary } from '../../../accordion'

export const PeoplePickerAccordion = ({
  itemType,
  selectedItems,
  ItemCard,
  getItemCardProps = (item) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [ isExpanded, setIsExpanded ] = useState(false)

  return (
    <Accordion
      aria-controls={`PeoplePicker-Content-${itemType}`}
      id={`PeoplePicker-Header-${itemType}`}
      expanded={isExpanded}
      sx={[
        {
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'divider',
          cursor: 'unset',
          boxShadow: 'unset',
          transition: 'background-color 250ms',
          '&.Mui-expanded': { margin: 0 }
        },
        { backgroundColor: isExpanded ? 'common.white' : 'blue.95' }
      ]}
      header={(
        <AccordionSummary onClick={() => setIsExpanded(!isExpanded)}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
            <Stack direction="row" gap={1} justifyContent="center">
              <Typography variant="h5">{t(`peoplePicker.selected${itemType}s`)}</Typography>

              <Chip
                label={selectedItems?.length || 0}
                color="primary"
                size="small"
                sx={{ fontSize: '12px', fontWeight: '700' }}
              />
            </Stack>

            <Stack
              sx={{
                flexFlow: 'row',
                gap: 1,
                color: isExpanded ? 'primary.main' : 'text.primary'
              }}
            >
              {!isMobile && (
                <Typography variant="button" sx={{ transition: 'color 250ms' }}>
                  {isExpanded
                    ? t('peoplePicker.toggleExpanded')
                    : t('peoplePicker.toggleCollapsed')}
                </Typography>
              )}

              <ArrowDropDownIcon
                sx={[
                  { transition: 'transform 250ms' },
                  isExpanded && { transform: 'rotate(180deg)' }
                ]}
              />
            </Stack>
          </Stack>
        </AccordionSummary>
      )}
    >
      {selectedItems?.length
        ? (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)'
              },
              gap: 1
            }}
          >
            {selectedItems.map((item) => (
              <ItemCard
                key={item.id || item.uuid}
                isContextShown={false}
                {...getItemCardProps(item)}
              />
            ))}
          </Box>
        )
        : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100px'
            }}
          >
            <Typography
              variant="body2"
              textAlign="center"
              color="text.secondary"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {t(`peoplePicker.no${itemType}s`)}
            </Typography>
          </Box>
        )}
    </Accordion>
  )
}

PeoplePickerAccordion.propTypes = {
  itemType: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, uuid: PropTypes.string })).isRequired,
  ItemCard: PropTypes.func.isRequired,
  /**
   * @param item
   * @returns {object}
   */
  getItemCardProps: PropTypes.func.isRequired
}
