import { combineReducers } from "redux";
import AccessReviewReducer from "@src/redux/reducers/accessReviewReducer";
import UserReducer from "@src/redux/reducers/userReducer";
import OpenTaskReducer from "@src/redux/reducers/openTaskReducer";
import RequestOverViewReducer from "@src/redux/reducers/requestOverViewReducer";
import RoleReducer from "@src/redux/reducers/roleReducer";
import ProfileReducer from "@src/redux/reducers/profileReducer";
import controlCenter from "@src/redux/reducers/controlCenter";
import commonReducer from "./commonSlice";
// import controlCenterReducer from "./controlCenterSlice";
// import OpenTaskReducer from "./openTaskSlice";
import newRequestReducer from "./newRequestSlice";
import userSlice from "./userSlice";

const rootReducer = combineReducers({
  common: commonReducer,
  newRequestSlice: newRequestReducer,
  // controlCenterSlice: controlCenterReducer,
  // taskSlice: OpenTaskReducer,
  user: UserReducer,
  userSlice: userSlice,
  task: OpenTaskReducer,
  role: RoleReducer,
  overview: RequestOverViewReducer,
  profile: ProfileReducer,
  controlCenter: controlCenter,
  accessReview: AccessReviewReducer,
});

export default rootReducer;
