import React from "react";
import { hasData } from "@utils/Validator";
import LabelValue from "@components/LabelValue";
import { isSuperAdmin } from "@utils";
import { Switch } from "@components/Buttons";

import { AdminSwitchContainer } from "../styledComponents";

const AdminVisiblitySwicth = ({
  actionHandler,
  adminRoles,
  adminMode,
  translate,
}) => {
  return (
    <>
      {hasData(adminRoles) && isSuperAdmin(adminRoles, "task") && (
        <AdminSwitchContainer>
          <div>
            <LabelValue
              value={translate("show-all-tasks")}
              valueStyles={{
                color: "#000000",
                fontSize: "18px",
                padding: "0px",
              }}
            />
            <LabelValue label={translate("administration-mode")} />
          </div>
          <div className="ml-10">
            <Switch
              clickHandler={(sl) => {
                actionHandler(sl);
              }}
              checked={adminMode}
              inputProps={{ "aria-label": "Super admin control" }}
            />
          </div>
        </AdminSwitchContainer>
      )}
    </>
  );
};

export default AdminVisiblitySwicth;
