import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { IconButton } from '../../../../button'
import { Skeleton } from '../../../../skeleton'
import { mergeSxProps } from '../../../../../util'

export const ContextBtn = ({
  ContextPopover,
  id,
  isLoading,
  sx,
  ...otherProps
}) => (
  <Box className="contextBtn" sx={mergeSxProps({ margin: -0.5 }, sx)}>
    {isLoading
      ? (
        <Skeleton
          variant="circular"
          sx={{
            width: '20px',
            height: '20px',
            margin: '6px' // 2 (20/2 -> 24/2) + 4 (0.5 padding)
          }}
        />
      )
      : (
        <ContextPopover id={id} placement="left" {...otherProps}>
          <IconButton
            hasSmallClickArea
            color="grey.45"
            sx={{ fontSize: '24px' }}
          >
            <InfoOutlined fontSize="inherit" />
          </IconButton>
        </ContextPopover>
      )}
  </Box>
)
