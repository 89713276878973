import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { InteractionPropTypes } from '../../../constants'
import { createArrayWithInitialValues } from '../../../util'
import { MarketCard } from '../marketCard/MarketCard'

/**
 * This component renders a grid view using `UserCard` components, specifically designed for user search results.
 * The use of MUI-Grid with `UserCard` is preferred to achieve an optimal layout and responsive design.
 *
 * Important Notes:
 * - This component is purely for presentation and does not handle data fetching or state management.
 *   States such as `selectedUser` should be managed by a parent component.
 * - It is optimized for integration with user search functionalities, potentially in combination with components like `UserSearch` or `GenericSearch`.
 * - On mobile devices, a 'list' view mode is automatically adopted to enhance usability.
 */
export const MarketSearchGridViewRenderer = ({
  view = 'grid',
  data: markets,
  selectedData: selectedMarkets = [],
  disabledData: disabledMarkets = [],
  onItemClick,
  rowsPerPage,
  interaction,
  onGetHref,
  isIdLink = true,
  isLoading,
  isMobile, // Set as prop to avid errors in console.
  setSelectedData, // Set as prop to avid errors in console.
  ...otherProps
}) => {
  // todo: remove tiny
  view = view === 'tiny' ? 'list' : view

  const gridSx = [
    {
      display: 'grid',
      rowGap: 1,
      columnGap: 2,
      maxWidth: '100%'
    },
    view === 'grid' && { gridTemplateColumns: 'repeat(2, 1fr)' },
    view === 'list' && { gridTemplateColumns: 'repeat(1, 1fr)' }
  ]

  const mappableMarkets = isLoading
    ? createArrayWithInitialValues(rowsPerPage, (value, index) => ({ country: index.toString() }))
    : markets

  return (
    <Box data-testid="MarketSearchGridViewRenderer" sx={gridSx}>
      {mappableMarkets.map((market) => (
        <MarketCard
          key={market.country}
          variant="list"
          market={market}
          interaction={interaction}
          href={onGetHref}
          isIdLink={isIdLink}
          isLoading={isLoading}
          isSelected={selectedMarkets.includes(market.country)}
          isDisabled={disabledMarkets.includes(market.country)}
          onCardClick={onItemClick}
          {...otherProps}
        />
      ))}
    </Box>
  )
}

MarketSearchGridViewRenderer.propTypes = {
  /** View type */
  view: PropTypes.oneOf([ 'grid', 'list' ]).isRequired,
  /** Users to be displayed */
  data: PropTypes.any,
  /** Selected markets */
  selectedData: PropTypes.arrayOf(PropTypes.string),
  /** Disabled markets */
  disabledData: PropTypes.arrayOf(PropTypes.string),
  /** Callback when card got clicked */
  onItemClick: PropTypes.func,
  /** Items per page */
  rowsPerPage: PropTypes.number,
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  onGetHref: PropTypes.func,
  /** Determines whether the card ID is associated with a link */
  isIdLink: PropTypes.bool,
  /** Loading state */
  isLoading: PropTypes.bool
}
