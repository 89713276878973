import useSelectedTask from "../CustomHook/UseSelectedTask";
import { RejectCard } from "../../styledComponents";
import CloseIconForCard from "@Icons/closemulti.svg";
import { Grid } from "@mui/material";

const SelectedTaskRow = ({
  rows,
  handleRemove,
  hideRemove,
  bgColor = "#F4F4F4",
  key,
}) => {
  const { idwithTypeIcon, LeftData, RightData } = useSelectedTask(rows);
  return (
    <RejectCard spacing={2} bgcolor={bgColor} key={key}>
      {!hideRemove && (
        <CloseIconForCard
          onClick={() => handleRemove(rows.id)}
          id="delete-close"
        />
      )}
      <Grid item xs={12} sm={12} md={2}>
        <div>{idwithTypeIcon}</div>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <div>{LeftData}</div>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <div>{RightData}</div>
      </Grid>
    </RejectCard>
  );
};

export default SelectedTaskRow;
