import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const CommunityScope = ({
  administratedCommunities,
  selectedCommunity,
  setSelectedCommunity,
  availableCommunities = [
    'DEALER',
    'CORPORATE_CUSTOMER',
    'SUPPLIER',
    'CORPORATE_BUSINESS_PARTNER'
  ]
}) => {
  const { t } = useTranslation('componentLibrary')

  // Should only work for GCA
  const alreadySelectedScopes = useMemo(() => {
    if (administratedCommunities) {
      return administratedCommunities.map((com) => com.community)
    }

    return []
  }, [ administratedCommunities ])

  const handleChange = (event) => {
    if (event.target.checked) {
      const newScope = [ ...selectedCommunity ]

      newScope.push(event.target.name)
      setSelectedCommunity(newScope)
    } else {
      const newScope = [ ...selectedCommunity ]
      const filtered = newScope.filter((ele) => ele !== event.target.name)

      setSelectedCommunity(filtered)
    }
  }

  return (
    <Stack data-testid="CommunityScope">
      <FormGroup>
        {availableCommunities.map((ele) => (
          <FormControlLabel
            data-testid={`CommunityScope-${ele}`}
            key={ele}
            control={(
              <Checkbox
                checked={selectedCommunity.includes(ele) || alreadySelectedScopes.includes(ele)}
                disabled={alreadySelectedScopes.includes(ele)}
                onChange={handleChange}
                name={ele}
              />
            )}
            label={t(`communities.${ele}`)}
          />
        ))}
      </FormGroup>
    </Stack>
  )
}

CommunityScope.propTypes = {
  administratedCommunities: PropTypes.array.isRequired,
  selectedCommunity: PropTypes.array.isRequired,
  setSelectedCommunity: PropTypes.func.isRequired,
  availableCommunities: PropTypes.array
}
