import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { mergeSxProps } from '../../../util'

/**
 * A pre styled footer bar, that sticky to the bottom of the section
 */
export const StickyHeaderBar = forwardRef(({
  children,
  sx = [],
  ...otherProps
}, ref) => {
  const [ boxRef, setBoxRef ] = useState(null)

  useImperativeHandle(ref, () => boxRef)

  useMemo(() => {
    if (boxRef) {
      const observer = new IntersectionObserver(([ entry ]) => {
        entry.target.classList.toggle('pinned', !entry.isIntersecting)
      }, {
        rootMargin: '-64px', // todo: make dynamic
        threshold: [ 1 ]
      })

      observer.observe(boxRef)
    }
  }, [ boxRef ])

  return (
    <Box
      data-testid="StickyHeaderBar"
      className="stickyHeaderBar"
      ref={setBoxRef}
      sx={mergeSxProps(
        {
          zIndex: 5, /* Otherwise, MUI-Textfield Labels are above the bar */
          position: 'sticky',
          display: 'flex',
          top: '63px', // headerHeight -1px
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'common.white',
          paddingBlock: 1,
          paddingInline: 3,
          marginInline: -3,
          transition: 'box-shadow 500ms',
          borderBottom: '1px solid',
          borderBottomColor: 'grey.80',
          '&.pinned': { boxShadow: '0 10px 10px -10px rgb(0 0 0 / 0.3)' }
        },
        sx
      )}
      {...otherProps}
    >
      {children}
    </Box>
  )
})

StickyHeaderBar.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node
}
