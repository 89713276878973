import { PopupCardContainer } from "./PopupStyle";

const Popups = ({ data }) => {
  return (
    <>
      <PopupCardContainer>
        <div className="header_description">{data?.header_description}</div>
        <div className="review-reason">
          <div className="header-text">{data?.reason}</div>
          {data?.dropdown}
          <div>{data?.selectedreason}</div>
        </div>
        {data?.validityHeader ? (
          <div className="review-reason">
            <div className="header-text">{data?.validityHeader}</div>
            <div>{data?.validity}</div>
          </div>
        ) : null}
        {data?.content ? (
          <div className="selected-assignments">
            <div className="selected-assignments-title">
              {data?.selected_assignments}
            </div>
            <div>{data?.content}</div>
          </div>
        ) : null}
      </PopupCardContainer>
    </>
  );
};

export default Popups;
