import React from "react";
import "../Icon.css";
import "../App.css";
import NoTasksIcon from "../pages/icons/noTasksIcons";
import { Grid } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "./button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NoTasks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const redirectTask = () => {
    navigate("/access/tasks");
  };
  const redirectHome = () => {
    navigate("/access");
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="text-align-center display-block pt-160"
      >
        <Grid className="mh-60vh">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="request-responsive"
          >
            <NoTasksIcon />

            <h3 className="font-weight-500">
              <span className="font-bold res-wrp font-20">
                {" "}
                {t("task-not-found")}{" "}
              </span>
            </h3>

            <h4 className="font-weight-100 font-16">
              {" "}
              {t("task-not-available-msg")}
            </h4>
            <br />
            <div>
              <SecondaryButton
                label={t("go-home")}
                onButtonClick={redirectHome}
              />
              <PrimaryButton
                label={t("menuBar.allTasks")}
                onButtonClick={redirectTask}
                className="btn-ml-26"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className="mh-8vh"></div>
    </>
  );
};

export default NoTasks;
