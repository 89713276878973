import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ReplayIcon from '@mui/icons-material/Replay'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { Box, Checkbox, Divider, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateUtils } from '../../../util'
import { IconButton, IconButtonText } from '../../button'
import { Card } from '../../card'
import { StateIcon } from '../../icon'
import { OrgContextPopover } from '../../orgElements'

// Styles
import styles from './RoleCardV2.module.scss'

const DATE_OPTIONS_LONG = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}

const DATE_OPTIONS_SHORT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

/**
 * Role Card V2 component
 */
export const RoleCardV2 = ({
  data,
  state = 'none',
  size = 'medium',
  hideActions = false,
  isOpen = false,
  isSelected = false,
  onSelect = (data, selected) => {},
  onReapply = (data) => {},
  onModify = (data) => {},
  onDelete = (data) => {},
  onExpand = (data, open) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ validFromDate, setValidFromDate ] = useState('-')
  const [ validToDate, setValidToDate ] = useState('-')
  const [ validToDaysLeft, setValidToDaysLeft ] = useState(null)

  // validFrom, validTo, orgScope, customScope could be undefined
  const {
    validFrom,
    validTo,
    orgScope,
    customScope,
    roleDefinition
  } = useMemo(() => data, [ data ])

  useEffect(() => {
    if (!data) return

    const dateOptions = size === 'small' ? DATE_OPTIONS_SHORT : DATE_OPTIONS_LONG

    setValidFromDate(validFrom
      ? new Date(Date.parse(validFrom)).toLocaleDateString(
        undefined,
        dateOptions
      )
      : '-')

    if (validTo) {
      const today = new Date(Date.now())
      const validToParsed = new Date(Date.parse(validTo))
      const daysBetween = DateUtils.daysBetweenDates(today, validToParsed)

      setValidToDate(validToParsed.toLocaleDateString(undefined, dateOptions))
      setValidToDaysLeft(daysBetween)
    } else {
      setValidToDate('-')
      setValidToDaysLeft(null)
    }
  }, [ data, validFrom, validTo, size ])

  const getBackgroundColor = () => {
    switch (state) {
      case 'none':
        return 'grey.95'
      case 'success':
        return 'green.95'
      case 'info':
        return 'blue.95'
      case 'warning':
        return 'yellow.95'
      case 'error':
        return 'red.95'
      default:
        return 'grey.95'
    }
  }

  const getChipBackgroundColor = () => {
    if (validToDaysLeft === null) return 'transparent'
    if (validToDaysLeft < 0) return 'red.80'
    if (validToDaysLeft <= 30) return 'yellow.80'
    return 'transparent'
  }

  const getSize = () => {
    switch (size) {
      case 'small':
        return '' // styles.small
      case 'large':
        return styles.large
      case 'medium':
      default:
        return '' // styles.medium
    }
  }

  const getHiddenAction = () => (hideActions ? styles.hiddenAction : '')
  const getOpen = () => (isOpen ? styles.open : '')

  const handleCheckboxClick = () => onSelect(data, !isSelected)
  const handleReapplyClick = () => onReapply(data)
  const handleModifyClick = () => onModify(data)
  const handleDeleteClick = () => onDelete(data)
  const handleExpandClick = () => onExpand(data, !isOpen)

  return (
    <Card
      data-testid="RoleCardV2"
      sx={{ backgroundColor: getBackgroundColor() }}
      className={`${styles.roleCardV2} ${getSize()} ${getHiddenAction()} ${getOpen()}`}
    >
      <Stack
        className={styles.roleCardV2Layout}
        direction="row"
        divider={!hideActions ? <Divider flexItem orientation="vertical" /> : null}
      >
        <div className={`${styles.roleCardV2Container}`}>
          <div className={styles.roleCardV2NameArea}>
            {hideActions && state !== 'none' && <StateIcon state={state} />}

            {!hideActions && (
              <Checkbox
                onClick={handleCheckboxClick}
                checked={isSelected}
                sx={{
                  padding: 0,
                  margin: 0
                }}
              />
            )}

            <div className={`${styles.roleCardV2Header}`}>
              <div className={`${styles.roleCardV2HeaderName}`}>
                {roleDefinition?.name}
              </div>

              <div
                className={`${styles.roleCardV2HeaderID}`}
                style={{ color: 'grey.60' }}
              >
                {roleDefinition?.id}
              </div>
            </div>
          </div>

          {!hideActions && (
            <Box className={`${styles.roleCardV2ActionArea}`}>
              { /* Using IconButtonText because otherwise the padding is off. */}
              {validToDaysLeft < 0 ? (
                <IconButtonText
                  onClick={handleReapplyClick}
                  icon={<ReplayIcon />}
                  text={size === 'small' ? '' : t('roleElements.reapply')}
                  color="blue.50"
                />
              ) : (
                <IconButtonText
                  onClick={handleModifyClick}
                  icon={<EditOutlinedIcon />}
                  text={size === 'small' ? '' : t('roleElements.modify')}
                  color="blue.50"
                />
              )}
            </Box>
          )}

          <div className={`${styles.roleCardV2ContentArea}`}>
            {/* OrgScope */}
            <div className={styles.roleCardV2Scope}>
              <CorporateFareOutlinedIcon fontSize="small" />

              {size !== 'small' && (
                <div className={styles.roleCardV2ScopeText}>
                  {t('roleElements.orgScope')}
                </div>
              )}
            </div>

            <div className={styles.roleCardV2ScopeName}>
              <div className={styles.roleCardV2ScopeNameText}>
                {orgScope?.name || '-'}
              </div>

              {orgScope?.name && (
                <Box sx={{ marginBottom: '-6px' }}>
                  <OrgContextPopover organizationId={orgScope?.id}>
                    <InfoOutlinedIcon sx={{ color: 'blue.50' }} fontSize="small" />
                  </OrgContextPopover>
                </Box>
              )}
            </div>

            {/* CustomScope */}
            <div className={styles.roleCardV2Scope}>
              <AccountTreeOutlinedIcon fontSize="small" />

              {size !== 'small' && (
                <div className={styles.roleCardV2ScopeText}>
                  {t('roleElements.customScope')}
                </div>
              )}
            </div>

            <div className={styles.roleCardV2ScopeName}>
              <div className={styles.roleCardV2ScopeNameText}>
                {customScope?.name || '-'}
              </div>
            </div>

            {/* Validity */}
            <div className={`${styles.roleCardV2Scope} ${styles.roleCardV2ScopeValidity}`}>
              <CalendarMonthOutlinedIcon fontSize="small" />

              {size !== 'small' && (
                <div className={styles.roleCardV2ScopeText}>
                  {t('roleElements.validity')}
                </div>
              )}
            </div>

            <Stack direction="row" spacing={2} className={styles.roleCardV2Scope}>
              {/* Valid From */}
              <div className={styles.roleCardV2ValidFrom}>
                <div className={styles.roleCardV2ValidFromText}>
                  {t('roleElements.validFrom')}
                </div>

                <div className={styles.roleCardV2ValidFromDate}>
                  {validFromDate}
                </div>
              </div>

              {/* Valid To */}
              <div className={styles.roleCardV2ValidTo}>
                <div className={styles.roleCardV2ValidToText}>
                  {t('roleElements.validTo')}
                </div>

                <Box
                  sx={{ backgroundColor: getChipBackgroundColor() }}
                  className={styles.roleCardV2Card}
                >
                  {validToDate === '-' ? (
                    <div className={styles.roleCardV2ValidToDate}>
                      {validToDate}
                    </div>
                  ) : (
                    <>
                      <div className={styles.roleCardV2ValidToDate}>
                        {validToDate}
                      </div>

                      <div className={styles.roleCardV2CardDivider} />

                      <div className={styles.roleCardV2CardDaysLeft}>
                        <TimerOutlinedIcon fontSize="small" />

                        <div>
                          {`${validToDaysLeft >= 0 ? validToDaysLeft : 0}${size === 'small' ? t('roleElements.daysLeftShort') : ` ${t('roleElements.daysLeft')}`}`}
                        </div>
                      </div>
                    </>
                  )}
                </Box>
              </div>
            </Stack>
          </div>
        </div>

        {!hideActions && (
          <div className={styles.roleCardV2SidebarContainer}>
            <div className={styles.roleCardV2DeleteButton}>
              <IconButton onClick={handleDeleteClick}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </div>

            <div className={`${styles.roleCardV2OpenButton}`}>
              <IconButton onClick={handleExpandClick}>
                <ExpandMoreOutlinedIcon sx={{ color: 'common.black' }} />
              </IconButton>
            </div>
          </div>
        )}
      </Stack>
    </Card>
  )
}

RoleCardV2.propTypes = {
  /** the entire object provided by the api */
  data: PropTypes.shape({
    validFrom: PropTypes.string,
    validTo: PropTypes.string,
    customScope: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    }),
    orgScope: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    roleDefinition: PropTypes.shape({
      customScopes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string
        })
      ),
      defaultValidityType: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      isDynamic: PropTypes.bool,
      isJobTitle: PropTypes.bool,
      isSelfRequestable: PropTypes.bool,
      isWorkflowBased: PropTypes.bool,
      name: PropTypes.string,
      needsAdditionalSelfRequestApproval: PropTypes.bool,
      needsCustomScopes: PropTypes.bool,
      needsOrgScopes: PropTypes.bool
    })
  }),
  state: PropTypes.oneOf([ 'success', 'info', 'warning', 'error', 'none' ]),
  /** size of the role card */
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  /** hides actions, if you wanna just display the role by itself */
  hideActions: PropTypes.bool,
  /** defines, if the role card is open or not */
  isOpen: PropTypes.bool,
  /** defines, if the role card is selected or not */
  isSelected: PropTypes.bool,
  /**
   * called when the role card got selected
   *
   * @param data
   * @param selected
   * @returns {void}
   */
  onSelect: PropTypes.func,
  /**
   * called when user clicked on reapply button
   *
   * @param data
   * @returns {void}
   */
  onReapply: PropTypes.func,
  /**
   * called when user clicked on modify button
   *
   * @param data
   * @returns {void}
   */
  onModify: PropTypes.func,
  /**
   * called when user clicked on delete button
   *
   * @param data
   * @returns {void}
   */
  onDelete: PropTypes.func,
  /**
   * called when user clicked on expand button
   *
   * @param data
   * @param open
   * @returns {void}
   */
  onExpand: PropTypes.func
}
