import { FormHelperText, TextField as MuiTextField } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'

/**
 * Wrapper for [Mui TextField](https://mui.com/material-ui/api/text-field/)
 */
export const TextField = forwardRef((
  {
    error = false,
    FormHelperTextProps,
    helperText,
    sxHelperText,
    maxLength = null,
    minLength = null,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    showCounter = false,
    variant = 'filled',
    sx = [],
    ...otherProps
  },
  ref
) => {
  const [ charsCount, setCharsCount ] = useState(0)
  const [ hasError, setHasError ] = useState(false)

  let charsCountText = ''

  if (showCounter) {
    charsCountText = charsCount

    if (minLength && maxLength) {
      charsCountText = `${minLength} / ${charsCount} / ${maxLength}`
    } else if (minLength) {
      charsCountText = `${minLength} / ${charsCount}`
    } else if (maxLength) {
      charsCountText = `${charsCount} / ${maxLength}`
    }
  }

  const handleOnChange = (e) => {
    const valueLength = parseInt(e.target.value?.length, 10)

    if ((minLength && valueLength < minLength) || (maxLength && valueLength > maxLength)) {
      setHasError(true)
    } else {
      setHasError(false)
    }

    setCharsCount(valueLength)
    onChange(e)
  }

  return (
    <div
      data-testid="TextField"
      ref={ref}
      style={{
        display: 'inline-block',
        verticalAlign: 'top',
        width: otherProps.fullWidth ? '100%' : 'initial'
      }}
    >
      <MuiTextField
        error={error || hasError}
        FormHelperTextProps={FormHelperTextProps}
        onChange={handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        variant={variant}
        {...otherProps}
      />

      {(helperText || showCounter) && (
        <FormHelperText
          sx={{
            display: 'grid',
            gridTemplate: "'text count'",
            justifyContent: 'space-between',
            paddingInline: 1,
            marginTop: 0,
            ...sxHelperText
          }}
        >
          {helperText && <span style={{ gridArea: 'text' }}>{helperText}</span>}
          {showCounter && <span style={{ gridArea: 'count' }}>{charsCountText}</span>}
        </FormHelperText>
      )}
    </div>
  )
})

TextField.propTypes = {
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.<br>
   * The name can be confusing, as it's more like an autofill.<br>
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /** If `true`, the `input` element is focused during the first mount. */
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  /** Override or extend the styles applied to the component. */
  classes: PropTypes.object,
  className: PropTypes.string,
  /**
   * The color of the component.<br>
   * It supports both default and custom theme colors, which can be added as shown in the<br>
   * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
   */
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      'primary',
      'secondary',
      'error',
      'info',
      'success',
      'warning'
    ]),
    PropTypes.string
  ]),
  /** The default value. Use when the component is not controlled. */
  defaultValue: PropTypes.any,
  /** If `true`, the component is disabled. */
  disabled: PropTypes.bool,
  /** If `true`, the label is displayed in an error state. */
  error: PropTypes.bool,
  /** Props applied to the [`FormHelperText`](/material-ui/api/form-helper-text/) element. */
  FormHelperTextProps: PropTypes.object,
  /** If `true`, the input will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** The helper text content. */
  helperText: PropTypes.node,
  /**
   * The id of the `input` element.<br>
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string,
  /**
   * Props applied to the [`InputLabel`](/material-ui/api/input-label/) element.<br>
   * Pointer events like `onClick` are enabled if and only if `shrink` is `true`.
   */
  InputLabelProps: PropTypes.object,
  /** [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element. */
  inputProps: PropTypes.object,
  /**
   * Props applied to the Input element.<br>
   * It will be a [`FilledInput`](/material-ui/api/filled-input/),<br>
   * [`OutlinedInput`](/material-ui/api/outlined-input/) or [`Input`](/material-ui/api/input/)<br>
   * component depending on the `variant` prop value.
   */
  InputProps: PropTypes.object,
  /** Pass a ref to the `input` element. */
  inputRef: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
  /** The label content. */
  label: PropTypes.node,
  /** Maximum number of rows to display when multiline option is set to true. */
  maxRows: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  /** Minimum number of rows to display when multiline option is set to true. */
  minRows: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  /** If `true`, a `textarea` element is rendered instead of an input. */
  multiline: PropTypes.bool,
  /** Name attribute of the `input` element. */
  name: PropTypes.string,
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.<br>
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  /** The short hint displayed in the `input` before the user enters a value. */
  placeholder: PropTypes.string,
  /** If `true`, the label is displayed as required and the `input` element is required. */
  required: PropTypes.bool,
  /** Number of rows to display when multiline option is set to true. */
  rows: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  /**
   * Render a [`Select`](/material-ui/api/select/) element while passing the Input element to `Select` as `input` parameter.<br>
   * If this option is set you must pass the options of the select as children.
   */
  select: PropTypes.bool,
  /** Props applied to the [`Select`](/material-ui/api/select/) element. */
  SelectProps: PropTypes.object,
  /** The size of the component. */
  size: PropTypes.oneOf([ 'medium', 'small' ]),
  /** Shows a counter */
  showCounter: PropTypes.bool,
  /** Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types). */
  type: PropTypes.string,
  /** The value of the `input` element, required for a controlled component. */
  value: PropTypes.any,
  /** The variant to use. */
  variant: PropTypes.oneOf([ 'filled', 'outlined', 'standard' ]),
  sxHelperText: PropTypes.object, // additional Styling for the helper text
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
