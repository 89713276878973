import React from "react";
import { Typography, Divider } from "@mui/material";
import {
  Badge,
  TaskStatusChip,
  UserAvatar,
  UserContextPopover,
  UserListPopover,
} from "@alice/component_library";
import UserBadgeIcon from "@Icons/requestPage/user_badge.svg";

export const RequestId = ({ icon, id }) => {
  return (
    <div className="request-id">
      {icon}
      <Typography
        variant="body1"
        noWrap
        sx={{
          marginLeft: "4px",
          flex: 1,
          "& a": {
            color: "#0078D6",
            textDecoration: "none",
          },
        }}
      >
        {id}
      </Typography>
    </div>
  );
};
export const CommonColumn = ({ icon, data, highlight = false }) => {
  return (
    <div className={`request-id ${highlight ? "highlight" : ""}`}>
      {icon}
      {data && (
        <Typography
          variant="body1"
          sx={{
            marginLeft: "4px",
            flex: 1,
          }}
          noWrap
        >
          {data}
        </Typography>
      )}
    </div>
  );
};

export const StatusCell = ({ state, recipientList, title }) => {
  const length = recipientList.length;
  return (
    <div className="status-column-container">
      {length === 1 ? (
        <>
          <UserContextPopover id={recipientList[0]?.id}>
            <UserAvatar
              sizeStyle={{
                height: "32px",
                width: "32px",
                fontSize: "16px",
                // dotSize: "10px",
              }}
              userId={recipientList[0]?.id}
              userName={`${recipientList[0]?.givenname} ${recipientList[0]?.surname}`}
              showImage={true}
            />
          </UserContextPopover>
          <Divider orientation="vertical" flexItem sx={{ margin: "0 4px" }} />
        </>
      ) : length > 1 ? (
        <>
          <UserListPopover users={recipientList} title={title}>
            <div className="user-badge">
              <UserBadgeIcon />
              <Badge style={{ width: "30px", height: "25px" }} filled>
                {length}
              </Badge>
            </div>
          </UserListPopover>
          <Divider orientation="vertical" flexItem sx={{ margin: "0 4px" }} />
        </>
      ) : null}

      <TaskStatusChip sx={{ padding: "0px" }} type={state} variant="icon" />
    </div>
  );
};
