import Cookies from 'js-cookie'
import packageJson from '../../../package.json'
import { COUNTRY_LIST, DISPLAY_VALUE_MAP, LANGU_COOKIE_NAME } from '../AliceConstants'

export const printConsole = () => {
  /* eslint-disable no-useless-escape */
  console.log(
    `%c          _ _             _____                      _      _ _     
    /\\   | (_)           / ____|                    | |    (_) |    
   /  \\  | |_  ___ ___  | |     ___  _ __ ___  _ __ | |     _| |__  
  / /\\ \\ | | |/ __/ _ \\ | |    / _ \\| '_ ' _ \\| '_ \\| |    | | '_ \\ 
 / ____ \\| | | (_|  __/ | |___| (_) | | | | | | |_) | |____| | |_) |
/_/    \\_\\_|_|\\___\\___|  \\_____\\___/|_| |_| |_| .__/|______|_|_.__/ 
                                              | |                   
                                              |_|                   
`,
    'font-family:monospace'
  )
  console.log('/* =================================== */')
  console.log(`You are using the version: ${packageJson.version}`)
  console.log('/* =================================== */')
}

export const getVersion = () => packageJson?.version

export const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const abortableTimeout = (ms, { signal } = new AbortController()) => new Promise((resolve, reject) => {
  const abort = () => {
    clearTimeout(handle)
    reject()
  }

  const done = () => {
    signal?.removeEventListener('abort', abort)
    resolve()
  }

  const handle = setTimeout(done, ms)

  if (typeof signal?.throwIfAborted === 'function') signal?.throwIfAborted()
  signal?.addEventListener('abort', abort)
})

export const createArrayWithInitialValues = (length = 0, func = (value, index) => index) => {
  if (!func || !(typeof func === 'function')) return []

  return Array.from({ length }, func)
}

// eslint-disable-next-line max-len
export const getCountryByIsoCode = (isoCode) => COUNTRY_LIST.filter((country) => country.value === isoCode?.toUpperCase())?.[0]

export const changeUILanguage = (i18n, language) => {
  i18n.changeLanguage(language)
  Cookies.set(LANGU_COOKIE_NAME, language, {
    path: '/',
    secure: true
  })
}

export const getContrastColor = (hexColor) => {
  hexColor = hexColor.replace('#', '')
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export const getDisplayValue = (t, dataBaseValue) => {
  if (dataBaseValue === undefined || !DISPLAY_VALUE_MAP.hasOwnProperty(dataBaseValue)) return ''

  return t(`displayValues.${DISPLAY_VALUE_MAP.dataBaseValue}`)
}

export const deepEqual = (original, compare) => {
  if (original === compare) return true

  const isPrimitive = (obj) => obj !== Object(obj)

  if (isPrimitive(original) && isPrimitive(compare)) return original === compare

  if (Object.keys(original).length !== Object.keys(compare).length) return false

  for (const key in original) {
    if (!(key in compare)) return false // other object doesn't have this prop
    if (!deepEqual(original[key], compare[key])) return false
  }

  return true
}

// Could be changed to structuredClone once a wider range of users support it.
// https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
export const clone = (obj) => JSON.parse(JSON.stringify(obj))

// Usage:
// getUrlPArt(url).protocol -> 'https://'
// getUrlPArt(url).host -> 'www.gems.daimler.com'
// getUrlPArt(url).path -> returns only path after host url
// eslint-disable-next-line consistent-return
export const getUrlPart = (url) => {
  const cutUrl = url.split('//')

  if (cutUrl[0] === 'http:' || cutUrl[0] === 'https:') {
    const protocol = `${cutUrl[0]}//`
    const host = cutUrl[1].split('/')[0]
    const path = cutUrl[1].replace(host, '')

    return {
      protocol,
      host,
      path
    }
  }
}

export const isEmpty = (value) => (value == null
  || value.length === 0
  || (typeof value === 'object' && Object.keys(value || {}).length === 0))

export const flattenObject = (obj) => {
  const KEY_OBJECT_SEPERATOR = ': '

  const flattened = []

  const formatKey = (key) => {
    key = key.charAt(0).toUpperCase() + key.slice(1)
    return key.replace(/([A-Z])/g, '$1') // Adds a space before all uppercase letters
  }

  const isAllowedValue = (obj) => obj !== '' && obj !== '-' && obj !== undefined && obj !== null

  for (const key in obj) {
    // eslint-disable-next-line no-continue
    if (!obj.hasOwnProperty(key)) continue

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      const flatObject = flattenObject(obj[key])

      for (const subKey in flatObject) {
        // eslint-disable-next-line no-continue
        if (!flatObject.hasOwnProperty(subKey)) continue

        if (flatObject[subKey] !== '' && flatObject[subKey] !== undefined && flatObject[subKey] !== null) {
          flattened.push(formatKey(key) + KEY_OBJECT_SEPERATOR + flatObject[subKey])
        }
      }
    } else if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        if (typeof obj[key][i] === 'object' && obj[key][i] !== null) {
          const flatObject = flattenObject(obj[key][i])

          for (const subKey in flatObject) {
            // eslint-disable-next-line no-continue
            if (!flatObject.hasOwnProperty(subKey)) continue

            if (flatObject[subKey] !== '' && flatObject[subKey] !== undefined && flatObject[subKey] !== null) {
              flattened.push(formatKey(key) + KEY_OBJECT_SEPERATOR + flatObject[subKey])
            }
          }
        } else if (isAllowedValue(obj[key][i])) {
          flattened.push(formatKey(key) + KEY_OBJECT_SEPERATOR + obj[key][i])
        }
      }
    } else if (isAllowedValue(obj[key])) {
      flattened.push(formatKey(key) + KEY_OBJECT_SEPERATOR + obj[key])
    }
  }

  return flattened
}

export const mergeSxProps = (...sxProps) => {
  if (!sxProps || sxProps.length <= 0) return {}
  if (sxProps.length === 1) return sxProps[0]

  const sx = []

  sxProps.forEach((sxProp) => {
    sx.push(...(Array.isArray(sxProp) ? sxProp : [ sxProp ]))
  })

  return sx
}

export const mergeObjects = (obj1, obj2) => {
  const mergedObj = { ...obj1 }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && typeof mergedObj[key] === 'object' && mergedObj[key] !== null) {
        mergedObj[key] = mergeObjects(mergedObj[key], obj2[key])
      } else {
        mergedObj[key] = obj2[key]
      }
    }
  }

  return mergedObj
}

export const isPrimitive = (value) => {
  if (value === null || value === undefined) {
    return false
  }

  return !(typeof value === 'object' || typeof value === 'function')
}

export const includesString = (obj, searchString) => {
  // Base case: If the object is a string, check if it contains the search string
  if (typeof obj === 'string') return obj.toLowerCase().includes(searchString)

  // If the object is an array, iterate over its elements and search recursively
  if (Array.isArray(obj)) return obj.some((item) => includesString(item, searchString))

  // If the object is a regular object, iterate over its properties and search recursively
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (includesString(obj[key], searchString)) {
        return true
      }
    }
  }

  // If the object is neither a string nor an array nor a regular object, return false
  return false
}

export const removeKeys = (obj, keysToRemove) => {
  return Object.keys(obj)
    .filter((key) => !keysToRemove.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key]
      return acc
    }, {})
}

export const repeatString = (count, string) => {
  let result = ''

  for (let i = 0; i < count; i++) {
    result += `${string} `
  }

  return result
}
