import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { Badge } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnnouncementsService, CacheKey } from '../../../data'
import { useApi, useCache } from '../../../hooks'
import { getClient } from '../../../util'
import { IconButton } from '../../button'
import { Tooltip } from '../../message'
import { AnnouncementsPopover } from '../announcementPopover/AnnouncementsPopover'

/**
 * Announcement Button, that can be used to open the announcements inside the AppBar
 *
 * Note: This Button only supports usage within ``AliceAppBar``. If you want to have a dialog or popover with all announcements,
 * please consider using ``Announcements`` component.
 */
export const AnnouncementsButton = () => {
  const { t } = useTranslation('componentLibrary')
  const { getCachedItem } = useCache(CacheStorage.localStorage)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [ onForceUpdate, forceUpdate ] = useReducer((x) => x + 1, 0)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ unseen, setUnseen ] = useState(0)

  const {
    isLoading: isLoadingAnnouncements,
    response: responseAnnouncements
  } = useApi(
    AnnouncementsService.getAllActive,
    {
      config: { recipient: getClient() },
      initialResponse: [],
      responseTransformer: (response) => response.announcements
    }
  )

  useEffect(() => {
    if (!responseAnnouncements?.length) {
      setUnseen(0)
      return
    }

    const cachedSeenAnnouncements = getCachedItem(CacheKey.seenAnnouncements) || {}

    let seenAnnouncements = responseAnnouncements?.length

    responseAnnouncements.forEach((announcement) => {
      if (cachedSeenAnnouncements[announcement.id]) seenAnnouncements--
    })

    setUnseen(seenAnnouncements)
  }, [ onForceUpdate, responseAnnouncements, getCachedItem ])

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <>
      <Tooltip title={t('announcements.tooltip')} arrow>
        <IconButton onClick={handleOpen}>
          <Badge badgeContent={unseen} variant="dot" overlap="circular" color="primary">
            <NotificationsNoneOutlinedIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <AnnouncementsPopover
        announcements={responseAnnouncements}
        type={isMobile ? 'dialog' : 'popover'}
        isOpen={isOpen}
        isLoading={isLoadingAnnouncements}
        onClose={handleClose}
        onUpdate={forceUpdate}
      />
    </>
  )
}
