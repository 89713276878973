import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { AppLogo, NewTable } from "@alice/component_library";
import SearchComponent from "../PageHeader/Filters/SearchComponent";
import { EntitlementsContainer } from "./styledComponents";
import TableRowsLoader from "../Skeleton/TableRowsLoader";

const Entitlements = ({ entitlements, entitlementLoading }) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const { appData = {}, loading = false } = useSelector(
    (state) => state.newRequestSlice.applicationDetails
  );
  const [search, setSearch] = useState("");
  const handleSearch = (key) => setSearch(key);
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.setPageIndex(0);
      tableRef.current.setSearch(search);
    }
  }, [search]);
  return (
    <EntitlementsContainer>
      <Typography noWrap variant="subtitle2" className="subtitle-header">
        {t("entitlements")}
      </Typography>
      <SearchComponent
        label={t("searchbar_label_entitlement")}
        handleSearch={handleSearch}
        searchKey={search}
      />
      {entitlementLoading || loading ? (
        <TableRowsLoader
          dataGrid={true}
          showTableHeader
          rowsNum={2}
          columnCount={3}
          sx={{
            borderRadius: "4px",
            marginTop: "16px",
            background: "rgb(224, 224, 224)",
            "& .MuiTable-root": {
              border: "1px solid rgb(224, 224, 224)",
              "& .MuiTableHead-root": {
                background: "#F4F4F4",
              },
            },
          }}
        />
      ) : (
        <NewTable
          ref={tableRef}
          isLocal={true}
          localRows={entitlements}
          columns={["entitlementId", "description", "applicationName"]}
          getResponseData={(data) => data}
          rowIdKey="roles-entitlements"
          searchConfig={{
            apiFilters: ["entitlementId", "description", "applicationName"],
          }}
          noDataPlaceholder={t("role-doesnot-have-entitlements")}
          titleRenderer={(key) => {
            switch (key) {
              case "entitlementId":
                return t("entitlementHeaderData.entitlement-name");
              case "description":
                return t("entitlementHeaderData.description");
              case "applicationName":
                return t("entitlementHeaderData.application");
              default:
                return t("entitlementHeaderData.entitlement-name");
            }
          }}
          entryRenderer={(key, row) => {
            switch (key) {
              case "entitlementId":
                return row.entitlementId ?? "-";
              case "description":
                return row.description ?? "-";
              case "applicationName":
                return (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px 8px",
                      alignItems: "center",
                      "& >.MuiBox-root:has([data-testid=AppLogo]": {
                        height: "40px",
                      },
                    }}
                  >
                    <AppLogo
                      size="small"
                      logo={appData[row.applicationId]?.logoBase64}
                      sx={[{ height: "40px" }]}
                    />
                    {row.applicationName}
                  </Box>
                );
              default:
                return row.entitlementId ?? "-";
            }
          }}
        />
      )}
    </EntitlementsContainer>
  );
};

export default Entitlements;
