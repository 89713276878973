import React, { memo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { NewTable } from "@alice/component_library";
import { CLService } from "@src/services/CLServices";
import { getTitle, dataRenderer } from "./Newtableutils";
import { NewtableContainer } from "../../styledComponents";

const addKeyBasedOnValue = (object, key, value) => {
  // Check if the condition key exists and its value matches the condition value
  if (value.length > 0) {
    // Add the new key to the object
    object[key] = value;
  }
};

const RoleReviewTableLayout = ({
  searchValue,
  isAdminVisible,
  onBadgeClick,
  filterValue,
  userId,
  classification,
  isUserHasAdminAccess,
}) => {
  const stateFilter = {};
  if (isUserHasAdminAccess) {
    addKeyBasedOnValue(
      stateFilter,
      "reviewers.id",
      `${isAdminVisible ? "" : `${userId}`}`
    );
  }

  addKeyBasedOnValue(
    stateFilter,
    "state",
    filterValue === "all" ? "" : filterValue
  );

  addKeyBasedOnValue(stateFilter, "dataClassification", classification);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const [filter, setFilter] = useState(stateFilter);

  const query = searchValue?.length ? `'*${searchValue.trim()}*'` : "";

  const statusByOverdue = t("role-review-by-status.overdue");
  const statusByDone = t("role-review-by-status.done");

  const handleRowClick = (row) => {
    navigate(`/access/accessReview/${row?.id}`);
  };

  const handleRowClickHref = (id) => {
    return `/access/accessReview/${id}`;
  };

  const onBageClickhandler = (data) => {
    onBadgeClick(data);
  };

  useEffect(() => {
    tableRef?.current?.reload();
  }, [tableRef?.current?.filters]);

  useEffect(() => {
    const intialFilter = {};
    if (isUserHasAdminAccess) {
      addKeyBasedOnValue(
        intialFilter,
        "reviewers.id",
        isAdminVisible ? "" : userId
      );
    }

    addKeyBasedOnValue(
      intialFilter,
      "state",
      filterValue === "all" ? "" : filterValue
    );

    const searchParam = query.length ? `${query},roleName==${query})` : "";
    addKeyBasedOnValue(intialFilter, "(roleId", searchParam);

    addKeyBasedOnValue(intialFilter, "dataClassification", classification);

    if (tableRef.current) {
      tableRef.current.setPageIndex(0);
      tableRef.current.setFilters(intialFilter);
    }
    setFilter(intialFilter);
  }, [userId, isAdminVisible, classification, filterValue, query]);

  const getResponseData = (data) => {
    data.reviews?.forEach((item) => {
      // const id = item?.workflowId;
      const state = item?.state;
      let overdueCount = 0;
      if (state === "TIMEOUT") {
        overdueCount = item?.totalAssignments - item?.processedAssignments;
      }
      item.overdueCount = overdueCount;
    });
    return data.reviews;
  };

  const searchConfig = {
    apiFilters: [], // Custom API filter if endpoint does not support `search`
  };

  return (
    <NewtableContainer>
      <NewTable
        ref={tableRef}
        columns={[
          "roleId",
          "roleName",
          "dataClassification",
          "startTime",
          "reviewers.id",
          "processedAssignments",
        ]}
        entryRenderer={(key, row) =>
          dataRenderer(
            key,
            row,
            handleRowClick,
            statusByOverdue,
            statusByDone,
            onBageClickhandler
          )
        }
        selectablePageSizes={[12, 15, 20]}
        getResponseData={(data) => getResponseData(data)}
        titleRenderer={(key) => getTitle(key, filterValue)}
        apiFunction={(config) => {
          config.filters = {
            ...{
              ...config.filters,
              "(roleId": undefined,
              "reviewers.id": undefined,
              dataClassification: undefined,
            },
            ...filter,
          };

          return CLService.getAccessReviewbyReviewId({
            ...config,
          });
        }}
        noDataPlaceholder={
          <Typography>{t("no-role-reviews-available")}</Typography>
        }
        preselectedSort="-startTime"
        interaction="NAVIGATE"
        preselectedSearch={query}
        rowIdKey="workflowId"
        responseDataItemsKey="reviews"
        searchConfig={searchConfig}
        onGetHref={(id) => handleRowClickHref(id)}
        tableSx={{
          "& .tableEntry:last-child ": {
            display: "block !important",
          },
        }}
      />
    </NewtableContainer>
  );
};

const NewTableRenderer = (prevProps, nextProps) => {
  return (
    nextProps.searchValue === prevProps.searchValue &&
    nextProps.isAdminVisible === prevProps.isAdminVisible &&
    nextProps.filterValue === prevProps.filterValue &&
    nextProps.classification === prevProps.classification &&
    nextProps.userId === prevProps.userId
  );
};

export default memo(RoleReviewTableLayout, NewTableRenderer);
