import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterClassiFication from "../Filter/FilterClassification";
import FilterStatus from "../Filter/FilterStatus";
import GridLayout from "./GridLayout";
import { FIlterContainer, FilterStatusContainer } from "../../styledComponents";

import SortComponent from "@components/PageHeader/Filters/Sort";
import RecipientDialogue from "@components/workflowDialogue/RecipientDialouge";
import {
  Button,
  CacheStorage,
  useCache,
  usePermission,
  useUser,
} from "@alice/component_library";
import RoleReviewTableLayout from "./NewTableLayout";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/material";
import { isSuperAdmin } from "@utils";
import { hasData } from "@utils/Validator";

const RoleViewComponent = ({
  layout,
  isAdminVisible = false,
  searchValue,
  cacheKey,
  isMobile,
  isTablet,
  isDesktop,
}) => {
  const { getCachedItem, setCachedItem, removeCachedItem } = useCache(
    CacheStorage.sessionStorage
  );
  const cacheValue = getCachedItem(cacheKey);

  const defaultValues = {
    filter: cacheValue?.filters !== undefined ? cacheValue?.filters : "OPEN",
    classification:
      cacheValue?.classFilters !== undefined ? cacheValue?.classFilters : "All",
    sortKey:
      cacheValue?.tableSort !== undefined
        ? cacheValue?.tableSort
        : "-startTime",
  };

  const [filter, setFilter] = useState(defaultValues?.filter);
  const [classification, setClassification] = useState(
    defaultValues?.classification
  );
  const [sortKey, setSortkey] = useState(defaultValues?.sortKey);

  const resetToDefault = () => {
    setFilter("OPEN");
    setClassification("All");
    setSortkey("-startTime");
  };

  const [filterTab, setFilterTab] = useState(false);

  const windowSize = {
    isMobile,
    isTablet,
    isDesktop,
  };

  const [reviewProp, setReviewProp] = useState({ isOpen: false, data: [] });
  const { t } = useTranslation();

  const reviewDialogue = (data) => {
    setReviewProp((prevProps) => ({
      ...prevProps,
      isOpen: !prevProps.isOpen,
      data: data,
    }));
  };

  const { user: userUtils } = useUser();
  const { adminRoles: adminRoleSet } = usePermission();
  const adminRoles = adminRoleSet ? [...adminRoleSet] : [];

  const { id: userId } = userUtils;

  const handleSortKey = (e) => {
    setCachedItem(cacheKey, { ...cacheValue, tableSort: e });
    setSortkey(e);
  };

  const filterStatus = (fs) => {
    setCachedItem(cacheKey, { ...cacheValue, filters: fs });
  };

  const classificationFilter = (cf) => {
    setCachedItem(cacheKey, { ...cacheValue, classFilters: cf });
  };

  window.addEventListener("beforeunload", () => {
    removeCachedItem(cacheKey);
  });

  const loggedInUserAdminRoles = adminRoles;
  const isUserHaveAdminAccess =
    hasData(loggedInUserAdminRoles) &&
    isSuperAdmin(loggedInUserAdminRoles, "accessReviewAdminMode");

  return (
    <>
      <RecipientDialogue
        userList={reviewProp.data}
        isOpen={reviewProp.isOpen}
        toggleDialogue={(data) => {
          reviewDialogue([]);
        }}
        t={t}
      />
      {(isMobile || isTablet) && filterTab === false ? (
        <Box>
          <Button
            children={
              <>
                <FilterAltOutlinedIcon />
                {t("filter-by")}
              </>
            }
            onClick={() => setFilterTab(true)}
            variant="secondary"
            sx={{ justifyContent: "space-between" }}
          />
        </Box>
      ) : null}
      {isDesktop || filterTab === true ? (
        <FIlterContainer>
          <FilterStatusContainer>
            <FilterStatus
              filter={filter}
              setFilter={setFilter}
              filterStatus={filterStatus}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
            />
            <FilterClassiFication
              filter={classification}
              setFilter={setClassification}
              classificationFilter={classificationFilter}
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
            />
          </FilterStatusContainer>
          {layout !== "table" ? (
            <SortComponent
              sortOptions={[
                { value: "roleId", label: t("role-id-a-z") },
                { value: "-roleId", label: t("role-id-z-a") },
                { value: "roleName", label: t("role-name-a-z") },
                { value: "-roleName", label: t("role-name-z-a") },
                {
                  value: "dataClassification",
                  label: t("classification-ascending"),
                },
                {
                  value: "-dataClassification",
                  label: t("classification-descending"),
                },
                { value: "startTime", label: t("deadline-ascending") },
                { value: "-startTime", label: t("deadline-descending") },
              ]}
              label={t("sort-by")}
              handleSortKey={handleSortKey}
              sortKey={sortKey}
              windowSize={windowSize}
            />
          ) : (
            <></>
          )}
          {isMobile || isTablet ? (
            <Box>
              <Button
                children={
                  <>
                    <CloseOutlinedIcon />
                    {t("close-filters")}
                  </>
                }
                onClick={() => {
                  setFilterTab(false);
                  resetToDefault();
                }}
                variant="secondary"
                sx={{ justifyContent: "space-between" }}
              />
            </Box>
          ) : null}
        </FIlterContainer>
      ) : null}

      <>
        {layout === "table" ? (
          <RoleReviewTableLayout
            searchValue={searchValue}
            isAdminVisible={isAdminVisible}
            userId={userId}
            classification={classification === "All" ? "" : classification}
            onBadgeClick={(data) => reviewDialogue(data)}
            filterValue={filter}
            handleSorting={(value) => {
              setCachedItem(cacheKey, { ...cacheValue, tableSort: value });
              setSortkey(value);
            }}
            sortValue={sortKey}
            roleOwnerRoleIds={[]}
            isUserHasAdminAccess={isUserHaveAdminAccess}
          />
        ) : (
          <GridLayout
            searchValue={searchValue}
            isAdminVisible={isAdminVisible}
            userId={userId}
            classification={classification === "All" ? "" : classification}
            onBadgeClick={(data) => reviewDialogue(data)}
            filterValue={filter}
            handleSorting={(value) => {
              setCachedItem(cacheKey, { ...cacheValue, tableSort: value });
              setSortkey(value);
            }}
            sortValue={sortKey}
            isMobile={isMobile}
            roleOwnerRoleIds={[]}
            isUserHasAdminAccess={isUserHaveAdminAccess}
          />
        )}
      </>
    </>
  );
};

export default RoleViewComponent;
