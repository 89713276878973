import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReviewCardContainer } from "./RCardStyle";
import PropTypes from "prop-types";

const ReviewCard = ({ t, data }) => {
  return (
    <>
      <ReviewCardContainer>
        <div className="Role-review-header">
          <div className="id">
            <Typography sx={{ fontSize: "22px" }} noWrap>
              {data?.id}
            </Typography>
            <span>
              <Typography sx={{ fontSize: "14px", fontWeight: 700 }} noWrap>
                {data?.name}
              </Typography>
            </span>
          </div>
          <div className="data-classification-chip">
            {data?.dataClassification}
          </div>
        </div>
        <div className="Role-review-container">
          {data.content.map((content, i) => {
            return (
              <React.Fragment key={i}>
                <Grid container sx={{ alignItems: "center", rowGap: "10px" }}>
                  <Grid item xs={12} sm={4}>
                    <div className="deadline-title">
                      {content.icon}
                      {content.title}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {content?.chip}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </div>
      </ReviewCardContainer>
    </>
  );
};

ReviewCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReviewCard;
