import { Divider, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ApplicationsService } from '../../../data'
import { useApi } from '../../../hooks'
import { timeout } from '../../../util'
import { GenericContextPopover } from '../../genericContextPopover'
import { Skeleton } from '../../skeleton'
import { AppContext } from './AppContext'

// For dev: timeoutInMS = 3000 -> wait 3 sec before the initial and other api calls are triggered
// For live: Set timeoutInMS to 0 (zero)!
const timeoutInMS = process.env.NODE_ENV === 'development' ? 3000 : 0

// LoadingSkeleton is shown as long the request is running to get the user data
const LoadingSkeleton = () => (
  <>
    <Stack direction="row" gap={2} sx={{ justifyContent: 'space-between' }}>
      <Skeleton variant="rectangular" width={128} height={72} />

      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '2px',
          width: '100px',
          height: '24px'
        }}
      />
    </Stack>

    <Divider />

    <Stack gap={2}>
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '2px',
          width: '128px',
          height: '24px'
        }}
      />

      <Stack>
        <Skeleton width="290px" />
        <Skeleton width="290px" />
        <Skeleton width="290px" />
      </Stack>

      <Divider />

      <Stack>
        <Skeleton width={40} />
        <Skeleton />
      </Stack>

      <Stack>
        <Skeleton width={40} />
        <Skeleton />
      </Stack>

      <Stack>
        <Skeleton width={40} />
        <Skeleton />
      </Stack>
    </Stack>
  </>
)

export const AppContextPopover = ({
  id: newId,
  appId, // deprecated prop, will be removed soon
  placement,
  children,
  actions
}) => {
  const [ isTimeoutDone, setIsTimeoutDone ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  // This temporary variable is used as long as the deprecated prop is present in the component.
  const id = newId || appId

  const { response: responseApplication } = useApi(
    ApplicationsService.getById,
    {
      config: { id },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone
    }
  )

  const { response: responseSupportContacts } = useApi(
    ApplicationsService.getAllSupportContacts,
    {
      config: { id },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone,
      initialResponse: [],
      responseTransformer: (response) => response?.applicationSupportContacts || []
    }
  )

  useEffect(() => {
    const waitTimeout = async () => {
      if (timeoutInMS > 0) timeout(timeoutInMS)
      setIsTimeoutDone(true)
    }

    if (id && isOpen) waitTimeout()
  }, [ id, isOpen ])

  return (
    <GenericContextPopover
      context={
        responseApplication
          ? (
            <AppContext
              appData={responseApplication}
              supportData={responseSupportContacts}
              actions={actions}
            />
          )
          : <LoadingSkeleton />
      }
      placement={placement}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      {children}
    </GenericContextPopover>
  )
}

AppContextPopover.propTypes = {
  /** appId */
  id: PropTypes.string.isRequired,
  /**
   * appId
   *
   * @deprecated Do not use! Use `id` prop instead!
   */
  appId: PropTypes.string,
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ]),
  /** The component that should be hover-able */
  children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]).isRequired,
  /** action buttons object */
  actions: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.node, onClick: PropTypes.func }))
}
