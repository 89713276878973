import React from "react";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export const StyledDivContainer = styled("div")(({ theme }) => ({
  display: "block",
  "& > * + *": {
    marginLeft: theme.spacing(2),
  },
}));

export function CircularLoader(props) {
  const { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <React.Fragment>
      {props.inline ? (
        <StyledDivContainer>
          <CircularProgress variant="indeterminate" value={progress} />
        </StyledDivContainer>
      ) : (
        <StyledDivContainer>
          <CircularProgress variant="indeterminate" value={progress} />
          <br />
          <br />
          <span className="loader" style={{ color: "#333333" }}>
            {t("loading")}
          </span>
        </StyledDivContainer>
      )}
    </React.Fragment>
  );
}
