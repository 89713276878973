const urlConfig = {
  user: (id) => `/gems/users/${id}`,
  approveTask: (id) => `/gems/tasks/${id}/approve`,
  rejectTask: (id) => `/gems/tasks/${id}/reject`,
  roleEntitlements: (id) => `/gems/roles/${id}/entitlements`,
  application: (id) => `/gems/applications?filter=id==${id}`,
  taskById: (id) => `/gems/tasks?filter=id==${id}`,
  AdmintaskById: (id) => `/gems/alltasks?filter=id==${id}`,
  workflows: () => `/gems/workflows`,
  workflowsById: (id) => `/gems/workflows?filter=id==${id}`,
  organizations: () => `/gems/organizations`,
  manageAdminRoles: (id) => `/gems/users/${id}/manageadminroles`,
  supervisors: (id) => `/gems/supervisors/${id}/supervisedusers`,
  roles: (id) => `/gems/users/${id}/roles`,
  users: (search = "", offset = 0, limit = 20) =>
    search !== ""
      ? `/gems/users?filter=search=='*${search}*'&offset=${offset}&limit=${limit}`
      : `/gems/users?offset=${offset}&limit=${limit}`,
  myAdminRoles: (id) => `/gems/myadminroles`,
  manageMyAdminRoles: (id) => `gems/users/${id}/manageadminroles`,
  rolesCC: (id) => `/gems/users/${id}/roles?offset=0&limit=1`,
  bulkAssign: "/gems/roles/bulkimport/assign",
  bulkRemoval: "/gems/roles/bulkimport/unassign",
};

export default urlConfig;
