import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../hooks'
import { Done } from '@mui/icons-material'
import { changeUILanguage, LANGUAGES } from '../../../util'
import { CountryFlag } from '../../icon'

/**
 * Language Popover is used in the Appbar for the UserAvatarMenu.
 */
export const LanguagePopover = ({
  anchorEl,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right'
  },
  onChange = () => {},
  onClose
}) => {
  const { i18n, t } = useTranslation('componentLibrary')
  const { updateLanguage } = useLanguage()

  const availableLanguages = useRef([])

  useMemo(() => {
    const lang = [ ...LANGUAGES ]

    // empty object adds divider to  list
    lang.splice(2, 0, {})

    availableLanguages.current = lang
  }, [])

  const onItemSelect = (language) => {
    changeUILanguage(i18n, language)
    updateLanguage(language)
    onChange(language)
    onClose()
  }

  const isSelected = (lang) => i18n.language.includes(lang)

  return (
    <Menu
      disableScrollLock
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      MenuListProps={{
        style: {
          maxHeight: '80vh',
          width: '287px',
          padding: 0
        }
      }}
    >
      <Typography
        sx={{ padding: '14px 8px 14px 24px' }}
        variant="h5"
      >
        {t('general.language')}
      </Typography>

      <Divider flexItem />

      <Stack sx={{ padding: '26px 16px' }}>
        {availableLanguages.current.map((ele, i) => {
          if (i === 2) return <Divider key="divider" />

          return (
            <MenuItem
              key={ele.value}
              onClick={() => onItemSelect(ele.value)}
              selected={isSelected(ele.value)}
              sx={{
                gap: 1,
                padding: '12px 16px',
                '&.Mui-selected': {
                  borderRadius: '4px',
                  backgroundColor: 'blue.90',
                  color: 'primary.dark'
                }
              }}
            >
              <CountryFlag
                countryCode={ele.flag}
                sx={{
                  width: '30px',
                  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                }}
              />

              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{ele.label}</ListItemText>

              {isSelected(ele.value) && (
                <Box
                  sx={{
                    display: 'flex',
                    color: 'inherit',
                    fontSize: '18px'
                  }}
                >
                  <Done color="inherit" fontSize="inherit" />
                </Box>
              )}
            </MenuItem>
          )
        })}
      </Stack>
    </Menu>
  )
}

LanguagePopover.propTypes = {
  /** The anchor element of the dialog. If set, the dialog is open' */
  anchorEl: PropTypes.object,
  /** Describes the hook location on the anchor element */
  anchorOrigin: PropTypes.object,
  /** Describes the hook location on the dialog */
  transformOrigin: PropTypes.object,
  /**
   * Event on lang change
   *
   * @param language
   * @returns {void}
   */
  onChange: PropTypes.func,
  /**
   * Event on dialog close
   *
   * @returns {void}
   */
  onClose: PropTypes.func.isRequired
}
