import RightArrow from "@Icons/taskpage/right-arrow.svg";
import RoleKey from "@Icons/taskpage/rolekey.svg";
import RoleRequest from "@Icons/taskpage/rolerequest.svg";
import User from "@Icons/taskpage/user.svg";
import EntitleMentIcon from "@Icons/taskpage/AssignEntitelemnet.svg";
import AssignTechnicalUser from "@Icons/taskpage/AssignTechnicalUser.svg";
import AssignTechnicalUserLeft from "@Icons/taskpage/AssignTechnicalUserLeft.svg";
import AssignTechnicalUserRight from "@Icons/taskpage/AssignTechnicalRight.svg";
import OrgAdminPermission from "@Icons/taskpage/OrgAdminPermission.svg";
import AprooveRoleDeprovisioning from "@Icons/taskpage/AprooveRoleDeprovisioning.svg";
import RemoveIcon from "@Icons/taskpage/RemoveIcon.svg";
import UserCreation from "@Icons/taskpage/AprooveUserCreation.svg";
import UserCreationLeft from "@Icons/taskpage/AprooveUserLeft.svg";
import UserCreationRight from "@Icons/taskpage/AprooveUserRight.svg";
import UserOrganization from "@Icons/taskpage/AprooveUserToOrg.svg";
import UserOrganizationRight from "@Icons/taskpage/UserToOrgRight.svg";
import EntitlementToRole from "@Icons/taskpage/EntitlementToRole.svg";
import { useWidth } from "@src/utils/hooks";

const useSelectedTask = (taskInfo = {}) => {
  const { taskType, id, affectedData = {} } = taskInfo;
  const {
    user = {},
    role = {},
    homeOrg = {},
    org = {},
    entitlement = {},
  } = affectedData;
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm"].includes(widthPanel);
  let idwithTypeIcon = null;
  let LeftData = null;
  let RightData = null;
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      idwithTypeIcon = (
        <>
          <RoleRequest />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <RoleKey />
          <span> {role.id} </span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RightArrow />}
          <User /> <span>{`${user?.givenname} ${user?.surname}`}</span>
        </>
      );

      break;
    case "APPROVE_USER_CREATION":
      idwithTypeIcon = (
        <>
          <UserCreation />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <UserCreationLeft />
          <span>{`${user?.givenname} ${user?.surname}`}</span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RightArrow />}
          <UserCreationRight />
          <span>{user?.homeOrgId}</span>
        </>
      );
      break;
    case "APPROVE_TECHUSER_TO_APPLICATION":
      idwithTypeIcon = (
        <>
          <AssignTechnicalUser />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <AssignTechnicalUserLeft />
          <span>{user?.id}</span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {isMobile && <RightArrow />}
          <AssignTechnicalUserRight />
          <span>{homeOrg?.name}</span>
        </>
      );
      break;
    case "APPROVE_ORG_ADMIN_PERMISSION":
      idwithTypeIcon = (
        <>
          <OrgAdminPermission />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <OrgAdminPermission />
          <span>{org?.name}</span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RightArrow />}
          <User />
          <span>{`${user?.givenname} ${user?.surname}`}</span>
        </>
      );
      break;
    case "APPROVE_ROLE_DEPROVISIONING":
      idwithTypeIcon = (
        <>
          <AprooveRoleDeprovisioning />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <RoleKey />
          <span>{role?.id}</span>
          {isMobile && <RemoveIcon />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RemoveIcon />}
          <User />
          <span>{`${user?.givenname} ${user?.surname}`}</span>
        </>
      );
      break;
    case "APPROVE_USER_TO_ORGANIZATION":
      idwithTypeIcon = (
        <>
          <UserOrganization />
          {id}
        </>
      );
      LeftData = (
        <>
          <User />
          <span>{`${user?.givenname} ${user?.surname}`}</span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RightArrow />}
          <UserOrganizationRight />
          <span>{homeOrg?.name}</span>
        </>
      );
      break;
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      idwithTypeIcon = (
        <>
          <EntitleMentIcon />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <EntitlementToRole />
          <span>{entitlement?.entitlementId}</span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RightArrow />}
          <RoleKey />
          <span>{role?.id}</span>
        </>
      );
      break;
    default:
      idwithTypeIcon = (
        <>
          <RoleRequest />
          <span>{id}</span>
        </>
      );
      LeftData = (
        <>
          <RoleKey />
          <span> {role.id} </span>
          {isMobile && <RightArrow />}
        </>
      );
      RightData = (
        <>
          {!isMobile && <RightArrow />}
          <User /> <span>{`${user?.givenname} ${user?.surname}`}</span>
        </>
      );
      break;
  }
  return {
    idwithTypeIcon,
    LeftData,
    RightData,
  };
};

export default useSelectedTask;
