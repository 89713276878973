import { Box, CircularProgress } from '@mui/material'
import { StepIcon } from './StepIcon'

export const CircularProgressWithIcon = ({
  step,
  maxSteps,
  isMobile,
  activeStep,
  steps,
  isDone,
  isStepComplete
}) => {
  const progress = (step / maxSteps) * 100

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        size={48}
        thickness={3}
        sx={{ color: 'divider' }}
      />

      <CircularProgress
        variant="determinate"
        value={isDone ? 100 : progress}
        size={48}
        thickness={3}
        sx={{
          position: 'absolute',
          left: 0,
          color: 'primary.main',
          '.MuiCircularProgress-circle': { strokeLinecap: 'round' }
        }}
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StepIcon
          step={steps[activeStep]}
          index={step}
          isMobile={isMobile}
          activeStep={activeStep}
          steps={steps}
          isDone={isDone}
          isStepComplete={isStepComplete}
        />
      </Box>
    </Box>
  )
}
