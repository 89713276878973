import PropTypes from 'prop-types'
import { Badge, Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useOpenTasks } from '../../../hooks'
import { CheckCircleOutlined } from '@mui/icons-material'
import { Link } from '../../link'
import { IconWrapper } from '../../icon/iconWrapper/IconWrapper'

/**
 * A button that displays a badge if the user has open tasks.<br>
 * If clicked it takes you to "/access/tasks".
 */
export const OpenTasksButton = ({
  disableNotification = false,
  showNumber = false
}) => {
  const { t } = useTranslation('componentLibrary')

  const { openTasksCount } = useOpenTasks()

  return (
    <Tooltip
      data-testid="OpenTasksButton"
      title={t('header.navToOpenTasks')}
      arrow
    >
      <Link
        href="/access/tasks?status=pending"
        sx={{ borderRadius: 0 }}
      >
        {!!openTasksCount && !disableNotification && (
          <Badge
            variant={showNumber ? 'standard' : 'dot'}
            badgeContent={openTasksCount}
            overlap={showNumber ? 'rectangular' : 'circular'}
            color="primary"
            componentsProps={{
              badge: {
                sx: !showNumber && {
                  height: '12px',
                  width: '12px',
                  borderRadius: '50%'
                }
              }
            }}
          >
            <Box sx={{
              display: 'flex',
              color: 'common.white',
              fontSize: '24px'
            }}
            >
              <CheckCircleOutlined color="inherit" fontSize="inherit" />
            </Box>
          </Badge>
        )}

        {(openTasksCount === 0 || disableNotification) && (
          <IconWrapper
            color={'common.white'}
            size={24}
          >
            <CheckCircleOutlined color="inherit" fontSize="inherit" />
          </IconWrapper>
        )}
      </Link>
    </Tooltip>
  )
}

OpenTasksButton.propTypes = {
  /** Disables the notification bubble for open tasks */
  disableNotification: PropTypes.bool,
  /** Enables number for notification */
  showNumber: PropTypes.bool
}
