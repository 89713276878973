import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { SvgIcon } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTranslation } from "react-i18next";

const StyledLinearProgress = styled(LinearProgress)({
  "&.root": {
    background: "#0078D666",
  },
  "&.bar": {
    backgroundColor: "#0078D6",
  },

  "&.errorRoot": {
    marginTop: "2px",
    background: "rgba(217, 33, 33, 0.3)",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#0078D6 !important",
    },
  },
});

const PercentageTypography = styled(Typography)({
  color: " rgba(0, 0, 0, 0.87)",
  fontFamily: "MB Corpo S Text",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "20px",
  "& .value": {
    fontWeight: "400",
    marginLeft: "4px",
  },
});

const LinearProgressBarWithValue = ({
  value,
  total,
  overdueCount,
  Filtervalue,
  alignText = "left",
  sx,
}) => {
  const { t } = useTranslation();
  const color =
    Filtervalue === "TIMEOUT"
      ? "rgba(217, 33, 33, 0.3)"
      : "rgba(0, 120, 214, 1)";
  const percentage = Math.ceil(((value - overdueCount) / total) * 100);
  const typographySx = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign:
      alignText === "center"
        ? "center"
        : alignText === "right"
        ? "right"
        : "left",
    ...(sx || {}), // Custom sx properties
  };

  return (
    <>
      <PercentageTypography
        variant="body2"
        color="textSecondary"
        sx={typographySx}
      >
        <>
          {percentage === 100 ||
          (value == 0 && total == 0) ||
          Filtervalue === "TIMEOUT" ? (
            <SvgIcon
              component={
                Filtervalue === "TIMEOUT" ? RemoveCircleIcon : CheckCircleIcon
              }
              fontSize="15px"
              sx={{ fontSize: "15px", color: color }}
            />
          ) : (
            ""
          )}
          {isNaN(percentage) ? (
            value == 0 && total == 0 ? (
              "100% "
            ) : null
          ) : (
            <span>{`${percentage}% `}</span>
          )}
          <span className="value">{` (${value - overdueCount}/${total})`}</span>
        </>
      </PercentageTypography>
      <StyledLinearProgress
        classes={{
          root: Filtervalue === "TIMEOUT" ? "errorRoot" : "root",
          bar: Filtervalue === "TIMEOUT" ? "errorbar" : "bar",
        }}
        variant="determinate"
        value={isNaN(percentage) ? 100 : percentage}
      />
    </>
  );
};

export default LinearProgressBarWithValue;
