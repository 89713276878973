import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Card } from "@alice/component_library/";
import "./contactsupport.css";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PrimaryButton } from "@components/button";

function ExternalUserSupport({ checking }) {
  const { t } = useTranslation();

  const handlerSupportLink = () => {
    const url =
      "https://digitalservices.mercedes-benz.com/b2b?id=sc_cat_item&sys_id=715645d31b3c34103f96eb9f7b4bcb38&sysparm_variables=ewoic3lzcGFybV9jYXRlZ29yeSI6IjkzZDMwNjQ4ZGIwOWEwMTA1NzVmNTBkM2YzOTYxOTBmIiwKInNlcnZpY2Vfb2ZmZXJpbmciOiIwNzM5NWRiNDFiYTMwOTUwZjdjYjYyNDA2YjRiY2I0YiIsCiJvcHRpb25hbF90ZW1wbGF0ZSI6InRydWUiLAoiY2hvb3NlX2FwcF90ZW1wbGF0ZSI6IiIsCiJzaG9ydF9kZXNjcmlwdGlvbiI6IiIsCiJkZXNjcmlwdGlvbiI6IiIsCiJzZWxlY3RfdGVtcGxhdGUiOiJhNTQ1OWQ4YjFiZTdjMTEwM2M2M2ZjNGU4YjRiY2JhZSIKfQ%3D%3D";
    window.open(url, "_blank");
  };

  const getButtonLabel = (id, label) => (
    <>
      <span className="button-Text">{t(label)}</span>
      <ChevronRightIcon />
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <p className={checking ? "central-support" : "central-supports"}>
          {t("contact_page.alice-central-support")}
        </p>
        <p className="central-Subsupport">
          {t("contact_page.alice-central-title")}
        </p>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card
            showShadow
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <p className="padding-20 flexDirection">
              <span className="circle">
                <WarningAmberOutlinedIcon fontSize="large" />
              </span>

              <span className="contact-centerl mt-16">{t("support")}</span>
              <span className="contact-centerl-2 mt-10">
                {t("contact_page.support-title")}
              </span>
              <span className="mt-43">
                <PrimaryButton
                  label={getButtonLabel(
                    "arrowIcon",
                    "contact_page.open-support-ticket"
                  )}
                  onButtonClick={() => handlerSupportLink()}
                />
              </span>
            </p>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ExternalUserSupport;
