import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import "./contactsupport.css";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { PrimaryButton } from "@components/button";

const GuideDocumentation = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Grid item xs={12} sx={{ marginTop: "58px" }}>
        <Typography variant="h1" sx={{ marginBottom: "24px" }}>
          {t("contact_page.alice-guide-doc")}
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: "40px", fontSize: "20px" }}
        >
          {t("contact_page.alice-guide-title")}
        </Typography>
        <Grid lg={4}>
          <Box
            sx={{
              p: "16px",
              border: "1px solid #E6E6E6",
              borderRadius: "4px",
              "& .help-outline-icon": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgb(242, 242, 242)",
                height: "64px",
                width: "64px",
                borderRadius: "50%",
                "& svg": { fontSize: "32px" },
              },
            }}
          >
            <div className="help-outline-icon">
              <HelpOutlineOutlinedIcon />
            </div>
            <Typography variant="h6" sx={{ margin: "16px 0 8px 0" }}>
              {t("contact_page.alice-wiki")}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "50px" }}>
              {t("contact_page.alice-wiki-title")}
            </Typography>
            <Link
              to={`https://pages.git.i.mercedes-benz.com/alice/welcome/`}
              target="_blank"
            >
              <PrimaryButton
                label={
                  <div className="button-icon">
                    <span className="button-text">
                      {t("contact_page.browse-wiki")}
                    </span>
                    <ChevronRightIcon />
                  </div>
                }
                style={{
                  "& .button-icon": {
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  },
                }}
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuideDocumentation;
