import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/material";
import "../App.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Layout } from "@alice/component_library";

const MainContainer = styled("div")({
  flexGrow: 1,
});

const SubContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#444444",
  color: theme.palette.common.white,
  fontSize: 14,
}));

const TableDataCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
}));

const rows = [
  {
    name: "TEX",
    storagePeriod: "7 days",
    description:
      "This is currently used to fetch and pass the token for OIDC connection",
  },
  // {name:"JSESSIONID",storagePeriod:"30 days",description:"This cookie is used on the front-end and the Admin Consoleto identify a session. "}
];

const Cookies = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <MainContainer>
        <SubContainer item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} className="mh-95vh">
            <Typography>
              <p className="cookies-pageHeader">{i18n.t("cookies")}</p>
              <p className="cookies-header">{t("cookies_privacy")}</p>
              <h3 className="cookies-title">
                {t("statement_on_the_use_of_cookies")}
              </h3>
              <Typography gutterBottom className="cookies-content">
                {t("cookies_header")}
              </Typography>
              <h3 className="cookies-title">{t("function_useof_cookies")}</h3>
              <Typography gutterBottom className="cookies-content">
                <p>{t("cookies_functions1")}</p>
                <p> {t("cookies-functions2")}</p>
                <Typography className="cookies-content">
                  <ol type="I">
                    <li>{t("functional_list1")} </li>
                    <li>{t("functional_list2")} </li>
                    <li>{t("functional_list3")}</li>
                  </ol>
                  <p>{t("cookies_functions3")}</p>
                </Typography>
                <h3 className="cookies-title">{t("list_of_cookies")}</h3>
                <p className="cookies-content">
                  {t("use_the_following_cookie_on_our_website")}
                </p>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{t("cookie")}</StyledTableCell>
                        <StyledTableCell align="left">
                          {t("description")}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow>
                          <TableDataCell component="th" scope="row">
                            {row.name}
                          </TableDataCell>
                          <TableDataCell align="left">
                            {row.description}
                          </TableDataCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <h3 className="cookies-title">{t("additiona_info")}</h3>
              <p className="cookies-content">
                {t("more_info_refer_privacy_statement")}
              </p>
            </Typography>
          </Grid>
        </SubContainer>
      </MainContainer>
    </Layout>
  );
};

export default Cookies;
