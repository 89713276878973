import React, { useState } from "react";
import { components } from "react-select";
import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import axiosInstance from "@src/services/axiosInstance";

import { useTranslation } from "react-i18next";

import { IconButton } from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/system";

const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  letterSpacing: "0px",
  lineHeight: "1.4375em",
  fontWeight: "400",
  fontSize: "1rem",
  padding: "0px",
  display: "block",
  transformOrigin: "left top",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "calc(133% - 24px)",
  position: "absolute",
  left: "0px",
  top: "0px",
  transform: "translate(12px, 7px) scale(0.75)",
  transition:
    "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
  zIndex: "1",
  pointerEvents: "auto",
  userSelect: "none",
}));

const MuiFormControl = styled(FormControl)({
  display: "inline-flex",
  flexDirection: "column",
  position: "relative",
  minWidth: "0px",
  padding: "0px",
  margin: "16px 0px 8px",
  border: "0px",
  verticalAlign: "top",
  width: "100%",
  backgroundColor: "#eeeeee",
});

const MuiFormControlDrop = styled("div")({
  display: "inline-flex",
  marginTop: "15px",
  flexDirection: "column",
  position: "relative",
  minWidth: "0px",
  padding: "0px",
  border: "0px",
  verticalAlign: "top",
  width: "100%",
  backgroundColor: "#eeeeee",
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  paddingBottom: "2px",
  height: "41px",
});

const CustomAsyncSelect = styled(Select)({
  "input[type='text']": {
    cursor: "none",
  },
});

const StyledArrowDropDown = styled(ArrowDropDown)(({ menuIsOpen }) => ({
  color: "rgba(0, 0, 0, 0.54)",
  transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)", // Rotate based on menuIsOpen state
}));

const CustomAsyncPaginate = withAsyncPaginate(CustomAsyncSelect);

const AsyncAutocomplete = ({ userId, actionHandler, isMenuOpen }) => {
  const [isClearHovered, setIsClearHovered] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const onMenuOpen = () => {
    isMenuOpen(true);
  };

  const onMenuClose = () => {
    isMenuOpen(false);
  };

  const customStyles = {
    // Custom styles for the dropdown indicator
    dropdownIndicator: (provided, state) => ({
      ...provided,
      // Add more custom styles here if needed
    }),
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#eeeeee !important",
      border: "none",
      boxShadow: "none",
      height: "24px",
      fontFamily: "MB Corpo S Text",
      letterSpacing: "0px",
      lineHeight: "1.4375em",
      fontWeight: "400",
      fontSize: "1rem",
      "&:hover": {
        border: "none",
        backgroundColor: "#eeeeee !important",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderTop: "2px solid #0077d4",
      marginTop: "2px",
      zIndex: "999",
      borderRadius: "0px",
      top: "96%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      position: "relative",
      top: "-7px",
    }),
    option: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: state.isSelected
        ? "rgba(0, 120, 214, 0.08)"
        : "rgb(255, 255, 255)",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "rgba(0, 120, 214, 0.08)"
          : "rgba(236,236,236,255)",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "MB Corpo S Text",
      letterSpacing: "0px",
      lineHeight: "1.4375em",
      fontWeight: "400",
      fontSize: "1rem",
    }),
    menuList: (provided) => ({
      ...provided,
      fontFamily: "MB Corpo S Text",
      letterSpacing: "0px",
      lineHeight: "1.4375em",
      fontWeight: "400",
      fontSize: "1rem",
    }),
  };

  const handleClearHover = () => {
    setIsClearHovered(true);
  };

  const handleClearLeave = () => {
    setIsClearHovered(false);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <StyledArrowDropDown menuIsOpen={props.selectProps.menuIsOpen} />{" "}
        {/* Use Material-UI styled icon */}
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <div
          onMouseEnter={handleClearHover}
          onMouseLeave={handleClearLeave}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <IconButton
            onMouseEnter={handleClearHover}
            onMouseLeave={handleClearLeave}
            size="small"
            style={{ visibility: isClearHovered ? "visible" : "hidden" }}
          >
            <Clear />
          </IconButton>
        </div>
      </components.ClearIndicator>
    );
  };

  const CustomComponents = {
    DropdownIndicator: DropdownIndicator,
    ClearIndicator: ClearIndicator,
  };

  const handleSelectChange = async (newValue, actionMeta) => {
    if (actionMeta.action === "clear") {
      // If the select is cleared, set the default value and input value
      actionHandler("");
      setSelectedOption({
        label: t("all-applications"),
        value: "All Applications",
      });
      return;
    }
    actionHandler(newValue);
    setSelectedOption(newValue);
  };

  const loadedOptions = async (search, loadedOptions, { page }) => {
    const searchParam =
      search.length && search !== "All Applications" ? `${search.trim()}*` : "";
    const urlSearchParamsForApplication = new URLSearchParams();
    const filtersForApplication = [];
    urlSearchParamsForApplication.set("limit", 10);
    urlSearchParamsForApplication.set("offset", 10 * (page - 1));
    urlSearchParamsForApplication.set("sort", "name");

    if (search.trim() !== "" && search !== "All Applications") {
      filtersForApplication.push(
        `(id==${searchParam.replace(/\s/g, "*")},name==${searchParam.replace(
          /\s/g,
          "*"
        )})`
      );
      if (filtersForApplication.length) {
        if (filtersForApplication.length > 1) {
          urlSearchParamsForApplication.set(
            "filter",
            filtersForApplication.join(";")
          );
        } else {
          urlSearchParamsForApplication.set(
            "filter",
            filtersForApplication.join("")
          );
        }
      }
    }

    const response = await axiosInstance.get(
      `/gems/users/${userId}/availableapplications?${urlSearchParamsForApplication.toString()}`
    );

    const data = response?.data.applications;
    const totalCount = response?.data?.rels.totalCount;

    let applData = [];
    if (data.length > 0) {
      applData = data.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
    const newOptions = applData;
    return {
      options: newOptions,
      hasMore: 10 * (page - 1) < totalCount,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className="MuiAutocomplete-root MuiAutocomplete-hasClearIcon MuiAutocomplete-hasPopupIcon mt-4 subTextField">
      <MuiFormControl fullWidth={true}>
        <InputLabelStyled
          variant="filled"
          disableAnimation={true}
          color="primary"
        >
          {t("applications")}
        </InputLabelStyled>
        <MuiFormControlDrop className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot">
          <CustomAsyncPaginate
            value={selectedOption}
            defaultInputValue="All Applications"
            placeholder={t("all-applications")}
            loadOptions={loadedOptions}
            additional={{
              page: 1,
            }}
            isSearchable
            isClearable
            components={CustomComponents}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onChange={handleSelectChange}
            styles={customStyles}
            loadingMessage={() => t("loading")}
            noOptionsMessage={() => t("no-options")}
          />
        </MuiFormControlDrop>
      </MuiFormControl>
    </div>
  );
};

export default AsyncAutocomplete;
