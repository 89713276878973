/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloseButton } from "@alice/component_library";

// import { makeStyles } from "@material-ui/core/styles";
import { SecondaryButton } from "@components/button";
import EntitlementsTable from "@components/EntitlementsTable";
import { RemoveUsersEntitlementData } from "@actions/roleActions";
import { getUUID } from "@utils";

import "@components/roleDetails.css";
import "@src/Icon.css";
import "@src/App.css";
import "./profile.css";

const CustomTypographyDetail = styled(Typography)({
  fontSize: "20px",
  fontWeight: "500",
  // lineHeight: "46px",
  paddingBottom: "36px !important",
});

const CustomTypographyPaperClass = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: "bolder",
  lineHeight: "28px",
});

const CustomTypographyPaperClassText = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: 400,
  lineHeight: "27px",
});

const CustomTypographyPaperClassTextSpan = styled("span")({
  fontSize: "16px !important",
  fontWeight: 400,
  lineHeight: "27px",
});

const CustomTypographyPaperClassBlue = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: 400,
  lineHeight: "28px",
  color: "#007A93",
  wordWrap: "break-word",
  cursor: "pointer",
});

const CustomTypographyPaperClassBlueSpan = styled("span")({
  fontSize: "16px !important",
  fontWeight: 400,
  lineHeight: "28px",
  color: "#007A93",
  wordWrap: "break-word",
  cursor: "pointer",
});

export default function ApplicationTable(params) {
  const { setDetailsChange, width, supportContact, applications, isClose } =
    params;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sendMail = (mailId) => {
    window.location.assign(`mailto: ${mailId}`);
  };

  const onClickUrl = (url = "") => {
    if (url.length > 2) window.open(url, "_blank");
  };

  useEffect(() => {
    return function cleanup() {
      dispatch(RemoveUsersEntitlementData([]));
    };
  }, []);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} key={getUUID()}>
        {isClose ? (
          <CloseButton
            onClick={() => setDetailsChange(params.id)}
            sx={{ float: "right" }}
          />
        ) : (
          ""
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={width < 415 ? "p-16" : "p-26"}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} className="flex">
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <CustomTypographyDetail>
                {t("application-details")}
              </CustomTypographyDetail>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12} className="">
            <Grid item xs={5} sm={4} md={3} lg={3} className="mb-16 ">
              <CustomTypographyPaperClass>
                {t("name")}
              </CustomTypographyPaperClass>
            </Grid>
            <Grid item xs={7} sm={8} md={9} lg={9}>
              <CustomTypographyPaperClassText>
                {applications && applications.name}
              </CustomTypographyPaperClassText>
            </Grid>
            <Grid item xs={5} sm={4} md={3} lg={3} className="mb-16 ">
              <CustomTypographyPaperClass>{t("id")}</CustomTypographyPaperClass>
            </Grid>
            <Grid item xs={7} sm={8} md={9} lg={9}>
              <CustomTypographyPaperClassText>
                {applications && applications.id}
              </CustomTypographyPaperClassText>
            </Grid>
            <Grid item xs={5} sm={4} md={3} lg={3} className="mb-16 ">
              <CustomTypographyPaperClass>
                {t("description")}
              </CustomTypographyPaperClass>
            </Grid>
            <Grid item xs={7} sm={8} md={9} lg={9}>
              <CustomTypographyPaperClassText variant="body2">
                {applications && applications.description
                  ? applications.description
                  : "-"}
              </CustomTypographyPaperClassText>
            </Grid>
            <Grid item xs={5} sm={4} md={3} lg={3} className="mb-16 ">
              <CustomTypographyPaperClass>
                {t("url")}
              </CustomTypographyPaperClass>
            </Grid>
            <Grid item xs={7} sm={8} md={9} lg={9} className="role_url">
              <CustomTypographyPaperClassBlue
                onClick={() => onClickUrl(applications.url)}
              >
                {applications && applications.url ? applications.url : "-"}
              </CustomTypographyPaperClassBlue>
            </Grid>
            <Grid item xs={5} sm={4} md={3} lg={3} className="mb-16 ">
              <CustomTypographyPaperClass>
                {t("support")}
              </CustomTypographyPaperClass>
            </Grid>
            {supportContact &&
            supportContact[0] &&
            supportContact[0].name &&
            supportContact &&
            supportContact[0] &&
            supportContact[0].mailAddress &&
            supportContact &&
            supportContact[0] &&
            supportContact[0].telephoneNumber ? (
              <Grid item xs={7} sm={8} md={9} lg={9}>
                <CustomTypographyPaperClassTextSpan>
                  <Typography>
                    {supportContact &&
                    supportContact[0] &&
                    supportContact[0].name
                      ? supportContact[0].name
                      : "-"}
                  </Typography>
                </CustomTypographyPaperClassTextSpan>
                <CustomTypographyPaperClassBlueSpan
                  onClick={() => sendMail(supportContact[0].mailAddress)}
                >
                  <Typography>
                    {supportContact &&
                    supportContact[0] &&
                    supportContact[0].mailAddress
                      ? supportContact[0].mailAddress
                      : "-"}
                  </Typography>
                </CustomTypographyPaperClassBlueSpan>
                <CustomTypographyPaperClassTextSpan>
                  <Typography>
                    {supportContact &&
                    supportContact[0] &&
                    supportContact[0].telephoneNumber
                      ? supportContact[0].telephoneNumber
                      : "-"}
                  </Typography>
                </CustomTypographyPaperClassTextSpan>
              </Grid>
            ) : (
              "-"
            )}

            <EntitlementsTable
              search
              heading="application-entitlements"
              appId={params.applId}
            />
            <div className="closeDetails">
              <SecondaryButton
                label={t("close_details")}
                onButtonClick={() => setDetailsChange(params.id)}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
