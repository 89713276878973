export const arrayEqual = (arr1, arr2, key) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((item, index) => item[key] === arr2[index][key])
  );
};

export const insertSorted = (arr, newItem, ascending = false) => {
  const parseDate = (dateString) => {
    const parsedDate = Date.parse(dateString);
    return isNaN(parsedDate) ? new Date(dateString) : new Date(parsedDate);
  };
  const compareFunction = ascending
    ? (a, b) => parseDate(a.creationDate) - parseDate(b.creationDate)
    : (a, b) => parseDate(b.creationDate) - parseDate(a.creationDate);
  const indexToinsert = arr.findIndex(
    (item) => compareFunction(item, newItem) > 0
  );

  if (indexToinsert === -1) arr.push(newItem);
  else arr.splice(indexToinsert, 0, newItem);
};

export const concatUniqueByUUID = (array1, array2) => {
  // Create a Set of UUIDs from the first array
  const uuidSet = new Set(array1.map((item) => item.uuid));

  // Filter out items from the second array with duplicate UUIDs
  const uniqueArray2 = array2.filter((item) => !uuidSet.has(item.uuid));

  // Concatenate the unique items from the second array to the first array
  const result = array1.concat(uniqueArray2);

  return result;
};

export const areArraysSameStructure = (arr1, arr2) => {
  if (arr1.length === 0 && arr2.length == 0) {
    return true;
  }

  // Check if lengths of arrays are equal
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate through each object in the arrays
  for (let i = 0; i < arr1.length; i++) {
    const keys1 = Object.keys(arr1[i]);
    const keys2 = Object.keys(arr2[i]);

    // Check if lengths of keys arrays are equal
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if keys are the same
    for (let j = 0; j < keys1.length; j++) {
      if (keys1[j] !== keys2[j]) {
        return false;
      }
    }
  }

  // If all checks pass, return true
  return true;
};

export const isDataExist = (id, dataArray, identifier) => {
  return dataArray.some((item) => item[identifier] == id);
};

export const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};
