import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Button, IconButton } from '../../../button'
import { AliceIcon, AliceIconType } from '../../../icon'
import { Tooltip } from '../../../message'

export const AdminRoleTableToolbar = ({
  onSave,
  onDelete,
  onSelectAll,
  onDeselectAll,
  isSaveLoading,
  isDeleteLoading,
  isSaveDisabled,
  isDeleteDisabled
}) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Stack
      data-testid="AdminRoleTableToolbar"
      direction="row"
      spacing={1}
    >
      {typeof onSave === 'function' && (
        <Button
          data-testid="AdminRoleTableToolbar-save-descriptions"
          isLoading={isSaveLoading}
          disabled={isSaveDisabled}
          onClick={onSave}
        >
          <Typography>{t('adminRoles.saveDescriptions')}</Typography>
        </Button>
      )}

      {typeof onDelete === 'function' && (
        <Button
          data-testid="AdminRoleTableToolbar-delete-selected"
          isLoading={isDeleteLoading}
          disabled={isDeleteDisabled}
          onClick={onDelete}
        >
          <Typography>{t('adminRoles.deleteSelected')}</Typography>
        </Button>
      )}

      {typeof onSelectAll === 'function' && (
        <Tooltip title={t('general.selectAll')} arrow placement="top">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <IconButton
              data-testid="AdminRoleTableToolbar-select-all"
              onClick={onSelectAll}
            >
              <AliceIcon iconType={AliceIconType.LIST_ADD} color="grey.700" />
            </IconButton>
          </Box>
        </Tooltip>
      )}

      {typeof onDeselectAll === 'function' && (
        <Tooltip title={t('general.deselectAll')} arrow placement="top">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <IconButton
              data-testid="AdminRoleTableToolbar-deselect-all"
              onClick={onDeselectAll}
            >
              <AliceIcon iconType={AliceIconType.LIST_REMOVE} color="grey.700" />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </Stack>
  )
}
