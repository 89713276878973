import { createContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Cookie } from '../../constants'
import { AboutMeService, CacheKey, CacheStorage, CacheTTL, HttpCode, setupInterceptors, UsersService } from '../../data'
import { getCookie } from '../../util'
import { useApi } from '../api/useApi'
import { useApp } from '../app/UseApp'

export const UserProviderContext = createContext({})

export const UserProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const { setIsMaintenance } = useApp()

  const [ acceptTokenAgreements, setAcceptTokenAgreements ] = useState()

  setupInterceptors()

  const setLanguageByCookie = () => {
    const languageCookie = getCookie(Cookie.LANGUAGE)

    if (languageCookie) {
      i18n.changeLanguage(languageCookie)
      return true
    }

    return false
  }

  const handleRequestError = (error) => {
    invalidateUser()
    invalidateAgreements()

    if (error.status !== HttpCode.UNAUTHORIZED && error.status !== HttpCode.FORBIDDEN) setIsMaintenance(true)
  }

  const handleMeRequestStart = () => setLanguageByCookie()

  const handleMeRequestSuccess = (user) => {
    if (setLanguageByCookie()) return

    // UPDATE IF NOT EQUAL
    if (user?.preferredLanguage) {
      i18n.changeLanguage(user.preferredLanguage)
      return
    }

    i18n.changeLanguage(navigator?.language || navigator?.userLanguage || 'en')
  }

  const {
    isLoading: isLoadingUser,
    response: responseUser,
    invalidate: invalidateUser,
    mutate: mutateUser,
    isFailure: isFailureUser,
    status: statusUser,
    execute: executeUser
  } = useApi(
    AboutMeService.me,
    {
      onRequestStart: handleMeRequestStart,
      onRequestError: handleRequestError,
      onRequestSuccess: handleMeRequestSuccess
    }
  )

  const {
    isLoading: isLoadingAgreements,
    response: responseAgreements,
    invalidate: invalidateAgreements,
    mutate: mutateAgreements,
    isFailure: isFailureAgreements,
    status: statusAgreements,
    execute: executeAgreements
  } = useApi(
    UsersService.getAgreementsById,
    {
      config: { id: responseUser?.id },
      isRequestingInitially: false,
      isRequestingOnTruthy: responseUser?.id,
      isCaching: true,
      cacheKey: `${CacheKey.agreements}.${responseUser?.id}`,
      cacheStorage: CacheStorage.sessionStorage,
      cacheTTL: CacheTTL.oneYear,
      onRequestError: handleRequestError,
      responseTransformer: (response) => {
        setAcceptTokenAgreements(response.acceptToken)
        return response.agreements
      }
    }
  )

  const {
    isLoading: isLoadingAdminRoles,
    response: responseAdminRoles,
    mutate: mutateAdminRoles,
    isFailure: isFailureAdminRoles,
    status: statusAdminRoles,
    execute: executeAdminRoles
  } = useApi(
    AboutMeService.myAdminRoles,
    {
      isRequestingInitially: false,
      isRequestingOnTruthy: responseUser?.id && responseAgreements?.every((agreement) => agreement.isAccepted),
      onRequestError: handleRequestError,
      responseTransformer: (response) => {
        if (response === '') {
          return new Set()
        }

        return new Set(response.adminRoles.map((adminRole) => adminRole.adminRole))
      }
    }
  )

  const {
    isLoading: isLoadingEntitlements,
    response: responseEntitlements,
    mutate: mutateEntitlements,
    isFailure: isFailureEntitlements,
    status: statusEntitlements,
    execute: executeEntitlements
  } = useApi(
    UsersService.getAllEntitlementsById,
    {
      config: {
        id: responseUser?.id,
        filters: { applicationId: 'ALICE' },
        page: 0,
        pageSize: 500
      },
      isRequestingInitially: false,
      isRequestingOnTruthy: responseUser?.id && responseAgreements?.every((agreement) => agreement.isAccepted),
      onRequestError: handleRequestError,
      responseTransformer: (response) => {
        if (response === '') {
          return new Set()
        }

        return new Set(response.entitlements.map((entitlement) => entitlement.entitlementId))
      }
    }
  )

  return (
    <UserProviderContext.Provider
      value={useMemo(() => ({
        isLoading: (!responseUser && !isFailureUser)
          || isLoadingUser
          || isLoadingAgreements
          || isLoadingAdminRoles
          || isLoadingEntitlements,
        isFailure: isFailureUser
          || isFailureAgreements
          || isFailureAdminRoles
          || isFailureEntitlements,
        // User Object
        user: responseUser,
        isLoadingUser,
        invalidateUser,
        mutateUser,
        isFailureUser,
        statusUser,
        executeUser,

        // User Agreements
        agreements: responseAgreements,
        isLoadingAgreements,
        invalidateAgreements,
        mutateAgreements,
        isFailureAgreements,
        statusAgreements,
        executeAgreements,
        acceptTokenAgreements,

        // Users admin roles -- Legacy
        adminRoles: responseAdminRoles,
        isLoadingAdminRoles,
        mutateAdminRoles,
        isFailureAdminRoles,
        statusAdminRoles,
        executeAdminRoles,

        // Users entitlements
        entitlements: responseEntitlements,
        isLoadingEntitlements,
        mutateEntitlements,
        isFailureEntitlements,
        statusEntitlements,
        executeEntitlements
      }), [
        responseUser,
        isLoadingUser,
        invalidateUser,
        mutateUser,
        isFailureUser,
        statusUser,
        executeUser,
        responseAgreements,
        isLoadingAgreements,
        invalidateAgreements,
        mutateAgreements,
        isFailureAgreements,
        statusAgreements,
        executeAgreements,
        acceptTokenAgreements,
        responseAdminRoles,
        isLoadingAdminRoles,
        mutateAdminRoles,
        isFailureAdminRoles,
        statusAdminRoles,
        executeAdminRoles,
        responseEntitlements,
        isLoadingEntitlements,
        mutateEntitlements,
        isFailureEntitlements,
        statusEntitlements,
        executeEntitlements
      ])}
    >
      {children}
    </UserProviderContext.Provider>
  )
}
