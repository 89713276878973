import PropTypes from 'prop-types'
import { DeleteOutlineRounded } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { getClientLink, mergeSxProps } from '../../../util'
import { IconButtonEdged } from '../../button'
import { CardContent, CardDivided, ContextBtn, DetailItemType, DetailsList, ExpandBtn, GenericCard, IdTitle, useDetailItem } from '../../card/genericCard'
import { AppLogo } from '../appLogo/AppLogo'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { useInteraction } from '../../../hooks'
import { AppContextPopover } from '../appContext/AppContextPopover'
import { DataClassificationChip, TaskStatusChip } from '../../chip'
import { useAppCard } from '.'

/**
 * This component is designed for displaying specific application cards.
 *
 * **Important Note**:
 *
 * It extends the functionality of the GenericCard component, inheriting all its supported properties.
 * For a detailed list of available properties, please consult the [GenericCard documentation](./?path=/docs/alice-ui-card-genericcard--docs).
 */
export const AppCard = ({
  variant = 'grid',
  size = 'm',
  data,
  isContextShown: _isContextShown,
  isDescriptionShown: _isDescriptionShown,
  isClassificationShown: _isClassificationShown,
  isStatusShown: _isStatusShown,
  isLogoShown = true,
  isExpanded = false,
  onExpandClick: _onExpandClick, // ({ id, isExpanded, e }) => {},
  onDeleteClick: _onDeleteClick, // ({ id, data, e }) => {},
  // base card
  subgridContentColumns = 1,
  subgridContentRows = 1,
  isLoading = false,
  isIdLink = true,
  isSelected = false,
  isDisabled = false,
  isSubgrid = false,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const { getDetailItem } = useDetailItem()

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({ interaction })

  const isList = variant === 'list'
  const isGrid = !isList

  const {
    id,
    name,
    logoBase64: logo,
    description,
    dataClassification,
    assignmentStatus
  } = data || {}

  const {
    isTiny,
    isContextShown,
    isDescriptionShown,
    isClassificationShown,
    isStatusShown,
    isExpandShown,
    isDeleteShown,
    isChipsShown,
    isDividedHorizontal,
    isDividedShown
  } = useAppCard({
    size,
    isList,
    description,
    dataClassification,
    assignmentStatus,
    _isContextShown,
    _isDescriptionShown,
    _isClassificationShown,
    _isStatusShown,
    _onExpandClick,
    _onDeleteClick
  })

  const isContentShifted = isGrid && isSelectable && !isSelectHidden

  const onCardClick = _onCardClick ? ({
    id,
    isSelected,
    e
  }) => {
    _onCardClick({
      id,
      isSelected,
      data,
      e
    })
  } : undefined

  const onExpandClick = ({
    isExpanded,
    e
  }) => {
    e.preventDefault()

    _onExpandClick({
      id,
      isExpanded,
      e
    })
  }

  const onDeleteClick = (e) => {
    e.preventDefault()

    _onDeleteClick({
      id,
      data,
      e
    })
  }

  const context = (
    <ContextBtn
      ContextPopover={AppContextPopover}
      id={id}
      isLoading={isLoading}
    />
  )

  return (
    <GenericCard
      type="appCard"
      id={id}
      isList={isList}
      isTiny={isTiny}
      interaction={interaction}
      onCardClick={onCardClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isDividedShown={isDividedShown}
      isDividedHorizontal={isDividedHorizontal}
      isSubgrid={isSubgrid}
      subgridContentColumns={subgridContentColumns}
      subgridContentRows={subgridContentRows}
      sx={mergeSxProps([
        variant === 'grid' && size !== 's' && {
          '.select': {
            display: 'flex',
            alignItems: 'center',
            height: 56
          }
        }
      ], sx)}
      {...otherProps}
    >
      <CardContent
        context={context}
        isList={isList}
        isContextShown={isContextShown}
        isDividedHorizontal={isDividedHorizontal}
        sx={[
          { gridTemplate: `'id ${isDescriptionShown ? 'description' : ''}' / 1fr ${isDescriptionShown ? '1fr' : ''}` },
          isGrid && {
            gridTemplate: `
              'id context'
              ${isDescriptionShown ? '\'description description\'' : ''}
              / 1fr`
          }
        ]}
      >
        <Stack
          sx={{
            gridArea: 'id',
            flexFlow: 'row',
            gap: 2,
            overflow: 'hidden'
          }}
        >
          {isLogoShown && (
            <AppLogo
              size={size === 'l' ? 'm' : size}
              logo={logo}
              sx={[ { flexShrink: 0 }, size === 's' && { alignSelf: 'center' } ]}
              isLoading={isLoading}
            />
          )}

          <IdTitle
            id={id || 'APP ID'}
            title={name || 'Application Name'}
            size={size === 'l' ? 'm' : size}
            href={`${getClientLink('admin')}/applications/${id}`}
            isLink={isIdLink}
            isLoading={isLoading}
          />
        </Stack>

        {isDescriptionShown && (
          <DetailsList
            data={[
              getDetailItem({
                type: DetailItemType.DESCRIPTION,
                text: description
              })
            ]}
            variant={variant}
            isLoading={isLoading}
            isSubgrid
            sx={[
              { gridArea: 'description' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}
      </CardContent>

      <CardDivided
        isShown={isDividedShown}
        isList={isList}
        isTiny={isTiny}
        isHorizontal={isDividedHorizontal}
        isContextShown={isContextShown}
        context={context}
        action={(
          <>
            {isExpandShown && (
              <ExpandBtn
                isExpanded={isExpanded}
                isLoading={isLoading}
                hasSmallClickArea={isTiny}
                onClick={onExpandClick}
                sx={{ gridArea: 'action' }}
              />
            )}

            {isDeleteShown && (
              <IconButtonEdged
                onClick={onDeleteClick}
                icon={<DeleteOutlineRounded fontSize="inherit" />}
                isLoading={isLoading}
                sx={{ gridArea: 'action' }}
              />
            )}
          </>
        )}
      >
        {isChipsShown && (
          <Stack
            sx={[
              {
                gridArea: 'contextualChips',
                flexFlow: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1,
                gap: 1
              },
              variant === 'list' && { flexFlow: 'row-reverse' }
            ]}
          >
            {isClassificationShown && (
              <DataClassificationChip
                type={dataClassification.toLowerCase()}
                size="s"
                isLoading={isLoading}
              />
            )}

            {isStatusShown && <TaskStatusChip type={assignmentStatus} isLoading={isLoading} />}
          </Stack>
        )}
      </CardDivided>
    </GenericCard>
  )
}

AppCard.propTypes = {
  data: PropTypes.shape({
    /**
     * A base64-encoded image data representing the logo to be displayed.
     * If not provided, a default logo will be used.
     */
    logo: PropTypes.string,
    /** Application ID */
    id: PropTypes.string,
    /** Application url */
    url: PropTypes.string,
    /** Support email */
    email: PropTypes.string,
    /** Application name */
    name: PropTypes.string
  }),
  /** If ture, AppContextPopover Icon is displayed */
  isContextShown: PropTypes.bool,
  /** If ture, Description is displayed */
  isDescriptionShown: PropTypes.bool,
  /** If ture, ClassificationChip is displayed */
  isClassificationShown: PropTypes.bool,
  /** If ture, TaskStatusChip is displayed */
  isStatusShown: PropTypes.bool,
  /** If ture, ExpandBtn is in active state */
  isExpanded: PropTypes.bool,
  /**
   * If function provided and size is 'm', ExpandBtn will be displayed
   *
   * @param param { id, isExpanded, e }
   * @returns {void}
   */
  /** In case logo should not be displayed */
  isLogoShown: PropTypes.bool,
  onExpandClick: PropTypes.func,
  /**
   * If function provided and size is 'm', DeleteBtn will be displayed
   *
   * @param param { id, data, e }
   * @returns {void}
   */
  onDeleteClick: PropTypes.func,
  // base props
  /** Variant of the card */
  variant: PropTypes.oneOf([ 'grid', 'list' ]),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 'xs', 's', 'm', 'l', 'xl' ]),
  /** Loading state. If `true` placeholder will be shown */
  isLoading: PropTypes.bool,
  /**
   * Determines whether the card ID is associated with a link.
   *
   * Note:
   * Even if `isIdLink` is true,
   * visibility of the link requires the currently logged-in user to have at least one `admin role`.
   */
  isIdLink: PropTypes.bool,
  /** Specifies if the card is selected or not */
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubgrid: PropTypes.bool,
  interaction: InteractionPropTypes,
  /** Callback function when card gets clicked */
  onCardClick: PropTypes.func,
  sx: PropTypes.any
}
