import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Typography } from "@mui/material";
import { LinkButton } from "@alice/component_library";
import RoleRequestSuccessIcon from "@Icons/newRequestPage/RoleRequestSuccess.svg";
import SomethingWentWrongIcon from "@Icons/newRequestPage/SomethingWentWrong.svg";
import AuthoriztionErrorIcon from "@Icons/newRequestPage/AuthorizationError.svg";
import { ResultHeader, ResultHederContainer } from "../../styledComponents";

const Header = ({
  successfulRoles = [],
  failedRoles = [],
  blockedRoles = [],
}) => {
  const { t } = useTranslation();

  return (
    <ResultHederContainer>
      <ResultHeader>
        {t(
          blockedRoles?.length
            ? "authorization-error-occured"
            : failedRoles?.length
            ? "something-went-wrong"
            : "role-request-successfully-submitted"
        )}
      </ResultHeader>
      {blockedRoles.length ? (
        <AuthoriztionErrorIcon />
      ) : failedRoles.length ? (
        <SomethingWentWrongIcon />
      ) : (
        <RoleRequestSuccessIcon />
      )}
      <Typography
        variant="body1"
        sx={{
          "& a": {
            color: "#0078D6",
            textDecoration: "none",
          },
        }}
      >
        <Trans
          i18nKey={
            failedRoles.length && blockedRoles.length
              ? "request-blocked-failed-subheader"
              : blockedRoles.length
              ? "request-blocked-subheader"
              : failedRoles.length
              ? "request-failed-subheader"
              : "request-success-subheader"
          }
          values={{
            blockedCount: blockedRoles.length,
            failedCount: failedRoles.length,
            successCount: successfulRoles.length,
          }}
          components={[
            <b key={"first-highlight"} />,
            <b key={"second-highlight"} />,
          ]}
        />
      </Typography>
      {!!!failedRoles.length && !!!blockedRoles.length && (
        <LinkButton href="/access/requests" size="medium" variant="primary">
          {t("go-to-requests")}
        </LinkButton>
      )}
    </ResultHederContainer>
  );
};

export default Header;
