import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AliceIconType, Layout, useUser } from "@alice/component_library";
import { getCountsForRequestPage } from "@src/actions";
import { useWidth } from "@utils/hooks";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import TaskMenu from "@components/MenuList/index";
import PageHeader from "@components/PageHeader";
import { useQuery } from "@core/hooks";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import RoleRequestIcon from "@Icons/taskpage/taskMenu/RoleRequest.svg";
import AssignTechUserIcon from "@Icons/taskpage/taskMenu/AssignTechUser.svg";
import AssignEntitlementIcon from "@Icons/taskpage/taskMenu/AssignEntitlement.svg";
import RequestTable from "./Components/Table/Table";
import { Grid, PageContainer, PageContent } from "./styledComponents";
import { CacheStorage, useCache } from "@alice/component_library";

const cacheKey = "ReqPage_UserPreferance";

const RequestPage = () => {
  const dispatch = useDispatch();
  const { user: userUtils } = useUser();
  const taskListCounts = useSelector(
    (state) => state.overview.requestCountInfo
  );
  const { id: userId } = userUtils || {};

  const { getCachedItem, setCachedItem, removeCachedItem } = useCache(
    CacheStorage.sessionStorage
  );
  const cacheValue = getCachedItem(cacheKey);
  const query = useQuery();
  const taskType = query.get("tasktype") ?? "";
  const taskFilter = query.get("filter") ?? "";
  const { pathname } = window.location;
  const taskPAgeUrlWithoutParam = window.location.origin + pathname;
  window.history.replaceState({}, "", taskPAgeUrlWithoutParam);
  const [configMenuList, setConfigMenuList] = useState([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(
    cacheValue?.collapsed !== undefined ? cacheValue.collapsed : false
  );
  const [type, setType] = useState(
    cacheValue?.requesttypes !== undefined
      ? cacheValue?.requesttypes
      : taskType.length
      ? taskType
      : "allTasks"
  );
  const [searchKey, setSearchKey] = useState(
    cacheValue?.searchingKey !== undefined ? cacheValue?.searchingKey : ""
  );
  const [filter, setFilter] = useState({
    status:
      cacheValue?.status !== undefined
        ? cacheValue.status
        : taskFilter.length
        ? taskFilter === "All"
          ? ""
          : taskFilter
        : "PENDING",
    role: cacheValue?.role !== undefined ? cacheValue.role : "",
  });
  const [sortKey, setSortKey] = useState(
    cacheValue?.sortedValue !== undefined ? cacheValue.sortedValue : "1"
  );
  const [selectedRow, setSelectedRow] = useState({});
  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });
  const widthPanel = useWidth();

  const { t } = useTranslation();

  const mainHeading = t("requests");
  const subHeading = t("requestOverview-see-recent-requests-and-status");
  const searchLabel = t("requests-search");
  const filterByStatus = t("filter-by-status");
  const filterByUsersRole = t("filter-by-users-role");
  const taskMenuHeader = t("task-menu-header");
  const sortByLabel = t("sort-by");
  const oldToNew = t("creation-date-old-to-new");
  const newToOld = t("creation-date-new-to-old");

  useEffect(() => {
    dispatch(
      getCountsForRequestPage(userId, filter.status, filter.role, searchKey)
    );
  }, [dispatch, userId, filter, searchKey]);

  useEffect(() => {
    setIsDetailsOpen(false);
  }, [type, filter, searchKey, sortKey]);

  useEffect(() => {
    setConfigMenuList([
      {
        id: "allTasks",
        title: "menuBar.allRequest",
        badge: taskListCounts.tottalCount,
        // icon: AliceIconType.ALL_TASKS, not avialble in CL
        icon: (
          <EventNoteOutlinedIcon
            sx={{ color: "rgb(0, 120, 214)", fontSize: "1.7rem" }}
          />
        ),
      },
      {
        id: "APPROVE_ROLE_ASSIGNMENT",
        title: "taskType.ROLE_ASSIGNMENT",
        badge: taskListCounts.roleRequestCount,
        isCustomIcon: true,
        icon: <RoleRequestIcon />,
      },
      {
        id: "APPROVE_ROLE_DEPROVISIONING",
        title: "taskType.APPROVE_ROLE_DEPROVISIONING",
        badge: taskListCounts.deprovisioningCount,
        icon: AliceIconType.ROLE_DEPRO,
      },
      {
        id: "APPROVE_TECHUSER_TO_APPLICATION",
        title: "taskType.APPROVE_TECHUSER_TO_APPLICATION",
        badge: taskListCounts.techUserCount,
        isCustomIcon: true,
        icon: <AssignTechUserIcon />,
      },
      {
        id: "APPROVE_APPLICATIONROLE_TO_ROLE",
        title: "taskType.APPLICATIONROLE_TO_ROLE",
        badge: taskListCounts.entitileMentCount,
        isCustomIcon: true,
        icon: <AssignEntitlementIcon />,
      },
    ]);
  }, [taskListCounts]);

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
      }
    }
  }, [widthPanel]);

  window.addEventListener("beforeunload", () => {
    removeCachedItem(cacheKey);
  });

  const collapseState = () => {
    setCachedItem(cacheKey, { ...cacheValue, collapsed: !isCollapsed });
  };

  const requestType = (reqtype) => {
    setCachedItem(cacheKey, { ...cacheValue, requesttypes: reqtype });
  };

  return (
    <PageContainer>
      <Layout>
        <PageHeader
          mainHeading={mainHeading}
          subHeading={subHeading}
          searchProps={{
            label: searchLabel,
            searchKey: searchKey,
            handleSearch: (search) => {
              setCachedItem(cacheKey, { ...cacheValue, searchingKey: search });
              setSearchKey(search);
            },
          }}
          filterProps={{
            filterState: filter,
            filterConfig: [
              {
                key: "filter-by-status",
                id: "status",
                label: `${filterByStatus}${windowSize.isDesktop ? ":" : ""}`,
                filters: [
                  {
                    filter: "",
                    label: t("all"),
                  },
                  {
                    filter: "PENDING",
                    label: t("pending"),
                    icon: TimerOutlinedIcon,
                  },
                  {
                    filter: "APROOVED",
                    label: t("approved"),
                    icon: CheckCircleOutlineIcon,
                  },
                  {
                    filter: "EXPIRED",
                    label: t("expired"),
                    icon: CalendarMonthIcon,
                  },
                  {
                    filter: "FAILED",
                    label: t("failed"),
                    icon: ErrorOutlineIcon,
                  },
                  {
                    filter: "REJECTED",
                    label: t("rejected"),
                    icon: HighlightOffIcon,
                  },
                ],
              },
              {
                key: "filter-by-users-role",
                id: "role",
                label: `${filterByUsersRole}${windowSize.isDesktop ? ":" : ""}`,
                filters: [
                  {
                    filter: "",
                    label: t("all"),
                  },
                  {
                    filter: "Beneficiary",
                    label: t("beneficiary"),
                    icon: "USER_OUTLINE",
                    isalice: true,
                    size: 13,
                  },
                  {
                    filter: "Requester",
                    label: t("requester"),
                    icon: PeopleOutlinedIcon,
                  },
                  {
                    filter: "Beneficiary & Requester",
                    label: t("beneficiary-requester"),
                    icon: ManageAccountsOutlinedIcon,
                  },
                ],
              },
            ],
            handleFilter: (filter, key) => {
              setCachedItem(cacheKey, { ...cacheValue, [key]: filter });
              setFilter((prevFilter) => ({ ...prevFilter, [key]: filter }));
            },
          }}
          sortProps={{
            label: sortByLabel,
            sortOptions: [
              { value: "1", label: newToOld },
              { value: "2", label: oldToNew },
            ],
            sortKey: sortKey,
            handleSortKey: (sort) => {
              setCachedItem(cacheKey, { ...cacheValue, sortedValue: sort });
              setSortKey(sort);
            },
          }}
          windowSize={windowSize}
        />
        <PageContent windowsize={windowSize} isdetailsopen={isDetailsOpen}>
          <TaskMenu
            isCollapsed={isCollapsed}
            handleCollapse={setIsCollapsed}
            type={type}
            setType={setType}
            menuHeader={taskMenuHeader}
            configMenuList={configMenuList}
            windowSize={windowSize}
            collapseState={collapseState}
            requestType={requestType}
          />
          <Grid
            item
            xs={windowSize.isDesktop ? (isCollapsed ? 11 : 9) : 12}
            className="table-container"
          >
            <RequestTable
              isDetailsOpen={isDetailsOpen}
              rowClick={({ row }) => {
                setIsDetailsOpen(true);
                setSelectedRow(row);
              }}
              search={searchKey}
              translation={t}
              windowSize={windowSize}
              filter={filter}
              optionType={type}
              userId={userId}
              sortValue={sortKey}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              setIsDetailsOpen={setIsDetailsOpen}
            />
          </Grid>
        </PageContent>
      </Layout>
    </PageContainer>
  );
};

export default RequestPage;
