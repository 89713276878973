import AnnouncementIcon from '@mui/icons-material/Announcement'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from '../../message/Message'

/**
 * Displays a not found (HTTP-Code 404) message
 */
export const NotFound = forwardRef((
  {
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Message
      data-testid="NotFound"
      ref={ref}
      sx={sx}
      icon={<AnnouncementIcon fontSize="large" />}
      title={t('message.notFoundTitle')}
      description={t('message.notFoundDescription')}
      {...otherProps}
    />
  )
})

NotFound.propTypes = {
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
