import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { AppSearch } from '../../../appElements'
import { AssignmentDialog } from '../dialog/AssignmentDialog'

export const ApplicationHelpdeskAdministratorAssignmentDialog = ({
  userId,
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedApplications, setSelectedApplications ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.applications'),
      content: selectedApplications.length ? `${selectedApplications.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'applicationhelpdeskadmin',
        body: { applicationHelpdeskAdminPrivileges: selectedApplications.map((id) => ({ id })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.applicationHelpdeskAdmin') }),
      onRequestSuccess: (response) => onAssigned({ applicationHelpdeskAdmin: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsAssignDisabled = () => !(selectedApplications && selectedApplications.length > 0)

  const handleAppCardClick = (appId, selected) => {
    if (selected && !selectedApplications.includes(appId)) {
      setSelectedApplications([ ...selectedApplications, appId ])
    } else {
      setSelectedApplications(selectedApplications.filter((app) => app !== appId))
    }
  }
  const content = [
    {
      title: t('adminRoles.select.applications'),
      render: (
        <AppSearch
          interaction={Interaction.MULTI_SELECT}
          isCaching
          views={[ 'tiny' ]}
          onCardClick={handleAppCardClick}
          preselectedRowIds={selectedApplications}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="ApplicationHelpdeskAdministratorAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.applicationHelpdeskAdmin'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

ApplicationHelpdeskAdministratorAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
