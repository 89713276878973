import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  ValidityChip,
  Button,
  DateUtils,
} from "@alice/component_library";

import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import ReplayIcon from "@mui/icons-material/Replay";
import StartIcon from "@mui/icons-material/Start";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  RoleCardContainer,
  CardDetailContainer,
  CardIdContainer,
  CardIdIcon,
  CardIdTypo,
  CardNameTypo,
  CardValidityContainer,
  CardButtonContainer,
} from "./RCardStyles";

const RCardV3s = React.memo(
  ({
    data = {},
    size,
    onReapply = (data) => {},
    onModify = (data) => {},
    handleExtendOrReapply = null,
    ExtendOrReapplyButton = false,
  }) => {
    const remainingDaysToWarning = 14;
    const { t } = useTranslation();
    const {
      roleDefinition = {},
      validFrom,
      validTo,
    } = useMemo(() => data, [data]);

    const { id: roleId, name: roleName } = useMemo(
      () => roleDefinition,
      [roleDefinition]
    );

    const validToDaysLeft = validTo
      ? DateUtils.daysBetweenDates(
          new Date(Date.now()),
          new Date(Date.parse(validTo))
        )
      : null;
    let finalValidityDaysLeft = validToDaysLeft;

    return (
      <>
        <RoleCardContainer>
          <CardDetailContainer>
            <CardIdContainer>
              <CardIdIcon>{t("id")}</CardIdIcon>
              <Tooltip type="ellipsized" title={roleId}>
                <CardIdTypo>{roleId}</CardIdTypo>
              </Tooltip>
            </CardIdContainer>
            <Box
              sx={{
                overflow: "hidden",
              }}
            >
              <Tooltip type="ellipsized" title={roleName}>
                <CardNameTypo>{roleName}</CardNameTypo>
              </Tooltip>
            </Box>
          </CardDetailContainer>
          <CardValidityContainer>
            <RoleItem
              ele={{
                id: "validFrom",
                icon: <StartIcon sx={{ fontSize: 16 }} />,
                value: (
                  <div className="custom-validity-width">
                    {validFrom ? (
                      <ValidityChip
                        size="s"
                        expiryDate={new Date(validFrom)}
                        sx={{
                          backgroundColor: "transparent",
                          padding: 0,
                          fontFamily: "MB Corpo S Text",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                ),
              }}
            />

            <RoleItem
              ele={{
                id: "validTo",
                icon: <KeyboardTabIcon sx={{ fontSize: 16 }} />,
                value: (
                  <div>
                    {validTo ? (
                      <ValidityChip
                        sx={
                          finalValidityDaysLeft > remainingDaysToWarning
                            ? { backgroundColor: "transparent", padding: 0 }
                            : {}
                        }
                        remainingDaysToRed={0}
                        expiryDate={new Date(validTo)}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                ),
              }}
            />
          </CardValidityContainer>
          {ExtendOrReapplyButton ? (
            <CardButtonContainer>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ margin: "4px" }}
              />
              <Link
                to={`${handleExtendOrReapply}&mode=${
                  finalValidityDaysLeft < 0 ? "reapply" : "extend"
                }`}
              >
                <Button
                  size="medium"
                  variant="primary"
                  className="action-buttons"
                >
                  {finalValidityDaysLeft < 0 ? (
                    <ReplayIcon />
                  ) : (
                    <EditOutlinedIcon />
                  )}
                </Button>
              </Link>
            </CardButtonContainer>
          ) : null}
        </RoleCardContainer>
      </>
    );
  }
);

const RoleItem = React.memo(({ ele, size }) => (
  <div className="role-item">
    {ele.icon}
    <div className={size === "large" ? "name-value-large" : "name-value"}>
      {ele.value}
    </div>
  </div>
));

export default RCardV3s;
