import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Client, Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { getClientLink, isEmpty } from '../../../../util'
import { Link } from '../../../link'
import { NewTable } from '../../../table'
import { TabNavigation } from '../../../tabNavigation'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { RoleApproverAssignmentDialog } from '../assignment/RoleApproverAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const Tab = {
  ROLES: 0,
  GROUP_ROLES: 1
}

const ROW_KEY = 'roleId'
const COLUMNS = [ 'roleName', 'roleId' ]
const COLUMNS_GROUPS = [ 'roleName', 'roleId', 'group' ]

const getDefaultTabIndex = (administratedRoles, administratedGroupRoles) => {
  if (administratedRoles.length && administratedGroupRoles.length) return Tab.ROLES
  if (administratedRoles.length) return Tab.ROLES
  if (administratedGroupRoles.length) return Tab.GROUP_ROLES

  return Tab.ROLES
}

export const RoleApproverAccordion = ({
  userId,
  isAssigned,
  administratedRoles,
  administratedGroupRoles,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedRoles, setSelectedRoles ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'roleapprover',
        body: { roleApproverPrivileges: selectedRoles.map((roleId) => ({ roleId })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.roleApprover') }),
      onRequestSuccess: () => {
        const newAdministratedRoles = administratedRoles
          .filter((administrated) => selectedRoles
            .every((selected) => administrated.roleId !== selected))

        const roleApprover = {
          admin: !!administratedGroupRoles.length,
          administratedRolesByGroup: administratedGroupRoles
        }

        if (newAdministratedRoles.length) {
          roleApprover.admin = true
          roleApprover.administratedRoles = newAdministratedRoles
        }

        handleDeselectAll()
        onChange({ roleApprover })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'roleName':
        return t('general.roleName')
      case 'roleId':
        return t('general.roleId')
      case 'group':
        return t('general.group')
      default:
        return key
    }
  }

  const entryRenderer = (key, row) => {
    if (key === 'group') {
      return (
        <Link
          isBlank
          href={`${getClientLink(Client.ADMIN)}/user-groups/${row?.groups[0]?.uuid}`}
        >
          {row?.groups[0]?.name || '-'}
        </Link>
      )
    }

    return row[key]
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedRoles(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.roleId))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.roleId), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    newRole.roleApprover.administratedRolesByGroup = administratedGroupRoles

    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="RoleApproverAccordion"
        title={t('adminRoles.roleApprover')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {(!isEmpty(administratedRoles) || !isEmpty(administratedGroupRoles)) && (
          <Stack gap={2}>
            <TabNavigation
              initialIndex={getDefaultTabIndex(administratedRoles, administratedGroupRoles)}
              tabLabels={[
                t('adminRoles.userRoles'),
                t('adminRoles.groupRoles')
              ]}
            >
              {(activeTab) => (
                <>
                  <NewTable
                    ref={tableRef}
                    isLocal
                    interaction={Interaction.MULTI_SELECT}
                    localRows={administratedRoles}
                    titleRenderer={titleRenderer}
                    rowIdKey={ROW_KEY}
                    columns={COLUMNS}
                    onSelectionChange={handleSelectChange}
                    customSlot={(
                      <AdminRoleTableToolbar
                        onDelete={handleDelete}
                        onSelectAll={handleSelectAll}
                        onDeselectAll={handleDeselectAll}
                        isDeleteDisabled={!selectedRoles.length}
                        isDeleteLoading={isLoadingDelete}
                      />
                    )}
                    sx={[ activeTab !== Tab.ROLES && { display: 'none', maxHeight: 0 } ]}
                  />

                  <NewTable
                    isLocal
                    localRows={administratedGroupRoles}
                    titleRenderer={titleRenderer}
                    entryRenderer={entryRenderer}
                    rowIdKey={ROW_KEY}
                    columns={COLUMNS_GROUPS}
                    sx={[ activeTab !== Tab.GROUP_ROLES && { display: 'none', maxHeight: 0 } ]}
                  />
                </>
              )}
            </TabNavigation>
          </Stack>
        )}
      </AdminRoleAccordion>

      <RoleApproverAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

RoleApproverAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedRoles: PropTypes.array,
  onChange: PropTypes.func
}
