import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '../dialog/Dialog'

/**
 * This is a unique implementation of a dialog designed specifically for displaying information to the user.
 *
 * Use this type to display detail information about an object.
 *
 * ## Note
 * For more complex cases please use [Dialog](/docs/alice-ui-dialog-dialog--docs) component
 */
export const DetailsDialog = forwardRef((
  {
    isOpen,
    title,
    children,
    onClose = () => {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Dialog
      data-testid="DetailsDialog"
      ref={ref}
      open={isOpen}
      onClose={onClose}
      headerSection={title || t('dialog.details.title')}
      {...otherProps}
    >
      {children}
    </Dialog>
  )
})

DetailsDialog.propTypes = {
  /** Determines current state of dialog popup */
  isOpen: PropTypes.bool,
  /** Overrides the title of the dialog */
  title: PropTypes.string,
  /** Callback when user closes the dialog */
  onClose: PropTypes.func
}
