import { roleActionTypes } from "../constants/actionTypes";
import { endPoint } from "../constants/endPointTypes";
import Communication from "../services/Communication";

import { apiBaseUrl } from "../services/baseAPIService";
import { errorInfo, forceReload } from "./commonAction";
import { isWithinPreviousWeek, isWithinNextTwoWeeks } from "@src/utils";
import axiosInstance from "@src/services/axiosInstance";

const actions = {
  fetchselectedRoles: (payload) => ({
    type: roleActionTypes.FETCH_SELECTED_ROLES,
    data: payload,
  }),
  removeselectedRoles: (payload) => ({
    type: roleActionTypes.REMOVE_SELECTED_ROLES,
    data: payload,
  }),
  RoleDataUpdate: (payload) => ({
    type: roleActionTypes.ROLE_DATA_UPDATE,
    data: payload,
  }),
  fetchUserAssignedRoles: (payload, ismul) => ({
    type: roleActionTypes.FETCH_USER_ASSIGNED_ROLES,
    data: payload,
    ismul: ismul,
  }),
  fetchRolesBasedOnFilter: (payload, ismul, count) => ({
    type: roleActionTypes.FETCH_ROLES_BASED_ON_FILTERS,
    data: payload,
    ismul: ismul,
    count: count,
  }),
  getRoleEntitlements: (payload) => ({
    type: roleActionTypes.GET_ROLE_ENTITLEMENTS,
    data: payload,
  }),
  getOrgScope: (payload) => ({
    type: roleActionTypes.GET_ORG_SCOPE,
    data: payload,
  }),
  searchOrgScope: (payload) => ({
    type: roleActionTypes.SEARCH_ORG_SCOPE,
    data: payload,
  }),
  getOrgInfo: (payload) => ({
    type: roleActionTypes.GET_ORG_INFO,
    data: payload,
  }),
  getWorkingOrgInfo: (payload) => ({
    type: roleActionTypes.GET_WORK_ORG_INFO,
    data: payload,
  }),
  getAvailableOrgInfo: (payload) => ({
    type: roleActionTypes.GET_AVAILABLE_ORG_INFO,
    data: payload,
  }),
  getFilteredOrgInfo: (payload) => ({
    type: roleActionTypes.GET_REQUIRED_ORG_INFO,
    data: payload,
  }),
  fetchAllAssignedRoles: (payload) => ({
    type: roleActionTypes.FETCH_ALL_ASSIGNED_ROLES,
    data: payload,
  }),
  fetchExpiringRoles: (payload) => ({
    type: roleActionTypes.FETCH_EXP_ROLES,
    data: payload,
  }),
  submitFormData: (payload) => ({
    type: roleActionTypes.SUBMIT_DATA,
    data: payload,
  }),
  ClearsubmitFormData: (payload) => ({
    type: roleActionTypes.CLEAR_SUBMIT_DATA,
    data: payload,
  }),
  getAvailableApplications: (payload) => ({
    type: roleActionTypes.GET_AVAILABLE_APPLICATIONS,
    data: payload,
  }),

  getUserApplications: (payload) => ({
    type: roleActionTypes.GET_USER_APPLICATIONS,
    data: payload,
  }),

  getUserApplicationsLogo: (payload) => ({
    type: roleActionTypes.GET_APPLICATIONS_LOGO,
    data: payload,
  }),

  getUserEntitlements: (payload) => ({
    type: roleActionTypes.GET_USER_ENTITELMENTS,
    data: payload,
  }),

  getUserSupportContact: (payload) => ({
    type: roleActionTypes.GET_USER_SUPPORT_CONTACT,
    data: payload,
  }),

  clearRoleInfo: () => ({
    type: roleActionTypes.CLEAR_ROLE_INFO,
  }),
  removedStoredRolesData: () => ({
    type: roleActionTypes.REMOVE_STORED_ROLES_DATA,
  }),
  removedStoredApplicationsData: () => ({
    type: roleActionTypes.REMOVE_STORED_APP_DATA,
  }),
  storeSearchKeyWord: (payload) => ({
    type: roleActionTypes.STORE_SEARCH_KEYWORD,
    data: payload,
  }),

  updateAvailableApps: (payload) => ({
    type: roleActionTypes.UPDATE_AVAILABLE_APPLICATIONS,
    data: payload,
  }),
  storeExtendData: (payload) => ({
    type: roleActionTypes.STORE_EXTEND_DATA,
    data: payload,
  }),
  fetchRoleByID: (payload) => ({
    type: roleActionTypes.FETCH_ROLE_BY_ID,
    data: payload,
  }),
  fetchOrgByID: (payload) => ({
    type: roleActionTypes.FETCH_ORG_BY_ID,
    data: payload,
  }),
  updatePreviousOrgScope: (payload) => ({
    type: roleActionTypes.UPDATE_PREVIOUS_ORG_SCOPES,
    data: payload,
  }),
};
/**Fetching all Users information */
export const FetchUsersAssignedRolesData = (
  userId,
  selfRequestable,
  roleId,
  isMulti
) => {
  let Url = `${apiBaseUrl}/gems/users/${userId}/assignableroles`;

  if (selfRequestable)
    Url += `?filter=(isSelfRequestable==${selfRequestable};id==${roleId})`;

  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.fetchUserAssignedRoles(data, isMulti));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const CheckRoleAvailability = async (
  userId,
  roleId,
  orgScope = "",
  custScope = ""
) => {
  let roleAvailabilityURL = `${apiBaseUrl}/gems/users/${userId}/roles?filter=roleDefinition.id==${roleId};roleDefinition.isWorkflowBased==true`; //roleDefinition.isWorkflowBased
  if (orgScope)
    roleAvailabilityURL = `${roleAvailabilityURL};orgScope.id==${orgScope}`;
  if (custScope)
    roleAvailabilityURL = `${roleAvailabilityURL};customScope.name==${custScope}`;
  return await Communication.get(roleAvailabilityURL)
    .then(({ data }) => data?.roles ?? [])
    .catch((err) => []);
};

export const FetchRoleByID = (userId, roleId, isSelf = true) => {
  let url = `${apiBaseUrl}/gems/users/${userId}/assignableroles`;
  url += `?filter=id==${roleId};isWorkflowBased==true`;

  if (isSelf) url += `;isSelfRequestable==${isSelf}`;

  return function (dispatch) {
    dispatch(actions.fetchRoleByID({ loading: true, data: {} }));
    return axiosInstance
      .get(url)
      .then(({ data }) => {
        const { roles = [] } = data;

        dispatch(
          actions.fetchRoleByID({ loading: false, data: roles[0] ?? {} })
        );
      })
      .catch((error) => {
        dispatch(
          actions.fetchRoleByID({ loading: false, data: {}, error: true })
        );
      });
  };
};

export const ResetFetchRoleById = () => {
  return function (dispatch) {
    dispatch(actions.fetchRoleByID({ loading: false, data: {}, error: true }));
  };
};

export const InitialFetchUsersAssignedRolesData = (
  userId,
  offset,
  limit,
  selfRequestable = true,
  isMulti,
  filter
) => {
  let Url = `${apiBaseUrl}/gems/users/${userId}/assignableroles?offset=${offset}&limit=${limit}&filter=isWorkflowBased==true`;

  if (selfRequestable) Url += `;isSelfRequestable==${selfRequestable}`;
  if (filter.includes("JOBTITLE")) Url += `;isJobTitle==true`;
  const roleType = filter.filter(
    (item) => item !== "JOBTITLE" && item !== "all"
  );
  if (roleType.length) Url += `;roleType==${roleType[0]}`;
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.fetchUserAssignedRoles(data, isMulti));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }

        if (
          (error.response && error.response.status === 500) ||
          (error.response && error.response.status === 503)
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};
export const RemoveAssignableRolesData = () => {
  return function (dispatch) {
    dispatch(actions.fetchUserAssignedRoles());
  };
};

export const FetchRolesBasedOnSearch = (
  userId,
  offset,
  limit,
  data,
  formData,
  isMulti,
  count,
  filter
) => {
  let Url;
  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
  };
  const urlSearchParamsForAssignableRoles = new URLSearchParams();

  let filterData = "";
  if (data.includes(" ")) {
    const splitData = data.split(" ");
    for (let i = 0; i < splitData.length; i++) {
      const url =
        "(name==*" +
        splitData[i] +
        "*,id==*" +
        splitData[i] +
        "*,description==*" +
        splitData[i] +
        "*)";
      if (filterData === "") {
        filterData = filterData + url;
      } else {
        filterData = filterData + ";" + url;
      }
    }
  } else {
    filterData =
      "(name==*" + data + "*,id==*" + data + "*,description==*" + data + "*)";
  }

  const url = `isWorkflowBased==true`;

  if (formData !== "") {
    const isrolefilterId = formData.rolefilterBy === "" ? false : true;
    const appId = isObject(formData.rolefilterBy)
      ? formData.rolefilterBy.value
      : formData.rolefilterBy;
    if (isrolefilterId === true && appId !== "All Applications") {
      if (formData.roleOptions === "All role types") {
        if (data === "") {
          Url = url + ";applicationId==" + appId;
        } else {
          Url = url + ";" + filterData + ";applicationId==" + appId;
        }
      } else if (formData.roleOptions === "Self requestable") {
        if (data === "") {
          Url = url + ";applicationId==" + appId + ";isSelfRequestable==true";
        } else {
          Url =
            url +
            ";" +
            filterData +
            ";applicationId==" +
            appId +
            ";isSelfRequestable==true";
        }
      } else if (formData.roleOptions === "Job title") {
        if (data === "") {
          Url = url + ";applicationId==" + appId + ";isJobTitle==true";
        } else {
          Url =
            url +
            ";" +
            filterData +
            ";applicationId==" +
            appId +
            ";isJobTitle==true";
        }
      } else if (formData.roleOptions === "With organization scope") {
        if (data === "") {
          Url = url + ";applicationId==" + appId + ";needsOrgScopes==true";
        } else {
          Url =
            url +
            ";" +
            filterData +
            ";applicationId==" +
            appId +
            ";needsOrgScopes==true";
        }
      } else if (formData.roleOptions === "With custom scope") {
        if (data === "") {
          Url = url + ";applicationId==" + appId + ";needsCustomScopes==true";
        } else {
          Url =
            url +
            ";" +
            filterData +
            ";applicationId==" +
            appId +
            ";needsCustomScopes==true";
        }
      } else {
        if (data === "") {
          Url = url + ";applicationId==" + appId;
        } else {
          Url = url + ";" + filterData + ";applicationId==" + appId;
        }
      }
    } else {
      if (formData.roleOptions === "All role types") {
        if (data === "") {
          Url = url;
        } else {
          Url = url + ";" + filterData;
        }
      } else if (formData.roleOptions === "Self requestable") {
        if (data === "") {
          Url = url + ";isSelfRequestable==true";
        } else {
          Url = url + ";" + filterData + ";isSelfRequestable==true";
        }
      } else if (formData.roleOptions === "Job title") {
        if (data === "") {
          Url = url + ";isJobTitle==true";
        } else {
          Url = url + ";" + filterData + ";isJobTitle==true";
        }
      } else if (formData.roleOptions === "With custom scope") {
        if (data === "") {
          Url = url + ";needsCustomScopes==true";
        } else {
          Url = url + ";" + filterData + ";needsCustomScopes==true";
        }
      } else {
        if (data === "") {
          Url = url + ";needsOrgScopes==true";
        } else {
          Url = url + ";" + filterData + ";needsOrgScopes==true";
        }
      }
    }
  } else {
    if (data === "") {
      Url = url;
    } else {
      Url = url + ";" + filterData;
    }
  }
  if (filter.includes("JOBTITLE")) Url += `;isJobTitle==true`;
  const roleType = filter.filter(
    (item) => item !== "JOBTITLE" && item !== "all"
  );
  if (roleType.length) Url += `;roleType==${roleType[0]}`;
  let hasActiveRequest = Communication.activeRequests.some(
    (request) => request.endpoint === endPoint.CONTROLCENTERUSER
  );

  if (hasActiveRequest) {
    Communication.cancel(endPoint.CONTROLCENTERUSER);
  }

  urlSearchParamsForAssignableRoles.set("offset", offset);
  urlSearchParamsForAssignableRoles.set("limit", limit);
  urlSearchParamsForAssignableRoles.set("filter", Url);

  Url = `/gems/users/${userId}/assignableroles?${urlSearchParamsForAssignableRoles.toString()}`;

  return function (dispatch) {
    return Communication.get(Url, endPoint.CONTROLCENTERUSER)
      .then(({ data }) => {
        dispatch(actions.fetchRolesBasedOnFilter(data, isMulti, count));
        Communication.removeActiveRequest(endPoint.CONTROLCENTERUSER);
      })
      .catch((error) => {
        if (error.code !== "ERR_CANCELED") {
          Communication.removeActiveRequest(endPoint.CONTROLCENTERUSER);
          if (!error.response) {
            let status = 303;
            dispatch(forceReload(status));
          }
          if (
            (error.response && error.response.status === 500) ||
            error.response.status === 503
          ) {
            dispatch(errorInfo(error.response.status));
          }
          return error && error.response && error.response.status;
        }
      });
  };
};
export const GetRolesEntitlementData = (roleId) => {
  let Url = apiBaseUrl + "/gems/roles/" + roleId + "/entitlements";
  return function (dispatch) {
    dispatch(
      actions.getRoleEntitlements({ data: [], loading: true, prevKey: roleId })
    );
    return axiosInstance
      .get(Url)
      .then((resp) => {
        const { entitlements = [] } = resp.data;
        dispatch(
          actions.getRoleEntitlements({
            data: entitlements,
            loading: false,
            prevKey: roleId,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actions.getRoleEntitlements({
            data: [],
            loading: false,
            error: true,
            prevKey: roleId,
          })
        );
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const SearchEntitlementsData = (res) => {
  const data = res;
  return function (dispatch) {
    dispatch(actions.getRoleEntitlements({ data, loading: false }));
  };
};
export const StoreSearchKeyword = (keyword) => {
  return function (dispatch) {
    dispatch(actions.storeSearchKeyWord(keyword));
  };
};
export const RemoveRolesEntitlementData = () => {
  const data = [];
  return function (dispatch) {
    dispatch(actions.getRoleEntitlements({ data, loading: false }));
  };
};
export const selectedRoles = (data) => (dispatch) => {
  dispatch(actions.fetchselectedRoles(data));
};

export const removeselectedRoles = (data) => (dispatch) => {
  dispatch(actions.removeselectedRoles(data));
};

export const updateRoleData = (data) => (dispatch) => {
  dispatch(actions.RoleDataUpdate(data));
};

export const updatePreviousOrgSCopeRoleData = (data) => (dispatch) => {
  dispatch(actions.updatePreviousOrgScope(data));
};

export const FetchOrgByID = (user, role, id = "") => {
  let url = `${apiBaseUrl}/gems/users/${user}/roles/${role}/assignableorganizations`;
  if (id !== "") {
    const keyword = encodeURIComponent(id.trim());
    url += `?filter=id==${keyword};activationState==ALL`;
  }
  return function (dispatch) {
    dispatch(actions.fetchOrgByID({ data: {}, loading: true }));
    return axiosInstance
      .get(url)
      .then(({ data }) => {
        dispatch(
          actions.fetchOrgByID({
            data: data?.organizations[0] ?? {},
            loading: false,
            error: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actions.fetchOrgByID({
            data: {},
            loading: 0,
            error: 1,
            err: error,
          })
        );
      });
  };
};

export const ResetOrgByID = () => {
  return function (dispatch) {
    dispatch(
      actions.fetchOrgByID({
        data: {},
        loading: false,
        error: false,
      })
    );
  };
};

export const getOrganisationScope = (userId, roleId) => {
  let Url =
    apiBaseUrl +
    "/gems/users/" +
    userId +
    "/roles/" +
    roleId +
    "/assignableorganizations";

  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then((res) => {
        dispatch(actions.getOrgScope(res.data));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const searchForOrganisationScope = (
  userId,
  roleId,
  searchKey,
  country = "",
  orgType = ""
) => {
  const keyword = encodeURIComponent(searchKey.trim());

  let filter = "";
  if (keyword !== "") filter += `search=='*${keyword}*'`;
  if (country && country !== "") {
    let countryValue = country === "ALL" ? "" : country.toUpperCase();
    filter += filter !== "" ? ";" : "";
    filter += `country==${countryValue}`;
  }

  if (orgType && orgType !== "") {
    let orgTypeValue = orgType === "ALL_TYPES" ? "" : orgType.toUpperCase();

    filter += filter !== "" ? ";" : "";
    filter += `orgForm==${orgTypeValue}`;
  }

  let url = `${apiBaseUrl}/gems/users/${userId}/roles/${roleId}/assignableorganizations`;
  url += filter !== "" ? `?filter=${filter}` : "";

  return function (dispatch) {
    return axiosInstance
      .get(url)
      .then(({ data }) => {
        dispatch(actions.searchOrgScope(data));
        return data.organizations;
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const removeSearchData = () => {
  return function (dispatch) {
    dispatch(actions.searchOrgScope([]));
  };
};

export const getAvailableApplications = (userId) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/availableapplications";
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.getAvailableApplications(data));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

// Actions for My Applications

export const GetUserApplications = (userId) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/applications";
  return async function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(async ({ data }) => {
        if (data && data.applications && data.applications.length > 0) {
          let userInfo = data.applications;
          var result = userInfo.map(function (el) {
            var o = Object.assign({}, el);
            o.applLogo = "";
            return o;
          });

          data.applications = result;

          dispatch(actions.getUserApplications(data));
        } else {
          dispatch(actions.getUserApplications(data));
        }
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const SearchAssignedApplications = (userId, data) => {
  let Url = `${apiBaseUrl}/gems/users/${userId}/applications`;
  const search = data.split(" ");
  const filter = [];
  search.forEach((item) => {
    filter.push(`(name=='*${item}*',id=='*${item}*',description=='*${item}*')`);
  });

  const urlSearchParamsForTaskURL = new URLSearchParams();
  urlSearchParamsForTaskURL.set("filter", filter.toString());
  Url += `?${urlSearchParamsForTaskURL.toString()}`;

  return async function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(async ({ data }) => {
        if (data && data.applications && data.applications.length > 0) {
          let userInfo = data.applications;
          var result = userInfo.map(function (el) {
            var o = Object.assign({}, el);
            o.applLogo = "";
            return o;
          });

          data.applications = result;

          dispatch(actions.getUserApplications(data));
        } else {
          dispatch(actions.getUserApplications(data));
        }
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const removedStoredApplicationsData = () => (dispatch) => {
  dispatch(actions.removedStoredApplicationsData());
};

export const GetUserApplicationsLogo = (applicationId) => {
  let Url = apiBaseUrl + "/gems/applications/" + applicationId + "/logo";
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.getUserApplicationsLogo(data));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const GetUserEntitelments = (userId, value, search = "") => {
  let Url = `${apiBaseUrl}/gems/users/${userId}/entitlements?filter=applicationId==${value}`;
  if (search.trim() !== "")
    Url += `;(entitlementId=='*${search.trim()}*',applicationName=='*${search.trim()}*',description=='*${search.trim()}*')`;
  return function (dispatch) {
    dispatch(actions.getRoleEntitlements({ data: [], loading: true }));
    return axiosInstance
      .get(Url)
      .then((resp) => {
        const { entitlements } = resp.data;
        dispatch(
          actions.getRoleEntitlements({
            data: entitlements,
            loading: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actions.getRoleEntitlements({
            data: [],
            loading: false,
            error: true,
          })
        );
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const GetUserSupportContact = (userId) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/supportcontacts";
  return function (dispatch) {
    try {
      return axiosInstance
        .get(Url)
        .then(({ data }) => {
          dispatch(actions.getUserSupportContact(data));
        })
        .catch((error) => {
          if (!error.response) {
            let status = 303;
            dispatch(forceReload(status));
          }
          if (
            (error.response && error.response.status === 500) ||
            error.response.status === 503
          ) {
            dispatch(errorInfo(error.response.status));
          }
          return error && error.response && error.response.status;
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const SearchUserEntitlementsData = (res) => {
  const data = { entitlements: res };
  return function (dispatch) {
    dispatch(actions.getUserEntitlements(data));
  };
};

export const RemoveUserEntitlementData = () => {
  const data = { entitlements: [] };
  return function (dispatch) {
    dispatch(actions.getUserEntitlements(data));
  };
};

export const clearsubmitFormData = () => {
  return function (dispatch) {
    dispatch(actions.ClearsubmitFormData({ loading: false, data: [] }));
  };
};

export const submitData = (
  userId,
  roleId,
  needorgscope,
  needcustomscope,
  orgscope,
  orgId,
  custscope,
  validfrom,
  validto,
  reason,
  userName,
  roleName,
  defaultValidityType
) => {
  let postData = {};
  if (defaultValidityType === "NEVER" || defaultValidityType === "FIXED") {
    postData = {
      reason: reason,
    };
  } else {
    postData = {
      reason: reason,
      validFrom: validfrom,
      validTo: validto,
    };
  }
  let Url = `${apiBaseUrl}/gems/users/${userId}/roles/${roleId}`;

  if (needorgscope) Url += `/organizations/${orgId}`;

  if (needcustomscope) Url += `/customscopes/${custscope}`;

  return function (dispatch) {
    dispatch(actions.submitFormData({ loading: true, data: [] }));

    return axiosInstance
      .post(Url, postData)
      .then((res) => {
        let data = {};
        if (res && res.data) data = res.data;
        if (!data?.affectedData) {
          const obj = {};
          if (orgscope) obj.orgScopes = [{ name: orgscope }];
          if (custscope) obj.customScopes = [{ name: custscope }];

          data.affectedData = obj;
        }
        data.roleName = roleName;
        data.assignee = userName;
        data.code = res?.status;

        dispatch(actions.submitFormData({ loading: false, data: [data] }));
      })
      .catch((err) => {
        const data = err?.response?.data ?? {};

        data.roleName = roleName;
        data.assignee = userName;
        data.error = true;
        if (!data?.affectedData) {
          const obj = {};
          if (orgscope) obj.orgScopes = [{ name: orgscope }];
          if (custscope) obj.customScopes = [{ name: custscope }];

          data.affectedData = obj;
        }

        dispatch(actions.submitFormData({ loading: false, data: [data] }));
      });
  };
};

export const clearRolesData = () => (dispatch) => {
  dispatch(actions.clearRoleInfo());
};
/**
 * Actions For Profile Page
 */
export const GetOrgInfoIndividualUser = (userId, orgId) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/organizations/" + orgId;
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.getOrgInfo(data));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const GetOrgInfoIndividualWorkingUser = (userId, orgId) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/organizations/" + orgId;
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.getWorkingOrgInfo(data));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const GetAvailableOrgInfolUser = (userId) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/organizations";
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.getAvailableOrgInfo(data.organizations));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const GetFilteredOrgInfoUser = (userId) => {
  let Url =
    apiBaseUrl +
    "/gems/users/" +
    userId +
    "/organizations?filter=isHomeOrg==" +
    false +
    ";isWorkingOrg==" +
    false +
    ";isDealerHomeOrg==" +
    false;

  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.getFilteredOrgInfo(data.organizations));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const FetchAllAssignedRoles = ({
  userId,
  roleType,
  jobTitle,
  isDynamic,
  searchKey = "",
  sortKey = "",
}) => {
  let Url = apiBaseUrl + "/gems/users/" + userId + "/roles";
  const query = searchKey?.length ? `'*${searchKey.trim()}*'` : "";
  const urlSearchParamsForRole = new URLSearchParams();
  if (sortKey) {
    urlSearchParamsForRole.set("sort", sortKey);
  }
  const filterForRoles = [];
  if (query.length > 0) {
    filterForRoles.push(
      `(roleDefinition.id==${query},roleDefinition.name== ${query},roleDefinition.description== ${query})`
    );
  }
  if (roleType) {
    filterForRoles.push(`roleDefinition.roleType==${roleType}`);
  }
  if (jobTitle) {
    filterForRoles.push(`roleDefinition.isJobTitle==${jobTitle}`);
  }

  // filterForRoles.push(`roleDefinition.isDynamic==${isDynamic}`);

  if (filterForRoles.length)
    urlSearchParamsForRole.set("filter", filterForRoles.join(";"));
  const assignedRolesUrl = `${Url}?${urlSearchParamsForRole.toString()}`;
  return function (dispatch) {
    return axiosInstance
      .get(assignedRolesUrl)
      .then(({ data }) => {
        dispatch(actions.fetchAllAssignedRoles(data));
      })
      .catch((error) => {
        if (!error?.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error?.response && error?.response?.status === 500) ||
          error?.response?.status === 503
        ) {
          dispatch(errorInfo(error?.response?.status));
        }
        return error && error?.response && error?.response?.status;
      });
  };
};

export const SearchAssignedRoles = (userId, data) => {
  let Url =
    apiBaseUrl +
    "/gems/users/" +
    userId +
    "/roles?filter=(roleDefinition.name==*" +
    data +
    "*,roleDefinition.id==*" +
    data +
    "*,roleDefinition.description==*" +
    data +
    "*)";
  return function (dispatch) {
    return axiosInstance
      .get(Url)
      .then(({ data }) => {
        dispatch(actions.fetchAllAssignedRoles(data));
      })
      .catch((error) => {
        if (!error.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error.response && error.response.status === 500) ||
          error.response.status === 503
        ) {
          dispatch(errorInfo(error.response.status));
        }
        return error && error.response && error.response.status;
      });
  };
};

export const RemoveAssignedRoleData = async (
  userId,
  roleId,
  data,
  needcustscope,
  needorgscope,
  custScope,
  OrgScope
) => {
  let Url = `${apiBaseUrl}/gems/users/${userId}/roles/${roleId}`;
  if (needorgscope) Url += `/organizations/${OrgScope}`;
  if (needcustscope) Url += `/customscopes/${custScope}`;

  return async function (dispatch) {
    return await axiosInstance
      .delete(Url, {
        data: {
          reason: data,
        },
      })
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          return response.status;
        }
      })
      .catch((error) => {
        if (!error?.response) {
          let status = 303;
          dispatch(forceReload(status));
        }
        if (
          (error?.response && error?.response?.status === 500) ||
          error?.response?.status === 503
        ) {
          dispatch(errorInfo(error?.response?.status));
        }
        return error && error.response && error.response.status;
      });
  };
};
export const removedStoredRolesData = () => (dispatch) => {
  dispatch(actions.removedStoredRolesData());
};
export const updateAvailableApps = (data) => (dispatch) => {
  dispatch(actions.updateAvailableApps(data));
};

export const RemoveUsersEntitlementData = (data) => (dispatch) => {
  dispatch(actions.getUserEntitlements(data));
};

export const StoreExtendSelectedData = (data) => (dispatch) => {
  dispatch(actions.storeExtendData(data));
};

export const FetchExpiringRoles = (userId) => {
  let roleURL = `/gems/users/${userId}/roles?filter=roleDefinition.isDynamic==false&sort=-validTo`;
  return function (dispatch) {
    dispatch(
      actions.fetchExpiringRoles({
        tottalCount: 0,
        expiredRoleCount: 0,
        expiringRolesCount: 0,
        rolesDetail: [],
        loading: true,
        isLoaded: false,
      })
    );
    return Communication.get(roleURL)
      .then(({ data }) => {
        const { roles = [] } = data;
        const expiringData = roles.filter(
          (item) => item.validTo && isWithinNextTwoWeeks(item.validTo)
        );
        const expiredData = roles.filter(
          (item) => new Date(item.validTo) < new Date()
        );
        dispatch(
          actions.fetchExpiringRoles({
            tottalCount: expiringData.length + expiredData.length,
            expiredRoleCount: expiredData.length,
            expiringRolesCount: expiringData.length,
            rolesDetail: [
              ...expiredData.slice(0, 2),
              ...expiringData.slice(0, 3),
            ],
            loading: false,
            isLoaded: true,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actions.fetchExpiringRoles({
            tottalCount: 0,
            expiredRoleCount: 0,
            expiringRolesCount: 0,
            rolesDetail: [],
            loading: false,
            isLoaded: true,
          })
        );
        return error && error.response && error.response.status;
      });
  };
};

export default actions;
