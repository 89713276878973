import React from "react";
import { Typography, Divider } from "@mui/material";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import {
  Tooltip,
  UserAvatar,
  UserContextPopover,
  IconButton,
  IdChip,
} from "@alice/component_library";
import { ReferenceUserCard } from "../../styledComponents";

const SelectedReferenceUserCard = ({
  selectedReferenceUser,
  handleSelectReferenceUser,
}) => {
  const { id, givenname, surname, groupType, completeName } =
    selectedReferenceUser;
  return (
    <ReferenceUserCard key={id}>
      <div className="user-details">
        <UserContextPopover id={id}>
          <UserAvatar
            id={id}
            userId={id}
            userName={completeName}
            size="s"
            showGroupType
            groupType={groupType}
            showImage={true}
          />
        </UserContextPopover>
        <div className="header-name">
          <div className="user-card-header">
            <IdChip />
            <Tooltip type="ellipsized" title={id}>
              <Typography variant="subtitle1" className="user-card-id" noWrap>
                {id}
              </Typography>
            </Tooltip>
          </div>
          <Tooltip type="ellipsized" title={`${surname}, ${givenname}`}>
            <Typography variant="body1" className="user-card-name" noWrap>
              {`${surname}, ${givenname}`}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <div className="remove-icon">
        <Divider orientation="vertical" />
        <IconButton onClick={() => handleSelectReferenceUser({})}>
          <CancelOutlined />
        </IconButton>
      </div>
    </ReferenceUserCard>
  );
};

export default SelectedReferenceUserCard;
