import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@src/ComponentLibrary";
import MenuItem from "@mui/material/MenuItem";

const Dropdown = ({ dropdown_label, menuItems, handleChange, reason }) => {
  return (
    <div>
      <FormControl fullWidth variant="filled">
        <InputLabel shrink={true}>{dropdown_label}</InputLabel>
        <Select
          ref={{
            current: "[Circular]",
          }}
          label="Please select a reason"
          onChange={handleChange}
          value={reason}
          variant="filled"
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              // disabled={item.value === "zero"}
              sx={{
                "&:hover": { backgroundColor: "rgba(0, 120, 214, 0.16)" },
                "&.Mui-selected:hover": {
                  backgroundColor: "#0078D6",
                  color: "white",
                },
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
