import { Box } from "@mui/material";
import { Dropzone } from "@alice/component_library";

const DropZone = ({ files, uploadHint, error, handleFileChange }) => {
  return (
    <>
      <Box data-testid="dropzone-testid">
        <Dropzone
          fileTypes={[".csv"]}
          error={error}
          files={files}
          onChange={handleFileChange}
          uploadHint={uploadHint}
          sx={{
            "& .MuiStack-root:not(.MuiStack-root .MuiStack-root)": {
              backgroundColor: "rgba(0, 120, 214, 0.1)",
              border: "solid 1px rgba(0, 120, 214, 1)",
              borderStyle: "dashed",
              height: "104px",
              display: "flex",
              justifyContent: "center",
            },
          }}
        />
      </Box>
    </>
  );
};

export default DropZone;
