import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { IconButton } from "@alice/component_library";
import { IconContainer, StyledViewSwitch } from "./styledComponent";
const ViewSwitch = ({
  viewLabel = "",
  viewConfig = [],
  activeView = "",
  onChange = () => {},
}) => {
  return (
    <StyledViewSwitch>
      <Typography variant="body2">{viewLabel}</Typography>
      <IconContainer>
        {viewConfig?.map(({ icon: Icon, tooltip, id }, key) => (
          <Tooltip key={key} title={tooltip && tooltip} disabled={!tooltip}>
            <IconButton
              sx={{
                color: activeView === id ? "#0078D6" : "#0000008A",
              }}
              onClick={() => {
                onChange(id);
              }}
            >
              <Icon />
            </IconButton>
          </Tooltip>
        ))}
      </IconContainer>
    </StyledViewSwitch>
  );
};

export default ViewSwitch;
