import csvDownload from 'json-to-csv-export'
import * as XLSX from 'xlsx'
import { camelCaseToWords } from '../format/FormatUtils'
import { FileExporter } from './FileExporter'

export class JSONExporter {
  static asCSV(jsonData, {
    fileName = 'export.csv',
    headers = Object.keys(jsonData[0])?.map((key) => camelCaseToWords(key)),
    delimiter = ','
  }) {
    csvDownload({
      data: jsonData,
      filename: fileName,
      headers,
      delimiter
    })
  }

  static asXLSX(jsonData, {
    fileName = 'export.xlsx',
    sheetName = 'Sheet'
  }) {
    if (!Array.isArray(jsonData)) throw new Error('jsonData should be an array of objects')

    const worksheet = XLSX.utils.json_to_sheet(jsonData)
    const workbook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

    const binaryData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

    FileExporter.download(binaryData, { fileName })
  }
}
