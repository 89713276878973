import { blue } from '@mercedes-benz/mui5-theme'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link as RouterLinkDom, useResolvedPath } from 'react-router-dom'
import { CLIENTS, getClient, getClientLink, isUrl } from '../../util'

const linkStyle = {
  display: 'flex',
  textDecoration: 'none',
  color: blue[45]
}

const RouterLink = styled(RouterLinkDom)(linkStyle)
const A = styled('a')(linkStyle)

/** A simple Link, that can be configured to automatically choose the React-Router-Dom to navigate between pages. */
export const Link = forwardRef((
  {
    isUsingRouterIfPossible = true,
    isBlank,
    href,
    children,
    ...otherProps
  },
  ref
) => {
  const isPlainLink = isUrl(href)
  const resolvedPath = useResolvedPath(href)?.pathname
  const currentClient = getClient()

  let targetClient = resolvedPath.split('/').filter((pathname) => pathname)[0] || currentClient

  if (!CLIENTS.find((client) => client.toLowerCase() === targetClient.toLowerCase())) {
    targetClient = currentClient
  }

  const isSameClient = !isPlainLink && currentClient === targetClient

  const getHref = () => {
    if (isPlainLink || isSameClient) return href

    return `${getClientLink(targetClient)}${href.replace(`${targetClient}/`, '')}`
  }

  const handleClick = (e) => e.stopPropagation()

  return (
    isUsingRouterIfPossible && isSameClient ? (
      <RouterLink
        ref={ref}
        to={getHref()}
        rel="noreferrer"
        onClick={handleClick}
        {...otherProps}
      >
        { children }
      </RouterLink>
    ) : (
      <A
        ref={ref}
        href={getHref()}
        target={isBlank ? '_blank' : '_self'}
        rel="noreferrer"
        onClick={handleClick}
        {...otherProps}
      >
        { children }
      </A>
    )
  )
})

Link.propTypes = {
  /** Prefers using React-Router-Dom routing over browser routing */
  isUsingRouterIfPossible: PropTypes.bool,
  /** If true, target='_blank' will automatically be added when not routing */
  isBlank: PropTypes.bool,
  /** Href. Can be a full URL or just a path. */
  href: PropTypes.string.isRequired
}
