import { Card as MuiCard } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { mergeSxProps } from "@alice/component_library";

/**
 * Generic Card with Alice styling.
 *
 * active class can be set for special styling
 *
 * https://mui.com/material-ui/api/card/
 */

export const Card = forwardRef(
  ({ enableHover, enableShadow, children, sx = [], ...otherProps }, ref) => (
    <MuiCard
      data-testid="Card"
      ref={ref}
      sx={mergeSxProps(
        {
          boxShadow: !enableShadow ? "none" : null,
          backgroundColor: "grey.90",
          transition: "background 250ms, color 150ms",
          borderRadius: "4px",
          "&.active:hover, &:hover": enableHover && {
            backgroundColor: "grey.30",
            cursor: "pointer",
            color: "white",
            "#selectText": { visibility: "visible" },
          },
          "&.active": {
            backgroundColor: "grey.5",
            color: "white",
            boxShadow: !enableShadow ? "none" : null,
          },
        },
        sx
      )}
      {...otherProps}
    >
      {children}
    </MuiCard>
  )
);

Card.propTypes = {
  /** You can activate the active style of the card with: className="active". */
  className: PropTypes.string,
  /** Content to display in the card */
  children: PropTypes.node.isRequired,
  /**
   * If a shadow around the card should be shown.
   * This gives the prop an elevated look
   */
  enableShadow: PropTypes.bool,
  /** Enables hover effect on card */
  enableHover: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
};
