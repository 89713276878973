import { Message, SearchBar, UserCard } from "@src/ComponentLibrary";
import { GetOrganizationColleagues } from "@src/actions";
import { WarningIcon } from "@src/sharedComponents/Styled";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoResults from "@components/Noresults";
import { Box, Grid, TablePagination } from "@mui/material";
import { OrganizatoinHeaders } from "@src/pages/profilePage/styledComponents";
import {
  PaginationSkeleton,
  UserCardLoading,
} from "@src/sharedComponents/Skeleton/CommonSkeletons";

const OrgColleagues = ({
  t,
  dispatch,
  selectableRowsPerPage,
  homeOrgId,
  isCollapsed,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const {
    orgColleagueData: orgCollDataa = [],
    // error,
    loading = false,
    pageData = {
      data: [],
      paginationTottalCount: 0,
      search: "",
      rowsPerPage,
    },
  } = useSelector((state) => state.profile.organizatinColleagues);
  const getOrgCollDataForPage = useCallback(() => {
    let isNeedToReset = false;
    if (
      rowsPerPage !== pageData?.rowsPerPage ||
      searchValue !== pageData?.search
    ) {
      isNeedToReset = true;
    }
    if (
      pageData?.data[page] &&
      pageData?.data[page].length > 0 &&
      !isNeedToReset
    ) {
      return;
    } else {
      const offset = page * rowsPerPage;
      const limit = rowsPerPage;
      dispatch(
        GetOrganizationColleagues(
          homeOrgId,
          searchValue,
          offset,
          limit,
          page,
          isNeedToReset ? [] : pageData?.data,
          rowsPerPage,
          orgCollDataa
        )
      );
    }
  }, [dispatch, searchValue, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => setPage(newPage);
  const handleRowsPerPageChange = (event) => setRowsPerPage(event.target.value);

  useEffect(() => {
    getOrgCollDataForPage();
  }, [getOrgCollDataForPage]);

  useEffect(() => {
    setPage(0);
  }, [homeOrgId, searchValue]);

  const getNoData = (key) => {
    return key.length > 0 ? (
      <NoResults keysearch={key} searchFlag={true} />
    ) : (
      <Message icon={<WarningIcon />} title={t("no_data_found")} />
    );
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };
  const onClear = () => {
    // reviewSearch("");
    setSearch("");
    setSearchValue("");
  };
  const onSearch = (string) => {
    // reviewSearch(string);
    setSearchValue(string);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
        <OrganizatoinHeaders>{t("my-org-colleagues")}</OrganizatoinHeaders>
        <div style={{ marginBottom: "2rem" }}>
          <SearchBar
            ref={{
              current: "[Circular]",
            }}
            fullWidth
            label={t("colleagues-search-label")}
            onChange={onChange}
            onClear={onClear}
            onSearch={onSearch}
            showIcons
            size="normal"
            theme="dark"
            value={search}
          />
        </div>
        <div>
          {loading ? (
            <PaginationSkeleton />
          ) : (
            pageData?.data[page]?.length > 0 && (
              <TablePagination
                rowsPerPageOptions={selectableRowsPerPage}
                component="div"
                count={pageData?.paginationTottalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t("colleagues-per-page")}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )
          )}
        </div>
        <Grid container>
          {loading ? (
            <>
              <Grid container spacing={2}>
                {[...Array(12)].map((ele) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={isCollapsed ? 4 : 6}
                    key={ele}
                  >
                    <UserCardLoading />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                {pageData?.data[page]?.length > 0 ? (
                  <Grid container spacing={1}>
                    {pageData?.data[page].map((userDataItem, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={isCollapsed ? 4 : 6}
                        key={index}
                      >
                        <UserCard
                          userData={userDataItem}
                          readonly
                          isContextShown={true}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      justifyContent: "center",
                      width: "100%",
                      marginBottom: "50px",
                    }}
                  >
                    {getNoData(searchValue)}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default OrgColleagues;
