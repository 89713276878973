import { AliceService } from "@alice/component_library";

export class CLService {
  static getAccessReviewbyReviewId = ({ ...args }) =>
    AliceService.get(`/reviews/access`, { ...args });

  static getAccessReviewDetail = ({ id, ...args }) =>
    AliceService.get(`/reviews/access/${id}`, { ...args });

  static getEntitlement = ({ id, userId, offset, limit, ...args }) =>
    AliceService.get(
      `/users/${userId}/entitlements?filter=applicationId==${id}&offset=${offset}&limit=${limit}`
    );
}
