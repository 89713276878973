import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Devices, QuestionMark, BusinessRounded, RestartAltRounded, BadgeOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { AliceIcon } from '../../icon'
import { Skeleton } from '../../skeleton'

export const RoleTypeChipPlaceholder = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: '90px',
      height: '24px',
      borderRadius: '20px'
    }}
  />
)

/**
 * Generic Chip with Alice styling.
 *
 * *Note:*
 * - Props: `roleType`, `isDynamic` & `isJobTitle` are required.
 *
 * https://mui.com/material-ui/api/chip/
 */
export const RoleTypeChip = forwardRef((
  {
    roleType = 'ENTITLEMENT',
    isDynamic,
    isJobTitle,
    isLoading,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const normalizedRoleType = roleType && `${roleType?.charAt(0).toUpperCase()}${roleType?.slice(1).toLowerCase()}`

  const getRoleType = () => {
    if (isDynamic || isJobTitle) {
      return isDynamic
        ? { title: t('roleElements.roleTypeChip.dynamic'), icon: <RestartAltRounded color="inherit" fontSize="inherit" /> }
        : { title: t('roleElements.roleTypeChip.jobTitle'), icon: <BadgeOutlined color="inherit" fontSize="inherit" /> }
    }

    switch (roleType) {
      case 'PRODUCT':
        return { title: t('roleElements.roleTypeChip.product'), icon: <Devices color="inherit" fontSize="inherit" /> }
      case 'BUSINESS':
        return { title: t('roleElements.roleTypeChip.business'), icon: <BusinessRounded color="inherit" fontSize="inherit" /> }
      case 'ENTITLEMENT':
        return { title: t('roleElements.roleTypeChip.entitlement'), icon: <AliceIcon iconType="UIApps" color="primary.main" size={12} /> }
      default:
        return { title: t(`roleElements.roleTypeChip.${normalizedRoleType}`), icon: <QuestionMark color="inherit" fontSize="inherit" /> }
    }
  }

  return (isLoading
    ? <RoleTypeChipPlaceholder />
    : (
      <Stack
        ref={ref}
        sx={mergeSxProps(
          {
            flexDirection: 'row',
            alignItems: 'center',
            gap: '6px',
            backgroundColor: 'blue.85',
            borderRadius: '100px',
            padding: '4px',
            height: 24,
            width: 'fit-content'
          },
          sx
        )}
        {...otherProps}
      >
        <Box
          sx={{
            color: 'primary.main',
            backgroundColor: 'common.white',
            borderRadius: '100%',
            fontSize: '12px',
            lineHeight: 0,
            padding: '3px'
          }}
        >
          {getRoleType().icon}
        </Box>

        {roleType && (
        <Typography variant="body2" sx={{ marginRight: '4px' }}>{getRoleType().title}</Typography>
        )}
      </Stack>
    ))
})

RoleTypeChip.propTypes = {
  /** Defines RoleTypeChip label */
  roleType: PropTypes.string.isRequired,
  /** Defines RoleTypeChip label */
  isDynamic: PropTypes.bool.isRequired,
  /** Defines RoleTypeChip label */
  isJobTitle: PropTypes.bool.isRequired,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
