import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from '../../message/Message'

/**
 * This is a generic message component.
 */
export const UnknownError = forwardRef((
  {
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Message
      data-testid="UnknownError"
      ref={ref}
      sx={sx}
      icon={<FeedbackOutlinedIcon fontSize="large" />}
      title={t('message.unknownErrorTitle')}
      description={t('message.unknownErrorDescription')}
      {...otherProps}
    />
  )
})

UnknownError.propTypes = {
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
