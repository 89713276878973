import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { Box, Chip as MuiChip, Divider, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateUtils, mergeSxProps } from '../../../util'
import { Skeleton } from '../../skeleton'

const DATE_OPTIONS_LONG = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}

/**
 * The ValidityChip is a custom chip component designed to display and visually
 * communicate the validity status of an item based on its expiry date. This component
 * provides a clear indication of the remaining time until expiration using color-coded
 * backgrounds and informative labels.
 *
 * ### Usage
 *
 * The ValidityChip component can be used in an application to visually
 * represent the validity status of an item. The component appearance may be customized by adjusting
 * `sx` prop. Other props: `expiryDate`, `remainingDaysToWarning`, `remainingDaysToRed` have default values.
 * Please ensure to provide appropriate `expiryDate` prop in RFC2822 Date Format (e.g. Tue Nov 05 1985 06:23:20 GMT+0530 (IST)).
 *
 * ### Sizes
 * - s: Displays the expiry date
 * - m: Displays the expiry date and the remaining days
 */
export const ValidityChip = forwardRef((
  {
    size = 'm',
    expiryDate,
    remainingDaysToWarning = 14,
    remainingDaysToRed = 3,
    isLoading = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')
  const [ formattedExpiryDate, setFormattedExpiryDate ] = useState('')
  const daysLeft = DateUtils.daysBetweenDates(new Date(), expiryDate)

  useEffect(() => {
    if (!expiryDate) return

    if (!(expiryDate instanceof Date)) {
      setFormattedExpiryDate(new Date(expiryDate).toLocaleDateString(undefined, DATE_OPTIONS_LONG))
    } else {
      setFormattedExpiryDate(expiryDate.toLocaleDateString(undefined, DATE_OPTIONS_LONG))
    }
  }, [ expiryDate ])

  const chipBgColor = () => {
    if (daysLeft <= remainingDaysToRed) return 'red.80'
    if (daysLeft <= remainingDaysToWarning) return 'yellow.80'
    return 'white'
  }

  const Label = () => (
    <>
      <Typography as="span" className="chipDateWrapper">
        {formattedExpiryDate}
      </Typography>

      {size !== 's' && (
        <>
          <Divider orientation="vertical" sx={{ height: '16px' }} />

          <Stack direction="row" alignItems="center" gap="2px">
            <Box className="chipIconWrapper">
              <TimerOutlinedIcon fontSize="inherit" />
            </Box>

            <Typography as="span">
              {`${Math.max(daysLeft, 0)} ${t('validityChip.daysLeft')}`}
            </Typography>
          </Stack>
        </>
      )}
    </>
  )

  return isLoading
    ? (
      <Skeleton
        variant="rounded"
        sx={{
          width: '90px',
          height: '24px',
          borderRadius: '20px'
        }}
      />
    )
    : (
      <MuiChip
        data-testid="ValidityChip"
        ref={ref}
        size="small"
        label={(<Label />)}
        sx={mergeSxProps(
          {
            color: 'black',
            backgroundColor: chipBgColor(),
            border: 'unset',
            paddingInline: '8px',
            '.MuiChip-label': {
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              p: '0'
            },
            '.chipDateWrapper': {
              fontWeight: 700,
              fontSize: '14px'
            },
            '.chipIconWrapper': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '16px',
              aspectRatio: '1 / 1',
              marginInline: '0',
              fontSize: '16px'
            }
          },
          sx
        )}
        {...otherProps}
      />
    )
})

ValidityChip.propTypes = {
  /** Size variant of the chip */
  size: PropTypes.oneOf([ 's', 'm' ]),
  /** Determines the expiry date */
  expiryDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]),
  /** Determines quantity of dates before warning */
  remainingDaysToWarning: PropTypes.number,
  /** Determines quantity of dates before critical warning */
  remainingDaysToRed: PropTypes.number,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
