import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AliceIcon, AliceIconType } from "@alice/component_library";
import StatusRoleConfig from "@components/StatusRoleConfiguration";
import OrgAdditionCard from "@components/OrgAdditionCard";
import { OrgSearchDialogue } from "@components/OrgDialogue";
import { OrgScopeCard } from "@components/OrgScopeCard";
import { CustOrgSearchDialogue } from "@components/CustDialogue";
import {
  OrgScopeContainer,
  OrgscopeHeader,
  OrgHeaderContainer,
  OrganizationWrapper,
  OrganizationWrapperContentWrapper,
  CustomScopeContentWrapper,
  ScopeWrapper,
} from "@pages/newRequest/styledComponents";
import OrgDeletePopup from "./DeletePopUp";

const hasAllCustomScopes = (obj) => {
  return Object.keys(obj).every((key) => {
    const value = obj[key];
    return (
      value.hasOwnProperty("customScope") &&
      Array.isArray(value.customScope) &&
      value.customScope.length > 0
    );
  });
};

const OrganizationCustomScopeContainer = ({
  data,
  id,
  userid,
  changeValue,
  scopeData,
  combinedData,
  isFromDeeplink = false,
  isExtend = false,
}) => {
  const { t } = useTranslation();
  const { needsCustomScopes, needsOrgScopes, customScopes = [] } = data;
  const [scopeValues, setScopeValues] = useState([]);
  const [custScopeValues, setCustScopeValues] = useState([]);
  const [isShowOrgsearch, setShowOrgSearch] = useState(false);
  const [isShowCustOrg, setShowCustomOrg] = useState(false);
  const [scopeInfo, setScopeInfo] = useState(scopeData);
  const [orgId, setOrgId] = useState(null);
  const [lastOrgId, setLastorgId] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [reset, setReset] = useState(true);
  const customScopesWithIds = customScopes.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const resetPage = () => {
    setReset(true);
    setScopeValues([]);
    setCustScopeValues([]);
    setOrgId(null);
    setLastorgId(null);
    setScopeInfo(scopeData);
  };

  useEffect(() => {
    resetPage();
  }, [id, scopeData]);

  const getHeadValue = (orgScope, custScope) => {
    if (orgScope) {
      return {
        text: t("org-scopes"),
        icon: <AliceIcon size={16} iconType={AliceIconType.UIDate_From} />,
        iconText: t("add-org"),
      };
    } else if (custScope && orgScope === false) {
      return {
        text: t("cust-scopes"),
        icon: <AliceIcon size={16} iconType={AliceIconType.CUSTOM_SCOPE} />,
        iconText: t("add-cust"),
      };
    } else {
      return {
        text: t("org-scopes"),
        icon: <AliceIcon size={16} iconType={AliceIconType.UIDate_From} />,
        iconText: t("add-org"),
      };
    }
  };

  const setOrganisationScope = ({ orgs }) => {
    const orgIdToSave = orgs.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setLastorgId(orgs[0]?.id);
    setScopeInfo((prevScopeinfo) =>
      needsCustomScopes ? { ...prevScopeinfo, ...orgIdToSave } : orgIdToSave
    );
    setReset(false);
    setScopeValues(orgs);
  };

  const setCustomScope = ({ orgs }) => {
    setReset(false);
    setScopeInfo((prevScopeInfo) => {
      if (prevScopeInfo[orgId]) {
        return {
          ...prevScopeInfo,
          [orgId]: {
            ...prevScopeInfo[orgId],
            customScope: orgs,
          },
        };
      } else {
        return {
          ...prevScopeInfo,
          0: {
            ...prevScopeInfo[0],
            customScope: orgs,
          },
        };
      }
    });
  };

  const OpenModal = (_, isCsutomScope = false, orgIdParam) => {
    if (isCsutomScope) {
      setOrgId(orgIdParam);
      setCustScopeValues(scopeInfo[orgIdParam]?.customScope ?? []);
      setLastorgId(orgIdParam);
      setShowCustomOrg(true);
    } else {
      if (needsOrgScopes) {
        setScopeValues(
          Object.keys(scopeInfo).map((id) => ({ id, ...scopeInfo[id] }))
        );
        setShowOrgSearch(true);
      } else if (needsCustomScopes && needsOrgScopes == false) {
        setCustScopeValues(scopeInfo[0]?.customScope ?? []);
        setShowCustomOrg(true);
      } else {
        setScopeValues(
          Object.keys(scopeInfo).map((id) => ({ id, ...scopeInfo[id] }))
        );
        setShowOrgSearch(true);
      }
    }
  };

  const closeModal = () => {
    setShowOrgSearch(false);
    setShowCustomOrg(false);
  };

  // useEffect(() => {
  //   console.log(orgId, "Checking org id");
  //   console.log(scopeInfo, "Checking scope info");
  //   console.log(scopeInfo[orgId], "Checking scope info");
  //   setCustScopeValues(scopeInfo[orgId]?.customScope?.map(item => item.id) ?? []);
  //   setScopeValues([...Object.keys(scopeInfo)]);
  // }, [orgId]);

  const isAddCardNeedToShow = () => {
    if (Object.keys(scopeData).length && lastOrgId == null) {
      return (
        (needsOrgScopes && !needsCustomScopes) ||
        (needsCustomScopes && hasAllCustomScopes(scopeData))
      );
    } else {
      return (
        (needsOrgScopes && !needsCustomScopes) ||
        (needsCustomScopes && !Object.keys(scopeInfo).length) ||
        (Object.keys(scopeInfo).length &&
          !scopeInfo.hasOwnProperty(lastOrgId)) ||
        (Object.keys(scopeInfo).length &&
          needsCustomScopes &&
          lastOrgId &&
          scopeInfo[lastOrgId]?.hasOwnProperty("customScope") &&
          scopeInfo[lastOrgId]?.customScope.length)
      );
    }
  };

  const removeOrgScope = (id, isModal = false) => {
    setReset(false);
    if (
      !isModal &&
      Object.keys(scopeInfo[id]).length &&
      scopeInfo[id]?.hasOwnProperty("customScope") &&
      scopeInfo[id]?.customScope.length
    ) {
      setDeletePopup(true);
      setOrgId(id);
    } else {
      setDeletePopup(false);
      const scopeValuesTochange = { ...scopeInfo };
      // Delete the property with the specified ID
      delete scopeValuesTochange[id];
      // Update the state with the modified data
      setScopeInfo(scopeValuesTochange);

      setScopeValues((prevState) => prevState.filter((org) => org.id !== id));
    }
  };

  const removeCustomScope = (orgid, id) => {
    setReset(false);
    // Create a copy of the data object to avoid mutation
    const scopeValuesTochange = { ...scopeInfo };
    // Check if the ID exists in the data
    if (scopeValuesTochange[orgid]) {
      // Update the userRoles array for the specific ID
      scopeValuesTochange[orgid].customScope = scopeValuesTochange[
        orgid
      ].customScope.filter((scope) => scope.id !== id);
      // Update the state with the modified data
      if (scopeValuesTochange[orgid].customScope.length === 0) {
        delete scopeValuesTochange[orgid];
      }
      setScopeInfo(scopeValuesTochange);
      setScopeValues((prevState) => prevState.filter((org) => org.id !== id));
    }
  };

  const hasMissingCustomScope = () => {
    return !Object.values(scopeInfo).every(
      (entry) =>
        Array.isArray(entry.customScope) && entry.customScope.length > 0
    );
  };

  const closehandler = () => {
    setDeletePopup(false);
  };

  const isSucess = () => {
    if (needsOrgScopes && needsCustomScopes == false) {
      return Object.keys(scopeInfo).length;
    } else if (needsOrgScopes && needsCustomScopes) {
      return Object.keys(scopeInfo).length && !hasMissingCustomScope();
    } else if (needsCustomScopes) {
      return Object.keys(scopeInfo).length && !hasMissingCustomScope();
    }
  };

  useEffect(() => {
    if (!reset || isFromDeeplink) {
      if (isSucess()) {
        changeValue({ scopeData: { data: scopeInfo, isValidated: true } });
      } else {
        changeValue({ scopeData: { data: scopeInfo, isValidated: false } });
      }
    }
  }, [scopeInfo, isFromDeeplink]);

  return (
    <div key={id}>
      <OrgScopeContainer>
        <OrgHeaderContainer>
          <OrgscopeHeader>
            {getHeadValue(needsOrgScopes, needsCustomScopes).text}
          </OrgscopeHeader>
          <StatusRoleConfig status={isSucess() ? "success" : "failed"} />
        </OrgHeaderContainer>
        <OrganizationWrapper>
          {isAddCardNeedToShow() && !isExtend ? (
            <OrgAdditionCard
              icon={getHeadValue(needsOrgScopes, needsCustomScopes).icon}
              text={getHeadValue(needsOrgScopes, needsCustomScopes).iconText}
              onClick={OpenModal}
            />
          ) : (
            <></>
          )}
          <OrganizationWrapperContentWrapper
            isGreyedout={needsCustomScopes && needsOrgScopes ? true : false}
          >
            {needsOrgScopes &&
              Object.entries(scopeInfo).map(([id, obj]) => (
                <ScopeWrapper
                  isGreyedout={
                    needsCustomScopes && needsOrgScopes ? true : false
                  }
                >
                  <OrgScopeCard
                    name={obj.name}
                    icon={
                      <AliceIcon
                        size={16}
                        iconType={AliceIconType.UIDate_From}
                      />
                    }
                    actionHandler={() => removeOrgScope(id)}
                    isWithCustomScope={needsCustomScopes ? true : false}
                    isActionVisible={!isExtend}
                  />
                  {needsCustomScopes && (
                    <CustomScopeContentWrapper>
                      {obj?.customScope?.map((scope) => (
                        <OrgScopeCard
                          name={scope?.name}
                          icon={
                            <AliceIcon
                              size={16}
                              iconType={AliceIconType.CUSTOM_SCOPE}
                            />
                          }
                          isWithCustomScope={true}
                          actionHandler={() => removeCustomScope(id, scope.id)}
                          isActionVisible={!isExtend}
                        />
                      ))}
                      {!isExtend && (
                        <OrgAdditionCard
                          icon={
                            <AliceIcon
                              size={16}
                              iconType={AliceIconType.CUSTOM_SCOPE}
                            />
                          }
                          text={t("add-cust")}
                          onClick={(e) => OpenModal(e, true, id)}
                        />
                      )}
                    </CustomScopeContentWrapper>
                  )}
                </ScopeWrapper>
              ))}
            {needsOrgScopes === false &&
            needsCustomScopes &&
            Object.keys(scopeInfo).length &&
            scopeInfo[0]?.hasOwnProperty("customScope") ? (
              scopeInfo[0]?.customScope.map((scope) => (
                <ScopeWrapper isGreyedout={false}>
                  <OrgScopeCard
                    name={scope.name}
                    icon={
                      <AliceIcon
                        size={16}
                        iconType={AliceIconType.CUSTOM_SCOPE}
                      />
                    }
                    actionHandler={() => removeCustomScope(0, scope.id)}
                    isActionVisible={!isExtend}
                  />
                </ScopeWrapper>
              ))
            ) : (
              <></>
            )}
          </OrganizationWrapperContentWrapper>
        </OrganizationWrapper>
      </OrgScopeContainer>
      {needsCustomScopes ? (
        <CustOrgSearchDialogue
          isOpen={isShowCustOrg}
          onCancel={closeModal}
          onSave={setCustomScope}
          preselectedOrg={custScopeValues}
          data={customScopesWithIds}
        />
      ) : (
        <></>
      )}
      {needsOrgScopes ? (
        <OrgSearchDialogue
          isOpen={isShowOrgsearch}
          onCancel={closeModal}
          onSave={setOrganisationScope}
          preselectedOrg={!needsCustomScopes ? scopeValues : []}
          isNeedCustomScope={needsCustomScopes}
          disabledIds={needsCustomScopes ? [...Object.keys(scopeInfo)] : []}
          roleId={id}
          userId={userid}
          recentlyUsedData={combinedData}
        />
      ) : (
        <></>
      )}
      <OrgDeletePopup
        isOpen={deletePopup}
        data={scopeInfo[orgId]}
        handleClose={() => closehandler()}
        handleDelete={(id) => removeOrgScope(id, true)}
      />
    </div>
  );
};

export default OrganizationCustomScopeContainer;
