import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box } from '@mui/material'
import { useLottie } from 'lottie-react'
import PropTypes from 'prop-types'
import checkmarkAnimation from '../../../_assets/animation/CheckMark.json'
import { AnimationDirection, AnimationSpeed } from '../../../constants'
import { useCopyToClipboard } from '../../../hooks'
import { mergeSxProps } from '../../../util'
import { Tooltip } from '../../message/tooltip/Tooltip' // don't shorten
import { IconButton } from '../iconButton/IconButton'

const AnimationLength = 2000

export const CopyButton = ({
  text,
  tooltip,
  resetTime = 5000,
  sx,
  ...otherProps
}) => {
  const { copyToClipboard, isCopied } = useCopyToClipboard({
    resetTime,
    isNotifyingOnSuccess: false
  })

  const {
    View: LottieView,
    play: lottiePlay,
    setSpeed: lottieSetSpeed,
    setDirection: lottieSetDirection
  } = useLottie(
    {
      animationData: checkmarkAnimation,
      loop: false,
      autoplay: false
    },
    {
      width: '28px',
      height: '28px',
      display: isCopied && resetTime > 0 ? undefined : 'none'
    }
  )

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    copyToClipboard(text)

    lottieSetDirection(AnimationDirection.FORWARD)
    lottieSetSpeed(AnimationSpeed.NORMAL)

    lottiePlay()

    setTimeout(() => {
      lottieSetDirection(AnimationDirection.REVERSED)
      lottieSetSpeed(AnimationSpeed.NORMAL)

      lottiePlay()
    }, resetTime - AnimationLength)
  }

  return (
    <Box
      sx={mergeSxProps(
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        sx
      )}
      {...otherProps}
    >
      <Tooltip title={tooltip} disabled={!tooltip || isCopied} arrow>
        { /* PropTypes defined, only support single ReactElement */ }
        <Box>
          { /* For some reason lottie does NOT render when not permanently loaded into DOM */ }
          { LottieView }

          <IconButton
            size="small"
            color="primary"
            sx={{ display: !isCopied || resetTime <= 0 ? undefined : 'none' }}
            onClick={handleClick}
          >
            <ContentCopyIcon fontSize="28px" />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}

CopyButton.propTypes = {
  /**
   * The text to be copied when the button is clicked.
   *
   * Note: Since browsers only allow strings to be copied to the clipboard,
   * please ensure that a string is provided here.
   */
  text: PropTypes.string.isRequired,
  /** Optional tooltip text to display when hovering over the copy icon */
  tooltip: PropTypes.string,
  /**
   * The time in milliseconds until the animation resets and the content becomes copyable again.
   *
   * Note: Animation is 2000ms long. Therefor `resetTime` should be above 2x animation length (4000ms).
   */
  resetTime: PropTypes.number,
  /** Styling */
  sx: PropTypes.any
}
