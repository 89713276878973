import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { MarketRoleAssignerAssignmentDialog } from '../assignment/MarketRoleAssignerAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'id'
const COLUMNS = [ 'roleName', 'roleId', 'marketCountryCode', 'marketName' ]

const convertIdToApiObject = (id) => {
  const splitId = id.split(';')

  return {
    marketCountryCode: splitId[0],
    roleId: splitId[1]
  }
}

export const MarketRoleAssignerAccordion = ({
  userId,
  isAssigned,
  administratedRoles,
  onChange = (newRole) => {}
}) => {
  administratedRoles = administratedRoles.map((role) => ({
    ...role,
    id: `${role.marketCountryCode};${role.roleId}`
  }))

  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedRoles, setSelectedRoles ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'marketroleassigner',
        body: { marketRoleAssignerPrivileges: selectedRoles.map(convertIdToApiObject) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.organizationRoleAssigner') }),
      onRequestSuccess: () => {
        const newAdministratedRoles = administratedRoles
          .filter((administrated) => selectedRoles
            .map(convertIdToApiObject)
            .every(({ roleId, marketCountryCode }) => !(administrated.roleId === roleId && administrated.marketCountryCode === marketCountryCode)))

        const marketRoleAssigner = { admin: false }

        if (newAdministratedRoles.length) {
          marketRoleAssigner.admin = true
          marketRoleAssigner.administratedRoles = newAdministratedRoles
        }

        handleDeselectAll()
        onChange({ marketRoleAssigner })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'roleName':
        return t('general.roleName')
      case 'roleId':
        return t('general.roleId')
      case 'marketCountryCode':
        return t('general.countryCode')
      case 'marketName':
        return t('general.market')
      default:
        return key
    }
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedRoles(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="MarketRoleAssignerAccordion"
        title={t('adminRoles.marketRoleAssigner')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {administratedRoles.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={administratedRoles}
            titleRenderer={titleRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                isDeleteDisabled={!selectedRoles.length}
                isDeleteLoading={isLoadingDelete}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <MarketRoleAssignerAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

MarketRoleAssignerAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedRoles: PropTypes.array,
  onChange: PropTypes.func
}
