import { styled } from "@mui/system";

export const OrgDialogueCardContainer = styled("div")({
  display: "flex",
  padding: "8px 8px 8px 16px",
  alignItems: "center",
  gap: "16px",
  borderRadius: "4px",
  background: "#F8F8F8",
  "& .text": {
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.87)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px" /* 150% */,
    paddingLeft: "8px",
    width: "80%",
  },
  "& .icon": {
    borderLeft: "1px solid #DBDBDB",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    cursor: "pointer",
    "& svg": {
      color: "#0078D6",
    },
  },
});
