import React from "react";
import { useTranslation } from "react-i18next";
import { ContentCopy } from "@mui/icons-material";
import StatusRoleConfig from "@components/StatusRoleConfiguration";
import { AttachmentConatiner } from "@src/pages/newRequest/styledComponents";
import AttachmentDropzone from "./EditscreenDropZone";

const AttachmentComponent = ({ isShow, fileChange }) => {
  const { t } = useTranslation();

  return (
    <AttachmentConatiner>
      <div className="header-container">
        <span className="title">{t("attachments")}</span>
        <StatusRoleConfig />
      </div>
      <div className="body-conatiner">
        <div className="contents">
          <div className="sub-text">Additional Information</div>
          <div className="content-text">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et.
          </div>
        </div>
        <div className="uploader">
          <div className="sub-text">Additional Information</div>
          <a href="">
            www.applicationname.com/templatefile{" "}
            <ContentCopy sx={{ color: "#0078D6" }} />
          </a>
        </div>
        <AttachmentDropzone />
      </div>
    </AttachmentConatiner>
  );
};

export default AttachmentComponent;
