import React from 'react'
import { createContext, useState } from 'react'
import { NotificationStack } from './NotificationStack'

const MAX_NOTIFICATIONS = 5

export const NotificationContext = createContext({ notify: () => {} })

export const NotificationProvider = ({ children }) => {
  const [ notifications, setNotifications ] = useState([])

  const notify = ({
    key = Date.now(),
    severity = 'info',
    duration = 4000,
    message = '',
    variant = 'standard'
  }) => {
    setNotifications((prev) => {
      if (prev.find((notification) => notification.key === key)) return prev

      return [ {
        key,
        severity,
        duration,
        message,
        variant
      }, ...prev.length < MAX_NOTIFICATIONS
        ? prev
        : prev.slice(0, -1)
      ]
    })
  }

  const handleClose = (key) => setNotifications((prev) => prev.filter((notification) => notification.key !== key))

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}

      <NotificationStack notifications={notifications} onClose={handleClose} />
    </NotificationContext.Provider>
  )
}
