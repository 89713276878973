import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserById,
  fetchUserAssignableRoleById,
  fetchUserAssignedRoleById,
  fetchUserAssignableOrgScopeById,
} from "../sliceActions/UserActions";

const initialState = {
  userById: {
    data: {},
    loading: false,
    error: null,
  },
  userAssignableRoleById: {
    data: {},
    loading: false,
    error: null,
  },
  userAssignedRoleById: {
    data: {},
    loading: false,
    error: null,
  },
  userAssignableOrgScope: {
    data: {},
    loading: false,
    error: null,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.userById.loading = true;
        state.userById.error = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.userById.loading = false;
        state.userById.data = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.userById.loading = false;
        state.userById.error = action.error.message;
      })
      .addCase(fetchUserAssignableRoleById.pending, (state) => {
        state.userAssignableRoleById.loading = true;
        state.userAssignableRoleById.error = null;
      })
      .addCase(fetchUserAssignableRoleById.fulfilled, (state, action) => {
        state.userAssignableRoleById.loading = false;
        state.userAssignableRoleById.data = action.payload;
      })
      .addCase(fetchUserAssignableRoleById.rejected, (state, action) => {
        state.userAssignableRoleById.loading = false;
        state.userAssignableRoleById.error = action.error.message;
      })
      .addCase(fetchUserAssignedRoleById.pending, (state) => {
        state.userAssignedRoleById.loading = true;
        state.userAssignedRoleById.error = null;
      })
      .addCase(fetchUserAssignedRoleById.fulfilled, (state, action) => {
        state.userAssignedRoleById.loading = false;
        state.userAssignedRoleById.data = action.payload;
      })
      .addCase(fetchUserAssignedRoleById.rejected, (state, action) => {
        state.userAssignedRoleById.loading = false;
        state.userAssignedRoleById.error = action.error.message;
      })
      .addCase(fetchUserAssignableOrgScopeById.pending, (state) => {
        state.userAssignableOrgScope.loading = true;
        state.userAssignableOrgScope.error = null;
      })
      .addCase(fetchUserAssignableOrgScopeById.fulfilled, (state, action) => {
        state.userAssignableOrgScope.loading = false;
        state.userAssignableOrgScope.data = action.payload;
      })
      .addCase(fetchUserAssignableOrgScopeById.rejected, (state, action) => {
        state.userAssignableOrgScope.loading = false;
        state.userAssignableOrgScope.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
