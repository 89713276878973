import { createContext, useMemo } from 'react'
import { CacheKey, CacheTTL, CacheStorage, UsersService } from '../../data'
import { useApi } from '../api/useApi'
import { useAgreements, useUser } from '../user'

export const OpenTasksProviderContext = createContext({})

export const OpenTasksProvider = ({ children }) => {
  const { user } = useUser()
  const { hasAcceptedAgreements } = useAgreements()

  const {
    isLoading: isLoadingOpenTasksCount,
    response: responseOpenTasksCount,
    mutate: mutateOpenTasksCount,
    isFailure: isFailureOpenTasksCount,
    status: statusOpenTasksCount,
    execute: executeOpenTasksCount
  } = useApi(
    UsersService.getOpenTasksCount,
    {
      config: { id: user?.id },
      isRequestingInitially: false,
      isRequestingOnTruthy: user?.id && hasAcceptedAgreements,
      isCaching: true,
      cacheStorage: CacheStorage.localStorage,
      cacheTTL: CacheTTL.fiveMinutes,
      cacheKey: `${CacheKey.openTasks}.${user?.id}`
    }
  )

  return (
    <OpenTasksProviderContext.Provider
      value={useMemo(() => ({
        openTasksCount: responseOpenTasksCount,
        isLoadingOpenTasksCount,
        mutateOpenTasksCount,
        isFailureOpenTasksCount,
        statusOpenTasksCount,
        executeOpenTasksCount
      }), [
        executeOpenTasksCount,
        isFailureOpenTasksCount,
        isLoadingOpenTasksCount,
        mutateOpenTasksCount,
        responseOpenTasksCount,
        statusOpenTasksCount
      ])}
    >
      {children}
    </OpenTasksProviderContext.Provider>
  )
}
