import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

/**
 * For more information see: https://mui.com/material-ui/api/alert/
 */
export const Alert = forwardRef(({
  title,
  severity = 'success',
  variant = 'standard',
  children,
  onClose,
  sx = [],
  ...otherProps
}, ref) => (
  <MuiAlert
    data-testid="Alert"
    ref={ref}
    sx={mergeSxProps(
      {
        borderRadius: '4px',
        '.MuiAlert-icon': { fontSize: 24 },
        '.MuiAlert-action': { marginRight: '5px' }
      },
      sx
    )}
    severity={severity}
    variant={variant}
    onClose={onClose}
    {...otherProps}
  >
    { title && <MuiAlertTitle>{title}</MuiAlertTitle> }
    { children }
  </MuiAlert>
))

Alert.propTypes = {
  /** Alert title */
  title: PropTypes.string,
  /** Variant of the alert. */
  variant: PropTypes.oneOf([ 'standard', 'outlined', 'filled' ]),
  /**
   * The severity of the alert.<br>
   * This defines the color and icon used.
   */
  severity: PropTypes.oneOf([ 'success', 'error', 'warning', 'info' ]),
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
