import { Box, Link, Stack, Tooltip, Typography } from '@mui/material'
import { getLink } from '../../util'
import { AliceIcon, AliceIconType, CountryFlag } from '../icon'
import { FocusDot } from '../projectFocus'
import { OrgContextPopover } from './OrgContext/OrgContextPopover'

const validGroupTypes = [ 0, 1, '0', '1', 'S' ]

export const orgTableDefaultVisibleColumns = [
  'orgForm',
  'name',
  'id',
  'groupType',
  'community',
  'address',
  'country',
  'status'
]

// This should in theory return relatively early.
// And we should just have 50 Elements max. because of the ajax table
// Problems with using [0] | [1] directly:
//   - [1] would lead to an exception if there is just one column
//   - [0] would lead to an exception if in compact version
export const getOrgTableRowId = (rowData) => {
  const values = Object.values(rowData)
  for (let i = 0; i < values.length; i++) {
    if (values[i] && values[i].id) return values[i].id
  }

  // Adding a fallback
  console.warn('Please select at least on visible column with required data ("ID" or "Name")')
  return '0'
}

export const getOrgTableColumnDefinitions = ({
  t,
  compact,
  visibleColumns,
  localUserHasAnAdminRole,
  isIDLink
}) => {
  const addressCell = {
    Header: t('general.address'),
    key: 'address',
    disableSortBy: true,
    Cell: ({ value: org }) => {
      const address = [ org.street, org.postalCode, org.location ]
        .join('')
        .split('')

      return address.length ? (
        <Stack>
          {org.street && org.street.length ? (
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {org.street}
            </Typography>
          ) : (
            <Typography>-</Typography>
          )}

          {org.postalCode || org.location ? (
            <Stack direction="row" gap={1}>
              {org.postalCode && org.postalCode.length && (
                <Typography>{org.postalCode}</Typography>
              )}

              {org.location && org.location.length && (
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {org.location}
                </Typography>
              )}
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}
        </Stack>
      ) : (
        <Typography>-</Typography>
      )
    }
  }

  if (compact) {
    return [
      {
        Header: '',
        key: 'orgForm',
        style: { width: '50px' },
        disableSortBy: true,
        Cell: ({ value }) => (
          <Tooltip title={value} arrow>
            <Box>
              <AliceIcon
                iconType={AliceIconType[`ORG_${value}`]}
                viewBox="0 0 36 36"
              />
            </Box>
          </Tooltip>
        )
      },
      {
        Header: t('general.name'),
        key: 'name',
        Cell: ({ value: org }) => (
          <OrgContextPopover organizationId={org.id}>
            <Stack>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {org.name}
              </Typography>

              <Stack
                direction="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
                gap={1}
              >
                {isIDLink ? (
                  <Link
                    href={
                      localUserHasAnAdminRole
                        ? getLink('orgDetails', { orgId: org.id })
                        : null
                    }
                    underline="none"
                  >
                    <Typography>{org.id}</Typography>
                  </Link>
                ) : (
                  <Typography>{org.id}</Typography>
                )}

                {validGroupTypes.includes(org.orgGroupType) && (
                  <FocusDot groupType={org.orgGroupType} />
                )}
              </Stack>
            </Stack>
          </OrgContextPopover>
        )
      },
      addressCell,
      {
        Header: '',
        key: 'community',
        style: { width: '60px' },
        disableSortBy: true,
        Cell: ({ value }) => (
          <Tooltip title={t(`orgCommunity.${value}`)} arrow>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <AliceIcon
                iconType={AliceIconType[`ORG_${value}`]}
                viewBox="0 0 36 36"
              />
            </Box>
          </Tooltip>
        )
      },
      {
        Header: '',
        key: 'country',
        style: { width: '65px' },
        Cell: ({ value }) => (value ? (
          <Tooltip title={value} arrow>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CountryFlag countryCode={value} border />
            </Box>
          </Tooltip>
        ) : (
          <Typography>-</Typography>
        ))
      },
      {
        Header: '',
        key: 'status',
        style: { width: '60px' },
        disableSortBy: true,
        Cell: ({
          row,
          value
        }) => {
          const org = row.values.col0

          return value ? (
            <Tooltip
              title={
                org.manuallyActivated
                  ? t('status.MANUAL_ACTIVATED')
                  : t('status.ACTIVE')
              }
              arrow
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
              >
                <AliceIcon iconType={AliceIconType.ACTIVE} />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title={t('status.INACTIVE')} arrow>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
              >
                <AliceIcon iconType={AliceIconType.INACTIVE} />
              </Box>
            </Tooltip>
          )
        }
      }
    ]
  }

  const columns = []

  if (visibleColumns.includes('orgForm')) {
    columns.push({
      Header: '',
      key: 'orgForm',
      style: {
        width: '50px',
        padding: '0'
      },
      Cell: ({ value }) => (
        <Tooltip title={value} arrow>
          <Box>
            <AliceIcon
              iconType={AliceIconType[`ORG_${value}`]}
              viewBox="0 0 36 36"
            />
          </Box>
        </Tooltip>
      )
    })
  }

  if (visibleColumns.includes('id')) {
    columns.push({
      Header: t('general.id'),
      key: 'id',
      style: { width: '200px' },
      Cell: ({ value }) => (
        <Tooltip title={value.id} arrow>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {value.outletId || value.externalId || value.id}
          </Typography>
        </Tooltip>
      )
    })
  }

  if (visibleColumns.includes('name')) {
    columns.push({
      Header: t('general.name'),
      key: 'data',
      Cell: ({ value: org }) => (
        <OrgContextPopover organizationId={org.id}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {org.name}
          </Typography>
        </OrgContextPopover>
      )
    })
  }

  if (visibleColumns.includes('address')) {
    columns.push(addressCell)
  }

  if (visibleColumns.includes('city')) {
    columns.push({
      Header: t('general.city'),
      key: 'city',
      Cell: ({ value: org }) => {
        const locationWithCode = [ org.postalCode, org.location ]

        return org.location ? (
          <Tooltip
            arrow
            title={(
              <Stack>
                <Box>
                  {org.street && org.street.length > 0 ? org.street : '-'}
                </Box>

                {org.postalCode || org.location
                  ? locationWithCode.join(' ')
                  : '-'}
              </Stack>
            )}
          >
            <Typography>{org.location}</Typography>
          </Tooltip>
        ) : (
          <Typography>-</Typography>
        )
      }
    })
  }

  if (visibleColumns.includes('groupType')) {
    columns.push({
      Header: t('general.groupType'),
      key: 'groupType',
      style: { width: '80px' },
      Cell: ({ value }) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {validGroupTypes.includes(value) ? (
            <FocusDot groupType={value} />
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      )
    })
  }

  if (visibleColumns.includes('community')) {
    columns.push({
      Header: t('general.community'),
      key: 'community',
      style: { width: '120px' },
      Cell: ({ value }) => (
        <Tooltip title={t(`orgCommunity.${value}`)} arrow>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <AliceIcon
              iconType={AliceIconType[`ORG_${value}`]}
              viewBox="0 0 36 36"
            />
          </Box>
        </Tooltip>
      )
    })
  }

  if (visibleColumns.includes('country')) {
    columns.push({
      Header: t('general.country'),
      key: 'country',
      style: { width: '90px' },
      Cell: ({ value }) => (value ? (
        <Tooltip title={value}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CountryFlag countryCode={value} border />
          </Box>
        </Tooltip>
      ) : (
        <Typography>-</Typography>
      ))
    })
  }

  if (visibleColumns.includes('status')) {
    columns.push({
      Header: t('general.status'),
      key: 'status',
      style: { width: '80px' },
      Cell: ({ value: org }) => (org.isActive ? (
        <Tooltip
          title={
            org.manuallyActivated
              ? t('status.MANUAL_ACTIVATED')
              : t('status.ACTIVE')
          }
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
          >
            <AliceIcon iconType={AliceIconType.ACTIVE} />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t('status.INACTIVE')}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
          >
            <AliceIcon iconType={AliceIconType.INACTIVE} />
          </Box>
        </Tooltip>
      ))
    })
  }

  return columns
}

export const convertToOrgTableData = ({
  tableData,
  compact,
  visibleColumns
}) => tableData.map((org) => {
  const dataRow = compact ? [
    org.orgForm,
    org,
    {
      street: org.street,
      postalCode: org.postalCode,
      location: org.location
    },
    org.communities[0],
    org.country,
    org.isActive
  ] : []

  if (visibleColumns.includes('orgForm')) {
    dataRow.push(org.orgForm)
  }

  if (visibleColumns.includes('id')) {
    dataRow.push({
      id: org.id,
      externalId: org.externalId,
      outletId: org?.gssnData?.outletId
    })
  }

  if (visibleColumns.includes('name')) {
    dataRow.push(org)
  }

  if (visibleColumns.includes('address')) {
    dataRow.push({
      street: org.street,
      postalCode: org.postalCode,
      location: org.location
    })
  }

  if (visibleColumns.includes('city')) {
    dataRow.push({
      location: org.location,
      postalCode: org.postalCode
    })
  }

  if (visibleColumns.includes('groupType')) {
    dataRow.push(org.orgGroupType)
  }

  if (visibleColumns.includes('community')) {
    dataRow.push(org.communities[0])
  }

  if (visibleColumns.includes('country')) {
    dataRow.push(org.country)
  }

  if (visibleColumns.includes('status')) {
    dataRow.push({
      isActive: org.isActive,
      manuallyActivated: org.manuallyActivated
    })
  }

  return dataRow
})
