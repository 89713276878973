import { styled } from "@mui/system";

export const CardContainer = styled("div")(({ isSelected }) => ({
  display: "flex",
  padding: "16px",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  borderBottom: "1px solid var(--others-divider, #DBDBDB)",
  background: isSelected ? "rgba(0, 120, 214, 0.16)" : "#FFF",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",
}));

export const HeaderContainer = styled("div")({
  display: "flex",
});

export const DetailContainer = styled("div")({
  display: "flex",
  marginLeft: "16px",
  flexDirection: "column",
});

export const IdContainer = styled("div")({
  display: "flex",
  width: "24px",
  height: "24px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
  background:
    "var(--others-chips-filled-default-background, rgba(0, 0, 0, 0.08))",
  "& span": {
    color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
    textAlign: "center",
    fontFamily: "MB Corpo S Text",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
});

export const MainTitleHeadContainer = styled("div")({
  display: "flex",
});

export const HeadH2 = styled("div")(({ type }) => ({
  overflow: "hidden",
  marginLeft: type == "subhead" ? "0px" : "8px",
  display: "grid",
  textOverflow: "ellipsis",
  flex: "1",
  "& div": {
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "MB Corpo S Text",
    color:
      type == "subhead"
        ? "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))"
        : "var(--primary-main, #0078D6)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    whiteSpace: "nowrap",
  },
}));

export const Iconwrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "24px",
  padding: "2px 8px",
  alignItems: "center",
  gap: "6px",
  borderRadius: "100px",
  border: "1px solid  rgba(0, 0, 0, 0.60)",
  marginRight: "8px",
  "& svg": {
    fontSize: "16px",
  },
  "& span.icon-container": { marginTop: "5px" },
}));

export const IconContainer = styled("div")({
  display: "flex",
  marginTop: "8px",
  "& .vertical-line": {
    width: "1px",
    height: "100%",
    background: "#DBDBDB",
    margin: "3px",
  },
});

export const Count = styled("span")({
  color: "var(--text-primary-enabled, rgba(0, 0, 0, 0.87))",
  fontFamily: "MB Corpo S Text ",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "20px",
});
