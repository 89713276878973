import { useContext, useEffect, useState } from 'react'
import { UsersService } from '../../data'
import { useApi } from '../api/useApi'
import { useApp } from '../app'
import { UserProviderContext } from './UserProvider'

export const useAgreements = () => {
  const {
    user,
    isLoadingAgreements,
    agreements,
    executeAgreements,
    invalidateAgreements,
    acceptTokenAgreements
  } = useContext(UserProviderContext)

  const { setIsMaintenance } = useApp()

  const [ isAccepted, setIsAccepted ] = useState(false)

  useEffect(() => setIsAccepted(!!agreements?.every((agreement) => agreement.isAccepted)), [ agreements ])

  const {
    isLoading: isLoadingAccept,
    execute: executeAccept
  } = useApi(
    UsersService.acceptAgreementsById,
    {
      config: {
        id: user?.id,
        acceptToken: acceptTokenAgreements
      },
      isRequestingInitially: false,
      isNotifyingOnError: true,
      onRequestSuccess: () => {
        invalidateAgreements()
        executeAgreements()
      },
      onRequestError: () => setIsMaintenance(true)
    }
  )

  return {
    isLoadingAgreements,
    isLoadingAgreementsAccept: isLoadingAccept,
    hasAcceptedAgreements: isAccepted,
    agreements,
    acceptAgreements: executeAccept
  }
}
