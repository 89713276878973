import React from "react";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const location = useLocation();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <WrappedComponent
      {...props}
      params={params}
      location={location}
      navigate={navigation}
      searchParams={searchParams}
    />
  );
};

export default withRouter;
