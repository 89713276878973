export const User = {
  preferredLanguage: 'en',
  country: 'DE',
  groupType: 0,
  contractor: false,
  dealerHomeOrgState: 'AUTOMATIC',
  departmentNumber: 'ITP/TSS',
  financialServiceConsultant: false,
  givenname: 'Test',
  homeOrgId: 'E-9953',
  mailAddress: 'does-not-exist@really.com',
  mobileNumber: '+49 176 30907259',
  nationalLanguageGivenname: 'Test',
  nationalLanguageSurname: 'User',
  salesPerson: false,
  surname: 'User',
  workingOrgId: 'E-9953',
  communities: [
    'DAIMLER_EMPLOYEE',
    'DAIMLER_INTERNAL',
    'TEST_USER',
    'TEST_USER__ORG'
  ],
  companyId: '9953',
  completeName: 'TEST USER',
  employeeType: 'Mitarbeiter',
  id: 'TEST_USER',
  isActive: true,
  isSuspended: false,
  managementLevel: '999',
  plant1: '415',
  plant2: '415',
  sourceProcess: 'EWD-S',
  structureId: 'H00803060900099',
  supervisorId: 'JWANDEL'
}
