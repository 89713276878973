/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import LabelValue from "./LabelValue";

import { Skeleton, Table, useUser } from "@alice/component_library";
import { tableData } from "./helper";
import NoEntitlementPage from "./NoEntitlements";

import { Typography, Grid, FormControl } from "@mui/material";
import {
  GetRolesEntitlementData,
  GetUserEntitelments,
} from "../actions/roleActions";

import { SearchBar } from "@alice/component_library";
import { isValid } from "../utils/Validator";
import TableRowsLoader from "./Skeleton/TableRowsLoader";

const DetailLoader = styled(Grid)(({ theme }) => ({
  alignContent: "center",
  justifyContent: "center",
  minWidth: "325px",
  [theme.breakpoints.down("xs")]: {
    minWidth: "100px",
  },
}));

const SubEntitlementContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    overflowX: "auto",
  },
}));

const EntitlementsTable = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entitlementList = useSelector((state) => state.role.entitlements);
  const [entitlements, SetEntitlements] = useState(
    tableData(entitlementList?.data)
  );

  const { user: userUtils } = useUser();

  const width = window.screen.width;
  const [searchKey, setSearhKey] = useState("");

  const { search = false, heading, id } = props;

  useEffect(() => {
    if (isValid(id) && id !== entitlementList.prevKey)
      dispatch(GetRolesEntitlementData(id));
  }, [id]);

  useEffect(() => {
    if (entitlementList?.data)
      SetEntitlements(tableData(entitlementList?.data));
  }, [entitlementList]);

  let headerConfig = [];
  if (width > 375) {
    headerConfig = [
      {
        key: t("entitlementHeaderData.entitlement-name"),
        Header: t("entitlementHeaderData.entitlement-name"),
      },
      {
        key: t("entitlementHeaderData.application"),
        Header: t("entitlementHeaderData.application"),
      },
      {
        key: t("entitlementHeaderData.description"),
        Header: t("entitlementHeaderData.description"),
      },
    ];
  } else {
    headerConfig = [
      {
        key: t("entitlementHeaderData.entitlement-name"),
        Header: t("entitlementHeaderData.entitlement-name"),
      },
    ];
  }

  const applyFilter = (searchKey) => {
    const { appId } = props;
    if (appId)
      dispatch(GetUserEntitelments(userUtils.id, props.appId, searchKey));
    else {
      let filtered = [...entitlementList.data];
      if (searchKey !== "") {
        filtered = filtered.filter(
          (x) =>
            x.entitlementId.toLowerCase().includes(searchKey.toLowerCase()) ||
            x.applicationName.toLowerCase().includes(searchKey.toLowerCase())
        );
      }
      SetEntitlements(tableData(filtered));
    }
    setSearhKey(searchKey);
  };

  const handleChange = (event) => {
    setSearhKey(event.target.value);
  };

  if (entitlementList.loading)
    return (
      <DetailLoader container className="mh-65vh">
        <Skeleton
          height="42px"
          variant="rectangular"
          width="100%"
          sx={{ margin: "20px 0" }}
        />
        <TableRowsLoader columnCount={3} rowsNum={5} />
      </DetailLoader>
    );

  const getSearch = () => (
    <div className="Search_bar" style={{ width: "100%" }}>
      <FormControl
        variant="filled"
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <SearchBar
          label={
            width >= 1024
              ? t("searchbar_label_entitlement")
              : t("search_in_table")
          }
          onChange={handleChange}
          value={searchKey}
          onSearch={(keyword) => applyFilter(keyword)}
          onClear={() => applyFilter("")}
        />
      </FormControl>
    </div>
  );

  const getLabel = () => {
    const { headerType } = props;
    let header = (
      <LabelValue value={t(heading)} valueStyles={{ padding: "10px 0" }} />
    );
    if (headerType)
      header = (
        <LabelValue label={t(heading)} labelStyles={{ padding: "10px 0" }} />
      );
    return <Typography>{header}</Typography>;
  };

  return (
    <Grid container>
      {getLabel()}
      <Grid
        container
        style={{ justifyContent: "space-between", marginBottom: "5px" }}
      >
        {search && getSearch()}
      </Grid>
      <SubEntitlementContainer item xs={12} sm={12} md={12} lg={12}>
        {entitlements.length ? (
          <Table
            columns={headerConfig}
            data={entitlements}
            itemsPerPage={10}
            infoText={{
              single: t("entitlement-shown"),
              multiple: t("entitlements-shown"),
            }}
          />
        ) : (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              paddingTop: "20px",
            }}
          >
            <NoEntitlementPage />
          </div>
        )}
      </SubEntitlementContainer>
    </Grid>
  );
};

EntitlementsTable.propTypes = {};

export default EntitlementsTable;
