import { ArrowUpwardRounded, StarHalf } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AliceIconPng, AliceLogoPng } from '../../_assets'
import { useUser } from '../../hooks'
import { mergeSxProps, user as UserUtils } from '../../util'
import { Button, CloseButton } from '../button'
import { Dialog } from '../dialog'

/**
 * This component is a fancy link to an internal review page<br>
 * <b>Note: If you remove comp -> reload !</b>
 */
export const RateAlice = ({ sx, isDemo = false }) => {
  const { t } = useTranslation('componentLibrary')
  const { user } = useUser()

  const isDaimlerEmployee = UserUtils.is.daimlerEmployee(user?.communities) || isDemo

  const [ isRemoved, setIsRemoved ] = useState(window.sessionStorage.getItem('aliceReview') === 'removed' || window.localStorage.getItem('aliceReview') === 'removed')
  const [ isActive, setIsActive ] = useState(false)

  const [ isDialogOpen, setIsDialogOpen ] = useState(false)

  const onClose = (e) => {
    e.stopPropagation()
    setIsDialogOpen(true)
  }

  return !isRemoved && isDaimlerEmployee && (
    <Box
      data-testid="RateAlice"
      sx={mergeSxProps([
        {
          '--time': '300ms',
          '--iconSize': '40px',
          '--titleOffset': '68px',
          '--origin': 'var(--titleOffset) - (var(--iconSize) / 2)',
          position: 'relative',
          border: '1px solid',
          borderColor: 'grey.15',
          backgroundColor: 'white',
          width: '40px',
          height: '136px',
          transition: 'width calc(var(--time) * 2) var(--time) ease-in-out',
          userSelect: 'none',
          zIndex: 10,
          '.RateAliceIcons': {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '-1px',
            right: '-1px',
            border: '1px solid',
            borderColor: 'grey.15',
            width: 'var(--iconSize)',
            height: 'var(--iconSize)',
            transition: 'border-color var(--time) var(--time)',
            '&Close, &Logo': {
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 1,
              width: '100%',
              height: '100%',
              transition: 'opacity 0ms var(--time)',
              cursor: 'pointer'
            },
            '&Close': {
              width: '32px',
              height: '32px',
              margin: '4px',
              opacity: 0,
              zIndex: 0,
              minWidth: 'unset'
            },
            '&Logo': {
              opacity: 1,
              zIndex: 1
            },
            '&Rate': {
              position: 'absolute',
              bottom: 0,
              right: 0,
              padding: '3px',
              width: '50%',
              height: '50%',
              background: 'white',
              borderRadius: '100px',
              transition: 'width var(--time) var(--time), height var(--time) var(--time), right var(--time)',
              zIndex: 2,
              cursor: 'pointer'
            }
          },
          '.RateAliceTitle': {
            display: 'flex',
            gap: '3px',
            position: 'absolute',
            top: 0,
            right: 0,
            marginBlock: 1,
            marginRight: 'var(--titleOffset)',
            transform: 'rotate(-90deg)',
            transformOrigin: 'calc(100% + var(--titleOffset) - var(--iconSize) / 2) center',
            transition: 'transform var(--time) calc(var(--time) * 2), margin var(--time) var(--time)',
            content: 'var(--origin)',
            cursor: 'pointer',
            '&Plus': {
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              left: '100%',
              whiteSpace: 'nowrap',
              maxWidth: 0,
              transition: 'max-width var(--time)',
              overflow: 'hidden',
              marginLeft: '6px'
            }
          },
          '.RateAliceLogo': {
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: 0,
            overflow: 'hidden',
            position: 'absolute',
            top: '42px',
            right: '8px',
            transition: 'max-width calc(var(--time) * 2) var(--time)',
            height: '82.5px',
            '&Img': { width: '300px' }
          },
          '.RateAliceClickMe': {
            display: 'flex',
            alignItems: 'flex-end',
            gap: 0.5,
            position: 'absolute',
            top: '100%',
            left: '24px',
            paddingInline: 1,
            paddingBlock: 0,
            borderInline: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'transparent',
            maxHeight: 0,
            overflow: 'hidden',
            transition: 'max-height var(--time), padding var(--time), border-color 0ms var(--time), background-color 0ms var(--time)'
          }
        },
        isActive && {
          width: '318px',
          transition: 'width var(--time)',
          '.RateAliceIcons': {
            borderColor: 'transparent',
            transition: 'border-color var(--time)',
            '&Close, &Logo': { transition: 'opacity 0ms var(--time)' },
            '&Close': { opacity: 1, zIndex: 1 },
            '&Logo': { opacity: 0, zIndex: 0 },
            '&Rate': {
              right: '278px',
              width: '100%',
              height: '100%',
              transition: 'width var(--time), height var(--time), right calc(var(--time) * 1.5) var(--time)'
            }
          },
          '.RateAliceTitle': {
            transform: 'rotate(0)',
            marginRight: '242px',
            transition: 'transform var(--time), margin var(--time) var(--time)',
            '&Plus': { maxWidth: '41px', transition: 'max-width var(--time) calc(var(--time) * 3)' }
          },
          '.RateAliceLogo': {
            maxWidth: '300px',
            transition: 'max-width calc(var(--time) * 2) var(--time)'
          },
          '.RateAliceClickMe': {
            maxHeight: '36px',
            paddingBottom: 0.5,
            borderColor: 'grey.15',
            backgroundColor: 'white',
            transition: 'max-height var(--time) calc(var(--time) * 3), padding var(--time) calc(var(--time) * 3), border-color 0ms calc(var(--time) * 3), background-color 0ms calc(var(--time) * 3)'
          }
        }
      ], sx)}
    >
      <Box className="RateAliceIcons" onClick={() => setIsActive(!isActive)}>
        <CloseButton className="RateAliceIconsClose" onClick={onClose} />
        <img src={AliceIconPng} className="RateAliceIconsLogo" alt="Text" />
        <StarHalf className="RateAliceIconsRate" />
      </Box>

      <Box className="RateAliceTitle" onClick={() => setIsActive(!isActive)}>
        Rate
        <span className="RateAliceTitlePlus">Alice</span>
      </Box>

      <div className="RateAliceLogo">
        <a
          href="https://application-rating.e.corpintra.net/rating/rate.php?app=74988E00-074A-4F12-9372-1600658C04A3"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            setIsRemoved(true)
            window.localStorage.setItem('aliceReview', 'removed')
          }}
        >
          <img className="RateAliceLogoImg" src={AliceLogoPng} alt="ALice Logo" />
        </a>
      </div>

      <div className="RateAliceClickMe">
        Click me
        <ArrowUpwardRounded />
      </div>

      <Dialog
        headerSection="Remove Review Box"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        sx={{
          '.DialogContent': {
            display: 'flex',
            flexFlow: 'column',
            gap: 2
          }
        }}
      >
        <Stack gap={1}>
          <Button
            onClick={() => {
              setIsRemoved(true)
              window.sessionStorage.setItem('aliceReview', 'removed')
            }}
          >
            {t('rateAlice.removeSessionBtn')}
          </Button>

          <div>
            <Typography>{t('rateAlice.removeSession')}</Typography>
            <Typography variant="caption" color="grey.45">{t('rateAlice.removeSessionHint')}</Typography>
          </div>
        </Stack>

        <Stack gap={1}>
          <Button
            onClick={() => {
              setIsRemoved(true)
              window.localStorage.setItem('aliceReview', 'removed')
            }}
          >
            {t('rateAlice.removeLocalBtn')}
          </Button>

          <div>
            <Typography>{t('rateAlice.removeLocal')}</Typography>
            <Typography variant="caption" color="grey.45">{t('rateAlice.removeLocalHint')}</Typography>
          </div>
        </Stack>
      </Dialog>
    </Box>
  )
}

RateAlice.propTypes = {
  /** need to be used for positioning */
  sx: PropTypes.object
}
