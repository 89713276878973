import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Message } from '../../message'
import { GenericSearchFilter } from './GenericSearchFilter'

export const GenericSearchNoResults = ({ onClear = () => {} }) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Box
      data-testid="GenericSearchNoResults"
      sx={{
        width: 1,
        height: '450px',
        border: 1,
        borderColor: 'grey.300',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Message
        icon={<ContentPasteSearchOutlinedIcon sx={{ fontSize: '3rem' }} />}
        title={<Typography variant="h5">{t('genericSearch.noResultsTitle')}</Typography>}
        description={<Typography variant="body" color="grey.500">{t('genericSearch.noResultsDescription')}</Typography>}
        button={t('genericSearch.noResultsClear')}
        onButtonClick={onClear}
      />
    </Box>
  )
}

GenericSearchFilter.propTypes = { onClear: PropTypes.func }
