import { AccordionSummary as MuiAccordionSummary } from '@mui/material'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../util'

export const AccordionSummary = forwardRef((
  {
    sx = [],
    children,
    ...otherProps
  },
  ref
) => (
  <MuiAccordionSummary
    data-testid="AccordionSummary"
    ref={ref}
    sx={mergeSxProps(
      {
        minHeight: '48px !important',
        height: '48px !important',
        backgroundColor: (theme) => (theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)'
          : 'rgba(0, 0, 0, 0)')
      },
      sx
    )}
    {...otherProps}
  >
    {children}
  </MuiAccordionSummary>
))
