import { Box, Stack, Tooltip, Typography } from '@mui/material'
import BaseTypography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmailOutlined } from '@mui/icons-material'
import { getUserCommunities } from '../../../util'
import { Card } from '../../card'
import { AliceIcon, AliceIconType, IconWrapper } from '../../icon'
import { UserAvatar } from '../UserAvatar'

export const SupportContact = ({
  userData,
  onClick = () => {},
  sx = []
}) => {
  const { t } = useTranslation('componentLibrary')

  const userId = userData.id || userData.name
  const completeName = userData.name
  const phone = userData.telephoneNumber || userData.mobileNumber
  const appId = userData.applicationId

  let communities
  let communitiesTranslated
  if (userData.communities && userData.communities.length > 0) {
    communities = getUserCommunities(userData?.communities)
    communitiesTranslated = communities?.map((community) => t(`userCommunity.${community}`))
  }

  const Typography = ({
    children,
    sx,
    ...others
  }) => (
    // temporary fix for mbrdi until the mb theme is correctly integrated
    <BaseTypography
      {...others}
      sx={{ fontFamily: 'MB Corpo S Text !important', ...sx }}
    >
      {children}
    </BaseTypography>
  )

  const Title = ({ sx, children }) => (
    <Typography
      sx={{
        marginTop: 2,
        color: 'grey.60',
        fontSize: 16,
        ...sx
      }}
    >
      {children}
    </Typography>
  )

  const Content = ({ sx, children }) => (
    <Typography
      sx={{
        fontSize: 18,
        fontWeight: 600,
        ...sx
      }}
    >
      {children}
    </Typography>
  )

  const Avatar = () => (
    <UserAvatar
      userId={`${userId}`}
      userName={completeName}
      groupType={userData.groupType}
      showGroupType
      showImage={false}
      size="small"
    />
  )

  const CardContent = ({ sx = {} }) => (
    <Stack
      direction="column"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        ...sx
      }}
    >
      <Stack direction="row" spacing={2} sx={{ overflow: 'hidden' }}>
        <Box>
          <Avatar />
        </Box>

        <Stack sx={{ overflowX: 'hidden' }}>
          <Tooltip title={completeName} placement="top" arrow>
            <Box>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                {completeName}
              </Typography>
            </Box>
          </Tooltip>

          {userData.description && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginTop: 2 }}
            >
              <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                {userData.description}
              </Typography>
            </Stack>
          )}

          <Stack
            spacing={1}
            sx={{
              mt: 2,
              alignItems: 'start',
              display: 'flex'
            }}
          >
            {userData.mailAddress && (
              <Tooltip
                title={t('tooltip.clickToSendEmail')}
                placement="top"
                arrow
              >
                <Box>
                  <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                    <Typography
                      as="a"
                      href={`mailto:${userData.mailAddress}`}
                      sx={{
                        color: 'grey.10',
                        textDecoration: 'none',
                        marginTop: '3px',
                        marginBottom: '3px',
                        display: 'flex'
                      }}
                    >
                      <IconWrapper size={20} color="grey.50"><EmailOutlined /></IconWrapper>

                      <Typography
                        as="span"
                        sx={{ marginLeft: '10px', lineHeight: '20px' }}
                      >
                        {userData.mailAddress}
                      </Typography>
                    </Typography>
                  </Typography>
                </Box>
              </Tooltip>
            )}

            {phone && (
              <Tooltip
                title={t('tooltip.clickToDialPhoneNumber')}
                placement="top"
                arrow
              >
                <Box>
                  <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                    <Typography
                      as="a"
                      href={`tel:${phone}`}
                      sx={{
                        color: 'grey.10',
                        textDecoration: 'none',
                        marginTop: '3px',
                        marginBottom: '3px',
                        display: 'flex'
                      }}
                    >
                      <AliceIcon
                        iconType={AliceIconType.MOBILE_PHONE}
                        sx={({ palette }) => ({
                          fill: palette.grey[50],
                          marginLeft: '4px',
                          width: '20px',
                          height: '20px'
                        })}
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          height: 20,
                          fontSize: 16
                        }}
                      >
                        {phone}
                      </span>
                    </Typography>
                  </Typography>
                </Box>
              </Tooltip>
            )}

            {userData.url && (
              <Tooltip
                title={t('tooltip.openUrlInNewWindow')}
                placement="top"
                arrow
              >
                <Box>
                  <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
                    <Typography
                      as="a"
                      href={userData.url}
                      sx={{
                        color: 'grey.10',
                        textDecoration: 'none',
                        marginTop: '3px',
                        marginBottom: '3px',
                        display: 'flex'
                      }}
                    >
                      <AliceIcon
                        iconType={AliceIconType.EARTH_BLOCK}
                        sx={({ palette }) => ({
                          fill: palette.grey[50],
                          marginLeft: '4px',
                          width: '20px',
                          height: '20px'
                        })}
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          height: 20,
                          fontSize: 16
                        }}
                      >
                        {userData.url}
                      </span>
                    </Typography>
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Stack>

          {userData.market && (
            <Stack>
              <Title>{t('general.country')}</Title>
              <Content>{userData.market}</Content>
            </Stack>
          )}

          {communitiesTranslated && communitiesTranslated.length > 0 && (
            <Stack>
              <Title>{t('general.community')}</Title>
              <Content>{communitiesTranslated.join(', ')}</Content>
            </Stack>
          )}

          {userData.applicationId && (
            <Stack>
              <Title>{t('general.application')}</Title>
              <Content>{appId}</Content>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )

  return (
    <Card
      data-testid="SupportContact"
      sx={sx}
      onClick={() => onClick(userData)}
    >
      <CardContent sx={{ p: 2 }} />
    </Card>
  )
}

SupportContact.propTypes = {
  /** The user to display */
  userData: PropTypes.shape({
    givenname: PropTypes.string,
    surname: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string),
    groupType: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    mailAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
    departmentNumber: PropTypes.string,
    url: PropTypes.string,
    market: PropTypes.string,
    applicationId: PropTypes.string
  }).isRequired,
  /**
   * Event that is triggered when a card is clicked
   *
   * @param userData
   * @returns {void}
   */
  onClick: PropTypes.func,
  /** Add some additional styling */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
