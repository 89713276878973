export const useUserCard = ({
  size = 'm',
  isList = false,
  isRenderer = false,
  isSubgrid = false,
  mailAddress = isRenderer,
  mobileNumber = isRenderer,
  additionalData = isRenderer,
  communities = isRenderer,
  homeOrgName = isRenderer,
  isCompanyNameShown = isRenderer,
  departmentNumber = isRenderer,
  CustomInfoChip = isRenderer,
  status = isRenderer,
  _isContextShown = true,
  _isEmailShown = size !== 'xs',
  _isPhoneShown = size === 'xl',
  _isCommunityShown = size === 'm' || (size === 'xl' && !_isEmailShown && !_isPhoneShown),
  _isOrganisationShown = size === 'xl' && !_isEmailShown && !_isPhoneShown,
  _isInfoChipShown = size === 'l' || size === 'xl',
  _isStatusShown = size === 'l' || size === 'xl',
  _onDeleteClick
}) => {
  const isGrid = !isList
  const isTiny = size === 'xs'

  const isDeleteShown = !!_onDeleteClick
  const isContextShown = _isContextShown && !isDeleteShown
  const isEmailShown = _isEmailShown && (mailAddress || isSubgrid)
  const isPhoneShown = _isPhoneShown && (mobileNumber || isSubgrid)
  const isCommunityShown = _isCommunityShown && (!!communities.length || isSubgrid)
  const isOrganisationShown = _isOrganisationShown && ((homeOrgName && isCompanyNameShown) || isSubgrid)
  const isInfoChipShown = _isInfoChipShown && !!(departmentNumber || CustomInfoChip)
  const isStatusShown = _isStatusShown && status

  const isContactListShown = isEmailShown || isPhoneShown || !!additionalData.length
  const isDetailsShown = isCommunityShown || isOrganisationShown
  const isActionShown = isDeleteShown

  const isDividerShown = isGrid && (size === 'm' || size === 'xl')
  const isDividedHorizontal = isList || (isTiny && isActionShown)

  const isDividedShown = !!(
    (isList && isContextShown)
    || isInfoChipShown
    || isStatusShown
    || isDeleteShown
  )

  // eslint-disable-next-line max-len
  const subgridContentLength = [ true, isEmailShown, isPhoneShown, isCommunityShown, isOrganisationShown, isDividerShown ].filter((entry) => entry).length

  return {
    isTiny,
    isContextShown,
    isEmailShown,
    isPhoneShown,
    isCommunityShown,
    isOrganisationShown,
    isInfoChipShown,
    isStatusShown,
    isDividerShown,
    isDeleteShown,
    isContactListShown,
    isDetailsShown,
    isActionShown,
    isDividedHorizontal,
    isDividedShown,
    subgridContentColumns: subgridContentLength,
    subgridContentRows: subgridContentLength
  }
}
