import { useEffect, useState } from 'react'
import { AliceService } from '../../data'
import { sanitizeSearchQuery } from '../../util'
import { useApi } from '../api/useApi'

export const useApiItems = ({
  apiUrl,
  apiFunction = async (config, additionalInformation) => ({ }),
  responseDataItemsKey,
  getResponseData = (data) => data[responseDataItemsKey],
  additionalFunctionConfig,
  additionalFunctionInformation,
  searchConfig,
  preselectedPageIndex = 0,
  preselectedPageSize = 12,
  preselectedSort = '',
  preselectedSearch = '',
  preselectedFilters = {},
  // testing
  isForceLoading,
  ...otherProps
}) => {
  const [ itemsCount, setItemsCount ] = useState(0)
  const [ pageIndex, setPageIndex ] = useState(preselectedPageIndex)
  const [ pageSize, setPageSize ] = useState(preselectedPageSize)
  const [ sort, setSort ] = useState(preselectedSort)
  const [ search, setSearch ] = useState(preselectedSearch)
  const [ filters, setFilters ] = useState(preselectedFilters)

  const getFilters = () => {
    const useApiFilter = { ...filters }

    if (!search) return useApiFilter

    if (!searchConfig) searchConfig = { apiFilters: [ 'search' ] }
    else if (!searchConfig?.apiFilters) searchConfig.apiFilters = [ 'search' ]

    searchConfig.apiFilters.forEach((apiFilter) => {
      useApiFilter[apiFilter] = sanitizeSearchQuery(search)
    })

    return useApiFilter
  }

  const apiUrlFunctionWrapper = async ({ ...otherProps }) => AliceService.get(apiUrl, { ...otherProps })
  const apiFunctionWrapper = async ({ ...otherProps }) => apiFunction(otherProps, additionalFunctionInformation)

  const {
    execute: update,
    isLoading,
    response,
    isRequested
  } = useApi(
    apiUrl ? apiUrlFunctionWrapper : apiFunctionWrapper,
    {
      config: {
        filters: getFilters(),
        page: pageIndex,
        pageSize,
        params: { sort },
        ...additionalFunctionConfig
      },
      initialResponse: [],
      isNotifyingOnError: true,
      isRequestingInitially: true,
      responseTransformer: (response) => {
        setItemsCount(response?.rels?.totalCount || 0)

        return getResponseData(response)
      },
      ...otherProps
    }
  )

  useEffect(() => {
    if (!isRequested) return

    update()
    // eslint-disable-next-line
  }, [ apiUrl, filters, search, sort, pageSize, pageIndex, searchConfig ])

  return {
    items: response,
    itemsCount,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    sort,
    setSort,
    search,
    setSearch,
    filters,
    setFilters,
    isLoading: isLoading || isForceLoading,
    updateItems: update
  }
}
