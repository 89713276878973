import PropTypes from 'prop-types'
import { ContactPhoneOutlined, DeviceHubOutlined, DoubleArrow, Factory, LocalPhoneOutlined, MailOutline, OpenInNew, PhoneIphoneOutlined } from '@mui/icons-material'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../hooks'
import { format, getFineGranularUserCommunity, getLink, getManagementLevel, getUserCommunities, isInternalEmployee, user as userUtils } from '../../../util'
import { Button } from '../../button'
import { DetailsList } from '../../card/genericCard'
import { CardInfoChip, StatusChip } from '../../chip'
import { AliceIcon } from '../../icon'
import { Link } from '../../link'
import { FocusDot } from '../../projectFocus'
import { Skeleton } from '../../skeleton'
import { UserAvatarInfo } from '../UserAvatar'
import { SmartIcon } from '../../card/genericCard/componets/smartIcons/SmartIcons'

const Title = ({ sx, children }) => (
  <Typography
    variant="caption"
    sx={{
      color: 'text.secondary',
      lineHeight: 1.3,
      ...sx
    }}
  >
    {children}
  </Typography>
)

const Content = ({ sx, children }) => (
  <Typography
    variant="subtitle2"
    sx={{
      color: 'text.primary',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...sx
    }}
  >
    {children}
  </Typography>
)

const DetailsRow = ({
  label,
  content,
  icon,
  orgId,
  groupType,
  isLoading
}) => {
  const handleOpenInNewTab = () => {
    window.open(getLink('orgDetails', { orgId }), '_blank')
  }

  return (
    <Button
      variant="tertiary"
      onClick={orgId && handleOpenInNewTab}
      sx={[
        {
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          cursor: 'unset',
          justifyContent: 'unset',
          padding: 0,
          '&:hover': { backgroundColor: 'white' }
        },
        orgId && {
          '&:hover': {
            backgroundColor: 'white',
            cursor: 'pointer',
            '.hoverCopyIcon': { opacity: 1 }
          }
        }
      ]}
    >
      {isLoading
        ? (
          <Skeleton
            variant="rounded"
            sx={{
              width: '20px',
              height: '20px',
              margin: '10px'
            }}
          />
        )
        : <Box sx={{ padding: '10px', lineHeight: 0 }}>{icon}</Box>}

      <Stack
        sx={{
          display: 'grid',
          overflow: 'hidden'
        }}
      >
        {isLoading
          ? (
            <Skeleton
              variant="rectangular"
              sx={{
                width: '70px',
                height: '16px',
                marginBottom: '4px'
              }}
            />
          )
          : <Title sx={{ justifySelf: 'baseline' }}>{label}</Title>}

        {isLoading
          ? <Skeleton variant="rectangular" sx={{ width: '120px', height: '20px' }} />
          : (
            orgId
              ? (
                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: 'left',
                    color: 'text.primary',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    href={getLink('orgDetails', { orgId })}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      display: 'inline',
                      textDecoration: 'none',
                      color: 'primary.main',
                      paddingRight: '6px'
                    }}
                  >
                    {content}

                    <FocusDot
                      className="focusDot"
                      groupType={groupType}
                      size="small"
                      sx={{
                        minWidth: '10px',
                        minHeight: '10px',
                        width: '10px',
                        height: '10px',
                        border: 0,
                        display: 'inline-flex',
                        position: 'relative',
                        bottom: 0,
                        left: '6px'
                      }}
                    />
                  </Link>
                </Typography>
              )
              : <Content sx={{ textAlign: 'left' }}>{content}</Content>
          )}
      </Stack>

      {orgId && (
        <Box
          className="hoverCopyIcon"
          fontSize={18}
          sx={{
            lineHeight: 0,
            color: 'primary.main',
            marginLeft: 'auto',
            marginTop: '12px',
            opacity: 0,
            transition: 'opacity 250ms'
          }}
        >
          <OpenInNew fontSize="inherit" color="inherit" />
        </Box>
      )}
    </Button>
  )
}

export const UserContext = ({
  userData,
  orgData,
  supervisorData,
  showTeamsLink,
  showDetailedCommunity
}) => {
  const { t } = useTranslation('componentLibrary')
  const { user } = useUser()

  // USER (to show)
  const communities = getUserCommunities(userData?.communities)
  const userIsEmtUser = userUtils.is.emtUser(userData?.communities)
  const givenName = userData?.givenname
  const surname = userData?.surname
  const userId = format.userId(userData?.id)
  const mailAddress = userData?.mailAddress
  const externalEmail = userData?.externalEmail
  const telephoneNumber = userData?.telephoneNumber
  const mobileNumber = userData?.mobileNumber
  const departmentNumber = userData?.departmentNumber
  const isInternal = isInternalEmployee(userData?.communities)
  const plant = userData?.plant1 || null
  const level = getManagementLevel(userData?.managementLevel)
  const userGroupType = userData?.groupType

  const userIsDealer = userUtils.is.dealer(userData?.communities)
  const userIsSupplier = userUtils.is.supplier(userData?.communities)

  const status = userData?.isActive ? 'active' : 'inactive'

  const contactData = () => {
    const data = []

    if (mailAddress) {
      data.push({
        icon: <MailOutline fontSize="inherit" />,
        text: mailAddress,
        label: t('general.email')
      })
    }

    if (externalEmail) {
      data.push({
        icon: <MailOutline fontSize="inherit" />,
        text: mailAddress,
        label: t('general.externalEmail')
      })
    }

    if (telephoneNumber) {
      data.push({
        icon: <LocalPhoneOutlined fontSize="inherit" />,
        text: telephoneNumber,
        label: t('general.phone')
      })
    }

    if (mobileNumber) {
      data.push({
        icon: <PhoneIphoneOutlined fontSize="inherit" />,
        text: mobileNumber,
        label: t('general.mobile')
      })
    }

    if (showTeamsLink && bothUsersAreLocalEmployees) {
      data.push({
        icon: <ContactPhoneOutlined fontSize="inherit" />,
        text: getLink('mbTeams', { userId }),
        label: 'Teams'
      })
    }

    return data
  }

  // ORGANIZATION
  const homeOrg = orgData?.name
  const orgGroupType = orgData?.orgGroupType?.toString() // '0', '1', 'S'
  const orgOutletCode = orgData?.gssnData?.outletCode
  const orgExternalId = orgData?.externalId
  const orgId = orgData?.id

  // SUPERVISOR
  const supervisorHeadline = userIsEmtUser
    ? t('general.data_administrator')
    : t('general.supervisor')

  // LOCAL USER AND USER OF USERDATA ARE LOCAL EMPLOYEES? (Show MB TEAMS link)
  const bothUsersAreLocalEmployees = userUtils.is.internalEmployee(user.communities)
    && userUtils.is.internalEmployee(userData?.communities)

  const userCommunity = () => {
    const userCommunities = []

    communities.forEach((community) => {
      if (community === 'TECH_USER' || community === 'TEST_USER') {
        const fineCommunity = getFineGranularUserCommunity(userData?.communities, community)
        fineCommunity && showDetailedCommunity ? userCommunities.push(t(`userCommunity.${fineCommunity}`)) : userCommunities.push(t(`userCommunity.${community}`))
      } else {
        userCommunities.push(t(`userCommunity.${community}`))
      }
    })

    return userCommunities.join(', ')
  }

  return (
    <>
      <UserAvatarInfo
        user={{
          id: userId,
          givenname: givenName,
          surname,
          groupType: userGroupType,
          communities
        }}
        isGroupTypeShown
        isSurnameFirst
        sx={{
          '.userAvatarName': {
            fontWeight: 700, fontSize: '20px', lineHeight: '28px'
          }
        }}
      />

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 1
        }}
      >
        {departmentNumber && (
          <CardInfoChip
            icon={<DeviceHubOutlined color="inherit" fontSize="inherit" />}
            contentText={<Typography noWrap variant="body2" sx={{ color: 'text.primary', lineHeight: '14px' }}>{departmentNumber}</Typography>}
            sx={{ marginRight: 'auto' }}
          />
        )}

        <StatusChip
          type={status}
          sx={[
            {
              marginLeft: 'auto',
              height: '20px'
            },
            status === 'active' && {
              borderColor: 'success.light',
              '.MuiChip-label': { color: 'success.light' },
              '.MuiChip-icon': { color: 'success.light' }
            }
          ]}
        />
      </Stack>

      <Divider />

      {isInternal && (plant || level) && (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 1
          }}
        >
          {plant && (
            <DetailsRow
              label="Plant"
              content={plant}
              icon={(
                <Box sx={{ fontSize: '20px', color: 'primary.main' }}>
                  <Factory color="inherit" fontSize="inherit" />
                </Box>
              )}
            />
          )}

          {level && (
            <DetailsRow
              label="Level"
              content={level}
              icon={(
                <Box sx={{
                  fontSize: '20px',
                  color: 'primary.main',
                  rotate: '90deg'
                }}
                >
                  <DoubleArrow color="inherit" fontSize="inherit" />
                </Box>
              )}
            />
          )}
        </Stack>
      )}

      {communities && (
        <Stack gap={1}>
          <DetailsRow
            label={t('general.community')}
            icon={<SmartIcon type="userCommunity" name={communities[0]} />}
            content={userCommunity()}
          />

          {orgData && (
            <DetailsRow
              label={t('general.organization')}
              icon={<AliceIcon iconType="ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION" color="primary.main" size={16} />}
              content={homeOrg}
              orgId={orgId}
              groupType={orgGroupType}
            />
          )}

          {/* See ALICE-1095 */}
          {userIsDealer && orgOutletCode && (
            <DetailsRow
              label={t('userContext.dealerNumber')}
              content={orgOutletCode}
              icon={<AliceIcon iconType="ORG_USER_SEARCH_DEALER" color="primary.main" size={16} />}
            />
          )}

          {userIsSupplier && orgExternalId && (
            <DetailsRow
              label={t('userContext.supplierNumber')}
              content={orgExternalId}
              icon={<AliceIcon iconType="ORG_USER_SEARCH_SUPPLIER" color="primary.main" size={16} />}
            />
          )}
          {/* /See ALICE-1095 */}
        </Stack>
      )}

      {/* eslint-disable-next-line max-len */}
      {(mailAddress || externalEmail || telephoneNumber || mobileNumber || (showTeamsLink && bothUsersAreLocalEmployees)) && (
        <>
          <Divider />

          <DetailsList
            data={contactData()}
            size="m"
            sx={{ gridArea: 'infoList' }}
          />
        </>
      )}

      {supervisorData && (
        <>
          <Divider />

          <Stack sx={{ gap: 1 }}>
            <Title>{supervisorHeadline}</Title>

            <UserAvatarInfo
              user={supervisorData}
              variant="medium"
              isSurnameFirst
            />
          </Stack>
        </>
      )}
    </>
  )
}

UserContext.propTypes = {
  userData: PropTypes.object.isRequired,
  orgData: PropTypes.object,
  supervisorData: PropTypes.object,
  showTeamsLink: PropTypes.bool,
  showDetailedCommunity: PropTypes.bool
}
