import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WorkflowHistory } from "@alice/component_library";
import { isObjectEmpty } from "@pages/util";
import { WorkfowHeader } from "@src/pages/requestPage/styledComponents";

const WorkflowStepper = ({ workflowId, taskInfo, isDeeplink, isWorkFlow }) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState([]);

  const allWorkFlows =
    useSelector((state) => state?.overview?.taskInfo?.workflowInfo.data) ?? [];
  const deeplinkWorkflow =
    useSelector(
      (state) =>
        state?.task?.taskDeepLinkInfo?.relativeTask?.taskData?.workFlowData,
    ) ?? [];

  const deepLinkWorkflow =
    useSelector((state) => state?.overview?.requestDeeplink?.workFlowData) ??
    {};

  if (isDeeplink) {
    if (!isObjectEmpty(deepLinkWorkflow)) {
      const isWorkFLowExist = allWorkFlows?.find(
        (workflow) => workflow.id === deepLinkWorkflow.id,
      );
      if (!isWorkFLowExist) {
        allWorkFlows?.push(deepLinkWorkflow);
      }
    }
  }

  let selectedWorkflow = allWorkFlows?.find(
    (wokflow) => wokflow.id === workflowId,
  );

  if (isDeeplink) {
    const mergedWorkFlowInfo = [...allWorkFlows, ...deeplinkWorkflow].reduce(
      (uniqueWorkflowList, currentItem) => {
        const existingWorkflow = uniqueWorkflowList.find(
          (item) => item.id === currentItem.id,
        );
        if (!existingWorkflow) {
          uniqueWorkflowList.push(currentItem);
        }

        return uniqueWorkflowList;
      },
      [],
    );

    selectedWorkflow = mergedWorkFlowInfo?.find(
      (wokflow) => wokflow.id === workflowId,
    );
  }

  useEffect(() => {
    const stepsInfo = isWorkFlow ? selectedWorkflow : [taskInfo];

    setSteps(stepsInfo);
  }, [selectedWorkflow, isWorkFlow, taskInfo]);

  return (
    <>
      {steps?.tasks?.length ? (
        <>
          <WorkfowHeader>{t("status-approval-workflow")}</WorkfowHeader>
          <WorkflowHistory workflow={steps} />
        </>
      ) : null}
    </>
  );
};

export default WorkflowStepper;
