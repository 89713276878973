import React from "react";
import { Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  GenericCard,
  CardContent,
  CardDivided,
  Chip,
  IconButton,
  Tooltip,
} from "@alice/component_library";

const ActiveFilterTab = ({
  activeFilters,
  filter = [],
  onDelete = (value) => {},
  onClear = () => {},
  sx = {},
}) => {
  const { t } = useTranslation("");

  return (
    !!activeFilters.length && (
      <GenericCard
        data-testid="GenericSearchActiveFilter"
        isList
        isDividedShown
        sx={sx}
      >
        <CardContent
          isList
          sx={{
            gridTemplate: `
              "applied-filters"
              "content"`,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              gridArea: "applied-filters",
              color: "text.secondary",
            }}
          >
            {t("filters-applied")}
          </Typography>

          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            sx={{
              gridArea: "content",
              width: "100%",
            }}
          >
            {activeFilters.map(
              ({ icon, label, value }) =>
                // Add an if condition to check if the value is included in the filter array
                filter.includes(value) && (
                  <Chip
                    key={`test-${value}`}
                    sx={{
                      padding: "0px 14px",
                      cursor: "default",
                      "svg, img": {
                        width: "18px",
                        height: "18px",
                        color: "text.disabled",
                      },
                      ".MuiChip-deleteIcon": {
                        display: value !== "all" ? "block" : "none",
                        width: "20px",
                        height: "20px",
                        "&:hover": { color: "common.black" },
                      },
                    }}
                    onDelete={
                      value !== "All" ? () => onDelete(value) : undefined
                    }
                    icon={icon}
                    label={label}
                    variant="outlined"
                  />
                )
            )}
          </Stack>
        </CardContent>

        <CardDivided
          isList
          action={
            <Tooltip placement="bottom" title={"Clear"}>
              <IconButton
                size="small"
                onClick={onClear}
                sx={{ color: "text.primary" }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    gridArea: "applied-filters",
                    color: "primary.main",
                  }}
                >
                  {"Clear"}
                </Typography>
              </IconButton>
            </Tooltip>
          }
        />
      </GenericCard>
    )
  );
};

export default ActiveFilterTab;
