import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { CustomOrgCard } from "@components/CustCard";
import {
  Interaction,
  createArrayWithInitialValues,
} from "@alice/component_library";

export const CustomOrgCardViewRenderer = ({
  view,
  data: CustomOrgs,
  selectedData: slectedCustomOrgs = [],
  onItemClick,
  rowsPerpage,
  interaction,
  isLoading,
}) => {
  const gridSx = [
    {
      display: "grid",
      gap: 2,
      gridAutoFlow: "dense",
    },
    view === "grid" && { gridTemplateColumns: "repeat(3, 1fr)" },
    view === "list" && { gridTemplateColumns: "repeat(1, 1fr)" },
  ];

  const mappableCustomScope = isLoading
    ? createArrayWithInitialValues(rowsPerpage, (value, index) => ({
        id: index.toString(),
      }))
    : CustomOrgs;

  const getCardClickFunction = () => {
    if (
      interaction === Interaction.NONE ||
      interaction === Interaction.NAVIGATE ||
      isLoading
    )
      return undefined;

    return onItemClick;
  };

  return (
    <Box data-testid="AppSearchGridViewRenderer" sx={gridSx}>
      {mappableCustomScope.map((customOrg) => (
        <Fragment key={customOrg.id}>
          <CustomOrgCard
            data={customOrg}
            variant={view}
            interaction={interaction}
            isLoading={isLoading}
            isSelected={slectedCustomOrgs.includes(customOrg.id)}
            onCardClick={getCardClickFunction()}
          />
        </Fragment>
      ))}
    </Box>
  );
};
