import { forwardRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, IconButton, ListItemText, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { KeyboardArrowRight, Logout } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useUser } from '../../../../hooks'
import { getClient, getClientLink, user } from '../../../../util'
import { LanguageListDialog, LanguagePopover } from '../../../language'
import { UserAvatar } from '../UserAvatar/UserAvatar'

const linkStyle = {
  textDecoration: 'none',
  color: 'black'
}

export const UserAvatarMenu = forwardRef((
  {
    handleAbout,
    handleSettings,
    handleLogout,
    version,
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const { user: userObj } = useUser()

  const [ menuAnchor, setMenuAnchor ] = useState(null)
  const [ languageAnchor, setLanguageAnchor ] = useState(null)

  const isAccessUI = useMemo(() => getClient() === 'access', [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const shortVersion = version.split('.').slice(0, 2).join('.')

  const onUserPress = (event) => setMenuAnchor(event.currentTarget)
  const handleClose = () => setMenuAnchor(null)
  const onLanguagePress = (event) => setLanguageAnchor(event.currentTarget)

  const onCloseLanguage = () => {
    setLanguageAnchor(null)
    handleClose()
  }

  return (
    <>
      <IconButton
        data-testid="UserAvatarMenu"
        ref={ref}
        sx={{ borderRadius: 0 }}
        onClick={onUserPress}
        {...otherProps}
      >
        {userObj ? (
          <UserAvatar
            sx={{
              height: 35,
              width: 35,
              fontSize: '0.9rem'
            }}
            userName={userObj?.completeName}
            userId={userObj?.id}
            groupType={userObj?.groupType}
            showImage={user.is.internalEmployee(userObj?.communities)}
            size="m"
          />
        ) : (
          <Avatar />
        )}
      </IconButton>

      <Menu
        data-testid="UserAvatarMenu-Menu"
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleClose}
        disableScrollLock
        sx={{ '.MuiMenu-paper': { minWidth: '196px', padding: '16px 8px' } }}
      >
        {version && (
          <Stack
            key="version"
            sx={{
              flexDirection: 'row',
              backgroundColor: 'grey.90',
              borderRadius: '2px',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '6px 12px'
            }}
          >
            <Typography as="span"
                        variant="caption"
                        sx={{ color: 'text.primary' }}>{t('general.currentVersion')}</Typography>

            <Typography
              as="span"
              variant="caption"
              sx={{
                color: 'primary.main',
                fontSize: '0.75rem',
                fontWeight: 700,
                lineHeight: '16px'
              }}
            >
              {shortVersion}
            </Typography>
          </Stack>
        )}

        <Stack sx={{ marginTop: '12px', marginBottom: '8px' }}>
          {isAccessUI ? (
            <MenuItem
              component={Link}
              key="profile"
              onClick={handleClose}
              to="/access/profile/overview"
              style={linkStyle}
              sx={{ padding: '12px', '&:hover': { borderRadius: '2px' } }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t('general.profile')}</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              component="a"
              key="profile"
              onClick={handleClose}
              href={`${getClientLink('access')}/profile/overview`}
              style={linkStyle}
              rel="noreferrer"
              sx={{ padding: '12px', '&:hover': { borderRadius: '2px' } }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t('general.profile')}</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            key="language"
            onClick={onLanguagePress}
            sx={{ padding: '12px', '&:hover': { borderRadius: '2px' } }}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t('general.language')}</ListItemText>

            <Box
              sx={{
                display: 'flex',
                color: 'action.active',
                fontSize: '18px'
              }}
            >
              <KeyboardArrowRight color="inherit" fontSize="inherit" />
            </Box>
          </MenuItem>

          {handleAbout && (
            <MenuItem
              key="about"
              onClick={() => {
                handleAbout()
                handleClose()
              }}
              sx={{ padding: '12px', '&:hover': { borderRadius: '2px' } }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t('general.about')}</ListItemText>
            </MenuItem>
          )}

          {handleSettings && (
            <MenuItem
              key="settings"
              onClick={() => {
                handleSettings()
                handleClose()
              }}
              sx={{ padding: '12px', '&:hover': { borderRadius: '2px' } }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t('general.settings')}</ListItemText>
            </MenuItem>
          )}
        </Stack>

        {handleLogout && (
          <MenuItem
            key="logout"
            onClick={() => {
              handleLogout()
              handleClose()
            }}
            sx={{
              gap: 1, padding: '12px', '&:hover': { borderRadius: '2px' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                color: 'error.main',
                fontSize: '18px'
              }}
            >
              <Logout color="inherit" fontSize="inherit" />
            </Box>

            <ListItemText primaryTypographyProps={{ variant: 'body2' }}
                          sx={{ color: 'error.main' }}>{t('general.logout')}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      {!isMobile
        ? <LanguagePopover onClose={onCloseLanguage} anchorEl={languageAnchor} />
        : <LanguageListDialog onClose={onCloseLanguage} open={!!languageAnchor} />
      }
    </>
  )
})

UserAvatarMenu.propTypes = {
  /**
   * Function that is executed when about is pressed.<br>
   * If not set, the item will not show in the menu.
   *
   * @returns {void}
   */
  handleAbout: PropTypes.func,
  /**
   * Function that is executed when settings is pressed.<br>
   * If not set, the item will not show in the menu.
   *
   * @returns {void}
   */
  handleSettings: PropTypes.func,
  /**
   * Function that is executed when logout is pressed.<br>
   * If not set, the item will not show in the menu.
   *
   * @returns {void}
   */
  handleLogout: PropTypes.func,
  /** Displays the given Version if set to true */
  version: PropTypes.string
}
