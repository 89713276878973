import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Badge } from "@alice/component_library";
import { StatusHeaderContainer } from "../../styledComponents";
import { STATUS_OPTIONS } from "./utils";

const StatusHeader = ({ status = "success", count = 0 }) => {
  const { t } = useTranslation();
  const {
    icon: IconComponent,
    color,
    headingKey,
    subheadingKey,
  } = STATUS_OPTIONS[status] || STATUS_OPTIONS.failed; // Default to filed if invalid status
  const translatedHeading = t(headingKey);
  const translatedSubheading = t(subheadingKey);
  return (
    <>
      <StatusHeaderContainer color={color}>
        <IconComponent className="status-icon" />
        <Typography
          sx={{ wordBreak: { xs: "break-all", md: "normal" } }}
          variant="h4"
        >
          {translatedHeading}
        </Typography>
        <Badge filled className="badge-count">
          {count}
        </Badge>
      </StatusHeaderContainer>
      <Typography sx={{ paddingLeft: "40px" }} variant="body1">
        {translatedSubheading}
      </Typography>
    </>
  );
};

export default StatusHeader;
