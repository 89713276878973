/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { UserCard, Message, Skeleton, useUser } from "@alice/component_library";
import { hasData } from "@utils/Validator";
import { GetMyContactAdministrator } from "@actions";
import { UserCardLoading } from "@components/Skeleton/CommonSkeletons";
import { WarningIcon } from "@components/Styled";
import { AdministratorHeaderText } from "../../styledComponents";

const MyAdministartors = ({ isCollapsed }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useUser();
  const userUtils = user || {};
  const adminOrgs = useSelector((state) => state.profile.adminstrators);

  useEffect(() => {
    dispatch(GetMyContactAdministrator(userUtils.id));
  }, []);

  if (adminOrgs.loading)
    return (
      <Grid container spacing={2} sx={{ padding: "30px 0" }}>
        <Grid item xs={12}>
          <Skeleton variant="text" width={150} height={24} />
        </Grid>
        {[...Array(12)].map((ele) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={isCollapsed ? 4 : 6}
            key={ele}
          >
            <UserCardLoading />
          </Grid>
        ))}
      </Grid>
    );

  const getUsers = (label, key) => {
    const data = adminOrgs.data[key];
    if (hasData(data)) {
      return (
        <>
          <AdministratorHeaderText>{t(label)}</AdministratorHeaderText>
          <Grid
            container
            spacing={2}
            style={{ padding: "20px 0 25px" }}
            alignItems="stretch"
          >
            {data.map((d, i) => (
              <Grid
                key={i}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={isCollapsed ? 4 : 6}
                sx={{ "& .MuiPaper-root": { height: "100%" } }}
              >
                <UserCard userData={d} readonly isContextShown={true} />
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
  };

  const getNoData = () => {
    const valid = Object.keys(adminOrgs.data).filter(
      (d) => adminOrgs.data[d].length > 0
    );
    if (valid.length === 0) {
      return <Message icon={<WarningIcon />} title={t("no_data_found")} />;
    }
    return null;
  };

  return (
    <Grid container>
      {getUsers("organizations", "org")}
      {getUsers("markets", "market")}
      {getUsers("central", "global")}
      {getNoData()}
    </Grid>
  );
};

export default MyAdministartors;
