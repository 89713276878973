import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { getLink } from '../../../util'
import { IdChip } from '../../chip'
import { Link } from '../../link'
import { Skeleton } from '../../skeleton'

const Placeholder = ({ variant }) => (
  <Stack direction="row" gap={1}>
    <IdChip variant={variant} isLoading />

    <Skeleton
      variant="rectangular"
      width="96px"
      sx={[
        variant === 'small' && { height: '1rem' },
        variant === 'medium' && { height: '1.275rem' },
        variant === 'big' && { height: '1.5rem' }
      ]}
    />
  </Stack>
)

/**
 * Simple component to display ID Chip and a ID
 *
 * Integrated CLIB-Components
 *
 * - [IdChip](./?path=/docs/alice-ui-chip-idchip--docs)
 */
export const UserId = forwardRef(({
  userId,
  variant = 'medium',
  isLink,
  isLoading,
  ...otherProps
}, ref) => {
  if (isLoading) return <Placeholder />

  const id = (
    <Typography
      className="userAvatarId"
      variant={variant === 'small' ? 'subtitle2' : 'subtitle1'}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {userId}
    </Typography>
  )

  return (
    <Stack
      ref={ref}
      gap={1}
      direction="row"
      alignItems="center"
      {...otherProps}
    >
      <IdChip variant={variant} />

      {isLink ? (
        <Link
          href={getLink('userDetails', { userId })}
          isBlank
          sx={{ display: 'grid', overflow: 'hidden' }}
        >
          {id}
        </Link>
      ) : id}
    </Stack>
  )
})

UserId.propTypes = {
  /** UserId to display */
  userId: PropTypes.string,
  /** Variant of the component */
  variant: PropTypes.oneOf([ 'small', 'medium', 'big' ]),
  /** If true, User ID is a link to profile page */
  isLink: PropTypes.bool,
  /** Loading state */
  isLoading: PropTypes.bool
}
