import React, { memo } from "react";
import { UserContextPopover, UserAvatar } from "@alice/component_library";
import { user } from "@alice/component_library";

const UserProfileSection = ({ users }) => {
  const { givenname, surname, groupType, id } = users;
  const { communities } = users;

  return (
    <>
      <span className="mt-20">
        <UserContextPopover id={id}>
          <UserAvatar
            userId={id}
            userName={`${givenname} ${surname}`}
            groupType={groupType}
            showGroupType
            size="big"
            showImage={user.is.internalEmployee(communities)}
          />
        </UserContextPopover>
      </span>
    </>
  );
};

export default memo(UserProfileSection);
