import React from "react";
import { Button } from "@alice/component_library";

export const PrimaryButton = (props) => {
  const {
    label,
    onButtonClick = () => {},
    className,
    params,
    style = {},
  } = props;
  const { disabled = false } = props;

  return (
    <Button
      variant="primary"
      onClick={() => onButtonClick(params)}
      className={className}
      sx={style}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export const SecondaryButton = (props) => {
  const {
    label,
    onButtonClick = () => {},
    className,
    params,
    style = {},
  } = props;
  const { disabled = false } = props;
  return (
    <Button
      variant="secondary"
      onClick={() => onButtonClick(params)}
      className={className}
      disabled={disabled}
      sx={style}
    >
      {label}
    </Button>
  );
};

export const TertiaryButton = (props) => {
  const {
    label,
    onButtonClick = () => {},
    className,
    params,
    style = {},
  } = props;
  const { disabled = false } = props;
  return (
    <Button
      variant="tertiary"
      onClick={() => onButtonClick(params)}
      className={className}
      disabled={disabled}
      sx={style}
    >
      {label}
    </Button>
  );
};

export const DisabledButton = (props) => {
  const { label, onButtonClick = () => {}, params, className, style } = props;
  return (
    <Button
      disabled
      variant="contained"
      color="inherit"
      onClick={() => onButtonClick(params)}
      className={className}
      styles={style}
    >
      {label}
    </Button>
  );
};
