import { profileActionTypes } from "@constants/actionTypes";
const initialState = {
  organizatinColleagues: {
    orgColleagueData: [],
    error: "",
    loading: false,
    pageData: {
      data: [],
      paginationTottalCount: 0,
      search: "",
    },
  },
  workPlaceColleagues: {
    workColleagueData: [],
    error: "",
    loading: false,
    pageData: {
      data: [],
      paginationTottalCount: 0,
      search: "",
    },
  },
  adminstrators: { loading: false, data: {} },
  visibleSupport: {
    visible: false,
    adminRolesInfo: [],
    loading: false,
    isLoaded: false,
  },
  adminDetailInfo: { loading: false, data: {} },
};
const ProfileReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case profileActionTypes.GET_ORG_COLLEAGUES:
      return (state = {
        ...state,
        organizatinColleagues: data?.data,
      });
    case profileActionTypes.GET_WORKPLACE_COLLEAGUES:
      return (state = {
        ...state,
        workPlaceColleagues: data?.data,
      });
    case profileActionTypes.GET_ADMIN_INFO:
      return (state = {
        ...state,
        adminstrators: data,
      });
    case profileActionTypes.GET_VISIBLE_SUPORT:
      const {
        adminRoles = state.visibleSupport.adminRolesInfo,
        loading = false,
        visible = false,
        isLoaded = false,
      } = data;
      return (state = {
        ...state,
        visibleSupport: {
          visible: visible,
          adminRolesInfo: adminRoles,
          loading: loading,
          isLoaded: isLoaded,
        },
      });
    case profileActionTypes.GET_MORE_ADMIN_INFO:
      return (state = {
        ...state,
        adminDetailInfo: data,
      });
    default:
      return state;
  }
};
export default ProfileReducer;
