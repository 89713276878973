import PropTypes from 'prop-types'
import { grey } from '@mercedes-benz/mui5-theme'
import { Box, Stack, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { mergeSxProps } from '../../../../util'
import { CloseButton } from '../../../button'
import { Link } from '../../../link'

export const TableRow = ({
  children,
  isLink,
  href,
  ...others
}) => (isLink
  ? <Link className="tableRow" isBlank href={href} {...others}>{children}</Link>
  : <Box className="tableRow" {...others}>{children}</Box>
)

export const TableTitle = ({
  children,
  ...others
}) => <Box className="tableTitle" {...others}>{children}</Box>

export const TableEntry = ({
  children,
  ...others
}) => <Box className="tableEntry" {...others}>{children}</Box>

export const TableWrapper = ({
  titleChildren,
  entryChildren,
  columnCount,
  hasVerticalGutter = false,
  hasIconStart = false,
  noDataPlaceholder = <Typography>No data.</Typography>,
  sx = {} || [],
  ...otherProps
}) => (
  <Box
    data-testid="GridTable"
    className="table"
    sx={mergeSxProps(
      {
        '--tableTitleBg': grey[90],
        flex: 1,
        display: 'grid',
        gap: '1px',
        background: '#e0e0e0', // palette.divider,
        borderRadius: '4px',
        border: '1px solid #e0e0e0', // ${palette.divider}`,
        overscrollBehavior: 'contain auto',
        overflowX: 'auto',
        overflowY: 'hidden',
        '.table': {
          '&Row, &Wrapper': {
            display: 'grid',
            gridColumn: `1 / span ${columnCount}`,
            gridTemplateColumns: 'subgrid'
          },
          '&Wrapper': { gap: !hasVerticalGutter ? '1px 0' : '1px' },
          '&Row': {
            '&:nth-of-type(even)': { '--tableRowBg': `var(--tableRowBgEven, ${grey[95]})` },
            '&:nth-of-type(odd)': { '--tableRowBg': 'var(--tableRowBgOdd)' },
            '> div': {
              '&:last-of-type': { paddingRight: 2 },
              ...(!hasIconStart && { '&:first-of-type': { paddingLeft: 2 } })
            }
          },
          '&Title, &Entry': {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            height: '100%',
            minHeight: '56px',
            background: 'var(--tableRowBg, white)',
            padding: 1
          },
          '&Title': {
            '--tableRowBg': 'var(--tableTitleBg)',
            alignSelf: 'center',
            whiteSpace: 'nowrap',
            '&Wrapper': {
              minHeight: '56px',
              borderInline: '8px solid var(--tableTitleBg)',
              '> *': {
                ...(!hasIconStart && { '&:first-of-type': { paddingLeft: 2 } }),
                '&:last-of-type': { paddingRight: 2 }
              }
            }
          },
          '&Entry': {
            transition: 'background 200ms',
            '&Wrapper': {
              borderInline: '8px solid white',
              overscrollBehavior: 'auto contain',
              overflowY: 'auto',
              overflowX: 'hidden'
            }
          },
          '&NoData': { gridColumn: `auto / span ${columnCount}` }
        }
      },
      sx
    )}
    {...otherProps}
  >
    {titleChildren && <Typography className="tableWrapper tableTitleWrapper" variant="button" component="div">{titleChildren}</Typography>}
    {entryChildren && <Typography className="tableWrapper tableEntryWrapper" variant="body2" component="div">{entryChildren}</Typography>}

    {!entryChildren.length && (
      <Stack
        className="tableNoData"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100px',
          background: grey[90],
          opacity: 0.6
        }}
      >
        {noDataPlaceholder}
      </Stack>
    )}
  </Box>
)

TableWrapper.propTypes = {
  /**
   * for creating the first line of the table
   *
   * for example -> multiple TableTitle elements
   */
  titleChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  /**
   * for creating the body of the table
   *
   * for example -> multiple TableRows elements
   */
  entryChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  /** columnCount for css calculations */
  columnCount: PropTypes.number.isRequired,
  /** this enables vertical gutter lines */
  hasVerticalGutter: PropTypes.bool,
  sx: PropTypes.any
}

export const ExpandContainer = ({
  isExpanded,
  columnCount,
  heading,
  onClose,
  children,
  contentSx = {}
}) => {
  const [ innerRef, setInnerRef ] = useState(null)
  const [ wrapperRef, setWrapperRef ] = useState(null)

  useMemo(() => {
    if (wrapperRef && innerRef) {
      const observer = new ResizeObserver(() => {
        const { height } = innerRef.getBoundingClientRect()

        // set innerContent height for css transition
        wrapperRef.style.setProperty('--animationHeight', height)
        // set animation speed. 420px/s
        wrapperRef.style.setProperty('--animationSpeed', (height * 1000) / 420)
      })

      observer.observe(innerRef)
    }
  }, [ wrapperRef, innerRef ])

  return (
    <Box
      component="section"
      className="tableExpandContainer"
      ref={(ref) => setWrapperRef(ref)}
      sx={[
        {
          gridColumn: `auto / span ${columnCount}`,
          background: 'var(--tableRowBg)',
          overflow: 'hidden',
          maxHeight: 'calc(var(--animationHeight, 1000) * 1px)',
          transition: 'max-height ease-in-out calc(var(--animationSpeed, 500) * 1ms), background 200ms'
        },
        !isExpanded && { maxHeight: 0 }
      ]}
    >
      <Stack
        className="tableExpandContainerInner"
        ref={(ref) => setInnerRef(ref)}
        sx={{
          gap: 3,
          position: 'relative',
          paddingTop: 2,
          paddingBottom: 4,
          paddingInline: 5,
          '&::before': {
            position: 'absolute',
            top: 0,
            right: 16,
            left: 16,
            height: '1px',
            background: '#e0e0e0',
            content: '""'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" component="div">{heading}</Typography>

          <CloseButton
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
            sx={{ background: 'var(--tableRowBg)' }}
          />
        </Box>

        <Box sx={contentSx}>{children}</Box>
      </Stack>
    </Box>
  )
}

ExpandContainer.propTypes = {
  heading: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node
}
