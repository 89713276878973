/* eslint-disable max-len */
import { forwardRef, useEffect, useRef, useState, useImperativeHandle, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Checkbox, FormControlLabel, Pagination, Stack, TablePagination, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { CacheKey, CacheStorage, CacheTTL } from '../../../data'
import { useCache, useInteraction, useNotification } from '../../../hooks'
import { formatNumber, mergeSxProps, sanitizeLocalSearchQuery, sanitizeSearchQuery } from '../../../util'
import { SearchBar } from '../searchBar/SearchBar'
import { SearchBarAnimated } from '../searchBarAnimated/SearchBarAnimated'
import { ApiAdapter } from './adapter/ApiAdapter'
import { LocalAdapter } from './adapter/LocalAdapter'
import { GenericSearchActiveFilters } from './GenericSearchActiveFilter'
import { PreselectedBehavior } from './GenericSearchConstants'
import { GenericSearchFilter } from './GenericSearchFilter'
import { GenericSearchNoResults } from './GenericSearchNoResults'
import { GenericSearchSort } from './GenericSearchSort'
import { GenericSearchViews } from './GenericSearchViews'

/**
 * **GenericSearch Component**
 *
 * **Attention**: Examples in this documentation are based on the ``AppCard``. However, the component can be customized as needed.
 *
 * The `GenericSearch` component is a versatile UI element designed to simplify the process
 * of searching, filtering, and displaying data fetched from an API source. It offers a wide range
 * of configuration options to tailor its behavior and appearance according to your needs.
 *
 * The component is highly customizable and can include various configurations like search,
 * filtering, sorting, and view rendering. Each of these configurations can be fine-tuned or
 * omitted based on your requirements.
 *
 * ** Reference **
 * - *refetch()*: Refetches the data
 * - *refetchSilent()*: Refetches the data without setting loading state to true. Note, that it will still set loading to false after successful fetching
 * - *forceLoadingUntilNextFetch()*: Sets loading to true until next successful fetch
 * - *reset()*: Resets the page to 0
 * - *clear()*: Resets all filters back to default
 * - *activeSearch*: Current active search state
 * - *activeView*: Current active view state
 * - *activeSort*: Current active sort state
 * - *activeFilters*: Current active filters state
 * - *activePage*: Current active page state
 * - *activeRowsPerPage*: Current active rows per page state
 * - *selectedData*: Current data state
 *
 * **Search Configuration**
 *
 * The search configuration allows customization of the search functionality including the search type, label, API filters, and preselected behavior.
 * - *type*: Specifies the type of search input (e.g., 'big', 'small').
 * - *label*: The label or placeholder text for the search input field.
 * - *apiFilters*: Array of field names to be used as filters if the API doesn't support native search functionality.
 * - *preselectedBehavior*: Determines the behavior when preselected search values are given (e.g., 'disable' to disable search).
 *
 * **Example:**
 *
 * ```js
 * const searchConfig = {
 *   type: 'big',
 *   label: 'Search for ID, Name, or Status',
 *   apiFilters: ['id', 'name'], // Custom API filter if endpoint does not support `search`
 *   preselectedBehavior: 'disable' // Disables search when preselected is given
 * };
 * ```
 *
 * **Filter Configuration**
 *
 * The filter configuration provides options to manage filters, their display, and behavior including the "all" filter, preselected filter behavior, and individual filter settings.
 * - *isAllShown*: Controls the display of an "all" filter chip that is active when no other filters are selected.
 * - *preselectedBehavior*: Specifies the behavior when preselected filters are given (e.g., 'disableFilterGroup', 'disableFilter', 'hideFilterGroup').
 * - *filters*: An object containing individual filter settings. Each key represents a filter field sent to the API.
 *   - *label*: The label or name of the filter.
 *   - *icon*: Icon associated with the filter.
 *   - *searchLabel*: Custom search label of the filter.
 *   - *allLabel*: Custom all label of the filter.
 *   - *isSearchShown*: Defines if search is shown in filter popover. Defaults to true.
 *   - *isAllShown*: Defines if all button is shown in filter popover. Defaults to true.
 *   - *isMultiselect*: Defines if the filter are multiselect.
 *   - *isAutoComplete*: Defines if the filter is a AutoComplete on mobile.
 *   - *filters*: Array of filter options with values, labels, and icons.
 *
 *  **Note:**
 *  - if the desired configuration should contain single filters (without filter popup, like 'All' filter),
 *  filter with key name 'single' should be provided. Check the example below. See [UserGroupSearch](?path=/docs/alice-ui-userelements-usergroupsearch--docs)
 *
 *  **Single filters set example:**
 *
 * ```js
 *  const filterConfig = {
 *       isAllShown: true, // Displays an "all" filter chip that is active when no filter is selected.
 *       preselectedBehavior: preselectedFilterBehavior, // Desired preselected behaviour
 *       filters: {
 *       // It is mandatory to set object key to `single` in order to have single filters
 *         single: {
 *           label: 'Filter by:',
 *           filters: [
 *             {
 *               filterOverwrite: 'users.id', // `filterOverwrite` is used as object key in normal configuration to provide proper API call
 *               value: 'D2UNIQ',
 *               label: 'Cool label'
 *             },
 *             {
 *               filterOverwrite: 'owners.id', // `filterOverwrite` is used as object key in normal configuration to provide proper API call
 *               value: 'D2UNIQ',
 *               label: 'Cool label'
 *             }
 *           ]
 *         }
 *       }
 *     }
 * ```
 *
 * **Example:**
 *
 * ```js
 * const filterConfig = {
 *   isAllShown: true, // Displays an "all" filter chip that is active when no filter is selected.
 *   preselectedBehavior: 'disableFilterGroup', // Disables the filter group when preselected filters are given. Possible values (`disableFilterGroup`, `disableFilter`, `hideFilterGroup`)
 *   filters: {
 *     // Object key needs to match the field that will be sent to the API
 *     isActive: {
 *       label: 'Status',
 *       icon: <SignalWifiStatusbar4BarIcon />,
 *       filters: [
 *         {
 *           value: 'true',
 *           label: 'Active',
 *           icon: <CheckCircleIcon />
 *         }
 *       ]
 *     }
 *   }
 * }
 * ```
 *
 * **Sort Configuration**
 *
 * The sort configuration defines sorting options such as preselected behavior and available sorting criteria.
 * - *preselectedBehavior*: Determines the behavior when preselected sorting values are given (e.g., 'disable' to disable sorting).
 * - *sorts*: Array of objects specifying sorting options.
 *   - *value*: Sorting criteria to be sent to the API.
 *   - *label*: The label or name of the sorting option.
 *
 * **Example:**
 *
 * ```js
 * const sortConfig = {
 *   preselectedBehavior: 'disable', // Disables sort when preselected
 *   sorts: [
 *     {
 *       value: '+id', // This needs to be the same as the key that our API requires
 *       label: 'Title: A - Z'
 *     }
 *   ]
 * }
 * ```
 *
 * **View Configuration**
 *
 * While `GenericSearch` manages data but not display, you can pass a `viewConfig` to handle rendering.
 * For each view, provide a unique key and specify `tooltip`, `icon`, and `renderer`. The renderer is
 * a React component that displays data. It receives additional props like `view`, `data`, `selectedData`,
 * `rowsPerPage`, `isMultiselect`, `isLoading`, `isMobile` and `onClick`.
 *
 * **Example:**
 *
 * ```js
 * export const UserGridViewRenderer = ({
 *   view,
 *   data: users,
 *   selectedData: selectedUsers,
 *   rowsPerPage,
 *   isLoading,
 *   isMobile,
 *   onClick,
 *   { ...rendererProps }
 * }) => (
 *   // Your view rendering logic here
 * );
 *
 * const viewConfig = {
 *   grid: {
 *     tooltip: 'Grid View',
 *     icon: <GridViewOutlined />,
 *     renderer: UserGridViewRenderer
 *   },
 *   // Add other view configurations as needed
 * };
 * ```
 * ** Preselection **
 *
 * Preselection provides users with the ability to define or predefine their search, sort, view, filter, or the number of items displayed per page.
 *
 * Object Structure:
 *
 * - *preselectedSearch*: A string holding the predefined search query.
 * - *preselectedView*: A string representing the key of the view intended to be preselected.
 * - *preselectedSort*: A string containing the predefined sorting value.
 * - *preselectedRowsPerPage*: A number specifying the predefined number of rows (items) to display per page.
 * - *preselectedFilter*: An object comprising key-value pairs. The key corresponds to the object key of the filter, while the value is an array of selected filter IDs. Note that single select also requires an array.
 *
 * ```jsx
 * <GenericSearch
 *    preselectedSearch={'Test'}
 *    preselectedView={'list'}
 *    preselectedSort={'+name'}
 *    preselectedRowsPerPage={12}
 *    preselectedFilter={{
 *      isActive: [ 'false' ],
 *      community: [ 'DEALER_EMPLOYEE', 'CORPORATE_CUSTOMER_EMPLOYEE' ],
 *      country: [ 'DE' ]
 *    }}
 * />
 * ```
 *
 * The above example demonstrates how to use the GenericSearch component with preselected values for search, view, sort, rows per page, and filters, allowing users to experience a predefined setup upon component initialization.
 *
 * ** Preselected behavior **
 *
 * Preselected behavior offers a versatile way to customize the behavior of search, sorting, and filtering when preselected values are provided.
 *
 * - *Search and Sort*: Both Search and Sort functionalities provide an option to disable when preselected values are provided.
 * - *Filter*: Filtering, however, offers several options:
 *    - *disableFilter*: Disables a single filter in a multiselect scenario. When utilized with a single select filter group (FilterCard on desktop or Select on mobile), it will disable that specific filter.
 *    - *disableFilterGroup*: Disables the entire filter group (FilterCard on desktop or Select on mobile) when preselected filters are given.
 *    - *hideFilterGroup*: Hides the entire filter group (FilterCard on desktop or Select on mobile) when preselected filters are given.
 *
 * ** Filter customization, API restrain set **
 *
 * With filter customization is allowed to define data that is going to be shown (Provide API restrictions on showing Data).
 *
 * - *filterConfig*: `filters` in `filterConfig` must contain only desired options.
 * - *preselectedFilter*: `preselectedFilter` prop must be provided copying desired options in filters.
 * - *isRestrictedDataHidden*: `isRestrictedDataHidden={true}` prop must be provided.
 *
 * Check out example of Filter customization in [OrgSearch](./?path=/story/alice-ui-orgelements-orgsearch--with-api-restrain-set).
 * Note that it works the same way in all searches.
 *
 * ```js
 * const filterConfigRestrictedCommunities = {
 *   isAllShown: true,
 *   filters: {
 *   // other filters provided as needed
 *     community: {
 *       label: 'Community',
 *       searchLabel: 'Search community',
 *       allLabel: 'All communities',
 *       isMultiselect: true,
 *       icon: <CorporateFareIcon />,
 *       filters: [ // filters receive only those options that are desired to display
 *         {
 *           value: 'DEALER_EMPLOYEE',
 *           label: 'Dealer',
 *           icon: <AppropriateIcon />
 *         },
 *         {
 *           value: 'CORPORATE_CUSTOMER_EMPLOYEE',
 *           label: 'Customer',
 *           icon: <AppropriateIcon />
 *         }
 *       ]
 *     }
 *   }
 * }
 * ```
 *
 * ```jsx
 * <GenericSearch
 *    apiUrl={'/appropriateApiUrl'}
 *    isRestrictedDataHidden
 *    preselectedFilter={{
 *      community: [ 'DEALER_EMPLOYEE', 'CORPORATE_CUSTOMER_EMPLOYEE' ]
 *    }}
 *    // Other desired/important props
 * />
 * ```
 */
export const GenericSearch = forwardRef((
  {
    title,
    description,
    apiBaseUrl = '/gems',
    apiUrl,
    interaction = Interaction.NONE,
    isCaching,
    cacheKey = CacheKey.genericSearchQuery,
    isSelectAllHidden,
    isTopPaginationHidden,
    isBottomPaginationHidden,
    isAppliedFiltersHidden,
    isForceLoading,
    selectableRowsPerPage = [ 3, 6, 12, 24, 48 ],
    preselectedRowIds = [],
    preselectedSearch,
    preselectedView,
    preselectedSort,
    preselectedFilter,
    preselectedRowsPerPage,
    searchConfig = {},
    filterConfig = { filters: {} },
    viewConfig = { views: {} },
    sortConfig = { sorts: [] },
    disabledIds = [],
    getUniqueIdentifier = (data) => data?.id,
    getResponseData = (data) => [],
    onItemClick: _onItemClick = (id, selected, data) => {},
    onSelectAll = (selectedItems) => {},
    onSearch,
    onGetHref = ({ id }) => null,
    rendererProps,
    customSlot1,
    customSlot2,
    localData: _localData,
    isRestrictedDataHidden = false,
    onActiveSearchChange,
    onActiveFiltersChange,
    sx = {} || [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')
  const { notify } = useNotification()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const views = useMemo(() => Object.keys(viewConfig?.views || {}), [ viewConfig?.views ])
  const filters = useMemo(() => Object.keys(filterConfig?.filters || {}), [ filterConfig?.filters ])
  const sorts = useMemo(() => sortConfig?.sorts || [], [ sortConfig?.sorts ])

  const { isMultiselect, isSelectable } = useInteraction({ interaction })

  const isLocalSearch = Array.isArray(_localData)
  const isViewChangeable = views.length > 1
  const isFilterable = !!filters.length
  const isSortable = !!sorts.length

  const isAllSelectable = isMultiselect && !isSelectAllHidden

  const {
    getCachedItem,
    setCachedItem
  } = useCache(CacheStorage.sessionStorage, CacheTTL.oneDay)

  const controllerRef = useRef(null)
  const searchBarRef = useRef(null)

  const getAndVerifyCachedData = useCallback(() => {
    if (!isCaching) return {}

    const cachedData = getCachedItem(cacheKey)

    if (!cachedData || cachedData.apiUrl !== apiUrl) return {}

    // check if cached view is valid
    if (views.indexOf(cachedData.activeView) === -1) {
      // eslint-disable-next-line prefer-destructuring
      cachedData.activeView = views[0]
    }

    // check if cached filters are valid
    for (const filterKey of Object.keys(cachedData.activeFilters)) {
      if (filters.indexOf(filterKey) === -1) {
        // eslint-disable-next-line prefer-destructuring
        cachedData.activeFilters = {}
        break
      }
    }

    // check if cached sort is valid
    const sortIds = sorts.map(({ value }) => value)
    if (sortIds.indexOf(cachedData.activeSort) === -1) {
      // eslint-disable-next-line prefer-destructuring
      cachedData.activeSort = sortIds[0]
    }

    if (selectableRowsPerPage.indexOf(cachedData.activeRowsPerPage) === -1) {
      // eslint-disable-next-line prefer-destructuring
      cachedData.activeRowsPerPage = 12
    }

    return cachedData
  }, [ apiUrl, cacheKey, filters, getCachedItem, isCaching, selectableRowsPerPage, sorts, views ])

  const getInitialValue = (
    useCache,
    preselected,
    cached,
    defaultValue
  ) => preselected || (useCache && cached) || defaultValue

  const [ {
    activeSearch: cachedSearch,
    activeView: cachedView,
    activeSort: cachedSort,
    activeFilters: cachedFilter,
    activeRowsPerPage: cachedRowsPerPage,
    count: cachedCount
  } ] = useState(() => getAndVerifyCachedData())

  const [ data, setData ] = useState([])
  const [ localData, setLocalData ] = useState(_localData)
  const [ isFetching, setIsFetching ] = useState(true)

  const [ activeSearch, setActiveSearch ] = useState(getInitialValue(isCaching, preselectedSearch, cachedSearch, ''))
  const [ activeView, setActiveView ] = useState(getInitialValue(isCaching, preselectedView, cachedView, views[0]))
  const [ activeSort, setActiveSort ] = useState(getInitialValue(isCaching, preselectedSort, cachedSort, sorts[0]?.value || ''))
  const [ activeFilters, setActiveFilters ] = useState(getInitialValue(isCaching, preselectedFilter, cachedFilter, {}))
  const [ activePage, setActivePage ] = useState(0)
  const [ activeRowsPerPage, setActiveRowsPerPage ] = useState(getInitialValue(isCaching, preselectedRowsPerPage, cachedRowsPerPage, 12))

  const [ selectedData, setSelectedData ] = useState([ ...preselectedRowIds ])

  const [ count, setCount ] = useState(isCaching && cachedCount ? cachedCount : 0)

  const possibleSingleFilters = filterConfig?.filters?.single?.filters.reduce((acc, current) => {
    acc[current.filterOverwrite] = current
    return acc
  }, {})

  const possibleFilters = filterConfig?.filters?.single ? possibleSingleFilters : filterConfig?.filters

  const isLoading = isFetching || isForceLoading
  const RendererComponent = viewConfig?.views[activeView]?.renderer

  const setCache = useCallback((url, count, data) => {
    if (isCaching) {
      setCachedItem(cacheKey, {
        apiUrl,
        url,
        activeSearch,
        activeView,
        activeSort,
        activeFilters,
        count,
        activeRowsPerPage,
        data
      })
    }
  }, [ activeFilters, activeRowsPerPage, activeSearch, activeSort, activeView, apiUrl, cacheKey, isCaching, setCachedItem ])

  useEffect(() => {
    if (isCaching) {
      const cachedData = getCachedItem(cacheKey)

      if (cachedData) {
        const {
          url,
          count,
          data
        } = cachedData

        setCache(url, count, data)
      }
    }
  }, [ cacheKey, getCachedItem, isCaching, setCache ])

  useEffect(() => {
    if (typeof onActiveSearchChange === 'function') {
      onActiveSearchChange(activeSearch)
    }
  }, [ onActiveSearchChange, activeSearch ])

  useEffect(() => {
    if (typeof onActiveFiltersChange === 'function') {
      onActiveFiltersChange(activeFilters)
    }
  }, [ onActiveFiltersChange, activeFilters ])

  const isSelectAllChecked = () => !isLoading && data.every((data) => selectedData.includes(getUniqueIdentifier(data)))

  const isSelectAllIndeterminate = () => {
    if (isLoading || !selectedData.length) return false

    let counter = 0

    data.forEach((data) => {
      if (selectedData.includes(getUniqueIdentifier(data))) counter++
    })

    return counter !== 0 && counter !== data.length
  }

  const handleReset = () => {
    setActivePage(0)
  }

  const handleClear = () => {
    if (
      filterConfig.preselectedBehavior !== PreselectedBehavior.DISABLE
      && filterConfig.preselectedBehavior !== PreselectedBehavior.DISABLE_FILTER
      && filterConfig.preselectedBehavior !== PreselectedBehavior.DISABLE_FILTER_GROUP
    ) {
      setActiveFilters({})
    }

    if (searchConfig.preselectedBehavior !== PreselectedBehavior.DISABLE) {
      setActiveSearch('')
      searchBarRef?.current?.setQuery('')
    }
  }

  const handleSearchChange = (newSearch) => {
    handleReset()

    setActiveSearch(newSearch)
  }

  const handleSearchClear = () => {
    if (searchConfig.preselectedBehavior === PreselectedBehavior.DISABLE) return

    handleReset()

    setActiveSearch('')
    searchBarRef?.current?.setQuery('')
  }

  const handleFilterChange = (filterKey, filterValue) => {
    handleReset()

    const newFilter = { ...activeFilters }
    newFilter[filterKey] = filterValue

    setActiveFilters(newFilter)
  }

  const handleFiltersChange = (value) => {
    handleReset()

    setActiveFilters(value)
  }

  const handleFilterDelete = (filterKey, filterValue) => {
    const newFilter = { ...activeFilters }
    newFilter[filterKey] = newFilter[filterKey].filter((filter) => filter !== filterValue)

    setActiveFilters(newFilter)
  }

  const handleFilterClear = () => setActiveFilters(preselectedFilter || {})

  const handleSortChange = (newSort) => {
    handleReset()

    setActiveSort(newSort)
  }

  const handleViewChange = (newView) => setActiveView(newView)
  const handlePageChange = (event, newPage) => setActivePage(newPage)

  const handleRowsPerPageChange = (event) => {
    handleReset()

    setActiveRowsPerPage(event.target.value)
  }

  const onItemClick = ({
    id,
    isSelected,
    data
  }) => {
    if (isSelectable) {
      const isIdSelected = selectedData.includes(id)

      if (isIdSelected) {
        setSelectedData(selectedData.filter((currId) => currId !== id))
      } else if (isMultiselect) {
        setSelectedData([ ...selectedData, id ])
      } else {
        setSelectedData([ id ])
      }
    }

    if (typeof _onItemClick === 'function') {
      isSelectable
        ? _onItemClick(id, isSelected, data)
        : _onItemClick(id, data)
    }
  }

  const handleSelectAllChange = (evt, isSelected) => {
    let newSelectedData = [ ...selectedData ]

    data.forEach((data) => {
      const uniqueIdentifier = getUniqueIdentifier(data)
      // checks if selectedData includes items avoiding disabledIds
      const containsItem = selectedData.includes(uniqueIdentifier)
      const isDisabled = disabledIds.includes(uniqueIdentifier)

      if (!isDisabled && isSelected && !containsItem) {
        newSelectedData = [ ...newSelectedData, uniqueIdentifier ]
      }

      if (!isDisabled && !isSelected && containsItem) {
        newSelectedData = newSelectedData.filter((item) => item !== uniqueIdentifier)
      }
    })

    setSelectedData(newSelectedData)

    if (typeof onSelectAll === 'function') onSelectAll(newSelectedData)
  }

  // handle localData update
  useEffect(() => {
    handleReset()
    setLocalData(_localData)
  }, [ isLocalSearch, _localData ])

  // handle any data update
  useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeSearch, activeSort, activeFilters, activePage, activeRowsPerPage, localData ])

  const updateData = async (silent = false) => {
    setIsFetching(!silent)

    if (controllerRef.current) controllerRef.current.abort()

    controllerRef.current = new AbortController()

    const cachedItem = getCachedItem(cacheKey)

    try {
      const sanitizedActiveSearch = isLocalSearch
        ? sanitizeLocalSearchQuery(activeSearch)
        : sanitizeSearchQuery(activeSearch)

      const response = isLocalSearch
        ? await LocalAdapter.getData(localData, activePage, activeRowsPerPage, sanitizedActiveSearch, activeSort, activeFilters)
        : await ApiAdapter.getData({ signal: controllerRef.current.signal }, cachedItem, isCaching, apiBaseUrl, apiUrl, controllerRef, activePage, activeRowsPerPage, activeSort, filterConfig, activeFilters, sanitizedActiveSearch, searchConfig, preselectedFilter, isRestrictedDataHidden)

      if (Array.isArray(response)) {
        setData(response)
        setIsFetching(false)
      } else {
        const count = response?.data?.rels.totalCount
        const data = getResponseData(response?.data)
        const url = response?.url

        setCount(count)
        setData(data)
        setCache(url, count, data)
        setIsFetching(false)
      }
    } catch (error) {
      if (error.name === 'CanceledError') {
        // We don't want to set loading to false in this case
        console.warn(
          `Request aborted: ${error.message}. Requested url: ${error.url}`
        )
      } else {
        console.error(error)

        notify({
          severity: 'error',
          message: error.message
        })

        setIsFetching(false)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    refetch: () => updateData(false),
    refetchSilent: () => updateData(true),
    forceLoadingUntilNextFetch: () => setIsFetching(true),
    reset: handleReset,
    clear: handleClear,
    activeSearch,
    activeView,
    activeSort,
    activeFilters,
    activePage,
    activeRowsPerPage,
    data,
    setData,
    selectedData,
    setSelectedData
  }), [ activeSearch, activeView, activeSort, activeFilters, activePage, activeRowsPerPage, selectedData, data ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      data-testid="GenericSearch"
      sx={mergeSxProps([
        {
          '> *': { mb: 3 },
          display: 'grid',
          rowGap: 0,
          columnGap: 2,
          gridTemplateAreas: `
            "title title title"
            "search-large search-large search-large"
            "views views views"
            "filter filter sort"
            "active-filter active-filter active-filter"
            "pagination-top pagination-top pagination-top"
            "content content content"
            "pagination-bottom pagination-bottom pagination-bottom"
          `,
          gridTemplateColumns: '1fr 1fr 1fr'
        },
        isMobile && {
          gridTemplateAreas: `
            "title title title"
            "active-filter active-filter active-filter"
            "search-large search-large search-large"
            "filter filter filter"
            "pagination-top pagination-top pagination-top"
            "content content content"
            "pagination-bottom pagination-bottom pagination-bottom"
          `
        }
      ], sx)}
      gap={3}
      {...otherProps}
    >
      {(title || description) && (
        <Stack sx={{ gridArea: 'title' }}>
          {title && <Typography variant="h2">{title}</Typography>}
          {description && <Typography sx={{ fontSize: '16px' }}>{description}</Typography>}
        </Stack>
      )}

      {searchConfig?.type === 'large' && (
        <SearchBar
          ref={searchBarRef}
          fullWidth
          label={searchConfig.label}
          preselectQuery={activeSearch}
          disabled={searchConfig?.preselectedBehavior === PreselectedBehavior.DISABLE && !!preselectedSearch}
          onSearch={handleSearchChange}
          onClear={handleSearchClear}
          sx={{ gridArea: 'search-large' }}
        />
      )}

      {(isViewChangeable || customSlot1) && !isMobile && (
        <Box
          sx={{
            gridArea: 'views',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {customSlot1}

          {isViewChangeable && (
            <GenericSearchViews
              views={viewConfig?.views}
              activeView={activeView}
              onChange={handleViewChange}
              sx={{ marginLeft: 'auto' }}
            />
          )}
        </Box>
      )}

      {!!Object.keys(activeFilters).length && !isAppliedFiltersHidden && (
        <GenericSearchActiveFilters
          possibleFilters={possibleFilters}
          activeFilters={activeFilters}
          preselectedFilter={preselectedFilter}
          preselectedFilterBehavior={filterConfig?.preselectedBehavior}
          onDelete={handleFilterDelete}
          onClear={handleFilterClear}
          isSingleFilter={!!filterConfig?.filters?.single}
          sx={{ gridArea: 'active-filter' }}
        />
      )}

      {(isFilterable || isSortable || (isMobile && customSlot1)) && (
        <Box
          sx={{
            gridArea: 'filter',
            display: 'flex',
            flexFlow: 'column',
            gap: 2
          }}
        >
          {isMobile && customSlot1}

          <GenericSearchFilter
            possibleFilters={filterConfig?.filters}
            activeFilter={activeFilters}
            preselectedFilter={preselectedFilter}
            preselectedFilterBehavior={filterConfig?.preselectedBehavior}
            possibleSorts={sortConfig?.sorts}
            preselectedSort={preselectedSort}
            preselectedSortBehavior={sortConfig?.preselectedBehavior}
            activeSort={activeSort}
            isAllShown={filterConfig?.isAllShown}
            onSortChange={handleSortChange}
            onFilterChange={handleFilterChange}
            onFiltersChange={handleFiltersChange}
            onAllClick={handleFilterClear}
            isMobile={isMobile}
          />
        </Box>
      )}

      {(isSortable || searchConfig?.type === 'small') && !isMobile && (
        <Box
          sx={{
            gridArea: 'sort',
            mt: '6px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 2
          }}
        >
          {searchConfig?.type === 'small' && (
            <SearchBarAnimated
              isAbsolute
              clearOnOpen={false}
              size="normal"
              initialValue={activeSearch}
              disabled={searchConfig?.preselectedBehavior === PreselectedBehavior.DISABLE && !!preselectedSearch}
              label={searchConfig?.label}
              onSearch={handleSearchChange}
            />
          )}

          <GenericSearchSort
            sx={{ width: '275px', mt: '6px' }}
            activeSort={activeSort}
            possibleSorts={sortConfig?.sorts || []}
            preselectedSort={preselectedSort}
            preselectedSortBehavior={sortConfig?.preselectedBehavior}
            onChange={handleSortChange}
          />
        </Box>
      )}

      {(!isTopPaginationHidden || isAllSelectable || customSlot2) && (
        <Box
          sx={[
            {
              gridArea: 'pagination-top',
              display: 'flex',
              alignItems: 'center',
              grid: 2
            },
            isMobile && { flexFlow: 'wrap' }
          ]}
        >
          {isAllSelectable && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isSelectAllChecked()}
                  indeterminate={isSelectAllIndeterminate()}
                  disabled={isLoading}
                />
              )}
              label={t('genericSearch.selectAll')}
              onChange={handleSelectAllChange}
              sx={{ marginLeft: 1 }}
            />
          )}

          {customSlot2 && <Box sx={[ isMobile && { order: -1, width: '100%' } ]}>{customSlot2}</Box>}

          {!isTopPaginationHidden && (
            <TablePagination
              component="div"
              count={count}
              page={activePage}
              onPageChange={handlePageChange}
              rowsPerPage={activeRowsPerPage}
              rowsPerPageOptions={selectableRowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage={`${t('genericSearch.rowsPerPageLabel')}:`}
              labelDisplayedRows={({
                from,
                to,
                count
              }) => t('genericSearch.displayRowsLabel', {
                from: formatNumber({ num: from }),
                to: formatNumber({ num: to }),
                count: formatNumber({ num: count })
              })}
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              sx={[
                { marginLeft: 'auto' },
                isMobile && {
                  '.MuiToolbar-root': { paddingInline: 0 },
                  '.MuiTablePagination-spacer': { order: 2 },
                  '.MuiTablePagination-selectLabel': { order: 0 },
                  '.MuiTablePagination-select': { order: 1 },
                  '.MuiTablePagination-displayedRows': { order: 3 },
                  '.MuiTablePagination-actions': { order: 4 }
                }
              ]}
            />
          )}
        </Box>
      )}

      <Box sx={{ maxWidth: '100%', gridArea: 'content' }}>
        {((data && !!data.length) || isLoading)
          ? (
            <RendererComponent
              view={activeView}
              data={data}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              disabledData={disabledIds}
              rowsPerPage={activeRowsPerPage}
              interaction={interaction}
              isLoading={isLoading}
              isMobile={isMobile}
              onGetHref={onGetHref}
              onItemClick={onItemClick}
              {...rendererProps}
            />
          )
          : <GenericSearchNoResults onClear={handleClear} />}
      </Box>

      { /* Pagination */}
      {!isBottomPaginationHidden && data && !!data.length && (count / activeRowsPerPage) > 1 && (
        <Pagination
          count={Math.ceil(count / activeRowsPerPage)}
          page={activePage + 1}
          onChange={(event, page) => { handlePageChange(event, page - 1) }}
          color="primary"
          size="small"
          sx={{
            gridArea: 'pagination-bottom',
            display: 'flex',
            justifyContent: 'center'
          }}
        />
      )}
    </Box>
  )
})

GenericSearch.propTypes = {
  /** The title to display for the search component. */
  title: PropTypes.string,
  /** A brief description or subtitle for the search component. */
  description: PropTypes.string,
  /** The URL of the API to fetch data from. */
  apiUrl: PropTypes.string,
  interaction: InteractionPropTypes,
  /** Specifies whether caching of API responses is enabled. */
  isCaching: PropTypes.bool,
  /** The key to use for caching API responses. */
  cacheKey: PropTypes.string,
  /** An array of preselected row IDs. */
  preselectedRowIds: PropTypes.arrayOf(PropTypes.string),
  /** A preselected search term. */
  preselectedSearch: PropTypes.string,
  /** A preselected view configuration. */
  preselectedView: PropTypes.string,
  /** A preselected sorting configuration. */
  preselectedSort: PropTypes.string,
  /** A preselected filter configuration. */
  preselectedFilter: PropTypes.object,
  /** An array of preselected rows per page options. */
  preselectedRowsPerPage: PropTypes.number,
  /** Configuration for the search input. */
  searchConfig: PropTypes.shape({
    /** Type of search input (none/small/large). */
    type: PropTypes.oneOf([ 'none', 'small', 'large' ]),
    /** Label for the search input. */
    label: PropTypes.string,
    /** Array of API filters to apply based on search. */
    apiFilters: PropTypes.arrayOf(PropTypes.string),
    /** Behavior when preselected */
    preselectedBehavior: PropTypes.string
  }),
  /** Configuration for the filters. */
  filterConfig: PropTypes.shape({
    /** Defines if all FilterCard should be displayed */
    isAllShown: PropTypes.bool,
    /** Behavior when preselected */
    preselectedBehavior: PropTypes.string,
    /** Array of filter options with labels and icons. */
    filters: PropTypes.object
  }),
  /** Configuration for different views. */
  viewConfig: PropTypes.shape({
    views: PropTypes.objectOf(PropTypes.shape({
      /** Tooltip text or JSX node for the view option. */
      tooltip: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
      /** Icon for the view option. */
      icon: PropTypes.any,
      /** Renderer for the view. */
      renderer: PropTypes.any
    }))
  }),
  /** Array of sorting configurations. */
  sortConfig: PropTypes.shape({
    /** Behavior when preselected */
    preselectedBehavior: PropTypes.string,
    /** All possible sorts */
    sorts: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }))
  }),
  /** List of disabled ids */
  disabledIds: PropTypes.arrayOf(PropTypes.string),
  /** Function to retrieve a unique identifier for items. */
  getUniqueIdentifier: PropTypes.func,
  /** Function to extract data from API response. */
  getResponseData: PropTypes.func,
  /** Function to handle item click. */
  onItemClick: PropTypes.func,
  /** Function to handle select all action. */
  onSelectAll: PropTypes.func,
  /** Function to handle href acquirement in interaction mode "navigate" */
  onGetHref: PropTypes.func,
  /** a custom slot to put additional elements -> next to the views section */
  customSlot1: PropTypes.node,
  /** a custom slot to put additional elements -> next to the topPagination section */
  customSlot2: PropTypes.node,
  /** determines if GenericSearch should use local data without calling API */
  isLocalSearch: PropTypes.bool,
  /** local data to operate on without calling API */
  localData: PropTypes.array,
  /** determines if GenericSearch should show data that does not belong to the preselected filters */
  isRestrictedDataHidden: PropTypes.bool,
  /** Special case. Defines a function with active filters setter in the parent component.
   * In case dynamic active filters chang is required
   */
  onActiveFiltersChange: PropTypes.func
}
