import PropTypes from 'prop-types'
import { FmdGoodOutlined, OpenInNewOutlined } from '@mui/icons-material'
import { Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePermission } from '../../../hooks'
import { getCountryByIsoCode, getLink } from '../../../util'
import { StatusChip } from '../../chip'
import { CountryFlag } from '../../icon'
import { Tooltip } from '../../message'
import { FocusChip } from '../../projectFocus'
import { DetailItemType, DetailsList, useDetailItem } from '../../card/genericCard'

const Title = ({ sx, children }) => (
  <Typography
    variant="caption"
    sx={{
      color: 'grey.60',
      ...sx
    }}
  >
    {children}
  </Typography>
)

const Content = ({ sx, children }) => (
  <Typography
    variant="subtitle1"
    sx={{ ...sx }}
  >
    {children}
  </Typography>
)

export const OrgContext = ({ data = null }) => {
  const { t } = useTranslation('componentLibrary')
  const { isAdmin } = usePermission()
  const { getDetailItem } = useDetailItem()

  // ORG
  const community = data?.communities?.length > 0
    ? t(`orgCommunity.${data.communities[0]}`)
    : null

  const name = data?.name
  const groupType = data?.orgGroupType?.toString()
  const country = data?.country
  const communities = data?.communities
  const orgForm = data?.orgForm
  const orgFormTitle = data?.orgForm && t(`orgForm.${data?.orgForm}`)

  const isCommunityShown = community && communities[0]
  const isOrgTypeShown = orgFormTitle && orgForm

  const address = []

  data?.postalCode
  && data?.location
  && address.push(`${data.postalCode} ${data.location}`)

  data?.street && address.push(data.street)

  const isoCountry = getCountryByIsoCode(data?.country)?.label
  isoCountry && address.push(isoCountry)

  const addressToShow = address.join(', ')

  const status = data?.isActive !== undefined ? data?.isActive ? 'active' : 'inactive' : null
  const outletId = data?.gssnData?.outletId
  const technicalId = data?.id
  const dealerNumber = data?.gssnData?.outletCode

  return (
    <>
      {/* Name Address */}
      <Stack
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          rowGap: 1
        }}
      >
        <Tooltip title={name}>
          <Typography
            variant="h5"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {name}
          </Typography>
        </Tooltip>

        <Stack
          sx={{
            flexDirection: 'row',
            gap: 0.5,
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              color: 'text.secondary',
              fontSize: '14px',
              lineHeight: 0,
              padding: '4px'
            }}
          >
            <FmdGoodOutlined color="inherit" fontSize="inherit" />
          </Box>

          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {addressToShow}
          </Typography>
        </Stack>
      </Stack>

      {/* flag org type status */}
      { (country || groupType || status) && (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: 'blue.95',
            border: '1px solid',
            borderColor: 'blue.90',
            borderRadius: '20px',
            padding: '8px 10px'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center'
            }}
          >
            {country && (
              <CountryFlag
                countryCode={country}
                sx={{ borderRadius: '10px', overflow: 'hidden' }}
              />
            )}

            {groupType && <FocusChip groupType={groupType} sx={{ width: 'fit-content' }} />}
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center'
            }}
          >
            {status && <StatusChip type={status} />}
          </Stack>
        </Stack>
      )}

      <DetailsList
        data={[
          isCommunityShown && getDetailItem({
            type: DetailItemType.ORG_COMMUNITY,
            text: community,
            iconProps: { name: communities[0] }
          }),
          isOrgTypeShown && getDetailItem({
            type: DetailItemType.ORG_FORM,
            text: orgFormTitle,
            iconProps: { name: orgForm }
          })
        ]}
      />

      <Stack
        sx={{
          borderRadius: '4px',
          backgroundColor: 'grey.95',
          padding: '8px 16px',
          gap: 2
        }}
      >
        {outletId && (
          <Stack>
            <Title>Outlet ID</Title>
            <Content>{outletId}</Content>
          </Stack>
        )}

        {technicalId && (
          <div>
            <Title>Technical ID</Title>

            <Link
              href={
                isAdmin
                  ? getLink('orgDetails', { orgId: technicalId })
                  : null
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover > .MuiSvgIcon-root': { opacity: 1 }
              }}
              underline="none"
            >
              <Content>{technicalId}</Content>

              {isAdmin && (
                <OpenInNewOutlined
                  className="hidden"
                  fontSize="inherit"
                  color="inherit"
                  sx={{ opacity: 0, transition: 'opacity 250ms' }}
                />
              )}
            </Link>
          </div>
        )}

        {dealerNumber && (
          <div>
            <Title>{t('orgContext.dealerNumber')}</Title>
            <Content>{dealerNumber}</Content>
          </div>
        )}
      </Stack>
    </>
  )
}

OrgContext.propTypes = { data: PropTypes.object }
