import { grey } from '@mercedes-benz/mui5-theme'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCookie, setCookie } from '../../../util'
import { Button } from '../../button'
import { Dialog } from '../../dialog'
import { LanguageSelect } from '../languageSelect/LanguageSelect'

/* =========================================================== */
/* handle cookie                                               */
/* =========================================================== */
const cookieName = 'firstTimeVisit'

/**
 * The LanguageDialog component is a modal that allows the user to change the language. To utilize its functionality, you need to provide the open and onClose props to make it a controlled component. <br>
 * If the user opens the dialog and doesn't change the language, no action will be taken. However, if the user selects the "do later" option, a cookie will be set with a validity of 7 days. This allows the user to postpone the language selection without losing their preference when revisiting the application. <br>
 * By using the LanguageDialog, you can provide a seamless language selection experience for your users within a modal interface.
 */
export const LanguageDialog = ({
  loading,
  onClose,
  onLanguageChange,
  open,
  userData
}) => {
  const { t, i18n } = useTranslation('componentLibrary')

  const [ prefLanguage, setPrefLanguage ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  // handle firstTimeVisit Cookie
  useEffect(() => {
    const checkCookie = () => getCookie(cookieName)

    if (userData !== null) {
      if (userData.preferredLanguage) setPrefLanguage(userData.preferredLanguage)
      if (userData.preferredLanguage === undefined && !checkCookie()) setIsOpen(true)
    }
  }, [ userData ])

  const handlePrefLanguageChange = (event) => setPrefLanguage(event.target.value)

  const handleClose = () => {
    setIsOpen(false)

    if (onClose) {
      onClose()
    }
  }

  const handleDoItLater = () => {
    setLanguageCookie()
    handleClose()
  }

  const savePrefLanguage = () => {
    if (userData.preferredLanguage !== prefLanguage) {
      i18n.changeLanguage(prefLanguage)
      onLanguageChange(prefLanguage)

      // feelgood, show the loader
      window.setTimeout(handleClose, 200)
    } else {
      handleClose()
    }
  }

  const disableSaveButton = () => !(prefLanguage && prefLanguage !== '')

  const setLanguageCookie = () => {
    const date = new Date()
    const expireTime = new Date(date.getTime() + 1000 * 3600 * 24 * 7) // one week

    setCookie(cookieName, 'visited', { expires: expireTime, path: '/' })
  }

  return (
    <Dialog
      open={open ? isOpen || open : isOpen}
      onClose={handleClose}
      headerSection={(
        <Typography variant="h3">{t('startup.preferred_language')}</Typography>
      )}
      footerSection={!loading && (
        <>
          <Button
            variant="tertiary"
            sx={{
              color: 'primary',
              textDecoration: 'underline',
              '&:hover': { textDecoration: 'underline' }
            }}
            onClick={handleDoItLater}
          >
            {t('general.doItLater')}
          </Button>

          <Button
            variant="primary"
            onClick={savePrefLanguage}
            disabled={disableSaveButton()}
          >
            {t('general.save')}
          </Button>
        </>
      )}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack spacing={2}>
          <Typography>{t('startup.preferred-language-text')}</Typography>

          <LanguageSelect
            value={prefLanguage}
            onChange={handlePrefLanguageChange}
          />

          <Typography
            sx={{
              color: grey[55],
              fontStyle: 'italic'
            }}
          >
            {t('startup.preferred-language-helpertext')}
          </Typography>
        </Stack>
      )}
    </Dialog>
  )
}

LanguageDialog.propTypes = {
  /** Specifies whether the user data is currently loading. */
  loading: PropTypes.bool.isRequired,
  /** Represents the user data of the logged-in user. */
  userData: PropTypes.object,
  /** A function that dispatches the language change in the backend. */
  onLanguageChange: PropTypes.func.isRequired,
  /** Determines whether the dialog should be open. This prop will be overridden if the logged-in user has no preferred language set. */
  open: PropTypes.bool
}
