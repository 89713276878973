import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AliceIcon, Button } from "@alice/component_library";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  AccountsMainContainer,
  AccountHeadText,
  PaperClass,
} from "../../styledComponents";

const MyAccounts = () => {
  const { t } = useTranslation();
  const onClick = () => {
    const url = "https://login.mercedes-benz.com/password/mainframe";
    window.open(url);
  };
  return (
    <AccountsMainContainer>
      <Grid
        container
        spacing={1}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="request-responsive"
      >
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <AccountHeadText>{t("mainframe-account")}</AccountHeadText>
          <PaperClass>{t("mainframe-accnt-desc")}</PaperClass>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            children={
              <>
                <AliceIcon
                  color="#0078D6"
                  iconType="SHARE_EXTERNAL_LINK"
                  size={20}
                />
                {t("MB-pwd-mange-tool")}
                <ChevronRightIcon />
              </>
            }
            onClick={onClick}
            variant="tertiary"
          />
        </Grid>
      </Grid>
    </AccountsMainContainer>
  );
};

export default MyAccounts;
