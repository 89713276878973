import {
  Typography,
  Grid,
  styled,
  MenuItem,
  Select,
  FormControl,
  Chip,
} from "@mui/material";

export const RequestDeatilsContainer = styled("div")(({ ...props }) => ({
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "4px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "#FFF",
  "& .User-Card": {
    padding: "10px",
  },
}));

export const PageContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down("lg")]: {
    margin: "0 20px",
  },
}));

export const SubHeaderTypography = styled((props) => <Typography {...props} />)(
  ({ ...props }) => ({
    fontFamily: "MB Corpo S Text",
    lineHeight: "24px",
    letterSpacing: "0px",
    fontWeight: "400",
    fontSize: "16px",
    color: "rgb(51, 51, 51)",
    margin: "0px 0px 32px",
  })
);

export const SortContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  "& .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
    backgroundColor: "transparent",
  },
});

export const PageContent = styled("div")(({ theme, ...props }) => {
  const { windowsize, isdetailsopen } = props;
  const { isDesktop, isTablet, isMobile } = windowsize;
  return {
    display: "flex",
    flexDirection: isDesktop ? "row" : "column",
    width: "100%",
    paddingBottom: "40px",
    "& .table-container": {
      display: "flex",
      width: "100%",
      marginLeft: isDesktop && "10px",
      position: "relative",
      "& .request-id": {
        display: "flex",
        alignItems: "center",
        minWidth: 0,
        "& svg": {
          color: "#00000099",
        },
        "& .connection-icon": {
          color: "#00000061",
        },

        padding: "0 4px",
      },
      "& .highlight": {
        background: "#EAF4FB",
      },
      "& .status-column-container": {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "10px",
        alignItems: "center",
        width: isDesktop && "100%",
        "& .user-badge": {
          display: "flex",
          gap: "0px 1px",
        },
      },
      //mobile table column styles
      "& .request-details-action": {
        position: "absolute",
        top: "5px",
        right: "2px",
        zIndex: "100",
        "& .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
        "& .MuiIconButton-root": {
          color: "#000000",
        },
        "& .MuiIconButton-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
        },
      },
      "& .task-row-container": {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        paddingLeft: "10px",
        "& .status-column-container": {
          marginLeft: "auto",
          paddingRight: "15px",
        },
        "& .id-status-container": {
          display: "flex",
          width: "100%",
          paddingTop: "8px",
        },
        "& .display-flex": {
          display: "flex",
        },
        "& .task-card-container": {
          display: "flex",
          flexDirection: "column",
          background: "rgb(248, 248, 248)",
          borderRadius: "4px",
          width: "calc(100% - 15px)",
          margin: "6px 0",
          padding: "0 10px",
        },
      },
      //table styles
      "> .DataGridSingle-table": {
        width: "252px",
        display: "flex",
        flexDirection: "column-reverse",
        border: "none",
        "& .MuiDataGrid-overlayWrapperInner .MuiDataGrid-overlay": {
          display: "none",
        },
        "& .MuiDataGrid-selectedRowCount": {
          visibility: "hidden",
          display:
            (!isDesktop || isMobile || isTablet) && isdetailsopen && "none",
        },
        "& .MuiSvgIcon-fontSizeMedium": {
          fontSize: "24px",
        },
        "& .MuiTablePagination-actions": {
          display: !isDesktop && isdetailsopen && "none",
          marginTop: "0px !important",
          marginLeft: "0px !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          display: "flex",
          position: "relative",
          alignItems: "center",
          gap: "521px",
          alignSelf: "stretch",
          borderRadius: "4px 4px 0px 0px",
          border: "1px solid  rgba(0, 0, 0, 0.12)",
          zIndex: 0,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: "MB Corpo S Title",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 700,
            lineheight: "24px",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          // [theme.breakpoints.down("sm")]: {
          //   maxHeight: props.ispending && "100px !important",
          //   height: props.ispending && "100px !important",
          // },
        },
        "& .MuiDataGrid-virtualScroller": {
          overflowX: "hidden",
          height: "auto",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // width: "100% !important",
          minHeight: "445px",
          borderRadius: "0px 0px 4px 4px",
          borderRight: "1px solid  rgba(0, 0, 0, 0.12)",
          borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
          "& .svg-style svg": {
            marginRight: "4px",
          },
          "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row:hover":
            {
              backgroundColor: "rgba(0, 120, 214, 0.16) !important",
            },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            maxWidth: "100%",
            "&:not(:last-child)": {
              borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
            },
            "& .MuiDataGrid-cellCheckbox": {
              alignItems: "start",
            },
            "&::hover": {
              backgroundColor: "rgba(0, 120, 214, 0.16) !important",
            },
            "&::focus": {
              backgroundColor: "rgba(0, 120, 214, 0.16) !important",
            },
          },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            padding: isdetailsopen ? "0" : "0 6px 0 4px",
          },
        },
        "& .MuiDataGrid-columnHeader": {
          background: "rgb(244, 244, 244)",
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-cell": {
          // padding: "0 !important",
          border: "none !important",
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-footerContainer": {
          border: "none",
        },
      },
      "> .DataGridSingle-detail": {
        display: "flex",
        flexDirection: "column-reverse",
        border: "none",
        position: isdetailsopen ? "absolute" : "relative",
        width: "100%",
        "& .MuiDataGrid-main ": {
          width: "15%",
          display: isdetailsopen && !isDesktop && "none",
        },
        "& .MuiDataGrid-row.Mui-hovered": {
          backgroundColor: "rgba(0, 120, 214, 0.16) !important",
        },
        "& .MuiDataGrid-row": {
          borderLeft: "5px solid transparent",
        },
        "& .MuiDataGrid-selectedRowCount": {
          visibility: "hidden",
          display:
            (!isDesktop || isMobile || isTablet) && isdetailsopen && "none",
        },
        "& .MuiDataGrid-row-selected,.MuiDataGrid-row.Mui-selected": {
          background: "rgba(0, 120, 214, 0.16)",
          borderLeft: "5px solid #0078D6",
        },
        "& .MuiTablePagination-actions": {
          display: !isDesktop && isdetailsopen && "none",
          marginTop: "0px !important",
          marginLeft: "0px !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          display: "flex",
          height: "47px",
          alignItems: "center",
          gap: "521px",
          alignSelf: "stretch",
          borderRadius: "4px 4px 0px 0px",
          border: "1px solid  rgba(0, 0, 0, 0.12)",
          zIndex: 0,
          background: "rgb(244, 244, 244)",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: "MB Corpo S Title",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 700,
            lineheight: "24px",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        },
        "& .MuiDataGrid-virtualScroller": {
          overflowX: "hidden",
          height: "auto",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // width: "100% !important",
          minHeight: "445px",
          borderRadius: "0px 0px 4px 4px",
          borderRight: "1px solid  rgba(0, 0, 0, 0.12)",
          borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
          "& .svg-style svg": {
            marginRight: "4px",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            maxWidth: "100%",
            "&:not(:last-child)": {
              borderBottom: "1px solid  rgba(0, 0, 0, 0.12)",
            },
          },

          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            padding: isdetailsopen ? "0" : "0 6px 0 4px",
          },
        },
        "& .MuiDataGrid-columnHeader": {
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-cell": {
          border: "none !important",
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-footerContainer": {
          border: "none",
          marginRight: !isDesktop && isdetailsopen && "auto",
          "& .MuiToolbar-root": {
            paddingLeft: !isDesktop && isdetailsopen && 0,
          },
          "& MuiInputBase-root": {
            marginLeft: !isDesktop && isdetailsopen && 0,
            "& .MuiTablePagination-select": {
              paddingLeft: !isDesktop && isdetailsopen && 0,
            },
          },
        },
      },
    },
    "& .request-details": {
      position: "relative",
      top: "52px",
      left: isDesktop && "15%",
      width: isDesktop ? "calc(100% - 15% - 10px)" : "100%",
      paddingBottom: "52px",
      marginLeft: isDesktop && "10px",
    },
    "& .page20-min-height": {
      minHeight: "950px",
    },
    "& .page15-min-height": {
      minHeight: "750px",
    },
    "& .page10-min-height": {
      minHeight: "600px",
    },
  };
});
export const StatusChips = styled((props) => <Chip {...props} />)(
  ({ ...props }) => ({
    backgroundColor: props.bgcolor,
    height: "24px",
    color: "black",
    fontFamily: " MB Corpo S Text",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: props.bgcolor,
    },
    "& .MuiChip-label": {
      paddingRight: "8px",
      paddingLeft: "8px",
    },
    "& .MuiBox-root": {
      display: "flex",
      alignItem: "center",
    },
  })
);

export const RSubTopicContainer = styled((props) => <Grid item {...props} />)(
  ({ theme, ...props }) => ({
    marginTop: props.marginTop ? "0px" : "24px",
    "& .MuiCollapse-wrapperInner.MuiCollapse-vertical .MuiBox-root>span": {
      //In role card after clicking down arrow
      display: "none !important", // for removing the arrow " ^ "
    },
    maxWidth: "100%",
    flexBasis: "100%",
    display: "flex",
    flexDirection: "column",
    "& .user-card": {
      [theme.breakpoints.down("md")]: {
        width: "79vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "81vw",
      },
      "& .MuiStack-root": {
        width: "100%",
      },
    },

    "& .highlight-card.MuiPaper-root": {
      backgroundColor: "#EAF4FB",
    },

    "& .application-card": {
      [theme.breakpoints.down("md")]: {
        width: "79vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "81vw",
      },
    },
    "& .Card": {
      "& .MuiStack-root": {
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
          rowGap: "10px",
          width: "16rem",
        },
      },
    },
    ".appCard.MuiPaper-root": {
      maxWidth: "85vw",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
        width: "79vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "79vw",
      },
      "& .MuiGrid-root.MuiGrid-container": {
        [theme.breakpoints.up("md")]: {
          maxWidth: "21vw",
        },
      },
    },
  })
);

export const RequestSubHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  rowGap: "8px",
  columnGap: "8px",
  marginBottom: "8px",
});
export const RequestSubHeaderTypography = styled((props) => (
  <Typography variant="h6" {...props} />
))(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "8px",
  lineHeight: "24px",
  fontSize: "18px",
  fontWeight: 400,
  color: "#00000099",
}));

export const RequestDetailsContainer = styled("div")(({ ...props }) => ({
  padding: "24px 16px",
  opacity: props.disabled ? "0.6" : "1",
}));
export const RequestHeader = styled((props) => (
  <Typography variant="h6" {...props} />
))(({ ...props }) => ({
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "24px",
}));
export const NoDataFound = styled("div")(({ theme }) => ({
  position: "absolute",
  // height: "43vh",
  width: "100%",
  textAlign: "center",
  top: "138px",
  zIndex: 1000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "0.875rem",
  [theme.breakpoints.down(900)]: { top: "200px" },
}));

export const WorkfowHeader = styled("h2")({
  margin: "0px",
  marginTop: "14px",
  fontFamily: "MB Corpo S Text",
  letterSpacing: "0px",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "24px",
});

export { MenuItem, Select, FormControl, Typography, Grid };
