import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { IconButton, Tooltip } from "@alice/component_library";
import { SelectedRolesCard } from "@pages/newRequest/styledComponents";

const SelectedRoleCards = ({ role, handleRemove }) => {
  const { t } = useTranslation();
  const { data = {} } = role;
  const { roleDefinition = {} } = data;
  const { id, name } = roleDefinition;
  return (
    <SelectedRolesCard>
      <div className="role-card">
        <div className="header-container">
          <div className="role-cardv3-header">
            <Typography variant="caption" className="id-holder">
              {t("id")}
            </Typography>
            <Tooltip type="ellipsized" title={id}>
              <Typography variant="subtitle1" className="role-cardv3-id" noWrap>
                {id}
              </Typography>
            </Tooltip>
          </div>
          <Tooltip type="ellipsized" title={name}>
            <Typography variant="body1" className="role-cardv3-name" noWrap>
              {name}
            </Typography>
          </Tooltip>
        </div>

        <div className="remove-icon">
          <Divider orientation="vertical" />
          <IconButton onClick={() => handleRemove(role)}>
            <CancelOutlinedIcon />
          </IconButton>
        </div>
      </div>
    </SelectedRolesCard>
  );
};

export default SelectedRoleCards;
