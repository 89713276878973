import { HttpService } from '../HttpService'
import { ApiBaseUrl, FilterParser, PaginationParser, SortParser } from '../utils'

export class AliceService extends HttpService {
  static toHttpServiceConfig = (config = {}) => {
    if (typeof config !== 'object') return {}

    const httpServiceConfig = {
      ...config,
      params: { ...config?.params },
      body: config?.body
    }

    if (config?.filters) {
      httpServiceConfig.params = {
        ...httpServiceConfig.params,
        ...FilterParser.toAlice({
          filters: config?.filters,
          compositeOperator: config?.filtersCompositeOperator
        })
      }
    }

    if (config?.page !== undefined && config?.pageSize) {
      httpServiceConfig.params = {
        ...httpServiceConfig.params,
        ...PaginationParser.toAlice({
          page: config?.page,
          size: config?.pageSize
        })
      }
    }

    if (config?.sort) {
      httpServiceConfig.params = {
        ...httpServiceConfig.params,
        ...SortParser.toAlice({
          sort: config?.sort,
          order: config?.sortOrder
        })
      }
    }

    return httpServiceConfig
  }

  static toUrl = (url = '/') => {
    if (!url.startsWith('/')) url = `/${url}`

    return `${ApiBaseUrl.ALICE}${url}`
  }

  static get = async (url, config = {}) => super.get(AliceService.toUrl(url), AliceService.toHttpServiceConfig(config))

  static post = async (url, config = {}) => super.post(AliceService.toUrl(url), AliceService.toHttpServiceConfig(config))

  static put = async (url, config = {}) => super.put(AliceService.toUrl(url), AliceService.toHttpServiceConfig(config))

  static delete = async (url, config = {}) => super.delete(AliceService.toUrl(url), AliceService.toHttpServiceConfig(config))

  static patch = async (url, config = {}) => super.patch(AliceService.toUrl(url), AliceService.toHttpServiceConfig(config))

  static head = async (url, config = {}) => super.head(AliceService.toUrl(url), AliceService.toHttpServiceConfig(config))
}
