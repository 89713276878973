import React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mercedes-benz/mui5-theme";

const LabelContainer = styled("div")({
  color: grey[45],
  fontSize: "14px",
});

const ValueContainer = styled("div")({
  color: grey[20],
  fontSize: "16px",
  fontWeight: 700,
  padding: "6px 0px",
  wordBreak: "break-word",
});

const ValueWithJsxContainer = styled("div")({
  display: "flex",
  "& svg": {
    borderRadius: "20px",
  },
});

const JsxContainer = styled("div")({
  display: "flex",
  paddingRight: "10px",
  "& svg": {
    borderRadius: "20px",
  },
});

const LabelValue = ({
  label,
  value,
  labelStyles,
  valueStyles,
  jsxComponent = <></>,
}) => {
  return (
    <div>
      {label !== "" && (
        <LabelContainer style={labelStyles}>{label}</LabelContainer>
      )}
      {value !== "" && (
        <ValueWithJsxContainer>
          {jsxComponent}
          <ValueContainer style={valueStyles}>{value}</ValueContainer>
        </ValueWithJsxContainer>
      )}
    </div>
  );
};
LabelValue.defaultProps = {
  label: "",
  value: "",
};

export default LabelValue;
