import { CloseRounded } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

const buttonSize = {
  small: '24px',
  medium: '36px',
  large: '48px'
}

const iconSize = {
  small: 16,
  medium: 24,
  large: 36
}

/** A simple button to close a window or inlay */
export const CloseButton = forwardRef((
  {
    size = 'medium',
    onClick = (event) => {},
    sx = {},
    ...otherProps
  },
  ref
) => (
  <IconButton
    data-testid="CloseButton"
    ref={ref}
    onClick={onClick}
    sx={mergeSxProps(
      {
        margin: 0,
        padding: 0,
        height: buttonSize[size],
        minWidth: buttonSize[size],
        color: 'black',
        backgroundColor: 'white',
        ':hover': { backgroundColor: 'grey.85' }
      },
      sx
    )}
    {...otherProps}
  >
    <CloseRounded
      sx={{
        margin: '.25em',
        fontSize: iconSize[size]
      }}
    />
  </IconButton>
))

CloseButton.propTypes = {
  /** Size of the button (24px, 32px, 48px) */
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  /** Event on click */
  onClick: PropTypes.func,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
