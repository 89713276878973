// This is only a template. You still need to import the relevant data
// Copy this file to modify it

import React from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarMonthOutlined } from '@mui/icons-material'
import { AliceIcon, AliceIconType, IconWrapper } from '../../icon'
import { Menubar } from '../menubar/Menubar'

const Icon = ({ iconType }) => <AliceIcon iconType={iconType} size={32} color="blue.50" />

export const TasksMenubar = () => {
  const { t } = useTranslation([ 'common', 'componentLibrary' ])

  const onClick = (e) => {
    console.log(e)
  }

  const config = {
    title: t('menuBar.taskTypes'),
    collapsed: false,
    items: [
      {
        id: 'menuitem-0-1',
        title: t('menuBar.allTasks'),
        badge: 736,
        icon: <IconWrapper><CalendarMonthOutlined /></IconWrapper>
      },
      {
        title: t('menuBar.roleRequests'),
        opened: true,
        items: [
          {
            id: 'ROLE_ASSIGNMENT',
            title: t('taskType.ROLE_ASSIGNMENT'),
            badge: 5, // insert value from api here
            icon: <Icon iconType={AliceIconType.ROLE_REQ} />
          },
          {
            id: 'APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT',
            title: t('taskType.APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT'),
            badge: 5, // insert value from api here
            icon: <Icon iconType={AliceIconType.ROLE_REQ} />
          }
        ]
      },
      {
        title: t('menuBar.userOrganization'),
        opened: true,
        items: [
          {
            id: 'APPROVE_USER_CREATION1',
            title: t('taskType.APPROVE_USER_CREATION'),
            badge: 124,
            icon: <Icon iconType={AliceIconType.USER_CREATE} />
          },
          {
            id: 'APPROVE_USER_TO_ORGANIZATION',
            title: t('taskType.APPROVE_USER_TO_ORGANIZATION'),
            badge: 998,
            icon: <Icon iconType={AliceIconType.ASSIGN_USER_ORG} />
          },
          {
            id: 'ORG_ADMIN_PERMISSION',
            title: t('taskType.ORG_ADMIN_PERMISSION'),
            badge: 112,
            icon: <Icon iconType={AliceIconType.ORG_ADMIN} />
          }
        ]
      },
      {
        title: t('menuBar.reviews'),
        opened: true,
        items: [
          {
            id: 'USER_REVIEW_MARKET',
            title: t('taskType.USER_REVIEW_MARKET'),
            badge: 12,
            icon: <Icon iconType={AliceIconType.USER_REVIEW_MARKET} />
          },
          {
            id: 'USER_REVIEW_ORGANIZATION',
            title: t('taskType.USER_REVIEW_ORGANIZATION'),
            badge: 529,
            icon: <Icon iconType={AliceIconType.USER_REVIEW_ORG} />
          },
          {
            id: 'APPROVE_ROLE_DEPROVISIONING',
            title: t('taskType.APPROVE_ROLE_DEPROVISIONING'),
            badge: 42,
            icon: <Icon iconType={AliceIconType.ROLE_DEPRO} />
          }
        ]
      },
      {
        title: t('menuBar.admin'),
        opened: true,
        items: [
          {
            id: 'APPLICATIONROLE_TO_ROLE',
            title: t('taskType.APPLICATIONROLE_TO_ROLE'),
            badge: 5,
            icon: <Icon iconType={AliceIconType.ROLE_ENTITLEMENT} />
          },
          {
            id: 'APPROVE_TECHUSER_TO_APPLICATION',
            title: t('taskType.APPROVE_TECHUSER_TO_APPLICATION'),
            badge: 529,
            icon: <Icon iconType={AliceIconType.TECH_USER} />
          }
        ]
      }
    ]
  }

  return (
    <Menubar
      data-testid="TasksMenubar"
      onClick={onClick}
      config={config}
      selected="menuitem-0-1"
    />
  )
}
