import { useMemo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useNotification, useUser } from "@alice/component_library";
// import { setLoading } from "@alice/component_library/src/data/store/actions";
import { useQuery } from "@core/hooks";
import {
  loadTask as loadDeepTask,
  loadWorkFlow as loadDeepWorkflow,
  loadTaskCountDeeplink,
} from "@actions/openTaskActions";
import { isValid } from "@utils/Validator";
import { approveTasks, rejectTasks, saveBreadCrumbData } from "@actions";
import {
  loadTask as loadRequest,
  loadWorkFlow as loadWorkFlowRequest,
} from "@actions/overviewActions";
import { IdSpanContainer } from "./StyledComponent";
import { getTaskType } from "./util";

const useDeeplinkTask = (isFromTask = true) => {
  const query = useQuery();
  const { t } = useTranslation();
  const loadTask = isFromTask ? loadDeepTask : loadRequest;
  const loadWorkFlow = isFromTask ? loadDeepWorkflow : loadWorkFlowRequest;
  const id = query.get("taskId") ?? "";
  const taskType = query.get("tasktype") ?? "";
  const taskFilter = query.get("filter") ?? "";
  const isAdmin = query.get("isadmin") ?? "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: userUtils } = useUser();

  const {
    loading,
    isLoaded,
    selectedTask = {},
    relativeTask = {},
  } = useSelector((s) =>
    isFromTask ? s.task.taskDeepLinkInfo : s.overview.requestDeeplink
  );

  const count = relativeTask?.taskResult?.count;
  const relatedTaskList = relativeTask?.taskResult?.tasks;
  const taskTypeLoadTasks = relativeTask?.taskResult?.taskType;
  const { notify } = useNotification();

  const memorisedCountValue = useMemo(() => {
    return count;
  }, [count]);

  const memorisedSelectedTask = useMemo(() => {
    return selectedTask;
  }, [selectedTask]);

  const workFlowData = relativeTask?.taskData?.workFlowData;

  const taskCount = relativeTask?.countInfo?.taskCount;
  const workFlowCount = relativeTask?.countInfo?.workFlowCount;

  const offset = relativeTask?.paginationInfo?.fetchedCount;

  const { id: userId } = userUtils;
  const breadCrumbLabel = getTaskType(taskType, t);

  const { pathname } = window.location;
  const taskPAgeUrlWithoutParam = window.location.origin + pathname;
  const url = useMemo(() => {
    return new URL(taskPAgeUrlWithoutParam);
  }, [taskPAgeUrlWithoutParam]);
  const taskTypeParam = taskType.length ? `&tasktype=${taskType}` : "";
  const taskFiltereParam = taskFilter.length ? `&filter=${taskFilter}` : "";
  const isAdminParam = isAdmin.length ? `&isadmin=${isAdmin}` : "";

  const breadCrumbUrl = taskTypeParam + taskFiltereParam + isAdminParam;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [taskInfo, setTaskInfo] = useState({});
  const [openTasks, setOpenTasks] = useState(false);
  const [isAproove, setIsAproove] = useState(false);
  const [taskId, setTaskId] = useState(selectedTask?.id);

  const [isLoading, setIsLoading] = useState(loading);

  const openDialog = (isAproove) => {
    setOpenTasks(true);
    setIsAproove(isAproove);
  };

  const closeDialog = () => {
    setOpenTasks(false);
  };

  const loadData = useCallback(async () => {
    if (isValid(id)) {
      const isWorkFlow = id.toUpperCase().startsWith("W");

      if (isWorkFlow) dispatch(loadWorkFlow(id, userId));
      else await dispatch(loadTask(id, userId));
    } else {
      navigate("/access");
    }
  }, [dispatch, id, navigate, userId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setTotalCount(memorisedCountValue);
  }, [memorisedCountValue]);

  useEffect(() => {
    setTaskInfo(memorisedSelectedTask);
    setTaskId(memorisedSelectedTask?.id);
  }, [memorisedSelectedTask]);

  const handleApprove = async (comment = "") => {
    const { id, isWorkFlow, currentTaskId } = taskInfo;
    const approveSucces = await dispatch(
      approveTasks(isWorkFlow ? currentTaskId : id, comment)
    );
    if (approveSucces === 204 || approveSucces === 200) {
      handleNextTask(null, true);
    }
    notify({
      severity:
        approveSucces === 204 || approveSucces === 200 ? "success" : "error",
      message: (
        <Trans
          i18nKey={
            approveSucces === 204 || approveSucces === 200
              ? "alert-approve-success"
              : "alert-approve-fail"
          }
          values={{
            taskId: id,
          }}
          components={[
            <IdSpanContainer
              onClick={() => {
                navigate(`/access/tasks/details?taskId=${id}`);
              }}
              sx={{ cursor: "pointer" }}
            />,
          ]}
        />
      ),
    });
  };

  const handleReject = async (comment) => {
    const { id, isWorkFlow, currentTaskId } = taskInfo;
    const rejectStatus = await dispatch(
      rejectTasks(isWorkFlow ? currentTaskId : id, comment)
    );
    if (rejectStatus === 204 || rejectStatus === 200) {
      handleNextTask(null, true);
    }
    notify({
      severity:
        rejectStatus === 204 || rejectStatus === 200 ? "success" : "error",
      message: (
        <Trans
          i18nKey={
            rejectStatus === 204 || rejectStatus === 200
              ? "alert-reject-success"
              : "alert-reject-fail"
          }
          values={{
            taskId: id,
          }}
          components={[
            <IdSpanContainer
              onClick={() => {
                navigate(`/access/tasks/details?taskId=${id}`);
              }}
              sx={{ cursor: "pointer" }}
            />,
          ]}
        />
      ),
    });
  };

  const setTaskDetailInfo = (taskList, selectedIndex) => {
    setTaskInfo(taskList[selectedIndex]);
    if (typeof taskList[selectedIndex]?.id !== "undefined") {
      setTaskId(taskList[selectedIndex]?.id);
      url.searchParams.set("taskId", taskList[selectedIndex]?.id);
    }
    window.history.replaceState({}, document.title, url.toString());
  };

  const handleNextTask = async (e, isUpdate = false) => {
    // setLoading(true);
    await dispatch(
      loadTaskCountDeeplink(
        taskInfo.id,
        taskTypeLoadTasks,
        userId,
        taskCount,
        workFlowCount,
        offset,
        24,
        workFlowData,
        relatedTaskList,
        totalCount,
        true,
        isUpdate,
        selectedTask,
        selectedIndex
      )
    );
    let taskList = relatedTaskList;

    if (isUpdate) {
      if (taskList.length === 1) {
        navigate(`/access/tasks`);
      }
      taskList = taskList
        .filter((task) => task.id !== taskInfo.id)
        .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

      setTotalCount((prevCount) => prevCount--);
    }

    if (taskList.length === 0) {
      navigate(`/access/tasks`);
    }

    const index = isUpdate
      ? selectedIndex === taskList?.length
        ? selectedIndex - 1
        : selectedIndex
      : selectedIndex + 1;

    if (index < taskList?.length) {
      setSelectedIndex(index);
    }

    if (typeof taskList !== "undefined") {
      if (taskList.length) {
        setTaskDetailInfo(taskList, index);
        setIsLoading(false);
      } else {
        setTaskDetailInfo([selectedTask], 0);
      }
    } else {
      setTaskDetailInfo([selectedTask], 0);
    }
  };

  const handlePreviousTask = async (e, isUpdate = false) => {
    const index = selectedIndex - 1;
    if (index >= 0) {
      setSelectedIndex(index);
      if (typeof relatedTaskList !== "undefined") {
        if (relatedTaskList.length) {
          setTaskDetailInfo(relatedTaskList, index);
        } else {
          setTaskDetailInfo([selectedTask], 0);
        }
      } else {
        setTaskDetailInfo([selectedTask], 0);
      }
    }
  };

  const storeBreadcrumbInfo = (type, state) => {
    dispatch(saveBreadCrumbData({ type: type, state: state }));
  };

  return {
    id: taskId,
    taskInfo: taskInfo,
    handleApprove,
    openDialog,
    closeDialog,
    openTasks,
    relatedTaskList,
    selectedIndex,
    handleNextTask,
    handlePreviousTask,
    totalCount,
    storeBreadcrumbInfo,
    loading: isLoading && loading,
    handleReject,
    isLoaded,
    breadCrumbUrl,
    breadCrumbLabel,
    isLoading,
    isAproove,
    userId,
  };
};

export default useDeeplinkTask;
