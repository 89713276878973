import React from "react";

const NoEntitlementsIcon = () => {
  return (
    <span>
      <svg
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Enhancements"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="EntitlementMessage"
            transform="translate(-697.000000, -634.000000)"
            fillRule="nonzero"
          >
            <g id="Group-3" transform="translate(261.000000, 33.000000)">
              <g
                id="SearchResults"
                transform="translate(436.000000, 601.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                <g
                  id="Group-4"
                  transform="translate(11.000000, 8.000000)"
                  fill="#9E9E9E"
                >
                  <path
                    d="M5.625,11.25 C5.004,11.25 4.5,10.746 4.5,10.125 C4.5,9.504 5.004,9 5.625,9 L19.375,9 C19.996,9 20.5,9.504 20.5,10.125 C20.5,10.746 19.996,11.25 19.375,11.25 L5.625,11.25 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M5.625,18 C5.004,18 4.5,17.496 4.5,16.875 C4.5,16.254 5.004,15.75 5.625,15.75 L19.25,15.75 C19.871,15.75 20.375,16.254 20.375,16.875 C20.375,17.496 19.871,18 19.25,18 L5.625,18 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M5.625,24.75 C5.004,24.75 4.5,24.246 4.5,23.625 C4.5,23.004 5.004,22.5 5.625,22.5 L11.25,22.5 C11.871,22.5 12.375,23.004 12.375,23.625 C12.375,24.246 11.871,24.75 11.25,24.75 L5.625,24.75 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M3.375,31.5 C1.5135,31.5 0,29.9865 0,28.125 L0,3.375 C0,1.5135 1.5135,0 3.375,0 L19.3185,0 C20.22,0 21.066,0.351 21.7035,0.987 L26.0115,5.295 C26.649,5.9325 27,6.78 27,7.6815 L27,24.25 C27,24.871 26.496,25.375 25.875,25.375 C25.254,25.375 24.75,24.871 24.75,24.25 L24.75,7.6815 C24.75,7.386 24.63,7.0965 24.4215,6.8865 L20.1135,2.5785 C19.9005,2.367 19.6185,2.25 19.3185,2.25 L3.375,2.25 C2.754,2.25 2.25,2.754 2.25,3.375 L2.25,28.125 C2.25,28.746 2.754,29.25 3.375,29.25 L21.25,29.25 C21.871,29.25 22.375,29.754 22.375,30.375 C22.375,30.996 21.871,31.5 21.25,31.5 L3.375,31.5 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default NoEntitlementsIcon;
