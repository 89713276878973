import { Chip, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Entitlements } from '../../../constants'
import { usePermission } from '../../../hooks'
import { ENVIRONMENTS, getClient, getClientLink, getEnvironment, isShardedServiceAdmin, mergeSxProps } from '../../../util'

/**
 * Shows a chip with the current Environment
 */
export const EnvironmentChip = forwardRef((
  {
    label = '',
    mode = 'dark',
    showInProd = false,
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const { adminRoles, hasOneOfPermission } = usePermission()

  const anchorRef = useRef(null)

  const [ isMenuOpen, setIsMenuOpen ] = useState(false)

  const canOpen = isShardedServiceAdmin(adminRoles) || hasOneOfPermission(Entitlements)
  const environment = getEnvironment() || label
  const client = getClient()

  const handleOpenMenu = () => setIsMenuOpen(true)
  const handleCloseMenu = () => setIsMenuOpen(false)

  useImperativeHandle(ref, () => anchorRef)

  const currentEnv = getEnvironment()
  if (currentEnv === 'prod' && !showInProd) return null

  return (
    <>
      <Chip
        data-testid="EnvironmentChip"
        ref={anchorRef}
        onClick={canOpen ? handleOpenMenu : null}
        label={label || environment?.toUpperCase()}
        sx={mergeSxProps(
          {
            height: 18,
            userSelect: 'none',
            borderStyle: 'solid',
            borderRadius: '4px 4px 4px 0px',
            borderWidth: '1px',
            margin: '0px 6px',
            backgroundColor: 'grey.30',
            span: { padding: 0 },
            '&:hover': { backgroundColor: 'grey.35' },
            fontWeight: 700,
            fontSize: '0.625rem',
            lineHeight: '13px',
            padding: '2px 4px 2px 4px'
          },
          [
            mode === 'dark' && { color: 'common.white', borderColor: 'common.white' },
            mode !== 'dark' && { color: 'grey.40', borderColor: 'grey.40' }
          ],
          sx
        )}
        {...otherProps}
      />

      {canOpen && (
        <Menu
          anchorEl={anchorRef?.current}
          open={isMenuOpen}
          onClose={handleCloseMenu}
          disableScrollLock
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            top: '18px',
            '.MuiMenu-list': {
              color: 'white',
              backgroundColor: 'grey.25',
              minWidth: '160px !important'
            }
          }}
        >
          {ENVIRONMENTS.map((env) => (
            <MenuItem
              key={env}
              component="a"
              href={getClientLink(client, env)}
              selected={environment === env}
              sx={{
                ':hover': { backgroundColor: 'grey.60' },
                '&.Mui-selected': { backgroundColor: 'grey.45' },
                '&.Mui-selected:hover': { backgroundColor: 'grey.60' },
                justifyContent: 'center'
              }}
            >
              {t(`environment.${env}`)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
})

EnvironmentChip.propTypes = {
  /** Custom label for chip */
  label: PropTypes.string,
  /** Sets color according to the background. */
  mode: PropTypes.oneOf([ 'dark', 'bright' ]),
  /** Whether or not to show in prod */
  showInProd: PropTypes.bool,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
