export const headerData = (t, isMobile) => {
  if (isMobile)
    return [
      {
        Header: t("userId"),
        key: "userId",
      },
    ];

  return [
    {
      Header: t("userId"),
      key: "userId",
    },
    {
      Header: t("last-name"),
      key: "lastName",
    },
    {
      Header: t("first-name"),
      key: "firstName",
    },
    {
      Header: t("roles"),
      key: "roles",
    },
    {
      Header: t("status"),
      key: "status",
      style: { width: "90px" },
    },
    {
      Header: t("details"),
      key: "details",
      style: { width: "90px" },
    },
  ];
};

export const rolesHeader = (t) => [
  {
    Header: t("profileHeaderData.role-name"),
    key: "role",
    style: { width: "20%" },
  },
  {
    Header: t("profileHeaderData.description"),
    key: "desc",
    style: { width: "40%" },
  },
  {
    Header: t("controlCenterTable.timeframe-from"),
    key: "fromTime",
    style: { width: "20%" },
  },
  {
    Header: t("controlCenterTable.timeframe-to"),
    key: "toTime",
    style: { width: "20%" },
  },
];

export const tabs = (t) => [
  {
    label: t("roles"),
    key: "roles",
    value: 0,
    show: true,
  },
  {
    label: t("applications"),
    key: "applications",
    value: 1,
    show: true,
  },
];
