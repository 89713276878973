import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { GlobalRoleAssignerAssignmentDialog } from '../assignment/GlobalRoleAssignerAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'roleId'
const COLUMNS = [ 'roleName', 'roleId' ]

export const GlobalRoleAssignerAccordion = ({
  userId,
  isAssigned,
  administratedRoles,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedRoles, setSelectedRoles ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'globalroleassigner',
        body: { globalRoleAssignerPrivileges: selectedRoles.map((roleId) => ({ roleId })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.applicationHelpdeskAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedRoles = administratedRoles
          .filter((administrated) => selectedRoles
            .every((selected) => administrated.roleId !== selected))

        const globalRoleAssigner = { admin: false }

        if (newAdministratedRoles.length) {
          globalRoleAssigner.admin = true
          globalRoleAssigner.administratedRoles = newAdministratedRoles
        }

        handleDeselectAll()
        onChange({ globalRoleAssigner })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'roleName':
        return t('general.roleName')
      case 'roleId':
        return t('general.roleId')
      default:
        return key
    }
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedRoles(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.roleId))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.roleId), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="GlobalRoleAssignerAccordion"
        title={t('adminRoles.globalRoleAssigner')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {administratedRoles.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={administratedRoles}
            titleRenderer={titleRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                isDeleteDisabled={!selectedRoles.length}
                isDeleteLoading={isLoadingDelete}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <GlobalRoleAssignerAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

GlobalRoleAssignerAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedRoles: PropTypes.array,
  onChange: PropTypes.func
}
