import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { NewTable } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { ApplicationAdministratorAssignmentDialog } from '../assignment/ApplicationAdministratorAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const ROW_KEY = 'id'
const COLUMNS = [ 'id', 'name', 'description' ]

export const ApplicationAdminAccordion = ({
  userId,
  administratedApplications,
  isAssigned,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedApplications, setSelectedApplications ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'applicationadmin',
        body: { applicationAdminPrivileges: selectedApplications.map((id) => ({ id })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.applicationAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedApplications = administratedApplications
          .filter((administrated) => selectedApplications
            .every((selected) => administrated.id !== selected))

        const applicationAdmin = { admin: false }

        if (newAdministratedApplications.length) {
          applicationAdmin.admin = true
          applicationAdmin.administratedApplications = newAdministratedApplications
        }

        handleDeselectAll()
        onChange({ applicationAdmin })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'id':
        return t('general.id')
      case 'name':
        return t('general.name')
      case 'description':
        return t('general.description')
      default:
        return key
    }
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedApplications(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="ApplicationAdminAccordion"
        title={t('adminRoles.applicationAdmin')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {administratedApplications.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={administratedApplications}
            titleRenderer={titleRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                isDeleteDisabled={!selectedApplications.length}
                isDeleteLoading={isLoadingDelete}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <ApplicationAdministratorAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

ApplicationAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedApplications: PropTypes.array,
  onChange: PropTypes.func
}
