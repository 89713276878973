import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { InteractionDialog, FilterChip } from "@alice/component_library";

const ModalFilters = ({ children, handleReset, handleApply, handleCancel }) => {
  const { t } = useTranslation();
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const handleCancelClick = () => {
    setIsFilterDialogOpen(false);
    handleCancel();
  };

  const handleOkClick = () => {
    setIsFilterDialogOpen(false);
    handleApply();
  };

  const handleResetClick = () => {
    handleReset();
  };

  const handleFilterByClick = () => setIsFilterDialogOpen(true);

  return (
    <>
      <FilterChip
        key={"Add_Filters"}
        label={"Add Filters"}
        icon={<AddIcon />}
        isActive={false} // Check for active state
        onClick={() => handleFilterByClick()}
        sx={{ width: "150px" }}
      />

      <InteractionDialog
        isOpen={isFilterDialogOpen}
        title={t("filter-by-role-type")}
        onClose={handleCancelClick}
        onPrimaryClick={handleOkClick}
        primaryButtonText={t("apply")}
        onSecondaryClick={handleCancelClick}
        secondaryButtonText={t("cancel")}
        onTertiaryClick={handleResetClick}
        tertiaryButtonText={t("clear")}
        maxWidth="sm"
        fullWidth
      >
        {children}
      </InteractionDialog>
    </>
  );
};

export default ModalFilters;
