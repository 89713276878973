import PropTypes from 'prop-types'
import { ListItemButton, ListItemText } from '@mui/material'
import { IconWrapper } from '../../../icon'

export const NavigationMenuListItem = ({
  item,
  isSelected,
  isExpanded,
  onClick = ({ itemId, item }) => {}
}) => (
  <ListItemButton
    data-testid={`NavigationMenuListItem-${item.id}`}
    selected={isSelected}
    onClick={() => onClick({ item, itemId: item.id })}
    sx={{
      height: 48,
      ':not(:last-child)': {
        borderBottom: '1px solid',
        borderColor: 'divider'
      },
      '&::before': {
        position: 'absolute',
        top: '-1px',
        bottom: '-1px',
        left: 0,
        width: 0,
        backgroundColor: 'primary.main',
        transition: isSelected ? 'width 150ms 150ms' : 'width 150ms',
        content: "''"
      },
      '&.Mui-selected': {
        borderLeft: 0,
        paddingLeft: 2,
        '&::before': { width: 4 }
      },
      '.NML_Item': {
        '&Icon': {
          alignItems: 'center',
          justifyContent: 'center',
          width: '42px',
          height: '42px'
        },
        '&Text': {
          position: 'absolute',
          left: 'calc(var(--collapsedWidth) - 2px)',
          width: 'var(--textWidth)',
          opacity: isExpanded ? 1 : 0,
          transition: isExpanded ? 'opacity 150ms 100ms' : 'opacity 150ms'
        }
      }
    }}
  >
    <IconWrapper
      className="NML_ItemIcon"
      size={24}
      color="primary.main"
    >
      <item.icon />
    </IconWrapper>

    <ListItemText className="NML_ItemText" primary={item.label} />
  </ListItemButton>
)

NavigationMenuListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
  }).isRequired,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func
}
