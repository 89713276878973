import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class MarketsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/markets',
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/markets/${id}`,
    { ...args }
  )

  static updateById = ({
    id,
    market,
    ...args
  }) => AliceService.put(
    `markets/${id}`,
    mergeObjects(
      { body: market },
      { ...args }
    )
  )
}
