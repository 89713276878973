import { useEffect, useState } from 'react'

export const useLocalItems = ({
  localItems: _localItems = [],
  preselectedPageIndex = 0,
  preselectedPageSize = 12,
  preselectedSort = '',
  preselectedSearch = '',
  preselectedFilters = {},
  // testing
  isForceLoading
}) => {
  const [ items, setItems ] = useState([])
  const [ itemsCount, setItemsCount ] = useState(0)

  const [ localItems, setLocalItems ] = useState(_localItems)
  const [ pageIndex, setPageIndex ] = useState(preselectedPageIndex)
  const [ pageSize, setPageSize ] = useState(preselectedPageSize)
  const [ sort, setSort ] = useState(preselectedSort)
  const [ search, setSearch ] = useState(preselectedSearch)
  const [ filters, setFilters ] = useState(preselectedFilters)

  useEffect(() => {
    if (Array.isArray(_localItems)) setLocalItems(_localItems)
  }, [ _localItems ])

  useEffect(() => {
    updateItems()
    // eslint-disable-next-line
  }, [ sort, search, filters, localItems, pageSize, pageIndex ])

  const updateItems = () => {
    const startIndex = pageIndex * pageSize
    const endIndex = startIndex + pageSize

    let items = [ ...localItems ]

    // sort items
    if (sort) {
      items = items.sort((a, b) => {
        const sortOrder = sort.startsWith('-') ? -1 : 1
        const sortField = sort.slice(1)

        return (a[sortField] < b[sortField] ? -1 : (a[sortField] > b[sortField] ? 1 : 0)) * sortOrder
      })
    }

    // filter items by search
    if (search) {
      items = items.filter((item) => {
        let isIn = false

        for (const searchKey of Object.keys(item)) {
          const toTest = item[searchKey]

          if (toTest && toTest.toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1) {
            isIn = true
            break
          }
        }

        return isIn
      })
    }

    // filter items
    if (Object.keys(filters).length) {
      items = items.filter((item) => Object.keys(filters).every((filter) => {
        if (!filters[filter].length) return true

        const newFilter = filters[filter].map((filterValue) => filterValue.toLowerCase())

        return newFilter.includes(item[filter].toLowerCase())
      }))
    }

    setItemsCount(items.length)

    // pagination
    if (items.length > startIndex) {
      items = items.slice(startIndex, endIndex)
    }

    setItems(items)
  }

  return {
    items,
    itemsCount,
    localItems,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    sort,
    setSort,
    search,
    setSearch,
    filters,
    setFilters,
    isLoading: isForceLoading
  }
}
