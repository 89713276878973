import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class ConfigurationsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/configurations',
    { ...args }
  )

  static getByCategory = ({
    category,
    ...args
  }) => AliceService.get(
    `/configurations/${category}`,
    { ...args }
  )

  static getByCategoryAndKey = ({
    category,
    key,
    ...args
  }) => AliceService.get(
    `/configurations/${category}/${key}`,
    { ...args }
  )

  static updateByCategoryAndKey = ({
    category,
    key,
    value,
    ...args
  }) => AliceService.put(
    `/configurations/${category}/${key}`,
    mergeObjects(
      { body: { value } },
      { ...args }
    )
  )

  static updateAllByCategoriesAndKeys = async ({
    updates = [],
    ...args
  }) => {
    const requests = updates.map((update) => ConfigurationsService.updateByCategoryAndKey({
      category: update.category,
      key: update.key,
      value: update.value,
      ...args
    }))

    return AliceService.all(requests)
  }
}
