import React from "react";
import { useTranslation } from "react-i18next";
import { LinkButton } from "@alice/component_library";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AccessReviewFooterContainer } from "../../styledComponents";

const AccesReviewFooter = ({ count }) => {
  const { t } = useTranslation();
  return (
    <AccessReviewFooterContainer>
      {count - 5 > 0 ? (
        <div className="count-container">
          {t("exp-role-count", {
            count: count - 5,
          })}
        </div>
      ) : (
        <></>
      )}
      <div className="button-container">
        <LinkButton
          href="/access/accessReview"
          size="medium"
          variant="primary"
          isBlank={false}
        >
          {t("go-to-reviews")}
          <ChevronRightIcon />
        </LinkButton>
      </div>
    </AccessReviewFooterContainer>
  );
};

export default AccesReviewFooter;
