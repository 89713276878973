import { format } from 'date-fns'
import { mergeObjects } from '../../../util'
import { CommunicationsService } from './CommunicationsService'

export class AnnouncementsService {
  static getAll = ({
    recipient,
    ...args
  }) => CommunicationsService.get(
    '/announcements',
    mergeObjects(
      { params: { recipient: Array.isArray(recipient) ? recipient.join(',') : recipient } },
      { ...args }
    )
  )

  static getAllActive = ({
    recipient,
    ...args
  }) => CommunicationsService.get(
    'announcements/active',
    mergeObjects(
      { params: { recipient: Array.isArray(recipient) ? recipient.join(',') : recipient } },
      { ...args }
    )
  )

  static getAllByStatus = ({
    status,
    ...args
  }) => CommunicationsService.get(
    `/announcements/${status}`,
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => CommunicationsService.get(
    `/announcements/${id}`,
    { ...args }
  )

  static update = ({
    id,
    announcement,
    ...args
  }) => {
    delete announcement?.id // ID is not allowed while saving and not required at all
    delete announcement?.archived // Archived is not allowed while saving and not required at all
    announcement.startDate = format(new Date(announcement.startDate), 'yyyy-MM-dd HH:mm:ss.SSS')
    announcement.endDate = format(new Date(announcement.endDate), 'yyyy-MM-dd HH:mm:ss.SSS')

    return CommunicationsService.put(
      `/announcements/${id}`,
      mergeObjects(
        { body: announcement },
        { ...args }
      )
    )
  }

  static delete = ({
    id,
    ...args
  }) => CommunicationsService.delete(
    `/announcements/${id}`,
    { ...args }
  )

  static create = ({
    announcement,
    ...args
  }) => CommunicationsService.post(
    '/announcements',
    mergeObjects(
      { body: announcement },
      { ...args }
    )
  )
}
