const Authorizations = {
  task: [
    "APP_HELPDESK_ADMIN",
    "CENTRAL_HELPDESK_ADMIN",
    "SHARED_SERVICE_ADMIN",
    "FUNCTIONAL_ADMINISTRATION",
  ],
  request: [
    "GLOBAL_COMMUNITY_ADMIN",
    "GLOBAL_ROLE_ASSIGNER",
    "GROUP_ADMIN",
    "MARKET_CBP_ADMIN",
    "MARKETADMIN",
    "ORGADMIN",
    "SHARED_SERVICE_ADMIN",
    "MARKET_ROLE_ASSIGNER",
    "ORGANIZATION_ROLE_ASSIGNER",
    "FUNCTIONAL_ADMINISTRATION",
  ],
  cc: [
    "APP_HELPDESK_ADMIN",
    "CENTRAL_CBP_ADMIN",
    "CENTRAL_HELPDESK_ADMIN",
    "GLOBAL_COMMUNITY_ADMIN",
    "GLOBAL_ROLE_ASSIGNER",
    "MARKETADMIN",
    "GROUP_ADMIN",
    "MARKET_HELPDESK_ADMIN",
    "MARKET_ROLE_ASSIGNER",
    "ORGADMIN",
    "ORGANIZATION_ROLE_ASSIGNER",
    "SHARED_SERVICE_ADMIN",
    "FUNCTIONAL_ADMINISTRATION",
  ],
  bulkImport: [
    "GLOBAL_ROLE_ASSIGNER",
    "MARKET_ROLE_ASSIGNER",
    "ORGANIZATION_ROLE_ASSIGNER",
    "SHARED_SERVICE_ADMIN",
    "ORGADMIN",
    "GLOBAL_COMMUNITY_ADMIN",
    "GROUP_ADMIN",
    "MARKET_CBP_ADMIN",
    "FUNCTIONAL_ADMINISTRATION",
  ],
  visibilityAdmin: ["ORGADMIN", "GLOBAL_COMMUNITY_ADMIN", "MARKETADMIN"],
  emailAddress: [
    "DEALER_EMPLOYEE",
    "SUPPLIER_EMPLOYEE",
    "CORPORATE_CUSTOMER_EMPLOYEE",
    "CORPORATE_BUSINESS_PARTNER_PERSON",
  ],
  accessReview: [
    "SHARED_SERVICE_ADMIN",
    "ROLE_OWNER",
    "ROLE_APPROVER",
    "SUPERVISOR",
    "DATA_ADMIN",
    "FUNCTIONAL_ADMINISTRATION",
  ],
  accessReviewAdminMode: ["SHARED_SERVICE_ADMIN", "FUNCTIONAL_ADMINISTRATION"],
};

export { Authorizations };
