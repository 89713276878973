/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  AliceAppBar,
  UserAvatarMenu,
  AppSwitcher,
  EnvironmentChip,
  OpenTasksButton,
  useOpenTasks,
} from "@alice/component_library";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Stack } from "@mui/material";
import { BASE_URL, getEnvironment } from "@alice/component_library";
import { StyledHeader } from "./styles";
import packageJson from "../../../../package.json";

export default function Header(props) {
  const { t } = useTranslation();

  const { openTasksCount } = useOpenTasks();

  const handleProfile = () => {
    window.location.href = `${
      BASE_URL[getEnvironment()]
    }/access/profile/overview`;
  };

  const handleLogout = () => {
    window.location.href = `${BASE_URL[getEnvironment()]}/logout`;
  };

  return (
    <StyledHeader>
      <AliceAppBar
        contentLeft={
          <>
            <AppSwitcher tooltip={`${t("version")} ${packageJson.version}`} />
            <span style={{ fontWeight: 600 }}>
              <EnvironmentChip label={getEnvironment().toUpperCase()} />
            </span>
          </>
        }
        contentRight={
          <Stack
            spacing={3}
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <OpenTasksButton disableNotification={!openTasksCount > 0} />
            <UserAvatarMenu
              version={packageJson.version}
              handleProfile={handleProfile}
              handleLogout={handleLogout}
            />
          </Stack>
        }
      />
    </StyledHeader>
  );
}
