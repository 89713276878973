import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery } from "@mui/material";
import { SecondaryButton } from "@components/button";
import {
  DialogContainer,
  StyleDialogTitleForResult,
  StyledDialogContentText,
  StyledDialogContentTextForRemovalForResult,
  StyledDialogContent,
  StyledDialogAction,
  DeleteAllCardContainer,
  StyledDialog,
  StyledDelteMessageContainer,
} from "../../styledComponents";

import { CustomCard } from "../Card/Card";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RoleCardV3 from "@src/sharedComponents/RoleCardV3/RoleCardV3";

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const ResultDialog = ({ dialogOpen, handleDismiss, t, infoData }) => {
  let sucessCase = infoData?.filter((item) => item.status === "success");
  let accessDeniedCases = infoData?.filter(
    (item) => item.status === "access-denied"
  );
  let failedCases = infoData?.filter((item) => item.status === "failed");

  const [isSmall, setIsSmall] = useState(false);
  const [selectedRole, setSelectedRole] = useState({
    success: null,
    "access-denied": null,
    failed: null,
  });
  const handleClose = () => {
    setSelectedRole({
      success: null,
      "access-denied": null,
      failed: null,
    });
    handleDismiss();
  };

  const widthPanel = useWidth();

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
      case "sm":
        setIsSmall(true);
        break;
      case "md":
      case "lg":
        setIsSmall(true);
        break;
      case "xl":
        setIsSmall(false);

        break;
      default: {
        setIsSmall(true);
      }
    }
  }, [widthPanel]);

  const RoleCardForDelete = infoData?.length === 1 ? RoleCardV3 : CustomCard;
  return (
    <React.Fragment>
      <StyledDialog
        PaperProps={{
          style: { borderRadius: 2 },
        }}
        fullWidth={true}
        maxWidth="md"
        open={dialogOpen}
        onClose={handleClose}
      >
        <DialogActions>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              minWidth: 34,
              padding: 0,
            }}
            disableElevation
          >
            <Close
              sx={{
                margin: ".25em",
              }}
            />
          </Button>
        </DialogActions>
        <StyleDialogTitleForResult>
          {infoData?.length === 1 ? t("delete-role") : t("delete-roles")}
        </StyleDialogTitleForResult>

        <StyledDialogContent>
          <>
            {sucessCase?.length ? (
              <StyledDelteMessageContainer>
                <StyledDialogContentTextForRemovalForResult minMargin="16px">
                  <span className="icon-span">
                    <TaskAltIcon
                      sx={{
                        fontSize: "20px",
                        color: "rgb(25, 128, 37)",
                      }}
                    />
                  </span>
                </StyledDialogContentTextForRemovalForResult>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "95%",
                  }}
                >
                  <StyledDialogContentTextForRemovalForResult minMargin="16px">
                    {t("deleting-successful")}
                  </StyledDialogContentTextForRemovalForResult>

                  <StyledDialogContentText paddingSize="0px">
                    {sucessCase?.length === 1
                      ? t("successfully-deleted")
                      : t("successfully-deleted-bulk")}
                  </StyledDialogContentText>
                  <DialogContainer container xs={12} sm={12} md={12} lg={12}>
                    <DeleteAllCardContainer backGroundColor={"#ECF4ED"}>
                      <>
                        {sucessCase.map((item) => (
                          <>
                            <RoleCardForDelete
                              showSelection={false}
                              hideActions={true}
                              data={item.item}
                              icon={
                                <span className="icon-container">
                                  <TaskAltIcon
                                    sx={{
                                      fontSize: "14px",
                                      color: "rgb(25, 128, 37)",
                                    }}
                                  />
                                </span>
                              }
                              iconFor={
                                <TaskAltIcon
                                  sx={{
                                    fontSize: "14px",
                                    color: "rgb(25, 128, 37)",
                                  }}
                                />
                              }
                              color="#ECF4ED"
                              size={isSmall ? "small" : "large"}
                              state="success"
                              showMore={false}
                              onClick={() => {
                                setSelectedRole((prev) => ({
                                  ...prev,
                                  success:
                                    prev.success === item.item.id
                                      ? null
                                      : item.item.id,
                                }));
                              }}
                            />
                            {selectedRole.success === item.item.id && (
                              <Box sx={{ marginTop: "5px" }}>
                                <RoleCardV3
                                  data={item.item}
                                  hideActions={true}
                                  showSelection={false}
                                  showMore={false}
                                  size={isSmall ? "small" : "large"}
                                  state="success"
                                />
                              </Box>
                            )}
                          </>
                        ))}
                      </>
                    </DeleteAllCardContainer>
                  </DialogContainer>
                </Box>
              </StyledDelteMessageContainer>
            ) : null}
            {accessDeniedCases?.length ? (
              <StyledDelteMessageContainer>
                <StyledDialogContentTextForRemovalForResult minMargin="16px">
                  <span className="icon-span">
                    <CancelOutlinedIcon
                      sx={{ fontSize: "20px", color: "rgb(217, 33, 33)" }}
                    />
                  </span>
                </StyledDialogContentTextForRemovalForResult>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "100%",
                  }}
                >
                  <StyledDialogContentTextForRemovalForResult minMargin="16px">
                    {t("missing-permissions")}
                  </StyledDialogContentTextForRemovalForResult>
                  <StyledDialogContentText paddingSize="0px">
                    {accessDeniedCases?.length === 1
                      ? t("delete-role-fail-permission")
                      : t("delete-roles-fail-permission")}
                  </StyledDialogContentText>
                  <DialogContainer container xs={12} sm={12} md={12} lg={12}>
                    <DeleteAllCardContainer backGroundColor={"#FBEDED"}>
                      <>
                        {accessDeniedCases.map((item) => (
                          <>
                            <RoleCardForDelete
                              showSelection={false}
                              data={item.item}
                              icon={
                                <span className="icon-container">
                                  <CancelOutlinedIcon
                                    sx={{
                                      fontSize: "14px",
                                      color: "rgb(217, 33, 33)",
                                    }}
                                  />
                                </span>
                              }
                              iconFor={
                                <CancelOutlinedIcon
                                  sx={{
                                    fontSize: "14px",
                                    color: "rgb(217, 33, 33)",
                                  }}
                                />
                              }
                              hideActions={true}
                              color="#FBEDED"
                              size={isSmall ? "small" : "large"}
                              state="error"
                              isDisplayValidity={false}
                              showMore={false}
                              onClick={() =>
                                setSelectedRole((prev) => ({
                                  ...prev,
                                  "access-denied":
                                    prev["access-denied"] === item.item.id
                                      ? null
                                      : item.item.id,
                                }))
                              }
                            />
                            {selectedRole["access-denied"] === item.item.id && (
                              <Box sx={{ marginTop: "5px" }}>
                                <RoleCardV3
                                  data={item.item}
                                  hideActions={true}
                                  showSelection={false}
                                  showMore={false}
                                  size={isSmall ? "small" : "large"}
                                  state="error"
                                />
                              </Box>
                            )}
                          </>
                        ))}
                      </>
                    </DeleteAllCardContainer>
                  </DialogContainer>
                </Box>
              </StyledDelteMessageContainer>
            ) : null}
            {failedCases?.length ? (
              <StyledDelteMessageContainer>
                <StyledDialogContentTextForRemovalForResult minMargin="16px">
                  <span className="icon-span">
                    <CancelOutlinedIcon
                      sx={{ fontSize: "20px", color: "rgb(217, 33, 33)" }}
                    />
                  </span>
                </StyledDialogContentTextForRemovalForResult>

                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "100%",
                  }}
                >
                  <StyledDialogContentTextForRemovalForResult minMargin="16px">
                    {t("techinical-issues")}
                  </StyledDialogContentTextForRemovalForResult>
                  <StyledDialogContentText paddingSize="0px">
                    {failedCases?.length === 1
                      ? t("delete-role-fail")
                      : t("delete-roles-fail")}
                  </StyledDialogContentText>
                  <DialogContainer container xs={12} sm={12} md={12} lg={12}>
                    <DeleteAllCardContainer backGroundColor={"#FBEDED"}>
                      {failedCases.map((item) => (
                        <>
                          <RoleCardForDelete
                            showSelection={false}
                            data={item.item}
                            icon={
                              <span className="icon-container">
                                <CancelOutlinedIcon
                                  sx={{
                                    fontSize: "14px",
                                    color: "rgb(217, 33, 33)",
                                  }}
                                />
                              </span>
                            }
                            color="#FBEDED"
                            hideActions={true}
                            size={isSmall ? "small" : "large"}
                            state="error"
                            isDisplayValidity={false}
                            showMore={false}
                            onClick={() =>
                              setSelectedRole((prev) => ({
                                ...prev,
                                failed:
                                  prev.failed === item.item.id
                                    ? null
                                    : item.item.id,
                              }))
                            }
                          />
                          {selectedRole.failed === item.item.id && (
                            <Box sx={{ marginTop: "5px" }}>
                              <RoleCardV3
                                data={item.item}
                                hideActions={true}
                                showMore={false}
                                showSelection={false}
                                size={isSmall ? "small" : "large"}
                                state="error"
                              />
                            </Box>
                          )}
                        </>
                      ))}
                    </DeleteAllCardContainer>
                  </DialogContainer>
                </Box>
              </StyledDelteMessageContainer>
            ) : null}
          </>
        </StyledDialogContent>
        <StyledDialogAction>
          <SecondaryButton
            label={t("close")}
            onButtonClick={handleClose}
            className="user-save-button-align pointer"
          />
        </StyledDialogAction>
      </StyledDialog>
    </React.Fragment>
  );
};

export default ResultDialog;
