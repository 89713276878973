import { Divider, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '../link'

/** Generic Footer with copyright and legal links */
export const Footer = forwardRef((
  {
    textStyle = {
      fontSize: 16,
      color: '#888'
    },
    isNavFooter = false,
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const texts = {
    cookies: t('footer.cookies'),
    privacy: t('footer.privacy'),
    legal: t('footer.legal'),
    copyright: t('footer.copyright', { year: new Date().getFullYear() })
  }

  const textStyles = { userSelect: 'none', ...textStyle }

  const defaultLinks = {
    cookies: '/access/cookies',
    privacy: 'https://group.mercedes-benz.com/privacy',
    legal: 'https://group.mercedes-benz.com/provider'
  }

  const getLinkForHref = (type) => {
    if (type === 'cookies') {
      return window.location.origin + defaultLinks[type]
    }
    let url = t(`footer.links.${type}`)

    if (url === `footer.links.${type}`) {
      // no key in translation, so use default
      url = defaultLinks[type]
    }

    return url
  }

  return (
    <Stack
      data-testid="Footer"
      ref={ref}
      gap={2}
      sx={[
        {
          display: 'grid',
          gridTemplate: {
            xs: `
              'divider'
              'copyright'
              'links' / 1fr
            `,
            md: `
              'divider divider'
              'copyright links' / 1fr 1fr
            `
          },
          position: 'absolute',
          bottom: 0,
          transform: 'translateY(100%)',
          right: 0,
          left: 0,
          alignItems: 'center',
          pb: 4
        },
        isNavFooter && {
          gridTemplate: {
            xs: `
              'links'
              'divider'
              'copyright' / 1fr
            `
          },
          position: 'unset',
          transform: 'none',
          marginTop: 'auto'
        }
      ]}
      {...otherProps}
    >
      <Divider
        flexItem
        variant="middle"
        sx={{
          width: '100%',
          gridArea: 'divider',
          marginInline: 0
        }}
      />

      <Stack
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row'
        }}
        gap={3}
        justifyContent="space-between"
        sx={{ width: 1, gridArea: 'copyright' }}
      >
        <Stack
          justifyContent={{
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start'
          }}
          alignItems="center"
          spacing={2}
          sx={{ px: 2, textAlign: { xs: 'center' } }}
        >
          <Typography
            sx={[
              { ...textStyles },
              isNavFooter && {
                fontSize: '12px',
                lineHeight: '16px'
              }
            ]}>
            {texts.copyright}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction={isNavFooter ? 'column' : 'row'}
        justifyContent={{
          xs: 'center',
          lg: 'flex-end'
        }}
        gap={2}
        divider={!isNavFooter && (<Divider orientation="vertical" flexItem />)}
        sx={[
          { px: 2, gridArea: 'links' },
          isNavFooter && {
            alignItems: 'flex-start',
            marginBottom: '12px',
            gap: 0
          }
        ]}
      >
        {[ 'cookies', 'privacy', 'legal' ].map((item, i) => (
          <Link
            data-testid={`Footer-${item}`}
            href={getLinkForHref(item)}
            target="_blank"
            rel="noreferrer"
            sx={[ { ...textStyles, textAlign: 'center' },
              isNavFooter && {
                color: 'text.primary',
                fontSize: '12px',
                lineHeight: '16px',
                padding: '10px 16px'
              } ]}
            key={`LinksAtRight_${i}`}
          >
            {texts[item]}
          </Link>
        ))}
      </Stack>
    </Stack>
  )
})

Footer.propTypes = {
  /** A style object for the typo */
  textStyle: PropTypes.object
}
