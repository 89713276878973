import React, { useCallback, useEffect, useRef } from "react";
import { FormControl } from "@mui/material";
import { SearchBar } from "@alice/component_library";
import { useState } from "react";

const SearchComponent = ({
  onSearchHandler,
  windowSize,
  translate,
  searchKeyWord,
  isAdmin,
}) => {
  const [search, setSearch] = useState(searchKeyWord);
  const { isMobile, isTablet, isDesktop } = windowSize;
  const searchRef = useRef(null);

  const onSearch = (searchQuery) => {
    setSearch(searchQuery);
    onSearchHandler(searchQuery, searchRef);
  };

  return (
    <FormControl
      style={{ width: "100%" }}
      variant="filled"
      className="Search_bar"
    >
      <SearchBar
        ref={searchRef}
        label={translate(
          isAdmin
            ? "searchbar_label_overview_admin"
            : "searchbar_label_overview"
        )}
        preselectQuery={searchKeyWord}
        id="standard-searchfield"
        onSearch={() => onSearch(search)}
        onClear={() => onSearch("")}
        onChange={(e) => setSearch(e.target.value)}
        variant="filled"
        className="h-55 bg-color"
        autoComplete="off"
      />
    </FormControl>
  );
};

export default SearchComponent;
