import React from "react";
import { useTranslation } from "react-i18next";

import MyProfileTab from "./myProfile";
import MyOrganizations from "./myOrganization";
import MyAdminRoles from "./myAdministratorRoles";
import MyAdministartors from "./myAdministatrator";
import MyApplications from "./myApplications";
import MyColleagues from "./myColleague";
import MyAccounts from "./myAccount";
import MyRolesTab from "./myRoles";
import { TabContainer } from "../../styledComponents";
import { TabHeaderStyled } from "../../styledComponents";

const getTabs = (tab, isResponsive, isCollapsed) => {
  switch (tab) {
    case "myProfile":
      return <MyProfileTab isResponsive={isResponsive} />;
    case "myOrganizations":
      return <MyOrganizations isResponsive={isResponsive} />;
    case "myRoles":
      return <MyRolesTab isResponsive={isResponsive} />;
    case "myApplications":
      return <MyApplications isResponsive={isResponsive} />;
    case "myAdministartors":
      return (
        <MyAdministartors
          isResponsive={isResponsive}
          isCollapsed={isCollapsed}
        />
      );
    case "myAdmininstratorRoles":
      return <MyAdminRoles isResponsive={isResponsive} />;
    case "myColleagues":
      return (
        <MyColleagues isResponsive={isResponsive} isCollapsed={isCollapsed} />
      );
    case "myAccounts":
      return <MyAccounts isResponsive={isResponsive} />;
    default:
      return <MyProfileTab isResponsive={isResponsive} />;
  }
};

const ProfileTabPage = ({ tabName, isResponsive, isCollapsed }) => {
  const { t } = useTranslation();
  const tab = getTabs(tabName, isResponsive, isCollapsed);

  return (
    <TabContainer className="tab-container">
      <TabHeaderStyled>{t(`profileTabHeading.${tabName}`)}</TabHeaderStyled>
      {tab}
    </TabContainer>
  );
};

export default ProfileTabPage;
