import React, { useRef } from "react";
import { Grid, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { blue } from "@mercedes-benz/mui5-theme";

import ImageUnavailable from "@pageIcons/imageUnavailable";
import { IconButtonText } from "@alice/component_library";
import { TextOnlyTooltip } from "@components/TextOnlyTooltip";

import "./profile.css";
import "@components/roleDetails.css";

const MainCarContainer = styled(Card)(({ theme }) => ({
  background: "#f2f2f2 !important",
  color: "#1a1919 !important",
  cursor: "default",
  // minHeight: "85px",
  minHeight: "100%",
  borderRadius: "0px",
  padding: "20px 20px 20px 20px",
  boxShadow: "none !important",

  "& .paperClass": {
    paddingLeft: "10px !important",
    fontSize: "18px !important",
    fontWeight: "bolder",
    lineHeight: "42px",
  },
  "& .paperClassText": {
    fontSize: "18px !important",
    fontWeight: 400,
    lineHeight: "42px",
  },
  "& .disabledbutton": {
    marginLeft: "1rem",
    borderRadius: 0,
    textTransform: "none",
    fontSize: "14px",
    minWidth: 120,
    width: "25%",
    backgroundColor: "rgba(0, 0, 0, 0.13) !important",
  },
  "& .paper": {
    minWidth: "550px",
  },
  "& .papersm": {
    minWidth: "340px",
  },
  "& .formControl": {
    width: "100%",
  },
  "& .Appdetails": {
    fontFamily: "MB Corpo S Text",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0px",
    textAlign: "left",
    lineHeight: "24px",
    wordBreak: "break-all",
  },
  "& .detailsBlock": {
    display: "flex",
    flexDirection: "column",
    marginLeft: "15px",
  },
  "& .apptext": {
    maxWidth: "40%",
  },
  "& .logoText": {
    width: "100%",
    marginLeft: "20px",
  },
  "& .mt10": {
    marginTop: "10px",
  },
  "& .buttonContainer": {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  "& .iconOptions": {
    marginTop: "5px",
    marginLeft: "10px",
  },
  "& .my-AppcardTitle": {
    marginLeft: "10px",
  },
}));

const MyApplicationCard = (props) => {
  const elem = props.data;
  const { t } = useTranslation();
  const buttonRef = useRef(null);

  return (
    <>
      {elem && (
        <MainCarContainer>
          <Grid container sx={{ flexWrap: "nowrap" }}>
            <Grid item className="apptext">
              {elem.logoBase64 ? (
                <span className="application-info ">
                  <img
                    className="appLog"
                    src={`data:image/png;base64,${elem.logoBase64}`}
                    alt=""
                  />
                </span>
              ) : (
                <span className="application-info ">
                  <ImageUnavailable />
                </span>
              )}
            </Grid>
            <Grid item className="detailsBlock logoText">
              <TextOnlyTooltip title={elem.name}>
                <span className="ellipse-app my-AppcardTitle">{elem.name}</span>
              </TextOnlyTooltip>

              <Grid item className="iconOptions">
                <span className="App-Content wb">{elem.id}</span>
              </Grid>
              <Grid item className="buttonContainer mt10">
                <div ref={buttonRef}>
                  <IconButtonText
                    text={t("details")}
                    icon={<InfoIcon />}
                    onClick={(e) =>
                      props.onClick(props.index, elem, e, buttonRef)
                    }
                    active={elem.isOpen}
                    className="Appdetails"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </MainCarContainer>
      )}
    </>
  );
};

export default MyApplicationCard;
