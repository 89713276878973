import React from "react";
import { useTranslation } from "react-i18next";
import { CalendarMonthOutlined } from "@mui/icons-material";
import {
  AliceIcon,
  AliceIconType,
  IdChip,
  Tooltip,
} from "@alice/component_library";
import StatusRoleConfig from "../StatusRoleConfiguration";
import {
  HeaderContainer,
  CardContainer,
  DetailContainer,
  MainTitleHeadContainer,
  HeadH2,
  Iconwrapper,
  IconContainer,
  Count,
} from "./styledComponents";

const ConfigurationCard = ({
  data,
  selectedId,
  isConfigured = false,
  onClick = () => {},
  consfigureData = {},
}) => {
  const { t } = useTranslation();
  const {
    id,
    data: { roleDefinition },
  } = data;

  const getTotalCustomScopeCount = () => {
    let totalCount = 0;
    if (roleDefinition?.needsCustomScopes) {
      const scopeData =
        consfigureData?.configurationInformation?.scopeData?.data;
      if (scopeData) {
        Object.values(scopeData).forEach((scope) => {
          if (scope.customScope && Array.isArray(scope.customScope)) {
            totalCount += scope.customScope.length;
          }
        });
      }
    } else {
      totalCount = Object.keys(
        consfigureData?.configurationInformation?.scopeData?.data
      ).length;
    }
    return totalCount;
  };

  return (
    <CardContainer
      key={id}
      isSelected={selectedId === id}
      onClick={() => onClick(id)}
    >
      <HeaderContainer>
        <div className="rightcheck-icon">
          <StatusRoleConfig status={isConfigured ? "success" : "error"} />
        </div>
        <DetailContainer>
          <MainTitleHeadContainer>
            <IdChip />
            <HeadH2>
              <div>{id}</div>
            </HeadH2>
          </MainTitleHeadContainer>
          <MainTitleHeadContainer>
            <HeadH2 type="subhead">
              <div>{roleDefinition.name}</div>
            </HeadH2>
          </MainTitleHeadContainer>
          <IconContainer>
            {roleDefinition.defaultValidityType !== "OPTIONAL" ? (
              <Tooltip
                type="ellipsized"
                title={
                  roleDefinition.defaultValidityType === "FIXED"
                    ? t("fixed-validity")
                    : t("mandatory-validity")
                }
              >
                <Iconwrapper>
                  <CalendarMonthOutlined />
                </Iconwrapper>
              </Tooltip>
            ) : (
              <></>
            )}

            {roleDefinition?.needsOrgScopes ||
            roleDefinition?.needsCustomScopes ? (
              <Iconwrapper>
                {roleDefinition?.needsOrgScopes ? (
                  <Tooltip type="ellipsized" title={t("org-scope")}>
                    <span className="icon-container">
                      <AliceIcon
                        iconType={AliceIconType.UIDate_From}
                        size={16}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {roleDefinition?.needsCustomScopes ? (
                  <Tooltip type="ellipsized" title={t("custom-scope")}>
                    <span className="icon-container">
                      <AliceIcon
                        iconType={AliceIconType.CUSTOM_SCOPE}
                        size={16}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {consfigureData?.hasOwnProperty("configurationInformation") &&
                consfigureData?.configurationInformation?.hasOwnProperty(
                  "scopeData"
                ) &&
                Object.keys(
                  consfigureData?.configurationInformation?.scopeData?.data
                ).length ? (
                  <>
                    {roleDefinition?.needsOrgScopes ? (
                      <>
                        <div className="vertical-line"></div>
                        <Count>{getTotalCustomScopeCount()}</Count>
                      </>
                    ) : (
                      <>
                        <div className="vertical-line"></div>
                        <Count>
                          {
                            consfigureData?.configurationInformation?.scopeData
                              ?.data[0]?.customScope.length
                          }
                        </Count>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Iconwrapper>
            ) : (
              <></>
            )}
          </IconContainer>
        </DetailContainer>
      </HeaderContainer>
    </CardContainer>
  );
};

export default ConfigurationCard;
