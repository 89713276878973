import { styled } from "@mui/material";

export const StyledViewSwitch = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginLeft: "auto",
}));

export const IconContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "& .MuiIconButton-root": {
    padding: "12px",
  },
}));
