import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../util'

/** Simple state icon */
export const StateIcon = forwardRef((
  {
    state,
    sx = [],
    ...otherProps
  },
  ref
) => {
  switch (state) {
    case 'success':
      return (
        <TaskAltOutlinedIcon
          data-testid="StateIcon-success"
          ref={ref}
          sx={mergeSxProps({ color: 'success.main' }, sx)}
          {...otherProps}
        />
      )
    case 'info':
      return (
        <InfoOutlinedIcon
          data-testid="StateIcon-info"
          ref={ref}
          sx={mergeSxProps({ color: 'info.main' }, sx)}
          {...otherProps}
        />
      )
    case 'warning':
      return (
        <ErrorOutlineOutlinedIcon
          data-testid="StateIcon-warning"
          ref={ref}
          sx={mergeSxProps({ color: 'warning.main' }, sx)}
          {...otherProps}
        />
      )
    case 'error':
      return (
        <CancelOutlinedIcon
          data-testid="StateIcon-error"
          ref={ref}
          sx={mergeSxProps({ color: 'error.main' }, sx)}
          {...otherProps}
        />
      )
    default:
      return null
  }
})

StateIcon.propTypes = {
  /** State */
  state: PropTypes.oneOf([ 'success', 'info', 'warning', 'error' ]),
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
