import { Stack, Step, StepButton, StepLabel, Typography } from '@mui/material'
import { mergeSxProps } from '../../util'
import { CircularProgressWithIcon } from './Components'
import { StepperInteraction } from './Stepper.data'

export const MobileStepper = ({
  steps,
  activeStep,
  interaction,
  firstUncompletedStep,
  isStepComplete,
  isStepSkipped,
  isStepDisabled,
  isNonLinear,
  disabled,
  isMobile,
  isDone,
  onStepClick = () => {}
}) => (
  <Stack
    sx={{
      flexDirection: 'row',
      gap: 1.5,
      alignItems: 'center'
    }}
  >
    <CircularProgressWithIcon
      step={activeStep}
      maxSteps={steps.length}
      isStepComplete={isStepComplete}
      activeStep={activeStep}
      steps={steps}
      isMobile={isMobile}
      isDone={isDone}
    />

    <Step
      className={steps[activeStep] <= activeStep ? 'active' : ''}
      completed={isStepComplete(activeStep)}
      disabled={
        disabled // stepper is disabled
        || steps[activeStep].disabled // step is disabled
        || !(isNonLinear
          || activeStep // not disabled if step is active or before
          || isStepComplete(activeStep) // not disabled if step is completed
          || (isStepComplete(activeStep - 1) && !isStepDisabled(activeStep - 1)) // not disabled if prev step is completed and not disabled
          || (isStepSkipped(activeStep - 1) && !isStepDisabled(activeStep - 1)) // not disabled if prev step is skipped and not disabled
          || (steps[activeStep].optional && firstUncompletedStep > activeStep)) // not disabled if step is optional
      }
      sx={mergeSxProps({ minWidth: '150px', padding: 0 }, steps[activeStep].sx)}
    >
      {interaction === StepperInteraction.NONE
        ? (
          <StepLabel
            id={steps[activeStep]?.id || activeStep}
            sx={{ justifyContent: 'start' }}
          >
            <Typography
              variant="subtitle2"
              sx={[
                { color: 'primary.main' },
                isStepComplete(activeStep) && { color: 'primary.main' },
                steps[activeStep].disabled && { color: 'text.disabled' }
              ]}
            >
              {steps[activeStep].title || '\u00a0'}
            </Typography>
          </StepLabel>
        )
        : (
          <StepButton
            onClick={onStepClick}
            id={steps[activeStep]?.id || activeStep}
            sx={{ justifyContent: 'start' }}
          >
            <Typography
              variant="subtitle2"
              sx={[
                { color: 'primary.main' },
                isStepComplete(activeStep) && { color: 'primary.main' },
                steps[activeStep].disabled && { color: 'text.disabled' }
              ]}
            >
              {steps[activeStep].title || '\u00a0'}
            </Typography>
          </StepButton>
        )}

      <Typography
        className="stepContent"
        variant="body2"
        component="div"
        sx={[
          { color: 'primary.main' },
          isStepComplete(activeStep) && { color: 'text.secondary' },
          steps[activeStep].disabled && { color: 'text.disabled' }
        ]}
      >
        {steps[activeStep].content || '\u00a0'}
      </Typography>
    </Step>
  </Stack>
)
