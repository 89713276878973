import { Client, Entitlement, Entitlements } from '../../constants'
import { USER_COMMON_COMMUNITIES, USER_SPECIAL_COMMUNITIES } from '../AliceConstants'

const managementLevelMapping = {
  '005': 'E5',
  '004': 'E4',
  '003': 'E3',
  '002': 'E2',
  '001': 'E1',
  C: 'E1',
  B: 'BOM',
  A: 'CEO'
}

const groupTypeDefinition = {
  0: {
    name: 'Mercedes-Benz AG',
    short: 'MBAG'
  },
  1: {
    name: 'Daimler Truck AG',
    short: 'DTAG'
  },
  S: {
    name: 'Mercedes-Benz AG & Daimler Truck AG',
    short: 'MB_DTAG'
  }
}

const ApiConsumableWebServices = {
  supplierDatabaseConsumer: 'SDB',
  iagToolConsumerConsumer: 'IAGTOOL',
  internalConsumer: 'INTERNAL',
  ispDataManagementConsumer: 'IDM',
  runtimeAdminConsumer: 'RUNTIME_ADMIN',
  selfServiceConsumer: 'SELF_SERVICE',
  userManagementAfterSalesConsumer: 'UMAS'
}

/**
 * Usage:
 *
 * user.is.emtUser(['a','DAIMLER_EXTERNAL']) -> true
 * user.is.emtUser(['a','DAIMLER_EXTERNAL2']) -> false
 * user.is.internalEmployee(['a','DAIMLER_EXTERNAL']) -> true
 * user.is.dealer(['a','DEALER_EMPLOYEE']) -> true
 * user.is.supplier(['x', 'y', 'SUPPLIER_EMPLOYEE']) -> true
 *
 */
export const user = {
  _userTypes: {
    dealer: [ 'DEALER_EMPLOYEE' ],
    supplier: [ 'SUPPLIER_EMPLOYEE' ],
    internal: [ 'DAIMLER_INTERNAL', 'DAIMLER_EXTERNAL' ],
    emt: [ 'DAIMLER_EXTERNAL', 'DAIMLER_POOLID', 'TECH_USER__GLOBAL' ],
    ssa: [ 'SHARED_SERVICE_ADMIN' ],
    daimlerEmployee: [ 'DAIMLER_EMPLOYEE' ]
  },
  _check: (userType, communities) => {
    if (!communities || !Array.isArray(communities)) return false

    for (const community of communities) {
      if (user._userTypes[userType].includes(community)) return true
    }

    return false
  },
  is: {
    dealer: (communities = []) => user._check('dealer', communities),
    supplier: (communities = []) => user._check('supplier', communities),
    emtUser: (communities = []) => user._check('emt', communities),
    internalEmployee: (communities = []) => user._check('internal', communities),
    shardedServiceAdmin: (communities = []) => user._check('ssa', communities),
    daimlerEmployee: (communities = []) => user._check('daimlerEmployee', communities)
  }
}

export const getUserName = (userData) => ((userData?.givenname && userData?.surname && `${userData?.givenname} ${userData?.surname}`)
  || userData?.completeName
  || 'No name!'
)

export const getGroupTypeAsName = (groupType) => groupTypeDefinition[groupType]

export const getManagementLevel = (levelCode) => managementLevelMapping[levelCode]

export const isInternalEmployee = (userCommunities = []) => user.is.supplier(userCommunities)

export const isEmtUser = (userCommunities = []) => user.is.emtUser(userCommunities)

export const isShardedServiceAdmin = (userCommunities = []) => user.is.shardedServiceAdmin(userCommunities)

export const checkAuthority = (client, entitlements, adminRoles) => {
  switch (client && client.toLowerCase()) {
    case Client.CONFIG:
      return entitlements && Entitlements.some((entitlement) => entitlements.has(entitlement))
    case Client.ADMIN:
    case Client.IDENTITY:
      return (entitlements && (
        entitlements.has(Entitlement.FUNCTIONAL_ADMINISTRATION)
          || entitlements.has(Entitlement.SHARED_SERVICE_ADMIN)))
        || (adminRoles && adminRoles.size > 0)
    default:
      return true
  }
}

export const getUserCommunities = (userCommunities) => {
  if (!userCommunities || !Array.isArray(userCommunities)) return []

  const specialCommunities = []
  let restCommunities = []

  userCommunities.forEach((community) => {
    if (USER_SPECIAL_COMMUNITIES.includes(community)) {
      specialCommunities.push(community)
    } else if (USER_COMMON_COMMUNITIES.includes(community)) {
      restCommunities.push(community)
    }
  })

  if (restCommunities.length > 1
    && (restCommunities.includes('DAIMLER_INTERNAL')
      || restCommunities.includes('DAIMLER_EXTERNAL')
      || restCommunities.includes('DAIMLER_POOLID')
      || restCommunities.includes('DAIMLER_JOINT_VENTURE_PERSON'))
  ) {
    restCommunities = restCommunities.filter((community) => community !== 'DAIMLER_EMPLOYEE')
  }

  return restCommunities.concat(specialCommunities)
}

export const getFineGranularUserCommunity = (userCommunities = [], community = '') => {
  for (let i = 0; i < userCommunities.length; i++) {
    if (userCommunities[i].startsWith(community) && userCommunities[i]?.includes('__')) {
      return userCommunities[i]
    }
  }

  return null
}

export const getSecialCommunityForIcon = (communities) => {
  let techUser
  let otherUserSpecialCommunity

  communities?.forEach((community) => {
    if (USER_SPECIAL_COMMUNITIES.includes(community) && community === 'TECH_USER') {
      techUser = community
    } else if (USER_SPECIAL_COMMUNITIES.includes(community) && community !== 'TECH_USER') {
      otherUserSpecialCommunity = community
    }
  })

  return techUser || otherUserSpecialCommunity || null
}

export const convertWebServiceToConsumableWebService = (webService) => ApiConsumableWebServices[webService]
