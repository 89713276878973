import { styled } from "@mui/system";

export const CardContainer = styled("div")(({ isWithCustomScope, theme }) => ({
  display: "grid",
  height: "40px",
  gridTemplateColumns: "1fr auto",
  padding: "8px",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
  borderRadius: "4px",
  background: isWithCustomScope ? "#FFF" : "#F8F8F8",
  marginTop: "8px",
  ...(isWithCustomScope && {
    border: "1px solid #DBDBDB",
  }),
  "& .icon-name-container": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    overflow: "hidden",
    "& .text": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  "& .org-icon": {
    display: "flex",
    paddingRight: "8px",
  },
  "& .icon": {
    borderLeft: "1px solid #DBDBDB",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    cursor: "pointer",
    "& svg": { color: "#0078D6" },
  },
}));
