import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CardContainer } from "./styledComponents";
import { Tooltip } from "@mui/material";

export const OrgScopeCard = ({
  name,
  icon,
  actionHandler = () => {},
  isWithCustomScope = false,
  isActionVisible = true,
}) => {
  return (
    <CardContainer isWithCustomScope={isWithCustomScope}>
      <div className="icon-name-container">
        <span className="org-icon">{icon}</span>
        <Tooltip title={name} type="ellipsized">
          <span className="text">{name}</span>
        </Tooltip>
      </div>
      {isActionVisible && (
        <span className="icon" onClick={() => actionHandler()}>
          <HighlightOffIcon />
        </span>
      )}
    </CardContainer>
  );
};
