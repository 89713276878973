import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import DownloadIcon from "@Icons/downloadIcon.svg";
import InfoIcon from "@Icons/Info.svg";
import massImport from "@assets/documents/MassImport.pdf";
import sampleCSV from "@assets/documents/Example CSV.csv";
import { Switch } from "@mui/material";
import { Radio } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { FormControl, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { useTranslation } from "react-i18next";

const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  "& .fileBulkUpload": {
    "& svg": {
      display: "none",
    },
    "& > label": {
      maxWidth: "100%",
      alignItems: "center",
      height: "120px",
      [theme.breakpoints.down("md")]: {
        minWidth: "264px",
      },
      "& > div": {
        justifyContent: "center",
        fontWeight: 700,
      },
    },
    "& .file-types": {
      display: "none",
    },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },

  "& .radioGroup": {
    "& input": {
      opacity: "1 !important",
    },
  },
  "& .header": {
    display: "flex",
    alignItems: "flex-end",
    background: "#fff",
    height: "auto",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "baseline",
      "& .headerBulkUploadA": {
        paddingLeft: "13px",
      },
      "& .headerForDownloadLinkAndIcon": {
        alignItems: "baseline",
        display: "flex",
        flexDirection: "column",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  "& .headerBulkUploadA": {
    fontSize: "1rem",
  },
  "& .headerForDownloadLinkAndIcon": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  "& .headerBulkUploadB": {
    fontSize: "1rem",
    color: "#0078D6",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "& .headerBulkUploadBWrapperForNoFile": {
    paddingRight: "10px",
    flexDirection: "row",
  },
  "& .fileUploaderBorderForFilePresent": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    "& > label > div": {
      justifyContent: "center",
      fontWeight: 700,
    },
  },
  "& .secondaryButtonStyling": {
    marginLeft: "30px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: "10px",
    },
  },
  "& .attentionStyling": {
    fontSize: "10px",
    lineHeight: "10px",
    fontWeight: 700,
    display: "block",
    paddingLeft: "10px",
  },
  "& .attentionStylingForBulkRemoval": {
    fontSize: "11px",
    lineHeight: "16px",
    display: "block",
    fontWeight: "700",
  },
  "& .browser": {
    justifyContent: "center",
  },
  "& .fileBulkUploadIfFilePresent": {
    "& label": {
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        minWidth: "264px",
      },

      "& svg": {
        display: "none",
      },
      "& .dmajdp": {
        justifyContent: "center",
        color: "blue",
      },

      "& .file-types": {
        display: "none",
      },
      "& .bXgVCj span": {
        textDecoration: "none",
      },
    },
  },
  "& .selectFile": {
    border: "dashed 2px #0658c2",
    padding: "8px 16px 8px 8px",
    borderRadius: "5px",
    cursor: "pointer",
    height: "120px",
    maxWidth: "100%",
    alignItems: "center",
  },
  "& .fileName": {
    textAlign: "center",
    paddingTop: "20px",
    fontSize: "12px",
    color: "#666",
  },
  "& .browseName": {
    textDecoration: "underline",
    textDecorationColor: "#0658c2",
    color: "#0658c2",
    fontSize: "12px",
  },
  "& .message": {
    display: "none",
  },
  "& .helpTextContainer": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    "& svg": {
      display: "block",
    },
  },
  "& .helpTextImage": {
    "& img": {
      width: "25px",
    },
  },
  "& .fileUploaderAfterMain": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    minWidth: "322px",
    maxWidth: "508px",
    height: "48px",
    border: "2px dashed rgb(6, 88, 194)",
    padding: "8px 16px 8px 8px",
    borderRadius: "5px",
    cursor: "pointer",
    flexGrow: 0,
    "& div": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    "& span": {
      fontSize: "12px",
      color: "rgb(102, 102, 102)",
      justifyContent: "center",
    },
  },
}));

function FileBulkImport({ isAdd, submitHandler }) {
  const { t } = useTranslation();
  const fileTypes = ["CSV", "Xls"];

  const [file, setFile] = useState(null);
  const [header, setHeader] = useState(false);
  const [encoding, setEncoding] = useState("UTF-8");

  const handleChange = (selected) => {
    setFile(selected);
  };

  const handleClickChange = () => {
    document.querySelector("input[type=file]").click();
  };

  return (
    <MainContainer>
      {!file ? (
        <div className="fileBulkUpload">
          <div className="header">
            <div>
              {isAdd ? (
                <h1 className="headerBulkUploadA">{t("select_file")}</h1>
              ) : (
                <h1 className="headerBulkUploadA">{t("file")}</h1>
              )}
            </div>

            {isAdd ? (
              <>
                <div className="headerForDownloadLinkAndIcon">
                  <a
                    href={massImport}
                    without
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="helpTextContainer">
                      <div className="headerBulkUploadBWrapperForNoFile">
                        <h1 className="headerBulkUploadB">
                          {t("help-document")}
                        </h1>
                      </div>
                      <div className="helpTextImage">
                        <img
                          src={InfoIcon}
                          alt=""
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </a>
                  <a href={sampleCSV} download>
                    <div className="helpTextContainer">
                      <div className="headerBulkUploadBWrapperForNoFile">
                        <h1 className="headerBulkUploadB">
                          {t("download_CSV_example_file")}
                        </h1>
                      </div>
                      <div style={{ paddingLeft: "5px" }}>
                        <img
                          src={DownloadIcon}
                          alt=""
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </>
            ) : null}
          </div>

          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            hoverTitle={<span />}
            children={
              <div className="selectFile">
                <p className="fileName">
                  {t("drop-CSV-file")}{" "}
                  <span className="browseName">{t("browse")}</span>
                </p>
              </div>
            }
          />
        </div>
      ) : (
        <div className="fileBulkUploadIfFilePresent">
          <div className="header">
            <div>
              {isAdd ? (
                <h1 className="headerBulkUploadA">{t("select_file")}</h1>
              ) : (
                <h1 className="headerBulkUploadA">{t("file")}</h1>
              )}
            </div>

            {isAdd ? (
              <>
                <div className="headerForDownloadLinkAndIcon">
                  <a
                    href={massImport}
                    without
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="helpTextContainer">
                      <div className="headerBulkUploadBWrapperForNoFile">
                        <h1 className="headerBulkUploadB">
                          {t("help-document")}
                        </h1>
                      </div>

                      <div className="helpTextImage">
                        <img
                          src={InfoIcon}
                          alt=""
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </a>
                  <a href={sampleCSV} download>
                    <div className="helpTextContainer">
                      <div className="headerBulkUploadBWrapperForNoFile">
                        <h1 className="headerBulkUploadB">
                          {t("download_CSV_example_file")}
                        </h1>
                      </div>
                      <div style={{ paddingLeft: "5px" }}>
                        <img
                          src={DownloadIcon}
                          alt=""
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </>
            ) : null}
          </div>
          <div className="fileUploaderBorderForFilePresent" id="file-upload">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              dropMessageStyle={{ display: "none" }}
              label={file.name}
              hoverTitle="file"
              children={
                <label className="fileUploaderAfterMain" for="file">
                  <div>
                    <span>{file.name}</span>
                  </div>
                </label>
              }
            />
            <div className="secondaryButtonStyling">
              {isAdd ? (
                <SecondaryButton
                  style={{ marginLeft: "30px" }}
                  onButtonClick={handleClickChange}
                  label={t("exchange_file")}
                />
              ) : (
                <SecondaryButton
                  style={{ marginLeft: "30px" }}
                  onButtonClick={handleClickChange}
                  label={t("change_file")}
                />
              )}
            </div>
          </div>
          <div>
            {isAdd ? (
              <h3 style={{ fontSize: "1rem" }}>{t("add_import_options")}</h3>
            ) : (
              <h3 style={{ fontSize: "1rem" }}>{t("add_info_to_file")}</h3>
            )}
            <h3 style={{ fontSize: "1rem" }}>{t("headline")}</h3>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Switch
                color="default"
                onChange={(e) => setHeader(e.target.checked)}
              />
              <h3 style={{ fontSize: "0.6rem", marginTop: "8px" }}>
                {t("ignore_headline")}
              </h3>
            </div>
            <h3 style={{ fontSize: "1rem" }}>{t("source_file_encoding")}</h3>
            <FormControl className="radioGroup">
              <RadioGroup row defaultValue="UTF-8">
                <FormControlLabel
                  value="UTF-8"
                  control={
                    <Radio
                      style={{ marginRight: "5px" }}
                      onChange={(e) => setEncoding(e.target.value)}
                    />
                  }
                  label="UTF-8"
                />
                <FormControlLabel
                  value="Cp1252"
                  control={
                    <Radio
                      style={{ marginRight: "5px" }}
                      onChange={(e) => setEncoding(e.target.value)}
                    />
                  }
                  label="CP1252 (Windows)"
                />
              </RadioGroup>
            </FormControl>
            <div
              style={
                isAdd
                  ? {
                      display: "flex",
                      padding: "20px 0px ",
                      flexDirection: "row",
                      alignItems: "center",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column-reverse",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      padding: "20px 0px",
                    }
              }
            >
              <div
                style={
                  isAdd
                    ? {
                        display: "flex",
                        flexBasis: "30%",
                        maxWidth: "120px",
                        minWidth: "120px",
                      }
                    : { paddingTop: "10px" }
                }
              >
                <PrimaryButton
                  label={
                    isAdd ? t("assign_roles") : t("remove-role-assignments")
                  }
                  onButtonClick={() => submitHandler(file, header, encoding)}
                />
              </div>

              <div>
                <span
                  className={
                    isAdd
                      ? "attentionStyling"
                      : "attentionStylingForBulkRemoval"
                  }
                >
                  {t("attention_info")}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainContainer>
  );
}

export default FileBulkImport;
