import React, { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  OrgSearch,
  Interaction,
  Accordion,
  AccordionSummary,
} from "@alice/component_library";
import { OrgDialogueCard } from "./OrgDilogueCard";

export const OrgSearchDialogue = ({
  title,
  isOpen,
  preselectedOrg = [],
  otherOrgProps = {},
  isSlectedListBarVisible,
  onSave = ({ orgIds, orgs }) => {},
  onCancel = () => {},
  isLoading = false,
  isSaveDisabled = false,
  isNeedCustomScope = true,
  userId = "REDDYPR",
  roleId = "1402",
  disabledIds = [],
  recentlyUsedData = [],
}) => {
  const { t } = useTranslation();
  const orgSearchRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [originalSelectedOrg, setOriginalSelectedOrg] =
    useState(preselectedOrg);
  const [selectedOrg, setSelectedOrg] = useState(preselectedOrg);
  const [isExpanded, setIsExpanded] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [isRecentlyUsedActive, setIsRecentlyUsedActive] = useState(false);

  const toOrgIds = (orgs) => orgs.map((org) => org.id);

  const handleToggle = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    setOriginalSelectedOrg(preselectedOrg);
    setSelectedOrg(preselectedOrg);
  }, [preselectedOrg]);

  useEffect(() => {
    orgSearchRef?.current?.setSelectedData(toOrgIds(selectedOrg));
  }, [selectedOrg]);

  // Required, because status filter does not work locally
  useEffect(() => {
    if (!isRecentlyUsedActive) return;

    orgSearchRef.current?.clear();
  }, [isRecentlyUsedActive]);

  const handleClose = () => {
    setSelectedOrg(originalSelectedOrg);
    onCancel();
  };

  const handleSave = () => {
    const orgs = selectedOrg || [];
    setOriginalSelectedOrg(orgs);
    onSave({
      orgIds: orgs.map((org) => org.id),
      orgs,
    });
    handleClose();
  };

  const handleCardClick = (orgId, isSelected, orgObject) => {
    const newSelectedOrg = isSelected
      ? isNeedCustomScope
        ? [orgObject]
        : [...selectedOrg, orgObject]
      : selectedOrg.filter((org) => org.id !== orgId);

    setSelectedOrg(newSelectedOrg);
  };

  const handleDeleteClick = (orgObject) => {
    const newSelectedOrg = selectedOrg.filter((org) => org.id !== orgObject.id);
    orgSearchRef.current.setSelectedData(toOrgIds(newSelectedOrg));

    setSelectedOrg(newSelectedOrg);
  };

  const getAPIURL = () => {
    return `/users/${userId}/roles/${roleId}/assignableorganizations`;
  };

  const setResponseDataState = (response, isLocal = false) => {
    setResponseData((prevData) => {
      const newData = !isLocal
        ? response.organizations.filter((org) => {
            return !prevData.some((prevOrg) => prevOrg.id === org.id);
          })
        : response?.data.filter((org) => {
            return !prevData.some((prevOrg) => prevOrg.id === org.id);
          });
      return [...prevData, ...newData];
    });
    return isLocal ? response?.data : response.organizations;
  };

  const onSelectAll = (data) => {
    const selectedOrgData = responseData.filter((org) => data.includes(org.id));

    setSelectedOrg(selectedOrgData);
  };

  const handleRecentlyCheckboxChange = (event) =>
    setIsRecentlyUsedActive(event.target.checked);

  return (
    <Dialog
      data-testid="OrgpickerDialog"
      open={isOpen}
      fullWidth
      maxWidth="lg"
      headerSection={title || t("add-organization-scope")}
      footerSection={
        <Stack direction="row" gap={2}>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            isLoading={isLoading}
            disabled={selectedOrg.length ? false : true}
            onClick={handleSave}
          >
            {t("add-organization-scope")}
          </Button>
        </Stack>
      }
      onClose={handleClose}
    >
      <Stack data-testid="OrgPicker" gap={5}>
        {!isNeedCustomScope && (
          <Accordion
            aria-controls="orgPicker-Content"
            id="orgPicker-Header"
            expanded={isExpanded}
            sx={[
              {
                borderRadius: "4px",
                border: "1px solid",
                borderColor: "divider",
                cursor: "unset",
                boxShadow: "unset",
                transition: "background-color 250ms",
                backgroundColor: "common.white",
              },
            ]}
            header={
              <AccordionSummary onClick={handleToggle}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="row" gap={1} justifyContent="center">
                    <Typography variant="h5">
                      {t("selected-org-scope")}
                    </Typography>

                    <Chip
                      label={selectedOrg?.length || 0}
                      color="primary"
                      size="small"
                      sx={{ fontSize: "12px", fontWeight: "700" }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      flexFlow: "row",
                      gap: 1,
                      color: isExpanded ? "primary.main" : "text.primary",
                    }}
                  >
                    {!isMobile && (
                      <Typography
                        variant="button"
                        sx={{ transition: "color 250ms" }}
                      >
                        {isExpanded
                          ? t("hide-selected-scope")
                          : t("show-selected-scope")}
                      </Typography>
                    )}

                    <ArrowDropDownIcon
                      sx={[
                        { transition: "transform 250ms" },
                        isExpanded && { transform: "rotate(180deg)" },
                      ]}
                    />
                  </Stack>
                </Stack>
              </AccordionSummary>
            }
          >
            {selectedOrg?.length ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                  },
                  gap: 1,
                }}
              >
                {selectedOrg.map((Org) => (
                  <OrgDialogueCard
                    orgName={Org?.id}
                    actionHandler={() => handleDeleteClick(Org)}
                  />
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100px",
                }}
              >
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="text.secondary"
                  sx={{ whiteSpace: "pre-line" }}
                >
                  {t("no-org-accordian")}
                </Typography>
              </Box>
            )}
          </Accordion>
        )}
        <OrgSearch
          localData={isRecentlyUsedActive ? recentlyUsedData : undefined}
          ref={orgSearchRef}
          interaction={
            isNeedCustomScope
              ? Interaction.SINGLE_SELECT
              : Interaction.MULTI_SELECT
          }
          customSlot2={
            <FormControlLabel
              checked={isRecentlyUsedActive}
              onChange={handleRecentlyCheckboxChange}
              control={<Checkbox />}
              label={t("recently-used")}
              sx={{ marginLeft: "8px" }}
            />
          }
          preselectedStatus={isRecentlyUsedActive ? null : "active"}
          preselectedRowIds={toOrgIds(selectedOrg)}
          apiUrl={getAPIURL()}
          onCardClick={handleCardClick}
          onGetResponseData={(response) =>
            isRecentlyUsedActive
              ? setResponseDataState(response, true)
              : setResponseDataState(response)
          }
          onSelectAll={(data) => onSelectAll(data)}
          disabledIds={disabledIds}
          {...otherOrgProps}
        />
      </Stack>
    </Dialog>
  );
};
