import React from "react";
import { FormControl } from "@mui/material";
import { SearchBar } from "@alice/component_library";
import { useState } from "react";

const SearchComponent = ({ label, handleSearch, searchKey }) => {
  const [search, setSearch] = useState(searchKey);
  return (
    <FormControl
      style={{ width: "100%" }}
      variant="filled"
      className="Search_bar"
    >
      <SearchBar
        // ref={searchRef}
        label={label}
        id="standard-searchfield"
        onSearch={() => handleSearch(search)}
        onClear={() => {
          setSearch("");
          handleSearch("");
        }}
        onChange={(e) => setSearch(e.target.value)}
        variant="filled"
        className="h-55 bg-color"
        autoComplete="off"
        value={search}
      />
    </FormControl>
  );
};

export default SearchComponent;
