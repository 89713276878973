import RoleRequestType from "@pages/requestPage/Components/RequestTypes/RoleRequestType";
import DeprovReviewToRoleAssign from "@pages/requestPage/Components/RequestTypes/DeprovReviewToRoleAssign";
import AssignTechToApp from "@pages/requestPage/Components/RequestTypes/AssignTechToApp";
import AssignEntitleToRole from "@pages/requestPage/Components/RequestTypes/AssignEntitleToRole";

const RequestTypeCase = ({
  taskInfo,
  isDeeplink,
  windowSize,
  loggedInUser,
}) => {
  const { taskType } = taskInfo;
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      return (
        <RoleRequestType
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
          windowSize={windowSize}
          loggedInUser={loggedInUser}
        />
      );
    case "APPROVE_ROLE_DEPROVISIONING":
      return (
        <DeprovReviewToRoleAssign
          taskInfo={taskInfo}
          windowSize={windowSize}
          loggedInUser={loggedInUser}
        />
      );
    case "APPROVE_TECHUSER_TO_APPLICATION":
      return (
        <AssignTechToApp
          taskInfo={taskInfo}
          loggedInUser={loggedInUser}
          windowSize={windowSize}
        />
      );
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      return (
        <AssignEntitleToRole
          taskInfo={taskInfo}
          windowSize={windowSize}
          loggedInUser={loggedInUser}
        />
      );
    default:
      return (
        <RoleRequestType
          taskInfo={taskInfo}
          isDeeplink={isDeeplink}
          windowSize={windowSize}
          loggedInUser={loggedInUser}
        />
      );
  }
};

export default RequestTypeCase;
