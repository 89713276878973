import React from "react";
import { Typography } from "@mui/material";
import RoleCardV3 from "@components/RoleCardV3/RoleCardV3";
import RoleCardList from "@components/RoleCardV3/RoleCardList";
import { SummaryCardContainer } from "../../styledComponents";

const ConfiguredRoles = ({ view = "list", configuredRoles = [] }) => {
  const RoleCard = view === "list" ? RoleCardList : RoleCardV3; // different cards for different view
  return (
    <>
      {configuredRoles.map(({ data, configurationInformation }) => (
        <>
          <Typography variant="h4" noWrap sx={{ marginBottom: "16px" }}>
            {data?.roleDefinition?.name}
          </Typography>
          <SummaryCardContainer view={view}>
            {configurationInformation?.scopeData?.data ? ( // Check for scopeData existence
              <>
                {Object?.entries(configurationInformation?.scopeData?.data).map(
                  ([key, scopeData]) => (
                    <>
                      {scopeData?.customScope?.length ? ( //check for custom scope existence
                        <>
                          {scopeData?.customScope?.map(
                            (
                              cust // if custom scope present display data with both org and custom scope
                            ) => (
                              <RoleCard
                                id={data?.roleDefinition?.id}
                                key={key}
                                data={{
                                  ...data,
                                  validFrom: configurationInformation?.timeRange
                                    ?.isDisabled
                                    ? undefined
                                    : configurationInformation?.timeRange
                                        ?.validity?.from,
                                  validTo: configurationInformation?.timeRange
                                    ?.isDisabled
                                    ? undefined
                                    : configurationInformation?.timeRange
                                        ?.validity?.to,
                                  customScope: cust,
                                  orgScope: scopeData,
                                  needsOrgScopes:
                                    data?.roleDefinition?.needsOrgScopes,
                                  needsCustomScopes:
                                    data?.roleDefinition?.needsCustomScopes,
                                }}
                                hideActions={true}
                                showSelection={false}
                                showMore={false}
                              />
                            )
                          )}
                        </>
                      ) : (
                        // if no custom scope display card with only orgscope data
                        <RoleCard
                          id={data?.roleDefinition?.id}
                          key={key}
                          data={{
                            ...data,
                            validFrom: configurationInformation?.timeRange
                              ?.isDisabled
                              ? undefined
                              : configurationInformation?.timeRange?.validity
                                  .from,
                            validTo: configurationInformation?.timeRange
                              ?.isDisabled
                              ? undefined
                              : configurationInformation?.timeRange?.validity
                                  ?.to,
                            customScope: {},
                            orgScope: scopeData,
                            needsOrgScopes:
                              data?.roleDefinition?.needsOrgScopes,
                            needsCustomScopes:
                              data?.roleDefinition?.needsCustomScopes,
                          }}
                          hideActions={true}
                          showSelection={false}
                          showMore={false}
                        />
                      )}
                    </>
                  )
                )}
              </>
            ) : (
              // if no scope display data without orgscope or custom scope
              <RoleCard
                id={data?.roleDefinition?.id}
                key={data?.id}
                data={{
                  ...data,
                  validFrom: configurationInformation?.timeRange?.isDisabled
                    ? undefined
                    : configurationInformation?.timeRange?.validity.from,
                  validTo: configurationInformation?.timeRange?.isDisabled
                    ? undefined
                    : configurationInformation?.timeRange?.validity.to,
                  customScope: {},
                  orgScope: {},
                  needsOrgScopes: data?.roleDefinition?.needsOrgScopes,
                  needsCustomScopes: data?.roleDefinition?.needsOrgScopes,
                }}
                hideActions={true}
                showSelection={false}
                showMore={false}
              />
            )}
          </SummaryCardContainer>
        </>
      ))}
    </>
  );
};

export default ConfiguredRoles;
