import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkeletonOverlay from "@components/Skeleton/SkeletonOverlay";
import TaskPageSkeleton from "@components/Skeleton/TaskPageSkeleton";
import { getTaskstructInfo } from "@src/pages/util";

import {
  getTaskDetails,
  getTaskCounts,
  approveTasks,
  rejectTasks,
} from "@actions/openTaskActions";

import { TaskTableColumns, TaskMenuColumns, TableColumnsMob } from "./columns";
import {
  StickyDataGrid,
  TaskDetailedContainer,
  TaskPageTableContainer,
  TaskFlexContainer,
  TaskDetailsIconButton,
  TaskDetailsAction,
  IdSpanContainer,
} from "../../styledComponents";

import TaskDetails from "../TaskDetails";
import TaskActionButtons from "./actions";
import ApproveRejectBox from "../DialogBox";
import { useNotification } from "@alice/component_library";

function loadServerRows(page, pageSize, data) {
  return new Promise((resolve) => {
    resolve(data.slice(page * pageSize, (page + 1) * pageSize));
  });
}

const TaskDetailsTable = ({
  windowSize,
  isCollapsable = false,
  userId,
  filter = "PENDING",
  search = "",
  isAdmin = true,
  sortValue = "1",
  optionType,
  translate,
}) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const [limit, setLimit] = useState(48);
  const { structInfo, countInfo } = getTaskstructInfo(optionType);
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currrentPageRows, setCurrentPageRows] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [taskInfo, setTaskInfo] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAproove, setIsAproove] = useState(false);
  const taskList = useSelector((state) => state.task.taskInfo[structInfo]);
  const loadingApi = useSelector((state) => state.task.taskInfo.loading);
  const taskListCounts = useSelector((state) => state.task.openTaskCountInfo);
  // const errorApi = useSelector((state) => state.task.taskInfo.errorInfo);

  const { notify } = useNotification();

  const workFlowInfo = useSelector(
    (state) => state.task.taskInfo["workflowInfo"]
  );
  const rowCount = useSelector(
    (state) => state.task.openTaskCountInfo[countInfo]
  );
  const data = taskList.data;
  const { isDesktop, isTablet, isMobile } = windowSize;

  useEffect(() => {
    closeDeatils();
    setPaginationModel((prevPageInfo) => ({ ...prevPageInfo, page: 0 }));
    setRowSelectionModel([]);
  }, [optionType, filter, search, isAdmin, sortValue]);

  const getTaskAndWorkFlow = useCallback(() => {
    let limit =
      optionType === "APPROVE_ROLE_ASSIGNMENT" ||
      optionType === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
      optionType === "allTasks"
        ? 96
        : 48;
    setLimit(limit);

    dispatch(
      getTaskDetails(
        isAdmin,
        userId,
        optionType,
        filter,
        search,
        { taskOffset: 0, workFlow: 0 },
        limit,
        [],
        [],
        {
          taskListTottalCount: limit,
          workFlowTottalCount: limit,
        },
        0,
        sortValue
      )
    );
  }, [dispatch, userId, filter, isAdmin, search, optionType, sortValue]);

  useEffect(() => {
    getTaskAndWorkFlow();
  }, [getTaskAndWorkFlow, search]);

  const handleEvent = (
    params // GridRowParams
  ) => {
    setTaskInfo(params?.row);
    setIsDetailsOpen(true);
    const selectedTaskIndex = data?.findIndex((t) => t?.id === params?.id);
    setSelectedIndex(selectedTaskIndex);
  };

  useEffect(() => {
    const allRowIds = apiRef.current.getAllRowIds();
    setCurrentPageRows(allRowIds);
  }, [paginationModel, apiRef]);

  const handlePagination = (params, event, detatils) => {
    const currentPage =
      taskList.paginationInfo.currentPage > params.page
        ? taskList.paginationInfo.currentPage
        : params.page;
    setPaginationModel({ page: params.page, pageSize: params.pageSize });

    const taskLimit =
      optionType === "APPROVE_ROLE_ASSIGNMENT" ||
      optionType === "APPROVE_APPLICATIONROLE_TO_ROLE" ||
      optionType === "allTasks"
        ? limit / 2
        : limit;

    const taskOffset = taskList.paginationInfo.offset + taskLimit;
    const workflowOffset = workFlowInfo.paginationInfo.offset + limit / 2;
    if (taskList.paginationInfo.currentPage < params.page) {
      dispatch(
        getTaskDetails(
          isAdmin,
          userId,
          optionType,
          filter,
          search,
          { taskOffset: taskOffset, workFlow: workflowOffset },
          limit,
          taskList.data,
          workFlowInfo.data,
          {
            taskListTottalCount: taskList.fetchInfo.totalCount,
            workFlowTottalCount: workFlowInfo.fetchInfo.totalCount,
          },
          currentPage,
          sortValue
        )
      );
    }
  };

  const closeDeatils = () => {
    setIsDetailsOpen(false);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const newRows = await loadServerRows(
        paginationModel.page,
        paginationModel.pageSize,
        data
      );

      if (!active) {
        return;
      }
      setRows(newRows);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page, paginationModel.pageSize, data]);

  useEffect(() => {
    setIsDetailsOpen(false);
  }, [filter]);

  const handleRemove = (id) => {
    const selectedTasks = selectedRows.filter((row) => row.id !== id);
    setSelectedRows(selectedTasks);
    setRowSelectionModel(selectedTasks.map((row) => row.id));
  };

  const refreshData = (sucessCount = 0) => {
    getTaskAndWorkFlow();
    getTaskAndWorkFlowCount(sucessCount);
    setTaskInfo({});
    setIsDetailsOpen(false);
    setSelectedRows([]);
    setRowSelectionModel([]);
  };

  const getTaskAndWorkFlowCount = useCallback(
    (sucessCount = 0) => {
      const dashboardTottalCount = {
        ...taskListCounts,
        dashboardTottalCount: isNaN(taskListCounts.dashboardTottalCount)
          ? 0
          : taskListCounts?.dashboardTottalCount - sucessCount,
      };
      dispatch(
        getTaskCounts(isAdmin, userId, filter, search, dashboardTottalCount)
      );
    },
    [dispatch, isAdmin, userId, filter, search, taskListCounts]
  );

  const handleApprove = async (comment = "") => {
    const { id, isWorkFlow, currentTaskId } = taskInfo;
    const approveSucces = await dispatch(
      approveTasks(isWorkFlow ? currentTaskId : id, comment)
    );
    notify({
      severity:
        approveSucces === 204 || approveSucces === 200 ? "success" : "error",
      message: (
        <Trans
          i18nKey={
            approveSucces === 204 || approveSucces === 200
              ? "alert-approve-success"
              : "alert-approve-fail"
          }
          values={{
            taskId: id,
          }}
          components={[
            <IdSpanContainer
              onClick={() => {
                naviagte(
                  `/access/tasks/details?taskId=${id}&optiontype=${optionType}&isadmin=${isAdmin}&filter=${filter}`
                );
              }}
              sx={{ cursor: "pointer" }}
            />,
          ]}
        />
      ),
    });
    if (approveSucces === 204 || approveSucces === 200) {
      refreshData();
    }
  };

  const handleReject = async (comment) => {
    const { id, isWorkFlow, currentTaskId } = taskInfo;
    const rejectStatus = await dispatch(
      rejectTasks(isWorkFlow ? currentTaskId : id, comment)
    );
    notify({
      severity:
        rejectStatus === 204 || rejectStatus === 200 ? "success" : "error",
      message: (
        <Trans
          i18nKey={
            rejectStatus === 204 || rejectStatus === 200
              ? "alert-reject-success"
              : "alert-reject-fail"
          }
          values={{
            taskId: id,
          }}
          components={[
            <IdSpanContainer
              onClick={() => {
                naviagte(
                  `/access/tasks/details?taskId=${id}&optiontype=${optionType}&isadmin=${isAdmin}&filter=${filter}`
                );
              }}
              sx={{ cursor: "pointer" }}
            />,
          ]}
        />
      ),
    });
    if (rejectStatus === 204 || rejectStatus === 200) {
      refreshData();
    }
  };

  const handleShow = (isAproove) => {
    setShow(true);
    setIsAproove(isAproove);
  };

  const handleClose = () => setShow(false);
  const customLocalText = {
    noRowsLabel: translate("no_data_found"),
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }) =>
        `${from} - ${to} ${translate("of")} ${count}`,
    },
  };
  return (
    <TaskPageTableContainer
      item
      iscollapsable={isCollapsable}
      isdetailsopen={isDetailsOpen}
      width={isDetailsOpen ? "15%" : "100%"}
      svgcolor={rowSelectionModel.length ? "white" : "balck"}
      svgopacity={rowSelectionModel.length ? "1" : "0.26"}
      isdesktop={isDesktop}
      ispending={filter === "PENDING" || filter === "All"}
      xs={isDesktop ? (isCollapsable ? 11 : 9) : 12}
      sm={isDesktop ? (isCollapsable ? 11 : 9) : 12}
      md={isDesktop ? (isCollapsable ? 11 : 9) : 12}
      lg={isDesktop ? (isCollapsable ? 11 : 9) : 12}
    >
      {!isDetailsOpen && (filter === "PENDING" || filter === "All") && (
        <>
          <div className="select-all-label">
            <Typography variant="caption">
              {translate("select-all-task")}
            </Typography>
          </div>
          <TaskActionButtons
            isPrimary={rowSelectionModel.length}
            translate={translate}
            selectedRows={selectedRows}
            handleRemove={handleRemove}
            refreshData={refreshData}
          />
        </>
      )}

      {isDetailsOpen && !isDesktop && (
        <TaskDetailsAction>
          <TaskDetailsIconButton
            title="go to previous task"
            disabled={selectedIndex === 0}
            onClick={() => {
              const index = rows?.findIndex(
                (t) => t?.id === data[selectedIndex]?.id
              );
              if (index === 0) {
                setPaginationModel((prevPageInfo) => ({
                  ...prevPageInfo,
                  page: prevPageInfo.page - 1,
                }));
              }
              setSelectedIndex(selectedIndex - 1);
              setTaskInfo(data[selectedIndex - 1]);
            }}
          >
            <NavigateBeforeIcon fontSize="small" />
          </TaskDetailsIconButton>

          <TaskDetailsIconButton
            title="go to next task"
            disabled={selectedIndex === rowCount - 1}
            onClick={() => {
              const { page, pageSize } = paginationModel;
              const index = rows?.findIndex(
                (t) => t?.id === data[selectedIndex]?.id
              );
              if (index === pageSize - 1) {
                setPaginationModel((prevPageInfo) => ({
                  ...prevPageInfo,
                  page: prevPageInfo.page + 1,
                }));
                handlePagination({
                  page: page + 1,
                  pageSize: pageSize,
                });
              }
              setSelectedIndex(selectedIndex + 1);
              setTaskInfo(data[selectedIndex + 1]);
            }}
          >
            <NavigateNextIcon fontSize="small" />
          </TaskDetailsIconButton>
        </TaskDetailsAction>
      )}

      <StickyDataGrid
        isdesktop={isDesktop}
        apiRef={apiRef}
        className={
          !isDetailsOpen ? "DataGridSingle-table" : "DataGridSingle-detail"
        }
        {...data}
        // apiRef={apiRef}
        onRowClick={handleEvent}
        rows={rows}
        columns={
          isDesktop && !isMobile && !isTablet
            ? isDetailsOpen
              ? TaskMenuColumns(optionType, filter, isAdmin)
              : TaskTableColumns(t, optionType, filter, isAdmin)
            : TableColumnsMob()
        }
        rowHeight={isDesktop ? 42 : 110}
        disableRowSelectionOnClick
        sortingMode="server"
        autoHeight
        pagination
        checkboxSelection={
          !isDetailsOpen && (filter === "PENDING" || filter === "All")
            ? true
            : false
        }
        isRowSelectable={(params) => params.row.state === "PENDING"}
        paginationModel={paginationModel}
        pageSizeOptions={[12, 15, 20]}
        rowCount={rowCount}
        paginationMode="server"
        checkboxSelectionVisibleOnly={true}
        onPaginationModelChange={handlePagination}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          let selectedIds = new Set(newRowSelectionModel);
          setRowSelectionModel(newRowSelectionModel);
          setSelectedRows(
            data?.filter((row) => selectedIds.has(row.id.toString()))
          );
        }}
        rowSelectionModel={rowSelectionModel}
        loading={loading}
        slotProps={{
          pagination: { labelRowsPerPage: `${translate("task-per-page")}:` },
        }}
        getRowClassName={(params) =>
          params.id === taskInfo?.id ? "MuiDataGrid-row-selected" : ""
        }
        currentRow={currrentPageRows}
        keepNonExistentRowsSelected
        localeText={customLocalText}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "rgba(0, 120, 214, 0.16)",
          },
          "& .MuiDataGrid-row.Mui-selected,.MuiDataGrid-row.Mui-selected:hover":
            {
              backgroundColor: "rgba(0, 120, 214, 0.16)",
            },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-columnHeader--sortable": {
            display: "none",
          },
        }}
      />
      {isDetailsOpen ? (
        <TaskFlexContainer>
          <TaskDetailedContainer
            iscollapsable={isCollapsable}
            windowsize={windowSize}
            minheight={
              isDesktop && paginationModel.pageSize === 20
                ? "900px"
                : isDesktop && paginationModel.pageSize === 15
                ? "700px"
                : "500px"
            }
          >
            <TaskDetails
              windowSize={windowSize}
              taskInfo={taskInfo}
              closeActionHandler={() => closeDeatils()}
              handleApprove={() => handleShow(true)}
              handleReject={() => handleShow(false)}
              optionType={optionType}
              filter={filter}
              isAdmin={isAdmin}
              key={taskInfo?.id}
            />
          </TaskDetailedContainer>
        </TaskFlexContainer>
      ) : null}
      <SkeletonOverlay isOpen={loadingApi}>
        <TaskPageSkeleton
          showAdminSwitch={true}
          chipNumber={[5]}
          dataGrid={true}
          taskpageHeader={true}
          columnCount={isDesktop ? 4 : 1}
          windowSize={windowSize}
        />
      </SkeletonOverlay>
      {taskInfo && (
        <ApproveRejectBox
          open={show}
          handleClose={handleClose}
          t={translate}
          SelectedTaskData={[taskInfo]}
          approve={isAproove}
          handleReject={handleReject}
          handleApprove={handleApprove}
        />
      )}
    </TaskPageTableContainer>
  );
};

export default TaskDetailsTable;
