/* eslint-disable camelcase */

import Active from './Active.svg?react'
import APIDown from './APIDown.svg?react'
import ArrowCircleDown from './arrow-circle-down.svg?react'
import ArrowCircleLeft from './arrow-circle-left.svg?react'
import ArrowCircleRight from './arrow-circle-right.svg?react'
import ArrowCircleUp from './arrow-circle-up.svg?react'
import AssignUserOrg from './ASSIGN_USER_ORG.svg?react'
import Bulk from './Bulk.svg?react'

import BusinessPartner from './BusinessPartner.svg?react'
import CatSitting from './cat-sitting.svg?react'
import CheckCircle from './check-circle-1.svg?react'
import ConnectDeviceCancel from './connect-device-cancel.svg?react'
import CorporateCustomer from './CorporateCustomer.svg?react'

import CreditCardPhoneCall from './credit-card-phone-call.svg?react'
import CurrentWorkplace from './CurrentWorkplace.svg?react'
import CustomScope from './CustomScope.svg?react'
import Daimler from './Daimler.svg?react'
import DaimlerInternal from './DaimlerInternal.svg?react'
import Dealer from './Dealer.svg?react'
import DealerOrg from './DealerOrg.svg?react'
import DRDtoGEMS from './DRDtoGEMS.svg?react'
import EarthBlock from './earth-block.svg?react'
import EditMb from './Edit_MB.svg?react'
import GEMStoDRD from './GEMStoDRD.svg?react'
import Group from './Group.svg?react'
import CustomerSupportQuestion from './headphones-customer-support-question.svg?react'
import CustomerSupport from './headphones-customer-support.svg?react'
import HomeOrg from './HomeOrg.svg?react'
import ImageFile from './image-file-landscape.svg?react'
import Inactive from './Inactive.svg?react'
import InfoBubble from './information-bubble.svg?react'
import InfoCircle from './information-circle.svg?react'
import InitialSearch from './initialSearch.svg?react'
import IrisScanApproved from './iris-scan-approved-1.svg?react'
import JointVenture from './JointVenture.svg?react'
import Keys from './Keys.svg?react'
import LayoutModule from './layout-module-1.svg?react'
import Legalentity from './Legalentity.svg?react'

import ListAdd from './list-add.svg?react'
import ListBullets from './list-bullets.svg?react'
import ListRemove from './list-remove.svg?react'
import Mainlocation from './Mainlocation.svg?react'
import Megaphone from './megaphone.svg?react'
import MessagesPeopleUserCheck from './messages-people-user-check.svg?react'
import MobilePhone from './mobile-phone.svg?react'
import HorizontalMenu from './navigation-menu-horizontal.svg?react'
import NetworkUser from './network-user.svg?react'
import NoResult from './noResultsIcon.svg?react'
import OrgAdmin from './ORG_ADMIN.svg?react'

import OrgBusinessPartner from './OrgBusinessPartner.svg?react'
import OrgCorpCust from './OrgCorpCust.svg?react'
import OrgDaimlerExternal from './OrgDaimlerExternal.svg?react'
import OrgDaimlerInternal from './OrgDaimlerInternal.svg?react'
import OrgDaimlerJointVenture from './OrgDaimlerJointVenture.svg?react'
import OrgDealer from './OrgDealer.svg?react'
import OrgLegalEntity from './OrgLegalEntity.svg?react'
import OrgPoolUser from './OrgPoolUser.svg?react'
import OrgSubLocation from './OrgSubLocation.svg?react'
import OrgSupplier from './OrgSupplier.svg?react'
import OrgTechUser from './OrgTechUser.svg?react'
import OrgTestUser from './OrgTestUser.svg?react'
import OrgUserSearchCorporateCustomerOrganization from './OrgUserSearchCorporateCustomerOrganization.svg?react'
import OrgUserSearchDaimlerCompany from './OrgUserSearchDaimlerCompany.svg?react'

import OrgUserSearchDealer from './OrgUserSearchDealer.svg?react'
import OrgUserSearchDealerGroup from './OrgUserSearchDealerGroup.svg?react'
import OrgUserSearchEmployee from './OrgUserSearchEmployee.svg?react'
import OrgUserSearchExternal from './OrgUserSearchExternal.svg?react'
import OrgUserSearchInternalCompanies from './OrgUserSearchInternalCompanies.svg?react'
import OrgUserSearchISP from './OrgUserSearchISP.svg?react'
import OrgUserSearchMarketArea from './OrgUserSearchMarketArea.svg?react'
import OrgUserSearchMPCAndGD from './OrgUserSearchMPCAndGD.svg?react'
import OrgUserSearchPartnerOrganization from './OrgUserSearchPartnerOrganization.svg?react'
import OrgUserSearchSupplier from './OrgUserSearchSupplier.svg?react'
import OrgUserSearchSupplierOrganization from './OrgUserSearchSupplierOrganization.svg?react'
import Pencil from './pencil.svg?react'
import Phone from './phone.svg?react'
import PoolUser from './PoolUser.svg?react'
import ActionBuildingAdd from './real-estate-action-building-add.svg?react'
import ActionBuildingCheck from './real-estate-action-building-check.svg?react'
import ActionBuildingWarning from './real-estate-action-building-warning.svg?react'
import RequestOverview from './RequestOverview.svg?react'
import RequestReason from './RequestReason.svg?react'
import RoleDepro from './ROLE_DEPRO.svg?react'
import RoleEntitlement from './ROLE_ENTITLEMENT.svg?react'
import RoleRemove from './ROLE_REMOVE.svg?react'
import RoleReq from './ROLE_REQ.svg?react'
import Search from './search.svg?react'
import ShareExternalLink from './share-external-link-1.svg?react'
import NeutralActionsCheck from './single-neutral-actions-check-1.svg?react'
import NeutralActionsWarning from './single-neutral-actions-warning.svg?react'
import StepperCheckmark from './StepperCheckmark.svg?react'
import Sublocation from './Sublocation.svg?react'
import Supplier from './Supplier.svg?react'
import TaskListPen from './task-list-pen.svg?react'
import TaskListText from './task-list-text-1.svg?react'
import Tech_User from './TECH_USER.svg?react'
import OrgUserSearchTechUser from './technicalUser.svg?react'
import Techuser from './Techuser.svg?react'
import TestUser from './TestUser.svg?react'
import Translate from './translate.svg?react'

import UIApps from './UIApps.svg?react'
import UIDate_From from './UIDate_From.svg?react'
import UIGroup from './UIGroup.svg?react'
import UIHome from './UIHome.svg?react'
import UIInfo from './UIInfo.svg?react'
import UIMarkets from './UIMarkets.svg?react'
import UINewReq from './UINewReq.svg?react'
import UIOrg from './UIOrg.svg?react'
import UIProfile from './UIProfile.svg?react'
import UIReapply from './UIReapply.svg?react'
import UIReports from './UIReports.svg?react'
import UIRoles from './UIRoles.svg?react'
import UISummary from './UISummary.svg?react'
import UITasks from './UITasks.svg?react'
import UIUsers from './UIUsers.svg?react'
import UIWorkflow from './UIWorkflow.svg?react'
import UserCreate from './USER_CREATE.svg?react'
import UserReviewMarket from './USER_REVIEW_MARKET.svg?react'
import UserReviewOrg from './USER_REVIEW_ORG.svg?react'
import UserOutline from './UserOutline.svg?react'
import Validity from './Validity.svg?react'
import World from './World.svg?react'
import OrgScope from './OrganisationScopeNew.svg?react'

import NewRequest from './new_req.svg?react'
import NewRequestFilled from './new_req_filled.svg?react'
import Role from './Role.svg?react'
import ChildRole from './ChildRole.svg?react'

export {
  CatSitting,
  ConnectDeviceCancel,
  CustomerSupportQuestion,
  EarthBlock,
  AssignUserOrg,
  OrgAdmin,
  RoleDepro,
  RoleEntitlement,
  RoleReq,
  UserCreate,
  UserReviewMarket,
  UserReviewOrg,
  UserOutline,
  InfoCircle,
  InfoBubble,
  IrisScanApproved,
  Megaphone,
  MobilePhone,
  Phone,
  TaskListPen,
  CustomerSupport,
  Search,
  CheckCircle,
  APIDown,
  NoResult,
  InitialSearch,
  HomeOrg,
  DealerOrg,
  CurrentWorkplace,
  LayoutModule,
  ListBullets,
  EditMb,
  Active,
  Inactive,
  BusinessPartner,
  CorporateCustomer,
  Daimler,
  DaimlerInternal,
  Dealer,
  Group,
  JointVenture,
  Legalentity,
  Mainlocation,
  PoolUser,
  Sublocation,
  Supplier,
  Techuser,
  Tech_User,
  TestUser,
  World,
  Keys,
  Validity,
  RequestReason,
  RequestOverview,
  CustomScope,
  OrgBusinessPartner,
  OrgCorpCust,
  OrgDaimlerExternal,
  OrgDaimlerInternal,
  OrgDaimlerJointVenture,
  OrgDealer,
  OrgLegalEntity,
  OrgPoolUser,
  OrgSubLocation,
  OrgSupplier,
  OrgTechUser,
  OrgTestUser,
  UIApps,
  UIDate_From,
  UIGroup,
  UIHome,
  UIInfo,
  UIMarkets,
  UINewReq,
  UIOrg,
  UIProfile,
  UIReports,
  UIRoles,
  UITasks,
  UIUsers,
  UIWorkflow,
  UIReapply,
  UISummary,
  StepperCheckmark,
  DRDtoGEMS,
  GEMStoDRD,
  ListAdd,
  ListRemove,
  Bulk,
  ShareExternalLink,
  HorizontalMenu,
  Pencil,
  ActionBuildingAdd,
  ActionBuildingCheck,
  ActionBuildingWarning,
  NeutralActionsWarning,
  OrgUserSearchDealer,
  OrgUserSearchEmployee,
  OrgUserSearchExternal,
  OrgUserSearchISP,
  OrgUserSearchCorporateCustomerOrganization,
  OrgUserSearchInternalCompanies,
  OrgUserSearchSupplier,
  OrgUserSearchMPCAndGD,
  OrgUserSearchDealerGroup,
  OrgUserSearchDaimlerCompany,
  OrgUserSearchMarketArea,
  OrgUserSearchPartnerOrganization,
  OrgUserSearchSupplierOrganization,
  OrgUserSearchTechUser,
  NeutralActionsCheck,
  CreditCardPhoneCall,
  ArrowCircleDown,
  ArrowCircleRight,
  ArrowCircleLeft,
  ArrowCircleUp,
  MessagesPeopleUserCheck,
  NetworkUser,
  TaskListText,
  Translate,
  ImageFile,
  RoleRemove,
  OrgScope,
  NewRequest,
  NewRequestFilled,
  Role,
  ChildRole
}
