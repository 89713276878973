import { memo, useState } from "react";
import { InputControl } from "@utils/inputControls";
import { Select, FormControl } from "@mui/material";
import ArrowIconSelect from "@Icons/ArowIconSelect.svg";
import {
  FilterHeadTypography,
  StyledMenuItem,
  FilterSelectContainer,
} from "../../styledComponents";

const Sort = memo(({ translate, windowSize, sortHandler, sortedValue }) => {
  const { isMobile, isTablet, isDesktop } = windowSize;

  const [sortValue, setSortValue] = useState(
    sortedValue !== undefined ? sortedValue : "1"
  );
  const handleChange = (e) => {
    setSortValue(e.target.value);
    sortHandler(e.target.value);
  };

  const sortOptions = [
    { value: "1", label: translate("creation-date-new-to-old") },
    { value: "2", label: translate("creation-date-old-to-new") },
  ];
  return (
    <>
      {isDesktop && (
        <>
          <FilterHeadTypography variant="caption" display="block">
            {translate("sort-by")}
          </FilterHeadTypography>
          <FilterSelectContainer>
            <FormControl variant="standard">
              <Select
                labelId=""
                IconComponent={ArrowIconSelect}
                id="sort-select"
                onChange={handleChange}
                defaultValue="1"
                value={sortValue}
              >
                <StyledMenuItem value="1">
                  {translate("creation-date-new-to-old")}
                </StyledMenuItem>
                <StyledMenuItem value="2">
                  {translate("creation-date-old-to-new")}
                </StyledMenuItem>
              </Select>
            </FormControl>
          </FilterSelectContainer>
        </>
      )}
      {(isTablet || isMobile) && (
        <InputControl
          type="select"
          name="sort-by"
          labelName={translate("sort-by")}
          options={sortOptions}
          value={sortValue}
          onChange={handleChange}
        />
      )}
    </>
  );
});

export default Sort;
