import { CheckCircleOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { ListEntry } from './ListEntry'
import { Chip } from '../chip'
import { IconWrapper } from '../icon/iconWrapper/IconWrapper'

export const TasksLink = ({ size, totalCount }) => (
  <ListEntry
    data-testid="TasksLink"
    name="tasks"
    path="/access/tasks?status=pending"
    chip={(
      <Chip
        sx={{
          height: '16px',
          '.MuiChip-label': { paddingLeft: 1, paddingRight: 1 }
        }}
        label={(
          <Typography
            sx={{
              fontWeight: 700,
              lineHeight: '1rem',
              fontSize: '0.75rem'
            }}
          >
            {totalCount}
          </Typography>
        )}
      />
    )}
    icon={() => (
      <IconWrapper
        color={'inherit'}
        size={size === 'small' ? 24 : 18}
      >
        <CheckCircleOutlined />
      </IconWrapper>
    )}
    size={size}
  />
)
