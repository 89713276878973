import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { AssignmentDialog } from '../dialog/AssignmentDialog'
import { CommunityScope } from '../scope/CommunityScope'

export const GlobalCommunityAdministratorAssignmentDialog = ({
  userId,
  administratedCommunities = [],
  isOpen,
  onAssigned,
  onClose
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ selectedCommunities, setSelectedCommunites ] = useState([])

  const stepperConfig = [
    {
      title: t('adminRoles.stepper.communities'),
      content: selectedCommunities.length ? `${selectedCommunities.length} ${t('general.selected')}` : ''
    }
  ]

  const {
    isLoading: isLoadingAssign,
    execute: executeAssign
  } = useApi(
    UsersService.updatePrivilege,
    {
      config: {
        id: userId,
        privilege: 'globalcommunityadmin',
        body: { globalCommunityAdminPrivileges: selectedCommunities.map((community) => ({ community })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.save', { adminRole: t('adminRoles.globalCommunityAdmin') }),
      onRequestSuccess: (response) => onAssigned({ globalCommunityAdmin: response })
    }
  )

  const handleAssign = () => executeAssign()
  const handleIsAssignDisabled = () => !(selectedCommunities && selectedCommunities.length > 0)

  const content = [
    {
      title: t('adminRoles.select.communities'),
      render: (
        <CommunityScope
          administratedCommunities={administratedCommunities}
          selectedCommunity={selectedCommunities}
          setSelectedCommunity={setSelectedCommunites}
        />
      )
    }
  ]

  return (
    <AssignmentDialog
      data-testid="GlobalCommunityAdministratorAssignmentDialog"
      isOpen={isOpen}
      isLoading={isLoadingAssign}
      title={t('adminRoles.assign', {
        adminRole: t('adminRoles.globalCommunityAdmin'),
        userId
      })}
      content={content}
      stepperConfig={stepperConfig}
      disableAssign={handleIsAssignDisabled}
      onClose={onClose}
      onCancel={onClose}
      onAssign={handleAssign}
    />
  )
}

GlobalCommunityAdministratorAssignmentDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  administratedCommunities: PropTypes.array,
  isOpen: PropTypes.bool,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func
}
