import React, { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  Interaction,
  Accordion,
  AccordionSummary,
} from "@alice/component_library";
import { CustomOrgGenericSeacrh } from "@components/CustOrgSearch";
import { OrgDialogueCard } from "@components/OrgDialogue/OrgDilogueCard";

export const CustOrgSearchDialogue = ({
  title,
  isOpen,
  preselectedOrg = [],
  otherOrgProps = {},
  isSlectedListBarVisible,
  onSave = ({ orgIds, orgs }) => {},
  onCancel = () => {},
  isLoading = false,
  isSaveDisabled = false,
  data = [
    { id: 1, name: "jithin", detail: "Description for checking description" },
    { id: 2, name: "jithin01", detail: "Description for checking description" },
  ],
}) => {
  const { t } = useTranslation();
  const custorgSearchRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [originalSelectedOrg, setOriginalSelectedOrg] =
    useState(preselectedOrg);
  const [selectedOrg, setSelectedOrg] = useState(preselectedOrg);
  const [isExpanded, setIsExpanded] = useState(false);
  const toOrgIds = (orgs) => orgs.map((org) => org.id);

  const handleToggle = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    setOriginalSelectedOrg(preselectedOrg);
    setSelectedOrg(preselectedOrg);
  }, [preselectedOrg]);

  const handleClose = () => {
    setSelectedOrg(originalSelectedOrg);
    onCancel();
  };

  const handleSave = () => {
    const orgs = selectedOrg || [];
    setOriginalSelectedOrg(orgs);
    onSave({
      orgIds: orgs.map((org) => org.id),
      orgs,
    });
    handleClose();
  };

  const handleCardClick = (orgId, isSelected, orgObject) => {
    const newSelectedOrg = isSelected
      ? [...selectedOrg, orgObject]
      : selectedOrg.filter((org) => org.id !== orgId);

    setSelectedOrg(newSelectedOrg);
  };

  const handleDeleteClick = (orgObject) => {
    const newSelectedOrg = selectedOrg.filter((org) => org.id !== orgObject.id);
    custorgSearchRef.current.setSelectedData(toOrgIds(newSelectedOrg));
    setSelectedOrg(newSelectedOrg);
  };

  const onSelectAll = (selectedIds) => {
    const selectedOrgData = data.filter((cust) =>
      selectedIds.includes(cust.id)
    );

    setSelectedOrg(selectedOrgData);
  };

  return (
    <Dialog
      data-testid="OrgpickerDialog"
      open={isOpen}
      fullWidth
      maxWidth="lg"
      headerSection={title || t("add-custom-scope")}
      footerSection={
        <Stack direction="row" gap={2}>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            isLoading={isLoading}
            disabled={selectedOrg.length ? false : true}
            onClick={handleSave}
          >
            {t("add-custom-scope")}
          </Button>
        </Stack>
      }
      onClose={handleClose}
    >
      <Stack data-testid="OrgPicker" gap={5}>
        <Accordion
          aria-controls="orgPicker-Content"
          id="orgPicker-Header"
          expanded={isExpanded}
          sx={[
            {
              borderRadius: "4px",
              border: "1px solid",
              borderColor: "divider",
              cursor: "unset",
              boxShadow: "unset",
              transition: "background-color 250ms",
              backgroundColor: "common.white",
            },
          ]}
          header={
            <AccordionSummary onClick={handleToggle}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack direction="row" gap={1} justifyContent="center">
                  <Typography variant="h5">
                    {t("selected-custom-scope")}
                  </Typography>

                  <Chip
                    label={selectedOrg?.length || 0}
                    color="primary"
                    size="small"
                    sx={{ fontSize: "12px", fontWeight: "700" }}
                  />
                </Stack>

                <Stack
                  sx={{
                    flexFlow: "row",
                    gap: 1,
                    color: isExpanded ? "primary.main" : "text.primary",
                  }}
                >
                  {!isMobile && (
                    <Typography
                      variant="button"
                      sx={{ transition: "color 250ms" }}
                    >
                      {isExpanded
                        ? t("hide-selected-custom-scope")
                        : t("show-selected-custom-scope")}
                    </Typography>
                  )}

                  <ArrowDropDownIcon
                    sx={[
                      { transition: "transform 250ms" },
                      isExpanded && { transform: "rotate(180deg)" },
                    ]}
                  />
                </Stack>
              </Stack>
            </AccordionSummary>
          }
        >
          {selectedOrg?.length ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                },
                gap: 1,
              }}
            >
              {selectedOrg.map((Org) => (
                <OrgDialogueCard
                  orgName={Org?.name}
                  actionHandler={() => handleDeleteClick(Org)}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100px",
              }}
            >
              <Typography
                variant="body2"
                textAlign="center"
                color="text.secondary"
                sx={{ whiteSpace: "pre-line" }}
              >
                {t("no-org-custom-accordian")}
              </Typography>
            </Box>
          )}
        </Accordion>
        <CustomOrgGenericSeacrh
          ref={custorgSearchRef}
          data={data}
          views={["grid", "list"]}
          interaction={Interaction.MULTI_SELECT}
          preselectedRowOrgIds={toOrgIds(preselectedOrg)}
          onCardClick={handleCardClick}
          onSelectAll={(data) => onSelectAll(data)}
        />
      </Stack>
    </Dialog>
  );
};
