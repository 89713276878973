import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { selectedtabIndex } from "./actions";
import DeactiveImage from "./pages/icons/deActiveImage";
import "./App.css";

const CustomButton = styled(Button)({
  borderRadius: "0px !important",
  textTransform: "none !important",
  fontSize: "14px !important",
  backgroundColor: "#222222 !important",
  fontWeight: "400",
});

const CustomTypoGraphy = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: "400",
});

const DeActivation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileRedirect = useCallback(async () => {
    await dispatch(selectedtabIndex(5));
    await navigate("/access/profile");
  }, [dispatch, navigate]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="text-align-center display-block mt-70"
        >
          <Grid className="mh-95vh">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="request-responsive"
            >
              <p className="font-36">
                <span className="font-weight-400">{t("daimler")} </span>{" "}
                <span className="font-700"> {t("alice")} </span>
              </p>
              <DeactiveImage />
              <br />
              <h3 className="deact-bolder">{t("deactivation-title")}</h3>

              <br />
              <CustomTypoGraphy>{t("deactivation-content1")}</CustomTypoGraphy>
              <CustomTypoGraphy>{t("deactivation-content2")}</CustomTypoGraphy>

              <br />

              <div>
                <CustomButton
                  variant="contained"
                  id="newRequest"
                  onClick={() => profileRedirect()}
                  color="primary"
                >
                  {t("my_profile")}
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DeActivation;
