import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpRolesEmptyIcon from "./Icons/expiringRoles.svg";
import { AliceIcon, Button, Chip } from "@alice/component_library";

import { hasData } from "@utils/Validator";
import RCardV3s from "@components/RoleCardV3Small/RCardV3s";

import {
  ExpiringRolesContainer,
  ExpiringRolesDetails,
  ExpiringRolesHeader,
  ExpiringRolesProfileTypo,
  ExpiringRolesTypo,
  RoleDescriptionContainer,
  RoleDescriptionTypo,
  RCardV3sContainer,
  ExpRolesChipContainer,
  ExpRolesFooterTypo,
  ExpRolesEmptyTypo,
  ExpRolesFooterContainer,
  ExpRolesEmptyContaniner,
} from "../../styledComponents";
import { ExpiringRolesHome } from "@components/Skeleton/CommonSkeletons";
import ReloadButton from "@components/reloadButton";

const ExpiringRoleCard = ({
  roleData,
  handleExtendOrReaaply,
  globalRoleIds,
  fetchData,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const {
    tottalCount = 0,
    expiredRoleCount = 0,
    expiringRolesCount = 0,
    rolesDetail = [],
    loading,
  } = roleData;
  const remainingCount = tottalCount - rolesDetail.length;
  const expCount = remainingCount;
  const shouldShowButton = (data) => {
    const hasRole =
      globalRoleIds?.includes(data?.roleDefinition?.id) ||
      data?.roleDefinition?.isSelfRequestable;
    return hasRole;
  };
  return (
    <>
      <ExpiringRolesContainer>
        <ExpiringRolesDetails>
          <Box>
            <ExpiringRolesHeader>
              <AliceIcon iconType="UIProfile" size={16} />
              <ExpiringRolesProfileTypo>
                {t("profile")}
              </ExpiringRolesProfileTypo>
              <ReloadButton
                loading={loading}
                handleIconButtonClick={fetchData}
              />
            </ExpiringRolesHeader>
            <ExpiringRolesTypo>{t("expiring-roles")}</ExpiringRolesTypo>
          </Box>
          <RoleDescriptionContainer>
            {rolesDetail.length > 0 && (
              <RoleDescriptionTypo>
                <Trans
                  i18nKey="expiring-roles-description"
                  values={{
                    descriptionTwo: `<strong>${t("next-two-weeks")}</strong>`,
                  }}
                />
              </RoleDescriptionTypo>
            )}
            {rolesDetail.length <= 0 ? null : (
              <ExpRolesChipContainer>
                <div className="chip-container">
                  {isDownMd && (
                    <Typography variant="body2">{`${t(
                      "about-to-expire"
                    )}:`}</Typography>
                  )}
                  <Chip
                    label={expiringRolesCount}
                    state="warning"
                    variant="filled"
                    sx={{
                      height: "1.5rem",
                      fontWeight: "700",
                      "&.MuiChip-root:hover": {
                        color: "#000",
                      },
                    }}
                  />
                </div>
                <div className="chip-container">
                  {isDownMd && (
                    <Typography variant="body2">{`${t(
                      "expired"
                    )}:`}</Typography>
                  )}
                  <Chip
                    label={expiredRoleCount}
                    state="error"
                    variant="filled"
                    sx={{
                      height: "1.5rem",
                      fontWeight: "700",
                      "&.MuiChip-root:hover": {
                        color: "#000",
                      },
                    }}
                  />
                </div>
              </ExpRolesChipContainer>
            )}
          </RoleDescriptionContainer>
        </ExpiringRolesDetails>
        <RCardV3sContainer>
          {loading ? (
            <>
              <ExpiringRolesHome isDownMd={isDownMd} />
            </>
          ) : (
            <>
              {rolesDetail.length > 0 ? (
                <>
                  {!isDownMd &&
                    rolesDetail.map((data, index) => (
                      <RCardV3s
                        key={index}
                        data={data}
                        handleExtendOrReapply={`${handleExtendOrReaaply}&role=${
                          data?.roleDefinition?.id
                        }${
                          hasData(data.orgScope)
                            ? `&orgScope=${data.orgScope.id}`
                            : ""
                        }${
                          hasData(data.customScope)
                            ? `&custScope=${data.customScope.name}`
                            : ""
                        }${
                          data.hasOwnProperty("validFrom")
                            ? `&validFrom=${encodeURI(data.validFrom)}`
                            : ""
                        }`}
                        ExtendOrReapplyButton={shouldShowButton(data)}
                      />
                    ))}
                </>
              ) : (
                <ExpRolesEmptyContaniner>
                  <ExpRolesEmptyIcon />
                  <ExpRolesEmptyTypo>{t("no-exp-roles")}</ExpRolesEmptyTypo>
                </ExpRolesEmptyContaniner>
              )}
            </>
          )}
        </RCardV3sContainer>
        {rolesDetail.length <= 0 ? null : (
          <ExpRolesFooterContainer>
            {((isDownMd && tottalCount > 0) || expCount > 0) && (
              <ExpRolesFooterTypo>
                {t("exp-role-count", {
                  count: isDownMd ? tottalCount : expCount,
                })}
              </ExpRolesFooterTypo>
            )}

            <Link
              to={{
                pathname: "/access/profile",
                search: "?category=myRoles",
              }}
            >
              <Button
                children={
                  <>
                    {t("go-to-exp-roles")}
                    <ChevronRightIcon />
                  </>
                }
                variant="Primary"
              />
            </Link>
          </ExpRolesFooterContainer>
        )}
      </ExpiringRolesContainer>
    </>
  );
};

export default ExpiringRoleCard;
