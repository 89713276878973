/***
 * @description - Helper class to validate the values.
 * @author: Rijad Rahim
 */

export const isExist = (val, def = "-") =>
  val !== undefined && val !== null && val !== "" ? val : def;

export const isValid = (val) => val !== undefined && val !== null && val !== "";

export const hasData = (val) =>
  isValid(val) &&
  ((Array.isArray(val) && val.length > 0) ||
    (typeof val === "object" && Object.keys(val).length > 0));
