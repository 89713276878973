import { FormControlLabel, Stack, Switch as MuiSwitch, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  '--track-padding': '3px',
  '--track-width': '38px',
  '--track-height': '18px',
  '--thumb-size': '18px',

  width: 'calc(var(--track-width) + (var(--track-padding) * 2))',
  height: 'calc(var(--track-height) + (var(--track-padding) * 2))',
  padding: 0,
  display: 'flex',
  '.MuiSwitch-thumb': {
    width: 'var(--thumb-size)',
    height: 'var(--thumb-size)',
    boxShadow: 'unset',
    borderRadius: 9,
    transition: theme.transitions.create([ 'width', 'transform' ], { duration: 200 })
  },
  '.MuiSwitch-track': {
    borderRadius: 16,
    opacity: '1 !important', // Otherwise MUI overrides on disabled state
    backgroundColor: theme.palette.action.disabled,
    boxSizing: 'border-box',
    padding: 'var(--track-padding)'
  },
  '.MuiSwitch-switchBase': {
    padding: 'var(--track-padding)',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: 'white',
      '+ .MuiSwitch-track': { backgroundColor: 'primary.main' }
    }
  },
  '.Mui-disabled': {
    '.MuiSwitch-thumb': { opacity: '0.7 !important' },
    '+ .MuiSwitch-track': { opacity: '0.5 !important' }
  }
}))

/**
 * Switch-Component is a wrapper around MUI-Switch with specific styles, label and helper text.
 *
 * It supports both controlled and uncontrolled mode.
 *
 * **Controlled Mode:** Set the `checked` prop to manage the Switch state externally.
 * Example: `<Switch label="Controlled Switch" checked={true} />`
 *
 * **Uncontrolled Mode:** Omit the `checked` prop, and the Switch maintains its internal state.
 * Example: `<Switch label="Uncontrolled Switch" />`
 *
 * Read https://mui.com/material-ui/react-switch/ for further documentation
 */
export const Switch = forwardRef((
  {
    label,
    labelPlacement = 'end',
    helperText,
    sx = [],
    ...otherProps
  },
  ref
) => (
  <FormControlLabel
    data-testid="Switch"
    ref={ref}
    sx={mergeSxProps({ alignItems: 'flex-start', margin: 0 }, sx)}
    control={(<StyledSwitch {...otherProps} />)}
    labelPlacement={labelPlacement}
    label={label ? (
      <Stack
        sx={[
          labelPlacement === 'start' && { textAlign: 'right', paddingRight: '8px' },
          labelPlacement === 'end' && { textAlign: 'left', paddingLeft: '8px' }
        ]}
      >
        <Typography variant="body1" sx={{ color: 'grey.20', letterSpacing: '0.2px' }}>
          {label}
        </Typography>

        <Typography
          sx={{
            color: 'grey.45',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.2px'
          }}
        >
          {helperText}
        </Typography>
      </Stack>
    ) : null}
  />
))

Switch.propTypes = {
  /** Label to be displayed */
  label: PropTypes.string,
  /** Label placement */
  labelPlacement: PropTypes.oneOf([ 'start', 'end' ]),
  /** Helper text to be displayed */
  helperText: PropTypes.string,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
