import { isPrimitive } from '../../../../util'

export const FIQLOperator = {
  EQUAL: '==',
  NOT_EQUAL: '!=',
  GREATER: '>',
  GREATER_OR_EQUAL: '>=',
  LESS: '<',
  LESS_OR_EQUAL: '<=',
  IN: '=in=',
  OUT: '=out='
}

export const FIQLCompositeOperator = {
  AND: ';',
  OR: ','
}

export class FilterParser {
  static toAlice({
    filters,
    compositeOperator = FIQLCompositeOperator.AND
  }) {
    if (typeof filters !== 'object') return undefined

    const buildFilters = []

    Object.entries(filters).forEach(([ key, filter ]) => {
      if (isPrimitive(filter)) buildFilters.push(`${key}==${filter}`)
      else if (Array.isArray(filter)) buildFilters.push(`${key}${FIQLOperator.IN}(${filter.join(FIQLCompositeOperator.OR)})`)
      else if (filter && typeof filter === 'object') {
        if (isPrimitive(filter.value)) buildFilters.push(`${key}${filter.operator}${filter.value}`)
        else if (Array.isArray(filter.value)) buildFilters.push(`${key}${filter.operator}(${filter.join(filter.compositeOperator)})`)
      }
    })

    return { filter: buildFilters.join(compositeOperator) }
  }
}
