import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import StatusRoleConfig from "@components/StatusRoleConfiguration";

import {
  TandCMainContainer,
  CandRHeaderTypo,
  ConditionCheckBoxs,
  CheckboxLabel,
  ConditionHeader,
  TermsandConditionContainer,
} from "@pages/newRequest/styledComponents";

const TermsAndCondition = ({ isVisible }) => {
  const { t } = useTranslation();
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isAgbsChecked, setIsAgbsChecked] = useState(false);

  const handleTermsChange = (e) => {
    setIsTermsChecked(e.target.checked);
  };

  const handleAgbsChange = (e) => {
    setIsAgbsChecked(e.target.checked);
  };

  const checkboxStatus = isTermsChecked && isAgbsChecked && "success";

  return (
    <TermsandConditionContainer>
      <TandCMainContainer>
        <ConditionHeader>
          <CandRHeaderTypo>{t("terms-and-conditions")}</CandRHeaderTypo>
          <StatusRoleConfig status={checkboxStatus} />
        </ConditionHeader>
        <ConditionCheckBoxs>
          <FormControlLabel
            onChange={handleTermsChange}
            control={<Checkbox />}
            label={
              <CheckboxLabel>
                <Trans
                  i18nKey="i-accept-the-terms-and-conditions"
                  components={{ 1: <Link /> }}
                />
              </CheckboxLabel>
            }
            sx={{ width: "fit-content" }}
          />
          <FormControlLabel
            onChange={handleAgbsChange}
            control={<Checkbox />}
            label={
              <CheckboxLabel>
                <Trans
                  i18nKey="i-accept-the-agbs"
                  components={{ 1: <Link /> }}
                />
              </CheckboxLabel>
            }
            sx={{ width: "fit-content" }}
          />
        </ConditionCheckBoxs>
      </TandCMainContainer>
    </TermsandConditionContainer>
  );
};

export default TermsAndCondition;
