import { useTranslation } from 'react-i18next'
import { UsersService } from '../../data'
import { useApi } from '../api/useApi'
import { useUser } from './UseUser'

export const useLanguage = () => {
  const { i18n } = useTranslation()
  const { user } = useUser()

  const { execute } = useApi(
    UsersService.updateById,
    {
      isRequestingInitially: false,
      isNotifyingOnError: true
    }
  )

  const handleLanguageUpdate = (language) => {
    i18n.changeLanguage(language)

    execute({
      config: {
        id: user?.id,
        user: {
          preferredLanguage: language,
          givenname: user?.givenname,
          surname: user?.surname,
          homeOrgId: user?.homeOrgId,
          workingOrgId: user?.workingOrgId,
          mailAddress: user?.mailAddress
        }
      }
    })
  }

  return { language: i18n.language, updateLanguage: handleLanguageUpdate }
}
