import React from "react";
import {
  Stack,
  ProfileHeaderWithLayoutSkeleton,
  Skeleton,
  FilterWithSorting,
  RoleCardV2GridSkeleton,
} from "./CommonSkeletons";

const UserDetailsSkeleton = () => {
  return (
    <ProfileHeaderWithLayoutSkeleton>
      <Skeleton
        variant="text"
        height={32}
        width="10%"
        sx={{ marginTop: "30px" }}
      />
      <Stack
        direction="row"
        alignItems="center"
        gap="20px"
        sx={{ marginTop: "10px" }}
      >
        <Skeleton variant="text" height={32} width="10%" />
        <Skeleton variant="text" height={32} width="10%" />
      </Stack>
      <Skeleton variant="text" height={32} sx={{ marginTop: "20px" }} />
      <Stack
        direction="row"
        alignItems="center"
        gap="20px"
        sx={{ marginTop: "10px" }}
      >
        <Skeleton variant="text" height={32} width="10%" />
        <Skeleton variant="text" height={32} width="10%" />
      </Stack>
      <FilterWithSorting filterSize={2} chipNumber={[3, 3]} />
      <RoleCardV2GridSkeleton large={false} />
    </ProfileHeaderWithLayoutSkeleton>
  );
};

export default UserDetailsSkeleton;
