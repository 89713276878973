import { HttpService } from '../HttpService'
import { ApiBaseUrl } from '../utils'

export class CommunicationsService extends HttpService {
  static toHttpServiceConfig = (config = {}) => {
    if (typeof config !== 'object') return {}

    const httpServiceConfig = {
      ...config,
      params: { ...config?.params },
      body: config?.body,
      header: { ...config?.header }
    }

    return httpServiceConfig
  }

  static get = async (url, config = {}) => super.get(HttpService.toUrl(ApiBaseUrl.COMMUNICATIONS, url), CommunicationsService.toHttpServiceConfig(config))

  static post = async (url, config = {}) => super.post(HttpService.toUrl(ApiBaseUrl.COMMUNICATIONS, url), CommunicationsService.toHttpServiceConfig(config))

  static put = async (url, config = {}) => super.put(HttpService.toUrl(ApiBaseUrl.COMMUNICATIONS, url), CommunicationsService.toHttpServiceConfig(config))

  static delete = async (url, config = {}) => super.delete(HttpService.toUrl(ApiBaseUrl.COMMUNICATIONS, url), CommunicationsService.toHttpServiceConfig(config))

  static patch = async (url, config = {}) => super.patch(HttpService.toUrl(ApiBaseUrl.COMMUNICATIONS, url), CommunicationsService.toHttpServiceConfig(config))

  static head = async (url, config = {}) => super.head(HttpService.toUrl(ApiBaseUrl.COMMUNICATIONS, url), CommunicationsService.toHttpServiceConfig(config))
}
