import { HttpService } from '../HttpService'
import { ApiBaseUrl, PaginationParser, SortParser } from '../utils'

export class NotificationsModuleService extends HttpService {
  static toHttpServiceConfig = (config = {}) => {
    if (typeof config !== 'object') return {}

    const httpServiceConfig = {
      ...config,
      params: { ...config?.params },
      header: { ...config?.header }
    }

    if (config?.page !== undefined && config?.pageSize) {
      httpServiceConfig.params = {
        ...httpServiceConfig.params,
        ...PaginationParser.toNotificationModule({
          page: config?.page,
          size: config?.pageSize
        })
      }
    }

    if (config?.sort) {
      httpServiceConfig.params = {
        ...httpServiceConfig.params,
        ...SortParser.toNotificationModule({
          sort: config?.sort,
          order: config?.sortOrder
        })
      }
    }

    return httpServiceConfig
  }

  static get = async (url, config = {}) => super.get(HttpService.toUrl(ApiBaseUrl.NOTIFICATION_MODULE, url), NotificationsModuleService.toHttpServiceConfig(config))

  static post = async (url, config = {}) => super.post(HttpService.toUrl(ApiBaseUrl.NOTIFICATION_MODULE, url), NotificationsModuleService.toHttpServiceConfig(config))

  static put = async (url, config = {}) => super.put(HttpService.toUrl(ApiBaseUrl.NOTIFICATION_MODULE, url), NotificationsModuleService.toHttpServiceConfig(config))

  static delete = async (url, config = {}) => super.delete(HttpService.toUrl(ApiBaseUrl.NOTIFICATION_MODULE, url), NotificationsModuleService.toHttpServiceConfig(config))

  static patch = async (url, config = {}) => super.patch(HttpService.toUrl(ApiBaseUrl.NOTIFICATION_MODULE, url), NotificationsModuleService.toHttpServiceConfig(config))

  static head = async (url, config = {}) => super.head(HttpService.toUrl(ApiBaseUrl.NOTIFICATION_MODULE, url), NotificationsModuleService.toHttpServiceConfig(config))
}
