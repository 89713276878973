import moment from "moment";
export function formatDate(date) {
  var d = new Date(date);
  var month = d.getMonth() + 1;
  var day = "" + d.getDate();
  var year = d.getFullYear();
  if (month.toString().length < 2) month = "0" + month;
  if (day.toString().length < 2) day = "0" + day;
  return [day, month, year].join(".");
}
export function formatDatewithSlash(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const Formats = {
  MMDDYYYYSpace: "MM DD YYYY",
  MMDDYYYYHiphen: "MM-DD-YYYY",
  MMDDYYYYSlash: "MM/DD/YYYY",
  DDMMMCommaYYYY: "DD MMM, YYYY",
  YYYYMMDDH: "YYYY-MM-DD",
};

export const DateFormatter = {
  format: (date, format) => {
    if (date !== undefined && date !== null) return moment(date).format(format);
    return null;
  },
  add: (date, options, format) => {
    const { type, validity } = options;
    if (date !== undefined && date !== null)
      return moment(date).add(validity, type).format(format);
    return null;
  },
};

export const isValidDate = (value, format) => {
  return moment(decodeURI(value), format, true).isValid();
};
