import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { HighlightOffOutlined } from '@mui/icons-material'
import { mergeSxProps } from '../../../util'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { IconButton } from '../../button'
import { CardContent, CardDivided, ContextBtn, DetailsList, GenericCard, DetailItemType, useDetailItem } from '../../card/genericCard'
import { UserGroupAvatarInfo } from '../UserAvatar'
import { useInteraction } from '../../../hooks'
import { UserGroupContextPopover } from '../UserGroupContext'
import { UserGroupList, useUserGroupCard } from './components'

/** The UserGroupCard component is a versatile React card component designed to display user group information in a visually
 *  appealing manner. It offers various customization options and is particularly useful in scenarios where user
 *  details need to be presented in different layouts and styles.
 *
 * ## Usage
 *
 * The UserGroupCard component is designed to be highly customizable and adaptable to different use cases.
 * It extends the functionality of the ``GenericCard`` component, inheriting all its supported properties.
 * For a detailed list of available properties, please consult the [GenericCard documentation](./?path=/docs/alice-ui-card-genericcard--docs).
 *
 * - For full functionality check the prop list below.
 *
 * ### List of integrated CLIB components
 * - [GenericCard](?path=/docs/alice-ui-card-genericcard--docs)
 * - [IdTitle](?path=/docs/alice-ui-card-components-idtitle--docs)
 * - [UserAvatarGroup](?path=/docs/alice-ui-userelements-useravatar-useravatargroup--docs)
 *  */
export const UserGroupCard = ({
  variant = 'grid',
  size = 's',
  userGroupData,
  isContextShown: _isContextShown,
  isDescriptionShown: _isDescriptionShown,
  isUserGroupListShown: _isUserGroupListShown,
  onDeleteClick: _onDeleteClick, // ({ id, data, e })
  // base card
  subgridContentColumns = 1,
  subgridContentRows = 1,
  isLoading = false,
  isIdLink,
  isSelected = false,
  isDisabled = false,
  isSubgrid = false,
  interaction = Interaction.NONE,
  onCardClick: _onCardClick = ({
    id,
    isSelected,
    data,
    e
  }) => {},
  sx = {} || [],
  ...otherProps
}) => {
  const { getDetailItem } = useDetailItem()
  const { t } = useTranslation('componentLibrary')

  const {
    isSelectable,
    isSelectHidden
  } = useInteraction({
    interaction,
    isLoading,
    isDisabled
  })

  const {
    uuid: id,
    name,
    description,
    owners,
    users
  } = userGroupData || {}

  // main props
  const isList = variant === 'list'
  const isGrid = !isList

  const {
    isTiny,
    isContextShown,
    isDescriptionShown,
    isUserGroupListShown,
    isDeleteShown,
    isDividedHorizontal,
    isDividedShown
  } = useUserGroupCard({
    size,
    isList,
    description,
    owners,
    users,
    _isContextShown,
    _isDescriptionShown,
    _isUserGroupListShown,
    _onDeleteClick
  })

  const isContentShifted = isSelectable && !isSelectHidden && isGrid

  const onCardClick = _onCardClick ? ({
    id,
    e,
    isSelected
  }) => {
    _onCardClick({
      id,
      e,
      isSelected,
      data: userGroupData
    })
  } : undefined

  const onDeleteClick = (e) => {
    e.preventDefault()

    _onDeleteClick({
      id,
      data: userGroupData,
      e
    })
  }

  const context = (
    <ContextBtn
      ContextPopover={UserGroupContextPopover}
      id="id" // no request
      userGroupData={userGroupData}
      isLoading={isLoading}
      sx={[ { gridArea: 'context', justifySelf: 'flex-end' } ]}
    />
  )

  return (
    <GenericCard
      type="userGroupCard"
      id={id}
      isTiny={isTiny}
      isList={isList}
      isSelected={isSelected}
      interaction={interaction}
      onCardClick={onCardClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isDividedShown={isDividedShown}
      isDividedHorizontal={isDividedHorizontal}
      isSubgrid={isSubgrid}
      subgridContentColumns={subgridContentColumns}
      subgridContentRows={subgridContentRows}
      sx={mergeSxProps([
        isGrid && !isTiny && {
          '.select': {
            display: 'flex',
            alignItems: 'center',
            height: 64
          }
        }
      ], sx)}
      {...otherProps}
    >
      <CardContent
        context={context}
        isList={isList}
        isContextShown={isContextShown}
        isDividedHorizontal={isDividedHorizontal}
        sx={[
          { gridTemplate: `'avatarInfo ${isDescriptionShown ? 'description' : ''}' / 1fr ${isDescriptionShown ? '1fr' : ''}` },
          isGrid && {
            gridTemplate: `
              'avatarInfo context'
              ${isDescriptionShown ? "'description description'" : ''}             
              / 1fr`
          }
        ]}
      >
        <UserGroupAvatarInfo
          name={name}
          variant={isTiny ? 'grid' : 'list'}
          size={size}
          isLoading={isLoading}
          avatarInfoSize={size === 's' ? 'small' : 'big'}
          sx={{ gridArea: 'avatarInfo' }}
        />

        {isDescriptionShown && (
          <DetailsList
            data={[
              getDetailItem({
                type: DetailItemType.DESCRIPTION,
                text: description || t('userGroupCard.descriptionPlaceholder')
              })
            ]}
            isLoading={isLoading}
            sx={[
              { gridArea: 'description', overflow: 'hidden' },
              isContentShifted && { marginLeft: 'var(--selectBoxShift)' }
            ]}
          />
        )}
      </CardContent>

      <CardDivided
        isShown={isDividedShown}
        isList={isList}
        isTiny={isTiny}
        isHorizontal={isDividedHorizontal}
        isContextShown={isContextShown}
        context={context}
        action={isDeleteShown && (
          <IconButton
            hasSmallClickArea={isTiny}
            onClick={onDeleteClick}
            sx={{ fontSize: '24px' }}
          >
            <HighlightOffOutlined fontSize="inherit" />
          </IconButton>
        )}
      >
        {isUserGroupListShown && (
          <UserGroupList
            groupOwners={owners}
            groupUsers={users}
            isLoading={isLoading}
            size={32}
            sx={[ { gridArea: 'groupList', justifySelf: 'flex-end' } ]}
          />
        )}
      </CardDivided>
    </GenericCard>
  )
}

UserGroupCard.propTypes = {
  /** User group object, that should be displayed */
  userGroupData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    uuid: PropTypes.string,
    owners: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object)
  }),
  /** Variant of the card */
  variant: PropTypes.oneOf([ 'grid', 'list' ]),
  /** Size-variant of the card */
  size: PropTypes.oneOf([ 's', 'm' ]),
  interaction: InteractionPropTypes,
  /** Defines whether to show description or not. */
  isDescriptionShown: PropTypes.bool,
  /** Defines whether to show userGroupList or not. */
  isUserGroupListShown: PropTypes.bool,
  /** Defines whether to show context or not. */
  isContextShown: PropTypes.bool,
  /** Callback function when card gets clicked */
  onCardClick: PropTypes.func,
  /** Specifies if the card is selected or not */
  isSelected: PropTypes.bool,
  /** Loading state. If `true` placeholder will be shown */
  isLoading: PropTypes.bool,
  /** Function appears on size xs. Defines if the card is deletable & provides appropriate data */
  onDeleteClick: PropTypes.func
}
