import axios from 'axios'
import { getCookie, getEnvironment } from '../../util'

export const setupInterceptors = () => {
  const isLocal = () => [ 'local', 'gems' ].includes(getEnvironment())

  axios.interceptors.request.use((req) => {
    if (isLocal()) {
      req.headers.Authorization = getCookie('token')
    }

    return req
  })
}
