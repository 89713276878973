import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Popover, Slide, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CacheKey, CacheTTL } from '../../../data'
import { useCache } from '../../../hooks'
import { createArrayWithInitialValues } from '../../../util'
import { Button, IconButton } from '../../button'
import { Dialog } from '../../dialog'
import { AnnouncementsEmpty } from '../announcementsEmpty/AnnouncementsEmpty'
import { AnnouncementPopoverCard } from './AnnouncementPopoverCard'

const fallbackLanguage = 'en'
const skeletons = 3

/**
 * A list of announcements as a popover or fullscreen dialog. <br>
 * Note, that the popover is always placed in the top right corner of the viewport.
 */
export const AnnouncementsPopover = ({
  announcements = [],
  type = 'popover',
  anchor,
  isOpen,
  isLoading,
  onClose,
  onUpdate
}) => {
  const { t, i18n } = useTranslation('componentLibrary')
  const { getCachedItem, setCachedItem } = useCache(CacheStorage.localStorage, CacheTTL.oneMonth)

  const [ selectedAnnouncementIndex, setSelectedAnnouncementIndex ] = useState(-1)

  const announcementsLoadingArray = createArrayWithInitialValues(skeletons)

  const hasSeen = (id) => {
    const cached = getCachedItem(CacheKey.seenAnnouncements)

    return cached && cached[id]
  }

  const setSeen = (id, value = true) => {
    const cached = getCachedItem(CacheKey.seenAnnouncements) || {}

    cached[id] = value

    setCachedItem(CacheKey.seenAnnouncements, cached)

    onUpdate()
  }

  const getTitleInLanguage = (title = {}) => title[i18n.language] || title[fallbackLanguage]
  const getMessageInLanguage = (message = {}) => message[i18n.language] || message[fallbackLanguage]

  const handleClick = (index) => {
    setSeen(announcements[index]?.id)
    setSelectedAnnouncementIndex(index)
  }

  const handleBack = () => setSelectedAnnouncementIndex(-1)
  const handleClose = () => setSelectedAnnouncementIndex(-1)

  return (
    <>
      {type === 'dialog' && (
        <Dialog
          headerSection={t('announcements.title')}
          fullscreen
          open={isOpen}
          onClose={onClose}
        >
          <Stack gap={1}>
            {isLoading
              ? announcementsLoadingArray.map((index) => (
                <AnnouncementPopoverCard
                  key={`skeleton-${index}`}
                  isLoading
                  hasSeen={false}
                  lineClamp={2}
                />
              ))
              : announcements.length
                ? (
                  announcements.map((({
                    id,
                    title,
                    message
                  }, index) => (
                    <AnnouncementPopoverCard
                      key={`card${id}`}
                      id={id}
                      title={title ? getTitleInLanguage(title) : t('announcements.announcement.fallbackTitle')}
                      message={message ? getMessageInLanguage(message) : t('announcements.announcement.fallbackMessage')}
                      hasSeen={hasSeen(id)}
                      onClick={() => handleClick(index)}
                      lineClamp={2}
                    />
                  )))
                )
                : (
                  <AnnouncementsEmpty />
                )}
          </Stack>

          <Slide
            direction="left"
            in={selectedAnnouncementIndex !== -1}
            mountOnEnter
            unmountOnExit
            sx={{
              position: 'fixed',
              top: 58,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'white'
            }}
          >
            <Stack gap={2} justifyContent="space-between">
              <Stack gap={2} sx={{ height: '100%', overflow: 'hidden' }}>
                <Box sx={{ padding: '0 16px' }}>
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                  </IconButton>
                </Box>

                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  <Box sx={{ padding: '0 16px' }}>
                    <AnnouncementPopoverCard
                      id={announcements[selectedAnnouncementIndex]?.id}
                      title={getTitleInLanguage(announcements[selectedAnnouncementIndex]?.title) || t('announcements.announcement.fallbackTitle')}
                      message={getMessageInLanguage(announcements[selectedAnnouncementIndex]?.message) || t('announcements.announcement.fallbackTitle')}
                      hasSeen={false}
                    />
                  </Box>
                </Box>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '0 16px'
                }}
              >
                <Button sx={{ minWidth: '100px', marginBottom: '16px' }} onClick={handleBack}>
                  {t('general.ok')}
                </Button>
              </Box>
            </Stack>
          </Slide>
        </Dialog>
      )}

      {type === 'popover' && (
        <Popover
          anchorEl={anchor || document?.body}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          disableScrollLock
          open={isOpen}
          onClose={onClose}
          sx={{ top: '42px' }}
        >
          <Stack gap={1} sx={{ width: '42vw', padding: '10px 0' }}>
            <Typography sx={{ padding: '0 16px', color: 'grey.50' }}>{t('announcements.title')}</Typography>

            {isLoading
              ? announcementsLoadingArray.map((index) => (
                <AnnouncementPopoverCard
                  key={`skeleton-${index}`}
                  isLoading
                  hasSeen
                  lineClamp={2}
                />
              ))
              : announcements.length
                ? (
                  announcements.map((({
                    id,
                    title,
                    message
                  }, index) => (
                    <Box key={id}>
                      <AnnouncementPopoverCard
                        id={id}
                        title={title ? getTitleInLanguage(title) : t('announcements.announcement.fallbackTitle')}
                        message={message ? getMessageInLanguage(message) : t('announcements.announcement.fallbackMessage')}
                        hasSeen={hasSeen(id)}
                        lineClamp={2}
                        onClick={() => handleClick(index)}
                      />

                      <Dialog
                        headerSection={t('announcements.details')}
                        footerSection={<Button onClick={handleClose}>{t('general.ok')}</Button>}
                        open={selectedAnnouncementIndex === index}
                        onClose={handleClose}
                      >
                        <AnnouncementPopoverCard
                          id={id}
                          title={title ? getTitleInLanguage(title) : t('announcements.announcement.fallbackTitle')}
                          message={message ? getMessageInLanguage(message) : t('announcements.announcement.fallbackMessage')}
                          hasSeen={false}
                        />
                      </Dialog>
                    </Box>
                  )))
                )
                : (
                  <AnnouncementsEmpty />
                )}
          </Stack>
        </Popover>
      )}
    </>
  )
}

AnnouncementsPopover.propTypes = {
  /** An array of announcement objects */
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.shape({ en: PropTypes.string }).isRequired,
      message: PropTypes.shape({ en: PropTypes.string }).isRequired,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      archived: PropTypes.bool,
      recipient: PropTypes.arrayOf(PropTypes.string),
      authorId: PropTypes.any
    })
  ).isRequired,
  /** Type of the component, either 'dialog' or 'popover' */
  type: PropTypes.oneOf([ 'dialog', 'popover' ]),
  /** Indicates whether the component is open or not */
  isOpen: PropTypes.bool,
  /** Event handler function called when the component is closed */
  onClose: PropTypes.func,
  /** Event handler function called when the component needs to be updated */
  onUpdate: PropTypes.func
}
