import React, { useState } from "react";
import { UserAvatar } from "@alice/component_library";
import { UserContextPopover } from "@alice/component_library";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media (max-width: 320px) {
    width: "100px";
  }
  @media (min-width: 321px) {
    width: ${(props) => props.componentSize || "300px"};
  }
`;

const Circle = styled.div`
  position: relative;
  width: ${(props) => props.circleSize || "100px"};
  height: ${(props) => props.circleSize || "100px"};
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${(props) => `-${props.gapInbetween}` || "-30px"};
  border: ${(props) => props.borderWidth || "2px"} solid #fff; /* White border */
`;

// const Image = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  color: #fff;
  text-align: center;
  font-family: "MB Corpo S Text";
  font-size: "14px";
  font-style: "normal";
  font-weight: "400";
  line-height: "20px";
`;

const MultiProfileImageComponent = ({
  data,
  circleSize,
  fontSize,
  componentSize,
  onBadgeClick,
  borderWidth,
  maxImages,
  gapInbetween,
  isneeduserContextPopover = false,
}) => {
  const shownDatas = data?.slice(0, maxImages);
  const remainingDatas = data?.slice(maxImages);

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Container componentSize={componentSize}>
      {shownDatas?.map((data, index) => (
        <Circle
          key={index}
          circleSize={circleSize}
          gapInbetween={gapInbetween}
          borderWidth={borderWidth}
        >
          {isneeduserContextPopover ? (
            <UserContextPopover id={data?.id}>
              <UserAvatar
                sizeStyle={{
                  height: circleSize,
                  width: circleSize,
                  fontSize: fontSize,
                }}
                userId={data?.id}
                userName={`${data?.givenname} ${data?.surname}`}
                showImage={true}
              />
            </UserContextPopover>
          ) : (
            <UserAvatar
              sizeStyle={{
                height: circleSize,
                width: circleSize,
                fontSize: fontSize,
              }}
              userId={data?.id}
              userName={`${data?.givenname} ${data?.surname}`}
              showImage={true}
            />
          )}
        </Circle>
      ))}
      {remainingDatas?.length > 0 && (
        <Circle
          circleSize={circleSize}
          gapInbetween={gapInbetween}
          borderWidth={borderWidth}
        >
          <UserAvatar
            sizeStyle={{
              height: circleSize,
              width: circleSize,
              fontSize: fontSize,
            }}
            userId={remainingDatas?.[0].id}
            userName={`${remainingDatas?.[0].surname} ${remainingDatas?.[0].givenname}`}
            showImage={true}
          />

          <Badge
            onClick={(e) => {
              e.preventDefault();
              onBadgeClick(data);
            }}
          >
            +{remainingDatas.length}
          </Badge>
        </Circle>
      )}
    </Container>
  );
};

export default MultiProfileImageComponent;
