import {
  CustomCardContainer,
  CustomCardDetailHeader,
  CustomCardDetailDescription,
  CustomCardApplicationDetails,
} from "./RCardStyle";
import CardContent from "@mui/material/CardContent";
import { Stack } from "@mui/material";
import { RequestCase } from "@src/pages/requestPage/Components/Details/RequestCase";
import { useTranslation } from "react-i18next";

const CustomRCard = ({ taskInfo }) => {
  const { t } = useTranslation();
  const {
    customSvgLeft,
    customValueLeft,
    customOperationalIcon,
    customSvgRight,
    customValueRight,
    customAssignId,
    smsIcon,
    rejectReasonIcon,
    rejectReason,
    applicationReasonHeader,
    rejectReasonHeader,
    applicationReason,
    entitlementId,
    detail,
  } = RequestCase(taskInfo, t);
  return (
    <CustomCardContainer>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        useFlexGap
        flexWrap="wrap"
      >
        <CardContent
          sx={{
            width: "-webkit-fill-available",
            paddingBottom: "16px !important",
            ...(rejectReason && rejectReason.length
              ? { paddingBottom: "0px !important" }
              : {}),
            ...(applicationReason && applicationReason.length
              ? { paddingBottom: "0px !important" }
              : {}),
          }}
        >
          <CustomCardDetailHeader>
            <div className="custom-head-left">
              <div className="custom-header-svg-left">{customSvgLeft}</div>
              <div className="custom-header-value-left">
                {customValueLeft}
                {entitlementId && <span>({entitlementId})</span>}
              </div>
            </div>
            <div className="custom-header-svg-operation-icon">
              {customOperationalIcon}
            </div>
            <div className="custom-head-right">
              <div className="custom-header-svg-right">{customSvgRight}</div>
              <div className="custom-header-value-right">
                {customValueRight}
                {customAssignId && <span>({customAssignId})</span>}
              </div>
            </div>
          </CustomCardDetailHeader>
          <CustomCardDetailDescription
            dangerouslySetInnerHTML={{ __html: detail }}
          ></CustomCardDetailDescription>
        </CardContent>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          display: "inherit",
          justifyContent: "space-between",
        }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        useFlexGap
        flexWrap="wrap"
      >
        <CustomCardApplicationDetails>
          {applicationReason && applicationReason.length ? (
            <CardContent
              className="custom-appHead"
              sx={{
                paddingBottom: "16px !important",
                ...(rejectReason && rejectReason.length
                  ? { width: "48%", paddingBottom: "16px !important" }
                  : {}),
              }}
            >
              <div className="custom-smsIcon">
                {smsIcon}
                <span>{applicationReasonHeader}</span>
              </div>
              <div className="custom-applicationReasonText">
                {applicationReason}
              </div>
            </CardContent>
          ) : null}

          {rejectReason && (
            <CardContent
              className="custom-appHead"
              sx={{
                paddingBottom: "16px !important",
                ...(applicationReason && applicationReason.length
                  ? { width: "48%", paddingBottom: "16px !important" }
                  : { width: "100%" }),
              }}
            >
              <div className="custom-smsIcon">
                {rejectReasonIcon}
                <span>{rejectReasonHeader}</span>
              </div>
              <div className="custom-applicationReasonText">{rejectReason}</div>
            </CardContent>
          )}
        </CustomCardApplicationDetails>
      </Stack>
    </CustomCardContainer>
  );
};

export default CustomRCard;
