import { styled } from "@mui/material";

export const RoleDetailsContainer = styled("div")(() => ({
  "& .detail-header": {
    display: "grid",
    gridTemplateColumns: "1fr 24px",
    gap: "16px",
  },
  "& .caption-header": {
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.60)",
    paddingBottom: "4px",
  },
  "& .subtitle-header": {
    paddingBottom: "16px",
  },
}));

export const RoleDetailsHeaderContainer = styled("div")(() => ({
  paddingBottom: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  flexWrap: "wrap",
  overflow: "hidden",
}));

export const RoleIdNameContainer = styled("div")(() => ({
  overflow: "hidden",
  "& .role-cardv3-header": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    "& .id-holder": {
      background: "#00000014",
      padding: "5.5px 4px",
      borderRadius: "2px",
      lineHeight: "16px",
      height: "24px",
      width: "24px",
      minWidth: "24px",
      textAlign: "center",
      fontWeight: 700,
    },
    "& .role-cardv3-id": {
      color: "#0078D6",
    },
  },
  "& .role-cardv3-name": {
    lineHeight: "32px",
    fontSize: "1.25rem",
  },
}));

export const ConfigurationItemsConatainer = styled("div")(() => ({
  margin: "4px 0",
  display: "flex",
  gap: "8px",
  flexWrap: "wrap",
  alignItems: "center",
  "& .MuiChip-label": {
    display: "flex",
    padding: "4px 8px",
    "& .org-cust-chip": {
      display: "flex",
      gap: "4px",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
}));

export const DescriptionContainer = styled("div")(({ theme, ...props }) => ({
  padding: "16px 0",
  display: "grid",
  gridTemplateColumns: props.twosections ? "repeat(2, 1fr)" : "repeat(1, 1fr)",
  gap: "16px",
  "& .app-grid": {
    paddingTop: "4px",
    gap: "16px",
    display: "grid",
    gridTemplateColumns: props.twosections
      ? "repeat(1, 1fr)"
      : "repeat(2, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}));

export const AttachmentsContainer = styled("div")(() => ({
  padding: "16px 0",
  "& .template-file-container": {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
    "& .template-file-link": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& .link-text": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "noWrap",
      },
    },
  },
}));

export const EntitlementsContainer = styled("div")(() => ({
  padding: "16px 0",
}));
