import { ManageSearchOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'
import { Message } from '../../message'

/**
 * This is a generic message component.
 */
export const DataPlaceholder = forwardRef((
  {
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Box
      data-testid="DataPlaceholder"
      sx={mergeSxProps(
        {
          width: 1,
          height: '450px',
          border: 1,
          borderColor: 'grey.300',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4
        },
        sx
      )}
      {...otherProps}
    >
      <Message
        icon={<ManageSearchOutlined sx={{ fontSize: '3rem' }} />}
        title={<Typography variant="h4">{t('genericSearch.placeholderTitle')}</Typography>}
        description={(
          <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            {t('genericSearch.placeholderDescription')}
          </Typography>
        )}
      />
    </Box>
  )
})

DataPlaceholder.propTypes = {
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
