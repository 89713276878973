export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) return response.data

  const error = new Error(`HTTP Error ${response.statusText}`)

  error.status = response.statusText
  error.response = response

  console.error(error)

  throw error
}

export const tryFetch = async (tryFunction, catchFunction) => {
  try {
    await tryFunction()
  } catch (e) {
    console.error('Fetch Error:', e)
    catchFunction(e)
  }
}

// eslint-disable-next-line consistent-return
export const tryFetchReturn = async (tryFunction, catchFunction) => {
  try {
    return await tryFunction()
  } catch (e) {
    console.error('Fetch Error:', e)
    catchFunction(e)
  }
}

export const tryFetchCheckReturn = async (tryFunction, catchFunction) => {
  try {
    return checkStatus(await tryFunction())
  } catch (e) {
    console.error('Fetch Error:', e)
    catchFunction(e)
  }
}
