import { DataGrid } from "@mui/x-data-grid";

const MuiDataGrid = ({
  rows,
  columns,
  paginationModel = { page: 0, pageSize: 12 },
  rowClick = () => {},
  pageSizeOptions = [12, 15, 20],
  disableColumnMenu = true,
  sx = {},
  bottomPagination = false,
  ...props
}) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: paginationModel,
        },
      }}
      paginationModel={paginationModel}
      pagination
      onRowClick={rowClick}
      pageSizeOptions={pageSizeOptions}
      disableColumnMenu={disableColumnMenu}
      sx={{
        display: "flex",
        flexDirection: bottomPagination ? "column" : "column-reverse",
        border: "none",
        "& .MuiDataGrid-footerContainer": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeader": {
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-cell": {
          border: "none !important",
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default MuiDataGrid;
