import TableRowsLoader from "./TableRowsLoader";
import MenuCard from "./MenuCard";
import {
  FilterWithSorting,
  PageHeaderSkeleton,
  PaginationSkeleton,
  Grid,
  StyledLayout,
  Layout,
  Skeleton,
} from "./CommonSkeletons";

function TaskPageSkeleton({
  showAdminSwitch = false,
  filterSize = 1,
  chipNumber = [3],
  showSorting = true,
  menuCount = 8,
  showTableHeader = true,
  columnCount = 4,
  rowCount = 12,
  dataGrid = false,
  taskpageHeader = false,
  windowSize = { isDesktop: true, isMobile: true, isTablet: true },
}) {
  const { isDesktop, isMobile, isTablet } = windowSize;
  return (
    <StyledLayout>
      <Layout>
        <PageHeaderSkeleton showAdminSwitch={showAdminSwitch} />
        {(isTablet || isMobile) && !isDesktop ? (
          <>
            {[...Array(3)].map((ele) => (
              <Skeleton
                variant="rectangular"
                height="56px"
                sx={{ margin: "24px 0" }}
                key={ele}
              />
            ))}
          </>
        ) : (
          <FilterWithSorting
            filterSize={filterSize}
            chipNumber={chipNumber}
            showSorting={showSorting}
          />
        )}
        <PaginationSkeleton />
        <Grid container spacing={2}>
          <Grid item md={3} sx={{ display: { xs: "none", md: "block" } }}>
            <MenuCard rowsNum={menuCount} />
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <TableRowsLoader
              rowsNum={rowCount}
              columnCount={columnCount}
              showTableHeader={showTableHeader}
              dataGrid={dataGrid}
              taskpageHeader={taskpageHeader}
            />
          </Grid>
        </Grid>
      </Layout>
    </StyledLayout>
  );
}

export default TaskPageSkeleton;
