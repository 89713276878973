import { Divider, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { UsersService } from '../../../data'
import { useApi } from '../../../hooks'
import { timeout } from '../../../util'
import { GenericContextPopover } from '../../genericContextPopover'
import { Skeleton } from '../../skeleton'
import { UserContext } from './UserContext'

// For dev: timeoutInMS = 3000 -> wait 3 sec before the initial and other api calls are triggered
// For live: Set timeoutInMS to 0 (zero)!
const timeoutInMS = process.env.NODE_ENV === 'development' ? 3000 : 0

// LoadingSkeleton is shown as long the request is running to get the user data
const LoadingSkeleton = () => (
  <>
    <Stack sx={{ flexDirection: 'row', gap: 2 }}>
      <Skeleton
        variant="circular"
        sx={{ width: '64px', height: '64px' }}
      />

      <Stack gap="6px" justifyContent="center">
        <Stack direction="row" gap={1}>
          <Skeleton
            variant="rectangular"
            width="24px"
            sx={{ height: '1.5rem' }}
          />

          <Skeleton
            variant="rectangular"
            width="96px"
            sx={{ height: '1.5rem' }}
          />
        </Stack>

        <Skeleton
          variant="rectangular"
          width="150px"
          sx={{ height: '1.5rem' }}
        />
      </Stack>
    </Stack>

    <Stack
      sx={{
        flexDirection: 'row',
        marginTop: 1,
        height: '32px',
        justifyContent: 'space-between'
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '20px',
          height: '20px',
          width: '70px'
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '20px',
          height: '20px',
          width: '70px'
        }}
      />
    </Stack>

    <Stack
      sx={{
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center'
      }}
    >
      <Skeleton
        variant="rounded"
        sx={{
          width: '20px',
          height: '20px',
          margin: '10px'
        }}
      />

      <Stack>
        <Skeleton
          variant="rectangular"
          sx={{
            width: '70px',
            height: '16px',
            marginBottom: '4px'
          }}
        />

        <Skeleton variant="rectangular" sx={{ width: '120px', height: '20px' }} />
      </Stack>
    </Stack>

    <Divider />

    <Stack gap={2}>
      <Stack>
        <Skeleton
          variant="rectangular"
          sx={{
            width: '60px',
            height: '14px',
            marginBottom: '4px',
            marginLeft: '22px'
          }}
        />

        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Skeleton variant="rounded" sx={{ width: '14px', height: '14px' }} />

          <Skeleton variant="rectangular" sx={{ width: '120px', height: '16px' }} />
        </Stack>
      </Stack>

      <Stack>
        <Skeleton
          variant="rectangular"
          sx={{
            width: '60px',
            height: '14px',
            marginBottom: '4px',
            marginLeft: '22px'
          }}
        />

        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Skeleton variant="rounded" sx={{ width: '14px', height: '14px' }} />

          <Skeleton variant="rectangular" sx={{ width: '120px', height: '16px' }} />
        </Stack>
      </Stack>
    </Stack>

    <Divider />

    <Stack gap={1}>
      <Skeleton variant="rounded" sx={{ width: '60px', height: '16px' }} />

      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <Skeleton
          variant="circular"
          sx={{ width: '56px', height: '56px' }}
        />

        <Stack gap="6px" justifyContent="center">
          <Stack direction="row" gap={1}>
            <Skeleton
              variant="rectangular"
              width="24px"
              sx={{ height: '1.275rem' }}
            />

            <Skeleton
              variant="rectangular"
              width="96px"
              sx={{ height: '1.275rem' }}
            />
          </Stack>

          <Skeleton
            variant="rectangular"
            width="150px"
            sx={{ height: '1.275rem' }}
          />
        </Stack>
      </Stack>
    </Stack>
  </>
)

export const UserContextPopover = ({
  id: newId,
  userId, // deprecated prop, will be removed soon
  placement,
  children,
  showTeamsLink = false,
  showDetailedCommunity = false,
  ...otherProps
}) => {
  const [ isTimeoutDone, setIsTimeoutDone ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false)

  const [ organizationId, setOrganizationId ] = useState(null)

  // This temporary variable is used as long as the deprecated prop is present in the component.
  const id = newId || userId

  const { response: responseUser } = useApi(
    UsersService.getById,
    {
      config: { id },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone,
      onRequestSuccess: (response) => setOrganizationId(response.homeOrgId)
    }
  )

  const { isFailure: isFailureSupervisor, response: responseSupervisor } = useApi(
    UsersService.getSupervisorById,
    {
      config: { id },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone
    }
  )

  const { response: responseOrganization } = useApi(
    UsersService.getOrganizationById,
    {
      config: { id, organizationId },
      isRequestingInitially: false,
      isRequestingOnTruthy: isTimeoutDone && organizationId
    }
  )

  useEffect(() => {
    const waitTimeout = async () => {
      if (timeoutInMS > 0) await timeout(timeoutInMS)
      setIsTimeoutDone(true)
    }

    if (id && isOpen) waitTimeout()
  }, [ id, isOpen ])

  return (
    <GenericContextPopover
      data-testid="UserContextPopover"
      context={
        responseUser
          ? (
            <UserContext
              userData={responseUser}
              orgData={responseOrganization}
              supervisorData={!isFailureSupervisor ? responseSupervisor : null}
              showTeamsLink={showTeamsLink}
              showDetailedCommunity={showDetailedCommunity}
            />
          )
          : <LoadingSkeleton />
      }
      placement={placement}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      {...otherProps}
    >
      {children}
    </GenericContextPopover>
  )
}

UserContextPopover.propTypes = {
  /** userId */
  id: PropTypes.string.isRequired,
  /**
   * userId
   *
   * @deprecated Do not use! Use `id` prop instead!
   */
  userId: PropTypes.string,
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ]),
  /** The component that should be hover-able */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Shows MB Teams link */
  showTeamsLink: PropTypes.bool,
  /** Displays a detailed community name if TECH_USER or TEST_USER is present */
  showDetailedCommunity: PropTypes.bool
}
