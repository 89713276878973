import { useState } from 'react'

export const useBoolean = (initialState = false) => {
  const [ state, setState ] = useState(initialState)

  const on = () => setState(true)
  const off = () => setState(false)
  const toggle = () => setState(!state)

  return {
    state,
    on,
    off,
    toggle
  }
}
