import { Button as MuiButton, CircularProgress, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeSxProps } from '../../../util'

export const BtnIconWrapper = ({ children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      width: 20,
      height: 20
    }}
  >
    {children}
  </div>
)

/**
 * A simple button. For more documentation see: https://mui.com/material-ui/api/button/.<br>
 * All props from the button are available as usual.
 *
 * Use the primary button for the main action of the page.<br>
 * The secondary button is for all other actions on the page.<br>
 * The tertiary button is for very rarely used functions.
 */
export const Button = forwardRef((
  {
    variant = 'primary',
    size = 'medium',
    children,
    isLoading,
    disabled,
    sx = {},
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  const config = {
    primary: { variant: 'contained', color: 'primary' },
    secondary: { variant: 'outlined', color: 'secondary' },
    tertiary: { variant: 'text' },
    small: { sx: { gap: '4px' } },
    medium: { sx: { gap: '6px' } },
    large: { sx: { gap: 1 } }
  }

  // Fallback to primary if variant is unknown
  if (!Object.keys(config).includes(variant)) {
    variant = 'primary'
  }

  return (
    <MuiButton
      data-testid="Button"
      ref={ref}
      {...config[variant]}
      sx={mergeSxProps(
        {
          alignItems: 'center',
          ...config[variant]?.sx,
          ...config[size]?.sx
        },
        sx
      )}
      size={size}
      disabled={disabled || isLoading}
      disableElevation
      {...otherProps}
    >
      {!isLoading
        ? children
        : (
          <>
            <BtnIconWrapper>
              <CircularProgress size={16} />
            </BtnIconWrapper>

            <Typography>{t('general.loading')}</Typography>
          </>
        )}
    </MuiButton>
  )
})

Button.propTypes = {
  /** Determines the style of the button */
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'tertiary' ]),
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Add some additional styling to the button */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
