import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { Grid, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider, TextField } from "@mui/material";

import { useNotification } from "@alice/component_library";

import { Authorizations } from "@utils";
import { hasData } from "@utils/Validator";

import Communication from "@services/Communication";
import urlConfig from "@services/urlConfig";
import { SaveUserData } from "@actions";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { MyDetailsSkelton } from "@components/Skeleton/CommonSkeletons";
import { getProfileEditFields, getDefaultValues } from "../../helper";
import {
  PersonalInfoContainer,
  MainContainer,
  MailAddressContainer,
  EmailWarningContainer,
} from "../../styledComponents";

const MyProfileEdit = ({ editHandler }) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.userProfile);
  const userData = user.data;
  const { communities = [] } = userData;

  const showEmailAddress = hasData(
    communities?.filter((d) => Authorizations["emailAddress"].includes(d))
  );

  const theme = useTheme();
  const formRef = useRef(null);
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { notify } = useNotification();

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: getDefaultValues(userData),
    mode: "all",
    reValidateMode: "all",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const {
      id,
      homeOrgId,
      workingOrgId,
      preferredLanguage: originalPreferredLanguage,
    } = userData;
    const reqData = {
      preferredLanguage: data.PreferredLang,
      workingOrgId,
      surname: data.lastName,
      givenname: data.firstName,
      nationalLanguageGivenname: data.localFName,
      nationalLanguageSurname: data.localLName,
      telephoneNumber: data.phone,
      mobileNumber: data.mobile,
      faxNumber: data.fax,
      mailAddress: data.email,
      homeOrgId,
    };

    Communication.put(urlConfig.user(id), reqData)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(SaveUserData({ data: resp.data }));
          if (data.PreferredLang !== originalPreferredLanguage) {
            i18n.changeLanguage(data.PreferredLang);
          }
          editHandler(
            notify({
              severity: "success",
              message: t("user-update-success-message"),
            })
          );
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        editHandler(
          notify({
            severity: "error",
            message: t("user-update-failed-message"),
          })
        );
      });
  };

  const isEditable = (key) => {
    const { communities } = userData;

    const common = [
      "CORPORATE_CUSTOMER_EMPLOYEE",
      "CORPORATE_BUSINESS_PARTNER_PERSON",
      "DEALER_EMPLOYEE",
      "SUPPLIER_EMPLOYEE",
    ];
    const permission = {
      local: common.concat(["DAIMLER_EMPLOYEE"]),
      mobile: common,
      tel: common,
      fax: common,
      mail: common,
      lang: common.concat(["DAIMLER_EMPLOYEE"]),
      workPlace: common.concat(["DAIMLER_EMPLOYEE"]),
    };
    return permission[key]
      ? permission[key].some((d) => communities.includes(d))
      : false;
  };

  const getInputField = (config = {}) => {
    const { name, label, type, editKey } = config;
    const { options = [], validations = {}, errorMessage = "" } = config;
    const { extraValidation = "" } = config;
    const editable = isEditable(editKey);

    const generateSelectOptions = () => {
      return options.map((option) => {
        return (
          <MenuItem
            key={option.value}
            value={option.value}
            className="inputOptions"
          >
            {option.label}
          </MenuItem>
        );
      });
    };

    return (
      <MainContainer
        className={
          errors[name]
            ? xs
              ? "inputItemXS error"
              : "inputItem error"
            : xs
            ? "inputItemXS"
            : "inputItem"
        }
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              label={label}
              variant="filled"
              fullWidth
              type={type}
              disabled={!editable}
              select={type === "select"}
              inputProps={register(
                name,
                !editable ? (validations["required"] = false) : validations
              )}
              onKeyDown={(e) => {
                const constrolKeys = [
                  "Backspace",
                  "Delete",
                  "ArrowLeft",
                  "ArrowRight",
                ];
                if (constrolKeys.includes(e.key)) {
                  return true;
                }

                if (
                  extraValidation !== "" &&
                  !extraValidation.test(e.target.value + e.key)
                )
                  e.preventDefault();
              }}
            >
              {type === "select" ? generateSelectOptions() : null}
            </TextField>
          )}
        />
        {errors[name] ? (
          <span>
            {errors[name].type === "required"
              ? errorMessage
              : errors[name].message}
          </span>
        ) : null}
      </MainContainer>
    );
  };

  if (isLoading) return <MyDetailsSkelton />;

  const fields = getProfileEditFields(t);

  return (
    <Grid container style={{ padding: "30px 0", width: "100%" }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
        style={{ width: "100%" }}
        noValidate
      >
        <PersonalInfoContainer>
          <>
            <SecondaryButton
              label={<span>{t("cancel")}</span>}
              onButtonClick={() => editHandler()}
            />
            <PrimaryButton
              label={<span>{t("save-changes")}</span>}
              onButtonClick={() => {
                formRef.current.dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                );
              }}
              style={{ marginLeft: "20px" }}
              type="submit"
            />
          </>
        </PersonalInfoContainer>
        <MailAddressContainer container>
          <Grid container xs={12} sm={12} md={12} lg={12} spacing={1}>
            {fields.map((field) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                sx={{ marginBottom: "14px" }}
              >
                {getInputField(field)}
              </Grid>
            ))}
          </Grid>
          {showEmailAddress ? (
            <>
              {" "}
              {/* <Divider orientation="vertical" flexItem /> */}
              <Grid xs={12} sm={12} md={12} lg={12}>
                <EmailWarningContainer>
                  <span>{t("email")}</span>
                  <br />
                  <span>{t("email-caution")}</span>
                  <br />
                  <span>{t("email-verify")}</span>
                  <br />
                  <span>{t("new-email")}</span>
                </EmailWarningContainer>
              </Grid>
            </>
          ) : null}
        </MailAddressContainer>
      </form>
    </Grid>
  );
};

export default MyProfileEdit;
