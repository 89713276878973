import { v4 as uuidv4 } from "uuid";
import { Authorizations } from "./authorizations";

export const getUUID = () => uuidv4();

export const a11yProps = (index, id) => ({
  id: `id-${index}`,
  "aria-controls": `aria-id-${index}`,
});

export const isSuperAdmin = (roles, type) =>
  Authorizations[type].some((i) => roles?.includes(i));

export const listCreator = (data) =>
  data
    ? data.map((x) => ({
        label: x.name,
        value: x.id,
      }))
    : [];

export const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const clearSessionStorage = (key) => {
  return sessionStorage.clear();
};

export const getSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const adminLabelMap = {
  ORGADMIN: "orgAdmin",
  APP_HELPDESK_ADMIN: "applicationHelpdeskAdmin",
  MARKETADMIN: "marketAdmin",
  ORGANIZATION_ROLE_ASSIGNER: "organizationRoleAssigner",
  SHARED_SERVICE_ADMIN: "sharedServiceAdmin",
  CENTRAL_HELPDESK_ADMIN: "centralHelpdeskAdmin",
  GLOBAL_COMMUNITY_ADMIN: "globalCommunityAdmin",
  GLOBAL_ROLE_ASSIGNER: "globalRoleAssigner",
  CENTRAL_CBP_ADMIN: "centralBusinessPartnerAdmin",
  MARKET_HELPDESK_ADMIN: "marketHelpdeskAdmin",
  GROUP_ADMIN: "groupAdmin",
  APPADMIN: "applicationAdmin",
  ROLE_APPROVER: "roleApprover",
  ROLE_OWNER: "roleOwner",
  ROLE_CREATOR: "roleCreator",
  MARKET_ROLE_ASSIGNER: "marketRoleAssigner",
  MARKET_CBP_ADMIN: "marketCorporateBusinessPartnerAdmin",
  MARKET_IDENTSTATUS_ADMIN: "marketIdentstatusAdmin",
  SUPERVISOR: "supervisor",
  apiAdmin: "apiAdmin",
  DATA_ADMIN: "dataAdmin",
  FUNCTIONAL_ADMINISTRATION: "functionalAdministration",
  CORE_CONFIGURATION_ADMIN: "coreConfigurationAdmin",
  CONNECTOR_CONFIGURATION_ADMIN: "connectorConfigurationAdmin",
  ADMIN_ROLE_ASSIGNMENT_ADMIN: "adminRoleAssignmentAdmin",
  AUDIT_LOG: "auditLog",
  BILLING: "billing",
  EMPLOYEE_FOR_EMPLOYEE_MBAG_ADMIN: "employeeForEmployeeMbagAdmin",
};

export { Authorizations };

export const isWorkFlow = (task) => {
  if ("workflowId" in task) {
    return true;
  } else {
    return false;
  }
};

export const splitWord = (word) => {
  // Use regular expression to split words with multiple spaces
  const words = word.split(/\s+/);
  // Filter out empty strings
  return words.filter((w) => w !== "");
};

export const isWithinNextTwoWeeks = (date) => {
  const today = new Date();
  const nextTwoWeek = new Date(today.setDate(today.getDate() + 14));
  return new Date(date) <= nextTwoWeek && new Date(date) >= new Date();
};

export const isWithinPreviousWeek = (date) => {
  const today = new Date();
  const previousWeek = new Date(today.setDate(today.getDate() - 7));
  return new Date(date) >= previousWeek && new Date(date) <= new Date();
};

export const truncate = (input, length) =>
  input.length > length ? `${input.substring(0, length)}...` : input;
