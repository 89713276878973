import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DATE_OPTIONS_LONG = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const useFormatAndChunkData = (activityData, widthPanel = "xs") => {
  const { t } = useTranslation();
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    if (activityData.length) {
      const today = new Date();
      const yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24);

      const formattedData = activityData.reduce((acc, item) => {
        const itemDate = new Date(item.lastModifyDate); // Access the date key
        if (itemDate.toDateString() === today.toDateString()) {
          acc[t("today")] = acc[t("today")] || [];
          acc[t("today")].push(item);
        } else if (itemDate.toDateString() === yesterday.toDateString()) {
          acc[t("yesterday")] = acc[t("yesterday")] || [];
          acc[t("yesterday")].push(item);
        } else {
          const key = itemDate.toLocaleDateString("default", DATE_OPTIONS_LONG);
          acc[key] = acc[key] || [];
          acc[key].push(item);
        }

        return acc;
      }, {});

      const transformedData = [];
      for (const key in formattedData) {
        transformedData.push({ value: key });
        transformedData.push(...formattedData[key]);
      }

      // Grouping data into chunks of 5
      const chunkedData = [];
      let currentChunk = [];

      transformedData.forEach((item, index) => {
        currentChunk.push(item);

        // If the current chunk has reached the limit of 5 items
        if (currentChunk.length === 7 || index === transformedData.length - 1) {
          // If the last item in the chunk is a "value" structure, move it to the next chunk
          if (currentChunk[currentChunk.length - 1].value) {
            chunkedData.push(currentChunk.slice(0, -1));
            currentChunk = [currentChunk[currentChunk.length - 1]];
          } else {
            chunkedData.push(currentChunk);
            currentChunk = [];
          }
        }
      });

      const datatorender = ["lg", "xl", "xxl"].includes(widthPanel)
        ? chunkedData.slice(0, 2)
        : chunkedData.slice(0, 1);

      setGroupedData(datatorender);
    }
  }, [activityData, t, widthPanel]);

  return groupedData;
};

export default useFormatAndChunkData;
