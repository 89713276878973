import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useUser } from "@alice/component_library";
import OrgColleagues from "./Colleagues/OrgColleagues";
import WorkColleagues from "./Colleagues/WorkColleagues";

const MyColleagues = ({ isCollapsed }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectableRowsPerPage = [12, 24, 36];
  const userData = user;
  const { homeOrgId, workingOrgId } = userData;
  const { communities = [] } = userData;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
        <OrgColleagues
          t={t}
          dispatch={dispatch}
          selectableRowsPerPage={selectableRowsPerPage}
          homeOrgId={homeOrgId}
          isCollapsed={isCollapsed}
        />
        <WorkColleagues
          t={t}
          dispatch={dispatch}
          selectableRowsPerPage={selectableRowsPerPage}
          workingOrgId={workingOrgId}
          isCollapsed={isCollapsed}
        />
      </Box>
    </>
  );
};

export default MyColleagues;
