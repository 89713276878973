import { Typography } from '@mui/material'
import { AliceIcon } from '../../icon/aliceIcon/AliceIcon'
import { IconWrapper } from '../../icon/iconWrapper/IconWrapper'

export const StepIcon = ({
  step,
  index,
  isMobile,
  isStepComplete,
  isDone,
  activeStep,
  steps
}) => {
  const isCompleted = isStepComplete(index)

  const iconType = isCompleted
    ? 'StepperCheckmark'
    : step.iconType

  const isIconTypeString = typeof iconType === 'string'

  if (isMobile) {
    return !step.iconType && !iconType
      ? (
        <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
          {`${isDone ? steps?.length : activeStep}/${steps?.length}`}
        </Typography>
      )
      : isIconTypeString
        ? <IconWrapper size={24} color="primary.main"><AliceIcon iconType={iconType} /></IconWrapper>
        : <IconWrapper size={24} color="primary.main">{iconType}</IconWrapper>
  }

  return !step.iconType && !iconType
    ? <Typography variant="button" sx={{ lineHeight: 'unset' }}>{index + 1}</Typography>
    : isIconTypeString
      ? <AliceIcon iconType={iconType} />
      : iconType
}
