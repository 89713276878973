import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { TextField } from "@alice/component_library";
import StatusRoleConfig from "@components/StatusRoleConfiguration";

import {
  CandRMainContainer,
  CandRHeader,
  ConditionMainContainer,
  ConditionHeader,
  CandRHeaderTypo,
  ConditionCheckBoxs,
  TandCMainContainer,
  CheckboxLabel,
  RequestMainContainer,
  RequestReasonHeader,
  ReasonFooter,
} from "../../styledComponents";

const ConditionsAndReason = ({
  isTermRequired,
  data,
  saveReasonHandler = () => {},
  footerRef,
}) => {
  const { t } = useTranslation();

  const handleSaveReason = (value) => {
    // Save the reason here
    saveReasonHandler(value);
  };

  const [comment, setComment] = useState(data);
  const [reasonStatus, setReasonStatus] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const [isAgbsChecked, setIsAgbsChecked] = useState(false);
  const REASON_CHARACTER_LIMIT = 400;

  const handleTermsChange = (e) => {
    setIsTermsChecked(e.target.checked);
  };

  const handleAgbsChange = (e) => {
    setIsAgbsChecked(e.target.checked);
  };

  useEffect(() => {
    footerRef.current.updateFooternextVisibility(
      data.length > 19 ? false : true
    );
  }, [data]);

  useEffect(() => {
    const textCount = comment.trim().length;
    handleSaveReason(comment.trim());
    setTextCount(textCount);
    setReasonStatus(textCount > 19 ? "success" : "fail");
    footerRef.current.updateFooternextVisibility(textCount > 19 ? false : true);
  }, [comment]);

  const checkboxStatus = isTermsChecked && isAgbsChecked && "success";

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    // Call the save reason function here
  };

  return (
    <>
      <CandRMainContainer>
        <Box>
          <CandRHeader>{t("conditions-reason")}</CandRHeader>
        </Box>
        <ConditionMainContainer>
          <TandCMainContainer>
            {isTermRequired && (
              <>
                <ConditionHeader>
                  <CandRHeaderTypo>{t("terms-and-conditions")}</CandRHeaderTypo>
                  <StatusRoleConfig status={checkboxStatus} />
                </ConditionHeader>
                <ConditionCheckBoxs>
                  <FormControlLabel
                    onChange={handleTermsChange}
                    control={<Checkbox />}
                    label={
                      <CheckboxLabel>
                        <Trans
                          i18nKey="i-accept-the-terms-and-conditions"
                          components={{ 1: <Link /> }}
                        />
                      </CheckboxLabel>
                    }
                    sx={{ width: "fit-content" }}
                  />
                  <FormControlLabel
                    onChange={handleAgbsChange}
                    control={<Checkbox />}
                    label={
                      <CheckboxLabel>
                        <Trans
                          i18nKey="i-accept-the-agbs"
                          components={{ 1: <Link /> }}
                        />
                      </CheckboxLabel>
                    }
                    sx={{ width: "fit-content" }}
                  />
                </ConditionCheckBoxs>
              </>
            )}
          </TandCMainContainer>
          <RequestMainContainer>
            <RequestReasonHeader>
              <CandRHeaderTypo>{t("request-reason")}</CandRHeaderTypo>
              <StatusRoleConfig status={reasonStatus} />
            </RequestReasonHeader>
            <Box>
              <TextField
                label={t("enter-app-res")}
                rows={4}
                value={comment}
                fullWidth
                multiline
                variant="filled"
                maxLength={REASON_CHARACTER_LIMIT}
                inputProps={{
                  maxLength: REASON_CHARACTER_LIMIT,
                }}
                error={
                  comment && comment.length === Number(REASON_CHARACTER_LIMIT)
                    ? true
                    : false
                }
                onChange={handleCommentChange}
                onBlur={(e) => setComment(e.target.value.trim())}
              />
              <ReasonFooter>
                <span>{t("min-20-chars-requeired-info")}</span>
                <span>
                  {textCount} / {REASON_CHARACTER_LIMIT}
                </span>
              </ReasonFooter>
            </Box>
          </RequestMainContainer>
        </ConditionMainContainer>
      </CandRMainContainer>
    </>
  );
};

export default ConditionsAndReason;
