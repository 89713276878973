import React from "react";
import { Box, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export function CircularProgressComponent({ variant, size = 40, ...props }) {
  const isDeterminate = variant === "determinate" || variant === "loading";
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        {...props}
        variant={variant === "determinate" ? "determinate" : "indeterminate"}
        size={isDeterminate ? 68 : size}
      />
      {isDeterminate ? (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            component="div"
            color="#333333"
            sx={{ fontWeight: "bold" }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      ) : null}
    </Box>
  );
}

export const Spinner = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  color: #ffffff;
  ${({ isSecondary }) => (isSecondary ? "color: #000;" : "")}
`;
