/* eslint-disable camelcase */

import i18n_de_DE from './de_DE.json'
import i18n_en_US from './en_US.json'
import i18n_es_ES from './es_ES.json'
import i18n_fr_FR from './fr_FR.json'
import i18n_hu_HU from './hu_HU.json'
import i18n_it_IT from './it_IT.json'
import i18n_ja_JP from './ja_JP.json'
import i18n_ko_KR from './ko_KR.json'
import i18n_nl_NL from './nl_NL.json'
import i18n_pl_PL from './pl_PL.json'
import i18n_pt_PT from './pt_PT.json'
import i18n_ru_RU from './ru_RU.json'
import i18n_tr_TR from './tr_TR.json'
import i18n_zh_CN from './zh_CN.json'

export {
  i18n_de_DE,
  i18n_en_US,
  i18n_es_ES,
  i18n_fr_FR,
  i18n_hu_HU,
  i18n_it_IT,
  i18n_ja_JP,
  i18n_ko_KR,
  i18n_nl_NL,
  i18n_pl_PL,
  i18n_pt_PT,
  i18n_ru_RU,
  i18n_tr_TR,
  i18n_zh_CN
}
