import React from "react";
import { useSelector } from "react-redux";
import { GetLatestTaskActiviy } from "@actions";
import TasksIcon from "@Icons/homePage/tasks.svg";
import NoTasksIcon from "@Icons/homePage/noActiveTasks.svg";
import { useWidth } from "@utils/hooks";
import useFetchData from "./sharedComponents/useFetchData";
import useFormatAndChunkData from "./sharedComponents/useFormatAndChunkData";
import TaskAndRequest from "./sharedComponents/TaskAndRequest";

const LatestTask = ({ userData = {} }) => {
  const userId = userData.id;
  const { loading, latestTaskActivity, isLoaded } = useSelector(
    (state) => state.task?.latestTaskActivity
  );
  const widthPanel = useWidth();

  const fetchData = useFetchData(
    GetLatestTaskActiviy,
    userId,
    isLoaded,
    loading
  );
  const data = useFormatAndChunkData(latestTaskActivity, widthPanel);

  return (
    <TaskAndRequest
      heading="tasks"
      subHeading="latest-task-activity"
      noDataText="no-task-activity"
      linkButtonText="go-to-tasks"
      headerIcon={<TasksIcon />}
      noDataIcon={NoTasksIcon}
      activityData={data}
      dataFromApi={latestTaskActivity}
      fetchData={fetchData}
      loading={loading}
      isRequest={false}
    />
  );
};

export default LatestTask;
