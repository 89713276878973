import React from "react";

const SucessIcon = () => {
  return (
    <span>
      <svg
        width="62px"
        height="62px"
        viewBox="0 0 62 62"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="New-Request"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Success" transform="translate(-188.000000, -274.000000)">
            <g id="New-Req" transform="translate(188.000000, 274.000000)">
              <rect id="Rectangle" x="0" y="0" width="62" height="62"></rect>
              <g
                transform="translate(7.750000, 7.750000)"
                fill="#198025"
                fill-rule="nonzero"
                id="Path"
              >
                <path d="M4.35937409,40.6874915 C1.95493709,40.6874915 0,38.7325544 0,36.3281174 L0,4.35937409 C0,1.95493709 1.95493709,0 4.35937409,0 L33.421868,0 C35.826305,0 37.7812421,1.95493709 37.7812421,4.35937409 L37.7812421,17.4374964 C37.7812421,18.2396212 37.1302423,18.8906211 36.3281174,18.8906211 C35.5259926,18.8906211 34.8749927,18.2396212 34.8749927,17.4374964 L34.8749927,4.35937409 C34.8749927,3.55724926 34.2239929,2.90624939 33.421868,2.90624939 L4.35937409,2.90624939 C3.55724926,2.90624939 2.90624939,3.55724926 2.90624939,4.35937409 L2.90624939,36.3281174 C2.90624939,37.1302423 3.55724926,37.7812421 4.35937409,37.7812421 L15.9843717,37.7812421 C16.7864965,37.7812421 17.4374964,38.432242 17.4374964,39.2343668 C17.4374964,40.0364917 16.784559,40.6874915 15.9843717,40.6874915 L4.35937409,40.6874915 Z"></path>
                <path d="M15.9843717,11.6249976 C15.1822468,11.6249976 14.531247,10.9739977 14.531247,10.1718729 C14.531247,9.36974805 15.1822468,8.71874818 15.9843717,8.71874818 L27.6093692,8.71874818 C28.4114941,8.71874818 29.0624939,9.36974805 29.0624939,10.1718729 C29.0624939,10.9739977 28.4095566,11.6249976 27.6093692,11.6249976 L15.9843717,11.6249976 Z"></path>
                <path d="M10.1718729,20.3437458 C9.36974805,20.3437458 8.71874818,19.6927459 8.71874818,18.8906211 C8.71874818,18.0884962 9.36974805,17.4374964 10.1718729,17.4374964 L27.6093692,17.4374964 C28.4114941,17.4374964 29.0624939,18.0884962 29.0624939,18.8906211 C29.0624939,19.6927459 28.4114941,20.3437458 27.6093692,20.3437458 L10.1718729,20.3437458 Z"></path>
                <path d="M9.80208152,29.0624939 C9.20408152,29.0624939 8.71874818,28.4114941 8.71874818,27.6093692 C8.71874818,26.8072444 9.20408152,26.1562445 9.80208152,26.1562445 L20.6354148,26.1562445 C21.2334148,26.1562445 21.7187482,26.8072444 21.7187482,27.6093692 C21.7187482,28.4114941 21.2319704,29.0624939 20.6354148,29.0624939 L9.80208152,29.0624939 Z"></path>
              </g>
              <circle
                id="Oval"
                stroke="#198025"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                cx="43.4527778"
                cy="45.4527778"
                r="13.0888889"
              ></circle>
              <path
                d="M49.2860593,41.6460926 L42.9488556,50.0971519 C42.6637415,50.4763457 42.2282491,50.713324 41.7550076,50.7467982 C41.2817661,50.7802724 40.8172519,50.6069551 40.4816,50.2716704 L37.2093778,46.9994481"
                id="Path"
                stroke="#198025"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default SucessIcon;
