import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Accordion, AccordionSummary } from '../../accordion'
import { IconWrapper } from '../../icon'

export const AgreementPanel = ({ text, title }) => {
  const subtitle = text.split('\n')[0]
  const aText = text.replace(subtitle, '').split('\n')

  return (
    <Accordion
      defaultExpanded={false}
      header={(
        <AccordionSummary
          expandIcon={<IconWrapper size={32}><KeyboardArrowDown /></IconWrapper>}
          sx={{
            minHeight: 'fit-content !important',
            height: 'fit-content !important'
          }}
        >
          <Stack>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {title}
            </Typography>

            <Typography>{subtitle}</Typography>
          </Stack>
        </AccordionSummary>
      )}
    >
      {aText.map((ele, i) => <p key={i}>{ele}</p>)}
    </Accordion>
  )
}

AgreementPanel.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
