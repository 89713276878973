import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link } from '../../link'
import { Button } from '../button/Button'

/**
 * A simple [button](/?path=/docs/alice-ui-buttons-button--docs) that acts as a [Link](./?path=/docs/alice-ui-link--docs). For more documentation see: https://mui.com/material-ui/api/button/.<br>
 * All props from the [button](/?path=/docs/alice-ui-buttons-button--docs) are available as usual.
 *
 * Use the primary button for the main action of the page.<br>
 * The secondary button is for all other actions on the page.<br>
 * The tertiary button is for very rarely used functions.
 */
export const LinkButton = forwardRef((
  {
    isUsingRouterIfPossible = true,
    href,
    isBlank = true,
    children,
    ...otherProps
  },
  ref
) => (
  <Button
    ref={ref}
    component={Link}
    isUsingRouterIfPossible={isUsingRouterIfPossible}
    href={href}
    isBlank={isBlank}
    {...otherProps}
  >
    { children }
  </Button>
))

LinkButton.propTypes = {
  /** Prefers using React-Router-Dom routing over browser routing */
  isUsingRouterIfPossible: PropTypes.bool,
  /** Determines the style of the button */
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'tertiary' ]),
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Target URL */
  href: PropTypes.string.isRequired,
  /** Determines if page getting opened in a new tab or not */
  isBlank: PropTypes.bool,
  /** Add some additional styling to the button */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
