import { SupportAgent } from '@mui/icons-material'
import { ListEntry } from './ListEntry'
import { IconWrapper } from '../icon/iconWrapper/IconWrapper'

export const SupportLink = ({ size }) => (
  <ListEntry
    data-testid="SupportLink"
    name="support"
    path="/access/support"
    icon={() => (
      <IconWrapper
        color={'inherit'}
        size={size === 'small' ? 24 : 18}
      >
        <SupportAgent />
      </IconWrapper>
    )}
    size={size}
  />
)
