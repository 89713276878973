import React from "react";
import SelfRequestCard from "./SelfRequestCard";
// import NewReqCard from "@components/NewRequestCard";
// import { ReactComponent as NewSelfReqIcon } from "@Icons/newRequestPage/NewSelfReqIcon.svg";

const MyselfRoleRequest = ({ userData }) => {
  return <SelfRequestCard userData={userData} />;
};

export default MyselfRoleRequest;
