import React, { Fragment, useEffect, useState } from "react";
import "@src/App.css";
import "@src/Icon.css";
import "./summary.css";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import EditIcon from "@pageIcons/roles/editIcon";
import Loader from "@components/Loader";
import { PrimaryButton, SecondaryButton } from "@components/button";
import { RoleCardV2 } from "@components/roleElements/RoleCardV2";

import {
  Card,
  UserAvatar,
  UserContextPopover,
  useUser,
} from "@alice/component_library";
import { grey } from "@mercedes-benz/mui5-theme";
import { hasData } from "@utils/Validator";

import SucessIcon from "@pageIcons/summary/successIcon";
import ErrorIcon from "@pageIcons/summary/errorIcon";
import { user } from "@alice/component_library";

import { IconButtonText } from "@alice/component_library";
import { useWidth } from "@utils/hooks";
import { DayLabel } from "@components/sharedComponentTranslation";
import { ConfirmationSkeleton } from "@src/sharedComponents/Skeleton/CommonSkeletons";

const Subcontainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "0 20px",
  },
}));

const ButtonContainer = styled("div")({
  display: "flex",
  padding: "20px 0",
  marginBottom: "100px",
});

const SubContainerGrid = styled(Grid)({
  display: "flex",
  padding: "20px 0",
  marginBottom: "100px",
});

const UserDetails = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginLeft: "17px",
});

const SubcontainerSelectedRole = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "0 20px",
  },
}));

function Summary(props) {
  const { t, i18n } = useTranslation();

  const costData = useSelector((state) => state.user.costData);
  const selectedUser = useSelector((state) => state.user.selectedUserData);
  const selectedRoles = useSelector((state) => state.role.selectedRoles);

  const { user } = useUser();
  const loginUser = user ?? {};

  const submitResponse = useSelector((state) => state.role.newRequest);
  const [isSmall, setIsSmall] = useState(false);

  const navigate = useNavigate();

  const users = !hasData(selectedUser)
    ? loginUser.surname + ", " + loginUser.givenname
    : selectedUser.surname + ", " + selectedUser.givenname;

  const selected = !hasData(selectedUser) ? loginUser : selectedUser;

  const deptNo = !hasData(selectedUser)
    ? loginUser.departmentNumber
    : selectedUser.departmentNumber;

  const userId = !hasData(selectedUser) ? loginUser.id : selectedUser.id;
  const selectedRolesForRoleCard = selectedRoles.flatMap(
    ({ selectedScopes, name, id, ...allKeys }) =>
      selectedScopes && selectedScopes.length
        ? selectedScopes.map((orgScope) => ({
            ...allKeys,
            roleDefinition: { name: name, id: id },
            ...(orgScope && {
              orgScope: { name: orgScope.orgScope, id: orgScope.orgId },
            }),
            ...(orgScope.custScope && {
              customScope: { name: orgScope.custScope },
            }),
          }))
        : [{ ...allKeys, roleDefinition: { name: name, id: id } }]
  );

  const widthPanel = useWidth();

  useEffect(() => {
    switch (widthPanel) {
      case "sm":
      case "xs":
      case "md":
      case "lg":
        setIsSmall(true);
        break;
      case "xl":
        setIsSmall(false);
        break;
      default: {
        setIsSmall(true);
      }
    }
  }, [widthPanel]);

  if (submitResponse.loading) return <ConfirmationSkeleton />;

  const getRequestInfoLabel = (assigned) => (
    <div classname="mt-10 mb-10">
      <p className="desc-confirm-text2">
        <span>{t("you_follow_status_of_request_under")} </span>
        <span> {`${t("requests")}`}</span>
        {i18n.language === "de" && <span> {t("request_summary_success")}</span>}
        .
      </p>
      {!hasData(assigned) && (
        <p style={{ marginTop: "40px", marginBottom: "40px" }}>
          <span style={{ marginTop: "40px", display: "flex" }}>
            <PrimaryButton
              onButtonClick={(e) => navigate("/access/requests?reload=true")}
              label={t("show_all_requests")}
              style={{ marginRight: "30px" }}
            />
            <SecondaryButton
              onButtonClick={() => {
                props.resetHandler(1);
              }}
              label={t("new_request")}
            />
          </span>
        </p>
      )}
    </div>
  );

  const getAssignedInfoLabel = () => (
    <div classname="mt-10 mb-10">
      <p className="desc-confirm-text2">
        <span>
          {t("you_can_see_your_assigned_roles_under")} {t("my_profile")}{" "}
        </span>
        {i18n.language === "de" && <span> {t("assign_summary_success")}</span>}.
      </p>

      <p style={{ marginTop: "40px", display: "flex" }}>
        <span>
          <PrimaryButton
            onButtonClick={(e) => navigate("/access/profile/overview")}
            label={t("my_profile")}
            style={{ marginRight: "30px" }}
          />
        </span>

        <span>
          <PrimaryButton
            onButtonClick={(e) => navigate("/access/requests?reload=true")}
            label={t("show_all_requests")}
            style={{ marginRight: "30px" }}
          />
        </span>

        <span>
          <SecondaryButton
            onButtonClick={() => props.resetHandler(1)}
            label={t("new_request")}
          />
        </span>
      </p>
    </div>
  );

  const getOrgs = (d = {}) => {
    let { affectedData = {} } = d;
    let { orgScopes = [], customScopes = [] } = affectedData;

    if (hasData(orgScopes) || hasData(customScopes)) {
      const orgs = orgScopes.map((d) => d.name).toString();
      const cOrgs = customScopes.map((d) => d.name).toString();

      let temp = hasData(orgScopes) ? orgs : "";
      temp += hasData(orgScopes) && hasData(customScopes) ? `, ` : "";
      temp += cOrgs;
      return `(${temp})`;
    }
    return "";
  };

  const getFailedDetails = (failed) => (
    <div style={{ marginTop: "80px" }}>
      <ErrorIcon />
      <p className="main-confirm-text">
        {t("your_requests_have_been_Failed")}:
      </p>
      <div>{failed}</div>
    </div>
  );

  const getRequestDetails = (requests) => (
    <div>
      <div>{requests}</div>
    </div>
  );

  const getAssignDetails = (assigned) => (
    <div>
      <div>{assigned}</div>
    </div>
  );

  const getFailedInfoLabel = () => {
    return (
      <div className="mt-10 mb-10">
        <p className="desc-confirm-text2 mt-10 mb-10">
          {t("something_went_wrong_and_your_request_could_not_be_created")}
        </p>
        <p className="desc-confirm-text2">
          <span>{t("please_try_again_by_selecting")} </span>
          <span> {t("confirm_new_request")}</span>
        </p>
        <p className="desc-confirm-text2">
          {t(
            "if_the_problem_persists_contact_the_suport_for_further_assistance"
          )}
        </p>

        <p style={{ marginTop: "40px", display: "flex" }}>
          <span>
            <PrimaryButton
              onButtonClick={() => props.resetHandler(1)}
              label={t("new_request")}
              style={{ marginRight: "30px" }}
            />
          </span>
          <span>
            <SecondaryButton
              variant="outlined"
              color="inherit"
              onButtonClick={() => navigate("/access/support")}
              label={t("contact_support")}
            />
          </span>
        </p>
      </div>
    );
  };

  const getSummary = () => {
    const { data } = submitResponse;

    let requests = [];
    let assigned = [];
    let failed = [];

    data.forEach((d) => {
      const { id, assignee = "" } = d;
      const { roleName = "", error = false, code = "" } = d;

      if ([201].includes(code))
        requests.push(
          <div>
            <p>
              {t("task")}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {id} {roleName} {getOrgs(d)}{" "}
              </span>
              {t("has_been_created_for")}{" "}
              <span style={{ fontWeight: "bold" }}> {assignee}</span>
              {"."}
            </p>
          </div>
        );

      if ([200, 204].includes(code))
        assigned.push(
          <div>
            <p className="desc-confirm-text2 " style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>{roleName}</span>{" "}
              {t("was_directly_assigned_to")}{" "}
              <span style={{ fontWeight: "bold" }}>{assignee}</span>
            </p>
          </div>
        );

      if (error) failed.push(getSummaryError(d));
    });

    let label = t("your_requests_have_been_created");
    if (!hasData(requests) && hasData(assigned))
      label = t("the_requested_role_has_been_directly_assigned");

    return (
      <Subcontainer>
        {hasData(requests) || hasData(assigned) ? (
          <>
            <SucessIcon />
            <p className="main-confirm-text">{label}</p>

            {hasData(requests) ? (
              <div style={{ margin: "30px 0" }}>
                {getRequestDetails(requests)}
                {getRequestInfoLabel(assigned)}
              </div>
            ) : null}

            {hasData(assigned) ? (
              <div style={{ margin: "30px 0" }}>
                {getAssignDetails(assigned)}
                {getAssignedInfoLabel()}
              </div>
            ) : null}
          </>
        ) : null}

        {hasData(failed) ? (
          <div style={{ margin: "30px 0" }}>
            {getFailedDetails(failed)}
            {getFailedInfoLabel()}
          </div>
        ) : null}

        {getActionButtons(requests, assigned, failed)}
      </Subcontainer>
    );
  };

  const getSummaryError = (d) => {
    return (
      <Fragment>
        <p className="desc-confirm-text2" style={{ marginBottom: "10px" }}>
          <span className="summaryRequest">
            {t("assignment_of_role")}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {d.roleName} {getOrgs(d)}{" "}
            </span>
            {t("to_user")}{" "}
            <span style={{ fontWeight: "bold" }}> {d.assignee} </span>
            {t("confirmation_failed")}
          </span>
        </p>
      </Fragment>
    );
  };

  const getActionButtons = (request, assigned, failed) => {
    return <ButtonContainer></ButtonContainer>;
  };

  const getSummaryDetails = () => {
    return (
      <div>
        <br />
        <br />
        <Subcontainer
          style={{ justifyContent: "space-between" }}
          className="flex mb-16"
        >
          <div className="add_info">{t("app_role_for")}</div>
          <div className="font-16">
            <span className="cursor-pointer dp" id="user">
              <IconButtonText
                text={t("go_to_usr_selection")}
                icon={<EditIcon sx={{ color: grey[20] }} />}
                onClick={() => props.updateStep(1)}
                sx={{ color: grey[20] }}
                color="#333333"
              />
            </span>
          </div>
        </Subcontainer>

        <Grid>
          <SubContainerGrid
            item
            sm={6}
            md={6}
            lg={4}
            xl={4}
            sx={{ margin: "0px" }}
          >
            <Card>
              <div style={{ display: "flex", padding: "20px" }}>
                <div className="mt-5">
                  <UserContextPopover id={userId}>
                    <UserAvatar
                      userId={selected.id}
                      userName={`${selected.givenname} ${selected.surname}`}
                      groupType={selected.groupType}
                      showGroupType
                      showImage={user.is.internalEmployee(
                        selected?.communities
                      )}
                    />
                  </UserContextPopover>
                </div>
                <UserDetails>
                  <span className="bold">{users}</span>
                  <span className="font-16">
                    {!hasData(selectedUser)
                      ? loginUser.mailAddress
                      : selectedUser.mailAddress}
                  </span>
                  <span className="font-16">
                    {deptNo ? userId + " (" + deptNo + ")" : userId}
                  </span>
                </UserDetails>
              </div>
            </Card>
          </SubContainerGrid>
        </Grid>

        <Subcontainer
          style={{ justifyContent: "space-between" }}
          className="flex mb-16 mt-40"
        >
          <div className="add_info">{t("selected_roles")}</div>
          <div className="font-16 image-text-align">
            <IconButtonText
              text={t("go_to_role_selection")}
              icon={<EditIcon sx={{ color: grey[20] }} />}
              onClick={() => props.updateStep(2)}
              color="#333333"
            />
          </div>
        </Subcontainer>

        <SubcontainerSelectedRole container spacing={2}>
          {selectedRolesForRoleCard &&
            selectedRolesForRoleCard.map((elem, index) => (
              <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                <RoleCardV2
                  data={elem}
                  hideActions={true}
                  size={isSmall ? "small" : "medium"}
                  daysLeftMessage={DayLabel()}
                  isFromSummarryPage={true}
                />
              </Grid>
            ))}
        </SubcontainerSelectedRole>
        <Subcontainer
          style={{ justifyContent: "space-between" }}
          className="flex mb-16 mt-40"
        >
          <div className="add_info">{t("additional_Information")}</div>
          <div className="font-16 image-text-align">
            <IconButtonText
              text={t("go_to_add_info")}
              icon={<EditIcon sx={{ color: grey[20] }} />}
              onClick={() => props.updateStep(3)}
              color="#333333"
            />
          </div>
        </Subcontainer>
        <SubContainerGrid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="summary-info mb-20 summary-bg"
        >
          <div className="summary-Text ">{t("role_add_info_reason")}</div>{" "}
          <span
            className="summary-content"
            style={{
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {costData.trim()}
          </span>
        </SubContainerGrid>
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {props.type ? (
        <div>
          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
          <div className="confirm-text">
            <div className="comfirm-box">{getSummary()}</div>
          </div>
        </div>
      ) : (
        getSummaryDetails()
      )}
    </div>
  );
}

export default Summary;
