import { Box, Card, Tooltip, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { mergeSxProps } from '../../util'
import { Dialog } from '../dialog'

export const ContextPopoverCard = ({ children, sx }) => (
  <Card
    sx={mergeSxProps(
      {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        boxShadow: 10,
        borderRadius: 1,
        maxWidth: '350px',
        width: '350px'
      },
      sx
    )}
  >
    {children}
  </Card>
)

/**
 * Generic context popover component
 *
 * This component displays more information of a desired component on hover.
 */
export const GenericContextPopover = ({
  context,
  children,
  enterDelay = 1000,
  leaveDelay = 200,
  placement = 'right',
  onClose = () => {},
  onOpen = () => {},
  sx = [],
  ...otherProps
}) => {
  const [ isMobileDialogOpen, setIsMobileDialogOpen ] = useState(false)

  const isUsingDialog = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const handleClick = (e) => e.preventDefault()

  const handleOpenClick = (e) => {
    e.stopPropagation()

    if (!isUsingDialog) return

    setIsMobileDialogOpen(true)
    onOpen()
  }

  const handleCloseClick = () => {
    setIsMobileDialogOpen(false)
    onClose()
  }

  return (
    <>
      <Tooltip
        data-testid="GenericContextPopover"
        placement={placement}
        enterDelay={enterDelay}
        enterNextDelay={enterDelay}
        leaveDelay={leaveDelay}
        disabled={isUsingDialog}
        onOpen={onOpen}
        onClose={onClose}
        sx={{ width: 'fit-content' }}
        componentsProps={{
          tooltip: {
            sx: [
              {
                padding: 0,
                maxWidth: 'initial'
              }
            ]
          }
        }}
        PopperProps={{ onClick: handleClick }}
        title={<ContextPopoverCard sx={sx}>{context}</ContextPopoverCard>}
        {...otherProps}
      >
        <Box onClick={handleOpenClick}>
          {children}
        </Box>
      </Tooltip>

      { isUsingDialog && (
        <Dialog
          open={isMobileDialogOpen}
          onClose={handleCloseClick}
        >
          <ContextPopoverCard
            sx={{
              p: 0,
              boxShadow: 0,
              borderRadius: 0,
              maxWidth: 'unset',
              width: 'unset',
              minWidth: '350px',
              ...sx
            }}
          >
            {context}
          </ContextPopoverCard>
        </Dialog>
      ) }
    </>
  )
}

GenericContextPopover.propTypes = {
  /** The component that should be displayed in popover */
  context: PropTypes.node.isRequired,
  /** Placement of the context */
  placement: PropTypes.oneOf([ 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' ]),
  /** The component that should be hover-able */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Delay before the tooltip appears */
  enterDelay: PropTypes.number,
  /** Delay before the tooltip disappears after mouseOut */
  leaveDelay: PropTypes.number
}
