import { includesString } from '../../../../util'

export const LocalAdapter = {
  async getData(data, pageNumber, pageSize, activeSearch, activeSort, activeFilters) {
    let localData = data
    let totalCount = data.length

    const startIndex = (pageNumber) * pageSize
    const endIndex = startIndex + pageSize

    const sortData = (array, activeSort) => array.sort((a, b) => {
      const sortOrder = activeSort.startsWith('-') ? -1 : 1
      const sortField = activeSort.slice(1)

      return (a[sortField] < b[sortField] ? -1 : (a[sortField] > b[sortField] ? 1 : 0)) * sortOrder
    })

    // eslint-disable-next-line max-len
    const filterData = (array, activeFilters) => {
      localData = array.filter((item) => Object.entries(activeFilters).every(([ key, filterValues ]) => {
        // Skip filtering for empty filter values
        if (filterValues.length === 0) return true

        if (key === 'isActive' || key === 'archived') {
          const isActiveFilter = filterValues[0].toLowerCase() === 'true'
          return item[key] === isActiveFilter
        }

        if (key === 'community' && item.communities) { // Check if key is 'community' and item has 'communities' property
          return filterValues.some((filterVal) => item.communities.includes(filterVal))
        }

        if (Array.isArray(item[key])) {
          return filterValues.some((filterVal) => item[key].includes(filterVal))
        }

        return filterValues.includes(item[key])
      }))

      totalCount = localData.length
    }

    const searchData = (array, activeSearch) => {
      const searchTerm = activeSearch.toLowerCase()

      localData = array.filter((item) => includesString(item, searchTerm))
      totalCount = localData.length
    }

    if (activeSearch) searchData(data, activeSearch)

    if (Object.keys(activeFilters).length > 0) filterData(data, activeFilters)

    const result = sortData(localData, activeSort).slice(startIndex, endIndex)

    return {
      data: {
        data: result,
        rels: {
          totalCount,
          offset: pageNumber,
          limit: pageSize
        },
        url: null
      }
    }
  }
}
