import React from "react";
import { AppCardSelected } from "./styledComponent";
import { Typography, Divider } from "@mui/material";
import { AppLogo, IconButton, Tooltip } from "@alice/component_library";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";

const SelectedAppCard = ({
  handleSelectApplication,
  selectedApplication = {},
}) => {
  const { t } = useTranslation();
  const { id, logo, name } = selectedApplication;
  return (
    <AppCardSelected>
      <div className="app-details">
        <AppLogo size="small" logo={logo} />
        <div className="header-name">
          <div className="app-card-header">
            <Typography variant="caption" className="id-holder">
              {t("id")}
            </Typography>
            <Tooltip type="ellipsized" title={id}>
              <Typography variant="subtitle1" className="app-card-id" noWrap>
                {id}
              </Typography>
            </Tooltip>
          </div>
          <Tooltip type="ellipsized" title={name}>
            <Typography variant="body1" className="app-card-name" noWrap>
              {name}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <div className="remove-icon">
        <Divider orientation="vertical" />
        <IconButton onClick={() => handleSelectApplication({})}>
          <CancelOutlinedIcon />
        </IconButton>
      </div>
    </AppCardSelected>
  );
};

export default SelectedAppCard;
