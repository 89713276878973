import { useEffect, useState } from 'react'
import { ScrollShadow } from '../../scrollShadow'

// Styles
import style from './Dropdown.module.scss'

export const Dropdown = ({
  setDropdown,
  onClick,
  children
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const scrollableElement = document.getElementById('scrollable')
      scrollableElement.scrollTop = scrollableElement.scrollHeight
    }
  }, [ isOpen ])

  const generatedYears = () => {
    const currentYear = new Date().getFullYear()
    const min = currentYear - 15
    const max = currentYear + 15
    const years = []

    for (let i = max; i >= min; i -= 1) {
      years.push(i)
    }

    return years.reverse()
  }

  const isCurrentYear = (year) => {
    const currentYear = new Date().getFullYear()

    return year === currentYear
  }

  const onHandleOpen = () => {
    setDropdown(!isOpen)
    setIsOpen(!isOpen)
  }

  const onHandleYearSelect = (e) => {
    const year = e.target.value
    const currentMonth = new Date().getMonth()

    setDropdown(!isOpen)
    onClick(year, currentMonth)
    setIsOpen(!isOpen)
  }

  return (
    <>
      <button
        type="button"
        name="dropdownButton"
        className={style.dropdownButton}
        onClick={(e) => onHandleOpen(e)}
      >
        {children}
      </button>

      {isOpen && (
        <ScrollShadow
          id="scrollable"
          className={style.dropdownMenu}
          sx={{
            '&::-webkit-scrollbar': { width: '4px' },
            '&::-webkit-scrollbar-track': { boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: 'darkgrey' },
            '.ScrollShadowInner': {
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridAutoRows: '52px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0 16px'
            }
          }}
        >
          {generatedYears().map((year, index) => (
            <div className={style.dropdownMenuItem} key={index}>
              <button
                type="button"
                className={isCurrentYear(year) ? style.dropdownMenuItemButtonCurrent : style.dropdownMenuItemButton}
                value={year}
                onClick={(e) => onHandleYearSelect(e)}
              >
                {year}
              </button>
            </div>
          ))}
        </ScrollShadow>
      )}
    </>
  )
}
