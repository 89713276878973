import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { OrgCardContainer } from "./styledComponents";

const OrgAdditionCard = ({ icon, text, onClick = () => {} }) => {
  return (
    <OrgCardContainer onClick={onClick}>
      <div className="icon-container">{icon}</div>
      <div className="text-container">
        <AddCircleOutlineIcon /> <span className="container-text">{text}</span>
      </div>
    </OrgCardContainer>
  );
};

export default OrgAdditionCard;
