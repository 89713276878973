import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@alice/component_library";
import RoleDetails from "@components/RoleDetails/RoleDetails";

const DetailDialogue = ({ isOpen, role, closeHandler = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="OrgDetailDialog"
      open={isOpen}
      fullWidth
      maxWidth="md"
      headerSection={t("requestApproval_roleDetails")}
      onClose={closeHandler}
    >
      <RoleDetails role={role?.data?.roleDefinition} />
    </Dialog>
  );
};

export default DetailDialogue;
