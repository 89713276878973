import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AliceIconType } from '../../../icon'
import { Message } from '../../message/Message'

/**
 * Displays a internal server error (HTTP-Code 500) message
 */
export const InternalServerError = forwardRef((
  {
    sx = [],
    ...otherProps
  },
  ref
) => {
  const { t } = useTranslation('componentLibrary')

  return (
    <Message
      data-testid="InternalServerError"
      ref={ref}
      sx={sx}
      icon={AliceIconType.API_DOWN}
      title={t('message.internalServerErrorTitle')}
      description={t('message.internalServerErrorDescription')}
      {...otherProps}
    />
  )
})

InternalServerError.propTypes = {
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}
