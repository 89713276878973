import { useEffect, useState } from 'react'

export const useSelectableItems = ({
  isMultiselect = false,
  preselectedIds = [],
  onSelectionChange = (selectedIds) => {},
  // additional features
  items = [],
  itemIdKey = '',
  disabledIds = [],
  notSelectableIds = []
}) => {
  const [ selectedIds, setSelectedIds ] = useState(preselectedIds)
  const [ afterInit, setAfterInit ] = useState(false)

  const [ itemIds, setItemIds ] = useState([])
  const [ excludeIds, setExcludeIds ] = useState([])

  useEffect(() => {
    // to not fire onSelectionChange on init
    setAfterInit(true)
  }, [])

  useEffect(() => {
    afterInit && onSelectionChange(selectedIds)
    // eslint-disable-next-line
  }, [ onSelectionChange, selectedIds ])

  // get itemIds from items to check excludeIds against curr page
  useEffect(() => {
    if (items.length) setItemIds(items.map((item) => item[itemIdKey]))
  }, [ itemIdKey, items ])

  useEffect(() => {
    if (itemIds.length) setExcludeIds([ ...disabledIds, ...notSelectableIds ].filter((id) => itemIds.includes(id)))
  }, [ disabledIds, itemIds, notSelectableIds ])

  const updateSelection = (updateIds = [], isDeselect = false) => {
    if (!updateIds.length) return

    setSelectedIds((currIds) => {
      if (isDeselect) {
        return currIds.filter((currId) => updateIds.indexOf(currId) === -1)
      }

      if (isMultiselect) {
        const mergedIds = [ ...currIds, ...updateIds ]

        // remove duplicates
        return mergedIds.filter((id, index) => mergedIds.indexOf(id) === index)
      }

      // singleSelect
      return ([ updateIds[0] ])
    })
  }

  const onSelectAll = (isSelect) => {
    updateSelection(itemIds.filter((id) => !excludeIds.includes(id)), !isSelect)
  }

  const isSelectAllChecked = () => items.every((item) => [ ...selectedIds, ...excludeIds ].includes(item[itemIdKey]))

  const isSelectAllIndeterminate = () => {
    if (!selectedIds.length) return false

    let counter = excludeIds.filter((id) => !selectedIds.includes(id)).length

    items.forEach((item) => {
      if (selectedIds.includes(item[itemIdKey])) counter++
    })

    return counter !== 0 && counter !== items.length
  }

  return {
    selectedIds,
    updateSelection,
    onSelectAll,
    isSelectAllChecked,
    isSelectAllIndeterminate
  }
}
