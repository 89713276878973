import CardStatusHeader from "@src/sharedComponents/CardHeader/CardStatusHeader";
import RequestHeaderIcon from "./RequestHeaderIcon";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Calender from "@Icons/taskpage/calender.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";
import {
  StatusChips,
  RequestDeatilsContainer,
} from "@pages/requestPage/styledComponents";
import CustomRCard from "@src/sharedComponents/RequestCard/CustomRCard";
import Divider from "@mui/material/Divider";
import {
  UserAvatar,
  UserContextPopover,
  UserListPopover,
  Badge,
} from "@alice/component_library";
import RequestTypeCase from "./RequestTypeCase";
import { getTaskDateInfoText } from "@pages/requestPage/util";
import UserBadgeIcon from "@Icons/requestPage/user_badge.svg";

const StatusChip = ({ status = "PENDING", t, taskInfo }) => {
  switch (status) {
    case "PENDING":
      return (
        <StatusChips
          label={t("pending")}
          bgcolor="#B4DDFE"
          avatar={
            <>
              {taskInfo.recipients.length > 1 ? (
                <UserListPopover
                  users={taskInfo.recipients}
                  title={t("recipients")}
                >
                  <div
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "1px",
                      cursor: "pointer",
                    }}
                  >
                    <UserBadgeIcon />
                    <Badge
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "26px",
                        height: "18px",
                        justifyContent: "center",
                      }}
                      filled
                    >
                      {taskInfo.recipients.length}
                    </Badge>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <TimerOutlinedIcon
                    sx={{ color: "#0078D6", fontSize: "18px" }}
                  />
                </UserListPopover>
              ) : taskInfo.recipients.length === 1 ? (
                <>
                  <UserContextPopover id={taskInfo.recipients[0].id}>
                    <UserAvatar
                      userId={taskInfo.recipients[0].id}
                      userName={`${taskInfo.recipients[0].givenname} ${taskInfo.recipients[0].surname}`}
                      sizeStyle={{
                        marginLeft: "1px",
                        height: "22px",
                        width: "22px",
                        fontSize: "10px",
                      }}
                    />
                  </UserContextPopover>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <TimerOutlinedIcon
                    sx={{ color: "#0078D6", fontSize: "18px" }}
                  />
                </>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TimerOutlinedIcon
                    sx={{ color: "#0078D6", fontSize: "18px" }}
                  />
                </div>
              )}
            </>
          }
        />
      );
    case "COMPLETED":
      return (
        <StatusChips
          label={t("approved")}
          bgcolor="#BCE3C1"
          avatar={
            <>
              {taskInfo.aprooverId ? (
                <>
                  <UserContextPopover id={taskInfo.aprooverId}>
                    <UserAvatar
                      userId={taskInfo.aprooverId}
                      userName={taskInfo.aprooverId}
                      sizeStyle={{
                        marginLeft: "1px",
                        height: "22px",
                        width: "22px",
                        fontSize: "10px",
                      }}
                      showImage={true}
                    />
                  </UserContextPopover>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <CheckCircleOutlinedIcon sx={{ fontSize: "18px" }} />
                </>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlinedIcon sx={{ fontSize: "18px" }} />
                </div>
              )}
            </>
          }
        />
      );
    case "REJECTED":
      return (
        <StatusChips
          label={t("rejected")}
          bgcolor="#FFB7B7"
          avatar={
            <>
              {taskInfo.aprooverId ? (
                <>
                  <UserContextPopover id={taskInfo.aprooverId}>
                    <UserAvatar
                      userId={taskInfo.aprooverId}
                      userName={taskInfo.aprooverId}
                      sizeStyle={{
                        marginLeft: "1px",
                        height: "22px",
                        width: "22px",
                        fontSize: "10px",
                      }}
                      showImage={true}
                    />
                  </UserContextPopover>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <CancelOutlinedIcon sx={{ fontSize: "18px" }} />
                </>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CancelOutlinedIcon sx={{ fontSize: "18px" }} />
                </div>
              )}
            </>
          }
        />
      );
    case "EXPIRED":
      return (
        <StatusChips
          label={t("expired")}
          bgcolor="#BBB"
          avatar={
            <>
              {taskInfo.recipients.length >= 1 ? (
                <>
                  <div
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "1px",
                    }}
                  >
                    <UserBadgeIcon />
                    <Badge
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "26px",
                        height: "18px",
                        justifyContent: "center",
                      }}
                      filled
                    >
                      {taskInfo.recipients.length}
                    </Badge>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <CalendarMonthIcon sx={{ fontSize: "18px" }} />
                </>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthIcon sx={{ fontSize: "18px" }} />
                </div>
              )}
            </>
          }
        />
      );
    case "FAILED":
      return (
        <StatusChips
          label={t("failed")}
          bgcolor="#FDEB99"
          avatar={
            <>
              {taskInfo.recipients.length >= 1 ? (
                <>
                  <div
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "1px",
                    }}
                  >
                    <UserBadgeIcon />
                    <Badge
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "26px",
                        height: "18px",
                        justifyContent: "center",
                      }}
                      filled
                    >
                      {taskInfo.recipients.length}
                    </Badge>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <ErrorOutlineIcon sx={{ fontSize: "18px" }} />
                </>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ErrorOutlineIcon sx={{ fontSize: "18px" }} />
                </div>
              )}
            </>
          }
        />
      );
    default:
      return (
        <StatusChips
          label={t("pending")}
          bgcolor="#B4DDFE"
          avatar={
            <>
              {taskInfo.recipients.length >= 1 ? (
                <>
                  <div
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "1px",
                    }}
                  >
                    <UserBadgeIcon />
                    <Badge
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "26px",
                        height: "18px",
                        justifyContent: "center",
                      }}
                      filled
                    >
                      {taskInfo.recipients.length}
                    </Badge>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ margin: "4px" }}
                  />
                  <TimerOutlinedIcon
                    sx={{ color: "#0078D6", fontSize: "18px" }}
                  />
                </>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TimerOutlinedIcon
                    sx={{ color: "#0078D6", fontSize: "18px" }}
                  />
                </div>
              )}
            </>
          }
        />
      );
  }
};

const RequestDetailsData = ({
  taskInfo,
  onClose,
  windowSize,
  userId,
  deeplink,
}) => {
  const { t } = useTranslation();
  const requestName = t("requestApproval_requestId");
  const requestheadericon = (
    <RequestHeaderIcon taskType={taskInfo.taskType} size={24} />
  );
  let recipientList = [];
  const statusChip = (
    <StatusChip
      taskInfo={taskInfo}
      status={taskInfo.state}
      t={t}
      recipientList={recipientList}
    />
  );
  const calender_icon = <Calender />;
  const close_icon = <CloseOutlinedIcon />;
  const RequestInfoText = getTaskDateInfoText(taskInfo, t);

  return (
    <>
      <RequestDeatilsContainer>
        <CardStatusHeader
          t={t}
          requestName={requestName}
          requestheadericon={requestheadericon}
          statusChip={statusChip}
          calender_icon={calender_icon}
          close_icon={close_icon}
          onClose={onClose}
          taskInfo={taskInfo}
          RequestInfoText={RequestInfoText}
          deeplink={deeplink}
        />
        <CustomRCard taskInfo={taskInfo} />
        <RequestTypeCase
          taskInfo={taskInfo}
          t={t}
          isDeeplink={deeplink}
          windowSize={windowSize}
          loggedInUser={userId}
        />
      </RequestDeatilsContainer>
    </>
  );
};
export default RequestDetailsData;
