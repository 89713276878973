import { Skeleton as MuiSkeleton } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

/** Placeholder skeleton. Could be used while having loading phases for example. */
export const Skeleton = forwardRef((
  {
    variant = 'text',
    animation = 'wave',
    ...otherProps
  },
  ref
) => (
  <MuiSkeleton
    data-testid="Skeleton"
    ref={ref}
    variant={variant}
    animation={animation}
    {...otherProps}
  />
))

Skeleton.propTypes = {
  /** Variant of the skeleton. */
  variant: PropTypes.oneOf([ 'text', 'circular', 'rectangular', 'rounded' ]),
  /** Animation type of the skeleton. */
  animation: PropTypes.oneOf([ 'pulse', 'wave', false ])
}
