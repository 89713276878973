import React from "react";
import { styled } from "@mui/system";

// Define your styled components using Mui styled
const ProgressBarContainer = styled("div")({
  borderRadius: "0 4px 4px 0",
  position: "relative",
  width: "100%",
  height: "20px",
  backgroundColor: "rgba(0, 120, 214, 0.4)",
});

const ProgressFill = styled("div")(({ percentage }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: `${percentage}%`,
  backgroundColor: `#0078D6`,
}));

const PercentageText = styled("div")(({ ...props }) => ({
  position: "absolute",
  top: "-20px",
  right: 0,
  left: props.width < 100 && 0,
  // transform: "translateX(-50%)",
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: '"MB Corpo S Text"',
  fontSize: "14px",
  fontStyle: "normal",
  whiteSpace: "nowrap",
  lineHeight: "20px",
  "& .percentage-text": {
    fontWeight: 700,
  },
  "& .count-text": {
    fontWeight: 400,
  },
}));

// Usage
const ProgressBar = ({ percentage, count, width }) => {
  return (
    <ProgressBarContainer>
      <ProgressFill percentage={percentage} />
      <PercentageText width={width}>
        <span className="percentage-text">{`${percentage}%`}</span>
        <span className="count-text">{`(${count.processedAssignments}/${count.totalAssignments})`}</span>
      </PercentageText>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
