import React from "react";
import { Footer } from "@alice/component_library";
import { Grid } from "@mui/material";
import { Layout } from "@alice/component_library";

const FooterComponent = (props) => {
  return (
    <Grid container>
      <Layout>
        <Footer
          textStyle={{
            fontSize: 16,
            color: "#888",
          }}
        />
      </Layout>
    </Grid>
  );
};

export default FooterComponent;
