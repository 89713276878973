import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NewSelect } from '../../form'
import { PreselectedBehavior } from './GenericSearchConstants'

export const GenericSearchSort = ({
  variant = 'standard',
  possibleSorts = [],
  activeSort = possibleSorts[0]?.value,
  preselectedSort,
  preselectedSortBehavior,
  isFullWidth,
  onChange = () => {},
  ...otherProps
}) => {
  const { t } = useTranslation('componentLibrary')

  const handleSortChange = ({ target: { value } }) => onChange(value)

  return (
    <NewSelect
      data-testid="GenericSearchSort"
      variant={variant}
      items={possibleSorts}
      label={t('genericSearch.sort')}
      value={[ activeSort ]}
      disabled={preselectedSortBehavior === PreselectedBehavior.DISABLE && !!preselectedSort}
      isFullWidth={isFullWidth}
      onChange={handleSortChange}
      {...otherProps}
    />
  )
}

GenericSearchSort.propTypes = {
  variant: PropTypes.oneOf([ 'standard', 'outlined', 'filled' ]),
  possibleSorts: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })),
  activeSort: PropTypes.string,
  preselectedSort: PropTypes.string,
  preselectedSortBehavior: PropTypes.string,
  isFullWidth: PropTypes.bool,
  onChange: PropTypes.func
}
