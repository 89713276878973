import { AliceIcon, AliceIconType } from "@src/ComponentLibrary";
import RoleRequest from "@Icons/taskpage/TaskDeeplink/RoleRequest.svg";
import EntitlementToRole from "@Icons/taskpage/TaskDeeplink/EntitlementToRole.svg";
import OrgAdminPermission from "@Icons/taskpage/TaskDeeplink/OrgAdminPermission.svg";
import TechUserToApplication from "@Icons/taskpage/TaskDeeplink/TechUserToApplication.svg";
import RoleRequestIcon from "@Icons/taskpage/taskDetails/RoleRequest.svg";
import EntitlementToRoleIcon from "@Icons/taskpage/taskDetails/EntitlementToRole.svg";
import OrgAdminPermissionIcon from "@Icons/taskpage/taskDetails/OrgAdminPermission.svg";
import TechUserToApplicationIcon from "@Icons/taskpage/taskDetails/TechUserToApplication.svg";

const TaskHeaderIcon = ({
  taskType = "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT",
  size = 48,
  color = "#000000",
}) => {
  switch (taskType) {
    case "APPROVE_SELFREQUESTABLE_ROLE_ASSIGNMENT":
    case "ROLE_APPROVAL_WORKFLOW":
    case "APPROVE_ROLE_ASSIGNMENT":
      return size === 48 ? <RoleRequest /> : <RoleRequestIcon />;
    case "APPROVE_USER_CREATION":
      return (
        <AliceIcon
          iconType={AliceIconType.USER_CREATE}
          size={size}
          sx={{ pointerEvents: "none", cursor: "not-allowed" }}
          color={color}
        />
      );
    case "APPROVE_TECHUSER_TO_APPLICATION":
      return size === 48 ? (
        <TechUserToApplication />
      ) : (
        <TechUserToApplicationIcon />
      );
    case "APPROVE_ORG_ADMIN_PERMISSION":
      return size === 48 ? <OrgAdminPermission /> : <OrgAdminPermissionIcon />;
    case "APPROVE_ROLE_DEPROVISIONING":
      return (
        <AliceIcon
          iconType={AliceIconType.ROLE_DEPRO}
          size={size}
          sx={{ pointerEvents: "none", cursor: "not-allowed" }}
          color={color}
        />
      );
    case "APPROVE_USER_TO_ORGANIZATION":
      return (
        <AliceIcon
          iconType={AliceIconType.ASSIGN_USER_ORG}
          size={size}
          sx={{ pointerEvents: "none", cursor: "not-allowed" }}
          color={color}
        />
      );
    case "APPROVE_APPLICATIONROLE_TO_ROLE":
    case "ENTITLEMENT_TO_ROLE_APPROVAL":
      return size === 48 ? <EntitlementToRole /> : <EntitlementToRoleIcon />;
    default:
      return size === 48 ? <RoleRequest /> : <RoleRequestIcon />;
  }
};

export default TaskHeaderIcon;
