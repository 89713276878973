import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@src/ComponentLibrary";
import { generatedDateArray } from "./util";
import ProgressBar from "./ProgressBar";
import {
  DateConatiner,
  DateMonthLabel,
  DateMonthContainer,
  DateDatesContainer,
  Styledspan,
  StyledDivider,
  AccessReviewGraphContainer,
  DataGraphContainer,
  BlueIndicatorForCurrent,
  GreyLineIndicator,
  DataGraphDataContainer,
  InnerGraphContainer,
  AccessReviewWrapper,
} from "../../styledComponents";

const AccessReviewGraph = ({ componentData, count }) => {
  const next30daysArray = generatedDateArray();
  const [dateCollection, setDateCollection] = useState([]);
  const dateRefs = useRef({});
  const blueIndicatorRef = useRef(null);
  const absoluteEelementRed = useRef(null);

  useEffect(() => {
    if (
      dateRefs.current &&
      Object.keys(dateRefs.current).length > 0 &&
      blueIndicatorRef.current
    ) {
      const firstDateRef = dateRefs.current[Object.keys(dateRefs.current)[0]];
      const firstDateDetails = firstDateRef?.getBoundingClientRect();
      const containerRefDetails =
        firstDateRef.offsetParent?.getBoundingClientRect();
      blueIndicatorRef.current.style.left = `${
        firstDateDetails.left - (containerRefDetails.left + 3)
      }px`;

      const weekendRefs = Object.values(dateRefs.current).filter((ref) =>
        ref.classList.contains("weekend")
      );
      weekendRefs.forEach((ref, index) => {
        const dateRect = ref?.getBoundingClientRect();
        const greyDiv = document.createElement("div");
        greyDiv.style.position = "absolute";
        greyDiv.style.left = `${
          dateRect.left - (containerRefDetails.left + 3)
        }px`;
        greyDiv.style.width = index % 2 == 0 ? "28px" : "15px";
        greyDiv.style.height = "100%";
        greyDiv.style.backgroundColor = "#FAFAFA";
        absoluteEelementRed.current.appendChild(greyDiv);
      });
      setDateCollection(componentData);
    }
  }, [dateRefs, componentData]);

  const findDateRef = (date) => {
    const dateRef =
      new Date(date).toLocaleDateString("default", { month: "long" }) +
      "-" +
      date;
    return dateRefs.current[dateRef];
  };

  const calculateDistance = (endDate) => {
    const firstDateRef = dateRefs.current[Object.keys(dateRefs.current)[0]];
    const targetElement = findDateRef(endDate);
    const firstDateDetails = firstDateRef?.getBoundingClientRect();
    const targetRefDetails = targetElement?.getBoundingClientRect();
    const distance = targetRefDetails.left + 29 - firstDateDetails.left;
    return distance;
  };

  return (
    <AccessReviewWrapper>
      <DataGraphDataContainer>
        {dateCollection.map((item) => (
          <div className="item-container">
            <div className="title-container">
              <Tooltip
                arrow
                placement="top"
                title={item?.title}
                type="ellipsized"
              >
                <p className="title">
                  <Link to={`/access/accessReview/${item.workflowId}`}>
                    {item?.title}
                  </Link>
                </p>
              </Tooltip>
              <p className="title-id">{item?.id}</p>
            </div>
          </div>
        ))}
      </DataGraphDataContainer>
      <AccessReviewGraphContainer>
        <div className="time-line">
          <DateConatiner>
            {Object.entries(next30daysArray).map(
              ([monthLabel, dates], index) => (
                <>
                  <DateMonthContainer key={monthLabel}>
                    <DateMonthLabel>{monthLabel}</DateMonthLabel>
                    <DateDatesContainer
                      Count={
                        index < Object.keys(next30daysArray).length - 1
                          ? dates.length + 1
                          : dates.length
                      }
                    >
                      {dates.map((date, i) => (
                        <Styledspan
                          key={date}
                          ref={(el) =>
                            (dateRefs.current[`${monthLabel}-${date}`] = el)
                          }
                          isFirst={
                            date === new Date().toISOString().split("T")[0]
                          }
                          className={
                            [0, 6].includes(new Date(date).getDay())
                              ? "weekend"
                              : ""
                          }
                          isSpan12={
                            Object.keys(next30daysArray).length > 1 &&
                            index == 1
                              ? true
                              : false
                          }
                        >
                          {new Date(date).getDate()}
                        </Styledspan>
                      ))}
                    </DateDatesContainer>
                  </DateMonthContainer>
                  {index < Object.keys(next30daysArray).length - 1 && (
                    <StyledDivider orientation="vertical" flexItem />
                  )}
                </>
              )
            )}
          </DateConatiner>
          <DataGraphContainer>
            <BlueIndicatorForCurrent ref={blueIndicatorRef} />
            <GreyLineIndicator ref={absoluteEelementRed} />
            {dateCollection?.map((item) => (
              <InnerGraphContainer>
                <div style={{ width: calculateDistance(item?.endDate) }}>
                  <ProgressBar
                    percentage={item?.percentage}
                    count={item?.count}
                    width={calculateDistance(item?.endDate)}
                  />
                </div>
              </InnerGraphContainer>
            ))}
          </DataGraphContainer>
        </div>
      </AccessReviewGraphContainer>
    </AccessReviewWrapper>
  );
};

export default AccessReviewGraph;
