import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { UsersService } from '../../../data'
import { useApi } from '../../../hooks'
import { AvailableAdminRoles } from '../../../util'
import { ErrorStatusBoundary, LoadingBoundary } from '../../boundary'
import { AdminRoleList } from './AdminRoleList'

/**
 * This comprehensive UI component is designed for efficient management of admin roles.
 *
 * Please note that this component autonomously manages its states and offers an update function solely for retrieving the updated user roles.
 * However, if you require complete control over the content, we recommend using the `AdminRoleList` component instead.
 *
 * With this component, users can easily assign, unassign, and update admin roles from the list of available options.
 *
 * List of all available roles given by the API:
 *
 * - apiAdmin
 * - centralHelpdeskAdmin
 * - marketHelpdeskAdmin
 * - applicationHelpdeskAdmin
 * - applicationAdmin
 * - globalCommunityAdmin
 * - globalRoleAssigner
 * - identStatusAdmin
 * - marketAdmin
 * - marketRoleAssigner
 * - organizationRoleAssigner
 * - roleCreator
 * - roleApprover
 * - roleOwner
 * - sharedServiceAdmin
 * - supportAdmin
 * - orgAdmin
 */
export const AdminRoles = ({
  userId,
  availableAdminRoles = AvailableAdminRoles,
  isVisibleAsSupportContactSwitchShown = false,
  onChange = (newUserRoles) => {}
}) => {
  const {
    isLoading: isLoadingManageAdminRoles,
    isFailure: isFailureManageAdminRoles,
    status: statusManageAdminRoles,
    response: responseManageAdminRoles,
    mutate: mutateManageAdminRoles
  } = useApi(
    UsersService.getAllManageAdminRoles,
    {
      config: { id: userId },
      notificationMessageError: true,
      initialResponse: {},
      responseTransformer: (response) => {
        availableAdminRoles.forEach((role) => {
          if (!response[role]) response[role] = { admin: false }
        })

        return response
      }
    }
  )

  useEffect(() => {
    if (!responseManageAdminRoles) return

    onChange(responseManageAdminRoles)
  }, [ onChange, responseManageAdminRoles ])

  const { assignedAdminRole, unassignedAdminRoles } = useMemo(() => {
    const assignedAdminRole = []
    const unassignedAdminRoles = []

    availableAdminRoles.forEach((role) => {
      if (responseManageAdminRoles && responseManageAdminRoles[role] && responseManageAdminRoles[role].admin) {
        assignedAdminRole.push(role)
      } else {
        unassignedAdminRoles.push(role)
      }
    })

    return {
      assignedAdminRole,
      unassignedAdminRoles
    }
  }, [ availableAdminRoles, responseManageAdminRoles ])

  const handleRoleUpdate = (role) => {
    if (!responseManageAdminRoles) return

    mutateManageAdminRoles({
      ...responseManageAdminRoles,
      ...role
    })
  }

  return (
    <LoadingBoundary isLoading={isLoadingManageAdminRoles}>
      <ErrorStatusBoundary isError={isFailureManageAdminRoles} status={statusManageAdminRoles}>
        <AdminRoleList
          userId={userId}
          userRoles={responseManageAdminRoles}
          assignedAdminRoles={assignedAdminRole}
          unassignedAdminRoles={unassignedAdminRoles}
          isVisibleAsSupportContactSwitchShown={isVisibleAsSupportContactSwitchShown}
          onRoleUpdate={handleRoleUpdate}
        />
      </ErrorStatusBoundary>
    </LoadingBoundary>
  )
}

AdminRoles.propTypes = {
  /** UserId to fetch the data for */
  userId: PropTypes.string.isRequired,
  /** List of available admin roles. Default is all */
  availableAdminRoles: PropTypes.arrayOf(PropTypes.string),
  /**
   * If set to true switch will be shown, that toggles the visibility of the user as support contact.
   * Note, that because of API requirements the switch will only we visible if user has one of the roles: ``orgAdmin``, ``globalCommunityAdmin`` or ``marketAdmin``.
   */
  isVisibleAsSupportContactSwitchShown: PropTypes.bool,
  /** Called when userRoles changed */
  onChange: PropTypes.func
}
