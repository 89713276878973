import { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { blue, grey } from '@mercedes-benz/mui5-theme'
import { Box, Checkbox, Radio } from '@mui/material'
import { Interaction, InteractionPropTypes } from '../../../constants'
import { useInteraction } from '../../../hooks'
import { mergeSxProps, repeatString } from '../../../util'
import { Link } from '../../link'
import { Skeleton } from '../../skeleton'
import { Card } from '../card/Card'

/**
 * Generic Card Component
 *
 * ## Description
 * The SelectableCard component is a versatile card designed for React applications with the following features:
 *
 * - Selection: It can display radio buttons or checkboxes for selection, useful in forms or lists.
 * - Visual Variants: Comes in three styles: 'grid,' 'list,' and 'tiny,' offering design flexibility.
 *
 * **Important Note**:
 *
 * Due to the implementation for disabling card functionality, we have two options: either disable all buttons, etc., within specific instances of this component, or disable only the click events and checkbox/radio functionality.
 * We have decided that it is preferable to not disable click events within specific instances.
 * As a result, implementations will not disable, for example, A-tags and buttons.
 */
export const GenericCard = forwardRef((
  {
    id,
    type = 'genericCard',
    isList = false,
    isTiny = false,
    interaction = Interaction.NONE,
    href,
    isSelected = false,
    isLoading = false,
    isDisabled = false,
    isDividedShown = false,
    isDividedHorizontal = isList,
    isSubgrid = false,
    subgridContentColumns = 1,
    subgridContentRows = 1,
    onCardClick: _onCardClick = ({
      id,
      isSelected,
      e
    }) => {},
    children,
    sx = {} || [],
    ...otherProps
  },
  ref
) => {
  const isGrid = !isList

  const {
    isSelectable,
    isSelectHidden,
    isMultiselect,
    isLink,
    isClickable
  } = useInteraction({
    interaction,
    isLoading,
    isDisabled
  })

  const hasCheckbox = isSelectable && !isSelectHidden

  const onCardClick = useCallback((e) => {
    e.stopPropagation()

    const targetTag = e.target.tagName.toLowerCase()
    const targetParentTag = e.target.parentElement.tagName.toLowerCase()

    if (
      typeof _onCardClick === 'function'
      && targetTag !== 'button' && targetTag !== 'svg' && targetTag !== 'a'
      && targetParentTag !== 'button' && targetParentTag !== 'svg' && targetParentTag !== 'a'
    ) {
      _onCardClick({
        id,
        e,
        isSelected: !isSelected
      })
    }
  }, [ _onCardClick, id, isSelected ])

  return (
    <Card
      className={`${type} ${isLoading ? 'isLoading' : ''}`}
      data-testid={`${isLoading ? 'isLoading' : `${type[0].toUpperCase() + type.slice(1)}-${id}`}`}
      ref={ref}
      sx={mergeSxProps(
        [
          {
            '--selectBoxShift': '-34px',
            display: 'grid',
            gridTemplate: `${repeatString(subgridContentRows, '"content"')} 1fr ${isDividedShown ? '"divided"' : ''} / 1fr`,
            gap: 2,
            position: 'relative',
            padding: !isTiny ? 2 : 1,
            transition: 'background-color 250ms',
            background: `var(--cardBg, ${grey[90]})`
            // minHeight: 8
          },
          (isList || isDividedHorizontal) && { gridTemplate: `"content ${isDividedShown ? 'divided' : ''}" / 1fr` },
          isList && !isTiny && { paddingRight: 3 },
          hasCheckbox && isList && { gridTemplate: `"select content ${isDividedShown ? 'divided' : ''}" / auto 1fr` },
          hasCheckbox && isGrid && { gridTemplate: `${repeatString(subgridContentRows, '"select content"')} 1fr ${isDividedShown ? '"divided divided"' : ''} / auto 1fr` },
          isSubgrid && isList && {
            gridColumn: `span ${subgridContentColumns + (hasCheckbox ? 1 : 0) + (isDividedShown ? 1 : 0)}`,
            gridTemplate: 'auto / subgrid',
            '> .content': { gridTemplateColumns: 'subgrid' }
          },
          isSubgrid && isGrid && {
            gridRow: `span ${subgridContentRows + (hasCheckbox ? 1 : 0) + (isDividedShown && !isDividedHorizontal ? 1 : 0)}`,
            gridTemplateRows: 'subgrid',
            '> .content': { gridRow: `span ${subgridContentRows}`, gridTemplateRows: 'subgrid' }
          },
          // colors
          isSelected && { '--cardBg': `${blue[95]}` },
          isSelected && isSelectHidden && { '--cardBg': `${grey[85]}` },
          isClickable && { cursor: 'pointer', '&:hover': { '--cardBg': `var(--cardBgHover, ${grey[85]})` } },
          isClickable && isSelected && { '&:hover': { '--cardBgHover': `${blue[90]}` } },
          isClickable && isSelected && isSelectHidden && { '&:hover': { '--cardBgHover': `${grey[80]}` } },
          isDisabled && { opacity: '0.5' }
        ],
        sx
      )}
      component={isLink && href && !isLoading && !isDisabled ? Link : undefined}
      href={typeof href === 'function' ? href({ id }) : href}
      onClick={isClickable ? onCardClick : undefined}
      {...otherProps}
    >
      {isSelectable && !isSelectHidden && (
        <Box
          className="select"
          sx={[
            { gridArea: 'select', marginInline: '-12px' },
            isList && { alignSelf: 'center' },
            isTiny && { marginLeft: 'unset' }
          ]}
        >
          {!isLoading
            ? !isMultiselect
              ? <Radio checked={isSelected} disabled={isDisabled} onClick={onCardClick} />
              : <Checkbox checked={isSelected} disabled={isDisabled} onClick={onCardClick} />
            : (
              <Skeleton
                variant="rounded"
                width="20px"
                height="20px"
                sx={{ margin: '11px' }}
              />
            )}
        </Box>
      )}

      {children}
    </Card>
  )
})

GenericCard.propTypes = {
  /**
   * ID that refs to a content (application card, role card, etc.)
   * rendered inside generic card. It is mandatory for all click events
   * (checkbox/radiobutton functionality)
   * */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * cardType like 'applicationCard'.<br>
   * Used for testId and className
   */
  type: PropTypes.string,
  /** App card variant */
  isList: PropTypes.bool,
  /** If true cards borders are reduced */
  isTiny: PropTypes.bool,
  interaction: InteractionPropTypes,
  /**
   * Required when using 'navigate' as behavior. Specifies the target URL of the link.
   * This can be either a string representing the URL or a function returning the URL dynamically.
   * If a function is provided, it should return the target URL.
   */
  href: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]),
  /** Defines current state of the card */
  isSelected: PropTypes.bool,
  /** If true, loading skeletons will be displayed */
  isLoading: PropTypes.bool,
  /** When set to true, the card appears disabled. However, it's important to note that specific implementations may not fully disable functionality, such as actions within the ``AppCard``, for example. */
  isDisabled: PropTypes.bool,
  isDividedShown: PropTypes.bool,
  isDividedHorizontal: PropTypes.bool,
  isSubgrid: PropTypes.bool,
  subgridContentColumns: PropTypes.number,
  subgridContentRows: PropTypes.number,
  /** Card event handler */
  onCardClick: PropTypes.func,
  /** Content layout */
  children: PropTypes.node
}
