// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mr-rd {
  margin-right: 2rem !important;
}
.ml-rd {
  margin-left: 5rem !important;
}

.mlr-26 {
  margin-left: 1.625rem !important;
}

.mtr-26 {
  margin-top: 1.625rem !important;
}

.mtrminus-26 {
  margin-top: -1.625rem !important;
}

@media (min-width: 1281px) {
  .button-allign {
    text-align: end;
  }
}
@media (min-width: 769px) {
  .button-allign {
    text-align: end;
  }
}
@media (max-width: 690px) {
  .button-allign {
    text-align: center;
  }
  .mr-rd {
    margin-right: 1rem !important;
  }
  .ml-rd {
    margin-left: 1rem !important;
  }

  .mlr-26 {
    margin-left: 1rem !important;
  }

  .mtr-26 {
    margin-top: 1rem !important;
  }

  .mtrminus-26 {
    margin-top: -1rem !important;
  }
}
.MuiTypography-body2 {
  white-space: pre-line;
}
`, "",{"version":3,"sources":["webpack://./src/sharedComponents/roleDetails.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,4BAA4B;EAC9B;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,4BAA4B;EAC9B;AACF;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".mr-rd {\n  margin-right: 2rem !important;\n}\n.ml-rd {\n  margin-left: 5rem !important;\n}\n\n.mlr-26 {\n  margin-left: 1.625rem !important;\n}\n\n.mtr-26 {\n  margin-top: 1.625rem !important;\n}\n\n.mtrminus-26 {\n  margin-top: -1.625rem !important;\n}\n\n@media (min-width: 1281px) {\n  .button-allign {\n    text-align: end;\n  }\n}\n@media (min-width: 769px) {\n  .button-allign {\n    text-align: end;\n  }\n}\n@media (max-width: 690px) {\n  .button-allign {\n    text-align: center;\n  }\n  .mr-rd {\n    margin-right: 1rem !important;\n  }\n  .ml-rd {\n    margin-left: 1rem !important;\n  }\n\n  .mlr-26 {\n    margin-left: 1rem !important;\n  }\n\n  .mtr-26 {\n    margin-top: 1rem !important;\n  }\n\n  .mtrminus-26 {\n    margin-top: -1rem !important;\n  }\n}\n.MuiTypography-body2 {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
