import DoneAllIcon from '@mui/icons-material/DoneAll'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Interaction } from '../../../../constants'
import { UsersService } from '../../../../data'
import { useApi } from '../../../../hooks'
import { AliceIcon, AliceIconType, CountryFlag } from '../../../icon'
import { Tooltip } from '../../../message'
import { OrgContextPopover } from '../../../orgElements'
import { FocusDot } from '../../../projectFocus'
import { NewTable, Table } from '../../../table'
import { AdminRoleAccordion } from '../AdminRoleAccordion'
import { OrganizationAdministratorAssignmentDialog } from '../assignment/OrganizationAdministratorAssignmentDialog'
import { AdminRoleTableToolbar } from '../table/AdminRoleTableToolbar'

const VALID_GROUP_TYPES = [ 0, 1, '0', '1', 'S' ]
const ROW_KEY = 'id'
const COLUMNS = [ 'name', 'street', 'city', 'focus', 'community', 'country', 'status' ]

export const OrganizationAdminAccordion = ({
  userId,
  isAssigned,
  administratedOrgs,
  onChange = (newRole) => {}
}) => {
  const { t } = useTranslation('componentLibrary')

  const tableRef = useRef(null)

  const [ isAssignmentDialogOpen, setIsAssignmentDialogOpen ] = useState(false)
  const [ selectedOrgs, setSelectedOrgs ] = useState([])

  const {
    isLoading: isLoadingDelete,
    execute: executeDelete
  } = useApi(
    UsersService.deletePrivilege,
    {
      config: {
        id: userId,
        privilege: 'orgadmin',
        body: { orgAdminPrivileges: selectedOrgs.map((id) => ({ id })) }
      },
      isRequestingInitially: false,
      isNotifyingOnSuccess: true,
      isNotifyingOnError: true,
      notificationMessageSuccess: t('adminRoles.delete', { adminRole: t('adminRoles.orgAdmin') }),
      onRequestSuccess: () => {
        const newAdministratedOrgs = administratedOrgs
          .filter((administrated) => selectedOrgs
            .every((selected) => administrated.id !== selected))

        const orgAdmin = { admin: false }

        if (newAdministratedOrgs.length) {
          orgAdmin.admin = true
          orgAdmin.administratedOrgs = newAdministratedOrgs
        }

        handleDeselectAll()
        onChange({ orgAdmin })
      }
    }
  )

  const titleRenderer = (key) => {
    switch (key) {
      case 'name':
        return t('general.name')
      case 'street':
        return t('general.address')
      case 'city':
        return t('general.city')
      case 'focus':
      case 'community':
      case 'country':
      case 'status':
        return ''
      default:
        return key
    }
  }

  const entryRenderer = (key, row) => {
    switch (key) {
      case 'name':
        return (<OrgContextPopover id={row.id}>{row.name}</OrgContextPopover>)
      case 'city':
        return (
          <Stack direction="row" spacing={1}>
            {row.postalCode && row.postalCode.length && (<Box>{row.postalCode}</Box>)}
            {row.location && row.location.length && (<Box>{row.location}</Box>)}
          </Stack>
        )
      case 'focus':
        return VALID_GROUP_TYPES.includes(row.orgGroupType) && (
          <FocusDot groupType={row.orgGroupType} />
        )
      case 'community':
        return (
          <Tooltip title={t(`orgCommunity.${row.communities}`)} arrow>
            <AliceIcon iconType={AliceIconType[`ORG_${row.communities}`]} viewBox="0 0 36 36" />
          </Tooltip>
        )
      case 'country':
        return row.country ? (
          <Tooltip title={row.country} arrow>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CountryFlag countryCode={row.country} border />
            </Box>
          </Tooltip>
        ) : (
          null
        )
      case 'status':
        return row.active ? (
          <Tooltip title={t('general.active')} arrow>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <DoneAllIcon sx={{ color: 'success.main' }} />
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title={t('general.inactive')} arrow>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <DoNotDisturbIcon sx={{ color: 'error.main' }} />
            </Box>
          </Tooltip>
        )
      default:
        return row[key]
    }
  }

  const handleDelete = () => executeDelete()

  const handleSelectChange = (selectedRowIds) => setSelectedOrgs(selectedRowIds)
  const handleSelectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id))
  const handleDeselectAll = () => tableRef?.current?.updateSelection(tableRef?.current?.rows.map((row) => row.id), true)

  const handleOpenAssignmentDialog = () => setIsAssignmentDialogOpen(true)
  const handleCloseAssignmentDialog = () => setIsAssignmentDialogOpen(false)

  const handleAssign = (newRole) => {
    handleCloseAssignmentDialog()
    onChange(newRole)
  }

  return (
    <>
      <AdminRoleAccordion
        data-testid="OrganizationAdminAccordion"
        title={t('adminRoles.orgAdmin')}
        isActive={isAssigned}
        onAssignClick={handleOpenAssignmentDialog}
      >
        {administratedOrgs.length && (
          <NewTable
            ref={tableRef}
            isLocal
            interaction={Interaction.MULTI_SELECT}
            localRows={administratedOrgs}
            titleRenderer={titleRenderer}
            entryRenderer={entryRenderer}
            rowIdKey={ROW_KEY}
            columns={COLUMNS}
            onSelectionChange={handleSelectChange}
            customSlot={(
              <AdminRoleTableToolbar
                onDelete={handleDelete}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
                isDeleteDisabled={!selectedOrgs.length}
                isDeleteLoading={isLoadingDelete}
              />
            )}
          />
        )}
      </AdminRoleAccordion>

      <OrganizationAdministratorAssignmentDialog
        userId={userId}
        isOpen={isAssignmentDialogOpen}
        onAssigned={handleAssign}
        onClose={handleCloseAssignmentDialog}
      />
    </>
  )
}

OrganizationAdminAccordion.propTypes = {
  userId: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool,
  administratedOrgs: PropTypes.array,
  onChange: PropTypes.func
}
