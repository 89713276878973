import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import { useAgreements, useApp, useLanguage, useUser } from '../../hooks'
import { AgreementsDialog } from '../agreements'
import { LanguageDialog } from '../language'
import { BotUserPage, MaintenancePage, NotAuthorizedPage } from '../placeholder'

export const Startup = ({ children, overrideAuthorized }) => {
  const { isMaintenance } = useApp()

  const {
    isLoadingUser,
    user,
    isBotUser,
    isUserAuthorized
  } = useUser({ overrideAuthorized })

  const { updateLanguage } = useLanguage()

  const {
    isLoadingAgreementsAccept,
    hasAcceptedAgreements,
    agreements,
    acceptAgreements
  } = useAgreements()

  if (isLoadingUser) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (agreements && !hasAcceptedAgreements) {
    return (
      <AgreementsDialog
        agreements={agreements}
        isLoading={isLoadingAgreementsAccept}
        onAccept={acceptAgreements}
      />
    )
  }

  if (isMaintenance) return <MaintenancePage />
  if (!isUserAuthorized) return <NotAuthorizedPage />
  if (isBotUser) return <BotUserPage />

  return (
    <>
      {children}

      <LanguageDialog
        userData={user}
        loading={false}
        onLanguageChange={updateLanguage}
      />
    </>
  )
}

Startup.propTypes = {
  /** The content of the component. */
  children: PropTypes.node,
  /** Debug only: If true authorization will be overwritten */
  overrideAuthorized: PropTypes.bool
}
