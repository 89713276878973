import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccessReviewHeader from "./Header";
import { Layout } from "@alice/component_library";
import AccessReviewMainPage from "./MainPage/index.js";
import {
  CacheStorage,
  useCache,
  usePermission,
} from "@alice/component_library";
import { useWidth } from "@utils/hooks";
import {
  AccessReviewMainContainer,
  AccessReviewContainer,
} from "./styledComponents";

const cacheKey = "Access_Review_UserPreferance";

const AccessReview = () => {
  const { getCachedItem, setCachedItem, removeCachedItem } = useCache(
    CacheStorage.sessionStorage
  );
  const cacheValue = getCachedItem(cacheKey);
  const { adminRoles } = usePermission();
  const [isAdminVisible, setIsadminVisible] = useState(
    cacheValue?.adminSwitchs !== undefined ? cacheValue?.adminSwitchs : false
  );
  const widthPanel = useWidth();

  const [windowSize, setWindoSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const hasReviewAccess = useSelector(
    (state) => state.accessReview.hasReviewAccess
  ); //has to be changed later

  const { t } = useTranslation();
  const navigate = useNavigate();

  const adminSwichVisibiltyHandler = (sl) => {
    setCachedItem(cacheKey, { ...cacheValue, adminSwitchs: sl });
    setIsadminVisible(sl);
  };

  useEffect(() => {
    if (!hasReviewAccess) navigate("/access");
  }, [navigate, hasReviewAccess]);

  window.addEventListener("beforeunload", () => {
    removeCachedItem(cacheKey);
  });

  useEffect(() => {
    switch (widthPanel) {
      case "xs":
        setWindoSize({ isMobile: true, isTablet: false, isDesktop: false });
        break;
      case "sm":
        setWindoSize({ isMobile: false, isTablet: true, isDesktop: false });
        break;
      case "md":
      case "lg":
      case "xl":
      case "xxl":
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
        break;
      default: {
        setWindoSize({ isMobile: false, isTablet: false, isDesktop: true });
      }
    }
  }, [widthPanel]);

  const arrayOfAdminRole = adminRoles ? [...adminRoles] : [];
  return (
    <AccessReviewMainContainer>
      <Layout>
        <AccessReviewContainer>
          <AccessReviewHeader
            actionHandler={adminSwichVisibiltyHandler}
            adminRoles={arrayOfAdminRole}
            isAdminVisible={isAdminVisible}
            translate={t}
          />
          <AccessReviewMainPage
            isAdminVisible={isAdminVisible}
            translate={t}
            cacheKey={cacheKey}
            windowSize={windowSize}
          />
        </AccessReviewContainer>
      </Layout>
    </AccessReviewMainContainer>
  );
};

export default AccessReview;
