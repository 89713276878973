import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { mergeSxProps } from '../../../util'
import { Skeleton } from '../../skeleton'

export const InfoChipPlaceholder = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: '90px',
      height: '24px',
      borderRadius: '20px'
    }}
  />
)

/**
 * New style Card Chip
 */
export const CardInfoChip = forwardRef((
  {
    icon,
    contentText,
    sx = {} || [],
    isLoading = false,
    ...otherProps
  },
  ref
) => (isLoading
  ? <InfoChipPlaceholder />
  : (
    <Stack
      ref={ref}
      sx={mergeSxProps(
        {
          flexDirection: 'row',
          alignItems: 'center',
          gap: '6px',
          backgroundColor: 'common.white',
          borderRadius: '20px',
          padding: '4px 8px',
          height: 24,
          width: 'fit-content'
        },
        sx
      )}
      {...otherProps}
    >
      {icon && (
        <Box
          sx={{
            color: 'info.main',
            fontSize: '16px',
            lineHeight: 0,
            svg: { fontSize: '1rem' }
          }}
        >
          {icon}
        </Box>
      )}

      {contentText && (
        <Typography variant="body2">{contentText}</Typography>
      )}
    </Stack>
  )))

CardInfoChip.propTypes = {
  /** Desired  icon
   * example: `<DeviceHubOutlined color="inherit" fontSize="inherit" />`
   * */
  icon: PropTypes.node,
  /** Content text of the chip */
  contentText: PropTypes.node,
  /** Override component style */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ]),
  /** Loading state. If `true` Placeholder is shown */
  isLoading: PropTypes.bool
}
