import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NewTable, Tooltip } from "@alice/component_library";
const entryRenderer = (key, row) => {
  const { count = {}, id, title, workflowId } = row;
  const { totalAssignments, processedAssignments } = count;
  const percentage = Math.ceil((processedAssignments / totalAssignments) * 100);

  if (key === "progress") {
    return (
      <div>
        <div className="percentage">{`${percentage}%`}</div>
        {`(${processedAssignments}/${totalAssignments})`}
      </div>
    );
  }
  if (key === "id") {
    return (
      <div>
        <div className="item-container">
          <div className="title-container">
            <Tooltip arrow placement="top" title={title} type="ellipsized">
              <p className="title">
                <Link to={`/access/accessReview/${workflowId}`}>{title}</Link>
              </p>
            </Tooltip>
            <p className="title-id">{id}</p>
          </div>
        </div>
      </div>
    );
  }
};
const AccessReviewTable = ({ componentData, count }) => {
  const { t } = useTranslation();
  return (
    <>
      <NewTable
        isLocal
        hidePagination
        localRows={componentData}
        columns={["id", "progress"]}
        titleRenderer={(key) => {
          if (key === "progress")
            return (
              <Tooltip type="ellipsized" title={t("proggress")}>
                <span className="progress">{t("proggress")}</span>
              </Tooltip>
            );
        }}
        entryRenderer={entryRenderer}
        onGetHref={function noRefCheck() {}}
        onRowClick={function noRefCheck() {}}
        onSelectionChange={function noRefCheck() {}}
        rowIdKey="entitlementId"
      />
    </>
  );
};

export default AccessReviewTable;
