import ChangePW from "@Icons/ChangePW.svg";
import ExternalLink from "@Icons/ExternalLink.svg";
import Edit from "@Icons/Edit.svg";
import Home from "@Icons/Home.svg";
import Profile from "@Icons/Profile.svg";
import Task from "@Icons/Tasks.svg";
import NewReq from "@Icons/NewReq.svg";
import Requests from "@Icons/Reports.svg";
import ControlCenter from "@Icons/ControlCenter.svg";
import DownloadIcon from "@Icons/downloadIcon.svg";
import DeleteApproveIcon from "@Icons/DeleteApproveIcon.svg";
import DeleteFailedIcon from "@Icons/DeleteFailedIcon.svg";
import GlobalIcon from "@Icons/GlobalIcon.svg";
import MailIcon from "@Icons/MailIcon.svg";
import PhoneIcon from "@Icons/MailIcon.svg";
import InquiryHelpIcon from "@Icons/InquiryHelpIcon.svg";
import IncidentIcon from "@Icons/IncidentIcon.svg";
import FeedbackIcon from "@Icons/FeedbackIcon.svg";
import ArrowIcon from "@Icons/ArrowIcon.svg";
import HeadPhoneIcon from "@Icons/HeadPhone.svg";
import ActiveIcon from "@Icons/ActiveIcon.svg";
import CopyMailIcon from "@Icons/CopyMailIcon.svg";
import { ChecklistRtl } from "@mui/icons-material";

function Icon(id) {
  const iconMapper = {
    passwordIcon: ChangePW,
    link: ExternalLink,
    edit: Edit,
    home: Home,
    profile: Profile,
    task: Task,
    newReq: NewReq,
    requests: Requests,
    controlcenter: ControlCenter,
    downloadIcon: DownloadIcon,
    deleteApproveIcon: DeleteApproveIcon,
    deleteFailedIcon: DeleteFailedIcon,
    globalIcon: GlobalIcon,
    mailIcon: MailIcon,
    phoneIcon: PhoneIcon,
    inquiryHelpIcon: InquiryHelpIcon,
    incidentIcon: IncidentIcon,
    feedbackIcon: FeedbackIcon,
    arrowIcon: ArrowIcon,
    headPhone: HeadPhoneIcon,
    activeIcon: ActiveIcon,
    copyMailIcon: CopyMailIcon,
    accessReview: ChecklistRtl,
  };

  return iconMapper[id];
}

export default Icon;
