import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { isEmpty } from '../../../util'

/**
 * Simple component to display information with a label.
 *
 * In order to address the issue of empty strings potentially disrupting the UI layout, the component employs a workaround.
 * It checks for empty strings and, when encountered, renders a non-breaking space (&nbsp;).
 * This prevents undesired layout breaks while maintaining the visual integrity of the UI.
 */
export const LabelledInfo = forwardRef(({
  label,
  sx,
  children,
  ...otherProps
}, ref) => (
  <Stack ref={ref} gap={1} sx={sx} {...otherProps}>
    {!isEmpty(label)
      ? <Typography variant="caption" color="text.secondary">{label}</Typography>
      : <Typography variant="caption">&nbsp;</Typography>}

    {children}
  </Stack>
))

LabelledInfo.propTypes = {
  /** String to display as label */
  label: PropTypes.string.isRequired,
  /** Styling applies to Stack */
  sx: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}
