import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  SearchBar,
  useUser,
  usePermission,
} from "@alice/component_library";
import {
  getRoleReviewDetail,
  getOverdueCount,
  getRoleDetailsWithId,
  GetVisibleSupportStatus,
} from "@actions";
import { isSuperAdmin } from "@utils";
import { RoleReviewSkeleton } from "@components/Skeleton/RoleReviewSkeleton";
import ReviewTable from "./ReviewTable";
import TabComponent from "../MainPage/TabComponent";
import DetailHeader from "./DetailHeader";
import { RoleReviewDetailsContainer } from "../styledComponents";
import BreadCrumbHeader from "./BreadCrumbHeader";
import ProgressComponent from "../subComponents/SpinnerComponent";
import {
  getRoleReviewDone,
  getJobPercentage,
} from "@actions/accessReviewActions";
import { debounce } from "lodash";

const RoleReviewDetails = () => {
  const { roleReviewId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState({});
  const [countInfo, setCountInfo] = useState({ open: 0, processed: 0 });
  const [filterValue, setFilterValue] = useState("open");
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [progressURL, setProgressURL] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [overDuecount, setOverdueCount] = useState(0);
  const [savedTableData, setSavedtableData] = useState([]);
  const url = useSelector((state) => state.accessReview?.jobUrl);

  const { user } = useUser();
  const { adminRoles: adminRolesSet } = usePermission();
  const adminRoles = useMemo(
    () => (adminRolesSet ? [...adminRolesSet] : []),
    [adminRolesSet]
  );

  const { adminRolesInfo = [] } = useSelector(
    (state) => state.profile.visibleSupport
  );

  const roleOwnerRoles = adminRolesInfo?.find(
    (roleInfo) => roleInfo.adminRole === "ROLE_OWNER"
  );

  const roleOwnerRoleIds = useMemo(
    () =>
      roleOwnerRoles
        ? roleOwnerRoles?.administratedRoles?.map((role) => role.roleId)
        : [],
    [roleOwnerRoles]
  );

  const { urlProgress, units } = useSelector(
    (state) => state.accessReview?.jobStatus
  );

  const userUrl = localStorage.getItem(`review-${roleReviewId}`);

  const hasReviewAccess = useSelector(
    (state) => state.accessReview.hasReviewAccess
  ); // has to be changed later

  const roleReviewDetailCount = useSelector(
    (state) => state.accessReview.roleReviewDetailCount
  );

  const getOverDueCount = async () => {
    const overdueCount = await getOverdueCount(roleReviewId);
    setOverdueCount(overdueCount);
  };

  useEffect(() => {
    if (!hasReviewAccess) navigate("/access");
  }, [navigate, hasReviewAccess]);

  useEffect(() => {
    if (!hasReviewAccess) navigate("/access");
  }, [navigate, hasReviewAccess]);

  useEffect(() => {
    dispatch(getRoleReviewDetail(roleReviewId));
    getOverDueCount();
  }, [dispatch, roleReviewId]);

  useEffect(() => {
    const userUrl = localStorage.getItem(`review-${roleReviewId}`);
    setProgressURL(userUrl);
  }, [url]);

  const { reviewDetail = [], loading } = useSelector(
    (state) => state?.accessReview?.roleReviewDetailData
  );
  const disableActions = useMemo(
    () =>
      roleOwnerRoleIds?.includes(reviewDetail?.roleId) &&
      !reviewDetail?.reviewers?.filter((el) => el.id === user?.id)?.length &&
      !isSuperAdmin(adminRoles, "accessReviewAdminMode"),
    [
      roleOwnerRoleIds,
      reviewDetail?.roleId,
      reviewDetail?.reviewers,
      adminRoles,
      user,
    ]
  );
  useEffect(() => {
    if (adminRolesInfo.length === 0) {
      dispatch(GetVisibleSupportStatus());
    }
  }, [dispatch, adminRolesInfo]);
  useEffect(() => {
    if (roleReviewId) {
      dispatch(getRoleReviewDone(roleReviewId, "open", searchValue));
      dispatch(getRoleReviewDone(roleReviewId, "done", searchValue));
    }
  }, [dispatch, filterValue, roleReviewId, searchValue]);

  useEffect(() => {
    let intervalId;

    if (progressURL) {
      const sendData = () => {
        intervalId = setInterval(() => {
          dispatch(getJobPercentage(roleReviewId, progressURL));
        }, 1000);
      };

      if (urlProgress < 100) {
        sendData();
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [roleReviewId, dispatch, urlProgress, progressURL]);

  const rolesDataInfo = useSelector(
    (state) => state.accessReview?.roleReviewRolesDetailsInfoWithWf?.roles[0]
  );

  useEffect(() => {
    const startTime = new Date(reviewDetail?.startTime);

    const deadline = startTime?.setDate(startTime?.getDate() + 28);
    const openCount =
      reviewDetail?.totalAssignments - reviewDetail?.processedAssignments;
    setCountInfo({
      open: openCount,
      processed: reviewDetail?.processedAssignments,
    });
    const detailHeader = {
      roleId: reviewDetail?.roleId,
      roleName: reviewDetail?.roleName,
      dataClassification: reviewDetail?.dataClassification?.toLowerCase(),
      reviewersList: reviewDetail?.reviewers,
      deadline: deadline,
      endTime: reviewDetail?.endTime,
      state: reviewDetail?.state,
      status: {
        completed: reviewDetail?.processedAssignments,
        total: reviewDetail?.totalAssignments,
      },
      overDueCount: overDuecount,
    };
    setCardInfo(detailHeader);
  }, [reviewDetail, overDuecount]);

  const handleTabChange = useCallback(
    (newValue) => {
      setSelectedTab(newValue);
      setFilterValue(newValue === 0 ? "open" : "done");
    },
    [setSelectedTab]
  );

  const handleClose = () => {
    localStorage.removeItem(`review-${roleReviewId}`);
    dispatch(getRoleReviewDetail(roleReviewId));
    dispatch(getRoleDetailsWithId(roleReviewId, "open", "", 0, 10, ""));
  };
  const tabs = [
    {
      label: t("role-review-by-status.open"),
      number: searchValue.length
        ? roleReviewDetailCount.openCount
        : countInfo?.open,
    },
    {
      label: t("role-review-by-status.done"),
      number: searchValue.length
        ? roleReviewDetailCount.doneCount
        : countInfo?.processed,
    },
  ];

  const handleAllRowSelected = (currentState) => {
    setSelectAll(!currentState);
  };

  const redirectToAccessReveiw = () => {
    navigate("/access/accessReview");
  };

  const onChange = (e) => {
    const searchTerm = e.target.value;
    setSearchKey(searchTerm);
  };

  const onSearch = (searchTerm) => {
    handleSearch(searchTerm);
  };

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      setSearchValue(searchTerm);
    }, 300), // Debounce for 300 milliseconds
    [setSearchValue]
  );

  return (
    <>
      <RoleReviewDetailsContainer>
        <Layout>
          <BreadCrumbHeader
            breadCrumbOptions={[
              {
                label: t("reviews"),
                link: "/access/accessReview",
              },
              { label: t("role-reviews"), active: "true" },
            ]}
            goBackLink={"/access/accessReview"}
            goBackLabel={t("go-to-reviews")}
          />
          {loading ? (
            <RoleReviewSkeleton />
          ) : (
            <DetailHeader data={cardInfo} translate={t} />
          )}
          {userUrl ? (
            <ProgressComponent
              isOPen={userUrl}
              key={reviewDetail?.roleId}
              tottalCount={countInfo?.open}
              onRedirect={() => redirectToAccessReveiw()}
              onCloseHadler={() => handleClose()}
              units={units}
              progress={urlProgress}
            />
          ) : (
            <>
              <SearchBar
                fullWidth
                label={t("review-process-search-placeholder")}
                onChange={(e) => onChange(e)}
                onClear={() => {
                  setSearchKey("");
                  setSearchValue("");
                }}
                onSearch={onSearch}
                value={searchKey}
                showIcons
              />
              <TabComponent
                selectedTab={selectedTab}
                onChange={handleTabChange}
                tabs={tabs}
                sx={{ marginBottom: "24px", marginTop: "24px" }}
              />
              <ReviewTable
                searchValue={searchValue}
                filterValue={filterValue}
                roleReviewId={roleReviewId}
                rolesDataInfo={rolesDataInfo}
                reviewStatus={selectedTab === 0 ? "ACTIVE" : "COMPLETED"}
                isSelectAll={selectAll}
                countInfo={countInfo.open}
                previousTableData={savedTableData}
                disableActions={disableActions}
              />
            </>
          )}
        </Layout>
      </RoleReviewDetailsContainer>
    </>
  );
};

export default RoleReviewDetails;
