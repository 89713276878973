import { createAsyncThunk } from "@reduxjs/toolkit";
import Communication from "@services/Communication";

export const fetchUserById = createAsyncThunk(
  "user/fetchUserById",
  async ({ userId }) => {
    try {
      let url = `/gems/users/${userId}`;
      const userData = await Communication.get(url);
      return userData?.data;
    } catch (error) {
      throw new Error("failed fetching user by id");
    }
  }
);

export const fetchUserAssignableRoleById = createAsyncThunk(
  "user/fetchUserAssignableRoleById",
  async ({ userId, roleId, isSelfRequestable }) => {
    try {
      const urlSearchParamsForRoles = new URLSearchParams();
      const filtersForRoles = ["isWorkflowBased==true", `id==${roleId}`];
      if (isSelfRequestable)
        filtersForRoles.push(`isSelfRequestable==${isSelfRequestable}`);
      urlSearchParamsForRoles.set("filter", filtersForRoles.join(";"));
      let url = `/gems/users/${userId}/assignableroles?${urlSearchParamsForRoles.toString()}`;
      const userRoleAssignableRoles = await Communication.get(url);
      return userRoleAssignableRoles?.data?.roles?.length > 0
        ? userRoleAssignableRoles?.data?.roles[0]
        : {};
    } catch (error) {
      throw new Error("failed fetching user role by id");
    }
  }
);

export const fetchUserAssignedRoleById = createAsyncThunk(
  "user/fetchUserAssignedRoleById",
  async ({ userId, roleId, orgScope = "", custScope = "" }) => {
    console.log(userId, roleId, orgScope, custScope);
    try {
      const urlSearchParamsForRoles = new URLSearchParams();
      const filtersForRoles = [
        "roleDefinition.isWorkflowBased==true",
        `roleDefinition.id==${roleId}`,
      ];
      if (custScope) filtersForRoles.push(`customScope.name==${custScope}`);
      if (orgScope) filtersForRoles.push(`orgScope.id==${orgScope}`);
      urlSearchParamsForRoles.set("filter", filtersForRoles.join(";"));
      let url = `/gems/users/${userId}/roles?${urlSearchParamsForRoles.toString()}`;
      const userRoles = await Communication.get(url);
      const assignedRoles = userRoles?.data?.roles;
      return assignedRoles?.length > 0 ? assignedRoles[0] : {};
    } catch (error) {
      throw new Error("failed fetching user roles");
    }
  }
);

export const fetchUserAssignableOrgScopeById = createAsyncThunk(
  "user/fetchUserAssignableOrgScopeById",
  async ({ userId, orgId, roleId, isSelfRequestable }) => {
    try {
      const urlSearchParamsForRoles = new URLSearchParams();
      const filtersForRoles = ["activationState==ALL", `id==${orgId}`];
      if (isSelfRequestable)
        filtersForRoles.push(`isSelfRequestable==${isSelfRequestable}`);
      urlSearchParamsForRoles.set("filter", filtersForRoles.join(";"));
      let url = `/gems/users/${userId}/roles/${roleId}/assignableorganizations?${urlSearchParamsForRoles.toString()}`;
      const orgScope = await Communication.get(url);
      return orgScope?.data?.organizations
        ? orgScope?.data?.organizations[0]
        : {};
    } catch (error) {
      throw new Error("failed fetching user role by id");
    }
  }
);
