import { styled, Typography, Grid, Chip } from "@mui/material";
import { Link } from "react-router-dom";

export const TaskRequestDetailsContainer = styled("div")(({ ...props }) => ({
  padding: "24px 16px",
  opacity: props.disabled ? "0.6" : "1",
}));
export const TaskRequestHeader = styled((props) => (
  <Typography variant="h6" {...props} />
))(({ ...props }) => ({
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "24px",
}));

export const TaskIdLink = styled((props) => <Link {...props} />)(() => ({
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "MB Corpo S Text",
  textDecoration: "none",
  paddingLeft: "3px",
  cursor: "pointer",
  color: "rgb(0, 120, 214)",
}));

export const TaskIdHeader = styled((props) => (
  <Typography variant="h6" {...props} />
))(() => ({
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "28px",
}));

export const SubTopicContainer = styled((props) => <Grid item {...props} />)(
  ({ theme, ...props }) => ({
    marginTop: props.marginTop ? "0px" : "24px",
    "& .MuiCollapse-wrapperInner.MuiCollapse-vertical .MuiBox-root>span": {
      //In role card after clicking down arrow
      display: "none !important", // for removing the arrow " ^ "
    },
    maxWidth: "100%",
    flexBasis: "100%",
    "& .user-card": {
      height: "78%",
      "& .MuiPaper-root": { height: "100%" },
      [theme.breakpoints.down("md")]: {
        width: "79vw",
        height: "80%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "81vw",
      },
      "& .MuiStack-root": {
        width: "100%",
      },
    },
    "& .application-card": {
      [theme.breakpoints.down("md")]: {
        width: "79vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "81vw",
      },
    },
    "& .Card": {
      "& .MuiStack-root": {
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
          rowGap: "10px",
          width: "16rem",
        },
      },
    },
    "& .appCard.MuiPaper-root": {
      maxWidth: "85vw",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
        width: "79vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "79vw",
      },
      "& .MuiGrid-root.MuiGrid-container": {
        [theme.breakpoints.up("md")]: {
          maxWidth: "21vw",
        },
      },
    },
  })
);
export const SubHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: "8px",
});
export const SubHeaderTypography = styled((props) => (
  <Typography variant="h6" {...props} />
))(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "8px",
  lineHeight: "24px",
  fontSize: "18px",
  fontWeight: 400,
  color: "#00000099",
}));

export const StatusChips = styled((props) => <Chip {...props} />)(
  ({ ...props }) => ({
    backgroundColor: props.bgcolor,
    "&:hover": {
      backgroundColor: props.bgcolor,
    },
  })
);
