import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class ReportsService {
  static getById = ({
    id,
    format,
    fields,
    ...args
  }) => AliceService.get(
    `/reports/${id}`,
    mergeObjects(
      {
        params: {
          format: format || 'json',
          fields: Array.isArray(fields) ? fields.join(',') : fields
        }
      },
      { ...args }
    )
  )

  static getByIds = async ({
    ids = [],
    ...args
  }) => {
    const requests = ids.map((id) => ReportsService.getById({ id, ...args }))
    const responses = await AliceService.all(requests)
    let reports = []

    responses?.forEach((response) => {
      if (response?.records) {
        reports = reports.concat(response.records)
      }
    })

    return reports
  }

  static getApplicationGroupTypes = () => AliceService.get(
    '/applications'
  )

  static getBillings = async ({ ...args }) => AliceService.get(
    '/reports/Billing',
    { ...args }
  )
}
