import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { grey } from '@mercedes-benz/mui5-theme'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Checkbox, Radio, Stack, TablePagination, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Interaction } from '../../../constants'
import { useApiItems, useInteraction, useLocalItems, useSelectableItems } from '../../../hooks'
import { formatNumber, mergeSxProps } from '../../../util'
import { IconButton } from '../../button'
import { AliceIcon, AliceIconType } from '../../icon'
import { ExpandContainer, TableEntry, TableRow, TableTitle, TableWrapper } from './tableElements/TableElements'

const getSort = (sort) => ({
  key: sort.slice(1),
  isAsc: sort.startsWith('+')
})

/**
 * ItemList in table style <br>
 * item -> row
 *
 * ##Props in ref
 * - rows - rows in page
 * - rowsCount - total row count
 * - updateSelection - update selected ids, see useSelectableItems.js
 * - reload - calls updateItems, see useApiItems.js
 *
 *
 * - see [Local Example](./?path=/story/alice-ui-table-newtable--local-example) for following props
 * - pageIndex - current pagination index
 * - setPageIndex
 * - pageSize - rows per page
 * - setPageSize
 * - search - search filter text
 * - setSearch
 * - sort - sort text -|+columnId
 * - setSort
 * - filters - filter object
 * - setFilters
 *
 * ## Notes:
 * - For a basic table use `isLocal` and `localRows`.
 * - The titleRow is automatically created by keys of the first object in `localRows`.
 * - Set `columns` to filter and/or sort the titleRow.
 * - The `searchConfig` allows customization of the search functionality by applying API filters. (See example below)
 * - There is a possibility to create controlled local Table e.g. User needs to select & deselect all rows in all pages (See example below)
 *
 * **Search Config Example:**<br>
 * ```js
 * const searchConfig = {
 *   apiFilters: ['id', 'name'], // Custom API filter if endpoint does not support `search`
 * };
 * ```
 *
 * For ApiTable use `apiUrl || apiFunction` and `getResponseData` and optional `searchConfig`.
 * Preferably integrated with [Api-Services](./?path=/docs/alice-guides-how-to-use-api-services--docs), such as `ApplicationsService.getAll()`.
 *
 * Provide `maxHeight` in `tableSx` to get a vertical scrollbar.
 *
 * See Local and Api Story for minimal prop usage<br>
 * and everything else for deeper information.
 */
export const NewTable = forwardRef(({
  isLocal = false,
  customSlot,
  // todo? could be enhanced to include column size
  columns: _columns,
  selectablePageSizes = [ 3, 6, 12, 24, 48 ],
  interaction = Interaction.NONE,
  rowIdKey = 'id',
  onRowClick: _onRowClick = ({
    data,
    id,
    isSelected
  }) => {},
  noDataPlaceholder,
  errorIds = [],
  disabledIds: _disabledIds = [],
  notSelectableIds: _notSelectableIds = [],
  expandIds: _expandIds = [],
  getRowExpandProps,
  //   ({ row, id }) => ({
  //   heading: '',
  //   children: '',
  //   contentSx: {}
  // }),
  titleRenderer = (key) => key,
  entryRenderer = (key, row) => row[key],
  // selection
  onSelectionChange = (selectedIds) => {},
  preselectedIds,
  // general items
  preselectedPageIndex,
  preselectedPageSize,
  preselectedSearch,
  preselectedSort,
  preselectedFilters,
  // api items
  apiUrl,
  apiFunction,
  additionalApiFunctionConfig,
  additionalApiFunctionInformation,
  responseDataItemsKey,
  getResponseData = (data) => data[responseDataItemsKey],
  searchConfig = null,
  // local items
  localRows,
  // table style
  hidePagination = false,
  sx = {} || [],
  tableSx = {} || [],
  // for testing
  isForceLoading = false,
  onGetHref = (id) => null,
  ...otherProps
}, ref) => {
  const { t } = useTranslation('componentLibrary')
  const { palette } = useTheme()

  const [ columns, setColumns ] = useState([ '' ])
  const [ sortObj, setSortObj ] = useState(preselectedSort ? getSort(preselectedSort) : {})
  const [ disabledIds, setDisabledIds ] = useState(_disabledIds)
  const [ notSelectableIds, setNotSelectableIds ] = useState(_notSelectableIds)
  const [ expandIds, setExpandIds ] = useState(_expandIds)

  const useItems = useMemo(() => (isLocal ? useLocalItems : useApiItems), [ isLocal ])

  const {
    items: rows,
    itemsCount: rowsCount,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    search,
    setSearch,
    sort,
    setSort,
    filters,
    setFilters,
    isLoading,
    updateItems
  } = useItems({
    preselectedPageIndex,
    preselectedPageSize,
    preselectedSearch,
    preselectedSort,
    preselectedFilters,
    // api
    apiUrl,
    getResponseData,
    apiFunction,
    additionalFunctionConfig: additionalApiFunctionConfig,
    additionalFunctionInformation: additionalApiFunctionInformation,
    searchConfig,
    // local
    localItems: localRows,
    // testing
    isForceLoading
  })

  const {
    isClickable,
    isMultiselect,
    isSelectable,
    isSelectHidden,
    isLink
  } = useInteraction({ interaction, isLoading })

  const {
    selectedIds,
    updateSelection,
    // additional features
    onSelectAll,
    isSelectAllChecked,
    isSelectAllIndeterminate
  } = useSelectableItems({
    isMultiselect,
    preselectedIds,
    onSelectionChange,
    // additional features
    items: rows,
    itemIdKey: rowIdKey,
    disabledIds,
    notSelectableIds
  })

  useEffect(() => {
    if (_disabledIds.length) {
      setDisabledIds(
        (disabledIds) => (disabledIds !== _disabledIds ? _disabledIds : disabledIds)
      )
    }

    if (_notSelectableIds.length) {
      setNotSelectableIds(
        (notSelectableIds) => (notSelectableIds !== _notSelectableIds ? _notSelectableIds : notSelectableIds)
      )
    }
  }, [ _disabledIds, _notSelectableIds ])

  useEffect(() => {
    if (_columns || rows.length) {
      const newColumns = _columns || Object.keys(rows[0])

      setColumns(newColumns.length ? newColumns : [ '' ])
    }
  }, [ _columns, rows ])

  useImperativeHandle(ref, () => ({
    rows,
    rowsCount,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    search,
    setSearch,
    sort,
    setSort,
    filters,
    setFilters,
    updateSelection,
    reload: updateItems
    // eslint-disable-next-line
  }), [
    rows,
    rowsCount,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    search,
    setSearch,
    sort,
    setSort,
    filters,
    setFilters,
    updateItems
  ])

  const onRowClick = (data) => {
    const itemId = data[rowIdKey]

    if (isSelectable) {
      const isSelected = selectedIds.indexOf(itemId) === -1

      updateSelection([ itemId ], !isSelected)
      _onRowClick({
        data,
        id: itemId,
        isSelected
      })
    } else {
      _onRowClick({ data, id: itemId })
    }
  }

  const hasExpand = typeof getRowExpandProps === 'function'
  const hasReload = typeof updateItems === 'function'
  const hasPagination = rowsCount > pageSize

  const itemColumnCount = columns.length
  const actionColumnCount = (hasExpand || (isSelectable && !isSelectHidden)) ? 1 : 0
  const totalColumnCount = itemColumnCount + actionColumnCount

  const TitleRow = () => (
    <>
      {(hasExpand || (isSelectable && !isSelectHidden)) && (
        <TableTitle>
          {isMultiselect && (
            <Checkbox
              checked={isSelectAllChecked()}
              indeterminate={isSelectAllIndeterminate()}
              onChange={(e, isSelected) => onSelectAll(isSelected)}
            />
          )}
        </TableTitle>
      )}

      {columns.map((key) => (
        <TableTitle
          key={key}
          onClick={() => {
            let newSortObj

            if (sortObj.key !== key) {
              newSortObj = { key, isAsc: true }
            } else if (sortObj.isAsc) {
              newSortObj = { key, isAsc: false }
            } else {
              newSortObj = {}
            }

            setSortObj(newSortObj)
            setSort(newSortObj.key ? `${newSortObj.isAsc ? '+' : '-'}${newSortObj.key}` : null)
          }}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }
          ]}
        >
          {titleRenderer(key)}

          <span style={{ width: 24, height: 24 }}>
            {sortObj.key === key ? sortObj.isAsc ? <KeyboardArrowDown /> : <KeyboardArrowUp /> : ''}
          </span>
        </TableTitle>
      ))}
    </>
  )

  const ExpandableRow = ({ row }) => {
    const rowId = row[rowIdKey]
    const isNotSelectable = notSelectableIds.includes(rowId)

    const expandProps = hasExpand ? getRowExpandProps({ row, id: rowId }) : {}

    const [ isExpanded, setIsExpanded ] = useState(expandIds.includes(rowId))

    useEffect(() => {
      setExpandIds((currExpandIds) => {
        const idIndex = currExpandIds.indexOf(rowId)

        if (isExpanded && idIndex === -1) currExpandIds.push(rowId)
        if (!isExpanded && idIndex !== -1) currExpandIds.splice(idIndex, 1)

        return currExpandIds
      })
    }, [ isExpanded, rowId ])

    return (
      <TableRow
        onClick={(isClickable && !isNotSelectable) ? () => onRowClick(row) : undefined}
        href={typeof onGetHref === 'function' ? onGetHref(rowId) : onGetHref}
        isLink={isLink}
        sx={[
          {
            '--tableRowBgOdd': palette.interaction.default,
            '--tableRowBgEven': palette.interaction.default2
          },
          isClickable && {
            cursor: 'pointer',
            '&:hover': {
              '--tableRowBgOdd': `var(--tableRowHoverOdd, ${palette.interaction.hover})`,
              '--tableRowBgEven': `var(--tableRowHoverEven, ${palette.interaction.hover2})`
            }
          },
          selectedIds.includes(rowId) && {
            '--tableRowBgOdd': palette.interaction.selected,
            '--tableRowBgEven': palette.interaction.selected2,
            '--tableRowHoverOdd': palette.interaction.selectedHover,
            '--tableRowHoverEven': palette.interaction.selectedHover2
          },
          errorIds.includes(rowId) && {
            '--tableRowBgOdd': palette.interaction.error,
            '--tableRowBgEven': palette.interaction.error2,
            '--tableRowHoverOdd': palette.interaction.errorHover,
            '--tableRowHoverEven': palette.interaction.errorHover2
          },
          disabledIds.includes(rowId) && {
            opacity: palette.action.disabledOpacity,
            pointerEvents: 'none'
          },
          isNotSelectable && {
            pointerEvents: 'none',
            '&:hover': {
              '--tableRowBgOdd': palette.interaction.default,
              '--tableRowBgEven': palette.interaction.default2
            }
          }
        ]}
      >
        {(hasExpand || (isSelectable && !isSelectHidden)) && (
          <TableEntry>
            {hasExpand && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setIsExpanded((oldVal) => !oldVal)
                }}
                sx={[
                  {
                    color: 'text.primary',
                    transition: 'transform 500ms'
                  },
                  isExpanded && { transform: 'rotate(180deg)' }
                ]}
              >
                <KeyboardArrowDown />
              </IconButton>
            )}

            {isSelectable && !isSelectHidden && !isNotSelectable && (
              (isSelectable && !isMultiselect)
                ? <Radio checked={selectedIds.indexOf(rowId) !== -1} />
                : <Checkbox checked={selectedIds.indexOf(rowId) !== -1} />
            )}
          </TableEntry>
        )}

        {columns.map((key) => (
          <TableEntry
            sx={[
              (typeof entryRenderer(key, row) !== 'string' && entryRenderer(key, row)) && { pointerEvents: 'auto' },
              isLink && { color: 'text.primary' }
            ]}
            key={`${row[key]}-${key}`}
          >
            {entryRenderer(key, row)}
          </TableEntry>
        ))}

        {hasExpand && (
          <ExpandContainer
            {...expandProps}
            isExpanded={isExpanded}
            onClose={() => setIsExpanded(false)}
            columnCount={totalColumnCount}
          />
        )}
      </TableRow>
    )
  }

  return (
    <Stack sx={mergeSxProps({ gap: 1 }, sx)}>
      {(hasReload || !hidePagination) && (
        <Stack
          sx={{
            flexFlow: 'row',
            alignItems: 'center',
            gap: 2
          }}
        >
          {hasReload && (
            <IconButton
              onClick={() => updateItems()}
              disabled={isLoading}
              color="text.icon"
              sx={{ margin: 0.5 }}
            >
              <AliceIcon
                iconType={AliceIconType.LOADING}
                sx={{
                  transform: 'rotateY(180deg)',
                  animation: isLoading
                    ? 'spin 1.5s linear infinite'
                    : 'none',
                  '@keyframes spin': {
                    '0%': { transform: 'rotate(0deg) rotateY(180deg)' },
                    '100%': { transform: 'rotate(360deg) rotateY(180deg)' }
                  }
                }}
              />
            </IconButton>
          )}

          {customSlot}

          {!hidePagination && (
            <TablePagination
              component="div"
              count={rowsCount}
              page={rowsCount ? pageIndex : 0}
              onPageChange={(e, newPageIndex) => {
                setPageIndex(newPageIndex)
              }}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(e) => {
                setPageSize(e.target.value)
              }}
              rowsPerPageOptions={selectablePageSizes}
              labelRowsPerPage={`${t('genericSearch.rowsPerPageLabel')}:`}
              labelDisplayedRows={({
                from,
                to,
                count
              }) => t('genericSearch.displayRowsLabel', {
                from: formatNumber({ num: from }),
                to: formatNumber({ num: to }),
                count: formatNumber({ num: count })
              })}
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              sx={[
                { marginLeft: 'auto' },
                !hasPagination && {
                  '.MuiTablePagination-displayedRows, .MuiTablePagination-actions': { display: 'none' },
                  '.MuiTablePagination-input': { marginRight: 1 }
                }
              ]}
            />
          )}
        </Stack>
      )}

      <Box sx={{ display: 'flex', position: 'relative' }}>
        <TableWrapper
          data-testid="NewTable"
          columnCount={totalColumnCount}
          titleChildren={<TitleRow />}
          entryChildren={rows.map((row) => <ExpandableRow row={row} key={row[rowIdKey]} />)}
          hasIconStart={!!actionColumnCount}
          noDataPlaceholder={noDataPlaceholder}
          sx={mergeSxProps([
            !!actionColumnCount && { gridTemplateColumns: `repeat(${actionColumnCount}, min-content)` },
            tableSx
          ])}
          {...otherProps}
        />

        {isLoading && (
          <Stack
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              minHeight: '100px',
              background: grey[90],
              opacity: 0.6,
              zIndex: 1
            }}
          >
            <Typography>Loading ...</Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  )
})

NewTable.propTypes = {
  isLocal: PropTypes.bool,
  /** slot to put custom elements left to pagination */
  customSlot: PropTypes.element,
  /**
   * defines the order and selection for the column keys
   * - can be used to filter object data to display
   * - can be used to order data to display
   *
   * - if empty Object.keys(data[0]) will be used
   */
  columns: PropTypes.arrayOf(PropTypes.string),
  /** list of displayed page sizes */
  selectablePageSizes: PropTypes.array,
  /** defines the interaction pattern */
  interaction: PropTypes.string,
  /**
   * defines the key (inside the rowObject) that is used as an internal id
   *
   * needed for all interactions
   */
  rowIdKey: PropTypes.string,
  /**
   * @param param { data, id, [isSelected] }
   * @returns {void}
   */
  onRowClick: PropTypes.func,
  /**
   * placeholder box when no data can be shown
   *
   * default: "`<Typography>No data.</Typography>`"
   */
  noDataPlaceholder: PropTypes.element,
  /** list of Ids shown in error state */
  errorIds: PropTypes.array,
  /** list of Ids shown in disabled state */
  disabledIds: PropTypes.array,
  /** list of Ids shown in notSelectable state */
  notSelectableIds: PropTypes.array,
  /** list of Ids shown in expanded state */
  expandIds: PropTypes.array,
  /**
   * sets `<ExpandContainer>` props per row.
   *
   * @param param { row, id }
   * @returns {expandProps} { heading: '', children: '', contentSx: {} }
   */
  getRowExpandProps: PropTypes.func,
  /**
   * intercepts each title render
   * - can be used to render a custom title based on the given column key
   *
   * @param key
   * @returns {void}
   */
  titleRenderer: PropTypes.func,
  /**
   * intercepts each entry render
   * - can be used to render a custom entry based on the given column key and row
   *
   * @param key
   * @param row
   * @returns {void}
   */
  entryRenderer: PropTypes.func,
  // selection
  /**
   * event that's fired when `selectedIds` getting updated
   *
   * used in `useSelectableItems()`
   *
   * @param selectedIds
   * @returns {void}
   */
  onSelectionChange: PropTypes.func,
  /** list of preselected Ids for `selectedIds` */
  preselectedIds: PropTypes.array,
  // general items
  /**
   * preselect page index (pagination pointer)
   *
   * default: `0`
   *
   * used in `useLocalItems()` or `useApiItems()`
   */
  preselectedPageIndex: PropTypes.number,
  /**
   * preselect page size (items per page)
   *
   * default: `12`
   *
   * used in `useLocalItems()` or `useApiItems()`
   */
  preselectedPageSize: PropTypes.number,
  /**
   * preselect search text
   *
   * used in `useLocalItems()` or `useApiItems()`
   */
  preselectedSearch: PropTypes.string,
  /**
   * preselect sort
   *
   * used in `useLocalItems()` or `useApiItems()`
   */
  preselectedSort: PropTypes.string,
  /**
   * preselect filter
   *
   * used in `useLocalItems()` or `useApiItems()`
   */
  preselectedFilters: PropTypes.object,
  // api items
  /**
   * Base URL for API calls.
   *
   * Required when the API is not local and used within `useApiItems()`.
   *
   * It is recommended to use a function with API services for enhanced flexibility and maintainability.
   * The 'apiUrl' prop is provided for backward compatibility but may be deprecated in future versions.
   */
  apiUrl: PropTypes.string,
  /**
   * Function to execute when making API calls, preferably integrated with [Api-Services](./?path=/docs/alice-guides-how-to-use-api-services--docs), such as `ApplicationsService.getAll()`.
   *
   * This function is required when the API is not local and is utilized within `useApiItems()`.
   */
  apiFunction: PropTypes.func,
  /**
   * Additional configuration for Api-Function.
   * Note, that double keys will overwrite existing (mostly required) keys.
   */
  additionalApiFunctionConfig: PropTypes.object,
  /**
   * Additional information for Api-Function.
   * Getting provided via `apiFunction(config, additionalInformation)` from `useApiItems`-Hook
   */
  additionalApiFunctionInformation: PropTypes.object,
  /**
   * Optional for less complex API response to extract data.
   * Getting used: `getResponseData = (data) => data[responseDataItemsKey]`
   */
  responseDataItemsKey: PropTypes.string,
  /** Function to extract data from API response. */
  getResponseData: PropTypes.func,
  /**
   * Object. Should be provided with `apiFilters` array.
   * *apiFilters*: Array of field names to be used as filters if the API doesn't support native search functionality.
   *
   * used in `useApiItems()`
   */
  searchConfig: PropTypes.object,
  // local items
  /**
   * array of objects to display (object -> row)
   *
   * is required when local
   *
   * used in `useLocalItems()`
   */
  localRows: PropTypes.arrayOf(PropTypes.object),
  // table styling
  /** to hide pageSize and pagination box */
  hidePagination: PropTypes.bool,
  /** to style all internal elements */
  sx: PropTypes.any,
  /** to style just table */
  tableSx: PropTypes.any,
  /** activate the vertical table gutters */
  hasVerticalGutter: PropTypes.bool,
  /** forces loading state (for testing) */
  isForceLoading: PropTypes.bool,
  /** Function to handle href acquirement in interaction mode "navigate" */
  onGetHref: PropTypes.func
}
