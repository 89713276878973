import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const CustomScopeName = ({ custId, needsCustomScopes }) => {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body2"
      noWrap
      sx={needsCustomScopes && !custId ? { color: "#E8BD00" } : {}}
      id="custom-scope-name"
    >
      {custId ? custId : needsCustomScopes ? t("missing-info") : "-"}
    </Typography>
  );
};

CustomScopeName.prototype = {
  custId: PropTypes.string,
  needsCustomScopes: PropTypes.bool,
};

export default CustomScopeName;
