import { Box, Collapse, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { AliceIcon, AliceIconType } from '../../icon'
import { SearchBar } from '../searchBar/SearchBar'

/** An animated searchbar */
export const SearchBarAnimated = forwardRef((
  {
    timeout = 250,
    size = 'small',
    initialValue = null,
    onChange = () => {},
    onSearch = () => {},
    onClear = () => {},
    onAnimationEnd = () => {},
    onAnimationExited = () => {},
    openToSide = 'left',
    isAbsolute = false,
    clearOnOpen = true,
    showSearchbarIcons = false,
    label = '',
    disabled = false
  },
  ref
) => {
  const [ open, setOpen ] = useState(false)
  const [ value, setValue ] = useState(initialValue)

  useEffect(() => {
    if (!open && clearOnOpen) {
      setValue('')
      onClear()
    }
  }, [ open ]) //eslint-disable-line

  const openOrCloseSearchInput = () => {
    setOpen((prev) => !prev)
  }

  const handleChange = (event) => {
    // triggered on key down
    const value = event?.target?.value

    setValue(value)
    onChange(value)
  }

  const handleSearch = (value) => {
    // triggered on key press 'enter'
    onSearch(value)
  }

  const handleClear = () => {
    // is called only if icons are
    // visible (showSearchbarIcons = true) and
    // the 'clear' icon was clicked
    setValue('')
    onClear()
  }

  const sxStyles = {
    left: {
      // open to the left side
      container: { justifyContent: 'right' },
      wrapper: { flexDirection: 'row-reverse', right: '45px' },
      containerIcon: {
        marginLeft: '15px',
        ...(disabled && { opacity: 0.5 })
      }
    },
    right: {
      // open to the right side
      container: {
        justifyContent: 'left',
        flexDirection: 'row-reverse'
      },
      wrapper: { left: '45px' },
      containerIcon: {
        marginRight: '15px',
        ...(disabled && { opacity: 0.5 })
      }
    }
  }

  const sx = sxStyles[openToSide]

  const iconType = open ? AliceIconType.CLOSE : AliceIconType.SEARCH

  return (
    <Box
      data-testid="SearchBarAnimated"
      sx={{
        width: !isAbsolute ? '300px' : undefined,
        position: !isAbsolute ? 'block' : 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...sx.container
      }}
    >
      <Box
        sx={{
          width: '200px',
          position: isAbsolute ? 'absolute' : 'block',
          display: 'flex',
          ...sx.wrapper
        }}
      >
        <Collapse
          orientation="horizontal"
          in={open}
          timeout={timeout}
          addEndListener={() => {
            onAnimationEnd()
          }}
          onExited={() => {
            onAnimationExited()
          }}
        >
          <SearchBar
            ref={ref}
            label={label}
            onChange={handleChange}
            onSearch={handleSearch}
            onClear={handleClear}
            preselectQuery=""
            size={size}
            showIcons={showSearchbarIcons}
            value={value}
            disabled={disabled}
          />
        </Collapse>
      </Box>

      <Box
        sx={{ ...sx.containerIcon }}
      >
        <IconButton
          onClick={openOrCloseSearchInput}
          disabled={disabled}
          size={size === 'small' ? size : 'normal'}
        >
          <AliceIcon
            iconType={iconType}
            color="black"
            size={16}
          />
        </IconButton>
      </Box>
    </Box>
  )
})

SearchBarAnimated.propTypes = {
  /** Animation timeout in ms */
  timeout: PropTypes.number,
  /** Size of the textfield */
  size: PropTypes.oneOf([ 'small', 'normal' ]),
  /**
   * Event that is triggered after input changed
   *
   * @param value
   * @returns {void}
   */
  onChange: PropTypes.func,
  /**
   * Event that is triggered on enter press
   *
   * @param value
   * @returns {void}
   */
  onSearch: PropTypes.func,
  /**
   * Event that is triggered after clear button is pressed.<br>
   * Only functional if "showSearchbarIcons" is "true".
   *
   * @returns {void}
   */
  onClear: PropTypes.func,
  /**
   * Is called when animation ends
   *
   * @returns {void}
   */
  onAnimationEnd: PropTypes.func,
  /**
   * Is called when animation is finished
   *
   * @returns {void}
   */
  onAnimationExited: PropTypes.func,
  /** Side to which the textfield opens */
  openToSide: PropTypes.oneOf([ 'left', 'right' ]),
  /** Show search and clear icons inside search field. */
  showSearchbarIcons: PropTypes.bool,
  /** Label to displayed in searchbar */
  label: PropTypes.string,
  /** Determines whether the textfield is disabled or not */
  disabled: PropTypes.bool,
  /** Determines whether the textfield position is absolute or not */
  isAbsolute: PropTypes.bool,
  /** Determines whether the textfield should be cleared on open or not */
  clearOnOpen: PropTypes.bool
}
