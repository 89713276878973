import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Chip } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { FilterPopover } from './FilterPopover'

const PopoverIcon = ({ isOpen }) => (
  <KeyboardArrowDownIcon
    sx={{
      transition: 'all 0.1s ease-in',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    }}
  />
)

/**
 * Filter Chip using MuiChip<br>
 * https://mui.com/material-ui/api/chip/
 *
 * This can be utilized in two ways:
 *
 * 1. It can function as either an active or inactive chip.
 * 2. It can serve as a filter popover.
 *
 * For the filter popover to work, it necessitates the inclusion of the 'possibleFilters' property structured as follows:
 *
 * ```
 * possibleFilters: [
 *  {
 *    value: "filter1",
 *    label: "Filter - 1"
 *  }
 * ]
 * ```
 *
 */
export const FilterChip = forwardRef((
  {
    icon,
    label,
    searchLabel,
    allLabel,
    isMultiselect,
    isActive = false,
    isSearchShown = true,
    isAllShown = true,
    possibleFilters = [],
    activeFilters = [],
    disabledFilters = [],
    filtersPerRow = 6,
    maxFilterRows = 3,
    onClick = (isActive) => {},
    onAllClick,
    onChange = (newValues) => {},
    ...otherProps
  },
  ref
) => {
  const [ isPopoverOpen, setIsPopoverOpen ] = useState(false)

  const chipRef = useRef(null)

  useImperativeHandle(ref, () => chipRef?.current)

  const handleClick = () => {
    setIsPopoverOpen(!isPopoverOpen)
    onClick(!isActive)
  }

  const handlePopoverClose = () => setIsPopoverOpen(false)

  const hasPopover = !!possibleFilters.length

  const isActiveOrOpen = isActive
    || possibleFilters.some((possibleFilter) => activeFilters.includes(possibleFilter.value))
    || (hasPopover && isPopoverOpen)

  return (
    <>
      <Chip
        data-testid="FilterChip"
        ref={chipRef}
        icon={icon}
        label={label}
        onDelete={hasPopover ? () => {} : undefined}
        onClick={handleClick}
        deleteIcon={hasPopover ? <PopoverIcon isOpen={isPopoverOpen} /> : undefined}
        variant="outlined"
        sx={({ palette }) => ({
          gap: '4px',
          paddingInline: '14px',
          borderColor: !isActiveOrOpen ? palette.grey[70] : palette.primary.light,
          background: 'white',
          color: !isActiveOrOpen ? palette.text.primary : palette.primary.light,
          cursor: 'pointer',
          transition: 'color 250ms, background-color 250ms, border-color 250ms, box-shadow 250ms',
          '&.MuiChip-clickable:hover': { backgroundColor: !isActiveOrOpen ? palette.grey[85] : palette.blue[95] },
          '.MuiTouchRipple-ripple': { color: !isActiveOrOpen ? palette.grey[65] : palette.blue[75] },
          '.MuiSvgIcon-root': { margin: 0, color: 'inherit' },
          '.MuiChip-label': { padding: 0 }
        })}
        {...otherProps}
      />

      { hasPopover && (
        <FilterPopover
          isOpen={isPopoverOpen}
          anchorEl={chipRef.current}
          searchLabel={searchLabel}
          allLabel={allLabel}
          possibleFilters={possibleFilters}
          activeFilters={activeFilters}
          disabledFilters={disabledFilters}
          isMultiselect={isMultiselect}
          isSearchShown={isSearchShown}
          isAllShown={isAllShown}
          itemsPerRow={filtersPerRow}
          maxFilterRows={maxFilterRows}
          onAllClick={onAllClick}
          onChange={onChange}
          onClose={handlePopoverClose}
        />
      ) }
    </>
  )
})

FilterChip.propTypes = {
  /** Chip icon */
  icon: PropTypes.node,
  /** Chip label */
  label: PropTypes.string,
  /** Popover search label */
  searchLabel: PropTypes.string,
  /** Popover all button label */
  allLabel: PropTypes.string,
  /** Popover multiselect */
  isMultiselect: PropTypes.bool,
  /** State of chip that determines its current status and its appropriate style */
  isActive: PropTypes.bool,
  /** Defines if search should be shown in popover */
  isSearchShown: PropTypes.bool,
  /** Defines if all button should be shown in popover */
  isAllShown: PropTypes.bool,
  /** All possible filters in popover */
  possibleFilters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      renderer: PropTypes.node
    })
  ),
  /** All active filters in popover. Should be the value of the filter */
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  /** All disabled filters in popover. Should be the value of the filter */
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
  /** Defines the number of filters per row in popover */
  filtersPerRow: PropTypes.number,
  /** On click callback */
  onClick: PropTypes.func,
  /** All button click callback */
  onAllClick: PropTypes.func,
  /** On change callback */
  onChange: PropTypes.func
}
