import { useContext } from 'react'
import { UsersService } from '../../data'
import { useApi } from '../api/useApi'
import { OpenTasksProviderContext } from './OpenTasksProvider'

export const useOpenTasks = () => {
  const {
    user,
    openTasksCount,
    mutateOpenTasksCount
  } = useContext(OpenTasksProviderContext)

  const { execute } = useApi(
    UsersService.getOpenTasksCount,
    {
      config: { id: user?.id },
      isRequestingInitially: false,
      isNotifyingOnError: true,
      onRequestSuccess: mutateOpenTasksCount
    }
  )

  return {
    openTasksCount,
    setOpenTasksCount: mutateOpenTasksCount,
    reloadOpenTasksCount: execute
  }
}
