import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'
import { DescriptionOutlined, EmailOutlined, FmdGoodOutlined, OpenInNewOutlined, PhoneOutlined, QuestionMarkOutlined, TitleOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { isEmail, isPhone, isUrl, mergeSxProps } from '../../../../../util'
import { Skeleton } from '../../../../skeleton'
import { Link } from '../../../../link'
import { CopyButton } from '../../../../button'
import { SmartIcon } from '../smartIcons/SmartIcons'
import { AliceIcon, IconWrapper } from '../../../../icon'
import { DetailItemType } from './DetailsList.const'
import { TypographyLineClamp } from '../../../../typography'

const DetailItem = ({
  text,
  label,
  icon,
  lineClamp = 1,
  isBlank = false,
  isLoading = false,
  isLabelShown: _isLabelShown = true,
  sx = {} || []
}) => {
  const textIsEmail = typeof text !== 'object' && isEmail(text)
  const textIsUrl = typeof text !== 'object' && isUrl(text)
  const textIsPhone = typeof text !== 'object' && isPhone(text)

  const isLink = text && (textIsEmail || textIsUrl || textIsPhone)
  const isLabelShown = _isLabelShown && label

  const textElm = (
    <TypographyLineClamp variant="body2" lineClamp={lineClamp}>
      {text}
    </TypographyLineClamp>
  )

  return (
    <Stack
      className="detailItem"
      sx={mergeSxProps({
        gap: '4px',
        marginBlock: '2px',
        paddingLeft: `${16 + 8}px`,
        overflow: 'hidden',
        '&:hover .copyBtn': { opacity: 1 },
        '.copyBtn': {
          position: 'absolute',
          right: 0,
          top: '50%',
          opacity: 0,
          transform: 'translateY(-50%)',
          transition: 'opacity 250ms'
        }
      }, sx)}
    >
      {isLabelShown && (
        <Typography
          variant="caption"
          sx={{ color: 'text.secondary', lineHeight: '16px' }}
        >
          {label}
        </Typography>
      )}

      <Box sx={{ position: 'relative' }}>
        {icon && (
          <IconWrapper
            size={16}
            color="text.secondary"
            isLoading={isLoading}
            sx={[
              {
                position: 'absolute',
                left: `-${16 + 8}px`,
                top: isLoading ? 2 : 0
              },
              !isLabelShown && { top: '50%', transform: 'translateY(-50%)' }
            ]}
          >
            { icon }
          </IconWrapper>
        )}

        {isLoading
          ? (
            <Skeleton
              variant="rectangular"
              sx={{
                width: '120px',
                height: '16px',
                marginBlock: '2px'
              }}
            />
          )
          : (
            isLink
              ? (
                <Link
                  isBlank={isBlank}
                  href={
                    !textIsEmail
                      ? textIsPhone
                        ? `tel:${text}`
                        : text
                      : `mailto:${text}`
                  }
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    marginRight: '40px',
                    '&:hover > .MuiSvgIcon-root': { opacity: 1 }
                  }}
                >
                  {textElm}

                  <OpenInNewOutlined
                    fontSize="inherit"
                    color="inherit"
                    sx={{ opacity: 0, transition: 'opacity 250ms' }}
                  />
                </Link>
              )
              : textElm
          )}

        {isLink && !isLoading && <CopyButton className="copyBtn" text={text} />}
      </Box>
    </Stack>
  )
}

DetailItem.propTypes = {
  text: PropTypes.node,
  label: PropTypes.node,
  icon: PropTypes.element,
  lineClamp: PropTypes.number,
  isBlank: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLabelShown: PropTypes.bool
}

/**
 * List Component for Cards and Contexts<br>
 * For Default-Use -> see ``const { getDetailItem } = useDetailItem()``
 *
 * [Design Link](https://www.figma.com/design/dO3y3MMdf7GfOGCLyyxdVR/Alice-Component-Library?node-id=2924-1197&t=XeKQ5OsQ4704JwBo-0)
 */
export const DetailsList = ({
  data = [],
  size = 'm',
  isBlank,
  isLoading,
  isLabelShown = size === 'm',
  isVertical = false,
  isSubgrid = false,
  sx = {} || []
}) => (
  <Box
    className="detailsList"
    sx={mergeSxProps([
      {
        display: 'grid',
        columnGap: 4,
        rowGap: size === 'm' ? 2 : 1
      },
      !isVertical && { gridAutoFlow: 'column', alignItems: 'center' },
      isSubgrid && !isVertical && {
        gridColumn: `span ${data.length}`,
        gridTemplateColumns: 'subgrid'
      },
      isSubgrid && isVertical && {
        gridRow: `span ${data.length}`,
        gridTemplateRows: 'subgrid'
      }
    ], sx)}
  >
    {data.map((detailItem) => (detailItem && detailItem?.text
      ? (
        <DetailItem
          key={`${detailItem.text}_${detailItem.label}`}
          text={detailItem.text}
          label={detailItem.label}
          icon={detailItem.icon}
          isBlank={isBlank}
          isLoading={isLoading}
          lineClamp={detailItem.lineClamp}
          isLabelShown={isLabelShown}
          sx={detailItem.sx}
        />
      )
      : <Box sx={{ height: 24 }} />
    ))}
  </Box>
)

DetailsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ])).isRequired,
  size: PropTypes.oneOf([ 's', 'm' ]),
  isBlank: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLabelShown: PropTypes.bool,
  sx: PropTypes.any
}

export const useDetailItem = () => {
  const { t } = useTranslation('componentLibrary')

  const getDetailItem = ({
    type,
    text = '',
    iconProps,
    ...otherProps
  }) => {
    switch (type) {
      case DetailItemType.DESCRIPTION:
        return {
          text,
          label: 'Description',
          icon: <DescriptionOutlined {...iconProps} />,
          lineClamp: 3,
          ...otherProps
        }
      case DetailItemType.ADDRESS:
        return {
          text,
          label: 'Address',
          icon: <FmdGoodOutlined sx={{ marginTop: '2px' }} {...iconProps} />,
          lineClamp: 3,
          ...otherProps
        }
      case DetailItemType.USER_COMMUNITY:
        return {
          text,
          label: t('general.community'),
          icon: <SmartIcon type="userCommunity" sx={{ marginTop: '1px' }} {...iconProps} />,
          ...otherProps
        }
      case DetailItemType.ORG_COMMUNITY:
        return {
          text,
          label: t('general.community'),

          icon: <SmartIcon type="orgCommunity" sx={{ marginTop: '1px' }} {...iconProps} />,
          ...otherProps
        }
      case DetailItemType.ORG_FORM:
        return {
          text,
          label: t('general.orgType'),
          icon: <SmartIcon type="orgForm" sx={{ marginTop: '1px' }} {...iconProps} />,
          ...otherProps
        }
      case DetailItemType.ORGANIZATION:
        return {
          text,
          label: t('general.organization'),
          icon: <AliceIcon iconType="ORG_USER_SEARCH_CORPORATE_CUSTOMER_ORGANIZATION" {...iconProps} />,
          ...otherProps
        }
      case DetailItemType.EMAIL:
        return {
          text,
          label: 'E-Mail',
          icon: <EmailOutlined sx={{ marginTop: '2px' }} {...iconProps} />,
          ...otherProps
        }
      case DetailItemType.PHONE:
        return {
          text,
          label: 'Phone',
          icon: <PhoneOutlined sx={{ marginTop: '2px' }} {...iconProps} />,
          ...otherProps
        }
      case DetailItemType.TITLE:
        return {
          text,
          label: 'Title',
          icon: <TitleOutlined sx={{ marginTop: '2px' }} {...iconProps} />,
          ...otherProps
        }
      default:
        return {
          text,
          label: 'Unknown',
          icon: <QuestionMarkOutlined />
        }
    }
  }

  return { getDetailItem }
}
