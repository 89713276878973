import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog } from "@alice/component_library";
import MainViewHeader from "@pages/newRequest/components/RoleConfiguration/mainViewHeader";
import SideBarNewRequest from "@pages/newRequest/components/RoleConfiguration/sideBar";
import { useWidth } from "@utils/hooks";
import MainEditScreen from "./edistScreen";

import {
  MainViewContainer,
  SideBarWrapper,
  MainViewWrapper,
  MainViewHeaderStyle,
} from "@pages/newRequest/styledComponents";

const MainView = ({
  footerRef,
  rolesData,
  userid,
  pageIndex,
  saveRoleData = () => {},
}) => {
  const { t } = useTranslation();
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm"].includes(widthPanel);

  const [showEditScreen, setShowEditScreen] = useState(false);
  const [index, setIndex] = useState(0);
  const [isNextNeedDisabled, setIsNextNeedDisable] = useState(
    rolesData.length > 0 ? false : true
  );
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(true);
  const [activeSelectedId, setActiveSelectedId] = useState(rolesData[0]?.id);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({
    id: null,
    data: { roleDefinition: {} },
  });
  const [roleDataForComponent, setRoleDataForComponent] = useState([]);
  const [configuredId, setConfiguredId] = useState(new Set());

  const onNext = () => {
    const nextIndex = index + 1;
    setIsPreviousDisabled(false);
    if (nextIndex + 1 === rolesData.length) {
      setIsNextNeedDisable(true);
    }
    setIndex(nextIndex);
    setActiveSelectedId(rolesData[nextIndex]?.id);
  };

  const onPrevious = () => {
    const nextIndex = index - 1;
    setIsNextNeedDisable(false);
    if (nextIndex === 0) {
      setIsPreviousDisabled(true);
    }
    setIndex(nextIndex);
    setActiveSelectedId(rolesData[nextIndex]?.id);
  };

  const updateSelectedRecord = useCallback(() => {
    const selectedData = rolesData.find(
      (roleData) => roleData.id === activeSelectedId
    );

    const configurationData = roleDataForComponent.find(
      (roleData) => roleData.id === activeSelectedId
    );

    const selectedRecord = { ...selectedData, ...configurationData };
    setSelectedRecord((prevState) => selectedRecord ?? prevState);
  }, [activeSelectedId, roleDataForComponent, rolesData]);

  const closeDialog = () => {
    setShowEditScreen(false);
    updateSelectedRecord();
  };

  const openDialog = () => {
    setShowEditScreen(true);
  };

  useEffect(() => {
    setActiveSelectedId(rolesData[index]?.id);
    setRoleDataForComponent(rolesData);
    const validIds = new Set(rolesData.map((role) => role.id));
    const filteredConfiguredId = new Set(
      [...configuredId].filter((id) => validIds.has(id))
    );
    setConfiguredId(filteredConfiguredId);

    if (rolesData.length === 1) {
      setIsNextNeedDisable(true);
    } else {
      setIsNextNeedDisable(false);
    }
  }, [rolesData]);

  useEffect(() => {
    updateSelectedRecord();
  }, [activeSelectedId]);

  const saveInfoWithroleData = (id, data) => {
    const index = roleDataForComponent.findIndex((item) => item.id === id);
    if (index !== -1) {
      setRoleDataForComponent((prevState) => {
        const updatedData = [...prevState];
        updatedData[index] = { ...updatedData[index], ...data };
        return updatedData;
      });
    }

    if (data.id && data.isConfigured) {
      setConfiguredId((prevIds) => new Set(prevIds.add(data.id)));
    } else if (data.id && configuredId.size > 0) {
      if ("id" in data && configuredId.has(data.id)) {
        setConfiguredId((prevIds) => {
          const newSet = new Set(prevIds);
          newSet.delete(data?.id);
          return newSet;
        });
      }
    }
  };

  useEffect(() => {
    footerRef?.current?.updateConfigured(configuredId.size);
  }, [configuredId]);

  const SetActiveIndexByClick = (id) => {
    setIsNextNeedDisable(false);
    setIsPreviousDisabled(false);
    setActiveSelectedId(id);
    const index = rolesData.findIndex((item) => item.id === id);
    setIndex(index);
    if (index === 0) {
      setIsPreviousDisabled(true);
    }
    if (index + 1 === rolesData.length) {
      setIsNextNeedDisable(true);
    }
  };

  useEffect(() => {
    footerRef?.current?.updateConfigured(configuredId.size);
    setIndex(0);
    setActiveSelectedId(rolesData[0]?.id);
    setIsPreviousDisabled(true);
    setIsNextNeedDisable(rolesData.length > 1 ? false : true);
  }, [pageIndex]);

  useEffect(() => {
    const combinedScopeData = [];
    const seenExternalIds = new Set();
    roleDataForComponent
      .filter((item) => item.id !== activeSelectedId)
      .forEach((item) => {
        if (
          item.configurationInformation &&
          item.configurationInformation.scopeData
        ) {
          const scopeData = item.configurationInformation.scopeData.data;
          for (const key in scopeData) {
            if (scopeData.hasOwnProperty(key)) {
              const scopeItem = scopeData[key];
              if (
                !seenExternalIds.has(scopeItem.externalId) &&
                scopeItem?.id?.length > 0
              ) {
                combinedScopeData.push(scopeItem);
                seenExternalIds.add(scopeItem.externalId);
              }
            }
          }
        }
      });
    setCombinedData(combinedScopeData);
    footerRef?.current?.updateConfigured(configuredId.size);
    saveRoleData(roleDataForComponent);
  }, [roleDataForComponent]);

  return (
    <MainViewContainer>
      <SideBarWrapper>
        <SideBarNewRequest
          rolesData={rolesData}
          configuredRoleId={configuredId ?? []}
          selectedId={activeSelectedId}
          onClickHandler={(id) => {
            SetActiveIndexByClick(id);
            if (isMobile) openDialog();
          }}
          configuredList={roleDataForComponent}
        />
      </SideBarWrapper>
      {!isMobile && (
        <MainViewWrapper>
          <MainViewHeaderStyle>
            <MainViewHeader
              selectedCount={rolesData?.length}
              completedCount={index + 1}
              isPreviousDisbaled={isPreviousDisabled}
              isNextDisabled={isNextNeedDisabled}
              onNext={() => onNext()}
              onPrevious={() => onPrevious()}
            />
          </MainViewHeaderStyle>
          <MainEditScreen
            data={selectedRecord}
            combinedData={combinedData}
            userid={userid}
            saveDataToParent={(id, data) => saveInfoWithroleData(id, data)}
            pageIndex={pageIndex}
          />
        </MainViewWrapper>
      )}

      {
        <Dialog
          open={isMobile && showEditScreen}
          onClose={closeDialog}
          headerSection={activeSelectedId}
          sx={{
            "& .MuiTypography-root": {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
            "& .DialogActions": {
              "align-items": "center",
            },
          }}
          footerSection={
            <MainViewHeader
              selectedCount={rolesData?.length}
              completedCount={index + 1}
              isPreviousDisbaled={isPreviousDisabled}
              isNextDisabled={isNextNeedDisabled}
              onNext={() => onNext()}
              onPrevious={() => onPrevious()}
            />
          }
        >
          <MainEditScreen
            data={selectedRecord}
            combinedData={combinedData}
            userid={userid}
            saveDataToParent={(id, data) => saveInfoWithroleData(id, data)}
            pageIndex={pageIndex}
          />
        </Dialog>
      }
    </MainViewContainer>
  );
};

export default MainView;
