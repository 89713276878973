import { mergeObjects } from '../../../util'
import { AliceService } from './AliceService'

export class SupportContactsService {
  static getAll = ({ ...args }) => AliceService.get(
    '/supportcontacts',
    { ...args }
  )

  static getById = ({
    id,
    ...args
  }) => AliceService.get(
    `/supportcontacts/${id}`,
    { ...args }
  )

  static create = ({
    body,
    ...args
  }) => AliceService.post(
    '/supportcontacts',
    mergeObjects(
      { body },
      { ...args }
    )
  )

  static updateById = ({
    id,
    body,
    ...args
  }) => AliceService.put(
    `/supportcontacts/${id}`,
    mergeObjects(
      { body },
      { ...args }
    )
  )

  static deleteById = ({
    id,
    ...args
  }) => AliceService.delete(
    `/supportcontacts/${id}`,
    { ...args }
  )
}
