import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const ErrorFallbackPage = () => {
  const { t } = useTranslation();

  const refreshLoad = () => {
    const currentUrl = window.location.href;
    const homePath = "/access";
    const homeUrl = currentUrl.split("/").slice(0, 3).join("/") + homePath;
    window.location.href = homeUrl;
  };

  const supportPage = () => {
    const currentUrl = window.location.href;
    const homePath = "/access/support";
    const homeUrl = currentUrl.split("/").slice(0, 3).join("/") + homePath;
    window.location.href = homeUrl;
  };

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{ display: "flex", alignItems: "center" }}
          spacing={1}
        >
          <Typography fontSize="3rem" fontWeight="bold">
            Something Went Wrong here...
          </Typography>
        </Stack>
        <br />

        <br />
        <Typography variant="h3">Appologies for inconvenience</Typography>
        <br />
        <Container>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="outlined"
                id="showRequest"
                onClick={() => supportPage()}
              >
                {"Help"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                id="showRequest"
                onClick={() => refreshLoad()}
              >
                {"Home"}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Paper>
  );
};

export default ErrorFallbackPage;
