import { Grid } from "@mui/material";
import {
  SubTopicContainer,
  SubHeader,
  SubHeaderTypography,
  TaskRequestDetailsContainer,
  TaskRequestHeader,
} from "./StyledComponents";
import {
  AliceIcon,
  AliceIconType,
  OrgCard,
  UserCard,
} from "@alice/component_library";
import { useTranslation } from "react-i18next";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import OrganizationIcon from "@Icons/taskpage/taskDetails/Organization.svg";

// import BeneficiaryCard from "@src/sharedComponents/UserCards/userCard";
// import OrganizationCard from "@src/sharedComponents/UserCards/organizationCard";

const AssignUserToOrg = ({ taskInfo, windowSize }) => {
  const { t } = useTranslation();
  const { state, originator = {}, affectedData = {}, id } = taskInfo;
  const { org = {}, user = {} } = affectedData;
  const { isMobile } = windowSize;
  return (
    <TaskRequestDetailsContainer disabled={state !== "PENDING"}>
      <TaskRequestHeader>
        {t("task-type-details-header.assign-user-org-details")}
      </TaskRequestHeader>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <RequesterIcon />
            <SubHeaderTypography>{t("requester")}</SubHeaderTypography>
          </SubHeader>

          <UserCard
            userData={originator}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
          />
        </SubTopicContainer>
      </Grid>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <AliceIcon
              iconType={AliceIconType.USER_OUTLINE}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <SubHeaderTypography>{t("user")}</SubHeaderTypography>
          </SubHeader>
          {/* <BeneficiaryCard
            userData={user}
            isTechUser={taskType === "APPROVE_TECHUSER_TO_APPLICATION"}
          /> */}

          <UserCard
            userData={user}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
          />
        </SubTopicContainer>
      </Grid>
      <Grid container>
        <SubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <SubHeader>
            <OrganizationIcon />
            <SubHeaderTypography>{t("organization")}</SubHeaderTypography>
          </SubHeader>

          <OrgCard
            onCardClick={function noRefCheck() {}}
            organization={org}
            variant="grid"
            hasContext={true}
          />
        </SubTopicContainer>
      </Grid>
    </TaskRequestDetailsContainer>
  );
};

export default AssignUserToOrg;
