import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CacheKey, CacheStorage, CacheTTL } from '../../data'
import { abortableTimeout, deepEqual } from '../../util'
import { useCache } from '../cache/UseCache'
import { useNotification } from '../notification'

export const useApi = (
  apiFunction,
  {
    config: globalConfig = {},
    isCaching = false,
    cacheKey = CacheKey.unnamed,
    cacheStorage = CacheStorage.localStorage,
    cacheTTL = CacheTTL.oneHour,
    isRequestingInitially = true,
    isRequestingOnTruthy = false,
    notificationMessageSuccess: globalNotificationMessageSuccess,
    isNotifyingOnSuccess = !!globalNotificationMessageSuccess,
    notificationMessageError: globalNotificationMessageError,
    isNotifyingOnError = !!globalNotificationMessageError,
    initialResponse = null,
    responseTransformer = (response) => response,
    onRequestStart = (config) => {},
    onRequestEnd = (config) => {},
    onRequestSuccess = (response) => {},
    onRequestAbort = (error) => {},
    onRequestError = (error) => {}
  } = {}
) => {
  const { t } = useTranslation('componentLibrary')
  const { notify } = useNotification()

  const {
    getCachedItem,
    setCachedItem,
    removeCachedItem
  } = useCache(cacheStorage, cacheTTL)

  const [ isRequested, setIsRequested ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(isRequestingInitially)
  const [ response, setResponse ] = useState(initialResponse)
  const [ status, setStatus ] = useState(null)
  const [ isSuccessful, setIsSuccessful ] = useState(false)
  const [ isFailure, setIsFailure ] = useState(false)
  const [ error, setError ] = useState(null)
  const [ errorCode, setErrorCode ] = useState(null)
  const [ errorMessage, setErrorMessage ] = useState(null)

  const abortController = useRef(new AbortController())

  useEffect(() => () => abort(), [])

  const setCache = (response, config) => isCaching && setCachedItem(cacheKey, { response, config: config || globalConfig || {} })
  const invalidateCache = () => isCaching && removeCachedItem(cacheKey)
  const isCacheMatching = (item, config) => isCaching && item && deepEqual(config || globalConfig || {}, item?.config || {})

  const mutate = (newValue) => setResponse(newValue)

  const resetError = () => {
    setIsFailure(false)
    setError(null)
    setErrorCode(null)
    setErrorMessage(null)
  }

  const updateError = (error) => {
    setStatus(error?.status)
    setError(error)
    setErrorCode(error?.status)
    setErrorMessage(error?.data?.message)
  }

  const abort = () => abortController?.current?.abort()

  const abortAndCreateNewController = () => {
    abort()

    abortController.current = new AbortController()
  }

  const successfulCache = (item) => {
    setIsSuccessful(true)
    setResponse(item.response)
    onRequestSuccess(item.response)
  }

  const successfulRequest = (response, config, message) => {
    if (isNotifyingOnSuccess) {
      notify({
        severity: 'success',
        message: message
          || (typeof globalNotificationMessageSuccess === 'function'
            ? globalNotificationMessageSuccess(response, config)
            : globalNotificationMessageSuccess
          )
          || t('general.success')
      })
    }

    const transformedResponse = responseTransformer(response)

    setIsSuccessful(true)
    setCache(transformedResponse, config)
    setResponse(transformedResponse)
    onRequestSuccess(transformedResponse, response)
  }

  const failedRequest = (error, message) => {
    setIsFailure(true)
    updateError(error)

    if (error?.code === 'ERR_CANCELED') {
      console.info('Request canceled:', error.message)

      onRequestAbort(error)

      return
    }

    console.error('Request error: ', error)

    onRequestError(error)

    if (isNotifyingOnError) {
      notify({
        severity: 'error',
        message: message
          || (typeof globalNotificationMessageError === 'function'
            ? globalNotificationMessageError(error)
            : globalNotificationMessageError
          )
          || error?.data?.message
          || (error?.status ? t(`httpCode.${error.status}`) : t('httpCode.unknown'))
      })
    }
  }

  const endRequest = (config) => {
    setIsLoading(false)
    setIsRequested(true)
    onRequestEnd(config)
  }

  const execute = ({
    config = {},
    notificationMessageSuccess,
    notificationMessageError,
    isSilent
  } = {}) => {
    setIsLoading(!isSilent)

    resetError()
    abortAndCreateNewController()
    onRequestStart(config)

    const cachedItem = getCachedItem(cacheKey)

    if (isCacheMatching(cachedItem, config)) {
      abortableTimeout(200, abortController.current)
        .then(() => { successfulCache(cachedItem) })
        .finally(() => endRequest(config))
    } else {
      apiFunction({
        ...globalConfig,
        ...config,
        signal: abortController?.current?.signal
      })
        .then((response) => successfulRequest(response, config, notificationMessageSuccess))
        .catch((error) => failedRequest(error, notificationMessageError))
        .finally(() => endRequest(config))
    }
  }

  useEffect(() => {
    if ((isRequestingInitially || isRequestingOnTruthy) && !isRequested) execute()

    // We don't want to execute to be called automatically again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isRequestingInitially, isRequestingOnTruthy, isRequested ])

  return {
    isRequested,
    isLoading,
    isSuccessful,
    isFailure,
    response,
    status,
    error,
    errorCode,
    errorMessage,
    execute,
    abort,
    mutate,
    invalidate: invalidateCache
  }
}
