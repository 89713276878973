import { Stack } from '@mui/material'
import { isBefore, isValid } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateInput } from '../form/dateInput/DateInput'

export const RangeInput = ({
  onChange,
  parentFrom,
  parentTo,
  isRange
}) => {
  const { t } = useTranslation('componentLibrary')

  const [ fromValue, setFromValue ] = useState('')
  const [ toValue, setToValue ] = useState('')

  // useEffect is used to show chosen dates in calendar when the dates are
  // entered from input
  useEffect(() => {
    const validatedFrom = isValid(fromValue) ? fromValue : undefined
    const validatedTo = isValid(toValue) ? toValue : undefined

    if (validatedFrom && validatedTo) onChange({ from: fromValue, to: toValue })

    if (validatedFrom && !isRange) onChange({ from: validatedFrom })
  }, [ fromValue, toValue, onChange, isRange ])

  // useEffect is used to show chosen dates in input fields when the dates are
  // entered from calendar
  useEffect(() => {
    if (parentFrom && parentTo) {
      setFromValue(parentFrom)

      setToValue(parentTo)
    }
  }, [ parentTo, parentFrom ])

  // useEffect is used to show chosen date in input field when the date is
  // entered from calendar (single date picker logic)
  useEffect(() => {
    if (!isRange && parentFrom instanceof Date) {
      setFromValue(parentFrom)
    }
  }, [ isRange, parentFrom ])

  const onHandleSubmit = (e) => {
    e.preventDefault()

    const fromDate = fromValue
    const toDate = toValue

    if (isRange && toDate && isBefore(toDate, fromDate)) {
      onChange({ from: toDate, to: fromDate })
    } else if (isRange && toDate && isBefore(fromDate, toDate)) {
      onChange({ from: fromDate, to: toDate })
    }

    if (!isRange) onChange({ from: fromDate })
  }

  const handleStartDateChange = (value) => setFromValue(value)
  const handleEndDateChange = (value) => setToValue(value)

  return (
    <Stack
      component="form"
      direction="column"
      sx={{ p: '16px 24px' }}
      gap={2}
      justifyContent="center"
      noValidate
      autoComplete="off"
      onSubmit={onHandleSubmit}
      data-testid="rangeInput"
    >
      <DateInput
        inputLabel={isRange ? t('dialog.inputStart') : t('dialog.date')}
        onChange={handleStartDateChange}
        value={fromValue}
        isPickerButtonDisabled
        fullWidth
        slotPropsTextField={{ 'data-testid': 'RangeInput-start-date' }}
      />

      {isRange && (
        <DateInput
          inputLabel={t('dialog.inputEnd')}
          onChange={handleEndDateChange}
          value={toValue}
          isPickerButtonDisabled
          fullWidth
          slotPropsTextField={{ 'data-testid': 'RangeInput-end-date' }}
        />
      )}
    </Stack>
  )
}
