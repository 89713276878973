import React from "react";
import getReviewBgColor from "@src/pages/accessReview/RoleReviews/BgColor";
import {
  ReviewStatusbar,
  AssignmentsContainer,
} from "@pages/accessReview/styledComponents";
import { UserAvatar, UserContextPopover } from "@alice/component_library";
import { useWidth } from "@utils/hooks";
import { dateMonthFullYear } from "../utils";

const StatusBar = ({ data, reviewType }) => {
  const widthPanel = useWidth();
  const isMobile = ["xs", "sm", "md"].includes(widthPanel);

  return (
    <>
      {data?.length > 0 && (
        <ReviewStatusbar>
          {data.map((item, i) => {
            const { roleToUser = {} } = item;
            const {
              user = {},
              orgScope = {},
              customScope = {},
              validFrom,
              validTo,
            } = roleToUser;

            return (
              <>
                <AssignmentsContainer
                  sx={{ backgroundColor: getReviewBgColor(reviewType) }}
                >
                  <div className="assignment-container">
                    <UserContextPopover id={user.id}>
                      <UserAvatar
                        size="small"
                        userId={user.id}
                        userName={`${user.givenname} ${user.surname}`}
                        showImage={true}
                      />
                    </UserContextPopover>
                    <div className="assignment-containers">{user?.surname}</div>
                  </div>
                  <div className="assignment-containers">{user?.givenname}</div>
                  {isMobile ? null : (
                    <>
                      {orgScope?.name && (
                        <div className="assignment-containers">
                          {orgScope?.name}
                        </div>
                      )}
                      {customScope?.name && (
                        <div className="assignment-containers">
                          {customScope?.name}
                        </div>
                      )}

                      <div className="assignment-containers">
                        {validFrom ? (
                          dateMonthFullYear(new Date(validFrom))
                        ) : (
                          <>-</>
                        )}
                      </div>

                      <div className="assignment-containers">
                        {validTo ? (
                          dateMonthFullYear(new Date(validTo))
                        ) : (
                          <>-</>
                        )}
                      </div>
                    </>
                  )}
                </AssignmentsContainer>
              </>
            );
          })}
        </ReviewStatusbar>
      )}
    </>
  );
};

export default StatusBar;
