import DeeplinkTasks from "@components/TaskDeeplink";
import TaskHeaderIcon from "../TaskDetails/TaskHeaderIcon";
import TaskDetails from "../TaskDetails";
import ApproveRejectBox from "../DialogBox";

const TaskDeeplink = () => {
  return (
    <DeeplinkTasks
      TaskHeaderIcon={TaskHeaderIcon}
      ApproveRejectBox={ApproveRejectBox}
      TaskDetails={TaskDetails}
    />
  );
};

export default TaskDeeplink;
