import axios from "axios";
import { apiBaseUrl } from "./baseAPIService";
import instance from "./axiosInstance";

const Communication = {
  activeRequests: [],
  get: (endPoint, endpointForcancel = "") => {
    const abortController = new AbortController();
    const config = {
      signal: abortController.signal,
    };

    const request = instance.get(apiBaseUrl + endPoint, config);
    if (endpointForcancel.length) {
      Communication.activeRequests.push({
        endpoint: endpointForcancel,
        request,
        abortController,
      });
    }
    return request;
  },
  post: (endPoint, data = {}) => instance.post(apiBaseUrl + endPoint, data),
  put: (endPoint, data = {}) => instance.put(apiBaseUrl + endPoint, data),
  all: (requests = []) => axios.all(requests),
  removeActiveRequest: (endpoint) => {
    const newActiveRequest = Communication.activeRequests.filter(
      (item) => item.endpoint !== endpoint
    );
    Communication.activeRequests = newActiveRequest;
  },
  cancel: (endpoint) => {
    const matchingRequest = Communication.activeRequests.filter(
      (item) => item.endpoint === endpoint
    );
    matchingRequest.forEach(({ abortController, endpoint }) => {
      abortController.abort();
      Communication.removeActiveRequest(endpoint);
    });
  },
};

export default Communication;
