import { Typography } from "@mui/material";
import { TaskStatusChip } from "@alice/component_library";
import Calender from "@Icons/taskpage/calender.svg";
import CloseIcon from "@Icons/taskpage/closeIcon.svg";
import {
  TaskDetailsContainer,
  TaskDetailsHeader,
} from "../../styledComponents";
import TaskRequestDetails from "./TaskRequestDetails";
import { TaskIdHeader, TaskIdLink } from "./StyledComponents";
import { useTranslation } from "react-i18next";
import TaskHeaderIcon from "./TaskHeaderIcon";
import { getTaskDateInfoText } from "@src/pages/taskPage/util";
import TCard from "@src/sharedComponents/taskCard/TCard";

const BgColor = {
  PENDING: "#E6F5FF",
  ACTIVE: "#E6F5FF",
  APPROVED: "#E9F6EA",
  COMPLETED: "#E9F6EA",
  EXPIRED: "#DBDBDB",
  SUSPENDED: "#DBDBDB",
  FAILED: "#FFFAE6",
  REJECTED: "#FFEDED;",
  EXTERNALLY_TERMINATED: "#FFEDED;",
  INTERNALLY_TERMINATED: "#FFEDED;",
};
const TaskDetails = ({
  windowSize,
  taskInfo,
  closeActionHandler,
  handleApprove = {},
  handleReject = {},
  deeplink = false,
  optionType,
  isAdmin,
  filter,
}) => {
  const { t } = useTranslation();
  const { id, taskType, state } = taskInfo;
  const taskInfoText = getTaskDateInfoText(taskInfo, t).stateText;
  const taskStatus = getTaskDateInfoText(taskInfo, t).status;

  return (
    <>
      <TaskDetailsContainer windowsize={windowSize}>
        <TaskDetailsHeader bgcolor={BgColor[state]} windowsize={windowSize}>
          <div className="task-id-container mr-w-10">
            {!deeplink && (
              <div className="task-icon-id-link">
                <TaskHeaderIcon taskType={taskType} size={24} />
                <TaskIdHeader> {t("task-id")} </TaskIdHeader>
                <TaskIdLink
                  to={`/access/tasks/details?taskId=${id}&tasktype=${optionType}&isadmin=${isAdmin}&filter=${filter}`}
                >
                  {id}
                </TaskIdLink>
              </div>
            )}
            <div className="task-id-container w-100">
              <TaskStatusChip type={taskStatus} variant="long" />
              {!deeplink && (
                <div className="calender-container">
                  <Calender />
                  <Typography variant="body1">{taskInfoText}</Typography>
                </div>
              )}
            </div>
          </div>
          {!deeplink && (
            <span className="close-icon" onClick={() => closeActionHandler()}>
              <CloseIcon />
            </span>
          )}
        </TaskDetailsHeader>
        <TCard
          handleApprove={handleApprove}
          handleReject={handleReject}
          taskInfo={taskInfo}
          taskDeepLink={deeplink}
        />
        <TaskRequestDetails
          taskInfo={taskInfo}
          t={t}
          isDeeplink={deeplink}
          windowSize={windowSize}
        />
      </TaskDetailsContainer>
    </>
  );
};

export default TaskDetails;
