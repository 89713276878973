import { Box, Collapse, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { CloseButton } from '../../button'

const arrowWidth = 20
const fontSize = 26

/**
 * A custom Tooltip Component
 *
 * **Note**: In this particular stories, you may notice a misalignment issue with the arrow used to point to certain elements.
 * It's important to clarify that the problem lies within the Storybook application itself and does not effect anything outside of storybook.
 */
export const Inlay = forwardRef((
  {
    open = false,
    closeButton = false,
    fixTop = false,
    onClose,
    anchorElement,
    headline,
    children
  },
  ref
) => {
  const [ arrowPosition, setArrowPosition ] = useState(0)
  const [ arrowTop, setArrowTop ] = useState(0)

  const calcArrowPosition = (anchorEl) => {
    if (anchorEl) {
      const centerX = anchorEl.offsetLeft + anchorEl.offsetWidth / 2
      const centerY = anchorEl.offsetTop + anchorEl.offsetHeight / 2

      setArrowPosition(centerX - arrowWidth / 2)
      setArrowTop(centerY + fontSize)
    }
  }

  useEffect(() => {
    if (anchorElement) calcArrowPosition(anchorElement)
  }, [ anchorElement ])

  window.onresize = () => {
    calcArrowPosition(anchorElement)
  }

  const Arrow = () => (
    <Box
      sx={{
        position: 'absolute',
        left: `${arrowPosition}px`,
        top: fixTop ? `${arrowTop}px` : 'initial'
      }}
    >
      <span
        style={{
          display: 'inline-block',
          transform: 'scaleX(1.5)',
          color: 'white',
          textShadow: '0px 0px white, 0 -2px 0px black',
          fontSize: `${fontSize}px`
        }}
      >
        ▲
      </span>
    </Box>
  )

  return (
    <Box data-testid="Inlay" ref={ref}>
      <Collapse in={open} unmountOnExit>
        <Arrow />

        <Grid
          container
          sx={{
            border: '1px solid #000000',
            marginTop: `${fontSize - 9}px`
          }}
        >
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Grid
              item
              xs={10}
              sx={{
                fontSize: '20px',
                fontWeight: '500',
                color: '#000000',
                padding: '1rem 2rem'
              }}
            >
              {headline}
            </Grid>

            <Grid item xs={2} sx={{ textAlign: 'end' }}>
              {closeButton && <CloseButton onClick={onClose} />}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ paddingX: '2rem', paddingBottom: '1rem' }}>
            {children}
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
})

Inlay.propTypes = {
  /** Ref to the DOM element that opened the inlay. */
  anchorElement: PropTypes.object.isRequired,
  /** A headline to display at the top of the inlay. */
  headline: PropTypes.string,
  /** Control the inlay */
  open: PropTypes.bool,
  /** If a close button should be shown */
  closeButton: PropTypes.bool,
  /**
   * Event handler for the closeButton
   *
   * @param {Event}
   * @returns {void}
   */
  onClose: PropTypes.func,
  /** If the arrow is not displayed, try to set this to true. */
  fixTop: PropTypes.bool,
  children: PropTypes.node
}
