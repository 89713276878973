import React from "react";
import Header from "../RoleConfiguration/header";
import MainEditScreen from "../RoleConfiguration/edistScreen";
import {
  MainEditScreenContainer,
  RoleConfigMainComponent,
} from "../../styledComponents";

const RoleConfiguration = ({
  roleDetails = {},
  saveRoleData = () => {},
  isExtend = false,
  isReapply = false,
  isDisplay = true,
}) => {
  return (
    <RoleConfigMainComponent isDisplay={isDisplay}>
      <Header />
      <MainEditScreenContainer>
        <MainEditScreen
          data={roleDetails}
          saveDataToParent={saveRoleData}
          isFromDeeplink
          isExtend={isExtend}
          isReapply={isReapply}
          combinedData={[]}
        />
      </MainEditScreenContainer>
    </RoleConfigMainComponent>
  );
};

export default RoleConfiguration;
