import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  RequestSubHeader,
  RequestSubHeaderTypography,
  RequestDetailsContainer,
  RequestHeader,
  RSubTopicContainer,
} from "@pages/requestPage/styledComponents";
import { AliceIcon, AliceIconType, UserCard } from "@alice/component_library";
import RequesterIcon from "@Icons/taskpage/taskDetails/requester.svg";
import ApplicationCard from "@src/sharedComponents/UserCards/applicationCard";
import WorkFlowData from "../RequestWorkflow/WorkFlowData";
import { RoleCard } from "./RoleCardComponent";

const AssignEntitleToRole = ({ taskInfo, windowSize, loggedInUser }) => {
  const { t } = useTranslation();
  const {
    originator = {},
    state,
    affectedData = {},
    isWorkFlow,
    id,
  } = taskInfo;
  const { entitlement = {} } = affectedData;

  const { id: requesterId } = originator;

  const { isMobile } = windowSize;

  return (
    <RequestDetailsContainer>
      <RequestHeader>
        {t("task-type-details-header.assign-entitlement-details")}
      </RequestHeader>

      <Grid container spacing={2}>
        <RSubTopicContainer
          xs={12}
          sm={12}
          md={6}
          sx={{ width: "37vw", flexBasis: "100%" }}
        >
          <RequestSubHeader>
            <RequesterIcon />
            <RequestSubHeaderTypography>
              {t("requester")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>

          <UserCard
            userData={originator}
            readonly
            size={isMobile ? "small" : "medium"}
            sx={{ flexWrap: "wrap" }}
            isContextShown={true}
            className={loggedInUser === requesterId ? "highlight-card" : ""}
          />
        </RSubTopicContainer>
        <RSubTopicContainer xs={12} sm={12} md={6}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>
              {t("entitlement")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>

          <ApplicationCard
            appId={entitlement?.applicationId}
            entitlementName={entitlement?.name}
            entitlementDescription={entitlement?.description}
            dataClassification={entitlement?.dataClassification}
            appCard={false}
            sx={{ height: "80%" }}
          />
        </RSubTopicContainer>
      </Grid>

      <Grid container>
        <RSubTopicContainer xs={12}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>{t("role")}</RequestSubHeaderTypography>
          </RequestSubHeader>
        </RSubTopicContainer>
        <RoleCard taskInfo={taskInfo} isMobile={isMobile} />
      </Grid>
      {(state === "PENDING" || isWorkFlow) && (
        <WorkFlowData
          workflowId={id}
          isWorkFlow={isWorkFlow}
          taskInfo={taskInfo}
          isDeeplink={false}
        />
      )}
    </RequestDetailsContainer>
  );
};

export default AssignEntitleToRole;
