import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Interaction } from '../../constants'

export const useInteraction = ({
  interaction,
  isLoading = false,
  isDisabled = false
}) => {
  const [ isSelectable, setIsSelectable ] = useState(false)
  const [ isSelectHidden, setIsSelectHidden ] = useState(false)
  const [ isMultiselect, setIsMultiselect ] = useState(false)
  const [ isLink, setIsLink ] = useState(false)
  const [ isClickable, setIsClickable ] = useState(false)

  useEffect(() => {
    setIsSelectable(
      interaction === Interaction.SINGLE_SELECT
      || interaction === Interaction.MULTI_SELECT
      || interaction === Interaction.SINGLE_SELECT_WITHOUT_RADIO
      || interaction === Interaction.MULTI_SELECT_WITHOUT_CHECKBOX
    )

    setIsSelectHidden(
      interaction === Interaction.SINGLE_SELECT_WITHOUT_RADIO
      || interaction === Interaction.MULTI_SELECT_WITHOUT_CHECKBOX
    )

    setIsMultiselect(
      interaction === Interaction.MULTI_SELECT
      || interaction === Interaction.MULTI_SELECT_WITHOUT_CHECKBOX
    )

    setIsClickable(interaction !== Interaction.NONE && !isLoading && !isDisabled)
    setIsLink(interaction === Interaction.NAVIGATE)
  }, [ interaction, isDisabled, isLoading ])

  return {
    isSelectable,
    isSelectHidden,
    isMultiselect,
    isLink,
    isClickable
  }
}

export const InteractionType = PropTypes.oneOf([
  Interaction.NONE,
  Interaction.NAVIGATE,
  Interaction.SINGLE_SELECT,
  Interaction.MULTI_SELECT,
  Interaction.SINGLE_SELECT_WITHOUT_RADIO,
  Interaction.MULTI_SELECT_WITHOUT_CHECKBOX
])
