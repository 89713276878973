import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { UserCard } from '../UserCard/UserCard'

/**
 * List of UserCards mainly used for [`UserListPopover`](./?path=/docs/alice-ui-userelements-userlistpopover--docs)
 *
 * Integrated CLIB-Components
 *
 * - [UserCard](./?path=/docs/alice-ui-userelements-usercard--docs)
 */
export const UserList = ({
  users,
  isLoading,
  sx
}) => (
  <Grid
    data-testid="UserList"
    container
    gap={2}
    sx={sx}
  >
    {users.map((user) => (
      <Grid key={user.id} item xs={12}>
        <UserCard
          userData={user}
          isContextShown={false}
          isLoading={isLoading}
          variant="grid"
          size="m"
        />
      </Grid>
    ))}
  </Grid>
)

UserList.propTypes = {
  /** List of users to be displayed */
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    givenname: PropTypes.string,
    surname: PropTypes.string,
    groupType: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    departmentNumber: PropTypes.string,
    communities: PropTypes.arrayOf(PropTypes.string)
  }))
}
