// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xtNFnfk4mdau8kiWZUCw{display:flex;justify-content:space-between;align-items:center;position:relative;height:56px;margin-left:4px;border:none;z-index:1}.tv5dpGU5VSeB2OgBRafU{display:flex;align-items:center;gap:8px}.tv5dpGU5VSeB2OgBRafU{display:flex;gap:8px;align-items:center}.tv5dpGU5VSeB2OgBRafU svg{width:18px;height:18px;transition:transform 250ms}.xtNFnfk4mdau8kiWZUCw.UJTOpQvs6mYgL8oo_38Q .tv5dpGU5VSeB2OgBRafU svg{transform:rotate(180deg)}.IJhHxH5uGfLMWTnXT65W{display:flex;align-items:center;gap:1em;opacity:1;transition:opacity 250ms}.xtNFnfk4mdau8kiWZUCw.UJTOpQvs6mYgL8oo_38Q .IJhHxH5uGfLMWTnXT65W{opacity:0;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/ComponentLibrary/src/ui/datePicker/Caption/Caption.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CACA,eAAA,CACA,WAAA,CACA,SAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,0BAAA,CAEA,qEACE,wBAAA,CAKN,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,SAAA,CACA,wBAAA,CAEA,iEACE,SAAA,CACA,mBAAA","sourcesContent":[".caption {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  height: 56px;\n  margin-left: 4px;\n  border: none;\n  z-index: 1;\n\n  &Date {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n\n  &Date {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n\n    svg {\n      width: 18px;\n      height: 18px;\n      transition: transform 250ms;\n\n      .caption.open & {\n        transform: rotate(180deg)\n      }\n    }\n  }\n\n  &NavButtons {\n    display: flex;\n    align-items: center;\n    gap: 1em;\n\n    opacity: 1;\n    transition: opacity 250ms;\n\n    .caption.open & {\n      opacity: 0;\n      pointer-events: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": `xtNFnfk4mdau8kiWZUCw`,
	"captionDate": `tv5dpGU5VSeB2OgBRafU`,
	"open": `UJTOpQvs6mYgL8oo_38Q`,
	"captionNavButtons": `IJhHxH5uGfLMWTnXT65W`
};
export default ___CSS_LOADER_EXPORT___;
