import React, { useCallback, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { FetchExpiringRoles, GetVisibleSupportStatus } from "@actions";

import ExpiringRoleCard from "./ExpiringRoleCard";
import { ExpiringCardWrapper } from "../../styledComponents";
import { useUser } from "@alice/component_library";

const ExpiringRoles = () => {
  const dispatch = useDispatch();

  const {
    role: { rolesInfoDesktop },
  } = useSelector((state) => state);

  const { user } = useUser();
  const { id: userId } = user;

  const { adminRolesInfo = [] } = useSelector(
    (state) => state.profile.visibleSupport
  );

  const globalRoleAssigner = adminRolesInfo?.find(
    (roleInfo) => roleInfo.adminRole === "GLOBAL_ROLE_ASSIGNER"
  );

  const globalRoleIds = globalRoleAssigner
    ? globalRoleAssigner?.administratedRoles?.map((role) => role.roleId)
    : [];

  const fetchData = useCallback(() => {
    dispatch(FetchExpiringRoles(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!rolesInfoDesktop?.isLoaded && !rolesInfoDesktop.loading) {
      fetchData();
    }
  }, [fetchData, rolesInfoDesktop?.isLoaded, rolesInfoDesktop.loading]);

  useEffect(() => {
    if (adminRolesInfo.length === 0) {
      dispatch(GetVisibleSupportStatus());
    }
  }, [dispatch, adminRolesInfo]);

  return (
    <ExpiringCardWrapper>
      <ExpiringRoleCard
        roleData={rolesInfoDesktop}
        handleExtendOrReaaply={`/access/accessRequest/mySelf?user=${userId}`}
        globalRoleIds={globalRoleIds}
        fetchData={fetchData}
      />
    </ExpiringCardWrapper>
  );
};

export default ExpiringRoles;
