import { styled } from "@mui/system";

export const CardContainer = styled("div")(({ ...props }) => ({
  width: "-webkit-fill-available",
  background: "#f4f4f4",
  padding: "20px 16px",
}));
export const CardDetailHeader = styled("div")(({ theme, ...props }) => ({
  [theme.breakpoints.down("sm")]: {
    marginBottom: "25px",
  },
  display: "flex",
  flexWrap: "wrap",
  width: "-webkit-fill-available",
  "& .head-left": {
    display: "flex",
    columnGap: "3px",
    "& .header-svg-left": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "baseline",
      },
    },
    "& .header-value-left": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
      flexWrap: "wrap",
      wordBreak: "break-all",
      margin: "0 3px",
      "& span": {
        margin: "0 3px",
      },
    },
  },

  "& .remove-icon-svg-": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    // margin: "0 8px",
  },
  "& .head-right": {
    display: "flex",
    columnGap: "3px",
    "& .header-svg-right": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "baseline",
      },
    },
    "& .header-value-right": {
      margin: "0 4px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-all",
      "& span": {
        marginLeft: "2px",
      },
    },
  },

  "& .header-svg-operation-icon": {
    display: "flex",
  },
}));

export const CardDetailDescription = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  color: "rgba(0, 0, 0, 0.60)",
  flexWrap: "wrap",
  flexDirection: "row",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  "& strong": {
    margin: "0 5px",
    wordBreak: "break-all",
  },
  "& .detail-left": {
    display: "flex",
    flexWrap: "wrap",
    fontSize: "14px",
    "& .card-detail-1": {
      "& span": {
        fontWeight: "700",
        marginLeft: "3px",
      },
    },
    "& .detail-value-left": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
      },
      display: "flex",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-all",
      flexWrap: "wrap",
      marginLeft: "4px",
      fontWeight: "700",
      "& span": {
        margin: "0 3px",
      },
    },
  },

  "& .detail-operation-text": {
    marginLeft: "4px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4px",
    },
    fontSize: "14px",
    "& span": {
      fontWeight: "700",
      margin: "0 4px",
    },
  },
  "& .detail-value-right": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    fontWeight: "700",
    fontSize: "14px",
    "& span": {
      fontWeight: "700",
      marginLeft: "2px",
    },
  },
}));

export const CardApplicationDetails = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-between",
  "& .hidden": {
    visibility: "hidden",
  },
  "& .appHead": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    marginTop: "16px",
    padding: "0 !important",
  },
  "& .Button": {
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      // padding: "0px",
    },
    "& button": {
      height: "31px",
    },
  },
  "& .smsIcon": {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    "& span": {
      marginLeft: "5px",
    },
  },
  "& .applicationReasonText": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
}));

export const TAButtons = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  right: "8px",
  margin: "5px",
  [theme.breakpoints.down("sm")]: {
    margin: "0px",
  },
  flexDirection: "row",
  zIndex: 1,
  "& button": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "16px",
    paddingRight: "16px",
    "& svg": {
      marginRight: "3px",
      path: {
        fill: props.svgcolor,
        fillOpacity: props.svgopacity,
      },
    },
    "& span": {
      fontFamily: "MB Corpo S Text",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
    },
  },
}));
