import React, { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useTranslation } from "react-i18next";
import {
  ReviewProcessRoleId,
  ReviewProcessRoleDescp,
  ReviewProcessSubheaders,
} from "../styledComponents";
import { DataClassificationChip, Tooltip } from "@src/ComponentLibrary";
import LinearProgressBarWithValue from "@components/ProgressBar";
import MultiProfileImageComponent from "@components/MultiProfileImageComponent";
import CustomValidityChip from "@components/CustomValidityChip";
import RecipientDialogue from "@components/workflowDialogue/RecipientDialouge";
import { getColumnName } from "../MainPage/Layout/Newtableutils";

const DetailHeader = ({ data, translate }) => {
  const {
    roleId,
    roleName,
    dataClassification,
    deadline,
    endTime,
    reviewersList,
    status,
    state,
    overDueCount,
  } = data;
  const { t } = useTranslation();
  const [reviewProp, setReviewProp] = useState({ isOpen: false, data: [] });

  const reviewDialogue = (data) => {
    setReviewProp((prevProps) => ({
      ...prevProps,
      isOpen: !prevProps.isOpen,
      data: data,
    }));
  };

  return (
    <>
      <Tooltip type="ellipsized" title={roleId}>
        <ReviewProcessRoleId>{roleId}</ReviewProcessRoleId>
      </Tooltip>
      <Tooltip type="ellipsized" title={roleName}>
        <ReviewProcessRoleDescp>{roleName}</ReviewProcessRoleDescp>
      </Tooltip>
      <DataClassificationChip type={dataClassification?.toLowerCase()} />
      <div className="sub-detail-header">
        <div>
          <ReviewProcessSubheaders sx={{ paddingBottom: "12px" }}>
            {getColumnName(state)}
          </ReviewProcessSubheaders>

          <CustomValidityChip
            date={state === "COMPLETED" ? endTime ?? deadline : deadline}
            icon={
              state === "COMPLETED" ? (
                <CheckCircleOutlineIcon fontSize="1.2rem" />
              ) : (
                <RemoveCircleOutlineIcon fontSize="1.2rem" />
              )
            }
            label={translate(
              state === "COMPLETED"
                ? "role-review-by-status.done"
                : "role-review-by-status.overdue"
            )}
            state={state}
            color={state === "COMPLETED" ? "#0078D6" : "#D92121"}
          />
        </div>
        <div>
          <ReviewProcessSubheaders
            sx={{
              paddingBottom: "5px",
            }}
          >
            {translate("role-review.reviewers")}
          </ReviewProcessSubheaders>
          <MultiProfileImageComponent
            data={reviewersList}
            circleSize="32px" // Customize the circle size
            componentSize="300px" // Customize the component size
            fontSize="14px"
            onBadgeClick={(data) => {
              // Handle badge click in the parent component
              reviewDialogue(data);
            }}
            gapInbetween="8px"
            borderWidth="0px"
            maxImages={3}
            isneeduserContextPopover={true}
          />
        </div>
      </div>
      <div className="sub-detail-status">
        <ReviewProcessSubheaders
          sx={{
            paddingBottom: "8px",
          }}
        >
          {translate("role-review.status")}
        </ReviewProcessSubheaders>
        <LinearProgressBarWithValue
          total={status?.total}
          value={status?.completed}
          overdueCount={overDueCount}
          Filtervalue={state}
        />
      </div>
      <RecipientDialogue
        userList={reviewProp.data}
        isOpen={reviewProp.isOpen}
        toggleDialogue={(data) => {
          reviewDialogue([]);
        }}
        t={t}
      />
    </>
  );
};

export default DetailHeader;
