import { AlertTitle } from '@mui/material'
import PropTypes from 'prop-types'
import { createArrayWithInitialValues } from '../../../util'
import { Alert } from '../../message'
import { Skeleton } from '../../skeleton'
import { TypographyLineClamp } from '../../typography'

const AnnouncementCardSkeleton = ({ lineClamp = 3 }) => {
  if (!lineClamp) lineClamp = 3

  return (
    <Alert
      data-testid="AnnouncementCardSkeleton"
      severity="info"
      icon={<Skeleton variant="rectangular" width="20px" height="20px" />}
      sx={{ '.MuiAlert-message': { width: '100%' } }}
    >
      <AlertTitle>
        <Skeleton variant="rectangular" width="100%" height="20px" />
      </AlertTitle>

      {createArrayWithInitialValues(lineClamp).map((index) => (
        <Skeleton key={`skeleton-${index}`} width={index <= lineClamp - 1 ? '100%' : '65%'} />
      ))}
    </Alert>
  )
}

/** Displays a single announcement as a card */
export const AnnouncementPopoverCard = ({
  id,
  title,
  message,
  onClick,
  hasSeen,
  isLoading,
  lineClamp = 0
}) => {
  const handleClick = () => {
    if (typeof onClick === 'function') onClick(id)
  }

  return isLoading
    ? (
      <AnnouncementCardSkeleton lineClamp={lineClamp} />
    )
    : (
      <Alert
        data-testid="AnnouncementCard"
        severity="info"
        sx={[
          hasSeen && { backgroundColor: 'transparent' },
          onClick && { cursor: 'pointer' }
        ]}
        onClick={handleClick}
      >
        <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>

        <TypographyLineClamp lineClamp={lineClamp}>
          {message}
        </TypographyLineClamp>
      </Alert>
    )
}

AnnouncementPopoverCard.propTypes = {
  /** Unique identifier of the announcement */
  id: PropTypes.number,
  /** Announcement title */
  title: PropTypes.string,
  /** Announcement message. */
  message: PropTypes.string,
  /** Event handler function called when the card is clicked */
  onClick: PropTypes.func,
  /** Indicates whether the announcement has been seen or not. */
  hasSeen: PropTypes.bool,
  /**
   * The number of lines to clamp the message content.
   * If specified, the content will be truncated after the given number of lines.
   */
  lineClamp: PropTypes.number
}
