import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  RequestSubHeader,
  RequestSubHeaderTypography,
  RequestDetailsContainer,
  RequestHeader,
  RSubTopicContainer,
} from "@pages/requestPage/styledComponents";
import {
  AliceIcon,
  AliceIconType,
  Table,
  UserCard,
} from "@alice/component_library";
// import BeneficiaryCard from "@src/sharedComponents/UserCards/userCard";
import WorkFlowData from "../RequestWorkflow/WorkFlowData";
import { RoleCard } from "./RoleCardComponent";

const DeprovReviewToRoleAssign = ({ taskInfo, windowSize, loggedInUser }) => {
  const { t } = useTranslation();
  const { state, affectedData = {}, isWorkFlow, id } = taskInfo;
  const { user = {} } = affectedData;

  const { isMobile } = windowSize;
  const { id: userId } = user;

  let headerConfig = [
    {
      key: t("attribute_name"),
      Header: t("attribute_name"),
    },
    {
      key: t("old_value"),
      Header: t("old_value"),
    },
    {
      key: t("new_name"),
      Header: t("new_name"),
    },
  ];

  let data = [
    ["dcxDeptld", user?.oldDeptId, user?.newDeptId],
    ["Department", user?.oldDepartmentNumber, user?.newDepartmentNumber],
    [
      "SUPERVISOR",
      user.oldSupervisor?.givenname + " " + user.oldSupervisor?.surname,
      user.newSupervisor?.givenname + " " + user.newSupervisor?.surname,
    ],
  ];
  return (
    <RequestDetailsContainer>
      <RequestHeader>
        {t("task-type-details-header.deprovisioning-review-details")}
      </RequestHeader>
      <Grid container>
        <RSubTopicContainer xs={12}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.KEYS}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>{t("role")}</RequestSubHeaderTypography>
          </RequestSubHeader>
        </RSubTopicContainer>
        <RoleCard taskInfo={taskInfo} isMobile={isMobile} />
      </Grid>
      <Grid container>
        <RSubTopicContainer xs={12} sx={{ width: "37vw", flexBasis: "100%" }}>
          <RequestSubHeader>
            <AliceIcon
              iconType={AliceIconType.USER_OUTLINE}
              size={18}
              sx={{ pointerEvents: "none", cursor: "not-allowed" }}
              color={"#00000099"}
            />
            <RequestSubHeaderTypography>
              {t("affected-user")}
            </RequestSubHeaderTypography>
          </RequestSubHeader>
          {/* <BeneficiaryCard
            userData={user}
            isTechUser={taskType === "APPROVE_TECHUSER_TO_APPLICATION"}
          /> */}

          <UserCard
            userData={user}
            readonly
            isContextShown={true}
            size={isMobile ? "small" : "medium"}
            className={loggedInUser === userId ? "highlight-card" : ""}
          />

          <Grid container>
            <RSubTopicContainer
              xs={12}
              sx={{ width: "37vw", flexBasis: "100%" }}
            >
              <RequestSubHeader>
                <AliceIcon
                  iconType={AliceIconType.INFO_CIRCLE}
                  size={18}
                  sx={{ pointerEvents: "none", cursor: "not-allowed" }}
                  color={"#00000099"}
                />
                <RequestSubHeaderTypography>
                  {t("additional-information")}
                </RequestSubHeaderTypography>
              </RequestSubHeader>
              <Table columns={headerConfig} data={data} />
            </RSubTopicContainer>
          </Grid>
        </RSubTopicContainer>
      </Grid>
      {(state === "PENDING" || isWorkFlow) && (
        <WorkFlowData
          workflowId={id}
          isWorkFlow={isWorkFlow}
          taskInfo={taskInfo}
          isDeeplink={false}
        />
      )}
    </RequestDetailsContainer>
  );
};

export default DeprovReviewToRoleAssign;
